import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Column, Container, Row} from 'components-lib';
import {Subtitle} from 'admin/components';
import {getUploadFirstColList, ResourceListColumn} from 'admin/resources';
import {SelectionEnum} from 'filters-selections';
import {UploadTypeEnum} from 'admin/resources/upload/enums';

export const ContentStart = () => {
  const classes = useStyles();

  const firstCollist = useMemo(
    () => [
      SelectionEnum.WebLinkInput,
      SelectionEnum.DisplayNameInput,
      ...getUploadFirstColList(UploadTypeEnum.Scorm),
      SelectionEnum.KeyTermsWithInfo,
      SelectionEnum.AdminPermissionCheckbox,
    ],
    []
  );

  const secondColList = useMemo(
    () => [
      SelectionEnum.BriefDescriptionTextArea,
      SelectionEnum.ProgramMultiSelection,
      SelectionEnum.KnowledgeSkillsAndAbilitiesWithInfo,
      SelectionEnum.ExpirationWithInfoDatePicker,
      SelectionEnum.LearningObjectiveInputWithInfo,
      SelectionEnum.AccessibleToStudentsWithInfo,
    ],
    []
  );

  return (
    <Container disableGutters>
      <Subtitle withColor={false} as="h3" color="textPrimary" title="Details" />
      <Row justify="flex-start" align="flex-start">
        <Column sm={12} md={12} lg={12} xl={12}>
          <Row justify="flex-start" align="flex-start" classes={{root: classes.resourcesContent}}>
            <ResourceListColumn list={firstCollist} sm={6} md={4} lg={4} xl={3} />
            <ResourceListColumn list={secondColList} sm={6} md={4} lg={4} xl={3} />
          </Row>
        </Column>
      </Row>
    </Container>
  );
};

export const useStyles = makeStyles((theme) => ({
  resourcesContent: {
    paddingRight: theme.spacing(4),
  },
}));

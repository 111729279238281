import {createSlice} from '@reduxjs/toolkit';
import {filesAllExtraReducer} from './filesAllExtra.reducer';
import {filesAllReducer} from './filesAll.reducer';
import {filesAllInitialState} from './filesAll.initialState';

export const filesAllSlice = createSlice({
  name: 'filesAll',
  initialState: filesAllInitialState,
  reducers: filesAllReducer,
  extraReducers: filesAllExtraReducer,
});

export const {actions: filesActions} = filesAllSlice;

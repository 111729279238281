import {makeStyles} from '@material-ui/core';
import {IComponentWithClassName} from 'models';
import React, {ChangeEvent, useCallback, useMemo} from 'react';
import {toNumber} from 'utils';
import {Icon} from 'components-lib';

interface ITagProps extends IComponentWithClassName {
  totalItems: number;
  position: number;
  moveHandler?: (movedItemIdx: number, newPosition: number) => void;
}

export function CardItemPositionTag({totalItems, position, moveHandler}: ITagProps) {
  const classes = useStyles();

  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const newPosition: number = toNumber(event.target.value);

      moveHandler(position, newPosition);
    },
    [moveHandler, position]
  );

  const renderOptions = useMemo(
    () =>
      [...Array(totalItems)].map((_, idx) => (
        <option key={`item-position-option-${idx}`} value={idx}>
          {idx + 1}
        </option>
      )),
    [totalItems]
  );

  return (
    <div className={classes.root}>
      <span className={classes.label}>Item</span>
      <div className={classes.selectWrapper}>
        {position + 1}
        <Icon.ExpandIcon color="primary" />
        <select value={position} onChange={changeHandler} className={classes.select}>
          {renderOptions}
        </select>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    borderRadius: theme.spacing(1),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#285f74',
    paddingLeft: 8,
    paddingRight: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  label: {
    marginRight: theme.spacing(1),
  },
  selectWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  select: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
  },
}));

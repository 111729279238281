import React, {ReactNode} from 'react';
import {CardHeader} from '@material-ui/core';
import CardMui from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {IComponentWithChildren, IComponentWithClassName} from 'models';
interface ICardProps extends IComponentWithChildren, IComponentWithClassName {
  header?: string;
  actions?: () => ReactNode;
}

export function Card({header, children, actions, className}: ICardProps) {
  const classes = useStyles();
  return (
    <CardMui className={classnames(classes.root, className)} variant="outlined">
      {!!header && <CardHeader className={classes.header} title={header} />}
      <CardContent className={classes.content}>{children}</CardContent>
      {!!actions && <CardActions className={classes.actions}>{actions()}</CardActions>}
    </CardMui>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    width: '100%',
    marginBottom: theme.spacing(3),
    padding: `${theme.spacing(3)}px ${theme.spacing(2) + theme.spacing(3)}px`,
    overflowY: 'auto',
  },
  header: {
    padding: 0,
    marginBottom: theme.spacing(3),
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  actions: {
    padding: 0,
  },
}));

import {replaceWithNullIfEmptyString} from 'admin/integrations';
import {IIntegrationDetailsFormBaseValues} from 'models/integrations';

export const mapUpdateMicrosoftTeamsPayload = ({
  clientId,
  clientSecret,
  authorityUrl,
  integrationName,
}: IIntegrationDetailsFormBaseValues) => {
  const isSecretStartsWithAsteristic = clientSecret.startsWith('*');

  const configClientId = replaceWithNullIfEmptyString(clientId);
  const configAuthorityUrl = replaceWithNullIfEmptyString(authorityUrl);
  const configIntegrationName = replaceWithNullIfEmptyString(integrationName);

  return {
    name: configIntegrationName,
    clientId: configClientId,
    clientSecret: !isSecretStartsWithAsteristic ? clientSecret : null,
    authorityUrl: configAuthorityUrl,
  };
};

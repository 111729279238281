import React, {SyntheticEvent, DragEvent, useCallback, FC, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {heightUploadResources} from 'utils';
import {UploadSingleFile} from 'admin/components';
import {useFormikContext} from 'formik';
import {IWithDisabled} from 'models/common';
import {DraggableArea, Row} from 'components-lib';

interface ISingleFileUploadProps extends IWithDisabled {
  name: string;
  accept?: string;
  title?: string;
  position?: 'horizontal' | 'vertical';
  withOnlyBrowseButton?: boolean;
  disableDragAndDrop?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  align?: 'flex-start' | 'center' | 'flex-end';
  justify?: 'flex-start' | 'center' | 'flex-end';
  deleteCallback?: () => void;
}

export const SingleFileUpload: FC<ISingleFileUploadProps> = ({
  name,
  accept = '*',
  position = 'vertical',
  disabled = false,
  disableDragAndDrop = false,
  withOnlyBrowseButton = false,
  fullHeight = true,
  fullWidth = true,
  align = 'center',
  justify = 'center',
  title = 'Upload File',
  deleteCallback,
}) => {
  const classes = useStyles({fullHeight, fullWidth});
  const {setFieldValue} = useFormikContext<any>();

  const handleFileDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();

      const newFile = event.dataTransfer.files[0];
      setFieldValue(name, newFile);
    },
    [setFieldValue, name]
  );

  const handleDragOver = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const singleFileUpload = useMemo(
    () => (
      <UploadSingleFile
        disabled={disabled}
        title={title}
        position={position}
        accept={accept}
        name="file"
        loading={false}
        withOnlyBrowseButton={withOnlyBrowseButton}
        deleteCallback={deleteCallback}
      />
    ),
    [accept, disabled, position, title, withOnlyBrowseButton, deleteCallback]
  );

  return (
    <Row align={align} justify={justify} classes={{root: classes.content}}>
      {disableDragAndDrop && singleFileUpload}
      {!disableDragAndDrop && (
        <DraggableArea onDrop={handleFileDrop} onDragOver={handleDragOver} disabled={disabled}>
          {singleFileUpload}
        </DraggableArea>
      )}
    </Row>
  );
};

const useStyles = makeStyles(() => ({
  content: ({fullHeight, fullWidth}: Pick<ISingleFileUploadProps, 'fullHeight' | 'fullWidth'>) => {
    return {
      width: fullWidth ? '100%' : '',
      height: fullHeight ? heightUploadResources : '',
    };
  },
}));

import {combineReducers} from '@reduxjs/toolkit';

import {programsAllSlice} from '../all/store';
import {programDetailsSlice} from '../details/store';
import {programClassesSlice} from '../details/classes/all/store';
import {programMasterInstrumentsSlice} from '../details/masterInstruments/store';
import {programClassDetailsSlice} from '../details/classes/details/store';
import {programClassInstrumentsSlice} from '../details/classes/details/instruments/store';
import {programClassesPaperAssessmentsSlice} from '../details/classes/details/paper-assessments/store';

export const programsReducer = combineReducers({
  all: programsAllSlice.reducer,
  details: programDetailsSlice.reducer,
  classes: programClassesSlice.reducer,
  masterInstruments: programMasterInstrumentsSlice.reducer,
  classDetails: programClassDetailsSlice.reducer,
  classInstruments: programClassInstrumentsSlice.reducer,
  paperAssessments: programClassesPaperAssessmentsSlice.reducer,
});

import {useParams} from 'react-router-dom';

interface IGoogleSiteIdParam {
  id: string;
}

export const useGoogleSiteIdParam = () => {
  const urlParams = useParams<IGoogleSiteIdParam>();
  const googleSiteId = urlParams?.id;
  return {googleSiteId};
};

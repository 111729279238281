export enum OrderingNamesEnum {
  Name = 'Instrument Name',
  Created = 'Created On',
  Type = 'Type',
  Status = 'Status',
  Audience = 'Audience',
}

export enum OrderingBackendEnum {
  Name = 't.Name',
  Created = 't.DateCreated',
  Type = 't.SurveyType',
  Status = 't.TemplateStatus',
  Audience = 't.SurveyAudience',
}

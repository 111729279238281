import React from 'react';
import {Icon, Navigation, NavigationLink} from 'components-lib';
import {paths} from 'paths';
import {AdminRoles, USAAndSuperAdminRoles, usePermissions} from 'permissions';

export function ResourcesNavBar() {
  const {hasPermission} = usePermissions();
  const shouldRenderLearningPaths = hasPermission(AdminRoles);
  const shouldRenderGoogleSites = hasPermission(USAAndSuperAdminRoles);

  return (
    <Navigation type="secondary">
      <NavigationLink
        to={`${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.files}`}
        type="secondary"
      >
        <>
          <Icon.AllInboxIcon />
          Files
        </>
      </NavigationLink>
      {shouldRenderLearningPaths && (
        <NavigationLink
          to={`${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.learningPaths.root}`}
          type="secondary"
        >
          <>
            <Icon.PermMediaIcon />
            Learning Paths
          </>
        </NavigationLink>
      )}
      {shouldRenderGoogleSites && (
        <NavigationLink
          to={`${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.googleSites.root}${paths.adminPortal.resources.googleSites.all}`}
          type="secondary"
        >
          <>
            <Icon.WebIcon />
            Google Sites
          </>
        </NavigationLink>
      )}
    </Navigation>
  );
}

import React, {useState, FC, useCallback} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {Tooltip} from 'components-lib';
import {IWithChildren, IWithDisabled} from 'models';
import {useToastNotifications} from 'toast-notifications';

interface ICopyToClipboardProps extends IWithChildren, IWithDisabled {
  title?: string;
  text: string;
  successNotificationText?: string;
}

export const CopyToClipboardToolTip: FC<ICopyToClipboardProps> = ({
  children,
  disabled,
  title = 'Click here to copy.',
  successNotificationText = 'The text has been copied to your clipboard.',
  text,
}) => {
  const [copyText] = useState(text ? text : null);
  const {addToastNotification} = useToastNotifications();

  const copyClickHandler = useCallback(() => addToastNotification(successNotificationText), [
    addToastNotification,
    successNotificationText,
  ]);

  return (
    <Tooltip title={title} disableTouchListener={disabled}>
      <CopyToClipboard text={copyText} onCopy={copyClickHandler}>
        {children as React.ReactElement<any>}
      </CopyToClipboard>
    </Tooltip>
  );
};

import {useCallback} from 'react';
import {useAppDispatch} from 'hooks';
import {learningPathsAllSitesActions as actions} from '../store';
import {ILearningPathsAllFilter} from 'models/resources';

export const useLearningPathsAllStoreActions = () => {
  const dispatch = useAppDispatch();

  const clearLearningPaths: () => void = useCallback(() => dispatch(actions.clear()), [dispatch]);

  const setFilterLearningPathsAll: (filter: ILearningPathsAllFilter) => void = useCallback(
    (filter: ILearningPathsAllFilter) => dispatch(actions.setFilterLearningPathsAll(filter)),
    [dispatch]
  );

  const resetFilterLearningPathsAll: () => void = useCallback(() => dispatch(actions.resetFilterLearningPathsAll()), [
    dispatch,
  ]);

  const setCurrentPage: (newPage: number) => void = useCallback(
    (newPage: number) => dispatch(actions.setCurrentPageLearningPaths(newPage)),
    [dispatch]
  );

  const setPerPage: (perPage: number) => void = useCallback(
    (perPage: number) => dispatch(actions.setPerPageLearningPaths(perPage)),
    [dispatch]
  );

  return {clearLearningPaths, setFilterLearningPathsAll, resetFilterLearningPathsAll, setCurrentPage, setPerPage};
};

import {Link} from '@material-ui/icons';
import React from 'react';

interface ILinkIconProps {
  color?: 'primary' | 'secondary' | 'inherit';
}

export function LinkIcon({color = 'inherit'}: ILinkIconProps) {
  return <Link fontSize="small" color={color} />;
}

import React, {FC, Fragment, useMemo} from 'react';

import {BUTTON_NUM_LIST} from 'utils';
import {RatingToggleGroup} from '../RatingToggleGroup/RatingToggleGroup';
import {FormNameEnum} from 'filters-selections';
import {IAnswer} from 'models';
interface IAnswersRatingProps {
  disabled?: boolean;
  list?: number[];
  answers: IAnswer[];
}

export const AnswersRating: FC<IAnswersRatingProps> = ({list = BUTTON_NUM_LIST, disabled = true, answers}) => {
  const buttonList = useMemo(() => {
    return (
      <RatingToggleGroup
        name={FormNameEnum.positiveScores}
        withLabel={false}
        disabled={disabled}
        list={list}
        answers={answers}
        withPreview
        withForm
      />
    );
  }, [list, disabled, answers]);

  return <Fragment>{buttonList}</Fragment>;
};

import React, {FC} from 'react';
import {IWithDisabled} from 'models';
import {LanguagesSelect} from './LanguagesSelect';
import {FormNameEnum} from 'filters-selections';

type ILanguagesSingleSelectProps = IWithDisabled;

export const LanguagesSingleSelect: FC<ILanguagesSingleSelectProps> = ({disabled}) => {
  return <LanguagesSelect label="Language" selectionType="single" disabled={disabled} name={FormNameEnum.language} />;
};

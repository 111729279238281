import {createAsyncThunk} from '@reduxjs/toolkit';
import {pamApi} from 'api';
import {IGetAllFileTypeOptionsApiResponse} from 'models';
import {prefix} from './config';

export const fetchAllFileTypeOptionsThunk: any = createAsyncThunk<IGetAllFileTypeOptionsApiResponse>(
  `${prefix} fetchAllFileTypeOptions`,
  async (_, {rejectWithValue}) => {
    try {
      const {parsedBody} = await pamApi.getAllFileTypeOptions();
      return parsedBody;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import React, {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Accordion, Column, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {
  fetchContentItemStatusesThunk,
  filtersLearningPathStatusesEntitiesCollectionSelector,
} from 'filters-selections/store';
import {Form} from 'forms';
import {IWithDisabled} from 'models';

type ILearningPathStatusesCheckboxProps = IWithDisabled;

export const LearningPathStatusesCheckbox: FC<ILearningPathStatusesCheckboxProps> = ({disabled}) => {
  const dispatch = useDispatch();
  const learningPathStatuses = useSelector(filtersLearningPathStatusesEntitiesCollectionSelector);

  useEffect(() => {
    if (!learningPathStatuses.length) {
      dispatch(fetchContentItemStatusesThunk());
    }
  }, [dispatch, learningPathStatuses]);

  return (
    <Accordion heading="Statuses">
      <Column>
        {learningPathStatuses.length ? (
          learningPathStatuses.map((learningPathStatus) => (
            <Form.Checkbox
              key={learningPathStatus.id}
              name={FormNameEnum.learningPathStatuses}
              value={learningPathStatus.id}
              label={learningPathStatus.displayText}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>No Data</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
};

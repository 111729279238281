import React, {ReactNode, useMemo} from 'react';
import {PageViewHeader, Tag} from 'components-lib';
import {ButtonWithAdminPermissions} from 'admin/components';
import {LearningPathsHeaderSortBy} from '../LearningPathsHeaderSortBy/LearningPathsHeaderSortBy';
import {useLearningPaths, useLearningPathsAllStoreActions} from 'admin/resources/learning-paths/views/all/hooks';
import {LearningPathsAllFilterNamesEnum} from 'admin/resources/learning-paths/views/all/enums';
import {ILearningPathsAllFilter} from 'models/resources';
import {cloneDeep} from 'utils/functions';
import {filesAllLearningPathsFilterList, learningPathsAllFilterToFilterNameMap} from 'admin/resources/learning-paths';

export const LearningPathContentHeader = () => {
  const {filter, redirectToCreateLearningPath, filterEntities} = useLearningPaths();
  const {setFilterLearningPathsAll} = useLearningPathsAllStoreActions();

  const renderSortOrder = useMemo(() => <LearningPathsHeaderSortBy />, []);

  const appliedFilters = useMemo(() => {
    const chips: ReactNode[] = [];

    const deleteClickHandler = (name: LearningPathsAllFilterNamesEnum) => {
      let value = null as null | undefined | '';

      if (name === LearningPathsAllFilterNamesEnum.SEARCH_BY_QUERY) {
        value = '';
      }

      const updated: ILearningPathsAllFilter = {
        ...filter,
        [name]: value,
      };
      setFilterLearningPathsAll(updated);
    };

    const resetDropdownFilter = (propName: string, id: string | number) => {
      const updated: ILearningPathsAllFilter = cloneDeep(filter);
      updated[propName] = updated[propName].filter((pId: number) => pId !== id);
      setFilterLearningPathsAll(updated);
    };

    // string[] filters

    filesAllLearningPathsFilterList.forEach((learningPathsAllFilter) => {
      const mappedFilterName = learningPathsAllFilterToFilterNameMap[learningPathsAllFilter];
      const current = filter[mappedFilterName];

      if (current instanceof Array && current.length > 0) {
        current.forEach((id) => {
          const currentFilter = filterEntities[mappedFilterName][id];
          const label = currentFilter?.displayText ? currentFilter?.displayText : currentFilter?.name;

          !!currentFilter &&
            chips.push(
              <Tag
                withFilterChip
                key={`${mappedFilterName}-${id}`}
                label={label}
                deleteHandler={() => resetDropdownFilter(mappedFilterName, currentFilter.id)}
              />
            );
        });
      }
    });

    if (!!filter.query) {
      chips.push(
        <Tag
          withFilterChip
          key={filter.query}
          label={filter.query}
          deleteHandler={() => deleteClickHandler(LearningPathsAllFilterNamesEnum.SEARCH_BY_QUERY)}
        />
      );
    }

    return chips;
  }, [filter, setFilterLearningPathsAll, filterEntities]);

  const buttonWithAdminPermissions = useMemo(
    () => (
      <ButtonWithAdminPermissions
        withPrimary={false}
        withUSAAndSuperAdminRole
        clickHandler={() => redirectToCreateLearningPath()}
      >
        Create New
      </ButtonWithAdminPermissions>
    ),
    [redirectToCreateLearningPath]
  );

  return (
    <PageViewHeader
      heading="Learning Paths"
      sort={renderSortOrder}
      filters={appliedFilters}
      action={buttonWithAdminPermissions}
    />
  );
};

import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {IWithDisabled} from 'models/common';
import {Link} from '../Link/Link';
import {Tooltip} from '../Tooltips';
import {Heading} from '../text';

interface ILinkWithTooltipProps extends IWithDisabled {
  tooltipTitle?: string;
  children: string;
  withNewTab?: boolean;
  className?: string;
  charsLimit?: number;
  maxWidth?: number;
  isDeletedOrExpired?: boolean;
  clickHandler: (event?: any) => void;
}

export const LinkWithTooltip: FC<ILinkWithTooltipProps> = ({
  tooltipTitle,
  clickHandler,
  children,
  withNewTab = false,
  className,
  disabled,
  isDeletedOrExpired = false,
  maxWidth = 320,
}) => {
  const classes = useStyles({maxWidth, isDeletedOrExpired});

  return (
    <Tooltip title={tooltipTitle}>
      <Link withNewTab={withNewTab} className={className} clickHandler={clickHandler} disabled={disabled}>
        <Heading classes={{root: classes.link}} as="h4" color="inherit">
          {children}
        </Heading>
      </Link>
    </Tooltip>
  );
};

const useStyles = makeStyles(() => ({
  link: ({maxWidth, isDeletedOrExpired}: Pick<ILinkWithTooltipProps, 'maxWidth' | 'isDeletedOrExpired'>) => ({
    maxWidth,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textDecoration: isDeletedOrExpired && 'line-through',
  }),
  '@media screen and (max-width: 1920px)': {
    link: ({maxWidth}: Pick<ILinkWithTooltipProps, 'maxWidth'>) => ({
      maxWidth: maxWidth / 1.1,
    }),
  },
  '@media screen and (max-width: 1600px)': {
    link: ({maxWidth}: Pick<ILinkWithTooltipProps, 'maxWidth'>) => ({
      maxWidth: maxWidth / 1.5,
    }),
  },
  '@media screen and (max-width: 960px)': {
    link: ({maxWidth}: Pick<ILinkWithTooltipProps, 'maxWidth'>) => ({
      maxWidth: maxWidth / 2,
    }),
  },
}));

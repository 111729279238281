import React, {FC, ReactNode, useCallback} from 'react';
import {Button, Modal, Text} from 'components-lib';
import {useFormikContext} from 'formik';
import {useDebounce} from 'hooks';
interface IModalCreateNewInstrumentProps {
  isOpen: boolean;
  error: string;
  activeStep: ReactNode;
  nextButton: ReactNode;
  closeHandler: () => void;
}

export const ModalCreateNewInstrument: FC<IModalCreateNewInstrumentProps> = ({
  isOpen,
  error,
  activeStep,
  nextButton,
  closeHandler,
}) => {
  const {resetForm} = useFormikContext();
  const debouncedReset = useDebounce(() => resetForm());

  const handleClose = useCallback(() => {
    closeHandler();
    debouncedReset();
  }, [debouncedReset, closeHandler]);

  return (
    <Modal disableBackdropClick isOpen={isOpen} handleClose={handleClose} title="Create New Instrument" size="medium">
      <>
        {error && <Text.Error>{error}</Text.Error>}
        {activeStep}
      </>
      <>
        <Button.Secondary clickHandler={handleClose} size="small">
          Cancel
        </Button.Secondary>
        {nextButton}
      </>
    </Modal>
  );
};

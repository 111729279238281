import {BUTTON_NUM_LIST} from 'utils/constants';
import {FormNameEnum} from 'filters-selections';
import {useFormikContext} from 'formik';

export const useRangeAnswerStack = () => {
  const {values} = useFormikContext();
  const list = BUTTON_NUM_LIST;

  const rangeFrom = values[FormNameEnum.rangeFrom];
  const rangeTo = values[FormNameEnum.rangeTo];

  const rangeFromIdx = list.indexOf(Number(rangeFrom));
  const rangeToIdx = list.indexOf(Number(rangeTo));
  const rangeList = list.slice(rangeFromIdx, rangeToIdx + 1);

  return {
    list: rangeList,
  };
};

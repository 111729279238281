import React from 'react';
import {SubtitlePageViewHeader} from 'admin/components';
import {Wrapper} from 'components-lib';

export const Subtitle = ({withColor, as, color, title}) => {
  return (
    <SubtitlePageViewHeader withColor={withColor} as={as} color={color}>
      <Wrapper>{title}</Wrapper>
    </SubtitlePageViewHeader>
  );
};

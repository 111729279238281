import React, {FC, useCallback, useContext, useMemo} from 'react';
import {batch} from 'react-redux';
import {IWithChildren} from 'models/common';
import {Form} from 'forms';
import {
  cloneLearningPathFormBaseValidationSchema,
  ICloneLearningPathFormBaseValues,
} from './cloneLearningPathBaseConfig';
import {FormContext} from 'forms/formik/FormContextProvider';
import {FormNameEnum} from 'enums/form';
import {useToastNotifications} from 'toast-notifications';
import {learningPathsMessages, useLearningPaths} from 'admin/resources/learning-paths/views/all';
import {IDuplicateLearningPathThunkPayload} from 'models/resources/learning-paths';
import {unwrapResult} from '@reduxjs/toolkit';

interface IModalCloneFormProps extends IWithChildren {
  learningPathId: string;
}

const mapCloneLearningPath = (
  learningPathId: string,
  values: ICloneLearningPathFormBaseValues
): IDuplicateLearningPathThunkPayload => ({
  learningPathId,
  program: values.program,
  expirationDate: values.expirationDate,
});

export const ModalCloneForm: FC<IModalCloneFormProps> = ({learningPathId, children}) => {
  const {formikFormRef} = useContext(FormContext);
  const {addToastNotification} = useToastNotifications();
  const {cloneLearningPath} = useLearningPaths();

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.program]: null,
      [FormNameEnum.expirationDate]: null,
    }),
    []
  );

  const submitHandler = useCallback(
    (values: ICloneLearningPathFormBaseValues) => {
      const payload = mapCloneLearningPath(learningPathId, values);
      return batch(() => {
        cloneLearningPath(payload)
          .then(unwrapResult)
          .then(() => addToastNotification(learningPathsMessages.cloning));
      });
    },
    [addToastNotification, cloneLearningPath, learningPathId]
  );

  return (
    <Form.Form<ICloneLearningPathFormBaseValues>
      initialValues={initialValues}
      validationSchema={cloneLearningPathFormBaseValidationSchema}
      submitHandler={submitHandler}
      formikRef={formikFormRef}
    >
      {children}
    </Form.Form>
  );
};

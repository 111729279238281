import {InputTypeEnum} from 'enums/form';
import {useCallback, useState} from 'react';

export const usePasswordInput = () => {
  const [type, setType] = useState(InputTypeEnum.Password);

  const toggleIconClickHandler = useCallback(() => {
    if (type === InputTypeEnum.Password) {
      setType(InputTypeEnum.Text);
    } else {
      setType(InputTypeEnum.Password);
    }
  }, [type, setType]);

  return {
    type,
    toggleIconClickHandler,
  };
};

export enum ResourceTypeNumEnum {
  Video = 1,
  Audio = 2,
  Image = 3,
  Document = 4,
  PDF = 5,
  FillablePDF = 6,
  Other = 7,
  ScormPackage = 8,
  WebLink = 9,
  K12Resource = 10,
  Excel = 11,
  Archive = 12,
}

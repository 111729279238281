import React, {FC} from 'react';
import {Button} from 'components-lib';
import {withRole, AllRolesWithoutStudent, SuperAdminRole, USAAndSuperAdminRoles} from 'permissions';
import {IComponentWithChildren} from 'models';
import {IButtonBaseProps} from 'components-lib/buttons_/IButtonBaseProps';

interface IButtonWithAdminPermissionsProps extends IComponentWithChildren, IButtonBaseProps {
  withPrimary: boolean;
  withSuperAdminRole?: boolean;
  withUSAAndSuperAdminRole?: boolean;
}

export const ButtonWithAdminPermissions: FC<IButtonWithAdminPermissionsProps> = ({
  withPrimary = true,
  withSuperAdminRole = false,
  withUSAAndSuperAdminRole = false,
  clickHandler,
  children,
  className,
  size = 'large',
}) => {
  if (withPrimary) {
    if (withSuperAdminRole) {
      return (
        <ButtonPrimaryWithSuperAdminPermissions className={className} size={size} clickHandler={clickHandler}>
          {children}
        </ButtonPrimaryWithSuperAdminPermissions>
      );
    }

    if (withUSAAndSuperAdminRole) {
      return (
        <ButtonPrimaryWithUSAAndSuperAdminPermissions className={className} size={size} clickHandler={clickHandler}>
          {children}
        </ButtonPrimaryWithUSAAndSuperAdminPermissions>
      );
    }

    return (
      <ButtonPrimaryWithAdminPermissions className={className} size={size} clickHandler={clickHandler}>
        {children}
      </ButtonPrimaryWithAdminPermissions>
    );
  }

  if (!withPrimary && withSuperAdminRole) {
    return (
      <ButtonSecondaryWithSuperAdminPermissions className={className} size={size} clickHandler={clickHandler}>
        {children}
      </ButtonSecondaryWithSuperAdminPermissions>
    );
  }

  if (!withPrimary && withUSAAndSuperAdminRole) {
    return (
      <ButtonSecondaryWithUSAAndSuperAdminPermissions className={className} size={size} clickHandler={clickHandler}>
        {children}
      </ButtonSecondaryWithUSAAndSuperAdminPermissions>
    );
  }

  return (
    <ButtonSecondaryWithAdminPermissions className={className} size={size} clickHandler={clickHandler}>
      {children}
    </ButtonSecondaryWithAdminPermissions>
  );
};

export const ButtonPrimaryWithAdminPermissions = withRole(AllRolesWithoutStudent, '')(Button.Primary);
export const ButtonSecondaryWithAdminPermissions = withRole(AllRolesWithoutStudent, '')(Button.Secondary);
export const ButtonDropdownWithAdminPermissions = withRole(AllRolesWithoutStudent, '')(Button.Dropdown);
export const ButtonPrimaryWithSuperAdminPermissions = withRole(SuperAdminRole, '')(Button.Primary);
export const ButtonSecondaryWithSuperAdminPermissions = withRole(SuperAdminRole, '')(Button.Secondary);
export const ButtonDropdownWithSuperAdminPermissions = withRole(SuperAdminRole, '')(Button.Dropdown);

export const ButtonPrimaryWithUSAAndSuperAdminPermissions = withRole(USAAndSuperAdminRoles, '')(Button.Primary);
export const ButtonSecondaryWithUSAAndSuperAdminPermissions = withRole(USAAndSuperAdminRoles, '')(Button.Secondary);
export const ButtonDropdownWithUSAAndSuperAdminPermissions = withRole(USAAndSuperAdminRoles, '')(Button.Dropdown);

import React, {useCallback, useMemo} from 'react';
import {FormikHelpers} from 'formik';
import {batch} from 'react-redux';
import {editTagsFormValidationSchema} from './builderInstructionalTextFormBaseConfig';
import {useBuilder, useBuilderItemsEntities} from '../../hooks';
import {FormNameEnum} from 'filters-selections';
import {IInstrumentItem} from 'models';
import {Form} from 'forms';
import {BuilderInstructionalText} from './BuilderInstructionalText';

export const BuilderInstructionalTextForm = ({id, position, applyClickHandler}) => {
  const {builderItemsEntities} = useBuilderItemsEntities();
  const {updateInstructionalText} = useBuilder();
  const item: IInstrumentItem = builderItemsEntities[id];

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.builderInstructionalText]: '',
      [FormNameEnum.builderInstructionalTextHTML]: item?.html ?? '',
    }),
    [item.html]
  );

  const submitHandler = useCallback(
    ({builderInstructionalTextHTML}, {resetForm}: FormikHelpers<any>) =>
      batch(() => {
        updateInstructionalText(item, builderInstructionalTextHTML);
        applyClickHandler();
        resetForm();
      }),
    [updateInstructionalText, applyClickHandler, item]
  );

  return (
    <Form.Form
      initialValues={initialValues}
      validationSchema={editTagsFormValidationSchema}
      submitHandler={submitHandler}
    >
      <BuilderInstructionalText item={item} position={position} />
    </Form.Form>
  );
};

import {GoogleSiteDetailsNameEnum, GoogleSitesDetailOrderingEnum} from 'admin/resources/google-sites';
import {SortingOrderEnum} from 'enums';
import {IGoogleSiteDetailsSortOrder} from 'models';

export const getSortGoogleSiteDetailsMenuItemsList = (
  itemClickHandler: () => void,
  handleItemClick: (handleItemSortObj: IGoogleSiteDetailsSortOrder) => void
) => {
  const onClick = (
    itemClickHandler: () => void,
    handleItemClick: (handleItemSortObj: IGoogleSiteDetailsSortOrder) => void,
    handleItemSortObj: IGoogleSiteDetailsSortOrder
  ) => {
    return () => {
      itemClickHandler();
      handleItemClick(handleItemSortObj);
    };
  };

  return [
    {
      name: GoogleSiteDetailsNameEnum.AREA_NAME_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: GoogleSitesDetailOrderingEnum.AreaName,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: GoogleSiteDetailsNameEnum.AREA_NAME_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: GoogleSitesDetailOrderingEnum.AreaName,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: GoogleSiteDetailsNameEnum.LAST_COPIED_DATE_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: GoogleSitesDetailOrderingEnum.LastCopiedDate,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: GoogleSiteDetailsNameEnum.LAST_COPIED_DATE_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: GoogleSitesDetailOrderingEnum.LastCopiedDate,
        sort: SortingOrderEnum.DESC,
      }),
    },
  ];
};

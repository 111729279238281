import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {IFetchMergedExportQueryParams} from '../../models';

export const fetchMergedExport: any = createAsyncThunk(
  `${prefix} fetchMergedExport`,
  async (params: IFetchMergedExportQueryParams, {rejectWithValue, dispatch}) => {
    try {
      if (params.areaId == -1) {
        params.areaId = null;
      }
      const {parsedBody} = await instrumentsApi.getMergedProgramExport(
        params.pasProgramId,
        params.d2lProgramId,
        params.areaId,
        params.sessionId
      );
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {makeStyles, Menu} from '@material-ui/core';
import {Button, Icon, Row, Text} from 'components-lib';
import {IComponentWithChildren, IWithDisabled} from 'models';

interface IDropdownProps extends IComponentWithChildren, IWithDisabled {
  label?: string;
  text: string;
  disabled?: boolean;
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  withTextButton?: boolean;
  withSecondaryButton?: boolean;
  withRow?: boolean;
}

export function Dropdown({
  children,
  text,
  label,
  disabled = false,
  justify = 'flex-start',
  withTextButton = true,
  withSecondaryButton = false,
  withRow = true,
}: IDropdownProps) {
  const classes = useStyles();
  const [menuPosition, setMenuPosition] = useState<any>(null);

  const buttonClickHandler = useCallback(
    (event: React.MouseEvent) => {
      if (menuPosition) {
        return;
      }
      event.preventDefault();
      setMenuPosition({
        top: event.pageY,
        left: event.pageX,
      });
    },
    [menuPosition]
  );

  const itemClickHandler = useCallback(() => {
    setMenuPosition(null);
  }, []);

  const dropdownButton = useMemo(
    () => (
      <Fragment>
        {withTextButton && (
          <Button.Text
            disabled={disabled}
            endIcon={<Icon.ExpandIcon color="primary" />}
            clickHandler={buttonClickHandler}
            className={classes.button}
          >
            {text}
          </Button.Text>
        )}
        {withSecondaryButton && (
          <Button.Secondary
            disabled={disabled}
            clickHandler={buttonClickHandler}
            endIcon={<Icon.ExpandIcon color="primary" />}
          >
            {text}
          </Button.Secondary>
        )}
      </Fragment>
    ),
    [classes.button, text, withSecondaryButton, withTextButton, buttonClickHandler, disabled]
  );

  const dropdownContent = useMemo(
    () => (
      <Fragment>
        {label && <Text.Caption>{label}</Text.Caption>}
        {dropdownButton}
      </Fragment>
    ),
    [label, dropdownButton]
  );

  return (
    <>
      {withRow ? <Row justify={justify}>{dropdownContent}</Row> : dropdownContent}
      <Menu
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
      >
        {children(itemClickHandler, menuPosition)}
      </Menu>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.common.black,
    padding: 0,
  },
}));

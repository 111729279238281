import {TrendingFlat} from '@material-ui/icons';
import React from 'react';

interface ITrendingLeftProps {
  color?: 'primary' | 'disabled' | 'action';
}

export function TrendingRight({color = 'primary'}: ITrendingLeftProps) {
  return <TrendingFlat fontSize="small" color={color} />;
}

import {ILearningPathsAllStoreState} from './ILearningPathsAllStoreState';
import {ILearningPathPage, ILearningPathsAllFilter, ILearningPathsPagination} from 'models/resources/learning-paths';
import {EntitiesPerPageEnum, SortingOrderEnum} from 'enums';
import {LearningPathsAllFilterNamesEnum, LearningPathsOrderingAllEnum} from '../enums';

const LearningPathsPageDefault = {
  loading: false,
} as ILearningPathPage;

const learningPathsAllpaginationDefault = {
  currentPage: 1,
  totalCount: 0,
  perPage: EntitiesPerPageEnum.FIFTY,
} as ILearningPathsPagination;

const learningPathsAllListItemsDefault = {};

const learningPathsListDefault = {
  result: [],
  entities: {
    items: learningPathsAllListItemsDefault,
  },
};

const filterDefault: ILearningPathsAllFilter = {
  [LearningPathsAllFilterNamesEnum.SEARCH_BY_QUERY]: '',
  [LearningPathsAllFilterNamesEnum.PROGRAMS]: [],
  [LearningPathsAllFilterNamesEnum.PROGRAM_YEARS]: [],
  [LearningPathsAllFilterNamesEnum.GRADE_LEVELS]: [],
  [LearningPathsAllFilterNamesEnum.PILLARS]: [],
  [LearningPathsAllFilterNamesEnum.LANGUAGES]: [],
  [LearningPathsAllFilterNamesEnum.LEARNING_PATH_STATUSES]: [],
};

const learningPathDefault = {
  id: null,
  exporting: false,
  cloning: false,
};

export const learningPathsAllInitialState: ILearningPathsAllStoreState = {
  pagination: learningPathsAllpaginationDefault,
  page: LearningPathsPageDefault,
  list: learningPathsListDefault,
  learningPath: learningPathDefault,
  filter: filterDefault,
  sortOrder: {
    order: LearningPathsOrderingAllEnum.Created,
    sort: SortingOrderEnum.DESC,
  },
};

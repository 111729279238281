import {IAddWebLinkFormBaseValues, IUploadFileFormBaseValues, IUploadScormFormBaseValues} from 'admin/resources';

const mapBaseResourceMetadata = (
  values: IUploadFileFormBaseValues | IUploadScormFormBaseValues | IAddWebLinkFormBaseValues
) => ({
  isAccessibleToStudents: values.isAccessibleToStudents,
  keyTerms: values.keyTerms,
  learningObjectives: values.learningObjectives,
  languages: values.languages,
  accessExpirationDate: values.expirationDate,
  competencies: values.competencies,
  pillars: values.pillars,
  programs: values.programs,
  gradeLevels: values.gradeLevels,
  ksAs: values.knowledgeSkillsAndAbilities,
});

export const mapUploadResourceMetadata = (
  values: IUploadFileFormBaseValues | IUploadScormFormBaseValues | IAddWebLinkFormBaseValues
) => {
  const baseMetadata = mapBaseResourceMetadata(values);

  return baseMetadata;
};

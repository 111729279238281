import {
  fetchGoogleSiteListThunk,
  fetchGoogleSitesCopySiteThunk,
  fetchGoogleSitesSourceDetailsThunk,
  fetchGoogleSitesSourceHistoryThunk,
} from './thunks';
import {IGoogleSitesStoreState} from './IGoogleSitesStoreState';
import {IActionFulfilled} from 'models/common';
import {
  IGoogleSiteCopyApiResponse,
  IGoogleSiteCopyPayload,
  IGoogleSitesListApiResponse,
  IGoogleSitesSourceDetailsResponse,
  IGoogleSitesSourceHistoryApiResponse,
} from 'models';
import {isObject} from 'utils';
import {normalize} from 'normalizr';
import {googleSitesListSchema, historyListSchema} from './normalizationSchemas';
// import {HistoryTableStatusEnum} from '../details/enums';

export const googleSitesExtraReducer = {
  // fetchGoogleSitesSourceDetailsThunk
  [fetchGoogleSitesSourceDetailsThunk.fulfilled]: (
    state: IGoogleSitesStoreState,
    action: IActionFulfilled<null, IGoogleSitesSourceDetailsResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.googleSite = {...data};
    }
    state.page.loading = false;
  },
  [fetchGoogleSitesSourceDetailsThunk.pending]: (state: IGoogleSitesStoreState) => {
    state.page.loading = true;
  },
  [fetchGoogleSitesSourceDetailsThunk.rejected]: (state: IGoogleSitesStoreState) => {
    state.page.loading = false;
  },

  // fetchGoogleSitesSourceHistoryThunk
  [fetchGoogleSitesSourceHistoryThunk.fulfilled]: (
    state: IGoogleSitesStoreState,
    action: IActionFulfilled<null, IGoogleSitesSourceHistoryApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;

      state.details.history.historyList = normalize(data, historyListSchema);
    }
    state.details.history.loading = false;
  },
  [fetchGoogleSitesSourceHistoryThunk.pending]: (state: IGoogleSitesStoreState) => {
    state.details.history.loading = true;
  },
  [fetchGoogleSitesSourceHistoryThunk.rejected]: (state: IGoogleSitesStoreState) => {
    state.details.history.loading = false;
  },

  // fetchGoogleSitesCopySiteThunk;
  [fetchGoogleSitesCopySiteThunk.fulfilled]: (
    state: IGoogleSitesStoreState,
    action: IActionFulfilled<IGoogleSiteCopyPayload, IGoogleSiteCopyApiResponse>
  ) => {
    if (isObject(action.payload)) {
      state.details.history.copyData.copying = false;
      state.details.history.copyData.integrationId = null;
    }
  },
  [fetchGoogleSitesCopySiteThunk.pending]: (state: IGoogleSitesStoreState) => {
    state.details.history.copyData.copying = true;
  },
  [fetchGoogleSitesCopySiteThunk.rejected]: (state: IGoogleSitesStoreState) => {
    state.details.history.copyData.copying = false;
    state.details.history.copyData.integrationId = null;
  },

  // fetchGoogleSiteListThunk
  [fetchGoogleSiteListThunk.fulfilled]: (
    state: IGoogleSitesStoreState,
    action: IActionFulfilled<null, IGoogleSitesListApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {
        data: {list},
      } = action.payload;

      state.googleSites.list = normalize(list, googleSitesListSchema);
      state.googleSites.pagination.totalCount = list.length;
    }
    state.page.loading = false;
  },
  [fetchGoogleSiteListThunk.pending]: (state: IGoogleSitesStoreState) => {
    state.page.loading = true;
  },
  [fetchGoogleSiteListThunk.rejected]: (state: IGoogleSitesStoreState) => {
    state.page.loading = false;
  },
};

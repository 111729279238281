import React from 'react';
import {makeStyles} from '@material-ui/core';
import {RemoveRedEye} from '@material-ui/icons';
import {Wrapper} from 'components-lib';
import {IntegrationInput} from 'admin/integrations/components';
import {heightThreeStepsActivity} from 'utils/layout';
import {fixedInputHeight} from 'admin/integrations/add-integration/utils';

export const AddIntegrationInputList = ({list, withHeightThreeStepsActivity = true}) => {
  const classes = useStyles({withHeightThreeStepsActivity});

  return (
    <Wrapper className={classes.content}>
      {list.map(({label, name, type, options: {withMargin, withIcon}}, idx: number) => (
        <IntegrationInputWrapper key={idx}>
          <IntegrationInput
            label={label}
            name={name}
            type={type}
            withMargin={withMargin}
            withIcon={withIcon}
            icon={withIcon && <RemoveRedEye />}
          />
        </IntegrationInputWrapper>
      ))}
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  content: ({withHeightThreeStepsActivity}: {withHeightThreeStepsActivity: boolean}) => {
    return {
      height: withHeightThreeStepsActivity ? heightThreeStepsActivity : '',
      paddingLeft: theme.spacing(2),
    };
  },
  inputContainer: {
    height: fixedInputHeight,
    marginBottom: theme.spacing(3),
  },
}));

const IntegrationInputWrapper = ({children}) => {
  const {inputContainer} = useStyles();

  return <Wrapper className={inputContainer}>{children}</Wrapper>;
};

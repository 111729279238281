const maxChar = 45;

export function updateSelectLabel(label: string) {
  const slicedLabel = label.slice(0, maxChar);
  const shouldRenderDots = label.length > maxChar;
  const updatedLabel = `${slicedLabel} ${shouldRenderDots ? '...' : ''}`;

  return {
    shouldRenderDots,
    updatedLabel,
  };
}

import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {SortByDropdown} from 'admin/components';
import {useAppDispatch} from 'hooks';
import {getMenuItemsList, integrationsOrderingMap} from '../../utils';
import {IIntegrationsAllSortOrder} from 'models/integrations';
import {integrationsAllSortOrderSelector, integrationsActions as actions} from 'admin/integrations';

export const IntegrationsAllSortBy = () => {
  const dispatch = useAppDispatch();
  const sortOrder = useSelector(integrationsAllSortOrderSelector);

  const handleItemClick = useCallback(
    (sortOrder: IIntegrationsAllSortOrder) => {
      dispatch(actions.setSortOrderIntegrationsAll(sortOrder));
    },
    [dispatch]
  );

  return (
    <SortByDropdown
      label="Sort By: "
      handleItemClick={handleItemClick}
      getMenuItemsList={getMenuItemsList}
      sortOrder={sortOrder}
      map={integrationsOrderingMap}
    />
  );
};

import {FiltersEnum} from 'filters-selections';
import {InstrumentsFilterNamesEnum} from '../../enums';

export const instrumentAllFilterToFilterNameMap = {
  [FiltersEnum.ProgramMulti]: InstrumentsFilterNamesEnum.PROGRAMS,
  [FiltersEnum.InstrumentStatus]: InstrumentsFilterNamesEnum.STATUSES,
  [FiltersEnum.Audience]: InstrumentsFilterNamesEnum.AUDIENCES,
  [FiltersEnum.InstrumentType]: InstrumentsFilterNamesEnum.TYPES,
  [FiltersEnum.Phase]: InstrumentsFilterNamesEnum.PHASES,
  [FiltersEnum.Pillar]: InstrumentsFilterNamesEnum.PILLARS,
  [FiltersEnum.InstrumentViewType]: InstrumentsFilterNamesEnum.INSTRUMENT_VIEW_TYPE,
};

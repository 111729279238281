import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Button, Wrapper} from 'components-lib';
import {useMainNavigation} from 'hooks';

interface IAdminNavDropdowns {
  text: string;
  clickHandler: () => void;
}

export const AdminNavMoreActionsButton = () => {
  const classes = useStyles();
  const {redirectToResources, redirectToPrograms, redirectToLibrary} = useMainNavigation();

  const dropdownItems: IAdminNavDropdowns[] = [
    {
      text: 'Resources',
      clickHandler: () => redirectToResources(),
    },
    {
      text: 'Programs',
      clickHandler: () => redirectToPrograms(),
    },
    {
      text: 'Library',
      clickHandler: () => redirectToLibrary(),
    },
  ];

  return (
    <Wrapper className={classes.menuIconContainer}>
      <Button.Dropdown items={dropdownItems} hideOnResize={true} />
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  menuIconContainer: {
    paddingRight: theme.spacing(1) + theme.spacing(4),

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
}));

import {createAsyncThunk} from '@reduxjs/toolkit';
import {integrationsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {IDeleteIntegrationPayload, IIntegrationDetails} from 'models';

export const deleteIntegrationThunk: any = createAsyncThunk<IIntegrationDetails, IDeleteIntegrationPayload>(
  `${prefix} deleteIntegration`,
  async ({integrationId}: IDeleteIntegrationPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await integrationsApi.deleteIntegration(integrationId);
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

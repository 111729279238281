import {createSlice} from '@reduxjs/toolkit';
import {sessionBuilderInitialState} from './sessionBuilder.initialState';
import {sessionBuilderReducer} from './sessionBuilder.reducer';
import {sessionBuilderExtraReducer} from './sessionBuilderExtra.reducer';

export const sessionBuilderSlice = createSlice({
  name: 'sessionBuilder',
  initialState: sessionBuilderInitialState,
  reducers: sessionBuilderReducer,
  extraReducers: sessionBuilderExtraReducer,
});

export const {actions: sessionBuilderSliceSitesActions} = sessionBuilderSlice;

import React, {RefObject} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {IComponentWithChildren} from 'models';

const threshold = 120;

interface IListInfiniteProps extends IComponentWithChildren {
  hasMorePages: boolean;
  loading: boolean;
  loadMoreHandler: () => void;
}

export function ListInfinite({children, loading, hasMorePages, loadMoreHandler}: IListInfiniteProps) {
  const infiniteRef: RefObject<HTMLDivElement> = useInfiniteScroll({
    loading,
    hasNextPage: hasMorePages,
    onLoadMore: loadMoreHandler,
    scrollContainer: 'parent',
    threshold,
  });

  return <div ref={infiniteRef}>{children}</div>;
}

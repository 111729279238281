import {QuestionAnswersTypeEnum} from 'enums';
import {IAnswerStackCustom} from 'models';
import {
  INITIAL_QUESTION_ID,
  INITIAL_QUESTION_ANSWERSTACK_ID,
  INITIAL_ORIGINAL_QUESTION_ANSWERSTACK_ID,
  uniqueId,
  INITIAL_ANSWER_POSITION,
  camelToTitle,
  MAX_EMPTY_ANSWERS,
} from 'utils';

export function createEmptyAnswer(isCorrect = false, position = INITIAL_ANSWER_POSITION) {
  return {
    id: uniqueId(`answer`),
    text: '',
    position,
    isCorrect,
  };
}

export const createEmptyFourAnswers = (listLength = MAX_EMPTY_ANSWERS) =>
  Array(listLength).fill(createEmptyAnswer(false));

export function createEmptyCustomAnswerStack(): IAnswerStackCustom {
  return {
    id: uniqueId('customAnswerStack'),
    questionType: QuestionAnswersTypeEnum.checkbox,
    isSelected: true,
    isApproved: false,
    title: '',
    questionId: INITIAL_QUESTION_ID,
    questionAnswerStackId: INITIAL_QUESTION_ANSWERSTACK_ID,
    originalAnswerStackId: INITIAL_ORIGINAL_QUESTION_ANSWERSTACK_ID,
    canBeUnpublished: true,
    canEdit: false,
    canModifyAnswerQuestionAnswerstacks: false,
    metaData: {
      pillars: [],
      evaluationTypes: [],
      domain: {id: null, name: 's'},
      programs: [],
      competencies: [],
      concepts: [],
      administration: {id: null, name: ''},
      schoolLevels: [],
      category: '',
      instruments: [],
    },
    choices: [createEmptyAnswer(true)],
  };
}

export const updateNameConceptsWithKSAs = (key: string) => {
  return key === 'concepts' ? 'KSAs' : camelToTitle(key);
};

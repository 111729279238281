import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {instrumentsMessages} from '../../utils/messages';

export const publishMasterInstrumentThunk: any = createAsyncThunk<undefined, number>(
  `${prefix} publishInstrumentThunk`,
  async (templateId: number, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await instrumentsApi.putPublishMasterTemplate(templateId);
      dispatch(toastNotificationManager.createSuccessToastAction(instrumentsMessages.publishSuccess));
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

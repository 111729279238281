import {createAsyncThunk} from '@reduxjs/toolkit';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {importItemsMessages} from '../../utils/constants/messages';
import {itemImportApi} from 'api';
import {IGetCriticalIssuesApiResponse} from 'models';

export const fetchImportCriticalIssuesThunk: any = createAsyncThunk<IGetCriticalIssuesApiResponse, null>(
  `${prefix} fetchImportCriticalIssues`,
  async (itemImportId: number, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await itemImportApi.getImportCriticalIssues(itemImportId);
      dispatch(toastNotificationManager.createSuccessToastAction(importItemsMessages.downloadIssuesSuccess));
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

import {createAsyncThunk} from '@reduxjs/toolkit';
import {IHttpResponse, learningPathsApi} from 'api';
import {prefix} from './config';
import {toastNotificationManager} from 'toast-notifications';
import {IGetExportLearningPathApiResponse} from 'models';
import {getContentResponseHeaders} from 'admin/resources/files/views/edit/utils';
import {learningPathsMessages} from '../../utils';

export const fetchExportLearningPathThunk: any = createAsyncThunk<IGetExportLearningPathApiResponse, string>(
  `${prefix} fetchExportLearningPath`,
  async (id: string, {dispatch, rejectWithValue}) => {
    dispatch(toastNotificationManager.createSuccessToastAction(learningPathsMessages.exporting));

    try {
      const response = await learningPathsApi.getExportLearningPath(id);
      const {mimeType, fileName} = getContentResponseHeaders(response);

      return {
        blob: response.data,
        fileName,
        mimeType,
      };
    } catch (error) {
      if (error.response) {
        // In case of blob response type, the response is a Promise
        // Please review the documentation: https://developer.mozilla.org/en-US/docs/Web/API/Blob#extracting_data_from_a_blob
        const responsePromise: IHttpResponse<IGetExportLearningPathApiResponse> = await error.response.data.text();
        const errorMessage = responsePromise.messages;

        dispatch(toastNotificationManager.createErrorToastAction(errorMessage));
        return rejectWithValue({error: errorMessage});
      }
    }
  }
);

import React from 'react';
import {IWithDisabled} from 'models';
import {FieldProps} from 'formik';
import {Field} from './Field';
import {useDebounceUserInput} from 'hooks';
import {PasswordInput} from 'components-lib';

interface IFormPasswordInputProps extends IWithDisabled {
  name: string;
  value?: string;
}

export const FormPasswordInput = ({name, disabled, value}: IFormPasswordInputProps) => {
  return <Field name={name} value={value} Component={PasswordInputDecorated} disabled={disabled} />;
};

interface IPasswordInputDecoratedProps extends FieldProps, IFormPasswordInputProps {}

function PasswordInputDecorated({field: {name, onChange}, form: {values}, ...rest}: IPasswordInputDecoratedProps) {
  const initialValue: string = values[name];
  const [value, changeHandler] = useDebounceUserInput(initialValue, onChange);

  const props = {
    ...rest,
    value,
    changeHandler,
  };

  return <PasswordInput {...props} />;
}

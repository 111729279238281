import React, {FC, Fragment, useCallback} from 'react';
import {makeStyles} from '@material-ui/core';

import {Accordion, Button, Modal, Row} from 'components-lib';
import {IWithModal} from 'models';
import {CardQuestionSimple} from '..';
import {AnswersView} from 'admin/library/components/Answers/AnswersView';
import {getLinkedItem} from '../../utils';
import {usePretestItem} from 'admin/library/instruments/hooks';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IModalInfoLinkItemProps extends IWithModal {
  linkedQuestionAnswerStackId: number;
}

export const ModalInfoLinkItem: FC<IModalInfoLinkItemProps> = ({isOpen, setIsOpen, linkedQuestionAnswerStackId}) => {
  const classes = useStyles();
  const closeHandler = useCallback(() => setIsOpen(false), [setIsOpen]);
  const {possiblePretestItems} = usePretestItem();
  const linkedItem = getLinkedItem(possiblePretestItems, linkedQuestionAnswerStackId);

  return (
    <Modal isOpen={isOpen} handleClose={closeHandler} title="Linked Item Info " size="medium">
      {linkedItem ? (
        <Accordion
          initialIsExpanded
          heading={<CardQuestionSimple type={linkedItem.questionType} text={linkedItem.questionText} />}
        >
          <Row align="center">
            <div className={classes.answersContainer}>
              {linkedItem.questionStackChoices.map((stack: any, idx: number) => (
                <AnswersView answers={stack.choices} key={`choice-${idx}-${stack.questionAnswerStackId}`} />
              ))}
            </div>
          </Row>
        </Accordion>
      ) : (
        <Fragment>No linked items available.</Fragment>
      )}
      <div>
        <Button.Secondary clickHandler={closeHandler} size="small">
          Close
        </Button.Secondary>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  answersContainer: {
    width: '100%',
    padding: theme.spacing(3),
    margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(2)}px`,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 4,
  },
}));

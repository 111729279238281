import * as Yup from 'yup';
import {FormNameEnum} from 'enums/form';
import {IBaseResourceItem, ILearningPathInstrumentItem} from 'models/resources';

export interface ILearningPathSessionsFormBaseValues {
  [FormNameEnum.sessionName]: string;
  [FormNameEnum.sessionDescription]: string;
  [FormNameEnum.resources]: IBaseResourceItem[];
  [FormNameEnum.instruments]: ILearningPathInstrumentItem[];
}

export const learningPathSessionsFormBaseValidationSchema = Yup.object().shape({
  [FormNameEnum.sessionName]: Yup.string()
    .nullable()
    .required('You should include a session name to create the session'),
  [FormNameEnum.sessionDescription]: Yup.string(),
  [FormNameEnum.resources]: Yup.array().of(Yup.mixed()),
  [FormNameEnum.instruments]: Yup.array().of(Yup.mixed()),
});

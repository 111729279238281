import {IGoogleSitesStoreState} from './IGoogleSitesStoreState';
import {
  IGoogleSitePage,
  IGoogleSiteDetails,
  IGoogleSiteOwner,
  IGoogleSiteLastModifyingUser,
} from '../../../../models/resources/google-sites';
import {EntitiesPerPageEnum, SortingOrderEnum} from 'enums';
import {GoogleSitesDetailOrderingEnum} from '../details/enums';
import {GoogleSitesOrderingEnum} from '../all/enums';
import {IResourcePagination} from 'models';

const googleSiteDefaultOwner = {
  displayName: null,
  emailAddress: null,
  avatarUrl: null,
} as IGoogleSiteOwner;

const googleSiteDefaultLastModifyingUser = {
  displayName: null,
  emailAddress: null,
  avatarUrl: null,
} as IGoogleSiteLastModifyingUser;

const pageDefault = {
  loading: false,
} as IGoogleSitePage;

const googleSiteDefault = {
  createdTime: null,
  editLink: null,
  id: null,
  lastModifiedTime: null,
  lastModifyingUser: googleSiteDefaultLastModifyingUser,
  mimetype: null,
  siteOwner: googleSiteDefaultOwner,
  name: null,
  version: 0,
} as IGoogleSiteDetails;

const googleSiteDetailsDefault = {
  sortOrder: {
    order: GoogleSitesDetailOrderingEnum.LastCopiedDate,
    sort: SortingOrderEnum.DESC,
  },
  history: {
    historyList: {
      result: [],
      entities: {
        items: {},
      },
    },
    loading: false,
    copyData: {
      integrationId: null,
      copying: false,
    },
  },
};

const paginationDefault: IResourcePagination = {
  currentPage: 1,
  totalCount: 0,
  perPage: EntitiesPerPageEnum.FIFTY,
};

const googleSitesDefault = {
  sortOrder: {
    order: GoogleSitesOrderingEnum.LastModifiedDate,
    sort: SortingOrderEnum.DESC,
  },
  pagination: paginationDefault,
  list: {
    result: [],
    entities: {
      items: {},
    },
  },
};

export const googleSitesInitialState: IGoogleSitesStoreState = {
  googleSite: googleSiteDefault,
  details: googleSiteDetailsDefault,
  page: pageDefault,
  googleSites: googleSitesDefault,
  pagination: paginationDefault,
};

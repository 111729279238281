import {SortingOrderEnum} from 'enums';
import {IGoogleSiteListSortOrder} from 'models/resources/google-sites';
import {GoogleSitesOrderingEnum} from '../enums/GoogleSitesListOrdering.enum';
import {GoogleSiteListSortNameEnum} from '../enums/GoogleSitesListSortName.enum';

export const getSortGoogleSiteLiesMenuItemsList = (
  itemClickHandler: () => void,
  handleItemClick: (handleItemSortObj: IGoogleSiteListSortOrder) => void
) => {
  const onClick = (
    itemClickHandler: () => void,
    handleItemClick: (handleItemSortObj: IGoogleSiteListSortOrder) => void,
    handleItemSortObj: IGoogleSiteListSortOrder
  ) => {
    return () => {
      itemClickHandler();
      handleItemClick(handleItemSortObj);
    };
  };

  return [
    {
      name: GoogleSiteListSortNameEnum.NAME_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: GoogleSitesOrderingEnum.Name,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: GoogleSiteListSortNameEnum.NAME_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: GoogleSitesOrderingEnum.Name,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: GoogleSiteListSortNameEnum.LAST_MODIFIED_DATE_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: GoogleSitesOrderingEnum.LastModifiedDate,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: GoogleSiteListSortNameEnum.LAST_MODIFIED_DATE_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: GoogleSitesOrderingEnum.LastModifiedDate,
        sort: SortingOrderEnum.DESC,
      }),
    },
  ];
};

import {SortingOrderEnum} from 'enums';
import {IProgramClassInstrumentsSortOrder} from 'models/programs';
import {ProgramClassInstrumentsDropdownNameEnum, ProgramClassInstrumentsOrderingEnum} from '../../enums';

export const getProgramClassInstrumentsMenuItemsList = (
  itemClickHandler: () => void,
  handleItemClick: (handleItemSortObj: IProgramClassInstrumentsSortOrder) => void
) => {
  const onClick = (
    itemClickHandler: () => void,
    handleItemClick: (handleItemSortObj: IProgramClassInstrumentsSortOrder) => void,
    handleItemSortObj: IProgramClassInstrumentsSortOrder
  ) => {
    return () => {
      itemClickHandler();
      handleItemClick(handleItemSortObj);
    };
  };

  return [
    {
      name: ProgramClassInstrumentsDropdownNameEnum.Name_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramClassInstrumentsOrderingEnum.Name,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: ProgramClassInstrumentsDropdownNameEnum.Name_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramClassInstrumentsOrderingEnum.Name,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: ProgramClassInstrumentsDropdownNameEnum.Type_PRE_POST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramClassInstrumentsOrderingEnum.Type,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: ProgramClassInstrumentsDropdownNameEnum.Type_POST_PRE,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramClassInstrumentsOrderingEnum.Type,
        sort: SortingOrderEnum.DESC,
      }),
    },
  ];
};

import React from 'react';
import {Form} from 'forms';
import {FormNameEnum} from 'filters-selections';
import {DynamicCharacters, InstrumentEditContainer} from '..';

export const InstrumentEditInstructionalText = () => (
  <InstrumentEditContainer>
    <Form.TextArea name={FormNameEnum.instructionText} placeholder={'Add Instructional Text...'} />
    <DynamicCharacters />
  </InstrumentEditContainer>
);

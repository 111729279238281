import {axiosApiInstance, IHttpResponse} from 'api';
import {ApiPathsEnum} from 'enums';
import {config} from 'config';
import {del, get, post, put} from './http-client';
import {getApiJsonBlobDataHeadersConfig, returnQueryStringWithParams} from 'utils';
import {
  ICreateUpdateLearningPathApiResponse,
  ICreateLearningPathPayload,
  IGetAllFilesApiResponse,
  IGetLearningPathByIdApiResponse,
  IGetLearningPathsPayload,
  IUpdateLearningPathPayload,
  ILearningDetailsPathBaseApiResponse,
  IGetExportLearningPathApiResponse,
  IDuplicateLearningPathApiResponse,
  IDuplicateLearningPathPayload,
} from 'models/resources';

const {jaEeApi: apiConfig} = config;

export const learningPathsApi = {
  async getAllLearningPaths({
    pagination,
    sortOrder,
    filter,
  }: IGetLearningPathsPayload): Promise<IHttpResponse<IGetAllFilesApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.LEARNING_PATH}/List`;

    const queryString = returnQueryStringWithParams(url, {
      ...pagination,
      ...sortOrder,
      ...filter,
    });

    return await get<IGetAllFilesApiResponse>(queryString);
  },

  async createLearningPath(
    payload: ICreateLearningPathPayload
  ): Promise<IHttpResponse<ICreateUpdateLearningPathApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.LEARNING_PATH}/Create`;
    return await post<ICreateUpdateLearningPathApiResponse>(url, payload);
  },

  async getLearningPathById(id: string): Promise<IHttpResponse<IGetLearningPathByIdApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.LEARNING_PATH}/${id}/Details`;
    return await get<IGetLearningPathByIdApiResponse>(url);
  },

  async updateLearningPath(
    payload: IUpdateLearningPathPayload
  ): Promise<IHttpResponse<ICreateUpdateLearningPathApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.LEARNING_PATH}/Update`;
    return await post<ICreateUpdateLearningPathApiResponse>(url, payload);
  },

  async deleteLearningPath(contentItemId: string): Promise<IHttpResponse<ILearningDetailsPathBaseApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.LEARNING_PATH}/${contentItemId}/Delete`;
    return await del<ILearningDetailsPathBaseApiResponse>(url, {});
  },

  async publishLearningPath(contentItemId: string): Promise<IHttpResponse<ILearningDetailsPathBaseApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.LEARNING_PATH}/${contentItemId}/Publish`;
    return await put<ILearningDetailsPathBaseApiResponse>(url, {});
  },

  async getExportLearningPath(id: string): Promise<IHttpResponse<IGetExportLearningPathApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.LEARNING_PATH}/ExportLearningPath`;
    const config = getApiJsonBlobDataHeadersConfig();
    const queryString = returnQueryStringWithParams(url, {
      id,
    });

    return await axiosApiInstance.get(queryString, config);
  },

  async postDuplicateLearningPath(
    contentItemId: string,
    payload: IDuplicateLearningPathPayload
  ): Promise<IHttpResponse<IDuplicateLearningPathApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.LEARNING_PATH}/${contentItemId}/Duplicate`;
    return await post<IDuplicateLearningPathApiResponse>(url, payload);
  },
};

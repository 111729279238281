import {createAsyncThunk} from '@reduxjs/toolkit';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {itemImportApi} from 'api';
import {TGetUploadItemBankApiRequestPayload} from 'models';
import {importItemsMessages} from 'admin';

export const uploadItemBankThunk: any = createAsyncThunk<any, TGetUploadItemBankApiRequestPayload>(
  `${prefix} uploadItemBankThunk`,
  async (payload: TGetUploadItemBankApiRequestPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await itemImportApi.uploadItemBank(payload);
      dispatch(toastNotificationManager.createSuccessToastAction(importItemsMessages.mappingSuccess));
      return response.data;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

import {makeStyles} from '@material-ui/core';
import {Row} from 'components-lib';
import React from 'react';
import {Link} from 'react-router-dom';

interface ISidebarLinkProps {
  to: string;
  text: string;
}

export function SidebarLink({to, text}: ISidebarLinkProps) {
  const classes = useStyles();
  return (
    <Row justify="flex-start">
      <Link to={to} className={classes.link}>
        {text}
      </Link>
    </Row>
  );
}

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
}));

import * as Yup from 'yup';
import {QuestionAnswersTypeEnum} from 'enums';
import {FormNameEnum} from 'filters-selections';
import {errorMessages} from 'utils';

export interface IItemCreateFormValues {
  [FormNameEnum.questionId]: number;
  [FormNameEnum.questionTextHtml]: string;
  [FormNameEnum.text]: string;
  [FormNameEnum.type]: QuestionAnswersTypeEnum;
}

export const itemCreateFormValidationSchema = Yup.object().shape({
  [FormNameEnum.type]: Yup.number().required(errorMessages.requiredWithFieldName('a Question option type')),
  [FormNameEnum.text]: Yup.string().nullable().required('Question text cannot be empty!'),
  [FormNameEnum.questionTextHtml]: Yup.string(),
});

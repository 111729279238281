import {reducer as oidc} from '@axa-fr/react-oidc-redux';
import {combineReducers} from '@reduxjs/toolkit';
import {integrationsSlice, libraryReducer, programsReducer, uploaderReducer, resourcesReducer} from 'admin';
import {itemsImportSlice} from 'admin/items-import/store/itemsImport.slice';
import {filtersSelectionsReducer} from 'filters-selections';
import {permissionsReducer} from 'permissions';
import {toastNotificationsReducer} from 'toast-notifications';
import {userReducer} from 'user';

export const rootReducer = combineReducers({
  oidc,
  permissions: permissionsReducer,
  programs: programsReducer,
  resources: resourcesReducer,
  upload: uploaderReducer,
  user: userReducer,
  library: libraryReducer,
  filtersSelections: filtersSelectionsReducer,
  toastNotifications: toastNotificationsReducer,
  integrations: integrationsSlice?.reducer,
  itemsImport: itemsImportSlice.reducer,
});

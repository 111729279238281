import {RolesEnum} from 'permissions/enums';

class PermissionsManager {
  #activeUserRoles: RolesEnum[];

  public getActiveUserRoles() {
    return this.#activeUserRoles;
  }

  public setActiveUserRoles(roles: RolesEnum[]) {
    this.#activeUserRoles = roles;
  }
}

export const permissionsManager = new PermissionsManager();

import {useCallback} from 'react';
import debounce from 'lodash.debounce';

export const useDebounce = (callback: any, delay = 500) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFn = useCallback(
    debounce((...args) => callback(...args), delay),
    [delay] // will recreate if delay changes
  );
  return debouncedFn;
};

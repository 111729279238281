import {IGoogleSitesAreasTableEnum} from '../enums';
import {IGoogleSitesAreasTableColumn} from '../models';

export const getGoogleSiteTableColumns = (): IGoogleSitesAreasTableColumn[] => [
  {id: IGoogleSitesAreasTableEnum.Name, label: 'Name', align: 'left'},
  {id: IGoogleSitesAreasTableEnum.Area, label: 'Area', align: 'left'},
  {id: IGoogleSitesAreasTableEnum.Status, label: 'Status', align: 'left'},
  {id: IGoogleSitesAreasTableEnum.LastCopiedDate, label: 'Last Copied Date (UTC)', align: 'left'},
  {id: IGoogleSitesAreasTableEnum.Copy, label: '', align: 'right'},
];

import {BuilderItemDragTypeEnum} from 'admin/library/enums';
import {useBuilderItemDrag} from 'admin/library/instruments/builder/hooks';
import {IBuilderDragItem} from 'admin/library/models';
import {DragItem} from 'dragAndDrop';
import {QuestionAnswersTypeEnum} from 'enums';
import {IAnswer, IQuestionAnswerStackDetails} from 'models';
import React, {ReactNode} from 'react';
import {AnswersView} from './AnswersView';

interface IAnswersViewDragProps {
  answers: IAnswer[];
  type: QuestionAnswersTypeEnum;
  questionId: number;
  stackId?: number;
  dropHandler?: (item: IBuilderDragItem) => void;
  disabled?: boolean;
  hasMatchedMetadata: boolean;
  details?: IQuestionAnswerStackDetails;
  headingActions?: ReactNode;
}

export function AnswersViewDrag({
  answers = [],
  type,
  questionId,
  stackId,
  dropHandler,
  disabled = false,
  details,
  headingActions,
  hasMatchedMetadata,
}: IAnswersViewDragProps) {
  const itemDrag: IBuilderDragItem = {
    questionId,
    questionType: type,
    type: BuilderItemDragTypeEnum.item,
    answerStackId: stackId,
  };
  const {drag, isDragging} = useBuilderItemDrag(itemDrag, dropHandler);

  return (
    <DragItem drag={drag}>
      <AnswersView
        questionType={type}
        answers={answers}
        disabled={disabled}
        draggable
        isDragging={isDragging}
        details={details}
        headingActions={headingActions}
        hasMatchedMetadata={hasMatchedMetadata}
      />
    </DragItem>
  );
}

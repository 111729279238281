import React from 'react';
import {Row} from 'components-lib';
import {ReactComponent as SVGInfo} from 'resources/icons/info-large.svg';
import {ReactComponent as DisabledSVGInfo} from 'resources/icons/info-large-disabled.svg';
import {IWithDisabled} from 'models';

export function InfoIcon({disabled}: IWithDisabled) {
  return (
    <Row justify="center" align="center">
      {disabled ? <DisabledSVGInfo /> : <SVGInfo />}
    </Row>
  );
}

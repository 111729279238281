import {IActionFulfilled, IGetLevelsOfReportingApiResponse, IProgramBase} from 'models';
import {IProgramDetailsState} from './IProgramDetailsState';
import {fetchLevelsOfReportingListThunk, fetchProgramThunk} from './thunks';

export const programDetailsExtraReducer = {
  //fetchProgramThunk
  [fetchProgramThunk.fulfilled]: (state: IProgramDetailsState, action: IActionFulfilled<null, IProgramBase>) => {
    if (action.payload?.id) {
      state.activeEntity = {...action.payload};
    }

    state.loading = false;
  },
  [fetchProgramThunk.pending]: (state: IProgramDetailsState) => {
    state.loading = true;
  },
  [fetchProgramThunk.rejected]: (state: IProgramDetailsState) => {
    state.loading = false;
  },

  //  fetchLevelsOfReportingListThunk
  [fetchLevelsOfReportingListThunk.fulfilled]: (
    state: IProgramDetailsState,
    action: IActionFulfilled<null, IGetLevelsOfReportingApiResponse>
  ) => {
    if (action.payload) {
      const {data} = action.payload;
      state.levelOfReports.menuItems = [...data];
    }

    state.levelOfReports.loading = false;
  },
  [fetchLevelsOfReportingListThunk.pending]: (state: IProgramDetailsState) => {
    state.levelOfReports.loading = true;
  },
  [fetchLevelsOfReportingListThunk.rejected]: (state: IProgramDetailsState) => {
    state.levelOfReports.loading = false;
  },
};

import React, {ChangeEvent, useCallback} from 'react';

import {IComponentWithChildren} from 'models';
import {Field} from './Field';
import {FieldProps} from 'formik';
import {ToggleButtonGroup} from '@material-ui/lab';

type TValueChangeHandler = (id: number | string) => any;

interface IFormToggleButtonGroupProps extends IComponentWithChildren {
  name: string;
  value?: string | number;
  label?: string;
  disabled?: boolean;
}

export function FormToggleButtonGroup({name, value, label, disabled = false, children}: IFormToggleButtonGroupProps) {
  return (
    <Field<TValueChangeHandler>
      name={name}
      value={value}
      label={label}
      disabled={disabled}
      Component={ToggleButtonGroupDecorated}
    >
      {children}
    </Field>
  );
}

interface IInputDecoratedProps extends FieldProps, IFormToggleButtonGroupProps {}

function ToggleButtonGroupDecorated({field: {name}, form: {setFieldValue}, ...rest}: IInputDecoratedProps) {
  const changeHandler = useCallback(
    (event: ChangeEvent<any>, newValues: number[]) => {
      // There is not existing name property for ToggleButtonGroup and we attach it to the event
      event.target.name = name; // See: https://github.com/mui-org/material-ui/issues/13485

      setFieldValue(name, newValues);
    },
    [setFieldValue, name]
  );

  return (
    <ToggleButtonGroup {...rest} value={rest.meta.value} onChange={changeHandler}>
      {rest.children}
    </ToggleButtonGroup>
  );
}

import React, {useCallback, useMemo} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {Container, Label, Link, Row} from 'components-lib';
import {ModalVersions} from './ModalVersions';
import {useModal} from 'hooks';
import {SingleFileUpload} from 'admin/resources/upload/components';
import {FormNameEnum} from 'enums/form';
import {useFormikContext} from 'formik';
import {IEditFileMetadataFormBaseValues} from 'admin/resources/files/views/edit/components/FilesEditResourceForm/config';
import {
  ALLOWED_MIMETYPE_FORMATS,
  audioMimeType,
  documentMimeTypes,
  excelMimeTypes,
  imageMimeTypes,
  pdfMimeType,
  videoMimeType,
  zipMimeType,
} from 'admin/resources';

export const VersionFileUpload = () => {
  const classes = useStyles();
  const {isOpen: isModalVersionsOpen, setIsOpen: setIsModalVersionsOpen} = useModal();
  const {values} = useFormikContext<IEditFileMetadataFormBaseValues>();
  const shouldDisableReUploadButton = !values?.canEdit;
  const resourceMimeTypes = ALLOWED_MIMETYPE_FORMATS.join(', ');

  const allowedFormats = useMemo(() => {
    if (values?.fileTypeRecognition?.isVideo) {
      return videoMimeType;
    }

    if (values?.fileTypeRecognition?.isAudio) {
      return audioMimeType;
    }

    if (values?.fileTypeRecognition?.isImage) {
      return imageMimeTypes;
    }

    if (values?.fileTypeRecognition?.isDocument) {
      return documentMimeTypes;
    }

    if (values?.fileTypeRecognition?.isExcel) {
      return excelMimeTypes;
    }

    if (values?.fileTypeRecognition?.isPDF) {
      return pdfMimeType;
    }

    if (values?.fileTypeRecognition?.isArchive || values?.fileTypeRecognition?.isScormPackage) {
      return zipMimeType;
    }

    return resourceMimeTypes;
  }, [values.fileTypeRecognition, resourceMimeTypes]);

  const openModalVersionsClickHandler = useCallback(() => setIsModalVersionsOpen(true), [setIsModalVersionsOpen]);

  return (
    <Container disableGutters>
      <Label label="Version" withLeftMargin={false} withFontBold />
      <Row justify="flex-start" align="flex-start">
        <Box pr={4}>
          {values?.fileVersion && (
            <Link clickHandler={openModalVersionsClickHandler} disabled={!values?.canEdit}>
              {values?.fileVersion}
            </Link>
          )}
        </Box>
        <Row justify="flex-start" align="flex-start" classes={{root: classes.fileUpload}}>
          <SingleFileUpload
            disableDragAndDrop
            disabled={shouldDisableReUploadButton}
            fullHeight={false}
            name={FormNameEnum.file}
            accept={allowedFormats}
            position="horizontal"
            align="flex-start"
            justify="flex-start"
            withOnlyBrowseButton
          />
        </Row>
      </Row>
      <ModalVersions isOpen={isModalVersionsOpen} setIsOpen={setIsModalVersionsOpen} versions={values?.versions} />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  fileUpload: {
    paddingTop: theme.spacing(3),
  },
}));

import {createAsyncThunk} from '@reduxjs/toolkit';
import {integrationsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {IPublishIntegrationPayload, IIntegrationDetails} from 'models';
import {integrationDetailsMessages} from 'admin/integrations/details/utils';

export const publishIntegrationThunk: any = createAsyncThunk<IIntegrationDetails, IPublishIntegrationPayload>(
  `${prefix} publishIntegration`,
  async ({integrationId}: IPublishIntegrationPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await integrationsApi.publishIntegration(integrationId);
      return parsedBody;
    } catch (error) {
      dispatch(
        toastNotificationManager.createErrorToastAction(
          error.messages ?? integrationDetailsMessages.unknownIntegrationError
        )
      );
      return rejectWithValue({error});
    }
  }
);

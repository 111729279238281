import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';
import {values} from 'utils';

const getLearningPathsState = (state: TStateStore) => state.resources.learningPaths;

export const getSessionBuilderState = createSelector(
  getLearningPathsState,
  (learningPaths) => learningPaths.sessionBuilder
);

export const getBuilderState = createSelector(getSessionBuilderState, (sessionBuilder) => sessionBuilder.builder);

export const getLearningPathsBuilderSessionItems = createSelector(
  getBuilderState,
  (builder) => builder.builderSessionItems
);

export const builderSessionItemsEntitiesSelector = createSelector(
  getLearningPathsBuilderSessionItems,
  (builderSessionItems) => builderSessionItems.entities.items ?? {}
);

export const builderSessionItemsResultSelector = createSelector(
  getLearningPathsBuilderSessionItems,
  (builderSessionItems) => builderSessionItems.result ?? []
);

export const builderSessionItemsCollectionSelector = createSelector(
  getLearningPathsBuilderSessionItems,
  (builderSessionItems) => {
    return builderSessionItems.entities.items ? values(builderSessionItems.entities.items) : [];
  }
);

export const learningPathsCreateBuilderIsAllSessionsExpanded = createSelector(getBuilderState, (builder) => {
  return builder.isAllSessionsExpanded;
});

export const isSomeOfSessionsInEditModeSelector = createSelector(getBuilderState, (builder) => {
  return builder.isSomeOfSessionsInEditMode;
});

export const isSomeOfSessionsWithEmptyNameSelector = createSelector(getBuilderState, (builder) => {
  return builder.isSomeOfSessionsWithEmptyName;
});

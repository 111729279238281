import {useSelector} from 'react-redux';
import {filtersLanguagesEntitiesCollectionSelector} from 'filters-selections';

export const useLanguages = () => {
  const languages = useSelector(filtersLanguagesEntitiesCollectionSelector);
  const firstLanguage = languages && languages[0];
  const englishName = firstLanguage?.name as string;
  const englishId = firstLanguage?.id as number;

  return {englishName, englishId};
};

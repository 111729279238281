import React, {useCallback} from 'react';
import {ModalDelete} from 'admin/components';
import {FormNameEnum} from 'filters-selections';
import {isDomainKnowledge} from 'utils';
import {getAnswerStackData} from '../../builder/utils';
import {useItem, useItemIdParam, useItemStateActions} from '../../hooks';
import {getFormValue, idProp} from 'admin/library/utils';
import {useNavigate} from 'hooks';
import {pages} from 'paths';

interface IModalDeleteAnswerStackProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

export function ModalDeleteAnswerStack({isOpen, setIsOpen}: IModalDeleteAnswerStackProps) {
  let answerStack = null;

  const {goBackOptionally} = useNavigate();
  const {itemId, answerStackId} = useItemIdParam();
  const {deleteAnswerStack} = useItemStateActions();
  const {item} = useItem();
  const {questionTextHtml, text, questionOptionsTypeId} = item;
  const isDomainKnowledgeSelected = isDomainKnowledge(answerStack?.metaData?.domain);
  const questionIdQueryParam = `?questionId=${itemId}`;

  if (item) {
    answerStack = getAnswerStackData(item, answerStackId);
  }

  const mapDeleteAnswerStackPayload = useCallback(
    () => ({
      [FormNameEnum.id]: itemId,
      [FormNameEnum.questionAnswerStackId]: answerStackId,
      [FormNameEnum.isDeleted]: true,
      [FormNameEnum.questionType]: questionOptionsTypeId,
      [FormNameEnum.text]: text,
      [FormNameEnum.questionTextHtml]: questionTextHtml,
      [FormNameEnum.pillars]: getFormValue(answerStack?.metaData?.pillars, idProp),
      [FormNameEnum.evaluationTypes]: getFormValue(answerStack?.metaData?.evaluationTypes, idProp),
      [FormNameEnum.domain]: answerStack?.metaData?.domain?.id,
      [FormNameEnum.programs]: isDomainKnowledgeSelected
        ? answerStack?.metaData.programs[0]?.id
        : getFormValue(answerStack?.metaData?.programs, idProp),
      [FormNameEnum.competencies]: getFormValue(answerStack?.metaData?.competencies, idProp),
      [FormNameEnum.concepts]: getFormValue(answerStack?.metaData?.concepts, idProp),
      [FormNameEnum.administration]: answerStack?.metaData?.administration?.id,
      [FormNameEnum.schoolLevels]: getFormValue(answerStack?.metaData?.schoolLevels, idProp),
      [FormNameEnum.category]: answerStack?.metaData?.category ?? '',
      [FormNameEnum.choices]: answerStack?.choices,
      [FormNameEnum.rangeFrom]: answerStack?.rangeFrom,
      [FormNameEnum.rangeTo]: answerStack?.rangeTo,
      [FormNameEnum.minimumRateDescription]: answerStack?.minimumRateDescription ?? '',
      [FormNameEnum.maximumRateDescription]: answerStack?.maximumRateDescription ?? '',
    }),
    [answerStack, answerStackId, isDomainKnowledgeSelected, itemId, questionOptionsTypeId, questionTextHtml, text]
  );

  const deleteAnswerStackHandler = useCallback(() => {
    const payload = mapDeleteAnswerStackPayload();
    deleteAnswerStack(payload).then(() => goBackOptionally(`${pages.adminPortal.items.create}${questionIdQueryParam}`));
  }, [mapDeleteAnswerStackPayload, deleteAnswerStack, goBackOptionally, questionIdQueryParam]);

  return (
    <ModalDelete
      title="Delete Answer stack"
      text="This action will delete the question's answer stack. Are you sure you want to delete this answer stack?"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      confirmHandler={deleteAnswerStackHandler}
    />
  );
}

import React, {Fragment} from 'react';
import {SidebarAuthor, SidebarTagWithValue, SidebarTitle} from 'admin/components';
import {useGeneralInstrumentMeta} from 'admin/library/instruments/hooks';
import {Divider} from 'components-lib';
import {IInstrumentDetails} from 'models';
interface ISidebarInstrumentDetailsViewProps {
  instrument: IInstrumentDetails;
}

export function SidebarInstrumentDetailsView({instrument}: ISidebarInstrumentDetailsViewProps) {
  const {isGeneralTemplate, pillar, programs, phase, type, audience, createdBy, createdOn} = instrument;
  const {audienceType, feedbackType} = useGeneralInstrumentMeta();

  if (isGeneralTemplate) {
    return (
      <Fragment>
        <SidebarTagWithValue tag="Type" text={feedbackType?.displayText} />
        <SidebarTagWithValue tag="Audience" text={audienceType?.displayText} />
        <Divider.Horizontal />
        <SidebarTitle text="Details" />
        <SidebarAuthor author={createdBy} date={createdOn} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <SidebarTagWithValue tag="Pillar" text={pillar?.name} />
      {programs?.map((program) => (
        <SidebarTagWithValue key={program.id} tag="Program" text={program?.name} />
      ))}
      <SidebarTagWithValue tag="Phase" text={phase?.displayText} />
      <SidebarTagWithValue tag="Type" text={type?.displayText} />
      <SidebarTagWithValue tag="Audience" text={audience?.displayText} />
      <Divider.Horizontal />
      <SidebarTitle text="Details" />
      <SidebarAuthor author={createdBy} date={createdOn} />
    </Fragment>
  );
}

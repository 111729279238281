import {createAsyncThunk} from '@reduxjs/toolkit';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {itemImportApi} from 'api';
import {TGetImportInstructionalTextApiResponse} from 'models';
import {errorMessages} from 'utils/constants';

export const fetchImportInstructionalTextThunk: any = createAsyncThunk<TGetImportInstructionalTextApiResponse, null>(
  `${prefix} fetchImportInstructionalText`,
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await itemImportApi.getImportInstructionalText();
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(errorMessages.defaultErrorMessage));
      return rejectWithValue({error});
    }
  }
);

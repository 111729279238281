import React from 'react';
import {
  ButtonPrimaryWithAdminAndLocalAreaPermissions,
  ButtonSecondaryWithAdminAndLocalAreaPermissions,
  ButtonSecondaryWithSuperAdminPermissions,
} from 'admin/library/instruments/components';
import {useInstruments} from 'admin/library/instruments/hooks';
import {Button} from 'components-lib';
import {EntityStatusEnum} from 'enums';
import {Form} from 'forms';
import {ActiveModeEnum} from 'hooks';
import {PreviewDropdown} from '..';
import {withRole} from 'permissions';

interface IHeaderActionButtonsProps {
  instrumentId: number;
  status: EntityStatusEnum;
  editMode: ActiveModeEnum;
  isMasterInstrument: boolean;
  isGeneralTemplate: boolean;
  hasAssignmentResults: boolean;
  canEdit: boolean;
  canPublish?: boolean;
  parentId: number | null;
  valuesPristine?: boolean;
  previewClickHandler: () => void;
  previewWithAnswersClickHandler: () => void;
  updateModeClickHandler: () => void;
  cancelClickHandler: () => void;
  deleteModalOpenHandler: () => void;
  publishModalOpenHandler: () => void;
  cloneModalOpenHandler: () => void;
  exportGeneralClickHandler: () => void;
}

export function HeaderActionButtons({
  status,
  editMode,
  isMasterInstrument,
  isGeneralTemplate,
  canEdit,
  canPublish,
  updateModeClickHandler,
  previewClickHandler,
  previewWithAnswersClickHandler,
  cancelClickHandler,
  deleteModalOpenHandler,
  publishModalOpenHandler,
  cloneModalOpenHandler,
  exportGeneralClickHandler,
}: IHeaderActionButtonsProps) {
  const {pageSaving} = useInstruments();

  const isLive = status === EntityStatusEnum.live;
  const isEditMode = editMode === ActiveModeEnum.edit;

  const shouldDisablePublish = !canPublish || pageSaving || isLive;
  const showEditAndPublishButton = canEdit && !isLive;
  const showSuperAdminEditButton = canEdit;
  const showCloneButton = isLive && isMasterInstrument;

  if (isEditMode) {
    return (
      <div>
        <Button.Secondary clickHandler={cancelClickHandler}>Cancel</Button.Secondary>
        <Form.ButtonSubmit text="Save" size="large" disabled={pageSaving} />
      </div>
    );
  }

  /*
   * A published template could be un-published only if it doesn't have assignment results and
   * if it has an parentId
   */

  return (
    <div>
      {canEdit && (
        <ButtonSecondaryWithAdminAndLocalAreaPermissions clickHandler={deleteModalOpenHandler} disabled={pageSaving}>
          Delete
        </ButtonSecondaryWithAdminAndLocalAreaPermissions>
      )}
      {showEditAndPublishButton && (
        <ButtonSecondaryWithAdminAndLocalAreaPermissions clickHandler={updateModeClickHandler} disabled={pageSaving}>
          Edit
        </ButtonSecondaryWithAdminAndLocalAreaPermissions>
      )}
      {showSuperAdminEditButton && (
        <ButtonSecondaryWithSuperAdminPermissions clickHandler={updateModeClickHandler} disabled={pageSaving}>
          Super Admin Edit
        </ButtonSecondaryWithSuperAdminPermissions>
      )}

      <PreviewDropdown
        previewClickHandler={previewClickHandler}
        previewWithAnswersClickHandler={previewWithAnswersClickHandler}
      />

      {isGeneralTemplate && (
        <ButtonSecondaryWithAdminAndLocalAreaPermissions clickHandler={exportGeneralClickHandler}>
          Export Data
        </ButtonSecondaryWithAdminAndLocalAreaPermissions>
      )}
      {showCloneButton && (
        <ButtonPrimaryWithAdminAndLocalAreaPermissions clickHandler={cloneModalOpenHandler} disabled={pageSaving}>
          Clone
        </ButtonPrimaryWithAdminAndLocalAreaPermissions>
      )}
      {showEditAndPublishButton && (
        <ButtonPrimaryWithAdminAndLocalAreaPermissions
          clickHandler={publishModalOpenHandler}
          disabled={shouldDisablePublish}
        >
          Publish
        </ButtonPrimaryWithAdminAndLocalAreaPermissions>
      )}
    </div>
  );
}

import {createAsyncThunk} from '@reduxjs/toolkit';
import {pamApi} from 'api';
import {IResourceItemBase} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {fileDetailsMessages} from '../../utils';

export const deleteFileThunk: any = createAsyncThunk<IResourceItemBase, string>(
  `${prefix} deleteFileThunk`,
  async (id, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await pamApi.deleteFileById(id);
      dispatch(toastNotificationManager.createSuccessToastAction(fileDetailsMessages.deleteSuccess));
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

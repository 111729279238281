import {createAsyncThunk} from '@reduxjs/toolkit';
import {pamApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {getContentResponseHeaders} from '../../../edit/utils';
import {filesAllMessages} from '../../utils';

import {prefix} from './config';

export const downloadFileThunk: any = createAsyncThunk<any, string>(
  `${prefix} downloadFile`,
  async (id, {dispatch, rejectWithValue}) => {
    dispatch(toastNotificationManager.createSuccessToastAction(filesAllMessages.resourceDownloading));
    try {
      const response = await pamApi.downloadFileByResourceId(id);
      const {mimeType, fileName} = getContentResponseHeaders(response);

      return {
        blob: response.data,
        fileName,
        mimeType,
      };
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

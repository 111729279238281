export enum TagsEnum {
  ProgramSingle,
  ProgramMulti,
  ProgramYear,
  GradeLevel,
  Audience,
  Phase,
  Pillar,
  Metadata,
  Areas,
  Educators,
  Districts,
  Schools,
  FromToDate,
  InstrumentType,
  InstrumentStatus,
  EvaluationType,
  Domain,
  Competency,
  Concept,
  Category,
  Administration,
  SchoolLevel,
  ItemType,
  ItemStatus,
}

import React, {FC} from 'react';
import {Container} from '@material-ui/core';
import {Form} from 'forms';
import {IWithDisabled} from 'models/common';
import {ItemWithPlaceholder} from 'filters-selections';

interface IResourcesFormInputProps extends IWithDisabled {
  name: string;
  label: string;
  autoFocus?: boolean;
}

export const ResourcesFormInput: FC<IResourcesFormInputProps> = ({label, name, disabled, autoFocus = false}) => {
  return (
    <Container disableGutters>
      <ItemWithPlaceholder label={label} hasItems>
        <Form.InputWithField name={name} type="text" disabled={disabled} value="" autoFocus={autoFocus} />
      </ItemWithPlaceholder>
    </Container>
  );
};

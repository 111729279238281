import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {IUpdateInstrumentApiResponse, IUpdateInstrumentPayload} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {instrumentsMessages} from '../../utils';
import {prefix} from './config';

const defaultMessage = 'Something went wrong!';

export const updateInstrumentThunk: any = createAsyncThunk<IUpdateInstrumentApiResponse, IUpdateInstrumentPayload>(
  `${prefix} updateInstrument`,
  async (payload: IUpdateInstrumentPayload, {rejectWithValue, dispatch}) => {
    try {
      const response = await instrumentsApi.updateInstrument(payload);
      dispatch(toastNotificationManager.createSuccessToastAction(instrumentsMessages.saveSuccess));
      return response.data;
    } catch (error) {
      let message = defaultMessage;

      if (error.response.data) {
        message = error.response.data.error;
      }

      dispatch(toastNotificationManager.createErrorToastAction(message));
      return rejectWithValue({error});
    }
  }
);

import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import {CircularProgress, Icon, Row, Tooltip, Wrapper} from 'components-lib';
import {useEditLearningPath, useLearningPathIdParam} from '../../hooks';
import {learningPathEditMessages} from '../../utils';

export const EditLearningPathDetailsHeaderActions = () => {
  const classes = useStyles();
  const {exporting, fetchExportLearningPath} = useEditLearningPath();
  const {learningPathId} = useLearningPathIdParam();

  const exportClickHandler = useCallback(() => fetchExportLearningPath(learningPathId), [
    fetchExportLearningPath,
    learningPathId,
  ]);

  return (
    <Row justify="flex-end">
      <Tooltip title={learningPathEditMessages.clickToExport}>
        <Icon.Button disabled={exporting} clickHandler={exportClickHandler}>
          {exporting ? (
            <Wrapper className={classes.progress}>
              <CircularProgress size={20} />
            </Wrapper>
          ) : (
            <Icon.ExportXlsIcon />
          )}
        </Icon.Button>
      </Tooltip>
    </Row>
  );
};

const useStyles = makeStyles((theme) => ({
  progress: {
    padding: theme.spacing(0, 1),
  },
}));

import {FormControlLabel, makeStyles} from '@material-ui/core';
import {Accordion, Column, Row, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import {IAnswerStack} from 'models';
import React, {SyntheticEvent, useMemo} from 'react';

interface IItemAnswerStackProps {
  idx: number;
  answerStack: IAnswerStack;
  disabled?: boolean;
}

export function ItemAnswerStack({answerStack, idx, disabled}: IItemAnswerStackProps) {
  const classes = useStyles();

  const heading = useMemo(() => {
    const subHeading = answerStack.choices.map((answer) => answer.text).join(', ');
    return (
      <Row align="center" classes={{root: classes.heading}}>
        <Text.Heading as="h4">{answerStack.answerTypeName}</Text.Heading>
        <div className={classes.subHeading}>
          <Text.Caption>{subHeading}</Text.Caption>
        </div>
      </Row>
    );
  }, [answerStack.choices, answerStack.answerTypeName, classes.heading, classes.subHeading]);

  const accordionHeadingAction = useMemo(() => {
    const stopPropagationHandler = (event: SyntheticEvent) => event.stopPropagation();

    return (
      <FormControlLabel
        onClick={stopPropagationHandler}
        onFocus={stopPropagationHandler}
        control={<Form.Checkbox name={`${FormNameEnum.answerStacks}.${idx}.isSelected`} disabled={disabled} />}
        label=""
        classes={{root: classes.accordionAction}}
      />
    );
  }, [idx, classes, disabled]);

  return (
    <Accordion heading={heading} headingActionLeft={accordionHeadingAction} withBorder>
      <Column>
        {answerStack.choices.map((answer, answerIdx) => {
          return (
            <Row key={answer.answerId} classes={{root: classes.answer}} justify="flex-start">
              <Form.Checkbox
                name={`${FormNameEnum.answerStacks}.${idx}.choices.${answerIdx}.isCorrect`}
                label={answer.text}
                disabled={disabled}
              />
            </Row>
          );
        })}
      </Column>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  answer: {
    paddingLeft: theme.spacing(4),
  },
  heading: {
    flex: 1,
  },
  subHeading: {
    marginLeft: theme.spacing(3),
    flex: 1,
  },
  accordionAction: {
    margin: 0,
  },
}));

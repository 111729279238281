import {createAsyncThunk} from '@reduxjs/toolkit';
import {itemsApi} from 'api';
import {SortingOrderEnum} from 'enums';
import {IBindItemsPage, IItemsFilter, IGetPageOfEntities} from 'models';
import {TStateStore} from 'store';
import {prefix} from './config';
import {IItemsAllSortOrder} from './../../../../../models/library/items/IItemsAllSortOrder';
import {toastNotificationManager} from 'toast-notifications';
interface IPayload {
  filter: IItemsFilter;
  sortOrder: IItemsAllSortOrder;
  pagination: IGetPageOfEntities;
}

export const fetchListItemsPagedThunk: any = createAsyncThunk<IBindItemsPage, any, {dispatch: any; state: TStateStore}>(
  `${prefix} fetchListItemsPaged`,
  async ({filter, sortOrder: {sort, order}, pagination}: IPayload, {dispatch, rejectWithValue}) => {
    try {
      const sortAsc = sort === SortingOrderEnum.ASC;
      const {parsedBody} = await itemsApi.getPageOfItems(
        {pageSize: pagination.itemsPerPage, pageNumber: pagination.page},
        {sortAsc, sortBy: order},
        filter
      );
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

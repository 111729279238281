import {DragHandle} from '@material-ui/icons';
import React, {FC} from 'react';

interface IDragHandleIconProps {
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
}

export const DragHandleIcon: FC<IDragHandleIconProps> = ({color = 'disabled'}) => {
  return <DragHandle fontSize="small" color={color} />;
};

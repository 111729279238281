import React from 'react';
import {Form} from 'forms';
import {Column, Row, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections';
import {ItemQuestionAnswersTypeEnum as answerTypeEnum} from 'admin/library/items/enums';

enum ResponseTypeOptions {
  SingleSelect = 'Single Select',
  MultiSelect = 'Multiple Select',
  Dropdown = 'Dropdown',
  OpenEnded = 'Open-ended',
  NumericScale = 'Numeric Scale',
}

const responseTypeOptions = [
  {
    label: ResponseTypeOptions.SingleSelect,
    value: answerTypeEnum.singleSelectAnswerEnum,
  },
  {
    label: ResponseTypeOptions.MultiSelect,
    value: answerTypeEnum.matrixMultiSelectAnswerEnum,
  },
  {
    label: ResponseTypeOptions.Dropdown,
    value: answerTypeEnum.dropdownAnswerEnum,
  },
  {
    label: ResponseTypeOptions.OpenEnded,
    value: answerTypeEnum.openEndedAnswerEnum,
  },
  {
    label: ResponseTypeOptions.NumericScale,
    value: answerTypeEnum.ratingAnswerEnum,
  },
];

export const ResponseTypeGroup = () => {
  return (
    <Column>
      <Text.Caption>Response Type</Text.Caption>
      <Form.RadioGroup name={FormNameEnum.questionType}>
        <Row justify="flex-start">
          {responseTypeOptions.map((responseType, index: number) => (
            <Form.Radio key={index} value={responseType.value} label={responseType.label} />
          ))}
        </Row>
      </Form.RadioGroup>
    </Column>
  );
};

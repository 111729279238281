import React, {useEffect} from 'react';
import {LearningObjectiveSelect} from './LearningObjectiveSelect';
import {useIsDomainKnowledgeSelected} from 'filters-selections/hooks';
import {FormNameEnum} from 'filters-selections';
import {useFormikContext} from 'formik';

export const LearningObjectiveWithDomainDependencySelect = () => {
  const {values, setFieldValue} = useFormikContext();
  const {isDomainKnowledgeSelected} = useIsDomainKnowledgeSelected();
  const learningObjectiveValue = values[FormNameEnum.learningObjective];

  useEffect(() => {
    if (!isDomainKnowledgeSelected) {
      setFieldValue(FormNameEnum.learningObjective, '');
    }
  }, [learningObjectiveValue, isDomainKnowledgeSelected, setFieldValue]);

  return <LearningObjectiveSelect value={learningObjectiveValue} disabled={!isDomainKnowledgeSelected} />;
};

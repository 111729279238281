import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {IInstrumentDetailsAndParticipantSummary} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchInstrumentItemsAndParticipants: any = createAsyncThunk<
  IInstrumentDetailsAndParticipantSummary,
  number
>(`${prefix} fetchInstrumentItemsAndParticipants`, async (templateId: number, {rejectWithValue, dispatch}) => {
  try {
    const {parsedBody} = await instrumentsApi.getInstrumentItemsAndParticipantsById(templateId);
    return parsedBody;
  } catch (err) {
    dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
    return rejectWithValue({error: err});
  }
});

import React, {FC, useEffect, useMemo} from 'react';

import {IWithDisabled} from 'models';
import {Form} from 'forms';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {useDispatch} from 'react-redux';
import {fetchUserRoleListThunk, usePermissions} from 'permissions';
import {FormNameEnum} from 'filters-selections';

interface IRoleSelectProps extends IWithDisabled {
  label: string;
}

export const RoleSelect: FC<IRoleSelectProps> = ({disabled, label = 'Role'}) => {
  const dispatch = useDispatch();

  const {allUserRoles} = usePermissions();

  useEffect(() => {
    dispatch(fetchUserRoleListThunk());
  }, [dispatch]);

  const options: any = useMemo(() => {
    return allUserRoles.map((role) => ({
      value: role.value,
      label: role.name,
    }));
  }, [allUserRoles]);

  return (
    <ItemWithPlaceholder label={label} hasItems={!!allUserRoles.length}>
      <Form.SelectAutocomplete multiple name={FormNameEnum.role} options={options} disabled={disabled} />
    </ItemWithPlaceholder>
  );
};

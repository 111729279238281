import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Column, Container} from 'components-lib';
import {SelectionEnum, SelectionListItems} from 'filters-selections';
import {TWidth} from 'components-lib/layout/Column/Column';
interface IResourceListColumnProps {
  sm?: TWidth;
  md?: TWidth;
  lg?: TWidth;
  xl?: TWidth;
  list: SelectionEnum[];
  disableAll?: boolean;
}

export const ResourceListColumn: FC<IResourceListColumnProps> = ({
  list,
  disableAll = false,
  sm = 6,
  md = 4,
  lg = 4,
  xl = 3,
}) => {
  const classes = useStyles();

  return (
    <Column sm={sm} md={md} lg={lg} xl={xl}>
      <Container disableGutters classes={{root: classes.root}}>
        <SelectionListItems list={list} disableAll={disableAll} />
      </Container>
    </Column>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(2),
  },
}));

import React, {FC, Fragment, useCallback} from 'react';
import {batch} from 'react-redux';
import {LearningPathListItem} from 'admin/resources/learning-paths/components';
import {
  getAccessibleToStudentsTooltip,
  getLinkTooltip,
  useCreateLearningPathResources,
} from 'admin/resources/learning-paths/views/create';
import {ModalDelete} from 'admin/components';
import {useModal} from 'hooks';

interface ISessionInstrumentListItemProps {
  itemId?: string;
  instrumentItem: any;
  canDelete?: boolean;
  canHide?: boolean;
  isEditMode?: boolean;
}

export const SessionInstrumentListItem: FC<ISessionInstrumentListItemProps> = ({
  itemId,
  instrumentItem: {name, id, isDeleted, accessibleToStudents},
  canDelete = false,
  isEditMode = true,
}) => {
  const {redirectToEditInstrumentPage, deleteSessionBuilderInstrumentItem} = useCreateLearningPathResources({});
  const {isOpen: isDeleteModalOpen, setIsOpen: setIsDeleteModalOpen} = useModal();

  const openModalClickHandler = useCallback(() => setIsDeleteModalOpen(true), [setIsDeleteModalOpen]);

  const deleteClickHandler = useCallback(
    (instrumentId: number) =>
      batch(() => {
        itemId && deleteSessionBuilderInstrumentItem(instrumentId, itemId);
        setIsDeleteModalOpen(false);
      }),
    [itemId, setIsDeleteModalOpen, deleteSessionBuilderInstrumentItem]
  );

  const toolTip = getAccessibleToStudentsTooltip(accessibleToStudents);
  const linkTooltip = getLinkTooltip(isDeleted, false);
  const hasUrl = !!id;

  return (
    <Fragment>
      <LearningPathListItem
        name={name}
        isDeletedOrExpired={isDeleted}
        linkTooltip={linkTooltip}
        acessibleToStudentsTooltip={toolTip}
        disableLink={!hasUrl}
        disableDelete={!canDelete || !isEditMode}
        isAvailableToStudents={accessibleToStudents}
        linkClickHandler={() => redirectToEditInstrumentPage(id)}
        openModalClickHandler={openModalClickHandler}
      />
      <ModalDelete
        loading={false}
        title="Delete Instrument"
        text="Would you like to take the instrument out of the session?"
        cancelButtonText="No"
        confirmButtonText="Yes"
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        confirmHandler={() => deleteClickHandler(id)}
      />
    </Fragment>
  );
};

import React, {Fragment, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';

import {FormNameEnum, SelectionEnum, SelectionListItems} from 'filters-selections';
import {ItemAnswerRangeInput} from '..';
import {RangeButtons} from 'components-lib';

export const ItemAnswersRange = () => {
  const rateListItems = useMemo(() => {
    const selectionList = [SelectionEnum.RangeFrom, SelectionEnum.RangeTo];

    return <SelectionListItems list={selectionList} withFullWidth />;
  }, []);

  const positiveScoreItems = useMemo(() => {
    return (
      <Fragment>
        <RangeButtons />
      </Fragment>
    );
  }, []);

  const rateDescriptionItems = useMemo(() => {
    return (
      <Fragment>
        <ItemAnswerRangeInput name={FormNameEnum.minimumRateDescription} label={'Minimum Rate Description'} />
        <ItemAnswerRangeInput name={FormNameEnum.maximumRateDescription} label={'Maximum Rate Description'} />
      </Fragment>
    );
  }, []);

  return (
    <Fragment>
      <ContainerList>{rateListItems}</ContainerList>
      <ContainerList>{positiveScoreItems}</ContainerList>
      <ContainerList>{rateDescriptionItems}</ContainerList>
    </Fragment>
  );
};

const ContainerList = ({children}) => {
  const classes = useStyles();
  return <div className={classes.container}>{children}</div>;
};

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
  },
}));

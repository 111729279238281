import {SortingOrderEnum, EntitiesPerPageEnum} from 'enums';
import {IIntegrationDetails, IIntegrationPage, IIntegrationPagination} from 'models/integrations';
import {IIntegratiosAllFilter} from 'models/library';
import {IntegrationsFilterNamesEnum, IntegrationsOrderingEnum} from '../enums';
import {IIntegrationStoreState} from './IIntegrationsStoreState';

export const integrationConfigurationDefault = {
  serviceEmailAccount: null,
  clientId: null,
  clientSecret: null,
  authorityUrl: null,
};

const integrationDefault = {
  id: null,
  integrationType: null,
  name: null,
  status: null,
  areaId: null,
  areaName: null,
  createdOn: null,
  schoolId: null,
  schoolName: null,
  schoolDistrict: null,
  configuration: integrationConfigurationDefault,
  expiryDateCertificate: null,
} as IIntegrationDetails;

const filterDefault: IIntegratiosAllFilter = {
  [IntegrationsFilterNamesEnum.INTEGRATION_TYPE]: null,
  [IntegrationsFilterNamesEnum.INTEGRATION_STATUSES]: [],
  [IntegrationsFilterNamesEnum.AREAS]: [],
  [IntegrationsFilterNamesEnum.SCHOOL_NAMES]: [],
  [IntegrationsFilterNamesEnum.SCHOOL_DISTRICTS]: [],
  [IntegrationsFilterNamesEnum.SEARCH_BY_NAME]: '',
};

const integrationListItemsDefault = {};

const integrationListDefault = {
  result: [],
  entities: {
    items: integrationListItemsDefault,
  },
};

const pageDefault: IIntegrationPage = {
  loading: false,
  saving: false,
  deleting: false,
  loadingPageSection: false,
};

const paginationDefault: IIntegrationPagination = {
  currentPage: 1,
  totalCount: 0,
  perPage: EntitiesPerPageEnum.FIFTY,
};

const auditLogsDetault = [];

const addIntegrationMetadataDefault = {
  id: null,
  name: null,
  serviceSourceEmail: null,
  errorMessage: null,
};

export const integrationsInitialState: IIntegrationStoreState = {
  integration: integrationDefault,
  integrationList: integrationListDefault,
  addIntegration: {
    metadata: addIntegrationMetadataDefault,
  },
  details: {
    integrationStatuses: {
      result: [],
      entities: {
        integrationStatuses: {},
      },
    },
    auditLogs: {
      entities: auditLogsDetault,
      loading: false,
    },
  },
  page: pageDefault,
  sortOrder: {
    order: IntegrationsOrderingEnum.Created,
    sort: SortingOrderEnum.DESC,
  },
  filter: filterDefault,
  pagination: paginationDefault,
};

import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useFormikContext} from 'formik';
import {Form} from 'forms';
import {Accordion, Column} from 'components-lib';
import {IOption} from 'models';
import {
  delayTime,
  dynamicSearchMessages,
  FormNameEnum,
  getLoadingStateSchoolNames,
  minLength,
  schoolNamesEntitiesCollectionSelector,
  searchSchoolsThunk,
} from 'filters-selections';
import {useDebounce} from 'hooks';
import {IIntegrationsAllFiltersFormValues} from 'admin/integrations/all/components/SidebarIntegrationsAll';
import {useSchoolNameBasedOnAreaSelect} from 'filters-selections/hooks';
import {IWithDisabled} from 'models';

export const SchoolNameFilterBasedOnAreaAndDistrict = ({disabled}: IWithDisabled) => {
  const [options, setOptions] = useState<IOption[]>([]);
  const dispatch = useDispatch();
  const {clearSchoolNameSelectItems} = useSchoolNameBasedOnAreaSelect();
  const {values} = useFormikContext<IIntegrationsAllFiltersFormValues>();

  const schoolNames = useSelector(schoolNamesEntitiesCollectionSelector);
  const loading = useSelector(getLoadingStateSchoolNames);

  useEffect(() => {
    if (loading) {
      setOptions([]);
    } else {
      setOptions(
        schoolNames.length > minLength
          ? schoolNames.map((schoolName) => ({
              value: schoolName.id,
              label: schoolName.name,
            }))
          : []
      );
    }
  }, [schoolNames, loading]);

  const debouncedSearchSchools = useDebounce(
    (nextValue: string, areaIds: string, districtIds: string) =>
      dispatch(
        searchSchoolsThunk({
          schoolNameQuery: nextValue,
          areaIds,
          districtIds,
        })
      ),
    delayTime
  );

  const handleInputChange = useCallback(
    (nextValue: string) => {
      const areaIds = values?.areas ? values?.areas.join(',') : '';
      const districtIds = values.schoolDistricts ? values.schoolDistricts.join(',') : '';

      if (!!nextValue && !!nextValue.trim()) {
        if (options.length > minLength) {
          clearSchoolNameSelectItems();
        }
        return debouncedSearchSchools(nextValue.trim(), areaIds, districtIds);
      }
    },
    [values.areas, values.schoolDistricts, debouncedSearchSchools, options, clearSchoolNameSelectItems]
  );

  return (
    <Accordion heading="School Names">
      <Column>
        <Form.SearchSelectAutocomplete
          isFilter
          multiple
          disableCloseOnSelect
          loading={loading}
          name={FormNameEnum.schoolNames}
          options={options}
          noOptionsText={dynamicSearchMessages.noOptionsSchoolName}
          disabled={loading || disabled}
          inputChangeHandler={handleInputChange}
        />
      </Column>
    </Accordion>
  );
};

import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';

const getFilesState = (state: TStateStore) => state.resources.files;

export const getFileDetailsState = createSelector(getFilesState, (files) => files.details);

export const pageLoadingSelector = createSelector(getFileDetailsState, (details) => details.page.loading);

export const fileDetailsSelector = createSelector(getFileDetailsState, (details) => details.fileDetails);

export const fileDownloadingSelector = createSelector(getFileDetailsState, (details) => details.page.fileDownloading);

import {createSlice} from '@reduxjs/toolkit';
import {learningPathEditInitialState} from './learningPathsEdit.initialState';
import {learningPathEditReducer} from './learningPathsEdit.reducer';
import {learningPathEditExtraReducer} from './learningPathsEditExtra.reducer';

export const learningPathEditSlice = createSlice({
  name: 'learningPathEdit',
  initialState: learningPathEditInitialState,
  reducers: learningPathEditReducer,
  extraReducers: learningPathEditExtraReducer,
});

export const {actions: learningPathEditSliceSitesActions} = learningPathEditSlice;

import {IActionFulfilled, IFetchUserIdAndAreaIdApiResponse, IFetchUserPayload} from 'models';
import {isObject} from 'utils';
import {IUserStoreState} from './IUserStoreState';
import {fetchUserIdAndAreaIdThunk, fetchUserIdThunk} from './thunks';

export const userExtraReducer = {
  //fetchUserIdThunk
  [fetchUserIdThunk.fulfilled]: (state: IUserStoreState, action: IActionFulfilled<IFetchUserPayload, number>) => {
    if (typeof action.payload === 'string') {
      state.loading = false;
      state.error = true;
      return;
    }
    state.profile.id = action.payload;
    state.loading = false;
    state.error = false;
  },
  [fetchUserIdThunk.pending]: (state: IUserStoreState) => {
    state.loading = true;
  },
  [fetchUserIdThunk.rejected]: (state: IUserStoreState) => {
    state.loading = false;
    state.error = true;
  },

  //fetchUserIdAndAreaIdThunk
  [fetchUserIdAndAreaIdThunk.fulfilled]: (
    state: IUserStoreState,
    action: IActionFulfilled<IFetchUserPayload, IFetchUserIdAndAreaIdApiResponse>
  ) => {
    if (typeof action.payload === 'string') {
      state.loading = false;
      state.error = true;
      return;
    }

    if (isObject(action.payload)) {
      const {userId, userArea} = action.payload;
      state.profile.id = userId;
      state.profile.areaId = userArea;
      state.loading = false;
      state.error = false;
    }
  },
  [fetchUserIdAndAreaIdThunk.pending]: (state: IUserStoreState) => {
    state.loading = true;
  },
  [fetchUserIdAndAreaIdThunk.rejected]: (state: IUserStoreState) => {
    state.loading = false;
    state.error = true;
  },
};

import {IUploadPaperAssessmentFormBaseValues} from '../../components';

interface IMapUploadPaperAssessmentPayload {
  values: IUploadPaperAssessmentFormBaseValues;
  programId: number;
  classId: number;
}

export const mapUploadPaperAssessmentPayload = ({
  values,
  programId,
  classId,
}: IMapUploadPaperAssessmentPayload): FormData => {
  const data = new FormData();
  const {file, description} = values;

  data.append('file', file);
  data.append('description', description);
  data.append('programId', programId.toString());
  data.append('programSessionId', classId.toString());

  return data;
};

import {IAction} from '../../../models/interfaces/IAction';
import {createReducer} from 'redux-create-reducer';
import {IUploaderState} from '../models';
import {UploaderActionTypes} from './uploader.types';
import {updateFileUploadPercent, updateUploadFileStatus, markCancelFileUpload, addFileToUpload} from '../utils';

const initialUploadReducer: IUploaderState = {
  error: {},
  notificationManager: {
    isOpen: false,
    files: [],
  },
};

export const uploaderReducer = createReducer<IUploaderState, IAction>(initialUploadReducer, {
  [UploaderActionTypes.SET_UPLOAD_FILE_PROGRESS]: (state, action) => {
    return {
      ...state,
      notificationManager: {
        ...state.notificationManager,
        files: updateFileUploadPercent(state.notificationManager.files, action.payload),
      },
    };
  },
  [UploaderActionTypes.SET_UPLOADED_FILE_STATUS]: (state, action) => {
    return {
      ...state,
      notificationManager: {
        ...state.notificationManager,
        files: updateUploadFileStatus(state.notificationManager.files, action.payload),
      },
    };
  },
  [UploaderActionTypes.SET_CLOSE_NOTIFICATION_MANAGER]: (state) => {
    return {
      ...state,
      notificationManager: {
        ...state.notificationManager,
        isOpen: false,
      },
    };
  },
  [UploaderActionTypes.REMOVE_FILE_FROM_UPLOAD_MANAGER]: (state, action) => {
    return {
      ...state,
      notificationManager: {
        ...state.notificationManager,
        files: state.notificationManager.files.filter((f) => f.id !== action.payload),
      },
    };
  },
  [UploaderActionTypes.CANCEL_FILE_UPLOAD]: (state, action) => {
    return {
      ...state,
      notificationManager: {
        ...state.notificationManager,
        files: markCancelFileUpload(state.notificationManager.files, action.payload),
      },
    };
  },

  [UploaderActionTypes.POST_UPLOAD_FILE_REQUEST]: (state, action) => {
    return {
      ...state,
      isLoading: true,
      notificationManager: {
        ...state.notificationManager,
        files: addFileToUpload(state.notificationManager.files, action.payload),
      },
    };
  },
});

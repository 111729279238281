import React, {useEffect, useMemo} from 'react';
import {useBeforeunload} from 'react-beforeunload';
import {Prompt} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {PageLayoutWithFixedAreas} from 'layout';
import {FilesViewAllHeader} from '../FilesViewAllHeader/FilesViewAllHeader';
import {FilesViewAllFooter} from '../FilesViewAllFooter/FilesViewAllFooter';
import {Button, CircularProgress, Loading, Row, Table, Wrapper} from 'components-lib';
import {VerticalDropdown} from 'admin/resources/files/components/Dropdowns';
import {AdminWithoutStaffRoles, USAAndSuperAdminRoles, usePermissions} from 'permissions';
import {IColumn} from 'models/library/common';
import {IBaseFileItem} from 'models';
import {useFilesAll, useFilesAllStoreActions} from '../../hooks';
import {FileAllTableColumnsEnum} from '../../enums';
import {filesAllMessages, filesDropdownList, getFilteredFilesColumns, mapFilesDropdownList} from '../../utils';

import {IBaseFileItemStatusEnum} from 'admin/resources/enums';

const fileColumns: IColumn[] = [
  {id: 'name', label: 'Name'},
  {id: 'type', label: 'Resource Type'},
  {id: 'username', label: 'User Name'},
  {id: 'status', label: 'Status'},
  {id: 'viewGroup', label: '', align: 'right'},
];

export const FilesViewAllContent = () => {
  const classes = useStyles();
  const {
    loading,
    filesAllIds,
    filesAllEntities,
    fileDownloading,
    downloadingId,
    fetchFilesAllPaged,
    redirectToEditFilePage,
  } = useFilesAll();
  const {clearFiles} = useFilesAllStoreActions();
  const {hasPermission} = usePermissions();

  const shouldFilterColumnsBasedOnUsername = !hasPermission(AdminWithoutStaffRoles);
  const shouldFilterColumnsBasedOnStatus = !hasPermission(USAAndSuperAdminRoles);
  const shouldDisableViewExpiredResource = !hasPermission(USAAndSuperAdminRoles);

  useEffect(() => {
    fetchFilesAllPaged();

    return () => {
      clearFiles();
    };
  }, [fetchFilesAllPaged, clearFiles]);

  useBeforeunload(() => {
    if (fileDownloading) {
      return filesAllMessages.warningUnsavedChanges;
    }
  });

  const columns = useMemo(() => {
    let filteredCols = [...fileColumns];

    if (shouldFilterColumnsBasedOnUsername) {
      filteredCols = getFilteredFilesColumns(filteredCols, FileAllTableColumnsEnum.UserName);
    }

    if (shouldFilterColumnsBasedOnStatus) {
      filteredCols = getFilteredFilesColumns(filteredCols, FileAllTableColumnsEnum.Status);
    }

    return filteredCols;
  }, [shouldFilterColumnsBasedOnUsername, shouldFilterColumnsBasedOnStatus]);

  const rows = useMemo(() => {
    if (!filesAllIds.length) {
      return [];
    }

    return filesAllIds.map((key: string) => {
      const file = filesAllEntities[key] as IBaseFileItem;
      const {title, contentType, contentItemId, username, status} = file;

      const isFileExpired = status === IBaseFileItemStatusEnum.Expired;
      const shouldDisableButtons = isFileExpired && shouldDisableViewExpiredResource;

      const dropdownItems = mapFilesDropdownList(filesDropdownList, isFileExpired);

      return {
        name: title,
        type: contentType,
        username: username ?? '',
        status: status ?? '',
        viewGroup: (
          <Row justify="flex-end">
            <Button.Secondary size="small" clickHandler={() => redirectToEditFilePage(contentItemId)}>
              View
            </Button.Secondary>
            {fileDownloading && downloadingId === contentItemId ? (
              <Wrapper className={classes.progress}>
                <CircularProgress size={20} />
              </Wrapper>
            ) : (
              <VerticalDropdown file={file} dropdownItems={dropdownItems} shouldDisable={shouldDisableButtons} />
            )}
          </Row>
        ),
      };
    });
  }, [
    filesAllIds,
    filesAllEntities,
    redirectToEditFilePage,
    shouldDisableViewExpiredResource,
    downloadingId,
    fileDownloading,
    classes.progress,
  ]);

  return (
    <PageLayoutWithFixedAreas header={<FilesViewAllHeader />} footer={<FilesViewAllFooter />}>
      {loading ? <Loading /> : <Table rows={rows} columns={columns} />}
      <Prompt when={fileDownloading} message={filesAllMessages.warningUnsavedChanges} />
    </PageLayoutWithFixedAreas>
  );
};

const useStyles = makeStyles(() => ({
  progress: {
    padding: 12,
  },
}));

import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormikContext} from 'formik';
import {Subtitle, UploadDraggableArea} from 'admin/components';
import {FormNameEnum} from '../../../enums';
import {Container} from 'components-lib/layout';
import {SingleFileUpload} from 'admin/resources/upload/components';
import {getFileName, IUploadScormFormBaseValues} from 'admin/resources';

export const ScormContentStart = () => {
  const {
    values: {file, displayName},
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IUploadScormFormBaseValues>();

  const fileName = useMemo(() => (file ? getFileName(file.name) : ''), [file]);

  useEffect(() => {
    if (!!file) {
      if (!displayName) {
        setFieldValue(FormNameEnum.displayName, fileName);
        setFieldTouched(FormNameEnum.displayName, true, true);
      }
    }
  }, [file, setFieldValue, fileName, displayName, setFieldTouched]);

  const deleteCallback = useCallback(() => {
    if (displayName) {
      setFieldValue(FormNameEnum.displayName, null);
    }
  }, [displayName, setFieldValue]);

  return (
    <UploadDraggableArea name={FormNameEnum.file}>
      <Container disableGutters>
        <Subtitle withColor={false} as="h3" color="textPrimary" title="Upload File" />
        <SingleFileUpload name={FormNameEnum.file} accept="application/zip" deleteCallback={deleteCallback} />
      </Container>
    </UploadDraggableArea>
  );
};

import React, {FC, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormNameEnum} from 'filters-selections';
import {IWithMultiSelectOption} from 'filters-selections/models';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {fetchFutureProgramYearsThunk, filtersFutureYearsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {IOption, IWithDisabled} from 'models';

interface IProgramYearSelectProps extends IWithMultiSelectOption, IWithDisabled {
  label?: string;
  name?: string;
}

export const FutureProgramYearSelect: FC<IProgramYearSelectProps> = ({
  selectionType,
  disabled,
  label = 'Program Year',
  name = FormNameEnum.futureProgramYears,
}) => {
  const dispatch = useDispatch();
  const futureProgramYears = useSelector(filtersFutureYearsEntitiesCollectionSelector);
  const isMultiSelect = selectionType === 'multi';

  useEffect(() => {
    if (!futureProgramYears.length) {
      dispatch(fetchFutureProgramYearsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options: IOption[] = useMemo(
    () =>
      futureProgramYears.map((futureProgramYear) => ({
        value: futureProgramYear.id,
        label: futureProgramYear.displayText,
      })),
    [futureProgramYears]
  );

  return (
    <ItemWithPlaceholder label={label} hasItems={!!futureProgramYears.length}>
      {isMultiSelect ? (
        <Form.SelectAutocomplete name={name} options={options} multiple disabled={disabled} />
      ) : (
        <Form.Select name={name} options={options} disabled={disabled} />
      )}
    </ItemWithPlaceholder>
  );
};

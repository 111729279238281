import * as Yup from 'yup';
import {errorMessages} from 'utils/constants';
import {FormNameEnum, UploadFormLabelEnum} from '../../enums';
import {IUploadResourceFormBaseValues} from './../../models';
import {getIsDateEarlierFromToday, isValidFormValue} from '../helpers';

export interface IAddWebLinkFormBaseValues extends IUploadResourceFormBaseValues {
  [FormNameEnum.knowledgeSkillsAndAbilities]?: number[];
  [FormNameEnum.webLink]: string;
}

export const addWebLinkFormBaseValidationSchema = Yup.object().shape({
  [FormNameEnum.webLink]: Yup.string()
    .nullable()
    .required(errorMessages.requiredWithInputName(`a ${UploadFormLabelEnum.WebLink}`))
    .url('Web link must be a valid url!'),
  [FormNameEnum.displayName]: Yup.string()
    .nullable()
    .required(errorMessages.requiredWithInputName(`a ${UploadFormLabelEnum.DisplayName}`)),
  [FormNameEnum.briefDescription]: Yup.string().max(500, 'Brief description should be less than 500 characters!'),
  [FormNameEnum.gradeLevels]: Yup.array().of(Yup.number()),
  [FormNameEnum.programs]: Yup.array().of(Yup.number()),
  [FormNameEnum.pillars]: Yup.array().of(Yup.number()),
  [FormNameEnum.knowledgeSkillsAndAbilities]: Yup.array().of(Yup.number()),
  [FormNameEnum.languages]: Yup.array().of(Yup.number()).required('You should select at least one language!'),
  [FormNameEnum.expirationDate]: Yup.date()
    .nullable()
    .typeError('Expected date format: (mm/dd/yyyy)')
    .test('is-date-earlier-than-now', 'The selected date should be in the future.', function () {
      const {expirationDate} = this.parent;

      // If the datepicker is cleaned up, then validation is true
      return getIsDateEarlierFromToday(expirationDate);
    }),
  [FormNameEnum.keyTerms]: Yup.string().nullable().max(250, 'Key Terms should be less than 250 characters!'),
  [FormNameEnum.learningObjectives]: Yup.string()
    .nullable()
    .max(250, 'Learning objectives should be less than 250 characters!'),
  [FormNameEnum.isAccessibleToStudents]: Yup.boolean()
    .nullable()
    .required('You should choose if this file will be accessible to students!'),
  [FormNameEnum.hasUploadPermissions]: Yup.boolean().test(
    'is-checked',
    'You should accept upload file permissions!',
    function () {
      return isValidFormValue(this.parent.hasUploadPermissions);
    }
  ),
});

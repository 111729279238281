import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';

const getUserStateLoading = (state: TStateStore) => state.user.loading;
export const userStateLoadingSelector = createSelector(getUserStateLoading, (loading) => loading);

const getUserStateError = (state: TStateStore) => state.user.error;
export const userStateErrorSelector = createSelector(getUserStateError, (error) => error);

const getUserId = (state: TStateStore) => state.user.profile.id;
export const userIdSelector = createSelector(getUserId, (id) => id);

const getUserArea = (state: TStateStore) => state.user.profile.areaId;
export const userAreaSelector = createSelector(getUserArea, (areaId) => areaId);

const getUserProfile = (state: TStateStore) => state.oidc?.user?.profile;
export const userProfileSelector = createSelector(getUserProfile, (user) => user);

const getUserProfileLoading = (state: TStateStore) => state.oidc?.isLoadingUser;
export const userProfileLoadingSelector = createSelector(getUserProfileLoading, (loading) => loading);

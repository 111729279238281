import {createAsyncThunk} from '@reduxjs/toolkit';
import {learningPathsApi} from 'api';
import {prefix} from './config';
import {toastNotificationManager} from 'toast-notifications';
import {IGetExportLearningPathApiResponse} from 'models';
import {getContentResponseHeaders} from 'admin/resources/files/views/edit/utils';
import {learningPathEditMessages} from '../../utils';

export const exportLearningPathThunk: any = createAsyncThunk<IGetExportLearningPathApiResponse, string>(
  `${prefix} exportLearningPath`,
  async (id: string, {dispatch, rejectWithValue}) => {
    dispatch(toastNotificationManager.createSuccessToastAction(learningPathEditMessages.exporting));

    try {
      const response = await learningPathsApi.getExportLearningPath(id);
      const {mimeType, fileName} = getContentResponseHeaders(response);

      return {
        blob: response.data,
        fileName,
        mimeType,
      };
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

import React, {useCallback, useMemo} from 'react';
import {Prompt} from 'react-router-dom';
import {useBeforeunload} from 'react-beforeunload';
import {makeStyles} from '@material-ui/core';
import {CircularProgress, CopyToClipboardToolTip, Icon, Row, Tooltip, Wrapper} from 'components-lib';
import {useFileEdit, useFileIdParam, useFileType} from '../../hooks';
import {openFileInNewWindow} from 'utils';
import {fileEditMessages} from '../../utils';
import {EntityStatusEnum} from 'enums';
import {useToastNotifications} from 'toast-notifications';

export const FilesDetailsActions = () => {
  const classes = useStyles();
  const {fileId} = useFileIdParam();
  const {isArchive, isWebLink, isScormPackage} = useFileType();
  const {downloadFileById, fileDetails, fileDownloading, status} = useFileEdit();
  const {addToastNotification} = useToastNotifications();

  useBeforeunload(() => {
    if (fileDownloading) {
      return fileEditMessages.warningUnsavedChanges;
    }
  });

  const isResourceExpired = status === EntityStatusEnum.expired;
  const hasShortUrl = !!fileDetails?.url;
  const hasId = !!fileId;
  const shouldDisableViewResource = isArchive || !hasShortUrl;

  const viewLinkClickHandler = useCallback(() => {
    hasShortUrl && openFileInNewWindow(fileDetails?.url);
    addToastNotification(fileEditMessages.openInNewTab);
  }, [fileDetails, hasShortUrl, addToastNotification]);

  const downloadClickHandler = useCallback(() => downloadFileById(fileId), [downloadFileById, fileId]);

  const getHasNotShortUrl = useCallback(() => {
    if (!hasShortUrl && isScormPackage) {
      return fileEditMessages.scormPackage + fileEditMessages.hasNotShortUrl;
    }

    if (!hasShortUrl && !isScormPackage && !isArchive && !isWebLink) {
      return fileEditMessages.file + fileEditMessages.hasNotShortUrl;
    }

    return fileEditMessages.webLink + fileEditMessages.hasNotShortUrl;
  }, [hasShortUrl, isScormPackage, isArchive, isWebLink]);

  const viewResourceTooltip = useMemo(() => {
    if (isResourceExpired) {
      return fileEditMessages.expiredResource;
    }

    if (hasShortUrl && !isArchive) {
      return fileEditMessages.clickToOpen;
    }

    if (hasShortUrl && isArchive) {
      return fileEditMessages.cannotOpenArchive;
    }

    return getHasNotShortUrl();
  }, [isArchive, hasShortUrl, getHasNotShortUrl, isResourceExpired]);

  const copyTooltip = useMemo(() => {
    if (isResourceExpired) {
      return fileEditMessages.expiredResource;
    }

    if (hasShortUrl) {
      return fileEditMessages.clickToCopy;
    }

    return getHasNotShortUrl();
  }, [hasShortUrl, getHasNotShortUrl, isResourceExpired]);

  const copyToClipboardText = !isResourceExpired ? fileDetails?.url : '';

  return (
    <Row justify="flex-end">
      <Tooltip title={viewResourceTooltip}>
        <Icon.Button clickHandler={viewLinkClickHandler} disabled={shouldDisableViewResource || isResourceExpired}>
          <Icon.ViewResourceIcon disabled={shouldDisableViewResource || isResourceExpired} />
        </Icon.Button>
      </Tooltip>
      {!isWebLink && (
        <Tooltip title={fileEditMessages.clickToDownload}>
          <Icon.Button disabled={fileDownloading} clickHandler={downloadClickHandler}>
            {fileDownloading ? (
              <Wrapper className={classes.progress}>
                <CircularProgress size={20} />
              </Wrapper>
            ) : (
              <Icon.DownloadIcon disabled={!hasId} />
            )}
          </Icon.Button>
        </Tooltip>
      )}

      <CopyToClipboardToolTip
        text={copyToClipboardText}
        title={copyTooltip}
        successNotificationText={fileEditMessages.copied}
      >
        <div>
          <Icon.Button disabled={!hasShortUrl || isResourceExpired}>
            <Icon.CopyLinkSmallIcon disabled={!hasShortUrl || isResourceExpired} />
          </Icon.Button>
        </div>
      </CopyToClipboardToolTip>
      <Prompt when={fileDownloading} message={fileEditMessages.warningUnsavedChanges} />
    </Row>
  );
};

const useStyles = makeStyles((theme) => ({
  progress: {
    padding: theme.spacing(0, 1),
  },
}));

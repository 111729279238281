export const mockKSAsList = [
  {
    id: 1,
    name: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis',
    displayText: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis',
  },
  {
    id: 2,
    name: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis',
    displayText: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis',
  },
  {
    id: 3,
    name: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis',
    displayText: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis',
  },
];

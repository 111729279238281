import React, {FC} from 'react';
import {IconButton as MUIIconButton, makeStyles} from '@material-ui/core';
import {IWithChildren, IWithDisabled} from 'models/common';
interface IIconButtonProps extends IWithChildren, IWithDisabled {
  clickHandler?: () => void;
  ariaLabel?: string;
  edge?: 'start' | 'end';
  size?: 'small' | 'medium';
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
}

export const IconButton: FC<IIconButtonProps> = ({
  children,
  disabled,
  clickHandler,
  ariaLabel = 'info',
  edge = 'start',
  color,
  size,
}) => {
  const {root} = useStyles();
  return (
    <MUIIconButton
      onClick={clickHandler}
      disabled={disabled}
      aria-label={ariaLabel}
      edge={edge}
      color={color}
      size={size}
      className={root}
    >
      {children}
    </MUIIconButton>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
}));

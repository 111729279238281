import {makeStyles, Typography} from '@material-ui/core';
import {IComponentWithChildren, IComponentWithClassName} from 'models';
import React from 'react';

interface IParagraphProps extends IComponentWithChildren, IComponentWithClassName {
  align?: 'inherit' | 'center';
  color?: 'primary' | 'secondary' | 'textPrimary' | 'error';
  variant?: 'body1' | 'body2';
  withMargin?: boolean;
  classes?: {[key: string]: any};
}

export function Paragraph({
  children,
  color,
  align = 'inherit',
  variant = 'body1',
  withMargin = false,
  className,
}: IParagraphProps) {
  const classes = useStyles(withMargin);

  return (
    <div className={classes.root}>
      <Typography variant={variant} color={color} align={align} className={className}>
        {children}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: (withMargin: boolean) => {
    if (withMargin) {
      const spacing = theme.spacing(2);
      return {
        marginTop: spacing,
        marginBottom: spacing,
      };
    }
  },
}));

import {makeStyles} from '@material-ui/core';
import {Warning} from '@material-ui/icons';
import React from 'react';

export function WarningIcon() {
  const classes = useStyles();
  return <Warning fontSize="small" color="error" classes={{root: classes.root}} />;
}

const useStyles = makeStyles((theme) => ({root: {color: theme.palette.info.main}}));

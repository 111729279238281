import React from 'react';
import {makeStyles} from '@material-ui/core';
import {IBuilderDragItem} from 'admin/library/models';
import {ListInfinite, Loading, Row, Text} from 'components-lib';
import {TBuilderItemBase} from 'models';
import {PoolItem} from '../PoolItem/PoolItem';

interface IPoolItemsListProps {
  hasMorePages: boolean;
  loading: boolean;
  builderItemsBase: TBuilderItemBase[];
  builderItems: any[];
  loadMoreHandler: () => void;
  dropHandler: (position: number) => (item: IBuilderDragItem) => void;
}
export function PoolItemsList({
  hasMorePages,
  loading = false,
  builderItemsBase,
  builderItems,
  loadMoreHandler,
  dropHandler,
}: IPoolItemsListProps) {
  const classes = useStyles();

  const hasBuilderItems = builderItemsBase.length > 0;
  const loadingSize = hasBuilderItems ? 20 : 40;

  return (
    <ListInfinite hasMorePages={hasMorePages} loadMoreHandler={loadMoreHandler} loading={loading}>
      {!!builderItemsBase.length &&
        builderItemsBase.map(({id, questionType}, idx) => (
          <PoolItem key={idx} itemId={id} dropHandler={dropHandler} builderItems={builderItems} type={questionType} />
        ))}

      <Row justify="center" align="flex-start" classes={{root: classes.loadingContainer}}>
        {loading && <Loading halfHeight={!hasBuilderItems} size={loadingSize} />}
        {loading && hasBuilderItems && <Text.Caption>Loading more items...</Text.Caption>}
      </Row>
    </ListInfinite>
  );
}

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    marginBottom: theme.spacing(2),
  },
}));

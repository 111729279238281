import {AddIntegrationLabelEnum} from 'admin/integrations/add-integration/enums';

export const getDefaultPreviewSecondColumnList = ({
  integrationName,
  schoolDistrictStringValue,
  email,
  googleDriveSourceFolderLink,
}) => {
  return [
    {
      label: AddIntegrationLabelEnum.IntegrationName,
      value: integrationName ?? '',
      options: {
        withForm: false,
        disabled: true,
      },
    },
    {
      label: AddIntegrationLabelEnum.SchoolDistrict,
      value: schoolDistrictStringValue ?? '',
      options: {
        withForm: false,
        disabled: true,
      },
    },
    {
      label: AddIntegrationLabelEnum.ServiceEmailAdress,
      value: email ?? '',
      options: {
        withForm: false,
        disabled: true,
      },
    },
    {
      label: AddIntegrationLabelEnum.GoogleDriveSourceFolderLink,
      value: googleDriveSourceFolderLink ?? '',
      options: {
        withForm: false,
        disabled: true,
      },
    },
  ];
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {prefix} from './config';
import {programsApi} from 'api';
import {IGetPageOfProgramsResponse, IGetPageOfProgramsPayload} from 'models';
import {toastNotificationManager} from 'toast-notifications';

export const fetchProgramsPagedThunk: any = createAsyncThunk<IGetPageOfProgramsResponse, IGetPageOfProgramsPayload>(
  `${prefix} fetchProgramsPaged`,
  async (payload: IGetPageOfProgramsPayload, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await programsApi.getActiveProgramsPaged(payload);

      return parsedBody;
    } catch (err) {
      dispatch(
        toastNotificationManager.createErrorToastAction(err.parsedBody || err.statusText || 'Unknown error occurred.')
      );
      return rejectWithValue({error: err});
    }
  }
);

import {createAsyncThunk} from '@reduxjs/toolkit';
import {filtersApi} from 'api';
import {IBindBaseEntity} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchAdministrationsThunk: any = createAsyncThunk<IBindBaseEntity[]>(
  `${prefix} fetchAdministrations`,
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await filtersApi.getAdministrations();
      return parsedBody;
    } catch (error) {
      dispatch(
        toastNotificationManager.createErrorToastAction(
          error.parsedBody || error.statusText || 'Failed to fetch administrations. Unknown error occurred.'
        )
      );
      return rejectWithValue(error);
    }
  }
);

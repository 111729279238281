import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {SingleFileUpload} from 'admin/resources/upload/components';
import {FormNameEnum} from 'enums/form';
import {zipMimeType} from 'admin/resources';
import {Row} from 'components-lib';
import {useFormikContext} from 'formik';
import {IUploadPaperAssessmentFormBaseValues} from '../UploadPaperAssessmentForm/uploadPaperAssessmentsBaseConfig';

interface IUploadPaperAssessmentDroppableAreaProps {
  isDirty: boolean;
  setIsDirtyCallback: Dispatch<SetStateAction<boolean>>;
}

export const UploadPaperAssessmentDroppableArea: FC<IUploadPaperAssessmentDroppableAreaProps> = ({
  isDirty,
  setIsDirtyCallback,
}) => {
  const {dirty, isValid} = useFormikContext<IUploadPaperAssessmentFormBaseValues>();

  useEffect(() => {
    if (dirty && isValid && !isDirty) {
      setIsDirtyCallback(true);
    } else if (!isValid) {
      setIsDirtyCallback(false);
    }
  }, [dirty, isDirty, isValid, setIsDirtyCallback]);

  return (
    <Row>
      <SingleFileUpload
        disabled={false}
        fullHeight={false}
        name={FormNameEnum.file}
        accept={zipMimeType}
        fullWidth
        position="horizontal"
        justify="flex-start"
        title="Select File"
      />
    </Row>
  );
};

export const paths = {
  root: '/',
  adminPortal: {
    root: '/admin',
    dashboard: '/dashboard',
    pathways: '/pathways',
    resource: '/resource',
    upload: '/upload',
    itemImport: {
      root: '/item-import',
    },
    resources: {
      root: '/resources',
      files: '/files',
      learningPaths: {
        root: '/learning-paths',
        edit: '/edit',
        create: '/create',
        all: '/all',
      },
      googleSites: {
        root: '/google-sites',
        details: '/details',
        all: '/all',
      },
      edit: '/edit',
      upload: {
        root: '/upload',
        file: '/file',
        scorm: '/scorm',
        learningPath: '/learning-path',
        webLink: '/web-link',
      },
    },
    library: {
      root: '/library',
      reports: '/reports',
      instruments: '/instruments',
      build: '/build',
      create: '/create',
      items: '/items',
      sessions: '/sessions',
      resources: '/resources',
      preview: '/preview',
      previewWithAnswers: '/preview-answers',
      edit: '/edit',
      editAnswerStack: '/edit-stack',
      printPreview: '/print-preview',
    },
    programs: {
      root: '/programs',
      details: '/details',
      masterInstruments: '/master-instruments',
      reports: '/reports',
      exports: '/exports',
      class: '/class',
      classReports: '/class-reports',
      classExports: '/class-exports',
      classPaperAssessments: '/class-paper-assessments',
    },
    integrations: {
      root: '/integrations',
      details: '/details',
      all: '/all',
      add: '/add',
    },
  },
  survey: {
    root: '/survey',
  },
  surveyGeneral: {
    root: '/survey-general',
  },
  fileDownload: {
    root: '/s',
    notFound: '/not-found',
  },
  fileNotFound: {
    root: '/file',
    notFound: '/not-found',
  },
  noPermission: '/no-permissions',
  error: '/error',
  rest: '*',
  withId: '/:id',
  withItemId: '/:itemId',
  withAnswerStackId: '/:answerStackId',
  withProgramId: '/:programId',
  withClassId: '/:classId',
  withInstrumentId: '/:instrumentId',
  withShortenerUrlId: '/:id',
  withIntegrationId: '/:integrationId',
  authenticationCallback: '/authentication/callback',
};

export const pages = {
  adminPortal: {
    library: `${paths.adminPortal.root}${paths.adminPortal.library.root}`,
    resources: `${paths.adminPortal.root}${paths.adminPortal.resources.root}`,
    upload: {
      root: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.upload.root}`,
      file: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.upload.root}${paths.adminPortal.resources.upload.file}`,
      learningPath: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.upload.root}${paths.adminPortal.resources.upload.learningPath}`,
      webLink: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.upload.root}${paths.adminPortal.resources.upload.webLink}`,
      scorm: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.upload.root}${paths.adminPortal.resources.upload.scorm}`,
    },
    files: {
      root: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.files}`,
      details: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.files}${paths.withId}`,
      detailsLink: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.files}`,
      edit: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.files}${paths.adminPortal.resources.edit}${paths.withId}`,
      editLink: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.files}${paths.adminPortal.resources.edit}`,
    },
    googleSites: {
      root: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.googleSites.root}`,
      all: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.googleSites.root}${paths.adminPortal.resources.googleSites.all}`,
      details: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.googleSites.root}${paths.adminPortal.resources.googleSites.details}${paths.withId}`,
      detailsLink: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.googleSites.root}${paths.adminPortal.resources.googleSites.details}`,
    },
    integrations: {
      root: `${paths.adminPortal.root}${paths.adminPortal.integrations.root}`,
      all: `${paths.adminPortal.root}${paths.adminPortal.integrations.root}${paths.adminPortal.integrations.all}`,
      details: `${paths.adminPortal.root}${paths.adminPortal.integrations.root}${paths.adminPortal.integrations.details}${paths.withIntegrationId}`,
      detailsLink: `${paths.adminPortal.root}${paths.adminPortal.integrations.root}${paths.adminPortal.integrations.details}`,
      add: `${paths.adminPortal.root}${paths.adminPortal.integrations.root}${paths.adminPortal.integrations.add}`,
    },
    learningPaths: {
      root: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.learningPaths.root}`,
      all: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.learningPaths.root}${paths.adminPortal.resources.learningPaths.all}`,
      create: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.learningPaths.root}${paths.adminPortal.resources.learningPaths.create}`,
      edit: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.learningPaths.root}${paths.adminPortal.resources.learningPaths.edit}${paths.withId}`,
      editLink: `${paths.adminPortal.root}${paths.adminPortal.resources.root}${paths.adminPortal.resources.learningPaths.root}${paths.adminPortal.resources.learningPaths.edit}`,
    },
    items: {
      root: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.items}`,
      detailsLink: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.items}`,
      details: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.items}${paths.withId}`,
      build: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.items}${paths.adminPortal.library.build}`,
      create: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.items}${paths.adminPortal.library.build}${paths.adminPortal.library.create}`,
      edit: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.items}${paths.adminPortal.library.build}${paths.withId}`,
      editAnswerStack: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.items}${paths.adminPortal.library.build}${paths.adminPortal.library.editAnswerStack}${paths.withId}${paths.withAnswerStackId}`,
      editLink: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.items}${paths.adminPortal.library.build}`,
      editAnswerStackLink: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.items}${paths.adminPortal.library.build}${paths.adminPortal.library.editAnswerStack}`,
    },
    programs: {
      root: `${paths.adminPortal.root}${paths.adminPortal.programs.root}`,
      details: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.details}${paths.withProgramId}`,
      detailsLink: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.details}`,

      masterInstruments: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.masterInstruments}${paths.withProgramId}`,
      masterInstrumentsLink: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.masterInstruments}`,

      reports: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.reports}${paths.withProgramId}`,
      reportsLink: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.reports}`,

      exports: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.exports}${paths.withProgramId}`,
      exportsLink: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.exports}`,

      class: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.class}${paths.withProgramId}${paths.withClassId}`,
      classLink: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.class}`,

      classReports: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.classReports}${paths.withProgramId}${paths.withClassId}`,
      classReportsLink: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.classReports}`,

      classExports: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.classExports}${paths.withProgramId}${paths.withClassId}`,
      classExportsLink: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.classExports}`,

      classPaperAssessments: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.classPaperAssessments}${paths.withProgramId}${paths.withClassId}`,
      classPaperAssessmentsLink: `${paths.adminPortal.root}${paths.adminPortal.programs.root}${paths.adminPortal.programs.classPaperAssessments}`,
    },
    instruments: {
      all: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.instruments}`,
      detailsRoot: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.instruments}`,
      details: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.instruments}${paths.withId}`,
      build: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.instruments}${paths.adminPortal.library.build}${paths.withId}`,
      buildLink: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.instruments}${paths.adminPortal.library.build}`,
      preview: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.instruments}${paths.adminPortal.library.preview}${paths.withId}`,
      previewWithAnswers: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.instruments}${paths.adminPortal.library.previewWithAnswers}${paths.withId}`,
      previewLink: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.instruments}${paths.adminPortal.library.preview}`,
      previewWithAnswersLink: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.instruments}${paths.adminPortal.library.previewWithAnswers}`,
      printPreview: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.instruments}${paths.adminPortal.library.printPreview}${paths.withId}`,
      printPreviewLink: `${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.instruments}${paths.adminPortal.library.printPreview}`,
    },

    itemImport: {
      root: `${paths.adminPortal.root}${paths.adminPortal.itemImport.root}`,
    },
  },
  survey: {
    takerLink: `${paths.survey.root}`,
    taker: `${paths.survey.root}${paths.withProgramId}${paths.withInstrumentId}`,
  },
  surveyGeneral: {
    takerLink: `${paths.surveyGeneral.root}`,
    taker: `${paths.surveyGeneral.root}${paths.withInstrumentId}`,
  },
  fileDownload: {
    download: `${paths.fileDownload.root}${paths.withShortenerUrlId}`,
    downloadLink: `${paths.fileDownload.root}${paths.withShortenerUrlId}`,
    notFound: `${paths.fileDownload.root}${paths.fileDownload.notFound}`,
  },
  fileNotFound: {
    root: `${paths.fileNotFound.root}${paths.fileNotFound.notFound}`,
  },
  error: paths.error,
  noPermission: paths.noPermission,
  authentication: {
    callback: paths.authenticationCallback,
  },
};

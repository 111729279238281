import React, {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {SortByDropdown} from 'admin/components';
import {useAppDispatch} from 'hooks';
import {programClassInstrumentsSortOrderSelector, programClassInstrumentsActions as actions} from '../../store';
import {IProgramClassInstrumentsSortOrder} from 'models/programs';
import {getProgramClassInstrumentsMenuItemsList, programClassInstrumentsOrderingMap} from 'admin/programs/details';

export const ProgramsClassInstrumentsSortBy: FC = () => {
  const dispatch = useAppDispatch();
  const sortOrder = useSelector(programClassInstrumentsSortOrderSelector);

  const handleItemClick = useCallback(
    (sortOrder: IProgramClassInstrumentsSortOrder) => {
      dispatch(actions.setSortOrderProgramClasseInstruments(sortOrder));
    },
    [dispatch]
  );

  return (
    <SortByDropdown
      label="Sort By: "
      handleItemClick={handleItemClick}
      getMenuItemsList={getProgramClassInstrumentsMenuItemsList}
      sortOrder={sortOrder}
      map={programClassInstrumentsOrderingMap}
    />
  );
};

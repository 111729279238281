import React, {FC, Fragment, useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {CircularProgress, Dropdown, Wrapper} from 'components-lib';
import {LearningPathDropdownEnum} from '../../enums';
import {useLearningPaths} from '../../hooks';

interface ILearningPathDropdown {
  id: string;
  openModalCloneClickHandler: () => void;
}

export const LearningPathsDropdown: FC<ILearningPathDropdown> = ({id, openModalCloneClickHandler}) => {
  const classes = useStyles();
  const {fetchExportLearningPath, exporting, cloning, selectedLearningPathId} = useLearningPaths();

  const isSelectedId = selectedLearningPathId === id;
  const showProgress = (exporting || cloning) && isSelectedId;

  const exportClickHandler = useCallback(() => fetchExportLearningPath(id), [id, fetchExportLearningPath]);

  const dropdownOptions = useMemo(
    () => [
      {
        text: LearningPathDropdownEnum.Clone,
        clickHandler: () => openModalCloneClickHandler(),
      },
      {
        text: LearningPathDropdownEnum.Export,
        clickHandler: () => exportClickHandler(),
        disabled: exporting,
      },
    ],
    [exportClickHandler, openModalCloneClickHandler, exporting]
  );

  return (
    <Fragment>
      {showProgress ? (
        <Wrapper className={classes.progress}>
          <CircularProgress size={20} />
        </Wrapper>
      ) : (
        <Dropdown.ThreeDots items={dropdownOptions} disabled={false} />
      )}
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  progress: {
    padding: 12,
  },
}));

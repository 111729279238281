import {createLearningPathThunk} from './thunks';
import {ILearningPathsCreateStoreState} from './ILearningPathsCreateStoreState';

export const learningPathsCreateExtraReducer = {
  // createLearningPathThunk
  [createLearningPathThunk.fulfilled]: (state: ILearningPathsCreateStoreState) => {
    state.page.loading = false;
  },
  [createLearningPathThunk.pending]: (state: ILearningPathsCreateStoreState) => {
    state.page.loading = true;
  },
  [createLearningPathThunk.rejected]: (state: ILearningPathsCreateStoreState) => {
    state.page.loading = false;
  },
};

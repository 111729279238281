import {SortingOrderEnum} from 'enums';
import {PaperAssessmentsSortDropdownNameEnum, ProgramClassPaperAssessmentsOrderingEnum} from 'enums/paper-assessments';
import {IProgramClassesPaperAssessmentsSortOrder} from 'models';

export const getPaperAssessmentsMenuItemsList = (
  itemClickHandler: () => void,
  handleItemClick: (handleItemSortObj: IProgramClassesPaperAssessmentsSortOrder) => void
) => {
  const onClick = (
    itemClickHandler: () => void,
    handleItemClick: (handleItemSortObj: IProgramClassesPaperAssessmentsSortOrder) => void,
    handleItemSortObj: IProgramClassesPaperAssessmentsSortOrder
  ) => {
    return () => {
      itemClickHandler();
      handleItemClick(handleItemSortObj);
    };
  };

  return [
    {
      name: PaperAssessmentsSortDropdownNameEnum.NAME_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramClassPaperAssessmentsOrderingEnum.Name,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: PaperAssessmentsSortDropdownNameEnum.NAME_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramClassPaperAssessmentsOrderingEnum.Name,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: PaperAssessmentsSortDropdownNameEnum.CREATED_NEWEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramClassPaperAssessmentsOrderingEnum.Created,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: PaperAssessmentsSortDropdownNameEnum.CREATED_OLDEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramClassPaperAssessmentsOrderingEnum.Created,
        sort: SortingOrderEnum.ASC,
      }),
    },
  ];
};

import {makeStyles} from '@material-ui/core';
import React, {FC} from 'react';

import {FooterText} from './FooterText';
import {FooterLogo} from './FooterLogo';

interface IFooterProps {
  footerText: string;
  footerUrl: string;
}

const Footer: FC<IFooterProps> = ({footerText, footerUrl}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FooterLogo footerUrl={footerUrl} />
      <FooterText footerText={footerText} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default Footer;

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, {useRef} from 'react';
import State from '../State';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface IOption {
  value: string;
  label: string;
}

interface IProps {
  id?: string;
  name: string;
  options: IOption[];
  placeholder?: string;
  label?: string;
  isMultipleSelect?: boolean;
  disabled?: boolean;
  handleChange?: (name: string, value: IOption[]) => void;
}

export const AutocompleteWithSelect = ({
  id,
  name,
  options,
  label,
  placeholder,
  isMultipleSelect,
  disabled,
  handleChange,
}: IProps) => {
  const onChange = (event: any, opt: IOption[], form: any) => {
    form.setFieldValue(name, opt);

    if (typeof handleChange === 'function') {
      handleChange(name, opt);
    }
  };

  const getOptionSelected = (option: IOption, chosenValue: IOption) => {
    return option.value === chosenValue.value;
  };

  const textInput = useRef(null);

  return (
    <State>
      {(form) => (
        <React.Fragment>
          <Autocomplete
            multiple={isMultipleSelect === false ? isMultipleSelect : true}
            id={id}
            options={options}
            disabled={disabled ? disabled : false}
            disableCloseOnSelect
            value={form.values[name]}
            onChange={(event: any, opt: IOption[]) => onChange(event, opt, form)}
            getOptionLabel={(option: IOption) => option.label}
            getOptionSelected={(option: IOption, value: any) => getOptionSelected(option, value)}
            renderOption={(option: IOption, {selected}) => (
              <React.Fragment>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                {option.label}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" inputRef={textInput} label={label} placeholder={placeholder} />
            )}
          />
        </React.Fragment>
      )}
    </State>
  );
};

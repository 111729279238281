import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core';

import {PreviewEditor} from 'components-lib';
import {PaperNodeTypeEnum} from '../../enums/PaperNodeTypeEnum';
import {IPaperQuestion} from '../../models/IPaperQuestion';
import {ReactComponent as SVGCheckbox} from '../../resources/checkbox.svg';

interface IPaperSurveyBaseItemProps {
  text: string;
  htmlStr?: string;
  id: string;
  choices: Array<any>;
  afterMount?: (questionInfo: IPaperQuestion) => void;
  withId?: boolean;
}

const PaperSurveyBaseItem = ({text, htmlStr, id, choices, afterMount, withId = !!id}: IPaperSurveyBaseItemProps) => {
  const classes = useStyles({withId});
  const ref2 = useRef(null);

  useEffect(() => {
    if (!afterMount) return;
    afterMount({
      type: PaperNodeTypeEnum.checkbox,
      heights: {
        total: ref2?.current.clientHeight,
        headerArea: 0,
        textArea: 0,
        questionAreas: [0],
      },
      id: id,
      text: text,
      choices: choices,
      htmlStr: htmlStr,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const questionTextRow = (
    <tr className={classes.textRow}>
      <td className={classes.leftQuestionIdCell}>{!!id && id}</td>
      <td className={classes.questionTextCell}>{<PreviewEditor htmlStr={htmlStr} />}</td>
    </tr>
  );

  const answerRows = choices.map((item) => {
    const choiceId = `${item.value}`;

    return (
      <tr key={id}>
        <td className={classes.answerIdCell}>{choiceId}</td>
        <td>
          <div className={classes.answerWrapper}>
            <div>
              <SVGCheckbox className={classes.svgCheckbox} />
            </div>
            <div>{item.text}</div>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className={classes.root} ref={ref2}>
      <table className={classes.table}>
        <tbody>
          {questionTextRow}
          {!!id && choices?.length && answerRows}
        </tbody>
      </table>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    padding: '0.75em 0',
    pageBreakInside: 'avoid',
  },
  table: {
    width: '100%',
    textAlign: 'left',
    borderCollapse: 'collapse',
  },
  textRow: {
    borderBottom: 'solid lightgray 1px',
    minHeight: '10.5em',
  },
  questionTextCell: {
    fontWeight: 'bold',
    paddingLeft: '0.5em',
  },
  leftQuestionIdCell: ({withId}: Pick<IPaperSurveyBaseItemProps, 'withId'>) => {
    const styles: any = {
      minWidth: '5%',
      width: '6%',
      verticalAlign: 'middle',
      fontWeight: 'normal',
      fontSize: '75%',
      textAlign: 'left',
      borderRight: 'solid lightgray 1px',
    };

    if (withId) {
      return {
        ...styles,
        padding: '0em',
        '&:before': {
          content: '"#"',
        },
        '&:after': {
          content: '"#"',
        },
      };
    }

    return styles;
  },
  svgCheckbox: {
    verticalAlign: 'middle',
    fill: 'gray',
    opacity: 0.8,
    margin: '0 4px',
  },
  answerIdCell: {
    '&:before': {
      content: '"*"',
    },
    '&:after': {
      content: '"*"',
    },
    minWidth: '5%',
    width: '6%',
    verticalAlign: 'middle',
    paddingRight: '1em',
    fontWeight: 'normal',
    fontSize: '75%',
    textAlign: 'left',
    borderRight: 'solid lightgray 1px',
  },
  answerWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default PaperSurveyBaseItem;

import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';

const getLearningPathsState = (state: TStateStore) => state.resources.learningPaths;

export const getLearningPathsEditState = createSelector(getLearningPathsState, (learningPaths) => learningPaths.edit);

export const loadingLearningPathSelector = createSelector(
  getLearningPathsEditState,
  (learningPathState) => learningPathState.page.loading
);

export const learningPathExportingSelector = createSelector(
  getLearningPathsEditState,
  (learningPathState) => learningPathState.page.exporting
);

export const learningPathDetailsSelector = createSelector(
  getLearningPathsEditState,
  (learningPathState) => learningPathState.learningPath
);

export const learningPathSessionsSelector = createSelector(
  learningPathDetailsSelector,
  (learningPath) => learningPath.sessions
);

import {IFilesAllFilter} from 'models/resources';
import {IFilesAllFiltersFormValues} from '../components/FilesViewAllSidebar/config';
import {FilesAllFilterNamesEnum} from '../enums';

export const mapFilesAllFilters = (formValues: IFilesAllFiltersFormValues): IFilesAllFilter => ({
  [FilesAllFilterNamesEnum.SEARCH_BY_QUERY]: formValues.query,
  [FilesAllFilterNamesEnum.FILE_TYPES]: formValues.fileTypes,
  [FilesAllFilterNamesEnum.PROGRAMS]: formValues.programs,
  [FilesAllFilterNamesEnum.GRADE_LEVELS]: formValues.gradeLevels,
  [FilesAllFilterNamesEnum.PILLARS]: formValues.pillars,
  [FilesAllFilterNamesEnum.COMPETENCIES]: formValues.competencies,
  [FilesAllFilterNamesEnum.KSAS]: formValues.knowledgeSkillsAndAbilities,
  [FilesAllFilterNamesEnum.IS_ACCESSIBLE_TO_STUDENTS]: formValues.isAccessibleToStudents,
  [FilesAllFilterNamesEnum.PUBLISHED_BY]: formValues.publishedBy,
  [FilesAllFilterNamesEnum.LANGUAGES]: formValues.languages,
  [FilesAllFilterNamesEnum.FILE_STATUSES]: formValues.fileStatuses,
});

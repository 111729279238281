import * as Yup from 'yup';
import {FormNameEnum} from 'filters-selections';
export interface IProgramClassesFilterSidebarForm {
  [FormNameEnum.programYears]: number[];
  [FormNameEnum.educators]: number[];
  [FormNameEnum.districts]: string[];
  [FormNameEnum.schoolNames]: number[];
  [FormNameEnum.areas]: number[];
  [FormNameEnum.startDate]: Date | null;
  [FormNameEnum.endDate]: Date | null;
  [FormNameEnum.search]: string;
  [FormNameEnum.onlyVirtualClass]: boolean | null;
}

export const sidebarProgramClassesValidationSchema = Yup.object().shape({
  [FormNameEnum.programYears]: Yup.array().of(Yup.number()),
  [FormNameEnum.educators]: Yup.array().of(Yup.number()),
  [FormNameEnum.districts]: Yup.array().of(Yup.string()),
  [FormNameEnum.schoolNames]: Yup.array().of(Yup.number()),
  [FormNameEnum.areas]: Yup.array().of(Yup.number()),
  [FormNameEnum.startDate]: Yup.date().nullable().default(undefined),
  [FormNameEnum.endDate]: Yup.date()
    .nullable()
    .default(undefined)
    .test('is-end-date-earlier-than-start-date', 'The start date should be before the end date!', function () {
      const {endDate, startDate} = this.parent;

      if (startDate instanceof Date && endDate instanceof Date && !!endDate) {
        return isBefore(startDate, endDate);
      }

      return true;
    }),
  [FormNameEnum.search]: Yup.string(),
});

function isBefore(startDate: Date, endDate: Date): boolean {
  if (startDate > endDate) {
    return false;
  }
  return true;
}

import React, {useCallback, useEffect, useMemo} from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import {useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {SubtitlePageViewHeader} from 'admin/components';
import {Button, Icon, Loading, PageViewHeader, PaginationLocal, Row, Table, Tag, Wrapper} from 'components-lib';
import {ProgramsClassesSortBy, ProgramClassesAllSidebarFilters} from './components';
import {PageLayoutTwoCol, PageLayoutWithFixedAreas} from 'layout';
import {FormNameEnum} from 'filters-selections';
import {useNavigate} from 'hooks';
import {IBindBaseEntity, IBindBaseEnumEntity, IColumn, IProgramClassBase} from 'models';
import {pages} from 'paths';
import {cloneDeep, dateFormat, toNumber} from 'utils';
import {ClassTypes} from '../../enums';
import {useProgram} from '../../hooks';
import {useProgramClasses} from './hooks';

export const ProgramClassesView = () => {
  const classes = useStyles();
  const {push} = useNavigate();
  const {programId} = useParams<{programId: string}>();
  const {program, fetchProgram} = useProgram(toNumber(programId));
  const {
    loading,
    programClasses,
    filter,
    filterEntitiesCollection,
    totalCount,
    perPage,
    currentPage,
    fetchProgramClasses,
    fetchFilterEntities,
    setFilter,
    setCurrentPage,
    setPerPage,
  } = useProgramClasses(toNumber(programId));

  useEffect(() => {
    fetchProgram(true);
  }, [fetchProgram]);

  useEffect(() => {
    fetchFilterEntities();
  }, [fetchFilterEntities]);

  useEffect(() => {
    fetchProgramClasses();
  }, [fetchProgramClasses]);

  const showViewGroup = useCallback(
    (id: number, isVirtualClass: boolean, virtualClassUrl: string) => {
      const hasVirtualClassUrl = !!virtualClassUrl;

      const toolTipText = !hasVirtualClassUrl
        ? 'It might take some time to see the virtual class link. Please try again later.'
        : '';

      return (
        <Wrapper className={isVirtualClass ? classes.viewGroup : ''}>
          <Button.Secondary
            size="small"
            clickHandler={() => push(`${pages.adminPortal.programs.classLink}/${programId}/${id}`)}
          >
            View
          </Button.Secondary>
          {isVirtualClass && (
            <Button.CopyLink
              title={!hasVirtualClassUrl ? toolTipText : 'Copy Link'}
              text={virtualClassUrl}
              disabled={!hasVirtualClassUrl}
            />
          )}
        </Wrapper>
      );
    },
    [programId, push, classes]
  );

  const rows = useMemo(() => {
    return programClasses.result.map((id) => {
      const entry: IProgramClassBase = programClasses.entities.byId[id];
      const educatorsNames = entry?.educators.map((educator) => <div key={educator?.id}>{educator?.name}</div>);
      const actionButtons = showViewGroup(entry.id, entry.isVirtualClass, entry.virtualClassUrl);

      const virtualClassIcon = entry.isVirtualClass ? (
        <Row justify="center" align="center">
          <Icon.VirtualClassIcon />
        </Row>
      ) : (
        ''
      );

      return {
        code: entry.id,
        mtIcon: virtualClassIcon,
        virtualUrl: entry.virtualClassUrl,
        name: entry.name,
        bcrmClassNumber: entry.bcrmClassNumber,
        bcrmProgramYear: entry.bcrmProgramYear?.replace('-', ' - '),
        educatorsNames,
        schoolName: entry.schoolName,
        studentCount: entry.studentCount,
        startDate: entry.endDate ? <Moment>{entry.startDate}</Moment> : '',
        endDate: entry.endDate ? <Moment>{entry.endDate}</Moment> : '',
        viewBtn: actionButtons,
      };
    });
  }, [programClasses.entities.byId, programClasses.result, showViewGroup]);

  const headerSubheading = useMemo(
    () => program && <SubtitlePageViewHeader> / {program?.name}</SubtitlePageViewHeader>,
    [program]
  );

  const renderSortOrder = useMemo(() => <ProgramsClassesSortBy />, []);

  const appliedFilters = useMemo(() => {
    const chips: any[] = [];

    const deleteHandler = (prop: string, item: number | string) => {
      const updated = cloneDeep(filter);
      const idx = filter[prop].indexOf(item);

      if (idx > -1) {
        updated[prop].splice(idx, 1);
      }

      setFilter(updated);
    };

    if (!!filter.searchByName) {
      chips.push(
        <Tag
          withFilterChip
          key={filter.searchByName}
          label={filter.searchByName}
          deleteHandler={() => setFilter({...filter, searchByName: ''})}
        />
      );
    }

    if (!!filter.educatorsFilter.length) {
      filter.educatorsFilter.forEach((id) => {
        const option: IBindBaseEntity = filterEntitiesCollection.educators.find((e) => e.id === id);
        option &&
          chips.push(
            <Tag withFilterChip label={option.name} deleteHandler={() => deleteHandler('educatorsFilter', id)} />
          );
      });
    }

    if (!!filter.programYearsFilter && filter.programYearsFilter.length > 0) {
      filter.programYearsFilter.forEach((yearId) => {
        const programYear: IBindBaseEnumEntity = filterEntitiesCollection.programYears.find(
          (programYear) => programYear.id === yearId
        );

        return chips.push(
          <Tag
            withFilterChip
            key={yearId}
            label={programYear.displayText}
            deleteHandler={() => deleteHandler('programYearsFilter', yearId)}
          />
        );
      });
    }

    if (!!filter.areasFilter.length) {
      filter.areasFilter.forEach((id) => {
        const option: IBindBaseEntity = filterEntitiesCollection.areas.find((e) => e.id === id);
        option &&
          chips.push(<Tag withFilterChip label={option.name} deleteHandler={() => deleteHandler('areasFilter', id)} />);
      });
    }

    if (!!filter.schoolNamesFilter.length) {
      filter.schoolNamesFilter.forEach((id) => {
        const option: IBindBaseEntity = filterEntitiesCollection.schoolNames.find((e) => e.id === id);
        option &&
          chips.push(
            <Tag withFilterChip label={option.name} deleteHandler={() => deleteHandler('schoolNamesFilter', id)} />
          );
      });
    }

    if (!!filter.districtsFilter.length) {
      filter.districtsFilter.forEach((id) =>
        chips.push(<Tag withFilterChip label={id} deleteHandler={() => deleteHandler('districtsFilter', id)} />)
      );
    }

    if (!!filter.startDate) {
      chips.push(
        <Tag
          withFilterChip
          key="startDate"
          label={moment(filter.startDate).format(dateFormat)}
          deleteHandler={() => setFilter({...filter, startDate: null})}
        />
      );
    }

    if (!!filter.endDate) {
      chips.push(
        <Tag
          withFilterChip
          key="endDate"
          label={moment(filter.endDate).format(dateFormat)}
          deleteHandler={() => setFilter({...filter, endDate: null})}
        />
      );
    }

    if (filter.onlyVirtualClasses !== null) {
      const deleteClassHandler = () => {
        const updated = {...filter, [FormNameEnum.onlyVirtualClass]: null};
        setFilter(updated);
      };

      chips.push(
        <Tag
          withFilterChip
          key={FormNameEnum.onlyVirtualClass}
          label={ClassTypes[filter.onlyVirtualClasses.toString()]}
          deleteHandler={deleteClassHandler}
        />
      );
    }

    return chips;
  }, [filter, setFilter, filterEntitiesCollection]);

  const header = useMemo(
    () => (
      <PageViewHeader
        heading="Programs"
        subHeading={headerSubheading}
        sort={renderSortOrder}
        filters={appliedFilters}
        withBackButton
        backButtonClickHandler={() => push(pages.adminPortal.programs.root)}
      />
    ),
    [headerSubheading, renderSortOrder, appliedFilters, push]
  );

  const footer = useMemo(
    () => (
      <PaginationLocal
        onPageChange={setCurrentPage}
        onPerPageChange={setPerPage}
        totalCount={totalCount}
        perPage={perPage}
        currentPage={currentPage}
        disabled={loading}
      />
    ),
    [currentPage, perPage, setCurrentPage, setPerPage, totalCount, loading]
  );

  const sidebar = useMemo(() => <ProgramClassesAllSidebarFilters />, []);

  const content = useMemo(() => {
    return (
      <PageLayoutWithFixedAreas withSecondaryNavigation={false} header={header} footer={footer}>
        {loading ? <Loading /> : !!rows.length ? <Table rows={rows} columns={columns} /> : 'No results found'}
      </PageLayoutWithFixedAreas>
    );
  }, [footer, header, loading, rows]);

  return <PageLayoutTwoCol withSecondaryNavigation={false} sidebar={sidebar} content={content} />;
};

const useStyles = makeStyles((theme) => ({
  viewGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
  },
  btnSpacer: {
    marginLeft: theme.spacing(5),
  },
  btnRightSpacer: {
    marginRight: theme.spacing(4),
  },
}));

const columns: IColumn[] = [
  {id: 'mtIcon', label: '', className: 'insrt-icon-col'},
  {id: 'name', label: 'Class'},
  {id: 'bcrmClassNumber', label: 'BCRM Class Number'},
  {id: 'bcrmProgramYear', label: 'BCRM Program Year'},
  {id: 'educatorsNames', label: 'Educator'},
  {id: 'schoolName', label: 'School Name'},
  {id: 'studentCount', label: 'Number of Students'},
  {id: 'startDate', label: 'Start Date'},
  {id: 'endDate', label: 'End Date'},
  {id: 'viewBtn', label: '', align: 'left', className: 'btn-col'},
];

import {useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useFormikContext} from 'formik';
import {useAppDispatch} from 'hooks';
import {googleSiteDetailsSortOrderSelector, googleSitesActions} from '../../store';
import {IGoogleSitesAreasFormValues} from '../utils';
import {FormNameEnum} from 'filters-selections';
import {IGoogleSiteDetailsSortOrder} from 'models/resources/google-sites';
import {orderBy} from 'utils/functions';

export const useGoogleSiteDetailsSortOrder = () => {
  const dispatch = useAppDispatch();
  const {
    values: {historyFilteredList},
    setFieldValue,
  } = useFormikContext<IGoogleSitesAreasFormValues>();
  const sortOrder = useSelector(googleSiteDetailsSortOrderSelector);

  const sortGoogleSitesBy = useCallback(
    (sortOrder: IGoogleSiteDetailsSortOrder) => {
      const sortedList = orderBy(historyFilteredList, sortOrder.order, sortOrder.sort.toLowerCase());
      return setFieldValue(FormNameEnum.historyFilteredList, sortedList);
    },
    [setFieldValue, historyFilteredList]
  );

  const handleItemClick = useCallback(
    (sortOrder) => dispatch(googleSitesActions.setSortOrderGoogleSiteDetails(sortOrder)),
    [dispatch]
  );

  useEffect(() => {
    sortGoogleSitesBy(sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrder]);

  return {sortOrder, handleItemClick};
};

import {IActionFulfilled, IGetPageOfProgramsResponse} from 'models';
import {normalize} from 'normalizr';
import {isObject} from 'utils';
import {IProgramsAllState} from './IProgramsAllState';
import {programsListSchema} from './normalizationSchemas';
import {fetchProgramsPagedThunk} from './thunks';

export const programsAllExtraReducer = {
  //fetchProgramsPagedThunk
  [fetchProgramsPagedThunk.fulfilled]: (
    state: IProgramsAllState,
    action: IActionFulfilled<null, IGetPageOfProgramsResponse>
  ) => {
    if (isObject(action.payload)) {
      state.programs = normalize(action.payload.programs, programsListSchema);
      state.pagination.totalCount = action.payload.totalCount;
    }

    state.page.loading = false;
  },
  [fetchProgramsPagedThunk.pending]: (state: IProgramsAllState) => {
    state.page.loading = true;
  },
  [fetchProgramsPagedThunk.rejected]: (state: IProgramsAllState) => {
    state.page.loading = false;
  },
};

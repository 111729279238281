import React, {FC} from 'react';
import {FormNameEnum} from 'filters-selections';
import {TextInputSelection} from '../TextInputSelection/TextInputSelection';
import {IWithDisabled} from 'models/common';

type IServiceEmailTextInputProps = IWithDisabled;

export const ServiceEmailTextInput: FC<IServiceEmailTextInputProps> = ({disabled}) => (
  <TextInputSelection name={FormNameEnum.email} label="Service Email Address" disabled={disabled} />
);

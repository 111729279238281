import React, {FC, Fragment, useCallback, useMemo} from 'react';
import {Form} from 'forms';
import {Button, Modal, Text} from 'components-lib';
import {useFormikContext} from 'formik';
import {CreateInstrumentFormFields} from '../CreateInstrumentForm';
import {useDebounce} from 'hooks';
interface IModalCloneInstrumentProps {
  isOpen: boolean;
  error: string;
  closeHandler: () => void;
}

export const ModalCloneInstrument: FC<IModalCloneInstrumentProps> = ({isOpen, error, closeHandler}) => {
  const {resetForm} = useFormikContext();
  const debouncedReset = useDebounce(() => resetForm());

  const handleClose = useCallback(() => {
    closeHandler();
    debouncedReset();
  }, [debouncedReset, closeHandler]);

  const instrumentFields = useMemo(() => <CreateInstrumentFormFields />, []);

  return (
    <Modal disableBackdropClick isOpen={isOpen} handleClose={handleClose} title="Clone Instrument" size="medium">
      <Fragment>
        {error && <Text.Error>{error}</Text.Error>}
        {instrumentFields}
      </Fragment>
      <Fragment>
        <Button.Secondary clickHandler={handleClose} size="small">
          Cancel
        </Button.Secondary>
        <Form.ButtonSubmit text="Clone" size="small" />
      </Fragment>
    </Modal>
  );
};

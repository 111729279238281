import {makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {Checkbox} from 'components-lib';
import {IAnswer, IItemColumn} from 'models';
import React from 'react';

interface IAnswersMatrixViewProps {
  columns: IItemColumn[];
  rows: IAnswer[];
}

export function AnswersMatrixView({columns, rows}: IAnswersMatrixViewProps) {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns &&
              columns.map((col, idx) => (
                <TableCell key={idx} className={classes.cell} align="center">
                  {col && col.text}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row) => (
              <TableRow key={row.value} className={classes.row}>
                {columns &&
                  columns.map((c, idx) => {
                    if (idx === 0) {
                      return (
                        <TableCell component="th" scope="row" key={idx} classes={{head: classes.firstCell}}>
                          {row.text}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell component="th" scope="row" key={idx} align="center" classes={{head: classes.cell}}>
                        <Checkbox checked={false} disabled />
                      </TableCell>
                    );
                  })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  firstCell: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[800],
    width: 20,
  },
  cell: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[800],
    width: 80,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  circle: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
}));

import {createAsyncThunk} from '@reduxjs/toolkit';
import {integrationsApi} from 'api';
import {IIntegrationListDropdown} from 'models/integrations';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchIntegrationListDropdownThunk: any = createAsyncThunk<IIntegrationListDropdown, any>(
  `${prefix} fetchIntegrationListDropdown`,
  async (_, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await integrationsApi.getIntegrationListDropdownData();
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

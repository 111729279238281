import update from 'immutability-helper';
import {normalize} from 'normalizr';
import {cloneDeep, uniqBy, values} from 'utils';
import {IActionFulfilled} from 'models/common';
import {
  IAddInstrumentItemsPayload,
  IAddResourceItemsPayload,
  IBaseInstrumentItem,
  IBaseResourceItem,
  ILearningPathAddSessionBuilderResourceItem,
  ILearningPathSessionItem,
  IMoveSessionResourceItemActionPayload,
} from 'models/resources';
import {ISessionBuilderStoreState} from './ISessionBuilderStoreState';
import {sessionBuilderItemsSchema} from './normalizationSchemas';
import {SessionBuilderEditModeEnum} from 'enums/learning-paths';
import {sessionBuilderInitialState} from './sessionBuilder.initialState';

import {IMoveSessionItemActionPayload} from 'models';

export const sessionBuilderReducer = {
  clearBuilder: (state: ISessionBuilderStoreState) => {
    state.builder = {...sessionBuilderInitialState.builder};
  },

  addSessionBuilderItem: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, ILearningPathSessionItem>
  ) => {
    const {id} = action.payload;
    state.builder.builderSessionItems.entities.items[id] = action.payload;
    state.builder.builderSessionItems.result = [...state.builder.builderSessionItems.result, id];

    state.builder.isSomeOfSessionsInEditMode = true;
    state.builder.isSomeOfSessionsWithEmptyName = true;
  },

  updateSessionBuilderItem: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, ILearningPathSessionItem>
  ) => {
    const {id} = action.payload;

    state.builder.builderSessionItems.entities.items[id] = {
      ...action.payload,
      isAccordionExpanded: false,
      mode: SessionBuilderEditModeEnum.View,
    };
  },

  removeSessionBuilderItem: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, ILearningPathSessionItem>
  ) => {
    const {id} = action.payload;

    delete state.builder.builderSessionItems.entities.items[id];

    state.builder.builderSessionItems.result = state.builder.builderSessionItems.result.filter(
      (sessionId) => sessionId !== id
    );
  },

  collapseSessionBuilderItem: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, ILearningPathSessionItem>
  ) => {
    const {id} = action.payload;
    const sessionItem = state.builder.builderSessionItems.entities.items[id];

    state.builder.builderSessionItems.entities.items[id] = {
      ...sessionItem,
      isAccordionExpanded: sessionItem.isAccordionExpanded ? false : true,
    };
  },

  collapseAllSessionBuilderItems: (state: ISessionBuilderStoreState, action: IActionFulfilled<null, boolean>) => {
    const isExpanded = action.payload;

    const clonedSessionItemsCollection = getClonedSessionEntitiesCollection(
      state.builder.builderSessionItems.entities.items
    );

    const sessionItems = clonedSessionItemsCollection.map((item) => {
      return {
        ...item,
        isAccordionExpanded: isExpanded ? true : false,
      };
    }) as ILearningPathSessionItem[];

    const sessionItemsObj = normalize(sessionItems, sessionBuilderItemsSchema) as any;

    state.builder.isAllSessionsExpanded = isExpanded;
    state.builder.builderSessionItems = sessionItemsObj;
  },

  addSessionBuilderResourceItem: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, ILearningPathAddSessionBuilderResourceItem>
  ) => {
    const {
      id,
      resourceItem: {contentItemId},
    } = action.payload;

    state.builder.builderSessionItems.entities.items[id].resources[contentItemId] = action.payload.resourceItem;
  },

  addSessionBuilderResourceItems: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, IAddResourceItemsPayload>
  ) => {
    const {id, resources} = action.payload;
    const uniqueResources = uniqBy(
      [...state.builder.builderSessionItems.entities.items[id].resources, ...resources],
      'contentItemId'
    );

    state.builder.builderSessionItems.entities.items[id].resources = uniqueResources;
  },

  addSessionBuilderInstrumentItems: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, IAddInstrumentItemsPayload>
  ) => {
    const {id, instruments} = action.payload;
    const uniqueInstruments = uniqBy(
      [...state.builder.builderSessionItems.entities.items[id].instruments, ...instruments],
      'id'
    );

    state.builder.builderSessionItems.entities.items[id].instruments = uniqueInstruments;
  },

  removeSessionResourceBuilderItem: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, {contentItemId: string; itemId: string}>
  ) => {
    const {itemId, contentItemId} = action.payload;
    const resources = state.builder.builderSessionItems.entities.items[itemId].resources;

    state.builder.builderSessionItems.entities.items[itemId].resources = removeResourceItemUtil(
      resources,
      contentItemId
    );
  },

  removeSessionInstrumentBuilderItem: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, {instrumentId: number; itemId: string}>
  ) => {
    const {itemId, instrumentId} = action.payload;
    const instruments = state.builder.builderSessionItems.entities.items[itemId].instruments;

    state.builder.builderSessionItems.entities.items[itemId].instruments = removeInstrumentItemUtil(
      instruments,
      instrumentId
    );
  },

  setSessionBuilderMode: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, ILearningPathSessionItem>
  ) => {
    const {id} = action.payload;
    const cloned = cloneDeep(state.builder.builderSessionItems.entities.items[id]);
    const isEdit = cloned.mode === SessionBuilderEditModeEnum.Edit;

    state.builder.builderSessionItems.entities.items[id] = {
      ...state.builder.builderSessionItems.entities.items[id],
      mode: isEdit ? SessionBuilderEditModeEnum.View : SessionBuilderEditModeEnum.Edit,
    };
  },

  checkIfEmptySession: (state: ISessionBuilderStoreState) => {
    const builderSessionCollectionItems = values(state.builder.builderSessionItems.entities.items);
    const isSomeOfSessionsWithEmptyName = checkIfSessionsWithEmptyNameUtil(builderSessionCollectionItems);

    state.builder.isSomeOfSessionsWithEmptyName = isSomeOfSessionsWithEmptyName;
  },

  moveSessionItem: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, IMoveSessionItemActionPayload>
  ) => {
    const {dragItem, from, to} = action.payload;
    const clonedSessionItemsCollection = getClonedSessionEntitiesCollection(
      state.builder.builderSessionItems.entities.items
    );

    const updatedSessionItems = update(clonedSessionItemsCollection, {
      $splice: [
        [from, 1],
        [to, 0, dragItem],
      ],
    });

    const normalizedSessionEntities = normalize(updatedSessionItems, sessionBuilderItemsSchema) as any;
    state.builder.builderSessionItems = normalizedSessionEntities;
  },

  moveSessionResourceItem: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, IMoveSessionResourceItemActionPayload>
  ) => {
    const clonedBuilderSessionEntityItems = cloneDeep(state.builder.builderSessionItems.entities.items);
    const {dragItem, from, to, itemId} = action.payload;
    const session = clonedBuilderSessionEntityItems[itemId];

    const updatedResourceItems = update(session.resources, {
      $splice: [
        [from, 1],
        [to, 0, dragItem],
      ],
    });

    state.builder.builderSessionItems.entities.items[itemId].resources = updatedResourceItems;
  },
};

function removeResourceItemUtil(resources: IBaseResourceItem[], contentItemId: string) {
  return resources.filter((resource) => resource.contentItemId !== contentItemId);
}

function removeInstrumentItemUtil(instruments: IBaseInstrumentItem[], instrumentId: number) {
  return instruments.filter((instrument) => instrument.id !== instrumentId);
}

function checkIfSessionsWithEmptyNameUtil(sessions: ILearningPathSessionItem[]) {
  if (!sessions.length) {
    return false;
  }

  return sessions.some((session) => session.title === '');
}

function getClonedSessionEntitiesCollection(items: any) {
  const clonedSessionItemsEntities = cloneDeep(items);
  const clonedSessionItemsCollection = values(clonedSessionItemsEntities) as ILearningPathSessionItem[];

  return clonedSessionItemsCollection;
}

import React, {useCallback} from 'react';
import {FieldProps} from 'formik';
import {Select} from 'components-lib';
import {IOption, IWithDisabled} from 'models';
import {isFunction} from 'utils';
import {Field} from './Field';

type TCustomValueChangeHandler = (
  nextId: number,
  prevId: number,
  setFieldValue: (field: string, value: any) => void
) => void;

interface IFormSelectProps extends IWithDisabled {
  name: string;
  label?: string;
  options: IOption[];
  customValueChangeHandler?: TCustomValueChangeHandler;
}

export function FormSelect({name, options, label, disabled, customValueChangeHandler}: IFormSelectProps) {
  return (
    <Field<TCustomValueChangeHandler>
      name={name}
      options={options}
      label={label}
      disabled={disabled}
      customValueChangeHandler={customValueChangeHandler}
      Component={SelectDecorated}
    />
  );
}

interface ISelectDecoratedProps extends FieldProps, IFormSelectProps {}

function SelectDecorated({
  field: {name},
  form: {setFieldValue, errors, values},
  customValueChangeHandler,
  ...rest
}: ISelectDecoratedProps) {
  const error = errors[name];
  const value = values[name];

  let props: any = {
    name,
    value,
  };

  if (error) {
    props = {
      ...props,
      error: true,
      helperText: error,
    };
  }

  const changeHandler = useCallback(
    (nextValue: number) => {
      isFunction(customValueChangeHandler) && customValueChangeHandler(nextValue, value, setFieldValue);
      setFieldValue(name, nextValue, true);
    },
    [customValueChangeHandler, value, setFieldValue, name]
  );

  const selected = value ? rest.options.find((option) => value === option.value) : null;

  return <Select {...props} {...rest} value={selected} changeHandler={changeHandler} label={rest.label} />;
}

import React, {FC} from 'react';
import {FormSelectAutocomplete} from './FormSelectAutocomplete';
import {IOption, IWithDisabled} from 'models';

interface IFormSearchSelectAutocompleteProps extends IWithDisabled {
  name: string;
  options: IOption[];
  multiple?: boolean;
  noOptionsText?: string;
  withCheckboxRenderOption?: boolean;
  withFontWeightBold?: boolean;
  loading?: boolean;
  isFilter?: boolean;
  disableCloseOnSelect?: boolean;
  inputChangeHandler?: (value: string) => void;
}

export const FormSearchSelectAutocomplete: FC<IFormSearchSelectAutocompleteProps> = ({
  name,
  options,
  multiple = true,
  noOptionsText,
  disabled,
  isFilter,
  inputChangeHandler,
  withCheckboxRenderOption,
  withFontWeightBold = false,
  loading = false,
  disableCloseOnSelect = false,
}) => {
  return (
    <FormSelectAutocomplete
      withSearchOnType
      disableCloseOnSelect={disableCloseOnSelect}
      isFilter={isFilter}
      loading={loading}
      name={name}
      options={options}
      multiple={multiple}
      noOptionsText={noOptionsText}
      disabled={disabled}
      withCheckboxRenderOption={withCheckboxRenderOption}
      inputChangeHandler={inputChangeHandler}
      withFontWeightBold={withFontWeightBold}
      filterOptions={(option) => option}
    />
  );
};

import React, {Fragment, useCallback, useMemo} from 'react';
import {batch} from 'react-redux';
import {FormikHelpers} from 'formik';
import {makeStyles} from '@material-ui/core';
import {PageViewHeader, Wrapper, Text} from 'components-lib';
import {Form} from 'forms';
import {IItemsImportFormBaseValues, itemsImportFormBaseValidationSchema, NavigationProgressBar} from 'admin';
import {ModalCancelDataImport, ContentFooter, ItemImportActiveStepPage} from '../components';
import {useItemsImport} from '../hooks';
import {FormNameEnum} from 'enums/form';
import {useActiveStep, useModal} from 'hooks';
import {ActiveStepEnum} from 'enums/common';

export const ItemsImportView = () => {
  const classes = useStyles();
  const {activeStep, setActiveStep, isFirstStep, isSecondStep, isThirdStep} = useActiveStep();
  const {isOpen: isModalCancelDataImport, setIsOpen: setIsModalCancelDataImport} = useModal();
  const {
    fileName,
    uploadItemBank,
    resetMappingState,
    previewLoading,
    uploading,
    shouldDisableImport,
    startItemBankImport,
  } = useItemsImport();

  const shouldDisableBackButton = isFirstStep;
  const shouldDisableNextButton = isThirdStep;
  const shouldDisableCancelButton = isFirstStep || previewLoading || uploading;

  const backButtonClickHandler = useCallback(() => {
    if (activeStep < ActiveStepEnum.Second) {
      return;
    }

    if (activeStep === ActiveStepEnum.Second) {
      resetMappingState();
    }

    return batch(() => {
      setActiveStep(activeStep - ActiveStepEnum.First);
    });
  }, [activeStep, setActiveStep, resetMappingState]);

  const nextClickHandler = useCallback(
    ({templateImportFile}, resetForm, setSubmitting?: (isSubmitting: boolean) => void) => {
      if (activeStep > ActiveStepEnum.Third) {
        return;
      }

      if (isFirstStep) {
        uploadItemBank(templateImportFile)
          .then(() => setSubmitting(false))
          .then(() => setActiveStep(activeStep + ActiveStepEnum.First));
      }

      if (isSecondStep) {
        setActiveStep(activeStep + ActiveStepEnum.First);
      }

      return;
    },
    [activeStep, setActiveStep, isFirstStep, isSecondStep, uploadItemBank]
  );

  const closeModalCancelDataImportHandler = useCallback(() => setIsModalCancelDataImport(false), [
    setIsModalCancelDataImport,
  ]);

  const openModalCancelDataImportHandler = useCallback(() => setIsModalCancelDataImport(true), [
    setIsModalCancelDataImport,
  ]);

  const cancelClickHandler = useCallback(() => {
    if (activeStep < ActiveStepEnum.Second) {
      return;
    }

    return batch(() => {
      resetMappingState();
      closeModalCancelDataImportHandler();
      setActiveStep(ActiveStepEnum.First);
    });
  }, [activeStep, setActiveStep, resetMappingState, closeModalCancelDataImportHandler]);

  const importDataClickHandler = useCallback(
    (resetForm: () => void) => {
      startItemBankImport(resetForm).then(() => resetMappingState());
    },
    [startItemBankImport, resetMappingState]
  );

  const submitHandler = useCallback(
    (formValues, {resetForm, setSubmitting}: FormikHelpers<any>) => {
      if (!isThirdStep) {
        return nextClickHandler(formValues, resetForm, setSubmitting);
      }

      if (isThirdStep) {
        return importDataClickHandler(resetForm);
      }
    },
    [isThirdStep, nextClickHandler, importDataClickHandler]
  );

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.templateImportFile]: null,
    }),
    []
  );

  const contentFooter = useMemo(
    () => (
      <ContentFooter
        isFirstStep={isFirstStep}
        isSecondStep={isSecondStep}
        isThirdStep={isThirdStep}
        shouldDisableNextButton={shouldDisableNextButton}
        shouldDisableCancelButton={shouldDisableCancelButton}
        shouldDisableImportButton={shouldDisableImport}
        openModalCancelDataImportHandler={openModalCancelDataImportHandler}
        nextClickHandler={nextClickHandler}
      />
    ),
    [
      isFirstStep,
      isSecondStep,
      isThirdStep,
      shouldDisableNextButton,
      shouldDisableCancelButton,
      shouldDisableImport,
      openModalCancelDataImportHandler,
      nextClickHandler,
    ]
  );

  const extraSubHeading = useMemo(() => {
    return (
      <Wrapper className={classes.extraSubHeading}>
        <Text.Heading classes={{root: classes.fileName}} as="h4" color="inherit">
          {fileName}
        </Text.Heading>
      </Wrapper>
    );
  }, [classes, fileName]);

  const subHeading = useMemo(() => {
    return (
      <NavigationProgressBar
        isFirstStep={isFirstStep}
        isSecondStep={isSecondStep}
        isThirdStep={isThirdStep}
        stepOneText="Step 1: Upload file"
        stepTwoText="Step 2: Mapping"
        stepThreeText="Step 3: Preview and Import"
      />
    );
  }, [isFirstStep, isSecondStep, isThirdStep]);

  const header = useMemo(() => {
    return (
      <PageViewHeader
        fullFlexWidth
        withColumnPadding={false}
        heading="Import Data"
        headingColor="textPrimary"
        subHeading={subHeading}
        withBackButton
        shouldDisableBackButton={shouldDisableBackButton}
        backButtonClickHandler={backButtonClickHandler}
        extraSubHeading={extraSubHeading}
      />
    );
  }, [subHeading, extraSubHeading, backButtonClickHandler, shouldDisableBackButton]);

  return (
    <Fragment>
      <Form.Form<IItemsImportFormBaseValues>
        initialValues={initialValues}
        validationSchema={itemsImportFormBaseValidationSchema}
        submitHandler={submitHandler}
      >
        <ItemImportActiveStepPage activeStep={activeStep} header={header} contentFooter={contentFooter} />
        <ModalCancelDataImport
          isOpen={isModalCancelDataImport}
          handleClose={closeModalCancelDataImportHandler}
          handleCancelDataImport={cancelClickHandler}
        />
      </Form.Form>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  extraSubHeading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(4),
  },
  fileName: {
    maxWidth: 350,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

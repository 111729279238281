import {schema as Schema} from 'normalizr';

export const integrationsListEntity = new Schema.Entity('googleSites', {}, {idAttribute: 'id'});
export const integrationsListSchema = new Schema.Array(integrationsListEntity);

export const historyListEntity = new Schema.Entity('items', {}, {idAttribute: 'integrationId'});
export const historyListSchema = new Schema.Array(historyListEntity);

export const googleSitesListEntity = new Schema.Entity('items', {}, {idAttribute: 'id'});
export const googleSitesListSchema = new Schema.Array(googleSitesListEntity);

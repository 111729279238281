import {IMetaDataDto} from 'models';
import {POST_SESSION_NUM, PRE_SESSION_NUM} from 'utils';
import {ICloneInstrumentFormData} from '../components/CloneInstrumentForm';
import {ICreateInstrumentFormData} from '../components/CreateInstrumentForm';

export const mapInstrumentCreateModalMetadata = ({
  instrumentName,
  audience,
  phase,
  pillars,
  programs,
  type,
  subType,
  isGeneralInstrument,
}: ICreateInstrumentFormData | ICloneInstrumentFormData) => {
  let newType = type;

  if (type === PRE_SESSION_NUM || type === POST_SESSION_NUM) {
    newType = subType;
  }

  let metaData: IMetaDataDto = {
    audience: audience,
    instrumentName: instrumentName,
    phase: phase,
    pillar: {id: pillars},
    programs: programs.map((program) => {
      return {id: program};
    }),
    type: newType,
  };

  if (isGeneralInstrument) {
    metaData = {
      audience: audience,
      instrumentName: instrumentName,
      type: type,
    };
  }

  return metaData;
};

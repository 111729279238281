import React, {forwardRef, useCallback} from 'react';
import {batch} from 'react-redux';
import {makeStyles, MenuItem} from '@material-ui/core';
import {IProgramClassReportMenuListOption} from 'models';
import {useFormikContext} from 'formik';
import {ILevelReportingFormBaseValues} from '../LevelReportingMenuForm/config';
import {FormNameEnum} from 'enums/form';
import {useDebounce} from 'hooks';
import {formResetDelay} from '../../utils';
interface ILevelReportingMenuItemProps {
  item: IProgramClassReportMenuListOption;
  selected?: boolean;
  closeMenuClickHandler: () => void;
}

export const LevelReportingMenuItem = forwardRef(
  ({item, selected, closeMenuClickHandler}: ILevelReportingMenuItemProps, ref: any) => {
    const classes = useStyles();
    const {submitForm, setFieldValue, resetForm} = useFormikContext<ILevelReportingFormBaseValues>();

    const debouncedReset = useDebounce(() => resetForm(), formResetDelay);

    const clickHandler = useCallback(() => {
      batch(() => {
        setFieldValue(FormNameEnum.selectedId, item.id);
        submitForm()
          .then(() => closeMenuClickHandler())
          .then(() => debouncedReset());
      });
    }, [closeMenuClickHandler, submitForm, item, setFieldValue, debouncedReset]);

    return (
      <MenuItem ref={ref} onClick={clickHandler}>
        <span className={selected ? classes.selected : ''}>{item.displayText}</span>
      </MenuItem>
    );
  }
);

LevelReportingMenuItem.displayName = 'LevelReportingMenuItem';

const useStyles = makeStyles((theme) => ({
  selected: {
    color: theme.palette.primary.main,
  },
}));

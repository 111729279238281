import {integrationsActions as actions} from 'admin';
import {useAppDispatch} from 'hooks';
import {useCallback} from 'react';

export const useIntegrationStoreState = () => {
  const dispatch = useAppDispatch();

  const clearIntegrationState = useCallback(() => dispatch(actions.clear()), [dispatch]);

  const clearIntegrationAuditLogsState = useCallback(() => dispatch(actions.clearAuditLogs()), [dispatch]);

  return {clearIntegrationState, clearIntegrationAuditLogsState};
};

import {IResourceStatusAndUserDetails} from 'models';
import {getDatetimeAMPMinUTC, getFirstTwoCharsFromFullName} from 'utils/common';

export const getUserDetails = (details: IResourceStatusAndUserDetails) => {
  const createdBy = details?.createdBy ?? '';
  const updatedBy = details?.updatedBy ?? '';
  const createdUtc = details?.createdUtc ? getDatetimeAMPMinUTC(details?.createdUtc) : '';
  const modifiedUtc = details?.modifiedUtc ? getDatetimeAMPMinUTC(details?.modifiedUtc) : '';
  const emailCreator = details?.emailCreator ?? '';
  const emailUpdater = details?.emailUpdater ?? '';

  const createdByInitials = createdBy ? getFirstTwoCharsFromFullName(createdBy) : '';
  const updatedByInitials = updatedBy ? getFirstTwoCharsFromFullName(updatedBy) : '';

  return {
    createdBy,
    updatedBy,
    createdUtc,
    modifiedUtc,
    createdByInitials,
    updatedByInitials,
    emailCreator,
    emailUpdater,
  };
};

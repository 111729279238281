import React, {FC, useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {Menu, MenuItem, makeStyles, IconButton} from '@material-ui/core';
import classnames from 'classnames';
import {useBeforeunload} from 'react-beforeunload';

import {Button, Icon, Row, Text, Wrapper} from 'components-lib';
import {capitalize, UPLOAD_ITEM_HEIGHT, UPLOAD_ITEM_WIDTH, ROUNDED_BY_TWO_DIGITS} from 'utils';
import {cancelUpload, IUploadFile, useUploadManager} from 'admin';
import {UploadFileStatusEnum} from 'admin/upload/enums';
import {calculateLengthText, convertBytesToMegaBytes, uploadMessages} from 'admin/upload/utils';
import {useToastNotifications, ToastNotificationTypeEnum} from 'toast-notifications';
import {useMenu} from 'hooks';

export const UploadManager: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {uploadFiles, uploadingText, getFileManagerUploadingStatus} = useUploadManager();
  const {addToastNotification} = useToastNotifications();
  const {anchorEl, setAnchorEl} = useMenu();
  const hasUploadingFiles = !!uploadFiles && uploadFiles.length > 0;

  // Prevent closing or refreshing the browser tab in case the user is uploading a resource (alert prompt)
  useBeforeunload(() => {
    const isFileManagerUploading = getFileManagerUploadingStatus(uploadFiles);

    if (isFileManagerUploading) {
      return uploadMessages.warning;
    }
  });

  const openUploadManager = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(e.currentTarget);
    },
    [setAnchorEl]
  );

  const closeUploadManager = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleCancelation = useCallback(
    (file) => {
      dispatch(cancelUpload(file?.id));
      file?.cancelRequest();

      addToastNotification(`File: ${file?.name} ${uploadMessages.abortRequest}`, ToastNotificationTypeEnum.error);
    },
    [dispatch, addToastNotification]
  );

  const getIcon = useCallback((file: IUploadFile) => {
    const {status} = file;

    switch (status) {
      case UploadFileStatusEnum.Uploading:
        return <Icon.UploadIcon />;
      case UploadFileStatusEnum.Failed:
        return <Icon.FailIcon />;
      case UploadFileStatusEnum.Uploaded:
        return <Icon.CheckIcon />;
      default:
        return <Icon.UploadIcon />;
    }
  }, []);

  const missingFiles = useMemo(() => <MenuItem className={classes.noFiles}>No files right now</MenuItem>, [
    classes.noFiles,
  ]);

  return (
    <Wrapper className={classes.content}>
      <Row justify="flex-start">
        <Button.Text
          aria-controls="upload-manager-menu"
          startIcon={<Icon.UploadIcon />}
          endIcon={<Icon.ExpandIcon color="primary" />}
          clickHandler={openUploadManager}
          className={classes.button}
        >
          {uploadingText}
        </Button.Text>
      </Row>

      <Menu
        id="upload-manager-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeUploadManager}
        PaperProps={{
          style: {
            maxHeight: UPLOAD_ITEM_HEIGHT * 7,
            width: UPLOAD_ITEM_WIDTH,
          },
        }}
      >
        <div className={classes.heading}>
          <Text.Heading as="h4" classes={{root: classes.title}}>
            {uploadingText}
          </Text.Heading>
        </div>

        {!hasUploadingFiles && missingFiles}

        {uploadFiles?.map((uploadFile: IUploadFile, idx: number) => {
          const isLong = calculateLengthText(uploadFile?.name);

          return (
            <MenuItem key={idx}>
              <div className={classes.menuItem}>
                <div className={classes.menuHeading}>
                  <div className={classes.fileNameContainer}>
                    {getIcon(uploadFile)}
                    <span className={classnames(isLong ? classes.cropText : '', classes.fileName)}>{`${
                      uploadFile?.name
                    }  / ${convertBytesToMegaBytes(uploadFile?.size, ROUNDED_BY_TWO_DIGITS)} MB`}</span>
                  </div>
                  {!uploadFile?.isCanceledUpload && (
                    <IconButton
                      edge="end"
                      color="secondary"
                      aria-label="close"
                      onClick={() => handleCancelation(uploadFile)}
                    >
                      <Icon.CloseIcon />
                    </IconButton>
                  )}
                </div>
                <span className={classes.uploadingInfo}>{`${capitalize(uploadFile?.status)}... ${
                  uploadFile?.percentage
                }%`}</span>
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(2)}px ${theme.spacing(4)}px`,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  actions: {
    padding: `${theme.spacing(4)}px ${theme.spacing(0)}px`,
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  content: {
    paddingRight: theme.spacing(3) + theme.spacing(4),
  },
  manuPaperProps: {
    maxHeight: UPLOAD_ITEM_HEIGHT * 7,
    width: UPLOAD_ITEM_WIDTH,
  },
  menuItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  menuHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileNameContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '90%',
  },
  fileName: {
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: theme.spacing(2),
  },
  uploadingInfo: {
    fontSize: 12,
    color: theme.palette.grey[700],
    paddingLeft: theme.spacing(4),
  },
  button: {
    color: theme.palette.grey[700],
    padding: 0,
  },
  cropText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '90%',
  },
  noFiles: {
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
  },
}));

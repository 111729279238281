import * as Yup from 'yup';
import {FormNameEnum as IntegrationFiltersFormNameEnum} from 'filters-selections';
import {FormNameEnum as IntegrationFormNameEnum} from 'enums/form';
import {errorMessages} from 'utils/constants';
import {AddIntegrationLabelEnum} from '../../enums';
import {addIntegrationValidationWarnings, GOOGLE_DRIVE_URL, SUPPORTED_CERTIFICATE_FORMATS} from '../constants';
import {emailRegex} from 'utils/common';

const isChosenIntegrationType =
  IntegrationFormNameEnum.isGoogleClassroom ||
  IntegrationFormNameEnum.isGoogleSitesDestination || // not sure if it is applicable for area, schoolname, schoolDistrict
  IntegrationFormNameEnum.isMicrosoftTeams;

export const addIntegrationFormBaseValidationSchema = Yup.object().shape({
  [IntegrationFiltersFormNameEnum.integrationName]: Yup.string()
    .nullable()
    .required(errorMessages.requiredWithInputName('an Integration Name')),
  [IntegrationFiltersFormNameEnum.integrationType]: Yup.number()
    .nullable()
    .required(errorMessages.requiredWithFieldName('an Integration Type')),
  [IntegrationFiltersFormNameEnum.area]: Yup.number()
    .nullable()
    .when(isChosenIntegrationType, {
      is: true,
      then: Yup.number().test(
        'has-selected-field',
        addIntegrationValidationWarnings.hasSelectedAreaOrSchoolNameOrSchoolDistrictField,
        function () {
          const {area, schoolDistrict, schoolName} = this.parent;
          const hasSomeOfFieldsSelected = checkIfHasAField(area, schoolDistrict, schoolName);

          return hasSomeOfFieldsSelected;
        }
      ),
      otherwise: Yup.number().nullable(),
    }),
  [IntegrationFiltersFormNameEnum.schoolName]: Yup.number()
    .nullable()
    .when(isChosenIntegrationType, {
      is: true,
      then: Yup.number().test(
        'has-selected-field',
        addIntegrationValidationWarnings.hasSelectedAreaOrSchoolNameOrSchoolDistrictField,
        function () {
          const {area, schoolDistrict, schoolName} = this.parent;
          const hasSomeOfFieldsSelected = checkIfHasAField(area, schoolDistrict, schoolName);

          return hasSomeOfFieldsSelected;
        }
      ),
      otherwise: Yup.number().nullable(),
    }),
  [IntegrationFiltersFormNameEnum.schoolDistrict]: Yup.number()
    .nullable()
    .when(isChosenIntegrationType, {
      is: true,
      then: Yup.number().test(
        'has-selected-field',
        addIntegrationValidationWarnings.hasSelectedAreaOrSchoolNameOrSchoolDistrictField,
        function () {
          const {area, schoolDistrict, schoolName} = this.parent;
          const hasSomeOfFieldsSelected = checkIfHasAField(area, schoolDistrict, schoolName);

          return hasSomeOfFieldsSelected;
        }
      ),
      otherwise: Yup.number().nullable(),
    }),
  [IntegrationFormNameEnum.certificate]: Yup.mixed()
    .nullable()
    .when(
      IntegrationFormNameEnum.isGoogleClassroom ||
        IntegrationFormNameEnum.isGoogleSitesDestination ||
        IntegrationFormNameEnum.isGoogleSitesSource ||
        IntegrationFormNameEnum.isMicrosoftTeams,
      {
        is: true,
        then: Yup.mixed().test(
          'has-correct-format',
          addIntegrationValidationWarnings.certificateSupportedFormatWarning,
          (value) => (value ? SUPPORTED_CERTIFICATE_FORMATS.includes(value.type) : true)
        ),
        otherwise: Yup.mixed().nullable(),
      }
    ),

  // Service email required for Google Sites Source + Google Classroom
  [IntegrationFormNameEnum.email]: Yup.string()
    .nullable()
    .when([IntegrationFormNameEnum.isMicrosoftTeams, IntegrationFormNameEnum.isGoogleSitesDestination], {
      is: (isMicrosoftTeams, isGoogleSitesDestination) => {
        if (isMicrosoftTeams || isGoogleSitesDestination) return true;
      },
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .nullable()
        .required(errorMessages.requiredWithInputName(`a ${AddIntegrationLabelEnum.ServiceEmailAdress}`))
        .matches(emailRegex, addIntegrationValidationWarnings.validationServiceEmailWarning),
    }),

  [IntegrationFormNameEnum.masterClassroomEmailAccount]: Yup.string()
    .nullable()
    .when([IntegrationFormNameEnum.isGoogleClassroom], {
      is: (isGoogleClassroom, isGoogleSitesSource) => {
        if (isGoogleClassroom || isGoogleSitesSource) return true;
      },
      then: Yup.string()
        .nullable()
        .matches(emailRegex, addIntegrationValidationWarnings.validationmMsterClassroomEmailAccountlWarning),
      otherwise: Yup.string().nullable().notRequired(),
    }),

  // Google Sites
  [IntegrationFormNameEnum.googleDriveSourceFolderLink]: Yup.string()
    .nullable()
    .url(addIntegrationValidationWarnings.urlWarning(AddIntegrationLabelEnum.GoogleDriveSourceFolderLink))
    .when(IntegrationFormNameEnum.isGoogleSitesSource, {
      is: true,
      then: Yup.string()
        .required(errorMessages.requiredWithInputName(`a ${AddIntegrationLabelEnum.GoogleDriveSourceFolderLink}`))
        .test('has-google-drive-url', addIntegrationValidationWarnings.validationGoogleDriveLinkWarning, (value) =>
          value ? value.includes(GOOGLE_DRIVE_URL) : true
        ),
      otherwise: Yup.string().nullable(),
    }),

  [IntegrationFormNameEnum.googleDriveDestinationFolderLink]: Yup.string()
    .nullable()
    .url(addIntegrationValidationWarnings.urlWarning(AddIntegrationLabelEnum.GoogleDriveDestinationFolderLink))
    .when(IntegrationFormNameEnum.isGoogleSitesDestination, {
      is: true,
      then: Yup.string()
        .required(errorMessages.requiredWithInputName(`a ${AddIntegrationLabelEnum.GoogleDriveDestinationFolderLink}`))
        .test('has-google-drive-url', addIntegrationValidationWarnings.validationGoogleDriveLinkWarning, (value) =>
          value ? value.includes(GOOGLE_DRIVE_URL) : true
        ),
      otherwise: Yup.string().nullable(),
    }),

  // Microsoft teams
  [IntegrationFormNameEnum.authorityUrl]: Yup.string()
    .url(addIntegrationValidationWarnings.urlWarning(AddIntegrationLabelEnum.AuthorityUrl))
    .nullable()
    .when(IntegrationFormNameEnum.isMicrosoftTeams, {
      is: true,
      then: Yup.string()
        .url(addIntegrationValidationWarnings.urlWarning(AddIntegrationLabelEnum.AuthorityUrl))
        .required(errorMessages.requiredWithInputName(`a ${AddIntegrationLabelEnum.AuthorityUrl}`)),
      otherwise: Yup.string().nullable(),
    }),

  [IntegrationFormNameEnum.clientId]: Yup.string()
    .nullable()
    .when(IntegrationFormNameEnum.isMicrosoftTeams, {
      is: true,
      then: Yup.string().required(errorMessages.requiredWithInputName(`a ${AddIntegrationLabelEnum.ClientID}`)),
      otherwise: Yup.string().nullable(),
    }),

  [IntegrationFormNameEnum.clientSecret]: Yup.string()
    .nullable()
    .when(IntegrationFormNameEnum.isMicrosoftTeams, {
      is: true,
      then: Yup.string().required(errorMessages.requiredWithInputName(`a ${AddIntegrationLabelEnum.ClientSecret}`)),
      otherwise: Yup.string().nullable(),
    }),
});

function checkIfHasAField(area: number, schoolDistrict: number, schoolName: number) {
  return !!area || !!schoolDistrict || !!schoolName;
}

import {useHistory} from 'react-router-dom';
import {Location} from 'history';

interface IWithPrevPath {
  prevPath?: string;
}

export function useNavigate() {
  const history = useHistory();
  const {state: locationState}: Location<IWithPrevPath> = history?.location || ({} as Location<IWithPrevPath>);

  const push = (path: string, state?: IWithPrevPath) => history.push(path, state);
  const goBackOptionally = (path: string) => history.push(locationState?.prevPath || path);

  return {
    locationState,
    push,
    goBackOptionally,
  };
}

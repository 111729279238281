import {useParams} from 'react-router-dom';

interface IFileIdParam {
  id: string;
}

export const useFileIdParam = () => {
  const urlParams = useParams<IFileIdParam>();
  return {fileId: urlParams?.id};
};

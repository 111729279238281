import {IProgramComparison} from 'admin/programs/details/reports';

export const mapProgramComparison = (
  pasId: number,
  d2lId: number,
  areaId?: number,
  sessionId?: number
): IProgramComparison => {
  return {
    pasId,
    d2lId,
    areaId,
    sessionId,
  };
};

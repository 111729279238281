import React, {useCallback, useMemo} from 'react';
import {batch} from 'react-redux';
import {FormikHelpers} from 'formik';
import {ContentStart, WebLinkFooter, WebLinkHeader} from './components';
import {Form} from 'forms';
import {FormNameEnum} from '../enums';
import {useCreateWebLink} from './hooks';
import {addWebLinkFormBaseValidationSchema, IAddWebLinkFormBaseValues, mapCreateResourceWebLink} from 'admin/resources';
import {PageLayoutOneVerticalCol} from 'layout/components';
import {usePermissions} from 'permissions';
import {SuperAndNationalAdminroles} from 'enums/admin-roles';
import {useLanguages} from '../hooks';

export const UploadResourceWebLink = () => {
  const {loading, createWebLink} = useCreateWebLink();
  const {hasPermission} = usePermissions();
  const {englishId} = useLanguages();
  const hasAdminPermission = hasPermission(SuperAndNationalAdminroles);

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.isAccessibleToStudents]: null,
      [FormNameEnum.hasUploadPermissions]: hasAdminPermission ? true : false,
      [FormNameEnum.displayName]: null,
      [FormNameEnum.keyTerms]: null,
      [FormNameEnum.programs]: [],
      [FormNameEnum.sessions]: [],
      [FormNameEnum.pillars]: [],
      [FormNameEnum.competencies]: [],
      [FormNameEnum.gradeLevels]: [],
      [FormNameEnum.languages]: englishId ? [englishId] : [],
      [FormNameEnum.skillsAndBehaviors]: [],
      [FormNameEnum.briefDescription]: '',
      [FormNameEnum.learningObjectives]: null,
      [FormNameEnum.expirationDate]: null,
      [FormNameEnum.knowledgeSkillsAndAbilities]: [],
      [FormNameEnum.webLink]: null,
    }),
    [hasAdminPermission, englishId]
  );

  const submitHandler = useCallback(
    (formValues: IAddWebLinkFormBaseValues, {resetForm, setSubmitting}: FormikHelpers<any>) => {
      const payload = mapCreateResourceWebLink(formValues);

      return createWebLink(payload).then(() =>
        batch(() => {
          resetForm({
            values: initialValues,
          });
          setSubmitting(false);
        })
      );
    },
    [createWebLink, initialValues]
  );

  return (
    <Form.Form<IAddWebLinkFormBaseValues>
      initialValues={initialValues}
      validationSchema={addWebLinkFormBaseValidationSchema}
      submitHandler={submitHandler}
    >
      <PageLayoutOneVerticalCol
        header={<WebLinkHeader />}
        contentStart={<ContentStart />}
        footer={<WebLinkFooter />}
        withSecondaryNavigation
        loading={loading}
      />
    </Form.Form>
  );
};

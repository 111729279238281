import {IFilterListNameMap} from './../models/IFilterListNameMap';
import {InstrumentsFilterNamesEnum} from 'admin/library/instruments/enums';
import {FiltersEnum} from 'filters-selections';

export const getFilterList = (): FiltersEnum[] => [
  FiltersEnum.PillarMulti,
  FiltersEnum.EvaluationType,
  FiltersEnum.Domain,
  FiltersEnum.ProgramMulti,
  FiltersEnum.LearningObjective,
  FiltersEnum.Category,
  FiltersEnum.Competency,
  FiltersEnum.Concept,
  FiltersEnum.Administration,
  FiltersEnum.SchoolLevel,
  FiltersEnum.Areas,
  FiltersEnum.Search,
];

export const getFilterToFilterNameMap = (): IFilterListNameMap => ({
  [FiltersEnum.PillarMulti]: InstrumentsFilterNamesEnum.PILLARS,
  [FiltersEnum.EvaluationType]: InstrumentsFilterNamesEnum.EVALUATION_TYPE,
  [FiltersEnum.Domain]: InstrumentsFilterNamesEnum.DOMAIN,
  [FiltersEnum.ProgramMulti]: InstrumentsFilterNamesEnum.PROGRAMS,
  [FiltersEnum.LearningObjective]: InstrumentsFilterNamesEnum.LEARNING_OBJECTIVE,
  [FiltersEnum.Category]: InstrumentsFilterNamesEnum.CATEGORY,
  [FiltersEnum.Competency]: InstrumentsFilterNamesEnum.COMPETENCY,
  [FiltersEnum.Concept]: InstrumentsFilterNamesEnum.CONCEPT,
  [FiltersEnum.Administration]: InstrumentsFilterNamesEnum.ADMINISTRATION,
  [FiltersEnum.SchoolLevel]: InstrumentsFilterNamesEnum.SCHOOL_LEVEL,
  [FiltersEnum.Areas]: InstrumentsFilterNamesEnum.AREAS,
  [FiltersEnum.Search]: InstrumentsFilterNamesEnum.SEARCH,
});

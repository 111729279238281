import React, {FC, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {PreviewEditor, TextArea} from 'components-lib';

interface IAnswerInstructionaTextProps {
  html: string;
}

export const AnswerInstructionaText: FC<IAnswerInstructionaTextProps> = ({html = ''}) => {
  const classes = useStyles();
  const shouldRender = !!html;

  const textArea = useMemo(() => <TextArea placeholder={'...'} rows={5} disabled fullWidth />, []);
  const previewEditor = useMemo(() => <PreviewEditor htmlStr={html} />, [html]);

  if (!shouldRender) {
    return <div className={classes.textArea}>{textArea}</div>;
  }

  return <div className={classes.instructionalText}>{previewEditor}</div>;
};

const useStyles = makeStyles((theme) => ({
  instructionalText: {
    maxWidth: 800,
    color: theme.palette.grey[800],
    fontSize: '0.75rem',
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${
      theme.spacing(5) + theme.spacing(1)
    }px`,
  },
  textArea: {
    width: '95%',
    padding: `${theme.spacing(1)}px 0px ${theme.spacing(3)}px ${theme.spacing(1) + theme.spacing(2)}px`,
  },
}));

import {makeStyles} from '@material-ui/core';
import {footerHeight, secondaryNavigation} from 'utils/layout';

const height = `calc(100vh - 111px)`;
const fullHeight = `calc(100vh - 65px)`;

interface IContentStyles {
  height: string;
  withFooter?: boolean;
}

export const useStyles = makeStyles(
  (theme) => ({
    root: (withSecondaryNavigation) => ({
      flexGrow: 1,
      maxHeight: withSecondaryNavigation ? height : fullHeight,
    }),
    row: {
      width: '100%',
      margin: 0,
    },
    sidebar: (withSecondaryNavigation) => ({
      background: theme.palette.grey[50],
      alignItems: 'stretch',
      height: withSecondaryNavigation ? height : fullHeight,
      overflow: 'auto',
      boxShadow: `inset 0 0 10px  ${theme.palette.grey[200]}`,
    }),
    content: (withSecondaryNavigation) => ({
      background: theme.palette.common.white,
      alignItems: 'stretch',
      height: withSecondaryNavigation ? height : fullHeight,
      overflow: 'auto',
    }),
    contentEqualCols: {
      background: theme.palette.common.white,
      alignItems: 'stretch',
      height: '100vh',
      overflow: 'auto',
    },
    contentMiddle: {
      borderRight: `1px solid ${theme.palette.grey[200]}`,
    },
    borderRight: {
      borderRight: `1px solid ${theme.palette.grey[200]}`,
    },
    contentOneOrTwoVerticalColsRoot: ({height}: IContentStyles) => ({
      flexGrow: 1,
      maxHeight: height,
    }),
    contentOneOrTwoVerticalColsFooter: {
      height: footerHeight,
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      background: theme.palette.common.white,
    },
    contentOneOrTwoVerticalCols: ({height, withFooter}: IContentStyles) => ({
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: !withFooter && theme.spacing(4),
      background: theme.palette.common.white,
      alignItems: 'stretch',
      height: height,
      overflow: 'auto',
      width: '100%',
    }),
    contentLeftOneOrTwoVerticalCols: {
      borderRight: `1px solid ${theme.palette.grey[200]}`,
    },
    headerSecondaryNavigation: {
      paddingLeft: theme.spacing(4),
      height: secondaryNavigation,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      background: theme.palette.common.white,
    },
  }),
  {index: 1} // https://github.com/mui-org/material-ui/issues/18919
);

import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Subtitle} from 'admin/components';
import {Container, Link, Table} from 'components-lib';
import {useFileEdit} from '../../hooks';
import {getFileEditLearningPathColumns} from '../../utils';
import {getDatetimeAMPMinUTC} from 'utils';
import {AdminWithoutStaffRoles, usePermissions} from 'permissions';

export const FilesEditLearningPaths = () => {
  const classes = useStyles();
  const {hasPermission} = usePermissions();
  const {redirectToEditLearningPathPage, linkedLearningPaths} = useFileEdit();
  const hasEditPermissions = hasPermission(AdminWithoutStaffRoles);

  const columns = useMemo(() => getFileEditLearningPathColumns(), []);

  const rows = useMemo(() => {
    if (!linkedLearningPaths?.length) {
      return [];
    }

    return linkedLearningPaths.map(({id, title, createdBy, createdUtc, updatedBy, modifiedUtc}) => ({
      title: hasEditPermissions ? (
        <Link disabled={!title || !hasEditPermissions} clickHandler={() => redirectToEditLearningPathPage(id)}>
          {title}
        </Link>
      ) : (
        title
      ),
      createdBy,
      createdUtc: createdUtc ? getDatetimeAMPMinUTC(createdUtc) : '',
      updatedBy,
      modifiedUtc: modifiedUtc ? getDatetimeAMPMinUTC(modifiedUtc) : '',
    }));
  }, [redirectToEditLearningPathPage, linkedLearningPaths, hasEditPermissions]);

  return (
    <Container disableGutters classes={{root: classes.content}}>
      <Subtitle withColor={false} as="h3" color="textPrimary" title="Learning Paths" />
      {rows?.length ? (
        <Table rows={rows} columns={columns} />
      ) : (
        <Container classes={{root: classes.noResultMessage}} disableGutters>
          No related learning paths
        </Container>
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: theme.spacing(5),
  },
  noResultMessage: {
    paddingLeft: theme.spacing(2),
  },
}));

import React, {FC} from 'react';
import {IconButton, makeStyles} from '@material-ui/core';
import {Icon, Wrapper, Text, Tooltip, Row} from 'components-lib';
import {IInputFile} from 'models';
interface IAttachmentAreaProps {
  error?: string;
  loading: boolean;
  deleteCallback: () => void;
  file: IInputFile;
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  align?: 'center' | 'flex-start' | 'flex-end';
}

export const AttachmentArea: FC<IAttachmentAreaProps> = ({
  error,
  loading,
  deleteCallback,
  file,
  justify = 'space-between',
  align = 'center',
}) => {
  const classes = useStyles({justify, align});

  return (
    <Wrapper className={classes.attachmentArea}>
      <Wrapper className={classes.fileIconContainer}>
        <Icon.FileIcon />
      </Wrapper>
      {!error && (
        <Row justify="center">
          <Tooltip title={file?.name}>
            <Text.Heading classes={{root: classes.fileName}} as="h4" color="textPrimary">
              {file?.name}
            </Text.Heading>
          </Tooltip>
        </Row>
      )}
      {error && <Text.Error withBottomMargin={false}>{error}</Text.Error>}
      <IconButton disabled={loading} onClick={deleteCallback}>
        <Icon.DeleteIcon color={loading ? 'disabled' : 'error'} />
      </IconButton>
    </Wrapper>
  );
};

type TAttachmentAreaStyle = Pick<IAttachmentAreaProps, 'justify' | 'align'>;

const useStyles = makeStyles((theme) => ({
  attachmentArea: ({justify, align}: TAttachmentAreaStyle) => {
    return {
      width: '100%',
      display: 'flex',
      justifyContent: justify,
      alignItems: align,
    };
  },
  fileIconContainer: {
    paddingRight: theme.spacing(1) + theme.spacing(2),
  },
  fileName: {
    maxWidth: 300,
    fontWeight: 700,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

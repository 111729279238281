import React, {Fragment, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Label} from 'components-lib';
import {
  fetchSchoolDistrictThunk,
  loadingStateDistricts,
  schoolDistrictsEntitiesCollectionSelector,
} from 'filters-selections/store';
import {Form} from 'forms';
import {useAppDispatch, useDebounce} from 'hooks';
import {IOption, IWithDisabled} from 'models';
import {delayTime, dynamicSearchMessages, FormNameEnum} from 'filters-selections';

export const SchoolDistrictSelect = ({disabled}: IWithDisabled) => {
  const dispatch = useAppDispatch();
  const schoolDistricts = useSelector(schoolDistrictsEntitiesCollectionSelector);
  const loading = useSelector(loadingStateDistricts);

  const options: IOption[] = useMemo(() => {
    if (loading) {
      return [];
    }

    return schoolDistricts
      ? schoolDistricts.map((schoolDistrict) => ({value: schoolDistrict.id, label: schoolDistrict.displayText}))
      : [];
  }, [schoolDistricts, loading]);

  const debouncedSearch = useDebounce((query: string) => dispatch(fetchSchoolDistrictThunk(query)), delayTime);

  const handleInputChange = useCallback((value: string) => !!value && !!value.trim() && debouncedSearch(value.trim()), [
    debouncedSearch,
  ]);

  return (
    <Fragment>
      <Label label="School District" />
      <Form.SearchSelectAutocomplete
        loading={loading}
        multiple={false}
        name={FormNameEnum.schoolDistrict}
        options={options}
        disabled={disabled || loading}
        noOptionsText={dynamicSearchMessages.noOptionsSchoolDistrict}
        inputChangeHandler={handleInputChange}
        withFontWeightBold={true}
        withCheckboxRenderOption={false}
      />
    </Fragment>
  );
};

import React, {Fragment, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {heightThreeStepsActivity} from 'utils/layout';
import {getPreviewFirstColumnList, getPreviewSecondColumnList, useAddIntegration} from '../..';
import {useFormikContext} from 'formik';
import {IAddIntegrationFormBaseValues} from 'models/integrations';
import {Column, Loading, Row} from 'components-lib';
import {Subtitle} from '..';
import {PreviewList} from 'admin/integrations/components';

export const PreviewAndTest = () => {
  const classes = useStyles();
  const {
    values: {integrationType},
  } = useFormikContext<IAddIntegrationFormBaseValues>();
  const {
    loading,
    email,
    integrationName,
    certificateName,
    areaName,
    integrationTypeStringValue,
    schoolDistrictStringValue,
    schoolNameStringValue,
    googleDriveSourceFolderLink,
    clientId,
    clientSecret,
    authorityUrl,
    masterClassroomEmailAccount,
  } = useAddIntegration();

  const type = Number(integrationType);

  const firstColInputList = getPreviewFirstColumnList(type, {
    certificateName,
    areaName,
    integrationTypeStringValue,
    schoolNameStringValue,
    clientId,
  });

  const secondColInputList = getPreviewSecondColumnList(type, {
    email,
    integrationName,
    schoolDistrictStringValue,
    googleDriveSourceFolderLink,
    clientSecret,
    authorityUrl,
    masterClassroomEmailAccount,
  });

  const contentFirstCol = useMemo(() => <PreviewList list={firstColInputList} />, [firstColInputList]);
  const contentSecondCol = useMemo(() => <PreviewList list={secondColInputList} />, [secondColInputList]);

  const content = useMemo(() => {
    return (
      <Fragment>
        <Subtitle withColor={false} as="h3" color="textPrimary" title="Step 3: Preview and Test" />
        <Row justify="flex-start" align="flex-start" classes={{root: classes.row}}>
          <Column sm={8} md={8} lg={8} xl={6} className={classes.content}>
            <Row>
              <Column sm={6} md={6} lg={6} xl={6} className={classes.firstCol}>
                {contentFirstCol && contentFirstCol}
              </Column>
              <Column sm={6} md={6} lg={6} xl={6} className={classes.secondCol}>
                {contentSecondCol && contentSecondCol}
              </Column>
            </Row>
          </Column>
          <Column sm={4} md={4} lg={4} xl={4} />
        </Row>
      </Fragment>
    );
  }, [classes, contentFirstCol, contentSecondCol]);

  return <Fragment>{loading ? <Loading /> : content}</Fragment>;
};

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(2),
  },
  content: () => {
    return {
      height: heightThreeStepsActivity,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(4),
    };
  },
  firstCol: {
    height: heightThreeStepsActivity,
  },
  secondCol: {
    height: heightThreeStepsActivity,
  },
}));

import React, {FC} from 'react';
import Typography from '@material-ui/core/Typography';
import {Box as MUIBox, makeStyles} from '@material-ui/core';
import {Text} from 'components-lib';
import {BoxContent} from './components';
interface IBoxProps {
  label?: string;
  content: string;
  withLink?: boolean;
  withButtonLink?: boolean;
  className?: string;
  avatarUrl?: string;
  userInitials?: string;
  size?: 'small' | 'large';
  linkCallback?: () => void;
}

export const Box: FC<IBoxProps> = ({
  label,
  content,
  withLink = false,
  withButtonLink = false,
  linkCallback,
  className,
  avatarUrl,
  userInitials,
  size,
}) => {
  const classes = useStyles();

  return (
    <Typography component="div">
      {label && <Text.Caption classes={{root: classes.root}}>{label}</Text.Caption>}
      {content && (
        <MUIBox color="#171A1D" className={className}>
          <BoxContent
            size={size}
            avatarUrl={avatarUrl}
            userInitials={userInitials}
            content={content}
            withLink={withLink}
            withButtonLink={withButtonLink}
            linkCallback={linkCallback}
          />
        </MUIBox>
      )}
    </Typography>
  );
};

export const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 600,
    color: theme.palette.grey[700],
  },
}));

import React, {useMemo} from 'react';
import {ResourceHeader} from 'admin/resources';
import {useFileEdit, useFileType} from '../../hooks';
import {ResourceTypeHeadingNameEnum} from 'enums';
import {Status} from 'admin/library/components';
import {getFileStatus} from '../../utils';

export const FilesEditHeader = () => {
  const {isResource, isScormPackage, isWebLink} = useFileType();
  const {loading} = useFileEdit();
  const {fileDetails, redirectToFilesAllPage, status} = useFileEdit();

  const fileProcessingStatus = getFileStatus(fileDetails?.processingDetails?.status, status);

  const heading = useMemo(() => {
    if (isResource) {
      return ResourceTypeHeadingNameEnum.File;
    }

    if (isScormPackage) {
      return ResourceTypeHeadingNameEnum.ScormPackage;
    }

    if (isWebLink) {
      return ResourceTypeHeadingNameEnum.WebLink;
    }

    return '';
  }, [isResource, isScormPackage, isWebLink]);

  const extraHeading = useMemo(
    () => <Status status={fileProcessingStatus} message={fileDetails?.processingDetails?.errorMessages} />,
    [fileProcessingStatus, fileDetails]
  );

  return (
    <ResourceHeader
      heading={!loading && heading}
      subHeading={!loading && fileDetails?.name}
      extraHeading={!loading && extraHeading}
      withBackButton
      withSubHeading
      backButtonClickHandler={redirectToFilesAllPage}
    />
  );
};

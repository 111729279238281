import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Wrapper} from 'components-lib';

export const SessionDetailsDivider = () => {
  const classes = useStyles();

  return <Wrapper className={classes.divider} />;
};

export const useStyles = makeStyles((theme) => ({
  divider: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));

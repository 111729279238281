import {makeStyles} from '@material-ui/core';
import {Column} from 'components-lib';
import React, {ReactNode, useCallback} from 'react';
import {EntityCard} from '../EntityCard/EntityCard';

interface IEntityItemsProps<T> {
  items: T[];
  itemPrefixText?: string;
  withTitle?: boolean;
  actions?: (isApproved: boolean, canBeUnpublished: boolean, id: number) => ReactNode;
  setCurrentItem?: (item: any) => void;
  handleLinkModalOpen?: () => void;
}

export function EntityItems<T>({
  items,
  itemPrefixText,
  withTitle = true,
  actions,
  setCurrentItem,
  handleLinkModalOpen,
}: IEntityItemsProps<T>) {
  const classes = useStyles();

  const openInfoLinkClickHandler = useCallback(
    (item) => {
      setCurrentItem(item);
      handleLinkModalOpen();
    },
    [handleLinkModalOpen, setCurrentItem]
  );

  return (
    <Column className={classes.root}>
      {items.map((item: any, idx) => {
        const openInfoLinkClickChanger = openInfoLinkClickHandler ? () => openInfoLinkClickHandler(item) : null;

        return (
          <EntityCard
            key={idx}
            item={item}
            position={idx}
            itemPrefixText={itemPrefixText}
            withTitle={withTitle}
            openInfoLinkClickHandler={openInfoLinkClickChanger}
            actions={
              actions &&
              actions(
                item.isApproved,
                item.canBeUnpublished,
                item.questionAnswerStackIdOriginal ?? item.questionAnswerStackId
              )
            }
          />
        );
      })}
    </Column>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}));

import React, {FC, Fragment, useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Checkbox, Input, Tooltip} from 'components-lib';
import {ListboxComponent} from './ListboxComponent';
import {isFunction, updateSelectLabel} from 'utils';
import {IOption} from 'models';
import {colors} from 'styles/colors';

interface ISearchOnTypeSelectAutocompleteProps {
  loading?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  options: IOption[];
  label: string;
  values: IOption[];
  placeholder?: string;
  withCheckboxRenderOption?: boolean;
  isFilter?: boolean;
  noOptionsText?: string;
  disableCloseOnSelect?: boolean;
  filterOptions?: (options: IOption[]) => IOption[];
  changeHandler: (option: IOption | IOption[]) => void;
  inputChangeHandler?: (value: string) => void;
}

export const SearchOnTypeSelectAutocomplete: FC<ISearchOnTypeSelectAutocompleteProps> = ({
  options,
  changeHandler,
  inputChangeHandler,
  placeholder,
  disabled = false,
  loading = false,
  multiple = false,
  noOptionsText,
  withCheckboxRenderOption = true,
  disableCloseOnSelect = false,
  filterOptions,
  values,
  isFilter = false,
}) => {
  const classes = useStyles();
  const showSelectedValues = isFilter && multiple;

  const changeHandlerInner = useCallback((_, value: any) => changeHandler(value), [changeHandler]);

  const getOption = useCallback(
    (label: string, multiple: boolean, selected: boolean) => {
      const {updatedLabel, shouldRenderDots} = updateSelectLabel(label);
      const title = shouldRenderDots ? label : '';

      return (
        <Fragment>
          {withCheckboxRenderOption && multiple && <Checkbox checked={selected} />}
          <Tooltip title={title}>{updatedLabel}</Tooltip>
        </Fragment>
      );
    },
    [withCheckboxRenderOption]
  );

  return (
    <Autocomplete
      loading={loading}
      classes={classes}
      multiple={multiple}
      value={showSelectedValues ? values : undefined}
      disabled={disabled}
      disableCloseOnSelect={disableCloseOnSelect}
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      options={options}
      filterOptions={filterOptions} // use for async requests
      noOptionsText={noOptionsText}
      getOptionLabel={(option) => option.label || ''}
      onChange={changeHandlerInner}
      onInputChange={(_, value: string, reason: string) => {
        if (reason === 'input') {
          isFunction(inputChangeHandler) && inputChangeHandler(value);
        }
      }}
      renderInput={(params) => (
        <Input {...params} loading={loading} placeholder={placeholder} withIcon withMargin={false} disabled={false} />
      )}
      renderOption={(option: IOption, {selected}) => getOption(option.label, multiple, selected)}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiAutocomplete-input': {
      width: '100%',
      padding: theme.spacing(1),
    },
  },
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
  tag: {
    margin: theme.spacing(1),

    '&:after': {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: 'absolute',
      backgroundColor: theme.palette.primary.main,
      zIndex: -1,
    },
  },
  inputRoot: ({withFontWeightBold}: {withFontWeightBold: boolean}) => {
    const muiAutocompleteInputFirstChildStyles: any = withFontWeightBold && {
      fontSize: 13,
      fontWeight: 600,
      color: theme.palette.grey[900],
    };

    return {
      '& .Mui-disabled': {
        color: theme.palette.grey[700],
        backgroundColor: colors.gray,
      },
      '&&[class*="MuiOutlinedInput-root"]': {
        padding: 3.3,
        '& .MuiAutocomplete-input:first-child': muiAutocompleteInputFirstChildStyles,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[200],
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[900],
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
      },
    };
  },

  popper: {
    width: 'fit-content',
  },
}));

import React, {useEffect} from 'react';

import {makeStyles} from '@material-ui/core';
import {Container, Loading, Row} from 'components-lib';
import {redirectWindowLocation} from 'utils';
import {config} from 'config';
import {useParams} from 'react-router';
interface IFileDownloadParamType {
  id: string;
}

export const FileDownloadPage = () => {
  const classes = useStyles();
  const {id} = useParams<IFileDownloadParamType>();
  const redirectDownloadUrl = `${config.jaEeApi.baseUrlDownloadDigitalResource}/${id}`;

  useEffect(() => {
    redirectWindowLocation(redirectDownloadUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container classes={{root: classes.container}}>
      <Container>
        <Row>
          <Loading fullHeight />
        </Row>
      </Container>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
  h1: {
    color: theme.palette.grey[700],
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

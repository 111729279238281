import {Button} from 'components-lib';
import {withRole} from 'permissions';
import {AdminRolesItems, AdminRolesItemsWithoutArea, SuperAdminRolesItems} from '../../enums';

export const ButtonPrimaryWithAdminPermissions = withRole(AdminRolesItems, '')(Button.Primary);
export const ButtonSecondaryWithAdminPermissions = withRole(AdminRolesItems, '')(Button.Secondary);
export const ButtonSecondaryWithSuperAdminPermissions = withRole(SuperAdminRolesItems, '')(Button.Secondary);
export const ButtonSecondaryWithAdminRolesItemsWithoutAreaPermissions = withRole(
  AdminRolesItemsWithoutArea,
  ''
)(Button.Secondary);

import {IBindBaseEnumEntity, IProgramsAllFilter} from 'models';
import {IProgramsAllSidebarFiltersForm} from '../../components/SidebarFilters/config';

export const mapProgramsAllPayload = (
  filter: IProgramsAllFilter,
  {gradeLevels, programYears, search}: IProgramsAllSidebarFiltersForm,
  years: {[id: number]: IBindBaseEnumEntity}
) => {
  const programYearsFilter = programYears.map((yearId) => {
    const id = years[yearId].id;
    return id;
  });

  return {
    ...filter,
    gradeLevelsFilter: gradeLevels,
    programYearsFilter,
    searchByName: search,
  };
};

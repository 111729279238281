import React, {FC} from 'react';
import {Container, makeStyles} from '@material-ui/core';
import {Form} from 'forms';
import {IWithDisabled} from 'models/common';
import {ItemWithPlaceholder} from 'filters-selections';

interface IFileUploadFormInputProps extends IWithDisabled {
  name: string;
  label: string;
  type?: string;
  min?: string;
  max?: string;
}

export const FileUploadFormInput: FC<IFileUploadFormInputProps> = ({
  label,
  name,
  disabled,
  type = 'text',
  min,
  max,
}) => {
  const classes = useStyles();

  return (
    <Container disableGutters classes={{root: classes.input}}>
      <ItemWithPlaceholder label={label} hasItems>
        <Form.InputWithField name={name} type={type} disabled={disabled} value="" min={min} max={max} />
      </ItemWithPlaceholder>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    paddingRight: theme.spacing(3),
  },
}));

import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useModal} from 'hooks';
import {useClassPaperAssessments} from '.';
import {uploadLoadingSelector} from '../store';

export const useUploadPaperAssessmentModal = () => {
  const uploadLoading = useSelector(uploadLoadingSelector);

  const {isOpen: isUploadModalOpen, setIsOpen: setIsUploadModalOpen} = useModal();

  const {uploadPaperAssessment} = useClassPaperAssessments();

  const openUploadPaperAssessmentModal: () => void = useCallback(() => setIsUploadModalOpen(true), [
    setIsUploadModalOpen,
  ]);

  const closeUploadPaperAssessmentModal: () => void = useCallback(() => setIsUploadModalOpen(false), [
    setIsUploadModalOpen,
  ]);

  return {
    uploadLoading,
    isUploadModalOpen,
    setIsUploadModalOpen,
    openUploadPaperAssessmentModal,
    closeUploadPaperAssessmentModal,
    uploadPaperAssessment,
  };
};

import React from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {filtersPeriodSelector, ItemWithPlaceholder} from 'filters-selections';
import {Accordion, Row} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {Form} from 'forms';
import {IWithDisabled} from 'models';

export function FromToDateFilter({disabled}: IWithDisabled) {
  const classes = useStyles();
  const filter = useSelector(filtersPeriodSelector);

  return (
    <Accordion heading="Start Date / End Date">
      <Row justify="space-between">
        <Row classes={{root: classes.startDateContainer}}>
          <ItemWithPlaceholder label="Start Date" hasItems>
            <Form.DatePicker
              disabled={disabled}
              name={FormNameEnum.startDate}
              value={filter.startDate}
              format="MM/dd/yyyy"
              withMaxDate
              maxWidth
              maxDateFormField={FormNameEnum.endDate}
            />
          </ItemWithPlaceholder>
        </Row>

        <Row classes={{root: classes.endDateContainer}}>
          <ItemWithPlaceholder label="End Date" hasItems>
            <Form.DatePicker
              disabled={disabled}
              name={FormNameEnum.endDate}
              value={filter.endDate}
              format="MM/dd/yyyy"
              withMinDate
              maxWidth
              minDateFormField={FormNameEnum.startDate}
            />
          </ItemWithPlaceholder>
        </Row>
      </Row>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  startDateContainer: {
    padding: theme.spacing(2, 0),
  },
  endDateContainer: {
    padding: theme.spacing(2, 0),
  },
}));

import {config} from 'config';
import {ApiPathsEnum} from 'enums';
import {IGetUserActivityExportResponse, TUserRolesApiResponse} from 'models';
import {IFetchUserActivityQueryParams} from 'permissions';
import {get, post} from './http-client';
import {IHttpResponse} from './models';

const {jaEeApi: apiConfig} = config;

export const permissionsApi = {
  async getUserRole(): Promise<IHttpResponse<TUserRolesApiResponse>> {
    const url = `${apiConfig.baseUrlPermissions}/${ApiPathsEnum.PERMISSION}/GetUserRoles`;
    return await get<TUserRolesApiResponse>(url);
  },

  async getUserRolesList(): Promise<IHttpResponse<TUserRolesApiResponse>> {
    const url = `${apiConfig.baseUrlPermissions}/${ApiPathsEnum.PERMISSION}/GetRolesList`;
    return await get<TUserRolesApiResponse>(url);
  },

  async getUserActivityExport(
    url: string,
    params: IFetchUserActivityQueryParams
  ): Promise<IHttpResponse<IGetUserActivityExportResponse>> {
    return await post<IGetUserActivityExportResponse>(url, params);
  },
};

import {Button} from 'components-lib';
import {Form} from 'forms';
import {ActiveModeEnum, useQueryParams} from 'hooks';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {usePublish} from '../../hooks';
import {deletingSelector, savingSelector} from '../../store';

import {
  ButtonPrimaryWithAdminPermissions,
  ButtonSecondaryWithAdminPermissions,
  ButtonSecondaryWithAdminRolesItemsWithoutAreaPermissions,
} from '../buttonsWithAdminPermissions/buttonsWithAdminPermissions';

interface IHeaderActionButtonsProps {
  itemId: number;
  isPublished: boolean;
  editMode: ActiveModeEnum;
  canDelete?: boolean;
  editModeClickHandler: () => void;
  dismissClickHandler: () => void;
  deleteModalOpenHandler: () => void;
  deleteAnswerStackHandler: () => void;
}

export function HeaderActionButtons({
  itemId,
  isPublished,
  editMode,
  canDelete,
  editModeClickHandler,
  dismissClickHandler,
  deleteModalOpenHandler,
  deleteAnswerStackHandler,
}: IHeaderActionButtonsProps) {
  const saving = useSelector(savingSelector);
  const {publishingStack, publishAllAnswerStacksForQuestion} = usePublish();
  const {questionId} = useQueryParams();

  const isCreateMode = editMode === ActiveModeEnum.create;
  const isEditMode = editMode === ActiveModeEnum.edit;
  const isCreateOrEditMode = editMode === ActiveModeEnum.edit || isCreateMode;

  const publishHandler = useCallback(() => {
    publishAllAnswerStacksForQuestion(itemId);
  }, [itemId, publishAllAnswerStacksForQuestion]);

  if (isCreateOrEditMode) {
    const submitContent = questionId || !isCreateMode ? 'Save' : 'Create';

    return (
      <div>
        {isCreateOrEditMode && (
          <DeleteButton
            isEditMode={isEditMode}
            clickHandler={isCreateMode ? deleteModalOpenHandler : deleteAnswerStackHandler}
            disabled={isCreateMode && !canDelete}
          />
        )}
        <DismissButton clickHandler={dismissClickHandler} />

        <Form.ButtonSubmit text={submitContent} size="large" disabled={saving} />
      </div>
    );
  }

  return (
    <div>
      <DismissButton clickHandler={dismissClickHandler} />
      <DeleteButton clickHandler={deleteModalOpenHandler} disabled={!canDelete} />

      <ButtonSecondaryWithAdminPermissions clickHandler={editModeClickHandler} disabled={publishingStack}>
        Edit
      </ButtonSecondaryWithAdminPermissions>

      {!isPublished && (
        <ButtonPrimaryWithAdminPermissions
          clickHandler={!publishingStack ? publishHandler : null}
          disabled={isPublished || publishingStack}
        >
          Publish
        </ButtonPrimaryWithAdminPermissions>
      )}
    </div>
  );
}

interface IButtonProps {
  clickHandler: () => void;
  disabled?: boolean;
  isEditMode?: boolean;
}

function DismissButton({clickHandler}: IButtonProps) {
  return <Button.Secondary clickHandler={clickHandler}>Dismiss</Button.Secondary>;
}

function DeleteButton({clickHandler, disabled = false, isEditMode = false}: IButtonProps) {
  const deleting = useSelector(deletingSelector);
  const deleteContent = deleting ? 'Loading...' : 'Delete';
  const shouldDisableDelete = deleting || disabled;

  if (isEditMode) {
    return (
      <ButtonSecondaryWithAdminPermissions clickHandler={clickHandler} disabled={shouldDisableDelete}>
        {deleteContent}
      </ButtonSecondaryWithAdminPermissions>
    );
  }

  return (
    <ButtonSecondaryWithAdminRolesItemsWithoutAreaPermissions
      clickHandler={clickHandler}
      disabled={shouldDisableDelete}
    >
      {deleteContent}
    </ButtonSecondaryWithAdminRolesItemsWithoutAreaPermissions>
  );
}

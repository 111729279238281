import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Label, Wrapper} from 'components-lib';
import {Form} from 'forms';

export const ItemAnswerRangeInput = ({label, name}) => {
  const classes = useStyles();
  return (
    <Wrapper className={classes.descriptionItem}>
      {label && <Label label={label} />}
      <Form.Input name={name} />
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
  },
  descriptionItem: (withWidth: boolean) => {
    let styles: any = {marginTop: theme.spacing(3), marginRight: theme.spacing(3), marginBottom: theme.spacing(3)};

    if (withWidth) {
      styles = {...styles, width: '100%'};
    }
    return styles;
  },
}));

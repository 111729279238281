import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {ItemWithPlaceholder} from 'filters-selections';
import {Form} from 'forms';
import {IWithDisabled} from 'models/common';
import {Container} from 'components-lib';

interface IResourcesFormTextAreaProps extends IWithDisabled {
  label: string;
  name: string;
  placeholder?: string;
  rows?: number;
}

export const ResourcesFormTextArea: FC<IResourcesFormTextAreaProps> = ({
  label,
  name,
  disabled,
  placeholder = '',
  rows = 6,
}) => {
  const classes = useStyles();

  return (
    <Container disableGutters classes={{root: classes.textArea}}>
      <ItemWithPlaceholder label={label} hasItems>
        <Form.TextArea disabled={disabled} name={name} rows={rows} placeholder={placeholder} />
      </ItemWithPlaceholder>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  textArea: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

import React, {useMemo} from 'react';
import {Accordion, Column, Text} from 'components-lib';
import {useSelector} from 'react-redux';
import {IOption, IWithDisabled} from 'models';
import {FormNameEnum, filterIntegrationStatusesEntitiesCollectionSelector} from 'filters-selections';
import {Form} from 'forms';

type IIntegrationTypeMultiFilterProps = IWithDisabled;

export const IntegrationStatusMultiFilter = ({disabled}: IIntegrationTypeMultiFilterProps) => {
  const integrationStatuses = useSelector(filterIntegrationStatusesEntitiesCollectionSelector);

  const options: IOption[] = useMemo(() => {
    return integrationStatuses.map((integrationType) => ({
      value: integrationType.id,
      label: integrationType.displayText,
    }));
  }, [integrationStatuses]);

  return (
    <Accordion heading="Statuses">
      <Column>
        {integrationStatuses.length ? (
          <Form.SelectAutocomplete
            name={FormNameEnum.integrationStatuses}
            options={options}
            multiple
            disabled={disabled}
          />
        ) : (
          <Text.Caption>No Statuses</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
};

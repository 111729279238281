import React, {useCallback, useMemo} from 'react';
import {FormikHelpers} from 'formik';
import {InstrumentsFilterNamesEnum} from 'admin/library/instruments/enums';
import {Filters} from 'components-lib';
import {FiltersEnum, FiltersList} from 'filters-selections';
import {Form} from 'forms';
import {ISidebarBuilderFilterForm, formValidationSchema} from './sidebarBuilderBaseConfig';
import {usePermissions} from 'permissions';
import {AdminRolesItemsWithoutArea} from 'admin/library/items/enums';
import {useBuilder, useBuilderState, usePoolItemsPagination} from '../../hooks';

const filterList = [
  FiltersEnum.PillarMulti,
  FiltersEnum.EvaluationType,
  FiltersEnum.Domain,
  FiltersEnum.ProgramMulti,
  FiltersEnum.LearningObjective,
  FiltersEnum.Category,
  FiltersEnum.Competency,
  FiltersEnum.Concept,
  FiltersEnum.Administration,
  FiltersEnum.SchoolLevel,
  FiltersEnum.Areas,
];
interface ISidebarBuilderViewProps {
  submitHandler: (data: ISidebarBuilderFilterForm, actions?: FormikHelpers<any>) => void;
  disableAll?: boolean;
}

export function SidebarBuilderView({submitHandler, disableAll}: ISidebarBuilderViewProps) {
  const {pageLoading} = useBuilder();
  const {resetBuilderFilter, setSearch, builderFilter, searchText, getPoolItems} = useBuilderState();
  const {initialPage} = usePoolItemsPagination();

  const {hasPermission} = usePermissions();

  const initialValues: ISidebarBuilderFilterForm = useMemo(
    () => ({
      [InstrumentsFilterNamesEnum.PILLARS]: builderFilter[InstrumentsFilterNamesEnum.PILLARS],
      [InstrumentsFilterNamesEnum.EVALUATION_TYPE]: builderFilter[InstrumentsFilterNamesEnum.EVALUATION_TYPE],
      [InstrumentsFilterNamesEnum.DOMAIN]: builderFilter[InstrumentsFilterNamesEnum.DOMAIN],
      [InstrumentsFilterNamesEnum.PROGRAMS]: builderFilter[InstrumentsFilterNamesEnum.PROGRAMS],
      [InstrumentsFilterNamesEnum.LEARNING_OBJECTIVE]: builderFilter[InstrumentsFilterNamesEnum.LEARNING_OBJECTIVE],
      [InstrumentsFilterNamesEnum.CATEGORY]: builderFilter[InstrumentsFilterNamesEnum.CATEGORY],
      [InstrumentsFilterNamesEnum.COMPETENCY]: builderFilter[InstrumentsFilterNamesEnum.COMPETENCY],
      [InstrumentsFilterNamesEnum.CONCEPT]: builderFilter[InstrumentsFilterNamesEnum.CONCEPT],
      [InstrumentsFilterNamesEnum.ADMINISTRATION]: builderFilter[InstrumentsFilterNamesEnum.ADMINISTRATION],
      [InstrumentsFilterNamesEnum.SCHOOL_LEVEL]: builderFilter[InstrumentsFilterNamesEnum.SCHOOL_LEVEL],
      [InstrumentsFilterNamesEnum.SEARCH]: searchText,
      [InstrumentsFilterNamesEnum.AREAS]: builderFilter[InstrumentsFilterNamesEnum.AREAS],
    }),
    [builderFilter, searchText]
  );

  const clearButtonClickHandler = useCallback(() => {
    resetBuilderFilter();
    setSearch('');
    getPoolItems(initialPage);
  }, [resetBuilderFilter, setSearch, getPoolItems, initialPage]);

  const renderFiltersList = useMemo(() => {
    /**
     * JA Area Filter  is visible only for Super and National Admins.
     */

    const shouldRenderAreaFilter = hasPermission(AdminRolesItemsWithoutArea);
    /**
     * Constant index: please see a bug: (https://mentormate.atlassian.net/browse/JADF-619)
     */

    const lastFilterIndex = 10;

    if (!shouldRenderAreaFilter) {
      filterList.splice(lastFilterIndex, 1);
    }

    return <FiltersList disableAll={pageLoading || disableAll} filters={filterList} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLoading]);

  return (
    <Form.Form<ISidebarBuilderFilterForm>
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      submitHandler={submitHandler}
    >
      <Filters.FiltersPanel
        clearButtonClickHandler={clearButtonClickHandler}
        filters={renderFiltersList}
        searchFieldName={InstrumentsFilterNamesEnum.SEARCH}
      />
    </Form.Form>
  );
}

import {createAsyncThunk} from '@reduxjs/toolkit';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {itemImportApi} from 'api';
import {TGetItemBankVerificationApiResponse} from '../../../../models/library';

export const fetchItemBankPreviewTableRowsThunk: any = createAsyncThunk<TGetItemBankVerificationApiResponse, null>(
  `${prefix} fetchItemBankPreviewTableRows`,
  async (itemImportId: number, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await itemImportApi.getItemBankVerification(itemImportId);
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

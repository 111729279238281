import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {FormNameEnum} from 'filters-selections';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {fetchDefualtKeyTermsThunk, filtersDefaultKeyTermsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {useAppDispatch} from 'hooks';

export function K12KeyTermsSelect() {
  const dispatch = useAppDispatch();
  const keyTerms = useSelector(filtersDefaultKeyTermsEntitiesCollectionSelector);

  useEffect(() => {
    if (!keyTerms.length) {
      dispatch(fetchDefualtKeyTermsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const options = useMemo(() => {
    const res = keyTerms.map((keyTerm) => ({value: keyTerm.id, label: keyTerm.name}));
    return res;
  }, [keyTerms]);

  return (
    <ItemWithPlaceholder label="Key Terms" hasItems={!!keyTerms.length}>
      <Form.SelectAutocomplete name={FormNameEnum.keyTerms} options={options} multiple />
    </ItemWithPlaceholder>
  );
}

import React, {FC} from 'react';
import {FormNameEnum} from 'filters-selections';
import {TextInputSelection} from '../TextInputSelection/TextInputSelection';
import {IWithDisabled} from 'models/common';

type IUserToImpersonateTextInputProps = IWithDisabled;

export const UserToImpersonateTextInput: FC<IUserToImpersonateTextInputProps> = ({disabled}) => (
  <TextInputSelection name={FormNameEnum.masterClassroomEmailAccount} label="User to Impersonate" disabled={disabled} />
);

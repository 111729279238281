import {useSelector} from 'react-redux';
import {savingSelector} from './../store';

export const useItemSavingActions = () => {
  const saving = useSelector(savingSelector);

  return {
    saving,
  };
};

import {IUserStoreState} from './IUserStoreState';

export const userInitialState: IUserStoreState = {
  profile: {
    id: 0,
    areaId: null,
  },
  loading: true,
  error: false,
};

import React, {useCallback} from 'react';
import {Button, Column, Modal, Row, Text} from 'components-lib';
import {ReactComponent as IllustrationTrash} from 'resources/images/instruments/Illustration_trash.svg';
import {useFormikContext} from 'formik';

export const ModalCancelAddIntegration = ({isOpen, handleClose, handleCancelAddIntegration}) => {
  const {resetForm} = useFormikContext();

  const closeClickHandler = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const cancelAddIntegrationClickHandler = useCallback(() => {
    resetForm();
    handleCancelAddIntegration();
  }, [handleCancelAddIntegration, resetForm]);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Cancel Integration Creation" size="small">
      <Column>
        <Row align="center">
          <IllustrationTrash />
          <Text.Paragraph align="center" withMargin>
            Are you sure you want to cancel the creation of a new integration?
          </Text.Paragraph>
          <Text.Paragraph>This action cannot be undone!</Text.Paragraph>
        </Row>
      </Column>
      <>
        <Button.Secondary clickHandler={closeClickHandler} size="small">
          Close
        </Button.Secondary>
        <Button.Primary clickHandler={cancelAddIntegrationClickHandler} size="small">
          Yes
        </Button.Primary>
      </>
    </Modal>
  );
};

import {SortingOrderEnum} from 'enums';
import {GoogleSitesOrderingEnum} from '../enums/GoogleSitesListOrdering.enum';

export const googleSiteListOrderingMap = {
  [GoogleSitesOrderingEnum.Name]: {
    [SortingOrderEnum.ASC]: 'Name: A-Z',
    [SortingOrderEnum.DESC]: 'Name: Z-A',
  },
  [GoogleSitesOrderingEnum.LastModifiedDate]: {
    [SortingOrderEnum.ASC]: 'Last Modified Date: Asc',
    [SortingOrderEnum.DESC]: 'Last Modified Date: Desc',
  },
};

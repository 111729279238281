import {SortingOrderEnum} from 'enums';
import {IFetchClassPaperAssessmentRawPayload, IGetPageOfPaperAssessmentsPayload} from 'models';

export const mapFetchClassPaperAssessmentsPayload = ({
  sortOrder,
  pageNumber,
  pageSize,
  classId,
  programId,
}: IFetchClassPaperAssessmentRawPayload): IGetPageOfPaperAssessmentsPayload => ({
  sortOptions: {
    sortBy: sortOrder.order,
    sortAsc: sortOrder.sort === SortingOrderEnum.ASC,
  },
  pagination: {
    pageNumber,
    pageSize,
  },
  classId,
  programId,
});

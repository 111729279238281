import React from 'react';
import {ISurvey} from 'survey-react';
import {SurveyTakerContent} from './components';

interface ISurveyTakerPreviewProps {
  survey: ISurvey;
  isPreviewWithAnswers?: boolean;
}

export function SurveyTakerPreview({survey, isPreviewWithAnswers}: ISurveyTakerPreviewProps) {
  return (
    <SurveyTakerContent
      survey={survey}
      error={false}
      loading={false}
      surveyTaken={false}
      isPreview={!isPreviewWithAnswers}
      isPreviewWithAnswers={isPreviewWithAnswers}
    />
  );
}

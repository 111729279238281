import React, {FC, ReactNode, useCallback, useState} from 'react';
import {makeStyles, Tooltip as MUITooltip} from '@material-ui/core';
import {IWithChildren} from 'models/common';
import {colors} from 'styles';

enum TooltipMaxWidthEnum {
  Small = 300,
  Large = 500,
}
interface ITooltipProps extends IWithChildren {
  size?: 'small' | 'large';
  title: string | ReactNode;
  disableFocusListener?: boolean;
  disableTouchListener?: boolean;
  disableHoverListener?: boolean;
  open?: boolean;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  onOpen?: () => void;
  onClose?: () => void;
}

export const Tooltip: FC<ITooltipProps> = ({
  size = 'small',
  title,
  children,
  disableFocusListener = false,
  disableTouchListener = false,
  disableHoverListener = false,
  open = false,
  onOpen,
  onClose,
  placement = 'bottom',
}) => {
  const classes = useStyles({size});
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleOnTooltipClose = useCallback(() => {
    setShowTooltip(false);
  }, [setShowTooltip]);

  const handleOnToolTipOpen = useCallback(() => {
    setShowTooltip(true);
  }, [setShowTooltip]);

  //Wrap the children inside a fragment, see: github.com/DefinitelyTyped/DefinitelyTyped/issues/18051

  return (
    <MUITooltip
      placement={placement}
      open={open ? open : showTooltip}
      onOpen={onOpen ? onOpen : handleOnToolTipOpen}
      onClose={onClose ? onClose : handleOnTooltipClose}
      title={title}
      disableFocusListener={disableFocusListener}
      disableTouchListener={disableTouchListener}
      disableHoverListener={disableHoverListener}
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      <span>{children}</span>
    </MUITooltip>
  );
};

const useStyles = makeStyles((theme) => ({
  tooltip: ({size}: Pick<ITooltipProps, 'size'>) => {
    const isSmall = size === 'small';

    return {
      padding: theme.spacing(3),
      fontSize: 13,
      background: colors.white,
      boxShadow: '0px 16px 24px #26262614',
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: 5,
      opacity: 1,
      color: theme.palette.grey[900],
      cursor: 'pointer',
      maxWidth: isSmall ? TooltipMaxWidthEnum.Small : TooltipMaxWidthEnum.Large,
    };
  },
}));

import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';

const getProgramsState = (state: TStateStore) => state.programs.all;

export const pageSelector = createSelector(getProgramsState, (programs) => programs.page);
export const pageLoadingSelector = createSelector(getProgramsState, (programs) => programs.page.loading);

export const programsSelector = createSelector(getProgramsState, (programs) => programs.programs);

export const filterSelector = createSelector(getProgramsState, (programs) => programs.filter);
export const paginationSelector = createSelector(getProgramsState, (programs) => programs.pagination);

export const programsAllSortOrderSelector = createSelector(getProgramsState, (programs) => programs.sortOrder);

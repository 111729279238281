import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {useFormikContext} from 'formik';
import {
  LearningPathDetails,
  LearningPathDetailsFirstCol,
  LearningPathDetailsSecondCol,
} from 'admin/resources/learning-paths/components';
import {ILearningPathViewCreateFormBaseValues} from '../../utils';
import {useLearningPathBuilderItemsEntities} from 'admin/resources/learning-paths/views/session-builder';

interface ICreateLearningPathDetailsProps {
  isDirty: boolean;
  setIsDirtyCallback: Dispatch<SetStateAction<boolean>>;
}

export const CreateLearningPathDetails: FC<ICreateLearningPathDetailsProps> = ({isDirty, setIsDirtyCallback}) => {
  const {dirty, isValid} = useFormikContext<ILearningPathViewCreateFormBaseValues>();
  const {isSomeOfSessionsWithEmptyName} = useLearningPathBuilderItemsEntities();

  useEffect(() => {
    if ((dirty && isValid && !isDirty) || isSomeOfSessionsWithEmptyName) {
      setIsDirtyCallback(true);
    } else if (!isValid) {
      setIsDirtyCallback(false);
    }
  }, [isDirty, setIsDirtyCallback, dirty, isValid, isSomeOfSessionsWithEmptyName]);

  return (
    <LearningPathDetails>
      <LearningPathDetailsFirstCol />
      <LearningPathDetailsSecondCol />
    </LearningPathDetails>
  );
};

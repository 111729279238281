import {ContentState, EditorState, SelectionState} from 'draft-js';
import {useFormikContext} from 'formik';
import htmlToDraft from 'html-to-draftjs';
import {useCallback, useState, useEffect} from 'react';

export const useRichTextEditor = (fieldName: string) => {
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  const {values} = useFormikContext();
  const value = values[fieldName];

  const getEditorText = useCallback((raw: ContentState) => raw.getPlainText(), []);

  const moveSelectionToEnd = (editorState: EditorState) => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    });
    return EditorState.forceSelection(editorState, selection);
  };

  const prepareDraftFromHTML = useCallback((value) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState?.createFromBlockArray(draft?.contentBlocks);

    return EditorState.createWithContent(contentState);
  }, []);

  useEffect(() => {
    if (value) {
      const editorState = prepareDraftFromHTML(value);
      const newState = value ? moveSelectionToEnd(editorState) : EditorState.createEmpty();
      setEditorState(newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    editorState,
    setEditorState,
    getEditorText,
    prepareDraftFromHTML,
  };
};

import {FormControlLabel, makeStyles, Radio, RadioGroup} from '@material-ui/core';
import {Column, Text} from 'components-lib';
import {ProgramsSectionNavigatorEnum} from 'enums';
import {useNavigate} from 'hooks';
import {pages} from 'paths';
import React, {useCallback} from 'react';
import {useParams, useRouteMatch} from 'react-router-dom';
import {toNumber} from 'utils';

const options = [
  {label: 'Show Classes', value: ProgramsSectionNavigatorEnum.CLASSES},
  {label: 'Show Master Instruments', value: ProgramsSectionNavigatorEnum.MASTER_INSTRUMENTS},
  {label: 'Show Reports', value: ProgramsSectionNavigatorEnum.REPORTS},
  {label: 'Show Exports', value: ProgramsSectionNavigatorEnum.EXPORTS},
];

const pathToViewMap = {
  [pages.adminPortal.programs.details]: ProgramsSectionNavigatorEnum.CLASSES,
  [pages.adminPortal.programs.masterInstruments]: ProgramsSectionNavigatorEnum.MASTER_INSTRUMENTS,
  [pages.adminPortal.programs.reports]: ProgramsSectionNavigatorEnum.REPORTS,
  [pages.adminPortal.programs.exports]: ProgramsSectionNavigatorEnum.EXPORTS,
};

const viewToLinkMap = {
  [ProgramsSectionNavigatorEnum.CLASSES]: pages.adminPortal.programs.detailsLink,
  [ProgramsSectionNavigatorEnum.MASTER_INSTRUMENTS]: pages.adminPortal.programs.masterInstrumentsLink,
  [ProgramsSectionNavigatorEnum.REPORTS]: pages.adminPortal.programs.reportsLink,
  [ProgramsSectionNavigatorEnum.EXPORTS]: pages.adminPortal.programs.exportsLink,
};

export function ViewExpansionPanel() {
  const {programId} = useParams<{programId: string}>();
  const {push} = useNavigate();
  const {path} = useRouteMatch();
  const classes = useStyles();

  const onChange = useCallback((_event: any, value: any) => push(`${viewToLinkMap[toNumber(value)]}/${programId}`), [
    programId,
    push,
  ]);

  return (
    <Column className={classes.root}>
      <Text.Heading as="h4">View</Text.Heading>
      <RadioGroup aria-label="choose_view" name="choose_view" value={pathToViewMap[path]} onChange={onChange}>
        {options.map((option: any, i: number) => (
          <FormControlLabel value={option.value} control={<Radio color="primary" />} label={option.label} key={i} />
        ))}
      </RadioGroup>
    </Column>
  );
}

const useStyles = makeStyles({
  root: {
    paddingLeft: 10,
  },
});

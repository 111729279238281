import {createSlice} from '@reduxjs/toolkit';
import {programClassesInitialState} from './programClasses.initialState';
import {programClassesReducer} from './programClasses.reducer';
import {programClassesExtraReducer} from './programClassesExtra.reducer';

export const programClassesSlice = createSlice({
  name: 'instruments',
  initialState: programClassesInitialState,
  reducers: programClassesReducer,
  extraReducers: programClassesExtraReducer,
});

export const {actions: programClassesActions} = programClassesSlice;

import {createSelector} from '@reduxjs/toolkit';
import {TBuilderItemBase} from 'models';
import {TStateStore} from 'store';
import {values} from 'utils';

const getInstrumentsState = (state: TStateStore) => state.library.instruments;
const getBuilderState = (state: TStateStore) => state.library.instruments.builder;

export const instrumentsPageSelector = createSelector(getInstrumentsState, (instruments) => instruments.page);
export const pageLoadingSelector = createSelector(getInstrumentsState, (instruments) => instruments.page.loading);
export const savingSelector = createSelector(getInstrumentsState, (instruments) => instruments.page.saving);
export const deletingSelector = createSelector(getInstrumentsState, (instruments) => instruments.page.deleting);
export const paginationSelector = createSelector(getInstrumentsState, (instruments) => instruments.pagination);

export const activeTemplateSelector = createSelector(
  getInstrumentsState,
  (instruments) => instruments.templates.entities
);

export const surveyForPreviewTemplateSelector = createSelector(
  getInstrumentsState,
  (instruments) => instruments.survey.previewTemplate.entities
);

export const builderPoolItemsIdsSelector = createSelector(getBuilderState, (builder) => builder.poolItems.result);
export const builderPoolItemsLoadingSelector = createSelector(
  getBuilderState,
  (builder) => builder.builderItems.loading
);
export const builderPoolItemsEntitiesSelector = createSelector(
  getBuilderState,
  (builder) => builder.poolItems.entities.items
);
export const builderPoolItemsEntitiesBaseCollectionSelector = createSelector(
  [builderPoolItemsIdsSelector, builderPoolItemsEntitiesSelector],
  (ids, poolItems): TBuilderItemBase[] => {
    return ids.map((id) => {
      const item = poolItems[id];
      const questionAnswerStackId = item.elements[0] ? item.elements[0].questionAnswerStackId : -1;

      return {
        id,
        questionText: item?.text,
        questionType: item?.questionOptionsTypeId,
        questionAnswerStackId,
      };
    });
  }
);

export const builderPossiblePretestItemsIdsSelector = createSelector(
  getBuilderState,
  (builder) => builder.pretestItems.result
);

export const builderPossiblePretestItemsEntitiesSelector = createSelector(
  getBuilderState,
  (builder) => builder.pretestItems.entities.items
);
export const builderPossiblePretestEntitiesBaseCollectionSelector = createSelector(
  [builderPossiblePretestItemsIdsSelector, builderPossiblePretestItemsEntitiesSelector],
  (ids, pretestItems) => {
    return ids.map((id) => {
      const item = pretestItems[id];

      let questionAnswerStackId: any = -1;

      if (item.elements.length > 1) {
        questionAnswerStackId = item.elements.map((el) => el.questionAnswerStackId);
      } else if (item.elements.length === 1) {
        questionAnswerStackId = item.elements[0].questionAnswerStackId;
      }

      return {
        id,
        questionText: item?.text,
        questionType: item?.questionOptionsTypeId,
        questionAnswerStackId,
        questionStackChoices: item?.elements,
      };
    });
  }
);

export const builderItemsIdsSelector = createSelector(getBuilderState, (builder) => builder.builderItems.result);
export const builderItemsEntitiesSelector = createSelector(
  getBuilderState,
  (builder) => builder.builderItems.entities.items ?? {}
);
export const builderItemsResultSelector = createSelector(
  getBuilderState,
  (builder) => builder.builderItems.result ?? []
);
export const builderItemsCollectionSelector = createSelector(getBuilderState, (builder) => {
  return builder.builderItems.entities?.items ? values(builder.builderItems.entities?.items) : [];
});

export const pagesTotalSelector = createSelector(getBuilderState, (builder) => builder.pagesTotal);
export const searchTextSelector = createSelector(getBuilderState, (builder) => builder.searchText);
export const instrumentsBuilderFilterSelector = createSelector(getBuilderState, (builder) => builder.filter);

export const instrumentsAllSelector = createSelector(getInstrumentsState, (instruments) => instruments.templates);
export const instrumentsAllFilterSelector = createSelector(getInstrumentsState, (instruments) => instruments.filter);

export const instrumentsAllSortOrderSelector = createSelector(
  getInstrumentsState,
  (instruments) => instruments.sortOrder
);

import {IActionFulfilled, IProgramsAllFilter, IProgramsAllSortOrder} from 'models';
import {cloneDeep} from 'utils';

import {IProgramsAllState} from './IProgramsAllState';
import {programsAllInitialState} from './programsAll.initialState';

export const programsAllReducer = {
  setFilter: (state: IProgramsAllState, action: IActionFulfilled<null, IProgramsAllFilter>) => {
    state.filter = action.payload;
    resetPagination(state);
  },

  resetFilter: (state: IProgramsAllState) => {
    state.filter = cloneDeep(programsAllInitialState.filter);
    resetPagination(state);
  },

  setCurrentPage: (state: IProgramsAllState, action: IActionFulfilled<null, number>) => {
    state.pagination.currentPage = action.payload;
  },

  setPerPage: (state: IProgramsAllState, action: IActionFulfilled<null, number>) => {
    state.pagination.perPage = action.payload;
    resetPagination(state);
  },

  setSortOrderProgramsAll: (state: IProgramsAllState, action: IActionFulfilled<null, IProgramsAllSortOrder>) => {
    state.sortOrder = action.payload;
    resetPagination(state);
  },
};

const resetPagination = (state: IProgramsAllState) => {
  state.pagination.currentPage = programsAllInitialState.pagination.currentPage;
  state.pagination.totalCount = programsAllInitialState.pagination.totalCount;
};

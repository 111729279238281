import {SortingOrderEnum} from 'enums';
import {ProgramClassInstrumentsOrderingEnum} from '../../enums';

export const programClassInstrumentsOrderingMap = {
  [ProgramClassInstrumentsOrderingEnum.Name]: {
    [SortingOrderEnum.ASC]: 'Name: A-Z',
    [SortingOrderEnum.DESC]: 'Name: Z-A',
  },
  [ProgramClassInstrumentsOrderingEnum.Type]: {
    [SortingOrderEnum.ASC]: 'Type: Pre-Post',
    [SortingOrderEnum.DESC]: 'Type: Post-Pre',
  },
};

import * as Yup from 'yup';
import {FormNameEnum} from 'filters-selections';

export const sidebarFiltersValidationSchema = Yup.object().shape({
  [FormNameEnum.search]: Yup.string(),
  [FormNameEnum.instrumentStatus]: Yup.array(Yup.number()),
  [FormNameEnum.audience]: Yup.array(Yup.number()),
  [FormNameEnum.instrumentType]: Yup.array().of(Yup.number()),
  [FormNameEnum.phase]: Yup.array().of(Yup.number()),
  [FormNameEnum.pillar]: Yup.array(Yup.number()),
  [FormNameEnum.instrumentViewType]: Yup.number().nullable(),
});

import React from 'react';
import {makeStyles} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import {IComponentWithChildren} from 'models';
import {colors} from 'styles/colors';

interface INavigationLinkProps extends IComponentWithChildren {
  to: string;
  type?: 'primary' | 'secondary';
  withDisabled?: boolean;
}

const secondaryActiveStyle = {color: colors.primary};
const primaryActiveStyle = {...secondaryActiveStyle, borderBottom: `3px solid ${colors.primary}`};

export function NavigationLink({to, type = 'primary', children, withDisabled = false}: INavigationLinkProps) {
  const classes = useStyles({type, withDisabled});
  return (
    <NavLink
      to={to}
      className={classes.root}
      activeStyle={type === 'primary' ? primaryActiveStyle : secondaryActiveStyle}
      type={type}
      color="grey60"
    >
      {children}
    </NavLink>
  );
}

type TRootStyles = Pick<INavigationLinkProps, 'type' | 'withDisabled'>;

const useStyles = makeStyles((theme) => ({
  root: ({type, withDisabled}: TRootStyles) => {
    const baseHeight = type === 'primary' ? 66 : 48;

    let styles: any = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: baseHeight / 1.375,
      lineHeight: `${baseHeight - 3}px`,
      height: `${baseHeight - 3}px`,
      padding: 0,
      color: theme.palette.grey[700],
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.grey[800],
      },
    };

    if (withDisabled) {
      styles = {
        ...styles,
        pointerEvents: 'none',
      };
    }

    return styles;
  },
}));

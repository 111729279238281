import React, {useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {useBeforeunload} from 'react-beforeunload';
import {Prompt} from 'react-router-dom';
import {Form} from 'forms';
import {Button, Column, Modal, Row, Text} from 'components-lib';
import {IWithModal} from 'models';
import {FormNameEnum, SelectionEnum, SelectionListItems} from 'filters-selections';
import {useUserActivity} from 'permissions';
import {useFormikContext} from 'formik';
import {useDebounce} from 'hooks';
import {exportUserActivityMessages} from 'admin/utils';

interface IModalUserActivityProps extends IWithModal {
  title?: string;
}

export function ModalUserActivity({isOpen, setIsOpen, title = 'Export User Login Data'}: IModalUserActivityProps) {
  const classes = useStyles();
  const {exporting} = useUserActivity();
  const {resetForm, errors, dirty} = useFormikContext();
  const debouncedReset = useDebounce(() => resetForm());

  useBeforeunload(() => {
    if (exporting || dirty) {
      return exportUserActivityMessages.warning;
    }
  });

  const datePickerErrors = errors[FormNameEnum.customValidation];

  const closeHandler = useCallback(() => {
    setIsOpen(false);
    debouncedReset();
  }, [debouncedReset, setIsOpen]);

  const renderFromDatepicker = useMemo(
    () => <SelectionListItems list={firstColList} className={classes.datepicker} withMargin={false} />,
    [classes.datepicker]
  );

  const renderToDatepicker = useMemo(
    () => <SelectionListItems list={secondColList} className={classes.datepicker} withMargin={false} />,
    [classes.datepicker]
  );

  const DatePickers = useMemo(
    () => () => (
      <div className={classes.datePickersContainer}>
        {renderFromDatepicker}
        {renderToDatepicker}
      </div>
    ),
    [classes, renderToDatepicker, renderFromDatepicker]
  );

  const RoleList = useMemo(
    () => () => {
      return <SelectionListItems list={roleList} withFullWidth />;
    },
    []
  );

  return (
    <Modal isOpen={isOpen} handleClose={closeHandler} title={title} size="medium" disableBackdropClick>
      <Column>
        <Row align="center" classes={{root: classes.content}}>
          <DatePickers />
          {datePickerErrors && (
            <Row justify="flex-start" align="flex-start">
              <Text.Error>{datePickerErrors}</Text.Error>
            </Row>
          )}
          <RoleList />
        </Row>
        <Prompt when={exporting} message={exportUserActivityMessages.warning} />
      </Column>
      <div>
        <Button.Secondary clickHandler={closeHandler} size="small">
          Cancel
        </Button.Secondary>
        <Form.ButtonSubmit text={exporting ? 'Loading...' : 'Export'} size="small" disabled={exporting} />
      </div>
    </Modal>
  );
}

const firstColList = [SelectionEnum.FromDatePicker];
const secondColList = [SelectionEnum.ToDatePicker];
const roleList = [SelectionEnum.Role];

const useStyles = makeStyles((theme) => ({
  content: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  datePickersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  datepicker: {
    marginRight: theme.spacing(3),
  },
}));

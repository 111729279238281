import React, {FC, SyntheticEvent, DragEvent} from 'react';
import {makeStyles} from '@material-ui/core';
import {IComponentWithChildren, IWithDisabled} from 'models';

interface IDraggableAreaProps extends IComponentWithChildren, IWithDisabled {
  hightlight?: boolean;
  disabled?: boolean;
  onDragOver?: (event: SyntheticEvent) => void;
  onDrop: (event: DragEvent<HTMLDivElement>) => void;
  onDragLeave?: (event: SyntheticEvent) => void;
}

export const DraggableArea: FC<IDraggableAreaProps> = ({
  children,
  hightlight,
  onDragOver,
  onDrop,
  onDragLeave,
  disabled,
}) => {
  const classes = useStyles({hightlight, disabled});

  return (
    <div className={classes.draggableArea} onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave}>
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  draggableArea: ({hightlight, disabled}: Pick<IDraggableAreaProps, 'hightlight' | 'disabled'>) => {
    let styles: any = {
      backgroundColor: theme.palette.primary.contrastText,
      width: '100%',
    };

    if (hightlight) {
      styles = {...styles, backgroundColor: theme.palette.grey[50]};
    }

    if (disabled) {
      styles = {...styles, pointerEvents: disabled ? 'none' : 'all'};
    }

    return styles;
  },
}));

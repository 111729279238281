import {v4 as uuidv4} from 'uuid';
import {IAdminToolOptions} from 'models/navigation';

export const getAdminToolsOptions = (
  openUserActivityModalClickHandler: () => void,
  integrationsClickHandler: () => void,
  importItemsClickHandler: () => void,
  logInAsClickHandler: () => void
): IAdminToolOptions[] => [
  {
    id: uuidv4(),
    text: 'User log',
    clickHandler: () => openUserActivityModalClickHandler(),
  },
  {
    id: uuidv4(),
    text: 'Integrations',
    clickHandler: () => integrationsClickHandler(),
  },
  {
    id: uuidv4(),
    text: 'Import items',
    clickHandler: () => importItemsClickHandler(),
  },
  {
    id: uuidv4(),
    text: 'Log in as',
    disabled: true,
    clickHandler: () => logInAsClickHandler(),
  },
];

import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch} from 'hooks';
import {createWebLinkPageLoadingSelector, createWebLinkThunk} from 'admin';
import {ICreateWebLinkPayload} from 'models/resources';

export const useCreateWebLink = () => {
  const dispatch = useAppDispatch();
  const loading = useSelector(createWebLinkPageLoadingSelector) as boolean;
  const createWebLink = useCallback((payload: ICreateWebLinkPayload) => dispatch(createWebLinkThunk(payload)), [
    dispatch,
  ]);

  return {loading, createWebLink};
};

import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Container, Label, TextArea} from 'components-lib';
import {IWithDisabled} from 'models';

interface IResourceTextAreaProps extends IWithDisabled {
  value: string;
  label: string;
  rows?: number;
  fullWidth?: boolean;
  placeholder?: string;
}

export const ResourceTextArea: FC<IResourceTextAreaProps> = ({
  label,
  value,
  disabled,
  rows = 6,
  fullWidth = true,
  placeholder = '',
}) => {
  const classes = useStyles();

  return (
    <Container disableGutters classes={{root: classes.textArea}}>
      <Label label={label} />
      <TextArea value={value} placeholder={placeholder} disabled={disabled} rows={rows} fullWidth={fullWidth} />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  textArea: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

import {IProgramClassInstrumentsState} from './IProgramClassInstrumentsState';
import {FilterBackendNamesEnum, ProgramClassInstrumentsOrderingEnum} from '../enums';
import {IProgramClassInstrumentsFilter} from 'models/programs';
import {EntitiesPerPageEnum, SortingOrderEnum} from 'enums';

const filterDefault: IProgramClassInstrumentsFilter = {
  [FilterBackendNamesEnum.SEARCH_BY_NAME]: '',
  [FilterBackendNamesEnum.INSTRUMENT_STATUSES]: [],
  [FilterBackendNamesEnum.AUDIENCES]: [],
  [FilterBackendNamesEnum.TYPES]: [],
  [FilterBackendNamesEnum.PHASES]: [],
  [FilterBackendNamesEnum.PILLARS]: [],
  [FilterBackendNamesEnum.INSTRUMENT_VIEW_TYPE]: null,
};

export const programClassInstrumentsInitialState: IProgramClassInstrumentsState = {
  pool: {
    result: [],
    entities: {byId: {}},
  },

  filter: filterDefault,
  page: {
    loading: false,
  },
  sortOrder: {
    order: ProgramClassInstrumentsOrderingEnum.Name,
    sort: SortingOrderEnum.ASC,
  },
  pagination: {
    currentPage: 0,
    totalCount: 0,
    perPage: EntitiesPerPageEnum.FIFTY,
  },
};

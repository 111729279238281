import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';

import {Text} from 'components-lib';
interface IHeaderLogo {
  headerUrl?: string;
}

const HeaderLogo: FC<IHeaderLogo> = ({headerUrl}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!headerUrl ? (
        <Text.Caption>Loading...</Text.Caption>
      ) : (
        <img src={headerUrl} className={classes.headerLogo} alt="Header logo" loading="lazy" style={{width: '100%'}} />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
  },
  headerLogo: {
    height: '60px',
  },
}));

export default HeaderLogo;

import {useFormikContext} from 'formik';
import {useNavigate} from 'hooks';
import {pages} from 'paths';
import {useCallback} from 'react';
import {isEmpty} from 'utils';
import {IUploadResourceFormBaseValues} from '../models';

export const useUploadActionButtons = () => {
  const {push} = useNavigate();
  const {
    errors,
    isValid,
    values: {displayName, file, fileType, isAccessibleToStudents, hasUploadPermissions, webLink},
  } = useFormikContext<IUploadResourceFormBaseValues>();

  const redirectToViewListOfFiles = useCallback(() => {
    push(`${pages.adminPortal.files.root}`);
  }, [push]);

  const hasErrors = !isEmpty(errors);
  const hasFile = !!file;
  const hasFileType = !!fileType;
  const hasAccessibleToStudentsField = !!isAccessibleToStudents;
  const hasWebLinkField = !!webLink;
  const hasDisplayName = !!displayName;
  const hasUploadRequiredFields = hasFile && hasFileType && hasAccessibleToStudentsField && hasDisplayName;
  const hasOneOfUploadRequiredFields = hasFile || hasFileType || hasAccessibleToStudentsField || hasDisplayName;
  const hasOneOfUploadScormRequiredFields = hasFile || hasAccessibleToStudentsField || hasDisplayName;
  const hasOneOfAddWebLinkRequiredFields = hasWebLinkField || hasAccessibleToStudentsField || hasDisplayName;

  const shouldDisableFileUpload =
    hasErrors || !hasFile || !hasAccessibleToStudentsField || !hasDisplayName || !hasFileType || !hasUploadPermissions;

  const shouldDisableScormUpload =
    hasErrors || !hasFile || !hasAccessibleToStudentsField || !hasDisplayName || !hasUploadPermissions;

  const shouldDisableAddWebLink =
    hasErrors || !hasWebLinkField || !hasAccessibleToStudentsField || !hasDisplayName || !hasUploadPermissions;

  return {
    isFormValid: isValid,
    hasUploadRequiredFields,
    hasOneOfUploadRequiredFields,
    hasOneOfUploadScormRequiredFields,
    hasOneOfAddWebLinkRequiredFields,
    hasErrors,
    hasFile,
    hasFileType,
    hasAccessibleToStudentsField,
    hasDisplayName,
    hasUploadPermissions,
    shouldDisableFileUpload,
    shouldDisableScormUpload,
    shouldDisableAddWebLink,
    redirectToViewListOfFiles,
  };
};

import update from 'immutability-helper';
import {
  IAction,
  IActionFulfilled,
  IAnswer,
  IInstrumentItem,
  IInstrumentsAllFilter,
  IInstrumentsAllSortOrder,
} from 'models';
import {cloneDeep, toNumber} from 'utils';
import {isMatrixQuestion} from '../builder/utils';
import {IMoveBuilderItemActionPayload} from '../models';
import {IInstrumentsStoreState} from './IInstrumentsStoreState';
import {instrumentsInitialState} from './instruments.initialState';
import {QuestionPropertiesType} from 'enums';

export const instrumentsReducer = {
  clear(state: IInstrumentsStoreState) {
    state.builder = {...instrumentsInitialState.builder};
  },

  clearPoolItems(state: IInstrumentsStoreState) {
    state.builder.poolItems = {...instrumentsInitialState.builder.poolItems};
  },

  setSearch(state: IInstrumentsStoreState, action: IAction) {
    state.builder.searchText = action.payload;
  },

  setBuilderFilterInstruments: (
    state: IInstrumentsStoreState,
    action: IActionFulfilled<null, IInstrumentsAllFilter>
  ) => {
    state.builder.filter = action.payload;
    resetPagination(state);
  },

  resetBuilderFilterInstruments: (state: IInstrumentsStoreState) => {
    state.builder.filter = cloneDeep(instrumentsInitialState.builder.filter);
    resetPagination(state);
  },

  setFilterInstruments: (state: IInstrumentsStoreState, action: IActionFulfilled<null, IInstrumentsAllFilter>) => {
    state.filter = action.payload;
    resetPagination(state);
  },

  resetFilterInstruments: (state: IInstrumentsStoreState) => {
    state.filter = cloneDeep(instrumentsInitialState.filter);
    resetPagination(state);
  },

  setCurrentPage: (state: IInstrumentsStoreState, action: IActionFulfilled<null, number>) => {
    state.pagination.currentPage = action.payload;
  },

  setPerPage: (state: IInstrumentsStoreState, action: IActionFulfilled<null, number>) => {
    state.pagination.perPage = action.payload;
    resetPagination(state);
  },

  setSortOrderInstrumentsAll: (
    state: IInstrumentsStoreState,
    action: IActionFulfilled<null, IInstrumentsAllSortOrder>
  ) => {
    state.sortOrder = action.payload;
  },

  addBuilderItem: (state: IInstrumentsStoreState, action: IActionFulfilled<null, IInstrumentItem>) => {
    const {questionAnswerStackId} = action.payload;
    state.builder.builderItems.entities.items[questionAnswerStackId] = action.payload;
    state.builder.builderItems.result = [...state.builder.builderItems.result, questionAnswerStackId];
  },

  addInstructionalText: (state: IInstrumentsStoreState, action: IActionFulfilled<null, any>) => {
    const {questionAnswerStackId} = action.payload;
    state.builder.builderItems.entities.items[questionAnswerStackId] = action.payload;
    state.builder.builderItems.result = [...state.builder.builderItems.result, questionAnswerStackId];
  },

  updateInstructionalText: (state: IInstrumentsStoreState, action: IActionFulfilled<null, IInstrumentItem>) => {
    const {questionAnswerStackId} = action.payload;
    state.builder.builderItems.entities.items[questionAnswerStackId] = action.payload;
  },

  linkBuilderItem: (state: IInstrumentsStoreState, action: IActionFulfilled<null, IInstrumentItem>) => {
    const {questionAnswerStackId, answerStackId, linkedQuestionAnswerStackId, type} = action.payload;
    const isMatrix = isMatrixQuestion(type);

    if (isMatrix) {
      state.builder.builderItems.entities.items[questionAnswerStackId] = action.payload;
    } else {
      state.builder.builderItems.entities.items[answerStackId][
        QuestionPropertiesType.linkedQuestionAnswerStackId
      ] = linkedQuestionAnswerStackId;
    }
  },

  moveBuilderItem: (state: IInstrumentsStoreState, action: IActionFulfilled<null, IMoveBuilderItemActionPayload>) => {
    const {id, from, to} = action.payload;
    state.builder.builderItems.result = update(state.builder.builderItems.result, {
      $splice: [
        [from, 1],
        [to, 0, id],
      ],
    });
  },

  removeBuilderItem: (state: IInstrumentsStoreState, action: IActionFulfilled<null, number>) => {
    delete state.builder.builderItems.entities.items[action.payload];
    state.builder.builderItems.result = state.builder.builderItems.result.filter(
      (id) => toNumber(id) !== action.payload
    );
  },

  addMatrixRow: (
    state: IInstrumentsStoreState,
    action: IActionFulfilled<null, {questionAnswerStackId: number; row: IAnswer}>
  ) => {
    const {questionAnswerStackId, row} = action.payload;
    const itemRaw = state.builder.builderItems.entities.items[questionAnswerStackId];
    const rowsUpdated = [...itemRaw.rows, {...row, position: itemRaw.rows.length + 1}];
    state.builder.builderItems.entities.items[questionAnswerStackId] = {
      ...itemRaw,
      rows: rowsUpdated,
    };
  },

  removeMatrixRow: (state: IInstrumentsStoreState, action: IActionFulfilled<null, {id: number; rowIdx: number}>) => {
    const {id, rowIdx} = action.payload;
    const item = state.builder.builderItems.entities.items[id];
    state.builder.builderItems.entities.items[id] = {
      ...item,
      rows: [...item.rows.filter((_, idx) => idx !== rowIdx)],
    };
  },

  moveMatrixRow: (state: IInstrumentsStoreState, action: IActionFulfilled<null, IMoveBuilderItemActionPayload>) => {
    const {id, from, to} = action.payload;
    const item = state.builder.builderItems.entities.items[id];
    const fromRow = item.rows[from];
    state.builder.builderItems.entities.items[id] = {
      ...item,
      rows: [
        ...update(item.rows, {
          $splice: [
            [from, 1],
            [to, 0, fromRow],
          ],
        }),
      ],
    };
  },

  setTemporaryBuilderItems: (state: IInstrumentsStoreState) => {
    state.builder.temporaryBuilderItems = {
      entities: {...state.builder.builderItems.entities},
      result: [...state.builder.builderItems.result],
    };
  },

  resetTemporaryBuilderItems: (state: IInstrumentsStoreState) => {
    state.builder.temporaryBuilderItems = {...instrumentsInitialState.builder.temporaryBuilderItems};
  },

  setBuilderItems: (state: IInstrumentsStoreState) => {
    state.builder.builderItems = {...state.builder.temporaryBuilderItems, loading: false};
  },
};

const resetPagination = (state: IInstrumentsStoreState) => {
  state.pagination.currentPage = instrumentsInitialState.pagination.currentPage;
  state.pagination.totalCount = instrumentsInitialState.pagination.totalCount;
};

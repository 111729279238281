import {useAppDispatch} from 'hooks';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {
  publishAllAnswerStacksForQuestionThunk,
  publishingStackSelector,
  publishItemAnswerStackThunk,
  unPublishItemAnswerStackThunk,
} from '../store';
import {useItemStateActions} from './useItemStateActions';

export function usePublish() {
  const dispatch = useAppDispatch();
  const publishingStack = useSelector(publishingStackSelector);
  const {fetchItem, loadingPageSection} = useItemStateActions();

  const publishItemAnswerStack = useCallback(
    async (answerStackId: number, questionId: number) => {
      await publishItemAnswerStackThunk(answerStackId)(dispatch);
      await fetchItem(questionId, false);
    },
    [dispatch, fetchItem]
  );

  const unPublishItemAnswerStack = useCallback(
    async (answerStackId: number, questionId: number) => {
      await unPublishItemAnswerStackThunk(answerStackId)(dispatch);
      await fetchItem(questionId, false);
    },
    [dispatch, fetchItem]
  );

  const publishAllAnswerStacksForQuestion = useCallback(
    (questionId: number) => {
      dispatch(publishAllAnswerStacksForQuestionThunk(questionId));
    },
    [dispatch]
  );

  return {
    publishingStack: publishingStack || loadingPageSection,
    publishItemAnswerStack,
    publishAllAnswerStacksForQuestion,
    unPublishItemAnswerStack,
  };
}

import {EntityStatusEnum, QuestionAnswersTypeEnum} from 'enums';
import {
  IActionFulfilled,
  IBindInstrumentsAllPage,
  IFetchPoolItemsPayload,
  IGetItemsApiResponse,
  IInstrumentDetails,
  IInstrumentItem,
  IUpdateInstrumentApiResponse,
  IUpdateInstrumentPayload,
} from 'models';
import {normalize} from 'normalizr';
import {isObject, values} from 'utils';
import {IInstrumentsStoreState} from './IInstrumentsStoreState';
import {instrumentsInitialState} from './instruments.initialState';
import {builderItemsSchema, instrumentsListSchema, poolItemsSchema, pretestItemsSchema} from './normalizationSchemas';
import {
  deleteInstrumentThunk,
  fetchGeneralInstrumentExportThunk,
  fetchInstrumentForPreview,
  fetchInstrumentsAllPagedThunk,
  fetchInstrumentThunk,
  fetchMatrixRelatedPoolItemsThunk,
  fetchPoolItemsThunk,
  fetchPossiblePretestItemsThunk,
  publishMasterInstrumentThunk,
  unPublishSessionInstrumentThunk,
  updateDraftInstrumentThunk,
  updateInstrumentThunk,
  uploadInstrumentFooterLogoThunk,
  uploadInstrumentHeaderLogoThunk,
} from './thunks';

export const instrumentsExtraReducer = {
  //fetchInstrumentThunk
  [fetchInstrumentThunk.fulfilled]: (
    state: IInstrumentsStoreState,
    action: IActionFulfilled<{templateId: number}, IInstrumentDetails>
  ) => {
    state.page.loading = false;
    if (action.payload?.templateId) {
      const updated = {...action.payload, elements: mapMatrixElementAnswerStackId(action.payload.elements)};

      state.templates.entities[action.payload.templateId] = {...updated};

      if (updated.elements.length) {
        state.builder.builderItems = normalize(updated.elements, builderItemsSchema);

        return;
      }

      state.builder.builderItems = {...instrumentsInitialState.builder.builderItems};
    }
  },
  [fetchInstrumentThunk.pending]: (state: IInstrumentsStoreState) => {
    state.page.loading = true;
  },
  [fetchInstrumentThunk.rejected]: (state: IInstrumentsStoreState) => {
    state.page.loading = false;
  },

  //fetchGeneralInstrumentExportThunk
  [fetchGeneralInstrumentExportThunk.fulfilled]: (state: IInstrumentsStoreState) => {
    state.page.loading = false;
  },
  [fetchGeneralInstrumentExportThunk.pending]: (state: IInstrumentsStoreState) => {
    state.page.loading = true;
  },
  [fetchGeneralInstrumentExportThunk.rejected]: (state: IInstrumentsStoreState) => {
    state.page.loading = false;
  },

  //unPublishSessionInstrumentThunk
  [unPublishSessionInstrumentThunk.fulfilled]: (
    state: IInstrumentsStoreState,
    action: IActionFulfilled<number, null>
  ) => {
    const {arg: templateId} = action.meta;
    state.templates.entities[templateId] = {
      ...state.templates.entities[templateId],
      status: EntityStatusEnum.draft,
    };
    state.page.saving = false;
  },
  [unPublishSessionInstrumentThunk.pending]: (state: IInstrumentsStoreState) => {
    state.page.saving = true;
  },
  [unPublishSessionInstrumentThunk.rejected]: (state: IInstrumentsStoreState) => {
    state.page.saving = false;
  },

  //fetchPoolItemsThunk
  [fetchPoolItemsThunk.fulfilled]: (
    state: IInstrumentsStoreState,
    action: IActionFulfilled<null, IGetItemsApiResponse>
  ) => {
    if (!action.payload.items) {
      state.page.loading = false;
      return;
    }

    const isSearch = state.builder.searchText.length > 0;

    /* 
      The following block covers two special cases:
      1. When there is nothing in the search field.
      2. When the search was reset after a search that resulted in 0 items
    */
    let current = [];
    if (!isSearch && state.builder.poolItems.entities.items) {
      current = values(state.builder.poolItems.entities.items);
    }

    const newEntities = [...current, ...action.payload.items];
    state.builder.poolItems = normalize(newEntities, poolItemsSchema);
    state.builder.pagesTotal.poolItems = action.payload.pageCount;
    state.builder.builderItems.loading = false;
  },
  [fetchPoolItemsThunk.pending]: (state: IInstrumentsStoreState) => {
    state.builder.builderItems.loading = true;
  },
  [fetchPoolItemsThunk.rejected]: (state: IInstrumentsStoreState) => {
    state.builder.builderItems.loading = false;
  },

  //fetchPossiblePretestItemsThunk
  [fetchPossiblePretestItemsThunk.fulfilled]: (state: IInstrumentsStoreState, action: IActionFulfilled<null, any>) => {
    const newEntities = [...action.payload.items];

    state.builder.pretestItems = normalize(newEntities, pretestItemsSchema);
  },

  //fetchMatrixRelatedPoolItemsThunks
  [fetchMatrixRelatedPoolItemsThunk.fulfilled]: (
    state: IInstrumentsStoreState,
    action: IActionFulfilled<IFetchPoolItemsPayload, IGetItemsApiResponse>
  ) => {
    const newEntities = [...action.payload.items];
    state.builder.poolItems = normalize(newEntities, poolItemsSchema);
    state.builder.pagesTotal.poolItems = action.payload.pageCount;
    state.builder.builderItems.loading = false;
  },
  [fetchMatrixRelatedPoolItemsThunk.pending]: (state: IInstrumentsStoreState) => {
    state.builder.builderItems.loading = true;
  },
  [fetchMatrixRelatedPoolItemsThunk.rejected]: (state: IInstrumentsStoreState) => {
    state.builder.builderItems.loading = false;
  },

  //updateDraftInstrumentThunk
  [updateDraftInstrumentThunk.fulfilled]: (
    state: IInstrumentsStoreState,
    action: IActionFulfilled<IUpdateInstrumentPayload, IInstrumentDetails>
  ) => {
    if (action.payload.templateId) {
      const templateId = action.payload.templateId;
      const updated = {
        ...action.payload,
        elements: mapMatrixElementAnswerStackId(action.payload.elements),
      };

      state.templates.entities[templateId] = {...updated};

      if (updated.elements.length) {
        state.builder.builderItems = normalize(updated.elements, builderItemsSchema);
      }
    }
    state.page.loading = false;
    state.page.saving = false;
  },
  [updateDraftInstrumentThunk.pending]: (state: IInstrumentsStoreState) => {
    state.page.loading = true;
    state.page.saving = true;
  },
  [updateDraftInstrumentThunk.rejected]: (state: IInstrumentsStoreState) => {
    state.page.loading = false;
    state.page.saving = false;
  },

  //updateInstrumentInfoThunk
  [updateInstrumentThunk.fulfilled]: (
    state: IInstrumentsStoreState,
    action: IActionFulfilled<IUpdateInstrumentPayload, IUpdateInstrumentApiResponse>
  ) => {
    if (action.payload.data) {
      const {data} = action.payload;
      const {templateId} = data;

      state.templates.entities[templateId] = {
        ...state.templates.entities[templateId],
        ...data,
      };
    }

    state.page.loading = false;
  },
  [updateInstrumentThunk.pending]: (state: IInstrumentsStoreState) => {
    state.page.loading = true;
  },
  [updateInstrumentThunk.rejected]: (state: IInstrumentsStoreState) => {
    state.page.loading = false;
  },

  //fetchInstrumentsAllPagedThunk
  [fetchInstrumentsAllPagedThunk.fulfilled]: (
    state: IInstrumentsStoreState,
    action: IActionFulfilled<null, IBindInstrumentsAllPage>
  ) => {
    if (isObject(action.payload)) {
      state.templates = normalize(action.payload.templates, instrumentsListSchema);
      state.pagination.totalCount = action.payload.totalCount;
    }

    state.page.loading = false;
  },
  [fetchInstrumentsAllPagedThunk.pending]: (state: IInstrumentsStoreState) => {
    state.page.loading = true;
  },
  [fetchInstrumentsAllPagedThunk.rejected]: (state: IInstrumentsStoreState) => {
    state.page.loading = false;
  },

  //uploadInstrumentFooterLogoThunk
  [uploadInstrumentFooterLogoThunk.fulfilled]: (state: IInstrumentsStoreState) => {
    state.page.saving = false;
  },
  [uploadInstrumentFooterLogoThunk.pending]: (state: IInstrumentsStoreState) => {
    state.page.saving = true;
  },
  [uploadInstrumentFooterLogoThunk.rejected]: (state: IInstrumentsStoreState) => {
    state.page.saving = false;
  },

  //uploadInstrumentHeaderLogoThunk
  [uploadInstrumentHeaderLogoThunk.fulfilled]: (state: IInstrumentsStoreState) => {
    state.page.saving = false;
  },
  [uploadInstrumentHeaderLogoThunk.pending]: (state: IInstrumentsStoreState) => {
    state.page.saving = true;
  },
  [uploadInstrumentHeaderLogoThunk.rejected]: (state: IInstrumentsStoreState) => {
    state.page.saving = false;
  },

  //publishMasterInstrumentThunk
  [publishMasterInstrumentThunk.fulfilled]: (state: IInstrumentsStoreState) => {
    state.page.saving = false;
  },
  [publishMasterInstrumentThunk.pending]: (state: IInstrumentsStoreState) => {
    state.page.saving = true;
  },
  [publishMasterInstrumentThunk.rejected]: (state: IInstrumentsStoreState) => {
    state.page.saving = false;
  },

  //fetchInstrumentForPreview
  [fetchInstrumentForPreview.fulfilled]: (
    state: IInstrumentsStoreState,
    action: IActionFulfilled<{templateId: number}, IInstrumentDetails>
  ) => {
    if (action.payload) {
      const surveyTemplate = {...action.payload};
      state.survey.previewTemplate.entities[action.payload.templateId] = {...surveyTemplate};
    }

    state.page.loading = false;
  },
  [fetchInstrumentForPreview.pending]: (state: IInstrumentsStoreState) => {
    state.page.loading = true;
  },
  [fetchInstrumentForPreview.rejected]: (state: IInstrumentsStoreState) => {
    state.page.loading = false;
  },

  //deleteInstrumentThunk
  [deleteInstrumentThunk.fulfilled]: (state: IInstrumentsStoreState) => {
    state.page.deleting = false;
  },
  [deleteInstrumentThunk.pending]: (state: IInstrumentsStoreState) => {
    state.page.deleting = true;
  },
  [deleteInstrumentThunk.rejected]: (state: IInstrumentsStoreState) => {
    state.page.deleting = false;
  },
};

function mapMatrixElementAnswerStackId(elements: IInstrumentItem[]): IInstrumentItem[] {
  return elements.map((element) => {
    const {type} = element;
    const isMatrix = type === QuestionAnswersTypeEnum.matrix || type === QuestionAnswersTypeEnum.matrixEnum;

    if (isMatrix) {
      return {
        ...element,
        originalAnswerStackId: element.questionAnswerStackId,
        questionAnswerStackId: element.questionId,
      };
    }

    return element;
  });
}

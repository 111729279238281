import axios from 'axios';
import {config} from '../config';
import {getCurrentAccessToken} from 'utils';

const {jaEeTestApi: apiConfig} = config;
const axiosApiInstance = axios.create();
// Request interceptor for API calls

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const token = getCurrentAccessToken();

    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': apiConfig.subscriptionKey,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export {axiosApiInstance};

import {IIntegratiosAllFilter} from 'models';
import {IGetIntegrationsQueryFilter} from '../../../../models/integrations/IGetIntegrationsQueryFilter';
import {IIntegrationsPagedFilterEnum} from '../../enums/IIntegrationsPagedFilter.enum';

export const getIntegrationQueryFilter = (
  filter: IIntegratiosAllFilter,
  separator: string
): IGetIntegrationsQueryFilter => {
  const {areas, integrationType, integrationStatuses, name, schoolNames, schoolDistricts} = filter;
  const queryFilter = {};

  const joinList = (list: any, separator: string) => {
    return list.join(separator);
  };

  if (areas.length) {
    queryFilter[IIntegrationsPagedFilterEnum.AREA_IDS] = joinList(areas, separator);
  }

  if (integrationStatuses.length) {
    queryFilter[IIntegrationsPagedFilterEnum.INTEGRATION_STATUSES] = joinList(integrationStatuses, separator);
  }

  if (schoolNames.length) {
    queryFilter[IIntegrationsPagedFilterEnum.SCHOOL_IDS] = joinList(schoolNames, separator);
  }

  if (schoolDistricts.length) {
    queryFilter[IIntegrationsPagedFilterEnum.DISTRICT_IDS] = joinList(schoolDistricts, separator);
  }

  if (!!integrationType) {
    queryFilter[IIntegrationsPagedFilterEnum.INTEGRATION_TYPE] = integrationType;
  }

  if (!!name) {
    queryFilter[IIntegrationsPagedFilterEnum.SEARCH_BY_NAME] = name;
  }

  return queryFilter;
};

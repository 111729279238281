import React, {Fragment, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {CardBody} from './CardBody';
import {Card, Row, Text} from 'components-lib';
import {QuestionAnswersTypeEnum} from 'enums';
import {useEntityCard} from 'hooks';
import {useBuilderItemsEntities} from '../../hooks';
import {isInstructionalTextQuestion, isMatrixQuestion} from '../../utils';
import {getAnswersComponentMap} from 'admin/library/utils';
import {IAnswer, IAnswersMatrixBuilder, IComponentWithChildren, IInstrumentItem, IItemColumn} from 'models';

interface ICardItemProps extends IComponentWithChildren {
  questionId: number;
  answerStackId: number;
  columns?: IItemColumn[] | IAnswersMatrixBuilder[];
  rows?: IAnswer[];
  position?: number;
  totalItems?: number;
  type?: QuestionAnswersTypeEnum;
  text?: string;
  builderInstructionalText?: string;
  html?: string;
  editClickHandler?: () => void;
  deleteClickHandler?: () => void;
  moveHandler?: (movedItemIdx: number, newPosition: number) => void;
  linkClickHandler?: () => void;
  openInfoLinkClickHandler?: () => void;
}

// TODO merge with the other CardItem component once all the interfaces are unified
export function CardItem({
  children,
  questionId,
  answerStackId,
  columns,
  rows,
  position,
  totalItems,
  type,
  builderInstructionalText,
  html,
  editClickHandler,
  deleteClickHandler,
  moveHandler,
  linkClickHandler,
  openInfoLinkClickHandler,
}: ICardItemProps) {
  let title: string;
  let linkedQuestionAnswerStackId: number;
  let answers: any[];

  const isMatrix = isMatrixQuestion(type);
  const isInstructionalText = isInstructionalTextQuestion(type);
  const classes = useStyles({isMatrix, isInstructionalText});
  const {builderItemsEntities} = useBuilderItemsEntities();
  const answersComponentMap = getAnswersComponentMap(true);
  const cardId = !isInstructionalText ? answerStackId : null;

  useEntityCard(answersComponentMap, type, questionId);

  const AnswersComponent: any = useMemo(() => answersComponentMap[type], [type, answersComponentMap]);

  const answersContent = useMemo(
    () => () => {
      if (!answersComponentMap[type]) {
        const error = `Unknown question answer stack: ${type}!`;
        return <Fragment>{error && <Text.Error>{error}</Text.Error>}</Fragment>;
      }

      return (
        <AnswersComponent
          answers={answers}
          columns={columns}
          rows={rows}
          questionId={questionId}
          html={html}
          type={type}
          builderInstructionalText={builderInstructionalText}
        />
      );
    },
    [AnswersComponent, answers, columns, rows, questionId, type, builderInstructionalText, html, answersComponentMap]
  );

  const itemBuilder: IInstrumentItem = builderItemsEntities[answerStackId];

  /*
   * The reason for the code block below is that not all items from the pool are loaded at once.
   */
  if (itemBuilder) {
    title = itemBuilder.text || itemBuilder.title;
    answers = itemBuilder.choices;
    linkedQuestionAnswerStackId = itemBuilder.linkedQuestionAnswerStackId;
  }

  return (
    <Card>
      <CardBody
        id={cardId}
        title={title}
        linkedQuestionAnswerStackId={linkedQuestionAnswerStackId}
        position={position}
        totalItems={totalItems}
        type={type}
        moveHandler={moveHandler}
        withDelete={!!deleteClickHandler}
        deleteClickHandler={deleteClickHandler}
        withEdit={!!editClickHandler}
        editClickHandler={editClickHandler}
        withLink={!!linkClickHandler && !isInstructionalText}
        linkClickHandler={linkClickHandler}
        withItemInfo={!!openInfoLinkClickHandler}
        openInfoLinkClickHandler={openInfoLinkClickHandler}
      />
      <Row justify="flex-start" classes={{root: classes.answers}}>
        <Fragment>{answersContent()}</Fragment>
      </Row>
      {children}
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  answers: ({isMatrix}: {isMatrix: boolean}) => ({
    marginLeft: isMatrix ? 0 : theme.spacing(4),
  }),
}));

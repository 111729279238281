import React, {FC, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  fetchUploadFileTypeOptionsThunk,
  filtersUploadFileTypesEntitiesCollectionSelector,
  FormNameEnum,
} from 'filters-selections';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
import {useAppDispatch} from 'hooks';
import {useFormikContext} from 'formik';
import {IUploadFileFormBaseValues} from 'admin/resources/upload';

type IFileTypeSelectProps = IWithDisabled;

export const FileTypeSelectBasedOnAttachedFileSelect: FC<IFileTypeSelectProps> = ({disabled}) => {
  const dispatch = useAppDispatch();
  const {values} = useFormikContext<IUploadFileFormBaseValues>();

  const types = useSelector(filtersUploadFileTypesEntitiesCollectionSelector);

  useEffect(() => {
    if (!types.length) {
      dispatch(fetchUploadFileTypeOptionsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const options = useMemo(() => types.map((type) => ({value: type.id, label: type.displayText})), [types]);

  return (
    <ItemWithPlaceholder label="File Type" hasItems>
      <Form.Select
        name={FormNameEnum.fileType}
        options={options}
        disabled={disabled || values?.shouldDisableFileType}
      />
    </ItemWithPlaceholder>
  );
};

import React, {FC, useCallback} from 'react';
import {filterInstrumentCollectionById} from 'admin/resources/learning-paths/views/create';
import {useFormikContext} from 'formik';
import {FormNameEnum} from 'enums/form';
import {IBaseInstrumentItem} from 'models/resources';
import {AddBaseDrawerListItem} from '..';
import {IAddSessionInstrumentsFormValues} from '../AddSessionInstrumentsForm/config';

interface IAddInstrumentsDrawerListItemProps {
  id: number;
  name: string;
}

export const AddInstrumentsDrawerListItem: FC<IAddInstrumentsDrawerListItemProps> = ({id, name}) => {
  const {
    values: {selectedInstruments},
    setFieldValue,
  } = useFormikContext<IAddSessionInstrumentsFormValues>();

  const deleteDrawerItemClickHandler = useCallback(() => {
    const filteredInstruments = filterInstrumentCollectionById(selectedInstruments, id);
    const filteredInstrumentIds = filteredInstruments.map((resource: IBaseInstrumentItem) => resource.id);

    setFieldValue(FormNameEnum.selectedInstruments, filteredInstruments);
    setFieldValue(FormNameEnum.selectedInstrumentsIds, filteredInstrumentIds);
  }, [id, selectedInstruments, setFieldValue]);

  return (
    <AddBaseDrawerListItem
      id={id}
      name={name}
      deleteDrawerItemClickHandler={deleteDrawerItemClickHandler}
      isResource={false}
    />
  );
};

import React from 'react';
import {makeStyles} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import {Close} from '@material-ui/icons';
import classnames from 'classnames';
import {IComponentWithClassName} from 'models';

interface ITagProps extends IComponentWithClassName {
  label: string;
  clickHandler?: () => void;
  deleteHandler?: () => void;
  color?: 'default' | 'primary' | 'secondary';
  withLeftMargin?: boolean;
  withFilterChip?: boolean;
}

export function Tag({
  label,
  color = 'default',
  clickHandler,
  deleteHandler,
  className,
  withLeftMargin,
  withFilterChip = false,
}: ITagProps) {
  const classes = useStyles({withLeftMargin, withFilterChip});
  return (
    <Chip
      onClick={clickHandler}
      onDelete={deleteHandler}
      deleteIcon={<Close color="primary" />}
      label={label}
      variant="outlined"
      size="small"
      className={classnames(className, classes.root)}
      color={color}
      classes={{deleteIcon: classes.deleteIcon}}
      title={label}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: ({withLeftMargin, withFilterChip}: {withLeftMargin: boolean; withFilterChip: boolean}) => {
    const marginWithoutLeftStyle = `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px 0px`;
    const marginStyle = withLeftMargin ? `${theme.spacing(1)}px` : marginWithoutLeftStyle;

    let styles: any = {
      margin: marginStyle,
      borderRadius: theme.spacing(1),
      '&:hover .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
      },
    };

    if (withFilterChip) {
      styles = {...styles, maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'};
    }

    return styles;
  },
  deleteIcon: {
    color: theme.palette.primary.main,
  },
}));

import React from 'react';
import {FormContextProvider} from 'forms';
import {LearningPathViewCreateContainer} from './components';
import {useCreateLearningPath} from './hooks';

export const LearningPathViewCreate = () => {
  const {loading} = useCreateLearningPath();

  return (
    <FormContextProvider>
      <LearningPathViewCreateContainer loading={loading} />
    </FormContextProvider>
  );
};

import {useCallback} from 'react';
import {useAppDispatch} from 'hooks';
import {learningPathEditSliceSitesActions as actions} from '../store';

export const useEditLearningPathStateActions = () => {
  const dispatch = useAppDispatch();

  const clearLearningPath: () => void = useCallback(() => dispatch(actions.clearLearningPath()), [dispatch]);

  return {
    clearLearningPath,
  };
};

import React, {FC, useMemo} from 'react';
import {Form} from 'forms';
import {FormNameEnum} from 'enums/form';
import {IWithChildren} from 'models/common';
import {
  ILearningPathSessionsFormBaseValues,
  learningPathSessionsFormBaseValidationSchema,
} from 'admin/resources/learning-paths/views/session-builder/utils';
import {ILearningPathSessionItem} from 'models/resources/learning-paths';

interface ILearningPathSessionsFormProps extends IWithChildren {
  item: ILearningPathSessionItem;
}

export const LearningPathSessionsForm: FC<ILearningPathSessionsFormProps> = ({children, item}) => {
  const initialValues = useMemo(
    () => ({
      [FormNameEnum.sessionName]: item?.title ? item?.title : '',
      [FormNameEnum.sessionDescription]: item?.description ? item?.description : '',
      [FormNameEnum.resources]: item?.resources ?? [],
      [FormNameEnum.instruments]: item?.instruments ?? [],
    }),

    // Track only changes for title and description
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item.title, item.description]
  );

  return (
    <Form.Form<ILearningPathSessionsFormBaseValues>
      initialValues={initialValues}
      validationSchema={learningPathSessionsFormBaseValidationSchema}
      submitHandler={() => null}
      validateOnMount={true}
    >
      {children}
    </Form.Form>
  );
};

import {createSlice} from '@reduxjs/toolkit';
import {instrumentsInitialState} from './instruments.initialState';
import {instrumentsReducer} from './instruments.reducer';
import {instrumentsExtraReducer} from './instrumentsExtra.reducer';

export const instrumentsSlice = createSlice({
  name: 'instruments',
  initialState: instrumentsInitialState,
  reducers: instrumentsReducer,
  extraReducers: instrumentsExtraReducer,
});

export const {actions: instrumentsActions} = instrumentsSlice;

import React, {ReactNode, useMemo} from 'react';
import {PageViewHeader, Tag} from 'components-lib';
import {UploadDropdown} from 'admin/resources/files/components/Dropdowns';
import {FilesViewAllSortBy} from '../FilesViewAllSortBy/FilesViewAllSortBy';
import {useFilesAll, useFilesAllStoreActions} from '../../hooks';
import {FilesAllFilterNamesEnum} from '../../enums';
import {IFilesAllFilter} from 'models/resources/files';
import {filesAllFilterList, filesAllFilterToFilterNameMap} from '../../utils';
import {cloneDeep} from 'utils/functions';
import {getPublishedByExtendedEntities} from 'filters-selections';

export const FilesViewAllHeader = () => {
  const {filter, filterEntities, userArea} = useFilesAll();
  const {setFilterFilesAll} = useFilesAllStoreActions();

  const publishedByEntities = useMemo(() => getPublishedByExtendedEntities(userArea, filterEntities.publishedBy), [
    userArea,
    filterEntities.publishedBy,
  ]);

  const renderSortOrder = useMemo(() => <FilesViewAllSortBy />, []);

  const appliedFilters = useMemo(() => {
    const chips: ReactNode[] = [];

    const deleteClickHandler = (name: FilesAllFilterNamesEnum) => {
      let value = null as null | undefined | '';

      if (name === FilesAllFilterNamesEnum.SEARCH_BY_QUERY) {
        value = '';
      }

      const updated: IFilesAllFilter = {
        ...filter,
        [name]: value,
      };
      setFilterFilesAll(updated);
    };

    const resetDropdownFilter = (propName: string, id: string | number) => {
      const updated: IFilesAllFilter = cloneDeep(filter);
      updated[propName] = updated[propName].filter((pId: number) => pId !== id);
      setFilterFilesAll(updated);
    };

    // string[] filters

    filesAllFilterList.forEach((filesAllFilter) => {
      const mappedFilterName = filesAllFilterToFilterNameMap[filesAllFilter];
      const current = filter[mappedFilterName];

      if (current instanceof Array && current.length > 0) {
        current.forEach((id) => {
          const currentFilter = filterEntities[mappedFilterName][id];
          const label = currentFilter?.displayText ? currentFilter?.displayText : currentFilter?.name;

          !!currentFilter &&
            chips.push(
              <Tag
                withFilterChip
                key={`${mappedFilterName}-${id}`}
                label={label}
                deleteHandler={() => resetDropdownFilter(mappedFilterName, currentFilter.id)}
              />
            );
        });
      }
    });

    if (!!filter.query) {
      chips.push(
        <Tag
          withFilterChip
          key={filter.query}
          label={filter.query}
          deleteHandler={() => deleteClickHandler(FilesAllFilterNamesEnum.SEARCH_BY_QUERY)}
        />
      );
    }

    if (!!filter.isAccessibleToStudents || filter.isAccessibleToStudents === 0) {
      const isAccessibleToStudentsLabel =
        filterEntities.accessibleToStudents[filter.isAccessibleToStudents].displayText;

      chips.push(
        <Tag
          withFilterChip
          key={filter.isAccessibleToStudents}
          label={isAccessibleToStudentsLabel}
          deleteHandler={() => deleteClickHandler(FilesAllFilterNamesEnum.IS_ACCESSIBLE_TO_STUDENTS)}
        />
      );
    }

    if (!!filter.publishedBy) {
      const publishedByLabel = publishedByEntities[filter.publishedBy].displayText;

      chips.push(
        <Tag
          withFilterChip
          key={filter.publishedBy}
          label={publishedByLabel}
          deleteHandler={() => deleteClickHandler(FilesAllFilterNamesEnum.PUBLISHED_BY)}
        />
      );
    }

    return chips;
  }, [filter, setFilterFilesAll, filterEntities, publishedByEntities]);

  return <PageViewHeader heading="Files" sort={renderSortOrder} filters={appliedFilters} action={<UploadDropdown />} />;
};

import {Accordion} from 'components-lib';
import {Form} from 'forms';
import React from 'react';
import {FormNameEnum} from '../../../enums';
import {IWithDisabled} from 'models';

export function CategoryFilter({disabled}: IWithDisabled) {
  return (
    <Accordion heading="Category">
      <Form.Search name={FormNameEnum.category} disabled={disabled} withIcon={false} />
    </Accordion>
  );
}

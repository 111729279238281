import {setToken} from 'utils';

export const saveAuthToken = () => (next: any) => (action: any) => {
  // TODO do we need this?
  if (action.type === 'redux-oidc/USER_FOUND') {
    setToken(action.payload.access_token);
  }

  return next(action);
};

import React, {ChangeEvent, useCallback} from 'react';
import {Checkbox, Row} from 'components-lib';
import {FieldProps, isString} from 'formik';
import {isFunction, isNumber, isObject, toNumber} from 'utils';
import {Field} from './Field';

type TCustomValueChangeHandler = (selected: boolean, checkboxValue: any) => any;

interface IFormCheckboxProps {
  name: string;
  value?: string | number;
  customValueChangeHandler?: TCustomValueChangeHandler;
  withDefaultChangeHandler?: boolean;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  color?: 'primary' | 'secondary';
}

export function FormCheckbox({
  name,
  value,
  label,
  customValueChangeHandler,
  withDefaultChangeHandler = false,
  disabled = false,
  checked,
  color = 'primary',
}: IFormCheckboxProps) {
  return (
    <Field<TCustomValueChangeHandler>
      name={name}
      value={value}
      label={label}
      disabled={disabled}
      checked={checked}
      customValueChangeHandler={customValueChangeHandler}
      withDefaultChangeHandler={withDefaultChangeHandler}
      color={color}
      Component={CheckboxDecorated}
    />
  );
}

interface IInputDecoratedProps extends FieldProps, IFormCheckboxProps {}

function CheckboxDecorated({
  field: {name, value},
  form: {errors, setFieldValue},
  label,
  customValueChangeHandler,
  withDefaultChangeHandler,
  ...rest
}: IInputDecoratedProps) {
  const error = errors[name];

  let props: any = {
    name,
    ...rest,
  };

  if (error) {
    props = {
      ...props,
      error: 'true',
      helpertext: error,
    };
  }

  if (rest.checked) {
    props = {...props, checked: rest.checked};
  }

  if (isNumber(rest.value) || isString(rest.value)) {
    const checked = Array.isArray(value) ? value.includes(rest.value) : value === rest.value;
    props = {...props, checked};
  }

  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>, selected: boolean) => {
      // default case where there is no value and we need only  boolean
      if (event.target.value === '') {
        setFieldValue(name, selected);
        return;
      }

      const valueNumber = toNumber(event.target.value);
      const checkboxValue = isNaN(valueNumber) ? event.target.value : valueNumber;

      if (isFunction(customValueChangeHandler)) {
        customValueChangeHandler(selected, checkboxValue);
        return;
      }

      const next = isObject(value)
        ? selected
          ? value.concat(checkboxValue)
          : value.filter((v: number | string | any) => {
              return v !== checkboxValue;
            })
        : checkboxValue;

      setFieldValue(name, next);
    },
    [value, customValueChangeHandler, setFieldValue, name]
  );

  const handlers = withDefaultChangeHandler ? {} : {changeHandler};

  if (!label) {
    return <Checkbox label={label} {...handlers} {...props} />;
  }

  return (
    <Row justify="flex-start">
      <Checkbox label={label} {...handlers} {...props} color={rest.color} />
    </Row>
  );
}

import {createAsyncThunk} from '@reduxjs/toolkit';
import {learningPathsApi} from 'api';
import {ILearningDetailsPathBaseApiResponse} from 'models/resources/learning-paths';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const publishLearningPathThunk: any = createAsyncThunk<ILearningDetailsPathBaseApiResponse, string>(
  `${prefix} publishLearningPath`,
  async (contentItemId, {dispatch, rejectWithValue}) => {
    try {
      const response = await learningPathsApi.publishLearningPath(contentItemId);
      dispatch(toastNotificationManager.createSuccessToastAction('Learning path was published successfully!'));
      return response.data;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.messages));
      return rejectWithValue({error});
    }
  }
);

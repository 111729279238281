import React, {FC, Fragment, useMemo} from 'react';
import {useFormikContext} from 'formik';
import {makeStyles} from '@material-ui/core';
import {ItemAnswerRangeInput} from '..';
import {RatingToggleGroup} from 'admin/library/components';
import {FormNameEnum, SelectionEnum, SelectionListItems} from 'filters-selections';
import {useRangeAnswerStack} from '../../hooks';
import {Wrapper} from 'components-lib';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEditRangeAnswerStackProps {}

export const EditRangeAnswerStack: FC<IEditRangeAnswerStackProps> = () => {
  const classes = useStyles();
  const {list} = useRangeAnswerStack();
  const {
    values: {choices},
  } = useFormikContext<any>();

  const PositiveScore = useMemo(
    () => () => {
      return (
        <RatingToggleGroup
          name={FormNameEnum.positiveScores}
          withLabel
          disabled={false}
          list={list}
          answers={choices}
          withForm
        />
      );
    },
    [list, choices]
  );

  const rateListItems = useMemo(() => {
    const selectionList = [SelectionEnum.RangeFrom, SelectionEnum.RangeTo];

    return <SelectionListItems list={selectionList} withFullWidth />;
  }, []);

  const rateDescriptionItems = useMemo(() => {
    return (
      <Fragment>
        <ItemAnswerRangeInput name={FormNameEnum.minimumRateDescription} label={'Minimum Rate Description'} />
        <ItemAnswerRangeInput name={FormNameEnum.maximumRateDescription} label={'Maximum Rate Description'} />
      </Fragment>
    );
  }, []);

  const Rating = useMemo(
    () => () => {
      return (
        <Wrapper className={classes.rating}>
          {rateListItems}
          {rateDescriptionItems}
        </Wrapper>
      );
    },
    [classes, rateDescriptionItems, rateListItems]
  );

  return (
    <Wrapper className={classes.container}>
      <Rating />
      <PositiveScore />
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
  },
  rating: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

import React, {ReactNode} from 'react';
import PaperSurveyBaseItem from '../components/PaperSurveyBaseItem/PaperSurveyBaseItem';
import SurveyHeadline from '../components/SurveyHeadline/SurveyHeadline';
import {PaperNodeTypeEnum} from '../enums/PaperNodeTypeEnum';
import {IPaperQuestion} from '../models/IPaperQuestion';
import {splitPaperNode} from '../utils/splitPaperNode';

export const createSurveyPages = (objects: IPaperQuestion[], pageContentHeight: number) => {
  const pages: any[] = [];
  const questions: ReactNode[] = [];

  let currentPageHeight = 0;
  objects.forEach((obj, index) => {
    const {heights, id, text, type, choices, htmlStr} = obj;
    const nextHeight = currentPageHeight + heights.total;

    switch (type) {
      case PaperNodeTypeEnum.checkbox:
      case PaperNodeTypeEnum.radioGroup:
        if (nextHeight < pageContentHeight) {
          questions.push(
            <PaperSurveyBaseItem key={index} text={text} htmlStr={htmlStr} id={id.toString()} choices={choices} />
          );
          currentPageHeight += heights.total;
        } else {
          pages.push({questions: [...questions]});
          questions.length = 0; // empty questions array

          currentPageHeight = heights.total; // reset current height to the height of the question

          // add the question to the new page
          questions.push(
            <PaperSurveyBaseItem key={index} text={text} htmlStr={htmlStr} id={id.toString()} choices={choices} />
          );
        }
        break;
      case PaperNodeTypeEnum.matrix:
      case PaperNodeTypeEnum.participantsTable:
        const splitResult = splitPaperNode(currentPageHeight, pageContentHeight, obj);
        splitResult.forEach(({node, height}) => {
          const nextHeight = currentPageHeight + height;
          if (nextHeight < pageContentHeight) {
            questions.push(node);
            currentPageHeight += height;
          } else {
            pages.push({questions: [...questions]});
            questions.length = 0; // empty questions array

            currentPageHeight = height; // reset current height to the height of the question

            // add the question to the new page
            questions.push(node);
          }
        });
        break;
      case PaperNodeTypeEnum.headline:
        if (nextHeight < pageContentHeight) {
          questions.push(<SurveyHeadline key={index} text={text} />);
          currentPageHeight += heights.total;
        } else {
          pages.push({questions: [...questions]});
          questions.length = 0; // empty questions array

          // start creating new page
          questions.push(<SurveyHeadline key={index} text={text} />);
          currentPageHeight = heights.total; // reset height to height of current question
        }
        break;
    }
  });

  // for the cases where the last questions all fitted on the page.
  if (questions.length > 0) {
    pages.push({questions: [...questions]});
  }
  return pages;
};

import React from 'react';
import {FastField} from './Field';
import {RichTextEditor} from 'components-lib';

export interface IFormProps {
  name: string;
  placeholder?: string;
  withEdit?: boolean;
  readOnly?: boolean;
  toolbarHidden?: boolean;
  withInstructionalText?: boolean;
  value?: any;
}

export function FormRichTextEditor({
  name,
  placeholder,
  withEdit = false,
  readOnly = false,
  toolbarHidden = false,
  withInstructionalText = false,
  value = null,
}: IFormProps) {
  return (
    <FastField
      name={name}
      placeholder={placeholder}
      withEdit={withEdit}
      readOnly={readOnly}
      toolbarHidden={toolbarHidden}
      Component={RichTextEditor}
      value={value}
      withInstructionalText={withInstructionalText}
    />
  );
}

import {makeStyles} from '@material-ui/core';
import {ErrorMessage, Formik} from 'formik';
import * as React from 'react';
import ButtonReset from './ButtonReset';
import ButtonSubmit from './ButtonSubmit';
import Field from './Field';
import Input from './Input';
import CustomizedSearchButton from './Search';
import State from './State';

interface IProps<T = any> {
  initialValues: T;
  submit?: (values: T, resetForm: any, setSubmitting: any) => any;
  className?: string;
  validate: (values: T) => any;
  children: React.ReactNode;
}

export function Form({initialValues, validate, submit, className, children}: IProps) {
  const {root} = useStyles();
  return (
    <div className={root}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={validate}
        onSubmit={async (values, {setSubmitting, resetForm}) => {
          if (submit) {
            await submit(values, resetForm, setSubmitting);
          }
          setSubmitting(false);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className={className}>
            {children}
          </form>
        )}
      </Formik>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

Form.Input = Input;
Form.ButtonSubmit = ButtonSubmit;
Form.Field = Field;
Form.State = State;
Form.ErrorMessage = ErrorMessage;
Form.ButtonReset = ButtonReset;
Form.Search = CustomizedSearchButton;

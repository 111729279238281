import React, {FC} from 'react';
import {FutureProgramYearSelect} from '../FutureProgramYear/FutureProgramYearSelect';
import {IWithDisabled} from 'models/common';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IFutureProgramYearMultiSelectProps extends IWithDisabled {}

export const FutureProgramYearMultiSelect: FC<IFutureProgramYearMultiSelectProps> = ({disabled}) => {
  return <FutureProgramYearSelect selectionType="multi" disabled={disabled} />;
};

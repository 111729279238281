import React, {FC, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Container, Icon, Label, Row, Tooltip, Wrapper} from 'components-lib';
import {FormNameEnum} from '../../../enums';
import {getAccessibleToStudentsOptions} from 'admin/resources/upload';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
interface IAccessibleToStudentsOption {
  label: string;
  value: number | string | boolean;
}

interface IAccessibleToStudentsRadioGroupProps extends IWithDisabled {
  infoText?: string;
}

export const AccessibleToStudentsRadioGroup: FC<IAccessibleToStudentsRadioGroupProps> = ({disabled, infoText}) => {
  const classes = useStyles();

  const accessibleToStudentsOptions: IAccessibleToStudentsOption[] = useMemo(
    () => getAccessibleToStudentsOptions(),
    []
  );

  return (
    <Container disableGutters>
      <Row justify="flex-start">
        <Label label="Accessible to students?" withLeftMargin={false} withFontBold className={classes.label} />
        {infoText && (
          <Wrapper className={classes.infoText}>
            <Tooltip title={infoText}>
              <Icon.InfoIcon />
            </Tooltip>
          </Wrapper>
        )}
      </Row>
      <Form.RadioGroup name={FormNameEnum.isAccessibleToStudents}>
        <Row justify="flex-start">
          {accessibleToStudentsOptions.map((option: IAccessibleToStudentsOption, idx: number) => (
            <Form.Radio key={idx} value={option.value} label={option.label} disabled={disabled} />
          ))}
        </Row>
      </Form.RadioGroup>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    marginTop: theme.spacing(2),
  },

  infoText: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(1),
  },
}));

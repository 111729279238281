import React, {useCallback, useMemo} from 'react';
import {batch} from 'react-redux';
import {useActiveStep, useModal} from 'hooks';
import {Form} from 'forms';
import {AddIntegrationActiveStepPage, AddIntegrationContentFooter, ModalCancelAddIntegration} from './components';
import {IAddIntegrationFormBaseValues, IAddIntegrationPayload} from 'models/integrations';
import {addIntegrationFormBaseValidationSchema} from './utils/schema';
import {ActiveStepEnum} from 'enums/common';
import {FormNameEnum as FiltersFormNameEnum} from 'filters-selections';
import {FormNameEnum as IntegrationFormNameEnum} from 'enums/form';
import {mapAddIntegrationDataForPayload} from './utils/helpers';
import {useAddIntegrationStateActions} from './hooks';
import {getIsGoogleClassRoom, getIsMicrosoftTeams, getIsGoogleSitesSource, getIsGoogleSitesDestination} from 'admin';

export const AddIntegrationView = () => {
  const {
    redirectToViewListOfIntegrations,
    createGoogleClassroom,
    createGoogleSitesSource,
    createGoogleSitesDestination,
    createMicrosoftTeams,
  } = useAddIntegrationStateActions();
  const {activeStep, setActiveStep, isFirstStep, isSecondStep, isThirdStep} = useActiveStep();
  const {isOpen: isModalCancelAddIntegration, setIsOpen: setIsModalCancelAddIntegration} = useModal();
  const shouldDisableNextButton = isThirdStep;
  const shouldDisableCancelButton = false;

  const closeModalCancelAddIntegrationHandler = useCallback(() => setIsModalCancelAddIntegration(false), [
    setIsModalCancelAddIntegration,
  ]);

  const openModalCancelAddIntegrationHandler = useCallback(() => setIsModalCancelAddIntegration(true), [
    setIsModalCancelAddIntegration,
  ]);

  const backButtonClickHandler = useCallback(() => {
    if (activeStep < ActiveStepEnum.Second) {
      return;
    }

    setActiveStep(activeStep - ActiveStepEnum.First);
  }, [activeStep, setActiveStep]);

  const nextClickHandler = useCallback(() => setActiveStep(activeStep + ActiveStepEnum.First), [
    activeStep,
    setActiveStep,
  ]);

  const cancelClickHandler = useCallback(() => {
    return batch(() => {
      closeModalCancelAddIntegrationHandler();

      if (activeStep > ActiveStepEnum.First) {
        setActiveStep(ActiveStepEnum.First);
      }

      redirectToViewListOfIntegrations();

      return;
    });
  }, [activeStep, setActiveStep, closeModalCancelAddIntegrationHandler, redirectToViewListOfIntegrations]);

  const submitRequest = useCallback(
    (
      payload: IAddIntegrationPayload,
      isGoogleClassRoom: boolean,
      isMicrosoftTeams: boolean,
      isGoogleSitesSource: boolean,
      isGoogleSitesDestination: boolean
    ) => {
      if (isGoogleClassRoom) {
        createGoogleClassroom(payload);
      } else if (isMicrosoftTeams) {
        createMicrosoftTeams(payload);
      } else if (isGoogleSitesSource) {
        createGoogleSitesSource(payload);
      } else if (isGoogleSitesDestination) {
        createGoogleSitesDestination(payload);
      }
    },
    [createGoogleClassroom, createGoogleSitesSource, createGoogleSitesDestination, createMicrosoftTeams]
  );

  const submitHandler = useCallback(
    (formValues: IAddIntegrationFormBaseValues) => {
      const {integrationType} = formValues;
      const type = Number(integrationType);
      const isGoogleClassRoom = getIsGoogleClassRoom(type);
      const isMicrosoftTeams = getIsMicrosoftTeams(type);
      const isGoogleSitesSource = getIsGoogleSitesSource(type);
      const isGoogleSitesDestination = getIsGoogleSitesDestination(type);

      const payload = mapAddIntegrationDataForPayload(
        formValues,
        isGoogleClassRoom,
        isMicrosoftTeams,
        isGoogleSitesSource,
        isGoogleSitesDestination
      ) as FormData | any;

      submitRequest(payload, isGoogleClassRoom, isMicrosoftTeams, isGoogleSitesSource, isGoogleSitesDestination);
    },
    [submitRequest]
  );

  const initialValues = useMemo(
    () => ({
      [FiltersFormNameEnum.integrationType]: null,
      [FiltersFormNameEnum.area]: null,
      [FiltersFormNameEnum.schoolName]: null,
      [FiltersFormNameEnum.integrationName]: '',
      [FiltersFormNameEnum.masterClassroomEmailAccount]: '',
      [FiltersFormNameEnum.schoolDistrict]: null,
      [IntegrationFormNameEnum.clientId]: null,
      [IntegrationFormNameEnum.clientSecret]: null,
      [IntegrationFormNameEnum.certificate]: null,
      [IntegrationFormNameEnum.email]: null,
      [IntegrationFormNameEnum.canGiveEditorPermissions]: false,
      [IntegrationFormNameEnum.googleDriveSourceFolderLink]: null,
      [IntegrationFormNameEnum.authorityUrl]: null,
      [IntegrationFormNameEnum.isGoogleClassroom]: false,
      [IntegrationFormNameEnum.isGoogleSitesSource]: false,
      [IntegrationFormNameEnum.isGoogleSitesDestination]: false,
      [IntegrationFormNameEnum.isMicrosoftTeams]: false,
    }),
    []
  );

  const footer = useMemo(
    () => (
      <AddIntegrationContentFooter
        isFirstStep={isFirstStep}
        isSecondStep={isSecondStep}
        isThirdStep={isThirdStep}
        shouldDisableCancelButton={shouldDisableCancelButton}
        shouldDisableNextButton={shouldDisableNextButton}
        nextClickHandler={nextClickHandler}
        openModalCancelAddIntegrationHandler={openModalCancelAddIntegrationHandler}
      />
    ),
    [
      isFirstStep,
      isSecondStep,
      isThirdStep,
      nextClickHandler,
      shouldDisableNextButton,
      shouldDisableCancelButton,
      openModalCancelAddIntegrationHandler,
    ]
  );

  return (
    <Form.Form<IAddIntegrationFormBaseValues>
      initialValues={initialValues}
      validationSchema={addIntegrationFormBaseValidationSchema}
      submitHandler={submitHandler}
      validateOnChange={false}
      validateOnBlur={true}
    >
      <AddIntegrationActiveStepPage
        footer={footer}
        backButtonClickHandler={backButtonClickHandler}
        activeStep={activeStep}
        isFirstStep={isFirstStep}
        isSecondStep={isSecondStep}
        isThirdStep={isThirdStep}
      />
      <ModalCancelAddIntegration
        isOpen={isModalCancelAddIntegration}
        handleClose={closeModalCancelAddIntegrationHandler}
        handleCancelAddIntegration={cancelClickHandler}
      />
    </Form.Form>
  );
};

import {SortingOrderEnum} from 'enums';
import {IntegrationsOrderingEnum} from '../../../enums';

export const integrationsOrderingMap = {
  [IntegrationsOrderingEnum.Name]: {
    [SortingOrderEnum.ASC]: 'Name: A-Z',
    [SortingOrderEnum.DESC]: 'Name: Z-A',
  },
  [IntegrationsOrderingEnum.Created]: {
    [SortingOrderEnum.ASC]: 'Created: Oldest First',
    [SortingOrderEnum.DESC]: 'Created: Newest First',
  },
};

import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';

const getProgramsState = (state: TStateStore) => state.programs.classInstruments;

export const pageSelector = createSelector(getProgramsState, (classInstruments) => classInstruments.page);
export const pageLoadingSelector = createSelector(
  getProgramsState,
  (classInstruments) => classInstruments.page.loading
);

export const poolSelector = createSelector(getProgramsState, (classInstruments) => classInstruments.pool);

export const filterSelector = createSelector(getProgramsState, (classInstruments) => classInstruments.filter);

export const programClassInstrumentsSortOrderSelector = createSelector(
  getProgramsState,
  (classInstruments) => classInstruments.sortOrder
);

export const paginationSelector = createSelector(getProgramsState, (classes) => classes.pagination);

import {
  IActionFulfilled,
  IBindBaseEntity,
  IBindBaseEntityApiResponse,
  IBindBaseEnumEntity,
  IBaseFiltersApiResponse,
  IGetAllFileTypeOptionsApiResponse,
  IGetInstrumentsApiResponse,
  IGetResourcesApiResponse,
  IProgramClassesFilterOptions,
} from 'models';
import {normalize} from 'normalizr';
import {isObject, uniqBy, values} from 'utils';
import {IFiltersSelectionsStoreState} from './IFiltersSelectionsStoreState';
import {createSchema} from './normalizationSchemas';
import {
  fetchAdministrationsThunk,
  fetchAudienceThunk,
  fetchCompetenciesThunk,
  fetchConceptsThunk,
  fetchDomainsThunk,
  fetchEvaluationTypesThunk,
  fetchGradeLevelsThunk,
  fetchInstrumentStatusesThunk,
  fetchInstrumentTypesThunk,
  fetchItemTypesThunk,
  fetchPhasesThunk,
  fetchPillarsThunk,
  fetchProgramsThunk,
  fetchProgramYearsThunk,
  fetchSchoolLevelThunk,
  fetchSessionsThunk,
  fetchTagGradeLevelsThunk,
  fetchSkillsAndBehaviorsThunk,
  fetchLanguagesThunk,
  fetchProgramClassesFiltersThunk,
  fetchAreasThunk,
  fetchDefualtKeyTermsThunk,
  fetchKeyTermsThunk,
  fetchLearningObjectivesThunk,
  fetchDigitalResourcesConceptsThunk,
  fetchDigitalResourceTypesThunk,
  fetchSessionsByProgramIdsThunk,
  fetchInstrumentMainTypesThunk,
  fetchIntegrationListDropdownThunk,
  fetchIntegrationTypesThunk,
  searchSchoolsThunk,
  fetchSchoolDistrictThunk,
  fetchUploadFileTypeOptionsThunk,
  fetchAllFileTypeOptionsThunk,
  fetchFutureProgramYearsThunk,
  fetchResourcesThunk,
  fetchInstrumentsThunk,
  fetchContentItemStatusesThunk,
  fetchFileStatusesThunk,
} from './thunks';

const programsSchema = createSchema('programs');
const gradeLevelsSchema = createSchema('gradeLevels');
const areasSchema = createSchema('areas');
const educatorsSchema = createSchema('educators');
const schoolsSchema = createSchema('schools');
const audiencesSchema = createSchema('audiences');
const phasesSchema = createSchema('phases');
const instrumentTypesSchema = createSchema('instrumentTypes');
const instrumentMainTypesSchema = createSchema('instrumentMainTypes');
const instrumentStatusesSchema = createSchema('instrumentStatuses');
const pillarsSchema = createSchema('pillars');
const domainsSchema = createSchema('domains');
const evaluationTypesSchema = createSchema('evaluationTypes');
const competenciesSchema = createSchema('competencies');
const conceptsSchema = createSchema('concepts');
const administrationsSchema = createSchema('administrations');
const itemTypesSchema = createSchema('itemTypes');
const schoolLevelSchema = createSchema('schoolLevel');
const sessionsSchema = createSchema('sessions');
const tagGradeLevelsSchema = createSchema('tagGradeLevels');
const skillsAndBehaviorsSchema = createSchema('skillsAndBehaviors');
const languagesSchema = createSchema('languages');
const keyTermsSchema = createSchema('keyTerms');
const digitalResourceTypesSchema = createSchema('digitalResourceTypes');
const integrationStatusesSchema = createSchema('integrationStatuses');
const integrationTypesSchema = createSchema('integrationTypes');
const integrationSchoolNamesSchema = createSchema('schoolNames');
const schoolDistrictSchema = createSchema('schoolDistricts');
const uploadFileTypesSchema = createSchema('uploadFileTypes');
const fileTypesSchema = createSchema('fileTypes');
const resourcesSchema = createSchema('resources');
const resourcesBaseSchema = createSchema('resourcesBase', 'contentItemId');
const resourcesConcatenatedBase = createSchema('resourcesConcatenatedBase', 'contentItemId');
const instrumentsSchema = createSchema('instruments');
const instrumentsBaseSchema = createSchema('instrumentsBase');
const instrumentsConcatenatedBase = createSchema('instrumentsConcatenatedBase');
const futureYearsSchema = createSchema('futureYears');
const programYearsSchema = createSchema('years');
const learningPathStatusesSchema = createSchema('learningPathStatuses');
const fileStatusesSchema = createSchema('fileStatuses');

export const filtersSelectionExtra = {
  //fetchProgramsThunk
  [fetchProgramsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntity>
  ) => {
    state.programs = normalize(action.payload, programsSchema);
  },

  //fetchProgramYearsThunk
  [fetchProgramYearsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBaseFiltersApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.years = normalize(data, programYearsSchema);
    }
  },

  //fetchFutureProgramYearsThunk
  [fetchFutureProgramYearsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBaseFiltersApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.futureYears = normalize(data, futureYearsSchema);
    }
  },

  //fetchGradeLevelsThunk
  [fetchGradeLevelsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntity>
  ) => {
    state.gradeLevels = normalize(action.payload, gradeLevelsSchema);
  },

  //fetchAreasThunk
  [fetchAreasThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntity>
  ) => {
    state.areas = normalize(action.payload, areasSchema);
  },

  //fetchProgramClassesFiltersThunk
  [fetchProgramClassesFiltersThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<number, IProgramClassesFilterOptions>
  ) => {
    state.areas = normalize(action.payload.areas, areasSchema);
    state.districts = [...action.payload.districts];
    state.educators = normalize(action.payload.educators, educatorsSchema);
    state.schools = normalize(action.payload.schools, schoolsSchema);
  },

  //fetchAudienceThunk
  [fetchAudienceThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEnumEntity>
  ) => {
    state.audiences = normalize(action.payload, audiencesSchema);
  },

  //fetchPhasesThunk
  [fetchPhasesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEnumEntity>
  ) => {
    state.phases = normalize(action.payload, phasesSchema);
  },

  //fetchInstrumentTypesThunk
  [fetchInstrumentTypesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEnumEntity>
  ) => {
    state.instrumentTypes = normalize(action.payload, instrumentTypesSchema);
  },

  //fetchInstrumentMainTypesThunk
  [fetchInstrumentMainTypesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEnumEntity>
  ) => {
    state.instrumentMainTypes = normalize(action.payload, instrumentMainTypesSchema);
  },

  //fetchInstrumentStatusesThunk
  [fetchInstrumentStatusesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEnumEntity>
  ) => {
    state.instrumentStatuses = normalize(action.payload, instrumentStatusesSchema);
  },

  //fetchPillarsThunk
  [fetchPillarsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEnumEntity>
  ) => {
    state.pillars = normalize(action.payload, pillarsSchema);
  },

  //fetchDomainsThunk
  [fetchDomainsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEnumEntity>
  ) => {
    state.domains = normalize(action.payload, domainsSchema);
  },

  //fetchAdministrationsThunk
  [fetchAdministrationsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEnumEntity>
  ) => {
    state.administrations = normalize(action.payload, administrationsSchema);
  },

  //fetchEvaluationTypesThunk
  [fetchEvaluationTypesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEnumEntity>
  ) => {
    state.evaluationTypes = normalize(action.payload, evaluationTypesSchema);
  },

  //fetchCompetenciesThunk
  [fetchCompetenciesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEnumEntity>
  ) => {
    state.competencies = normalize(action.payload, competenciesSchema);
  },

  //fetchConceptsThunk
  [fetchConceptsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEnumEntity>
  ) => {
    state.concepts = normalize(action.payload, conceptsSchema);
  },

  //fetchItemTypesThunk
  [fetchItemTypesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEnumEntity>
  ) => {
    state.itemTypes = normalize(action.payload, itemTypesSchema);
  },

  //fetchSchoolLevelThunk
  [fetchSchoolLevelThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntity>
  ) => {
    state.schoolLevel = normalize(action.payload, schoolLevelSchema);
  },

  //fetchSessionsThunk
  [fetchSessionsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntity>
  ) => {
    state.sessions = normalize(action.payload, sessionsSchema);
  },

  //fetchTagGradeLevelsThunk
  [fetchTagGradeLevelsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntity>
  ) => {
    state.tagGradeLevels = normalize(action.payload, tagGradeLevelsSchema);
  },

  //fetchSkillsAndBehaviorsThunk
  [fetchSkillsAndBehaviorsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntity>
  ) => {
    state.skillsAndBehaviors = normalize(action.payload, skillsAndBehaviorsSchema);
  },

  //fetchLanguagesThunk
  [fetchLanguagesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntity>
  ) => {
    state.languages = normalize(action.payload, languagesSchema);
  },

  //fetchGradeLevelsThunk
  [fetchGradeLevelsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntity>
  ) => {
    state.gradeLevels = normalize(action.payload, gradeLevelsSchema);
  },

  //fetchDefualtKeyTermsThunk
  [fetchDefualtKeyTermsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntity>
  ) => {
    state.defaultKeyTerms = normalize(action.payload, keyTermsSchema);
  },

  //fetchDigitalResourcesConcepts
  [fetchDigitalResourcesConceptsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, string[]>
  ) => {
    state.digitalResourcesConcepts = action.payload;
  },

  //fetchKeyTerms
  [fetchKeyTermsThunk.fulfilled]: (state: IFiltersSelectionsStoreState, action: IActionFulfilled<null, string[]>) => {
    state.keyTerms = action.payload;
  },

  //fetchLearningObjectives
  [fetchLearningObjectivesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, string[]>
  ) => {
    state.learningObjectives = action.payload;
  },

  //fetchDigitalResourceTypesThunk
  [fetchDigitalResourceTypesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntity>
  ) => {
    state.digitalResourceTypes = normalize(action.payload, digitalResourceTypesSchema);
  },

  //fetchSessionsByProgramIdsThunk
  [fetchSessionsByProgramIdsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntity>
  ) => {
    state.sessions = normalize(action.payload, sessionsSchema);
  },

  // fetchIntegrationListDropdownThunk
  [fetchIntegrationListDropdownThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, any>
  ) => {
    if (isObject(action.payload)) {
      const {
        data: {areas, integrationStatuses, integrationTypes},
      } = action.payload;

      state.areas = normalize(areas, areasSchema);
      state.integrationTypes = normalize(integrationTypes, integrationTypesSchema);
      state.integrationStatuses = normalize(integrationStatuses, integrationStatusesSchema);
    }
  },

  //fetchIntegrationTypesThunk
  [fetchIntegrationTypesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntityApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;

      state.integrationTypes = normalize(data, integrationTypesSchema);
    }
  },

  //searchSchoolsThunk
  [searchSchoolsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntityApiResponse>
  ) => {
    const {data} = action.payload;

    state.selection.loadingSchoolNames = false;
    state.schoolNames = normalize(data, integrationSchoolNamesSchema);
  },

  [searchSchoolsThunk.pending]: (state: IFiltersSelectionsStoreState) => {
    state.selection.loadingSchoolNames = true;
  },
  [searchSchoolsThunk.rejected]: (state: IFiltersSelectionsStoreState) => {
    state.selection.loadingSchoolNames = false;
  },

  //fetchUploadFileTypeOptionsThunk
  [fetchUploadFileTypeOptionsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IGetAllFileTypeOptionsApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.uploadFileTypes = normalize(data, uploadFileTypesSchema);
    }
  },

  //fetchAllFileTypeOptionsThunk
  [fetchAllFileTypeOptionsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IGetAllFileTypeOptionsApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.fileTypes = normalize(data, fileTypesSchema);
    }
  },

  // fetchResourcesThunk
  [fetchResourcesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IGetResourcesApiResponse>
  ) => {
    const {data} = action.payload;

    const resourcesData = data.map(({contentItemId, title}) => ({
      id: contentItemId,
      name: title,
      displayText: title,
    }));

    const uniqueConcatResources = uniqBy(
      [...values(state.resourcesConcatenatedBase.entities.resourcesConcatenatedBase), ...data],
      'contentItemId'
    );

    state.resourcesConcatenatedBase = normalize(uniqueConcatResources, resourcesConcatenatedBase);
    state.resourcesBase = normalize(data, resourcesBaseSchema);
    state.resources = normalize(resourcesData, resourcesSchema);
    state.selection.loadingResources = false;
  },
  [fetchResourcesThunk.pending]: (state: IFiltersSelectionsStoreState) => {
    state.selection.loadingResources = true;
  },
  [fetchResourcesThunk.rejected]: (state: IFiltersSelectionsStoreState) => {
    state.selection.loadingResources = false;
  },

  // fetchInstrumentsThunk
  [fetchInstrumentsThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IGetInstrumentsApiResponse>
  ) => {
    const {data} = action.payload;

    const instrumentsData = data.map(({id, name}) => ({
      id,
      name,
      displayText: name,
    }));

    const uniqueConcatInstruments = uniqBy(
      [...values(state.instrumentsConcatenatedBase.entities.instrumentsConcatenatedBase), ...data],
      'id'
    );

    state.instrumentsConcatenatedBase = normalize(uniqueConcatInstruments, instrumentsConcatenatedBase);
    state.instrumentsBase = normalize(data, instrumentsBaseSchema);
    state.instruments = normalize(instrumentsData, instrumentsSchema);
    state.initialMappedInstruments = instrumentsData;
    state.selection.loadingInstruments = false;
  },
  [fetchInstrumentsThunk.pending]: (state: IFiltersSelectionsStoreState) => {
    state.selection.loadingInstruments = true;
  },
  [fetchInstrumentsThunk.rejected]: (state: IFiltersSelectionsStoreState) => {
    state.selection.loadingInstruments = false;
  },

  // fetchSchoolDistrictThunk
  [fetchSchoolDistrictThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBindBaseEntityApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.schoolDistricts = normalize(data, schoolDistrictSchema);
    }

    state.selection.loadingDistricts = false;
  },
  [fetchSchoolDistrictThunk.pending]: (state: IFiltersSelectionsStoreState) => {
    state.selection.loadingDistricts = true;
  },
  [fetchSchoolDistrictThunk.rejected]: (state: IFiltersSelectionsStoreState) => {
    state.selection.loadingDistricts = false;
  },

  //fetchContentItemStatusesThunk
  [fetchContentItemStatusesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBaseFiltersApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.learningPathStatuses = normalize(data, learningPathStatusesSchema);
    }
  },

  //fetchFileStatusesThunk
  [fetchFileStatusesThunk.fulfilled]: (
    state: IFiltersSelectionsStoreState,
    action: IActionFulfilled<null, IBaseFiltersApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.fileStatuses = normalize(data, fileStatusesSchema);
    }
  },
};

import {SortingOrderEnum} from 'enums';
import {InstrumentsOrderingEnum} from '../../enums';

export const instrumentAllOrderingMap = {
  [InstrumentsOrderingEnum.Name]: {
    [SortingOrderEnum.ASC]: 'Name: A-Z',
    [SortingOrderEnum.DESC]: 'Name: Z-A',
  },
  [InstrumentsOrderingEnum.Created]: {
    [SortingOrderEnum.ASC]: 'Created: Oldest First',
    [SortingOrderEnum.DESC]: 'Created: Newest First',
  },
};

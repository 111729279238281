import React, {FC, memo, useMemo} from 'react';
import {AttachmentArea, FileUploader} from 'components-lib';
import {IWithDisabled} from 'models/common';
import {useDragAndDrop} from 'hooks';
interface IVerticalPositionedProps extends IWithDisabled {
  name: string;
  loading: boolean;
  accept?: string;
  deleteCallback?: () => void;
  fileRef?: any;
}

const VerticalPositioned: FC<IVerticalPositionedProps> = ({name, loading, accept, disabled, deleteCallback}) => {
  const {
    file,
    error,
    fileInputRef,
    addFileClickHandler,
    deleteClickHandler,
    openFileDialogClickHandler,
  } = useDragAndDrop(name, deleteCallback);

  const attachmentArea = useMemo(
    () => <AttachmentArea file={file} error={error} loading={loading} deleteCallback={deleteClickHandler} />,
    [error, loading, deleteClickHandler, file]
  );

  return (
    <FileUploader.Vertical
      accept={accept}
      file={file}
      error={error}
      disabled={disabled}
      attachmentArea={attachmentArea}
      fileInputName={name}
      fileInputRef={fileInputRef}
      addFileCallback={addFileClickHandler}
      openFileDialogClickHandler={openFileDialogClickHandler}
    />
  );
};

export default memo(VerticalPositioned);

import {axiosApiInstance, IHttpResponse} from 'api';
import {ApiPathsEnum} from 'enums';
import {config} from 'config';
import {del, get, post} from './http-client';
import {
  IFetchFilesAllPagedThunkPayload,
  IFetchResourcesThunkPayload,
  IGetAllFilesApiResponse,
  IGetAllFileTypeOptionsApiResponse,
  IGetFileByIdApiResponse,
  IGetInstrumentsThunkPayload,
  IGetInstrumentsApiResponse,
  IGetResourcesApiResponse,
  IResourceItemBase,
  IUpdateFileResourcePayload,
  IUpdateFileResourceApiResponse,
  IUpdateWebLinkResourcePayload,
  IUpdateWebLinkResourceApiResponse,
  IGetFileByResourceIdApiResponse,
} from 'models';
import {
  getApiJsonBlobDataHeadersConfig,
  getApiMultipartFormDataHeadersConfig,
  returnQueryStringWithParams,
} from 'utils';

const {jaEeApi: apiConfig} = config;

export const pamApi = {
  // Enumerations

  async getAllFileTypeOptions(): Promise<IHttpResponse<IGetAllFileTypeOptionsApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.HELPERS}/GetAllFileTypeOptions`;
    return await get<IGetAllFileTypeOptionsApiResponse>(url);
  },

  // Files

  async getAllFiles(payload: IFetchFilesAllPagedThunkPayload): Promise<IHttpResponse<IGetAllFilesApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.RESOURCES}/List`;
    const {pagination, sortOrder, filter} = payload;

    const queryString = returnQueryStringWithParams(url, {
      ...pagination,
      ...sortOrder,
      ...filter,
    });
    return await get<IGetAllFilesApiResponse>(queryString);
  },

  // Resources

  async getFileById(id: string): Promise<IHttpResponse<IGetFileByIdApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.RESOURCES}/${id}/Details`;
    return await get<IGetFileByIdApiResponse>(url);
  },

  async getAllResources(payload: IFetchResourcesThunkPayload): Promise<IHttpResponse<IGetResourcesApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.RESOURCES}/List`;

    const queryString = returnQueryStringWithParams(url, {
      ...payload.filter,
    });
    return await get<IGetResourcesApiResponse>(queryString);
  },

  async deleteFileById(contentItemId: string): Promise<IHttpResponse<IResourceItemBase>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.RESOURCES}/${contentItemId}/Delete`;
    return await del<IResourceItemBase>(url, {});
  },

  async updateFileResource(
    payload: IUpdateFileResourcePayload
  ): Promise<IHttpResponse<IUpdateFileResourceApiResponse>> {
    const configHeaders = getApiMultipartFormDataHeadersConfig();
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.RESOURCES}/${ApiPathsEnum.FILE}/Update`;

    return axiosApiInstance.post(url, payload, configHeaders);
  },

  async updateScormResource(
    payload: IUpdateFileResourcePayload
  ): Promise<IHttpResponse<IUpdateFileResourceApiResponse>> {
    const configHeaders = getApiMultipartFormDataHeadersConfig();
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.RESOURCES}/${ApiPathsEnum.SCORM}/Update`;

    return axiosApiInstance.post(url, payload, configHeaders);
  },

  async updateWebLinkResource(
    payload: IUpdateWebLinkResourcePayload
  ): Promise<IHttpResponse<IUpdateWebLinkResourceApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.RESOURCES}/${ApiPathsEnum.WEB_LINK}/Update`;

    return await post<IUpdateWebLinkResourceApiResponse>(url, payload);
  },

  async downloadFileByResourceId(id: string): Promise<IHttpResponse<IGetFileByResourceIdApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.RESOURCES}/GetFileByResourceId`;
    const config = getApiJsonBlobDataHeadersConfig();
    const queryString = returnQueryStringWithParams(url, {
      id,
    });

    return await axiosApiInstance.get(queryString, config);
  },

  // Instruments

  async getAllInstruments(payload: IGetInstrumentsThunkPayload): Promise<IHttpResponse<IGetInstrumentsApiResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.INSTRUMENT}/GetInstrumentsForLearningPaths`;

    const queryString = returnQueryStringWithParams(url, {
      ...payload.filter,
    });
    return await get<IGetInstrumentsApiResponse>(queryString);
  },
};

import React, {useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Button, Wrapper} from 'components-lib';
import {useItemsImport} from 'admin';

export const DownloadIssuesButton = () => {
  const classes = useStyles();
  const {downloadingCriticalIssues, downloadIssues, itemImportCriticalIssueCount} = useItemsImport();
  const buttonText = `Issues (${itemImportCriticalIssueCount})`;

  const loadingIndicator = useMemo(() => <Wrapper className={classes.loadingContainer}>Loading...</Wrapper>, [
    classes.loadingContainer,
  ]);

  const downloadImportIssuesClickHandler = useCallback(() => downloadIssues(), [downloadIssues]);

  return (
    <Button.Secondary
      className={classes.button}
      size="small"
      disabled={downloadingCriticalIssues}
      clickHandler={downloadImportIssuesClickHandler}
    >
      {!downloadingCriticalIssues && buttonText}
      {downloadingCriticalIssues && loadingIndicator}
    </Button.Secondary>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(6) + (theme.spacing(4) - theme.spacing(2)) - 2,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
}));

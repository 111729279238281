import {config} from 'config';
import {ApiPathsEnum} from 'enums';
import {
  IProgramBase,
  IGetPageOfProgramsResponse,
  IGetPageOfProgramClassesPayload,
  IGetPageOfProgramClassesResponse,
  IGetPageOfProgramMasterInstrumentsResponse,
  IGetPageOfProgramMasterInstrumentsPayload,
  IGetPageOfProgramClassInstrumentsPayload,
  IGetPageOfProgramClassInstrumentsResponse,
  IBindBaseEntity,
  IGetProgramReportResponse,
  IGetPageOfPaperAssessmentsPayload,
  IGetPageOfPaperAssessmentsResponse,
  IGetLevelsOfReportingApiResponse,
  IGetLevelsOfReportingQueryParams,
} from 'models';
import {returnQueryStringWithParams} from 'utils';
import {get, post} from './http-client';
import {IHttpResponse} from './models';

const {jaEeApi} = config;

export const programsApi = {
  async getActiveProgramsByIds(programIds: number[]): Promise<IHttpResponse<IProgramBase[]>> {
    return await post<IProgramBase[]>(`${jaEeApi.baseUrlUms}/${ApiPathsEnum.UMS}/GetActiveProgramsByIds`, programIds);
  },

  async getActiveProgramsPaged({pagination, filter, sortOptions}): Promise<IHttpResponse<IGetPageOfProgramsResponse>> {
    const queryString = returnQueryStringWithParams(
      `${jaEeApi.baseUrlUms}/${ApiPathsEnum.UMS}/GetActiveProgramsPaged`,
      {...pagination, ...sortOptions}
    );

    return await post<IGetPageOfProgramsResponse>(queryString, filter);
  },

  async getProgramClassesPaged({
    filter,
    programId,
    pagination,
    sortOptions,
  }: IGetPageOfProgramClassesPayload): Promise<IHttpResponse<IGetPageOfProgramClassesResponse>> {
    const queryString = returnQueryStringWithParams(
      `${jaEeApi.baseUrlUms}/${ApiPathsEnum.UMS}/GetProgramClassesPaged`,
      {programId, ...pagination, ...sortOptions}
    );

    return await post<IGetPageOfProgramClassesResponse>(queryString, filter);
  },

  async getProgramMasterInstrumentsPaged({
    pagination,
    filter,
    sortOptions,
  }: IGetPageOfProgramMasterInstrumentsPayload): Promise<IHttpResponse<IGetPageOfProgramMasterInstrumentsResponse>> {
    const queryString = returnQueryStringWithParams(
      `${jaEeApi.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/GetMasterTemplatesPaged`,
      {...pagination, ...sortOptions}
    );

    return await post<IGetPageOfProgramMasterInstrumentsResponse>(queryString, filter);
  },

  async getProgramClassInstrumentsPaged(
    payload: IGetPageOfProgramClassInstrumentsPayload
  ): Promise<IHttpResponse<IGetPageOfProgramClassInstrumentsResponse>> {
    const {filter, classId, sortOptions, pagination} = payload;

    const queryString = returnQueryStringWithParams(
      `${jaEeApi.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/GetTemplatesAssociatedWithClass`,
      {
        classId: classId,
        ...pagination,
        ...sortOptions,
      }
    );

    return await post<IGetPageOfProgramClassInstrumentsResponse>(queryString, filter);
  },

  async getProgramClassById(classId: number): Promise<IHttpResponse<IProgramBase>> {
    const queryString = returnQueryStringWithParams(`${jaEeApi.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/GetClassById`, {
      classId,
    });
    return await get<IProgramBase>(queryString);
  },

  async getSortOrderColumns(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${jaEeApi.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/SortTemplates`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getImpactSummaryReport(url: string): Promise<IHttpResponse<IGetProgramReportResponse>> {
    return await get<IGetProgramReportResponse>(url);
  },

  async getAchievementSummaryReport(url: string): Promise<IHttpResponse<IGetProgramReportResponse>> {
    return await get<IGetProgramReportResponse>(url);
  },

  async getStudentGradeBookReport(url: string): Promise<IHttpResponse<IGetProgramReportResponse>> {
    return await get<IGetProgramReportResponse>(url);
  },

  async getStudentAchievementSummaryReport(url: string): Promise<IHttpResponse<IGetProgramReportResponse>> {
    return await get<IGetProgramReportResponse>(url);
  },

  async getClassPaperAssessmentsPaged(
    payload: IGetPageOfPaperAssessmentsPayload
  ): Promise<IHttpResponse<IGetPageOfPaperAssessmentsResponse>> {
    const {programId, classId, pagination, sortOptions} = payload;

    const queryString = returnQueryStringWithParams(`${jaEeApi.baseUrlPam}/${ApiPathsEnum.PAPER_ASSESSMENTS}/List`, {
      programId,
      classId,
      ...pagination,
      ...sortOptions,
    });

    return await get<IGetPageOfPaperAssessmentsResponse>(queryString);
  },

  async getLevelsOfReportingListByProgramId(
    queryParams: IGetLevelsOfReportingQueryParams
  ): Promise<IHttpResponse<IGetLevelsOfReportingApiResponse>> {
    const queryString = returnQueryStringWithParams(
      `${jaEeApi.baseUrlSurvey}/${ApiPathsEnum.TEMPLATE}/LevelsOfReporting`,
      {...queryParams}
    );

    return await get<IGetLevelsOfReportingApiResponse>(queryString);
  },
};

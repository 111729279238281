/* eslint-disable @typescript-eslint/no-use-before-define */

import {makeStyles} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {IOption} from 'models';
import React, {useCallback} from 'react';
import {filter, findIndex} from 'utils';
import State from '../State';

interface IProps {
  id?: string;
  name: string;
  form: any;
  options: IOption[];
  placeholder?: string;
}

// TODO refactor
export default function MultiSelect({id, name, options, placeholder}: IProps) {
  const classes = useStyles();
  const onChange = useCallback(
    (event: any, opt: any, form: any) => {
      const key = opt.key.replace(/^\D+/g, '');
      const newSelect: IOption = {value: key, label: opt.props.value};
      let optElements = form.values[name];

      if (findIndex(form.values[name], newSelect) > -1) {
        optElements = filter(optElements, (p) => p.label !== newSelect.label);
      } else {
        optElements.push(newSelect);
      }
      form.setFieldValue(name, optElements);
    },
    [name]
  );

  return (
    <State>
      {(form) => (
        <Select
          style={{width: '100%'}}
          labelId={id + '-label'}
          id={id}
          multiple
          value={form.values[name]}
          onChange={(event: any, opt: any) => onChange(event, opt, form)}
          input={<Input />}
          renderValue={(selected: IOption[]) =>
            selected && selected.length > 0 ? selected.map((s) => s.label).join(', ') : placeholder
          }
        >
          {options.map((opt: IOption) => (
            <MenuItem key={opt.value} value={opt.label} classes={{root: classes.menuItemRootOverride}}>
              <Checkbox checked={isChecked(form.values[name], opt)} />
              <ListItemText primary={opt.label} />
            </MenuItem>
          ))}
        </Select>
      )}
    </State>
  );
}

const isChecked = (optElements: IOption[], opt: IOption) => {
  return optElements.filter((op) => op.value === opt.value).length > 0;
};

const useStyles = makeStyles(() => {
  return {
    root: {},
    menuItemRootOverride: {
      '& MuiIconButton-root input': {
        padding: 0,
      },
      padding: 0,
    },
  };
});

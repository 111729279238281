import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {IBindInstrumentsAllPage, IGetPageOfInstrumentsPayload} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchInstrumentsAllPagedThunk: any = createAsyncThunk<
  IBindInstrumentsAllPage,
  IGetPageOfInstrumentsPayload,
  {dispatch: any}
>(`${prefix} fetchListInstrumentsPaged`, async (payload: IGetPageOfInstrumentsPayload, {dispatch, rejectWithValue}) => {
  try {
    const {parsedBody} = await instrumentsApi.getPageOfInstruments(payload);
    return parsedBody;
  } catch (err) {
    dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
    return rejectWithValue({error: err});
  }
});

import {IEditFileMetadataFormBaseValues} from '../components/FilesEditResourceForm/config';

export const mapUpdateResourceBasePayload = (
  baseData: {data: FormData; fileId: string; metadata},
  formValues: IEditFileMetadataFormBaseValues
) => {
  const {data, fileId, metadata} = baseData;

  data.append('id', fileId);
  data.append('file', formValues?.file);
  data.append('displayName', formValues.displayName);
  data.append('briefDescription', formValues.briefDescription);
  data.append('metadata', JSON.stringify(metadata));

  return data;
};

import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {SortByDropdown} from 'admin/components';
import {
  getSortGoogleSiteLiesMenuItemsList,
  googleSiteListOrderingMap,
  useGoogleSitesSortOrder,
} from 'admin/resources/google-sites';
import {Container} from 'components-lib';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IGoogleSitesSortByProps {}

export const GoogleSitesSortBy: FC<IGoogleSitesSortByProps> = () => {
  const classes = useStyles();

  const {sortOrder, handleItemClick} = useGoogleSitesSortOrder();

  return (
    <Container disableGutters classes={{root: classes.root}}>
      <SortByDropdown
        label="Sort By: "
        handleItemClick={handleItemClick}
        getMenuItemsList={getSortGoogleSiteLiesMenuItemsList}
        sortOrder={sortOrder}
        map={googleSiteListOrderingMap}
        justify="flex-end"
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(3),
  },
}));

import {useParams} from 'react-router-dom';
import {toNumber} from 'utils';

export function useItemIdParam() {
  const urlParams = useParams<{id: string; answerStackId: string}>();

  const itemId = toNumber(urlParams?.id);
  const answerStackId = toNumber(urlParams?.answerStackId);

  return {itemId, answerStackId};
}

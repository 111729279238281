import {DragLayerMonitor, useDrag} from 'react-dnd';

export const useItemDrag = (itemDrag: any, dragging = true) => {
  const [{isDragging}, drag] = useDrag({
    item: itemDrag,
    collect: (monitor: DragLayerMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      return dragging;
    },
  });

  return {drag, isDragging};
};

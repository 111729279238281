import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {makeStyles} from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {errorMessages} from 'utils';
interface IDatePickerProps {
  value: Date;
  label: string;
  minDate?: Date;
  maxDate?: Date;
  maxWidth?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  disabled?: boolean;
  format?: string;
  minDateMessage?: string;
  maxDateMessage?: string;
  changeHandler: (date: Date, value?: string) => void;
}

export function DatePicker({
  value,
  label,
  changeHandler,
  maxWidth,
  disableFuture = true,
  disablePast = false,
  minDateMessage,
  maxDateMessage,
  disabled,
  format = 'MM/dd/yyyy',
}: IDatePickerProps) {
  const classes = useStyles({maxWidth});

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={classes.root}
        disableToolbar
        autoOk
        variant="inline"
        format={format}
        margin="normal"
        label={label}
        value={value}
        disabled={disabled}
        onChange={changeHandler}
        invalidDateMessage={errorMessages.invalidDate}
        disableFuture={disableFuture}
        disablePast={disablePast}
        minDateMessage={minDateMessage}
        maxDateMessage={maxDateMessage}
        InputProps={{className: classes.input, disableUnderline: true}}
      />
    </MuiPickersUtilsProvider>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    root: ({maxWidth}: Pick<IDatePickerProps, 'maxWidth'>) => {
      let styles: any = {
        margin: 0,
      };

      if (maxWidth) {
        styles = {...styles, width: '100%'};
      }

      return styles;
    },
    input: {
      fontWeight: 600,
      padding: '4.7px 0px 4.7px 12px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      borderRadius: theme.spacing(1),
    },
  };
});

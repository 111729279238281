import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/core';
import {Container} from 'components-lib';
import {layoutConfig} from 'layout/config';
import {IComponentWithChildren} from 'models';

interface IPageLayoutWithFixedAreasProps extends IComponentWithChildren {
  headerSize?: 'small' | 'large';
  header?: ReactNode;
  footer?: ReactNode;
  withSecondaryNavigation?: boolean;
  withExtraHeight?: boolean;
}

export function PageLayoutWithFixedAreas({
  children,
  headerSize = 'large',
  header,
  footer,
  withSecondaryNavigation = true,
  withExtraHeight = true,
}: IPageLayoutWithFixedAreasProps) {
  const classes = useStyles({hasFooter: !!footer, headerSize, withSecondaryNavigation, withExtraHeight});
  return (
    <Container classes={{root: classes.root}}>
      <div className={classes.header}>{header}</div>
      <div className={classes.content}>{children}</div>
      {footer && <div className={classes.footer}>{footer}</div>}
    </Container>
  );
}

const headerHeightSmall = 130;
const headerHeightLarge = 200;
const footerHeight = 60;
const additionalHeight = 20;

type TSize = Pick<IPageLayoutWithFixedAreasProps, 'headerSize'>;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      paddingLeft: theme.spacing(4),
    },
    header: ({headerSize}: TSize) => {
      return {
        height: headerSize === 'small' ? headerHeightSmall : headerHeightLarge,
      };
    },
    content: ({
      hasFooter,
      headerSize,
      withSecondaryNavigation,
      withExtraHeight,
    }: {
      hasFooter: boolean;
      headerSize: 'small' | 'large';
      withSecondaryNavigation: boolean;
      withExtraHeight: boolean;
    }) => {
      const headerHeight = headerSize === 'small' ? headerHeightSmall : headerHeightLarge;
      const footerPagginationHeight = withExtraHeight ? additionalHeight : 0;

      return {
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${
          withSecondaryNavigation
            ? layoutConfig.scrollableAreaSubtrahend
            : layoutConfig.scrollableAreaWithoutSecondaryNavigationSubtrahend
        }px - ${headerHeight + (hasFooter ? footerHeight : 0)}px - ${footerPagginationHeight}px)`,
        overflowY: 'auto',
        overflowX: 'hidden',
      };
    },
    footer: {
      height: footerHeight,
      overflow: 'hidden',
    },
  }),

  // Webpack production build causes CSS to be injected in different order
  // See: https://github.com/mui-org/material-ui/issues/18919
  // index - 0 by default - determines DOM rendering order, higher number = higher specificity (inserted after).
  {
    index: 1,
  }
);

import {createAsyncThunk} from '@reduxjs/toolkit';
import {addIntegrationMessages} from 'admin/integrations/add-integration';
import {integrationsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {integrationDetailsMessages} from '../../details/utils/constants';
import {prefix} from './config';

export const testGoogleSitesSourceThunk: any = createAsyncThunk(
  `${prefix} testGoogleSitesSourceThunk`,
  async (integrationId: string, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await integrationsApi.testGoogleSitesSourceIntegration(integrationId);
      dispatch(toastNotificationManager.createSuccessToastAction(integrationDetailsMessages.testIntegrationSuccess));
      return parsedBody;
    } catch (error) {
      dispatch(
        toastNotificationManager.createErrorToastAction(
          error.messages ?? addIntegrationMessages.unknownIntegrationError
        )
      );
      return rejectWithValue({error});
    }
  }
);

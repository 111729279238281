import React, {Fragment, ReactNode, useMemo} from 'react';
import classnames from 'classnames';
import {Column, Container, Loading, Row} from 'components-lib';
import {footerHeight, navHeight, secondaryNavigation} from 'utils/layout';
import {makeStyles} from '@material-ui/core';

interface IPageLayoutTwoVerticalColsProps {
  header?: ReactNode;
  contentStart: ReactNode;
  contentMiddle: ReactNode;
  footer?: ReactNode;
  secondRow?: ReactNode;
  withFooter?: boolean;
  withSecondaryNavigation?: boolean;
  withFirstColBorderRight?: boolean;
  withColumn?: boolean;
  withDoubleFirst?: boolean;
  withDoubleSecond?: boolean;
  loading?: boolean;
}

export const PageLayoutTwoVerticalCols = ({
  contentStart,
  contentMiddle,
  withSecondaryNavigation = false,
  withFooter = false,
  header,
  footer,
  withFirstColBorderRight = true,
  withDoubleFirst = true,
  withDoubleSecond = false,
  secondRow,
  loading = false,
}: IPageLayoutTwoVerticalColsProps) => {
  let height = `calc(100vh - ${navHeight}px - ${footerHeight}px - 45px)`;
  const firstColSize = !withDoubleFirst ? 4 : 8;
  const secondColSize = !withDoubleSecond ? 4 : 8;

  if (!withFooter) {
    height = `calc(100vh - ${navHeight}px)`;
  }

  const classes = useStyles({height, withFooter, withSecondaryNavigation});

  const firstCol = useMemo(
    () => (
      <Column
        sm={firstColSize}
        md={firstColSize}
        lg={firstColSize}
        xl={firstColSize}
        className={classnames(classes.contentOneOrTwoVerticalCols, withFirstColBorderRight && classes.borderRight)}
      >
        {contentStart && contentStart}
      </Column>
    ),
    [classes.borderRight, classes.contentOneOrTwoVerticalCols, withFirstColBorderRight, contentStart, firstColSize]
  );

  const secondCol = useMemo(
    () => (
      <Column
        sm={secondColSize}
        md={secondColSize}
        lg={secondColSize}
        xl={secondColSize}
        className={classes.contentOneOrTwoVerticalCols}
      >
        {contentMiddle && contentMiddle}
      </Column>
    ),
    [classes.contentOneOrTwoVerticalCols, contentMiddle, secondColSize]
  );

  return (
    <Container classes={{root: classes.contentOneOrTwoVerticalColsRoot}} disableGutters>
      <Row justify="flex-start" classes={{root: classes.headerSecondaryNavigation}}>
        {header}
      </Row>
      <Row classes={{root: classes.row}}>
        {loading ? (
          <Container disableGutters classes={{root: classes.contentOneOrTwoVerticalCols}}>
            <Loading />
          </Container>
        ) : (
          <Fragment>
            {firstCol}
            {secondCol}
            {secondRow && (
              <Row classes={{root: classes.row}}>
                <Column>{secondRow}</Column>
              </Row>
            )}
          </Fragment>
        )}
      </Row>
      {withFooter && (
        <Row justify="space-between" align="center" classes={{root: classes.contentOneOrTwoVerticalColsFooter}}>
          {footer}
        </Row>
      )}
    </Container>
  );
};

interface IContentStyles {
  height: string;
  withFooter?: boolean;
}

export const useStyles = makeStyles((theme) => ({
  contentOneOrTwoVerticalColsRoot: ({height}: IContentStyles) => ({
    flexGrow: 1,
    maxHeight: height,
  }),
  contentOneOrTwoVerticalColsFooter: {
    height: footerHeight,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    background: theme.palette.common.white,
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  row: {
    width: '100%',
    margin: 0,
  },
  contentOneOrTwoVerticalCols: ({height, withFooter}: IContentStyles) => ({
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: !withFooter && theme.spacing(4),
    background: theme.palette.common.white,
    alignItems: 'stretch',
    height: height,
    overflow: 'auto',
    width: '100%',
  }),
  headerSecondaryNavigation: {
    paddingLeft: theme.spacing(4),
    height: secondaryNavigation,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    background: theme.palette.common.white,
  },
}));

import {ILearningPathsAllFilter} from 'models/resources/learning-paths';
import {ILearningPathsFiltersFormValues} from '../schema';
import {LearningPathsAllFilterNamesEnum} from './../../enums';

export const mapLearningPathsAllFilters = (formValues: ILearningPathsFiltersFormValues): ILearningPathsAllFilter => ({
  [LearningPathsAllFilterNamesEnum.SEARCH_BY_QUERY]: formValues.query,
  [LearningPathsAllFilterNamesEnum.PROGRAMS]: formValues.programs,
  [LearningPathsAllFilterNamesEnum.PROGRAM_YEARS]: formValues.programYears,
  [LearningPathsAllFilterNamesEnum.GRADE_LEVELS]: formValues.gradeLevels,
  [LearningPathsAllFilterNamesEnum.PILLARS]: formValues.pillars,
  [LearningPathsAllFilterNamesEnum.LANGUAGES]: formValues.languages,
  [LearningPathsAllFilterNamesEnum.LEARNING_PATH_STATUSES]: formValues.learningPathStatuses,
});

import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {fetchResourcesThunk, filtersFutureYearsEntitiesSelector} from 'filters-selections';
import {useNavigate, useAppDispatch} from 'hooks';
import {pages} from 'paths';
import {useLearningPathBuilderItemsEntities} from 'admin/resources/learning-paths/views/session-builder';
import {createLearningPathThunk, learningPathCreatePageLoadingSelector} from '../store';
import {ILearningPathViewCreateFormBaseValues} from '../utils/schema';
import {IFetchResourcesThunkPayload} from 'models/resources';
import {mapCreateUpdateLearningPathPayload} from 'admin/resources/learning-paths/utils';

export const useCreateLearningPath = () => {
  const dispatch = useAppDispatch();
  const {push} = useNavigate();
  const futureProgramYears = useSelector(filtersFutureYearsEntitiesSelector);
  const {builderSessionItemsEntities, builderSessionItemsCollection} = useLearningPathBuilderItemsEntities();

  const loading = useSelector(learningPathCreatePageLoadingSelector) as boolean;

  const redirectToViewListOLearningPaths: () => void = useCallback(
    () => push(`${pages.adminPortal.learningPaths.root}`),
    [push]
  );

  const createLearningPath = useCallback(
    (formValues: ILearningPathViewCreateFormBaseValues) => {
      const futureProgramYearObj = futureProgramYears[formValues.futureProgramYear];
      const payload = mapCreateUpdateLearningPathPayload(
        {...formValues, futureProgramYear: futureProgramYearObj ? futureProgramYearObj.displayText : null},
        builderSessionItemsCollection
      );
      return dispatch(createLearningPathThunk(payload)).then(unwrapResult);
    },
    [dispatch, builderSessionItemsCollection, futureProgramYears]
  );

  const fetchResources: (payload: IFetchResourcesThunkPayload) => void = useCallback(
    (payload) => {
      return dispatch(fetchResourcesThunk(payload));
    },
    [dispatch]
  );

  const getResourcesIdsByLearningPathId = useCallback(
    (id: string) => {
      const learningPath = builderSessionItemsEntities[id];
      return learningPath.resources.map((resource) => resource.contentItemId);
    },
    [builderSessionItemsEntities]
  );

  return {
    loading,

    createLearningPath,
    redirectToViewListOLearningPaths,
    fetchResources,
    getResourcesIdsByLearningPathId,
  };
};

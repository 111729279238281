import * as Yup from 'yup';
import {FormNameEnum} from 'filters-selections';

export interface IGoogleSitesAreasFormValues {
  [FormNameEnum.searchTerm]: string;
  [FormNameEnum.historyList]: any[];
  [FormNameEnum.historyFilteredList]: any[];
}

export const googleSitesAreasFormValidationSchema = Yup.object().shape({
  [FormNameEnum.searchTerm]: Yup.string().nullable(),
});

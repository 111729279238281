import {createSlice} from '@reduxjs/toolkit';
import {learningPathsAllInitialState} from './learningPathsAll.initialState';
import {learningPathsAllReducer} from './learningPathsAll.reducer';
import {learningPathsAllExtraReducer} from './learningPathsAllExtra.reducer';

export const learningPathsAllSlice = createSlice({
  name: 'learningPathsAll',
  initialState: learningPathsAllInitialState,
  reducers: learningPathsAllReducer,
  extraReducers: learningPathsAllExtraReducer,
});

export const {actions: learningPathsAllSitesActions} = learningPathsAllSlice;

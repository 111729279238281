export enum IntegrationsFilterNamesEnum {
  SEARCH_BY_NAME = 'name',
  INTEGRATION_TYPE = 'integrationType',
  INTEGRATION_TYPES = 'integrationTypes',
  INTEGRATION_STATUSES = 'integrationStatuses',
  AREAS = 'areas',
  SCHOOL_NAMES = 'schoolNames',
  SCHOOL_DISTRICTS = 'schoolDistricts',
  SEARCH_BY_INTEGRATIONS_NAME = 'searchByIntegrationsName',
  SCHOOL_NAME_QUERY = 'schoolNameQuery',
}

import React, {Fragment, useCallback, useEffect, useMemo, useRef} from 'react';
import {SubtitlePageViewHeader} from 'admin/components';
import {Column, Loading, PageViewHeader} from 'components-lib';
import {ActiveModeEnum, useAppDispatch, useModal, useNavigate, useQueryParams} from 'hooks';
import {PageLayoutTwoCol, PageLayoutWithFixedAreas} from 'layout';
import {IItemDetails} from 'models';
import {pages} from 'paths';
import {HeaderActionButtons, ModalDeleteAnswerStack, ModalDeleteItem} from '../components';
import {useItem, useItemIdParam} from '../hooks';
import {itemsActions} from '../store';
interface IItemBaseViewProps {
  Sidebar: any;
  editMode: ActiveModeEnum;
  children: (item: IItemDetails) => void;
}

export function ItemBaseView({children, Sidebar, editMode}: IItemBaseViewProps) {
  const dispatch = useAppDispatch();
  const {itemId, answerStackId} = useItemIdParam();
  const {questionId} = useQueryParams();
  const {push} = useNavigate();
  const shouldClearItem = useRef(true);
  const {item, pageLoading, loadingPageSection} = useItem();
  const {isOpen: isDeleteItemModalOpen, setIsOpen: setIsDeleteItemModalOpen} = useModal();
  const {isOpen: isDeleteAnswerStackModalOpen, setIsOpen: setIsDeleteIAnswerStackModalOpen} = useModal();
  const isEditMode = editMode === ActiveModeEnum.edit;

  const hasAnswerStackId = !!answerStackId;
  const hasEmptyAnswerStacks = item.elements.length === 0;
  const canDelete = !!item?.id && hasEmptyAnswerStacks;
  const buildQuestionMode = !!questionId ? 'Edit' : 'Create';
  const buildHeader = hasAnswerStackId ? 'Answer Stack' : 'Question';
  const questionHeader = `${buildQuestionMode} ${buildHeader}`;
  const answerStackHeader = `Edit ${buildHeader}`;

  const isLoading = pageLoading && !loadingPageSection;

  useEffect(() => {
    return () => {
      // We would not clear the item data if the next route is the item edit
      shouldClearItem.current && dispatch(itemsActions.clear());
    };
  }, [dispatch, shouldClearItem]);

  const backButtonClickHandler = useCallback(() => {
    if (isEditMode) {
      return push(`${pages.adminPortal.items.create}?questionId=${itemId}`);
    }

    return push(pages.adminPortal.items.root);
  }, [push, itemId, isEditMode]);

  const headerSubHeading = useMemo(() => {
    const headerSubHeadingTitle = hasAnswerStackId ? answerStackId : itemId;
    return <SubtitlePageViewHeader> {!!itemId && `/ ID: ${headerSubHeadingTitle}`}</SubtitlePageViewHeader>;
  }, [itemId, answerStackId, hasAnswerStackId]);

  const header = useMemo(() => {
    const headerAction = (
      <HeaderActionButtons
        itemId={itemId}
        isPublished={item.isApproved}
        editMode={editMode}
        canDelete={canDelete}
        editModeClickHandler={() => {
          shouldClearItem.current = false;
          push(`${pages.adminPortal.items.editLink}/${itemId}`);
        }}
        dismissClickHandler={() => {
          if (isEditMode) {
            shouldClearItem.current = false;
            push(`${pages.adminPortal.items.create}?questionId=${itemId}`);
            return;
          }

          push(pages.adminPortal.items.root);
        }}
        deleteModalOpenHandler={() => setIsDeleteItemModalOpen(true)}
        deleteAnswerStackHandler={() => setIsDeleteIAnswerStackModalOpen(true)}
      />
    );

    const heading = editMode === ActiveModeEnum.create ? questionHeader : answerStackHeader;

    return isLoading ? null : (
      <PageViewHeader
        heading={heading}
        subHeading={headerSubHeading}
        action={headerAction}
        withBackButton
        backButtonClickHandler={backButtonClickHandler}
      />
    );
  }, [
    item,
    editMode,
    isEditMode,
    canDelete,
    isLoading,
    headerSubHeading,
    backButtonClickHandler,
    push,
    itemId,
    setIsDeleteItemModalOpen,
    questionHeader,
    answerStackHeader,
    setIsDeleteIAnswerStackModalOpen,
  ]);

  const sidebar = useMemo(
    () => <Sidebar item={item} loading={isLoading} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item, isLoading]
  );

  const content = useMemo(
    () => (
      <PageLayoutWithFixedAreas headerSize="small" header={header}>
        <Column sm={12} md={12} lg={12} xl={12}>
          {isLoading ? <Loading /> : children(item)}
        </Column>
      </PageLayoutWithFixedAreas>
    ),
    [children, header, item, isLoading]
  );

  return (
    <Fragment>
      <PageLayoutTwoCol sidebar={sidebar} content={content} />
      <ModalDeleteItem isOpen={isDeleteItemModalOpen} setIsOpen={setIsDeleteItemModalOpen} />
      <ModalDeleteAnswerStack isOpen={isDeleteAnswerStackModalOpen} setIsOpen={setIsDeleteIAnswerStackModalOpen} />
    </Fragment>
  );
}

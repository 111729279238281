import {useState} from 'react';
import {useSelector} from 'react-redux';
import {pagesTotalSelector} from '../../store';

const initialPage = 1;

export function usePoolItemsPagination() {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const {poolItems: poolItemsPagesTotal} = useSelector(pagesTotalSelector);
  const hasMorePages = poolItemsPagesTotal > currentPage;

  return {
    poolItemsPagesTotal,
    initialPage,
    currentPage,
    setCurrentPage,
    hasMorePages,
  };
}

import {createAsyncThunk} from '@reduxjs/toolkit';
import {integrationsApi} from 'api';
import {IGetPublishedApiSourceDetails} from 'models/integrations';
import {prefix} from './config';

export const fetchPublishedSourceDetailsThunk: any = createAsyncThunk<IGetPublishedApiSourceDetails, any>(
  `${prefix} fetchPublishedSourceDetails`,
  async (_, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await integrationsApi.getPublishedSourceDetails();
      return parsedBody;
    } catch (error) {
      return rejectWithValue({error});
    }
  }
);

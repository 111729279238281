import React, {Fragment} from 'react';
import {TableRow as MUITableRow, TableCell as MUITableCell, makeStyles} from '@material-ui/core';

export const TableRowWithError = ({columns, row, withSecondRow}) => {
  const {textError} = useStyles();
  return (
    <Fragment>
      {columns.map((column) => {
        const value = row[column.id];
        const errorMsg = row['error'];
        return (
          <Fragment key={column.id}>
            <MUITableRow>
              <MUITableCell align={column.align} className={errorMsg && textError}>
                {column.format && typeof value === 'number' ? column.format(value) : value}
              </MUITableCell>
            </MUITableRow>
            {withSecondRow && errorMsg && (
              <MUITableRow>
                <MUITableCell className={textError} align={column.align}>
                  {errorMsg}
                </MUITableCell>
              </MUITableRow>
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  textError: {
    color: theme.palette.error.main,
  },
}));

import React, {useMemo} from 'react';
import {
  TableContainer as MUITableContainer,
  TableBody as MUITableBody,
  Table as MUITable,
  TableHead as MUITableHead,
  TableRow as MUITableRow,
  TableCell as MUITableCell,
  makeStyles,
} from '@material-ui/core';
import {useTable, useResizeColumns} from 'react-table';
import {IColumn} from 'models';

interface IResizableTableProps {
  muiRows: any[];
  columns: IColumn[];
}

const mapTableColumns = (columns: IColumn[]) =>
  columns.map((col) => ({
    Header: col.label,
    id: col.id,
    accessor: col.id,
    disableGroupBy: true,
  }));

const TABLE_COL_CONF = {
  // When using the useFlexLayout:
  minWidth: 30, // minWidth is only used as a limit for resizing
  width: 100, // width is used for both the flex-basis and flex-grow
  maxWidth: 200, // maxWidth is only used as a limit for resizing
};

export const ResizableTable = ({muiRows, columns}: IResizableTableProps) => {
  const classes = useStyles();
  const reactTableColumns = useMemo(() => mapTableColumns(columns), [columns]);
  // muiRows: The data array that you want to display on the table.
  const data = useMemo(() => muiRows, [muiRows]);

  const defaultColumn = useMemo(() => TABLE_COL_CONF, []);

  // Use the state and functions returned from useTable to build your UI
  const {getTableProps, headers, rows, prepareRow} = useTable(
    {
      columns: reactTableColumns,
      data,
      defaultColumn,
    },
    useResizeColumns
    // useFlexLayout
    // not working
    // see: https://github.com/tannerlinsley/react-table/issues/3314
  );

  const tableProps = getTableProps();

  return (
    <MUITableContainer>
      <MUITable {...tableProps}>
        <MUITableHead>
          <MUITableRow>
            {headers.map((column: any, columnIdx: number) => {
              const headerProps = column.getHeaderProps();
              const headerResizeProps = column.getResizerProps();
              // Search or make for a react-table column interface
              return (
                <MUITableCell {...headerProps} key={`headerCellIdx-${columnIdx}`} className={classes.th}>
                  {column.render('Header')}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  {column.canResize && <div {...headerResizeProps} className={classes.resizer} />}
                </MUITableCell>
              );
            })}
          </MUITableRow>
        </MUITableHead>
        <MUITableBody>
          {rows.map((row, rowIdx) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <MUITableRow key={`rowIdx-${rowIdx}`} {...rowProps} tabIndex={-1}>
                {row.cells.map((cell: any, cellIdx: number) => {
                  const cellProps = cell.getCellProps();
                  // Search or make for a react-table cell interface
                  return (
                    <MUITableCell key={`cellIdx-${cellIdx}`} {...cellProps}>
                      {cell.render('Cell')}
                    </MUITableCell>
                  );
                })}
              </MUITableRow>
            );
          })}
        </MUITableBody>
      </MUITable>
    </MUITableContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  th: {
    '&:hover': {
      borderRight: `3px solid ${theme.palette.grey[200]}`,
    },
  },
  resizer: {
    right: 0,
    width: 3,
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 1,
    touchAction: 'none',
  },
}));

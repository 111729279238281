import React, {Fragment, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Row, Text} from 'components-lib';
import {QuestionAnswersTypeEnum} from 'enums';
import {useEntityCard} from 'hooks';
import {IAnswer, IItemColumn} from 'models';
import {getAnswersComponentMap} from 'admin/library/utils';

interface ICardAnswersProps {
  type: QuestionAnswersTypeEnum;
  choices?: IAnswer[];
  columns?: IItemColumn[];
  rows?: IAnswer[];
  questionId: number;
  html?: string;
}

export function EntityCardAnswers({type, choices, columns, rows, questionId, html}: ICardAnswersProps) {
  const classes = useStyles({type});
  const answersComponentMap = getAnswersComponentMap();
  const AnswersComponent: any = useMemo(() => answersComponentMap[type], [type, answersComponentMap]);

  useEntityCard(answersComponentMap, type, questionId);

  const answersContent = useMemo(() => {
    if (!answersComponentMap[type]) {
      const error = `Unknown question answer stack: ${type}!`;
      return <Fragment>{error && <Text.Error>{error}</Text.Error>}</Fragment>;
    }

    return (
      <AnswersComponent
        answers={choices}
        columns={columns}
        rows={rows}
        questionId={questionId}
        type={type}
        html={html}
      />
    );
  }, [AnswersComponent, choices, columns, rows, questionId, type, html, answersComponentMap]);

  return (
    <Row justify="flex-start" classes={{root: classes.answers}}>
      <Fragment>{answersContent}</Fragment>
    </Row>
  );
}

const useStyles = makeStyles((theme) => ({
  answers: ({type}: {type: QuestionAnswersTypeEnum}) => ({
    marginLeft: type === QuestionAnswersTypeEnum.matrix ? 0 : theme.spacing(4),
  }),
}));

import React, {Dispatch, SetStateAction, FC, useCallback, useContext, useMemo} from 'react';
import {batch} from 'react-redux';
import {IWithChildren} from 'models';
import {Form} from 'forms';
import {
  IUploadPaperAssessmentFormBaseValues,
  uploadPaperAssessmentsFormBaseValidationSchema,
} from './uploadPaperAssessmentsBaseConfig';
import {FormNameEnum} from 'enums/form';
import {FormContext} from 'forms/formik/FormContextProvider';
import {useClassPaperAssessments, useUploadPaperAssessmentModal} from 'admin/programs/details';
import {isFunction} from 'utils/functions';

interface IUploadPaperAssessmentFormProps extends IWithChildren {
  closeModalClickHandler?: () => void;
  setIsDirtyCallback: Dispatch<SetStateAction<boolean>>;
}

export const UploadPaperAssessmentForm: FC<IUploadPaperAssessmentFormProps> = ({
  children,
  closeModalClickHandler,
  setIsDirtyCallback,
}) => {
  const {formikFormRef} = useContext(FormContext);
  const {uploadPaperAssessment} = useUploadPaperAssessmentModal();
  const {fetchClassPaperAssessmentsPaged} = useClassPaperAssessments();

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.file]: null,
      [FormNameEnum.description]: '',
    }),
    []
  );

  const submitHandler = useCallback(
    (values) => {
      batch(() => {
        setIsDirtyCallback(false);
        uploadPaperAssessment(values)
          .then(() => isFunction(closeModalClickHandler) && closeModalClickHandler())
          .then(() => fetchClassPaperAssessmentsPaged());
      });
    },
    [uploadPaperAssessment, closeModalClickHandler, setIsDirtyCallback, fetchClassPaperAssessmentsPaged]
  );

  return (
    <Form.Form<IUploadPaperAssessmentFormBaseValues>
      initialValues={initialValues}
      validationSchema={uploadPaperAssessmentsFormBaseValidationSchema}
      submitHandler={submitHandler}
      formikRef={formikFormRef}
    >
      {children}
    </Form.Form>
  );
};

export enum FilterBackendNamesEnum {
  SEARCH_BY_NAME = 'name',
  AUDIENCES = 'audiences',
  PHASES = 'phases',
  TYPES = 'types',
  INSTRUMENT_STATUSES = 'statuses',
  INSTRUMENT_TYPE = 'type',
  INSTRUMENT_VIEW_TYPE = 'isGeneralTemplate',
  PILLARS = 'pillars',
}

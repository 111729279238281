import React, {forwardRef, useCallback} from 'react';
import {batch} from 'react-redux';
import {MenuItem as MUIMenuItem, makeStyles} from '@material-ui/core';
import {IMenuItem, IWithChildren} from 'models/common';
import {isFunction} from 'utils/functions';

interface IMenuItemProps extends IWithChildren {
  item: IMenuItem;
  closeClickHandler: () => void;
}

export const MenuItem = forwardRef(({item, children, closeClickHandler}: IMenuItemProps, ref: any) => {
  const classes = useStyles();

  const menuItemClickHandler = useCallback(
    (item: IMenuItem) =>
      batch(() => {
        closeClickHandler();
        isFunction(item.clickHandler) && item.clickHandler();
      }),
    [closeClickHandler]
  );

  return (
    <MUIMenuItem
      classes={{root: classes.root}}
      ref={ref}
      disabled={item.disabled}
      onClick={() => menuItemClickHandler(item)}
    >
      {children}
    </MUIMenuItem>
  );
});

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 500,
  },
}));

MenuItem.displayName = 'MenuItem';

import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {ICreateQuestionPayload, IQuestionBase} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {itemsMessages} from '../../utils';
import {prefix} from './config';

export const createQuestionThunk: any = createAsyncThunk<IQuestionBase, ICreateQuestionPayload>(
  `${prefix} createQuestion`,
  async (payload: ICreateQuestionPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await instrumentsApi.createQuestion(payload);
      dispatch(toastNotificationManager.createSuccessToastAction(itemsMessages.createQuestionSuccess));
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

import React, {ReactNode} from 'react';

import {IBuilderDragItem} from 'admin/library/models';
import {QuestionAnswersTypeEnum} from 'enums';
import {IAnswer, IQuestionAnswerStackDetails} from 'models';
import {AnswersView} from './AnswersView';
import {AnswersViewDrag} from './AnswersViewDrag';

interface IAnswersProps {
  answers: IAnswer[];
  type: QuestionAnswersTypeEnum;
  draggable?: boolean;
  questionId: number;
  stackId?: number;
  dropHandler?: (item: IBuilderDragItem) => void;
  disabled?: boolean;
  hasMatchedMetadata?: boolean;
  details?: IQuestionAnswerStackDetails;
  headingActions?: ReactNode;
}

export function Answers({
  answers = [],
  type,
  draggable = false,
  questionId,
  stackId,
  dropHandler,
  disabled = false,
  details,
  headingActions,
  hasMatchedMetadata,
}: IAnswersProps) {
  if (draggable && !disabled) {
    return (
      <AnswersViewDrag
        answers={answers}
        type={type}
        questionId={questionId}
        disabled={disabled}
        dropHandler={dropHandler}
        stackId={stackId}
        details={details}
        headingActions={headingActions}
        hasMatchedMetadata={hasMatchedMetadata}
      />
    );
  }

  return (
    <AnswersView
      answers={answers}
      disabled={disabled}
      isDragging={false}
      questionType={type}
      details={details}
      headingActions={headingActions}
      hasMatchedMetadata={hasMatchedMetadata}
    />
  );
}

import React, {useMemo} from 'react';
import {SelectionEnum, SelectionListItems} from 'filters-selections';
import {Container} from 'components-lib';
import {makeStyles} from '@material-ui/core';

export const LinkExistingInstruments = () => {
  const classes = useStyles();
  const list = useMemo(() => [SelectionEnum.Instruments], []);

  return (
    <Container disableGutters classes={{root: classes.selectionList}}>
      <SelectionListItems list={list} />
    </Container>
  );
};

const useStyles = makeStyles(({spacing}) => ({
  selectionList: {
    paddingLeft: spacing(2),
  },
}));

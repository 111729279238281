import React from 'react';
import {FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import {InstrumentEditContainer} from '..';

export const InstrumentEditName = () => (
  <InstrumentEditContainer>
    <Form.Input name={FormNameEnum.templateName} />
  </InstrumentEditContainer>
);

import {createSlice} from '@reduxjs/toolkit';
import {toastNotificationsInitialState} from './toastNotifications.initialState';
import {toastNotificationsReducer as reducers} from './toastNotifications.reducer';

export const toastNotificationsSlice = createSlice({
  name: 'toastNotifications',
  initialState: toastNotificationsInitialState,
  reducers,
});

export const {actions: toastNotificationsActions, reducer: toastNotificationsReducer} = toastNotificationsSlice;

import {useAppDispatch} from 'hooks';
import {useEffect} from 'react';
import {fetchSortByColumnsThunk, fetchSortOrderTypesThunk} from '../store';

export const useItemFilters = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSortByColumnsThunk());
    dispatch(fetchSortOrderTypesThunk());
  }, [dispatch]);

  return {};
};

import React, {FC} from 'react';
import {Form} from 'forms';
import {ItemWithPlaceholder} from 'filters-selections';
import {Container, makeStyles} from '@material-ui/core';
import {IWithDisabled} from 'models/common';

interface IFileUploadFormTextAreaProps extends IWithDisabled {
  label: string;
  name: string;
  placeholder?: string;
  rows?: number;
}

export const FileUploadFormTextArea: FC<IFileUploadFormTextAreaProps> = ({
  label,
  name,
  disabled,
  placeholder = '',
  rows = 6,
}) => {
  const classes = useStyles();

  return (
    <Container disableGutters classes={{root: classes.root}}>
      <ItemWithPlaceholder label={label} hasItems>
        <Form.TextArea disabled={disabled} name={name} rows={rows} placeholder={placeholder} />
      </ItemWithPlaceholder>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(3),
    paddingBottom: 8.5,
  },
}));

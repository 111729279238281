import React from 'react';
import {Column, Container, Row} from 'components-lib';
import {EditLearningPathDetailsHeaderActions} from '../EditLearningPathDetailsHeaderActions/EditLearningPathDetailsHeaderActions';
import {Subtitle} from 'admin/components';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';

export const EditLearningPathDetailsHeader = () => {
  const {hasPermission} = usePermissions();
  const hasSuperAndUSAAdminPermission = hasPermission(USAAndSuperAdminRoles);

  return (
    <Row justify="flex-start">
      <Column sm={12} md={12} lg={12} xl={8}>
        <Container disableGutters>
          <Row justify="space-between">
            <Column sm={9} md={9} lg={3} xl={2}>
              <Subtitle withColor={false} withMargin={false} as="h3" color="textPrimary" title="Details" />
            </Column>
            <Column sm={3} md={3} lg={3} xl={2}>
              {hasSuperAndUSAAdminPermission && <EditLearningPathDetailsHeaderActions />}
            </Column>
          </Row>
        </Container>
      </Column>
    </Row>
  );
};

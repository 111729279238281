import React from 'react';
import {makeStyles} from '@material-ui/core';
import {LoadingSpinner} from '../LoadingSpinner/LoadingSpinner';

interface ILoadingProps {
  fullHeight?: boolean;
  halfHeight?: boolean;
  size?: number;
}

export function Loading({fullHeight = false, halfHeight = false, size}: ILoadingProps) {
  const classes = useStyles({fullHeight, halfHeight});
  return (
    <div className={classes.root}>
      <LoadingSpinner size={size} />
    </div>
  );
}

const useStyles = makeStyles({
  root: ({fullHeight, halfHeight}: Pick<ILoadingProps, 'fullHeight' | 'halfHeight'>) => {
    let height = '100%';

    if (fullHeight) {
      height = '100vh';
    }
    if (halfHeight) {
      height = '50vh';
    }

    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: height,
    };
  },
});

import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchInstrumentForPreview: any = createAsyncThunk(
  `${prefix} fetchInstrumentForPreview`,
  async (templateId: number, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await instrumentsApi.getInstrumentForPreview(templateId);
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {IGetActiveSurveyApiResponse, IGetActiveSurveyPayload} from 'models';
import {prefix} from './config';

export const getActiveSurveyThunk: any = createAsyncThunk<IGetActiveSurveyApiResponse, IGetActiveSurveyPayload>(
  `${prefix} getActiveSurvey`,
  async ({userId, programId, instrumentId}) => {
    try {
      const {parsedBody} = await instrumentsApi.getActiveSurvey(userId, programId, instrumentId);
      return parsedBody;
    } catch (error) {
      throw new Error(error.parsedBody);
    }
  }
);

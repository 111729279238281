import {useParams} from 'react-router-dom';

interface IIntegrationIdParam {
  integrationId: string;
}

export const useIntegrationIdParam = () => {
  const urlParams = useParams<IIntegrationIdParam>();
  const integrationId = urlParams?.integrationId;
  return {integrationId};
};

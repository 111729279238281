import React, {FC, Fragment, useCallback, useMemo} from 'react';
import {Form} from 'forms';
import {Button, Modal, Text} from 'components-lib';
import {useFormikContext} from 'formik';
import {useDebounce} from 'hooks';
import {RolloverProgramFormFields} from './RolloverProgramFormFields';
import {ProgramSelect} from 'filters-selections/selection/components/ProgramSelect/ProgramSelect';
import {FormNameEnum} from 'filters-selections';

interface IModalRolloverProgramProps {
  isOpen: boolean;
  error: string;
  closeHandler: () => void;
}

export const ModalRolloverProgram: FC<IModalRolloverProgramProps> = ({isOpen, error, closeHandler}) => {
  const {resetForm, values} = useFormikContext();
  const debouncedReset = useDebounce(() => resetForm());

  const handleClose = useCallback(() => {
    closeHandler();
    debouncedReset();
  }, [debouncedReset, closeHandler]);

  const programFields = useMemo(() => <RolloverProgramFormFields />, []);

  return (
    <Modal
      disableBackdropClick
      isOpen={isOpen}
      handleClose={handleClose}
      title="Rollover Program and Instruments"
      size="large"
    >
      <Fragment>
        {error && <Text.Error>{error}</Text.Error>}
        {programFields}
        <div>
          <p>
            &nbsp;
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </p>
        </div>{' '}
        {/* TODO: replace this bad vertical space, it needs a style -JH */}
      </Fragment>
      <Fragment>
        <Button.Secondary clickHandler={handleClose} size="small">
          Cancel
        </Button.Secondary>
        <Form.ButtonSubmit text="Submit" size="small" />
      </Fragment>
    </Modal>
  );
};

import React, {useCallback} from 'react';
import {useParams, useRouteMatch} from 'react-router-dom';
import {FormControlLabel, makeStyles, Radio, RadioGroup} from '@material-ui/core';
import {Column, Text} from 'components-lib';
import {ProgramsSectionNavigatorEnum} from 'enums';
import {useNavigate} from 'hooks';
import {IWithDisabled} from 'models';
import {pages} from 'paths';
import {toNumber} from 'utils';

const options = [
  {label: 'Show Instruments', value: ProgramsSectionNavigatorEnum.INSTRUMENTS},
  {label: 'Show Paper Assessments', value: ProgramsSectionNavigatorEnum.PAPER_ASSESSMENTS},
  {label: 'Show Exports & Reports', value: ProgramsSectionNavigatorEnum.REPORTS},
];

const pathToViewMap = {
  [pages.adminPortal.programs.class]: ProgramsSectionNavigatorEnum.INSTRUMENTS,
  [pages.adminPortal.programs.classPaperAssessments]: ProgramsSectionNavigatorEnum.PAPER_ASSESSMENTS,
  [pages.adminPortal.programs.classReports]: ProgramsSectionNavigatorEnum.REPORTS,
};

const viewToLinkMap = {
  [ProgramsSectionNavigatorEnum.INSTRUMENTS]: pages.adminPortal.programs.classLink,
  [ProgramsSectionNavigatorEnum.PAPER_ASSESSMENTS]: pages.adminPortal.programs.classPaperAssessmentsLink,
  [ProgramsSectionNavigatorEnum.REPORTS]: pages.adminPortal.programs.classReportsLink,
};

export function ViewExpansionPanel({disabled}: IWithDisabled) {
  const {programId, classId} = useParams<{programId: string; classId: string}>();
  const {push} = useNavigate();
  const match = useRouteMatch();
  const classes = useStyles();

  const onChange = useCallback(
    (_event: any, value: any) => push(`${viewToLinkMap[toNumber(value)]}/${programId}/${classId}`),
    [classId, programId, push]
  );

  return (
    <Column className={classes.root}>
      <Text.Heading as="h4">View</Text.Heading>
      <RadioGroup aria-label="choose_view" name="choose_view" value={pathToViewMap[match.path]} onChange={onChange}>
        {options.map((option: any, i: number) => (
          <FormControlLabel
            value={option.value}
            control={<Radio color="primary" />}
            label={option.label}
            key={i}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </Column>
  );
}

const useStyles = makeStyles({
  root: {
    paddingLeft: 10,
  },
});

import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {TGetQuestionsApiResponse} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchQuestionsThunk: any = createAsyncThunk<TGetQuestionsApiResponse, boolean>(
  `${prefix} fetchQuestions`,
  async (isApproved = false, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await instrumentsApi.getQuestions(isApproved);
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

import {Accordion, Text} from 'components-lib';
import {Form} from 'forms';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormNameEnum} from '../../../enums';
import {fetchLearningObjectivesThunk, LearningObjectivesSelector} from '../../../store';
import {IWithDisabled} from 'models';

export function LearningObjectivesFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const learningObjectives = useSelector(LearningObjectivesSelector);

  useEffect(() => {
    if (!learningObjectives?.length) {
      dispatch(fetchLearningObjectivesThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const options: any = useMemo(() => learningObjectives.map((objective) => ({value: objective, label: objective})), [
    learningObjectives,
  ]);

  return (
    <Accordion heading="Learning Objectives">
      {learningObjectives.length ? (
        <Form.SelectAutocomplete
          name={FormNameEnum.learningObjectives}
          options={options}
          multiple
          disabled={disabled}
        />
      ) : (
        <Text.Caption>No Data</Text.Caption>
      )}
    </Accordion>
  );
}

import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Column, Container, Icon, Input, Label, LoadingSmall, Row, Tooltip, Wrapper} from 'components-lib';
import {IComponentWithChildren} from 'models';
import {colors} from 'styles';

interface IItemWithPlaceholderProps extends IComponentWithChildren {
  label: string;
  hasItems: boolean;
  infoText?: string;
}

export function ItemWithPlaceholder({children, label, hasItems, infoText}: IItemWithPlaceholderProps) {
  const classes = useStyles();
  return (
    <Column>
      <>
        <Row justify="flex-start">
          <Label label={label} />
          {infoText && (
            <Wrapper className={classes.infoText}>
              <Tooltip title={infoText}>
                <Icon.InfoIcon />
              </Tooltip>
            </Wrapper>
          )}
        </Row>
        {hasItems ? (
          children
        ) : (
          <Container disableGutters classes={{root: classes.container}}>
            <Input withMargin disabled color={colors.gray} />
            <Container classes={{root: classes.loading}}>
              <LoadingSmall />
            </Container>
          </Container>
        )}
      </>
    </Column>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  loading: {
    right: theme.spacing(1),
  },
  infoText: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(1),
  },
}));

export enum FilterNamesEnum {
  SEARCH_BY_NAME = 'searchByName',
  AREAS = 'areasFilter',
  EDUCATORS = 'educatorsFilter',
  SCHOOL_NAMES = 'schoolNamesFilter',
  DISTRICTS = 'districtsFilter',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  ORDER_BY = 'orderBy',
  ORDER_ASC = 'orderAsc',
  YEARS = 'programYearsFilter',
  ONLY_VIRTUAL_CLASSES = 'onlyVirtualClasses',
}

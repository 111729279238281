import React from 'react';
import MatrixQuestion from '../components/MatrixQuestion/MatrixQuestion';
import {IPaperQuestion} from '../models/IPaperQuestion';
import {IPaperNodeSplitResult} from '../models/IPaperNodeSplitResult';
import {PaperNodeTypeEnum} from '../enums/PaperNodeTypeEnum';
import ParticipantsTable from '../components/ParticipantsTable/ParticipantsTable';

// reeturns [{matrix: <Matrix>, isItUsingTotalHeight}]
export const splitPaperNode = (
  filledHeightOnCurrentPage: number,
  availableHeightPerPage: number,
  paperNode: IPaperQuestion
): IPaperNodeSplitResult[] => {
  const questions: IPaperNodeSplitResult[] = [];

  let currentPageHeight = filledHeightOnCurrentPage;

  const {heights, rows} = paperNode;
  const nextHeight = currentPageHeight + heights.total;

  if (nextHeight < availableHeightPerPage) {
    questions.push({
      node: createSplitNode(paperNode, 0, rows.length), // <MatrixQuestion text={text} id={id.toString()} rows={rows} columns={columns} />,
      height: heights.total,
    });
    currentPageHeight += heights.total;
  } else {
    // SPLIT MATRIX
    const questionAreasTotalHeight = heights.questionAreas.reduce((height, rowHeight) => (height += rowHeight), 0);
    const paddingHeight = heights.total - (heights.textArea + heights.headerArea + questionAreasTotalHeight); // correction for paddings

    let startRowIndex = 0;
    let rowIndex = 0;
    let newHeight = currentPageHeight + heights.textArea + heights.headerArea + paddingHeight;

    while (rowIndex < rows.length) {
      // check how much you can fit into the remaining height
      newHeight += heights.questionAreas[rowIndex];

      // add a part of matrix to current page and move to the next
      if (newHeight > availableHeightPerPage) {
        // when the header + 1 row can't fit, move to next page and reset height
        if (startRowIndex === rowIndex) {
          currentPageHeight = 0; // reset page height
          newHeight = calcHeightOfSplit(paperNode, startRowIndex, rowIndex + 1, paddingHeight);
          continue;
        }

        questions.push({
          node: createSplitNode(paperNode, startRowIndex, rowIndex),
          height: calcHeightOfSplit(paperNode, startRowIndex, rowIndex, paddingHeight),
        });

        currentPageHeight = 0; // reset page height
        startRowIndex = rowIndex; // move start row index next

        // reset page height to the height of the header's size plus the first row plus the padding
        newHeight = calcHeightOfSplit(paperNode, startRowIndex, rowIndex + 1, paddingHeight);
      }

      rowIndex++;
    }
    // all rows checked but the height has not reached the page height
    if (startRowIndex <= rowIndex) {
      questions.push({
        node: createSplitNode(paperNode, startRowIndex, rowIndex),
        height: calcHeightOfSplit(paperNode, startRowIndex, rowIndex + 1, paddingHeight),
      });
    }
  }

  return questions;
};

const calcHeightOfSplit = (obj: IPaperQuestion, startRowIndex: number, endRowIndex: number, paddingHeight: number) => {
  const {heights} = obj;
  const rowsHeight = heights.questionAreas
    .slice(startRowIndex, endRowIndex)
    .reduce((height, rowHeight) => (height += rowHeight), 0);

  return heights.textArea + heights.headerArea + rowsHeight + paddingHeight;
};

// return partial node
const createSplitNode = (fullNode: IPaperQuestion, startRowIndex: number, endRowIndex: number) => {
  const {type, id, text, rows, columns} = fullNode;
  switch (type) {
    case PaperNodeTypeEnum.matrix:
      return (
        <MatrixQuestion
          key={id}
          text={text}
          id={id.toString()}
          rows={rows.slice(startRowIndex, endRowIndex)}
          columns={columns}
        />
      );
    case PaperNodeTypeEnum.participantsTable:
      return <ParticipantsTable key={id.toString()} participants={rows.slice(startRowIndex, endRowIndex)} />;
  }
};

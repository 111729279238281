import {createAsyncThunk} from '@reduxjs/toolkit';
import {integrationsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {IUpdateMicrosoftTeamsIntegrationPayload} from 'models/integrations';
import {prefix} from './config';
import {integrationDetailsMessages} from 'admin/integrations';

interface IUpdatePayload {
  integrationId: string;
  payload: IUpdateMicrosoftTeamsIntegrationPayload;
}

export const updateMicrosoftTeamsIntegrationThunk: any = createAsyncThunk(
  `${prefix} updateMicrosoftTeamsIntegration`,
  async ({integrationId, payload}: IUpdatePayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await integrationsApi.updateMicrosoftTeamsIntegration(integrationId, payload);
      dispatch(toastNotificationManager.createSuccessToastAction(integrationDetailsMessages.updateIntegrationSuccess));
      return parsedBody;
    } catch (error) {
      dispatch(
        toastNotificationManager.createErrorToastAction(
          error.messages ?? integrationDetailsMessages.unknownIntegrationError
        )
      );
      return rejectWithValue({error});
    }
  }
);

import {FiltersEnum} from 'filters-selections';
import {FilesAllFilterNamesEnum} from '../../enums';

export const filesAllFilterToFilterNameMap = {
  [FiltersEnum.FileTypeMulti]: FilesAllFilterNamesEnum.FILE_TYPES,
  [FiltersEnum.ProgramMulti]: FilesAllFilterNamesEnum.PROGRAMS,
  [FiltersEnum.TagGradeLevels]: FilesAllFilterNamesEnum.GRADE_LEVELS,
  [FiltersEnum.Pillar]: FilesAllFilterNamesEnum.PILLARS,
  [FiltersEnum.Competencies]: FilesAllFilterNamesEnum.COMPETENCIES,
  [FiltersEnum.KnowledgeSkillsAndAbilities]: FilesAllFilterNamesEnum.KSAS,
  [FiltersEnum.Languages]: FilesAllFilterNamesEnum.LANGUAGES,
  [FiltersEnum.AccessibleToStudentsRadio]: FilesAllFilterNamesEnum.IS_ACCESSIBLE_TO_STUDENTS,
  [FiltersEnum.FileStatusesCheckbox]: FilesAllFilterNamesEnum.FILE_STATUSES,
};

export class UserStore {
  public getItem(key: string) {
    return window.localStorage.getItem(key);
  }

  public setItem(key: string, value: any) {
    return window.localStorage.setItem(key, value);
  }

  public removeItem(key: string) {
    return window.localStorage.removeItem(key);
  }

  public removeAllItems() {
    Object.keys(localStorage).forEach((key) => localStorage.removeItem(key));
  }

  public key(index: number) {
    return window.localStorage.key(index);
  }

  public length() {
    return window.localStorage.length;
  }
}

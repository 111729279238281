import {makeStyles} from '@material-ui/core';
import {cardTypeMap} from 'admin/library/enums';
import {Button, Divider, Icon, Row, Text} from 'components-lib';
import {QuestionAnswersTypeEnum} from 'enums';
import React, {Fragment} from 'react';
import {CardItemPositionTag} from './CardItemPositionTag';
import {CardLinkInfoTooltip} from 'admin/library/instruments/components';
import {Form} from 'forms';

interface ICardBodyProps {
  id?: number | null;
  type?: QuestionAnswersTypeEnum;
  title?: string;
  linkedQuestionAnswerStackId?: number;
  position?: number;
  totalItems?: number;
  withDelete?: boolean;
  withLink?: boolean;
  deleteClickHandler?: () => void;
  withEdit?: boolean;
  editClickHandler?: () => void;
  withApply?: boolean;
  applyClickHandler?: () => void;
  moveHandler?: (movedItemIdx: number, newPosition: number) => void;
  linkClickHandler?: () => void;
  withItemInfo?: boolean;
  openInfoLinkClickHandler?: () => void;
  withSubmit?: boolean;
}

export function CardBody({
  id,
  type,
  title,
  linkedQuestionAnswerStackId,
  position,
  totalItems,
  withDelete,
  deleteClickHandler,
  withEdit,
  editClickHandler,
  withApply,
  applyClickHandler,
  withLink,
  linkClickHandler,
  moveHandler,
  withItemInfo,
  openInfoLinkClickHandler,
  withSubmit,
}: ICardBodyProps) {
  const classes = useStyles();
  const shouldRenderActions = withDelete || withEdit || withApply || withLink;

  return (
    <>
      <Row justify="flex-start" classes={{root: classes.header}}>
        <Fragment>
          {moveHandler && (
            <Fragment>
              <CardItemPositionTag totalItems={totalItems} position={position} moveHandler={moveHandler} />
              <Divider.Vertical size="small" />
            </Fragment>
          )}
        </Fragment>
        {type && <Text.Paragraph variant="body2">{cardTypeMap.get(type)}</Text.Paragraph>}
        {id && (
          <Fragment>
            <Divider.Vertical size="small" />
            <Text.Paragraph variant="body2">Item ID: {id}</Text.Paragraph>
          </Fragment>
        )}
        {linkedQuestionAnswerStackId && withItemInfo && openInfoLinkClickHandler && (
          <Fragment>
            <Divider.Vertical size="small" />
            <CardLinkInfoTooltip clickHandler={openInfoLinkClickHandler} />
          </Fragment>
        )}
        {shouldRenderActions && (
          <div className={classes.deleteButton}>
            {withEdit && (
              <Button.Icon clickHandler={editClickHandler}>
                <Icon.EditIcon />
              </Button.Icon>
            )}
            {withLink && (
              <Button.Icon clickHandler={linkClickHandler}>
                <Icon.LinkIcon />
              </Button.Icon>
            )}
            {withDelete && (
              <Button.Icon clickHandler={deleteClickHandler}>
                <Icon.DeleteIcon />
              </Button.Icon>
            )}
            {withApply && (
              <Fragment>
                {!withSubmit && (
                  <Button.Secondary size="small" clickHandler={applyClickHandler}>
                    Apply
                  </Button.Secondary>
                )}

                {withSubmit && <Form.ButtonSubmit size="small" text="Apply" />}
              </Fragment>
            )}
          </div>
        )}
      </Row>
      <Row justify="flex-start">{title && <Text.Heading as="h3">{title}</Text.Heading>}</Row>
    </>
  );
}

const useStyles = makeStyles((theme) => {
  const marginBottom = theme.spacing(3);
  return {
    header: {
      marginBottom,
    },
    heading: {
      marginBottom,
    },
    paragraph: {
      color: theme.palette.grey[100],
    },
    dragIcon: {
      marginRight: theme.spacing(3),
    },
    deleteButton: {
      marginLeft: 'auto',
    },
  };
});

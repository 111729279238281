import {Search} from 'components-lib';
import React from 'react';
import State from '../State';

interface IProps {
  name: any;
  isDisabled?: boolean;
}

// TODO refactor
export default function CustomizedSearchButton({name, isDisabled}: IProps) {
  const onChange = (event: any, form: any) => {
    form.setFieldValue(name, event.target.value);
  };

  return (
    <State>
      {(form) => (
        <Search
          value={form.values[name]}
          changeHandler={(ev: any) => onChange(ev, form)}
          submitDisabled={form.isSubmitting || isDisabled}
        />
      )}
    </State>
  );
}

import React, {Component} from 'react';

class ErrorBoundary extends Component {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  componentDidCatch(error: Error) {
    console.log(error);
  }

  static getDerivedStateFromError() {
    return <div>The system has found an unrecoverable error.</div>;
  }

  render() {
    return this.props.children;
  }
}

export {ErrorBoundary};

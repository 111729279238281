import {Accordion, Text} from 'components-lib';
import {Form} from 'forms';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormNameEnum} from '../../../enums';
import {fetchKeyTermsThunk, KeyTermsSelector} from '../../../store';
import {IWithDisabled} from 'models';

export function KeyTermsFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const keyTerms = useSelector(KeyTermsSelector);

  useEffect(() => {
    if (!keyTerms?.length) {
      dispatch(fetchKeyTermsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const options: any = useMemo(() => keyTerms.map((term) => ({value: term, label: term})), [keyTerms]);

  return (
    <Accordion heading="Key Terms">
      {keyTerms.length ? (
        <Form.SelectAutocomplete name={FormNameEnum.keyTerms} options={options} multiple disabled={disabled} />
      ) : (
        <Text.Caption>No Data</Text.Caption>
      )}
    </Accordion>
  );
}

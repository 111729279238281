import React, {FC} from 'react';
import {PillarSelect} from './PillarSelect';
import {IWithDisabled} from 'models';
import {FormNameEnum} from 'filters-selections';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPrimaryPillarSelectProps extends IWithDisabled {}

export const PrimaryPillarSelect: FC<IPrimaryPillarSelectProps> = ({disabled}) => {
  return (
    <PillarSelect name={FormNameEnum.primaryPillar} selectionType="single" disabled={disabled} label="Primary Pillar" />
  );
};

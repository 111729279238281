import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {filesAllFiltersEntitiesSelector, filterAccessibleToStudentsEntitiesSelector} from 'filters-selections';
import {useAppDispatch, useNavigate} from 'hooks';
import {IBaseFileItem, IFilesAllFilter, IFilesAllSortOrder} from 'models/resources';
import {pages} from 'paths';
import {userAreaSelector} from 'user';
import {createLinkForDownload, openFileInNewWindow} from 'utils';
import {
  fetchFilesAllPagedThunk,
  fileDetailsSelector,
  filesAllEntitiesCollectionSelector,
  filesAllEntitiesSelector,
  filesAllIdsSelector,
  pageLoadingSelector,
  paginationSelector,
  filesAllSortOrderSelector,
  filesAllFilterSelector,
  downloadFileThunk,
  fileDownloadingSelector,
  downloadingIdSelector,
} from '../store';
import {filesAllMessages, mapFetchFilesAllPayload} from '../utils';
import {useToastNotifications} from 'toast-notifications';

export const useFilesAll = () => {
  const {push} = useNavigate();
  const dispatch = useAppDispatch();
  const {addToastNotification} = useToastNotifications();

  const loading = useSelector(pageLoadingSelector) as boolean;
  const filesAllIds = useSelector(filesAllIdsSelector) as string[];
  const filesAllEntities = useSelector(filesAllEntitiesSelector);
  const filesAllEntitiesCollection = useSelector(filesAllEntitiesCollectionSelector) as IBaseFileItem[];
  const file = useSelector(fileDetailsSelector) as IBaseFileItem;
  const sortOrder = useSelector(filesAllSortOrderSelector) as IFilesAllSortOrder;
  const filter = useSelector(filesAllFilterSelector) as IFilesAllFilter;
  const {currentPage, perPage} = useSelector(paginationSelector);
  const filterEntities = useSelector(filesAllFiltersEntitiesSelector);
  const filterAccessibleToStudentsEntities = useSelector(filterAccessibleToStudentsEntitiesSelector);
  const downloadingId = useSelector(downloadingIdSelector);
  const fileDownloading = useSelector(fileDownloadingSelector);
  const userArea = useSelector(userAreaSelector);

  const fetchFilesAllPaged: () => void = useCallback(() => {
    const payload = mapFetchFilesAllPayload(sortOrder, currentPage, perPage, filter);
    return dispatch(fetchFilesAllPagedThunk(payload));
  }, [dispatch, currentPage, perPage, sortOrder, filter]);

  const downloadFile = useCallback(
    (id: string) =>
      dispatch(downloadFileThunk(id))
        .then(unwrapResult)
        .then(({blob, fileName}) => {
          const objectUrl = window.URL.createObjectURL(blob);
          createLinkForDownload(objectUrl, fileName);
          // Freeing resources in memory
          window.URL.revokeObjectURL(objectUrl);
        }),
    [dispatch]
  );

  const openFileInNewTab: (url: string) => void = useCallback(
    (url: string) => {
      openFileInNewWindow(url);
      addToastNotification(filesAllMessages.openInNewTab);
    },
    [addToastNotification]
  );

  const redirectToEditFilePage: (id: string) => void = useCallback(
    (id: string) => {
      push(`${pages.adminPortal.files.editLink}/${id}`);
    },
    [push]
  );

  const copyClickHandler = useCallback(() => addToastNotification(filesAllMessages.resourceCopied), [
    addToastNotification,
  ]);

  return {
    loading,
    file,
    filesAllIds,
    filesAllEntities,
    filesAllEntitiesCollection,
    sortOrder,
    filter,
    filterEntities,
    filterAccessibleToStudentsEntities,
    userArea,
    fileDownloading,
    downloadingId,

    fetchFilesAllPaged,
    openFileInNewTab,
    redirectToEditFilePage,
    downloadFile,
    copyClickHandler,
  };
};

import {cloneDeep} from 'utils';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {integrationsApi} from 'api';
import {SortingOrderEnum} from 'enums';
import {IGetPageOfEntities, IIntegratiosAllFilter, IPaginationOptions, ISortingOptions} from 'models';
import {IIntegrationItem, IIntegrationsAllSortOrder} from 'models/integrations';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {getIntegrationQueryFilter} from 'admin';

interface IPayload {
  filter: IIntegratiosAllFilter;
  sortOrder: IIntegrationsAllSortOrder;
  pagination: IGetPageOfEntities;
}

export const fetchIntegrationsPagedThunk: any = createAsyncThunk<IIntegrationItem[], any>(
  `${prefix} fetchIntegrationsPaged`,
  async (
    {filter, sortOrder: {order, sort}, pagination: {page, itemsPerPage}}: IPayload,
    {dispatch, rejectWithValue}
  ) => {
    try {
      const sortAsc = sort === SortingOrderEnum.ASC;
      const clonedFilter = cloneDeep(filter);
      const queryFilter = getIntegrationQueryFilter(clonedFilter, ',');
      const pagination = {pageNumber: page, pageSize: itemsPerPage} as IPaginationOptions;
      const sortOptions = {sortBy: order, sortAsc} as ISortingOptions;

      const {parsedBody} = await integrationsApi.getIntegrations(pagination, sortOptions, queryFilter);
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

import {googleSitesMessages} from './../../utils/constants/messages';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {googleSitesApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {IGoogleSiteCopyApiResponse, IGoogleSiteCopyPayload} from 'models/resources';
import {arrayOfStringsToString, isEmpty} from 'utils';

export const fetchGoogleSitesCopySiteThunk: any = createAsyncThunk<IGoogleSiteCopyApiResponse, IGoogleSiteCopyPayload>(
  `${prefix} fetchGoogleSitesCopySite`,
  async (payload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await googleSitesApi.getGoogleSitesCopySite(payload);
      dispatch(toastNotificationManager.createSuccessToastAction(googleSitesMessages.copySuccess));
      return parsedBody;
    } catch (error) {
      if (!isEmpty(error.messages)) {
        const errorMsg = arrayOfStringsToString(error.messages);
        dispatch(toastNotificationManager.createErrorToastAction(errorMsg));
      } else {
        dispatch(toastNotificationManager.createErrorToastAction(googleSitesMessages.copyError));
      }

      return rejectWithValue({error});
    }
  }
);

import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Box, Column, Row} from 'components-lib';
import {IBoxListItem} from 'models/resources';

interface IResourceUserDetailsProps {
  list: IBoxListItem[];
}

export const ResourceUserDetails: FC<IResourceUserDetailsProps> = ({list}) => {
  const classes = useStyles();

  return (
    <Row justify="flex-start" align="flex-start">
      {list.map(({size = 'large', label, content, userInitials = ''}, idx: number) => (
        <Column sm={6} md={6} lg={3} xl={2} key={idx} className={classes.boxColumn}>
          <Box className={classes.box} size={size} label={label} content={content} userInitials={userInitials} />
        </Column>
      ))}
    </Row>
  );
};

const useStyles = makeStyles((theme) => ({
  box: {
    height: 28,
    display: 'flex',
    alignItems: 'center',
  },
  boxColumn: {
    paddingBottom: theme.spacing(3),
  },
}));

import {IActionFulfilled, IProgramMasterInstrumentsFilter, IProgramsMasterInstrumentsSortOrder} from 'models';
import {cloneDeep} from 'utils';

import {IProgramMasterInstrumentsState} from './IProgramMasterInstrumentsState';
import {programMasterInstrumentsInitialState} from './programMasterInstruments.initialState';

export const programMasterInstrumentsReducer = {
  setFilter: (
    state: IProgramMasterInstrumentsState,
    action: IActionFulfilled<null, IProgramMasterInstrumentsFilter>
  ) => {
    state.filter = action.payload;
    resetPagination(state);
  },

  resetFilter: (state: IProgramMasterInstrumentsState) => {
    state.filter = cloneDeep(programMasterInstrumentsInitialState.filter);
    resetPagination(state);
  },

  setCurrentPage: (state: IProgramMasterInstrumentsState, action: IActionFulfilled<null, number>) => {
    state.pagination.currentPage = action.payload;
  },

  setPerPage: (state: IProgramMasterInstrumentsState, action: IActionFulfilled<null, number>) => {
    state.pagination.perPage = action.payload;
    resetPagination(state);
  },

  setSortOrderProgramClasses: (
    state: IProgramMasterInstrumentsState,
    action: IActionFulfilled<null, IProgramsMasterInstrumentsSortOrder>
  ) => {
    state.sortOrder = action.payload;
    resetPagination(state);
  },
};

const resetPagination = (state: IProgramMasterInstrumentsState) => {
  state.pagination.currentPage = programMasterInstrumentsInitialState.pagination.currentPage;
  state.pagination.totalCount = programMasterInstrumentsInitialState.pagination.totalCount;
};

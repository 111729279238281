/* eslint-disable react/display-name */
import Input from '@material-ui/core/Input';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {Row} from 'components-lib';
import {Checkbox} from 'components-lib/Checkbox/Checkbox';
import {ErrorMessage, Field, FieldArray} from 'formik';
import * as React from 'react';
import {uniqueId} from 'utils';
import AutocompleteCustom from './Autocomplete';
import {AutocompleteWithSelect} from './AutocompleteWithSelect';
import DatePicker from './DatePicker';
import MultiSelect from './Multiselect';
import CustomizedSearchButton from './Search';

// TODO refactor and drop in favor of the form module
const COMPONENTS = {
  undefined: ({field, ...props}: any) => <Input type="text" className="form-control" {...field} {...props} />,
  text: ({field, ...props}: any) => <Input type="text" className="form-control" {...field} {...props} />,
  search: ({form: _form, field, ...props}: any) => <CustomizedSearchButton form={_form} {...field} {...props} />,
  password: ({field, ...props}: any) => <Input type="password" className="form-control" {...field} {...props} />,
  email: ({field, ...props}: any) => <Input type="email" className="form-control" {...field} {...props} />,
  textarea: ({field, ...props}: any) => <TextareaAutosize className="form-control" {...field} {...props} />,
  checkbox: ({field, form, ...props}: any) => {
    return (
      <Row justify="flex-start">
        <Checkbox
          {...props}
          changeHandler={() => {
            const isValueSet = field.value.includes(props.value);
            const nextValue = isValueSet
              ? field.value.filter((value) => value !== props.value)
              : field.value.concat(props.value);
            form.setFieldValue(field.name, nextValue);
          }}
          label={props.label}
          name={field.name}
          value={props.value}
        />
      </Row>
    );
  },
  autocomplete: ({form: _form, field, options, isMultipleSelect, disabled, handleChange, ...props}: any) => (
    <AutocompleteCustom
      options={options}
      form={_form}
      isMultipleSelect={isMultipleSelect}
      disabled={disabled}
      handleChange={handleChange}
      {...field}
      {...props}
    />
  ),
  customAutocomplete: ({form: _form, field, options, isMultipleSelect, disabled, handleChange, ...props}: any) => {
    return (
      <AutocompleteWithSelect
        isMultipleSelect={isMultipleSelect}
        disabled={disabled}
        handleChange={handleChange}
        options={options}
        form={_form}
        {...field}
        {...props}
      />
    );
  },
  multiselect: ({form, field, options, ...props}: any) => (
    <MultiSelect options={options} form={form} {...field} {...props} />
  ),
  datePicker: ({field, placeholder, date, onChange, ...props}: any) => (
    <DatePicker placeholder={placeholder} date={date} onChange={onChange} {...field} {...props} />
  ),
};

interface IProps {
  name: string;
  label?: any;
  control?: any;
  noLabel?: boolean;
  isMultipleSelect?: boolean;
  disabled?: boolean;
  handleChange?: (name: any, value: any[]) => void;
  id?: string;
  [key: string]: any;
}

export default function FormField({
  id,
  label,
  name,
  component,
  isMultipleSelect,
  disabled,
  handleChange,
  ...otherProps
}: IProps) {
  const idx = React.useMemo(() => id || uniqueId(`form-${name}-`), [id, name]);

  const componentIsFunction: boolean = typeof component === 'function';

  const Component: any = componentIsFunction ? component : (COMPONENTS as any)[component];

  return (
    <>
      {Component.isArray ? (
        <FieldArray name={name}>
          {(arrayHelpers) => (
            <Component
              arrayHelpers={arrayHelpers}
              array={arrayHelpers.form.values[name]}
              id={id}
              label={label}
              {...otherProps}
            />
          )}
        </FieldArray>
      ) : (
        <Field
          id={idx}
          name={name}
          component={Component}
          isMultipleSelect={isMultipleSelect}
          disabled={!!disabled}
          handleChange={handleChange}
          label={label}
          {...otherProps}
        />
      )}
      <ErrorMessage name={name}>{(error) => <div>{error}</div>}</ErrorMessage>
    </>
  );
}

FormField.defaultProps = {
  component: 'text',
};

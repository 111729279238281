import React from 'react';
import {PageViewHeader} from 'components-lib';

export const WebLinkHeader = () => {
  return (
    <PageViewHeader
      fullFlexWidth
      withColumnPadding={false}
      heading="Add Web Link"
      headingColor="textPrimary"
      withBackButton={false}
    />
  );
};

import React, {Fragment} from 'react';
import {Row} from 'components-lib';
import {ErrorIcon, ErrorMessage} from '..';

export const ErrorBoxMessageList = ({errorMessages, defaultMessage}) => {
  return (
    <Fragment>
      {errorMessages.map((message: string, idx: number) => (
        <Row key={idx} justify="flex-start">
          <ErrorIcon />
          <ErrorMessage message={message} defaultMessage={defaultMessage} />
        </Row>
      ))}
    </Fragment>
  );
};

import {BuilderItemDragTypeEnum} from 'admin/library/enums';
import {IBuilderDragItem} from 'admin/library/models';
import {useDrag, useDrop} from 'react-dnd';

export function useBuilderItemDrop(
  item: IBuilderDragItem,
  ref: any,
  position: number,
  moveHandler: (dragIdx: number, hoverIdx: number) => void
) {
  const [, drop] = useDrop({
    accept: BuilderItemDragTypeEnum.builderItem,
    hover(item: IBuilderDragItem) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.position;
      const hoverIndex = position;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveHandler(dragIndex, hoverIndex);
      item.position = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    item,
  });

  drag(drop(ref));
}

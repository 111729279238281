export const instrumentsAllColumns = [
  {id: 'mtIcon', label: ''},
  {id: 'name', label: 'Instrument'},
  {id: 'programsList', label: 'Program'},
  {id: 'audience', label: 'Audience'},
  {id: 'type', label: 'Type'},
  {id: 'phase', label: 'Phase'},
  {id: 'status', label: 'Status'},
  {id: 'actions', label: ''},
];

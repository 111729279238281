import React, {FC} from 'react';
import {usePermissions} from 'permissions';
import {SuperAndNationalAdminroles} from 'enums/admin-roles';
import {PermissionCheckbox} from './PermissionCheckbox';
import {IWithDisabled} from 'models/common';

type IAdminPermissionCheckboxProps = IWithDisabled;

export const AdminPermissionCheckbox: FC<IAdminPermissionCheckboxProps> = ({disabled}) => {
  const {hasPermission} = usePermissions();
  const hasAdminPermission = hasPermission(SuperAndNationalAdminroles) as boolean;
  const checked = hasAdminPermission ? hasAdminPermission : undefined;

  return <PermissionCheckbox disabled={disabled || hasAdminPermission} checked={checked} />;
};

import * as Yup from 'yup';
import {FormNameEnum} from 'enums/form';
import {emailRegex, errorMessages, isEmpty} from 'utils';
import {useIntegrationDetailType} from 'admin';
import {GOOGLE_DRIVE_URL, SUPPORTED_CERTIFICATE_FORMATS} from 'admin/integrations/add-integration';

export const integrationDetailsFormBaseValidationSchema = Yup.object().shape({
  // All integrations

  [FormNameEnum.integrationName]: Yup.string()
    .trim()
    .required(errorMessages.requiredWithInputName('an Integration Name')),

  // Google Classroom

  [FormNameEnum.masterClassroomEmailAccount]: Yup.string()
    .trim()
    .nullable()
    // Optional field
    .test('is-valid-master-email-account', 'User to impersonate should be a valid email!', function () {
      const {integrationType, masterClassroomEmailAccount} = this.parent;

      // Check if a type exist and a master account is empty
      if (!integrationType || isEmpty(masterClassroomEmailAccount)) {
        return true;
      }

      const {isGoogleClassRoom} = useIntegrationDetailType(integrationType);

      if (isGoogleClassRoom) {
        // Check if a master user email account is not empty and match an email regex
        return masterClassroomEmailAccount.match(emailRegex);
      }

      return true;
    }),

  // Google Classroom + Google Sites Source

  [FormNameEnum.serviceEmail]: Yup.string()
    .nullable()
    .trim()
    // Required field
    .test('is-service-email-exist', errorMessages.requiredWithInputName('a Service Email Address'), function () {
      const {integrationType, serviceEmail} = this.parent;

      // Double check if type exist
      if (!integrationType) {
        return true;
      }

      const {isGoogleClassRoom, isGoogleSitesSource} = useIntegrationDetailType(integrationType);

      if (isGoogleClassRoom || isGoogleSitesSource) {
        // Check if the service email is not empty
        return !isEmpty(serviceEmail);
      }

      return true;
    })
    .test('is-valid-service-email', 'Service Email Address should be a valid email!', function () {
      const {serviceEmail} = this.parent;

      if (serviceEmail) {
        return serviceEmail.match(emailRegex);
      }

      return true;
    }),

  [FormNameEnum.certificate]: Yup.mixed()
    .nullable()
    .test('has-correct-format', 'Only .p12 format is supported', function () {
      const {integrationType, certificate} = this.parent;

      // Check if a type exist
      if (!integrationType || !certificate) {
        return true;
      }

      const {isGoogleClassRoom, isGoogleSitesSource} = useIntegrationDetailType(integrationType);

      if (isGoogleClassRoom || isGoogleSitesSource) {
        return SUPPORTED_CERTIFICATE_FORMATS.includes(certificate.type);
      }

      return true;
    }),

  // Microsoft teams

  [FormNameEnum.clientId]: Yup.string()
    .nullable()
    .trim('The client id cannot include leading and trailing spaces!')
    .test('is-client-id-exist', errorMessages.requiredWithInputName('a Client ID'), function () {
      const {integrationType, clientId} = this.parent;

      // Check if a type exist
      if (!integrationType) {
        return true;
      }

      const {isMicrosoftTeams} = useIntegrationDetailType(integrationType);

      if (isMicrosoftTeams) {
        return !isEmpty(clientId);
      }

      return true;
    }),

  [FormNameEnum.clientSecret]: Yup.string()
    .nullable()
    .trim('The client secret cannot include leading and trailing spaces!')
    .test('is-client-secret-exist', errorMessages.requiredWithInputName('a Client Secret'), function () {
      const {integrationType, clientSecret} = this.parent;

      // Check if a type exist
      if (!integrationType) {
        return true;
      }

      const {isMicrosoftTeams} = useIntegrationDetailType(integrationType);

      if (isMicrosoftTeams) {
        return !isEmpty(clientSecret);
      }

      return true;
    }),

  [FormNameEnum.authorityUrl]: Yup.string()
    .trim()
    .url('Authority url should be a valid url!')
    .nullable()
    .test('is-authority-url-exist', errorMessages.requiredWithInputName('a Authority url'), function () {
      const {integrationType, authorityUrl} = this.parent;

      // Check if a type exist
      if (!integrationType) {
        return true;
      }

      const {isMicrosoftTeams} = useIntegrationDetailType(integrationType);

      if (isMicrosoftTeams) {
        return !isEmpty(authorityUrl);
      }

      return true;
    }),

  // Google Sites Source

  [FormNameEnum.googleDriveSourceFolderLink]: Yup.string()
    .nullable()
    .url('Google Drive Source folder link should be a valid url!')
    .test(
      'is-google-drive-source-url-exist',
      errorMessages.requiredWithInputName('a Google Drive Source url'),
      function () {
        const {integrationType, googleDriveSourceFolderLink} = this.parent;

        // Check if a type exist
        if (!integrationType) {
          return true;
        }

        const {isGoogleSitesSource} = useIntegrationDetailType(integrationType);

        if (isGoogleSitesSource) {
          return !isEmpty(googleDriveSourceFolderLink);
        }

        return true;
      }
    )
    .test('has-google-drive-url', 'You should enter a valid google drive link!', (value) =>
      value ? value.includes(GOOGLE_DRIVE_URL) : true
    ),

  // Google Sites Destination

  [FormNameEnum.googleDriveDestinationFolderLink]: Yup.string()
    .nullable()
    .url('Google Drive Destination folder link should be a valid url!')
    .test(
      'is-google-drive-destination-url-exist',
      errorMessages.requiredWithInputName('a Google Drive Destination url'),
      function () {
        const {integrationType, googleDriveDestinationFolderLink} = this.parent;

        // Check if a type exist
        if (!integrationType) {
          return true;
        }

        const {isGoogleSitesDestination} = useIntegrationDetailType(integrationType);

        if (isGoogleSitesDestination) {
          return !isEmpty(googleDriveDestinationFolderLink);
        }

        return true;
      }
    )
    .test('has-google-drive-url', 'You should enter a valid google drive link!', (value) =>
      value ? value.includes(GOOGLE_DRIVE_URL) : true
    ),
});

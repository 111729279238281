import React, {useCallback, useMemo} from 'react';
import {FormikHelpers} from 'formik';
import {batch} from 'react-redux';
import {Form} from 'forms';
import {PageLayoutTwoVerticalCols} from 'layout/components';
import {IUploadScormFormBaseValues, mapUploadResourceScorm, uploadScormFormBaseValidationSchema} from 'admin/resources';
import {ScormContentHeader, ScormContentFooter, ScormContentMiddle, ScormContentStart} from './components';
import {FormNameEnum} from '../enums';
import {useUploadScorm} from './';
import {usePermissions} from 'permissions';
import {SuperAndNationalAdminroles} from 'enums/admin-roles';
import {useLanguages} from '../hooks';

export const UploadResourceScorm = () => {
  const {loading, uploadScormPackage} = useUploadScorm();
  const {hasPermission} = usePermissions();
  const {englishId} = useLanguages();
  const hasAdminPermission = hasPermission(SuperAndNationalAdminroles);

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.file]: null,
      [FormNameEnum.displayName]: null,
      [FormNameEnum.gradeLevels]: [],
      [FormNameEnum.programs]: [],
      [FormNameEnum.pillars]: [],
      [FormNameEnum.knowledgeSkillsAndAbilities]: [],
      [FormNameEnum.time]: null,
      [FormNameEnum.keyTerms]: null,
      [FormNameEnum.isAccessibleToStudents]: null,
      [FormNameEnum.briefDescription]: '',
      [FormNameEnum.competencies]: [],
      [FormNameEnum.languages]: englishId ? [englishId] : [],
      [FormNameEnum.expirationDate]: null,
      [FormNameEnum.learningObjectives]: null,
      [FormNameEnum.hasUploadPermissions]: hasAdminPermission ? true : false,
    }),
    [hasAdminPermission, englishId]
  );

  const submitHandler = useCallback(
    (formValues: IUploadScormFormBaseValues, {resetForm, setSubmitting}: FormikHelpers<any>) => {
      const payload = mapUploadResourceScorm(formValues);

      return uploadScormPackage(payload).then(() =>
        batch(() => {
          resetForm({
            values: initialValues,
          });
          setSubmitting(false);
        })
      );
    },
    [uploadScormPackage, initialValues]
  );

  return (
    <Form.Form<IUploadScormFormBaseValues>
      initialValues={initialValues}
      validationSchema={uploadScormFormBaseValidationSchema}
      submitHandler={submitHandler}
    >
      <PageLayoutTwoVerticalCols
        header={<ScormContentHeader />}
        contentStart={<ScormContentStart />}
        contentMiddle={<ScormContentMiddle />}
        footer={<ScormContentFooter />}
        withDoubleFirst={false}
        withDoubleSecond
        withFooter
        withSecondaryNavigation
        loading={loading}
      />
    </Form.Form>
  );
};

import {makeStyles} from '@material-ui/core';
import React, {useEffect, useRef} from 'react';
import {PaperNodeTypeEnum} from '../../enums/PaperNodeTypeEnum';
import {IPaperQuestion} from '../../models/IPaperQuestion';

interface IProps {
  text: string;
  afterMount?: (questionInfo: IPaperQuestion) => void;
}

const SurveyHeadline = ({text, afterMount}: IProps) => {
  const classes = useStyles();
  const ref = useRef(null);

  useEffect(() => {
    if (!afterMount) return;
    afterMount({
      type: PaperNodeTypeEnum.headline,
      heights: {
        total: ref?.current.clientHeight,
        headerArea: 0,
        textArea: 0,
        questionAreas: [0],
      },
      id: 'NA',
      text: text,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.surveyHeadline} ref={ref}>
      {text}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  surveyHeadline: {
    width: '100%',
    textAlign: 'center',
    fontSize: '3em',
    fontWeight: 'bold',
    pageBreakInside: 'avoid',
  },
}));

export default SurveyHeadline;

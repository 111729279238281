import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Wrapper} from 'components-lib';
import {ActiveHeader} from '../../../components/ActiveHeader';

interface IHeaderSubHeadingProps {
  isFirstStep: boolean;
  isSecondStep: boolean;
  isThirdStep: boolean;
}

export const HeaderSubHeading: FC<IHeaderSubHeadingProps> = ({isFirstStep, isSecondStep, isThirdStep}) => {
  const classes = useStyles();
  return (
    <Wrapper className={classes.headerSubHeading}>
      {'\u00a0 /'}
      <ActiveHeader isActive={isFirstStep}>{'\u00a0 Step 1: Upload file \u00a0'}</ActiveHeader>
      {'/'}
      <ActiveHeader isActive={isSecondStep}>{'\u00a0 Step 2: Mapping \u00a0'}</ActiveHeader>
      {'/'}
      <ActiveHeader isActive={isThirdStep}>{'\u00a0 Step 3: Preview and Import \u00a0'}</ActiveHeader>
    </Wrapper>
  );
};

const useStyles = makeStyles(() => ({
  headerSubHeading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

import React, {FC, useCallback, useMemo, useState} from 'react';
import {IconButton, makeStyles, Menu} from '@material-ui/core';
import {IVerticalDropdownItem} from 'admin/resources/files';
import {Icon, Wrapper} from 'components-lib';
import {DropdownItem} from './DropdownItem';
import {IBaseFileItem} from 'models/resources/files';
import {IResourceContentTypeNameEnum} from 'enums/upload';
interface IVerticalDropdownProps {
  className?: string;
  file: IBaseFileItem;
  dropdownItems: IVerticalDropdownItem[];
  shouldDisable: boolean;
}

export const VerticalDropdown: FC<IVerticalDropdownProps> = ({className, dropdownItems, shouldDisable, file}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  let menuList = [...dropdownItems];
  const isWebLink = file.contentType === IResourceContentTypeNameEnum.WebLinkResource;

  if (isWebLink) {
    menuList = menuList.splice(0, 2);
  }

  const openMenuClickHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget),
    []
  );

  const closeMenuClickHandler = useCallback(() => setAnchorEl(null), []);

  const MenuIcon = useMemo(
    () => () => (
      <IconButton disabled={shouldDisable} className={classes.button} onClick={openMenuClickHandler}>
        <Icon.MoreIcon disabled={shouldDisable} />
      </IconButton>
    ),
    [classes, shouldDisable, openMenuClickHandler]
  );

  return (
    <Wrapper className={className}>
      <MenuIcon />
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenuClickHandler}>
        {menuList?.map((item: IVerticalDropdownItem, idx: number) => {
          return <DropdownItem key={idx} item={item} file={file} handleDropdownClose={closeMenuClickHandler} />;
        })}
      </Menu>
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    cursor: 'pointer',
    padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
  },
}));

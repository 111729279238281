import {
  IAddIntegrationFormBaseValues,
  IAddIntegrationPayload,
  ICreateMicrosoftTeamsVirtualClassPayload,
} from 'models/integrations';

// Optional multipart form data for Google
const prepareOptionalMultiPartFormDataForGoogle = (
  data: FormData,
  area: number,
  schoolId: number,
  schoolDistrictId: number
) => {
  if (!!area) {
    data.append('areaId', area.toString()); // optional
  }

  if (!!schoolId) {
    data.append('schoolId', schoolId.toString()); // optional
  }

  if (!!schoolDistrictId) {
    data.append('districtId', schoolDistrictId.toString()); // optional
  }
};

// Google Classroom
const prepareMultiPartFormDataForGoogleClassRoom = ({
  certificate,
  integrationName,
  area,
  schoolName,
  schoolDistrict,
  email,
  masterClassroomEmailAccount,
}): FormData => {
  const data = new FormData();

  prepareOptionalMultiPartFormDataForGoogle(data, area, schoolName, schoolDistrict);

  data.append('certificate', certificate);
  data.append('name', integrationName);
  data.append('serviceEmailAccount', email);
  data.append('masterClassroomEmailAccount', masterClassroomEmailAccount);

  return data;
};

const getGoogleClassRoomPayloadDetails = (formValues) => {
  const payload = prepareMultiPartFormDataForGoogleClassRoom(formValues);
  return payload;
};

// Google Sites Source
const prepareMultiPartFormDataForGoogleSitesSource = ({
  certificate,
  integrationName,
  area,
  schoolName,
  schoolDistrict,
  email,
  googleDriveSourceFolderLink,
}): FormData => {
  const data = new FormData();

  prepareOptionalMultiPartFormDataForGoogle(data, area, schoolName, schoolDistrict);

  data.append('certificate', certificate);
  data.append('name', integrationName);
  data.append('serviceEmailAccount', email);
  data.append('defaultFolderLink', googleDriveSourceFolderLink);

  return data;
};

const getGoogleSitesSourcePayloadDetails = (formValues) => {
  const payload = prepareMultiPartFormDataForGoogleSitesSource(formValues);
  return payload;
};

// Google Sites Destination
const prepareMultiPartFormDataForGoogleSitesDestination = ({
  integrationName,
  area,
  schoolName,
  schoolDistrict,
  email,
  googleDriveDestinationFolderLink,
}): FormData => {
  const data = new FormData();

  prepareOptionalMultiPartFormDataForGoogle(data, area, schoolName, schoolDistrict);

  data.append('name', integrationName);
  data.append('serviceEmailAccount', email);
  data.append('defaultFolderLink', googleDriveDestinationFolderLink);

  return data;
};

// Microsoft Teams
const prepareJsonDataForMicrosoftTeams = ({
  integrationName,
  area,
  schoolName,
  schoolDistrict,
  clientId,
  clientSecret,
  authorityUrl,
}) => {
  let microsoftTeamsPayload = {
    name: integrationName,
    clientId,
    clientSecret,
    authorityUrl,
  } as ICreateMicrosoftTeamsVirtualClassPayload;

  if (!!area) {
    microsoftTeamsPayload = {...microsoftTeamsPayload, areaId: area};
  }

  if (!!schoolName) {
    microsoftTeamsPayload = {...microsoftTeamsPayload, schoolId: schoolName};
  }

  if (!!schoolDistrict) {
    microsoftTeamsPayload = {...microsoftTeamsPayload, districtId: schoolDistrict};
  }

  return microsoftTeamsPayload;
};

const getGoogleSitesDestinationPayloadDetails = (formValues) => {
  const payload = prepareMultiPartFormDataForGoogleSitesDestination(formValues);
  return payload;
};

// Microsoft Teams
const getMicrosoftTeamsPayloadDetails = (formValues): ICreateMicrosoftTeamsVirtualClassPayload => {
  const payload = prepareJsonDataForMicrosoftTeams(formValues);
  return payload;
};

// Add Integration Payload
const getAddIntegrationPayload = (
  formValues: IAddIntegrationFormBaseValues,
  isGoogleClassRoom: boolean,
  isMicrosoftTeams: boolean,
  isGoogleSitesSource: boolean,
  isGoogleSitesDestination: boolean
): IAddIntegrationPayload => {
  if (isGoogleClassRoom) {
    return getGoogleClassRoomPayloadDetails(formValues);
  }

  if (isGoogleSitesSource) {
    return getGoogleSitesSourcePayloadDetails(formValues);
  }

  if (isGoogleSitesDestination) {
    return getGoogleSitesDestinationPayloadDetails(formValues);
  }

  if (isMicrosoftTeams) {
    return getMicrosoftTeamsPayloadDetails(formValues);
  }
};

export const mapAddIntegrationDataForPayload = (
  formValues: IAddIntegrationFormBaseValues,
  isGoogleClassRoom: boolean,
  isMicrosoftTeams: boolean,
  isGoogleSitesSource: boolean,
  isGoogleSitesDestination: boolean
) => {
  const payload = getAddIntegrationPayload(
    formValues,
    isGoogleClassRoom,
    isMicrosoftTeams,
    isGoogleSitesSource,
    isGoogleSitesDestination
  );
  return payload;
};

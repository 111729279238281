import React, {FC, useCallback} from 'react';
import {batch} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {Button, Row} from 'components-lib';
import {useFormikContext} from 'formik';
import {IAddSessionResourcesFormValues} from '../AddSessionResourcesForm/config';
import {IAddSessionInstrumentsFormValues} from '../AddSessionInstrumentsForm/config';

interface IAddResourcesActionButtonsProps {
  confirmButtonText?: string;
  closeClickHandler: () => void;
  submitClickHandler: () => void;
  isResource?: boolean;
  disableSubmit?: boolean;
}

export const AddResourcesActionButtons: FC<IAddResourcesActionButtonsProps> = ({
  confirmButtonText = 'Add Resources',
  closeClickHandler,
  submitClickHandler,
  isResource = true,
  disableSubmit,
}) => {
  const classes = useStyles();
  const addResourcesFormContext = useFormikContext<IAddSessionResourcesFormValues>();
  const addInstrumentsFormContext = useFormikContext<IAddSessionInstrumentsFormValues>();

  const closeDrawerClickHandler = useCallback(() => {
    batch(() => {
      if (isResource) {
        addResourcesFormContext.resetForm();
      } else {
        addInstrumentsFormContext.resetForm();
      }
      closeClickHandler();
    });
  }, [isResource, addResourcesFormContext, addInstrumentsFormContext, closeClickHandler]);

  return (
    <Row justify="flex-end" classes={{root: classes.actionButtons}}>
      <Button.Secondary disabled={false} size="large" clickHandler={closeDrawerClickHandler}>
        Cancel
      </Button.Secondary>
      <Button.Primary size="large" clickHandler={submitClickHandler} disabled={disableSubmit}>
        {confirmButtonText}
      </Button.Primary>
    </Row>
  );
};

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    position: 'absolute',
    bottom: 0,
    padding: theme.spacing(2, 3),
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
}));

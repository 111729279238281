import React, {FC} from 'react';
import {CircularProgress as MUICircularProgress} from '@material-ui/core';

interface ICircularProgressProps {
  classes?: {[key: string]: any};
  size?: number | string;
}

export const CircularProgress: FC<ICircularProgressProps> = ({classes, size = 24}) => {
  return <MUICircularProgress size={size} classes={classes} color="primary" />;
};

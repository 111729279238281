import {IOption} from 'models';
import {
  MAX_ALLOWED_SIZE,
  ARCHIVE_FORMATS,
  DIGITAL,
  NOT_ALLOWED_VIDEO_FORMATS,
  ALLOWED_AUDIO_FORMATS,
  RESOURCE_NUMBER_TYPE,
  ALLOWED_VIDEO_FORMATS,
  ALLOWED_DOC_FORMATS,
  ALLOWED_IMG_FORMATS,
  ALLOWED_PAPER_ASSESSMENTS_FORMAT,
} from 'utils';
import {IUploadFile} from '../models';
import {UploadFileStatusEnum} from '../enums';
import {AxiosError} from 'axios';
import {FormNameEnum} from 'filters-selections';

export const validateFormat = (fileFormat: string, resourceType: any) => {
  const isArchiveFormat = ARCHIVE_FORMATS.includes(fileFormat);
  const isZipFormat = ALLOWED_PAPER_ASSESSMENTS_FORMAT.includes(fileFormat);
  const isDigitalResourceType = resourceType.toLowerCase().includes(DIGITAL);
  const isAllowedVideoFormat = !NOT_ALLOWED_VIDEO_FORMATS.includes(fileFormat);
  const isDigitalResource = isDigitalResourceType && isAllowedVideoFormat && !isArchiveFormat;
  const isPaperAssessment = isZipFormat && !isDigitalResourceType;
  const canBeUploaded = isDigitalResource || isPaperAssessment;

  return canBeUploaded ? canBeUploaded : false;
};

export const convertBytesToMegaBytes = (bytes: number, digits: number) => {
  const calculation = Number((bytes / (1024 * 1024)).toFixed(digits));
  return calculation;
};

export const validateFileSize = (bytes: number, digits: number) => {
  const fileSizeInMB = convertBytesToMegaBytes(bytes, digits);

  return fileSizeInMB < MAX_ALLOWED_SIZE ? true : false;
};

export const convertToSelectOptionData = (data: any, value: string, label: string) => {
  interface IOption {
    label: string;
    value: number;
  }

  return data.map((p: IOption) => {
    return {
      value: p[value],
      label: p[label],
    };
  });
};

export const extractDataBeforeSeparator = (raw: string, separator: string) => raw.substr(0, raw.lastIndexOf(separator));

export const convertToDropDownData = (data) =>
  data?.map((e: {id: number; name: string}) => {
    return {
      value: e.id,
      label: e.name,
    };
  });

export const convertStringListToDropDownData = (data: string[]) =>
  data?.map((e: string) => {
    return {
      value: e,
      label: e,
    };
  });

export const filterDataById = (data: IOption[], id: number, filterId: string) =>
  data.filter((el) => el[filterId] === id);

export const filterDropdownData = (data: IOption[], id: number, filterId: string) => {
  const filteredData = filterDataById(data, id, filterId);
  return convertToSelectOptionData(filteredData, 'id', 'name');
};

export const updateFileUploadPercent = (files: IUploadFile[], updatedFileUpload: IUploadFile) => {
  const existingFile = files.find((f: IUploadFile) => f.id === updatedFileUpload?.id);
  if (!existingFile) {
    return [...files, updatedFileUpload];
  }

  if (existingFile.percentage <= 100) {
    const updatedFiles = files.map((f) =>
      f.id === existingFile.id ? {...existingFile, percentage: updatedFileUpload.percentage} : f
    );
    return updatedFiles;
  }
};

export const updateUploadFileStatus = (files: IUploadFile[], {name, status}) => {
  const existingFile = files.length
    ? files.find((f: IUploadFile) => f?.name?.toLowerCase() === name?.toLowerCase())
    : null;

  if (existingFile) {
    const updatedFiles = files.map((f) => (f.name === name ? {...existingFile, status} : f));
    return updatedFiles;
  }
  return;
};

export const getUploadingFileNumber = (files, status: string) => {
  return files ? files.reduce((acc, f) => acc + (f.status === status), 0) : 0;
};

export const markCancelFileUpload = (files: IUploadFile[], id: string) => {
  const existingFile = files.find((f: IUploadFile) => f?.id === id);

  if (existingFile) {
    const updatedFiles = files.map((f) =>
      f.id === id ? {...existingFile, isCanceledUpload: true, status: UploadFileStatusEnum.Failed} : f
    );
    return updatedFiles;
  }
  return;
};

export const addFileToUpload = (files: IUploadFile[], fileToUpload: IUploadFile) => {
  const existingFile = files.length ? files.find((f: IUploadFile) => f?.id === fileToUpload?.id) : null;
  if (!existingFile) {
    return [...files, fileToUpload];
  }

  return;
};

export const getUploadErrorMessage = (err: AxiosError, filename: string, errorMessage: string) => {
  if (err.response.data) {
    return err.response.data.error;
  } else {
    return `File: ${filename} ${errorMessage}`;
  }
};

export const mapInitialUploadFormValues = () => {
  return {
    [FormNameEnum.uploadResourceType]: null,
    [FormNameEnum.fileUpload]: null,
    [FormNameEnum.programs]: [],
    [FormNameEnum.sessions]: [],
    [FormNameEnum.pillars]: [],
    [FormNameEnum.competencies]: [],
    [FormNameEnum.gradeLevels]: [],
    [FormNameEnum.skillsAndBehaviors]: [],
    [FormNameEnum.languages]: [],
    [FormNameEnum.drmExpirationDate]: null,
    [FormNameEnum.keyTerms]: [],
    [FormNameEnum.learningObjectives]: [],
    [FormNameEnum.concepts]: [],
  };
};

export const mapDigitalResourceType = (fileFormat: string) => {
  const isDocument = ALLOWED_DOC_FORMATS.includes(fileFormat.toLowerCase());
  const isVideo = ALLOWED_VIDEO_FORMATS.includes(fileFormat.toLowerCase());
  const isAudio = ALLOWED_AUDIO_FORMATS.includes(fileFormat.toLowerCase());
  const isImage = ALLOWED_IMG_FORMATS.includes(fileFormat.toLowerCase());
  const isPaperAssessment = ALLOWED_PAPER_ASSESSMENTS_FORMAT.includes(fileFormat.toLowerCase());

  if (isVideo) {
    return RESOURCE_NUMBER_TYPE.video;
  }

  if (isAudio) {
    return RESOURCE_NUMBER_TYPE.audio;
  }

  if (isDocument) {
    return RESOURCE_NUMBER_TYPE.document;
  }

  if (isImage) {
    return RESOURCE_NUMBER_TYPE.image;
  }

  if (isPaperAssessment) {
    return RESOURCE_NUMBER_TYPE.paperAssessment;
  }
};

export const extractFileTypeFromString = (raw: string) => raw?.substr(raw.lastIndexOf('.'), raw.length - 1);
export const calculateLengthText = (text: string) => (text.length > 10 ? true : false);

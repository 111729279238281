import moment from 'moment';
import {getDateFromISOString} from './getDateFromISOString';

export const getIsBeforeDate = (beforeDate: Date, afterDate?: Date) => {
  let today = new Date();

  if (afterDate) {
    today = afterDate;
  }

  const beforeISOString = getDateFromISOString(beforeDate);
  const todayISOString = getDateFromISOString(today);

  return moment(beforeISOString).isBefore(moment(todayISOString));
};

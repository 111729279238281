import React, {useEffect} from 'react';
import {FormNameEnum} from '../../../enums';
import {LearningObjectiveFilter} from './LearningObjectiveFilter';
import {useIsDomainKnowledgeSelected} from '../../../hooks/useIsDomainKnowledgeSelected';
import {useFormikContext} from 'formik';

export const LearningObjectiveWithDomainDependencyFilter = () => {
  const {values, setFieldValue} = useFormikContext();
  const {isDomainKnowledgeSelected} = useIsDomainKnowledgeSelected();
  const learningObjectiveValue = values[FormNameEnum.learningObjective];

  useEffect(() => {
    if (!isDomainKnowledgeSelected) {
      setFieldValue(FormNameEnum.learningObjective, '');
    }
  }, [learningObjectiveValue, isDomainKnowledgeSelected, setFieldValue]);

  return <LearningObjectiveFilter value={learningObjectiveValue} disabled={!isDomainKnowledgeSelected} />;
};

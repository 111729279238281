import * as Yup from 'yup';
import {FormNameEnum} from 'enums/form';
import {errorMessages} from 'utils/constants';
import {LearningPathLabelEnum} from 'admin/resources/learning-paths';
import {isEqual} from 'utils/functions';
import {getIsBeforeDate} from 'utils/common';

export interface ILearningPathDetailsFormBaseValues {
  [FormNameEnum.id]?: string;
  [FormNameEnum.program]: number;
  [FormNameEnum.futureProgramYear]: number;
  [FormNameEnum.learningPathName]: string;
  [FormNameEnum.keyTerms]: string;
  [FormNameEnum.primaryPillar]: number;
  [FormNameEnum.secondaryPillar]: number;
  [FormNameEnum.gradeLevels]: number[];
  [FormNameEnum.language]: number;
  [FormNameEnum.briefDescription]: string;
  [FormNameEnum.learningObjectives]: string;
  [FormNameEnum.expirationDate]: Date | string;
}

export const ILearningPathValidationsShape = {
  [FormNameEnum.learningPathName]: Yup.string()
    .nullable()
    .required(errorMessages.requiredWithInputName(`a ${LearningPathLabelEnum.Name}`)),
  [FormNameEnum.program]: Yup.number().nullable(),
  [FormNameEnum.futureProgramYear]: Yup.number().nullable(),
  [FormNameEnum.primaryPillar]: Yup.number()
    .nullable()
    .test('has-distinct-secondary', 'Please select distinct primary and secondary pillars', function () {
      return checkIfDistinctPillars(this.parent.primaryPillar, this.parent.secondaryPillar);
    }),
  [FormNameEnum.secondaryPillar]: Yup.number()
    .nullable()
    .test('has-distinct-primary', 'Please select distinct primary and secondary pillars', function () {
      return checkIfDistinctPillars(this.parent.primaryPillar, this.parent.secondaryPillar);
    }),
  [FormNameEnum.gradeLevels]: Yup.array()
    .of(Yup.number())
    .required(errorMessages.requiredWithFieldName(`a ${LearningPathLabelEnum.GradeLevel}`)),
  [FormNameEnum.language]: Yup.number()
    .nullable()
    .required(errorMessages.requiredWithFieldName(`a ${LearningPathLabelEnum.Language}`)),
  [FormNameEnum.expirationDate]: Yup.date()
    .nullable()
    .typeError('Expected date format: (mm/dd/yyyy)')
    .test('is-date-earlier-than-now', 'The selected date should be in the future.', function () {
      const {expirationDate} = this.parent;

      // If the datepicker is cleaned up, then validation is true
      if (expirationDate === null) {
        return true;
      }

      function isBefore(expirationDate: Date): boolean {
        const dateIsBeforeToday = getIsBeforeDate(expirationDate);

        if (dateIsBeforeToday) {
          return false;
        }
        return true;
      }

      if (expirationDate instanceof Date) {
        return isBefore(expirationDate);
      }
    }),
  [FormNameEnum.briefDescription]: Yup.string().nullable(),
  [FormNameEnum.keyTerms]: Yup.string().nullable(),
  [FormNameEnum.learningObjectives]: Yup.string().nullable(),
};

function checkIfDistinctPillars(primaryPillar, secondaryPillar) {
  const hasPrimaryPillar = !!primaryPillar;
  const hasSecondaryPillar = !!secondaryPillar;

  if (!hasPrimaryPillar && !hasSecondaryPillar) {
    return true;
  }

  const isPillarsEqual = isEqual(primaryPillar, secondaryPillar);
  return isPillarsEqual ? false : true;
}

import {createAsyncThunk} from '@reduxjs/toolkit';
import {toastNotificationManager} from 'toast-notifications';
import {programsApi} from 'api';
import {prefix} from './config';
import {IGetPageOfPaperAssessmentsPayload, IGetPageOfPaperAssessmentsResponse} from 'models';

export const fetchClassPaperAssessmentsPagedThunk: any = createAsyncThunk<
  IGetPageOfPaperAssessmentsResponse,
  IGetPageOfPaperAssessmentsPayload
>(
  `${prefix} fetchClassPaperAssessmentsPaged`,
  async (payload: IGetPageOfPaperAssessmentsPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await programsApi.getClassPaperAssessmentsPaged(payload);
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

import React, {Fragment, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {
  FileFieldTableEnum,
  IMappingTableColumn,
  IMappingTableTableRow,
  importItemsMessages,
  ISystemFieldOptionEnum,
  MappingTableLabelEnum,
  useItemsImport,
} from 'admin/items-import';
import {Table as TableLib, Wrapper} from 'components-lib';
import {useBeforeunload} from 'react-beforeunload';
import {Prompt} from 'react-router-dom';

export const MappingTable = () => {
  const classes = useStyles();
  const {fieldList} = useItemsImport();
  const emptyList = useMemo(() => [], []);

  useBeforeunload(() => importItemsMessages.warning);

  const columns: IMappingTableColumn[] = useMemo(
    () => [
      {
        id: FileFieldTableEnum.SystemFieldName,
        label: MappingTableLabelEnum.SystemFieldName,
        align: 'left',
        className: classes.systemFieldNameCell,
      },
      {
        id: FileFieldTableEnum.Option,
        label: MappingTableLabelEnum.RequiredField,
        align: 'left',
        className: classes.requiredFieldCell,
      },
      {
        id: FileFieldTableEnum.FileFieldName,
        label: MappingTableLabelEnum.FileFieldName,
        align: 'left',
        className: classes.fileFieldNameCell,
        hasError: true,
      },
    ],
    [classes]
  );

  const rows = useMemo(() => {
    if (!fieldList.length) {
      return emptyList;
    }

    return fieldList.map((field) => {
      const {systemFieldName, isRequired, fileFieldName, validationError} = field;

      const option = isRequired ? ISystemFieldOptionEnum.Required : ISystemFieldOptionEnum.Optional;
      const systemFieldNameModified = isRequired ? `${systemFieldName}*` : systemFieldName;
      return {
        systemFieldName: systemFieldNameModified,
        option,
        fileFieldName,
        error: validationError ? fileFieldName : '',
      };
    });
  }, [fieldList, emptyList]) as IMappingTableTableRow[];

  const hasRows = !!rows.length;

  const Table = useMemo(
    () => () =>
      hasRows ? <TableLib rows={rows} columns={columns} withTableHead /> : <Fragment>No results found</Fragment>,
    [hasRows, rows, columns]
  );

  return (
    <Wrapper className={classes.tableContainer}>
      <Prompt when={true} message={importItemsMessages.itemsImportWarning} />
      <Table />
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    paddingRight: theme.spacing(3),
  },
  systemFieldNameCell: {
    width: '40%',
  },
  requiredFieldCell: {
    width: '10%',
  },
  fileFieldNameCell: {
    width: '50%',
  },
  textError: {
    color: theme.palette.error.main,
  },
}));

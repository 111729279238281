import React, {Fragment, useCallback, useEffect, useMemo} from 'react';
import {batch} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {Subtitle} from 'admin/components';
import {Button, Container, Loading, CircularProgress, Row} from 'components-lib';
import {
  getGoogleSiteTableColumns,
  IGoogleSitesAreasFormValues,
  IGoogleSitesAreasTableColumn,
} from 'admin/resources/google-sites';
import {GoogleSiteAreasTable} from '..';
import {
  useGoogleSiteHistory,
  useGoogleSiteIdParam,
  useGoogleSiteStoreState,
} from 'admin/resources/google-sites/details/hooks';
import {getDatetimeAMPMinUTC} from 'utils/common';
import {IGoogleSiteCopyPayload} from 'models/resources/google-sites';
import {useFormikContext} from 'formik';

export const GoogleSitesAreas = () => {
  const classes = useStyles();
  const {googleSiteId} = useGoogleSiteIdParam();
  const {
    fetchGoogleSitesSourceHistory,
    fetchGoogleSitesCopySite,
    loadingHistory,
    copyingGoogleSite,
    copyingHistoryItemIntegrationId,
  } = useGoogleSiteHistory();
  const {clearHistoryState, setCopyIntegrationIdState} = useGoogleSiteStoreState();
  const {
    values: {historyFilteredList},
  } = useFormikContext<IGoogleSitesAreasFormValues>();

  const columns: IGoogleSitesAreasTableColumn[] = getGoogleSiteTableColumns();

  useEffect(() => {
    if (googleSiteId) {
      fetchGoogleSitesSourceHistory(googleSiteId);
    }

    return () => {
      clearHistoryState();
    };
  }, [googleSiteId, fetchGoogleSitesSourceHistory, clearHistoryState]);

  const copyGoogleSiteClickHandler = useCallback(
    (payload: IGoogleSiteCopyPayload) =>
      batch(() => {
        setCopyIntegrationIdState(payload.integrationId);
        fetchGoogleSitesCopySite(payload);
      }),
    [fetchGoogleSitesCopySite, setCopyIntegrationIdState]
  );

  const LoadingMemoized = useMemo(() => () => <Loading halfHeight />, []);

  const rows = useMemo(() => {
    if (!historyFilteredList.length) {
      return [];
    }

    return historyFilteredList.map(({integrationId, dateLastSent, integrationName, status, areaName}) => {
      const datetimeAMPMinUTC = dateLastSent ? getDatetimeAMPMinUTC(dateLastSent) : '';
      const copyPayload = {integrationId, siteFieldId: googleSiteId} as IGoogleSiteCopyPayload;
      const shouldDisableCopyButton = copyingHistoryItemIntegrationId === integrationId;

      return {
        integrationName,
        area: (
          <Container disableGutters>
            <Row align="center" justify="flex-start">
              {/* <GoogleSiteAreasCheckbox /> */}
              {areaName ?? ''}
            </Row>
          </Container>
        ),
        status,
        lastCopiedDate: datetimeAMPMinUTC,
        copyId: integrationId,
        copy: (
          <Row justify="center">
            {shouldDisableCopyButton ? (
              <CircularProgress classes={{root: classes.circularProgress}} />
            ) : (
              <Button.Secondary
                size="small"
                disabled={shouldDisableCopyButton || copyingGoogleSite}
                clickHandler={() => copyGoogleSiteClickHandler(copyPayload)}
              >
                Copy
              </Button.Secondary>
            )}
          </Row>
        ),
      };
    });
  }, [
    classes.circularProgress,
    copyGoogleSiteClickHandler,
    googleSiteId,
    copyingHistoryItemIntegrationId,
    copyingGoogleSite,
    historyFilteredList,
  ]);

  return (
    <Fragment>
      <Subtitle withColor={false} as="h3" color="textPrimary" title="Areas" />
      <Container disableGutters classes={{root: classes.root}}>
        {loadingHistory ? <LoadingMemoized /> : <GoogleSiteAreasTable rows={rows} columns={columns} />}
      </Container>
    </Fragment>
  );
};

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  circularProgress: {
    margin: `${theme.spacing(1) + theme.spacing(2)}px ${theme.spacing(0)}px`,
    marginRight: `${theme.spacing(1) + theme.spacing(2)}px`,
  },
}));

import React, {FC, Fragment} from 'react';
import {ReactComponent as ViewResourceSvg} from 'resources/icons/view.svg';
import {ReactComponent as ViewResourceDisabledSvg} from 'resources/icons/view_disabled.svg';
import {IWithDisabled} from 'models';

type IViewResourceIconProps = IWithDisabled;

export const ViewResourceIcon: FC<IViewResourceIconProps> = ({disabled = false}) => {
  return <Fragment>{disabled ? <ViewResourceDisabledSvg /> : <ViewResourceSvg />}</Fragment>;
};

import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Wrapper} from 'components-lib';
import {IntegrationInput} from 'admin/integrations/components';
import {IPreviewListOption} from 'models/integrations';
import {RemoveRedEye} from '@material-ui/icons';
interface IPreviewListProps {
  list: IPreviewListOption[];
}

export const PreviewList: FC<IPreviewListProps> = ({list}) => {
  const classes = useStyles();

  return (
    <Wrapper className={classes.input}>
      {list.map(({label, name, value, options: {withForm, withIcon, withMargin, disabled}, type}, id) => (
        <IntegrationInput
          key={id}
          label={label}
          value={value}
          withForm={withForm}
          withMargin={withMargin}
          withIcon={withIcon}
          disabled={disabled}
          name={name}
          type={type}
          icon={withIcon && <RemoveRedEye />}
        />
      ))}
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

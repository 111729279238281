import * as Yup from 'yup';
import {FormNameEnum} from 'enums/form';
import {SUPPORTED_FILE_FORMATS} from '../constants';

export const itemsImportFormBaseValidationSchema = Yup.object().shape({
  [FormNameEnum.templateImportFile]: Yup.mixed()
    .nullable()
    .test('has-correct-format', `Only .xlsx format is supported`, (value) =>
      value ? SUPPORTED_FILE_FORMATS.includes(value.type) : true
    ),
});

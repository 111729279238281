import React, {Fragment, useEffect} from 'react';
import {makeStyles} from '@material-ui/core';
import {Subtitle} from 'admin/components';
import {Container, Loading} from 'components-lib';
import {GoogleSitesDetailsContent} from './components';
import {useGoogleSiteIdParam, useGoogleSitesDetails, useGoogleSiteStoreState} from '../../hooks';

export const GoogleSitesDetails = () => {
  const {googleSiteId} = useGoogleSiteIdParam();
  const {loading, fetchGoogleSiteDetails} = useGoogleSitesDetails();
  const {clearGoogleSiteState} = useGoogleSiteStoreState();

  useEffect(() => {
    if (googleSiteId) {
      fetchGoogleSiteDetails(googleSiteId);
    }

    return () => {
      clearGoogleSiteState();
    };
  }, [googleSiteId, fetchGoogleSiteDetails, clearGoogleSiteState]);

  const classes = useStyles();
  return (
    <Fragment>
      <Subtitle withColor={false} as="h3" color="textPrimary" title="Details" />
      <Container classes={{root: classes.detailsContent}}>
        {loading ? (
          <Container classes={{root: classes.loadingContainer}}>
            <Loading />
          </Container>
        ) : (
          <GoogleSitesDetailsContent />
        )}
      </Container>
    </Fragment>
  );
};

export const useStyles = makeStyles((theme) => ({
  detailsContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  loadingContainer: {
    paddingBottom: theme.spacing(4),
  },
}));

import {IComponentWithChildren} from 'models';
import React from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDragAndDropProviderProps extends IComponentWithChildren {}

/// TODO touch backend for touch devices
export function DragAndDropProvider({children}: IDragAndDropProviderProps) {
  return <DndProvider backend={HTML5Backend}>{children}</DndProvider>;
}

import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch, useNavigate} from 'hooks';
import {
  auditLogsLoadingSelector,
  auditLogsSelector,
  deleteIntegrationThunk,
  fetchAuditLogThunk,
  fetchIntegrationDetailsThunk,
  integrationDetailsSelector,
  integrationStatusesSelector,
  pageLoadingSelector,
  publishIntegrationThunk,
  serviceSourceEmailSelector,
} from '../store';
import {
  IIntegrationDetails,
  IIntegrationDetailsFormBaseValues,
  IUpdateIntegrationApiResponse,
} from 'models/integrations';
import {pages} from 'paths';
import {useIntegrations} from './useIntegrations';
import {useIntegrationStateActions} from '../details/hooks';
import {
  mapUpdateGoogleClassroomPayload,
  mapUpdateMicrosoftTeamsPayload,
  testGoogleClassroomThunk,
  testMicrosoftTeamsThunk,
  updateGoogleClassroomIntegrationThunk,
  updateMicrosoftTeamsIntegrationThunk,
  useIntegrationDetailType,
  mapGoogleSitesSourcePayload,
  updateGoogleSitesSourceIntegrationThunk,
  testGoogleSitesSourceThunk,
  updateGoogleSitesDestinationIntegrationThunk,
  mapGoogleSitesDestinationPayload,
  testGoogleSitesDestinationThunk,
} from 'admin';

import {unwrapResult} from '@reduxjs/toolkit';
import {IHttpResponse} from 'api/models';

export const useIntegration = () => {
  const dispatch = useAppDispatch();
  const {push} = useNavigate();
  const {fetchIntegrationStatuses} = useIntegrations();
  const {fetchServiceEmail, fetchPublishedSourceDetails} = useIntegrationStateActions();

  const integration = useSelector(integrationDetailsSelector);
  const loading = useSelector(pageLoadingSelector);
  const statuses = useSelector(integrationStatusesSelector);
  const serviceSourceEmail = useSelector(serviceSourceEmailSelector);
  const hasServiceSourceEmail = !!serviceSourceEmail;
  const auditLogs = useSelector(auditLogsSelector);
  const loadingAuditLogs = useSelector(auditLogsLoadingSelector);

  const {isGoogleClassRoom, isMicrosoftTeams, isGoogleSitesSource, isGoogleSitesDestination} = useIntegrationDetailType(
    integration.integrationType
  );

  const fetchIntegration = useCallback(
    (integrationId: string) => dispatch(fetchIntegrationDetailsThunk(integrationId)),
    [dispatch]
  );

  const fetchAuditLog = useCallback((integrationId: string) => dispatch(fetchAuditLogThunk(integrationId)), [dispatch]);

  const updateMicrosoftTeams = useCallback(
    (integrationId, payload) =>
      dispatch(updateMicrosoftTeamsIntegrationThunk({integrationId, payload}))
        .then(unwrapResult)
        .then((response: IHttpResponse<IUpdateIntegrationApiResponse>) => response.data)
        .then(
          ({configurationUpdated, details: {id}}: {configurationUpdated: boolean; details: IIntegrationDetails}) => {
            if (configurationUpdated) {
              return dispatch(testMicrosoftTeamsThunk(id)).then(() => fetchAuditLog(id));
            }

            return fetchAuditLog(id);
          }
        ),
    [dispatch, fetchAuditLog]
  );

  const updateGoogleClassroom = useCallback(
    (integrationId, payload) =>
      dispatch(updateGoogleClassroomIntegrationThunk({integrationId, payload}))
        .then(unwrapResult)
        .then((response: IHttpResponse<IUpdateIntegrationApiResponse>) => response.data)
        .then(
          ({configurationUpdated, details: {id}}: {configurationUpdated: boolean; details: IIntegrationDetails}) => {
            if (configurationUpdated) {
              return dispatch(testGoogleClassroomThunk(id)).then(() => fetchAuditLog(id));
            }

            return fetchAuditLog(id);
          }
        ),
    [dispatch, fetchAuditLog]
  );

  const updateGoogleSitesSource = useCallback(
    (integrationId, payload) =>
      dispatch(updateGoogleSitesSourceIntegrationThunk({integrationId, payload}))
        .then(unwrapResult)
        .then((response: IHttpResponse<IUpdateIntegrationApiResponse>) => response.data)
        .then(
          ({configurationUpdated, details: {id}}: {configurationUpdated: boolean; details: IIntegrationDetails}) => {
            if (configurationUpdated) {
              return dispatch(testGoogleSitesSourceThunk(id)).then(() => fetchAuditLog(id));
            }

            return fetchAuditLog(id);
          }
        ),
    [dispatch, fetchAuditLog]
  );

  const updateGoogleSitesDestination = useCallback(
    (integrationId, payload) =>
      dispatch(updateGoogleSitesDestinationIntegrationThunk({integrationId, payload}))
        .then(unwrapResult)
        .then((response: IHttpResponse<IUpdateIntegrationApiResponse>) => response.data)
        .then(
          ({configurationUpdated, details: {id}}: {configurationUpdated: boolean; details: IIntegrationDetails}) => {
            if (configurationUpdated) {
              return dispatch(testGoogleSitesDestinationThunk(id)).then(() => fetchAuditLog(id));
            }

            return fetchAuditLog(id);
          }
        ),
    [dispatch, fetchAuditLog]
  );

  const updateIntegration = useCallback(
    (integrationId: string, formikValues: IIntegrationDetailsFormBaseValues) => {
      if (isMicrosoftTeams) {
        const payload = mapUpdateMicrosoftTeamsPayload(formikValues);
        return updateMicrosoftTeams(integrationId, payload);
      }

      if (isGoogleClassRoom) {
        const payload = mapUpdateGoogleClassroomPayload(formikValues);
        return updateGoogleClassroom(integrationId, payload);
      }

      if (isGoogleSitesSource) {
        const payload = mapGoogleSitesSourcePayload(formikValues);
        return updateGoogleSitesSource(integrationId, payload);
      }

      if (isGoogleSitesDestination) {
        const payload = mapGoogleSitesDestinationPayload(formikValues);
        return updateGoogleSitesDestination(integrationId, payload);
      }
    },
    [
      isMicrosoftTeams,
      isGoogleClassRoom,
      isGoogleSitesSource,
      isGoogleSitesDestination,
      updateMicrosoftTeams,
      updateGoogleClassroom,
      updateGoogleSitesSource,
      updateGoogleSitesDestination,
    ]
  );

  const redirectToViewListOfIntegrations = useCallback(() => {
    push(`${pages.adminPortal.integrations.all}`);
  }, [push]);

  const deleteIntegration = useCallback(
    (integrationId: string) =>
      dispatch(deleteIntegrationThunk({integrationId})).then(() => redirectToViewListOfIntegrations()),
    [dispatch, redirectToViewListOfIntegrations]
  );

  const publishIntegration = useCallback(
    (integrationId: string) =>
      dispatch(publishIntegrationThunk({integrationId})).then(() => redirectToViewListOfIntegrations()),
    [dispatch, redirectToViewListOfIntegrations]
  );

  return {
    loading,
    integration,
    statuses,
    serviceSourceEmail,
    hasServiceSourceEmail,
    auditLogs,
    loadingAuditLogs,

    fetchServiceEmail,
    fetchPublishedSourceDetails,
    fetchIntegrationStatuses,
    redirectToViewListOfIntegrations,
    fetchIntegration,
    updateIntegration,
    fetchAuditLog,
    deleteIntegration,
    publishIntegration,
  };
};

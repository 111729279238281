import React, {ChangeEvent, useCallback} from 'react';
import {RadioGroup} from '@material-ui/core';
import {FieldProps} from 'formik';
import {IComponentWithChildren} from 'models';
import {toNumber} from 'utils';
import {Field} from './Field';

type TCustomValueChangeHandler = (id: number | string) => any;
interface IFormRadioGroupProps extends IComponentWithChildren {
  name: string;
  value?: string | number;
  customValueChangeHandler?: TCustomValueChangeHandler;
  label?: string;
  disabled?: boolean;
}

export function FormRadioGroup({
  name,
  value = null,
  label,
  customValueChangeHandler,
  disabled = false,
  children,
}: IFormRadioGroupProps) {
  return (
    <Field<TCustomValueChangeHandler>
      name={name}
      value={value}
      label={label}
      disabled={disabled}
      customValueChangeHandler={customValueChangeHandler}
      Component={RadioGroupDecorated}
    >
      {children}
    </Field>
  );
}

interface IInputDecoratedProps extends FieldProps, IFormRadioGroupProps {}

function RadioGroupDecorated({
  field: {name, value},
  form: {setFieldValue},
  customValueChangeHandler,
  ...rest
}: IInputDecoratedProps) {
  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>, selectedId: string) => {
      const valueNumber = toNumber(selectedId);
      const radioValue = isNaN(valueNumber) ? selectedId : valueNumber;

      const isValueAnArray = Array.isArray(value);
      const updatedValue = customValueChangeHandler && customValueChangeHandler(radioValue);
      const nextValue = customValueChangeHandler ? updatedValue.value : radioValue;

      function updateArrayValue(nextValue: any) {
        const {prop} = updatedValue;
        return value.filter((v) => v[prop] !== updatedValue.value[prop]).concat(nextValue);
      }

      const next = isValueAnArray ? updateArrayValue(nextValue) : nextValue;
      setFieldValue(name, next);
    },
    [customValueChangeHandler, name, setFieldValue, value]
  );

  return (
    <RadioGroup {...rest} name={name} value={rest.meta.value ?? ''} onChange={changeHandler}>
      {rest.children}
    </RadioGroup>
  );
}

import {pages} from 'paths';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {ItemsAllView} from './all';
import {ItemBuilderView} from './builder';
import {ItemDetailsView} from './details';

export function Items() {
  return (
    <Switch>
      <Route exact path={pages.adminPortal.items.root} component={ItemsAllView} />
      <Route path={pages.adminPortal.items.build} component={ItemBuilderView} />
      <Route path={pages.adminPortal.items.details} component={ItemDetailsView} />
    </Switch>
  );
}

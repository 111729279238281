import {useEffect} from 'react';
import {useProgramClassDetails} from './useProgramClassDetails';

export const useProgramClassDetailsActions = () => {
  const {fetchProgram, fetchClass, program, currentClass} = useProgramClassDetails();

  useEffect(() => {
    if (!program) {
      fetchProgram(true);
    }
  }, [fetchProgram, program]);

  useEffect(() => {
    if (!currentClass) {
      fetchClass(true);
    }
  }, [fetchClass, currentClass]);
};

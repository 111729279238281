import {makeStyles} from '@material-ui/core';
import {Container} from 'components-lib';
import {IComponentWithChildren} from 'models';
import React, {ReactNode} from 'react';
import {Header} from '../Header/Header';

interface ILayoutProps extends IComponentWithChildren {
  navigation?: ReactNode;
  withActions?: boolean;
}

export function Layout({children, navigation, withActions = true}: ILayoutProps) {
  const classes = useStyles();

  return (
    <Container disableGutters classes={{root: classes.root}}>
      <>
        <Header navigation={navigation} withActions={withActions} />
        {children}
      </>
    </Container>
  );
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: 0,
  },
});

import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {useFormikContext} from 'formik';
import {Column, Row, Text} from 'components-lib';
import {QuestionTypeEnum} from 'enums';
import {FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import {useActiveItem} from '../../../hooks';
import {useQueryParams} from 'hooks';
import {usePermissions} from 'permissions';
import {ActiveModeEnum, AdminRolesItems} from 'admin/library/items/enums';
import {CreateQuestionEditor, EditQuestionEditor} from '..';
import {IItemCreateFormValues} from '../../views/create/components/ItemBuilderCreateForm/config';

interface IAddEditQuestionProps {
  mode: ActiveModeEnum;
}

export const AddEditQuestion: FC<IAddEditQuestionProps> = ({mode}) => {
  const classes = useStyles();
  const {values} = useFormikContext<IItemCreateFormValues>();
  const {item} = useActiveItem();
  const {hasPermission} = usePermissions();
  const {questionId} = useQueryParams();

  const typeValue = values[FormNameEnum.type];
  const canEditText = item?.canEditQuestionText;
  const isCreateMode = mode === ActiveModeEnum.create;
  const hasAdminPermission = hasPermission(AdminRolesItems);
  const hasQuestionText = !!values[FormNameEnum.questionTextHtml];
  const isQuestionExist = !!questionId;

  return (
    <>
      <Column className={classes.group}>
        {!isQuestionExist && (
          <CreateQuestionEditor
            toolbarHidden={!hasAdminPermission}
            readOnly={!canEditText && !isCreateMode}
            withEdit={false}
          />
        )}
        {hasQuestionText && isQuestionExist && (
          <EditQuestionEditor
            hasQuestionText={hasQuestionText}
            readOnly={!canEditText && !isCreateMode}
            withEdit
            toolbarHidden={!hasAdminPermission}
          />
        )}
      </Column>
      {hasAdminPermission && (
        <Column className={classes.group}>
          <Text.Caption>Question type</Text.Caption>
          <Form.RadioGroup name={FormNameEnum.type} value={typeValue}>
            <Row justify="flex-start" align="flex-start">
              <Form.Radio
                value={QuestionTypeEnum.questionAnswerEnum}
                label="Question + Answer"
                disabled={isQuestionExist}
              />
              <Form.Radio value={QuestionTypeEnum.matrixEnum} label="Matrix Header" disabled={isQuestionExist} />
            </Row>
          </Form.RadioGroup>
        </Column>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  group: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

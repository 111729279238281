import React, {FC, Fragment, useCallback} from 'react';
import {batch} from 'react-redux';
import {ModalDelete} from 'admin/components';
import {
  getAccessibleToStudentsTooltip,
  getLinkTooltip,
  useCreateLearningPathResources,
} from 'admin/resources/learning-paths/views/create';
import {useModal} from 'hooks';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';
import {LearningPathListItem} from 'admin/resources/learning-paths/components';
import {IBaseResourceItem} from 'models/resources';
interface ISessionResourceListItemProps {
  itemId?: string;
  resourceItem: IBaseResourceItem;
  canDelete?: boolean;
  canHide?: boolean;
  isEditMode?: boolean;
  isResource?: boolean;
}

export const SessionResourceListItem: FC<ISessionResourceListItemProps> = ({
  itemId,
  resourceItem,
  canDelete = false,
  isEditMode = true,
  isResource,
}) => {
  const {contentItemId, title, isAccessibleToStudents, isDeleted, isExpired} = resourceItem;
  const {hasPermission} = usePermissions();
  const {hasUrl, redirectToEditResourcePage, deleteSessionBuilderResourceItem} = useCreateLearningPathResources({
    id: contentItemId,
  });
  const {isOpen: isDeleteSessionModalOpen, setIsOpen: setIsDeleteSessionModalOpen} = useModal();

  const toolTip = getAccessibleToStudentsTooltip(isAccessibleToStudents);
  const linkTooltip = getLinkTooltip(isDeleted, isExpired);
  const hasAdminPermission = hasPermission(USAAndSuperAdminRoles);
  const shouldDisableLink = !hasAdminPermission;

  const openModalClickHandler = useCallback(() => setIsDeleteSessionModalOpen(true), [setIsDeleteSessionModalOpen]);

  const deleteClickHandler = useCallback(
    () =>
      batch(() => {
        itemId && deleteSessionBuilderResourceItem(contentItemId, itemId);
        setIsDeleteSessionModalOpen(false);
      }),
    [itemId, contentItemId, deleteSessionBuilderResourceItem, setIsDeleteSessionModalOpen]
  );

  const linkClickHandler = useCallback(() => redirectToEditResourcePage(), [redirectToEditResourcePage]);

  return (
    <Fragment>
      <LearningPathListItem
        isEditMode={isEditMode}
        isResource={isResource}
        name={title}
        isDeletedOrExpired={isDeleted || isExpired}
        linkTooltip={linkTooltip}
        acessibleToStudentsTooltip={toolTip}
        disableLink={!hasUrl || shouldDisableLink}
        disableDelete={!canDelete || !isEditMode}
        isAvailableToStudents={isAccessibleToStudents}
        linkClickHandler={linkClickHandler}
        openModalClickHandler={openModalClickHandler}
      />
      <ModalDelete
        loading={false}
        title="Delete Resource"
        text="Would you like to take the resource out of the session?"
        cancelButtonText="No"
        confirmButtonText="Yes"
        isOpen={isDeleteSessionModalOpen}
        setIsOpen={setIsDeleteSessionModalOpen}
        confirmHandler={deleteClickHandler}
      />
    </Fragment>
  );
};

import {createSlice} from '@reduxjs/toolkit';
import {itemReducer} from './item.reducer';
import {itemsInitialState} from './items.initialState';
import {itemsExtraReducer} from './itemsExtra.reducer';

export const itemsSlice = createSlice({
  name: 'items',
  initialState: itemsInitialState,
  reducers: itemReducer,
  extraReducers: itemsExtraReducer,
});

export const {actions: itemsActions} = itemsSlice;

import React, {Fragment} from 'react';
import {useFormikContext} from 'formik';
import {Form} from 'forms';
import {Card, Row} from 'components-lib';
import {CardBody} from '../CardItem/CardBody';
import {FormNameEnum} from 'filters-selections';

export const BuilderInstructionalText = ({item, position}) => {
  const {values} = useFormikContext();
  const value = values[FormNameEnum.builderInstructionalTextHTML];

  return (
    <Fragment>
      {item && (
        <Card>
          <CardBody title={item.title} type={item.type ?? 'html'} position={position} withApply withSubmit />
          <Row justify="flex-start">
            <Form.RichTextEditor
              readOnly={false}
              withEdit={true}
              value={value}
              name={FormNameEnum.builderInstructionalTextHTML}
              placeholder="Add Instructional Text..."
              toolbarHidden={false}
              withInstructionalText
            />
          </Row>
        </Card>
      )}
    </Fragment>
  );
};

export enum QuestionPropertiesType {
  choices = 'choices',
  defaultAnswerStackId = 'defaultAnswerStackId',
  linkedQuestionAnswerStack = 'linkedQuestionAnswerStack',
  linkedQuestionAnswerStackId = 'linkedQuestionAnswerStackId',
  name = 'name',
  order = 'order',
  questionAnswerStackId = 'questionAnswerStackId',
  questionId = 'questionId',
  title = 'title',
  type = 'type',
}

import React, {FC, ReactNode} from 'react';
import {Column, Container, Loading, Row} from 'components-lib';
import {footerHeight, navHeight} from 'utils/layout';
import {useStyles} from './styles';

interface IPageLayoutOneVerticalColProps {
  header: ReactNode;
  contentStart: ReactNode;
  footer?: ReactNode;
  loading?: boolean;
  withSecondaryNavigation?: boolean;
}

export const PageLayoutOneVerticalCol: FC<IPageLayoutOneVerticalColProps> = ({
  contentStart,
  withSecondaryNavigation = false,
  header,
  footer,
  loading = false,
}) => {
  let height = `calc(100vh - ${navHeight}px - ${footerHeight}px - 45px)`;

  const classes = useStyles({height, footer, withSecondaryNavigation});

  if (!footer) {
    height = `calc(100vh - ${navHeight}px)`;
  }
  return (
    <Container classes={{root: classes.root}} disableGutters>
      <Row justify="flex-start" classes={{root: classes.headerSecondaryNavigation}}>
        {header}
      </Row>
      <Row classes={{root: classes.row}}>
        <Column sm={12} md={12} lg={12} xl={12}>
          <Container classes={{root: classes.contentOneOrTwoVerticalCols}} disableGutters>
            {loading ? <Loading /> : contentStart}
          </Container>
        </Column>
      </Row>
      {footer && (
        <Row justify="space-between" align="center" classes={{root: classes.contentOneOrTwoVerticalColsFooter}}>
          {footer}
        </Row>
      )}
    </Container>
  );
};

import React, {useCallback, useMemo, DragEvent, SyntheticEvent} from 'react';
import {PageLayoutOneOrTwoEqualCols} from 'layout';
import {ActiveStepPage, NavigationProgressBar} from 'admin/components';
import {AddIntegrationMetadata, UploadCertificate, PreviewAndTest, PickIntegrationTypeComponent} from '../';
import {useFormikContext} from 'formik';
import {FormNameEnum} from 'enums/form';
import {DraggableArea, PageViewHeader} from 'components-lib';
import {IAddIntegrationFormBaseValues} from 'models/integrations';
import {NavigationProgressBarTextEnum} from 'admin/integrations/enums';
import {NavigationProgressStepsEnum} from 'enums/common';
import {useAddIntegrationType} from '../../hooks';

export const AddIntegrationActiveStepPage = ({
  footer,
  backButtonClickHandler,
  activeStep,
  isFirstStep,
  isSecondStep,
  isThirdStep,
}) => {
  const {
    values: {integrationType},
    setFieldValue,
  } = useFormikContext<IAddIntegrationFormBaseValues>();
  const {isMicrosoftTeams, isGoogleSitesSource, isGoogleSitesDestination} = useAddIntegrationType(integrationType);

  const shouldDisableBackButton = isFirstStep;
  const stepOneText = NavigationProgressStepsEnum.StepOne + NavigationProgressBarTextEnum.Step1Text;
  const stepTwoText = NavigationProgressStepsEnum.StepTwo + NavigationProgressBarTextEnum.Step2Text;
  const stepThreeText = NavigationProgressStepsEnum.StepThree + NavigationProgressBarTextEnum.Step3Text;

  const handleFileDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();

      const certificate = event.dataTransfer.files[0];
      setFieldValue(FormNameEnum.certificate, certificate);
    },
    [setFieldValue]
  );

  const handleDragOver = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const subHeading = useMemo(
    () => (
      <NavigationProgressBar
        isFirstStep={isFirstStep}
        isSecondStep={isSecondStep}
        isThirdStep={isThirdStep}
        stepOneText={stepOneText}
        stepTwoText={stepTwoText}
        stepThreeText={stepThreeText}
      />
    ),
    [isFirstStep, isSecondStep, isThirdStep, stepOneText, stepTwoText, stepThreeText]
  );

  const header = useMemo(
    () => (
      <PageViewHeader
        fullFlexWidth
        withColumnPadding={false}
        heading="Add Integration"
        headingColor="textPrimary"
        subHeading={subHeading}
        withBackButton
        shouldDisableBackButton={shouldDisableBackButton}
        backButtonClickHandler={backButtonClickHandler}
      />
    ),
    [subHeading, backButtonClickHandler, shouldDisableBackButton]
  );

  const uploadCertificateWrapper = useMemo(
    () => (
      <DraggableArea onDrop={handleFileDrop} onDragOver={handleDragOver}>
        <UploadCertificate />
      </DraggableArea>
    ),
    [handleFileDrop, handleDragOver]
  );

  const addIntegrationComponent = useMemo(
    () => (
      <PickIntegrationTypeComponent
        isMicrosoftTeams={isMicrosoftTeams}
        isGoogleSitesSource={isGoogleSitesSource}
        isGoogleSitesDestination={isGoogleSitesDestination}
      />
    ),
    [isMicrosoftTeams, isGoogleSitesSource, isGoogleSitesDestination]
  );

  const pageLayoutFirst = useMemo(
    () => <PageLayoutOneOrTwoEqualCols header={header} footer={footer} content={<AddIntegrationMetadata />} />,
    [header, footer]
  );

  const pageLayoutSecond = useMemo(() => {
    if (isGoogleSitesDestination || isMicrosoftTeams) {
      return <PageLayoutOneOrTwoEqualCols header={header} footer={footer} content={addIntegrationComponent} />;
    }

    return (
      <PageLayoutOneOrTwoEqualCols
        header={header}
        footer={footer}
        contentLeft={addIntegrationComponent}
        contentRight={uploadCertificateWrapper}
      />
    );
  }, [header, footer, uploadCertificateWrapper, addIntegrationComponent, isGoogleSitesDestination, isMicrosoftTeams]);

  const pageLayoutThird = useMemo(
    () => <PageLayoutOneOrTwoEqualCols header={header} footer={footer} content={<PreviewAndTest />} />,
    [header, footer]
  );

  return (
    <ActiveStepPage
      activeStep={activeStep}
      pageLayoutFirst={pageLayoutFirst}
      pageLayoutSecond={pageLayoutSecond}
      pageLayoutThird={pageLayoutThird}
    />
  );
};

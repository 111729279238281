import React, {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {Filters} from 'components-lib';
import {FiltersList, FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import {IInstrumentsBaseFilter} from 'models';
import {InstrumentsFilterNamesEnum} from 'admin/library/instruments/enums';
import {instrumentsActions as actions} from 'admin/library/instruments/store';
import {useInstruments} from 'admin/library/instruments/hooks';
import {IInstrumentsAllFiltersFormValues, instrumentsAllFiltersFormValidationSchema} from './config';
import {instrumentAllFilterList} from '../..';

export function InstrumentsAllFilters() {
  const dispatch = useDispatch();
  const {filter, pageLoading} = useInstruments();

  const initialValues: IInstrumentsAllFiltersFormValues = useMemo(() => {
    return {
      [FormNameEnum.programs]: filter[InstrumentsFilterNamesEnum.PROGRAMS],
      [FormNameEnum.pillars]: filter[InstrumentsFilterNamesEnum.PILLARS],
      [FormNameEnum.phase]: filter[InstrumentsFilterNamesEnum.PHASES],
      [FormNameEnum.instrumentStatus]: filter[InstrumentsFilterNamesEnum.STATUSES],
      [FormNameEnum.instrumentType]: filter[InstrumentsFilterNamesEnum.TYPES],
      [FormNameEnum.audience]: filter[InstrumentsFilterNamesEnum.AUDIENCES],
      [FormNameEnum.name]: filter[InstrumentsFilterNamesEnum.SEARCH_BY_NAME],
      [FormNameEnum.instrumentViewType]: filter[InstrumentsFilterNamesEnum.INSTRUMENT_VIEW_TYPE],
    };
  }, [filter]);

  const submitHandler = async ({
    programs,
    pillars,
    phase,
    status,
    type,
    audience,
    name,
    instrumentViewType,
  }: IInstrumentsAllFiltersFormValues) => {
    const filter: IInstrumentsBaseFilter = {
      [InstrumentsFilterNamesEnum.SEARCH_BY_NAME]: name.trim().replace(/\s+/g, ' '), // trim and replace multiple spaces (works on on the actual search, the form is not updated)
      [InstrumentsFilterNamesEnum.PILLARS]: pillars,
      [InstrumentsFilterNamesEnum.PHASES]: phase,
      [InstrumentsFilterNamesEnum.STATUSES]: status,
      [InstrumentsFilterNamesEnum.TYPES]: type,
      [InstrumentsFilterNamesEnum.AUDIENCES]: audience,
      [InstrumentsFilterNamesEnum.PROGRAMS]: programs,
      [InstrumentsFilterNamesEnum.INSTRUMENT_VIEW_TYPE]: instrumentViewType,
    };

    dispatch(actions.setFilterInstruments(filter));
  };

  const resetHandler = useCallback(() => {
    dispatch(actions.resetFilterInstruments());
  }, [dispatch]);

  const renderFiltersList = useMemo(() => <FiltersList disableAll={pageLoading} filters={instrumentAllFilterList} />, [
    pageLoading,
  ]);

  return (
    <Form.Form<IInstrumentsAllFiltersFormValues>
      initialValues={initialValues}
      validationSchema={instrumentsAllFiltersFormValidationSchema}
      submitHandler={submitHandler}
    >
      <Filters.FiltersPanel
        clearButtonClickHandler={resetHandler}
        searchFieldName={InstrumentsFilterNamesEnum.SEARCH_BY_NAME}
        filters={renderFiltersList}
        disableAll={pageLoading}
      />
    </Form.Form>
  );
}

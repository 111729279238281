import {createAsyncThunk} from '@reduxjs/toolkit';
import {learningPathsApi} from 'api';
import {prefix} from './config';
import {toastNotificationManager} from 'toast-notifications';
import {IGetAllLearningPathsApiResponse, IGetLearningPathsPayload} from 'models/resources/learning-paths';

export const fetchLearningPathsPagedThunk: any = createAsyncThunk<IGetAllLearningPathsApiResponse, null>(
  `${prefix} fetchLearningPathsPaged`,
  async (payload: IGetLearningPathsPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await learningPathsApi.getAllLearningPaths(payload);
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

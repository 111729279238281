import React, {SyntheticEvent, useMemo} from 'react';
import {IconButton, makeStyles} from '@material-ui/core';
import {Icon} from 'components-lib';
import {Input} from '../Input/Input';
import {IWithDisabled} from 'models';

interface ISearchProps extends IWithDisabled {
  value: string | null;
  changeHandler: (ev: SyntheticEvent) => void;
  submitDisabled?: boolean;
  withIcon?: boolean;
}

export function Search({
  value,
  changeHandler,
  submitDisabled = false,
  disabled = false,
  withIcon = true,
  ...rest
}: ISearchProps) {
  const {root, button} = useStyles();
  const icon = useMemo(
    () => (
      <IconButton type="submit" className={button} disabled={submitDisabled || disabled}>
        <Icon.SearchIcon />
      </IconButton>
    ),
    [button, disabled, submitDisabled]
  );
  return (
    <Input
      {...rest}
      value={value}
      changeHandler={changeHandler}
      withIcon={withIcon}
      iconPosition="end"
      icon={icon}
      className={root}
      disabled={disabled}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  button: {
    padding: 0,
  },
}));

export const filesAllMessages = {
  scormHasNotShortUrlMessage: 'The SCORM package is not yet ready.',
  fileExpiredMessage: 'This resource has expired and the URL cannot be copied and shared.',
  fileDownload: 'File was downloaded successfully.',
  warningUnsavedChanges:
    'Are you sure you want to leave this page? You have unsaved changes. If you leave the page, these changes will be lost!',
  openInNewTab: 'The resource is being opened in a new tab.',
  resourceCopied: 'The resource link has been copied.',
  resourceDownloading: 'The resource is being downloaded.',
};

import React, {FC, Fragment, useRef} from 'react';
import {IconButton, makeStyles} from '@material-ui/core';
import {Button, Text, Icon, Label} from 'components-lib';
import {DraggableArea} from '../DraggableArea/DraggableArea';
import {useImageUploadWithPreview} from './hooks';
import {convertBytesToMegaBytes} from 'admin/upload/utils';

interface IImageUploadWithPreview {
  text?: string;
  label?: string;
  name: string;
  disabled?: boolean;
  withFolderIcon?: boolean;
  withButton?: boolean;
  withLabel?: boolean;
  accept?: string;
  buttonText?: string;
  textSize?: string;
  textFormat?: string;
  url?: string;
}

export const ImageUploadWithPreview: FC<IImageUploadWithPreview> = ({
  text = '',
  label = '',
  url = '',
  name,
  withButton = true,
  withLabel = true,
  disabled = false,
  buttonText = 'Browse',
  accept = 'image/png, image/jpeg',
  textSize = '',
  textFormat = 'Png and jpeg',
}) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const {
    file,
    imgPreview,
    openFileDialog,
    onFilesAddedChange,
    onDragOver,
    onDrop,
    onDragLeave,
    onDelete,
    hightlight,
  } = useImageUploadWithPreview({name, fileInputRef, url});

  const fileSize = file && convertBytesToMegaBytes(file.size, 2);
  const size = file ? `Size: ${fileSize}MB` : `Required size: ${textSize}`;
  const format = file ? `` : `Required format: ${textFormat}`;

  return (
    <div>
      {withLabel && <Label label={label} withLeftMargin />}
      <DraggableArea hightlight={hightlight} onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave}>
        <div className={classes.container}>
          <div className={classes.iconContainer}>
            {imgPreview ? (
              <img className={classes.logoPreview} src={imgPreview} alt="No logo" loading="lazy" />
            ) : (
              <Icon.UploadFileIcon />
            )}
          </div>
          <div className={classes.content}>
            <div className={classes.textContainer}>
              {text && (
                <Text.Paragraph withMargin classes={{root: classes.text}} className={file ? classes.fileName : ''}>
                  {file ? file.name : text}
                </Text.Paragraph>
              )}
              <Text.Caption> {<span>{size}</span>}</Text.Caption>
              <Text.Caption> {<span>{format}</span>}</Text.Caption>
            </div>
            <input
              name={name}
              className={classes.fileInput}
              ref={fileInputRef}
              type="file"
              onChange={onFilesAddedChange}
              accept={accept}
            />
            <Fragment>
              {withButton && !file && (
                <Button.Secondary size="small" disabled={disabled} clickHandler={() => openFileDialog(fileInputRef)}>
                  {buttonText}
                </Button.Secondary>
              )}

              {file && (
                <IconButton className={classes.iconDelete} onClick={onDelete}>
                  <Icon.DeleteIcon />
                </IconButton>
              )}
            </Fragment>
          </div>
        </div>
      </DraggableArea>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px dashed ${theme.palette.grey[100]}`,
    borderRadius: `${theme.spacing(1) + theme.spacing(2) / 2}px`,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '60%',
    padding: theme.spacing(3) - theme.spacing(1),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1) + theme.spacing(2),
  },
  iconContainer: {
    height: 106,
    width: '40%',
    background: theme.palette.grey[50],

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: theme.palette.grey[700],
  },
  fileName: {
    fontWeight: 700,
  },
  fileInput: {
    display: 'none',
  },
  logoPreview: {
    minHeight: 40,
    maxWidth: 164,
  },
  iconDelete: {
    color: theme.palette.grey[700],
  },
}));

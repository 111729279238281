import React, {Fragment, useMemo} from 'react';
import {ResourceUserDetails} from 'admin/resources';
import {EditLearningPathDetailsHeader} from '../EditLearningPathDetailsHeader/EditLearningPathDetailsHeader';
import {useEditLearningPath} from '../../hooks';
import {getUserDetails} from '../../utils';
import {IBoxListItem} from 'models/resources';

export const UserDetails = () => {
  const {learningPathDetails} = useEditLearningPath();
  const {createdBy, updatedBy, createdUtc, modifiedUtc, createdByInitials, updatedByInitials} = getUserDetails(
    learningPathDetails?.details
  );

  const boxList = useMemo(
    (): IBoxListItem[] => [
      {
        size: 'small',
        label: 'Created By',
        content: createdBy,
        userInitials: createdByInitials,
      },
      {
        label: 'Date Created',
        content: createdUtc,
      },
      {
        size: 'small',
        label: 'Updated by',
        content: updatedBy,
        userInitials: updatedByInitials,
      },
      {
        label: 'Date Last Updated',
        content: modifiedUtc,
      },
    ],
    [createdBy, createdByInitials, createdUtc, updatedBy, updatedByInitials, modifiedUtc]
  );

  return (
    <Fragment>
      <EditLearningPathDetailsHeader />
      {learningPathDetails && <ResourceUserDetails list={boxList} />}
    </Fragment>
  );
};

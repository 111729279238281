import {IIntegrationDetails} from 'models/integrations';
import {
  getDefaultPreviewFirstColumnList,
  getIsGoogleClassRoom,
  getIsGoogleSitesDestination,
  getIsGoogleSitesSource,
  getIsMicrosoftTeams,
} from '../../../utils/helpers';
import {getDateFromISOString, toNumber} from 'utils';
import {IntegrationLabel} from 'admin/integrations/enums';
import {DEFAULT_GOOGLE_DRIVE_FOLDER_LINK, IntegrationTypeObj} from 'admin/integrations/utils';
import {FormNameEnum, InputTypeEnum} from 'enums/form';
import {IntegrationStatusNum} from 'enums/integrations';

export const getIntegrationDetailsFirstColumnList = ({
  integrationType,
  areaName,
  schoolName,
  certificateExpirationDate,
  configuration: {clientId, defaultFolderId, masterClassroomEmailAccount},
  status,
}: IIntegrationDetails) => {
  let list = [];
  const type = toNumber(integrationType);
  const integrationTypeString = IntegrationTypeObj[type];
  const isGoogleClassroom = getIsGoogleClassRoom(type);
  const isMicrosoftTeams = getIsMicrosoftTeams(type);
  const isGoogleSitesSource = getIsGoogleSitesSource(type);
  const isGoogleSitesDestination = getIsGoogleSitesDestination(type);
  const expirationDate = certificateExpirationDate ? getDateFromISOString(certificateExpirationDate) : '';

  const shouldDisableFormIntegrationInput =
    status !== IntegrationStatusNum.TEST_FAILED &&
    status !== IntegrationStatusNum.ERROR &&
    status !== IntegrationStatusNum.TEST_PENDING;

  const defaultList = getDefaultPreviewFirstColumnList({
    integrationTypeStringValue: integrationTypeString ?? '',
    areaName: areaName ?? '',
    schoolNameStringValue: schoolName ?? '',
    certificateName: '',
  });

  if (isGoogleClassroom) {
    const googleClassroomList = defaultList.slice(0, defaultList.length - 1);

    list = [
      ...googleClassroomList,
      {
        label: IntegrationLabel.ExpiryDateCertificate,
        value: expirationDate,
        options: {
          withForm: false,
          disabled: true,
        },
      },
      {
        name: FormNameEnum.masterClassroomEmailAccount,
        label: IntegrationLabel.UserToImpersonate,
        value: masterClassroomEmailAccount ?? '',
        options: {
          withForm: true,
          disabled: shouldDisableFormIntegrationInput,
        },
      },
    ];
  }

  if (isMicrosoftTeams) {
    const microsoftTeamsList = defaultList.slice(0, defaultList.length - 1);

    list = [
      ...microsoftTeamsList,
      {
        name: FormNameEnum.clientId,
        label: IntegrationLabel.ClientID,
        value: clientId ?? '',
        options: {
          withForm: true,
          disabled: shouldDisableFormIntegrationInput,
        },
      },
      {
        name: FormNameEnum.clientSecret,
        label: IntegrationLabel.ClientSecret,
        options: {
          withForm: true,
          withIcon: true,
          disabled: shouldDisableFormIntegrationInput,
        },
        type: InputTypeEnum.Password,
      },
    ];

    return list;
  }

  if (isGoogleSitesSource) {
    const googleSitesSourceList = defaultList.slice(0, defaultList.length - 1);

    list = [
      ...googleSitesSourceList,
      {
        label: IntegrationLabel.GoogleDriveSourceFolderLink,
        value: DEFAULT_GOOGLE_DRIVE_FOLDER_LINK + defaultFolderId ?? '',
        options: {
          withForm: true,
          disabled: shouldDisableFormIntegrationInput,
        },
        name: FormNameEnum.googleDriveSourceFolderLink,
      },
      {
        label: IntegrationLabel.ExpiryDateCertificate,
        value: expirationDate,
        options: {
          withForm: true,
          disabled: true,
        },
        name: FormNameEnum.certificateExpirationDate,
      },
    ];

    return list;
  }

  if (isGoogleSitesDestination) {
    const googleSitesDestinationList = defaultList.slice(0, defaultList.length - 1);

    list = [
      ...googleSitesDestinationList,
      {
        label: IntegrationLabel.GoogleDriveDestinationFolderLink,
        value: DEFAULT_GOOGLE_DRIVE_FOLDER_LINK + defaultFolderId ?? '',
        options: {
          withForm: true,
          disabled: shouldDisableFormIntegrationInput,
        },
        name: FormNameEnum.googleDriveDestinationFolderLink,
      },
    ];
    return list;
  }

  return list;
};

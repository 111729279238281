import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {IGetActiveSurveyApiResponse} from 'models';
import {prefix} from './config';

export const saveActiveSurveyThunk: any = createAsyncThunk<
  IGetActiveSurveyApiResponse,
  {userId: number; instrumentId: number; results: {[id: number]: string}}
>(`${prefix} saveActiveSurvey`, async (payload) => {
  try {
    const {parsedBody} = await instrumentsApi.saveSurvey(payload.userId, payload.instrumentId, payload.results);
    return parsedBody;
  } catch (err) {
    throw new Error(err.parsedBody);
  }
});

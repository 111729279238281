import {makeStyles} from '@material-ui/core';
import {Text} from 'components-lib';
import React, {memo} from 'react';

interface ILabelProps {
  label: string;
  withLeftMargin?: boolean;
  withFontBold?: boolean;
  withMarginBottom?: boolean;
  className?: any;
}

export const Label = memo(({label, withFontBold, withLeftMargin = true, withMarginBottom = true}: ILabelProps) => {
  const classes = useStyles({withLeftMargin, withFontBold, withMarginBottom});
  return (
    <div className={classes.label}>
      <Text.Caption classes={{caption: classes.caption}}>{label}</Text.Caption>
    </div>
  );
});

const useStyles = makeStyles((theme) => ({
  label: ({withLeftMargin, withMarginBottom}: {withLeftMargin: boolean; withMarginBottom: boolean}) => {
    const leftMargin = theme.spacing(1) + theme.spacing(2);

    let styles: any = {
      marginLeft: withLeftMargin ? `${leftMargin}px` : 0,
    };

    if (withMarginBottom) {
      styles = {...styles, marginBottom: theme.spacing(2)};
    }

    return styles;
  },

  caption: ({withFontBold}: {withFontBold: boolean}) => {
    if (withFontBold) {
      return {
        fontWeight: 600,
        color: theme.palette.grey[700],
      };
    }
  },
}));

import {IconButton, makeStyles} from '@material-ui/core';
import {IComponentWithChildren} from 'models';
import React, {SyntheticEvent} from 'react';

interface IButtonIconProps extends IComponentWithChildren {
  size?: 'small' | 'medium';
  clickHandler: (event?: SyntheticEvent) => void;
  disabled?: boolean;
}

export function ButtonIcon({children, clickHandler, disabled = false, size = 'small'}: IButtonIconProps) {
  const classes = useStyles();
  return (
    <IconButton size={size} onClick={clickHandler} disabled={disabled} classes={{disabled: classes.disabled}}>
      {children}
    </IconButton>
  );
}

const useStyles = makeStyles({
  disabled: {
    opacity: 0.5,
  },
});

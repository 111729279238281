import {Accordion, Column, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {fetchAreasThunk, filtersAreasEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IBindBaseEntity, IWithDisabled} from 'models';
import {useSortOrder} from 'admin/programs/details/hooks';
import {SortingOrderEnum} from 'enums';

export function AreasFilter({disabled}: IWithDisabled) {
  const NAME_FIELD = 'name';
  const dispatch = useDispatch();
  const areas: IBindBaseEntity[] = useSelector(filtersAreasEntitiesCollectionSelector);
  const {sortBy} = useSortOrder();
  const sortedOptions = sortBy(areas, NAME_FIELD, SortingOrderEnum.ASC);

  useEffect(() => {
    if (!areas.length) {
      dispatch(fetchAreasThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Accordion heading="JA Area">
      <Column>
        {sortedOptions.length ? (
          sortedOptions.map((area) => (
            <Form.Checkbox
              key={area.id}
              name={FormNameEnum.areas}
              value={area.id}
              label={area.name}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>No Areas</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
}

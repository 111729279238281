import {config} from 'config';
import {ApiPathsEnum} from 'enums';
import {IReportApiEnum} from './../enums/IReportApi.enum';
import {ALL_AREAS_INDEX, returnQueryStringWithParams} from 'utils';
import {ReportTypesEnum} from 'admin/programs/details/enums';
import {IHttpResponse, programsApi} from 'api';
import {IGetProgramReportResponse, IProgramClassReportPayload} from 'models';
import {IProgramClassReportsQueryParams} from 'admin/programs/details';

export const IMPACT_SUMMARY_REPORT_URL =
  config.jaEeApi.baseUrlExports + '/' + ApiPathsEnum.REPORT_GENERATOR + '/' + IReportApiEnum.ImpactSummaryReport;

export const ACHIEVEMENT_SUMMARY_REPORT_URL =
  config.jaEeApi.baseUrlExports + '/' + ApiPathsEnum.REPORT_GENERATOR + '/' + IReportApiEnum.AchievementSummaryReport;

export const STUDENT_GRADEBOOK_REPORT_URL =
  config.jaEeApi.baseUrlExports + '/' + ApiPathsEnum.REPORT_GENERATOR + '/' + IReportApiEnum.StudentGradebookReport;

export const STUDENT_ACHIEVEMENT_SUMMARY_REPORT_URL =
  config.jaEeApi.baseUrlExports + '/' + ApiPathsEnum.REPORT_GENERATOR + '/' + IReportApiEnum.StudentAchievementReport;

export const mapProgramClassReport = ({
  programId,
  programSessionId,
  areaId,
  matchedSurveyTypesId,
}: IProgramClassReportPayload) => {
  let params = {programId} as IProgramClassReportPayload;

  const hasAreaId = !!areaId;
  const isSingleArea = hasAreaId && areaId > ALL_AREAS_INDEX;

  if (programSessionId) {
    params = {...params, programSessionId};
  }

  if (isSingleArea) {
    params = {...params, areaId};
  }

  if (matchedSurveyTypesId) {
    params = {...params, matchedSurveyTypesId};
  }

  return params;
};

export const getReportUrl = (type: ReportTypesEnum, params: IProgramClassReportsQueryParams) => {
  switch (type) {
    case ReportTypesEnum.ImpactSummary:
      return returnQueryStringWithParams(IMPACT_SUMMARY_REPORT_URL, params);
    case ReportTypesEnum.AchievementSummary:
      return returnQueryStringWithParams(ACHIEVEMENT_SUMMARY_REPORT_URL, params);
    case ReportTypesEnum.StudentGradebookReport:
      return returnQueryStringWithParams(STUDENT_GRADEBOOK_REPORT_URL, params);
    case ReportTypesEnum.StudentAchievementSummary:
      return returnQueryStringWithParams(STUDENT_ACHIEVEMENT_SUMMARY_REPORT_URL, params);
  }
};

export const generateReport = (
  type: ReportTypesEnum,
  {programId, programSessionId, areaId, matchedSurveyTypesId, exportFormat}: IProgramClassReportsQueryParams
): Promise<IHttpResponse<IGetProgramReportResponse>> => {
  const programClassReportPayload = {
    programId,
    programSessionId,
    areaId,
    matchedSurveyTypesId,
  } as IProgramClassReportPayload;

  const updatedParams = mapProgramClassReport(programClassReportPayload);
  const reportQueryUrl = getReportUrl(type, {...updatedParams, exportFormat});

  switch (type) {
    case ReportTypesEnum.ImpactSummary:
      return programsApi.getImpactSummaryReport(reportQueryUrl);
    case ReportTypesEnum.AchievementSummary:
      return programsApi.getAchievementSummaryReport(reportQueryUrl);
    case ReportTypesEnum.StudentGradebookReport:
      return programsApi.getStudentGradeBookReport(reportQueryUrl);
    case ReportTypesEnum.StudentAchievementSummary:
      return programsApi.getStudentAchievementSummaryReport(reportQueryUrl);
  }
};

import {useAppDispatch, useNavigate} from 'hooks';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {fetchUserIdAndAreaIdThunk, userProfileSelector, userStateErrorSelector} from '../store';

export function useUser() {
  const {push} = useNavigate();
  const userProfile = useSelector(userProfileSelector);
  const userStateError = useSelector(userStateErrorSelector);
  const dispatch = useAppDispatch();
  const userEmail = userProfile?.email;
  const userId = userProfile?.id;
  useEffect(() => {
    const shouldFetch = userEmail && !userStateError && !userId;
    shouldFetch && dispatch(fetchUserIdAndAreaIdThunk({email: userEmail, push}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail, userStateError]);
}

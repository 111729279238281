import {IAddResourceItemsPayload, IBaseResourceItem} from 'models/resources';
import {getResourcesCollectionByIds} from '.';

export const mapAddResourceItemsPayload = (
  id: string,
  contentItemIds: string[],
  resourcesCollection: IBaseResourceItem[]
): IAddResourceItemsPayload => {
  const filteredResourcesCollection = getResourcesCollectionByIds(contentItemIds, resourcesCollection);

  return {
    id,
    resources: filteredResourcesCollection,
  };
};

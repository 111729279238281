import {pages} from 'paths';
import {useCallback} from 'react';
import {useSessionBuilderStoreActions} from '../../session-builder';

interface IUseCreateLearningPathResources {
  id?: string | number;
}

export const useCreateLearningPathResources = ({id}: IUseCreateLearningPathResources) => {
  const {deleteSessionBuilderResourceItem, deleteSessionBuilderInstrumentItem} = useSessionBuilderStoreActions();

  const hasUrl = !!id;

  const redirectToEditResourcePage = useCallback(
    () => window.open(`${pages.adminPortal.files.editLink}/${id}`, '_blank'),
    [id]
  );

  const downloadResource = useCallback((url: string) => window.open(url, '_blank'), []);

  const redirectToEditInstrumentPage = useCallback(
    (instrumentId?: number) => {
      const detailsId = instrumentId ? instrumentId : id;
      return window.open(`${pages.adminPortal.instruments.detailsRoot}/${detailsId}`, '_blank');
    },
    [id]
  );

  return {
    hasUrl,
    redirectToEditResourcePage,
    redirectToEditInstrumentPage,
    deleteSessionBuilderResourceItem,
    deleteSessionBuilderInstrumentItem,
    downloadResource,
  };
};

import React, {FC, Fragment} from 'react';
import {useFormikContext} from 'formik';
import {makeStyles} from '@material-ui/core';
import {Button, Column, Row, Text} from 'components-lib';
import {useModal, useQueryParams} from 'hooks';
import {ModalCreateAnswerStack, ModalSelectAnswerStack} from '..';
import {AddEditQuestion} from '../AddEditQuestion/AddEditQuestion';
import {useActiveItem, usePageLoading} from '../../../hooks';
import {ItemCard} from '../ItemCard/ItemCard';
import {usePermissions} from 'permissions';
import {ActiveModeEnum, AdminRolesItems} from 'admin/library/items/enums';
import {QuestionAnswersTypeEnum} from 'enums';
import {IItemCreateFormValues} from '../../views/create/components/ItemBuilderCreateForm/config/itemBuilderCreateFormBaseValuesConfig';
import {getQuestionMode} from '../../utils';
import {FormContextProvider} from 'forms';

export const ItemQuestionEdit: FC = () => {
  const classes = useStyles();
  const {values} = useFormikContext<IItemCreateFormValues>();
  const {item} = useActiveItem();
  const {loadingPageSection} = usePageLoading();
  const {hasPermission} = usePermissions();
  const hasAdminPermission = hasPermission(AdminRolesItems);
  const {questionId} = useQueryParams();

  const isMatrixType = values.type === QuestionAnswersTypeEnum.matrixEnum;
  const itemQuestionId = Number(questionId);
  const mode = itemQuestionId ? ActiveModeEnum.edit : ActiveModeEnum.create;
  const {isCreateMode} = getQuestionMode(mode);

  const shouldDisableButtonGroup = !!itemQuestionId;
  const shouldRenderComponent = hasAdminPermission && !isCreateMode;

  // Disable using Create Answer stack if type is matrix
  const shouldDisableCreateAnswerStackButton = isMatrixType;

  const {isOpen: isSelectAnswerStackOpen, setIsOpen: setIsSelectAnswerStackOpen} = useModal();
  const {isOpen: isCreateAnswerStackOpen, setIsOpen: setIsCreateAnswerStackOpen} = useModal();

  return (
    <>
      <Row align="flex-start" justify="flex-start">
        <Column sm={12} md={12} lg={12} xl={12}>
          <AddEditQuestion mode={mode} />
          {shouldRenderComponent && (
            <Fragment>
              <Text.Heading as="h3">Answer(s)</Text.Heading>
              <Text.Caption>Add at least one answer stack.</Text.Caption>
            </Fragment>
          )}
        </Column>
        {shouldRenderComponent && (
          <div className={classes.buttonGroup}>
            <Button.Secondary
              size="small"
              isFirst
              clickHandler={() => setIsSelectAnswerStackOpen(true)}
              disabled={loadingPageSection || !shouldDisableButtonGroup}
            >
              Select Answer Stack
            </Button.Secondary>
            <Button.Secondary
              size="small"
              clickHandler={() => setIsCreateAnswerStackOpen(true)}
              disabled={loadingPageSection || !shouldDisableButtonGroup || shouldDisableCreateAnswerStackButton}
            >
              Create Answer Stack
            </Button.Secondary>
          </div>
        )}
        {item && (
          <Column className={classes.itemGroup} sm={12} md={12} lg={12} xl={12}>
            {item.elements.map((item) => (
              <ItemCard key={item.questionAnswerStackId} item={item} />
            ))}
          </Column>
        )}
      </Row>
      <FormContextProvider>
        {isSelectAnswerStackOpen && (
          <ModalSelectAnswerStack isOpen={isSelectAnswerStackOpen} setIsOpen={setIsSelectAnswerStackOpen} />
        )}
        {isCreateAnswerStackOpen && (
          <ModalCreateAnswerStack isOpen={isCreateAnswerStackOpen} setIsOpen={setIsCreateAnswerStackOpen} />
        )}
      </FormContextProvider>
    </>
  );
};

const useStyles = makeStyles({
  buttonGroup: {
    marginTop: 10,
  },
  itemGroup: {
    marginTop: 20,
  },
});

import React, {memo} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Integrations} from 'admin';
import {pages, paths} from 'paths';
import {ItemImport} from './items-import';
import {Library} from './library';
import {Programs} from './programs';
import {Resources} from './resources';

export function AdminPortal() {
  return (
    <Switch>
      <Route exact path={paths.adminPortal.root} render={() => <Redirect to={pages.adminPortal.programs.root} />} />
      <Route path={pages.adminPortal.resources} component={Resources} />
      <Route path={pages.adminPortal.programs.root} component={Programs} />
      <Route path={pages.adminPortal.library} component={Library} />
      <Route path={pages.adminPortal.integrations.root} component={Integrations} />
      <Route path={pages.adminPortal.itemImport.root} component={ItemImport} />
    </Switch>
  );
}

export default memo(AdminPortal);

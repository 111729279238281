import React, {useCallback} from 'react';
import {useAppDispatch} from 'hooks';
import {useSelector} from 'react-redux';

import {itemsActions as actions} from '../../../store/items.slice';
import {itemSortOrderSelector} from 'admin/library/items/store';
import {IItemsAllSortOrder} from 'models';
import {SortByDropdown} from 'admin';
import {itemAllOrderingMap} from '../../utils';
import {getSortMenuItemsList} from 'admin/library/utils';

export function ItemsAllSortBy() {
  const dispatch = useAppDispatch();

  const sortOrder = useSelector(itemSortOrderSelector);

  const handleItemClick = useCallback(
    (sortOrder: IItemsAllSortOrder) => {
      dispatch(actions.setSortOrderItemsAll(sortOrder));
    },
    [dispatch]
  );

  return (
    <SortByDropdown
      label="Sort By: "
      handleItemClick={handleItemClick}
      getMenuItemsList={getSortMenuItemsList}
      sortOrder={sortOrder}
      map={itemAllOrderingMap}
    />
  );
}

import {Accordion, Column, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {filtersDistrictsSelector} from 'filters-selections/store';
import {Form} from 'forms';
import React from 'react';
import {useSelector} from 'react-redux';
import {IWithDisabled} from 'models';

export function DistrictsFilter({disabled}: IWithDisabled) {
  const districts = useSelector(filtersDistrictsSelector);

  return (
    <Accordion heading="School Districts">
      <Column>
        {districts.length ? (
          districts.map((district) => (
            <Form.Checkbox
              key={district}
              name={FormNameEnum.districts}
              value={district}
              label={district}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>No School Districts</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
}

import {RolesEnum} from './Roles.enum';

export const AdminRoles = [RolesEnum.SuperAdmin, RolesEnum.USAAdmin, RolesEnum.AreaAdmin, RolesEnum.Staff];

export const AdminWithoutStaffRoles = [RolesEnum.SuperAdmin, RolesEnum.USAAdmin, RolesEnum.AreaAdmin];

export const USAAndSuperAdminRoles = [RolesEnum.SuperAdmin, RolesEnum.USAAdmin];

export const AllRolesWithoutStudent = [
  RolesEnum.AreaAdmin,
  RolesEnum.Educator,
  RolesEnum.Staff,
  RolesEnum.SuperAdmin,
  RolesEnum.USAAdmin,
  RolesEnum.Volunteer,
];

export const AllAreaRolesWithEducatorsAndStaff = [
  RolesEnum.AreaAdmin,
  RolesEnum.Educator,
  RolesEnum.Staff,
  RolesEnum.Volunteer,
];

export const AllRoles = [
  RolesEnum.AreaAdmin,
  RolesEnum.Educator,
  RolesEnum.Staff,
  RolesEnum.Student,
  RolesEnum.SuperAdmin,
  RolesEnum.USAAdmin,
  RolesEnum.Volunteer,
];

export const SuperAdminRole = [RolesEnum.SuperAdmin];

export const AreaAdminRole = [RolesEnum.AreaAdmin];

import React, {useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {Accordion, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {Form} from 'forms';
import {useAppDispatch} from 'hooks';
import {IWithDisabled} from 'models';
import {
  fetchDigitalResourceTypesThunk,
  filtersDigitalResourceTypesEntitiesCollectionSelector,
} from 'filters-selections/store';

export function DigitalResourceTypesFilter({disabled}: IWithDisabled) {
  const dispatch = useAppDispatch();
  const types = useSelector(filtersDigitalResourceTypesEntitiesCollectionSelector);

  useEffect(() => {
    if (!types.length) {
      dispatch(fetchDigitalResourceTypesThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const options = useMemo(() => types.map((type) => ({value: type.id, label: type.displayText})), [types]);

  return (
    <Accordion heading="Types">
      {types && types.length ? (
        <Form.Select name={FormNameEnum.digitalResourceTypes} options={options} disabled={disabled} />
      ) : (
        <Text.Caption>No Data</Text.Caption>
      )}
    </Accordion>
  );
}

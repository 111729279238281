import React from 'react';
import {ViewExpansionPanel} from '../../../components';
import {useClassPaperAssessments} from '../../hooks';
import {SidebarBase} from 'components-lib/sidebar/SidebarBase';

export const SidebarFilters = () => {
  const {loading} = useClassPaperAssessments();

  return (
    <SidebarBase>
      <ViewExpansionPanel disabled={loading} />
    </SidebarBase>
  );
};

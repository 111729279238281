import React, {useMemo} from 'react';
import {useEditLearningPath} from '../..';
import {Status} from 'admin/library/components';
import {ResourceHeader} from 'admin';

export const EditLearningPathHeader = () => {
  const {redirectToViewListOLearningPaths, learningPathDetails} = useEditLearningPath();

  const extraHeading = useMemo(() => <Status status={learningPathDetails?.details?.status} />, [
    learningPathDetails.details,
  ]);

  return (
    <ResourceHeader
      heading="Learning Path"
      extraHeading={extraHeading}
      subHeading={learningPathDetails?.title}
      withBackButton
      withSubHeading
      backButtonClickHandler={redirectToViewListOLearningPaths}
    />
  );
};

import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Form} from 'forms';
import {Column, Row} from 'components-lib';
import {InstrumentsFormNameEnum} from 'admin/library/instruments/enums';
import {useBuilder} from '../../hooks';
import {useInstrument} from 'admin/library/instruments/details/hooks';

export const InstrumentEditHeaderAndFooter = () => {
  const classes = useStyles();
  const {instrumentId} = useBuilder();
  const {activeTemplate} = useInstrument(instrumentId);

  const headerUrl = activeTemplate?.header?.url ?? '';
  const footerUrl = activeTemplate?.footer?.url ?? '';

  return (
    <Row justify="flex-start" align="flex-start" classes={{root: classes.row}}>
      <Column sm={6} md={6} lg={6} xl={6} className={classes.headerLogo}>
        <Form.ImageUpload
          name={InstrumentsFormNameEnum.headerLogo}
          text="Drag and Drop file here"
          textSize="600 x 140px"
          label="Header"
          url={headerUrl}
        />
      </Column>

      <Column sm={6} md={6} lg={6} xl={6} className={classes.footerLogo}>
        <Form.ImageUpload
          name={InstrumentsFormNameEnum.footerLogo}
          text="Drag and Drop file here"
          textSize="360 x 80px"
          label="Footer"
          url={footerUrl}
        />
      </Column>
    </Row>
  );
};

const useStyles = makeStyles((theme) => ({
  row: {
    marginRight: theme.spacing(3),
  },
  headerLogo: {
    paddingRight: theme.spacing(2),
  },
  footerLogo: {
    paddingLeft: theme.spacing(2),
  },
}));

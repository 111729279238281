import {createAsyncThunk} from '@reduxjs/toolkit';
import {ICustomAnswerStackPayload, IQuestionBase} from 'models';
import {prefix} from './config';
import {itemsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {itemsMessages} from '../../utils';

export const addCustomAnswerStackThunk: any = createAsyncThunk<IQuestionBase, ICustomAnswerStackPayload>(
  `${prefix} addDefaultAnswerStack`,
  async (payload, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await itemsApi.addCustomAnswerStack(payload);
      dispatch(toastNotificationManager.createSuccessToastAction(itemsMessages.updateSuccess));
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

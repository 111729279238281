import {useCallback} from 'react';
import {useAppDispatch} from 'hooks';
import {fetchServiceEmailThunk, fetchPublishedSourceDetailsThunk} from 'admin';

export const useIntegrationStateActions = () => {
  const dispatch = useAppDispatch();

  const fetchServiceEmail = useCallback((): void => dispatch(fetchServiceEmailThunk()), [dispatch]);

  const fetchPublishedSourceDetails = useCallback((): void => dispatch(fetchPublishedSourceDetailsThunk()), [dispatch]);

  return {
    fetchServiceEmail,
    fetchPublishedSourceDetails,
  };
};

import {IHttpResponse} from 'api';
import {ApiPathsEnum} from 'enums';
import {config} from 'config';
import {get, put} from './http-client';
import {
  IGoogleSiteCopyApiResponse,
  IGoogleSiteCopyPayload,
  IGoogleSiteDetails,
  IGoogleSitesListApiResponse,
} from 'models/resources/google-sites';

const {jaEeApi: apiConfig} = config;

// Google Sites Source

export const googleSitesApi = {
  async getDetailsGoogleSiteSource(siteFieldId: string): Promise<IHttpResponse<IGoogleSiteDetails>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.GOOGLE_SITES}/${ApiPathsEnum.SOURCE}/${ApiPathsEnum.SITE}/${siteFieldId}/Details`;
    return await get<IGoogleSiteDetails>(url);
  },

  // Google Site List from Source Integration

  async getGoogleSitesList(): Promise<IHttpResponse<IGoogleSitesListApiResponse>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.GOOGLE_SITES}/${ApiPathsEnum.SOURCE}/${ApiPathsEnum.LIST_SITES}`;
    return await get<IGoogleSitesListApiResponse>(url);
  },

  // Google Sites Destination

  async getDetailsGoogleSiteDestination(siteFieldId: string): Promise<IHttpResponse<IGoogleSiteDetails>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.GOOGLE_SITES}/${ApiPathsEnum.DESTINATION}/${ApiPathsEnum.SITE}/${siteFieldId}/Details`;
    return await get<IGoogleSiteDetails>(url);
  },

  async getGoogleSitesCopySite({
    integrationId,
    siteFieldId,
  }: IGoogleSiteCopyPayload): Promise<IHttpResponse<IGoogleSiteCopyApiResponse>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.GOOGLE_SITES}/${ApiPathsEnum.DESTINATION}/${integrationId}/${ApiPathsEnum.COPY_SITE}/${siteFieldId}`;
    return await put<IGoogleSiteCopyApiResponse>(url, {});
  },
};

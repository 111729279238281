import {createAsyncThunk} from '@reduxjs/toolkit';
import {addIntegrationMessages} from 'admin/integrations/add-integration';
import {integrationsApi} from 'api';
import {ICreateGoogleVirtualClassPayload} from 'models/integrations';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const createGoogleVirtualClassThunk: any = createAsyncThunk(
  `${prefix} createGoogleVirtualClass`,
  async (payload: ICreateGoogleVirtualClassPayload, {dispatch, rejectWithValue}) => {
    try {
      const {data} = await integrationsApi.createGoogleVirtualClass(payload);
      dispatch(toastNotificationManager.createSuccessToastAction(addIntegrationMessages.createIntegrationSuccess));
      return data;
    } catch (error) {
      const errMsg = error.response.data.messages[0];
      dispatch(
        toastNotificationManager.createErrorToastAction(errMsg ?? addIntegrationMessages.unknownIntegrationError)
      );
      return rejectWithValue({error});
    }
  }
);

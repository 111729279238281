import {replaceWithNullIfEmptyString} from 'admin';
import {IIntegrationDetailsFormBaseValues} from 'models/integrations';

export const mapGoogleSitesDestinationPayload = ({
  integrationName,
  googleDriveDestinationFolderLink,
}: IIntegrationDetailsFormBaseValues) => {
  const configIntegrationName = replaceWithNullIfEmptyString(integrationName);
  const configDefaultFolderLink = replaceWithNullIfEmptyString(googleDriveDestinationFolderLink);

  return {
    name: configIntegrationName,
    defaultFolderLink: configDefaultFolderLink,
  };
};

import {unwrapResult} from '@reduxjs/toolkit';
import {useAppDispatch, useQueryParams} from 'hooks';
import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {toNumber} from 'utils';
import {SurveyTakerContent, ViewError} from './components';
import {IExtendedSurvey} from './models';
import {getGeneralSurveyThunk, saveGeneralSurveyThunk} from './store';

export function SurveyTakerGeneralInstrument() {
  const dispatch = useAppDispatch();
  const {instrumentId: instrumentIdRaw} = useParams<{instrumentId: string}>();
  const instrumentId = toNumber(instrumentIdRaw);
  const {aid: areaIdRaw} = useQueryParams();
  const areaId = toNumber(areaIdRaw);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>('');
  const [survey, setSurvey] = useState(null);
  const [surveyTaken, setSurveyTaken] = useState<boolean>(false);

  useEffect(() => {
    if (instrumentId) {
      dispatch(getGeneralSurveyThunk(instrumentId))
        .then(unwrapResult)
        .then((result: IExtendedSurvey) => {
          if (!result?.pages) {
            setLoading(false);
            setError((result as unknown) as string);
            return;
          }
          const {pages, footerUrl, logo, logoWidth, logoHeight, logoPosition} = result;

          setSurvey({
            showCompletedPage: false,
            showPageNumbers: false, // See: https://mentormate.atlassian.net/browse/JADF-559
            pages,
            footerUrl,
            logo,
            logoWidth,
            logoHeight,
            logoPosition,
          } as any); // TODO
          setLoading(false);
        })
        .catch((err: Error) => {
          setError(err.message);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveResultsHandler = useCallback(
    (results: {[id: number]: string}, timeSpent: number) => {
      setLoading(true);

      dispatch(saveGeneralSurveyThunk({instrumentId, results, timeSpent, areaId}))
        .then(unwrapResult)
        .then(() => {
          setSurveyTaken(true);
          setLoading(false);
        })
        .catch((err: Error) => {
          setError(err.message);
          setLoading(false);
        });
    },
    [dispatch, instrumentId, areaId]
  );

  if (!instrumentId) {
    return <ViewError text="Missing data." />;
  }

  return (
    <SurveyTakerContent
      loading={loading}
      surveyTaken={surveyTaken}
      error={error}
      survey={survey}
      saveResultsHandler={saveResultsHandler}
    />
  );
}

import React, {useEffect, useMemo} from 'react';
import {useFormikContext} from 'formik';
import {useGeneralInstrumentMeta} from 'admin/library/instruments/hooks';
import {FormNameEnum, SelectionEnum, SelectionListItems} from 'filters-selections';

interface ISidebarInstrumentDetailsEditProps {
  isGeneralTemplate: boolean;
  disableAll?: boolean;
}

export function SidebarInstrumentDetailsEdit({isGeneralTemplate, disableAll}: ISidebarInstrumentDetailsEditProps) {
  const {setFieldValue} = useFormikContext();
  const {audienceType, feedbackType} = useGeneralInstrumentMeta();

  useEffect(() => {
    if (isGeneralTemplate) {
      setFieldValue(FormNameEnum.instrumentType, feedbackType?.id);
      setFieldValue(FormNameEnum.audience, audienceType?.id);
    }
  }, [isGeneralTemplate, feedbackType.id, audienceType.id, setFieldValue]);

  const selectionList = useMemo(() => {
    if (isGeneralTemplate) {
      return [SelectionEnum.InstrumentType, SelectionEnum.Audience];
    }

    return [
      SelectionEnum.Pillar,
      SelectionEnum.ProgramMultiSelection,
      SelectionEnum.Phase,
      SelectionEnum.InstrumentType,
      SelectionEnum.InstrumentSubType,
      SelectionEnum.Audience,
    ];
  }, [isGeneralTemplate]);

  return <SelectionListItems list={selectionList} disableAll={isGeneralTemplate || disableAll} />;
}

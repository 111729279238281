import {AppBar, makeStyles, Toolbar} from '@material-ui/core';
import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import JAEELogo from 'resources/logos/Logo.svg';
import {ProfileMenu} from '../ProfileMenu/ProfileMenu';

interface IHeaderProps {
  navigation?: ReactNode;
  withActions?: boolean;
}

export function Header({navigation, withActions = true}: IHeaderProps) {
  const classes = useStyles();
  return (
    <AppBar position="relative" className={classes.root} elevation={0}>
      <Toolbar>
        <Link to="/" className={classes.link}>
          <img alt="Logo" src={JAEELogo} className={classes.logo} loading="lazy" />
        </Link>
        <div className={classes.navigation}>{!!navigation && navigation}</div>
        {withActions && <ProfileMenu />}
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  link: {
    pointer: 'cursor',
  },
  logo: {
    height: 55,
  },
  navigation: {
    flexGrow: 1,
  },
}));

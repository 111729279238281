import React, {Fragment, ReactNode, useMemo} from 'react';
import {
  AddIntegrationGoogleClassRoom,
  AddIntegrationMicrosoftTeams,
  AddIntegrationGoogleSitesSource,
  AddIntegrationGoogleSitesDestination,
} from '../';

export const PickIntegrationTypeComponent = ({isMicrosoftTeams, isGoogleSitesSource, isGoogleSitesDestination}) => {
  const content = useMemo(() => {
    let component: ReactNode = <AddIntegrationGoogleClassRoom />;

    if (isMicrosoftTeams) {
      component = <AddIntegrationMicrosoftTeams />;
    }

    if (isGoogleSitesSource) {
      component = <AddIntegrationGoogleSitesSource />;
    }

    if (isGoogleSitesDestination) {
      component = <AddIntegrationGoogleSitesDestination />;
    }

    return component;
  }, [isMicrosoftTeams, isGoogleSitesSource, isGoogleSitesDestination]);

  return <Fragment>{content}</Fragment>;
};

import {
  IDigitalResourcesFormats,
  IFileSizes,
  IPapperAssessmentsFormats,
  ITags,
  IUploaderMessages,
  IVideoFormats,
  IDigitalResourceTagsMessages,
  IDigitalResourceNumberType,
  IDigitalResourceType,
} from 'models';
import moment from 'moment';

// TODO refactor
export const formInputRegexes = {
  email: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
  // at least 1 lowercase letter, 1 uppercase letter, 1 numeric character and be at least 8 symbols long
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
  queryParamRetrievar: '=(.*?)(&|$)',
};

export const errorMessages = {
  invalidDate: 'The date is invalid',
  invalidDatePeriod: 'Invalid date period',
  queryParam: 'Query parameter was not found',
  userNotLoggedIn: 'User is not logged in.',
  required: 'Required!',
  requiredWithFieldName: (fieldName: string, optionalText = 'You should select') => `${optionalText} ${fieldName}!`,
  requiredWithInputName: (fieldName: string, optionalText = 'You should enter') => `${optionalText} ${fieldName}!`,
  defaultErrorMessage: 'Something Went Wrong.',

  questionTextRequired: 'Question is required.',
  instrumentNameRequired: 'Instrument Name is required.',
  audienceRequired: 'Audience is required.',
  phaseRequired: 'Phase is required.',
  pillarRequired: 'Pillar is required.',
  instrumentTypeRequired: 'Type is required.',
  programRequired: 'At least one program is required.',
  subTypeRequired: 'Sub type is required.',

  onlyFeedbackTypeAllowed: 'Only surveys with Type: Feedback are allowed for the selected Audience!',
  onlyGeneralAllowed: 'This type is not allowed for the selected Audience!',
};

export const MIN_INSTRUMENT_NAME_LENGTH = 32;

export const DISPLAY_PAGE_MARGIN = 2;

export const AUTOCOMPLETE_OR_MULTISELECT_THRESHOLD = 8;

export const dateFormat = 'MM/DD/YYYY';

export const returnDateRange = (startDate: any, endDate: any) => {
  return returnDateRangeStr(returnFormattedDate(startDate), returnFormattedDate(endDate));
};

export const returnDateRangeStr = (startDate: string, endDate: string): string => {
  return startDate + ' - ' + endDate;
};

export const returnFormattedDate = (date: any): string => {
  if (!date) {
    return '';
  }

  return moment(date).format(dateFormat);
};

// Uploader

export const INDICATOR = {
  number: 'number',
  string: 'string',
};

export const UPLOADER_MESSAGES: IUploaderMessages = {
  errInvalidFormat: 'Invalid format',
  errInvalidFileSize: 'Invalid file size',
  errNotAllowedFormats: 'This format does not meet allowed formats',
  allowedFileSize: 'Max allowed file size: 1024 MB (1GB)',
};

export const EXTERNAL_ID = 'externalId';
export const ADAPTIVE_CARD = 'Adaptive Card';
export const VIRTUAL_CLASS_ADAPTIVE_CARD = 'Virtual Class Adaptive Card';
export const K12_RESOURCES = 'K-12 Resources';

export const AUTOCOMPLETE_TIMEOUT = 1500; //ms
export const VIDEO_PLAYER_TIMEOUT = 100;
export const EDIT_MODAL_OPEN_TIMEOUT = 1200;

export const DROPZONE_TEXT = 'Drag and drop a file here or';
export const PICK_TAGS_TEXT = 'Pick and add tags to the already selected file.';

// Digital Resources

export const TAGS: ITags = {
  pillars: 'Pillars',
  pillar: 'Pillar',
  competencies: 'Competencies',
  sessions: 'Session',
  programs: 'Programs',
  gradeLevels: 'Grade Levels',
  languages: 'Languages',
  skillsAndBehaviors: 'Skills and Behaviors',
  timing: 'Timing',
  selectPlaceholder: 'Select',
  drmExpirationDate: 'DRM Expiration Date',
  keyTerms: 'Key Terms',
  concepts: 'KSAs',
  addTags: 'Add tags',
  learningObjective: 'Learning Objective',
  tags: 'Tags',
};

export const ARCHIVE_FORMATS = 'rar, zip, 7z, targz, tgz, tarZ, tarbz2, tbz2, tarlz, tlz, tarxz, txz';
export const ALLOWED_DIGITAL_RESOURCES_FORMATS =
  'audio/*, image/*, .ODP, .ODS, .MOV, .MP4, .M4V, .RTF, .DJVU, .DOC, .DOCX, .TXT, .ODT, .XLS, .XLSX, .PPT, .PPTX, .PPS, .PPSX, .PDF, .GIF';
export const ALLOWED_PAPER_ASSESSMENTS_FORMAT = '.zip';
export const ALLOWED_VIDEO_FORMATS = '.avi,.mp4,.mov,.mpeg4,.wmv,.mpegps,.flv,.3gp,.3g2,.ogv,.mpg,.m4v';
export const NOT_ALLOWED_VIDEO_FORMATS = '.avi,.wmv.flv,.3gp,.3g2,.mpg,';
export const ALLOWED_AUDIO_FORMATS = '.mp3,.m4a,.ogg,.wav';
export const ALLOWED_DOC_FORMATS = '.pdf,.doc,.docx,.ppt,.pptx,.pps,.ppsx,.odt,.xls,.xlsx';
export const ALLOWED_IMG_FORMATS = '.jpg,.jpeg,.png,.gif';

export const VIRTUAL_CLASS_TYPE = 'virtual class';
export const VIDEO_TYPE = 'video';
export const K12_RESOURCE = 'k-12 resource';
export const PAPER_ASSSESSMENT = 'paper assessment';
export const OTHERS = 'others';
export const CHAT_BOT = 'chat bot';
export const DIGITAL_RESOURCES_TYPES = 'document,audio,image,paper assessment,others';

export const AZURE_STREAMING_FORMAT = 'application/vnd.ms-sstr+xml';

export const VIDEO_FORMATS: IVideoFormats = {
  AVI: 'avi',
  MP4: 'mp4',
  MOV: 'mov',
  MPEG4: 'mpeg4',
  WMV: 'wmv',
  MPEGPS: 'mpegps',
  FLV: 'flv',
};

export const DIGITAL = 'digital';
export const DIGITAL_RESOURCES_FORMATS: IDigitalResourcesFormats = {
  AVI: 'avi',
  MP4: 'mp4',
  MOV: 'mov',
  MPEG4: 'mpeg4',
  WMV: 'wmv',
  MPEGPS: 'mpegps',
  FLV: 'flv',
  M4A: ',4a',
  MP3: 'mp3',
  OGG: 'ogg',
  WAV: 'wav',
  DOC: 'doc',
  DOCX: 'docx',
  ODT: 'odt',
  XLS: 'xls',
  XLSX: 'xlsx',
  PPT: 'ppt',
  PPTX: 'pptx',
  PPS: 'pps',
  PPSX: 'ppsx',
  PDF: 'pdf',
  JPG: 'jpg',
  JPEG: 'jpeg',
  PNG: 'png',
  GIF: 'gif',
};

export const PAPPER_ASSESSMENTS_FORMATS: IPapperAssessmentsFormats = {
  ZIP: 'zip',
};

export const DIGITAL_RESOURCE_TAGS_MESSAGES: IDigitalResourceTagsMessages = {
  editK12TagsSuccess: 'K12 tags were updated successfully',
};

export const FILE_SIZES: IFileSizes = {
  mb: 'MB',
};

export const MAX_ALLOWED_SIZE = 1024; // MB
export const MAX_PERCENT = 100;
export const MIN_PERCENT = 0;
export const FIVE_PERCENT = 5;
export const ZERO_NUMBER = 0;
export const ONE_NUMBER = 1;
export const SEC_DURATION = 1000;
export const UPLOAD_ITEM_HEIGHT = 48;
export const UPLOAD_ITEM_WIDTH = 352;
export const ROUNDED_BY_TWO_DIGITS = 2;

export const videoMetaData = {
  programs: [],
  sessions: [],
  pillars: [],
  competencies: [],
  levels: [],
  languages: [],
  keyTerms: [],
  concepts: [],
  learningObjective: [],
  drmExpirationDate: '',
  skilsAndBehaviors: [],
};

export const TAGS_SELECTOR = {
  keyTerms: 'keyTerms',
  concepts: 'concepts',
  learningObjective: 'learningObjective',
  drmExpirationDate: 'drmExpirationDate',
  competencies: 'competencies',
  sessions: 'sessions',
  levels: 'levels',
  programs: 'programs',
  pillars: 'pillars',
  skilsAndBehaviors: 'skilsAndBehaviors',
  languages: 'languages',
};

export const DEFAULT_COPY_MSG = 'Copied!';

export const MODAL_MESSAGES = {
  VERIFY_DELETE: 'Are you sure you want to delete the file?',
  WARN_DELETE: 'This action cannot be undone!',
};

export const RESOURCE_TYPE: IDigitalResourceType = {
  1: 'Virtual Class',
  2: 'Video',
  3: 'Audio',
  4: 'Document',
  5: 'Image',
  6: 'Paper Assessment',
  7: 'K-12 Resource',
  8: 'Chatbot',
  100: 'Others',
};

export const RESOURCE_NUMBER_TYPE: IDigitalResourceNumberType = {
  virtualClass: 1,
  video: 2,
  audio: 3,
  document: 4,
  image: 5,
  paperAssessment: 6,
  k12Resource: 7,
  chatBot: 8,
  others: 100,
};

// Answer Rating

export const BUTTON_NUM_LIST: number[] = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

// Instrument Template

export const ALLOWED_LOGO_SIZE = 1024;
export const SUPPORTED_LOGO_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const allowedHeaderLogoDimensions = {
  width: 600,
  height: 140,
};

export const allowedFooterLogoDimensions = {
  width: 360,
  height: 80,
};

export const ALLOWED_INSTRUCTIONAL_TEXT_LENGTH = 500;

export const ALL_AREAS_INDEX = -1;

export const RANDOM_MULTIPLIER = 900000000;
export const RANDOM_COLLECTIVELY = 100000000;
export const NEGATIVE_ONE = -1;

import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

interface IDuplicateInstrumentPayload {
  instrumentId: number;
  programSessionId: number;
}

export const duplicateInstrumentThunk: any = createAsyncThunk<number, IDuplicateInstrumentPayload>(
  `${prefix} duplicateInstrument`,
  async ({instrumentId, programSessionId}: IDuplicateInstrumentPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await instrumentsApi.postDuplicateTemplate(instrumentId, programSessionId);

      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction('There was an error while duplicating the instrument.'));
      return rejectWithValue(err);
    }
  }
);

import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';

export const getUploadState = (state: TStateStore) => state.resources.upload;

const getUploadFileState = createSelector(getUploadState, (upload) => upload.file);
export const uploadFilePageLoadingSelector = createSelector(getUploadFileState, (file) => file.page.loading);

const getUploadScormState = createSelector(getUploadState, (upload) => upload.scormPackage);
export const uploadScormPageLoadingSelector = createSelector(getUploadScormState, (scorm) => scorm.page.loading);

const getAddWebLinkState = createSelector(getUploadState, (upload) => upload.webLink);
export const createWebLinkPageLoadingSelector = createSelector(getAddWebLinkState, (webLink) => webLink.page.loading);

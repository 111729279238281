import {Checkbox as CheckboxMui, FormControlLabel, makeStyles} from '@material-ui/core';
import React, {SyntheticEvent} from 'react';

interface ICheckboxProps {
  checked?: boolean;
  name?: string;
  label?: string;
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  value?: string;
  changeHandler?: (event: SyntheticEvent, selected?: boolean) => void;
}

export function Checkbox({
  checked,
  color = 'primary',
  name,
  label,
  value,
  changeHandler,
  disabled = false,
  ...rest
}: ICheckboxProps) {
  const classes = useStyles();

  return (
    <FormControlLabel
      onChange={changeHandler}
      label={label}
      value={value}
      checked={checked}
      classes={{root: classes.root}}
      control={<CheckboxMui name={name} color={color} disabled={disabled} checked={checked} />}
      {...rest}
    />
  );
}

const useStyles = makeStyles({
  root: {
    marginRight: 0,
  },
});

import React from 'react';
import {PaginationLocal} from 'components-lib';
import {useLearningPaths, useLearningPathsAllStoreActions} from 'admin/resources/learning-paths/views/all/hooks';

export const LearningPathContentFooter = () => {
  const {loading, totalCount, perPage, currentPage} = useLearningPaths();
  const {setCurrentPage, setPerPage} = useLearningPathsAllStoreActions();

  return (
    <PaginationLocal
      onPageChange={setCurrentPage}
      onPerPageChange={setPerPage}
      totalCount={totalCount}
      perPage={perPage}
      currentPage={currentPage}
      disabled={loading}
    />
  );
};

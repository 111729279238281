import React, {Fragment, useMemo} from 'react';
import {SidebarAuthor, SidebarTitle} from 'admin/components';
import {useActiveItem, usePageLoading} from 'admin/library/items/hooks';
import {Sidebar} from 'components-lib';

export const SidebarBuilderCreateItem = () => {
  const {item} = useActiveItem();
  const {loadingPageSection} = usePageLoading();

  const date = item?.createdOn;
  const creatorArea = item?.creatorArea;
  const author = item?.createdBy;
  const questionOptionsType = item?.questionOptionsType?.displayText;

  const SidebarContent = useMemo(
    () => () => (
      <Fragment>
        {author && (
          <Fragment>
            <SidebarTitle text="Question" />
            <SidebarAuthor author={author} date={date} creatorArea={creatorArea} questionType={questionOptionsType} />
          </Fragment>
        )}
      </Fragment>
    ),
    [author, date, creatorArea, questionOptionsType]
  );

  const sidebarInfo = useMemo(() => {
    return <Fragment>{!loadingPageSection && <SidebarContent />}</Fragment>;
  }, [loadingPageSection, SidebarContent]);

  return <Sidebar.Base withHeader={false}>{sidebarInfo}</Sidebar.Base>;
};

import {makeStyles} from '@material-ui/core';
import {TrendingFlat} from '@material-ui/icons';
import React from 'react';

interface ITrendingLeftProps {
  color?: 'primary' | 'disabled' | 'action';
}

export function TrendingLeft({color = 'primary'}: ITrendingLeftProps) {
  const classes = useStyles();
  return <TrendingFlat fontSize="small" color={color} classes={{root: classes.root}} />;
}

const useStyles = makeStyles({
  root: {
    transform: 'rotate(180deg)',
  },
});

import {getIsGoogleSitesDestination, getIsGoogleSitesSource} from 'admin/integrations/utils';
import {FormNameEnum} from 'enums/form';
import {AddIntegrationLabelEnum} from '../../enums';

export const getAddIntegrationGoogleSitesList = (type: number) => {
  const isGoogleSitesSource = getIsGoogleSitesSource(type);
  const isGoogleSitesDestination = getIsGoogleSitesDestination(type);
  const defaultList = getDefaultGoogleSitesList();
  let list = [];

  if (isGoogleSitesSource) {
    list = [...defaultList];
  }

  if (isGoogleSitesDestination) {
    list = [
      {
        label: AddIntegrationLabelEnum.GoogleDriveDestinationFolderLink,
        name: FormNameEnum.googleDriveDestinationFolderLink,
        options: {
          withMargin: false,
        },
      },
    ];
  }

  return list;
};

function getDefaultGoogleSitesList() {
  return [
    {
      label: AddIntegrationLabelEnum.ServiceEmailAdress,
      name: FormNameEnum.email,
      options: {
        withMargin: false,
      },
    },
    {
      label: AddIntegrationLabelEnum.GoogleDriveSourceFolderLink,
      name: FormNameEnum.googleDriveSourceFolderLink,
      options: {
        withMargin: false,
      },
    },
  ];
}

import {FormNameEnum} from 'filters-selections';
import {RolesEnum} from 'permissions';
import {IFetchUserActivityQueryParams} from '../models';

export const mapUserActivityExport = (from: Date, to: Date, roleList: RolesEnum[]): IFetchUserActivityQueryParams => {
  return {
    [FormNameEnum.from]: from.toISOString().substring(0, 10),
    [FormNameEnum.to]: to.toISOString().substring(0, 10),
    [FormNameEnum.role]: roleList,
  };
};

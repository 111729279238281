import React, {FC, ReactNode} from 'react';
import {Text} from 'components-lib';

interface IActiveHeaderProps {
  isActive: boolean;
  children: ReactNode;
}

export const ActiveHeader: FC<IActiveHeaderProps> = ({isActive, children}) => {
  return (
    <Text.Heading as="h4" color={isActive ? 'primary' : 'inherit'}>
      {children}
    </Text.Heading>
  );
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {userApi} from 'api';
import {IFetchUserPayload} from 'models';
import {paths} from 'paths';
import {prefix} from './config';

export const fetchUserIdThunk: any = createAsyncThunk<number, IFetchUserPayload>(
  `${prefix} fetchUserId`,
  async (payload: IFetchUserPayload, {rejectWithValue}) => {
    try {
      const {parsedBody} = await userApi.getUserIdByUserEmail(payload.email);
      return parsedBody;
    } catch (err) {
      const {push} = payload;
      push(paths.error);
      return rejectWithValue(err);
    }
  }
);

import * as Yup from 'yup';
import {FormNameEnum} from 'enums/form';
import {IProgramClassReportMenuListOption} from 'models';

export const levelReportingValidationSchema = Yup.object().shape({
  [FormNameEnum.selectedId]: Yup.number().nullable(),
  [FormNameEnum.searchTerm]: Yup.string(),
  [FormNameEnum.menuItems]: Yup.array().of(Yup.mixed()),
  [FormNameEnum.filteredMenuItems]: Yup.array().of(Yup.mixed()),
});

export interface ILevelReportingFormBaseValues {
  [FormNameEnum.selectedId]: number | null;
  [FormNameEnum.searchTerm]: string;
  [FormNameEnum.menuItems]: IProgramClassReportMenuListOption[];
  [FormNameEnum.filteredMenuItems]: IProgramClassReportMenuListOption[];
}

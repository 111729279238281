import {createSlice} from '@reduxjs/toolkit';
import {itemsImportInitialState} from './itemsImport.initialState';
import {itemsImportReducer} from './itemsImport.reducer';
import {itemsImportExtraReducer} from './itemsImportExtra.reducer';

export const itemsImportSlice = createSlice({
  name: 'itemsImport',
  initialState: itemsImportInitialState,
  reducers: itemsImportReducer,
  extraReducers: itemsImportExtraReducer,
});

export const {actions: itemsImportActions} = itemsImportSlice;

import {createAsyncThunk} from '@reduxjs/toolkit';
import {itemsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {itemsMessages} from '../../utils';
import {prefix} from './config';

export const promoteItemAnswerStackThunk: any = createAsyncThunk<any, number>(
  `${prefix} promoteItemAnswerStack`,
  async (answerStackId: number, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await itemsApi.promoteItemAnswerStack(answerStackId);
      dispatch(toastNotificationManager.createSuccessToastAction(itemsMessages.promoteAnswerStackSuccess));
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

import {fetchAudienceThunk} from 'filters-selections';
import {FormNameEnum} from 'filters-selections/enums';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {filtersAudiencesEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IAudienceSelectProps extends IWithDisabled {}

export function AudienceSelect({disabled}: IAudienceSelectProps) {
  const dispatch = useDispatch();
  const audiences = useSelector(filtersAudiencesEntitiesCollectionSelector);

  useEffect(() => {
    if (!audiences.length) {
      dispatch(fetchAudienceThunk());
    }
  }, [audiences.length, dispatch]);

  const options = useMemo(() => audiences.map((audience) => ({value: audience.id, label: audience.displayText})), [
    audiences,
  ]);

  return (
    <ItemWithPlaceholder label="Audience" hasItems={!!audiences.length}>
      <Form.Select name={FormNameEnum.audience} options={options} disabled={disabled} />
    </ItemWithPlaceholder>
  );
}

import {ChangeEvent, useCallback, useRef} from 'react';
import {useFormikContext} from 'formik';
import {isFunction} from 'utils/functions';

export const useDragAndDrop = (name: string, deleteCallback?: () => void) => {
  const {values, setFieldValue, errors} = useFormikContext<any>();

  const fileInputRef = useRef(null);
  const file = values[name];
  const error = errors[name] as string;

  const addFileClickHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newFile = event.target.files[0];
      setFieldValue(name, newFile);
    },
    [setFieldValue, name]
  );

  const deleteClickHandler = useCallback(() => {
    setFieldValue(name, null);
    isFunction(deleteCallback) && deleteCallback();
  }, [setFieldValue, name, deleteCallback]);

  const openFileDialogClickHandler = useCallback(() => !!fileInputRef.current && fileInputRef.current.click(), [
    fileInputRef,
  ]);

  return {file, error, fileInputRef, addFileClickHandler, deleteClickHandler, openFileDialogClickHandler};
};

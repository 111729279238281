import React, {Dispatch, FC, SetStateAction, useCallback, useContext, useMemo} from 'react';
import {AddResourcesActionButtons, AddSessionAdditionalContent, AddSessionInstrumentsForm} from '..';
import {Drawer} from 'components-lib';
import {FormContext} from 'forms/formik/FormContextProvider';

interface IAddSessionInstrumentsProps {
  id: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const AddSessionInstruments: FC<IAddSessionInstrumentsProps> = ({id, isOpen, setIsOpen}) => {
  const {formikFormRef, setIsDirty, isDirty, submitFormikForm, resetIsDirty} = useContext(FormContext);

  const submitFormClickHandler = useCallback(() => formikFormRef?.current && submitFormikForm(), [
    formikFormRef,
    submitFormikForm,
  ]);

  const closeDrawerClickHandler = useCallback(() => setIsOpen(false), [setIsOpen]);

  const addResourcesActionButtons = useMemo(
    () => (
      <AddResourcesActionButtons
        isResource={false}
        confirmButtonText="Add Instruments"
        closeClickHandler={closeDrawerClickHandler}
        submitClickHandler={submitFormClickHandler}
        disableSubmit={!isDirty}
      />
    ),
    [closeDrawerClickHandler, submitFormClickHandler, isDirty]
  );

  return (
    <Drawer
      title="Add Instruments"
      confirmButtonTitle="Add Instruments"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      actionButtons={addResourcesActionButtons}
    >
      <AddSessionInstrumentsForm
        id={id}
        formikFormRef={formikFormRef}
        resetIsDirty={resetIsDirty}
        closeDrawerClickHandler={closeDrawerClickHandler}
      >
        <AddSessionAdditionalContent isResource={false} isDirty={isDirty} setIsDirtyCallback={setIsDirty} />
      </AddSessionInstrumentsForm>
    </Drawer>
  );
};

import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/core';
import {Column, Container, Row} from 'components-lib';
import {layoutConfig} from 'layout';
import {FiltersHeader} from './FiltersHeader';

interface IFiltersPanelProps {
  clearButtonClickHandler: () => void;
  filters: ReactNode;
  searchFieldName?: string;
  customSearch?: ReactNode;
  disableAll?: boolean;
}

export function FiltersPanel({
  filters,
  searchFieldName,
  clearButtonClickHandler,
  customSearch,
  disableAll,
}: IFiltersPanelProps) {
  const classes = useStyles();
  return (
    <Container classes={{root: classes.root}}>
      <Column>
        <FiltersHeader
          searchFieldName={searchFieldName}
          clearButtonClickHandler={clearButtonClickHandler}
          customSearch={customSearch}
          disabled={disableAll}
        />
        <Row justify="flex-start" align="flex-start" classes={{root: classes.scrollableContainer}}>
          <div>{filters}</div>
        </Row>
      </Column>
    </Container>
  );
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      overflow: 'hidden',
      paddingLeft: theme.spacing(2) + theme.spacing(3), // 24px
      paddingRight: theme.spacing(2) + theme.spacing(3), // 24px
    },
    scrollableContainer: {
      height: `calc(100vh - ${layoutConfig.scrollableAreaSubtrahend + 190}px)`,
      paddingRight: theme.spacing(1) + theme.spacing(2), // 12px
      paddingTop: theme.spacing(2),
      overflowY: 'auto',
      '& > div': {
        width: '100%',
      },
    },
  }),
  // Webpack production build causes CSS to be injected in different order
  // See: https://github.com/mui-org/material-ui/issues/18919
  // index - 0 by default - determines DOM rendering order, higher number = higher specificity (inserted after).
  {
    index: 1,
  }
);

import React, {ChangeEvent} from 'react';
import {FormControlLabel, Switch as SwitchMui} from '@material-ui/core';

interface ISwitchProps {
  name?: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  changeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function Switch({checked, name, label, disabled, changeHandler}: ISwitchProps) {
  return (
    <FormControlLabel
      control={<SwitchMui disabled={disabled} checked={checked} onChange={changeHandler} color="primary" name={name} />}
      label={label}
    />
  );
}

import {ResourceTypeEnum} from 'admin/resources/enums';
import {getFileName, recognizeFillablePdf, recognizeTypeByFileMimeType} from '.';

export const getFileType = (fileType: string, fileFullName: string) => {
  let mappedType = recognizeTypeByFileMimeType(fileType);
  const fileName = getFileName(fileFullName);
  const isFillablePdf = mappedType === ResourceTypeEnum.PDF && recognizeFillablePdf(fileName);

  if (isFillablePdf) {
    mappedType = ResourceTypeEnum.FillablePDF;
  }

  return mappedType;
};

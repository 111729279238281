import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {UploadResourceFile} from './file/UploadResourceFile';
import {UploadResourceScorm} from './scorm/UploadResourceScorm';
import {UploadResourceWebLink} from './web-link/UploadResourceWebLink';
import {pages} from 'paths';

export const UploadResources = () => {
  return (
    <Switch>
      <Route
        exact
        path={pages.adminPortal.upload.root}
        render={() => <Redirect to={pages.adminPortal.upload.file} />}
      />
      <Route path={pages.adminPortal.upload.file} component={UploadResourceFile} />
      <Route path={pages.adminPortal.upload.scorm} component={UploadResourceScorm} />
      <Route path={pages.adminPortal.upload.webLink} component={UploadResourceWebLink} />
    </Switch>
  );
};

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Accordion, Column, Text} from 'components-lib';
import {fetchProgramYearsThunk} from 'filters-selections';
import {FormNameEnum} from 'filters-selections/enums';
import {filtersYearsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {IWithDisabled} from 'models';

export function ProgramYearCheckboxFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const programYears = useSelector(filtersYearsEntitiesCollectionSelector);

  useEffect(() => {
    if (!programYears.length) {
      dispatch(fetchProgramYearsThunk());
    }
  }, [dispatch, programYears]);

  return (
    <Accordion heading="Program Year">
      <Column>
        {programYears.length ? (
          programYears.map((programYear) => (
            <Form.Checkbox
              key={programYear.id}
              name={FormNameEnum.years}
              value={programYear.id}
              label={programYear.displayText}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>Loading...</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
}

import {Accordion, Column, Text} from 'components-lib';
import {Form} from 'forms';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormNameEnum} from '../../../enums';
import {fetchItemTypesThunk, filtersItemTypesEntitiesCollectionSelector} from '../../../store';
import {IWithDisabled} from 'models';

export function ItemTypeFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const itemTypes = useSelector(filtersItemTypesEntitiesCollectionSelector);

  useEffect(() => {
    if (!itemTypes.length) {
      dispatch(fetchItemTypesThunk());
    }
  }, [itemTypes.length, dispatch]);

  return (
    <Accordion heading="Item Type">
      <Column>
        {itemTypes.length ? (
          itemTypes.map((itemType) => (
            <Form.Checkbox
              key={itemType.id}
              name={FormNameEnum.itemType}
              value={itemType.id}
              label={itemType.displayText}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>No Data</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
}

import React, {useEffect, useMemo} from 'react';
import {FormNameEnum} from 'filters-selections/enums';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {Form} from 'forms';
import {IOption, IWithDisabled} from 'models';
import {useDispatch, useSelector} from 'react-redux';
import {fetchInstrumentMainTypesThunk, filtersInstrumentMainTypesEntitiesCollectionSelector} from 'filters-selections';

type IInstrumentTypeSelectProps = IWithDisabled;

export function InstrumentTypeSelect({disabled}: IInstrumentTypeSelectProps) {
  const dispatch = useDispatch();
  const mainTypes = useSelector(filtersInstrumentMainTypesEntitiesCollectionSelector);

  useEffect(() => {
    if (!mainTypes.length) {
      dispatch(fetchInstrumentMainTypesThunk());
    }
  }, [mainTypes.length, dispatch]);

  const options: IOption[] = useMemo(
    () =>
      mainTypes.map((type) => ({
        value: type.id,
        label: type.displayText,
      })),
    [mainTypes]
  );

  return (
    <ItemWithPlaceholder label="Type" hasItems={!!mainTypes.length}>
      <Form.Select name={FormNameEnum.instrumentType} options={options} disabled={disabled} />
    </ItemWithPlaceholder>
  );
}

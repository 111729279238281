import React, {FC, useCallback, useEffect, useState} from 'react';
import {ContentState, EditorState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface IRichEditorProps {
  htmlStr: string;
}

export const PreviewEditor: FC<IRichEditorProps> = ({htmlStr}) => {
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  const getDraftFromHTML = useCallback((htmlStr) => {
    const draft = htmlToDraft(htmlStr);
    const contentState = ContentState?.createFromBlockArray(draft?.contentBlocks);
    return EditorState.createWithContent(contentState);
  }, []);

  useEffect(() => {
    if (htmlStr) {
      const editorState = getDraftFromHTML(htmlStr);
      const newState = htmlStr ? editorState : EditorState.createEmpty();
      setEditorState(newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Editor readOnly toolbarHidden editorState={editorState} />;
};

import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Container} from 'components-lib';
import {ItemWithPlaceholder} from 'filters-selections';
import {Form} from 'forms';
import {IWithDisabled} from 'models/common';

interface IResourceDatePickerProps extends IWithDisabled {
  label: string;
  name: string;
  value?: any;
  maxWidth?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
}

export const ResourcesDatePicker: FC<IResourceDatePickerProps> = ({
  label,
  name,
  value = null,
  disabled = false,
  maxWidth = true,
  disablePast = false,
  disableFuture = false,
}) => {
  const classes = useStyles();

  return (
    <Container disableGutters classes={{root: classes.resourceDatePicker}}>
      <ItemWithPlaceholder label={label} hasItems>
        <Form.DatePicker
          name={name}
          label=""
          value={value}
          disabled={disabled}
          maxWidth={maxWidth}
          disablePast={disablePast}
          disableFuture={disableFuture}
          format="MM/dd/yyyy"
        />
      </ItemWithPlaceholder>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  resourceDatePicker: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

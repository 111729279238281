import {useAppDispatch} from 'hooks';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useInstruments} from '../../hooks';
import {
  builderPoolItemsEntitiesBaseCollectionSelector,
  builderPossiblePretestEntitiesBaseCollectionSelector,
  fetchMatrixRelatedPoolItemsThunk,
  fetchPoolItemsThunk,
  fetchPossiblePretestItemsThunk,
  instrumentsActions,
  instrumentsBuilderFilterSelector,
  searchTextSelector,
} from '../../store';
import {useBuilderPoolItemsEntities} from './useBuilderPoolItemsEntities';
import {instrumentsBuilderFiltersEntitiesSelector} from 'filters-selections';
import {mapBuilderFilterPayload} from '../utils';

export function useBuilderState() {
  const dispatch = useAppDispatch();
  const {pageLoading, poolItemsLoading} = useInstruments();
  const {builderPoolItemsEntities} = useBuilderPoolItemsEntities();

  const builderPoolBaseItems = useSelector(builderPoolItemsEntitiesBaseCollectionSelector);
  const builderPossiblePretestItems = useSelector(builderPossiblePretestEntitiesBaseCollectionSelector);
  const searchText = useSelector(searchTextSelector);
  const builderFilter = useSelector(instrumentsBuilderFilterSelector);
  const builderFilterEntities = useSelector(instrumentsBuilderFiltersEntitiesSelector);

  const getPoolItems = useCallback(
    (pageNumber = 1, builderFilter = initialBuilderFilter, setSubmitting?: (isSubmitting: boolean) => void) => {
      const filter = mapBuilderFilterPayload(builderFilter);
      dispatch(fetchPoolItemsThunk({pageNumber, builderFilter: filter, setSubmitting}));
    },
    [dispatch]
  );

  const getPossiblePretestItems: (templateId: string) => void = useCallback(
    (templateId) => {
      dispatch(fetchPossiblePretestItemsThunk(templateId));
    },
    [dispatch]
  );

  const clearState: () => void = useCallback(() => dispatch(instrumentsActions.clear()), [dispatch]);

  const clearPoolItems: () => void = useCallback(() => dispatch(instrumentsActions.clearPoolItems()), [dispatch]);

  const getMatrixRelatedPoolItems = useCallback(
    (defaultAnswerStackId, pageNumber = 1, builderFilter = initialBuilderFilter, searchText = '', setSubmitting?) => {
      const filter = !!builderFilter ? mapBuilderFilterPayload(builderFilter) : initialBuilderFilter;

      dispatch(
        fetchMatrixRelatedPoolItemsThunk({
          defaultAnswerStackId,
          pageNumber,
          builderFilter: filter,
          searchText: searchText ? searchText.trim() : '',
          setSubmitting,
        })
      );
    },
    [dispatch]
  );

  const setSearch: (text: string) => void = useCallback(
    (text: string) => dispatch(instrumentsActions.setSearch(text)),
    [dispatch]
  );

  const setFilterInstrumentsAction = useCallback(
    (filter) => {
      const updatedFilter = {...filter};
      delete updatedFilter.search;
      dispatch(instrumentsActions.setFilterInstruments(updatedFilter));
    },
    [dispatch]
  );

  const setBuilderFilterInstruments = useCallback(
    (filter: any) => {
      dispatch(instrumentsActions.setBuilderFilterInstruments(filter));
    },
    [dispatch]
  );

  const resetBuilderFilter: () => void = useCallback(
    () => dispatch(instrumentsActions.resetBuilderFilterInstruments()),
    [dispatch]
  );

  const resetTemporaryBuilderItems: () => void = useCallback(
    () => dispatch(instrumentsActions.resetTemporaryBuilderItems()),
    [dispatch]
  );

  const setTemporaryBuilderItems: () => void = useCallback(
    () => dispatch(instrumentsActions.setTemporaryBuilderItems()),
    [dispatch]
  );

  const setBuilderItems: () => void = useCallback(() => dispatch(instrumentsActions.setBuilderItems()), [dispatch]);

  return {
    initialBuilderFilter,
    builderFilter,
    builderFilterEntities,
    builderPoolBaseItems,
    builderPossiblePretestItems,
    builderPoolItemsEntities,
    pageLoading,
    poolItemsLoading,
    searchText,

    getPoolItems,
    getMatrixRelatedPoolItems,
    getPossiblePretestItems,
    clearPoolItems,
    clearState,
    setSearch,
    setFilterInstrumentsAction,
    setBuilderFilterInstruments,
    resetBuilderFilter,
    resetTemporaryBuilderItems,
    setTemporaryBuilderItems,
    setBuilderItems,
  };
}

export const initialBuilderFilter = {
  searchText: '',
  areas: [],
  pillars: [],
  evaluationType: [],
  domain: [],
  programs: [],
  category: '',
  learningObjective: '',
  concept: [],
  competency: [],
  administration: [],
  schoolLevel: [],
};

import React, {memo, useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import {AdminNavMoreActionsButton, getAdminToolsOptions, ModalUserActivity, UploadManager} from 'admin';
import {AdminRoles, AllRolesWithoutStudent, usePermissions, withRole} from 'permissions';
import {useModal, useNavigate} from 'hooks';
import {pages} from 'paths';
import {NavigationLink, Row, Wrapper} from 'components-lib';
import {ButtonDropdownWithSuperAdminPermissions} from '../ButtonsWithPermissions/ButtonWithAdminPermissions';
import {ModalUserActivityForm} from '../ModalUserActivity/ModalUserActivityForm';

const AdminNavEnhanced = withRole(AllRolesWithoutStudent, '')(AdminNav);

export function AdminPortalNavigation() {
  return <AdminNavEnhanced />;
}

function AdminNav() {
  const classes = useStyles();
  const {push} = useNavigate();
  const {hasPermission} = usePermissions();
  const {isOpen: isUserActivityModalOpen, setIsOpen: setIsUserActivityModalOpen} = useModal();

  const shouldRenderLibraryLink = hasPermission(AdminRoles);

  const handleOpenUserActivityModal = useCallback(() => setIsUserActivityModalOpen(true), [setIsUserActivityModalOpen]);
  const handleIntegrations = useCallback(() => push(pages.adminPortal.integrations.all), [push]);
  const handleImportItems = useCallback(() => push(pages.adminPortal.itemImport.root), [push]);
  const handleLogInAs = useCallback(() => null, []);

  const adminToolsOptions = getAdminToolsOptions(
    handleOpenUserActivityModal,
    handleIntegrations,
    handleImportItems,
    handleLogInAs
  );

  return (
    <Row justify="flex-end">
      <Row justify="flex-end" align="center">
        <AdminNavMoreActionsButton />
        <Wrapper className={classes.content}>
          <NavigationLink to={pages.adminPortal.resources}>Resources</NavigationLink>
          <NavigationLink to={pages.adminPortal.programs.root}>Programs</NavigationLink>
          {shouldRenderLibraryLink && <NavigationLink to={pages.adminPortal.library}>Library</NavigationLink>}
        </Wrapper>
        <ButtonDropdownWithSuperAdminPermissions items={adminToolsOptions} text="Admin tools" withText />
        <UploadManager />
      </Row>
      <ModalUserActivityForm setIsOpen={setIsUserActivityModalOpen}>
        <ModalUserActivity isOpen={isUserActivityModalOpen} setIsOpen={setIsUserActivityModalOpen} />
      </ModalUserActivityForm>
    </Row>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default memo(AdminPortalNavigation);

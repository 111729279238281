import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const unPublishSessionInstrumentThunk: any = createAsyncThunk<undefined, number>(
  `${prefix} unPublishSessionInstrument`,
  async (templateId: number, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await instrumentsApi.unPublishSessionInstrument(templateId);
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

export enum FiltersEnum {
  ProgramSingle,
  ProgramMulti,
  ProgramYear,
  ProgramYearCheckbox,
  ProgramYearMulti,
  GradeLevel,
  Audience,
  Phase,
  Pillar,
  Metadata,
  Areas,
  Educators,
  Districts,
  Schools,
  FromToDate,
  InstrumentType,
  InstrumentStatus,
  EvaluationType,
  Domain,
  Competency,
  Competencies,
  Concept,
  Category,
  Administration,
  SchoolLevel,
  ItemType,
  ItemStatus,
  InstrumentViewType,
  Session,
  PillarMulti,
  DigitalResourcesConcepts,
  KeyTerms,
  LearningObjectives,
  SkillsAndBehaviors,
  Languages,
  TagGradeLevels,
  DigitalResourceTypes,
  DrmExpirationDate,
  LearningObjective,
  LearningObjectiveWithDomainDependency,
  Search,
  IntegrationTypeSingle,
  IntegrationTypeMulti,
  IntegrationStatusMulti,
  SchoolNameMulti,
  SchoolDistrictMulti,
  AreasAutocompleteMulti,
  ClassesOnlyVirtual,
  FileType,
  FileTypeMulti,
  SchoolNameBasedOnAreaAndDistrict,
  LearningPathStatusesCheckbox,
  KnowledgeSkillsAndAbilities,
  AccessibleToStudentsRadio,
  PublishedByRadio,
  AreasMultiAutocompleteExternalData,
  FileStatusesCheckbox,
}

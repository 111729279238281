import React, {FC, Fragment, useCallback} from 'react';
import {ResourceFooterButtons} from 'admin/resources';
import {useFileEdit} from '../../hooks';
import {useModal} from 'hooks';
import {ModalDelete} from 'admin/components';
import {useFormikContext} from 'formik';
import {IEditFileMetadataFormBaseValues} from '../FilesEditResourceForm/config';

export const FilesEditFooter: FC = () => {
  const {loading, deleteFile, fileDetails} = useFileEdit();
  const {isOpen: isModalDeleteResourceOpen, setIsOpen: setIsModalDeleteResourceOpen} = useModal();

  const {values} = useFormikContext<IEditFileMetadataFormBaseValues>();
  const shouldDisableButtons = !fileDetails?.canEdit;
  const submitButtonText = values?.file ? 'Reupload' : 'Save';

  const confirmHandler = useCallback(() => deleteFile(), [deleteFile]);
  const openDeleteModalClickHandler = useCallback(() => setIsModalDeleteResourceOpen(true), [
    setIsModalDeleteResourceOpen,
  ]);

  return (
    <Fragment>
      <ResourceFooterButtons
        loading={loading}
        submitButtonText={submitButtonText}
        withSubmitButton
        withDeleteButton
        withCancelButton={false}
        disableSubmit={shouldDisableButtons}
        disableDelete={shouldDisableButtons}
        deleteClickHandler={openDeleteModalClickHandler}
      />
      <ModalDelete
        loading={false}
        title="Delete Resource"
        text="Are you sure you want to delete this resource?"
        cancelButtonText="No"
        confirmButtonText="Yes"
        isOpen={isModalDeleteResourceOpen}
        setIsOpen={setIsModalDeleteResourceOpen}
        confirmHandler={confirmHandler}
      />
    </Fragment>
  );
};

import React, {Fragment, ReactNode, useCallback, useEffect, useMemo} from 'react';
import {batch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
  ActionsBuilderView,
  Builder,
  BuilderInstructionalTextForm,
  MatrixEditExtraHeader,
  ModalInfoLinkItem,
  ModalLinkItems,
  ModalLinkItemsForm,
  PoolItemsList,
  PoolItemsMatrixList,
  SidebarBuilderView,
} from '../components';
import {Column, PageViewHeader, Tag, Loading} from 'components-lib';
import {BuilderMatrix} from '../components/BuilderMatrix/BuilderMatrix';
import {PageLayoutThreeCol, PageLayoutWithFixedAreas} from 'layout';
import {BuilderEditModeEnum} from '../enums';
import {InstrumentsFilterNamesEnum} from '../../enums';
import {QuestionPropertiesType} from 'enums';
import {useModal} from 'hooks';
import {useInstrument} from '../../details/hooks';
import {useInstrumentItem} from '../../hooks';
import {useBuilder, useBuilderEditMode, useBuilderState, usePoolItemsPagination} from '../hooks';
import {cloneDeep} from 'utils';
import {getChildQuestion, getFilterList, getFilterToFilterNameMap, isMatrixQuestion, mapBuilderFilter} from '../utils';
import {IInstrumentsAllFilter} from 'models';
import {ISingleBuilderFilter} from '../models';

export function InstrumentBuilderView() {
  const urlParams = useParams<{id: string}>();
  const {
    builderEditMode,
    setBuilderEditMode,
    isAllTypesMode,
    isEditMatrixMode,
    isEditInstructionalText,
    defaultBuilderEditModeAllTypes,
  } = useBuilderEditMode();

  const {
    instrumentId,
    builderItems,
    addBuilderItems,
    moveBuilderItem,
    deleteBuilderItem,
    linkBuilderItem,
    addMatrixRow,
    moveMatrixRow,
    deleteMatrixRow,
  } = useBuilder();

  const {
    searchText: search,
    builderFilter,
    builderFilterEntities,
    initialBuilderFilter,
    builderPossiblePretestItems,
    builderPoolBaseItems,
    pageLoading,
    poolItemsLoading,

    getPossiblePretestItems,
    getPoolItems,
    getMatrixRelatedPoolItems,
    clearState,
    setSearch,
    setBuilderFilterInstruments,
    clearPoolItems,
    setTemporaryBuilderItems,
  } = useBuilderState();

  const {fetchInstrumentById, activeTemplate} = useInstrument(instrumentId);
  const {currentItem: currentBuilderItem, setCurrentItem: setCurrentBuilderItem} = useInstrumentItem();
  const {initialPage, currentPage, setCurrentPage, hasMorePages} = usePoolItemsPagination();

  const {isOpen: isModalLinkItemsOpen, setIsOpen: setIsModalLinkItemsOpen} = useModal();
  const handleOpenModalLink = useCallback(() => setIsModalLinkItemsOpen(true), [setIsModalLinkItemsOpen]);
  const handleCloseModalLink = useCallback(() => setIsModalLinkItemsOpen(false), [setIsModalLinkItemsOpen]);

  const {isOpen: isInfoLinkItemModalOpen, setIsOpen: setIsInfoLinkItemModalOpen} = useModal();
  const handleOpenInfoLinkModal = useCallback(() => setIsInfoLinkItemModalOpen(true), [setIsInfoLinkItemModalOpen]);

  useEffect(() => {
    getPoolItems(initialPage);
    setCurrentPage(initialPage + 1);
    if (!activeTemplate) {
      fetchInstrumentById(instrumentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPage, instrumentId]);

  useEffect(() => {
    if (!builderPossiblePretestItems.length) {
      getPossiblePretestItems(urlParams.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPossiblePretestItems, urlParams.id]);

  useEffect(() => {
    return () => {
      clearState();
    };
  }, [clearState]);

  const unlinkClickHandler = useCallback(() => {
    const isMatrix = isMatrixQuestion(currentBuilderItem?.type);
    const updated = cloneDeep(currentBuilderItem);
    const childQuestion = getChildQuestion(updated, isMatrix);

    if (isMatrix) {
      // Check if a child question id exist and update its linkedQuestionAnswerStackId
      if (childQuestion) childQuestion[QuestionPropertiesType.linkedQuestionAnswerStackId] = null;
    } else {
      updated[QuestionPropertiesType.linkedQuestionAnswerStackId] = null;
    }

    batch(() => {
      linkBuilderItem(updated);
      handleCloseModalLink();
    });
  }, [currentBuilderItem, linkBuilderItem, handleCloseModalLink]);

  const getBuilderPoolItems = useCallback(
    (isEditMatrixMode = false, withInitialItems = false, initialPage, builderFilter, search, actions?) => {
      const text = withInitialItems ? '' : search;

      if (isEditMatrixMode) {
        return getMatrixRelatedPoolItems(
          builderEditMode.defaultAnswerStackId,
          initialPage,
          builderFilter,
          text,
          actions?.setSubmitting
        );
      }

      return getPoolItems(initialPage, builderFilter, actions?.setSubmitting);
    },
    [builderEditMode, getMatrixRelatedPoolItems, getPoolItems]
  );

  const searchSubmitHandler = useCallback(
    (
      {
        search = '',
        areas,
        pillars,
        evaluationType,
        domain,
        programs,
        category,
        learningObjective,
        competency,
        concept,
        administration,
        schoolLevel,
      },
      actions
    ) => {
      const builderFilter = mapBuilderFilter(
        search,
        areas,
        pillars,
        evaluationType,
        domain,
        programs,
        category,
        learningObjective,
        concept,
        competency,
        administration,
        schoolLevel
      );

      // This guarantees that the search will always be reset and the infinite scroll will work
      setSearch(search);
      clearPoolItems();
      setBuilderFilterInstruments(builderFilter);

      if (search && search.length > 0) {
        return getBuilderPoolItems(isEditMatrixMode, false, initialPage, builderFilter, search, actions);
      }

      // Go back to initial items
      getBuilderPoolItems(isEditMatrixMode, true, initialPage, builderFilter, search, actions);
      setCurrentPage(initialPage + 1);
    },
    [
      setSearch,
      setBuilderFilterInstruments,
      clearPoolItems,
      initialPage,
      setCurrentPage,
      isEditMatrixMode,
      getBuilderPoolItems,
    ]
  );

  const loadMoreHandler = useCallback(() => {
    isEditMatrixMode
      ? getMatrixRelatedPoolItems(builderEditMode.defaultAnswerStackId, currentPage)
      : !poolItemsLoading && getPoolItems(currentPage);

    hasMorePages && setCurrentPage(currentPage + 1);
  }, [
    poolItemsLoading,
    builderEditMode.defaultAnswerStackId,
    currentPage,
    getMatrixRelatedPoolItems,
    getPoolItems,
    hasMorePages,
    isEditMatrixMode,
    setCurrentPage,
  ]);

  const sidebar = useMemo(() => <SidebarBuilderView submitHandler={searchSubmitHandler} disableAll={pageLoading} />, [
    searchSubmitHandler,
    pageLoading,
  ]);

  const appliedFilters = useMemo(() => {
    const chips: ReactNode[] = [];
    const filterList = getFilterList();
    const filterToFilterNameMap = getFilterToFilterNameMap();

    const resetDropdownFilter = (propName: string, id: string | number) => {
      const updated: IInstrumentsAllFilter = {
        ...builderFilter,
      };
      updated[propName] = updated[propName].filter((pId: number) => pId !== id);

      batch(() => {
        setBuilderFilterInstruments(updated);
        clearPoolItems();
      });

      getBuilderPoolItems(isEditMatrixMode, false, initialPage, updated, search);
    };

    filterList.forEach((instrumentFilter) => {
      const mappedFilterName: string = filterToFilterNameMap[instrumentFilter];
      const current: ISingleBuilderFilter = builderFilter[mappedFilterName];

      if (current instanceof Array && current.length > 0) {
        current.forEach((id) => {
          const currentFilter = builderFilterEntities[mappedFilterName][id];

          chips.push(
            <Tag
              withFilterChip
              key={`key-${currentFilter.id}-${currentFilter.name}}`}
              label={currentFilter.name}
              deleteHandler={() => resetDropdownFilter(mappedFilterName, currentFilter.id)}
              withLeftMargin={false}
            />
          );
        });
      }

      if (typeof current === 'string' && !!current) {
        const deleteStringFilter = () => {
          const updated = {...builderFilter, [mappedFilterName]: ''};

          if (mappedFilterName === InstrumentsFilterNamesEnum.SEARCH) {
            setSearch('');
          }
          setBuilderFilterInstruments(updated);

          isEditMatrixMode
            ? getMatrixRelatedPoolItems(builderEditMode.defaultAnswerStackId, initialPage, updated, search)
            : getPoolItems(initialPage, updated);
        };

        chips.push(
          <Tag
            withFilterChip
            key={builderFilter[mappedFilterName]}
            label={current}
            deleteHandler={deleteStringFilter}
            withLeftMargin={false}
          />
        );
      }
    });

    return chips;
  }, [
    search,
    initialPage,
    builderFilter,
    builderFilterEntities,
    builderEditMode,
    isEditMatrixMode,
    clearPoolItems,
    getBuilderPoolItems,
    getMatrixRelatedPoolItems,
    getPoolItems,
    setBuilderFilterInstruments,
    setSearch,
  ]);

  const header = useMemo(
    () => (
      <Column>
        <PageViewHeader heading="Items" filters={appliedFilters} withDivider={false} />
        {isEditMatrixMode && <MatrixEditExtraHeader id={builderEditMode.questionAnswerStackId} />}
      </Column>
    ),
    [builderEditMode.questionAnswerStackId, isEditMatrixMode, appliedFilters]
  );

  const contentMiddle = useMemo(() => {
    const morePages = (!search || search.length === 0) && hasMorePages;

    return (
      <Fragment>
        {pageLoading ? (
          <Loading />
        ) : (
          <PageLayoutWithFixedAreas headerSize="large" header={header}>
            {isAllTypesMode && (
              <PoolItemsList
                hasMorePages={morePages}
                loading={poolItemsLoading || pageLoading}
                builderItems={builderItems}
                builderItemsBase={builderPoolBaseItems}
                dropHandler={addBuilderItems}
                loadMoreHandler={loadMoreHandler}
              />
            )}
            {isEditMatrixMode && (
              <PoolItemsMatrixList
                parentQuestionId={builderEditMode.questionAnswerStackId}
                hasMorePages={morePages}
                loading={poolItemsLoading || pageLoading}
                builderItemsBase={builderPoolBaseItems}
                loadMoreHandler={loadMoreHandler}
                dropHandler={addMatrixRow(builderEditMode.questionAnswerStackId)}
              />
            )}
          </PageLayoutWithFixedAreas>
        )}
      </Fragment>
    );
  }, [
    addBuilderItems,
    addMatrixRow,
    builderEditMode.questionAnswerStackId,
    builderItems,
    builderPoolBaseItems,
    hasMorePages,
    header,
    loadMoreHandler,
    poolItemsLoading,
    search,
    isAllTypesMode,
    isEditMatrixMode,
    pageLoading,
  ]);

  const contentEndHeader = useMemo(
    () => (
      <PageViewHeader
        action={
          <ActionsBuilderView
            isEditMatrixMode={isEditMatrixMode}
            isEditInstructionalText={isEditInstructionalText}
            setBuilderEditMode={setBuilderEditMode}
            loading={pageLoading}
          />
        }
      />
    ),
    [isEditMatrixMode, isEditInstructionalText, setBuilderEditMode, pageLoading]
  );

  const editClickHandler = useCallback(
    (questionAnswerStackId: number, defaultAnswerStackId: number, position: number) => {
      // Set temporary builder item list
      setTemporaryBuilderItems();

      setBuilderEditMode({
        questionAnswerStackId,
        defaultAnswerStackId,
        position,
        mode: BuilderEditModeEnum.matrix,
      });

      // This guarantees that the search and builder filter will always be reset in edit matrix mode
      setSearch('');
      clearPoolItems();
      setBuilderFilterInstruments(initialBuilderFilter);

      getMatrixRelatedPoolItems(defaultAnswerStackId, initialPage);
    },
    [
      getMatrixRelatedPoolItems,
      initialPage,
      setBuilderEditMode,
      clearPoolItems,
      setBuilderFilterInstruments,
      setSearch,
      initialBuilderFilter,
      setTemporaryBuilderItems,
    ]
  );

  const editInstructionalTextClickHandler = useCallback(
    (questionAnswerStackId: number, defaultAnswerStackId: number, position: number) => {
      setBuilderEditMode({
        questionAnswerStackId,
        defaultAnswerStackId,
        position,
        mode: BuilderEditModeEnum.instructionalText,
      });
    },
    [setBuilderEditMode]
  );

  const applyClickHandler = useCallback(() => {
    setSearch('');
    clearPoolItems();
    setBuilderFilterInstruments(initialBuilderFilter);
    getPoolItems(initialPage, initialBuilderFilter);

    setBuilderEditMode(defaultBuilderEditModeAllTypes);
  }, [
    defaultBuilderEditModeAllTypes,
    setSearch,
    getPoolItems,
    setBuilderEditMode,
    initialPage,
    clearPoolItems,
    initialBuilderFilter,
    setBuilderFilterInstruments,
  ]);

  const applyInstructionalTextClickHandler = useCallback(() => setBuilderEditMode(defaultBuilderEditModeAllTypes), [
    setBuilderEditMode,
    defaultBuilderEditModeAllTypes,
  ]);

  const contentEndBuilder = useMemo(() => {
    return (
      <Fragment>
        {isEditMatrixMode && (
          <BuilderMatrix
            id={builderEditMode.questionAnswerStackId}
            position={builderEditMode.position}
            applyClickHandler={applyClickHandler}
            moveRowHandler={moveMatrixRow}
            deleteRowHandler={deleteMatrixRow}
            setCurrentBuilderItem={setCurrentBuilderItem}
            handleOpenModalLink={handleOpenModalLink}
            handleOpenInfoLinkModal={handleOpenInfoLinkModal}
          />
        )}
        {isAllTypesMode && (
          <Builder
            pageLoading={pageLoading}
            activeTemplate={activeTemplate}
            items={builderItems}
            editClickHandler={editClickHandler}
            editInstructionalTextClickHandler={editInstructionalTextClickHandler}
            moveBuilderItemHandler={moveBuilderItem}
            deleteBuilderItemHandler={deleteBuilderItem}
            setCurrentBuilderItem={setCurrentBuilderItem}
            handleOpenModalLink={handleOpenModalLink}
            handleOpenInfoLinkModal={handleOpenInfoLinkModal}
          />
        )}
        {isEditInstructionalText && (
          <BuilderInstructionalTextForm
            id={builderEditMode.questionAnswerStackId}
            position={builderEditMode.position}
            applyClickHandler={applyInstructionalTextClickHandler}
          />
        )}
      </Fragment>
    );
  }, [
    pageLoading,
    activeTemplate,
    applyClickHandler,
    builderEditMode.position,
    builderEditMode.questionAnswerStackId,
    builderItems,
    deleteBuilderItem,
    deleteMatrixRow,
    editClickHandler,
    isEditMatrixMode,
    isAllTypesMode,
    isEditInstructionalText,
    moveBuilderItem,
    moveMatrixRow,
    handleOpenModalLink,
    handleOpenInfoLinkModal,
    setCurrentBuilderItem,
    editInstructionalTextClickHandler,
    applyInstructionalTextClickHandler,
  ]);

  const contentEnd = useMemo(
    () => (
      <PageLayoutWithFixedAreas headerSize="large" header={contentEndHeader}>
        {pageLoading ? <Loading halfHeight /> : contentEndBuilder}
      </PageLayoutWithFixedAreas>
    ),
    [contentEndBuilder, contentEndHeader, pageLoading]
  );

  return (
    <>
      <PageLayoutThreeCol sidebar={sidebar} contentMiddle={contentMiddle} contentEnd={contentEnd} />
      <ModalLinkItemsForm
        currentBuilderItem={currentBuilderItem}
        linkBuilderItemHandler={linkBuilderItem}
        handleClose={handleCloseModalLink}
      >
        <ModalLinkItems
          isOpen={isModalLinkItemsOpen}
          setIsOpen={setIsModalLinkItemsOpen}
          unlinkHandler={unlinkClickHandler}
        />
      </ModalLinkItemsForm>
      <ModalInfoLinkItem
        linkedQuestionAnswerStackId={currentBuilderItem?.linkedQuestionAnswerStackId}
        isOpen={isInfoLinkItemModalOpen}
        setIsOpen={setIsInfoLinkItemModalOpen}
      />
    </>
  );
}

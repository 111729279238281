import {useSelector} from 'react-redux';
import {
  poolSelector,
  paginationSelector,
  fetchProgramMasterInstrumentsPagedThunk,
  pageLoadingSelector,
  filterSelector,
  orderBySelector,
  programsMasterInstrumentsSortOrderSelector,
} from '../store';
import {useCallback, useState} from 'react';
import {useAppDispatch} from 'hooks';

import {programMasterInstrumentsActions as actions} from '../store/programMasterInstruments.slice';
import {IProgramMasterInstrumentsFilter} from 'models';
import {filtersMasterInstrumentsEntitiesSelector} from 'filters-selections';
import {getIsAscSortOrder, getSortingOptions} from 'utils/common';

export function useProgramMasterInstruments(programId: number) {
  const dispatch = useAppDispatch();
  const loading = useSelector(pageLoadingSelector);
  const instruments = useSelector(poolSelector);
  const filter = useSelector(filterSelector);
  const filterEntities = useSelector(filtersMasterInstrumentsEntitiesSelector);
  const orderBy = useSelector(orderBySelector);
  const {currentPage, perPage, totalCount} = useSelector(paginationSelector);
  const sortOrder = useSelector(programsMasterInstrumentsSortOrderSelector);
  const [isRolloverModalOpen, setIsRolloverModalOpen] = useState<boolean>(false);
  const handleRolloverModalOpen = () => setIsRolloverModalOpen(true);

  const fetchInstruments = useCallback(() => {
    const sortAsc = getIsAscSortOrder(sortOrder?.sort);
    const sortOptions = getSortingOptions(sortOrder?.order, sortAsc);

    return dispatch(
      fetchProgramMasterInstrumentsPagedThunk({
        filter: {...filter, programs: [programId]},
        pagination: {
          pageNumber: currentPage - 1,
          pageSize: perPage,
        },
        sortOptions,
      })
    );
  }, [currentPage, dispatch, filter, perPage, programId, sortOrder]);

  const setFilter = useCallback((filter: IProgramMasterInstrumentsFilter) => dispatch(actions.setFilter(filter)), [
    dispatch,
  ]);
  const resetFilter = useCallback(() => dispatch(actions.resetFilter()), [dispatch]);
  const setCurrentPage = useCallback((newPage: number) => dispatch(actions.setCurrentPage(newPage)), [dispatch]);
  const setPerPage = useCallback((perPage: number) => dispatch(actions.setPerPage(perPage)), [dispatch]);

  return {
    loading,
    filter,
    instruments,
    currentPage,
    perPage,
    totalCount,
    filterEntities,
    orderBy,
    fetchInstruments,
    setCurrentPage,
    setPerPage,
    setFilter,
    resetFilter,
    isRolloverModalOpen,
    handleRolloverModalOpen,
    setIsRolloverModalOpen,
  };
}

import * as Yup from 'yup';
import {FormNameEnum, UploadFormLabelEnum} from '../../enums';
import {errorMessages} from 'utils/constants';
import {IInputFile} from 'models/library/common';
import {getIsDateEarlierFromToday, isValidFormValue} from '../helpers';
import {ALLOWED_MIMETYPE_FORMATS} from 'admin/resources';

export interface IUploadFileFormBaseValues {
  [FormNameEnum.file]: IInputFile | any;
  [FormNameEnum.isAccessibleToStudents]: boolean;
  [FormNameEnum.hasUploadPermissions]: boolean;
  [FormNameEnum.displayName]: string;
  [FormNameEnum.keyTerms]: string;
  [FormNameEnum.programs]: number[];
  [FormNameEnum.pillars]: number[];
  [FormNameEnum.competencies]: number[];
  [FormNameEnum.gradeLevels]: number[];
  [FormNameEnum.skillsAndBehaviors]: number[];
  [FormNameEnum.languages]: number[];
  [FormNameEnum.knowledgeSkillsAndAbilities]: number[];
  [FormNameEnum.briefDescription]: string;
  [FormNameEnum.learningObjectives]: string;
  [FormNameEnum.expirationDate]: string | Date;
  [FormNameEnum.shouldDisableFileType]: boolean;
  [FormNameEnum.fileType]: number;
}

export const uploadFileFormBaseValidationSchema = Yup.object().shape({
  [FormNameEnum.file]: Yup.mixed()
    .nullable()
    .required(`You should attach a ${UploadFormLabelEnum.File}!`)
    .test('is-filetype-allowed', 'This file format is not allowed!', (value: IInputFile) =>
      value ? ALLOWED_MIMETYPE_FORMATS.includes(value.type) : true
    ),
  [FormNameEnum.fileType]: Yup.number()
    .nullable()
    .test('is-prepulated', errorMessages.requiredWithInputName(`a ${UploadFormLabelEnum.FileType}`), function () {
      return isValidFormValue(this.parent.file);
    }),
  [FormNameEnum.briefDescription]: Yup.string().max(500, 'Brief description should be less than 500 characters!'),
  [FormNameEnum.gradeLevels]: Yup.array().of(Yup.number()),
  [FormNameEnum.programs]: Yup.array().of(Yup.number()),
  [FormNameEnum.pillars]: Yup.array().of(Yup.number()),
  [FormNameEnum.competencies]: Yup.array().of(Yup.number()),
  [FormNameEnum.knowledgeSkillsAndAbilities]: Yup.array().of(Yup.number()),
  [FormNameEnum.languages]: Yup.array().of(Yup.number()).required('You should select at least one language!'),
  [FormNameEnum.expirationDate]: Yup.date()
    .nullable()
    .typeError('Expected date format: (mm/dd/yyyy)')
    .test('is-date-earlier-than-now', 'The selected date should be in the future.', function () {
      const {expirationDate} = this.parent;

      // If the datepicker is cleaned up, then validation is true
      return getIsDateEarlierFromToday(expirationDate);
    }),
  [FormNameEnum.keyTerms]: Yup.string().nullable().max(250, 'Key Terms should be less than 250 characters!'),
  [FormNameEnum.learningObjectives]: Yup.string()
    .nullable()
    .max(250, 'Learning objectives should be less than 250 characters!'),
  [FormNameEnum.isAccessibleToStudents]: Yup.boolean()
    .nullable()
    .required('You should choose if this file will be accessible to students!'),
  [FormNameEnum.hasUploadPermissions]: Yup.boolean().test(
    'is-checked',
    'You should accept upload file permissions!',
    function () {
      return isValidFormValue(this.parent.hasUploadPermissions);
    }
  ),
  [FormNameEnum.displayName]: Yup.string()
    .nullable()
    .required(errorMessages.requiredWithInputName(`a ${UploadFormLabelEnum.DisplayName}`)),
});

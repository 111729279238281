import React, {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Accordion, Column, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {fetchFileStatusesThunk, filtersFileStatusesEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {IWithDisabled} from 'models';

type IFileStatussesCheckboxFilterProps = IWithDisabled;

export const FileStatussesCheckboxFilter: FC<IFileStatussesCheckboxFilterProps> = ({disabled}) => {
  const dispatch = useDispatch();
  const fileStatuses = useSelector(filtersFileStatusesEntitiesCollectionSelector);

  useEffect(() => {
    if (!fileStatuses.length) {
      dispatch(fetchFileStatusesThunk());
    }
  }, [dispatch, fileStatuses]);

  return (
    <Accordion heading="Statuses">
      <Column>
        {fileStatuses.length ? (
          fileStatuses.map((fileStatus) => (
            <Form.Checkbox
              key={fileStatus.id}
              name={FormNameEnum.fileStatuses}
              value={fileStatus.id}
              label={fileStatus.displayText}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>No Data</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
};

import {IEditFileMetadataFormBaseValues} from '../components/FilesEditResourceForm/config';
import {mapResourceMetadata} from './mapResourceMetadata';

export const mapUpdateResourceWebLinkPayload = (fileId: string, formValues: IEditFileMetadataFormBaseValues) => {
  const metadata = mapResourceMetadata(formValues);
  return {
    id: fileId,
    displayName: formValues.displayName,
    briefDescription: formValues.briefDescription,
    url: formValues.webLink,
    metadata,
  };
};

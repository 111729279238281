import React, {useMemo} from 'react';
import {SelectionEnum} from 'filters-selections';
import {UploadTypeEnum} from 'admin/resources/upload/enums';
import {getUploadFirstColList, ResourceListColumn} from 'admin';
import {Container, Row} from 'components-lib';
import {useFileEditPermissions} from '../../hooks';

export const FilesEditWebLink = () => {
  const {hasFileEditPermissions} = useFileEditPermissions();

  const firstCollist = useMemo(
    () => [
      SelectionEnum.WebLinkInput,
      SelectionEnum.DisplayNameInput,
      ...getUploadFirstColList(UploadTypeEnum.WebLink, true),
      SelectionEnum.KeyTermsInput,
      SelectionEnum.AccessibleToStudentsRadioGroup,
    ],
    []
  );

  const secondColList = useMemo(
    () => [
      SelectionEnum.BriefDescriptionTextArea,
      SelectionEnum.ProgramMultiSelection,
      SelectionEnum.KnowledgeSkillsAndAbilities,
      SelectionEnum.ExpirationDatePicker,
      SelectionEnum.LearningObjectiveInput,
    ],
    []
  );

  return (
    <Container disableGutters>
      <Row justify="flex-start" align="flex-start">
        <ResourceListColumn list={firstCollist} disableAll={!hasFileEditPermissions} />
        <ResourceListColumn list={secondColList} disableAll={!hasFileEditPermissions} />
      </Row>
    </Container>
  );
};

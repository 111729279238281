import {getCurrentAccessToken, parseToJsonObject} from 'utils';
import {config} from '../config';
import {IHttpResponse} from './models/IHttpResponse';
import {parseErrorMessages} from './utils';

const {jaEeTestApi: apiConfig} = config;

function http<T>(request: Request): Promise<IHttpResponse<T>> {
  return new Promise((resolve, reject) => {
    let response: IHttpResponse<T>;

    fetch(request)
      .then((res) => {
        response = res;
        return response.text();
      })
      .then(function (text) {
        return text ? parseToJsonObject(text) : {};
      })
      .then((body) => {
        getResponseBody(body, response);
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function makeCall<T>(path: string, useAuthentication: boolean, args: RequestInit = {method: 'delete'}) {
  args.headers = returnBasicHeaders(useAuthentication);

  return await http<T>(new Request(path, args));
}

/**
 * HTTP Method PATCH is case sensitive due to native fetch implementation.
 * Please see : https://stackoverflow.com/questions/34666680/fetch-patch-request-is-not-allowed
 */

export async function get<T>(
  path: string,
  body?: any,
  useAuthentication = true,
  args: RequestInit = {method: 'GET', body: body ? JSON.stringify(body) : undefined}
): Promise<IHttpResponse<T>> {
  return await makeCall(path, useAuthentication, args);
}

export async function post<T>(
  path: string,
  body: any,
  useAuthentication = true,
  args: RequestInit = {method: 'POST', body: JSON.stringify(body)}
): Promise<IHttpResponse<T>> {
  return await makeCall(path, useAuthentication, args);
}

export async function put<T>(
  path: string,
  body: any,
  useAuthentication = true,
  args: RequestInit = {method: 'PUT', body: JSON.stringify(body)}
): Promise<IHttpResponse<T>> {
  return await makeCall(path, useAuthentication, args);
}

export async function del<T>(
  path: string,
  body: any,
  useAuthentication = true,
  args: RequestInit = {method: 'DELETE', body: JSON.stringify(body)}
): Promise<IHttpResponse<T>> {
  return await makeCall(path, useAuthentication, args);
}

export async function patch<T>(
  path: string,
  body: any,
  useAuthentication = true,
  args: RequestInit = {method: 'PATCH', body: JSON.stringify(body)}
): Promise<IHttpResponse<T>> {
  return await makeCall(path, useAuthentication, args);
}

function returnBasicHeaders(useAuthentication: boolean) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  if (useAuthentication) {
    const token = getCurrentAccessToken();

    headers.append('Ocp-Apim-Subscription-Key', apiConfig.subscriptionKey);
    headers.append('Authorization', 'Bearer ' + token);
  }

  return headers;
}

function getResponseBody(body: any, response: IHttpResponse<any>) {
  if (response.ok) {
    if (body.data && body.metadata) {
      getResponseData(body, response);
    } else {
      getResponseParsedBody(body, response);
    }
  } else if (body.errorCode) {
    getResponseParsedBody(body, response);
    throw response;
  } else if (body.status) {
    getResponseData(body, response);
    throw response;
  } else {
    response.parsedBody = !body.value ? body.error || body.title : body.value;
    throw response;
  }
}

function getResponseParsedBody(body: any, response: IHttpResponse<any>) {
  if (body.errorCode) {
    response.errorCode = body.errorCode;
  }
  response.parsedBody = body;
}

function getResponseData(body: any, response: IHttpResponse<any>) {
  if (body.status) {
    response.errorCode = body.status;
    response.messages = parseErrorMessages(body.messages);
  }

  response.parsedBody = {
    data: body.data,
    metadata: body.metadata,
  };
}

import {IColumn} from 'models/library/common';

export const getFileEditLearningPathColumns = (): IColumn[] => [
  {
    id: 'title',
    label: 'Name',
    align: 'left',
  },
  {
    id: 'createdBy',
    label: 'Created By',
    align: 'left',
  },
  {
    id: 'createdUtc',
    label: 'Date Created',
    align: 'left',
  },
  {
    id: 'updatedBy',
    label: 'Updated By',
    align: 'left',
  },
  {
    id: 'modifiedUtc',
    label: 'Date Updated',
    align: 'left',
  },
];

import React, {FC} from 'react';
import {PillarSelect} from './PillarSelect';
import {IWithDisabled} from 'models';
import {FormNameEnum} from 'filters-selections';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISecondaryPillarSelectProps extends IWithDisabled {}

export const SecondaryPillarSelect: FC<ISecondaryPillarSelectProps> = ({disabled}) => {
  return (
    <PillarSelect
      name={FormNameEnum.secondaryPillar}
      selectionType="single"
      disabled={disabled}
      label="Secondary Pillar"
      shouldFetch={false}
    />
  );
};

import React, {useMemo} from 'react';
import {SelectionEnum} from 'filters-selections';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';
import {ResourceListColumn} from 'admin/resources';

export const LearningPathDetailsSecondCol = () => {
  const {hasPermission} = usePermissions();
  const shouldDisableList = !hasPermission(USAAndSuperAdminRoles);

  const list = useMemo(
    () => [
      SelectionEnum.ProgramsSingle,
      SelectionEnum.TagGradeLevels,
      SelectionEnum.SecondaryPillar,
      SelectionEnum.ExpirationDatePicker,
      SelectionEnum.LearningObjectiveInput,
    ],
    []
  );

  return <ResourceListColumn sm={6} md={6} lg={6} xl={4} list={list} disableAll={shouldDisableList} />;
};

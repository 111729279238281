import {createAsyncThunk} from '@reduxjs/toolkit';
import {integrationsApi} from 'api';
import {prefix} from './config';
import {toastNotificationManager} from 'toast-notifications';
import {integrationDetailsMessages} from 'admin/integrations/details';
import {IGoogleSitesSourceHistoryApiResponse} from 'models/resources/google-sites';

export const fetchGoogleSitesSourceHistoryThunk: any = createAsyncThunk<IGoogleSitesSourceHistoryApiResponse, any>(
  `${prefix} fetchGoogleSitesSourceHistory`,
  async (integrationId: string, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await integrationsApi.getGoogleSitesSourceHistory(integrationId);
      return parsedBody;
    } catch (error) {
      const errMsg = error.messages;
      dispatch(
        toastNotificationManager.createErrorToastAction(errMsg ?? integrationDetailsMessages.unknownIntegrationError)
      );
      return rejectWithValue({error});
    }
  }
);

import React, {FC, useEffect} from 'react';
import {useFormikContext} from 'formik';
import {FormNameEnum} from 'filters-selections';
import {jaUSAId} from 'admin/integrations/add-integration';
import {IAddIntegrationFormBaseValues} from 'models/integrations';
import {AreaSelect} from './AreaSelect';
import {getIsGoogleSitesSource} from 'admin/integrations/utils';
import {IWithDisabled} from 'models';

type IAreaSingleSelectBasedGoogleSitesProps = IWithDisabled;

export const AreaSingleSelectBasedGoogleSites: FC<IAreaSingleSelectBasedGoogleSitesProps> = ({disabled}) => {
  const {values, setFieldValue} = useFormikContext<IAddIntegrationFormBaseValues>();
  const type = Number(values.integrationType);

  const isGoogleSitesSource = getIsGoogleSitesSource(type);

  useEffect(() => {
    if (isGoogleSitesSource) {
      setFieldValue(FormNameEnum.area, jaUSAId);
    } else {
      setFieldValue(FormNameEnum.area, null);
    }
  }, [setFieldValue, isGoogleSitesSource]);

  return <AreaSelect disabled={isGoogleSitesSource || disabled} />;
};

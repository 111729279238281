import React, {FC, useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import {Icon, LinkWithTooltip, Row} from 'components-lib';
import {useCreateLearningPathResources} from 'admin/resources/learning-paths/views/create';

interface IAddBaseDrawerListItemProps {
  id: number | string;
  name: string;
  isResource?: boolean;
  deleteDrawerItemClickHandler: () => void;
}

export const AddBaseDrawerListItem: FC<IAddBaseDrawerListItemProps> = ({
  id,
  name,
  isResource = true,
  deleteDrawerItemClickHandler,
}) => {
  const classes = useStyles();
  const {hasUrl, redirectToEditResourcePage, redirectToEditInstrumentPage} = useCreateLearningPathResources({id});

  const redirectLink = useCallback(() => (isResource ? redirectToEditResourcePage() : redirectToEditInstrumentPage()), [
    isResource,
    redirectToEditResourcePage,
    redirectToEditInstrumentPage,
  ]);

  return (
    <Row justify="space-between" align="center" classes={{root: classes.root}}>
      {name && (
        <LinkWithTooltip clickHandler={redirectLink} tooltipTitle={name} withNewTab disabled={!hasUrl}>
          {name}
        </LinkWithTooltip>
      )}
      <Icon.Button clickHandler={deleteDrawerItemClickHandler}>
        <Icon.DeleteIcon />
      </Icon.Button>
    </Row>
  );
};

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    height: 56,
    background: theme.palette.grey[50],

    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 3),
  },
}));

import React, {useState} from 'react';
import {DeleteOutline} from '@material-ui/icons';

import {Button, DatePicker} from 'components-lib';
import 'react-datepicker/dist/react-datepicker.css';
import State from '../State';

interface IProps {
  name: string;
  className?: string;
  placeholder?: string;
  date: any;
  onChange: any;
  withButton: boolean;
  customInput?: any;
  dateFormat?: string;
  maxWidth?: boolean;
  label: string;
}

export default function DatePickerCustom({name, date, placeholder, withButton, maxWidth}: IProps) {
  const [currDate, setCurrDate] = useState<Date | null>(date);

  const handleDateChange = (date: Date | null, form: any) => {
    setCurrDate(date);
    form.setFieldValue(name, date);
  };

  const handleResetDate = (form: any) => {
    handleDateChange(null, form);
  };

  return (
    <State>
      {(form) => (
        <React.Fragment>
          <DatePicker
            label={placeholder}
            value={(currDate && new Date(currDate)) || null}
            changeHandler={(date: any) => handleDateChange(date, form)}
            maxWidth={maxWidth}
          />
          {withButton && (
            <Button.Primary clickHandler={() => handleResetDate(form)}>
              <DeleteOutline fontSize="small" />
            </Button.Primary>
          )}
        </React.Fragment>
      )}
    </State>
  );
}

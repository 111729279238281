import React, {useCallback, useMemo} from 'react';
import {batch} from 'react-redux';
import {FormikHelpers} from 'formik';
import {PageLayoutTwoVerticalCols} from 'layout';
import {Form} from 'forms';
import {UploadFileHeader, UploadFileFooter, UploadFileContentMiddle, UploadFileContentStart} from './components';
import {FormNameEnum} from '../enums';
import {IUploadFileFormBaseValues, mapUploadResourceFile, uploadFileFormBaseValidationSchema} from 'admin/resources';
import {useUploadFile} from './hooks';
import {usePermissions} from 'permissions';
import {SuperAndNationalAdminroles} from 'enums/admin-roles';
import {useLanguages} from '../hooks';

export const UploadResourceFile = () => {
  const {loading, uploadFile} = useUploadFile();
  const {hasPermission} = usePermissions();
  const {englishId} = useLanguages();
  const hasAdminPermission = hasPermission(SuperAndNationalAdminroles);

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.file]: null,
      [FormNameEnum.isAccessibleToStudents]: null,
      [FormNameEnum.hasUploadPermissions]: hasAdminPermission ? true : false,
      [FormNameEnum.displayName]: null,
      [FormNameEnum.keyTerms]: null,
      [FormNameEnum.programs]: [],
      [FormNameEnum.sessions]: [],
      [FormNameEnum.pillars]: [],
      [FormNameEnum.competencies]: [],
      [FormNameEnum.gradeLevels]: [],
      [FormNameEnum.languages]: englishId ? [englishId] : [],
      [FormNameEnum.skillsAndBehaviors]: [],
      [FormNameEnum.briefDescription]: '',
      [FormNameEnum.learningObjectives]: null,
      [FormNameEnum.fileType]: null,
      [FormNameEnum.expirationDate]: null,
      [FormNameEnum.knowledgeSkillsAndAbilities]: [],
      [FormNameEnum.shouldDisableFileType]: false,
    }),
    [hasAdminPermission, englishId]
  );

  const submitHandler = useCallback(
    (formValues: IUploadFileFormBaseValues, {resetForm, setSubmitting}: FormikHelpers<any>) => {
      const payload = mapUploadResourceFile(formValues);

      return uploadFile(payload).then(() =>
        batch(() => {
          resetForm({
            values: initialValues,
          });
          setSubmitting(false);
        })
      );
    },
    [uploadFile, initialValues]
  );

  const contentMiddle = useMemo(() => <UploadFileContentMiddle />, []);
  const contentStart = useMemo(() => <UploadFileContentStart />, []);

  return (
    <Form.Form<IUploadFileFormBaseValues>
      initialValues={initialValues}
      validationSchema={uploadFileFormBaseValidationSchema}
      submitHandler={submitHandler}
    >
      <PageLayoutTwoVerticalCols
        header={<UploadFileHeader />}
        contentStart={contentStart}
        contentMiddle={contentMiddle}
        withDoubleFirst={false}
        withDoubleSecond
        withFooter
        footer={<UploadFileFooter />}
        withSecondaryNavigation
        loading={loading}
      />
    </Form.Form>
  );
};

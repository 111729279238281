import {createAsyncThunk} from '@reduxjs/toolkit';
import {addIntegrationMessages} from 'admin/integrations/add-integration';
import {integrationsApi} from 'api';
import {ICreateGoogleSitesSourceVirtualClassPayload} from 'models/integrations';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const createGoogleSitesSourceThunk: any = createAsyncThunk(
  `${prefix} createGoogleSitesSourceThunk`,
  async (payload: ICreateGoogleSitesSourceVirtualClassPayload, {dispatch, rejectWithValue}) => {
    try {
      const response = await integrationsApi.createGoogleSitesSource(payload);
      dispatch(toastNotificationManager.createSuccessToastAction(addIntegrationMessages.createIntegrationSuccess));
      return response.data;
    } catch (error) {
      const errMsg = error.response.data.messages[0];
      dispatch(
        toastNotificationManager.createErrorToastAction(errMsg ?? addIntegrationMessages.unknownIntegrationError)
      );
      return rejectWithValue({error});
    }
  }
);

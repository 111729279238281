import {reduxBatch} from '@manaflair/redux-batch';
import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import {config} from 'config';
import logger from 'redux-logger';
import {loadUser} from 'redux-oidc';
import {saveAuthToken} from './middleware/saveAuthToken';
import {rootReducer} from './reducers';
import {authenticationManager} from '../user';

const userManager = authenticationManager.getUserManager();

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
  saveAuthToken,
];

if (!config.isProduction) {
  middleware.push(logger);
}

export const store = configureStore({
  middleware,
  reducer: rootReducer,
  devTools: !config.isProduction,
  enhancers: [reduxBatch],
});

// Fix: 'getUser', please see: https://github.com/maxmantz/redux-oidc/issues/99

loadUser(store, userManager);

export type TAppDispatch = typeof store.dispatch;

import React, {FC, ReactNode, useMemo} from 'react';
import {IconButton, makeStyles} from '@material-ui/core';
import {FormNameEnum, InputTypeEnum} from 'enums/form';
import {Icon, Input, Label, Wrapper} from 'components-lib';
import {IWithDisabled} from 'models';
import {IntegrationFormInput} from '../../add-integration/components';

interface IIntegrationInputProps extends IWithDisabled {
  name?: FormNameEnum;
  label: string;
  type?: string;
  value?: string;
  withForm?: boolean;
  withMargin?: boolean;
  withIcon?: boolean;
  iconPosition?: 'start' | 'end';
  icon?: ReactNode;
}

export const IntegrationInput: FC<IIntegrationInputProps> = ({
  name,
  label,
  type,
  value,
  withForm = true,
  disabled = false,
  withMargin = true,
  withIcon = false,
  iconPosition = 'end',
  icon,
}) => {
  const classes = useStyles({withMargin});

  const iconButton = useMemo(
    () => (
      <IconButton disabled={disabled}>
        {type === InputTypeEnum.Password ? <Icon.VisibilityOnIcon /> : <Icon.VisibilityOffIcon />}
      </IconButton>
    ),
    [disabled, type]
  );

  return (
    <Wrapper className={classes.input}>
      <Label label={label} />
      {withForm ? (
        <IntegrationFormInput
          name={name}
          value=""
          type={type}
          disabled={disabled}
          withIcon={withIcon}
          iconPosition={iconPosition}
          icon={icon}
        />
      ) : (
        <Input
          value={value}
          disabled={disabled}
          type={type}
          withIcon={withIcon}
          iconPosition={iconPosition}
          icon={iconButton}
        />
      )}
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  input: ({withMargin}: {withMargin: boolean}) => {
    const styles = withMargin && {
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
    };
    return styles;
  },
}));

import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {IProgramComparison} from 'admin/programs/details/reports/IProgramComparison';

export const fetchProgramComparison: any = createAsyncThunk(
  `${prefix} fetchProgramComparison`,
  async (programComparison: IProgramComparison, {rejectWithValue, dispatch}) => {
    try {
      if (programComparison.areaId == -1) {
        programComparison.areaId = null;
      }
      const {parsedBody} = await instrumentsApi.getProgramComparison(
        programComparison.pasId,
        programComparison.d2lId,
        programComparison.areaId,
        programComparison.sessionId
      );
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

import * as Yup from 'yup';
import {FormNameEnum} from 'enums/form';
import {ILearningPathDetailsFormBaseValues, ILearningPathValidationsShape} from 'admin/resources/learning-paths/utils';
import {ILearningPathSessionItem} from 'models/resources/learning-paths';
import {isEmpty} from 'utils/functions';
import {getIsBeforeDate} from 'utils/common';

export interface ILearningPathViewEditFormBaseValues extends ILearningPathDetailsFormBaseValues {
  [FormNameEnum.id]: string;
  [FormNameEnum.sessions]: ILearningPathSessionItem[];
}

export const learningPathViewEditFormBaseValidationSchema = Yup.object().shape({
  ...ILearningPathValidationsShape,
  [FormNameEnum.id]: Yup.string(),
  [FormNameEnum.sessions]: Yup.mixed().test('has-empty-session', 'Sessions should not be empty!', (value) => {
    const hasEmptySession = value.find((session) => isEmpty(session.title));

    return !hasEmptySession;
  }),
  [FormNameEnum.expirationDate]: Yup.date()
    .nullable()
    .typeError('Expected date format: (mm/dd/yyyy)')
    .test('is-date-earlier-than-now', 'The selected date should be in the future.', function () {
      const {expirationDate} = this.parent;

      // If the datepicker is cleaned up, then validation is true
      if (expirationDate === null) {
        return true;
      }

      function isBefore(expirationDate: Date): boolean {
        const dateIsBeforeToday = getIsBeforeDate(expirationDate);

        if (dateIsBeforeToday) {
          return false;
        }
        return true;
      }

      if (expirationDate instanceof Date) {
        return isBefore(expirationDate);
      }
    }),
});

import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {IUpdateDraftInstrumentPayload, IWithOnSuccessCallback} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {errorMessages, isString, isFunction} from 'utils';
import {instrumentsMessages} from '../../utils';
import {prefix} from './config';

interface IUpdateInstrumentPayloadWithOnSuccessCallback extends IWithOnSuccessCallback {
  payload: IUpdateDraftInstrumentPayload;
}

export const updateDraftInstrumentThunk: any = createAsyncThunk<any, IUpdateInstrumentPayloadWithOnSuccessCallback>(
  `${prefix} updateDraftInstrument`,
  async ({payload, onSuccess}: IUpdateInstrumentPayloadWithOnSuccessCallback, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await instrumentsApi.updateDraftInstrumentById(payload);

      dispatch(toastNotificationManager.createSuccessToastAction(instrumentsMessages.saveSuccess));

      isFunction(onSuccess) && onSuccess();

      return parsedBody;
    } catch (err) {
      dispatch(
        toastNotificationManager.createErrorToastAction(
          isString(err.parsedBody) ? err.parsedBody : errorMessages.defaultErrorMessage
        )
      );
      return rejectWithValue({error: err});
    }
  }
);

import {IColumn} from 'models';

export const columns: IColumn[] = [
  {id: 'questionText', label: 'Question Text'},
  {id: 'responseType', label: 'Response Type'},
  {id: 'domain', label: 'Domain'},
  {id: 'administration', label: 'Administration'},
  {id: 'pillar', label: 'Pillar'},
  {id: 'evaluationType', label: 'Evaluation Type'},
  {id: 'schoolLevel', label: 'School Level'},
  {id: 'category', label: 'Category'},
  {id: 'learningObjective', label: 'Learning Objective'},
  {id: 'competency', label: 'Competency'},
  {id: 'concept', label: 'KSAs'},
  {id: 'program', label: 'Program'},
  {id: 'answer1', label: 'Answer1'},
  {id: 'a1c', label: 'A1C'},
  {id: 'answer2', label: 'Answer2'},
  {id: 'a2c', label: 'A2C'},
  {id: 'answer3', label: 'Answer3'},
  {id: 'a3c', label: 'A3C'},
  {id: 'answer4', label: 'Answer4'},
  {id: 'a4c', label: 'A4C'},
  {id: 'answer5', label: 'Answer5'},
  {id: 'a5c', label: 'A5C'},
  {id: 'answer6', label: 'Answer6'},
  {id: 'a6c', label: 'A6C'},
  {id: 'answer7', label: 'Answer7'},
  {id: 'a7c', label: 'A7C'},
];

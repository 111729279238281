import React, {Fragment, useCallback, useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Column, Loading, PageViewHeader, Row, Text} from 'components-lib';
import {Form} from 'forms';
import {SubtitlePageViewHeader} from 'admin/components';
import {EntityHeaderInstructionalText, EntityHeaderName, EntityItems, Status} from 'admin/library/components';
import {ButtonPrimaryWithAdminAndLocalAreaPermissions} from '../components';
import {PageLayoutTwoCol, PageLayoutWithFixedAreas} from 'layout';
import {
  Builder,
  InstrumentEditHeaderAndFooter,
  InstrumentEditInstructionalText,
  InstrumentEditName,
  ModalInfoLinkItem,
} from '../builder/components';
import {HeaderActionButtons, ModalEditClassRelatedInstrument, SidebarInstrumentDetails} from './components';
import {ModalsInstrumentDetails} from './components/modals';
import {EntityStatusEnum} from 'enums';
import {AdminRolesInstruments, AdminRolesWithLocalAreaInstruments} from '../enums';
import {FormNameEnum} from 'filters-selections';
import {IInstrumentItem} from 'models';
import {withRole} from 'permissions';
import {useBuilder} from '../builder/hooks';
import {ActiveModeEnum, useModal} from 'hooks';
import {getFormValue} from 'admin/library/utils';
import {IInstrumentDetailsFormBaseValues, instrumentDetailsFormBaseValidationSchema, getInstrumentLogo} from './utils';
import {getInstrumentIcon} from '../utils';
import {FEEDBACK_NUM, POST_SESSION_NUM, PRE_SESSION_FIRST_DIGIT, PRE_SESSION_NUM} from 'utils';
import {useInstrumentItem, usePretestItem} from '../hooks';
import {useInstrument, useInstrumentIdParams, useInstrumentModals} from './hooks';
import {FormikHelpers} from 'formik';

export const InstrumentDetailsView = () => {
  const classes = useStyles();
  const {instrumentId} = useInstrumentIdParams();
  const {builderItems, moveBuilderItem, deleteBuilderItem} = useBuilder();
  const {possiblePretestItems, getPossiblePretestItems} = usePretestItem();
  const {currentItem, setCurrentItem} = useInstrumentItem();
  const {
    isOpen: isEditClassRelatedInstrumentModalOpen,
    setIsOpen: setIsEditClassRelatedInstrumentModalOpen,
  } = useModal();
  const {isOpen: isInfoLinkItemModalOpen, setIsOpen: setIsInfoLinkItemModalOpen} = useModal();

  const {
    activeMode,
    fetchInstrumentById,
    exportGeneralClickHandler,
    activeTemplate,
    pageLoading,
    updateInstrument,
    backButtonClickHandler,
    setActiveModeHandler,
    classRelatedInstrumentEditConfirmationHandler,
    editItemsClickHandler,
    previewClickHandler,
    previewWithAnswersClickHandler,
  } = useInstrument(instrumentId);

  const {
    isDeleteModalOpen,
    setIsCloneModalOpen,
    handleDeleteModalOpen,
    handleDeleteModalClose,
    isPublishModalOpen,
    handlePublishModalOpen,
    handlePublishModalClose,
    isCloneModalOpen,
    handleCloneModalOpen,
  } = useInstrumentModals();

  const isViewMode = activeMode === ActiveModeEnum.view;
  const isEditMode = activeMode === ActiveModeEnum.edit;

  const handleLinkModalOpen = useCallback(() => setIsInfoLinkItemModalOpen(true), [setIsInfoLinkItemModalOpen]);

  const EditTextWithPermissions = withRole(AdminRolesInstruments, '')(Text.Caption);

  const EditHeaderAndFooterWithPermissions = withRole(
    AdminRolesWithLocalAreaInstruments,
    ''
  )(InstrumentEditHeaderAndFooter);

  // Renew the instrument details on each update. Dismiss handler in the builder will
  // clear instrument items on a url change. See: JADF-1719

  useEffect(() => {
    fetchInstrumentById(instrumentId);
  }, [fetchInstrumentById, instrumentId]);

  useEffect(() => {
    if (!possiblePretestItems.length) {
      getPossiblePretestItems(instrumentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPossiblePretestItems, instrumentId]);

  const initialValues: IInstrumentDetailsFormBaseValues = useMemo(() => {
    const {
      pillar,
      programs,
      phase,
      type,
      audience,
      isGeneralTemplate,
      templateName,
      instructionText,
      header,
      footer,
    } = activeTemplate ?? {
      pillars: null,
      programs: [],
      phase: null,
      type: null,
      subType: null,
      audience: null,
      templateName: '',
      instructionText: '',
      headerLogo: null,
      footerLogo: null,
    };

    let newType = null;
    let newSubType = null;

    if (!!type && type?.id > PRE_SESSION_NUM && type?.id < FEEDBACK_NUM) {
      newType = type?.id.toString().startsWith(PRE_SESSION_FIRST_DIGIT) ? PRE_SESSION_NUM : POST_SESSION_NUM;
      newSubType = type?.id;
    }

    return {
      [FormNameEnum.templateName]: templateName,
      [FormNameEnum.headerLogo]: header?.byteArray && getInstrumentLogo(header),
      [FormNameEnum.footerLogo]: footer?.byteArray && getInstrumentLogo(footer),
      [FormNameEnum.instructionText]: instructionText,
      [FormNameEnum.pillars]: pillar?.id,
      [FormNameEnum.programs]: getFormValue(programs, 'id'),
      [FormNameEnum.phase]: phase?.id,
      [FormNameEnum.instrumentType]: !!newType ? newType : type?.id,
      [FormNameEnum.instrumentSubType]: newSubType,
      [FormNameEnum.audience]: audience?.id,
      [FormNameEnum.isGeneralInstrument]: isGeneralTemplate,
    };
  }, [activeTemplate]);

  const submitHandler = useCallback(
    (data: IInstrumentDetailsFormBaseValues, {setSubmitting}: FormikHelpers<any>) =>
      updateInstrument(data, setSubmitting),
    [updateInstrument]
  );

  const updateModeClickHandler = useCallback(() => {
    /*
     * Show confirmation for un-publishing only
     * if the the status of the instrument is draft and there is a parentId in the instrument
     */
    if (activeTemplate?.parentId && activeTemplate?.status === EntityStatusEnum.live) {
      setIsEditClassRelatedInstrumentModalOpen(true);
      return;
    }

    setActiveModeHandler();
  }, [activeTemplate, setIsEditClassRelatedInstrumentModalOpen, setActiveModeHandler]);

  const {isGeneralTemplate, isMasterTemplate, status, elements, templateName, instructionText} = activeTemplate ?? {
    isMasterTemplate: null,
    templateId: null,
    status: null,
    elements: [],
    templateName: null,
    instructionText: '',
  };

  const headerSubHeading = useMemo(() => {
    const icon = getInstrumentIcon(!!isGeneralTemplate, isMasterTemplate);
    return (
      <Fragment>
        <SubtitlePageViewHeader> / ID: {instrumentId}</SubtitlePageViewHeader>
        {icon}
      </Fragment>
    );
  }, [isMasterTemplate, isGeneralTemplate, instrumentId]);

  const headerAction = useMemo(
    () => (
      <HeaderActionButtons
        instrumentId={instrumentId}
        status={status}
        editMode={activeMode}
        canPublish={activeTemplate?.canPublish}
        canEdit={activeTemplate?.canEdit}
        isMasterInstrument={activeTemplate?.isMasterTemplate}
        isGeneralTemplate={activeTemplate?.isGeneralTemplate}
        hasAssignmentResults={activeTemplate?.hasAssignmentResults}
        parentId={activeTemplate?.parentId}
        cancelClickHandler={setActiveModeHandler}
        previewClickHandler={previewClickHandler}
        previewWithAnswersClickHandler={previewWithAnswersClickHandler}
        updateModeClickHandler={updateModeClickHandler}
        cloneModalOpenHandler={handleCloneModalOpen}
        deleteModalOpenHandler={handleDeleteModalOpen}
        publishModalOpenHandler={handlePublishModalOpen}
        exportGeneralClickHandler={exportGeneralClickHandler}
      />
    ),
    [
      instrumentId,
      status,
      activeMode,
      activeTemplate,
      setActiveModeHandler,
      previewClickHandler,
      updateModeClickHandler,
      previewWithAnswersClickHandler,
      exportGeneralClickHandler,
      handleCloneModalOpen,
      handleDeleteModalOpen,
      handlePublishModalOpen,
    ]
  );

  const header = useMemo(
    () =>
      !pageLoading && (
        <PageViewHeader
          heading="Instrument"
          subHeading={headerSubHeading}
          extraHeading={<Status status={status} />}
          action={headerAction}
          withBackButton
          backButtonClickHandler={backButtonClickHandler}
        />
      ),
    [status, backButtonClickHandler, headerAction, headerSubHeading, pageLoading]
  );

  const sidebar = useMemo(
    () => (
      <Fragment>
        {!pageLoading && <SidebarInstrumentDetails instrument={activeTemplate} activeMode={activeMode} />}
      </Fragment>
    ),
    [activeTemplate, activeMode, pageLoading]
  );

  const renderItems = useMemo(
    () => (
      <Fragment>
        {isEditMode ? (
          <Builder items={builderItems} />
        ) : (
          <EntityItems<IInstrumentItem>
            items={elements}
            itemPrefixText="Item"
            handleLinkModalOpen={handleLinkModalOpen}
            setCurrentItem={setCurrentItem}
          />
        )}
      </Fragment>
    ),
    [isEditMode, builderItems, elements, handleLinkModalOpen, setCurrentItem]
  );

  const instrumentContent = useMemo(() => {
    return (
      <PageLayoutWithFixedAreas headerSize="small" header={header}>
        <Column className={classes.column}>
          {pageLoading ? (
            <Loading />
          ) : (
            <Column sm={12} md={12} lg={12} xl={12}>
              {isEditMode ? <InstrumentEditName /> : <EntityHeaderName name={templateName} />}
              {isEditMode ? (
                <InstrumentEditInstructionalText />
              ) : (
                <EntityHeaderInstructionalText instructionText={instructionText} />
              )}

              {isEditMode && (
                <Row justify="flex-start">
                  <ButtonPrimaryWithAdminAndLocalAreaPermissions
                    size="small"
                    clickHandler={editItemsClickHandler}
                    className={classes.editItemsButton}
                  >
                    Edit Items
                  </ButtonPrimaryWithAdminAndLocalAreaPermissions>
                </Row>
              )}
              <Row justify="flex-start" classes={{root: classes.logoGroup}}>
                {isEditMode && <EditHeaderAndFooterWithPermissions />}
              </Row>

              <Row justify="flex-start">
                {elements.length > 0 ? (
                  <Fragment>{renderItems}</Fragment>
                ) : (
                  <Fragment>
                    {isViewMode && <EditTextWithPermissions>Click Edit to Add Items</EditTextWithPermissions>}
                  </Fragment>
                )}
              </Row>
            </Column>
          )}
        </Column>
      </PageLayoutWithFixedAreas>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeMode,
    header,
    classes.column,
    classes.editItemsButton,
    pageLoading,
    templateName,
    instructionText,
    editItemsClickHandler,
    elements,
    builderItems,
    deleteBuilderItem,
    moveBuilderItem,
  ]);

  return (
    <Fragment>
      <Form.Form<IInstrumentDetailsFormBaseValues>
        initialValues={initialValues}
        validationSchema={instrumentDetailsFormBaseValidationSchema}
        submitHandler={submitHandler}
      >
        <PageLayoutTwoCol sidebar={sidebar} content={instrumentContent} />
      </Form.Form>
      {!!activeTemplate && (
        <>
          <ModalsInstrumentDetails
            template={activeTemplate}
            isCloneModalOpen={isCloneModalOpen}
            isDeleteModalOpen={isDeleteModalOpen}
            isPublishModalOpen={isPublishModalOpen}
            setIsCloneModalOpen={setIsCloneModalOpen}
            deleteModalOnClose={handleDeleteModalClose}
            publishModalOnClose={handlePublishModalClose}
            fetchInstrumentById={fetchInstrumentById}
          />

          <ModalEditClassRelatedInstrument
            isOpen={isEditClassRelatedInstrumentModalOpen}
            setIsOpen={setIsEditClassRelatedInstrumentModalOpen}
            confirmHandler={classRelatedInstrumentEditConfirmationHandler}
          />
        </>
      )}
      <ModalInfoLinkItem
        linkedQuestionAnswerStackId={currentItem?.linkedQuestionAnswerStackId}
        isOpen={isInfoLinkItemModalOpen}
        setIsOpen={setIsInfoLinkItemModalOpen}
      />
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  column: {
    flex: `1 0 auto`,
  },
  editItemsButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: 0,
    marginRight: 0,
  },
  logoGroup: {
    marginBottom: theme.spacing(3),
  },
}));

import React, {FC, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Form} from 'forms';
import {Accordion, Column, Text} from 'components-lib';
import {IOption, IWithDisabled} from 'models';
import {FormNameEnum, filtersAreasEntitiesCollectionSelector} from 'filters-selections';

type IAreasMultiAutocompleteExternalDataFilterProps = IWithDisabled;

export const AreasMultiAutocompleteExternalDataFilter: FC<IAreasMultiAutocompleteExternalDataFilterProps> = ({
  disabled,
}) => {
  const areas = useSelector(filtersAreasEntitiesCollectionSelector);

  const options: IOption[] = useMemo(() => {
    return areas.map((area) => ({value: area.id, label: area.name}));
  }, [areas]);

  return (
    <Accordion heading="JA Areas">
      <Column>
        {areas.length ? (
          <Form.SelectAutocomplete name={FormNameEnum.areas} options={options} multiple disabled={disabled} />
        ) : (
          <Text.Caption>No Areas</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
};

import React, {useCallback, useMemo} from 'react';
import {Form} from 'forms';
import {
  filesAllLearningPathsFilterList,
  ILearningPathsFiltersFormValues,
  learningPathsAllValidationSchema,
  mapLearningPathsAllFilters,
} from '../../utils';
import {Filters} from 'components-lib';
import {useLearningPaths, useLearningPathsAllStoreActions} from '../../hooks';
import {LearningPathsAllFilterNamesEnum} from '../../enums';
import {FiltersEnum, FiltersList} from 'filters-selections';
import {ILearningPathsAllFilter} from 'models/resources/learning-paths';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';

export const LearningPathsSidebar = () => {
  const {filter, loading} = useLearningPaths();
  const {setFilterLearningPathsAll, resetFilterLearningPathsAll} = useLearningPathsAllStoreActions();
  const {hasPermission} = usePermissions();

  const shouldRenderStatusFilter = hasPermission(USAAndSuperAdminRoles);

  const submitHandler = useCallback(
    (formValues: ILearningPathsFiltersFormValues) => {
      const filter: ILearningPathsAllFilter = mapLearningPathsAllFilters(formValues);
      setFilterLearningPathsAll(filter);
    },
    [setFilterLearningPathsAll]
  );

  const resetHandler = useCallback(() => resetFilterLearningPathsAll(), [resetFilterLearningPathsAll]);

  const initialValues: ILearningPathsFiltersFormValues = useMemo(
    () => ({
      [LearningPathsAllFilterNamesEnum.SEARCH_BY_QUERY]: filter[LearningPathsAllFilterNamesEnum.SEARCH_BY_QUERY],
      [LearningPathsAllFilterNamesEnum.PROGRAMS]: filter[LearningPathsAllFilterNamesEnum.PROGRAMS],
      [LearningPathsAllFilterNamesEnum.PROGRAM_YEARS]: filter[LearningPathsAllFilterNamesEnum.PROGRAM_YEARS],
      [LearningPathsAllFilterNamesEnum.GRADE_LEVELS]: filter[LearningPathsAllFilterNamesEnum.GRADE_LEVELS],
      [LearningPathsAllFilterNamesEnum.PILLARS]: filter[LearningPathsAllFilterNamesEnum.PILLARS],
      [LearningPathsAllFilterNamesEnum.LANGUAGES]: filter[LearningPathsAllFilterNamesEnum.LANGUAGES],
      [LearningPathsAllFilterNamesEnum.LEARNING_PATH_STATUSES]:
        filter[LearningPathsAllFilterNamesEnum.LEARNING_PATH_STATUSES],
    }),
    [filter]
  );

  const renderFiltersList = useMemo(() => {
    if (!shouldRenderStatusFilter) {
      const isNotExistIndex = -1;
      const onePosition = 1;
      const statusesFilterIndex = filesAllLearningPathsFilterList.indexOf(FiltersEnum.LearningPathStatusesCheckbox);

      if (statusesFilterIndex > isNotExistIndex) {
        filesAllLearningPathsFilterList.splice(statusesFilterIndex, onePosition);
      }
    }
    return <FiltersList disableAll={loading} filters={filesAllLearningPathsFilterList} />;
  }, [loading, shouldRenderStatusFilter]);

  return (
    <Form.Form<ILearningPathsFiltersFormValues>
      initialValues={initialValues}
      validationSchema={learningPathsAllValidationSchema}
      submitHandler={submitHandler}
    >
      <Filters.FiltersPanel
        disableAll={loading}
        clearButtonClickHandler={resetHandler}
        searchFieldName={LearningPathsAllFilterNamesEnum.SEARCH_BY_QUERY}
        filters={renderFiltersList}
      />
    </Form.Form>
  );
};

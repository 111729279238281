import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Container, LinkWithTooltip, Icon, Row, Wrapper, Tooltip} from 'components-lib';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';

interface ILearningPathListItemProps {
  name: string;
  linkTooltip: string;
  acessibleToStudentsTooltip: string;
  disableLink: boolean;
  disableDelete: boolean;
  isDeletedOrExpired?: boolean;
  isAvailableToStudents: boolean;
  isResource?: boolean;
  isEditMode?: boolean;
  linkClickHandler: () => void;
  openModalClickHandler: () => void;
}

export const LearningPathListItem: FC<ILearningPathListItemProps> = ({
  name,
  linkTooltip,
  acessibleToStudentsTooltip,
  disableLink,
  disableDelete,
  isAvailableToStudents,
  linkClickHandler,
  openModalClickHandler,
  isDeletedOrExpired = false,
  isEditMode = false,
  isResource,
}) => {
  const {hasPermission} = usePermissions();
  const canDrag = hasPermission(USAAndSuperAdminRoles);
  const isDraggable = isResource && isEditMode && canDrag;
  const classes = useStyles({isDraggable});

  return (
    <Container disableGutters classes={{root: classes.root}}>
      <Row justify="space-between" align="center">
        <Wrapper className={classes.row}>
          <Wrapper className={classes.icon}>
            <Icon.DragHandleIcon color="inherit" />
          </Wrapper>
          {name && (
            <LinkWithTooltip
              clickHandler={linkClickHandler}
              tooltipTitle={linkTooltip ? linkTooltip : name}
              withNewTab
              maxWidth={250}
              isDeletedOrExpired={isDeletedOrExpired}
              disabled={disableLink || !!linkTooltip}
            >
              {name}
            </LinkWithTooltip>
          )}
        </Wrapper>
        <Wrapper className={classes.row}>
          <Tooltip title={acessibleToStudentsTooltip}>
            <Icon.Button disabled>
              {isAvailableToStudents ? <Icon.VisibilityOnIcon /> : <Icon.VisibilityOffIcon />}
            </Icon.Button>
          </Tooltip>
          <Icon.Button disabled={disableDelete} clickHandler={openModalClickHandler}>
            <Icon.DeleteIcon color={disableDelete ? 'disabled' : 'error'} />
          </Icon.Button>
        </Wrapper>
      </Row>
    </Container>
  );
};

interface IRootStyles {
  isDraggable: boolean;
}

export const useStyles = makeStyles((theme) => ({
  root: ({isDraggable}: IRootStyles) => ({
    paddingLeft: theme.spacing(3),
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[400]}`,
    cursor: isDraggable ? 'move' : 'default',
  }),
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
}));

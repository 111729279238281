import React, {ComponentType} from 'react';
import {useNavigate} from 'hooks';
import {RolesEnum} from 'permissions/enums';
import {usePermissions} from 'permissions/hooks';
import {hasRequiredPermissions} from 'permissions/utils';
import {isObject} from 'utils';

export const withRole = <P extends unknown>(requiredRoles: RolesEnum[] | RolesEnum, redirectRoute: string) => (
  WrappedComponent: ComponentType<P>
): any => {
  const WithRole = ({...rest}: any) => {
    const {permissionsLoading, userRoles} = usePermissions();
    const {push} = useNavigate();

    if (permissionsLoading) return null;

    if (!isObject(requiredRoles) && requiredRoles === RolesEnum.All) {
      return <WrappedComponent {...(rest as P)} />;
    }

    const shouldGrantAccess = hasRequiredPermissions(userRoles, requiredRoles as RolesEnum[]);
    if (!shouldGrantAccess) {
      redirectRoute && push(redirectRoute);
      return null;
    }

    return <WrappedComponent {...(rest as P)} />;
  };

  return WithRole;
};

import React, {FC, useCallback, useContext} from 'react';
import {ResourceHeader} from 'admin/resources';
import {LearningPathSessions} from 'admin/resources/learning-paths/components';
import {PageLayoutHorizontalRows} from 'layout';
import {CreateLearningPathDetails, CreateLearningPathDetailsForm, CreateLearningPathFooter} from '..';
import {FormContext} from 'forms/formik/FormContextProvider';

interface ILearningPathViewCreateContainerProps {
  loading: boolean;
}

export const LearningPathViewCreateContainer: FC<ILearningPathViewCreateContainerProps> = ({loading}) => {
  const {formikFormRef, isDirty, setIsDirty, submitFormikForm} = useContext(FormContext);

  const submitFormClickHandler = useCallback(() => formikFormRef?.current && submitFormikForm(), [
    formikFormRef,
    submitFormikForm,
  ]);

  return (
    <PageLayoutHorizontalRows
      headerSize="large"
      loading={loading}
      header={<ResourceHeader heading="Create Learning Path" withBackButton={false} withSubHeading={false} />}
      contentUp={
        <CreateLearningPathDetailsForm formikFormRef={formikFormRef} setIsDirtyCallback={setIsDirty}>
          <CreateLearningPathDetails isDirty={isDirty} setIsDirtyCallback={setIsDirty} />
        </CreateLearningPathDetailsForm>
      }
      contentDown={<LearningPathSessions />}
      footer={<CreateLearningPathFooter isDirty={isDirty} submitFormCallback={submitFormClickHandler} />}
    />
  );
};

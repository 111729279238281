import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useFormikContext} from 'formik';
import {usePermissions} from 'permissions';
import {AdminRolesInstruments} from 'admin/library/instruments/enums';
import {Filters} from 'components-lib';
import {filtersDomainsEntitiesSelector, FiltersEnum, FiltersList, FormNameEnum} from 'filters-selections';
import {isDomainKnowledge as isDomainKnowledgeFunction} from 'utils';

interface IFiltersPanelItemsProps {
  clearButtonClickHandler: () => void;
  filterList: FiltersEnum[];
  searchFieldName?: string;
  disableAll?: boolean;
}

export function FiltersPanelItems({
  filterList,
  searchFieldName,
  clearButtonClickHandler,
  disableAll,
}: IFiltersPanelItemsProps) {
  const {values} = useFormikContext();

  const domainId = values[FormNameEnum.domain];
  const domains = useSelector(filtersDomainsEntitiesSelector);
  const domain = domains[domainId];
  const isDomainKnowledge = isDomainKnowledgeFunction(domain);
  const {hasPermission} = usePermissions();

  const filterListItems = useMemo(() => {
    const shouldRenderAreaFilter = hasPermission(AdminRolesInstruments);
    const filters = [...filterList];

    if (!shouldRenderAreaFilter) {
      const index = filters.indexOf(FiltersEnum.Areas);

      if (index > -1) {
        filters.splice(index, 1);
      }
    }

    if (isDomainKnowledge) {
      // replace the program enum
      const index = filters.indexOf(FiltersEnum.ProgramMulti); // multi is the default filter
      if (index > -1) {
        filters.splice(index, 1, FiltersEnum.ProgramSingle); // TODO find index and replace by index
      }
    } else {
      // remove concept and competency
      const competencyIndex = filters.indexOf(FiltersEnum.Competency);
      if (competencyIndex > -1) filters.splice(competencyIndex, 1);

      const conceptIndex = filters.indexOf(FiltersEnum.Concept);
      if (conceptIndex > -1) filters.splice(conceptIndex, 1);
    }

    return <FiltersList disableAll={disableAll} filters={filters} />;
  }, [filterList, isDomainKnowledge, disableAll, hasPermission]);

  return (
    <Filters.FiltersPanel
      clearButtonClickHandler={clearButtonClickHandler}
      searchFieldName={searchFieldName}
      filters={filterListItems}
      disableAll={disableAll}
    />
  );
}

import React, {Dispatch, FC, SetStateAction, useCallback, useContext, useMemo} from 'react';
import {Form} from 'forms';
import {FormNameEnum} from 'filters-selections';
import {useItem, useItemStateActions} from '../../../hooks';
import {createEmptyFourAnswers} from '../../utils';
import {
  IModalCreateAnswerStackFormValues,
  modalCreateAnswerStackFormValidationSchema,
} from './config/modalCreateAnswerStackValidationSchema';
import {FormikHelpers} from 'formik';
import {ItemQuestionAnswersTypeEnum as answerTypeEnum} from 'admin/library/items/enums';
import {FormContext} from 'forms/formik/FormContextProvider';
interface IModalCreateAnswerStackFormProps {
  setIsOpen: (arg: boolean) => void;
  setIsDirtyCallback: Dispatch<SetStateAction<boolean>>;
}

export const ModalCreateAnswerStackForm: FC<IModalCreateAnswerStackFormProps> = ({
  children,
  setIsOpen,
  setIsDirtyCallback,
}) => {
  const {formikFormRef} = useContext(FormContext);
  const {item} = useItem();
  const {addCustomAnswerStack} = useItemStateActions();

  const submitHandler = useCallback(
    async (data, {resetForm}: FormikHelpers<any>) => {
      try {
        await addCustomAnswerStack({
          ...data,
          rangeFrom: Number(data.rangeFrom),
          rangeTo: Number(data.rangeTo),
          questionId: item.questionId,
        });
        setIsOpen(false);
        resetForm();
        setIsDirtyCallback(false);
      } catch (err) {
        console.log(err);
      }
    },
    [item, addCustomAnswerStack, setIsOpen, setIsDirtyCallback]
  );

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.questionType]: answerTypeEnum.singleSelectAnswerEnum,
      [FormNameEnum.choices]: createEmptyFourAnswers(),
      [FormNameEnum.pillars]: [],
      [FormNameEnum.evaluationTypes]: [],
      [FormNameEnum.domain]: null,
      [FormNameEnum.programs]: [],
      [FormNameEnum.competencies]: [],
      [FormNameEnum.concepts]: [],
      [FormNameEnum.administration]: null,
      [FormNameEnum.schoolLevels]: [],
      [FormNameEnum.learningObjective]: '',
      [FormNameEnum.category]: '',
      [FormNameEnum.rangeFrom]: -5,
      [FormNameEnum.rangeTo]: 10,
      [FormNameEnum.positiveScores]: [],
      [FormNameEnum.minimumRateDescription]: '',
      [FormNameEnum.maximumRateDescription]: '',
    }),
    []
  );

  return (
    <Form.Form<IModalCreateAnswerStackFormValues>
      initialValues={initialValues}
      validationSchema={modalCreateAnswerStackFormValidationSchema}
      submitHandler={submitHandler}
      formikRef={formikFormRef}
    >
      {children}
    </Form.Form>
  );
};

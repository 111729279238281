import React, {FC, useEffect} from 'react';
import {SchoolDistrictSelect} from './SchoolDistrictSelect';
import {IWithDisabled} from 'models/common';
import {useFormikContext} from 'formik';
import {IAddIntegrationFormBaseValues} from 'models/integrations';
import {FormNameEnum} from 'filters-selections';

type ISchoolDistrictBasedOnAreaProps = IWithDisabled;

export const SchoolDistrictBasedOnAreaSelect: FC<ISchoolDistrictBasedOnAreaProps> = ({disabled}) => {
  const {setFieldValue, values} = useFormikContext<IAddIntegrationFormBaseValues>();
  const shouldDisableDistricts = !!values.area;

  useEffect(() => {
    if (values.area) {
      setFieldValue(FormNameEnum.schoolDistrict, null);
    }
  }, [values.area, setFieldValue]);

  return <SchoolDistrictSelect disabled={disabled || shouldDisableDistricts} />;
};

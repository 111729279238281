import React from 'react';
import {ItemBuilderCreateForm, ItemBuilderCreate} from './components';

export const ItemBuilderCreateView = () => {
  return (
    <ItemBuilderCreateForm>
      <ItemBuilderCreate />
    </ItemBuilderCreateForm>
  );
};

import React, {useCallback} from 'react';
import {batch} from 'react-redux';
import {Button} from 'components-lib';
import {useNavigate} from 'hooks';
import {pages} from 'paths';
import {BuilderEditModeEnum} from '../../enums';
import {useBuilder, useBuilderState, usePoolItemsPagination} from '../../hooks';
import {IBuilderEditMode} from '../../hooks/useBuilderEditMode';

interface IActionsBuilderViewProps {
  isEditMatrixMode: boolean;
  isEditInstructionalText: boolean;
  setBuilderEditMode: (builderMode: IBuilderEditMode) => void;
  loading: boolean;
}

export function ActionsBuilderView({
  isEditMatrixMode,
  isEditInstructionalText,
  setBuilderEditMode,
  loading,
}: IActionsBuilderViewProps) {
  const {push} = useNavigate();
  const {instrumentId, save, pageSaving, addInstructionalText} = useBuilder();
  const {
    initialBuilderFilter,
    setSearch,
    getPoolItems,
    clearPoolItems,
    setBuilderFilterInstruments,
    setBuilderItems,
  } = useBuilderState();
  const {currentPage} = usePoolItemsPagination();
  const shouldDisableButtons = !!loading || pageSaving;
  const shouldRenderButtons = !isEditMatrixMode && !isEditInstructionalText;

  const clearBuilderItems = useCallback(() => {
    batch(() => {
      setSearch('');
      setBuilderFilterInstruments(initialBuilderFilter);
      clearPoolItems();
      getPoolItems(currentPage);
    });
  }, [setSearch, getPoolItems, clearPoolItems, currentPage, initialBuilderFilter, setBuilderFilterInstruments]);

  const discardClickHandler = useCallback(() => push(`${pages.adminPortal.instruments.detailsRoot}/${instrumentId}`), [
    instrumentId,
    push,
  ]);

  const dismissClickHandler = useCallback(() => {
    if (isEditMatrixMode) {
      clearBuilderItems();
      setBuilderItems();
    }

    if (isEditMatrixMode || isEditInstructionalText) {
      return setBuilderEditMode({
        questionAnswerStackId: null,
        position: null,
        defaultAnswerStackId: null,
        mode: BuilderEditModeEnum.allTypes,
      });
    }

    return discardClickHandler();
  }, [
    clearBuilderItems,
    discardClickHandler,
    isEditMatrixMode,
    isEditInstructionalText,
    setBuilderEditMode,
    setBuilderItems,
  ]);

  const instructionalTextClickHandler = useCallback(() => {
    addInstructionalText();
  }, [addInstructionalText]);

  const saveAndExitClickHandler = useCallback(() => save(discardClickHandler), [discardClickHandler, save]);

  return (
    <div>
      <Button.Secondary disabled={shouldDisableButtons} clickHandler={dismissClickHandler}>
        Dismiss
      </Button.Secondary>
      {shouldRenderButtons && (
        <>
          <Button.Secondary disabled={shouldDisableButtons} clickHandler={instructionalTextClickHandler}>
            Instructional Text
          </Button.Secondary>
          <Button.Secondary disabled={shouldDisableButtons} clickHandler={saveAndExitClickHandler}>
            Save and Exit
          </Button.Secondary>
          <Button.Primary disabled={shouldDisableButtons} clickHandler={save}>
            {pageSaving ? 'Loading' : 'Save'}
          </Button.Primary>
        </>
      )}
    </div>
  );
}

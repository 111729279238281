export enum AddIntegrationLabelEnum {
  IntegrationType = 'Integration Type',
  Area = 'Area',
  SchoolName = 'School Name',
  ClientID = 'Client ID',
  Certificate = 'Certificate',
  IntegrationName = 'Integration Name',
  SchoolDistrict = 'School District',
  ServiceEmailAdress = 'Service Email Address',
  ClientSecret = 'Client Secret',
  GoogleDriveSourceFolderLink = 'Google Drive Source Folder Link',
  GoogleDriveDestinationFolderLink = 'Google Drive Destination Folder Link',
  AuthorityUrl = 'Authority URL',
  UserToImpersonate = 'User to Impersonate',
}

import * as Yup from 'yup';
import {FormNameEnum} from 'enums/form';
import {IBaseResourceItem} from 'models/resources';

export interface IAddSessionResourcesFormValues {
  [FormNameEnum.resources]: string[];
  [FormNameEnum.selectedResourcesIds]: string[];
  [FormNameEnum.selectedResources]: IBaseResourceItem[];
}

export const addSessionResourcesFormValidationSchema = Yup.object().shape({
  [FormNameEnum.resources]: Yup.array().of(Yup.string()),
  [FormNameEnum.selectedResourcesIds]: Yup.array().of(Yup.string()),
  [FormNameEnum.selectedResources]: Yup.mixed(),
});

import {useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useFormikContext} from 'formik';
import {useAppDispatch} from 'hooks';
import {FormNameEnum} from 'enums/form';
import {orderBy} from 'utils/functions';
import {googleSitesActions as actions, googleSitesListSortOrderSelector} from '../../store';
import {IGoogleSiteListSortOrder} from 'models/resources/google-sites';
import {IGoogleSitesFormValues} from '../utils';
import {useGoogleSitesAll} from '../..';

export const useGoogleSitesSortOrder = () => {
  const dispatch = useAppDispatch();
  const {googleSitesList} = useGoogleSitesAll();
  const {
    values: {googleSitesFilteredList, searchTerm},
    setFieldValue,
  } = useFormikContext<IGoogleSitesFormValues>();
  const sortOrder = useSelector(googleSitesListSortOrderSelector);

  const sortGoogleSitesBy: (sortOrder: IGoogleSiteListSortOrder) => void = useCallback(
    (sortOrder: IGoogleSiteListSortOrder) => {
      if (!googleSitesFilteredList.length) {
        return setFieldValue(FormNameEnum.googleSitesFilteredList, googleSitesList);
      }

      const sortedList = orderBy(googleSitesFilteredList, sortOrder.order, sortOrder.sort.toLowerCase());
      return setFieldValue(FormNameEnum.googleSitesFilteredList, sortedList);
    },
    [setFieldValue, googleSitesFilteredList, googleSitesList]
  );

  const handleItemClick: (sortOrder: IGoogleSiteListSortOrder) => void = useCallback(
    (sortOrder) => dispatch(actions.setSortOrderGoogleSites(sortOrder)),
    [dispatch]
  );

  useEffect(() => {
    sortGoogleSitesBy(sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrder]);

  return {sortOrder, handleItemClick, searchTerm, setFieldValue};
};

import * as Yup from 'yup';
import {FormNameEnum} from 'filters-selections';

export interface IItemsAllFiltersFormValues {
  [FormNameEnum.pillars]: number[];
  [FormNameEnum.evaluationType]: number[];
  [FormNameEnum.domain]: number[];
  [FormNameEnum.programs]: number | number[];
  [FormNameEnum.learningObjective]: string;
  [FormNameEnum.category]: string;
  [FormNameEnum.competency]: number[];
  [FormNameEnum.concept]: number[];
  [FormNameEnum.administration]: number[];
  [FormNameEnum.schoolLevel]: number[];
  [FormNameEnum.areas]: number[];
  [FormNameEnum.searchByName]: string;
}

export const itemsAllFiltersFormValidationSchema = Yup.object().shape({
  [FormNameEnum.pillars]: Yup.array().of(Yup.number()),
  [FormNameEnum.evaluationType]: Yup.array().of(Yup.number()),
  [FormNameEnum.domain]: Yup.array().of(Yup.number()),
  [FormNameEnum.programs]: Yup.array().of(Yup.number()),
  [FormNameEnum.learningObjective]: Yup.string(),
  [FormNameEnum.category]: Yup.string().ensure(),
  [FormNameEnum.competency]: Yup.array().of(Yup.number()),
  [FormNameEnum.concept]: Yup.array().of(Yup.number()),
  [FormNameEnum.administration]: Yup.array().of(Yup.number()),
  [FormNameEnum.schoolLevel]: Yup.array().of(Yup.number()),
  [FormNameEnum.areas]: Yup.array().of(Yup.number()),
  [FormNameEnum.searchByName]: Yup.string(),
});

import {FormNameEnum} from 'filters-selections';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {fetchSchoolLevelThunk, filtersSchoolLevelsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {useAppDispatch} from 'hooks';
import {IWithDisabled} from 'models';
import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISchoolLevelSelectProps extends IWithDisabled {}

export function SchoolLevelSelect({disabled}: ISchoolLevelSelectProps) {
  const dispatch = useAppDispatch();
  const schoolLevels = useSelector(filtersSchoolLevelsEntitiesCollectionSelector);

  useEffect(() => {
    if (!schoolLevels.length) {
      dispatch(fetchSchoolLevelThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const options = useMemo(
    () => schoolLevels.map((schoolLevel) => ({value: schoolLevel.id, label: schoolLevel.displayText})),
    [schoolLevels]
  );

  return (
    <ItemWithPlaceholder label="School Level" hasItems={!!schoolLevels.length}>
      <Form.SelectAutocomplete name={FormNameEnum.schoolLevels} options={options} multiple disabled={disabled} />
    </ItemWithPlaceholder>
  );
}

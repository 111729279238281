import React, {FC, Fragment} from 'react';
import {ReactComponent as DownloadSvg} from 'resources/icons/download_resource.svg';
import {ReactComponent as DownloadDisabledSvg} from 'resources/icons/download_resource_disabled.svg';
import {IWithDisabled} from 'models';

type IDownloadIconProps = IWithDisabled;

export const DownloadIcon: FC<IDownloadIconProps> = ({disabled = false}) => {
  return <Fragment>{disabled ? <DownloadDisabledSvg /> : <DownloadSvg />}</Fragment>;
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {googleSitesApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {IGoogleSitesListApiResponse} from 'models/resources';
import {arrayOfStringsToString, isEmpty} from 'utils';

export const fetchGoogleSiteListThunk: any = createAsyncThunk<IGoogleSitesListApiResponse>(
  `${prefix} fetchGoogleSiteList`,
  async (_, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await googleSitesApi.getGoogleSitesList();
      return parsedBody;
    } catch (error) {
      if (!isEmpty(error.messages)) {
        const errorMsg = arrayOfStringsToString(error.messages);
        dispatch(toastNotificationManager.createErrorToastAction(errorMsg));
      } else {
        dispatch(toastNotificationManager.createErrorToastAction('Google Sites cannot be fetched.'));
      }

      return rejectWithValue({error});
    }
  }
);

import {useSelector} from 'react-redux';
import {builderItemsCollectionSelector, builderItemsEntitiesSelector, builderItemsResultSelector} from '../../store';

export function useBuilderItemsEntities() {
  const builderItemsEntities = useSelector(builderItemsEntitiesSelector);
  const builderItemsResult = useSelector(builderItemsResultSelector);
  const builderItemsEntitiesCollection = useSelector(builderItemsCollectionSelector);

  return {builderItemsEntities, builderItemsResult, builderItemsEntitiesCollection};
}

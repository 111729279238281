import React, {useCallback} from 'react';
import {useAppDispatch} from 'hooks';
import {useFilesAll} from '../../hooks';
import {IFilesAllSortOrder} from 'models/resources/files';
import {filesActions as actions} from '../../store';
import {SortByDropdown} from 'admin/components';
import {FilesOrderingAllEnum} from '../../enums';
import {SortingOrderEnum} from 'enums';
import {getFilesAllMenuItemsList} from '../../utils';

const filesAllOrderingMap = {
  [FilesOrderingAllEnum.Name]: {
    [SortingOrderEnum.ASC]: 'Name: A-Z',
    [SortingOrderEnum.DESC]: 'Name: Z-A',
  },
  [FilesOrderingAllEnum.Created]: {
    [SortingOrderEnum.ASC]: 'Created: Oldest First',
    [SortingOrderEnum.DESC]: 'Created: Newest First',
  },
};

export const FilesViewAllSortBy = () => {
  const dispatch = useAppDispatch();
  const {sortOrder} = useFilesAll();

  const handleItemClick = useCallback(
    (sortOrder: IFilesAllSortOrder) => {
      dispatch(actions.setSortOrderFilesAll(sortOrder));
    },
    [dispatch]
  );

  return (
    <SortByDropdown
      label="Sort By: "
      handleItemClick={handleItemClick}
      getMenuItemsList={getFilesAllMenuItemsList}
      sortOrder={sortOrder}
      map={filesAllOrderingMap}
    />
  );
};

import React, {FC, Fragment} from 'react';
import {makeStyles} from '@material-ui/core';
import {Label, Text, Wrapper} from 'components-lib';
import {IQuestionAnswerStackMetadataValue} from 'models/library/instruments/IQuestionAnswerStack';

interface IStackFilterProps {
  label: string;
  text?: string;
  list?: IQuestionAnswerStackMetadataValue[];
  isSelected?: boolean;
  withBottomMargin?: boolean;
}

export const StackFilter: FC<IStackFilterProps> = ({
  label,
  text,
  list = null,
  isSelected = false,
  withBottomMargin = false,
}) => {
  const classes = useStyles({isSelected, withBottomMargin});

  if (list) {
    return (
      <Wrapper className={classes.container}>
        <Label label={label} className={classes.label} withFontBold withMarginBottom={false} withLeftMargin={false} />
        {list.map((stackFilter, idx: number) => {
          return (
            <Fragment key={idx}>
              {stackFilter?.isSelected ? (
                <StackFilterParagraph text={stackFilter.name} color="secondary" />
              ) : (
                <StackFilterParagraph text={stackFilter.name} withMainColor />
              )}
            </Fragment>
          );
        })}
        {!list.length && <StackFilterParagraph text={'-'} withMainColor />}
      </Wrapper>
    );
  }

  return (
    <Wrapper className={classes.container}>
      <Label label={label} className={classes.label} withFontBold withMarginBottom={false} withLeftMargin={false} />
      {isSelected ? (
        <StackFilterParagraph text={text} color="secondary" />
      ) : (
        <StackFilterParagraph text={text} withMainColor />
      )}
    </Wrapper>
  );
};

interface IStackFilterParagraphProps {
  text: string;
  withMainColor?: boolean;
  color?: 'primary' | 'secondary' | 'textPrimary' | 'error';
}

const StackFilterParagraph: FC<IStackFilterParagraphProps> = ({text, withMainColor = false, color = 'textPrimary'}) => {
  if (withMainColor) {
    return <Text.Paragraph color={color}>{text}</Text.Paragraph>;
  }

  return <Text.Paragraph color={color}>{text}</Text.Paragraph>;
};

type TStyleProps = Pick<IStackFilterProps, 'isSelected'> & {
  isSelected: boolean;
  withBottomMargin: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: ({withBottomMargin}: any) => {
    let containerStyles: any = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    };

    if (withBottomMargin) {
      containerStyles = {...containerStyles, marginBottom: theme.spacing(3)};
    }

    return containerStyles;
  },
  label: ({isSelected}: TStyleProps) => {
    let labelStyles: any = {color: theme.palette.grey[700], fontWeight: 'bold'};

    if (isSelected) {
      labelStyles = {...labelStyles, color: theme.palette.secondary.main};
    }
    return labelStyles;
  },
}));

import {createAsyncThunk} from '@reduxjs/toolkit';
import {ICreateFileApiResponse} from 'models/resources';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {CancelToken, uploadResourcesApi} from 'api';
import {createUniqueId} from 'utils/common';
import {IInputFile} from 'models/library/common';
import {FIVE_PERCENT, MAX_PERCENT, SEC_DURATION, ZERO_NUMBER} from 'utils/constants';
import {
  removeFileFromUploadManager,
  setUploadFileStatus,
  setUploadProgress,
  UploaderActionTypes,
} from 'admin/upload/store';
import {debounce} from 'utils/functions';
import {getInitialFileToUploadPayload} from '../../utils/helpers/getInitialFileToUploadPayload';
import {calculateLoadedPercent} from '../../utils/helpers/calculateLoadedPercent';
import {UploadFileStatusEnum} from 'admin/upload/enums';

export const uploadFileThunk: any = createAsyncThunk<ICreateFileApiResponse, FormData>(
  `${prefix} uploadFile`,
  async (payload: FormData, {dispatch, rejectWithValue}) => {
    const source = CancelToken.source();
    const id = createUniqueId();
    const file = payload.get('file') as IInputFile;
    const fileToUploadInitial = getInitialFileToUploadPayload(id, file.name, file.size, source.cancel);

    const uploadProgress = (progressEvent: ProgressEvent) => {
      const {loaded, total} = progressEvent;
      const percentageProgress = calculateLoadedPercent(loaded, total);

      if (percentageProgress % FIVE_PERCENT === ZERO_NUMBER) {
        const uploadingFile = {
          ...fileToUploadInitial,
          size: file.size,
          percentage: percentageProgress,
          status: percentageProgress < MAX_PERCENT ? UploadFileStatusEnum.Uploading : UploadFileStatusEnum.Uploaded,
        };
        dispatch(setUploadProgress(uploadingFile));
      }
    };

    try {
      dispatch({type: UploaderActionTypes.POST_UPLOAD_FILE_REQUEST, payload: fileToUploadInitial});
      const response = await uploadResourcesApi.uploadFile(payload, uploadProgress, source.token);
      dispatch(setUploadFileStatus(file.name, UploadFileStatusEnum.Uploaded));
      debounce(dispatch(removeFileFromUploadManager(id)), SEC_DURATION);
      dispatch(toastNotificationManager.createSuccessToastAction('File was uploaded successfully'));
      return response.data;
    } catch (error) {
      const errMsg = error.response.data.messages[0];
      dispatch(toastNotificationManager.createErrorToastAction(errMsg));
      return rejectWithValue({error});
    }
  }
);

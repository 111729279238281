import React, {FC, useMemo} from 'react';
import {useFormikContext} from 'formik';
import {makeStyles} from '@material-ui/core';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {Text, Wrapper} from 'components-lib';
import {BUTTON_NUM_LIST} from 'utils';

interface IRangeSelectProps extends IWithDisabled {
  name: string;
  label: string;
  list?: number[];
}

export const RangeSelect: FC<IRangeSelectProps> = ({disabled, name, label, list = BUTTON_NUM_LIST}) => {
  const classes = useStyles();
  const {errors} = useFormikContext();
  const error = errors[name];

  const options: any = useMemo(() => list.map((scale) => ({value: scale.toString(), label: scale.toString()})), [list]);

  return (
    <ItemWithPlaceholder label={label} hasItems={!!list.length}>
      <Form.Select name={name} options={options} disabled={disabled} />
      <Wrapper className={classes.selectError}>{error && <Text.Error>{error}</Text.Error>}</Wrapper>
    </ItemWithPlaceholder>
  );
};

const useStyles = makeStyles({
  selectError: {
    width: '100%',
  },
});

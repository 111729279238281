import {UploaderActionTypes} from './../uploader.types';
import {IUploadFile} from './../../models/IUploadFile';

export function setIsFileAttached(isValidFile: boolean) {
  return {
    type: UploaderActionTypes.SET_IS_FILE_ATTACHED,
    payload: isValidFile,
  };
}

export function resetIsFileAttached() {
  return {
    type: UploaderActionTypes.RESET_IS_FILE_ATTACHED,
  };
}

export function setUploadProgress(updatedFileUpload: IUploadFile) {
  return {
    type: UploaderActionTypes.SET_UPLOAD_FILE_PROGRESS,
    payload: updatedFileUpload,
  };
}

export function removeFileFromUploadManager(id: string) {
  return {
    type: UploaderActionTypes.REMOVE_FILE_FROM_UPLOAD_MANAGER,
    payload: id,
  };
}

export function setUploadFileStatus(name: string, status) {
  return {
    type: UploaderActionTypes.SET_UPLOADED_FILE_STATUS,
    payload: {name, status},
  };
}

export function cancelUpload(id: string) {
  return {
    type: UploaderActionTypes.CANCEL_FILE_UPLOAD,
    payload: id,
  };
}

import React, {FC, useCallback, DragEvent, SyntheticEvent} from 'react';
import {useFormikContext} from 'formik';
import {IWithChildren} from 'models/common';
import {DraggableArea} from 'components-lib';

interface IUploadDraggableAreaProps extends IWithChildren {
  name: string;
}

export const UploadDraggableArea: FC<IUploadDraggableAreaProps> = ({children, name}) => {
  const {setFieldValue, setFieldTouched} = useFormikContext();

  const handleFileDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();

      const newFile = event.dataTransfer.files[0];
      setFieldValue(name, newFile);
      setFieldTouched(name, true, true);
    },
    [setFieldValue, name, setFieldTouched]
  );

  const handleDragOver = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  return (
    <DraggableArea onDrop={handleFileDrop} onDragOver={handleDragOver}>
      {children}
    </DraggableArea>
  );
};

import {useSelector} from 'react-redux';
import {userIdSelector, userStateErrorSelector, userStateLoadingSelector, userAreaSelector} from '../store';

export function useUserId() {
  const userId = useSelector(userIdSelector);
  const userLoading = useSelector(userStateLoadingSelector);
  const userError = useSelector(userStateErrorSelector);
  const userAreaId = useSelector(userAreaSelector);
  return {userId, userLoading, userError, userAreaId};
}

import {SelectionEnum} from 'filters-selections';
import {UploadTypeEnum} from '../../enums';

const list = [
  SelectionEnum.FileTypeSelectBasedOnAttachedFile,
  SelectionEnum.TagGradeLevels,
  SelectionEnum.PillarMultiSelect,
  SelectionEnum.CompetenciesByPillarWithInfo,
  SelectionEnum.LanguagesMulti,
];

const getOriginalList = (isEdit: boolean) => {
  if (isEdit) {
    return [
      SelectionEnum.FileTypeSelectBasedOnAttachedFile,
      SelectionEnum.TagGradeLevels,
      SelectionEnum.PillarMultiSelect,
      SelectionEnum.CompetenciesByPillar,
      SelectionEnum.LanguagesMulti,
    ];
  }

  return list;
};

export const getUploadFirstColList = (uploadType: UploadTypeEnum, isEdit = false) => {
  const originalList = getOriginalList(isEdit);

  if (uploadType === UploadTypeEnum.File) {
    return originalList;
  }

  if (uploadType === UploadTypeEnum.WebLink) {
    return originalList.slice(1);
  }

  if (uploadType === UploadTypeEnum.Scorm) {
    return originalList.slice(1);
  }
};

import React, {FC, ReactNode} from 'react';
import {makeStyles} from '@material-ui/core';

import {AreaTitle} from '../AreaTitle/AreaTitle';

interface IAnswerHeaderProps {
  creatorArea: string;
  headingActions?: ReactNode;
}

export const AnswerHeader: FC<IAnswerHeaderProps> = ({creatorArea, headingActions}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {headingActions && <div className={classes.headingActions}>{headingActions}</div>}
      <AreaTitle creatorArea={creatorArea} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  headingActions: {
    marginRight: theme.spacing(1) + theme.spacing(2),
  },
}));

import {Accordion, Column, Text} from 'components-lib';
import {fetchAudienceThunk} from 'filters-selections';
import {FormNameEnum} from 'filters-selections/enums';
import {filtersAudiencesEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IWithDisabled} from 'models';

export function AudienceFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const audiences = useSelector(filtersAudiencesEntitiesCollectionSelector);

  useEffect(() => {
    if (!audiences.length) {
      dispatch(fetchAudienceThunk());
    }
  }, [audiences.length, dispatch]);

  return (
    <Accordion heading="Audience">
      <Column>
        {audiences.length ? (
          audiences.map((audience) => (
            <Form.Checkbox
              key={audience.id}
              name={FormNameEnum.audience}
              value={audience.id}
              label={audience.displayText}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>No Audience</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
}

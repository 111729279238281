export enum FilesAllFilterNamesEnum {
  SEARCH_BY_QUERY = 'query',
  FILE_TYPES = 'fileTypes',
  PROGRAMS = 'programs',
  GRADE_LEVELS = 'gradeLevels',
  PILLARS = 'pillars',
  COMPETENCIES = 'competencies',
  KSAS = 'knowledgeSkillsAndAbilities',
  IS_ACCESSIBLE_TO_STUDENTS = 'isAccessibleToStudents',
  PUBLISHED_BY = 'publishedBy',
  LANGUAGES = 'languages',
  FILE_STATUSES = 'fileStatuses',
}

import {combineReducers} from '@reduxjs/toolkit';
import {learningPathsAllSlice} from '../views/all/store';
import {learningPathsCreateSlice} from '../views/create/store';
import {learningPathEditSlice} from '../views/edit/store';
import {sessionBuilderSlice} from '../views/session-builder/store';

export const learningPathsReducer = combineReducers({
  all: learningPathsAllSlice.reducer,
  create: learningPathsCreateSlice.reducer,
  edit: learningPathEditSlice.reducer,
  sessionBuilder: sessionBuilderSlice.reducer,
});

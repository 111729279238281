import React, {SyntheticEvent} from 'react';
import {FormControlLabel, Radio as RadioMui} from '@material-ui/core';

interface IRadioProps {
  checked?: boolean;
  name?: string;
  label?: string;
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  value?: string | number;
  changeHandler?: (event: SyntheticEvent, selected?: boolean) => void;
}

export function Radio({checked, color = 'primary', name, label, value, changeHandler, disabled = false}: IRadioProps) {
  return (
    <FormControlLabel
      name={name}
      checked={checked}
      onChange={changeHandler}
      label={label}
      value={value}
      control={<RadioMui name={name} color={color} disabled={disabled} />}
    />
  );
}

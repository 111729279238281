import {ActiveModeEnum} from '../../enums';

export const getQuestionMode = (mode: ActiveModeEnum) => {
  const isCreateMode = mode === ActiveModeEnum.create;
  const isEditMode = mode === ActiveModeEnum.edit;

  return {
    isCreateMode,
    isEditMode,
  };
};

import {DeleteOutline} from '@material-ui/icons';
import React, {FC} from 'react';

interface IDeleteIconProps {
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
}

export const DeleteIcon: FC<IDeleteIconProps> = ({color = 'error'}) => {
  return <DeleteOutline fontSize="small" color={color} />;
};

import {makeStyles} from '@material-ui/core';
import {Container} from 'components-lib';
import React from 'react';
import {GoogleSitesAreas, GoogleSitesAreasForm} from './components';

export const GoogleSiteAreasDetails = () => {
  const classes = useStyles();

  return (
    <Container disableGutters classes={{root: classes.root}}>
      <GoogleSitesAreasForm>
        <GoogleSitesAreas />
      </GoogleSitesAreasForm>
    </Container>
  );
};

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

import {SortingOrderEnum} from 'enums';
import {LearningPathsDropdownNameEnum, LearningPathsOrderingAllEnum} from '../enums';
import {ILearningPathsAllSortOrder} from 'models/resources';

export const getLearningPathsAllMenuItemsList = (
  itemClickHandler: () => void,
  handleItemClick: (handleItemSortObj: ILearningPathsAllSortOrder) => void
) => {
  const onClick = (
    itemClickHandler: () => void,
    handleItemClick: (handleItemSortObj: ILearningPathsAllSortOrder) => void,
    handleItemSortObj: ILearningPathsAllSortOrder
  ) => {
    return () => {
      itemClickHandler();
      handleItemClick(handleItemSortObj);
    };
  };

  return [
    {
      name: LearningPathsDropdownNameEnum.NAME_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: LearningPathsOrderingAllEnum.Name,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: LearningPathsDropdownNameEnum.NAME_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: LearningPathsOrderingAllEnum.Name,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: LearningPathsDropdownNameEnum.CREATED_NEWEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: LearningPathsOrderingAllEnum.Created,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: LearningPathsDropdownNameEnum.CREATED_OLDEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: LearningPathsOrderingAllEnum.Created,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: LearningPathsDropdownNameEnum.PROGRAM_YEAR_NEWEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: LearningPathsOrderingAllEnum.ProgramYear,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: LearningPathsDropdownNameEnum.PROGRAM_YEAR_OLDEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: LearningPathsOrderingAllEnum.ProgramYear,
        sort: SortingOrderEnum.ASC,
      }),
    },
  ];
};

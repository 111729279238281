import {makeStyles} from '@material-ui/core';
import {BuilderItemDragTypeEnum} from 'admin/library/enums';
import {IBuilderDragItem} from 'admin/library/models';
import {DragItem} from 'dragAndDrop';
import {IAnswer, TBuilderItemBase} from 'models';
import React from 'react';
import {useBuilderItemDrag} from '../../hooks';
import {CardQuestionSimple} from '../CardQuestionSimple/CardQuestionSimple';

interface IPoolItemMatrixModeProps {
  item: TBuilderItemBase;
  itemRows: IAnswer[];
  dropHandler?: (item: IBuilderDragItem) => void;
}

export function PoolItemMatrixMode({item, itemRows, dropHandler}: IPoolItemMatrixModeProps) {
  const itemDrag: IBuilderDragItem = {
    questionId: item.id,
    questionType: item.questionType,
    type: BuilderItemDragTypeEnum.item,
    answerStackId: item.questionAnswerStackId,
  };

  const {drag, isDragging} = useBuilderItemDrag(itemDrag, dropHandler);
  const disabled = shouldBeDisabled(item.id, itemRows);
  const classes = useStyles({isDragging, disabled});

  return (
    <DragItem drag={drag}>
      <div className={classes.root}>
        <CardQuestionSimple type={item.questionType} text={item.questionText} withBorder withExtraPadding />
      </div>
    </DragItem>
  );
}

const useStyles = makeStyles((theme) => ({
  root: ({isDragging, disabled}: {isDragging: boolean; disabled: boolean}) => ({
    width: '100%',
    marginBottom: theme.spacing(2),
    cursor: 'move',
    opacity: isDragging || disabled ? 0.5 : 1,
  }),
}));

function shouldBeDisabled(id: number, itemRows: IAnswer[]) {
  const item = itemRows.find((row) => {
    return row.questionId === id;
  });
  return !!item;
}

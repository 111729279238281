import {createAsyncThunk} from '@reduxjs/toolkit';
import {integrationsApi} from 'api';
import {IIntegrationStatus} from 'models/integrations';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchIntegrationStatusesThunk: any = createAsyncThunk<IIntegrationStatus[], any>(
  `${prefix} fetchIntegrationStatuses`,
  async (_, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await integrationsApi.getIntegrationStatuses();
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

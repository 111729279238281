import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Column, Container, Row} from 'components-lib';
import {Subtitle} from 'admin/components';
import {
  EditIntegrationCertificate,
  EditorPermissionsCheckbox,
  getIntegrationDetailsFirstColumnList,
  getIntegrationDetailsSecondColumnList,
  getIsNotIntegrationFailedOrHasError,
  PreviewList,
  useIntegration,
  useIntegrationDetailType,
} from 'admin/integrations';

export const IntegrationDetails = () => {
  const classes = useStyles();
  const {integration} = useIntegration();
  const {isGoogleClassRoom, isGoogleSitesSource, isGoogleSitesDestination} = useIntegrationDetailType(
    integration?.integrationType
  );

  const shouldRenderEditorPermissionsCheckbox = isGoogleSitesSource || isGoogleSitesDestination;
  const shouldRenderEditIntegrationCertificate = isGoogleSitesSource || isGoogleClassRoom;
  const isTestingFailedOrErrorOrPending = getIsNotIntegrationFailedOrHasError(integration.status);

  const firstColInputList = getIntegrationDetailsFirstColumnList(integration);
  const secondColInputList = getIntegrationDetailsSecondColumnList(integration);

  const contentFirstCol = useMemo(() => <PreviewList list={firstColInputList} />, [firstColInputList]);
  const contentSecondCol = useMemo(() => <PreviewList list={secondColInputList} />, [secondColInputList]);

  return (
    <Container disableGutters classes={{root: classes.container}}>
      <Subtitle withColor={false} as="h3" color="textPrimary" title="Details" />
      <Row justify="flex-start" align="flex-start" classes={{root: classes.row}}>
        <Column sm={8} md={8} lg={8} xl={9} className={classes.content}>
          <Row justify="flex-start" align="flex-start">
            <Column sm={6} md={6} lg={6} xl={4}>
              {contentFirstCol && contentFirstCol}
            </Column>
            <Column sm={6} md={6} lg={6} xl={4}>
              {contentSecondCol && contentSecondCol}
            </Column>
          </Row>
          {shouldRenderEditorPermissionsCheckbox && (
            <Row justify="flex-start" align="flex-start">
              <Column sm={6} md={6} lg={6} xl={4} className={classes.checkbox}>
                <EditorPermissionsCheckbox />
              </Column>
            </Row>
          )}
          {shouldRenderEditIntegrationCertificate && (
            <Row justify="flex-start" align="flex-start">
              <Column sm={6} md={6} lg={6} xl={4}>
                <EditIntegrationCertificate disabled={isTestingFailedOrErrorOrPending} />
              </Column>
            </Row>
          )}
        </Column>
      </Row>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  row: {
    marginTop: theme.spacing(2),
  },
  content: {
    paddingLeft: theme.spacing(2),
  },
  checkbox: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

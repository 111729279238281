import {Accordion, Column, Text} from 'components-lib';
import {
  fetchAdministrationsThunk,
  filtersAdministrationsEntitiesCollectionSelector,
  FormNameEnum,
} from 'filters-selections';
import {Form} from 'forms';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IWithDisabled} from 'models';

export function AdministrationFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const administrations = useSelector(filtersAdministrationsEntitiesCollectionSelector);

  useEffect(() => {
    if (!administrations.length) {
      dispatch(fetchAdministrationsThunk());
    }
  }, [administrations.length, dispatch]);

  return (
    <Accordion heading="Administration">
      <Column>
        {administrations.length ? (
          administrations.map((administration) => (
            <Form.Checkbox
              key={administration.id}
              name={FormNameEnum.administration}
              value={administration.id}
              label={administration.displayText}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>No Data</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
}

import {DialogActions, makeStyles} from '@material-ui/core';
import {IComponentWithChildren} from 'models';
import React from 'react';

interface IModalActionsProps extends IComponentWithChildren {
  withExtraSpace?: boolean;
}

export function ModalActions({children, withExtraSpace = true}: IModalActionsProps) {
  const classes = useStyles(withExtraSpace);
  return <DialogActions className={classes.actions}>{children}</DialogActions>;
}

const useStyles = makeStyles((theme) => ({
  actions: (withExtraSpace: boolean) => ({
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    padding: withExtraSpace ? `${theme.spacing(3)}px 0` : 0,
  }),
}));

import {useFormikContext} from 'formik';
import {useSelector} from 'react-redux';
import {isDomainKnowledge} from 'utils';
import {FormNameEnum} from '../enums';
import {filtersDomainsEntitiesSelector} from '../store';

export function useIsDomainKnowledgeSelected() {
  const {values} = useFormikContext();

  const domains = useSelector(filtersDomainsEntitiesSelector);
  const domainId = values[FormNameEnum.domain];
  const domain = domains[domainId];
  const isDomainKnowledgeSelected = isDomainKnowledge(domain);

  return {isDomainKnowledgeSelected, formValues: values};
}

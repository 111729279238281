import {Button} from 'components-lib';
import {withRole} from 'permissions';
import {AdminRolesInstruments, AdminRolesWithLocalAreaInstruments, AllRolesExceptStudent} from '../enums';
import {SuperAdminRole} from 'enums/admin-roles';
import {SuperAdminRolesItems} from 'admin/library/items/enums';

export const ButtonPrimaryWithAdminPermissions = withRole(AdminRolesInstruments, '')(Button.Primary);
export const ButtonSecondaryWithAdminPermissions = withRole(AdminRolesInstruments, '')(Button.Secondary);

export const ButtonPrimaryWithSuperAdminPermissions = withRole(SuperAdminRole, '')(Button.Primary);
export const ButtonSecondaryWithSuperAdminPermissions = withRole(SuperAdminRole, '')(Button.Secondary);

export const ButtonPrimaryWithAdminAndLocalAreaPermissions = withRole(
  AdminRolesWithLocalAreaInstruments,
  ''
)(Button.Primary);
export const ButtonSecondaryWithAdminAndLocalAreaPermissions = withRole(
  AdminRolesWithLocalAreaInstruments,
  ''
)(Button.Secondary);
export const ButtonSecondaryWithAllRolesExceptStudentPermissions = withRole(
  AllRolesExceptStudent,
  ''
)(Button.Secondary);

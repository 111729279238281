import React, {SyntheticEvent, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Avatar} from 'components-lib';
import {authenticationManager, userProfileSelector} from 'user';

const defaultInitials = 'JA';

export function ProfileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = !!anchorEl;
  const userProfile = useSelector(userProfileSelector);

  const userInitials = useMemo(() => {
    if (!userProfile) {
      return defaultInitials;
    }

    const {given_name: firstName, family_name: lastName} = userProfile;
    const first = firstName ? firstName.charAt(0) : 'J';
    const last = lastName ? lastName.charAt(0) : 'A';
    return `${first}${last}`;
  }, [userProfile]);

  const avatarClickHandler = useCallback((event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const menuCloseHandler = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const logoutClickHandler = useCallback(() => {
    authenticationManager.logout();
  }, []);

  return (
    <>
      <Avatar clickHandler={avatarClickHandler}>{userInitials}</Avatar>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={menuCloseHandler} TransitionComponent={Fade}>
        <MenuItem onClick={logoutClickHandler}>Logout</MenuItem>
      </Menu>
    </>
  );
}

import {useState} from 'react';

export const useInstrumentModals = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const handleDeleteModalClose = () => setIsDeleteModalOpen(false);

  const [isPublishModalOpen, setIsPublishModalOpen] = useState<boolean>(false);
  const handlePublishModalOpen = () => setIsPublishModalOpen(true);
  const handlePublishModalClose = () => setIsPublishModalOpen(false);

  const [isCloneModalOpen, setIsCloneModalOpen] = useState<boolean>(false);
  const handleCloneModalOpen = () => setIsCloneModalOpen(true);

  return {
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    handleDeleteModalOpen,
    handleDeleteModalClose,
    isPublishModalOpen,
    handlePublishModalOpen,
    handlePublishModalClose,
    isCloneModalOpen,
    handleCloneModalOpen,
    setIsCloneModalOpen,
  };
};

import React, {useCallback, useMemo} from 'react';
import {Container, Row, Column, Box} from 'components-lib';
import {makeStyles} from '@material-ui/core';
import {useGoogleSitesDetails} from 'admin/resources/google-sites/details/hooks';
import {getDatetimeAMPMinUTC} from 'utils/common';
import {BoxSizeEnum} from 'admin/resources/google-sites/details';

export const GoogleSitesDetailsContent = () => {
  const classes = useStyles();
  const {
    googleSite: {createdTime, lastModifiedTime, version, editLink, lastModifyingUser, siteOwner},
    redirecToGoogleSite,
  } = useGoogleSitesDetails();

  const createdTimeAMPMinUTC = getDatetimeAMPMinUTC(createdTime);
  const lastModifiedTimeAMPMinUTC = getDatetimeAMPMinUTC(lastModifiedTime);

  const linkClickHandler = useCallback(() => redirecToGoogleSite(editLink), [redirecToGoogleSite, editLink]);

  const firstColList = useMemo(
    () => [
      {
        label: 'Created By',
        content: siteOwner?.displayName,
        avatar: siteOwner?.avatarUrl,
        size: BoxSizeEnum.small,
      },
      {
        label: 'Date Created (UTC)"',
        content: createdTimeAMPMinUTC,
      },
      {
        label: 'Updated by',
        content: lastModifyingUser?.displayName,
        avatar: lastModifyingUser?.avatarUrl,
        size: BoxSizeEnum.small,
      },
      {
        label: 'Date Last Updated (UTC)',
        content: lastModifiedTimeAMPMinUTC,
      },
    ],
    [
      siteOwner.displayName,
      siteOwner.avatarUrl,
      createdTimeAMPMinUTC,
      lastModifyingUser.displayName,
      lastModifyingUser.avatarUrl,
      lastModifiedTimeAMPMinUTC,
    ]
  );

  const firstRow = useMemo(
    () => (
      <Row classes={{root: classes.row}} justify="flex-start" align="flex-start">
        {firstColList.map(({label, content, avatar, size}, idx) => (
          <Column sm={3} md={3} lg={3} xl={3} key={idx}>
            <Box label={label} content={content} avatarUrl={avatar} size={size} />
          </Column>
        ))}
      </Row>
    ),
    [classes.row, firstColList]
  );

  const secondColList = useMemo(
    () => [
      {
        label: 'Version',
        content: version.toString(),
      },
      {
        label: 'Google Sites Link',
        content: editLink,
        withLink: true,
        withButtonLink: true,
        linkCallback: linkClickHandler,
      },
    ],
    [version, editLink, linkClickHandler]
  );

  const secondRow = useMemo(
    () => (
      <Row classes={{root: classes.row}} justify="flex-start" align="flex-start">
        {secondColList.map(({label, content, withLink, withButtonLink, linkCallback}, idx) => (
          <Column sm={3} md={3} lg={3} xl={3} key={idx}>
            <Box
              label={label}
              content={content}
              withLink={withLink}
              withButtonLink={withButtonLink}
              linkCallback={linkCallback}
            />
          </Column>
        ))}
      </Row>
    ),
    [classes.row, secondColList]
  );

  return (
    <Container disableGutters>
      {firstRow}
      {secondRow}
    </Container>
  );
};

export const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(4) - theme.spacing(2),
  },
}));

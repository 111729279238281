import React from 'react';
import {paths} from 'paths';
import {Navigation, NavigationLink, Icon} from 'components-lib';

export function LibraryNavBar() {
  return (
    <Navigation type="secondary">
      <NavigationLink
        to={`${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.instruments}`}
        type="secondary"
      >
        <>
          <Icon.DescriptionIcon />
          Instruments
        </>
      </NavigationLink>
      <NavigationLink
        to={`${paths.adminPortal.root}${paths.adminPortal.library.root}${paths.adminPortal.library.items}`}
        type="secondary"
      >
        <>
          <Icon.SubjectIcon />
          Questions
        </>
      </NavigationLink>
    </Navigation>
  );
}

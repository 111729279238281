import {IActionFulfilled, IProgramClassInstrumentsFilter, IProgramClassInstrumentsSortOrder} from 'models';
import {cloneDeep} from 'utils';

import {IProgramClassInstrumentsState} from './IProgramClassInstrumentsState';
import {programClassInstrumentsInitialState} from './programClassInstruments.initialState';

export const programClassInstrumentsReducer = {
  setFilter: (state: IProgramClassInstrumentsState, action: IActionFulfilled<null, IProgramClassInstrumentsFilter>) => {
    state.filter = action.payload;
  },

  resetFilter: (state: IProgramClassInstrumentsState) => {
    state.filter = cloneDeep(programClassInstrumentsInitialState.filter);
  },

  setSortOrder: (
    state: IProgramClassInstrumentsState,
    action: IActionFulfilled<null, IProgramClassInstrumentsSortOrder>
  ) => {
    state.sortOrder = action.payload;
  },

  setCurrentPage: (state: IProgramClassInstrumentsState, action: IActionFulfilled<null, number>) => {
    state.pagination.currentPage = action.payload;
  },

  setPerPage: (state: IProgramClassInstrumentsState, action: IActionFulfilled<null, number>) => {
    state.pagination.perPage = action.payload;
    resetPagination(state);
  },

  setSortOrderProgramClasseInstruments: (
    state: IProgramClassInstrumentsState,
    action: IActionFulfilled<null, IProgramClassInstrumentsSortOrder>
  ) => {
    state.sortOrder = action.payload;
    resetPagination(state);
  },
};

const resetPagination = (state: IProgramClassInstrumentsState) => {
  state.pagination.currentPage = programClassInstrumentsInitialState.pagination.currentPage;
  state.pagination.totalCount = programClassInstrumentsInitialState.pagination.totalCount;
};

import {Accordion, Column} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {Form} from 'forms';
import React from 'react';
import {IWithDisabled} from 'models';

export function MetadataFilter({disabled}: IWithDisabled) {
  return (
    <Accordion heading="Metadata">
      <Column>
        <Form.Checkbox key={1} name={FormNameEnum.metadata} value={0} label="Invalid" disabled={disabled} />
        <Form.Checkbox key={2} name={FormNameEnum.metadata} value={1} label="Valid" disabled={disabled} />
      </Column>
    </Accordion>
  );
}

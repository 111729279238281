import React, {FC, Fragment, useCallback, useMemo} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {FieldProps} from 'formik';
import {convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {getEditorOptions} from './richTextEditorBaseConfig';
import {useRichTextEditor} from './hooks';
import {FormNameEnum} from 'filters-selections';
import {makeStyles} from '@material-ui/core';

interface IFormProps {
  name: string;
  options?: any;
  placeholder?: string;
}
interface IRichTextEditorProps extends FieldProps, IFormProps {
  name: string;
  placeholder?: string;
  value: any;
  withEdit?: boolean;
  readOnly?: boolean;
  toolbarHidden?: boolean;
  withInstructionalText?: boolean;
}

export const RichTextEditor: FC<IRichTextEditorProps> = ({
  placeholder,
  withEdit,
  readOnly,
  toolbarHidden,
  value,
  withInstructionalText,
  field: {name},
  form: {setFieldValue, values},
}) => {
  const classes = useStyles();
  const {editorState, setEditorState} = useRichTextEditor(name);

  const editorOptions = getEditorOptions();
  const editorValue = value || values[FormNameEnum.questionTextHtml];

  const handleEditorStateChange = useCallback(
    (newState) => {
      setEditorState(newState);
      const currentEditorContent = newState?.getCurrentContent();
      const rawData = newState && convertToRaw(currentEditorContent);
      const htmlText = draftToHtml(rawData);
      setFieldValue(name, htmlText);

      const plainText = currentEditorContent.getPlainText();

      if (withInstructionalText) {
        return setFieldValue(FormNameEnum.builderInstructionalText, plainText);
      }

      setFieldValue(FormNameEnum.text, plainText);
    },
    [name, setFieldValue, setEditorState, withInstructionalText]
  );

  const contentWithEdit = useMemo(
    () => (
      <Editor
        stripPastedStyles
        toolbarHidden={toolbarHidden}
        editorClassName={classes.editor}
        toolbarClassName={classes.toolbar}
        readOnly={readOnly}
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        placeholder={placeholder}
        toolbar={editorOptions}
      />
    ),
    [
      classes.editor,
      classes.toolbar,
      editorOptions,
      editorState,
      handleEditorStateChange,
      placeholder,
      readOnly,
      toolbarHidden,
    ]
  );

  if (!editorValue && !withEdit) {
    return (
      <Editor
        stripPastedStyles
        toolbarHidden={toolbarHidden}
        editorClassName={classes.editor}
        toolbarClassName={classes.toolbar}
        readOnly={readOnly}
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        placeholder={placeholder}
        toolbar={editorOptions}
      />
    );
  }

  return <Fragment>{contentWithEdit}</Fragment>;
};

const useStyles = makeStyles((theme) => ({
  editor: {
    padding: theme.spacing(2),
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  toolbar: {
    borderRadius: '4px !important',
    border: `1px solid ${theme.palette.grey[200]} !important`,
  },
}));

import React, {useMemo} from 'react';
import {Container, Row} from 'components-lib';
import {UploadTypeEnum} from 'admin/resources/upload/enums';
import {Subtitle} from 'admin/components';
import {getUploadFirstColList, ResourceListColumn} from 'admin/resources';
import {SelectionEnum} from 'filters-selections';

export const UploadFileContentMiddle = () => {
  const firstCollist = useMemo(
    () => [
      SelectionEnum.DisplayNameInput,
      ...getUploadFirstColList(UploadTypeEnum.File),
      SelectionEnum.KeyTermsWithInfo,
      SelectionEnum.AdminPermissionCheckbox,
    ],
    []
  );

  const secondColList = useMemo(
    () => [
      SelectionEnum.BriefDescriptionTextArea,
      SelectionEnum.ProgramMultiSelection,
      SelectionEnum.KnowledgeSkillsAndAbilitiesWithInfo,
      SelectionEnum.ExpirationWithInfoDatePicker,
      SelectionEnum.LearningObjectiveInputWithInfo,
      SelectionEnum.AccessibleToStudentsWithInfo,
    ],
    []
  );

  return (
    <Container disableGutters>
      <Subtitle withColor={false} as="h3" color="textPrimary" title="Metadata" />
      <Row justify="flex-start" align="flex-start">
        <ResourceListColumn list={firstCollist} sm={6} md={6} lg={6} xl={5} />
        <ResourceListColumn list={secondColList} sm={6} md={6} lg={6} xl={5} />
      </Row>
    </Container>
  );
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {importItemsMessages} from '../../utils/constants/messages';
import {itemImportApi} from 'api';
import {TGetImportTemplateApiResponse} from 'models';

export const fetchImportItemsTemplateThunk: any = createAsyncThunk<TGetImportTemplateApiResponse, null>(
  `${prefix} fetchImportItemsTemplate`,
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await itemImportApi.getImportTemplate();
      dispatch(toastNotificationManager.createSuccessToastAction(importItemsMessages.downloadSuccess));
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

import React, {FC, useState} from 'react';
import {FormControlLabel, makeStyles, Radio as MUIRadio, RadioGroup as MUIRadioGroup} from '@material-ui/core';

interface IRadioBtnOption {
  label: string;
  value: string | number | boolean;
}

interface IProps {
  name?: string;
  options: IRadioBtnOption[];
  newValue?: string | boolean;
  initialOption?: string | boolean;
  direction?: string;
  withBoolean?: boolean;
  handleChange: (value: string | boolean) => void;
}

export const RadioGroup: FC<IProps> = ({
  name = 'choose_view',
  options,
  direction,
  handleChange,
  initialOption,
  newValue,
}) => {
  const classes = useStyles({direction});
  const [value, setValue] = useState<string | boolean>(newValue ? newValue : initialOption);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = (event.target as HTMLInputElement).value as string;

    setValue(targetValue);
    handleChange(targetValue);
  };

  return (
    <MUIRadioGroup className={classes.root} aria-label={name} name={name} value={value} onChange={onChange}>
      {options.map((option: IRadioBtnOption, i: number) => (
        <FormControlLabel key={i} value={option.value} control={<MUIRadio color="primary" />} label={option.label} />
      ))}
    </MUIRadioGroup>
  );
};

const useStyles = makeStyles({
  root: ({direction}: any) => ({
    flexDirection: direction,
  }),
});

import React, {useMemo} from 'react';
import {Table} from 'components-lib';
import {columns} from 'admin/items-import/utils';
import {useItemsImport} from 'admin';

export const FilePreviewTable = () => {
  const {itemImportPreviewRows} = useItemsImport();

  const rows = useMemo(() => {
    if (!itemImportPreviewRows.length) {
      return [];
    }

    return itemImportPreviewRows;
  }, [itemImportPreviewRows]);

  return <Table rows={rows} columns={columns} hasRows={!!rows.length} />;
};

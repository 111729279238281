import React, {useEffect} from 'react';
import {PageLayoutHorizontalRows} from 'layout';
import {
  FilesEditFooter,
  FilesEditHeader,
  FilesEditDetails,
  FilesEditLearningPaths,
  FilesEditResourceForm,
} from './components';
import {useFileEdit, useFileIdParam} from './hooks';

export const FilesEditView = () => {
  const {loading, fetchFileById} = useFileEdit();
  const {fileId} = useFileIdParam();

  useEffect(() => {
    if (fileId) {
      fetchFileById(fileId);
    }
  }, [fetchFileById, fileId]);

  return (
    <FilesEditResourceForm>
      <PageLayoutHorizontalRows
        headerSize="large"
        loading={loading}
        header={<FilesEditHeader />}
        contentUp={<FilesEditDetails />}
        contentMiddle={<FilesEditLearningPaths />}
        footer={<FilesEditFooter />}
      />
    </FilesEditResourceForm>
  );
};

export enum ApiPathsEnum {
  COLLECTOR = 'collector',
  SURVEY = 'Survey',
  UMS = 'UMS',
  QUESTION = 'Question',
  TEMPLATE = 'Template',
  GENERAL_TEMPLATE = 'GeneralTemplate',
  ENUMERATORS = 'Enumerator',
  VIDEO = 'Video',
  DIGITAL_RESOURCE = 'DigitalResource',
  INSTRUMENT = 'Instrument',
  ITEM = 'Item',
  ITEM_IMPORT = 'ItemImport',
  PERMISSION = 'Permission',
  REPORT = 'Report',
  EXPORT = 'Export',
  FILES = 'Files',
  HEADER_AND_FOOTER = 'HeaderAndFooter',
  REPORT_GENERATOR = 'ReportGenerator',
  INTEGRATION = 'Integration',
  VIRTUAL_CLASS = 'VirtualClass',
  CREATE = 'Create',
  GOOGLE_SITES = 'GoogleSites',
  SOURCE = 'Source',
  DESTINATION = 'Destination',
  GOOGLE_CLASSROOM = 'GoogleClassroom',
  MICROSOFT_TEAMS = 'MicrosoftTeams',
  TEST = 'Test',
  SITE = 'Site',
  COPY_SITE = 'CopySite',
  LIST_SITES = 'ListFiles',
  PUBLISHED = 'Published',
  LEARNING_PATHS = 'LearningPaths',
  LEARNING_PATH = 'LearningPath',
  LIST_LEARNING_PATHS = 'ListLearningPaths',
  RESOURCES = 'Resources',
  SCORM = 'Scorm',
  FILE = 'File',
  WEB_LINK = 'WebLink',
  CONTENT = 'Content',
  HELPERS = 'Helpers',
  PAPER_ASSESSMENTS = 'PaperAssessments',
}

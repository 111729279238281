export enum ItemFilterNamesEnum {
  SEARCH_BY_NAME = 'searchByName',
  PILLARS = 'pillarsFilter',
  EVALUATION_TYPES = 'evaluationTypesFilter',
  DOMAINS = 'domainsFilter',
  PROGRAMS = 'programsFilter',
  COMPETENCIES = 'competenciesFilter',
  CONCEPTS = 'conceptsFilter',
  CATEGORY = 'categoryFilter',
  ADMINISTRATIONS = 'administrationsFilter',
  SCHOOL_LEVELS = 'schoolLevelsFilter',
  ITEM_TYPES = 'itemTypesFilter',
  ITEM_STATUSES = 'isPublished',
  SORT_BY = 'sortBy',
  ORDER_BY = 'orderBy',
  AREAS = 'areasFilter',
  LEARNING_OBJECTIVE = 'learningObjectiveFilter',
}

import {Accordion, Text} from 'components-lib';
import {Form} from 'forms';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {useFormikContext} from 'formik';
import {IWithDisabled} from 'models';
import {fetchConceptsThunk, filtersConceptsEntitiesCollectionSelector, FormNameEnum} from 'filters-selections';

export function ConceptsFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const concepts = useSelector(filtersConceptsEntitiesCollectionSelector);
  const {values} = useFormikContext();

  const currentCompetenciesSelected = values[FormNameEnum.competency];

  useEffect(() => {
    if (currentCompetenciesSelected?.length) {
      dispatch(fetchConceptsThunk(currentCompetenciesSelected));
    }
  }, [currentCompetenciesSelected, dispatch]);

  const options: any = useMemo(() => concepts.map((concepts) => ({value: concepts.id, label: concepts.name})), [
    concepts,
  ]);

  return (
    <Accordion heading="KSAs">
      {concepts.length ? (
        <Form.SelectAutocomplete name={FormNameEnum.concept} options={options} multiple disabled={disabled} />
      ) : (
        <Text.Caption>Please select a competency first!</Text.Caption>
      )}
    </Accordion>
  );
}

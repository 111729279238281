import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {useAppDispatch, useNavigate} from 'hooks';
import {pages} from 'paths';
import {
  pageLoadingSelector,
  fileDetailsSelector,
  fileDownloadingSelector,
  fetchFileByIdThunk,
  deleteFileThunk,
  updateResourcesFileThunk,
  updateScormResourceThunk,
  updateWebLinkResourceThunk,
  downloadFileByIdThunk,
} from '../store';
import {IFileDetails, IGetFileApiResponse, IResourceMetadata} from 'models/resources';
import {useFileIdParam} from '.';
import {IEditFileMetadataFormBaseValues} from '../components/FilesEditResourceForm/config';
import {mapUpdateResourceFilePayload, mapUpdateResourceScormPayload, mapUpdateResourceWebLinkPayload} from '../utils';
import {createLinkForDownload} from 'utils';

export const useFileEdit = () => {
  const {push} = useNavigate();
  const dispatch = useAppDispatch();
  const {fileId} = useFileIdParam();

  const loading = useSelector(pageLoadingSelector);
  const fileDownloading = useSelector(fileDownloadingSelector);
  const fileDetails = useSelector(fileDetailsSelector) as IFileDetails;
  const fileMetadata = fileDetails?.metadata as IResourceMetadata;

  const fetchFileById: (id: string) => Promise<IGetFileApiResponse> = useCallback(
    (id: string) => dispatch(fetchFileByIdThunk({id, push})),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const redirectToFilesAllPage: () => void = useCallback(() => {
    push(`${pages.adminPortal.files.root}`);
  }, [push]);

  const redirectToEditLearningPathPage: (id: string) => void = useCallback(
    (id) => window.open(`${pages.adminPortal.learningPaths.editLink}/${id}`, '_blank'),
    []
  );

  const deleteFile: () => void = useCallback(
    () =>
      dispatch(deleteFileThunk(fileId))
        .then(unwrapResult)
        .then(() => redirectToFilesAllPage()),
    [dispatch, fileId, redirectToFilesAllPage]
  );

  const updateFile: (formValues: IEditFileMetadataFormBaseValues) => Promise<IGetFileApiResponse> = useCallback(
    (formValues: IEditFileMetadataFormBaseValues) => {
      const payload = mapUpdateResourceFilePayload(fileId, formValues);
      return dispatch(updateResourcesFileThunk(payload));
    },
    [dispatch, fileId]
  );

  const updateScorm: (formValues: IEditFileMetadataFormBaseValues) => Promise<IGetFileApiResponse> = useCallback(
    (formValues: IEditFileMetadataFormBaseValues) => {
      const payload = mapUpdateResourceScormPayload(fileId, formValues);
      return dispatch(updateScormResourceThunk(payload))
        .then(unwrapResult)
        .then(() => {
          // Redirect only if there is a new version of the file
          if (formValues?.file) {
            return redirectToFilesAllPage();
          }
        });
    },
    [dispatch, fileId, redirectToFilesAllPage]
  );

  const updateWebLink: (formValues: IEditFileMetadataFormBaseValues) => Promise<IGetFileApiResponse> = useCallback(
    (formValues: IEditFileMetadataFormBaseValues) => {
      const payload = mapUpdateResourceWebLinkPayload(fileId, formValues);
      return dispatch(updateWebLinkResourceThunk(payload));
    },
    [dispatch, fileId]
  );

  const updateResource = useCallback(
    (
      formValues: IEditFileMetadataFormBaseValues,
      fileType: {isResource: boolean; isScormPackage: boolean; isWebLink: boolean}
    ) => {
      if (fileType.isResource) {
        return updateFile(formValues);
      }

      if (fileType.isScormPackage) {
        return updateScorm(formValues);
      }

      if (fileType.isWebLink) {
        return updateWebLink(formValues);
      }
    },
    [updateFile, updateScorm, updateWebLink]
  );

  const downloadFileById = useCallback(
    (id: string) =>
      dispatch(downloadFileByIdThunk(id))
        .then(unwrapResult)
        .then(({blob, fileName}) => {
          const objectUrl = window.URL.createObjectURL(blob);
          createLinkForDownload(objectUrl, fileName);
          // Freeing resources in memory
          window.URL.revokeObjectURL(objectUrl);
          // Notify the user
          // dispatch(toastNotificationManager.createSuccessToastAction(fileEditMessages.fileDownload));
        }),
    [dispatch]
  );

  return {
    loading,
    fileDownloading,
    fileDetails,
    fileMetadata,
    fetchFileById,
    linkedLearningPaths: fileDetails?.relatedLearningPaths,
    status: fileDetails?.details?.status,
    userDetails: fileDetails?.details,

    updateResource,
    updateFile,
    deleteFile,
    redirectToFilesAllPage,
    redirectToEditLearningPathPage,
    downloadFileById,
  };
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {IGetGeneralInstrumentExportResponse} from 'models';
import {IHttpResponse, instrumentsApi} from 'api';
import {prefix} from './config';
import {config} from 'config';
import {instrumentsMessages} from '../../utils';
import {toastNotificationManager} from 'toast-notifications';
import {returnQueryStringWithParams} from 'utils';

interface IQueryParams {
  templateId: number;
}

const EXPORTS_URL = `${config.jaEeApi.baseUrlExports}/AssigmentResultsExport/GetByTemplateId`;

export const fetchGeneralInstrumentExportThunk: any = createAsyncThunk(
  `${prefix} fetchInstrumentExport`,
  async (templateId: number, {rejectWithValue, dispatch}) => {
    const params: IQueryParams = {
      templateId,
    };
    const url = returnQueryStringWithParams(EXPORTS_URL, params);

    try {
      const response: IHttpResponse<IGetGeneralInstrumentExportResponse> = await instrumentsApi.getGeneralInstrumentExport(
        url
      );

      if (response.ok) {
        dispatch(toastNotificationManager.createSuccessToastAction(instrumentsMessages.exportSuccess));
        return response.parsedBody;
      }
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.statusText || instrumentsMessages.exportError));
      return rejectWithValue(error);
    }
  }
);

export const integrationsMock: any[] = [
  {
    id: 1,
    integrationName: 'Integration Name 1',
    integrationType: 'MS Teams',
    area: 'Area Name1',
    schoolName: 'School Name1',
    status: 'Published',
  },
  {
    id: 2,
    integrationName: 'Integration Name 2',
    integrationType: 'Google Classroom',
    area: 'Area Name2',
    schoolName: 'School Name2',
    status: 'Tested',
  },
  {
    id: 3,
    integrationName: 'Integration Name 3',
    integrationType: 'MS Teams',
    area: 'Area Name3',
    schoolName: 'School Name3',
    status: 'Published',
  },
  {
    id: 4,
    integrationName: 'Integration Name 4',
    integrationType: 'Google Classroom',
    area: 'Area Name3',
    schoolName: 'School Name',
    status: 'Tested',
  },
  {
    id: 5,
    integrationName: 'Integration Name 5',
    integrationType: 'MS Teams',
    area: 'Area Name 5',
    schoolName: 'School Name',
    status: 'Tested',
  },
  {
    id: 6,
    integrationName: 'Integration Name 6',
    integrationType: 'Google Classroom',
    area: 'Area Name3',
    schoolName: 'School Name',
    status: 'Tested',
  },
  {
    id: 7,
    integrationName: 'Integration Name 7',
    integrationType: 'MS Teams',
    area: 'Area Name 5',
    schoolName: 'School Name',
    status: 'Tested',
  },
  {
    id: 8,
    integrationName: 'Integration Name 8',
    integrationType: 'Google Classroom',
    area: 'Area Name3',
    schoolName: 'School Name',
    status: 'Tested',
  },
  {
    id: 9,
    integrationName: 'Integration Name 9',
    integrationType: 'MS Teams',
    area: 'Area Name 5',
    schoolName: 'School Name',
    status: 'Tested',
  },
];

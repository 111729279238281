import React from 'react';
import {Button} from 'components-lib';
import State from './State';
import {IButtonBaseProps} from 'components-lib/buttons_/IButtonBaseProps';

interface IButtonSubmitProps extends IButtonBaseProps {
  text?: string;
  isDisabled?: boolean;
  isFirst?: boolean;
}

export default function ButtonSubmit({text, isDisabled, isFirst = false, size = 'small'}: IButtonSubmitProps) {
  return (
    <State>
      {(form) => (
        <Button.Primary
          size={size}
          disabled={form.isSubmitting || isDisabled}
          isFirst={isFirst}
          clickHandler={() => {
            form.submitForm();
          }}
        >
          {text ? text : 'Submit'}
        </Button.Primary>
      )}
    </State>
  );
}

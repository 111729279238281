import {config} from 'config';
import {ApiPathsEnum} from 'enums';
import {IFetchUserIdAndAreaIdApiResponse} from 'models/user';
import {get} from './http-client';
import {IHttpResponse} from './models';

const {jaEeApi: apiConfig} = config;

export const userApi = {
  async getUserIdByUserEmail(email: string): Promise<IHttpResponse<number>> {
    return await get<number>(`${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetUserIdByUserEmail?email=${email}`);
  },

  async getUserIdAndAreaByUserEmail(email: string): Promise<IHttpResponse<IFetchUserIdAndAreaIdApiResponse>> {
    return await get<IFetchUserIdAndAreaIdApiResponse>(
      `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetUserIdAndAreaByUserEmail?email=${email}`
    );
  },
};

import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchProgramsThunk} from 'filters-selections';
import {FormNameEnum} from 'filters-selections/enums';
import {IWithMultiSelectOption} from 'filters-selections/models';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {programsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {IOption, IWithDisabled} from 'models';

interface IProgramSelectProps extends IWithMultiSelectOption, IWithDisabled {
  label?: string;
  name?: string;
}

export function ProgramSelect({
  disabled,
  selectionType = 'single',
  label = 'Programs',
  name = FormNameEnum.programs,
}: IProgramSelectProps) {
  const dispatch = useDispatch();
  const programs = useSelector(programsEntitiesCollectionSelector);
  const isMultiSelect = selectionType === 'multi';

  useEffect(() => {
    if (!programs.length) {
      dispatch(fetchProgramsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options: IOption[] = useMemo(() => {
    return programs.map((program) => ({value: program.id, label: program.name}));
  }, [programs]);

  return (
    <ItemWithPlaceholder label={label} hasItems={!!programs.length}>
      {isMultiSelect ? (
        <Form.SelectAutocomplete name={name} options={options} multiple={isMultiSelect} disabled={disabled} />
      ) : (
        <Form.Select name={name} options={options} disabled={disabled} />
      )}
    </ItemWithPlaceholder>
  );
}

import {ViewExpansionPanel} from 'admin/programs/details/components';
import {Filters} from 'components-lib';
import {FiltersEnum, FiltersList, FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {toNumber} from 'utils';
import * as Yup from 'yup';
import {FilterBackendNamesEnum} from '../../enums';
import {useProgramMasterInstruments} from '../../hooks';

interface IFilterForm {
  [FormNameEnum.search]: string;
  [FormNameEnum.audience]: number[];
  [FormNameEnum.phase]: number[];
  [FormNameEnum.instrumentStatus]: number[];
}

const validationSchema = Yup.object().shape({
  [FormNameEnum.search]: Yup.string(),
  [FormNameEnum.educators]: Yup.array().of(Yup.number()),
  [FormNameEnum.phase]: Yup.array().of(Yup.number()),
  [FormNameEnum.instrumentStatus]: Yup.array().of(Yup.number()),
});

export function SidebarFilters() {
  const {programId} = useParams<{programId: string}>();
  const {loading, filter, setFilter, resetFilter} = useProgramMasterInstruments(toNumber(programId));

  const initialValues: IFilterForm = useMemo(
    () => ({
      [FormNameEnum.search]: filter.name,
      [FormNameEnum.audience]: filter.audiences,
      [FormNameEnum.phase]: filter.phases,
      [FormNameEnum.instrumentStatus]: filter.statuses,
    }),
    [filter]
  );

  const renderFiltersList = useMemo(() => {
    const filters = [FiltersEnum.Audience, FiltersEnum.Phase, FiltersEnum.InstrumentStatus];

    return (
      <>
        <ViewExpansionPanel disabled={loading} />
        <FiltersList filters={filters} disableAll={loading} />
      </>
    );
  }, [loading]);

  return (
    <Form.Form<IFilterForm>
      initialValues={initialValues}
      validationSchema={validationSchema}
      submitHandler={(f: IFilterForm) =>
        setFilter({
          [FilterBackendNamesEnum.SEARCH_BY_NAME]: f.search,
          [FilterBackendNamesEnum.AUDIENCES]: f.audience,
          [FilterBackendNamesEnum.PHASES]: f.phase,
          [FilterBackendNamesEnum.STATUSES]: f.status,
        })
      }
    >
      <Filters.FiltersPanel
        clearButtonClickHandler={resetFilter}
        searchFieldName={'search'}
        filters={renderFiltersList}
        disableAll={loading}
      />
    </Form.Form>
  );
}

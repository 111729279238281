import {createAsyncThunk} from '@reduxjs/toolkit';
import {itemsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {itemsMessages} from '../../utils';
import {prefix} from './config';

export const publishItemAnswerStackThunk: any = createAsyncThunk<any, number>(
  `${prefix} publishItemAnswerStack`,
  async (answerStackId: number, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await itemsApi.publishItemAnswerStack(answerStackId);
      dispatch(toastNotificationManager.createSuccessToastAction(itemsMessages.publishAnswerStackSuccess));
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

import {Accordion, Column, Text} from 'components-lib';
import {fetchGradeLevelsThunk} from 'filters-selections';
import {FormNameEnum} from 'filters-selections/enums';
import {filtersGradeLevelsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IWithDisabled} from 'models';

export function GradeLevelFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const gradeLevels = useSelector(filtersGradeLevelsEntitiesCollectionSelector);

  useEffect(() => {
    if (!gradeLevels.length) {
      dispatch(fetchGradeLevelsThunk());
    }
  }, [dispatch, gradeLevels]);

  return (
    <Accordion heading="Grade Levels">
      <Column>
        {gradeLevels.length ? (
          gradeLevels.map((gradeLevel) => (
            <Form.Checkbox
              key={gradeLevel.id}
              name={FormNameEnum.gradeLevels}
              value={gradeLevel.id}
              label={gradeLevel.name}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>Loading...</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
}

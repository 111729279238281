import React, {FC, useCallback, useEffect, useMemo} from 'react';
import {PageLayoutOneOrTwoEqualCols} from 'layout';
import {Header, IntegrationDetailsContent} from './components';
import {
  DEFAULT_CLIENT_SECRET,
  DEFAULT_GOOGLE_DRIVE_FOLDER_LINK,
  integrationDetailsFormBaseValidationSchema,
  useIntegration,
  useIntegrationDetailType,
  useIntegrationIdParam,
  useIntegrationStoreState,
} from 'admin/integrations';
import {Form} from 'forms';
import {FormNameEnum} from 'enums/form';
import {IIntegrationDetailsFormBaseValues} from 'models/integrations';
import {getIsGoogleSitesDestination} from '../utils';

export const IntegrationsDetailView: FC = () => {
  const {integrationId} = useIntegrationIdParam();
  const {integration, fetchAuditLog} = useIntegration();
  const {isGoogleSitesSource, isGoogleSitesDestination} = useIntegrationDetailType(integration.integrationType);

  const {
    loading,
    integration: {
      configuration: {
        serviceEmailAccount,
        clientId,
        clientSecret,
        authorityUrl,
        defaultFolderId,
        certificateExpirationDate,
        masterClassroomEmailAccount,
      },
      name,
      integrationType,
    },
    serviceSourceEmail,
    hasServiceSourceEmail,

    fetchIntegration,
    fetchPublishedSourceDetails,
    updateIntegration,
  } = useIntegration();

  const {clearIntegrationState} = useIntegrationStoreState();

  useEffect(() => {
    fetchIntegration(integrationId);

    return () => {
      clearIntegrationState();
    };
  }, [integrationId, fetchIntegration, clearIntegrationState]);

  useEffect(() => {
    if (!hasServiceSourceEmail && (isGoogleSitesSource || isGoogleSitesDestination)) {
      fetchPublishedSourceDetails();
    }
  }, [fetchPublishedSourceDetails, hasServiceSourceEmail, isGoogleSitesSource, isGoogleSitesDestination]);

  useEffect(() => {
    fetchAuditLog(integrationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = useMemo(() => {
    return <Header />;
  }, []);

  const content = useMemo(() => <IntegrationDetailsContent />, []);

  const submitHandler = useCallback(
    (formValues: IIntegrationDetailsFormBaseValues) => {
      // const castValues = Contact.cast(values)
      updateIntegration(integrationId, formValues);
    },
    [updateIntegration, integrationId]
  );

  const initialValues = useMemo(() => {
    const isGoogleSitesDestination = getIsGoogleSitesDestination(integrationType);

    let serviceEmail = serviceEmailAccount;

    if (isGoogleSitesDestination) {
      serviceEmail = serviceSourceEmail;
    }

    return {
      [FormNameEnum.integrationName]: name ?? null,
      [FormNameEnum.serviceEmail]: serviceEmail ?? null,
      [FormNameEnum.clientId]: clientId ?? null,
      [FormNameEnum.clientSecret]: clientSecret ?? DEFAULT_CLIENT_SECRET,
      [FormNameEnum.authorityUrl]: authorityUrl ?? null,
      [FormNameEnum.googleDriveSourceFolderLink]: defaultFolderId
        ? DEFAULT_GOOGLE_DRIVE_FOLDER_LINK + defaultFolderId
        : null,
      [FormNameEnum.googleDriveDestinationFolderLink]: defaultFolderId
        ? DEFAULT_GOOGLE_DRIVE_FOLDER_LINK + defaultFolderId
        : null,
      [FormNameEnum.certificateExpirationDate]: certificateExpirationDate ?? null,
      [FormNameEnum.canGiveEditorPermissions]: true,
      [FormNameEnum.masterClassroomEmailAccount]: masterClassroomEmailAccount,
      [FormNameEnum.integrationType]: integrationType,
      [FormNameEnum.certificate]: null,
    };
  }, [
    name,
    clientId,
    clientSecret,
    authorityUrl,
    defaultFolderId,
    certificateExpirationDate,
    serviceSourceEmail,
    integrationType,
    serviceEmailAccount,
    masterClassroomEmailAccount,
  ]);

  return (
    <Form.Form<IIntegrationDetailsFormBaseValues>
      initialValues={initialValues}
      validationSchema={integrationDetailsFormBaseValidationSchema}
      submitHandler={submitHandler}
    >
      <PageLayoutOneOrTwoEqualCols header={header} content={content} loading={loading} withFooter={false} />
    </Form.Form>
  );
};

import React, {FC} from 'react';
import {Form} from 'forms';
import {ItemWithPlaceholder} from '../ItemWithPlaceholder';
import {IWithDisabled} from 'models';

interface ITextAreaSelectionProps extends IWithDisabled {
  label: string;
  name: string;
  placeholder?: string;
  rows?: number;
}

export const TextAreaSelection: FC<ITextAreaSelectionProps> = ({label, name, disabled, placeholder = '', rows = 6}) => (
  <ItemWithPlaceholder label={label} hasItems>
    <Form.TextArea disabled={disabled} name={name} rows={rows} placeholder={placeholder} />
  </ItemWithPlaceholder>
);

export enum FormNameEnum {
  id = 'id',
  areas = 'areas',
  audience = 'audience',
  districts = 'districts',
  educators = 'educators',
  startDate = 'startDate',
  endDate = 'endDate',
  gradeLevels = 'gradeLevels',
  instrumentName = 'instrumentName',
  templateName = 'templateName',
  instrumentStatus = 'status',
  instrumentStatuses = 'statuses',
  instrumentType = 'type',
  instrumentSubType = 'subType',
  metadata = 'metadata',
  phase = 'phase',
  pillar = 'pillar',
  pillars = 'pillars',
  program = 'program',
  programs = 'programs',
  isGeneralInstrument = 'isGeneralInstrument',
  years = 'years',
  evaluationType = 'evaluationType',
  evaluationTypes = 'evaluationTypes',
  domain = 'domain',
  competency = 'competency',
  competencies = 'competencies',
  concept = 'concept',
  concepts = 'concepts',
  administrationType = 'administrationType',
  category = 'category',
  answerStacks = 'answerStacks',
  answers = 'answers',
  question = 'question',
  customStacks = 'customStacks',
  administration = 'administration',
  schoolLevel = 'schoolLevel',
  schoolLevels = 'schoolLevels',
  itemType = 'itemType',
  itemStatus = 'itemStatus',
  instrumentViewType = 'instrumentViewType',
  searchByName = 'searchByName',
  sessions = 'sessions',
  search = 'search',
  name = 'name',
  text = 'text',
  levels = 'levels',
  skillsAndBehaviors = 'skillsAndBehaviors',
  languages = 'languages',
  language = 'language',
  drmExpirationDate = 'drmExpirationDate',
  customValidation = 'customValidation',
  keyTerms = 'keyTerms',
  learningObjectives = 'learningObjectives',
  learningObjective = 'learningObjective',
  fileUpload = 'fileUpload',
  uploadResourceType = 'uploadResourceType',
  k12KeyTerms = 'k12KeyTerms',
  digitalResourceTypes = 'digitalResourceTypes',
  fileName = 'fileName',
  wysiwygEditor = 'wysiwygEditor',
  questionTextHtml = 'questionTextHtml',
  questionId = 'questionId',
  details = 'details',
  defaultAnswerStackId = 'defaultAnswerStackId',
  answerTypeName = 'answerTypeName',
  choices = 'choices',
  type = 'type',
  questionType = 'questionType',
  questionTypeAnswerStack = 'questionTypeAnswerStack',
  questionAnswerStackId = 'questionAnswerStackId',
  isDeleted = 'isDeleted',
  instructionText = 'instructionText',
  headerUrl = 'headerUrl',
  footerUrl = 'footerUrl',
  headerLogo = 'headerLogo',
  headerLogoName = 'headerLogoName',
  footerLogo = 'footerLogo',
  footerLogoName = 'footerLogoName',
  openEndedQuestion = 'openEndedQuestion',
  rangeFrom = 'rangeFrom',
  rangeTo = 'rangeTo',
  minimumRateDescription = 'minimumRateDescription',
  maximumRateDescription = 'maximumRateDescription',
  positiveScores = 'positiveScores',
  from = 'from',
  to = 'to',
  role = 'rolesList',
  builderInstructionalText = 'builderInstructionalText',
  builderInstructionalTextHTML = 'builderInstructionalTextHTML',
  integrationTypes = 'integrationTypes',
  integrationStatuses = 'integrationStatuses',
  schoolNames = 'schoolNames',
  integrationName = 'integrationName',
  integrationType = 'integrationType',
  area = 'area',
  schoolName = 'schoolName',
  schoolDistrict = 'schoolDistrict',
  schoolDistricts = 'schoolDistricts',
  onlyVirtualClass = 'onlyVirtualClasses',
  searchTerm = 'searchTerm',
  historyList = 'historyList',
  historyFilteredList = 'historyFilteredList',
  knowledgeSkillsAndAbilities = 'knowledgeSkillsAndAbilities',
  fileType = 'fileType',
  fileTypes = 'fileTypes',
  primaryPillar = 'primaryPillar',
  secondaryPillar = 'secondaryPillar',
  programYear = 'programYear',
  programYears = 'programYears',
  futureProgramYear = 'futureProgramYear',
  futureProgramYears = 'futureProgramYears',
  resources = 'resources',
  instruments = 'instruments',
  learningPathName = 'learningPathName',
  briefDescription = 'briefDescription',
  expirationDate = 'expirationDate',
  masterClassroomEmailAccount = 'masterClassroomEmailAccount',
  email = 'email',
  displayName = 'displayName',
  webLink = 'webLink',
  time = 'time',
  hasUploadPermissions = 'hasUploadPermissions',
  isAccessibleToStudents = 'isAccessibleToStudents',
  learningPathStatuses = 'learningPathStatuses',
  publishedBy = 'publishedBy',
  fileStatuses = 'fileStatuses',
}

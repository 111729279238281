import {IntegrationStatus, IntegrationStatusNum, IntegrationType, IntegrationTypeNum} from 'enums/integrations';

export const DEFAULT_GOOGLE_DRIVE_FOLDER_LINK = 'http://drive.google.com/drive/folders/';
export const DEFAULT_CLIENT_SECRET = '******';
export const EXAMPLE_EMAIL = 'ja@example.com';

export const IntegrationStatusObj = {
  [IntegrationStatusNum.TEST_PENDING]: IntegrationStatus.TEST_PENDING,
  [IntegrationStatusNum.TEST_IN_PROGRESS]: IntegrationStatus.TEST_IN_PROGRESS,
  [IntegrationStatusNum.TEST_SUCCESS]: IntegrationStatus.TEST_SUCCESS,
  [IntegrationStatusNum.TEST_FAILED]: IntegrationStatus.TEST_FAILED,
  [IntegrationStatusNum.PUBLISHED]: IntegrationStatus.PUBLISHED,
  [IntegrationStatusNum.ERROR]: IntegrationStatus.ERROR,
  [IntegrationStatusNum.DELETED]: IntegrationStatus.DELETED,
};

export const IntegrationTypeObj = {
  [IntegrationTypeNum.GOOGLE_CLASSROOM]: IntegrationType.GOOGLE_CLASSROOM,
  [IntegrationTypeNum.MICROSOFT_TEAMS]: IntegrationType.MICROSOFT_TEAMS,
  [IntegrationTypeNum.GOOGLE_SITES_SOURCE]: IntegrationType.GOOGLE_SITES_SOURCE,
  [IntegrationTypeNum.GOOGLE_SITES_DESTINATION]: IntegrationType.GOOGLE_SITES_DESTINATION,
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {uploadResourcesApi} from 'api';
import {IUploadPaperAssessmentsApiResponse, IUploadPaperAssessmentsPayload} from 'models/programs';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const uploadPaperAssessmentsThunk: any = createAsyncThunk<
  IUploadPaperAssessmentsApiResponse,
  IUploadPaperAssessmentsPayload
>(`${prefix} uploadPaperAssessments`, async (payload: IUploadPaperAssessmentsPayload, {dispatch, rejectWithValue}) => {
  try {
    const response = await uploadResourcesApi.uploadPaperAssessment(payload);
    dispatch(toastNotificationManager.createSuccessToastAction('Paper Assessments were uploaded successfully'));
    return response.data;
  } catch (error) {
    const errMsg = error.response.data.messages[0];
    dispatch(toastNotificationManager.createErrorToastAction(errMsg));
    return rejectWithValue({error});
  }
});

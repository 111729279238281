import React, {FC, useContext, useMemo} from 'react';
import {FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import {useItem, useItemStateActions} from 'admin/library/items/hooks';
import {
  IModalSelectAnswerStackFormValues,
  modalSelectAnswerStackFormValidationSchema,
} from './modalSelectAnswerStackValidationSchema';
import {FormikHelpers} from 'formik';
import {batch} from 'react-redux';
import {IWithChildren} from 'models';
import {FormContext} from 'forms/formik/FormContextProvider';

interface IModalSelectAnswerStackFormProps extends IWithChildren {
  setIsOpen: (arg: boolean) => void;
}

export const ModalSelectAnswerStackForm: FC<IModalSelectAnswerStackFormProps> = ({children, setIsOpen}) => {
  const {item} = useItem();
  const {formikFormRef, setIsDirty} = useContext(FormContext);
  const {addDefaultAnswerStack} = useItemStateActions();

  const submitHandler = async (data: IModalSelectAnswerStackFormValues, {resetForm}: FormikHelpers<any>) => {
    addDefaultAnswerStack({
      ...data,
      questionId: item.questionId,
    }).then(() => {
      batch(() => {
        setIsOpen(false);
        resetForm();
        setIsDirty(false);
      });
    });
  };

  const initialValues: IModalSelectAnswerStackFormValues = useMemo(
    () => ({
      [FormNameEnum.pillars]: [],
      [FormNameEnum.evaluationTypes]: [],
      [FormNameEnum.domain]: null,
      [FormNameEnum.programs]: [],
      [FormNameEnum.competencies]: [],
      [FormNameEnum.concepts]: [],
      [FormNameEnum.administration]: null,
      [FormNameEnum.schoolLevels]: [],
      [FormNameEnum.learningObjective]: '',
      [FormNameEnum.category]: '',
      [FormNameEnum.answerStacks]: item.answerStacks,
    }),
    [item]
  );

  return (
    <Form.Form<IModalSelectAnswerStackFormValues>
      initialValues={initialValues}
      validationSchema={modalSelectAnswerStackFormValidationSchema}
      submitHandler={submitHandler}
      formikRef={formikFormRef}
    >
      {children}
    </Form.Form>
  );
};

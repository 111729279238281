import {useAppDispatch} from 'hooks';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {ToastNotificationTypeEnum} from '../enums';
import {toastNotificationManager} from '../services';
import {notificationsSelector, toastNotificationsActions} from '../store';

export function useToastNotifications() {
  const dispatch = useAppDispatch();
  const {notifications, ids} = useSelector(notificationsSelector);

  const addToastNotification = useCallback(
    (message: string, type = ToastNotificationTypeEnum.success) => {
      dispatch(toastNotificationManager.createToastAction(message, type));
    },
    [dispatch]
  );

  const removeToastNotification = useCallback(
    (id: string) => {
      dispatch(toastNotificationsActions.remove({id}));
    },
    [dispatch]
  );

  return {
    ids,
    addToastNotification,
    notifications,
    removeToastNotification,
    type: ToastNotificationTypeEnum,
  };
}

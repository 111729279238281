import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch} from 'hooks';
import {filesActions as actions, paginationSelector} from '../store';
import {IFilesAllFilter} from 'models/resources/files';

export const useFilesAllStoreActions = () => {
  const dispatch = useAppDispatch();

  const {currentPage, perPage, totalCount} = useSelector(paginationSelector);

  const clearFiles = useCallback(() => dispatch(actions.clear()), [dispatch]);

  const clearFile = useCallback(() => dispatch(actions.clearFile()), [dispatch]);

  const setCurrentPage = useCallback((newPage: number) => dispatch(actions.setCurrentPage(newPage)), [dispatch]);

  const setPerPage = useCallback((perPage: number) => dispatch(actions.setPerPage(perPage)), [dispatch]);

  const setFilterFilesAll = useCallback((filter: IFilesAllFilter) => dispatch(actions.setFilterFilesAll(filter)), [
    dispatch,
  ]);

  const resetFilterFilesAll = useCallback(() => dispatch(actions.resetFilterFilesAll()), [dispatch]);

  return {
    currentPage,
    perPage,
    totalCount,
    setCurrentPage,
    setPerPage,
    clearFiles,
    clearFile,
    setFilterFilesAll,
    resetFilterFilesAll,
  };
};

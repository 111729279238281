import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {FormNameEnum} from 'filters-selections';
import {fetchPillarsThunk, pillarsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
import {Accordion} from 'components-lib';

interface IPillarsEntity {
  id: number;
  name: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPillarMultiSelectFilterProps extends IWithDisabled {}

export function PillarMultiSelectFilter({disabled}: IPillarMultiSelectFilterProps) {
  const dispatch = useDispatch();
  const pillars = useSelector(pillarsEntitiesCollectionSelector);

  useEffect(() => {
    if (!pillars.length) {
      dispatch(fetchPillarsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = useMemo(() => {
    return pillars.map((pillar: IPillarsEntity) => ({value: pillar.id, label: pillar.name}));
  }, [pillars]);

  return (
    <Accordion heading="Pillars">
      <Form.SelectAutocomplete name={FormNameEnum.pillars} options={options} multiple disabled={disabled} />
    </Accordion>
  );
}

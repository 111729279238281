import {Button} from 'components-lib';
import * as React from 'react';
import State from './State';

interface IProps {
  text?: string;
  onReset?: (form: any) => void;
  disabled?: boolean;
}

export default function ButtonReset({text, disabled, onReset}: IProps) {
  const onClick = (event: any, form: any) => {
    form.resetForm();

    if (onReset) {
      onReset(form);
    }
  };

  return (
    <State>
      {(form) => (
        <Button.Secondary
          size="small"
          disabled={form.isSubmitting || disabled}
          clickHandler={(ev) => onClick(ev, form)}
        >
          {text ? text : 'Reset'}
        </Button.Secondary>
      )}
    </State>
  );
}

import {IWithDisabled} from 'models';
import React from 'react';
import {PillarSelect} from './PillarSelect';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPillarMultiSelectProps extends IWithDisabled {}

export function PillarMultiSelect({disabled}: IPillarMultiSelectProps) {
  return <PillarSelect label="Pillars" selectionType="multi" disabled={disabled} />;
}

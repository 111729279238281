import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Accordion, Bullet, Row, Text} from 'components-lib';
import {NoResources} from 'admin/resources/learning-paths/components';
interface IDetailsKSAItemProps {
  heading: string;
  list: string[];
}

export const DetailsKSAItem: FC<IDetailsKSAItemProps> = ({heading, list}) => {
  const classes = useStyles();

  return (
    <Accordion
      heading={
        <Text.Heading as="h4" classes={{root: classes.heading}}>
          {heading}
        </Text.Heading>
      }
      withGrayBackground
      withBorder
    >
      <Row justify="flex-start" classes={{root: classes.row}}>
        {list?.length ? (
          list.map((item, idx: number) => (
            <Row justify="flex-start" classes={{root: classes.container}} key={idx}>
              <Bullet />
              <Text.Paragraph variant="body1">{item}</Text.Paragraph>
            </Row>
          ))
        ) : (
          <NoResources text={`No ${heading}`} />
        )}
      </Row>
    </Accordion>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
  row: {
    width: '100%',
    margin: theme.spacing(0, 2),
  },
  container: {
    display: 'flex',
    width: '100%',
  },
}));

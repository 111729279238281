import {IActionFulfilled} from 'models/common';
import {
  duplicateLearningPathThunk,
  fetchExportLearningPathThunk,
  fetchLearningPathsPagedThunk,
} from 'admin/resources/learning-paths';
import {ILearningPathsAllStoreState} from './ILearningPathsAllStoreState';
import {
  IDuplicateLearningPathApiResponse,
  IDuplicateLearningPathThunkPayload,
  IGetAllLearningPathsApiResponse,
} from 'models/resources';
import {isObject} from 'utils';
import {learningPathsAllSchema} from './normalizationSchemas';
import {normalize} from 'normalizr';

export const learningPathsAllExtraReducer = {
  // fetchLearningPathsPagedThunk
  [fetchLearningPathsPagedThunk.fulfilled]: (
    state: ILearningPathsAllStoreState,
    action: IActionFulfilled<null, IGetAllLearningPathsApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {
        data,
        metadata: {totalCount, pageNumber, pageSize},
      } = action.payload;

      state.list = normalize(data, learningPathsAllSchema);
      state.pagination.totalCount = totalCount;
      state.pagination.currentPage = pageNumber;
      state.pagination.perPage = pageSize;
    }

    state.page.loading = false;
  },
  [fetchLearningPathsPagedThunk.pending]: (state: ILearningPathsAllStoreState) => {
    state.page.loading = true;
  },
  [fetchLearningPathsPagedThunk.rejected]: (state: ILearningPathsAllStoreState) => {
    state.page.loading = false;
  },

  // fetchExportLearningPathThunk
  [fetchExportLearningPathThunk.fulfilled]: (state: ILearningPathsAllStoreState) => {
    state.learningPath.id = null;
    state.learningPath.exporting = false;
  },
  [fetchExportLearningPathThunk.pending]: (
    state: ILearningPathsAllStoreState,
    action: IActionFulfilled<null, string>
  ) => {
    state.learningPath.id = action.meta.arg;
    state.learningPath.exporting = true;
  },
  [fetchExportLearningPathThunk.rejected]: (state: ILearningPathsAllStoreState) => {
    state.learningPath.id = null;
    state.learningPath.exporting = false;
  },

  // duplicateLearningPathThunk
  [duplicateLearningPathThunk.fulfilled]: (state: ILearningPathsAllStoreState) => {
    state.learningPath.id = null;
    state.learningPath.cloning = false;
  },
  [duplicateLearningPathThunk.pending]: (
    state: ILearningPathsAllStoreState,
    action: IActionFulfilled<IDuplicateLearningPathThunkPayload, IDuplicateLearningPathApiResponse>
  ) => {
    if (isObject(action.meta.arg)) {
      state.learningPath.id = action.meta.arg.learningPathId;
    }

    state.learningPath.cloning = true;
  },
  [duplicateLearningPathThunk.rejected]: (state: ILearningPathsAllStoreState) => {
    state.learningPath.id = null;
    state.learningPath.cloning = false;
  },
};

import React, {useMemo, useEffect} from 'react';
import {PageViewHeader, PaginationLocal, Table, Button, Loading, Tag} from 'components-lib';
import {PageLayoutWithFixedAreas, PageLayoutTwoCol} from 'layout';
import {ProgramsAllSidebarFilters, ProgramsAllSortBy} from './components';
import {usePrograms} from './hooks';
import {IBindBaseEnumEntity, IProgramBase} from 'models';
import {cloneDeep} from 'utils';
import {ButtonSecondaryWithSuperAdminPermissions} from 'admin/components';

export const ProgramsAllView = () => {
  const {
    loading,
    filter,
    filterEntities,
    filterEntitiesCollection,
    programs,
    totalCount,
    perPage,
    currentPage,
    setFilter,
    fetchPrograms,
    setCurrentPage,
    setPerPage,
    redirectToProgramDetails,
  } = usePrograms();

  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);

  const renderSortOrder = useMemo(() => <ProgramsAllSortBy />, []);

  //TODO: REMOVE IF NOT USING ON THIS PAGE -JH
  // const buttonWithSuperAdminPermissions = useMemo(
  //   () => (
  //     <ButtonSecondaryWithSuperAdminPermissions
  //       withPrimary={false}
  //       withUSAAndSuperAdminRole
  //       clickHandler={() => alert('helloooo!')}
  //     >
  //       Roll-Over
  //     </ButtonSecondaryWithSuperAdminPermissions>
  //   ),
  //   []
  // );

  const filters = useMemo(() => {
    const chips: any[] = [];

    const deleteHandler = (prop: string, item: number | string) => {
      const updated = cloneDeep(filter);
      const idx = filter[prop].indexOf(item);

      if (idx > -1) {
        updated[prop].splice(idx, 1);
      }

      setFilter(updated);
    };

    if (!!filter.searchByName) {
      chips.push(
        <Tag
          withFilterChip
          key={filter.searchByName}
          label={filter.searchByName}
          deleteHandler={() => setFilter({...filter, searchByName: ''})}
        />
      );
    }

    if (!!filter.programYearsFilter && filter.programYearsFilter.length > 0) {
      filter.programYearsFilter.forEach((yearId) => {
        const programYear: IBindBaseEnumEntity = filterEntitiesCollection.years.find(
          (programYear) => programYear.id === yearId
        );

        return chips.push(
          <Tag
            withFilterChip
            key={yearId}
            label={programYear.displayText}
            deleteHandler={() => deleteHandler('programYearsFilter', yearId)}
          />
        );
      });
    }

    if (!!filter.gradeLevelsFilter && filter.gradeLevelsFilter.length > 0) {
      filter.gradeLevelsFilter.forEach((id) => {
        const level = filterEntities.gradeLevels[id];
        chips.push(
          <Tag
            withFilterChip
            key={level.id}
            label={level.name}
            deleteHandler={() => deleteHandler('gradeLevelsFilter', id)}
          />
        );
      });
    }

    return chips;
  }, [filter, filterEntities.gradeLevels, filterEntitiesCollection.years, setFilter]);

  const header = useMemo(() => {
    return <PageViewHeader heading="Programs" sort={renderSortOrder} filters={filters} />; //action={buttonWithSuperAdminPermissions}
  }, [renderSortOrder, filters]);

  const rows = useMemo(() => {
    if (!programs.result.length) {
      return [];
    }

    return programs.result.map((id) => {
      const program: IProgramBase = programs.entities.byId[id];

      return {
        code: program.id,
        name: program.name,
        year: program.year?.replace('-', ' - '),
        gradeLevels: program.gradeLevels?.join(';'),
        viewBtn: (
          <Button.Secondary size="small" clickHandler={() => redirectToProgramDetails(id)}>
            View
          </Button.Secondary>
        ),
        // rolloverBtn: (
        //   <Button.Secondary size="small" clickHandler={() => alert("roll over " + program.name)}>
        //     Rollover
        //   </Button.Secondary>
        // ),
      };
    });
  }, [programs.entities, programs.result, redirectToProgramDetails]);

  const footer = useMemo(() => {
    return (
      <PaginationLocal
        onPageChange={setCurrentPage}
        onPerPageChange={setPerPage}
        totalCount={totalCount}
        perPage={perPage}
        currentPage={currentPage}
        disabled={loading}
      />
    );
  }, [currentPage, perPage, setCurrentPage, setPerPage, totalCount, loading]);

  const content = useMemo(() => {
    return (
      <PageLayoutWithFixedAreas withSecondaryNavigation={false} header={header} footer={footer}>
        {loading ? <Loading /> : !!rows.length ? <Table rows={rows} columns={columns} /> : 'No results found'}
      </PageLayoutWithFixedAreas>
    );
  }, [footer, header, loading, rows]);

  const sidebar = useMemo(() => <ProgramsAllSidebarFilters />, []);

  return <PageLayoutTwoCol withSecondaryNavigation={false} sidebar={sidebar} content={content} />;
};

const columns: any[] = [
  {id: 'name', label: 'Program', className: 'programs-name-col'},
  {id: 'year', label: 'Program Year', className: 'programs-year-col'},
  {id: 'gradeLevels', label: 'Grade Level', className: 'programs-grade-level-col'},
  {id: 'viewBtn', label: '', align: 'center', className: 'btn-col'},
  // {id: 'rolloverBtn', label: '', align: 'center', className: 'btn-col'},
];

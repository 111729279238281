import {createSlice} from '@reduxjs/toolkit';
import {googleSitesExtraReducer, googleSitesInitialState, googleSitesReducer} from '.';

export const googleSitesSlice = createSlice({
  name: 'googleSites',
  initialState: googleSitesInitialState,
  reducers: googleSitesReducer,
  extraReducers: googleSitesExtraReducer,
});

export const {actions: googleSitesActions} = googleSitesSlice;

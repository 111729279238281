import React, {forwardRef, ReactNode} from 'react';
import {MenuItem as MUIMenuItem} from '@material-ui/core';

interface IMenuItemProps {
  clickHandlerCallback: () => void;
  children: ReactNode;
}

export const MenuItem = forwardRef(({clickHandlerCallback, children}: IMenuItemProps, ref: any) => {
  return (
    <MUIMenuItem ref={ref} onClick={() => clickHandlerCallback()}>
      {children}
    </MUIMenuItem>
  );
});

MenuItem.displayName = 'MenuItem';

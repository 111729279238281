import {Input} from 'components-lib';
import {FieldProps} from 'formik';
import React, {ReactNode} from 'react';
import {FastField, Field} from './Field';
import {useDebounceUserInput} from 'hooks';
import {IWithDisabled} from 'models';

interface IFormInputProps extends IWithDisabled {
  name: string;
  value?: string;
  label?: string;
  debounceDelay?: number;
  type?: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  withIcon?: boolean;
  iconPosition?: 'start' | 'end';
  icon?: ReactNode;
  autoFocus?: boolean;
}

export function FormInput({name, value, label, debounceDelay, disabled = false}: IFormInputProps) {
  return (
    <FastField
      name={name}
      value={value}
      label={label}
      disabled={disabled}
      debounceDelay={debounceDelay}
      Component={InputDecorated}
    />
  );
}

/**
 * Notes:
 * Use input without formik FastField if your component depends on other component
 * (see: https://stackoverflow.com/questions/59841681/when-not-to-use-formiks-fastfield)
 */

export function FormInputWithField({
  name,
  value,
  label,
  debounceDelay,
  type,
  min,
  max,
  disabled = false,
  withIcon = false,
  autoFocus = false,
  iconPosition,
  icon,
}: IFormInputProps) {
  return (
    <Field
      name={name}
      value={value}
      label={label}
      disabled={disabled}
      debounceDelay={debounceDelay}
      type={type}
      min={min}
      max={max}
      withIcon={withIcon}
      icon={icon}
      iconPosition={iconPosition}
      autoFocus={autoFocus}
      Component={InputDecorated}
    />
  );
}

interface IInputDecoratedProps extends FieldProps, IFormInputProps {}

function InputDecorated({
  field: {name, onChange},
  form: {values},
  debounceDelay,
  value: initialVal,
  ...rest
}: IInputDecoratedProps) {
  const initialValue: string = !!values[name] ? values[name] : initialVal;
  const [value, changeHandler] = useDebounceUserInput(initialValue, onChange, debounceDelay);

  const props = {
    ...rest,
    value,
    changeHandler,
  };

  return <Input withMargin {...props} />;
}

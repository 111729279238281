import React, {FC, Fragment, useCallback, useMemo, useState} from 'react';
import {Chip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {cardTypeMap} from 'admin/library/enums';
import {Button, Card, Column, Divider, Row, Text, Wrapper} from 'components-lib';
import {useNavigate} from 'hooks';
import {IAnswerStack} from 'models';
import {pages} from 'paths';
import {EntityCardAnswers} from 'admin/library/components/EntityCard/EntityCardAnswers';
import {useItem, usePublish} from 'admin/library/items/hooks';
import {getIsJaUSA, updateNameConceptsWithKSAs} from '../../utils';
import {USAAndSuperAdminRoles, withRole} from 'permissions';

interface IItemCardProps {
  item: IAnswerStack;
}

const dateFormat = 'MMM DD, YYYY';

const ButtonSecondaryWithSuperAdminPermissions = withRole(USAAndSuperAdminRoles, '')(Button.Secondary);

export const ItemCard: FC<IItemCardProps> = ({item}) => {
  const classes = useStyles();
  const {push} = useNavigate();
  const [showDetails, setShowDetails] = useState(false);
  const {publishItemAnswerStack, unPublishItemAnswerStack, publishingStack} = usePublish();
  const {promotingStack, promoteAnswerStackClickHandler} = useItem();

  const isItemJAUSA = getIsJaUSA(item.details);
  const showPromoteAnswerStack = !isItemJAUSA;

  const canBeUnpublished = useMemo(() => item.canBeUnpublished === true && item.isApproved === true, [
    item.canBeUnpublished,
    item.isApproved,
  ]);

  const canBePublished = useMemo(() => item.canEdit === true && item.isApproved === false, [
    item.canEdit,
    item.isApproved,
  ]);

  const toggleDetails = useCallback(() => {
    setShowDetails(!showDetails);
  }, [showDetails]);

  const togglePublish = useCallback(() => {
    if (canBeUnpublished) {
      unPublishItemAnswerStack(item.questionAnswerStackId, item.questionId);
    } else if (canBePublished) {
      publishItemAnswerStack(item.questionAnswerStackId, item.questionId);
    }
  }, [
    canBePublished,
    canBeUnpublished,
    item.questionAnswerStackId,
    item.questionId,
    publishItemAnswerStack,
    unPublishItemAnswerStack,
  ]);

  const handleEdit = useCallback(() => {
    push(`${pages.adminPortal.items.editAnswerStackLink}/${item.questionId}/${item.questionAnswerStackId}`);
  }, [item.questionAnswerStackId, item.questionId, push]);

  const handleAddToJSA = useCallback(
    () => promoteAnswerStackClickHandler(item.questionAnswerStackId, item.questionId),
    [promoteAnswerStackClickHandler, item.questionAnswerStackId, item.questionId]
  );

  const Chips = useMemo(
    () => () => (
      <Row justify="flex-start" classes={{root: classes.metaData}}>
        {Object.entries(item.metaData).map(([key, val]) => {
          if (!val) return val;
          const hasName = !!val.name;
          const name = hasName ? val.name : val;
          const getChip = (label: string) => <Chip className={classes.chip} key={key} label={label} />;

          if (Array.isArray(val)) {
            return val.map((value) => getChip(`${updateNameConceptsWithKSAs(key)}: ${value.name}`));
          }

          return getChip(`${updateNameConceptsWithKSAs(key)}: ${name}`);
        })}
      </Row>
    ),
    [classes.metaData, classes.chip, item.metaData]
  );

  const ItemDetails = useMemo(
    () => () => (
      <Column className={classes.details}>
        <Row justify="flex-start">
          <Text.Paragraph>Author: {item.details.createdBy}</Text.Paragraph>
        </Row>
        <Row justify="flex-start">
          <Text.Paragraph>Date Created: {moment(item.details.createdOn).format(dateFormat)}</Text.Paragraph>
        </Row>
      </Column>
    ),
    [classes.details, item.details]
  );

  return (
    <Card>
      <Row classes={{root: classes.header}}>
        <Text.Paragraph variant="body2">{item.details.creatorArea}</Text.Paragraph>
        <Divider.Vertical size="small" />
        {item?.questionType && (
          <Fragment>
            <Text.Paragraph variant="body2">{cardTypeMap.get(item?.questionType)}</Text.Paragraph>
            <Divider.Vertical size="small" />
          </Fragment>
        )}
        <Text.Paragraph variant="body2">Item ID: {item.questionAnswerStackId}</Text.Paragraph>
        <Wrapper className={classes.actions}>
          {showPromoteAnswerStack && (
            <ButtonSecondaryWithSuperAdminPermissions
              clickHandler={handleAddToJSA}
              size="small"
              disabled={promotingStack}
            >
              Add to JA USA
            </ButtonSecondaryWithSuperAdminPermissions>
          )}
          {item?.canEdit && (
            <Button.Secondary clickHandler={handleEdit} size="small" disabled={publishingStack || canBeUnpublished}>
              Edit
            </Button.Secondary>
          )}
          {(canBeUnpublished || canBePublished) && (
            <Button.Primary size="small" clickHandler={togglePublish} disabled={publishingStack}>
              {publishingStack ? 'loading...' : canBeUnpublished ? 'Un-publish' : canBePublished ? 'Publish' : ''}
            </Button.Primary>
          )}
        </Wrapper>
      </Row>
      <Row>
        {item.choices && (
          <EntityCardAnswers choices={item.choices} questionId={item.questionId} type={item.questionType} />
        )}
      </Row>
      <Row justify="flex-start">
        <Button.Text className={classes.showDetails} clickHandler={toggleDetails} color="primary">
          {showDetails ? 'Hide' : 'Show'} Details
        </Button.Text>
        {showDetails && (
          <Fragment>
            <Chips />
            <ItemDetails />
          </Fragment>
        )}
      </Row>
    </Card>
  );
};

const useStyles = makeStyles((theme) => {
  const marginBottom = theme.spacing(3);

  return {
    header: {
      marginBottom,
    },
    actions: {
      marginLeft: 'auto',
    },
    showDetails: {
      padding: 5,
    },
    metaData: {
      display: 'flex',
      flexDirection: 'row',
      gap: '5px',
    },
    details: {
      marginTop: 15,
      display: 'flex',
      gap: '5px',
    },
    chip: {
      '& .MuiChip-label': {
        color: 400,
      },
    },
  };
});

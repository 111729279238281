import {Accordion, Column, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {fetchPillarsThunk, pillarsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IWithDisabled} from 'models';

export function PillarFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const pillars = useSelector(pillarsEntitiesCollectionSelector);

  useEffect(() => {
    if (!pillars.length) {
      dispatch(fetchPillarsThunk());
    }
  }, [pillars.length, dispatch]);

  return (
    <Accordion heading="Pillars">
      <Column>
        {pillars.length ? (
          pillars.map((pillar) => (
            <Form.Checkbox
              key={pillar.id}
              name={FormNameEnum.pillars}
              value={pillar.id}
              label={pillar.name}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>No Pillars</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
}

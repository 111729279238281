import {Column, Container, Row} from 'components-lib';
import React, {ReactNode} from 'react';
import {useStyles} from './styles';

interface IPageLayoutProps {
  sidebar: ReactNode;
  content: ReactNode;
  withSecondaryNavigation?: boolean;
}

export function PageLayoutTwoCol({sidebar, content, withSecondaryNavigation = true}: IPageLayoutProps) {
  const classes = useStyles(withSecondaryNavigation);

  return (
    <Container classes={{root: classes.root}} disableGutters>
      <Row classes={{root: classes.row}}>
        <Column sm={3} md={3} lg={3} xl={3} className={classes.sidebar}>
          {sidebar && sidebar}
        </Column>
        <Column sm={9} md={9} lg={9} xl={9} className={classes.content}>
          {content && content}
        </Column>
      </Row>
    </Container>
  );
}

import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/core';
import {Button, Column, Divider, Row, Text} from 'components-lib';

interface IPageViewHeaderProps {
  heading?: string;
  subHeading?: ReactNode;
  extraHeading?: ReactNode;
  action?: ReactNode;
  sort?: ReactNode;
  search?: ReactNode;
  extraSubHeading?: ReactNode;
  filters?: any; // TODO
  withBackButton?: boolean;
  withDivider?: boolean;
  shouldDisableBackButton?: boolean;
  backButtonClickHandler?: () => void;
  headingColor?: 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'error';
  fullFlexWidth?: boolean;
  withColumnPadding?: boolean;
}

export function PageViewHeader({
  heading,
  action,
  sort,
  search,
  subHeading,
  extraHeading,
  extraSubHeading,
  filters,
  withBackButton = false,
  withDivider = true,
  shouldDisableBackButton = false,
  backButtonClickHandler,
  headingColor,
  fullFlexWidth = false,
  withColumnPadding = true,
}: IPageViewHeaderProps) {
  const classes = useStyles({fullFlexWidth, withColumnPadding});
  const hasFilters = !!(filters && filters.length);

  return (
    <Column className={classes.root}>
      <Row justify="space-between">
        <div className={classes.flex}>
          <div className={classes.headingContainer}>
            {!!withBackButton && (
              <Button.Back
                clickHandler={backButtonClickHandler}
                className={classes.backButton}
                disabled={shouldDisableBackButton}
              />
            )}
            {!!heading && (
              <Text.Heading as="h1" color={headingColor}>
                {heading}
              </Text.Heading>
            )}
            {!!subHeading && subHeading}
          </div>
          {extraSubHeading && extraSubHeading}
        </div>
        {action && action}
      </Row>
      {!!extraHeading && <Row justify="flex-start">{extraHeading}</Row>}
      {(sort || hasFilters || search) && (
        <Row justify="flex-start" classes={{root: classes.row}}>
          <div>{search && search}</div>
          <div>{sort && sort}</div>

          {hasFilters && withDivider && <Divider.Vertical />}
          <div>{hasFilters && filters}</div>
        </Row>
      )}
    </Column>
  );
}

type TStyleProps = Pick<IPageViewHeaderProps, 'fullFlexWidth'>;

const useStyles = makeStyles((theme) => ({
  root: ({withColumnPadding}: any) => {
    if (!withColumnPadding) {
      return;
    }

    return {paddingTop: theme.spacing(3), paddingBottom: theme.spacing(2)};
  },
  row: {
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  flex: ({fullFlexWidth}: TStyleProps) => {
    let styles: any = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    };

    if (fullFlexWidth) {
      styles = {...styles, width: '100%'};
    }
    return styles;
  },
  headingContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

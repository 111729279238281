import React, {FC, useCallback, useContext} from 'react';
import {useBeforeunload} from 'react-beforeunload';
import {ModalUpload} from 'admin';
import {UploadPaperAssessmentContent} from '../UploadPaperAssessmentContent/UploadPaperAssessmentContent';
import {FormContext} from 'forms/formik/FormContextProvider';
import {IWithModal} from 'models';
import {useUploadPaperAssessmentModal} from 'admin/programs/details';
import {uploadPaperAssessmentMessages} from '../../utils';

interface IUploadPaperAssessmentModalProps extends IWithModal {
  closeClickHandler: () => void;
}

export const UploadPaperAssessmentModal: FC<IUploadPaperAssessmentModalProps> = ({isOpen, closeClickHandler}) => {
  const {formikFormRef, setIsDirty, isDirty} = useContext(FormContext);
  const {uploadLoading: loading} = useUploadPaperAssessmentModal();

  useBeforeunload(() => {
    if (loading) {
      return uploadPaperAssessmentMessages.warning;
    }
  });

  const submitFormClickHandler = useCallback(() => formikFormRef?.current?.submitForm(), [formikFormRef]);

  return (
    <ModalUpload
      disableBackdropClick
      title="Upload Paper Assessment Responses"
      buttonsSize="large"
      content={
        <UploadPaperAssessmentContent
          loading={loading}
          isDirty={isDirty}
          setIsDirtyCallback={setIsDirty}
          closeModalClickHandler={closeClickHandler}
        />
      }
      isOpen={isOpen}
      disableSubmit={!isDirty || loading}
      confirmHandler={submitFormClickHandler}
      closeHandler={closeClickHandler}
    />
  );
};

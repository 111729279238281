import React, {Fragment, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Column, Container, Row, Wrapper} from 'components-lib';
import {GoogleSitesDestinationContent} from './components';
import {heightThreeStepsActivity} from 'utils/layout';
import {Subtitle} from 'admin/components';

export const AddIntegrationGoogleSitesDestination = () => {
  const classes = useStyles();

  const content = useMemo(
    () => (
      <Fragment>
        <Subtitle withColor={false} as="h3" color="textPrimary" title="Step 2: Google Sites Destination" />
        <Container disableGutters classes={{root: classes.content}}>
          <Row align="flex-start" justify="flex-start" classes={{root: classes.row}}>
            <Column sm={8} md={8} lg={8} xl={9}>
              <Row align="flex-start" justify="flex-start">
                <GoogleSitesDestinationContent />
              </Row>
            </Column>
            <Column sm={4} md={4} lg={4} xl={3} />
          </Row>
        </Container>
      </Fragment>
    ),
    [classes.row, classes.content]
  );

  return <Wrapper className={classes.content}>{content}</Wrapper>;
};

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(2),
  },
  content: {
    height: heightThreeStepsActivity,
    paddingRight: theme.spacing(4),
  },
}));

import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {pages} from 'paths';
import {FilesEditView, FilesViewAll} from './views';

export function Files() {
  return (
    <Switch>
      <Route exact path={pages.adminPortal.files.root} component={FilesViewAll} />
      <Route path={pages.adminPortal.files.edit} component={FilesEditView} />
    </Switch>
  );
}

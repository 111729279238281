import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';

const getProgramsState = (state: TStateStore) => state.programs.classes;

export const pageSelector = createSelector(getProgramsState, (classes) => classes.page);
export const pageLoadingSelector = createSelector(getProgramsState, (classes) => classes.page.loading);

export const poolSelector = createSelector(getProgramsState, (classes) => classes.pool);

export const filterSelector = createSelector(getProgramsState, (classes) => classes.filter);
export const paginationSelector = createSelector(getProgramsState, (classes) => classes.pagination);
export const programsClassesSortOrderSelector = createSelector(getProgramsState, (programs) => programs.sortOrder);

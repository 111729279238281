import {useSelector} from 'react-redux';
import {useFormikContext} from 'formik';
import {filtersCompetenciesEntitiesCollectionSelector, FormNameEnum} from 'filters-selections';
import {IAddWebLinkFormBaseValues, IUploadFileFormBaseValues, IUploadScormFormBaseValues} from 'admin/resources/upload';

export const useIsCompetencySelected = () => {
  const {values} = useFormikContext<
    IUploadFileFormBaseValues | IUploadScormFormBaseValues | IAddWebLinkFormBaseValues
  >();
  const comptencies = useSelector(filtersCompetenciesEntitiesCollectionSelector);
  const comptencyIds = values[FormNameEnum.competencies] as number[];
  const selectedCompetencies = comptencies.filter(({id}) => comptencyIds.includes(id));
  const hasCompetenciesSelected = selectedCompetencies.length > 0;

  return {hasCompetenciesSelected, comptencyIds};
};

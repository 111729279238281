import {FiltersEnum} from 'filters-selections';

export const filesAllFilterList = [
  FiltersEnum.ProgramMulti,
  FiltersEnum.TagGradeLevels,
  FiltersEnum.Languages,
  FiltersEnum.Pillar,
  FiltersEnum.Competencies,
  FiltersEnum.KnowledgeSkillsAndAbilities,
  FiltersEnum.FileTypeMulti,
  FiltersEnum.AccessibleToStudentsRadio,
  FiltersEnum.FileStatusesCheckbox,
  FiltersEnum.PublishedByRadio,
];

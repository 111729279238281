import React, {memo, useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';

import {Button, Divider, Icon, Row, Text, Wrapper} from 'components-lib';
import {EntitiesPerPageDropdown} from './EntitiesPerPageDropdown';
import {PageButton} from './PageButton';
import {EntitiesPerPageEnum, PaginationNumEnum} from 'enums';
import {DISPLAY_PAGE_MARGIN} from 'utils';

interface IProps {
  currentPage: number;
  perPage: EntitiesPerPageEnum;
  totalCount: number;
  isVisible?: boolean;
  disabled?: boolean;
  onPageChange: (newPage: number) => void;
  onPerPageChange: (perPage: EntitiesPerPageEnum) => void;
}

function calcOverallPages(totalCount: number, perPage: number): number {
  return Math.ceil(totalCount / perPage);
}

function PaginationLocal({
  currentPage = PaginationNumEnum.ZERO,
  perPage = EntitiesPerPageEnum.FIFTY,
  totalCount = PaginationNumEnum.ZERO,
  onPageChange,
  onPerPageChange,
  isVisible = true,
  disabled,
}: IProps) {
  const classes = useStyles({isVisible});
  const overallPages = calcOverallPages(totalCount, perPage);
  const shouldDisablePrevButton = currentPage === PaginationNumEnum.ONE || disabled;
  const shouldDisableNextButton = currentPage === overallPages || disabled;

  const onPageClicked = useCallback(
    (page: number) => {
      onPageChange(page);
    },
    [onPageChange]
  );

  const onPrevPageBtnClicked = useCallback(() => {
    if (currentPage > PaginationNumEnum.ONE) {
      onPageChange(currentPage - PaginationNumEnum.ONE);
    }
  }, [currentPage, onPageChange]);

  const onNextPageBtnClicked = useCallback(() => {
    if (currentPage < overallPages) {
      onPageChange(currentPage + PaginationNumEnum.ONE);
    }
  }, [currentPage, overallPages, onPageChange]);

  const generatePages = useCallback(() => {
    const pageNumberMapper: Map<number, any> = new Map();
    let keyId = 1;

    if (currentPage !== PaginationNumEnum.ONE) {
      pageNumberMapper.set(
        PaginationNumEnum.ONE,
        <PageButton clickHandler={() => onPageClicked(1)} key={`pn${keyId}`} variant="light">
          1
        </PageButton>
      );
    }

    // do it with recursion
    for (let pn = -DISPLAY_PAGE_MARGIN; pn <= DISPLAY_PAGE_MARGIN; pn++) {
      if (pn === PaginationNumEnum.ZERO) {
        keyId++;
        pageNumberMapper.set(
          currentPage,
          <PageButton
            disabled={disabled}
            variant="primary"
            clickHandler={() => onPageClicked(currentPage)}
            key={`pn${keyId}`}
          >
            {currentPage}
          </PageButton>
        );
      } else {
        if (currentPage + pn > PaginationNumEnum.ZERO && currentPage + pn < overallPages) {
          keyId++;
          const currentPageNum = currentPage + pn;
          pageNumberMapper.set(
            currentPageNum,
            <PageButton
              disabled={disabled}
              variant="light"
              key={`pn${keyId}`}
              clickHandler={() => onPageClicked(currentPageNum)}
            >
              {currentPageNum}
            </PageButton>
          );
        }
      }
    }

    keyId++;
    if (currentPage !== overallPages) {
      pageNumberMapper.set(
        overallPages,
        <PageButton
          disabled={disabled}
          key={`pn${keyId}`}
          variant="light"
          clickHandler={() => onPageClicked(overallPages)}
        >
          {overallPages}
        </PageButton>
      );
    }

    const listOfPages: any[] = [];
    const pageIndexes = Array.from(pageNumberMapper.keys());

    for (let index = 0; index < pageIndexes.length; index++) {
      if (index === 0 || index === pageIndexes.length - 1) {
        listOfPages.push(pageNumberMapper.get(pageIndexes[index]));
        continue;
      }

      for (let dn = -1; dn <= 1; dn++) {
        if (dn === 0) {
          listOfPages.push(pageNumberMapper.get(pageIndexes[index]));
        } else {
          if (pageIndexes[index] + dn !== pageIndexes[index + dn]) {
            keyId++;
            listOfPages.push(
              <PageButton disabled={disabled} key={`pn${keyId}`} variant="light">
                ...
              </PageButton>
            );
          }
        }
      }
    }

    return listOfPages;
  }, [currentPage, disabled, overallPages, onPageClicked]);

  const resultsText = useMemo(() => (totalCount !== 1 ? 'Results' : 'Result'), [totalCount]);

  const perPageDropdown = useMemo(
    () => (
      <>
        <Divider.Vertical />
        <EntitiesPerPageDropdown
          disabled={disabled}
          entitiesPerPage={perPage}
          onEntitiesPerPageChosen={onPerPageChange}
        />
      </>
    ),
    [disabled, perPage, onPerPageChange]
  );

  const pagination = useMemo(() => {
    const getIconColor = (shouldDisable: boolean) => {
      return shouldDisable ? 'disabled' : 'primary';
    };

    const prevButtonIconColor = getIconColor(shouldDisablePrevButton);
    const nextButtonIconColor = getIconColor(shouldDisableNextButton);

    return (
      <>
        <Button.Icon clickHandler={onPrevPageBtnClicked} disabled={shouldDisablePrevButton}>
          <Icon.TrendingLeft color={prevButtonIconColor} />
        </Button.Icon>
        <div key={currentPage} className={classes.item}>
          {generatePages()}
        </div>
        <Button.Icon clickHandler={onNextPageBtnClicked} disabled={shouldDisableNextButton}>
          <Icon.TrendingRight color={nextButtonIconColor} />
        </Button.Icon>
      </>
    );
  }, [
    classes.item,
    currentPage,
    generatePages,
    onNextPageBtnClicked,
    onPrevPageBtnClicked,
    shouldDisableNextButton,
    shouldDisablePrevButton,
  ]);

  return (
    <Row justify="space-between" classes={{root: classes.root}}>
      <Wrapper className={classes.item}>
        <Wrapper className={classes.count}>
          <Text.Caption>
            {totalCount} {resultsText}
          </Text.Caption>
        </Wrapper>
        {totalCount > PaginationNumEnum.ONE && perPageDropdown}
      </Wrapper>
      <Wrapper className={classes.item}>{totalCount > perPage && pagination}</Wrapper>
    </Row>
  );
}

const useStyles = makeStyles((theme) => ({
  root: ({isVisible}: Pick<IProps, 'isVisible'>) => ({
    display: isVisible ? '' : 'none',
    paddingTop: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  }),
  count: {
    minWidth: 80,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

export default memo(PaginationLocal);

import {IActionFulfilled} from 'models';
import {normalize} from 'normalizr';
import {isObject} from 'utils';
import {IIntegrationStoreState} from './IIntegrationsStoreState';
import {integrationsListSchema} from './normalizationSchemas';
import {
  createGoogleSitesDestinationThunk,
  createGoogleSitesSourceThunk,
  createGoogleVirtualClassThunk,
  createMicrosoftTeamsThunk,
  deleteIntegrationThunk,
  fetchAuditLogThunk,
  fetchIntegrationDetailsThunk,
  fetchIntegrationsPagedThunk,
  fetchPublishedSourceDetailsThunk,
  fetchServiceEmailThunk,
  publishIntegrationThunk,
  testGoogleClassroomThunk,
  testGoogleSitesDestinationThunk,
  testGoogleSitesSourceThunk,
  testMicrosoftTeamsThunk,
  updateGoogleClassroomIntegrationThunk,
  updateGoogleSitesDestinationIntegrationThunk,
  updateGoogleSitesSourceIntegrationThunk,
  updateMicrosoftTeamsIntegrationThunk,
} from './thunks';
import {
  IGetIntegrationDetailApiResponse,
  IGetServiceEmailResponse,
  IBindIntegrationsAllPage,
  IGetAuditLogResponse,
  ITestMicrosoftTeamsIntegrationApiResponse,
  ITestGoogleClassroomIntegrationApiResponse,
  IUpdateGoogleSitesSourceApiResponse,
  IUpdateGoogleSitesDestinationApiResponse,
  IUpdateGoogleClassroomApiResponse,
  ITestBaseIntegrationApiResponse,
  IGetPublishedApiSourceDetails,
} from 'models/integrations';
import {IntegrationStatusNum} from 'enums/integrations';
import {DEFAULT_CLIENT_SECRET} from 'admin';

export const integrationsExtraReducer = {
  // fetchIntegrationsPagedThunk
  [fetchIntegrationsPagedThunk.fulfilled]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, IBindIntegrationsAllPage>
  ) => {
    if (isObject(action.payload)) {
      const {
        data,
        metadata: {totalCount, pageNumber, pageSize},
      } = action.payload;

      state.integrationList = normalize(data, integrationsListSchema);
      state.pagination.totalCount = totalCount;
      state.pagination.currentPage = pageNumber;
      state.pagination.perPage = pageSize;
    }

    state.page.loading = false;
  },
  [fetchIntegrationsPagedThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [fetchIntegrationsPagedThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // fetchIntegrationDetailsThunk
  [fetchIntegrationDetailsThunk.fulfilled]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, IGetIntegrationDetailApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.integration = {...data};
    }

    state.page.loading = false;
  },
  [fetchIntegrationDetailsThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [fetchIntegrationDetailsThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // createGoogleSitesDestinationThunk
  [createGoogleSitesDestinationThunk.fulfilled]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },
  [createGoogleSitesDestinationThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [createGoogleSitesDestinationThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // createGoogleSitesSourceThunk
  [createGoogleSitesSourceThunk.fulfilled]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },
  [createGoogleSitesSourceThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [createGoogleSitesSourceThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // createGoogleSitesDestinationThunk
  [createGoogleVirtualClassThunk.fulfilled]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },
  [createGoogleVirtualClassThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [createGoogleVirtualClassThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // createMicrosoftTeamsThunk
  [createMicrosoftTeamsThunk.fulfilled]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },
  [createMicrosoftTeamsThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [createMicrosoftTeamsThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // fetchServiceEmailThunk
  [fetchServiceEmailThunk.fulfilled]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, IGetServiceEmailResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;

      state.addIntegration.metadata.serviceSourceEmail = data;
    }
  },
  [fetchServiceEmailThunk.rejected]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, IGetServiceEmailResponse>
  ) => {
    state.page.loading = false;

    if (isObject(action.payload)) {
      const {messages} = action.payload.error;

      state.addIntegration.metadata.errorMessage = messages;
    }
  },

  // fetchPublishedSourceDetailsThunk
  [fetchPublishedSourceDetailsThunk.fulfilled]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, IGetPublishedApiSourceDetails>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;

      state.addIntegration.metadata = {
        id: data.id,
        name: data.name,
        serviceSourceEmail: data.serviceEmailAccount,
        errorMessage: null,
      };
    }
  },
  [fetchPublishedSourceDetailsThunk.rejected]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, IGetPublishedApiSourceDetails>
  ) => {
    state.page.loading = false;

    if (isObject(action.payload)) {
      const {messages} = action.payload.error;

      state.addIntegration.metadata.errorMessage = messages;
    }
  },

  // fetchAuditLogThunk
  [fetchAuditLogThunk.fulfilled]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, IGetAuditLogResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.details.auditLogs.entities = data;
      state.details.auditLogs.loading = false;
    }
  },
  [fetchAuditLogThunk.pending]: (state: IIntegrationStoreState) => {
    state.details.auditLogs.loading = true;
  },
  [fetchAuditLogThunk.rejected]: (state: IIntegrationStoreState) => {
    state.details.auditLogs.loading = false;
  },

  // deleteIntegrationThunk
  [deleteIntegrationThunk.fulfilled]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },
  [deleteIntegrationThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [deleteIntegrationThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // updateMicrosoftTeamsIntegrationThunk
  [updateMicrosoftTeamsIntegrationThunk.fulfilled]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, any>
  ) => {
    state.page.loading = false;

    if (isObject(action.payload)) {
      const {data} = action.payload;

      state.integration = {
        ...state.integration,
        name: data.details.name,

        configuration: {
          ...data.details.configuration,
          authorityUrl: data.details.configuration.authorityUrl,
          clientId: data.details.configuration.clientId,
          clientSecret: DEFAULT_CLIENT_SECRET,
        },
      };
    }
  },
  [updateMicrosoftTeamsIntegrationThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [updateMicrosoftTeamsIntegrationThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // updateGoogleSitesSourceIntegrationThunk
  [updateGoogleSitesSourceIntegrationThunk.fulfilled]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, IUpdateGoogleSitesSourceApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;

      state.integration = {
        ...state.integration,
        name: data.details.name,

        configuration: {
          ...data.details.configuration,
          defaultFolderId: data.details.configuration.defaultFolderId,
          serviceEmailAccount: data.details.configuration.serviceEmailAccount,
        },
      };
    }

    state.page.loading = false;
  },
  [updateGoogleSitesSourceIntegrationThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [updateGoogleSitesSourceIntegrationThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // updateGoogleSitesDestinationIntegrationThunk
  [updateGoogleSitesDestinationIntegrationThunk.fulfilled]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, IUpdateGoogleSitesDestinationApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;

      state.integration = {
        ...state.integration,
        name: data.details.name,
        configuration: {
          ...data.details.configuration,
          defaultFolderId: data.details.configuration.defaultFolderId,
        },
      };
    }

    state.page.loading = false;
  },
  [updateGoogleSitesDestinationIntegrationThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [updateGoogleSitesDestinationIntegrationThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // updateGoogleClassroomIntegrationThunk
  [updateGoogleClassroomIntegrationThunk.fulfilled]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, IUpdateGoogleClassroomApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;

      state.integration = {
        ...state.integration,
        name: data.details.name,

        configuration: {
          ...data.details.configuration,
          serviceEmailAccount: data.details.configuration.serviceEmailAccount,
        },
      };
    }

    state.page.loading = false;
  },
  [updateGoogleClassroomIntegrationThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [updateGoogleClassroomIntegrationThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // testMicrosoftTeamsThunk
  [testMicrosoftTeamsThunk.fulfilled]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, ITestMicrosoftTeamsIntegrationApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.integration = {
        ...state.integration,
        status: data ? IntegrationStatusNum.TEST_IN_PROGRESS : IntegrationStatusNum.TEST_FAILED,
      };
    }
    state.page.loading = false;
  },
  [testMicrosoftTeamsThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [testMicrosoftTeamsThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // testGoogleClassroomThunk
  [testGoogleClassroomThunk.fulfilled]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, ITestGoogleClassroomIntegrationApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.integration = {
        ...state.integration,
        status: data ? IntegrationStatusNum.TEST_IN_PROGRESS : IntegrationStatusNum.TEST_FAILED,
      };
    }
    state.page.loading = false;
  },
  [testGoogleClassroomThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [testGoogleClassroomThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // testGoogleSitesSourceThunk
  [testGoogleSitesSourceThunk.fulfilled]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, ITestBaseIntegrationApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.integration = {
        ...state.integration,
        status: data ? IntegrationStatusNum.TEST_SUCCESS : IntegrationStatusNum.TEST_FAILED,
      };
    }
    state.page.loading = false;
  },
  [testGoogleSitesSourceThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [testGoogleSitesSourceThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // testGoogleSitesDestinationThunk
  [testGoogleSitesDestinationThunk.fulfilled]: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, ITestBaseIntegrationApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.integration = {
        ...state.integration,
        status: data ? IntegrationStatusNum.TEST_SUCCESS : IntegrationStatusNum.TEST_FAILED,
      };
    }
    state.page.loading = false;
  },
  [testGoogleSitesDestinationThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [testGoogleSitesDestinationThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },

  // publishIntegrationThunk
  [publishIntegrationThunk.fulfilled]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },
  [publishIntegrationThunk.pending]: (state: IIntegrationStoreState) => {
    state.page.loading = true;
  },
  [publishIntegrationThunk.rejected]: (state: IIntegrationStoreState) => {
    state.page.loading = false;
  },
};

import React, {Fragment, useMemo} from 'react';
import {ResourceUserDetails} from 'admin/resources';
import {IBoxListItem} from 'models/resources';
import {Column, Divider} from 'components-lib';
import {useFileEdit} from '../../hooks';
import {getUserDetails} from 'admin/resources/learning-paths/views/edit';

export const FilesEditUserDetails = () => {
  const {userDetails} = useFileEdit();
  const {
    createdBy,
    updatedBy,
    createdUtc,
    modifiedUtc,
    createdByInitials,
    updatedByInitials,
    emailCreator,
    emailUpdater,
  } = getUserDetails(userDetails);

  const userList = useMemo(
    (): IBoxListItem[] => [
      {
        size: 'small',
        label: 'Created By',
        content: createdBy,
        userInitials: createdByInitials,
      },
      {
        label: 'Date Created',
        content: createdUtc,
      },
      {
        size: 'small',
        label: 'Updated by',
        content: updatedBy,
        userInitials: updatedByInitials,
      },
      {
        label: 'Date Last Updated',
        content: modifiedUtc,
      },
    ],
    [createdBy, updatedBy, createdUtc, modifiedUtc, createdByInitials, updatedByInitials]
  );

  const emailList = useMemo(
    (): IBoxListItem[] => [
      {
        size: 'small',
        label: 'Email Address',
        content: emailCreator,
      },
      {
        label: '',
        content: '',
      },
      {
        size: 'small',
        label: 'Email Address',
        content: emailUpdater,
      },
      {
        label: '',
        content: '',
      },
    ],
    [emailCreator, emailUpdater]
  );

  return (
    <Fragment>
      <ResourceUserDetails list={userList} />
      <ResourceUserDetails list={emailList} />
      <Column sm={12} md={12} lg={12} xl={8}>
        <Divider.Horizontal as="div" fullWidth />
      </Column>
    </Fragment>
  );
};

import {useState, ChangeEvent, useCallback, useEffect} from 'react';
import {useDelay} from 'hooks';

const DEBOUNCE_TIMEOUT = 350;

export function useDebounceUserInput(
  initialVal: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  debounceDelay: number = DEBOUNCE_TIMEOUT
): [string, (event: ChangeEvent<HTMLInputElement>) => void] {
  const [value, setValue] = useState(initialVal);
  const delay = useDelay(debounceDelay);

  const changeValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();

      setValue(event.target.value);

      delay(() => onChange(event));
    },
    [onChange, delay]
  );

  useEffect(() => setValue(initialVal), [initialVal]);

  return [value, changeValue];
}

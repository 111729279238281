import {BuilderItemDragTypeEnum} from 'admin/library/enums';
import {useDrop} from 'react-dnd';

export function useBuilderDrop() {
  const [{canDrop, isOver}, drop] = useDrop({
    accept: BuilderItemDragTypeEnum.item,
    drop: () => ({
      name: `${allowedDropEffect}`,
      allowedDropEffect,
    }),
    canDrop: () => {
      return true;
    },
    // options
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;

  return {isActive, drop};
}

const allowedDropEffect = 'any';

import {createAsyncThunk} from '@reduxjs/toolkit';
import {userApi} from 'api';
import {IFetchUserIdAndAreaIdApiResponse, IFetchUserPayload} from 'models';
import {paths} from 'paths';
import {prefix} from './config';

export const fetchUserIdAndAreaIdThunk: any = createAsyncThunk<IFetchUserIdAndAreaIdApiResponse, IFetchUserPayload>(
  `${prefix} fetchUserIdAndAreaId`,
  async (payload: IFetchUserPayload, {rejectWithValue}) => {
    try {
      const {parsedBody} = await userApi.getUserIdAndAreaByUserEmail(payload.email);
      return parsedBody;
    } catch (err) {
      const {push} = payload;
      push(paths.error);
      return rejectWithValue(err);
    }
  }
);

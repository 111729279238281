import React, {useMemo, useEffect, FC} from 'react';
import {useSelector} from 'react-redux';
import {Accordion, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {Form} from 'forms';
import {useAppDispatch} from 'hooks';
import {IWithDisabled} from 'models';
import {fetchConceptsThunk, filtersConceptsEntitiesCollectionSelector} from 'filters-selections/store';
import {useFormikContext} from 'formik';
import {useIsCompetencySelected} from 'filters-selections/hooks';

type IKnowledgeSkillsAndAbilitiesFilterProps = IWithDisabled;

export const KnowledgeSkillsAndAbilitiesFilter: FC<IKnowledgeSkillsAndAbilitiesFilterProps> = ({disabled}) => {
  const dispatch = useAppDispatch();
  const knowledgeSkillsAndAbilities = useSelector(filtersConceptsEntitiesCollectionSelector);
  const {values, setFieldValue} = useFormikContext<any>();
  const {hasCompetenciesSelected} = useIsCompetencySelected();
  const shouldDisableKSAs = !hasCompetenciesSelected;

  useEffect(() => {
    if (values?.competencies?.length) {
      dispatch(fetchConceptsThunk(values.competencies));
    }
  }, [values.competencies, dispatch]);

  useEffect(() => {
    if (!hasCompetenciesSelected && values.competencies.length === 0 && values.knowledgeSkillsAndAbilities.length > 0) {
      setFieldValue(FormNameEnum.knowledgeSkillsAndAbilities, []);
    }
  }, [hasCompetenciesSelected, values.competencies, values.knowledgeSkillsAndAbilities, setFieldValue]);

  const options = useMemo(
    () =>
      knowledgeSkillsAndAbilities.map((knowledgeSkill) => ({
        value: knowledgeSkill.id,
        label: knowledgeSkill.name,
      })),
    [knowledgeSkillsAndAbilities]
  );

  return (
    <Accordion heading="KSAs">
      {knowledgeSkillsAndAbilities && knowledgeSkillsAndAbilities.length && !shouldDisableKSAs ? (
        <Form.SelectAutocomplete
          name={FormNameEnum.knowledgeSkillsAndAbilities}
          options={options}
          multiple
          disabled={disabled || shouldDisableKSAs}
        />
      ) : (
        <Text.Caption>Choose a competency first</Text.Caption>
      )}
    </Accordion>
  );
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {itemsApi} from 'api';
import {IBindBaseEntity} from 'models';
import {prefix} from '../../../instruments/store/thunks/config';

export const fetchSortByColumnsThunk: any = createAsyncThunk<IBindBaseEntity[]>(
  `${prefix} fetchSortByColumns`,
  async () => {
    const {parsedBody} = await itemsApi.getItemsSortByColumns();
    return parsedBody;
  }
);

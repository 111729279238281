import {createAsyncThunk} from '@reduxjs/toolkit';
import {integrationsApi} from 'api';
import {IUpdateGoogleSitesSourceIntegrationPayload} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {integrationDetailsMessages} from '../../details/utils/constants';
import {prefix} from './config';

interface IUpdateGoogleSitesSource {
  integrationId: string;
  payload: IUpdateGoogleSitesSourceIntegrationPayload;
}

export const updateGoogleSitesSourceIntegrationThunk: any = createAsyncThunk(
  `${prefix} updateGoogleSitesSourceIntegration`,
  async ({integrationId, payload}: IUpdateGoogleSitesSource, {dispatch, rejectWithValue}) => {
    try {
      const {data} = await integrationsApi.updateGoogleSiteSourceIntegration(integrationId, payload);
      dispatch(toastNotificationManager.createSuccessToastAction(integrationDetailsMessages.updateIntegrationSuccess));
      return data;
    } catch (error) {
      dispatch(
        toastNotificationManager.createErrorToastAction(
          error.response.data.messages[0] ?? integrationDetailsMessages.unknownIntegrationError
        )
      );
      return rejectWithValue({error});
    }
  }
);

import React, {FC} from 'react';
import {ResourceFooterButtons} from 'admin/resources';
interface ILearningPathFooterProps {
  loading: boolean;
  isSomeOfSessionsWithEmptyName?: boolean;
  withSubmitButton?: boolean;
  withPublishButton?: boolean;
  withDeleteButton?: boolean;
  withCancelButton?: boolean;
  withSaveButton?: boolean;
  disablePublish?: boolean;
  disableSubmit?: boolean;
  disableSave?: boolean;
  openModalClickHandler: () => void;
  publishClickHandler?: () => void;
  deleteClickHandler?: () => void;
  saveClickHandler?: () => void;
}

export const LearningPathFooter: FC<ILearningPathFooterProps> = ({
  loading,
  withSubmitButton = true,
  withPublishButton = false,
  withDeleteButton = false,
  withCancelButton = true,
  withSaveButton = false,
  disablePublish = false,
  disableSubmit = false,
  disableSave = false,
  openModalClickHandler,
  publishClickHandler,
  deleteClickHandler,
  saveClickHandler,
}) => (
  <ResourceFooterButtons
    loading={loading}
    withSubmitButton={withSubmitButton}
    withDeleteButton={withDeleteButton}
    withPublishButton={withPublishButton}
    withCancelButton={withCancelButton}
    withSaveButton={withSaveButton}
    disablePublish={disablePublish}
    disableSubmit={disableSubmit}
    disableSave={disableSave}
    cancelClickHandler={openModalClickHandler}
    publishClickHandler={publishClickHandler}
    deleteClickHandler={deleteClickHandler}
    saveClickHandler={saveClickHandler}
  />
);

import React, {useCallback, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {Button, Text} from 'components-lib';
import {Form} from 'forms';
import {useNavigate} from 'hooks';
import {IWithModal, IWithSetSubmitting} from 'models';
import {pages} from 'paths';
import {createNewInstrumentThunk} from '../../store';
import {mapInstrumentCreateModalMetadata} from '../../utils';
import {CreateInstrumentForm, ICreateInstrumentFormData} from '../CreateInstrumentForm';
import {CreateInstrumentFormFields} from '../CreateInstrumentForm/CreateInstrumentFormFields';
import {ModalCreateNewInstrument} from './ModalCreateNewInstrument';

enum ActiveStepEnum {
  First,
  Second,
}

type IModalCreateNewInstrumentWithFormProps = IWithModal;

export function ModalCreateNewInstrumentWithForm({isOpen, setIsOpen}: IModalCreateNewInstrumentWithFormProps) {
  const dispatch = useDispatch();
  const {push} = useNavigate();
  const [error, setError] = useState<string>('');

  const closeHandler = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const [activeStep, setActiveStep] = useState<ActiveStepEnum>(ActiveStepEnum.First);

  const renderActiveStep = useMemo(() => {
    const steps = {
      [ActiveStepEnum.First]: (
        <>
          <Text.Heading as="h3">Follow the steps below to create a new instrument.</Text.Heading>
          <Text.Paragraph>Create a new template and add metadata.</Text.Paragraph>
          <Text.Paragraph>Customize your template with an approved set of questions and answers.</Text.Paragraph>
          <Text.Paragraph>Publish your survey and share it with students and staff!</Text.Paragraph>
        </>
      ),
      [ActiveStepEnum.Second]: <CreateInstrumentFormFields />,
    };

    return steps[activeStep];
  }, [activeStep]);

  const submitHandler = (
    {instrumentName, audience, phase, pillars, programs, type, subType, isGeneralInstrument}: ICreateInstrumentFormData,
    actions: IWithSetSubmitting
  ) => {
    const metaData = mapInstrumentCreateModalMetadata({
      instrumentName,
      audience,
      phase,
      pillars,
      programs,
      type,
      subType,
      isGeneralInstrument,
    });

    setError('');

    dispatch(createNewInstrumentThunk({metaData, isGeneral: isGeneralInstrument}))
      .then(unwrapResult)
      .then((response: number) => {
        actions?.setSubmitting(false);
        setIsOpen(false);
        setActiveStep(ActiveStepEnum.First);
        push(`${pages.adminPortal.instruments.detailsRoot}/${response}`);
      })
      .catch((error: Error) => {
        actions?.setSubmitting(false);
        setError(error.message);
      });
  };

  const renderNextButton = useMemo(() => {
    const buttons = {
      [ActiveStepEnum.First]: (
        <Button.Primary size="small" clickHandler={() => setActiveStep(ActiveStepEnum.Second)}>
          Next
        </Button.Primary>
      ),
      [ActiveStepEnum.Second]: <Form.ButtonSubmit text="Create" size="small" />,
    };

    return <>{buttons[activeStep]}</>;
  }, [activeStep]);

  return (
    <CreateInstrumentForm submitHandler={submitHandler}>
      <ModalCreateNewInstrument
        isOpen={isOpen}
        error={error}
        activeStep={renderActiveStep}
        nextButton={renderNextButton}
        closeHandler={closeHandler}
      />
    </CreateInstrumentForm>
  );
}

import React, {FC, Fragment} from 'react';
import {FormNameEnum} from 'filters-selections';
import {Form} from 'forms';

interface IEditQuestionEditorProps {
  hasQuestionText: boolean;
  readOnly: boolean;
  withEdit: boolean;
  toolbarHidden: boolean;
}

export const EditQuestionEditor: FC<IEditQuestionEditorProps> = ({
  hasQuestionText,
  readOnly,
  withEdit,
  toolbarHidden,
}) => {
  return (
    <Fragment>
      {!hasQuestionText ? (
        <Fragment>Loading...</Fragment>
      ) : (
        <Form.RichTextEditor
          readOnly={readOnly}
          withEdit={withEdit}
          name={FormNameEnum.questionTextHtml}
          placeholder="Add question text here..."
          toolbarHidden={toolbarHidden}
        />
      )}
    </Fragment>
  );
};

import React from 'react';
import {useBeforeunload} from 'react-beforeunload';
import {UploadFooter} from 'admin/resources/upload/components';
import {uploadMessages} from 'admin/resources';
import {useUploadActionButtons} from 'admin/resources/upload/hooks';

export const ScormContentFooter = () => {
  const {
    hasOneOfUploadScormRequiredFields,
    shouldDisableScormUpload,
    redirectToViewListOfFiles,
  } = useUploadActionButtons();

  useBeforeunload(() => {
    if (hasOneOfUploadScormRequiredFields) {
      return uploadMessages.warning;
    }
  });

  return (
    <UploadFooter
      showPrompt={hasOneOfUploadScormRequiredFields}
      redirectCallback={redirectToViewListOfFiles}
      disableSubmit={shouldDisableScormUpload}
    />
  );
};

import React, {FC, Fragment, memo} from 'react';
import {Table as MUITable, TableBody as MUITableBody, TableContainer as MUITableContainer} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {IColumn} from 'models/library/common';
import {TableHeader} from './TableHeader';
import {TableRow} from './TableRow';
import {TableRowWithError} from './TableRowWithError';

interface ITableProps {
  columns: IColumn[];
  rows: any[];
  withTableHead?: boolean;
  withMainRow?: boolean;
  hasRows?: boolean;
}

const Table: FC<ITableProps> = ({columns, rows, withTableHead = true, withMainRow = true, hasRows = true}) => {
  const {tableBody} = useStyles();
  const isEmptyString = (el: IColumn) => el.label === '';

  return (
    <Fragment>
      {hasRows ? (
        <MUITableContainer>
          <MUITable stickyHeader aria-label="sticky table" className="simple-table">
            {withTableHead && !columns.every(isEmptyString) && <TableHeader columns={columns} />}
            {rows && (
              <MUITableBody className={tableBody}>
                {rows?.map((row: any, idx: number) => {
                  return (
                    <Fragment key={idx}>
                      {withMainRow && <TableRow role="checkbox" tabIndex={tabIndex} columns={columns} row={row} />}
                      {!withMainRow && <TableRowWithError columns={columns} row={row} withSecondRow />}
                    </Fragment>
                  );
                })}
              </MUITableBody>
            )}
          </MUITable>
        </MUITableContainer>
      ) : (
        <Fragment>No results found</Fragment>
      )}
    </Fragment>
  );
};

const tabIndex = -1;

const useStyles = makeStyles((theme) => ({
  tableBody: {
    '& tr:nth-child(even)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

export default memo(Table);

import {QuestionAnswersTypeEnum} from 'enums';

export const cardTypeMap = new Map();

const questionAndAnswerTitle = 'Question & Answer';
const matrixTitle = 'Matrix';
const openEndedTitle = 'Open-ended';
const ratingTitle = 'Numeric Scale';
const dropDownTitle = 'Dropdown';
const singleSelectTitle = 'Single Select';
const multiSelectTitle = 'Multiple Select';
const htmlTitle = 'Instructional Text';

cardTypeMap.set(QuestionAnswersTypeEnum.radioGroup, singleSelectTitle);
cardTypeMap.set(QuestionAnswersTypeEnum.checkbox, multiSelectTitle);
cardTypeMap.set(QuestionAnswersTypeEnum.dropdown, dropDownTitle);
cardTypeMap.set(QuestionAnswersTypeEnum.text, openEndedTitle);
cardTypeMap.set(QuestionAnswersTypeEnum.rating, ratingTitle);
cardTypeMap.set(QuestionAnswersTypeEnum.questionAnswerEnum, questionAndAnswerTitle);
cardTypeMap.set(QuestionAnswersTypeEnum.matrix, matrixTitle);
cardTypeMap.set(QuestionAnswersTypeEnum.matrixEnum, matrixTitle);
cardTypeMap.set(QuestionAnswersTypeEnum.html, htmlTitle);

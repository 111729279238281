import React, {FC, Fragment, useCallback, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {IOption, IWithDisabled} from 'models';
import {
  FormNameEnum,
  delayTime,
  fetchResourcesThunk,
  resourcesEntitiesCollectionSelector,
  loadingStateResources,
  filtersActions as actions,
  dynamicSearchMessages,
} from 'filters-selections';
import {Form} from 'forms';
import {useAppDispatch, useDebounce} from 'hooks';
import {Label} from 'components-lib';

type ResourcesSelectProps = IWithDisabled;

export const ResourcesSelect: FC<ResourcesSelectProps> = ({disabled}) => {
  const dispatch = useAppDispatch();
  const resources = useSelector(resourcesEntitiesCollectionSelector);
  const loading = useSelector(loadingStateResources);

  useEffect(() => {
    if (!resources.length) {
      dispatch(
        fetchResourcesThunk({
          filter: {
            query: '',
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(actions.clearResources());
    };
  }, [dispatch]);

  const options: IOption[] = useMemo(() => {
    if (loading) {
      return [];
    }

    return resources.length > 0
      ? resources.map(({id, displayText}) => ({
          value: id,
          label: displayText,
        }))
      : [];
  }, [resources, loading]);

  const debouncedSearch = useDebounce(
    (query: string) =>
      dispatch(
        fetchResourcesThunk({
          filter: {
            query,
          },
        })
      ),
    delayTime
  );

  const handleInputChange = useCallback(
    (value: string) => {
      const newValue = value.trim();
      return !!newValue && debouncedSearch(newValue);
    },
    [debouncedSearch]
  );

  return (
    <Fragment>
      <Label label="Existing Resources" />
      <Form.SearchSelectAutocomplete
        loading={loading}
        name={FormNameEnum.resources}
        options={options}
        disabled={disabled || loading}
        noOptionsText={dynamicSearchMessages.noOptionsResource}
        inputChangeHandler={handleInputChange}
        withFontWeightBold={true}
        withCheckboxRenderOption={false}
      />
    </Fragment>
  );
};

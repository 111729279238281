import {SvgIcon, makeStyles} from '@material-ui/core';
import React, {FC} from 'react';
import {ReactComponent as IllustrationFolderImg} from 'resources/images/uploader/illustration_folder.svg';
import {ReactComponent as DisabledIllustrationFolderImg} from 'resources/images/uploader/illustration_folder_disabled.svg';
interface IProps {
  disabled?: boolean;
}

export const FolderIcon: FC<IProps> = ({disabled}) => {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.icon} viewBox="0 0 64 64">
      {disabled ? <DisabledIllustrationFolderImg /> : <IllustrationFolderImg />}
    </SvgIcon>
  );
};

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 64,
    width: 64,
    height: 64,
  },
}));

import React, {useCallback} from 'react';
import {SortingOrderEnum} from 'enums';
import {useAppDispatch} from 'hooks';
import {getLearningPathsAllMenuItemsList} from 'admin/resources/learning-paths/views/all/utils';
import {LearningPathsOrderingAllEnum} from 'admin/resources/learning-paths/views/all/enums';
import {useLearningPaths} from 'admin/resources/learning-paths/views/all/hooks';
import {ILearningPathsAllSortOrder} from 'models/resources/learning-paths';
import {learningPathsAllSitesActions as actions} from 'admin/resources/learning-paths';
import {SortByDropdown} from 'admin/components';

const learningPathsAllOrderingMap = {
  [LearningPathsOrderingAllEnum.Name]: {
    [SortingOrderEnum.ASC]: 'Name: A-Z',
    [SortingOrderEnum.DESC]: 'Name: Z-A',
  },
  [LearningPathsOrderingAllEnum.Created]: {
    [SortingOrderEnum.ASC]: 'Created: Oldest First',
    [SortingOrderEnum.DESC]: 'Created: Newest First',
  },
  [LearningPathsOrderingAllEnum.ProgramYear]: {
    [SortingOrderEnum.ASC]: 'Program Year: Oldest First',
    [SortingOrderEnum.DESC]: 'Program Year: Newest First',
  },
};

export const LearningPathsHeaderSortBy = () => {
  const dispatch = useAppDispatch();
  const {sortOrder} = useLearningPaths();

  const handleItemClick = useCallback(
    (sortOrder: ILearningPathsAllSortOrder) => {
      dispatch(actions.setSortOrderFilesAll(sortOrder));
    },
    [dispatch]
  );

  return (
    <SortByDropdown
      label="Sort By: "
      handleItemClick={handleItemClick}
      getMenuItemsList={getLearningPathsAllMenuItemsList}
      sortOrder={sortOrder}
      map={learningPathsAllOrderingMap}
    />
  );
};

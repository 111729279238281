import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {NoPermissionsPage} from 'pages';
import {pages, paths} from 'paths';
import {AdminRoles, withRole} from 'permissions';
import {LibraryNavBar} from './components';
import {Instruments} from './instruments';
import {Items} from './items';

const AdminPortalItemsAllEnhanced = withRole(AdminRoles, `${paths.adminPortal.root}${paths.noPermission}`)(Items);

export function Library() {
  return (
    <>
      <LibraryNavBar />
      <Switch>
        <Route
          exact
          path={pages.adminPortal.library}
          render={() => <Redirect to={pages.adminPortal.instruments.all} />}
        />
        <Route path={pages.adminPortal.instruments.all} component={Instruments} />
        <Route path={pages.adminPortal.items.root}>
          <AdminPortalItemsAllEnhanced />
          <Route path={`${pages.adminPortal.items.root}${paths.noPermission}`}>
            <NoPermissionsPage />
          </Route>
        </Route>
      </Switch>
    </>
  );
}

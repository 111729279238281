import React, {SyntheticEvent} from 'react';
import {makeStyles, TextField} from '@material-ui/core';
import {IWithDisabled} from 'models';

interface ITextAreaProps extends IWithDisabled {
  value?: string | null;
  name?: string | null;
  changeHandler?: (ev: SyntheticEvent) => void;
  label?: string;
  placeholder?: string;
  withMargin?: boolean;
  withPadding?: boolean;
  color?: string;
  rows?: number;
  fullWidth?: boolean;
}

export function TextArea({
  name,
  value,
  changeHandler,
  label = '',
  placeholder = '',
  withMargin = false,
  withPadding = false,
  color,
  rows = 4,
  fullWidth = false,
  ...rest
}: ITextAreaProps) {
  const classes = useStyles({withMargin, withPadding, color});

  return (
    <TextField
      name={name}
      label={label}
      placeholder={placeholder}
      classes={classes}
      multiline
      rows={rows}
      variant="outlined"
      onChange={changeHandler}
      value={value}
      fullWidth={fullWidth}
      {...rest}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-inputMultiline': {
      padding: 0,
    },
    '& .MuiOutlinedInput-input ': {
      color: theme.palette.grey[900],
      fontWeight: 600,
      padding: '11px 9px',
    },
    '& .Mui-disabled': {
      color: theme.palette.grey[700],
    },
  },
}));

import React, {FC, Fragment, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {IOption, IWithDisabled} from 'models/common';
import {
  FormNameEnum,
  filterFileTypesEntitiesCollectionSelector,
  fetchAllFileTypeOptionsThunk,
} from 'filters-selections';
import {Form} from 'forms';
import {Accordion, Column, Text} from 'components-lib';
import {useAppDispatch} from 'hooks';
import {IBindBaseEnumEntity} from 'models/interfaces';

interface IFileTypeFilterProps extends IWithDisabled {
  selectionType?: 'single' | 'multi';
  name?: FormNameEnum;
}

export const FileTypeFilter: FC<IFileTypeFilterProps> = ({
  selectionType = 'multi',
  disabled,
  name = FormNameEnum.fileTypes,
}) => {
  const dispatch = useAppDispatch();
  const isMultiSelectFilter = selectionType === 'multi';
  const fileTypes: IBindBaseEnumEntity[] = useSelector(filterFileTypesEntitiesCollectionSelector);

  useEffect(() => {
    if (!fileTypes.length) {
      dispatch(fetchAllFileTypeOptionsThunk());
    }
  }, [fileTypes.length, dispatch]);

  const options: IOption[] = useMemo(() => {
    return fileTypes.map((fileType) => ({
      value: fileType.id,
      label: fileType.displayText,
    }));
  }, [fileTypes]);

  const content = useMemo(
    () => (
      <Fragment>
        {isMultiSelectFilter ? (
          <Form.SelectAutocomplete name={name} options={options} multiple disabled={disabled} />
        ) : (
          <Form.Select name={name} options={options} disabled={disabled} />
        )}
      </Fragment>
    ),
    [isMultiSelectFilter, disabled, options, name]
  );

  return (
    <Accordion heading="Resource Types">
      <Column>{fileTypes?.length ? <Fragment>{content}</Fragment> : <Text.Caption>No Data</Text.Caption>}</Column>
    </Accordion>
  );
};

import React, {Dispatch, FC, SetStateAction} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {UploadPaperAssessmentForm} from '../UploadPaperAssessmentForm/UploadPaperAssessmentForm';
import {UploadPaperAssessmentDroppableArea} from '../UploadPaperAssessmentDroppableArea/UploadPaperAssessmentDroppableArea';
import {FormNameEnum} from 'enums/form';
import {Form} from 'forms';
import {Column, Container, Label, Loading, Row} from 'components-lib';

interface IUploadPaperAssessmentContentProps {
  loading: boolean;
  isDirty: boolean;
  setIsDirtyCallback: Dispatch<SetStateAction<boolean>>;
  closeModalClickHandler?: () => void;
}

export const UploadPaperAssessmentContent: FC<IUploadPaperAssessmentContentProps> = ({
  loading,
  isDirty,
  setIsDirtyCallback,
  closeModalClickHandler,
}) => {
  const classes = useStyles();

  if (loading) {
    return (
      <Container classes={{root: classes.loadingContainer}}>
        <Loading />
      </Container>
    );
  }

  return (
    <UploadPaperAssessmentForm closeModalClickHandler={closeModalClickHandler} setIsDirtyCallback={setIsDirtyCallback}>
      <Box mb={3}>
        <UploadPaperAssessmentDroppableArea isDirty={isDirty} setIsDirtyCallback={setIsDirtyCallback} />
      </Box>
      <Box mb={3}>
        <Row justify="flex-start">
          <Column>
            <Label label="Description" />
            <Form.TextArea name={FormNameEnum.description} placeholder="Add Description..." />
          </Column>
        </Row>
      </Box>
    </UploadPaperAssessmentForm>
  );
};

const useStyles = makeStyles(() => ({
  loadingContainer: {
    height: 245,
  },
}));

import React, {FC, Fragment, useCallback, useContext} from 'react';
import {batch} from 'react-redux';
import {useFormikContext} from 'formik';
import {FormContext} from 'forms/formik/FormContextProvider';
import {Button} from 'components-lib';
import {ModalDelete} from 'admin/components';
import {useModal} from 'hooks';
import {ILearningPathSessionsFormBaseValues} from 'admin/resources/learning-paths/views/session-builder/utils';
import {
  useLearningPathBuilderItemsEntities,
  useSessionBuilderStoreActions,
} from 'admin/resources/learning-paths/views/session-builder';
import {SessionBuilderEditModeEnum} from 'enums/learning-paths';

interface ISessionsListItemActionButtonsProps {
  id: string;
  isEditMode?: boolean;
  disabled?: boolean;
  setSessionBuilderModeCallback: (mode: SessionBuilderEditModeEnum) => void;
}

export const SessionsListItemActionButtons: FC<ISessionsListItemActionButtonsProps> = ({
  id,
  isEditMode,
  disabled,
  setSessionBuilderModeCallback,
}) => {
  const {builderSessionItemsEntities} = useLearningPathBuilderItemsEntities();
  const {
    deleteSessionBuilderItem,
    updateSessionBuilderItem,
    checkIfEmptySessionExist,
  } = useSessionBuilderStoreActions();
  const {isOpen: isModalDeleteSession, setIsOpen: setIsDeleteSessionModal} = useModal();
  const {values, resetForm, isValid} = useFormikContext<ILearningPathSessionsFormBaseValues>();
  const {setIsDirty} = useContext(FormContext);

  const item = builderSessionItemsEntities[id];

  const hasSessionName = values.sessionName.length;
  const shouldDisableSaveButton = !hasSessionName;
  const shouldRenderEditButton = !disabled;

  const openDeleteSessionModal = useCallback(() => setIsDeleteSessionModal(true), [setIsDeleteSessionModal]);

  const deleteSessionItem = useCallback(
    () =>
      batch(() => {
        deleteSessionBuilderItem(item);
        checkIfEmptySessionExist();
        resetForm();
        setIsDirty(true);
      }),
    [item, deleteSessionBuilderItem, checkIfEmptySessionExist, resetForm, setIsDirty]
  );

  const deleteClickHandler = useCallback(() => openDeleteSessionModal(), [openDeleteSessionModal]);

  const saveClickHandler = useCallback(
    () =>
      batch(() => {
        updateSessionBuilderItem(item, values);
        checkIfEmptySessionExist();
        resetForm();
        setIsDirty(true);
      }),
    [updateSessionBuilderItem, checkIfEmptySessionExist, item, values, resetForm, setIsDirty]
  );

  const setEditModeClickHandler = useCallback(() => setSessionBuilderModeCallback(SessionBuilderEditModeEnum.Edit), [
    setSessionBuilderModeCallback,
  ]);

  return (
    <Fragment>
      {isEditMode && (
        <Button.Secondary size="small" clickHandler={deleteClickHandler} disabled={!isEditMode}>
          Delete
        </Button.Secondary>
      )}
      {isEditMode && (
        <Button.Primary
          size="small"
          clickHandler={saveClickHandler}
          disabled={!isEditMode || !isValid || shouldDisableSaveButton}
        >
          Save
        </Button.Primary>
      )}
      {!isEditMode && shouldRenderEditButton && (
        <Button.Primary size="small" clickHandler={setEditModeClickHandler}>
          Edit
        </Button.Primary>
      )}
      <ModalDelete
        loading={false}
        title="Delete Session"
        text="Are you sure you want to delete this session? The information will be lost."
        cancelButtonText="No"
        confirmButtonText="Yes"
        isOpen={isModalDeleteSession}
        setIsOpen={setIsDeleteSessionModal}
        confirmHandler={deleteSessionItem}
      />
    </Fragment>
  );
};

import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import TableMui from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {IColumn, IParticipantSummary} from 'models';
import {IPaperQuestion} from '../../models/IPaperQuestion';
import {PaperNodeTypeEnum} from '../../enums/PaperNodeTypeEnum';
interface IParticipantsTableProps {
  participants: IParticipantSummary[];
  afterMount?: (questionInfo: IPaperQuestion) => void;
}

const ParticipantsTable = ({participants, afterMount}: IParticipantsTableProps) => {
  const mainRef = useRef(null);
  const headerAreaRef = useRef(null);
  const rowRefs = useRef([]);
  const {fixedTable, header, cell, tableBody} = useStyles();
  const rows = participants;

  useEffect(() => {
    if (!afterMount) return;
    afterMount({
      type: PaperNodeTypeEnum.participantsTable,
      heights: {
        total: mainRef?.current.clientHeight,
        textArea: 0, // no text area
        headerArea: headerAreaRef?.current.clientHeight,
        questionAreas: rowRefs?.current.map((element) => element.clientHeight),
      },
      text: '',
      id: 'n/a',
      rows: participants,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const table = (
    <TableMui aria-label="table" className={classnames('simple-table', fixedTable)} innerRef={mainRef}>
      <TableHead innerRef={headerAreaRef}>
        <TableRow>
          {columns.map((column) => (
            <TableCell key={column.id} align={'center'} className={classnames(header, cell)}>
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {rows && (
        <TableBody className={tableBody}>
          {rows?.map((row: any, idx: number) => {
            return (
              <TableRow role="checkbox" tabIndex={-1} key={idx} innerRef={(ref) => rowRefs.current.push(ref)}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align} className={classnames(cell)}>
                      {column.format && typeof value === 'number' ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      )}
    </TableMui>
  );
  return table;
};

const useStyles = makeStyles((theme) => ({
  fixedTable: {
    tableLayout: 'fixed',
  },
  cell: {
    border: 0,
    overflowWrap: 'break-word',
  },
  header: {
    color: theme.palette.grey[700],
    fontWeight: 600,
    backgroundColor: theme.palette.common.white,
  },
  tableBody: {
    '& tr:nth-child(even)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

const columns: IColumn[] = [
  {id: 'userId', label: 'ID', align: 'center'},
  {id: 'firstName', label: 'First name', align: 'center'},
  {id: 'surname', label: 'Surname', align: 'center'},
  {id: 'email', label: 'E-Mail', align: 'center'},
  {id: 'sessionTemplateIdentifier', label: 'Identifier', align: 'center'},
];

export default ParticipantsTable;

import {Accordion, Text} from 'components-lib';
import {Form} from 'forms';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormNameEnum} from '../../../enums';
import {fetchDigitalResourcesConceptsThunk, DigitalResourcesFiltersConceptsSelector} from '../../../store';
import {IWithDisabled} from 'models';

export function DigitalResourcesConceptsFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const concepts = useSelector(DigitalResourcesFiltersConceptsSelector);

  useEffect(() => {
    if (!concepts?.length) {
      dispatch(fetchDigitalResourcesConceptsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const options: any = useMemo(() => concepts.map((concept) => ({value: concept, label: concept})), [concepts]);

  return (
    <Accordion heading="KSAs">
      {concepts.length ? (
        <Form.SelectAutocomplete name={FormNameEnum.concepts} options={options} multiple disabled={disabled} />
      ) : (
        <Text.Caption>No Data</Text.Caption>
      )}
    </Accordion>
  );
}

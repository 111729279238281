import {SidebarAuthor, SidebarLink, SidebarTagWithValue, SidebarTitle} from 'admin/components';
import {Divider, Sidebar} from 'components-lib';
import {IEntityBase, IItemDetails} from 'models';
import {pages} from 'paths';
import React from 'react';

interface ISidebarItemDetails {
  item: IItemDetails;
  loading: boolean;
}

export function SidebarItemDetails({item, loading}: ISidebarItemDetails) {
  if (!item.metaData || loading) return null;

  const {createdBy, createdOn} = item;
  const {
    administration,
    competencies,
    concepts,
    category,
    domain,
    evaluationTypes,
    instruments,
    pillars,
    programs,
    schoolLevels,
  } = item.metaData;

  return (
    <Sidebar.Base>
      <SidebarTitle text="Tags" />
      <SidebarTagWithValue tag="Pillar" text={mapValues(pillars)} />
      <SidebarTagWithValue tag="Evaluation Type" text={mapValues(evaluationTypes)} />
      <SidebarTagWithValue tag="Domain" text={domain?.name} />
      <SidebarTagWithValue tag="Program" text={mapValues(programs)} />
      <SidebarTagWithValue tag="Competency" text={mapValues(competencies)} />
      <SidebarTagWithValue tag="KSAs" text={mapValues(concepts)} />
      <SidebarTagWithValue tag="Administration" text={administration?.name} />
      <SidebarTagWithValue tag="School Level" text={mapValues(schoolLevels)} />
      <SidebarTagWithValue tag="Category" text={category} />
      <Divider.Horizontal />
      <SidebarTitle text="Details" />
      <SidebarAuthor author={createdBy} date={createdOn} />
      <Divider.Horizontal />
      {!!instruments && (
        <>
          <SidebarTitle text={`Used in ${instruments.length} instrument${instruments.length > 1 ? 's' : ''}`} />
          {instruments.map((instrument) => (
            <SidebarLink
              key={instrument.id}
              to={`${`${pages.adminPortal.instruments.all}/${instrument.id}`}`}
              text={instrument.name}
            />
          ))}
        </>
      )}
    </Sidebar.Base>
  );
}

function mapValues(values: IEntityBase[] | IEntityBase): string {
  if (Array.isArray(values)) {
    return values.map((value) => value.name).join(', ') ?? '';
  }

  return values?.name ?? '';
}

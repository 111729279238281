import React, {FC, MouseEvent, useCallback, useEffect, useState} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {IconButton, makeStyles} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {colors} from 'styles/colors';
import {Icon, Wrapper} from 'components-lib';
import {useToastNotifications} from 'toast-notifications';

export interface IDropdownItemProps {
  text: string;
  isCopyLink?: boolean;
  copyText?: string;
  disabled?: boolean;
  clickHandler?: () => void;
}

interface IButtonDropdownProps {
  items: IDropdownItemProps[];
  withText?: boolean;
  text?: string;
  hideOnResize?: boolean;
}

export const ButtonDropdown: FC<IButtonDropdownProps> = ({items, withText, text, hideOnResize = false}) => {
  const {iconButtonWithText, container} = useStyles();
  const {addToastNotification} = useToastNotifications();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (hideOnResize) {
      window.addEventListener('resize', handleClose);
    }
  }, [hideOnResize]);

  const copyHandler = useCallback(() => addToastNotification('The text has been copied to your clipboard.'), [
    addToastNotification,
  ]);

  return (
    <Wrapper className={container}>
      <IconButton disableRipple onClick={handleClick} className={withText && iconButtonWithText}>
        {withText ? text : <Icon.MoreIcon />}
      </IconButton>

      <Menu anchorEl={anchorEl} keepMounted onClose={handleClose} open={open}>
        {items.map((item: IDropdownItemProps, idx: number) => {
          const menuItem = (
            <MenuItem
              key={`dropdown-item-${idx}`}
              disabled={item.disabled}
              onClick={() => {
                item.clickHandler && item.clickHandler();
                handleClose();
              }}
            >
              {item.text}
            </MenuItem>
          );

          if (item.isCopyLink) {
            return (
              <CopyToClipboard key={`copy-to-clip-board-item-${idx}`} text={item.copyText} onCopy={copyHandler}>
                {menuItem}
              </CopyToClipboard>
            );
          } else {
            return menuItem;
          }
        })}
      </Menu>
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconButtonWithText: {
    color: theme.palette.grey[700],
    padding: 0,
    lineHeight: 0.63,
    borderRadius: 0,
    fontSize: '14px',
    textAlign: 'center',
    marginRight: '48px',

    '&:hover': {
      color: theme.palette.grey[800],
    },

    '&:active': {
      color: colors.primary,
    },

    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent',
    },
  },
}));

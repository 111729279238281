import React, {useMemo, useEffect, useState} from 'react';
import {Accordion, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {Form} from 'forms';
import {useAppDispatch} from 'hooks';
import {IOption, IWithDisabled} from 'models';
import {fetchSessionsByProgramIdsThunk} from 'filters-selections/store';
import {useIsProgramSelected} from 'filters-selections/hooks';
import {unwrapResult} from '@reduxjs/toolkit';
interface ISessionsEntity {
  id: number;
  name: string;
  programId?: number;
}

export function SessionsFilter({disabled}: IWithDisabled) {
  const dispatch = useAppDispatch();
  const {formValues} = useIsProgramSelected();
  const [sessions, setSessions] = useState([]);
  const programIds = formValues[FormNameEnum.programs];

  useEffect(() => {
    if (programIds.length) {
      dispatch(fetchSessionsByProgramIdsThunk(programIds))
        .then(unwrapResult)
        .then((response: ISessionsEntity[]) => {
          setSessions(response);
        });
    }
    return () => setSessions([]);
  }, [dispatch, programIds]);

  const options = useMemo(() => {
    if (!sessions.length) {
      return [];
    } else {
      return sessions.length && mapOptions(sessions);
    }
  }, [sessions]);
  return (
    <Accordion heading="Sessions">
      {sessions && sessions.length ? (
        <Form.SelectAutocomplete name={FormNameEnum.sessions} options={options} multiple disabled={disabled} />
      ) : (
        <Text.Caption>Select Programs</Text.Caption>
      )}
    </Accordion>
  );
}

const mapOptions = (sessions: ISessionsEntity[]): IOption[] => {
  return sessions?.map((f) => ({value: f.id, label: f.name}));
};

import React, {forwardRef, ReactNode, useCallback, useMemo, useState} from 'react';
import {IconButton, Menu} from '@material-ui/core';
import {MenuItemList} from './MenuItemList';
import {IWithDisabled} from 'models';
import {Icon, Wrapper} from 'components-lib';

interface IMenuItem {
  text: string;
  clickHandler: () => void;
}

interface IThreeDotsVerticalIconMenuProps extends IWithDisabled {
  items: IMenuItem[];
  icon?: ReactNode;
}

export const ThreeDotsVerticalIconMenu = forwardRef(
  ({items, icon, disabled}: IThreeDotsVerticalIconMenuProps, ref: any) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openClickHandler = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget),
      []
    );

    const closeClickHandler = useCallback(() => setAnchorEl(null), []);

    const defaultIcon = useMemo(() => <Icon.MoreIcon disabled={disabled} />, [disabled]);

    const menuIconButton = useMemo(
      () => (
        <IconButton disabled={disabled} onClick={openClickHandler}>
          {icon ? icon : defaultIcon}
        </IconButton>
      ),
      [disabled, openClickHandler, icon, defaultIcon]
    );

    return (
      <Wrapper>
        {menuIconButton}
        <Menu ref={ref} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeClickHandler}>
          {items.length && <MenuItemList items={items} closeClickHandler={closeClickHandler} />}
        </Menu>
      </Wrapper>
    );
  }
);

ThreeDotsVerticalIconMenu.displayName = 'ThreeDotsVerticalIconMenu';

import React, {FC, useMemo} from 'react';
import {Table, Tooltip, Wrapper} from 'components-lib';
import {IFileVersion} from 'models/resources';
import {getDateMMDDyyyy} from 'utils/common';
import {makeStyles} from '@material-ui/core';

const columns = [
  {
    id: 'uploadedUtc',
    label: 'Date uploaded',
  },
  {
    id: 'uploadedBy',
    label: 'Uploaded by',
  },
];

interface ITableVersionsProps {
  versions: IFileVersion[];
}

export const TableVersions: FC<ITableVersionsProps> = ({versions}) => {
  const classes = useStyles();

  const rows = useMemo(() => {
    if (!versions.length) {
      return [];
    }

    return versions.map(({uploadedUtc, uploadedBy}) => ({
      uploadedUtc: uploadedUtc ? getDateMMDDyyyy(uploadedUtc) : '',
      uploadedBy: (
        <Wrapper className={classes.email}>
          <Tooltip title={uploadedBy}>{uploadedBy}</Tooltip>
        </Wrapper>
      ),
    }));
  }, [classes.email, versions]);

  return <Table rows={rows} columns={columns} />;
};

const useStyles = makeStyles(() => ({
  email: {
    maxWidth: 300,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

import React, {FC, useEffect} from 'react';
import {useFormikContext} from 'formik';
import {IAddIntegrationFormBaseValues} from 'models/integrations';
import {SchoolNameBasedOnAreaSelect} from './SchoolNameBasedOnAreaSelect';
import {getIsGoogleSitesSource} from 'admin/integrations/utils';
import {IWithDisabled} from 'models/common';
import {useSchoolNameBasedOnAreaSelect} from 'filters-selections/hooks';
import {FormNameEnum} from 'filters-selections';

type ISchoolNameBasedOnAreaAndDistrictProps = IWithDisabled;

export const SchoolNameBasedOnAreaAndDistrict: FC<ISchoolNameBasedOnAreaAndDistrictProps> = ({disabled}) => {
  const {values, setFieldValue} = useFormikContext<IAddIntegrationFormBaseValues>();
  const {clearSchoolNameSelectItems} = useSchoolNameBasedOnAreaSelect();
  const type = Number(values.integrationType);

  const isGoogleSitesSource = getIsGoogleSitesSource(type);

  useEffect(() => {
    if (values.area || values.schoolDistrict) {
      setFieldValue(FormNameEnum.schoolName, null);
      clearSchoolNameSelectItems();
    }
    return () => {
      clearSchoolNameSelectItems();
    };
  }, [values.area, values.schoolDistrict, setFieldValue, clearSchoolNameSelectItems]);

  return <SchoolNameBasedOnAreaSelect disabled={disabled || isGoogleSitesSource} />;
};

import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ISurvey} from 'survey-react';
import {unwrapResult} from '@reduxjs/toolkit';
import {useAppDispatch} from 'hooks';
import {useUserId} from 'user';
import {toNumber} from 'utils';
import {SurveyTakerContent, ViewError} from './components';
import {IExtendedSurvey} from './models';
import {getActiveSurveyThunk, saveActiveSurveyThunk} from './store';

export function SurveyTakerMasterInstrument() {
  const dispatch = useAppDispatch();
  const {programId, instrumentId} = useParams<{programId: string; instrumentId: string}>();
  const {userLoading, userId, userError} = useUserId();

  const [loading, setLoading] = useState(userLoading);
  const [error, setError] = useState<any>('');
  const [survey, setSurvey] = useState<ISurvey>(null);
  const [surveySessionId, setSurveySessionId] = useState<number>();
  const [surveyTaken, setSurveyTaken] = useState<boolean>(false);

  useEffect(() => {
    if (userError) {
      setError('No user.');
      return;
    }

    if (userId && programId && instrumentId) {
      setLoading(true);
      dispatch(getActiveSurveyThunk({userId, programId, instrumentId}))
        .then(unwrapResult)
        .then((result: IExtendedSurvey) => {
          if (!result?.pages) {
            setLoading(false);
            setError((result as unknown) as string);
            return;
          }
          const {pages, footerUrl, logo, logoWidth, logoHeight, logoPosition} = result;

          setSurveySessionId(toNumber(pages[0]?.name));
          setSurvey({
            showCompletedPage: false,
            showPageNumbers: false, // See: https://mentormate.atlassian.net/browse/JADF-559
            pages,
            footerUrl,
            logo,
            logoWidth,
            logoHeight,
            logoPosition,
          } as any); // TODO

          setLoading(false);
        })
        .catch((err: Error) => {
          setError(err.message);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, instrumentId, programId, userError, userId]);

  const saveResultsHandler = useCallback(
    (results: {[id: number]: string}) => {
      setLoading(true);

      dispatch(saveActiveSurveyThunk({instrumentId: surveySessionId, userId, results}))
        .then(unwrapResult)
        .then(() => {
          setSurveyTaken(true);
          setLoading(false);
        })
        .catch((err: Error) => {
          setError(err.message);
          setLoading(false);
        });
    },
    [dispatch, surveySessionId, userId]
  );

  if (!userId || !programId || !instrumentId) {
    return <ViewError text="Missing data." />;
  }

  return (
    <SurveyTakerContent
      loading={loading}
      surveyTaken={surveyTaken}
      survey={survey}
      error={error}
      saveResultsHandler={saveResultsHandler}
    />
  );
}

import {SortingOrderEnum} from 'enums';
import {ProgramsMasterInstrumentsOrderingEnum} from '../enums';

export const programMasterInstrumentsOrderingMap = {
  [ProgramsMasterInstrumentsOrderingEnum.Name]: {
    [SortingOrderEnum.ASC]: 'Name: A-Z',
    [SortingOrderEnum.DESC]: 'Name: Z-A',
  },
  [ProgramsMasterInstrumentsOrderingEnum.Type]: {
    [SortingOrderEnum.ASC]: 'Type: Pre-Post',
    [SortingOrderEnum.DESC]: 'Type: Post-Pre',
  },
};

import {ISessionBuilderStoreState} from './ISessionBuilderStoreState';
import {fetchLearningPathByIdThunk, updateLearningPathThunk} from '../../edit/store';
import {IActionFulfilled, IGetLearningPathByIdApiResponse} from 'models';
import {isObject} from 'utils/functions';
import {normalize} from 'normalizr';
import {sessionBuilderItemsSchema} from './normalizationSchemas';

export const sessionBuilderExtraReducer = {
  // fetchLearningPathByIdThunk
  [fetchLearningPathByIdThunk.fulfilled]: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, IGetLearningPathByIdApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {
        data: {sessions},
      } = action.payload;

      if (Array.isArray(sessions)) {
        const sessionItems = normalize(sessions, sessionBuilderItemsSchema) as any;
        state.builder.builderSessionItems = sessionItems;
      }
    }
  },

  // updateLearningPathThunk
  [updateLearningPathThunk.fulfilled]: (
    state: ISessionBuilderStoreState,
    action: IActionFulfilled<null, IGetLearningPathByIdApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {
        data: {sessions},
      } = action.payload;

      if (Array.isArray(sessions)) {
        const sessionItems = normalize(sessions, sessionBuilderItemsSchema) as any;
        state.builder.builderSessionItems = sessionItems;
      }
    }
  },
};

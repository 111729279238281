import React from 'react';

import {Field} from 'forms/components/Field';
import {ImageWithPreview} from 'components-lib';

interface IFormProps {
  name: string;
  text?: string;
  textSize?: string;
  label?: string;
  accept?: string;
  fileInputRef?: any;
  onFilesAddedChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  openFileDialog?: () => void;
  withFolderIcon?: boolean;
  withButton?: boolean;
  withLabel?: boolean;
  className?: string;
  url?: string;
}

export const FormImageUpload = ({name, ...rest}: IFormProps) => {
  return <Field name={name} Component={ImageWithPreview} {...rest} />;
};

import {uniqueId} from 'utils';
import {ToastNotificationTypeEnum} from '../enums';
import {toastNotificationsActions} from '../store';

export class ToastNotificationManager {
  #defaultDuration = 5000;

  public createToastAction(message: string, type: ToastNotificationTypeEnum) {
    return toastNotificationsActions.add({
      message,
      type,
      id: uniqueId(),
      duration: this.#defaultDuration,
    });
  }

  public createSuccessToastAction(message: string) {
    return toastNotificationsActions.add({
      message,
      id: uniqueId(),
      type: ToastNotificationTypeEnum.success,
      duration: this.#defaultDuration,
    });
  }

  public createErrorToastAction(message: string) {
    return toastNotificationsActions.add({
      message,
      id: uniqueId(),
      type: ToastNotificationTypeEnum.error,
      duration: this.#defaultDuration,
    });
  }
}

export const toastNotificationManager = new ToastNotificationManager();

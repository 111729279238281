import React from 'react';

import {FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import {IComponentWithChildren} from 'models';
import {usePermissions} from 'permissions';
import {checkIfAreaAdmin} from '../../details/utils';
import {createInstrumentValidationSchema} from './createInstrumentValidationSchema';

export interface ICreateInstrumentFormData {
  [FormNameEnum.pillars]: number;
  [FormNameEnum.instrumentName]: string;
  [FormNameEnum.programs]: number[];
  [FormNameEnum.phase]: number;
  [FormNameEnum.instrumentType]: number;
  [FormNameEnum.instrumentSubType]: number;
  [FormNameEnum.audience]: number;
  [FormNameEnum.isGeneralInstrument]: boolean;
}

interface ICreateInstrumentFormProps extends IComponentWithChildren {
  submitHandler: (data: ICreateInstrumentFormData, actions: any) => void;
}

export function CreateInstrumentForm({children, submitHandler}: ICreateInstrumentFormProps) {
  const {hasPermission} = usePermissions();
  const isAreaAdmin = checkIfAreaAdmin(hasPermission);

  const initialValues: ICreateInstrumentFormData = {
    [FormNameEnum.instrumentName]: '',
    [FormNameEnum.phase]: null,
    [FormNameEnum.instrumentType]: null,
    [FormNameEnum.instrumentSubType]: null,
    [FormNameEnum.audience]: null,
    [FormNameEnum.pillars]: null,
    [FormNameEnum.programs]: [],
    [FormNameEnum.isGeneralInstrument]: isAreaAdmin ? true : false,
  };

  return (
    <Form.Form<ICreateInstrumentFormData>
      initialValues={initialValues}
      validationSchema={createInstrumentValidationSchema}
      submitHandler={submitHandler}
    >
      {children}
    </Form.Form>
  );
}

import {
  filtersAudiencesEntitiesCollectionSelector,
  filtersInstrumentTypesEntitiesCollectionSelector,
} from 'filters-selections';
import {useSelector} from 'react-redux';
import {audienceGeneralId, feedbackTypeName, generalAudienceName, typeFeedbackId} from 'utils/instruments';

export function useGeneralInstrumentMeta() {
  const instrumentTypes = useSelector(filtersInstrumentTypesEntitiesCollectionSelector);
  const audiences = useSelector(filtersAudiencesEntitiesCollectionSelector);

  const feedbackType = instrumentTypes.filter((type) => type.name === feedbackTypeName)[0] ?? {
    id: typeFeedbackId,
    displayText: feedbackTypeName,
  };
  const audienceType = audiences.filter((type) => type.name === generalAudienceName)[0] ?? {
    id: audienceGeneralId,
    displayText: generalAudienceName,
  };

  return {feedbackType, audienceType};
}

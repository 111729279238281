export enum PaperNodeTypeEnum {
  radioGroup = 'radiogroup',
  checkbox = 'checkbox',
  matrix = 'matrix',
  dropdown = 'dropdown',
  text = 'text',
  rating = 'rating',
  headline = 'headline',
  studentInfo = 'studentInfo',
  participantsTable = 'participantsTable',
  html = 'html',
}

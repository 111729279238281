import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';

const getIntegrationsState = (state: TStateStore) => state.integrations;

export const paginationSelector = createSelector(getIntegrationsState, (integrations) => integrations.pagination);
export const pageLoadingSelector = createSelector(getIntegrationsState, (integrations) => integrations.page.loading);
export const pageSavingSelector = createSelector(getIntegrationsState, (integrations) => integrations.page.saving);
export const pageDeletingSelector = createSelector(getIntegrationsState, (integrations) => integrations.page.deleting);

export const integrationsAllSortBySelector = createSelector(
  getIntegrationsState,
  (integrations) => integrations.sortBy
);

export const integrationsAllSortOrderSelector = createSelector(
  getIntegrationsState,
  (integrations) => integrations.sortOrder
);

export const integrationsAllSelector = createSelector(
  getIntegrationsState,
  (integrations) => integrations.integrationList
);

export const integrationsAllFilterSelector = createSelector(
  getIntegrationsState,
  (integrations) => integrations.filter
);

export const serviceSourceEmailSelector = createSelector(
  getIntegrationsState,
  (integrations) => integrations.addIntegration.metadata.serviceSourceEmail
);

export const addIntegrationErrorMessageSelector = createSelector(
  getIntegrationsState,
  (integrations) => integrations.addIntegration.metadata.errorMessage
);

export const integrationDetailsSelector = createSelector(
  getIntegrationsState,
  (integrations) => integrations.integration
);

export const integrationStatusesSelector = createSelector(
  getIntegrationsState,
  (integrations) => integrations.details.integrationStatuses
);

export const auditLogsSelector = createSelector(
  getIntegrationsState,
  (integrations) => integrations.details.auditLogs.entities
);

export const auditLogsLoadingSelector = createSelector(
  getIntegrationsState,
  (integrations) => integrations.details.auditLogs.loading
);

import React, {useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Button, Column, Icon, Modal, Row, Text, Wrapper} from 'components-lib';
import {IWithModal} from 'models';
import {splitStringByNewLine} from 'utils/common';

interface IModalDeleteProps extends IWithModal {
  title?: string;
  text: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  loading?: boolean;
  withNewLineText?: boolean;
  confirmHandler: () => void;
}

export function ModalDelete({
  isOpen,
  setIsOpen,
  confirmHandler,
  text,
  title = 'Delete Item',
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Delete',
  withNewLineText = false,
  loading,
}: IModalDeleteProps) {
  const classes = useStyles();
  const splittedText = splitStringByNewLine(text);

  const newLineText = useMemo(
    () =>
      splittedText.map((paragraph: string, idx: number) => (
        <Text.Paragraph key={`paragraph-${idx}`}>{paragraph}</Text.Paragraph>
      )),
    [splittedText]
  );

  const closeHandler = useCallback(() => setIsOpen(false), [setIsOpen]);

  const confirmActionHandler = useCallback(() => {
    confirmHandler();
    setIsOpen(false);
  }, [confirmHandler, setIsOpen]);

  return (
    <Modal isOpen={isOpen} handleClose={closeHandler} title={title} size="medium">
      <Column>
        <Row align="center" justify="center">
          <Column>
            <Row align="center" justify="center">
              <Icon.TrashIcon className={classes.icon} />
            </Row>
            <Row justify="center">{withNewLineText ? newLineText : <Text.Paragraph>{text}</Text.Paragraph>}</Row>
          </Column>
        </Row>
      </Column>
      <Wrapper>
        <Button.Secondary clickHandler={closeHandler} size="small">
          {cancelButtonText}
        </Button.Secondary>
        <Button.Primary size="small" clickHandler={confirmActionHandler}>
          {loading ? 'Loading...' : confirmButtonText}
        </Button.Primary>
      </Wrapper>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginBottom: theme.spacing(4),
  },
}));

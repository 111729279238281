import React, {FC} from 'react';
import {Route, Switch} from 'react-router-dom';
import {paths, pages} from 'paths';
import {ItemsImportView} from './import-view';
import {SuperAdminRole, withRole} from 'permissions';
import {NoPermissionsPage} from 'pages';

const ItemsImportViewEnhanced = withRole(
  SuperAdminRole,
  `${paths.adminPortal.root}${paths.noPermission}`
)(ItemsImportView);

export const ItemImport: FC = () => {
  return (
    <Switch>
      <Route exact path={pages.adminPortal.itemImport.root}>
        <ItemsImportViewEnhanced />
        <Route path={`${pages.adminPortal.itemImport.root}${paths.noPermission}`}>
          <NoPermissionsPage />
        </Route>
      </Route>
    </Switch>
  );
};

import {EntityStatusEnum} from 'enums';
import {ResponseApiStatusEnum} from 'enums/common';

export const getFileStatus = (processingStatus: number, fileDetailsStatus: EntityStatusEnum) => {
  if (processingStatus === ResponseApiStatusEnum.Error) {
    return EntityStatusEnum.error;
  }

  return fileDetailsStatus;
};

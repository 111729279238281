import React, {FC} from 'react';
import {FormNameEnum} from 'filters-selections';
import {DatePickerSelection} from '../DatePickerSelection/DatePickerSelection';
import {IWithDisabled} from 'models/common';

interface IExpirationDatePickerProps extends IWithDisabled {
  disablePast?: boolean;
  infoText?: string;
}

export const ExpirationDatePicker: FC<IExpirationDatePickerProps> = ({
  disabled = false,
  disablePast = true,
  infoText,
}) => {
  return (
    <DatePickerSelection
      label="Expiration Date"
      name={FormNameEnum.expirationDate}
      disablePast={disablePast}
      disabled={disabled}
      infoText={infoText}
      minDateMessage=""
      maxDateMessage=""
    />
  );
};

export enum UploadFormLabelEnum {
  DisplayName = 'Display Name',
  GradeLevel = 'Grade Level',
  Program = 'Program',
  Pillar = 'Pillar',
  KnowledgeSkillsAndAbilities = 'Knowledge, Skills and Abilities',
  Time = 'Time',
  KeyTerms = 'Key Terms',
  BriefDescription = 'Brief Description',
  Competency = 'Competency',
  Language = 'Language',
  Languages = 'Languages',
  AccessExpirationDate = 'Access Expiration Date',
  LearningObjectives = 'Learning Objectives',
  AccessibleToStudents = 'Accessible to Students',
  File = 'File',
  FileType = 'File Type',
  WebLink = 'Web Link',
}

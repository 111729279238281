import {useSelector} from 'react-redux';
import {
  isSomeOfSessionsInEditModeSelector,
  isSomeOfSessionsWithEmptyNameSelector,
  builderSessionItemsCollectionSelector,
  builderSessionItemsEntitiesSelector,
  builderSessionItemsResultSelector,
  learningPathsCreateBuilderIsAllSessionsExpanded,
} from '../../session-builder/store';
import {
  instrumentsBaseEntitiesCollectionSelector,
  instrumentsBaseEntitiesSelector,
  instrumentsConcatenatedBaseEntitiesCollectionSelector,
  instrumentsConcatenatedBaseEntitiesSelector,
  resourcesBaseEntitiesCollectionSelector,
  resourcesBaseEntitiesSelector,
  resourcesConcatenatedBaseEntitiesCollectionSelector,
  resourcesConcatenatedBaseEntitiesSelector,
} from 'filters-selections';
import {ZERO_NUMBER} from 'utils';

export const useLearningPathBuilderItemsEntities = () => {
  const builderSessionItemsEntities = useSelector(builderSessionItemsEntitiesSelector);
  const builderSessionItemsResult = useSelector(builderSessionItemsResultSelector);
  const builderSessionItemsCollection = useSelector(builderSessionItemsCollectionSelector);

  const isAllSessionsExpanded = useSelector(learningPathsCreateBuilderIsAllSessionsExpanded);

  const resourcesBaseEntities = useSelector(resourcesBaseEntitiesSelector);
  const resourcesBaseEntitiesCollection = useSelector(resourcesBaseEntitiesCollectionSelector);

  const resourcesConcatenatedBaseEntities = useSelector(resourcesConcatenatedBaseEntitiesSelector);
  const resourcesConcatenatedBaseEntitiesCollection = useSelector(resourcesConcatenatedBaseEntitiesCollectionSelector);

  const instrumentsBaseEntities = useSelector(instrumentsBaseEntitiesSelector);
  const instrumentsBaseEntitiesCollection = useSelector(instrumentsBaseEntitiesCollectionSelector);

  const instrumentsConcatenatedBaseEntities = useSelector(instrumentsConcatenatedBaseEntitiesSelector);
  const instrumentsConcatenatedBaseEntitiesCollection = useSelector(
    instrumentsConcatenatedBaseEntitiesCollectionSelector
  );

  const isSomeOfSessionsInEditMode = useSelector(isSomeOfSessionsInEditModeSelector);
  const isSomeOfSessionsWithEmptyName = useSelector(isSomeOfSessionsWithEmptyNameSelector);

  const hasSessions = builderSessionItemsCollection.length > ZERO_NUMBER;

  return {
    builderSessionItemsEntities,
    builderSessionItemsResult,
    builderSessionItemsCollection,

    resourcesBaseEntities,
    resourcesBaseEntitiesCollection,
    resourcesConcatenatedBaseEntities,
    resourcesConcatenatedBaseEntitiesCollection,

    instrumentsBaseEntities,
    instrumentsBaseEntitiesCollection,
    instrumentsConcatenatedBaseEntities,
    instrumentsConcatenatedBaseEntitiesCollection,

    isAllSessionsExpanded,
    isSomeOfSessionsInEditMode,
    isSomeOfSessionsWithEmptyName,

    hasSessions,
  };
};

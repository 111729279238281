/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback} from 'react';
import {unwrapResult} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';
import {useAppDispatch, useNavigate} from 'hooks';
import {ICreateQuestionPayload} from 'models';
import {usePageLoading} from '.';
import {useItemSavingActions} from '.';
import {
  createQuestionThunk,
  fetchItemThunk,
  fetchNewItemThunk,
  fetchQuestionsThunk,
  deleteItemThunk,
  fetchPossibleDefaultAnswerStacksThunk,
  addDefaultAnswerStackThunk,
  addCustomAnswerStackThunk,
  updateAnswerStackThunk,
  deletingSelector,
  promotingStackSelector,
} from '../store';
import {
  mapItemDefaultAnswerStacksPayload,
  mapItemFormDataPayload,
  mapItemFormDataToUpdateAnswerStackPayload,
} from '../builder/utils';
import {promoteItemAnswerStackThunk} from '../store/thunks/promoteItemAnswerStack.thunk';

export function useItemStateActions() {
  const dispatch = useAppDispatch();
  const {push} = useNavigate();
  const {pageLoading, loadingPageSection} = usePageLoading();
  const {saving} = useItemSavingActions();
  const promotingStack = useSelector(promotingStackSelector);

  const fetchNewItem = useCallback(() => dispatch(fetchNewItemThunk()), []);

  const fetchItem = useCallback(
    (id: number, fullPageLoad = true) => dispatch(fetchItemThunk({id, push, fullPageLoad})).then(unwrapResult),
    []
  );

  const fetchQuestions = useCallback(() => dispatch(fetchQuestionsThunk()), []);

  const fetchPossibleDefaultAnswerStacks = useCallback(
    (questionId: string) => dispatch(fetchPossibleDefaultAnswerStacksThunk(questionId)),
    []
  );

  const createQuestion = useCallback(
    (payload: ICreateQuestionPayload) => dispatch(createQuestionThunk(payload)).then(unwrapResult),
    [dispatch]
  );

  const deleteItem = useCallback((itemId: number) => dispatch(deleteItemThunk({itemId, push})), [dispatch, push]);

  const addDefaultAnswerStack = useCallback(
    (payload) => dispatch(addDefaultAnswerStackThunk(mapItemDefaultAnswerStacksPayload(payload))).then(unwrapResult),
    [dispatch]
  );

  const addCustomAnswerStack = useCallback(
    (payload) => dispatch(addCustomAnswerStackThunk(mapItemFormDataPayload(payload))).then(unwrapResult),
    [dispatch]
  );

  const updateAnswerStack = useCallback(
    (payload) =>
      dispatch(updateAnswerStackThunk(mapItemFormDataToUpdateAnswerStackPayload(payload))).then(unwrapResult),
    [dispatch]
  );

  const deleteAnswerStack = useCallback(
    (payload, fullPageLoad = true) =>
      dispatch(updateAnswerStackThunk(mapItemFormDataToUpdateAnswerStackPayload(payload), push, fullPageLoad)).then(
        unwrapResult
      ),
    [dispatch]
  );

  const promoteAnswerStack = useCallback(
    async (answerStackId: number, questionId: number) => {
      await promoteItemAnswerStackThunk(answerStackId)(dispatch);
      await fetchItem(questionId, false);
    },
    [dispatch, fetchItem]
  );

  return {
    fetchItem,
    fetchNewItem,
    deleteItem,
    fetchQuestions,
    fetchPossibleDefaultAnswerStacks,
    createQuestion,
    pageLoading,
    saving,
    deleting: deletingSelector,
    promotingStack,
    loadingPageSection,
    addDefaultAnswerStack,
    addCustomAnswerStack,
    updateAnswerStack,
    deleteAnswerStack,
    promoteAnswerStack,
  };
}

import {IUploadStoreState} from './IUploadStoreState';
import {uploadFileThunk, uploadScormThunk, createWebLinkThunk} from './thunks';

export const uploadExtraReducer = {
  // uploadFileThunk
  [uploadFileThunk.fulfilled]: (state: IUploadStoreState) => {
    state.file.page.loading = false;
  },
  [uploadFileThunk.pending]: (state: IUploadStoreState) => {
    state.file.page.loading = true;
  },
  [uploadFileThunk.rejected]: (state: IUploadStoreState) => {
    state.file.page.loading = false;
  },

  // uploadScormThunk
  [uploadScormThunk.fulfilled]: (state: IUploadStoreState) => {
    state.scormPackage.page.loading = false;
  },
  [uploadScormThunk.pending]: (state: IUploadStoreState) => {
    state.scormPackage.page.loading = true;
  },
  [uploadScormThunk.rejected]: (state: IUploadStoreState) => {
    state.scormPackage.page.loading = false;
  },

  // createWebLinkThunk
  [createWebLinkThunk.fulfilled]: (state: IUploadStoreState) => {
    state.webLink.page.loading = false;
  },
  [createWebLinkThunk.pending]: (state: IUploadStoreState) => {
    state.webLink.page.loading = true;
  },
  [createWebLinkThunk.rejected]: (state: IUploadStoreState) => {
    state.webLink.page.loading = false;
  },
};

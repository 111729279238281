export enum FormNameEnum {
  id = 'id',
  templateImportFile = 'templateImportFile',
  certificate = 'certificate',
  email = 'email',
  clientId = 'clientId',
  clientSecret = 'clientSecret',
  canGiveEditorPermissions = 'canGiveEditorPermissions',
  googleDriveSourceFolderLink = 'googleDriveSourceFolderLink',
  googleDriveDestinationFolderLink = 'googleDriveDestinationFolderLink',
  googleDriveFolderLink = 'googleDriveFolderLink',
  isGoogleClassroom = 'isGoogleClassroom',
  isGoogleSitesSource = 'isGoogleSitesSource',
  isGoogleSitesDestination = 'isGoogleSitesDestination',
  isMicrosoftTeams = 'isMicrosoftTeams',
  authorityUrl = 'authorityUrl',
  integrationName = 'integrationName',
  serviceEmail = 'serviceEmail',
  certificateExpirationDate = 'certificateExpirationDate',
  googleSitesList = 'googleSitesList',
  searchTerm = 'searchTerm',
  googleSitesFilteredList = 'googleSitesFilteredList',
  name = 'name',
  keyTerms = 'keyTerms',
  programs = 'programs',
  sessions = 'sessions',
  pillars = 'pillars',
  primaryPillars = 'primaryPillars',
  secondaryPillars = 'secondaryPillars',
  primaryPillar = 'primaryPillar',
  secondaryPillar = 'secondaryPillar',
  competencies = 'competencies',
  gradeLevels = 'gradeLevels',
  languages = 'languages',
  language = 'language',
  skillsAndBehaviors = 'skillsAndBehaviors',
  briefDescription = 'briefDescription',
  expirationDate = 'expirationDate',
  accessExpirationDate = 'accessExpirationDate',
  learningObjectives = 'learningObjectives',
  knowledgeSkillsAndAbilities = 'knowledgeSkillsAndAbilities',
  programYears = 'programYears',
  program = 'program',
  sessionName = 'sessionName',
  sessionDescription = 'sessionDescription',
  existingFiles = 'existingFiles',
  futureProgramYear = 'futureProgramYear',
  resources = 'resources',
  selectedResources = 'selectedResources',
  selectedResourcesIds = 'selectedResourcesIds',
  instruments = 'instruments',
  selectedInstruments = 'selectedInstruments',
  selectedInstrumentsIds = 'selectedInstrumentsIds',
  title = 'title',
  description = 'description',
  learningPathName = 'learningPathName',
  masterClassroomEmailAccount = 'masterClassroomEmailAccount',
  displayName = 'displayName',
  fileType = 'fileType',
  isAccessibleToStudents = 'isAccessibleToStudents',
  shouldDisableFileType = 'shouldDisableFileType',
  time = 'time',
  webLink = 'webLink',
  file = 'file',
  fileVersion = 'fileVersion',
  hasUploadPermissions = 'hasUploadPermissions',
  canEdit = 'canEdit',
  fileTypeRecognition = 'fileTypeRecognition',
  versions = 'versions',
  search = 'search',
  menuItems = 'menuItems',
  filteredMenuItems = 'filteredMenuItems',
  selectedId = 'selectedId',
  integrationType = 'integrationType',
}

import React, {Dispatch, FC, Fragment, SetStateAction, useEffect, useMemo} from 'react';
import {useFormikContext} from 'formik';
import {makeStyles} from '@material-ui/core/styles';
import {Column, Text, Wrapper} from 'components-lib';
import {FormNameEnum, SelectionEnum, SelectionListItems} from 'filters-selections';
import {ItemAnswerOpenEnded, ItemAnswersCustom, ItemAnswersRange, ItemAnswersMultiSelect, ResponseTypeGroup} from '..';
import {getAnswerType} from '../../utils';
import {IModalCreateAnswerStackFormValues} from './config';

const selectionList = [
  SelectionEnum.PillarMultiSelect,
  SelectionEnum.EvaluationType,
  SelectionEnum.Domain,
  SelectionEnum.ProgramSelectWithDomainDependency,
  SelectionEnum.CompetencyByDomain,
  SelectionEnum.Concepts,
  SelectionEnum.AdministrationType,
  SelectionEnum.SchoolLevel,
  SelectionEnum.LearningObjectiveWithDomainDependency,
  SelectionEnum.Category,
];

interface IModalCreateAnswerStackContentProps {
  isDirty: boolean;
  setIsDirtyCallback: Dispatch<SetStateAction<boolean>>;
}

export const ModalCreateAnswerStackContent: FC<IModalCreateAnswerStackContentProps> = ({
  isDirty,
  setIsDirtyCallback,
}) => {
  const classes = useStyles();
  const {values, dirty, isValid, errors} = useFormikContext<IModalCreateAnswerStackFormValues>();

  useEffect(() => {
    if (dirty && isValid && !isDirty) {
      setIsDirtyCallback(true);
    } else if (!isValid) {
      setIsDirtyCallback(false);
    }
  }, [dirty, isDirty, isValid, setIsDirtyCallback]);

  const answerStackErrors = errors[FormNameEnum.customValidation];

  const {
    isAnswerTypeMultiChoice,
    isAnswerTypeSingleDropdownChoice,
    isAnswerTypeOpenEnded,
    isAnswerTypeNumericScale,
  } = getAnswerType(values[FormNameEnum.questionType]);

  const selectionListItems = useMemo(() => <SelectionListItems list={selectionList} />, []);

  const Metatags = useMemo(() => <Wrapper className={classes.metatags}>{selectionListItems}</Wrapper>, [
    classes,
    selectionListItems,
  ]);

  const answerStackError = useMemo(
    () => (
      <Wrapper className={classes.answerStackErrors}>
        {answerStackErrors && <Text.Error>{answerStackErrors}</Text.Error>}
      </Wrapper>
    ),
    [classes, answerStackErrors]
  );

  const answerStack = useMemo(
    () => (
      <Fragment>
        {isAnswerTypeSingleDropdownChoice && <ItemAnswersCustom choices={values.choices} />}
        {isAnswerTypeMultiChoice && <ItemAnswersMultiSelect choices={values.choices} />}
        {isAnswerTypeOpenEnded && <ItemAnswerOpenEnded />}
        {isAnswerTypeNumericScale && <ItemAnswersRange />}
      </Fragment>
    ),
    [
      isAnswerTypeSingleDropdownChoice,
      isAnswerTypeMultiChoice,
      isAnswerTypeOpenEnded,
      isAnswerTypeNumericScale,
      values.choices,
    ]
  );

  return (
    <Column>
      <Text.Heading as="h4" classes={{root: classes.heading}}>
        Add tags to make this item easy to find later on.
      </Text.Heading>
      <Fragment>{Metatags}</Fragment>
      <Text.Heading as="h4" classes={{root: classes.heading}}>
        Create an answer stack. Then, mark the correct or positive answer(s).
      </Text.Heading>
      {answerStackError}
      <ResponseTypeGroup />
      {answerStack}
    </Column>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    padding: theme.spacing(1, 0),
    color: theme.palette.grey[800],
  },
  metatags: {
    maxWidth: 420,
  },
  answerStackErrors: {
    marginBottom: 10,
  },
}));

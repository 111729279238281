import React, {FC, ReactNode, useCallback, useMemo, SyntheticEvent, DragEvent} from 'react';
import {ItemsImportFilePreview, ItemsImportInstructions, UploadFile} from '..';
import {ActiveStepEnum} from 'enums';
import {PageLayoutOneOrTwoEqualCols} from 'layout';
import {ActiveStepPage} from 'admin';
import {FileFieldName} from '../FileFiledName';
import {useFormikContext} from 'formik';
import {FormNameEnum} from 'enums/form';
import {DraggableArea} from 'components-lib';
import {useItemsImport} from 'admin/items-import/hooks';

interface IItemImportActiveStepPageProps {
  activeStep: ActiveStepEnum;
  header: ReactNode;
  contentFooter: ReactNode;
}

export const ItemImportActiveStepPage: FC<IItemImportActiveStepPageProps> = ({activeStep, header, contentFooter}) => {
  const {setFieldValue} = useFormikContext();
  const {uploading} = useItemsImport();

  const handleFileDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();

      const newFile = event.dataTransfer.files[0];
      setFieldValue(FormNameEnum.templateImportFile, newFile);
    },
    [setFieldValue]
  );

  const handleDragOver = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const uploadFileWrapper = useMemo(
    () => (
      <DraggableArea onDrop={handleFileDrop} onDragOver={handleDragOver}>
        <UploadFile />
      </DraggableArea>
    ),
    [handleFileDrop, handleDragOver]
  );

  const pageLayoutFirst = useMemo(
    () => (
      <PageLayoutOneOrTwoEqualCols
        header={header}
        footer={contentFooter}
        contentLeft={uploadFileWrapper}
        contentRight={<ItemsImportInstructions />}
        loading={uploading}
      />
    ),
    [contentFooter, header, uploadFileWrapper, uploading]
  );
  const pageLayoutSecond = useMemo(
    () => <PageLayoutOneOrTwoEqualCols header={header} content={<FileFieldName />} footer={contentFooter} />,
    [header, contentFooter]
  );
  const pageLayoutThird = useMemo(
    () => <PageLayoutOneOrTwoEqualCols header={header} footer={contentFooter} content={<ItemsImportFilePreview />} />,
    [contentFooter, header]
  );

  return (
    <ActiveStepPage
      activeStep={activeStep}
      pageLayoutFirst={pageLayoutFirst}
      pageLayoutSecond={pageLayoutSecond}
      pageLayoutThird={pageLayoutThird}
    />
  );
};

import React, {FC, ReactNode, useCallback, useMemo, useRef, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core';
import {IParticipantSummary} from 'models';
import {PaperNodeTypeEnum} from '../../enums/PaperNodeTypeEnum';
import {IPaperQuestion} from '../../models/IPaperQuestion';
import {InstructionalTextForQuestion} from '../InstructionalTextForQuestion/InstructionalTextForQuestion';
import {Question} from '../Question/Question';
import MatrixQuestion from '../MatrixQuestion/MatrixQuestion';
import ParticipantsTable from '../ParticipantsTable/ParticipantsTable';
import SurveyHeadline from '../SurveyHeadline/SurveyHeadline';
import SurveyPage from '../SurveyPage/SurveyPage';
import {createSurveyPages} from '../../utils/createSurveyPages';

interface IProps {
  elements: Array<any>;
  headline?: string;
  header?: ReactNode;
  footer?: ReactNode;
  participantList?: IParticipantSummary[];
  pageNumbers?: boolean;
}

const SurveyForPrint: FC<IProps> = ({elements, header, footer, headline = undefined, pageNumbers = false}) => {
  const [pages, setPages] = useState<Array<any>>([]);
  const objects = useRef<IPaperQuestion[]>([]);
  const measurePageRef = useRef<any>(null);

  const classes = useStyles();

  const afterQuestionMounted = useCallback((obj: IPaperQuestion) => {
    objects.current.push(obj);
  }, []);

  // add headline
  if (headline) {
    elements = [{type: PaperNodeTypeEnum.headline, title: headline}].concat(elements);
  }

  const qs = useMemo(
    () =>
      elements.map((el, index) => {
        const {type, questionId, title, html, choices, rows, columns, participantList} = el;
        switch (type) {
          case PaperNodeTypeEnum.checkbox:
          case PaperNodeTypeEnum.radioGroup:
          case PaperNodeTypeEnum.dropdown:
          case PaperNodeTypeEnum.rating:
            return (
              <Question
                key={`${index}-${questionId}`}
                text={title}
                htmlStr={html}
                id={questionId}
                choices={choices}
                afterMount={afterQuestionMounted}
              />
            );
          case PaperNodeTypeEnum.matrix:
            return (
              <MatrixQuestion
                key={`${index}-${questionId}`}
                text={title}
                id={questionId}
                rows={rows}
                columns={columns}
                afterMount={afterQuestionMounted}
              />
            );
          case PaperNodeTypeEnum.html:
            return (
              <InstructionalTextForQuestion
                key={`${index}-${questionId}`}
                html={html}
                afterMount={afterQuestionMounted}
              />
            );
          case PaperNodeTypeEnum.headline:
            return <SurveyHeadline text={title} afterMount={afterQuestionMounted} key={index} />;
          case PaperNodeTypeEnum.participantsTable:
            return <ParticipantsTable key={index} participants={participantList} afterMount={afterQuestionMounted} />;
        }
        throw new Error('Unknown question type');
      }),
    [elements, afterQuestionMounted]
  );

  useEffect(() => {
    // Build new questions here. In theory they should be the same size.
    if (!measurePageRef || !measurePageRef.current) return;
    setPages(createSurveyPages(objects.current, measurePageRef.current.clientHeight));
  }, []);

  const initialPage = (
    <SurveyPage ref={measurePageRef} header={header} footer={footer}>
      {qs}
    </SurveyPage>
  );

  const pagesToRender = pages.map((page, index) => {
    const extendedFooter = pageNumbers ? (
      <>
        <div>{footer}</div>
        <div className={classes.pageNumbers}>{index + 1}</div>
      </>
    ) : (
      footer
    );

    return (
      <SurveyPage key={index} header={header} footer={extendedFooter}>
        {page.questions}
      </SurveyPage>
    );
  });

  return <>{pagesToRender.length > 0 ? pagesToRender : initialPage}</>;
};

const useStyles = makeStyles(() => ({
  pageNumbers: {
    textAlign: 'end',
    fontWeight: 'bold',
    padding: '0 8px',
  },
}));

export default SurveyForPrint;

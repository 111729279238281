import {useCallback, useMemo} from 'react';
import {batch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {unwrapResult} from '@reduxjs/toolkit';
import {
  downloadExport,
  IInstrumentDetailsFormBaseValues,
  mapAreaProgramClassExport,
  mapUpdateInstrument,
  mapProgramComparison,
  mapMergedExport,
} from '..';
import {useInstruments} from '../../hooks';
import {ActiveModeEnum, useAppDispatch, useNavigate, useViewEditMode} from 'hooks';
import {
  IGetAreaProgramClassExportResponse,
  IGetGeneralInstrumentExportResponse,
  IGetMergedExportResponse,
} from 'models';
import {
  activeTemplateSelector,
  surveyForPreviewTemplateSelector,
  fetchAreaProgramClassExportThunk,
  fetchGeneralInstrumentExportThunk,
  fetchInstrumentForPreview,
  fetchInstrumentThunk,
  publishMasterInstrumentThunk,
  unPublishSessionInstrumentThunk,
  updateInstrumentThunk,
  fetchAreaProgramClassDetailedExportThunk,
  fetchAreaProgramClassAggregatedExportThunk,
  fetchProgramComparison,
  fetchMergedExport,
} from '../../store';
import {createLinkForDownload, toNumber} from 'utils';
import {pages} from 'paths';
import {useInstrumentIdParams} from './useInstrumentIdParams';
import {IFetchMergedExportQueryParams} from '../../models/IFetchMergedExportQueryParams';
import {IProgramComparisonResult} from 'admin/programs/details/reports/IProgramComparisonResult';

const pathNamePreviewWithAnswers = 'preview-answers';

export function useInstrument(templateId?: number) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {instrumentId} = useInstrumentIdParams();
  const {pageLoading} = useInstruments();
  const {activeMode, setActiveMode} = useViewEditMode();
  const {goBackOptionally, push} = useNavigate();

  const templateEntities = useSelector(activeTemplateSelector);
  const surveyForPreviewTemplateEntitites = useSelector(surveyForPreviewTemplateSelector);

  const backButtonClickHandler = useCallback(() => {
    goBackOptionally(pages.adminPortal.instruments.all);
  }, [goBackOptionally]);

  const fetchInstrumentById: (templateId: number) => void = useMemo(
    () => (templateId: number) => dispatch(fetchInstrumentThunk(templateId)),
    [dispatch]
  );

  const fetchInstrumentForPreviewById: (templateId: number) => void = useMemo(
    () => (templateId: number) => dispatch(fetchInstrumentForPreview(templateId)),
    [dispatch]
  );

  const getProgramComparison = useMemo(
    () => (pasId: number, d2lId: number, areaId?: number, sessionId?: number) =>
      dispatch(fetchProgramComparison(mapProgramComparison(pasId, d2lId, areaId, sessionId))),
    [dispatch]
  );

  const getMergedProgramExport = useMemo(
    () => (pasId: number, d2lId: number, areaId?: number, sessionId?: number) =>
      dispatch(fetchMergedExport(mapMergedExport(pasId, d2lId, areaId, sessionId)))
        .then(unwrapResult)
        .then((parsedBody: IGetMergedExportResponse) => {
          const {mimeType, data, fileName} = parsedBody;
          downloadExport(mimeType, data, fileName);
        }),
    [dispatch]
  );

  const publishMasterTemplate: (templateId: number, onClose: () => void) => void = useMemo(
    () => (templateId: number, onClose: () => void) =>
      dispatch(publishMasterInstrumentThunk(templateId))
        .then(unwrapResult)
        .then(() => {
          onClose();
          fetchInstrumentById(templateId);
        })
        .catch(() => onClose),
    [dispatch, fetchInstrumentById]
  );

  const getGeneralInstrumentExport: (templateId: number) => void = useMemo(
    () => (templateId: number) =>
      dispatch(fetchGeneralInstrumentExportThunk(templateId))
        .then(unwrapResult)
        .then((parsedBody: IGetGeneralInstrumentExportResponse) => {
          const {mimeType, data, fileName} = parsedBody;
          createLinkForDownload(`data:${mimeType};base64,${data}`, fileName);
        }),
    [dispatch]
  );

  const getAreaProgramClassExport = useMemo(
    () => (programId: number, sessionId?: number, areaId?: number) =>
      dispatch(fetchAreaProgramClassExportThunk(mapAreaProgramClassExport(programId, sessionId, areaId)))
        .then(unwrapResult)
        .then((parsedBody: IGetAreaProgramClassExportResponse) => {
          const {mimeType, data, fileName} = parsedBody;
          downloadExport(mimeType, data, fileName);
        }),
    [dispatch]
  );

  const getAreaProgramClassDetailedExport = useMemo(
    () => (programId: number, sessionId?: number, areaId?: number) =>
      dispatch(fetchAreaProgramClassDetailedExportThunk(mapAreaProgramClassExport(programId, sessionId, areaId)))
        .then(unwrapResult)
        .then((parsedBody: IGetAreaProgramClassExportResponse) => {
          const {mimeType, data, fileName} = parsedBody;
          downloadExport(mimeType, data, fileName);
        }),
    [dispatch]
  );

  const getAreaProgramClassAggregatedExport = useMemo(
    () => (programId: number, sessionId?: number, areaId?: number) =>
      dispatch(fetchAreaProgramClassAggregatedExportThunk(mapAreaProgramClassExport(programId, sessionId, areaId)))
        .then(unwrapResult)
        .then((parsedBody: IGetAreaProgramClassExportResponse) => {
          const {mimeType, data, fileName} = parsedBody;
          downloadExport(mimeType, data, fileName);
        }),
    [dispatch]
  );

  const isRoutePreviewWithAnswers = location?.pathname.includes(pathNamePreviewWithAnswers);

  const activeTemplate = useMemo(() => templateEntities[templateId], [templateEntities, templateId]);

  const surveyForPreviewTemplate = useMemo(() => surveyForPreviewTemplateEntitites[templateId], [
    surveyForPreviewTemplateEntitites,
    templateId,
  ]);

  const updateInstrumentAndSetViewMode = useCallback(
    (setSubmitting?: (isSubmitting: boolean) => void) =>
      batch(() => {
        setSubmitting(false);
        setActiveMode(ActiveModeEnum.view);
      }),
    [setActiveMode]
  );

  const updateInstrument: (
    formData: IInstrumentDetailsFormBaseValues,
    setSubmitting?: (isSubmitting: boolean) => void
  ) => void = useCallback(
    (formData, setSubmitting) => {
      const payload = mapUpdateInstrument(formData, activeTemplate);
      dispatch(updateInstrumentThunk(payload))
        .then(unwrapResult)
        /**
         * Update the instrument changes and go back to view mode
         */
        .then(() => updateInstrumentAndSetViewMode(setSubmitting))
        .catch(() => updateInstrumentAndSetViewMode(setSubmitting));
    },
    [dispatch, updateInstrumentAndSetViewMode, activeTemplate]
  );

  const setActiveModeHandler: () => void = useCallback(() => {
    setActiveMode(activeMode === ActiveModeEnum.view ? ActiveModeEnum.edit : ActiveModeEnum.view);
  }, [activeMode, setActiveMode]);

  const classRelatedInstrumentEditConfirmationHandler: () => void = useCallback(() => {
    dispatch(unPublishSessionInstrumentThunk(instrumentId))
      .then(unwrapResult)
      .then(() => {
        setActiveModeHandler();
      });
  }, [dispatch, instrumentId, setActiveModeHandler]);

  const editItemsClickHandler: () => void = useCallback(() => {
    push(`${pages.adminPortal.instruments.buildLink}/${instrumentId}`);
  }, [push, instrumentId]);

  const previewClickHandler: () => void = useCallback(() => {
    push(`${pages.adminPortal.instruments.previewLink}/${instrumentId}`);
  }, [instrumentId, push]);

  const previewWithAnswersClickHandler: () => void = useCallback(() => {
    push(`${pages.adminPortal.instruments.previewWithAnswersLink}/${instrumentId}`);
  }, [instrumentId, push]);

  const exportGeneralClickHandler: () => void = useCallback(() => getGeneralInstrumentExport(toNumber(instrumentId)), [
    instrumentId,
    getGeneralInstrumentExport,
  ]);

  return {
    pageLoading,
    activeTemplate,
    surveyForPreviewTemplate,
    isRoutePreviewWithAnswers,
    activeMode,

    fetchInstrumentById,
    fetchInstrumentForPreviewById,
    publishMasterTemplate,
    getGeneralInstrumentExport,
    getAreaProgramClassExport,
    getAreaProgramClassDetailedExport,
    getAreaProgramClassAggregatedExport,
    setActiveMode,
    backButtonClickHandler,
    classRelatedInstrumentEditConfirmationHandler,
    setActiveModeHandler,
    editItemsClickHandler,
    previewClickHandler,
    previewWithAnswersClickHandler,
    exportGeneralClickHandler,
    updateInstrument,
    getProgramComparison,
    getMergedProgramExport,
  };
}

import {createAsyncThunk} from '@reduxjs/toolkit';
import {integrationsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {IIntegrationDetails} from 'models/integrations';
import {prefix} from './config';

export const fetchIntegrationDetailsThunk: any = createAsyncThunk<IIntegrationDetails, string>(
  `${prefix} fetchIntegrationStatuses`,
  async (integrationId: string, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await integrationsApi.getDetailsIntegration(integrationId);
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

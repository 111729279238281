import {createAsyncThunk} from '@reduxjs/toolkit';
import {IHttpResponse, permissionsApi} from 'api';
import {prefix} from './config';
import {config} from 'config';
import {toastNotificationManager} from 'toast-notifications';
import {IFetchUserActivityQueryParams} from '../../models';
import {IGetUserActivityExportResponse} from 'models';

const url = `${config.jaEeApi.baseUrlExports}/UserActivity/GetUserActivityReport`;

export const fetchUserActivityExportThunk: any = createAsyncThunk(
  `${prefix} fetchUserActivityExport`,
  async (params: IFetchUserActivityQueryParams, {rejectWithValue, dispatch}) => {
    try {
      const response: IHttpResponse<IGetUserActivityExportResponse> = await permissionsApi.getUserActivityExport(
        url,
        params
      );

      if (response.ok) {
        dispatch(toastNotificationManager.createSuccessToastAction('The export was generated successfully.'));
        return response.parsedBody;
      }
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.statusText || 'Unknown error occurred.'));
      return rejectWithValue(error);
    }
  }
);

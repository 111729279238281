import React, {FC, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Accordion, Column, Row, Text, Wrapper} from 'components-lib';
import {IAnswerStack} from 'models';
import {EditRangeAnswerStack, ItemAnswersCustom} from '..';
import {useFormikContext} from 'formik';
import {FormNameEnum} from 'filters-selections';
import {useEditAnswerStack, useMapChoicesBasedOnDomainKnowledge} from '../../hooks';
import {IItemEditFormValues} from '../../views/edit/itemBuilderEditForm';
interface IItemEditAnswerStackProps {
  answerStack: IAnswerStack;
}

export const ItemEditAnswerStack: FC<IItemEditAnswerStackProps> = ({answerStack}) => {
  const classes = useStyles();
  const {values, setFieldValue} = useFormikContext<IItemEditFormValues>();
  const choices = values[FormNameEnum.choices];
  const {isTypeRating, isTypeOpenEnded, isOtherType, isTypeMultiSelect} = useEditAnswerStack(answerStack);
  const {isDomainKnowledge} = useMapChoicesBasedOnDomainKnowledge(values, setFieldValue);

  const heading = useMemo(() => {
    return (
      <Row
        align="center"
        classes={{
          root: classes.heading,
        }}
      >
        <Text.Heading as="h4">{answerStack.answerTypeName}</Text.Heading>
      </Row>
    );
  }, [classes, answerStack]);

  return (
    <Accordion heading={heading} headingActionLeft="" initialIsExpanded withBorder disabled>
      {choices && (
        <Column>
          {isTypeRating && <EditRangeAnswerStack />}
          {isOtherType && (
            <Wrapper className={classes.otherAnswerStacks}>
              <ItemAnswersCustom
                disableDelete={isTypeMultiSelect && isDomainKnowledge}
                disableAdd={isTypeMultiSelect && isDomainKnowledge}
                withEdit
                withRightButton
                choices={choices}
                canEditAnswerInAnswerStack={answerStack?.canModifyAnswerQuestionAnswerstacks}
              />
            </Wrapper>
          )}
        </Column>
      )}
      {isTypeOpenEnded && <Column />}
    </Accordion>
  );
};

const useStyles = makeStyles((theme) => ({
  answer: {
    paddingLeft: theme.spacing(4),
  },
  heading: {
    flex: 1,
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(3),
  },
  otherAnswerStacks: {
    marginLeft: theme.spacing(2),
    width: '50%',
  },
}));

import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Text, Wrapper} from 'components-lib';
import {isString} from 'utils';

interface IErrorMessageProps {
  message?: string | string[];
  defaultMessage: string;
  withBottomMargin?: boolean;
}

export const ErrorMessage: FC<IErrorMessageProps> = ({message, defaultMessage, withBottomMargin}) => {
  const classes = useStyles();
  return (
    <Wrapper className={classes.message}>
      <Text.Error withBottomMargin={withBottomMargin}>{isString(message) ? message : defaultMessage}</Text.Error>
    </Wrapper>
  );
};

const useStyles = makeStyles(() => ({
  message: {
    flex: 1,
  },
}));

import React from 'react';
import {Typography} from '@material-ui/core';
import {IComponentWithChildren} from 'models';

interface ICaptionProps extends IComponentWithChildren {
  classes?: {[key: string]: any};
}

export function Caption({children, classes}: ICaptionProps) {
  return (
    <Typography variant="caption" classes={classes}>
      {children}
    </Typography>
  );
}

import React, {FC, useMemo} from 'react';
import {AttachmentArea, FileUploader} from 'components-lib';
import {IWithDisabled} from 'models/common';
import {useDragAndDrop} from 'hooks';
interface IHorizontalPositionedProps extends IWithDisabled {
  title?: string;
  loading?: boolean;
  name: string;
  accept?: string;
  withOnlyBrowseButton?: boolean;
  deleteCallback?: () => void;
}

export const HorizontalPositioned: FC<IHorizontalPositionedProps> = ({
  name,
  accept,
  loading,
  deleteCallback,
  title,
  disabled,
  withOnlyBrowseButton,
}) => {
  const {
    file,
    error,
    fileInputRef,
    addFileClickHandler,
    deleteClickHandler,
    openFileDialogClickHandler,
  } = useDragAndDrop(name, deleteCallback);

  const attachmentArea = useMemo(
    () => <AttachmentArea file={file} loading={loading} deleteCallback={deleteClickHandler} />,
    [loading, deleteClickHandler, file]
  );

  return (
    <FileUploader.Horizontal
      accept={accept}
      file={file}
      error={error}
      disabled={disabled}
      attachmentArea={attachmentArea}
      fileInputName={name}
      fileInputRef={fileInputRef}
      addFileCallback={addFileClickHandler}
      openFileDialogClickHandler={openFileDialogClickHandler}
      title={title}
      withOnlyBrowseButton={withOnlyBrowseButton}
    />
  );
};

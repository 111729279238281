import React, {useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {Accordion, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {Form} from 'forms';
import {useAppDispatch} from 'hooks';
import {IWithDisabled} from 'models';
import {
  fetchSkillsAndBehaviorsThunk,
  filtersSkillsAndBehaviorsEntitiesCollectionSelector,
} from 'filters-selections/store';

export function SkillsAndBehaviorsFilter({disabled}: IWithDisabled) {
  const dispatch = useAppDispatch();
  const skillsAndBehaviors = useSelector(filtersSkillsAndBehaviorsEntitiesCollectionSelector);

  useEffect(() => {
    if (!skillsAndBehaviors.length) {
      dispatch(fetchSkillsAndBehaviorsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const options = useMemo(() => skillsAndBehaviors.map((skills) => ({value: skills.id, label: skills.name})), [
    skillsAndBehaviors,
  ]);

  return (
    <Accordion heading="Skills And Behaviors">
      {skillsAndBehaviors && skillsAndBehaviors.length ? (
        <Form.SelectAutocomplete
          name={FormNameEnum.skillsAndBehaviors}
          options={options}
          multiple
          disabled={disabled}
        />
      ) : (
        <Text.Caption>No Data</Text.Caption>
      )}
    </Accordion>
  );
}

import {useEffect} from 'react';
import {FormNameEnum} from 'filters-selections';
import {IItemEditFormValues} from '../views/edit/itemBuilderEditForm';
import {createEmptyFourAnswers, getIsDomainKnowledge} from '../utils';
import {MAX_EMPTY_ANSWERS} from 'utils/items';
import {IModalCreateAnswerStackFormValues} from '../components/ModalCreateAnswerStack';

export const useMapChoicesBasedOnDomainKnowledge = (
  values: IItemEditFormValues | IModalCreateAnswerStackFormValues,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
) => {
  const choices = values[FormNameEnum.choices];

  const isDomainKnowledge = getIsDomainKnowledge(values?.domain);

  useEffect(() => {
    if (isDomainKnowledge && choices) {
      if (choices?.length > MAX_EMPTY_ANSWERS) {
        setFieldValue(FormNameEnum.choices, choices.slice(0, MAX_EMPTY_ANSWERS));
      }

      if (choices?.length < MAX_EMPTY_ANSWERS) {
        const restLength = MAX_EMPTY_ANSWERS - choices?.length ?? 0;
        setFieldValue(FormNameEnum.choices, [...choices, ...createEmptyFourAnswers(restLength)]);
      }
    }
  }, [isDomainKnowledge, setFieldValue, choices]);

  return {isDomainKnowledge};
};

import {SvgIcon} from '@material-ui/core';
import React from 'react';
import {ReactComponent as MasterTemplateSvg} from 'resources/icons/Icon-Master_Instrument.svg';

// TODO refactor
export function MasterTemplateIcon() {
  return (
    <SvgIcon>
      <MasterTemplateSvg />
    </SvgIcon>
  );
}

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {IOption} from 'models';
import * as React from 'react';
import {useState} from 'react';
import {AUTOCOMPLETE_TIMEOUT} from 'utils';
import State from '../State';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProps {
  id?: string;
  name: string;
  options?: IOption[];
  placeholder?: string;
  label?: string;
  noOptionsText?: string;
  isMultipleSelect?: boolean;
  disabled?: boolean;
  onStopTyping?: (text: string) => Promise<IOption[]>;
  handleChange?: (name: string, value: IOption[]) => void;
}

/// TODO refactor
export default function AutocompleteWithSelect({
  id,
  name,
  options,
  label,
  placeholder,
  onStopTyping,
  noOptionsText,
  isMultipleSelect,
  disabled,
  handleChange,
}: IProps) {
  const [currentOptions, setCurrOptions] = useState<IOption[]>(options || []);
  const [timeout, setCurrTimeout] = useState<any>(0);
  const [searchText, setSearchText] = useState<string | null | undefined>('');

  const onChange = (event: any, chosenOpts: IOption[], reason: any, form: any) => {
    if (reason === 'clear' || (reason === 'remove-option' && chosenOpts.length === 0)) {
      setCurrOptions([]);
    }

    if (typeof handleChange === 'function') {
      handleChange(name, chosenOpts);
    }

    form.setFieldValue(name, chosenOpts);
  };

  const getOptionSelected = (option: IOption, chosenValue: IOption) => {
    return option.value === chosenValue.value;
  };

  const onTextChange = async (event: any, form: any) => {
    const textValue = event.target.value;
    setSearchText('');

    if (onStopTyping) {
      if (timeout) {
        clearTimeout(timeout);
      }

      setCurrTimeout(
        setTimeout(async () => {
          const existing = form.values[name] || [];
          const opts = await onStopTyping(textValue);

          setCurrOptions(opts.concat(existing));
          form.setFieldValue(name, existing);
          setSearchText(textValue);
        }, AUTOCOMPLETE_TIMEOUT)
      );
    }
  };

  const onFocus = () => {
    if (onStopTyping) {
      setCurrOptions([]);
      setSearchText('');
    }
  };

  return (
    <State>
      {(form) => (
        <>
          <Autocomplete
            id={id}
            freeSolo
            style={{width: '100%'}}
            multiple={isMultipleSelect === false ? isMultipleSelect : true}
            disabled={disabled ? disabled : false}
            options={currentOptions || []}
            disableCloseOnSelect
            value={form.values[name] || []}
            onChange={(event: any, opt: IOption[], reason: any) => onChange(event, opt, reason, form)}
            getOptionLabel={(option: IOption) => option.label}
            getOptionSelected={(option: IOption, value: any) => getOptionSelected(option, value)}
            onFocus={onFocus}
            noOptionsText={searchText && searchText.trim().length > 0 ? 'No options available' : noOptionsText}
            renderOption={(option: IOption, {selected}) => (
              <>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                {option.label}
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                onChange={(event) => onTextChange(event, form)}
                label={label}
                placeholder={placeholder}
              />
            )}
          />
        </>
      )}
    </State>
  );
}

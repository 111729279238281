import React, {forwardRef, Fragment, useCallback} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {batch} from 'react-redux';
import {IVerticalDropdownItem} from 'admin/resources/files/models/IVerticalDropdownItem';
import {MenuItem as MUIMenuItem, makeStyles} from '@material-ui/core';
import {IBaseFileItem} from 'models/resources/files';
import {useFilesAll} from 'admin/resources/files/views/all/hooks';
import {IResourceContentTypeNameEnum} from 'enums/upload';
import {Tooltip} from 'components-lib';
import {filesAllMessages} from 'admin/resources/files/views/all/utils';

interface IDropdownItemProps {
  file: IBaseFileItem;
  handleDropdownClose?: () => void;
  clickHandler?: () => void;
  item: IVerticalDropdownItem;
}

export const DropdownItem = forwardRef(({item, file, handleDropdownClose}: IDropdownItemProps, ref: any) => {
  const classes = useStyles();
  const {fileDownloading, openFileInNewTab, downloadFile, copyClickHandler} = useFilesAll();

  const hasUrl = !!file?.url;
  const isCopyable = item.isCopyLink && hasUrl;
  const isOpenInNewWindow = item.isOpenInNewWindow && hasUrl;
  const isDownloadable = item.isDownload && !!file?.contentItemId;
  const isScormPackage = file?.contentType === IResourceContentTypeNameEnum.ScormResource;
  const shouldShowTooltip = isScormPackage && !hasUrl;
  const tooltipText = shouldShowTooltip ? filesAllMessages.scormHasNotShortUrlMessage : '';

  const handleMenuItemClick = useCallback(() => {
    batch(() => {
      handleDropdownClose();

      if (item.isCopyLink) {
        return copyClickHandler();
      }

      if (item.isOpenInNewWindow) {
        return openFileInNewTab(file?.url);
      }

      if (item.isDownload) {
        return downloadFile(file?.contentItemId);
      }
    });
  }, [item, file, handleDropdownClose, openFileInNewTab, copyClickHandler, downloadFile]);

  return (
    <Fragment>
      <Tooltip placement="left-start" title={tooltipText}>
        {item.isCopyLink && (
          <CopyToClipboard ref={ref} text={file?.url}>
            <MUIMenuItem
              classes={{root: classes.root}}
              disabled={!isCopyable || item.disabled}
              onClick={handleMenuItemClick}
            >
              {item.text}
            </MUIMenuItem>
          </CopyToClipboard>
        )}
      </Tooltip>
      <Tooltip placement="left-start" title={tooltipText}>
        {item.isOpenInNewWindow && (
          <MUIMenuItem
            classes={{root: classes.root}}
            ref={ref}
            disabled={!isOpenInNewWindow || item.disabled}
            onClick={handleMenuItemClick}
          >
            {item.text}
          </MUIMenuItem>
        )}
      </Tooltip>
      {item.isDownload && (
        <MUIMenuItem
          classes={{root: classes.root}}
          ref={ref}
          disabled={!isDownloadable || item.disabled || fileDownloading}
          onClick={handleMenuItemClick}
        >
          {item.text}
        </MUIMenuItem>
      )}
    </Fragment>
  );
});

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 500,
  },
}));

DropdownItem.displayName = 'DropdownItem';

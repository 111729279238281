import {Text} from 'components-lib';
import React from 'react';

interface IEntityHeaderNameProps {
  name: string;
}

export function EntityHeaderName({name}: IEntityHeaderNameProps) {
  return (
    <div style={{display: 'flex', width: '100%'}}>
      <Text.Heading as="h3">{name}</Text.Heading>
    </div>
  );
}

import React, {useCallback, useRef} from 'react';
import {makeStyles} from '@material-ui/core';
import {ModalDelete} from 'admin/components';
import {IBuilderDragItem} from 'admin/library/models';
import {Icon, Text} from 'components-lib';
import {DropContainer} from 'dragAndDrop';
import {useModal} from 'hooks';
import {useBuilderDrop} from '../../hooks';
import {BuilderItem} from '../BuilderItem/BuilderItem';
import {DroppableArea} from '../DroppableArea/DroppableArea';
import {isFunction} from 'utils/functions';
import {getCanLinkItem} from '../../utils';
import {IInstrumentDetails} from 'models';

interface IBuilderProps {
  pageLoading?: boolean;
  activeTemplate?: IInstrumentDetails;
  items: IBuilderDragItem[];
  deleteBuilderItemHandler?: (position: number) => void;
  editClickHandler?: (questionAnswerStackId: number, defaultAnswerStackId: number, position: number) => void;
  editInstructionalTextClickHandler?: (
    questionAnswerStackId: number,
    defaultAnswerStackId: number,
    position: number
  ) => void;
  moveBuilderItemHandler?: (movedItemIdx: number, newPosition: number) => void;
  linkBuilderItemHandler?: (item: IBuilderDragItem) => void;
  handleOpenModalLink?: () => void;
  handleOpenInfoLinkModal?: () => void;
  setCurrentBuilderItem?: (item: IBuilderDragItem) => void;
}

export function Builder({
  pageLoading = false,
  items,
  editClickHandler,
  editInstructionalTextClickHandler,
  deleteBuilderItemHandler,
  moveBuilderItemHandler,
  handleOpenModalLink,
  handleOpenInfoLinkModal,
  setCurrentBuilderItem,
  activeTemplate,
}: IBuilderProps) {
  const classes = useStyles();
  const {drop, isActive} = useBuilderDrop();
  const {isOpen: isDeleteBuilderItemModalOpen, setIsOpen: setIsDeleteBuilderItemModalOpen} = useModal();
  const deleteBuilderItemRef: any = useRef();

  const deleteBuilderItem = (questionAnswerStackId: number) => () => deleteBuilderItemHandler(questionAnswerStackId);

  const linkBuilderItemClickHandler = useCallback(
    (item) => {
      setCurrentBuilderItem(item);
      handleOpenModalLink();
    },
    [setCurrentBuilderItem, handleOpenModalLink]
  );

  const openInfoLinkClickHandler = useCallback(
    (item) => {
      setCurrentBuilderItem(item);
      handleOpenInfoLinkModal();
    },
    [setCurrentBuilderItem, handleOpenInfoLinkModal]
  );

  return (
    <>
      <DropContainer drop={drop}>
        {!items.length && !pageLoading ? (
          <DroppableArea isActive={isActive}>
            <>
              <Icon.FolderIcon disabled={false} />
              <Text.Caption>Drag and Drop items here from the left-hand sidebar.</Text.Caption>
            </>
          </DroppableArea>
        ) : (
          <>
            <div className={classes.droppableArea}>
              {isActive && <DroppableArea isActive={isActive} asOverlay />}
              {items.length &&
                items.map((item: IBuilderDragItem, idx: number) => {
                  const canLinkBuilderItem = getCanLinkItem(item, activeTemplate?.type?.id);

                  return (
                    <BuilderItem
                      key={idx}
                      canDelete={isFunction(deleteBuilderItemHandler)}
                      builderItem={item}
                      position={idx}
                      totalItems={items.length}
                      deleteHandler={(questionAnswerStackId) => {
                        deleteBuilderItemRef.current = deleteBuilderItem(questionAnswerStackId);
                        setIsDeleteBuilderItemModalOpen(true);
                      }}
                      editClickHandler={editClickHandler}
                      editInstructionalTextClickHandler={editInstructionalTextClickHandler}
                      moveHandler={moveBuilderItemHandler}
                      linkBuilderItem={canLinkBuilderItem && !!setCurrentBuilderItem && linkBuilderItemClickHandler}
                      openInfoLinkClickHandler={(item) => {
                        !!setCurrentBuilderItem && openInfoLinkClickHandler(item);
                      }}
                    />
                  );
                })}
            </div>
          </>
        )}
      </DropContainer>
      <ModalDelete
        text="Are you sure you want to delete this item? Once the survey has been published you won't be able to add it
        again."
        isOpen={isDeleteBuilderItemModalOpen}
        setIsOpen={setIsDeleteBuilderItemModalOpen}
        confirmHandler={() => deleteBuilderItemRef.current()}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  droppableArea: {
    position: 'relative',
    marginRight: theme.spacing(3),
  },
}));

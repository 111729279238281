import {useFormikContext} from 'formik';
import {useIntegration} from './../../hooks/useIntegration';
import {IIntegrationDetailsFormBaseValues} from 'models/integrations';
import {DEFAULT_GOOGLE_DRIVE_FOLDER_LINK, useIntegrationDetailType} from 'admin';

export const useIntegrationDetailsFormValues = () => {
  const {integration} = useIntegration();
  const {values} = useFormikContext<IIntegrationDetailsFormBaseValues>();
  const {isGoogleClassRoom, isMicrosoftTeams, isGoogleSitesSource, isGoogleSitesDestination} = useIntegrationDetailType(
    integration.integrationType
  );

  const isIntegrationNameChanged = !!values.integrationName ? integration.name !== values.integrationName : false;
  const isIntegrationAuthorityUrlChanged = integration.configuration.authorityUrl !== values.authorityUrl;
  const isIntegrationClientSecretChanged =
    !!integration.configuration.clientSecret && integration.configuration.clientSecret !== values.clientSecret;
  const isIntegrationClientIdChanged = integration.configuration.clientId !== values.clientId;
  const isIntegrationServiceEmailChanged =
    !isMicrosoftTeams && integration.configuration.serviceEmailAccount !== values.serviceEmail;
  const isIntegrationCertificateChanged = !!values.certificate;
  const isIntegrationGoogleDriveSourceFolderLinkChanged =
    DEFAULT_GOOGLE_DRIVE_FOLDER_LINK + integration.configuration.defaultFolderId !== values.googleDriveSourceFolderLink;
  const isIntegrationGoogleDriveDestinationFolderLinkChanged =
    DEFAULT_GOOGLE_DRIVE_FOLDER_LINK + integration.configuration.defaultFolderId !==
    values.googleDriveDestinationFolderLink;

  const getHasMsTeamsIntegrationNameChanged = () =>
    isIntegrationNameChanged &&
    !isIntegrationAuthorityUrlChanged &&
    !isIntegrationClientIdChanged &&
    !isIntegrationClientSecretChanged;

  const getGoogleClassroomIntegrationNameChanged = () => {
    return isIntegrationNameChanged && !isIntegrationServiceEmailChanged;
  };

  const getGoogleSitesSourceIntegrationNameChanged = () => {
    return (
      isIntegrationNameChanged &&
      !isIntegrationServiceEmailChanged &&
      !isIntegrationCertificateChanged &&
      !isIntegrationGoogleDriveSourceFolderLinkChanged
    );
  };

  const getGoogleSitesDestinationIntegrationNameChanged = () => {
    return isIntegrationNameChanged && !isIntegrationGoogleDriveDestinationFolderLinkChanged;
  };

  const getHasOnlyIntegrationNameChanged = () => {
    if (isGoogleClassRoom) {
      return getGoogleClassroomIntegrationNameChanged();
    }

    if (isMicrosoftTeams) {
      return getHasMsTeamsIntegrationNameChanged();
    }

    if (isGoogleSitesSource) {
      return getGoogleSitesSourceIntegrationNameChanged();
    }

    if (isGoogleSitesDestination) {
      return getGoogleSitesDestinationIntegrationNameChanged();
    }
  };
  const hasOnlyIntegrationNameChanged = getHasOnlyIntegrationNameChanged();

  return {
    isIntegrationNameChanged,
    isIntegrationAuthorityUrlChanged,
    isIntegrationClientSecretChanged,
    isIntegrationClientIdChanged,
    isIntegrationCertificateChanged,
    hasOnlyIntegrationNameChanged,
  };
};

import {useCallback} from 'react';
import {useAppDispatch} from 'hooks';
import {ILearningPathSessionItem} from 'models/resources';
import {useLearningPathBuilderItemsEntities} from '..';
import {sessionBuilderSliceSitesActions as actions} from '../store';

export const useSessionBuilderMode = () => {
  const dispatch = useAppDispatch();
  const {isSomeOfSessionsInEditMode} = useLearningPathBuilderItemsEntities();

  const setSessionBuilderMode = useCallback(
    (item: ILearningPathSessionItem) => dispatch(actions.setSessionBuilderMode(item)),
    [dispatch]
  );

  const getSessionBuilderMode = useCallback((item: ILearningPathSessionItem) => item.mode, []);

  return {getSessionBuilderMode, setSessionBuilderMode, isSomeOfSessionsInEditMode};
};

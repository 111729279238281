import React, {useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Container, Loading, PaginationLocal} from 'components-lib';
import {PageLayoutWithFixedAreas} from 'layout';
import {GoogleSitesContent, GoogleSitesForm, GoogleSitesHeader} from './components';
import {useGoogleSitesAll} from './hooks';

export const GoogleSitesAllView = () => {
  const classes = useStyles();

  const {
    loading,
    fetchGoogleSiteList,
    currentPage,
    perPage,
    totalCount,
    setCurrentPage,
    setPerPage,
    googleSitesList,
  } = useGoogleSitesAll();

  useEffect(() => {
    fetchGoogleSiteList();
  }, [fetchGoogleSiteList]);

  const contentFooter = useMemo(() => {
    return (
      <PaginationLocal
        onPageChange={setCurrentPage}
        onPerPageChange={setPerPage}
        totalCount={totalCount}
        perPage={perPage}
        currentPage={currentPage}
        disabled={loading}
      />
    );
  }, [currentPage, perPage, setCurrentPage, setPerPage, totalCount, loading]);

  const header = useMemo(() => <GoogleSitesHeader />, []);

  const content = useMemo(
    () => (
      <Container disableGutters classes={{root: classes.root}}>
        <GoogleSitesForm googleSitesList={googleSitesList}>
          <PageLayoutWithFixedAreas headerSize="small" header={header} footer={contentFooter}>
            {loading ? <Loading /> : <GoogleSitesContent />}
          </PageLayoutWithFixedAreas>
        </GoogleSitesForm>
      </Container>
    ),
    [loading, contentFooter, header, classes.root, googleSitesList]
  );

  return content;
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
  },
}));

import {SortingOrderEnum} from 'enums';
import {ProgramsAllOrderingEnum} from '../all/enums';

export const programsOrderingMap = {
  [ProgramsAllOrderingEnum.Name]: {
    [SortingOrderEnum.ASC]: 'Name: A-Z',
    [SortingOrderEnum.DESC]: 'Name: Z-A',
  },
  [ProgramsAllOrderingEnum.ProgramYear]: {
    [SortingOrderEnum.ASC]: 'Program Year: Oldest First',
    [SortingOrderEnum.DESC]: 'Program Year: Newest First',
  },
};

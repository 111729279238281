import React, {Fragment, useMemo} from 'react';
import {useFormikContext} from 'formik';

import {FormNameEnum} from 'filters-selections';
import {Form} from 'forms';

export function ItemQuestionName() {
  const {values} = useFormikContext();
  const hasQuestionText = !!values[FormNameEnum.questionTextHtml];

  const PreviewTextEditor = useMemo(
    () => () => <Form.RichTextEditor name={FormNameEnum.questionTextHtml} withEdit readOnly toolbarHidden />,
    []
  );
  return <Fragment>{hasQuestionText && <PreviewTextEditor />}</Fragment>;
}

import {useCallback} from 'react';
import {unwrapResult} from '@reduxjs/toolkit';
import {useAppDispatch, useNavigate} from 'hooks';

import {
  createGoogleSitesDestinationThunk,
  createGoogleSitesSourceThunk,
  createGoogleVirtualClassThunk,
  createMicrosoftTeamsThunk,
  fetchPublishedSourceDetailsThunk,
  fetchServiceEmailThunk,
  testGoogleClassroomThunk,
  testGoogleSitesDestinationThunk,
  testGoogleSitesSourceThunk,
  testMicrosoftTeamsThunk,
} from 'admin';
import {pages} from 'paths';
import {
  IAddIntegrationPayload,
  ICreatedIntegration,
  ICreateGoogleVirtualClassResponse,
  ICreateMicrosoftTeamsVirtualClassResponse,
} from 'models';
import {IHttpResponse} from 'api/models';

export const useAddIntegrationStateActions = () => {
  const dispatch = useAppDispatch();
  const {push} = useNavigate();

  const redirectToViewListOfIntegrations = useCallback(() => {
    push(`${pages.adminPortal.integrations.all}`);
  }, [push]);

  const createGoogleClassroom = useCallback(
    (payload: IAddIntegrationPayload): void =>
      dispatch(createGoogleVirtualClassThunk(payload))
        .then(unwrapResult)
        .then((response: IHttpResponse<ICreateGoogleVirtualClassResponse>) => response.data)
        .then(({id}: ICreatedIntegration) =>
          dispatch(testGoogleClassroomThunk(id)).then(() => redirectToViewListOfIntegrations())
        )
        .catch(() => redirectToViewListOfIntegrations()),
    [dispatch, redirectToViewListOfIntegrations]
  );

  const createGoogleSitesSource = useCallback(
    (payload: IAddIntegrationPayload): void =>
      dispatch(createGoogleSitesSourceThunk(payload))
        .then(unwrapResult)
        .then((response: IHttpResponse<ICreateGoogleVirtualClassResponse>) => response.data)
        .then(({id}: ICreatedIntegration) =>
          dispatch(testGoogleSitesSourceThunk(id)).then(() => redirectToViewListOfIntegrations())
        )
        .catch(() => redirectToViewListOfIntegrations()),
    [dispatch, redirectToViewListOfIntegrations]
  );

  const createGoogleSitesDestination = useCallback(
    (payload: IAddIntegrationPayload): void =>
      dispatch(createGoogleSitesDestinationThunk(payload))
        .then(unwrapResult)
        .then((response: IHttpResponse<ICreateGoogleVirtualClassResponse>) => response.data)
        .then(({id}: ICreatedIntegration) =>
          dispatch(testGoogleSitesDestinationThunk(id)).then(() => redirectToViewListOfIntegrations())
        )
        .catch(() => redirectToViewListOfIntegrations()),
    [dispatch, redirectToViewListOfIntegrations]
  );

  const createMicrosoftTeams = useCallback(
    (payload: IAddIntegrationPayload): void =>
      dispatch(createMicrosoftTeamsThunk(payload))
        .then(unwrapResult)
        .then((response: IHttpResponse<ICreateMicrosoftTeamsVirtualClassResponse>) => response.data)
        .then(({id}: ICreatedIntegration) =>
          dispatch(testMicrosoftTeamsThunk(id)).then(() => redirectToViewListOfIntegrations())
        )
        .catch(() => redirectToViewListOfIntegrations()),
    [dispatch, redirectToViewListOfIntegrations]
  );

  const fetchServiceEmail = useCallback((): void => dispatch(fetchServiceEmailThunk()), [dispatch]);

  const fetchPublishedSourceDetails = useCallback((): void => dispatch(fetchPublishedSourceDetailsThunk()), [dispatch]);

  return {
    createGoogleClassroom,
    createGoogleSitesSource,
    createGoogleSitesDestination,
    createMicrosoftTeams,
    redirectToViewListOfIntegrations,
    fetchServiceEmail,
    fetchPublishedSourceDetails,
  };
};

import {Accordion, Column, Text} from 'components-lib';
import {Form} from 'forms';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormNameEnum} from '../../../enums';
import {fetchDomainsThunk, filtersDomainsEntitiesCollectionSelector} from '../../../store';
import {IWithDisabled} from 'models';

export function DomainFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const domains = useSelector(filtersDomainsEntitiesCollectionSelector);

  useEffect(() => {
    if (!domains.length) {
      dispatch(fetchDomainsThunk());
    }
  }, [domains.length, dispatch]);

  return (
    <Accordion heading="Domain">
      <Column>
        {domains.length ? (
          domains.map((domain) => (
            <Form.Checkbox
              key={domain.id}
              name={FormNameEnum.domain}
              value={domain.id}
              label={domain.displayText}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>No Domains</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
}

import React, {Fragment, useEffect, useMemo} from 'react';
import {Wrapper, Table, Loading} from 'components-lib';
import {Subtitle} from 'admin/components';
import {makeStyles} from '@material-ui/core';
import {getAuditLogsColumns} from '../../utils';
import {useIntegration, useIntegrationStoreState} from 'admin/integrations';
import {getDatetimeAMPMinUTC} from 'utils/common';

export const AuditLogs = () => {
  const classes = useStyles();
  const {auditLogs, loadingAuditLogs} = useIntegration();
  const {clearIntegrationAuditLogsState} = useIntegrationStoreState();

  const columns = getAuditLogsColumns();

  useEffect(() => {
    return () => {
      clearIntegrationAuditLogsState();
    };
  }, [clearIntegrationAuditLogsState]);

  const rows = useMemo(() => {
    if (!auditLogs.length) {
      return [];
    }

    return auditLogs.map((auditLog) => {
      const {activity, date, messages, outcome, userName} = auditLog;
      const datetimeAMPMinUTC = getDatetimeAMPMinUTC(date);

      return {
        dateOfChange: datetimeAMPMinUTC,
        user: userName,
        activity,
        outcome,
        remarks: messages,
      };
    });
  }, [auditLogs]);

  const hasRows = !!rows.length;

  const tableContent = useMemo(
    () => (
      <Fragment>
        {hasRows ? <Table rows={rows} columns={columns} withTableHead /> : <Fragment>No results found</Fragment>}
      </Fragment>
    ),
    [rows, columns, hasRows]
  );

  return (
    <Fragment>
      <Subtitle withColor={false} as="h3" color="textPrimary" title="Audit Logs" />
      <Wrapper className={classes.content}>{loadingAuditLogs ? <Loading /> : tableContent}</Wrapper>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: theme.spacing(2),
  },
}));

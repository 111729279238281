import {IComponentWithClassName} from 'models';
import React from 'react';
import {ReactComponent as TrashSvg} from 'resources/images/instruments/Illustration_trash.svg';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ITrashIconProps extends IComponentWithClassName {}

export function TrashIcon({className}: ITrashIconProps) {
  return <TrashSvg className={className} />;
}

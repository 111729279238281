import * as Yup from 'yup';
import {IInputFile} from 'models';
import {FormNameEnum} from 'enums/form';
import {zipMimeType} from 'admin';

export interface IUploadPaperAssessmentFormBaseValues {
  [FormNameEnum.file]: IInputFile | undefined;
  [FormNameEnum.description]: string;
}

export const uploadPaperAssessmentsFormBaseValidationSchema = Yup.object().shape({
  [FormNameEnum.file]: Yup.mixed()
    .nullable()
    .required('You should attach a file!')
    .test('has-zip-extension', 'Only .zip file extension is allowed!', (file: IInputFile) => {
      const hasFile = !!file;
      if (!hasFile) return true;
      return zipMimeType.includes(file.type);
    }),
  [FormNameEnum.description]: Yup.string().max(250, 'Description should be less than 250 characters!'),
});

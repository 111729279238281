import {Column, Label} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ICategoryTagsProps extends IWithDisabled {}

export function CategoryTags({disabled}: ICategoryTagsProps) {
  return (
    <Column>
      <Label label="Category" />
      <Form.Input name={FormNameEnum.category} disabled={disabled} />
    </Column>
  );
}

import React, {FC, Fragment, useCallback, useMemo} from 'react';
import {Prompt} from 'react-router-dom';
import {useBeforeunload} from 'react-beforeunload';
import {LearningPathFooter} from 'admin/resources/learning-paths/components';
import {ModalDelete} from 'admin/components';
import {learningPathMessages} from 'admin/resources/learning-paths/utils';
import {useLearningPathBuilderItemsEntities} from 'admin/resources/learning-paths/views/session-builder';
import {useModal} from 'hooks';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';
import {useEditLearningPath, useLearningPathIdParam} from '../../hooks';
import {EntityStatusEnum} from 'enums';

interface IEditLearningPathFooterProps {
  isDirty: boolean;
  submitFormCallback: () => void;
}

export const EditLearningPathFooter: FC<IEditLearningPathFooterProps> = ({isDirty, submitFormCallback}) => {
  const {isSomeOfSessionsWithEmptyName} = useLearningPathBuilderItemsEntities();
  const {learningPathId} = useLearningPathIdParam();
  const {deleteLearningPath, publishLearningPath, loading, learningPathDetails} = useEditLearningPath();
  const {hasPermission} = usePermissions();
  const {isOpen: isModalEditLPOpen, setIsOpen: setIsModalEditLPOpen} = useModal();
  const {isOpen: isModalDeleteLPOpen, setIsOpen: setIsModalDeleteLPOpen} = useModal();

  useBeforeunload(() => (isDirty || isSomeOfSessionsWithEmptyName) && learningPathMessages.warning);

  const shouldRenderButton = hasPermission(USAAndSuperAdminRoles);
  const canPublishLearningPath = learningPathDetails?.details?.status === EntityStatusEnum.published;

  const shouldDisablePublish = learningPathDetails?.details ? canPublishLearningPath : true;

  const openModalClickHandler = useCallback(() => setIsModalEditLPOpen(true), [setIsModalEditLPOpen]);
  const openDeleteModalClickHandler = useCallback(() => setIsModalDeleteLPOpen(true), [setIsModalDeleteLPOpen]);

  const publishClickHandler = useCallback(() => learningPathId && publishLearningPath(learningPathId), [
    learningPathId,
    publishLearningPath,
  ]);

  const confirmHandler = useCallback(() => {
    if (isModalEditLPOpen) {
      return;
    }

    if (isModalDeleteLPOpen) {
      return learningPathId && deleteLearningPath(learningPathId);
    }
  }, [isModalEditLPOpen, isModalDeleteLPOpen, learningPathId, deleteLearningPath]);

  const modalDelete = useMemo(() => {
    return (
      <ModalDelete
        loading={false}
        title="Delete Learning Path"
        text="Are you sure you want to delete this learning path?"
        cancelButtonText="No"
        confirmButtonText="Yes"
        isOpen={isModalDeleteLPOpen}
        setIsOpen={setIsModalDeleteLPOpen}
        confirmHandler={confirmHandler}
      />
    );
  }, [isModalDeleteLPOpen, setIsModalDeleteLPOpen, confirmHandler]);

  return (
    <Fragment>
      <LearningPathFooter
        loading={loading}
        withSaveButton={true}
        withSubmitButton={false}
        withCancelButton={false}
        withDeleteButton={shouldRenderButton}
        withPublishButton={shouldRenderButton}
        disablePublish={shouldDisablePublish}
        disableSave={!isDirty || isSomeOfSessionsWithEmptyName}
        isSomeOfSessionsWithEmptyName={isSomeOfSessionsWithEmptyName}
        openModalClickHandler={openModalClickHandler}
        publishClickHandler={publishClickHandler}
        deleteClickHandler={openDeleteModalClickHandler}
        saveClickHandler={submitFormCallback}
      />
      {modalDelete}
      <Prompt when={isDirty} message={learningPathMessages.warning} />
    </Fragment>
  );
};

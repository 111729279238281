import React, {useEffect} from 'react';
import {LearningPathViewEditContainer} from './components';
import {useEditLearningPath, useEditLearningPathStateActions, useLearningPathIdParam} from './hooks';
import {FormContextProvider} from 'forms';

export const LearningPathViewEdit = () => {
  const {learningPathId} = useLearningPathIdParam();
  const {fetchLearningPathById, loading} = useEditLearningPath();
  const {clearLearningPath} = useEditLearningPathStateActions();

  useEffect(() => {
    if (learningPathId) {
      fetchLearningPathById(learningPathId);
    }
    return () => {
      clearLearningPath();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningPathId]);

  return (
    <FormContextProvider>
      <LearningPathViewEditContainer loading={loading} />
    </FormContextProvider>
  );
};

import React, {useCallback} from 'react';
import {useClassPaperAssessmentsStoreActions} from 'admin/programs/details/classes/details/paper-assessments/hooks';
import {SortByDropdown} from 'admin';
import {IProgramClassesPaperAssessmentsSortOrder} from 'models';
import {getPaperAssessmentsMenuItemsList, paperAssessmentsOrderingMap} from '../../utils';

export const PaperAssessmentsAllSortBy = () => {
  const {sortOrder, setSortOrder} = useClassPaperAssessmentsStoreActions();

  const handleItemClick = useCallback(
    (sortOrder: IProgramClassesPaperAssessmentsSortOrder) => setSortOrder(sortOrder),
    [setSortOrder]
  );

  return (
    <SortByDropdown
      label="Sort By: "
      handleItemClick={handleItemClick}
      getMenuItemsList={getPaperAssessmentsMenuItemsList}
      sortOrder={sortOrder}
      map={paperAssessmentsOrderingMap}
    />
  );
};

import {FormNameEnum} from 'filters-selections';
import {useFormikContext} from 'formik';
import {useCallback} from 'react';
import {createEmptyCustomAnswerStack} from '../utils';

export function useCustomAnswerStack() {
  const {values} = useFormikContext();

  const addCustomStack = useCallback(() => {
    return createEmptyCustomAnswerStack();
  }, []);

  const customStacksValue = values[FormNameEnum.customStacks];

  return {customStacksValue, addCustomStack};
}

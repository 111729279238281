import * as Yup from 'yup';
import {FormNameEnum} from 'filters-selections/enums';
import {errorMessages} from 'utils';
import {IAnswerStack} from 'models';

export interface IModalSelectAnswerStackFormValues {
  [FormNameEnum.pillars]: number[] | [];
  [FormNameEnum.evaluationTypes]: number[] | [];
  [FormNameEnum.domain]: number;
  [FormNameEnum.programs]: number[] | [] | number | null;
  [FormNameEnum.competencies]: number[] | [];
  [FormNameEnum.concepts]: number[] | [];
  [FormNameEnum.administration]: number | null;
  [FormNameEnum.schoolLevels]: number[] | [];
  [FormNameEnum.learningObjective]: string | '';
  [FormNameEnum.category]: string | '';
  [FormNameEnum.answerStacks]: IAnswerStack[];
}
export const modalSelectAnswerStackFormValidationSchema = Yup.object().shape({
  [FormNameEnum.pillars]: Yup.array().of(Yup.number()).required(errorMessages.requiredWithFieldName('a Pillar')),
  [FormNameEnum.evaluationTypes]: Yup.array()
    .of(Yup.number())
    .required(errorMessages.requiredWithFieldName('an Evaluation Type')),
  [FormNameEnum.domain]: Yup.number().nullable().required(errorMessages.requiredWithFieldName('a Domain')),
  [FormNameEnum.competencies]: Yup.array()
    .of(Yup.number())
    .when(FormNameEnum.domain, {
      is: 10,
      then: Yup.array().of(Yup.number()).required(errorMessages.requiredWithFieldName('a Competency')),
    }),
  [FormNameEnum.concepts]: Yup.array()
    .of(Yup.number())
    .when(FormNameEnum.domain, {
      is: 10,
      then: Yup.array().of(Yup.number()).required(errorMessages.requiredWithFieldName('a KSA')),
    }),
  [FormNameEnum.administration]: Yup.number()
    .nullable()
    .required(errorMessages.requiredWithFieldName('an Administration')),
  [FormNameEnum.schoolLevels]: Yup.array()
    .of(Yup.number())
    .required(errorMessages.requiredWithFieldName('a School Level')),
  [FormNameEnum.learningObjective]: Yup.string().ensure(),
  [FormNameEnum.category]: Yup.string().ensure(),
  [FormNameEnum.answerStacks]: Yup.array().of(
    Yup.object().shape({
      answerTypeName: Yup.string(),
      isSelected: Yup.boolean(),
      choises: Yup.array().of(
        Yup.object().shape({
          text: Yup.string(),
          isCorrect: Yup.boolean(),
          position: Yup.number(),
          answerQuestionAnswerStackId: Yup.number(),
          answerId: Yup.number(),
        })
      ),
      defaultAnswerStackId: Yup.number(),
    })
  ),
  [FormNameEnum.customValidation]: Yup.boolean().test(
    'has-selected-stack',
    'You should select a default answer stack and at least one correct answer!',
    function () {
      const {answerStacks} = this.parent;

      const isValid = answerStacks.some((stack: IAnswerStack) => {
        const isSelected = stack.isSelected;
        const hasCorrectChoice = stack.choices.some((choice) => choice.isCorrect);

        return isSelected && hasCorrectChoice;
      });

      return isValid;
    }
  ),
});

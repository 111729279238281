import ContainerMUI from '@material-ui/core/Container';
import {IComponentWithChildren} from 'models';
import React from 'react';

interface IContainerProps extends IComponentWithChildren {
  disableGutters?: boolean;
  justify?: 'center' | 'flex-start';
  maxWidth?: 'sm' | 'md' | false;
  classes?: any;
}

export function Container({children, disableGutters, classes, maxWidth = false}: IContainerProps) {
  return (
    <ContainerMUI maxWidth={maxWidth} disableGutters={disableGutters} classes={classes}>
      {children}
    </ContainerMUI>
  );
}

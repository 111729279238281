import {IFiltersSelectionsStoreState} from './IFiltersSelectionsStoreState';

export const filtersSelectionsInitialState: IFiltersSelectionsStoreState = {
  selection: {
    loadingSchoolNames: false,
    loadingResources: false,
    loadingInstruments: false,
    loadingDistricts: false,
  },
  programs: {
    result: [],
    entities: {
      programs: {},
    },
  },
  gradeLevels: {
    result: [],
    entities: {
      gradeLevels: {},
    },
  },
  years: {
    result: [],
    entities: {
      years: {},
    },
  },
  futureYears: {
    result: [],
    entities: {
      futureYears: {},
    },
  },
  educators: {
    result: [],
    entities: {
      educators: {},
    },
  },
  districts: [],
  period: {
    startDate: null,
    endDate: null,
  },
  areas: {
    result: [],
    entities: {
      areas: {},
    },
  },
  schools: {
    result: [],
    entities: {
      schools: {},
    },
  },
  audiences: {
    result: [],
    entities: {
      audiences: {},
    },
  },
  phases: {
    result: [],
    entities: {
      phases: {},
    },
  },
  instrumentTypes: {
    result: [],
    entities: {
      instrumentTypes: {},
    },
  },
  instrumentMainTypes: {
    result: [],
    entities: {
      instrumentMainTypes: {},
    },
  },
  instrumentStatuses: {
    result: [],
    entities: {
      instrumentStatuses: {},
    },
  },
  pillars: {
    result: [],
    entities: {
      pillars: {},
    },
  },
  domains: {
    result: [],
    entities: {
      domains: {},
    },
  },
  evaluationTypes: {
    result: [],
    entities: {
      evaluationTypes: {},
    },
  },
  competencies: {
    result: [],
    entities: {
      competencies: {},
    },
  },
  concepts: {
    result: [],
    entities: {
      concepts: {},
    },
  },
  administrations: {
    result: [],
    entities: {
      administrations: {},
    },
  },
  schoolLevel: {
    result: [],
    entities: {
      schoolLevel: {},
    },
  },
  itemTypes: {
    result: [],
    entities: {
      itemTypes: {},
    },
  },
  itemStatuses: {
    result: [],
    entities: {
      itemStatuses: {},
    },
  },
  sessions: {
    result: [],
    entities: {
      sessions: {},
    },
  },
  tagGradeLevels: {
    result: [],
    entities: {
      tagGradeLevels: {},
    },
  },
  skillsAndBehaviors: {
    result: [],
    entities: {
      skillsAndBehaviors: {},
    },
  },
  languages: {
    result: [],
    entities: {
      languages: {},
    },
  },
  defaultKeyTerms: {
    result: [],
    entities: {
      keyTerms: {},
    },
  },
  digitalResourceTypes: {
    entities: {
      digitalResourceTypes: {},
    },
    result: [],
  },
  integrationTypes: {
    result: [],
    entities: {
      integrationTypes: {},
    },
  },
  integrationStatuses: {
    result: [],
    entities: {
      integrationStatuses: {},
    },
  },
  schoolNames: {
    result: [],
    entities: {
      schoolNames: {},
    },
  },
  schoolDistricts: {
    result: [],
    entities: {
      schoolDistricts: {},
    },
  },
  uploadFileTypes: {
    result: [],
    entities: {
      uploadFileTypes: {},
    },
  },
  fileTypes: {
    result: [],
    entities: {
      fileTypes: {},
    },
  },
  resources: {
    result: [],
    entities: {
      resources: {},
    },
  },
  resourcesBase: {
    result: [],
    entities: {
      resourcesBase: {},
    },
  },
  resourcesConcatenatedBase: {
    result: [],
    entities: {
      resourcesConcatenatedBase: {},
    },
  },
  instruments: {
    result: [],
    entities: {
      instruments: {},
    },
  },
  instrumentsBase: {
    result: [],
    entities: {
      instrumentsBase: {},
    },
  },
  instrumentsConcatenatedBase: {
    result: [],
    entities: {
      instrumentsConcatenatedBase: {},
    },
  },
  learningPathStatuses: {
    result: [],
    entities: {
      learningPathStatuses: {},
    },
  },
  fileStatuses: {
    result: [],
    entities: {
      fileStatuses: {},
    },
  },
  accessibleToStudents: {
    result: [0, 1],
    entities: {
      accessibleToStudents: {
        0: {
          id: 0,
          name: 'No',
          displayText: 'No',
        },
        1: {
          id: 1,
          name: 'Yes',
          displayText: 'Yes',
        },
      },
    },
  },
  publishedBy: {
    result: [999999],
    entities: {
      publishedBy: {
        999999: {
          id: 999999,
          name: '999999',
          displayText: 'JA USA',
        },
      },
    },
  },

  initialMappedInstruments: [],
  digitalResourcesConcepts: [],
  keyTerms: [],
  learningObjectives: [],
};

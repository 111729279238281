import * as Yup from 'yup';
import {FormNameEnum} from 'enums/form';
import {getIsDateEarlierFromToday} from 'admin';
import {errorMessages} from 'utils';

export interface ICloneLearningPathFormBaseValues {
  [FormNameEnum.program]: number;
  [FormNameEnum.expirationDate]: string | Date;
}

export const cloneLearningPathFormBaseValidationSchema = Yup.object().shape({
  [FormNameEnum.program]: Yup.number().nullable().required(errorMessages.requiredWithFieldName('a Program')),
  [FormNameEnum.expirationDate]: Yup.date()
    .nullable()
    .required(errorMessages.requiredWithFieldName('an Expiration Date'))
    .typeError('Expected date format: (mm/dd/yyyy)')
    .test('is-date-earlier-than-now', 'The selected date should be in the future.', function () {
      const {expirationDate} = this.parent;

      // If the datepicker is cleaned up, then validation is true
      return getIsDateEarlierFromToday(expirationDate);
    }),
});

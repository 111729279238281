import {useCallback} from 'react';
import {useNavigate} from 'hooks';
import {pages} from 'paths';

export const useGoogleSites = () => {
  const {push} = useNavigate();

  const redirectToViewListOfGoogleSites = useCallback(() => {
    push(`${pages.adminPortal.googleSites.all}`);
  }, [push]);

  return {
    redirectToViewListOfGoogleSites,
  };
};

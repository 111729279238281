import {SvgIcon} from '@material-ui/core';
import React from 'react';
import {ReactComponent as GeneralInstrumentSvg} from 'resources/icons/iconGeneralInstrument.svg';

export function GeneralInstrumentIcon() {
  return (
    <SvgIcon>
      <GeneralInstrumentSvg />
    </SvgIcon>
  );
}

import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch} from 'hooks';
import {uploadFilePageLoadingSelector, uploadFileThunk} from 'admin/resources';

export const useUploadFile = () => {
  const dispatch = useAppDispatch();
  const loading = useSelector(uploadFilePageLoadingSelector) as boolean;
  const uploadFile = useCallback((payload: FormData) => dispatch(uploadFileThunk(payload)), [dispatch]);

  return {loading, uploadFile};
};

import {createSlice} from '@reduxjs/toolkit';
import {learningPathsCreateInitialState} from './learningPathsCreate.initialState';
import {learningPathsCreateReducer} from './learningPathsCreate.reducer';
import {learningPathsCreateExtraReducer} from './learningPathsCreateExtra.reducer';

export const learningPathsCreateSlice = createSlice({
  name: 'learningPathsCreateSlice',
  initialState: learningPathsCreateInitialState,
  reducers: learningPathsCreateReducer,
  extraReducers: learningPathsCreateExtraReducer,
});

export const {actions: learningPathsCreateSitesActions} = learningPathsCreateSlice;

import {useCallback} from 'react';
import {useActiveItem, useItemStateActions} from '.';

export function useItem() {
  const {item} = useActiveItem();
  const {
    fetchItem,
    fetchNewItem,
    deleteItem,
    promoteAnswerStack,
    pageLoading,
    loadingPageSection,
    saving,
    promotingStack,
  } = useItemStateActions();

  const deleteActiveItem = useCallback(() => deleteItem(item.id), [deleteItem, item.id]);

  const promoteAnswerStackClickHandler = useCallback(
    (answerStackId: number, questionId: number) => promoteAnswerStack(answerStackId, questionId),
    [promoteAnswerStack]
  );

  return {
    fetchItem,
    fetchNewItem,
    deleteItem: deleteActiveItem,
    promoteAnswerStackClickHandler,
    pageLoading,
    loadingPageSection,
    saving,
    item,
    promotingStack,
  };
}

import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {Container} from 'components-lib';
import {Subtitle} from 'admin/components';
import {makeStyles} from '@material-ui/core';
import {AddResourcesDrawerList, AddInstrumentsDrawerList, LinkExistingResources} from '..';
import {useFormikContext} from 'formik';
import {IAddSessionInstrumentsFormValues} from '../AddSessionInstrumentsForm/config';

interface IAddSessionAdditionalContentProps {
  isResource?: boolean;
  isDirty: boolean;
  setIsDirtyCallback: Dispatch<SetStateAction<boolean>>;
}

export const AddSessionAdditionalContent: FC<IAddSessionAdditionalContentProps> = ({
  isDirty,
  isResource = true,
  setIsDirtyCallback,
}) => {
  const classes = useStyles();
  const title = `Link existing ${isResource ? 'file' : 'instrument'}`;

  const {dirty, isValid} = useFormikContext<IAddSessionInstrumentsFormValues>();

  useEffect(() => {
    if (dirty && isValid && !isDirty) {
      setIsDirtyCallback(true);
    } else if (!isValid) {
      setIsDirtyCallback(false);
    }
  }, [dirty, isDirty, isValid, setIsDirtyCallback]);

  return (
    <Container disableGutters classes={{root: classes.content}}>
      <Subtitle withColor={false} as="h4" color="primary" title={title} />
      <LinkExistingResources isResource={isResource} />
      {isResource && <AddResourcesDrawerList />}
      {!isResource && <AddInstrumentsDrawerList />}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    height: '78vh',
    flex: '1 1 auto',
    padding: theme.spacing(0, 3),
    overflow: 'auto',

    [theme.breakpoints.down('md')]: {
      height: '70vh',
    },

    [theme.breakpoints.down('sm')]: {
      height: '60vh',
    },
  },
}));

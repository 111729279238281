import {FormNameEnum} from 'filters-selections';
import {useFormikContext} from 'formik';
import {useState, useCallback} from 'react';

export const useImageUploadWithPreview = ({name, fileInputRef, url}) => {
  const {values, setFieldValue} = useFormikContext();

  const fileName = `${name}Name`;
  const value = values[FormNameEnum[name]];

  const [hightlight, setHightlight] = useState<boolean>(false);
  const [imgPreview, setImgPreview] = useState<string>(url);
  const [file, setFile] = useState(value);

  const openFileDialog = useCallback((fileInputRef) => fileInputRef.current.click(), []);

  const defineDimensions = useCallback(
    async (file) =>
      Object.defineProperty(file, 'dimensions', {
        value: await checkDimensions(file),
      }),
    []
  );

  const setValues = useCallback(
    (file) => {
      if (!!file) {
        const url = window.URL.createObjectURL(file);
        setFile(file);
        setImgPreview(url);
        setFieldValue(FormNameEnum[name], file);
        setFieldValue(FormNameEnum[fileName], file.name);
        return;
      }
    },
    [name, setFieldValue, fileName]
  );

  const onFilesAddedChange = useCallback(
    async (e) => {
      const file = e.target.files[0];
      if (file) {
        const extendedFile = await defineDimensions(file);
        return setValues(extendedFile);
      }
    },
    [setValues, defineDimensions]
  );

  const onDrop = useCallback(
    async (e) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      if (!!file) {
        const extendedFile = await defineDimensions(file);
        return setValues(extendedFile);
      }
    },
    [setValues, defineDimensions]
  );

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    setHightlight(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setHightlight(false);
  }, []);

  const onDelete = useCallback(() => {
    if (!!value) {
      setFile(null);
      setImgPreview('');
      setHightlight(false);
      fileInputRef.current.value = '';
      return setFieldValue(FormNameEnum[name], null);
    }
  }, [name, setFieldValue, value, fileInputRef]);

  const checkDimensions = (value) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = function (value) {
        const img = new Image();
        img.src = value.target.result as string;
        img.onload = function () {
          const aspectRatio = img.width / img.height;
          resolve({
            width: img.width,
            height: img.height,
            aspectRatio,
          });
        };
      };
    });
  };

  return {
    hightlight,
    file,
    imgPreview,
    openFileDialog,
    onDrop,
    onDragOver,
    onDragLeave,
    onFilesAddedChange,
    onDelete,
  };
};

import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useBeforeunload} from 'react-beforeunload';
import {batch} from 'react-redux';
import {IWithModal} from 'models/common';
import {Button, Column, Modal, Wrapper} from 'components-lib';
import {Form} from 'forms';
import {ModalCloneForm} from './ModalCloneForm';
import {ModalCloneContent} from './ModalCloneContent';
import {FormContext} from 'forms/formik/FormContextProvider';
import {learningPathsMessages} from 'admin/resources/learning-paths/views/all';

interface IModalCloneProps extends IWithModal {
  learningPathId: string;
  title?: string;
  withSubmitButton?: boolean;
}

export const ModalClone: FC<IModalCloneProps> = ({
  isOpen,
  setIsOpen,
  title = 'Clone Learning Path',
  withSubmitButton = false,
  learningPathId,
}) => {
  const loading = false;
  const {formikFormRef, setIsDirty, isDirty} = useContext(FormContext);

  useEffect(() => {
    return () => {
      setIsDirty(false);
    };
  }, [setIsDirty]);

  useBeforeunload(() => {
    if (loading) {
      return learningPathsMessages.warning;
    }
  });

  const closeHandler = useCallback(() => setIsOpen(false), [setIsOpen]);

  const submitFormClickHandler = useCallback(() => formikFormRef?.current?.submitForm(), [formikFormRef]);

  const confirmActionHandler = useCallback(
    () =>
      batch(() => {
        submitFormClickHandler();
        closeHandler();
      }),
    [submitFormClickHandler, closeHandler]
  );

  return (
    <Modal disableBackdropClick isOpen={isOpen} handleClose={closeHandler} title={title} size="medium">
      <ModalCloneForm learningPathId={learningPathId}>
        <Column>
          <ModalCloneContent />
        </Column>
      </ModalCloneForm>
      <Wrapper>
        <Button.Secondary clickHandler={closeHandler}>Cancel</Button.Secondary>
        {withSubmitButton ? (
          <Form.ButtonSubmit text="Clone" />
        ) : (
          <Button.Primary clickHandler={confirmActionHandler} disabled={!isDirty || loading}>
            Clone
          </Button.Primary>
        )}
      </Wrapper>
    </Modal>
  );
};

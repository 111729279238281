import {IAddInstrumentItemsPayload, IBaseInstrumentItem} from 'models/resources';
import {getInstrumentsCollectionByIds} from '.';

export const mapAddInstrumentItemsPayload = (
  id: string,
  instrumentItemIds: number[],
  instrumentsCollection: IBaseInstrumentItem[]
): IAddInstrumentItemsPayload => {
  const filteredInstrumentsCollection = getInstrumentsCollectionByIds(instrumentItemIds, instrumentsCollection);

  return {
    id,
    instruments: filteredInstrumentsCollection,
  };
};

const noOptions = 'No options found.';
const startTypingMessage = 'Please start typing the name of the ';

export const dynamicSearchMessages = {
  loading: 'Loading...',
  noOptions,
  noOptionsSchoolName: `${noOptions} ${startTypingMessage} school (e.g., “Brookfield Elementary School“, ...etc)`,
  noOptionsSchoolDistrict: `${noOptions} ${startTypingMessage} district or the state 2-letter code with dot (e.g. “Jefferson“, “IL.“, ..etc)`,
  noOptionsResource: `${noOptions} ${startTypingMessage} resource (e.g., “JA Our Nation Program Brief“, ...etc)`,
  noOptionsInstrument: `${noOptions} ${startTypingMessage} instrument (e.g., “JA Be Entrepreneurial Post-session Survey 5“), ...etc`,
};

import React, {useCallback} from 'react';
import SelectVirtualized from 'react-virtualized-select';
import {makeStyles, MenuItem} from '@material-ui/core';
import {IOption} from 'models';
import {updateSelectLabel} from 'utils';

interface ISelectProps {
  options: IOption[];
  value: IOption;
  disabled?: boolean;
  changeHandler: (value: number) => void;
}

export function Select({options, changeHandler, value, disabled = false}: ISelectProps) {
  const classes = useStyles();

  const changeHandlerInner = useCallback(
    (option: IOption) => {
      changeHandler(option?.value);
    },
    [changeHandler]
  );

  return (
    <SelectVirtualized
      multi={false}
      clearable={false}
      options={options}
      optionRenderer={Option}
      disabled={disabled}
      value={value}
      onChange={changeHandlerInner}
      className={classes.root}
      placeholder=""
      autoBlur
    />
  );
}

interface IOptionProps {
  option: any;
  style: any;
  selectValue: (option: IOption) => void;
}

function Option({style, option, selectValue}: IOptionProps) {
  const {updatedLabel} = updateSelectLabel(option.label);

  return (
    <MenuItem key={option.value} style={style} onClick={() => selectValue(option)} title={option.label}>
      {updatedLabel}
    </MenuItem>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.8125rem',
    fontWeight: 600,
    color: theme.palette.grey[900],
    '& .Select-control': {
      background: theme.palette.common.white,
      borderRadius: theme.spacing(1),
      '& .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label': {
        color: `${theme.palette.grey[900]} !important`,
      },
    },
    '& .Select-control, .Select-placeholder': {
      padding: 2.65,
    },
    '& .Select-value ': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .Select-input': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '0.8125rem',
      fontWeight: 600,
      color: theme.palette.grey[900],
      padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
    },
    '& .Select-control:hover': {
      borderColor: theme.palette.grey[900],
    },
    '&.Select.is-open > .Select-control': {
      borderColor: theme.palette.secondary.main,
      borderRadius: theme.spacing(1),
    },
    '&.Select.is-open > .Select-control:hover': {
      borderColor: theme.palette.primary.main,
      borderRadius: theme.spacing(1),
    },
    '&.Select.is-focused:not(.is-open) > .Select-control': {
      boxShadow: 'none',
      borderColor: theme.palette.grey[700],
    },
    '& .Select-menu-outer': {
      marginTop: theme.spacing(1),
      borderRadius: theme.spacing(1),
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    '& .VirtualizedSelectFocusedOption': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .Select-arrow': {
      borderColor: '#707070 transparent transparent',
    },
  },
}));

import React, {FC} from 'react';
import {MenuItemList} from './MenuItemList';
import {Dropdown} from 'admin/components';

interface ISortByDropdownProps {
  label: string;
  handleItemClick: (sortOrder: any) => any;
  getMenuItemsList: any;
  sortOrder?: any;
  map?: any;
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
}

export const SortByDropdown: FC<ISortByDropdownProps> = ({
  label,
  handleItemClick,
  getMenuItemsList,
  sortOrder,
  map,
  justify,
}) => {
  const dropdownText = map[sortOrder.order][sortOrder.sort];

  return (
    <Dropdown text={dropdownText} label={label} justify={justify}>
      {(itemClickHandler: () => void) => (
        <MenuItemList
          itemClickHandler={itemClickHandler}
          handleItemClick={handleItemClick}
          getMenuItemsList={getMenuItemsList}
        />
      )}
    </Dropdown>
  );
};

import React, {useCallback, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {ViewExpansionPanel} from 'admin/programs/details/components';
import {Filters} from 'components-lib';
import {FiltersEnum, FiltersList, FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import {AdminRoles, RolesEnum, usePermissions} from 'permissions';
import {toNumber} from 'utils';
import {useProgramClasses} from '../../hooks';
import {sidebarProgramClassesValidationSchema, IProgramClassesFilterSidebarForm} from '../../utils/schema';

export function ProgramClassesAllSidebarFilters() {
  const {programId} = useParams<{programId: string}>();
  const {filter, setFilter, resetFilter, loading} = useProgramClasses(toNumber(programId));
  const {hasPermission} = usePermissions();

  const initialValues: IProgramClassesFilterSidebarForm = useMemo(
    () => ({
      [FormNameEnum.search]: filter.searchByName,
      [FormNameEnum.programYears]: filter.programYearsFilter,
      [FormNameEnum.educators]: filter.educatorsFilter,
      [FormNameEnum.districts]: filter.districtsFilter,
      [FormNameEnum.schoolNames]: filter.schoolNamesFilter,
      [FormNameEnum.areas]: filter.areasFilter,
      [FormNameEnum.startDate]: filter.startDate,
      [FormNameEnum.endDate]: filter.endDate,
      [FormNameEnum.onlyVirtualClass]: filter.onlyVirtualClasses,
    }),
    [filter]
  );

  const renderFiltersList = useMemo(() => {
    const shouldRenderAreaFilter = hasPermission([RolesEnum.SuperAdmin, RolesEnum.USAAdmin]);
    const shouldRenderFilters = hasPermission(AdminRoles);

    const filters = [
      FiltersEnum.ProgramYearMulti,
      FiltersEnum.SchoolNameMulti,
      // No Districts - Removed temporary, please see: https://mentormate.atlassian.net/browse/JADF-1598
      // FiltersEnum.Districts,
      FiltersEnum.FromToDate,
      FiltersEnum.Educators,
      FiltersEnum.ClassesOnlyVirtual,
    ];

    if (shouldRenderAreaFilter) {
      filters.splice(1, 0, FiltersEnum.AreasMultiAutocompleteExternalData);
    }

    return (
      <>
        <ViewExpansionPanel disabled={loading} />
        {shouldRenderFilters && <FiltersList filters={filters} disableAll={loading} />}
      </>
    );
  }, [hasPermission, loading]);

  const submitHandler = useCallback(
    (formValues: IProgramClassesFilterSidebarForm) => {
      return setFilter({
        ...filter,
        searchByName: formValues.search,
        educatorsFilter: formValues.educators,
        areasFilter: formValues.areas,
        schoolNamesFilter: formValues.schoolNames,
        districtsFilter: formValues.districts,
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        programYearsFilter: formValues.programYears,
        onlyVirtualClasses: formValues.onlyVirtualClasses,
      });
    },
    [filter, setFilter]
  );

  return (
    <Form.Form<IProgramClassesFilterSidebarForm>
      initialValues={initialValues}
      validationSchema={sidebarProgramClassesValidationSchema}
      submitHandler={submitHandler}
    >
      <Filters.FiltersPanel
        clearButtonClickHandler={resetFilter}
        searchFieldName={'search'}
        filters={renderFiltersList}
        disableAll={loading}
      />
    </Form.Form>
  );
}

import React, {useEffect, useMemo} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Oidc, OidcSecure} from '@axa-fr/react-oidc-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import {GlobalCss} from 'styles';
import {Loading} from 'components-lib';
import {Layout} from 'layout';
import {AuthFailedPage, FileNotFoundPage} from 'pages';
import {pages} from 'paths';
import {usePermissions} from 'permissions';
import {Routes} from 'routes';
import {store} from 'store';
import {SurveyTakerGeneralInstrument} from 'survey-taker';
import {ToastNotification} from 'toast-notifications';
import {useUser, useUserId} from 'user';
import {config} from './config';
import {UserStore} from './utils';
import {ErrorBoundary} from './shared/components';

const {oidc} = config;
const LoadingComponent = () => <Loading />;

function App() {
  useUser();
  const {userLoading} = useUserId();
  const {fetchUserRole, permissionsLoading, permissionsRequestTriggered} = usePermissions();
  const LoadingMemoized = useMemo(() => () => <Loading fullHeight />, []);

  useEffect(() => {
    !userLoading && !permissionsRequestTriggered && fetchUserRole();
  }, [userLoading, fetchUserRole, permissionsRequestTriggered]);

  const shouldRenderRoutes = !permissionsLoading && !userLoading;

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <CssBaseline />
        <GlobalCss />
        <ToastNotification />
        <Switch>
          <Route exact path={pages.surveyGeneral.taker}>
            <Layout navigation={null} withActions={false}>
              <SurveyTakerGeneralInstrument />
            </Layout>
          </Route>
          <Route exact path={pages.fileNotFound.root}>
            <Layout navigation={null} withActions={false}>
              <FileNotFoundPage />
            </Layout>
          </Route>
          <Oidc
            isEnabled
            store={store}
            notAuthorized={AuthFailedPage}
            notAuthenticated={AuthFailedPage}
            sessionLostComponent={LoadingComponent}
            callbackComponentOverride={LoadingMemoized}
            configuration={oidc}
            UserStore={UserStore}
          >
            <OidcSecure isEnabled authenticating={LoadingMemoized}>
              {shouldRenderRoutes ? <Routes /> : <LoadingMemoized />}
            </OidcSecure>
          </Oidc>
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;

import {useAppDispatch} from 'hooks';
import {useCallback} from 'react';
import {filtersActions as actions} from '../store/filtersSelections.slice';

export const useSchoolNameBasedOnAreaSelect = () => {
  const dispatch = useAppDispatch();

  const clearSchoolNameSelectItems = useCallback(() => dispatch(actions.clearSchools()), [dispatch]);

  return {clearSchoolNameSelectItems};
};

import React, {useCallback} from 'react';
import {GoogleSitesTable} from '..';
import {useFormikContext} from 'formik';
import {
  getFormattedGoogleSitesListDate,
  getGoogleSitesListColumns,
  IGoogleSitesFormValues,
} from 'admin/resources/google-sites';
import {Button} from 'components-lib';
import {pages} from 'paths';
import {useNavigate} from 'hooks';

export const GoogleSitesContent = () => {
  const {push} = useNavigate();
  const {
    values: {googleSitesFilteredList},
  } = useFormikContext<IGoogleSitesFormValues>();

  const columns = getGoogleSitesListColumns();

  const handleViewGoogleSitesDetails = useCallback(
    (googleSiteId: string) => push(`${pages.adminPortal.googleSites.detailsLink}/${googleSiteId}`),
    [push]
  );

  const rows = googleSitesFilteredList.map((el) => {
    if (!googleSitesFilteredList.length) {
      return [];
    }

    return {
      name: el.name,
      lastModifiedTime: getFormattedGoogleSitesListDate(el.lastModifiedTime),
      actions: (
        <Button.Secondary size="small" clickHandler={() => handleViewGoogleSitesDetails(el.id)}>
          View
        </Button.Secondary>
      ),
    };
  });

  return <GoogleSitesTable rows={rows} columns={columns} />;
};

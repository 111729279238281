import {FormNameEnum} from 'enums/form';
import {IBindBaseEnumEntity} from 'models';
import {ILearningPathDetails, ILearningPathSessionItem} from 'models/resources/learning-paths';
import {mapStringListToStringWithSemicolon} from 'admin/resources/utils';

const getFutureProgramYearsId = (
  learningPathDetails: ILearningPathDetails,
  futureProgramYears: IBindBaseEnumEntity[]
) => {
  const futureProgramYear = futureProgramYears.find(
    (el) => el.name === learningPathDetails?.metadata?.futureProgramYears
  );

  return futureProgramYear?.id;
};

const mapEditLearningPathPillars = (pillars: number[]) => ({
  primaryPillar: pillars && pillars[0],
  secondaryPillar: pillars && pillars[1],
});

export const mapEditLearningPathPayload = (
  learningPathDetails: ILearningPathDetails,
  futureProgramYearsCollection: IBindBaseEnumEntity[],
  sessions: ILearningPathSessionItem[]
) => {
  const futureProgramYearId = getFutureProgramYearsId(learningPathDetails, futureProgramYearsCollection);

  const keyTermsStr = mapStringListToStringWithSemicolon(learningPathDetails?.metadata?.keyTerms);
  const learningObjectiveStr = mapStringListToStringWithSemicolon(learningPathDetails?.metadata?.learningObjectives);
  const {primaryPillar, secondaryPillar} = mapEditLearningPathPillars(learningPathDetails?.metadata?.pillars);

  return {
    [FormNameEnum.id]: learningPathDetails?.id,
    [FormNameEnum.learningPathName]: learningPathDetails?.title,
    [FormNameEnum.futureProgramYear]: futureProgramYearId,
    [FormNameEnum.primaryPillar]: primaryPillar,
    [FormNameEnum.secondaryPillar]: secondaryPillar,
    [FormNameEnum.language]: learningPathDetails?.metadata?.language,
    [FormNameEnum.keyTerms]: keyTermsStr,
    [FormNameEnum.program]: learningPathDetails?.metadata?.program,
    [FormNameEnum.gradeLevels]: learningPathDetails?.metadata?.gradeLevels,
    [FormNameEnum.expirationDate]: learningPathDetails?.metadata?.expirationDate,
    [FormNameEnum.learningObjectives]: learningObjectiveStr,
    [FormNameEnum.briefDescription]: learningPathDetails?.description ?? '',
    [FormNameEnum.sessions]: sessions,
  };
};

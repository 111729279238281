import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {useSurvey} from 'survey-taker';
import {ISurvey} from 'survey-react';

interface IFooterLogoProps {
  survey: ISurvey;
  isPreview: boolean;
}

export const FooterLogo: FC<IFooterLogoProps> = ({survey, isPreview}) => {
  const classes = useStyles({isPreview});
  const {checkIfSurveyHasSinglePage, getSurveyPagesLength, getFooterLogo} = useSurvey();

  const surveyLen = getSurveyPagesLength(survey);
  const isSinglePage = checkIfSurveyHasSinglePage(surveyLen);
  const footerLogo = getFooterLogo(survey, isSinglePage);

  return (
    <div className={classes.footerLogo}>
      {footerLogo && (
        <img src={footerLogo} alt="Footer logo" loading="lazy" style={{width: '100%', maxWidth: '600px'}} />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  footerLogo: ({isPreview}: {isPreview: boolean}) => {
    const marginBottom = isPreview ? `${theme.spacing(5)}px` : `${theme.spacing(3)}px`;
    return {
      marginBottom,
      display: 'flex',
      justifyContent: 'center',
    };
  },
}));

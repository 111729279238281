import {config} from 'config';
import {ApiPathsEnum} from 'enums';
import {
  IAnswerStack,
  IBindBaseEntity,
  IBindItemsPage,
  ICustomAnswerStackPayload,
  IDefaultAnswerStackPayload,
  IGetPageOfItems,
  IInstrumentItem,
  IItemDetails,
  IItemsFilter,
  IUpdateAnswerStackPayload,
  IUpdateItemPayload,
  IUpdateItemResponse,
} from 'models';
import {returnQueryStringWithParams} from 'utils';
import {get, post, put} from './http-client';
import {IHttpResponse} from './models';

const {jaEeApi: apiConfig} = config;

export const itemsApi = {
  async getItems(): Promise<IHttpResponse<IInstrumentItem[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/GetItems`;
    return await get<IInstrumentItem[]>(url);
  },

  async getNewItem(): Promise<IHttpResponse<IAnswerStack[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.QUESTION}/GetNewQuestionWithMetadata`;
    return await get<IAnswerStack[]>(url);
  },

  async getItemById(itemId: number): Promise<IHttpResponse<IItemDetails>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/GetQuestion?id=${itemId}`;
    return await get<IItemDetails>(url);
  },

  async getPageOfItems(
    pagination: IGetPageOfItems | any,
    sortOptions: any,
    filters: IItemsFilter
  ): Promise<IHttpResponse<IBindItemsPage>> {
    const queryString = returnQueryStringWithParams(
      `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/GetItemsForPreview`,
      {
        ...pagination,
        ...sortOptions,
      }
    );

    return await post<IBindItemsPage>(queryString, filters);
  },

  async getItemsSortByColumns(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/SortItemsByColumn`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getItemsSortOrderTypes(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/SortOrder`;
    return await get<IBindBaseEntity[]>(url);
  },

  async promoteItemAnswerStack(answerStackId: number): Promise<IHttpResponse<any>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/PromoteItem?id=${answerStackId}`;
    return await post<any>(url, {});
  },

  async publishItemAnswerStack(answerStackId: number): Promise<IHttpResponse<any>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/PublishItem?id=${answerStackId}`;
    return await post<any>(url, {});
  },

  async unPublishItemAnswerStack(answerStackId: number): Promise<IHttpResponse<any>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/UnPublishItem?id=${answerStackId}`;
    return await post<any>(url, {});
  },

  async publishAllAnswerStacksForQuestion(questionId: number): Promise<IHttpResponse<any>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/PublishAllItemsForQuestion`;
    return await post<any>(url, {id: questionId});
  },

  async createUpdateItem(payload): Promise<IHttpResponse<any>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/CreateItemsForQuestion`;
    return await post<any>(url, payload.data);
  },

  async deleteItem(itemId: number): Promise<IHttpResponse<any>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.QUESTION}/DeleteQuestion?id=${itemId}`;
    return await put<any>(url, {});
  },

  async updateItem(payload: IUpdateItemPayload): Promise<IHttpResponse<IUpdateItemResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/UpdateQuestion`;
    return await post<IUpdateItemResponse>(url, payload);
  },

  async updateAnswerStack(payload: IUpdateAnswerStackPayload): Promise<IHttpResponse<any>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/UpdateAnswerStack`;
    return await post<any>(url, payload);
  },

  async getPossibleDefaultAnswerStacks(questionId: number): Promise<IHttpResponse<any>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/GetPossibleDefaultAnswerStacks?questionId=${questionId}`;
    return await get<IDefaultAnswerStackPayload[]>(url);
  },

  async addDefaultAnswerStack(payload: IDefaultAnswerStackPayload): Promise<IHttpResponse<any>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/AddDefaultAnswerStack`;
    return await post<IUpdateItemResponse>(url, payload);
  },

  async addCustomAnswerStack(payload: ICustomAnswerStackPayload): Promise<IHttpResponse<any>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM}/AddCustomAnswerStack`;
    return await post<IUpdateItemResponse>(url, payload);
  },
};

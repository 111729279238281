import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Column, Row} from 'components-lib';
import {IWithChildren} from 'models';

type IInstrumentEditContainerProps = IWithChildren;

export const InstrumentEditContainer: FC<IInstrumentEditContainerProps> = ({children}) => {
  const classes = useStyles();

  return (
    <Row justify="flex-start" classes={{root: classes.row}}>
      <Column sm={12} md={12} lg={12} xl={12} className={classes.col}>
        {children}
      </Column>
    </Row>
  );
};

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  col: {
    marginRight: theme.spacing(3),
  },
}));

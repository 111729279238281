import React, {Fragment, useCallback, useMemo} from 'react';
import {Button} from 'components-lib';
import {ILearningPathSessionItem} from 'models';
import {createEmptySession} from '../../utils';
import {
  useLearningPathBuilderItemsEntities,
  useSessionBuilderStoreActions,
} from 'admin/resources/learning-paths/views/session-builder';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';

export const AddSessionButton = () => {
  const {hasPermission} = usePermissions();
  const {builderSessionItemsCollection} = useLearningPathBuilderItemsEntities();
  const {addSessionBuilderItem} = useSessionBuilderStoreActions();

  const shouldRenderButton = hasPermission(USAAndSuperAdminRoles);

  const emptySession = createEmptySession();

  const emptySessionIdx = useMemo(
    () =>
      builderSessionItemsCollection.findIndex(
        ({title, description}: ILearningPathSessionItem) => title === '' && description === ''
      ),
    [builderSessionItemsCollection]
  );
  const shouldDisableAddSessionButton = emptySessionIdx > -1;

  const addSessionClickHandler = useCallback(() => addSessionBuilderItem(emptySession), [
    addSessionBuilderItem,
    emptySession,
  ]);

  return (
    <Fragment>
      {shouldRenderButton && (
        <Button.Primary
          size="small"
          isFirst
          clickHandler={addSessionClickHandler}
          disabled={shouldDisableAddSessionButton}
        >
          Add Session
        </Button.Primary>
      )}
    </Fragment>
  );
};

import React, {useCallback} from 'react';
import {Subheading} from '..';
import {PageViewHeader} from 'components-lib';
import {useIntegration} from 'admin/integrations';

export const Header = () => {
  const {redirectToViewListOfIntegrations} = useIntegration();

  const backButtonClickHandler = useCallback(() => redirectToViewListOfIntegrations(), [
    redirectToViewListOfIntegrations,
  ]);

  return (
    <PageViewHeader
      fullFlexWidth
      withColumnPadding={false}
      heading="Integrations"
      headingColor="textPrimary"
      subHeading={<Subheading />}
      withBackButton
      shouldDisableBackButton={false}
      backButtonClickHandler={backButtonClickHandler}
    />
  );
};

import React, {Dispatch, MutableRefObject, SetStateAction, useRef, useState} from 'react';
import {FormikProps, FormikValues} from 'formik';

export interface IContextProps {
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  formikFormRef: MutableRefObject<FormikProps<FormikValues>>;
  refresher?: number;
  setRefresher?: Dispatch<SetStateAction<number>>;
  forceUpdate: () => void;
  submitFormikForm: () => void;
  resetIsDirty: () => void;
}

/**
 * Used to connect up buttons ouside of a Formik form elsewhere in the app
 */
export const FormContext = React.createContext<Partial<IContextProps>>({});

// https://github.com/deeppatel234/react-context-devtool
FormContext.displayName = 'FormContext';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProviderProps {}

export const FormContextProvider: React.FC<IProviderProps> = ({children}) => {
  // Note, can't add specific TS form values to useRef here because the form will change from page to page.
  const formikFormRef = useRef<FormikProps<FormikValues>>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [refresher, setRefresher] = useState<number>(0);

  const store: IContextProps = {
    isDirty,
    setIsDirty,
    formikFormRef,
    // workaround to allow components to observe the ref changes like formikFormRef.current.dirty
    forceUpdate: () => setRefresher(refresher + 1),
    submitFormikForm: () => formikFormRef?.current?.submitForm() && setIsDirty(false),
    resetIsDirty: () => setIsDirty(false),
  };

  return <FormContext.Provider value={store}>{children}</FormContext.Provider>;
};

import React, {FC, Fragment, useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Wrapper, Loading} from 'components-lib';
import {importItemsMessages, Subtitle} from 'admin';
import {useItemsImport} from 'admin/items-import/hooks';
import {PreviewEditor} from 'components-lib';
import {useBeforeunload} from 'react-beforeunload';
import {Prompt} from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IItemsImportInstructionsProps {}

export const ItemsImportInstructions: FC<IItemsImportInstructionsProps> = () => {
  const classes = useStyles();
  const {loading, uploading, templateInstructionalText, fetchImportInstructionalText} = useItemsImport();

  /**
   * Prevent closing or refreshing the browser tab in case the user is uploading a file
   */

  useBeforeunload(() => {
    if (uploading) {
      return importItemsMessages.warning;
    }
  });

  useEffect(() => {
    if (!templateInstructionalText) {
      fetchImportInstructionalText();
    }
  }, [templateInstructionalText, fetchImportInstructionalText]);

  const contentInstructionText = useMemo(() => {
    if (!templateInstructionalText) {
      return <Wrapper className={classes.content}>No Data</Wrapper>;
    }

    return (
      <Wrapper className={classes.content}>
        <PreviewEditor htmlStr={templateInstructionalText} />
      </Wrapper>
    );
  }, [classes.content, templateInstructionalText]);

  return (
    <Fragment>
      <Prompt when={uploading} message={importItemsMessages.warning} />
      <Fragment>
        {loading ? (
          <Loading />
        ) : (
          <Wrapper>
            <Subtitle withColor={false} as="h3" color="textPrimary" title="Instructions" />
            <Fragment>{contentInstructionText}</Fragment>
          </Wrapper>
        )}
      </Fragment>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.text.primary,
  },
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.primary,
  },
}));

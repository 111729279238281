import React, {useCallback} from 'react';
import {Subheading} from '../Subheading/Subheading';
import {PageViewHeader} from 'components-lib';
import {useGoogleSites} from '../../../hooks';

export const Header = () => {
  const {redirectToViewListOfGoogleSites} = useGoogleSites();

  const backButtonClickHandler = useCallback(() => redirectToViewListOfGoogleSites(), [
    redirectToViewListOfGoogleSites,
  ]);

  return (
    <PageViewHeader
      fullFlexWidth
      withColumnPadding={false}
      heading="Google Sites"
      headingColor="textPrimary"
      subHeading={<Subheading />}
      withBackButton
      shouldDisableBackButton={false}
      backButtonClickHandler={backButtonClickHandler}
    />
  );
};

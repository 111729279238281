import React, {FC, Fragment} from 'react';
import {ILearningPathInstrumentItem, ILearningPathSessionItem} from 'models/resources';
import {NoResources} from 'admin/resources/learning-paths/components';
import {SessionInstrumentListItem} from '..';
interface ISessionInstrumentListEditProps {
  item: ILearningPathSessionItem;
  isEditMode: boolean;
}

export const SessionInstrumentListEdit: FC<ISessionInstrumentListEditProps> = ({item, isEditMode}) => {
  return (
    <Fragment>
      {item?.instruments?.length ? (
        item?.instruments.map((instrumentItem: ILearningPathInstrumentItem, idx: number) => {
          return (
            <SessionInstrumentListItem
              key={idx}
              itemId={item?.id}
              instrumentItem={instrumentItem}
              canDelete={isEditMode ? true : false}
              isEditMode={isEditMode}
            />
          );
        })
      ) : (
        <NoResources text="No Instruments" />
      )}
    </Fragment>
  );
};

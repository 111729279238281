import * as Yup from 'yup';
import {IntegrationsFilterNamesEnum} from 'admin';

export interface IIntegrationsAllFiltersFormValues {
  [IntegrationsFilterNamesEnum.INTEGRATION_TYPE]: number;
  [IntegrationsFilterNamesEnum.INTEGRATION_STATUSES]: number[];
  [IntegrationsFilterNamesEnum.AREAS]: number[];
  [IntegrationsFilterNamesEnum.SCHOOL_NAMES]: number[];
  [IntegrationsFilterNamesEnum.SCHOOL_DISTRICTS]: number[];
  [IntegrationsFilterNamesEnum.SEARCH_BY_NAME]: string;
  [IntegrationsFilterNamesEnum.SCHOOL_NAME_QUERY]?: string;
}

export const integrationsAllValidationSchema = Yup.object().shape({});

import {RolesEnum} from 'permissions';

export const AdminRolesInstruments = [RolesEnum.SuperAdmin, RolesEnum.USAAdmin];
export const AdminRolesWithLocalAreaInstruments = [RolesEnum.SuperAdmin, RolesEnum.USAAdmin, RolesEnum.AreaAdmin];
export const AllRolesExceptStudent = [
  RolesEnum.SuperAdmin,
  RolesEnum.USAAdmin,
  RolesEnum.AreaAdmin,
  RolesEnum.Educator,
  RolesEnum.Staff,
  RolesEnum.Volunteer,
];

import React, {Fragment, useEffect} from 'react';
import {makeStyles} from '@material-ui/core';
import {Subtitle} from 'admin';
import {Column, Container, Link, Row} from 'components-lib';
import {AddSessionButton} from '../../views/create/components';
import {SessionList} from './components';
import {useLearningPathBuilderItemsEntities, useSessionBuilderStoreActions} from '../../views/session-builder';
import {DragAndDropProvider} from 'dragAndDrop';

export const LearningPathSessions = () => {
  const classes = useStyles();
  const {clearBuilder, collapseAllSessionBuilderItems} = useSessionBuilderStoreActions();
  const {hasSessions} = useLearningPathBuilderItemsEntities();

  useEffect(() => {
    return () => {
      clearBuilder();
    };
  }, [clearBuilder]);

  return (
    <Fragment>
      <Row justify="space-between">
        <Subtitle withColor={false} as="h3" color="textPrimary" title="Sessions" />
        {hasSessions && (
          <Link disabled={!hasSessions} clickHandler={collapseAllSessionBuilderItems}>
            Expand all sessions
          </Link>
        )}
      </Row>
      <Container disableGutters classes={{root: classes.sessionsContent}}>
        <Row>
          <Column sm={12} md={12} lg={12} xl={12}>
            <DragAndDropProvider>
              <Container disableGutters classes={{root: classes.sessionList}}>
                <SessionList />
              </Container>
            </DragAndDropProvider>
          </Column>
        </Row>
        <AddSessionButton />
      </Container>
    </Fragment>
  );
};

export const useStyles = makeStyles((theme) => ({
  sessionsContent: {
    paddingLeft: theme.spacing(2),
  },
  sessionList: {
    paddingBottom: theme.spacing(3),
  },
}));

import {createSlice} from '@reduxjs/toolkit';
import {programClassInstrumentsInitialState} from './programClassInstruments.initialState';
import {programClassInstrumentsReducer} from './programClassInstruments.reducer';
import {programClassInstrumentsExtraReducer} from './programClassInstrumentsExtra.reducer';

export const programClassInstrumentsSlice = createSlice({
  name: 'programClassInstruments',
  initialState: programClassInstrumentsInitialState,
  reducers: programClassInstrumentsReducer,
  extraReducers: programClassInstrumentsExtraReducer,
});

export const {actions: programClassInstrumentsActions} = programClassInstrumentsSlice;

import React, {FC} from 'react';
import {IPaperQuestion} from '../../models/IPaperQuestion';
import PaperSurveBaseItem from '../PaperSurveyBaseItem/PaperSurveyBaseItem';

interface IInstructionalTextForQuestionProps {
  html: string;
  afterMount: (questionInfo: IPaperQuestion) => void;
}

export const InstructionalTextForQuestion: FC<IInstructionalTextForQuestionProps> = ({html, afterMount}) => {
  return <PaperSurveBaseItem withId={false} text={''} htmlStr={html} id={''} choices={[]} afterMount={afterMount} />;
};

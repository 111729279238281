import {makeStyles} from '@material-ui/core';
import {Answers, AnswersMatrix} from 'admin/library/components';
import {CardLinkInfoTooltip} from 'admin/library/instruments/components';
import {IBuilderDragItem} from 'admin/library/models';
import {Button, Icon} from 'components-lib';
import {DragItem} from 'dragAndDrop';
import {QuestionAnswersTypeEnum} from 'enums';
import React, {useRef} from 'react';

import {useBuilderItemDrop} from '../../hooks';
import {CardQuestionSimple} from '../CardQuestionSimple/CardQuestionSimple';

export const answersComponentMap = {
  0: Answers,
  '': Answers,
  [QuestionAnswersTypeEnum.checkbox]: Answers,
  [QuestionAnswersTypeEnum.radioGroup]: Answers,
  [QuestionAnswersTypeEnum.questionAnswerEnum]: AnswersMatrix,
  [QuestionAnswersTypeEnum.matrix]: AnswersMatrix,
  [QuestionAnswersTypeEnum.matrixEnum]: AnswersMatrix,
};

interface IMatrixQuestionItemProps {
  matrixItem: IBuilderDragItem;
  position: number;
  linkedQuestionAnswerStackId?: number;
  moveHandler: (dragIdx: number, hoverIdx: number) => void;
  deleteHandler: (rowId: number) => void;
  linkClickHandler?: (matrixItem: IBuilderDragItem) => void;
  openInfoLinkClickHandler?: () => void;
  withLink?: boolean;
}

export function MatrixQuestionItem({
  matrixItem,
  position,
  moveHandler,
  deleteHandler,
  linkClickHandler,
  openInfoLinkClickHandler,
  withLink,
}: IMatrixQuestionItemProps) {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  useBuilderItemDrop(matrixItem, ref, position, moveHandler);

  return (
    <DragItem drag={ref}>
      <div className={classes.root}>
        <CardQuestionSimple
          type={matrixItem.questionType}
          text={matrixItem.text}
          withBorder
          withExtraPadding
          actions={
            <>
              {matrixItem.linkedQuestionAnswerStackId && (
                <CardLinkInfoTooltip clickHandler={openInfoLinkClickHandler} />
              )}
              {withLink && (
                <Button.Icon clickHandler={() => linkClickHandler(matrixItem)}>
                  <Icon.LinkIcon />
                </Button.Icon>
              )}
              <Icon.DragIndicatorIcon />
              <Button.Icon clickHandler={() => deleteHandler(position)}>
                <Icon.DeleteIcon />
              </Button.Icon>
            </>
          }
        />
      </div>
    </DragItem>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    cursor: 'move',
  },
}));

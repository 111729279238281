import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';

const getProgramsState = (state: TStateStore) => state.programs.masterInstruments;

export const pageSelector = createSelector(getProgramsState, (masterInstruments) => masterInstruments.page);
export const pageLoadingSelector = createSelector(
  getProgramsState,
  (masterInstruments) => masterInstruments.page.loading
);

export const poolSelector = createSelector(getProgramsState, (masterInstruments) => masterInstruments.pool);

export const filterSelector = createSelector(getProgramsState, (masterInstruments) => masterInstruments.filter);
export const paginationSelector = createSelector(getProgramsState, (masterInstruments) => masterInstruments.pagination);
export const orderBySelector = createSelector(getProgramsState, (masterInstruments) => masterInstruments.orderBy);
export const programsMasterInstrumentsSortOrderSelector = createSelector(
  getProgramsState,
  (programs) => programs.sortOrder
);

import React, {FC, Fragment} from 'react';
import {Column, Row, Table} from 'components-lib';
import {GoogleSitesAllHeaderSearch, GoogleSitesSortBy} from '..';
import {IColumn} from 'models/library/common';

interface IGoogleSitesTableProps {
  columns: IColumn[];
  rows: any[];
}

export const GoogleSitesTable: FC<IGoogleSitesTableProps> = ({rows, columns}) => {
  return (
    <Fragment>
      <Row justify="space-between">
        <Column sm={4} md={4} lg={3} xl={2}>
          <GoogleSitesAllHeaderSearch />
        </Column>
        <Column sm={4} md={4} lg={3} xl={2}>
          <Row justify="flex-end">
            <Column sm={12} md={12} lg={12} xl={12}>
              <GoogleSitesSortBy />
            </Column>
          </Row>
        </Column>
      </Row>
      <Table rows={rows} columns={columns} hasRows={!!rows.length} />
    </Fragment>
  );
};

import React, {Fragment, useMemo} from 'react';
import {SidebarTitle} from 'admin/components';
import {Sidebar} from 'components-lib';
import {SelectionEnum, SelectionListItems} from 'filters-selections';
import {useItem, useItemIdParam, usePageLoading} from 'admin/library/items/hooks';

export function SidebarItem() {
  const {loadingPageSection} = usePageLoading();
  const {itemId} = useItemIdParam();
  const {pageLoading} = useItem();

  const selectionListItems = useMemo(() => {
    const selectionList = [
      SelectionEnum.PillarMultiSelect,
      SelectionEnum.EvaluationType,
      SelectionEnum.Domain,
      SelectionEnum.ProgramSelectWithDomainDependency,
      SelectionEnum.CompetencyByDomain,
      SelectionEnum.Concepts,
      SelectionEnum.AdministrationType,
      SelectionEnum.SchoolLevel,
      SelectionEnum.LearningObjectiveWithDomainDependency,
      SelectionEnum.Category,
    ];

    return <SelectionListItems list={selectionList} disableAll={!itemId || loadingPageSection} />;
  }, [loadingPageSection, itemId]);

  return (
    <Sidebar.Base withHeader={false}>
      <SidebarTitle text="Tags" />
      <Fragment>{!pageLoading && selectionListItems}</Fragment>
    </Sidebar.Base>
  );
}

import React, {Fragment, FC, useEffect, useMemo} from 'react';
import {Wrapper} from 'components-lib';
import {LevelReportingMenuItem, NoDataMenuItem} from '..';
import {useFormikContext} from 'formik';
import {ILevelReportingFormBaseValues} from '../LevelReportingMenuForm/config';
import {FormNameEnum} from 'enums/form';

interface ILevelReportingMenuItemListProps {
  closeMenuClickHandler: () => void;
}

export const LevelReportingMenuItemList: FC<ILevelReportingMenuItemListProps> = ({closeMenuClickHandler}) => {
  const {
    values: {filteredMenuItems, menuItems, searchTerm, selectedId},
    setFieldValue,
  } = useFormikContext<ILevelReportingFormBaseValues>();
  const clonedList = useMemo(() => [...menuItems], [menuItems]);
  const hasItems = filteredMenuItems?.length;

  useEffect(() => {
    if (searchTerm.trim()) {
      const filteredList = clonedList.filter((item) =>
        item.displayText.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFieldValue(FormNameEnum.filteredMenuItems, filteredList);
    } else {
      if (filteredMenuItems?.length !== menuItems?.length) {
        setFieldValue(FormNameEnum.filteredMenuItems, menuItems);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, menuItems, clonedList]);

  return (
    <Fragment>
      {hasItems ? (
        <Wrapper>
          {filteredMenuItems?.map((option, idx: number) => {
            const selected = option.id === selectedId;

            return (
              <LevelReportingMenuItem
                key={idx}
                selected={selected}
                closeMenuClickHandler={closeMenuClickHandler}
                item={option}
              />
            );
          })}
        </Wrapper>
      ) : (
        <NoDataMenuItem />
      )}
    </Fragment>
  );
};

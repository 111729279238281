import * as Yup from 'yup';
import {FormNameEnum} from 'filters-selections';

export interface IEditInstructionalTextFormBaseValues {
  [FormNameEnum.builderInstructionalText]: string;
  [FormNameEnum.builderInstructionalTextHTML]: string;
}

export const editTagsFormValidationSchema = Yup.object().shape({
  [FormNameEnum.builderInstructionalText]: Yup.string(),
  [FormNameEnum.builderInstructionalTextHTML]: Yup.string(),
});

import React, {useMemo} from 'react';
import {SelectionEnum} from 'filters-selections';
import {UploadTypeEnum} from 'admin/resources/upload/enums';
import {getUploadFirstColList, ResourceListColumn} from 'admin';
import {Container, Row} from 'components-lib';
import {useFileEditPermissions} from '../../hooks';
import {useFormikContext} from 'formik';
import {IEditFileMetadataFormBaseValues} from '../FilesEditResourceForm/config';
import {getEditFileFirstColumnExtendedList} from '../../utils';

export const FilesEditScorm = () => {
  const {hasFileEditPermissions} = useFileEditPermissions();
  const {values} = useFormikContext<IEditFileMetadataFormBaseValues>();

  const originalFirstColList = useMemo(
    () => [
      SelectionEnum.DisplayNameInput,
      ...getUploadFirstColList(UploadTypeEnum.Scorm, true),
      SelectionEnum.KeyTermsInput,
      SelectionEnum.TimeInput,
      SelectionEnum.AccessibleToStudentsRadioGroup,
      SelectionEnum.VersionFileUpload,
    ],
    []
  );

  const firstCollist = getEditFileFirstColumnExtendedList(originalFirstColList, values);

  const secondColList = useMemo(
    () => [
      SelectionEnum.BriefDescriptionTextArea,
      SelectionEnum.ProgramMultiSelection,
      SelectionEnum.KnowledgeSkillsAndAbilities,
      SelectionEnum.ExpirationDatePicker,
      SelectionEnum.LearningObjectiveInput,
    ],
    []
  );

  return (
    <Container disableGutters>
      <Row justify="flex-start" align="flex-start">
        <ResourceListColumn list={firstCollist} disableAll={!hasFileEditPermissions} />
        <ResourceListColumn list={secondColList} disableAll={!hasFileEditPermissions} />
      </Row>
    </Container>
  );
};

import React, {ChangeEvent, FC, ReactNode} from 'react';
import {makeStyles} from '@material-ui/core';
import {Button, FileInput, Icon, Wrapper, Text, Row} from 'components-lib';
import {IInputFile, IWithDisabled} from 'models';
import {borderGeneral} from 'styles';
interface IHorizontalFileUploaderProps extends IWithDisabled {
  attachmentArea: ReactNode;
  fileInputName: string;
  accept: string;
  file: IInputFile;
  fileInputRef: any;
  error: string;
  text?: string;
  title?: string;
  withIcon?: boolean;
  isActive?: boolean;
  browseButtonText?: string;
  withOnlyBrowseButton?: boolean;
  buttonSize?: 'small' | 'large';
  align?: 'flex-start' | 'center' | 'flex-end';
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  addFileCallback?: (event: ChangeEvent<HTMLInputElement>) => void;
  openFileDialogClickHandler?: () => void;
}

export const Horizontal: FC<IHorizontalFileUploaderProps> = ({
  attachmentArea,
  fileInputName,
  accept,
  file,
  fileInputRef,
  error,
  text = 'Drag and drop a file here or',
  title = 'Upload File',
  browseButtonText = 'Browse',
  buttonSize = 'large',
  withIcon = true,
  isActive = false,
  disabled,
  addFileCallback,
  align = 'center',
  justify = 'space-between',
  withOnlyBrowseButton,
  openFileDialogClickHandler,
}) => {
  const classes = useStyles({isActive, withOnlyBrowseButton});

  return (
    <Row justify="flex-start">
      {title && (
        <Text.Heading as="h4" color="textPrimary" classes={{root: classes.heading}}>
          {title}
        </Text.Heading>
      )}
      {file && <Row>{attachmentArea}</Row>}
      {!file && (
        <Row justify={justify} align={align} classes={{root: classes.droppableArea}}>
          {withIcon && <Icon.FolderIcon disabled={disabled} />}
          {text && <Text.Caption classes={{root: classes.caption}}>{text}</Text.Caption>}
          <Wrapper>
            <FileInput name={fileInputName} inputRef={fileInputRef} accept={accept} clickHandler={addFileCallback} />
            <Button.Secondary disabled={disabled} clickHandler={openFileDialogClickHandler} size={buttonSize} isFirst>
              {browseButtonText}
            </Button.Secondary>
          </Wrapper>
        </Row>
      )}
      {error && <Text.Error>{error}</Text.Error>}
    </Row>
  );
};

const useStyles = makeStyles((theme) => ({
  droppableArea: ({isActive, withOnlyBrowseButton}: {isActive: boolean; withOnlyBrowseButton: boolean}) => {
    let backgroundStyle = theme.palette.grey[50];

    if (withOnlyBrowseButton) {
      backgroundStyle = theme.palette.primary.contrastText;
    }

    if (isActive) {
      backgroundStyle = theme.palette.secondary.light;
    }

    return {
      width: '100%',
      padding: !withOnlyBrowseButton && '11px 11px 11px 16px',
      background: backgroundStyle,
      borderRadius: !withOnlyBrowseButton && borderGeneral.borderRadius,
      borderWidth: !withOnlyBrowseButton && borderGeneral.borderWidth,
      borderStyle: withOnlyBrowseButton ? 'none' : 'dashed',
      borderColor: !withOnlyBrowseButton && borderGeneral.borderColor,
    };
  },
  text: {
    padding: theme.spacing(1),
  },
  heading: {
    width: '100%',
  },
  caption: {
    fontWeight: 400,
  },
}));

import {TextArea} from 'components-lib';
import {FieldProps} from 'formik';
import {IWithDisabled} from 'models';
import React from 'react';
import {Field} from './Field';
import {useDebounceUserInput} from 'hooks';

interface IFormTextAreaProps extends IWithDisabled {
  name: string;
  value?: string;
  debounceDelay?: number;
  placeholder?: string;
  rows?: number;
}

export function FormTextArea({name, value, disabled, debounceDelay, placeholder, rows}: IFormTextAreaProps) {
  return (
    <Field
      name={name}
      value={value}
      disabled={disabled}
      debounceDelay={debounceDelay}
      placeholder={placeholder}
      Component={TextAreaDecorated}
      rows={rows}
    />
  );
}

interface ITextAreaDecoratedProps extends FieldProps, IFormTextAreaProps {}

function TextAreaDecorated({field: {name, onChange}, form: {values}, debounceDelay, ...rest}: ITextAreaDecoratedProps) {
  const initialValue: string = values[name];
  const [value, changeHandler] = useDebounceUserInput(initialValue, onChange, debounceDelay);

  const props = {
    ...rest,
    value,
    changeHandler,
  };

  return <TextArea withPadding {...props} />;
}

import {SvgIcon} from '@material-ui/core';
import React from 'react';
import {ReactComponent as IconVirtualClass} from 'resources/icons/Icon-VirtualClass.svg';

export function VirtualClassIcon() {
  return (
    <SvgIcon>
      <IconVirtualClass />
    </SvgIcon>
  );
}

import React, {FC} from 'react';
import {FormNameEnum} from 'filters-selections';
import {TextInputSelection} from '../TextInputSelection/TextInputSelection';
import {IWithDisabled} from 'models/common';
interface ILearningObjectiveTextInputProps extends IWithDisabled {
  infoText?: string;
}

export const LearningObjectiveTextInput: FC<ILearningObjectiveTextInputProps> = ({disabled, infoText}) => (
  <TextInputSelection
    name={FormNameEnum.learningObjectives}
    label="Learning Objectives"
    disabled={disabled}
    infoText={infoText}
  />
);

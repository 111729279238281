import {InstrumentsFilterNamesEnum} from 'admin/library/instruments/enums';
import * as Yup from 'yup';

export interface ISidebarBuilderFilterForm {
  [InstrumentsFilterNamesEnum.PILLARS]: string[];
  [InstrumentsFilterNamesEnum.EVALUATION_TYPE]: number[];
  [InstrumentsFilterNamesEnum.DOMAIN]: number[];
  [InstrumentsFilterNamesEnum.PROGRAMS]: number[];
  [InstrumentsFilterNamesEnum.LEARNING_OBJECTIVE]: string;
  [InstrumentsFilterNamesEnum.CATEGORY]: string;
  [InstrumentsFilterNamesEnum.COMPETENCY]: number[];
  [InstrumentsFilterNamesEnum.CONCEPT]: number[];
  [InstrumentsFilterNamesEnum.ADMINISTRATION]: number[];
  [InstrumentsFilterNamesEnum.SCHOOL_LEVEL]: number[];
  [InstrumentsFilterNamesEnum.AREAS]: number[];
  [InstrumentsFilterNamesEnum.SEARCH]: string; // Change with TEXT instead SEARCH
}

export const formValidationSchema: any = Yup.object().shape({
  [InstrumentsFilterNamesEnum.SEARCH]: Yup.string(),
  [InstrumentsFilterNamesEnum.EVALUATION_TYPE]: Yup.array().of(Yup.number()),
  [InstrumentsFilterNamesEnum.PILLARS]: Yup.array().of(Yup.string()),
  [InstrumentsFilterNamesEnum.DOMAIN]: Yup.array().of(Yup.number()),
  [InstrumentsFilterNamesEnum.PROGRAMS]: Yup.array().of(Yup.number()),
  [InstrumentsFilterNamesEnum.CATEGORY]: Yup.string().ensure(),
  [InstrumentsFilterNamesEnum.COMPETENCY]: Yup.array().of(Yup.number()),
  [InstrumentsFilterNamesEnum.CONCEPT]: Yup.array().of(Yup.number()),
  [InstrumentsFilterNamesEnum.ADMINISTRATION]: Yup.array().of(Yup.number()),
  [InstrumentsFilterNamesEnum.SCHOOL_LEVEL]: Yup.array().of(Yup.number()),
  [InstrumentsFilterNamesEnum.AREAS]: Yup.array().of(Yup.number()),
  [InstrumentsFilterNamesEnum.LEARNING_OBJECTIVE]: Yup.string().ensure(),
});

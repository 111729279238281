import React from 'react';
import {CircularProgress} from '@material-ui/core';

interface ILoadingSpinnerProps {
  size?: number;
  color?: 'inherit' | 'primary' | 'secondary';
}

export function LoadingSpinner({size = 40, color = 'primary'}: ILoadingSpinnerProps) {
  return <CircularProgress size={size} color={color} />;
}

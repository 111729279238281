import {createSlice} from '@reduxjs/toolkit';
import {integrationsInitialState} from './integrations.initialState';
import {integrationsReducer} from './integrations.reducer';
import {integrationsExtraReducer} from './integrationsExtra.reducer';

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: integrationsInitialState,
  reducers: integrationsReducer,
  extraReducers: integrationsExtraReducer,
});

export const {actions: integrationsActions} = integrationsSlice;

import React, {FC, Fragment} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, makeStyles} from '@material-ui/core';
import {Button, Icon, Row, Text} from 'components-lib';
import {ModalTypes, ModalWidth} from 'enums/upload/ModalTypes.enum';
import {IWithChildren} from 'models';

interface IButtonModalAction {
  title: string;
  disabled?: boolean;
  handleAction: () => void;
}

interface IButtonModalCancelAction {
  title: string;
  disabled?: boolean;
  handleCancel: () => void;
}

interface IModalButtonsContent {
  size?: 'small' | 'large';
  action: IButtonModalAction;
  cancel: IButtonModalCancelAction;
}
interface IModalProps extends IWithChildren {
  isOpen: boolean;
  title: string;
  size: string;
  customButtons?: boolean;
  withButtons?: boolean;
  disableBackdropClick?: boolean;
  buttonsContent?: IModalButtonsContent;
  handleClose: () => void;
}

const TRANSITION_DURATION = 325;

export const Modal: FC<IModalProps> = ({
  isOpen,
  handleClose,
  title,
  children,
  size,
  withButtons,
  buttonsContent,
  customButtons = false,
  disableBackdropClick = false,
}) => {
  const classes = useStyles();

  const modalContent = children[0];
  const modalActionButtons = children[1];
  const shouldRenderCustomContent = withButtons || !!customButtons;
  const shouldRenderCustomButtons = withButtons || modalActionButtons;

  function getSizeStyle(size: string) {
    switch (size) {
      case ModalTypes.small:
        return classes.small;
      case ModalTypes.medium:
        return classes.medium;
      case ModalTypes.large:
        return classes.large;
      default:
        return;
    }
  }

  const overrideSizeStyles = getSizeStyle(size);

  return (
    <Dialog
      classes={{paper: overrideSizeStyles}}
      open={isOpen}
      onClose={handleClose}
      transitionDuration={TRANSITION_DURATION}
      disableBackdropClick={disableBackdropClick}
    >
      <DialogTitle className={size} classes={{root: classes.title}}>
        <Row justify="space-between">
          <Text.Heading as="h3" classes={{root: classes.heading}}>
            {title}
          </Text.Heading>
          <Button.Icon clickHandler={handleClose}>
            <Icon.CloseIcon />
          </Button.Icon>
        </Row>
      </DialogTitle>
      <DialogContent>{shouldRenderCustomContent ? children : modalContent}</DialogContent>
      {shouldRenderCustomButtons && (
        <DialogActions className={classes.actions}>
          {withButtons ? (
            <Fragment>
              <Button.Secondary
                disabled={buttonsContent.cancel.disabled}
                size={buttonsContent.size ? buttonsContent.size : ModalTypes.large}
                clickHandler={buttonsContent.cancel.handleCancel}
              >
                {buttonsContent.cancel.title}
              </Button.Secondary>
              <Button.Primary
                disabled={buttonsContent.action.disabled}
                size={buttonsContent.size ? buttonsContent.size : ModalTypes.large}
                clickHandler={buttonsContent.action.handleAction}
              >
                {buttonsContent.action.title}
              </Button.Primary>
            </Fragment>
          ) : (
            modalActionButtons
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  small: {
    width: ModalWidth.small,
  },
  medium: {
    width: ModalWidth.medium,
  },
  large: {
    width: ModalWidth.large,
  },
  heading: {
    margin: 0,
    '&.MuiTypography-h6': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '90%',
  },
  title: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[200],
  },
  actions: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    padding: `${theme.spacing(3)}px ${theme.spacing(3) + theme.spacing(2)}px`,
  },
  closeButton: {
    alignSelf: 'flex-end',
    color: theme.palette.primary.main,
  },
}));

import {IActionFulfilled, IGetPageOfProgramMasterInstrumentsResponse, IProgramMasterInstrumentBase} from 'models';
import {normalize} from 'normalizr';
import {isObject} from 'utils';
import {IProgramMasterInstrumentsState} from './IProgramMasterInstrumentsState';
import {poolListSchema} from './normalizationSchemas';
import {fetchProgramMasterInstrumentsPagedThunk} from './thunks';

export const programMasterInstrumentsExtraReducer = {
  //fetchProgramMasterInstrumentsPagedThunk
  [fetchProgramMasterInstrumentsPagedThunk.fulfilled]: (
    state: IProgramMasterInstrumentsState,
    action: IActionFulfilled<null, IGetPageOfProgramMasterInstrumentsResponse>
  ) => {
    if (isObject(action.payload)) {
      state.pool = normalize(remapProgramMasterInstruments(action.payload.templates), poolListSchema);
      state.pagination.totalCount = action.payload.totalCount;
    }

    state.page.loading = false;
  },
  [fetchProgramMasterInstrumentsPagedThunk.pending]: (state: IProgramMasterInstrumentsState) => {
    state.page.loading = true;
  },
  [fetchProgramMasterInstrumentsPagedThunk.rejected]: (state: IProgramMasterInstrumentsState) => {
    // TODO handle errors
    state.page.loading = false;
  },
};

const remapProgramMasterInstruments = (instruments: IProgramMasterInstrumentBase[]) =>
  instruments.map(({templateId: id, templateName: name, programs, audience, type, phase, status}) => ({
    id,
    name,
    programName: programs.map((p) => p.name).join(', '),
    audience,
    type,
    phase,
    status,
  }));

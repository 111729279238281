import {FormNameEnum} from 'filters-selections';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {filtersAdministrationsEntitiesCollectionSelector, fetchAdministrationsThunk} from 'filters-selections/store';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IAdministrationSelectProps extends IWithDisabled {}

export function AdministrationSelect({disabled}: IAdministrationSelectProps) {
  const dispatch = useDispatch();
  const administrations = useSelector(filtersAdministrationsEntitiesCollectionSelector);

  useEffect(() => {
    if (!administrations.length) {
      dispatch(fetchAdministrationsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = useMemo(
    () => administrations.map((administration) => ({value: administration.id, label: administration.displayText})),
    [administrations]
  );

  return (
    <ItemWithPlaceholder label="Administration" hasItems={!!administrations.length}>
      <Form.Select name={FormNameEnum.administration} options={options} disabled={disabled} />
    </ItemWithPlaceholder>
  );
}

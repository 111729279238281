import {createAsyncThunk} from '@reduxjs/toolkit';
import {IGetAreaProgramClassExportResponse} from 'models';
import {IHttpResponse, instrumentsApi} from 'api';
import {prefix} from './config';
import {config} from 'config';
import {instrumentsMessages} from '../../utils';
import {toastNotificationManager} from 'toast-notifications';
import {returnQueryStringWithParams} from 'utils';
import {IFetchAreaProgramClassQueryParams} from '../../models';

const PROGRAM_CLASS_AGGREGATED_EXPORTS_URL = `${config.jaEeApi.baseUrlExports}/AssigmentResultsExport/GetAggregatedExport`;

export const fetchAreaProgramClassAggregatedExportThunk: any = createAsyncThunk(
  `${prefix} fetchAreaProgramClassAggregatedExport`,
  async (params: IFetchAreaProgramClassQueryParams, {rejectWithValue, dispatch}) => {
    const url = returnQueryStringWithParams(PROGRAM_CLASS_AGGREGATED_EXPORTS_URL, params);

    try {
      const response: IHttpResponse<IGetAreaProgramClassExportResponse> = await instrumentsApi.getAreaProgramClassAggregatedExport(
        url
      );

      if (response.ok) {
        dispatch(toastNotificationManager.createSuccessToastAction(instrumentsMessages.exportSuccess));
        return response.parsedBody;
      }
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.statusText || instrumentsMessages.exportError));
      return rejectWithValue(error);
    }
  }
);

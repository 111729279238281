import React, {FC} from 'react';
import {PageViewHeader} from 'components-lib';

export const ScormContentHeader: FC = () => (
  <PageViewHeader
    fullFlexWidth
    withColumnPadding={false}
    heading="Upload SCORM Package"
    headingColor="textPrimary"
    withBackButton={false}
  />
);

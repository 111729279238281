import {FiltersEnum} from 'filters-selections/enums';
import React, {FC} from 'react';
import {AdministrationFilter} from '../AdministrationFilter/AdministrationFilter';
import {AreasFilter, AreasMultiAutocompleteFilter, AreasMultiAutocompleteExternalDataFilter} from '../AreasFilter';
import {AudienceFilter} from '../AudienceFilter/AudienceFilter';
import {CategoryFilter} from '../CategoryFilter/CategoryFilter';
import {ClassIsVirtualFilter} from '../ClassIsVirtualFilter/ClassIsVirtualFilter';
import {CompetenciesFilter} from '../CompetencyFilter/CompetenciesFilter';
import {CompetencyFilter} from '../CompetencyFilter/CompetencyFilter';
import {ConceptsFilter} from '../ConceptFilter/ConceptFilter';
import {DigitalResourcesConceptsFilter} from '../ConceptFilter/DigitalResourcesConceptsFilter';
import {DigitalResourceTypesFilter} from '../DigitalResourceTypesFilter/DigitalResourceTypesFilter';
import {DistrictsFilter} from '../DistrictsFilter/DistrictsFilter';
import {DomainFilter} from '../DomainFilter/DomainFilter';
import {DrmExpirationDateFilter} from '../DrmExpirationDateFilter/DrmExpirationDateFilter';
import {EducatorFilter} from '../EducatorFilter/EducatorFilter';
import {EvaluationTypeFilter} from '../EvaluationTypeFilter/EvaluationTypeFilter';
import {FromToDateFilter} from '../FromToDateFilter/FromToDateFilter';
import {GradeLevelFilter} from '../GradeLevelFilter/GradeLevelFilter';
import {InstrumentStatusFilter} from '../InstrumentStatusFilter/InstrumentStatusFilter';
import {InstrumentTypeFilter} from '../InstrumentTypeFilter/InstrumentTypeFilter';
import {InstrumentViewTypeFilter} from '../InstrumentViewTypeFilter/InstrumentViewTypeFilter';
import {IntegrationStatusMultiFilter} from '../IntegrationStatusFilter/IntegrationStatusMultiFilter';
import {IntegrationTypeMultiFilter} from '../IntegrationTypeFilter/IntegrationTypeFilterMulti';
import {IntegrationTypeSingleFilter} from '../IntegrationTypeFilter/IntegrationTypeSingle';
import {ItemStatusFilter} from '../ItemStatusFilter/ItemStatusFilter';
import {ItemTypeFilter} from '../ItemTypeFilter/ItemTypeFilter';
import {KeyTermsFilter} from '../KeyTermsFilter/KeyTermsFilter';
import {LanguagesFilter} from '../LanguagesFilter/LanguagesFilter';
import {LearningObjectiveFilter} from '../LearningObjectiveFilter/LearningObjectiveFilter';
import {LearningObjectiveWithDomainDependencyFilter} from '../LearningObjectiveFilter/LearningObjectiveWithDomainDependencyFilter';
import {LearningObjectivesFilter} from '../LearningObjectivesFilter/LearningObjectivesFilter';
import {MetadataFilter} from '../MetadataFilter/MetadataFilter';
import {PhaseFilter} from '../PhaseFilter/PhaseFilter';
import {PillarFilter} from '../PillarFilter/PillarFilter';
import {PillarMultiSelectFilter} from '../PillarFilter/PillarMultiSelectFilter';
import {ProgramFilterMulti} from '../ProgramFilter/ProgramFilterMulti';
import {ProgramFilterSingle} from '../ProgramFilter/ProgramFilterSingle';
import {ProgramYearCheckboxFilter, ProgramYearMultiFilter} from '../ProgramYearFilter';
import {SchoolLevelFilter} from '../SchoolLevelFilter/SchoolLevelFilter';
import {SessionsFilter} from '../SessionsFilter/SessionsFilter';
import {SkillsAndBehaviorsFilter} from '../SkillsAndBehaviorsFilter/SkillsAndBehaviorsFilter';
import {TagGradeLevelsFilter} from '../TagGradeLevelsFilter/TagGradeLevelsFilter';
import {FileTypeFilter, FileTypeFilterMulti} from '../FileTypeFilter';
import {SchoolNameFilterBasedOnAreaAndDistrict} from '../SchoolNameFilter/SchoolNameFilterBasedOnAreaAndDistrict';
import {SchoolDistrictFilterMulti} from '../SchoolDistrictFilter/SchoolDistrictFilterMulti';
import {LearningPathStatusesCheckbox} from '../LearningPathStatusesFilter/LearningPathStatusesCheckbox';
import {KnowledgeSkillsAndAbilitiesFilter} from '../KnowledgeSkillsAndAbilitiesFilter/KnowledgeSkillsAndAbilitiesFilter';
import {AccessibleToStudentsRadioFilter} from '../AccessibleToStudentsRadioFilter/AccessibleToStudentsRadioFilter';
import {PublishedByRadioFilter} from '../PublishedByFilter/PublishedByRadioFilter';
import {FileStatussesCheckboxFilter} from '../FileStatussesFilter/FileStatussesCheckboxFilter';

const filterComponentMap = {
  [FiltersEnum.Audience]: AudienceFilter,
  [FiltersEnum.GradeLevel]: GradeLevelFilter,
  [FiltersEnum.Metadata]: MetadataFilter,
  [FiltersEnum.Phase]: PhaseFilter,
  [FiltersEnum.Pillar]: PillarFilter,
  [FiltersEnum.PillarMulti]: PillarMultiSelectFilter,
  [FiltersEnum.ProgramSingle]: ProgramFilterSingle,
  [FiltersEnum.ProgramMulti]: ProgramFilterMulti,
  [FiltersEnum.ProgramYearCheckbox]: ProgramYearCheckboxFilter,
  [FiltersEnum.ProgramYearMulti]: ProgramYearMultiFilter,
  [FiltersEnum.Educators]: EducatorFilter,
  [FiltersEnum.Areas]: AreasFilter,
  [FiltersEnum.Districts]: DistrictsFilter,
  [FiltersEnum.FromToDate]: FromToDateFilter,
  [FiltersEnum.InstrumentType]: InstrumentTypeFilter,
  [FiltersEnum.InstrumentStatus]: InstrumentStatusFilter,
  [FiltersEnum.Domain]: DomainFilter,
  [FiltersEnum.EvaluationType]: EvaluationTypeFilter,
  [FiltersEnum.Competency]: CompetencyFilter,
  [FiltersEnum.Competencies]: CompetenciesFilter,
  [FiltersEnum.Concept]: ConceptsFilter,
  [FiltersEnum.Category]: CategoryFilter,
  [FiltersEnum.Administration]: AdministrationFilter,
  [FiltersEnum.SchoolLevel]: SchoolLevelFilter,
  [FiltersEnum.ItemType]: ItemTypeFilter,
  [FiltersEnum.ItemStatus]: ItemStatusFilter,
  [FiltersEnum.InstrumentViewType]: InstrumentViewTypeFilter,
  [FiltersEnum.Session]: SessionsFilter,
  [FiltersEnum.DigitalResourcesConcepts]: DigitalResourcesConceptsFilter,
  [FiltersEnum.KeyTerms]: KeyTermsFilter,
  [FiltersEnum.LearningObjectives]: LearningObjectivesFilter,
  [FiltersEnum.SkillsAndBehaviors]: SkillsAndBehaviorsFilter,
  [FiltersEnum.Languages]: LanguagesFilter,
  [FiltersEnum.TagGradeLevels]: TagGradeLevelsFilter,
  [FiltersEnum.DigitalResourceTypes]: DigitalResourceTypesFilter,
  [FiltersEnum.DrmExpirationDate]: DrmExpirationDateFilter,
  [FiltersEnum.LearningObjective]: LearningObjectiveFilter,
  [FiltersEnum.LearningObjectiveWithDomainDependency]: LearningObjectiveWithDomainDependencyFilter,
  [FiltersEnum.IntegrationTypeSingle]: IntegrationTypeSingleFilter,
  [FiltersEnum.IntegrationTypeMulti]: IntegrationTypeMultiFilter,
  [FiltersEnum.IntegrationStatusMulti]: IntegrationStatusMultiFilter,
  [FiltersEnum.SchoolNameBasedOnAreaAndDistrict]: SchoolNameFilterBasedOnAreaAndDistrict,
  [FiltersEnum.AreasAutocompleteMulti]: AreasMultiAutocompleteFilter,
  [FiltersEnum.AreasMultiAutocompleteExternalData]: AreasMultiAutocompleteExternalDataFilter,
  [FiltersEnum.ClassesOnlyVirtual]: ClassIsVirtualFilter,
  [FiltersEnum.FileType]: FileTypeFilter,
  [FiltersEnum.FileTypeMulti]: FileTypeFilterMulti,
  [FiltersEnum.SchoolDistrictMulti]: SchoolDistrictFilterMulti,
  [FiltersEnum.LearningPathStatusesCheckbox]: LearningPathStatusesCheckbox,
  [FiltersEnum.KnowledgeSkillsAndAbilities]: KnowledgeSkillsAndAbilitiesFilter,
  [FiltersEnum.AccessibleToStudentsRadio]: AccessibleToStudentsRadioFilter,
  [FiltersEnum.PublishedByRadio]: PublishedByRadioFilter,
  [FiltersEnum.FileStatusesCheckbox]: FileStatussesCheckboxFilter,
};

interface IFiltersListProps {
  filters: FiltersEnum[];
  disableAll?: boolean;
}

export function FiltersList({filters, disableAll = false}: IFiltersListProps) {
  return (
    <>
      {filters.map((filter, idx) => {
        const FilterComponent: FC<any> = filterComponentMap[filter];
        return FilterComponent ? <FilterComponent key={idx} disabled={disableAll} /> : null;
      })}
    </>
  );
}

import {getIsBeforeDate} from 'utils/common';

export const getIsDateEarlierFromToday = (expirationDate: Date) => {
  // If the datepicker is cleaned up, then validation is true
  if (expirationDate === null) {
    return true;
  }

  function isBefore(expirationDate: Date): boolean {
    const dateIsBeforeToday = getIsBeforeDate(expirationDate);

    if (dateIsBeforeToday) {
      return false;
    }
    return true;
  }

  if (expirationDate instanceof Date) {
    return isBefore(expirationDate);
  }
};

import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import {makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import {IComponentWithChildren} from 'models';

interface INavigationProps extends IComponentWithChildren {
  type?: 'primary' | 'secondary';
}

export function Navigation({children, type = 'primary'}: INavigationProps) {
  const classes = useStyles({type});

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar className={classes.toolbar}>{children}</Toolbar>
    </AppBar>
  );
}
const useStyles = makeStyles((theme) => ({
  root: ({type}: any) => ({
    minHeight: type === 'primary' ? 64 : 45,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  }),
  toolbar: ({type}: any) => {
    const isPrimary = type === 'primary';
    return {
      minHeight: isPrimary ? 64 : 45,
      backgroundColor: isPrimary ? theme.palette.common.white : theme.palette.grey[50],
    };
  },
}));

import React from 'react';
import {Grid} from '@material-ui/core';
import {IComponentWithChildren, IComponentWithClassName} from 'models';

export type TWidth = boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

/*

The grid is based on a 12 column system meaning the screen at any
given point has 12 columns of available space. A grid item with sm=6 
will take up half the screen (6 of 12 columns) at screen size small
and larger (width = 600px+).

*/

interface IColumnProps extends IComponentWithChildren, IComponentWithClassName {
  sm?: TWidth; // 600 px - 960 px
  md?: TWidth; // 960 px - 1280 px
  lg?: TWidth; // 1280 px - 1920 px
  xl?: TWidth; // 1536 px +
}

export function Column({children, sm = 12, md = 12, lg = 12, xl = 12, className}: IColumnProps) {
  return (
    <Grid container direction="column" sm={sm} md={md} lg={lg} xl={xl} item className={className}>
      {children}
    </Grid>
  );
}

import {ResourceTypeNumEnum, ResourceTypeEnum} from 'admin/resources/enums';

export const mapFileTypeNumber = (type: string) => {
  switch (type) {
    case ResourceTypeEnum.Video:
      return ResourceTypeNumEnum.Video;
    case ResourceTypeEnum.Audio:
      return ResourceTypeNumEnum.Audio;
    case ResourceTypeEnum.Image:
      return ResourceTypeNumEnum.Image;
    case ResourceTypeEnum.Document:
      return ResourceTypeNumEnum.Document;
    case ResourceTypeEnum.PDF:
      return ResourceTypeNumEnum.PDF;
    case ResourceTypeEnum.FillablePDF:
      return ResourceTypeNumEnum.FillablePDF;
    case ResourceTypeEnum.Excel:
      return ResourceTypeNumEnum.Excel;
    case ResourceTypeEnum.Archive:
      return ResourceTypeNumEnum.Archive;
  }
};

import {createSlice} from '@reduxjs/toolkit';
import {userInitialState} from './user.initialState';
import {userReducer as reducers} from './user.reducer';
import {userExtraReducer} from './userExtra.reducer';

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers,
  extraReducers: userExtraReducer,
});

export const {reducer: userReducer} = userSlice;

import {IInstrumentDetailsWithLogos} from 'models';
import {IInstrumentDetailsFormBaseValues} from '..';

const mapInstrumentMetadata = (formData: IInstrumentDetailsFormBaseValues) =>
  JSON.stringify({
    pillarId: formData.pillars,
    programIds: formData.programs,
    phase: formData.phase,
    type: formData.subType ? formData.subType : formData.type,
    audience: formData.audience,
    isGeneralInstrument: formData.isGeneralInstrument,
    instrumentName: formData.templateName,
  });

export const mapUpdateInstrument = (
  formData: IInstrumentDetailsFormBaseValues,
  activeTemplate: IInstrumentDetailsWithLogos
) => {
  const data = new FormData();
  const metaData = mapInstrumentMetadata(formData);

  data.append('templateId', activeTemplate.templateId.toString());
  data.append('name', formData.templateName);
  data.append('instructionText', formData.instructionText);
  data.append('headerUrl', activeTemplate.header.url);
  data.append('footerUrl', activeTemplate.footer.url);
  data.append('metaData', metaData);
  data.append('headerImage', formData.headerLogo);
  data.append('footerImage', formData.footerLogo);

  return data;
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {pamApi} from 'api';
import {IUpdateFileResourceApiResponse, IUpdateFileResourcePayload} from 'models/resources';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const updateScormResourceThunk: any = createAsyncThunk<
  IUpdateFileResourceApiResponse,
  IUpdateFileResourcePayload
>(`${prefix} updateScormResource`, async (payload: IUpdateFileResourcePayload, {dispatch, rejectWithValue}) => {
  try {
    const response = await pamApi.updateScormResource(payload);
    dispatch(toastNotificationManager.createSuccessToastAction('Scorm package was updated successfully!'));
    return response.data;
  } catch (error) {
    dispatch(toastNotificationManager.createErrorToastAction(error.response.data.messages.join('')));
    return rejectWithValue({error});
  }
});

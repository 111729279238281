import {makeStyles} from '@material-ui/core';
import classnames from 'classnames';
import {IComponentWithClassName} from 'models';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IBulletProps extends IComponentWithClassName {}

export function Bullet({className}: IBulletProps) {
  const classes = useStyles();
  return <div className={classnames(classes.bullet, className)}></div>;
}

const useStyles = makeStyles((theme) => ({
  bullet: {
    display: 'list-item',
    listStyleType: 'disc',
    listStylePosition: 'inside',
    color: theme.palette.secondary.main,
  },
}));

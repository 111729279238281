import {Button} from 'components-lib';
import {useFormikContext} from 'formik';
import React, {useCallback} from 'react';
import {isEmpty, isFunction} from 'utils';

interface IFormButtonSubmitProps {
  text?: string;
  size?: 'small' | 'large';
  submitCallback?: () => void;
  disabled?: boolean;
  isLast?: boolean;
  withFormDirtyValidation?: boolean;
}

export function FormButtonSubmit({
  text = 'Submit',
  size = 'small',
  disabled = false,
  withFormDirtyValidation = true,
  isLast = false,
  submitCallback,
}: IFormButtonSubmitProps) {
  const {isSubmitting, isValid, submitForm, dirty, validateForm} = useFormikContext();
  const submitFormHandler = useCallback(async () => {
    const errors = await validateForm();
    if (isEmpty(errors)) {
      submitForm();
      isFunction(submitCallback) && submitCallback();
    }
  }, [validateForm, submitForm, submitCallback]);

  const isDisabledBase = isSubmitting || !isValid || disabled;
  const isDisabled = withFormDirtyValidation ? isDisabledBase || !dirty : isDisabledBase;

  return (
    <Button.Primary size={size} disabled={isDisabled} clickHandler={submitFormHandler} isLast={isLast}>
      {text}
    </Button.Primary>
  );
}

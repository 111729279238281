import {useState} from 'react';

export enum ActiveModeEnum {
  view = 'view',
  edit = 'edit',
  create = 'create',
  build = 'build',
}

export function useViewEditMode() {
  const [activeMode, setActiveMode] = useState<ActiveModeEnum>(ActiveModeEnum.view);
  return {activeMode, setActiveMode};
}

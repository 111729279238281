import {useState} from 'react';
import {ActiveStepEnum} from '../enums';

export const useActiveStep = () => {
  const [activeStep, setActiveStep] = useState<ActiveStepEnum>(ActiveStepEnum.First);

  const isFirstStep = activeStep === ActiveStepEnum.First;
  const isSecondStep = activeStep === ActiveStepEnum.Second;
  const isThirdStep = activeStep === ActiveStepEnum.Third;

  return {activeStep, setActiveStep, isFirstStep, isSecondStep, isThirdStep};
};

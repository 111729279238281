import classnames from 'classnames';
import {Column, Container, Row} from 'components-lib';
import React, {ReactNode} from 'react';
import {useStyles} from './styles';

interface IPageLayoutThreeColProps {
  sidebar: ReactNode;
  contentMiddle: ReactNode;
  contentEnd: ReactNode;
  withSecondaryNavigation?: boolean;
}

export function PageLayoutThreeCol({
  sidebar,
  contentMiddle,
  contentEnd,
  withSecondaryNavigation = false,
}: IPageLayoutThreeColProps) {
  const classes = useStyles(withSecondaryNavigation);

  return (
    <Container classes={{root: classes.root}} disableGutters>
      <Row classes={{root: classes.row}}>
        <Column sm={3} md={3} lg={3} xl={3} className={classes.sidebar}>
          {sidebar && sidebar}
        </Column>
        <Column sm={3} md={3} lg={3} xl={3} className={classnames(classes.content, classes.contentMiddle)}>
          {contentMiddle && contentMiddle}
        </Column>
        <Column sm={6} md={6} lg={6} xl={6} className={classes.content}>
          {contentEnd && contentEnd}
        </Column>
      </Row>
    </Container>
  );
}

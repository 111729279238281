import React, {useEffect, useMemo} from 'react';
import {FormNameEnum} from 'filters-selections/enums';
import {FormNameEnum as IntegrationFormNameEnum} from 'enums/form';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {Form} from 'forms';
import {IAddIntegrationFormBaseValues, IOption, IWithDisabled} from 'models';
import {useDispatch, useSelector} from 'react-redux';
import {fetchIntegrationTypesThunk, filterIntegrationTypesEntitiesCollectionSelector} from 'filters-selections';
import {useFormikContext} from 'formik';
import {
  getIsGoogleClassRoom,
  getIsMicrosoftTeams,
  getIsGoogleSitesSource,
  getIsGoogleSitesDestination,
} from 'admin/integrations';

type IIntegrationTypeSelectProps = IWithDisabled;

export function IntegrationTypeSelect({disabled}: IIntegrationTypeSelectProps) {
  const dispatch = useDispatch();
  const integrationTypes = useSelector(filterIntegrationTypesEntitiesCollectionSelector);
  const {setFieldValue, values} = useFormikContext<IAddIntegrationFormBaseValues>();
  const type = Number(values?.integrationType);
  const isGoogleClassRooom = getIsGoogleClassRoom(type);
  const isGoogleSitesSource = getIsGoogleSitesSource(type);
  const isGoogleSitesDestination = getIsGoogleSitesDestination(type);
  const isMicrosoftTeams = getIsMicrosoftTeams(type);

  useEffect(() => {
    if (!integrationTypes.length) {
      dispatch(fetchIntegrationTypesThunk());
    }
  }, [integrationTypes.length, dispatch]);

  useEffect(() => {
    if (isGoogleClassRooom) {
      setFieldValue(IntegrationFormNameEnum.isGoogleClassroom, isGoogleClassRooom);
      values?.isGoogleSitesSource && setFieldValue(IntegrationFormNameEnum.isGoogleSitesSource, false);
      values?.isGoogleSitesDestination && setFieldValue(IntegrationFormNameEnum.isGoogleSitesDestination, false);
      values?.isMicrosoftTeams && setFieldValue(IntegrationFormNameEnum.isMicrosoftTeams, false);
    }

    if (isGoogleSitesSource) {
      values?.isGoogleClassroom && setFieldValue(IntegrationFormNameEnum.isGoogleClassroom, false);
      setFieldValue(IntegrationFormNameEnum.isGoogleSitesSource, isGoogleSitesSource);
      values?.isGoogleSitesDestination && setFieldValue(IntegrationFormNameEnum.isGoogleSitesDestination, false);
      values?.isMicrosoftTeams && setFieldValue(IntegrationFormNameEnum.isMicrosoftTeams, false);
    }

    if (isGoogleSitesDestination) {
      values?.isGoogleClassroom && setFieldValue(IntegrationFormNameEnum.isGoogleClassroom, false);
      values?.isGoogleSitesSource && setFieldValue(IntegrationFormNameEnum.isGoogleSitesSource, false);
      setFieldValue(IntegrationFormNameEnum.isGoogleSitesDestination, isGoogleSitesDestination);
      values?.isMicrosoftTeams && setFieldValue(IntegrationFormNameEnum.isMicrosoftTeams, false);
    }

    if (isMicrosoftTeams) {
      values?.isGoogleClassroom && setFieldValue(IntegrationFormNameEnum.isGoogleClassroom, false);
      values?.isGoogleSitesSource && setFieldValue(IntegrationFormNameEnum.isGoogleSitesSource, false);
      values?.isGoogleSitesDestination && setFieldValue(IntegrationFormNameEnum.isGoogleSitesDestination, false);
      setFieldValue(IntegrationFormNameEnum.isMicrosoftTeams, isMicrosoftTeams);
    }
  }, [
    isGoogleClassRooom,
    isGoogleSitesSource,
    isGoogleSitesDestination,
    isMicrosoftTeams,
    setFieldValue,
    values.isGoogleClassroom,
    values.isMicrosoftTeams,
    values.isGoogleSitesDestination,
    values.isGoogleSitesSource,
  ]);

  const options: IOption[] = useMemo(
    () =>
      integrationTypes.map((type) => ({
        value: type.id.toString(),
        label: type.displayText,
      })),
    [integrationTypes]
  );

  return (
    <ItemWithPlaceholder label="Integration Type" hasItems={!!integrationTypes.length}>
      <Form.SelectAutocomplete
        name={FormNameEnum.integrationType}
        options={options}
        multiple={false}
        disabled={disabled}
      />
    </ItemWithPlaceholder>
  );
}

import * as Yup from 'yup';
import {LearningPathsAllFilterNamesEnum} from '../../enums';

export interface ILearningPathsFiltersFormValues {
  [LearningPathsAllFilterNamesEnum.SEARCH_BY_QUERY]: string;
  [LearningPathsAllFilterNamesEnum.PROGRAMS]: number[];
  [LearningPathsAllFilterNamesEnum.PROGRAM_YEARS]: number[];
  [LearningPathsAllFilterNamesEnum.GRADE_LEVELS]: number[];
  [LearningPathsAllFilterNamesEnum.PILLARS]: number[];
  [LearningPathsAllFilterNamesEnum.LANGUAGES]: number[];
  [LearningPathsAllFilterNamesEnum.LEARNING_PATH_STATUSES]: number[];
}

export const learningPathsAllValidationSchema = Yup.object().shape({
  [LearningPathsAllFilterNamesEnum.SEARCH_BY_QUERY]: Yup.string(),
  [LearningPathsAllFilterNamesEnum.PROGRAMS]: Yup.array().of(Yup.number()),
  [LearningPathsAllFilterNamesEnum.PROGRAM_YEARS]: Yup.array().of(Yup.number()),
  [LearningPathsAllFilterNamesEnum.GRADE_LEVELS]: Yup.array().of(Yup.number()),
  [LearningPathsAllFilterNamesEnum.PILLARS]: Yup.array().of(Yup.number()),
  [LearningPathsAllFilterNamesEnum.LANGUAGES]: Yup.array().of(Yup.number()),
  [LearningPathsAllFilterNamesEnum.LEARNING_PATH_STATUSES]: Yup.array().of(Yup.number()),
});

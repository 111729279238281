import {getIsGoogleClassRoom} from 'admin/integrations';
import {
  getDefaultPreviewSecondColumnList,
  getIsMicrosoftTeams,
  getIsGoogleSitesSource,
  getIsGoogleSitesDestination,
} from 'admin/integrations/utils/helpers';
import {InputTypeEnum} from 'enums/form';
import {AddIntegrationLabelEnum} from '../../enums';

export const getPreviewSecondColumnList = (
  type: number,
  {
    integrationName,
    schoolDistrictStringValue,
    email,
    googleDriveSourceFolderLink,
    clientSecret,
    authorityUrl,
    masterClassroomEmailAccount,
  }
) => {
  const isGoogleClassroom = getIsGoogleClassRoom(type);
  const isGoogleSitesSource = getIsGoogleSitesSource(type);
  const isGoogleSitesDestination = getIsGoogleSitesDestination(type);
  const isMicrosoftTeams = getIsMicrosoftTeams(type);
  const defaultList = getDefaultPreviewSecondColumnList({
    integrationName,
    schoolDistrictStringValue,
    email,
    googleDriveSourceFolderLink,
  });

  let list = [];

  if (isGoogleClassroom) {
    const googleClassRoomList = defaultList.slice(0, defaultList.length - 1);
    list = [
      ...googleClassRoomList,
      {
        label: AddIntegrationLabelEnum.UserToImpersonate,
        value: masterClassroomEmailAccount ?? '',
        options: {
          withForm: false,
          disabled: true,
        },
      },
    ];
  }

  if (isGoogleSitesSource) {
    list = [...defaultList];
  }

  if (isGoogleSitesDestination) {
    list = [
      {
        label: AddIntegrationLabelEnum.IntegrationName,
        value: integrationName ?? '',
        options: {
          withForm: false,
          disabled: true,
        },
      },
      {
        label: AddIntegrationLabelEnum.SchoolDistrict,
        value: schoolDistrictStringValue ?? '',
        options: {
          withForm: false,
          disabled: true,
        },
      },
    ];
  }

  if (isMicrosoftTeams) {
    const microsoftTeamsList = defaultList.slice(0, defaultList.length - 2);
    list = [
      ...microsoftTeamsList,
      {
        label: AddIntegrationLabelEnum.ClientSecret,
        value: clientSecret ?? '',
        options: {
          withIcon: true,
          withForm: false,
          disabled: true,
        },
        type: InputTypeEnum.Password,
      },
      {
        label: AddIntegrationLabelEnum.AuthorityUrl,
        value: authorityUrl ?? '',
        options: {
          withForm: false,
          disabled: true,
        },
      },
    ];
  }

  return list;
};

import React, {FC, Fragment, useCallback} from 'react';
import {Prompt} from 'react-router-dom';
import {useBeforeunload} from 'react-beforeunload';
import {useModal} from 'hooks';
import {ModalDelete} from 'admin';
import {learningPathMessages} from 'admin/resources/learning-paths/utils';
import {LearningPathFooter} from 'admin/resources/learning-paths/components';
import {useCreateLearningPath} from 'admin/resources/learning-paths/views/create/hooks';
import {useLearningPathBuilderItemsEntities} from 'admin/resources/learning-paths/views/session-builder';

interface ICreateLearningPathFooterProps {
  isDirty: boolean;
  submitFormCallback: () => void;
}

export const CreateLearningPathFooter: FC<ICreateLearningPathFooterProps> = ({isDirty, submitFormCallback}) => {
  const {loading, redirectToViewListOLearningPaths} = useCreateLearningPath();
  const {isSomeOfSessionsWithEmptyName} = useLearningPathBuilderItemsEntities();
  const {isOpen: isModalCreateLPOpen, setIsOpen: setIsModalCreateLPOpen} = useModal();

  useBeforeunload(() => (isDirty || isSomeOfSessionsWithEmptyName) && learningPathMessages.warning);

  const openModalClickHandler = useCallback(() => setIsModalCreateLPOpen(true), [setIsModalCreateLPOpen]);

  return (
    <Fragment>
      <LearningPathFooter
        loading={loading}
        withSaveButton={true}
        withSubmitButton={false}
        isSomeOfSessionsWithEmptyName={isSomeOfSessionsWithEmptyName}
        disableSave={!isDirty || isSomeOfSessionsWithEmptyName}
        openModalClickHandler={openModalClickHandler}
        saveClickHandler={submitFormCallback}
      />
      {isModalCreateLPOpen && (
        <ModalDelete
          loading={false}
          title="Learning Path Creation"
          text="Are you sure you want to cancel the creation of a learning path? The unsaved information will be lost."
          cancelButtonText="Return to creation"
          confirmButtonText="Cancel"
          isOpen={isModalCreateLPOpen}
          setIsOpen={setIsModalCreateLPOpen}
          confirmHandler={redirectToViewListOLearningPaths}
        />
      )}
      <Prompt when={isDirty} message={learningPathMessages.warning} />
    </Fragment>
  );
};

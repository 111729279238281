import React, {useCallback, useMemo} from 'react';
import {BuilderItemDragTypeEnum} from 'admin/library/enums';
import {IBuilderDragItem} from 'admin/library/models';
import {QuestionAnswersTypeEnum} from 'enums';
import {isMatrixQuestion} from '../../utils';
import {CardItem} from '../CardItem/CardItem';
interface IBuilderItemProps {
  builderItem: IBuilderDragItem;
  position: number;
  totalItems: number;
  canDelete?: boolean;
  deleteHandler: (questionAnswerStackId: number) => void;
  editClickHandler?: (questionAnswerStackId: number, defaultAnswerStackId: number, position: number) => void;
  editInstructionalTextClickHandler?: (
    questionAnswerStackId: number,
    defaultAnswerStackId: number,
    position: number
  ) => void;
  moveHandler: (movedItemIdx: number, newPosition: number) => void;
  linkBuilderItem?: (item: IBuilderDragItem) => void;
  openInfoLinkClickHandler?: (item: IBuilderDragItem) => void;
}

export function BuilderItem({
  builderItem,
  position,
  totalItems,
  canDelete = true,
  editClickHandler,
  editInstructionalTextClickHandler,
  deleteHandler,
  moveHandler,
  linkBuilderItem,
  openInfoLinkClickHandler,
}: IBuilderItemProps) {
  const item: IBuilderDragItem = useMemo(
    () => ({
      questionId: builderItem.questionId,
      defaultAnswerStackId: builderItem.defaultAnswerStackId,
      questionType: builderItem.questionType,
      questionIdOriginal: builderItem.questionIdOriginal,
      type: BuilderItemDragTypeEnum.builderItem,
      answerStackId: builderItem.answerStackId,
      originalAnswerStackId: builderItem.originalAnswerStackId,
      linkedQuestionAnswerStackId: builderItem.linkedQuestionAnswerStackId,
      builderInstructionalText: builderItem.builderInstructionalText,
      html: builderItem.html,
      position,
    }),
    [builderItem, position]
  );

  const isMatrix = isMatrixQuestion(builderItem.questionType);
  const isInstructionalText = builderItem.questionType === QuestionAnswersTypeEnum.html;

  const editClickHandlerChanger = useMemo(() => {
    if (editClickHandler && isMatrix) {
      return () => editClickHandler(item.answerStackId, item.defaultAnswerStackId, position);
    }

    if (editClickHandler && isInstructionalText) {
      return () => editInstructionalTextClickHandler(item.answerStackId, item.defaultAnswerStackId, position);
    }

    return null;
  }, [
    editClickHandler,
    editInstructionalTextClickHandler,
    isInstructionalText,
    isMatrix,
    item.answerStackId,
    item.defaultAnswerStackId,
    position,
  ]);

  const linkClickHandlerChanger = linkBuilderItem && !isMatrix ? () => linkBuilderItem(item) : null;
  const openInfoLinkClickChanger = openInfoLinkClickHandler && !isMatrix ? () => openInfoLinkClickHandler(item) : null;

  const deleteClickHandler = useCallback(() => deleteHandler(item.answerStackId), [deleteHandler, item.answerStackId]);

  return (
    <CardItem
      key={item.questionId}
      questionId={item.questionId}
      answerStackId={item.answerStackId}
      type={item.questionType}
      text={item.text}
      html={item.html}
      columns={builderItem.columns}
      rows={builderItem.rows}
      position={position}
      totalItems={totalItems}
      deleteClickHandler={canDelete && deleteClickHandler}
      editClickHandler={editClickHandlerChanger}
      moveHandler={moveHandler}
      linkClickHandler={linkClickHandlerChanger}
      openInfoLinkClickHandler={openInfoLinkClickChanger}
    />
  );
}

import {IAddWebLinkFormBaseValues, mapUploadResourceMetadata} from 'admin/resources';
import {ICreateWebLinkPayload} from 'models/resources';

export const mapCreateResourceWebLink = (values: IAddWebLinkFormBaseValues): ICreateWebLinkPayload => {
  const metadata = mapUploadResourceMetadata(values);

  return {
    displayName: values.displayName,
    briefDescription: values.briefDescription,
    url: values.webLink,
    metadata: {
      ...metadata,
    },
  };
};

import React from 'react';
import {makeStyles} from '@material-ui/core';
import {AddIntegrationInputList} from '../../../';
import {Column} from 'components-lib';
import {getAddIntegrationMicrosoftTeamsList} from 'admin/integrations/add-integration';

export const MicrosoftTeamsContent = () => {
  const classes = useStyles();
  const inputList = getAddIntegrationMicrosoftTeamsList();

  return (
    <Column sm={6} md={6} lg={6} xl={4} className={classes.col}>
      <AddIntegrationInputList list={inputList} withHeightThreeStepsActivity={false} />
    </Column>
  );
};

const useStyles = makeStyles((theme) => ({
  col: {
    marginTop: theme.spacing(2),
  },
}));

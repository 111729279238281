import React, {FC, ReactNode, useMemo, Fragment} from 'react';
import {Column, Container, Loading, Row, Wrapper} from 'components-lib';
import {makeStyles} from '@material-ui/core';
import {footerHeight, mainNavHeight, secondaryNavigation} from 'utils/layout';

interface IPageLayoutOneOrTwoEqualColsProps {
  header: ReactNode;
  footer?: ReactNode;
  content?: ReactNode;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
  loading?: boolean;
  withFooter?: boolean;
}

const navHeight = mainNavHeight + secondaryNavigation;

export const PageLayoutOneOrTwoEqualCols: FC<IPageLayoutOneOrTwoEqualColsProps> = ({
  header,
  footer,
  content,
  contentLeft,
  contentRight,
  loading,
  withFooter = true,
}) => {
  let height = `calc(100vh - ${navHeight}px - ${footerHeight}px)`;

  if (!withFooter) {
    height = `calc(100vh - ${navHeight}px)`;
  }

  const classes = useStyles({height, withFooter});

  const fullContent = useMemo(
    () => (
      <Fragment>
        {content && (
          <Column sm={12} md={12} lg={12} xl={12} className={classes.content}>
            {content && content}
          </Column>
        )}
        {contentLeft && (
          <Column sm={6} md={6} lg={6} xl={6} className={`${classes.content} ${classes.contentLeft}`}>
            {contentLeft && contentLeft}
          </Column>
        )}
        {contentRight && (
          <Column sm={6} md={6} lg={6} xl={6} className={classes.content}>
            {contentRight && contentRight}
          </Column>
        )}
      </Fragment>
    ),
    [classes, content, contentLeft, contentRight]
  );

  return (
    <Container classes={{root: classes.root}} disableGutters>
      <Row classes={{root: classes.secondaryNavigation}}>{header}</Row>
      <Row classes={{root: classes.row}}>
        {loading ? (
          <Wrapper className={classes.content}>
            <Loading />
          </Wrapper>
        ) : (
          fullContent
        )}
      </Row>
      {withFooter && (
        <Row justify="flex-end" align="center" classes={{root: classes.footer}}>
          {footer}
        </Row>
      )}
    </Container>
  );
};

interface IContentStyles {
  height: string;
  withFooter?: boolean;
}

export const useStyles = makeStyles((theme) => ({
  root: ({height}: IContentStyles) => ({
    flexGrow: 1,
    maxHeight: height,
  }),
  secondaryNavigation: {
    paddingLeft: theme.spacing(4),
    height: secondaryNavigation,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    background: theme.palette.common.white,
  },
  footer: {
    height: footerHeight,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    background: theme.palette.common.white,
  },
  row: {
    width: '100%',
    margin: 0,
  },
  content: ({height, withFooter}: IContentStyles) => ({
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: !withFooter && theme.spacing(4),
    background: theme.palette.common.white,
    alignItems: 'stretch',
    height: height,
    overflow: 'auto',
    width: '100%',
  }),
  contentLeft: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
}));

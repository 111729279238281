import {IUploadFileFormBaseValues, mapUploadResourceMetadata} from 'admin/resources';

export const mapUploadResourceFile = (values: IUploadFileFormBaseValues) => {
  const data = new FormData();
  const metadata = mapUploadResourceMetadata(values);

  data.append('file', values.file);
  data.append('displayName', values.displayName);
  data.append('briefDescription', values.briefDescription);
  data.append('fileTypeId', values.fileType.toString());
  data.append('metadata', JSON.stringify(metadata));

  return data;
};

import React, {useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {EntityHeaderName, EntityItems} from 'admin/library/components';
import {Button, Column, Row, Text} from 'components-lib';
import {IAnswerStack, IItemDetails} from 'models';
import {useItem, useItemIdParam, usePublish} from '../hooks';
import {ItemBaseView} from '../views';
import {SidebarItemDetails} from './components';
import {ActiveModeEnum} from '../enums';

export function ItemDetailsView() {
  const {itemId} = useItemIdParam();
  const classes = useStyles();
  const {publishingStack, publishItemAnswerStack, unPublishItemAnswerStack} = usePublish();
  const {item, fetchItem} = useItem();

  useEffect(() => {
    if (item?.id !== itemId) {
      fetchItem(itemId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchItem, item.id, itemId]);

  const actions = useMemo(
    () => (isApproved: boolean, canBeUnpublished: boolean, id: number) => {
      const publishClickHandler = () => {
        publishItemAnswerStack(id, item.questionId);
      };
      const unPublishClickHandler = () => {
        unPublishItemAnswerStack(id, item.questionId);
      };

      return (
        <div className={classes.actions}>
          {!isApproved ? (
            <Button.Primary
              size="small"
              clickHandler={!publishingStack ? publishClickHandler : null}
              disabled={publishingStack}
            >
              Publish
            </Button.Primary>
          ) : canBeUnpublished ? (
            <Button.Secondary
              size="small"
              clickHandler={!publishingStack ? unPublishClickHandler : null}
              disabled={publishingStack}
            >
              Un-publish
            </Button.Secondary>
          ) : (
            <Text.Caption>Published</Text.Caption>
          )}
        </div>
      );
    },
    [classes.actions, item.questionId, publishItemAnswerStack, publishingStack, unPublishItemAnswerStack]
  );

  return (
    <ItemBaseView Sidebar={SidebarItemDetails} editMode={ActiveModeEnum.view}>
      {(item: IItemDetails) => {
        const stacks = item?.answerStacks.filter((stack) => stack.questionId > -1 && stack.isSelected);

        return (
          !!item && (
            <Column>
              <EntityHeaderName name={item.text} />
              <Row justify="flex-start">
                {item?.answerStacks && !!item?.answerStacks.length && (
                  <EntityItems<IAnswerStack>
                    items={stacks}
                    itemPrefixText="Answer Stack"
                    withTitle={false}
                    actions={actions}
                  />
                )}
              </Row>
            </Column>
          )
        );
      }}
    </ItemBaseView>
  );
}

const useStyles = makeStyles({
  actions: {
    marginLeft: 'auto',
  },
});

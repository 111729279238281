import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';

const getProgramsState = (state: TStateStore) => state.programs.details;
const getLevelOfReportsState = createSelector(getProgramsState, (programReports) => programReports.levelOfReports);

export const activeEntitySelector = createSelector(getProgramsState, (programs) => programs.activeEntity);
export const loadingSelector = createSelector(getProgramsState, (programs) => programs.loading);

export const levelOfReportsLoadingSelector = createSelector(
  getLevelOfReportsState,
  (levelOfReports) => levelOfReports.loading
);

export const selectedIdSelector = createSelector(getLevelOfReportsState, (levelOfReports) => levelOfReports.selectedId);

export const levelOfReportsMenuItemsSelector = createSelector(
  getLevelOfReportsState,
  (levelOfReports) => levelOfReports.menuItems
);

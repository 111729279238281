export enum NavigationProgressBarTextEnum {
  // Text = 'Text',
  // GoogleClassRoom = 'Google Class Room',
  // MicrosoftTeams = 'Microsoft Teams',
  // PreviewAndTest = 'Preview and Test',
  // GoogleSitesSource = 'Google Sites Source',
  // GoogleSitesDestination = 'Google Sites Destination',
  Step1Text = 'Choose Integration Details',
  Step2Text = 'Set up Integration',
  Step3Text = 'Preview and Test',
}

import {useState, useEffect, useCallback} from 'react';

export function useDelay(delay: number) {
  const [timer, setTimer] = useState(null);

  const clear = useCallback(() => timer && clearTimeout(timer), [timer]);

  useEffect(() => () => clear(), [clear]);

  return (fn: () => void): void => setTimer(setTimeout(fn, delay));
}

import {IBindBaseStringIdEnumEntity} from 'models';

export const getPublishedByExtendedList = (userArea: number, publishedBy: IBindBaseStringIdEnumEntity) => [
  {
    name: userArea,
    displayText: 'Area',
  },
  {
    name: publishedBy.id,
    displayText: publishedBy.displayText,
  },
];

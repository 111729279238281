import {Button} from 'components-lib';
import {
  AreaAdminRole,
  EducatorRole,
  StaffRole,
  StudentRole,
  SuperAdminRole,
  SuperAndNationalAdminroles,
  USAAdminRole,
  VolunteerRole,
} from 'enums/admin-roles';
import {withRole, AdminRoles, AllRolesWithoutStudent} from 'permissions';

// SuperAdmin
export const ButtonPrimaryWithSuperAdminPermissions = withRole(SuperAdminRole, '')(Button.Primary);
export const ButtonSecondaryWithSuperAdminPermissions = withRole(SuperAdminRole, '')(Button.Secondary);

// AreaAdmin
export const ButtonPrimaryWithAreaAdminPermissions = withRole(AreaAdminRole, '')(Button.Primary);
export const ButtonSecondaryWithAreaAdminPermissions = withRole(AreaAdminRole, '')(Button.Secondary);

// USAAdmin
export const ButtonPrimaryWithUSAAdminPermissions = withRole(USAAdminRole, '')(Button.Primary);
export const ButtonSecondaryWithUSAAdminPermissions = withRole(USAAdminRole, '')(Button.Secondary);

// Staff
export const ButtonPrimaryWithStaffPermissions = withRole(StaffRole, '')(Button.Primary);
export const ButtonSecondaryWithStaffPermissions = withRole(StaffRole, '')(Button.Secondary);

// Educator
export const ButtonPrimaryWithEducatorPermissions = withRole(EducatorRole, '')(Button.Primary);
export const ButtonSecondaryWithEducatorPermissions = withRole(EducatorRole, '')(Button.Secondary);

// Volunteer
export const ButtonPrimaryWithVolunteerPermissions = withRole(VolunteerRole, '')(Button.Primary);
export const ButtonSecondaryWithVolunteerPermissions = withRole(VolunteerRole, '')(Button.Secondary);

// Student
export const ButtonPrimaryWithStudentPermissions = withRole(StudentRole, '')(Button.Primary);
export const ButtonSecondaryWithStudentPermissions = withRole(StudentRole, '')(Button.Secondary);

// Super, USA Admin and AreaAdmin
export const ButtonPrimaryWithAdminRolesPermissions = withRole(AdminRoles, '')(Button.Primary);
export const ButtonSecondaryWithAdminRolesPermissions = withRole(AdminRoles, '')(Button.Secondary);

// Super and USA Admin
export const ButtonPrimaryWithAdminRolesWitoutAreaPermissions = withRole(
  SuperAndNationalAdminroles,
  ''
)(Button.Primary);
export const ButtonSecondaryWithAdminRolesWitoutAreaPermissions = withRole(
  SuperAndNationalAdminroles,
  ''
)(Button.Secondary);

// AllRolesWithoutStudent
export const ButtonPrimaryWithAllRolesWithoutStudentPermissions = withRole(AllRolesWithoutStudent, '')(Button.Primary);
export const ButtonSecondaryWithAllRolesWithoutStudentPermissions = withRole(
  AllRolesWithoutStudent,
  ''
)(Button.Secondary);

import {config} from 'config';
import {ApiPathsEnum} from 'enums';
import {
  IBaseFiltersApiResponse,
  IBindBaseEntity,
  IBindBaseEnumEntity,
  IEntityBase,
  IProgramClassesFilterOptions,
} from 'models';
import {get, post} from './http-client';
import {IHttpResponse} from './models';

const {jaEeApi: apiConfig} = config;

export const filtersApi = {
  async getInstrumentPhases(): Promise<IHttpResponse<IBindBaseEnumEntity[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/GetTemplatePhases`;
    return await get<IBindBaseEnumEntity[]>(url);
  },

  async getInstrumentTypes(): Promise<IHttpResponse<IBindBaseEnumEntity[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/GetTemplateTypes`;
    return await get<IBindBaseEnumEntity[]>(url);
  },

  async getInstrumentMainTypes(): Promise<IHttpResponse<IBindBaseEnumEntity[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/GetMainTemplateTypes`;
    return await get<IBindBaseEnumEntity[]>(url);
  },

  async getQuestionTypes(): Promise<IHttpResponse<IBindBaseEnumEntity[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/GetQuestionTypes`;
    return await get<IBindBaseEnumEntity[]>(url);
  },

  async getInstrumentAudiences(): Promise<IHttpResponse<IBindBaseEnumEntity[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/GetTemplateAudiences`;
    return await get<IBindBaseEnumEntity[]>(url);
  },

  async getAvailableGradeLevels(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetActiveGradeLevels`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getInstrumentStatuses(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/GetTemplateStatuses`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getActivePillars(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetActivePillars`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getProgramYears(): Promise<IHttpResponse<string[]>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetDistinctProgramYears`;
    return await get<string[]>(url);
  },

  async getProgramYearsExt(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetDistinctProgramYearsExt`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getFilterProgramYears(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.HELPERS}/GetFilterProgramYears`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getFutureProgramYears(): Promise<IHttpResponse<IBaseFiltersApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.HELPERS}/GetFutureProgramYears`;
    return await get<IBaseFiltersApiResponse>(url);
  },

  async getPrograms(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetActiveProgramsAll`;
    return await get<IBindBaseEntity[]>(url, false);
  },

  async getAreas(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetActiveAreas`;
    return await get<IBindBaseEntity[]>(url, false);
  },

  async getClassFilterOptions(programId: number): Promise<IHttpResponse<IProgramClassesFilterOptions>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetClassFilterOptions?programId=${programId}`;
    return await get<IProgramClassesFilterOptions>(url);
  },

  async getEvaluationTypes(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/QuestionEvaluationTypeValues`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getDomains(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/QuestionDomainValues`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getCompetencies(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetActiveCompetencies`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getSessionsByProgramIds(programIds: number[]): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetActiveSessionsFilteredByProgramIds`;
    return await post<IBindBaseEntity[]>(url, programIds);
  },

  async getConcepts(competencies: number[]): Promise<IHttpResponse<IEntityBase[]>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetActiveConceptsFilteredByCompetencyIds`;
    return await post<IEntityBase[]>(url, competencies);
  },

  async getDigitalResourcesConcepts(): Promise<IHttpResponse<IEntityBase[]>> {
    const url = `${apiConfig.baseUrlDigitalResources}/${ApiPathsEnum.DIGITAL_RESOURCE}/GetExistingConcepts`;
    return await get<IEntityBase[]>(url);
  },

  async getAdministrations(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/QuestionAdministrationValues`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getSchoolLevels(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/QuestionSchoolLevel`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getSessions(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetActiveSessions`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getTaggingGradeLevels(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetTaggingGradeLevels`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getSkillsAndBehaviors(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetActiveSkillLevels`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getLanguages(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlUms}/${ApiPathsEnum.UMS}/GetLanguages`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getDefualtKeyTerms(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlDigitalResources}/${ApiPathsEnum.DIGITAL_RESOURCE}/GetDefaultKeyTerms`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getKeyTerms(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlDigitalResources}/${ApiPathsEnum.DIGITAL_RESOURCE}/GetExistingKeyTerms`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getKLearningObjectives(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlDigitalResources}/${ApiPathsEnum.DIGITAL_RESOURCE}/GetExistingLearningObjectives`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getDigitalResourcesTypes(): Promise<IHttpResponse<IBindBaseEntity[]>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ENUMERATORS}/DigitalResourceType`;
    return await get<IBindBaseEntity[]>(url);
  },

  async getSchoolDistrictsByQuery(query: string): Promise<IHttpResponse<IBindBaseEnumEntity[]>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.ENUMERATORS}/SearchDistricts`;
    return await post<IBindBaseEnumEntity[]>(url, {query});
  },

  async getFilterContentItemStatuses(): Promise<IHttpResponse<IBaseFiltersApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.HELPERS}/GetFilterContentItemStatuses`;
    return await get<IBaseFiltersApiResponse>(url);
  },

  async getFilterFileStatuses(): Promise<IHttpResponse<IBaseFiltersApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.HELPERS}/GetFilterFileStatuses`;
    return await get<IBaseFiltersApiResponse>(url);
  },
};

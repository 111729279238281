import React, {FC} from 'react';
import {ItemAnswersCustom} from '../ItemAnswersCustom/ItemAnswersCustom';
import {IAnswer} from 'models/library/common/IAnswer';
import {useFormikContext} from 'formik';
import {IModalCreateAnswerStackFormValues} from '../ModalCreateAnswerStack/config/modalCreateAnswerStackValidationSchema';
import {useMapChoicesBasedOnDomainKnowledge} from '../../hooks';
interface IItemAnswersMultiSelectProps {
  choices: IAnswer[];
}

export const ItemAnswersMultiSelect: FC<IItemAnswersMultiSelectProps> = ({choices}) => {
  const {values, setFieldValue} = useFormikContext<IModalCreateAnswerStackFormValues>();
  const {isDomainKnowledge} = useMapChoicesBasedOnDomainKnowledge(values, setFieldValue);

  return <ItemAnswersCustom choices={choices} disableAdd={isDomainKnowledge} disableDelete={isDomainKnowledge} />;
};

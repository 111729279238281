import {createAsyncThunk} from '@reduxjs/toolkit';
import {pamApi} from 'api';
import {IGetAllFilesApiResponse} from 'models/resources';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {IFetchFilesAllPagedThunkPayload} from 'models/resources/files';

export const fetchFilesAllPagedThunk: any = createAsyncThunk<IGetAllFilesApiResponse, null>(
  `${prefix} fetchFilesAllPaged`,
  async (payload: IFetchFilesAllPagedThunkPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await pamApi.getAllFiles(payload);
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

import React, {useCallback} from 'react';
import {Button, Column, Modal, Row, Text} from 'components-lib';
import {ReactComponent as IllustrationTrash} from 'resources/images/instruments/Illustration_trash.svg';
import {useFormikContext} from 'formik';

export const ModalCancelDataImport = ({isOpen, handleClose, handleCancelDataImport}) => {
  const {resetForm} = useFormikContext();

  const closeClickHandler = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const cancelClickHandler = useCallback(() => {
    resetForm();
    handleCancelDataImport();
  }, [handleCancelDataImport, resetForm]);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Cancel Import Data Confirmation" size="small">
      <Column>
        <Row align="center">
          <IllustrationTrash />
          <Text.Paragraph align="center" withMargin>
            Are you sure you want to cancel the data import?
          </Text.Paragraph>
          <Text.Paragraph>This action cannot be undone!</Text.Paragraph>
        </Row>
      </Column>
      <>
        <Button.Secondary clickHandler={closeClickHandler} size="small">
          Close
        </Button.Secondary>
        <Button.Primary clickHandler={cancelClickHandler} size="small">
          Cancel
        </Button.Primary>
      </>
    </Modal>
  );
};

import {Accordion, Column, Text} from 'components-lib';
import {Form} from 'forms';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormNameEnum} from '../../../enums';
import {fetchSchoolLevelThunk, filtersSchoolLevelsEntitiesCollectionSelector} from '../../../store';
import {IWithDisabled} from 'models';

export function SchoolLevelFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const schoolLevels = useSelector(filtersSchoolLevelsEntitiesCollectionSelector);

  useEffect(() => {
    if (!schoolLevels.length) {
      dispatch(fetchSchoolLevelThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Accordion heading="School Level">
      <Column>
        {schoolLevels.length ? (
          schoolLevels.map((schoolLevel) => (
            <Form.Checkbox
              key={schoolLevel.id}
              name={FormNameEnum.schoolLevel}
              value={schoolLevel.id}
              label={schoolLevel.displayText}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>No Data</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
}

import {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useAppDispatch} from 'hooks';

import {BuilderItemDragTypeEnum} from 'admin/library/enums';
import {IBuilderDragItem} from 'admin/library/models';
import {
  IAnswer,
  IInstrumentInstructionalItem,
  IInstrumentItem,
  IInstrumentsAllFilter,
  IItemColumn,
  IQuestionUpdate,
} from 'models';
import {isNumber, toNumber} from 'utils';
import {
  instrumentsActions,
  instrumentsAllFilterSelector,
  instrumentsPageSelector,
  savingSelector,
  updateDraftInstrumentThunk,
} from '../../store';
import {
  generateRandomQuestionId,
  isInstructionalTextQuestion,
  isMatrixQuestion,
  mapBuilderItemInstructionalText,
} from '../utils';
import {useBuilderItemsEntities} from './useBuilderItemsEntities';
import {useBuilderPoolItemsEntities} from './useBuilderPoolItemsEntities';
import {QuestionAnswersTypeEnum} from 'enums';

export function useBuilder() {
  const dispatch = useAppDispatch();

  const {builderItemsResult, builderItemsEntities} = useBuilderItemsEntities();
  const {builderPoolItemsEntities} = useBuilderPoolItemsEntities();

  const urlParams = useParams<{id: string}>();
  const instrumentId = toNumber(urlParams?.id);

  const filter = useSelector(instrumentsAllFilterSelector);

  const {loading} = useSelector(instrumentsPageSelector);
  const saving = useSelector(savingSelector);

  const [pristine, setPristine] = useState<boolean>(true);

  const builderItems: any = builderItemsResult.map((id) => mapBuilderItem(builderItemsEntities[toNumber(id)]));

  const pristineStateHandler = useCallback(() => {
    pristine && setPristine(false);
  }, [pristine]);

  const deleteBuilderItem = useCallback(
    (id: number) => {
      dispatch(instrumentsActions.removeBuilderItem(id));
      pristineStateHandler();
    },
    [pristineStateHandler, dispatch]
  );

  const linkBuilderItem = useCallback(
    (item) => {
      dispatch(instrumentsActions.linkBuilderItem(item));
      pristineStateHandler();
    },
    [pristineStateHandler, dispatch]
  );

  const moveBuilderItem = useCallback(
    (movedItemIdx: number, newPosition: number) => {
      const dragItem = builderItems[movedItemIdx];
      dispatch(
        instrumentsActions.moveBuilderItem({
          id: dragItem.answerStackId,
          from: movedItemIdx,
          to: newPosition,
        })
      );
      pristineStateHandler();
    },
    [builderItems, dispatch, pristineStateHandler]
  );

  const getBuilderItem = (isMatrix: boolean, element: any, itemRaw: any) => {
    if (isMatrix) {
      return {
        rows: [],
        columns: mapAnswerToMatrixColumn(element.choices),
        questionAnswerStackId: element.questionAnswerStackId,
        originalAnswerStackId: element.questionAnswerStackId,
        defaultAnswerStackId: element.defaultAnswerStackId,
        questionIdOriginal: element.questionId,
        questionId: element.questionId,
        questionType: itemRaw.questionOptionsTypeId,
        type: itemRaw.questionOptionsTypeId,
        text: itemRaw.text,
        title: itemRaw.text,
        name: '',
      };
    }

    return {
      ...element,
      questionIdOriginal: element.questionId,
      text: itemRaw.text,
    };
  };

  const addBuilderItems = useCallback(
    (position: number) => (item: IBuilderDragItem) => {
      const itemRaw = builderPoolItemsEntities[item.questionId];
      const isMatrix = isMatrixQuestion(itemRaw.questionOptionsTypeId);
      const element = builderPoolItemsEntities[item.questionId]?.elements[position];

      const builderItem: any = getBuilderItem(isMatrix, element, itemRaw);

      dispatch(instrumentsActions.addBuilderItem(builderItem));
      pristineStateHandler();
    },
    [builderPoolItemsEntities, dispatch, pristineStateHandler]
  );

  const addMatrixRow = useCallback(
    (questionAnswerStackId: number) => (item: IBuilderDragItem) => {
      const itemRaw = builderPoolItemsEntities[item.questionId];
      const row: IAnswer = {
        questionAnswerStackId: item.answerStackId, // TODO this is not the stackId
        questionId: item.questionId,
        text: itemRaw.text,
        value: `${item.questionId}`,
      };

      dispatch(instrumentsActions.addMatrixRow({questionAnswerStackId, row}));
      pristineStateHandler();
    },
    [builderPoolItemsEntities, dispatch, pristineStateHandler]
  );

  const addInstructionalText = useCallback(() => {
    const randomNumber = generateRandomQuestionId();

    const builderItem = {
      questionAnswerStackId: randomNumber,
      html: null,
      questionType: QuestionAnswersTypeEnum.html,
    } as IInstrumentInstructionalItem;

    dispatch(instrumentsActions.addInstructionalText(builderItem));
  }, [dispatch]);

  const updateInstructionalText = useCallback(
    (item: IInstrumentItem, html: string) => {
      const builderItem = mapBuilderItemInstructionalText(item, html);

      dispatch(instrumentsActions.updateInstructionalText(builderItem));
    },
    [dispatch]
  );

  const deleteMatrixRow = useCallback(
    (id: number) => (rowIdx: number) => {
      dispatch(instrumentsActions.removeMatrixRow({id, rowIdx}));
      pristineStateHandler();
    },
    [pristineStateHandler, dispatch]
  );

  const moveMatrixRow = useCallback(
    (id: number) => (dragIdx: number, hoverIdx: number) => {
      dispatch(instrumentsActions.moveMatrixRow({id, from: dragIdx, to: hoverIdx}));
      pristineStateHandler();
    },
    [dispatch, pristineStateHandler]
  );

  const save = useCallback(
    (onSuccess?: () => void) => {
      const items = mapItemsForSave(builderItems);
      dispatch(updateDraftInstrumentThunk({payload: {instrumentId, items}, onSuccess}));
    },
    [builderItems, dispatch, instrumentId]
  );

  const setFilter = useCallback(
    (filter: IInstrumentsAllFilter) => dispatch(instrumentsActions.setFilterInstruments(filter)),
    [dispatch]
  );

  return {
    pageLoading: loading,
    pageSaving: saving,
    filter,
    instrumentId,
    builderItems,
    save,
    pristine,
    addBuilderItems,
    moveBuilderItem,
    deleteBuilderItem,
    linkBuilderItem,
    addMatrixRow,
    moveMatrixRow,
    deleteMatrixRow,
    setFilter,
    addInstructionalText,
    updateInstructionalText,
  };
}

function mapItemsForSave(items: IBuilderDragItem[]): IQuestionUpdate[] {
  return items.reduce((acc: IQuestionUpdate[], item: IBuilderDragItem) => {
    if (isMatrixQuestion(item.questionType)) {
      const {originalAnswerStackId} = item;

      if (!item.rows.length) {
        acc.push({
          questionAnswerStackId: null,
          parentQuestionAnswerStackId: originalAnswerStackId,
        });

        return acc;
      }

      item.rows.forEach((row) => {
        acc.push({
          questionAnswerStackId: row.questionAnswerStackId,
          parentQuestionAnswerStackId: originalAnswerStackId,
          linkedQuestionAnswerStackId: row.linkedQuestionAnswerStackId,
        });
      });
      return acc;
    }

    if (isInstructionalTextQuestion(item.questionType)) {
      acc.push({
        instructionalTextId: item.answerStackId,
        instructionalText: item.html ?? '',
      });

      return acc;
    }

    acc.push({
      questionAnswerStackId: item.answerStackId,
      parentQuestionAnswerStackId: null,
      linkedQuestionAnswerStackId: item.linkedQuestionAnswerStackId,
    });

    return acc;
  }, []);
}

function mapBuilderItem(builderItem: IInstrumentItem): IBuilderDragItem {
  if (!builderItem) return;

  return {
    questionId: builderItem.questionIdOriginal ?? builderItem.questionId,
    answerStackId: builderItem.questionAnswerStackId,
    originalAnswerStackId: isNumber(builderItem.originalAnswerStackId) ? builderItem.originalAnswerStackId : -1,
    defaultAnswerStackId: builderItem.defaultAnswerStackId,
    linkedQuestionAnswerStackId: builderItem.linkedQuestionAnswerStackId,
    type: BuilderItemDragTypeEnum.item,
    questionType: builderItem.type || builderItem.questionType,
    rows: builderItem.rows,
    columns: builderItem.columns,
    html: builderItem.html,
    builderInstructionalText: builderItem.builderInstructionalText,
    domain: mapDomain(builderItem),
  };
}

function mapAnswerToMatrixColumn(answers: IAnswer[]): IItemColumn[] {
  return answers.map(({answerId, position, text}) => ({
    answerId,
    order: position,
    position,
    text,
    value: `${answerId}`,
  }));
}

function mapDomain(builderItem: IInstrumentItem) {
  let domain = null;

  if (builderItem?.domain) {
    domain = builderItem.domain.id;
  }

  if (builderItem?.metaData?.domain) {
    domain = builderItem.metaData.domain.id;
  }

  return domain;
}

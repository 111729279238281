import {
  IActionFulfilled,
  IProgramClassesPaperAssessmentsFilter,
  IProgramClassesPaperAssessmentsSortOrder,
} from 'models';
import {cloneDeep} from 'utils';
import {IProgramClassesPaperAssessmentsState} from './IProgramClassesPaperAssessmentsState';
import {programClassesPaperAssessmentsInitialState} from './programClassesPaperAssessments.initialState';

export const programClassesPaperAssessmentsReducer = {
  clear(state: IProgramClassesPaperAssessmentsState) {
    state.paperAssessmentList = {...programClassesPaperAssessmentsInitialState.paperAssessmentList};
  },
  setCurrentPage: (state: IProgramClassesPaperAssessmentsState, action: IActionFulfilled<null, number>) => {
    state.pagination.currentPage = action.payload;
  },

  setPerPage: (state: IProgramClassesPaperAssessmentsState, action: IActionFulfilled<null, number>) => {
    state.pagination.perPage = action.payload;
    resetPagination(state);
  },

  setFilter: (
    state: IProgramClassesPaperAssessmentsState,
    action: IActionFulfilled<null, IProgramClassesPaperAssessmentsFilter>
  ) => {
    state.filter = action.payload;
  },

  resetFilter: (state: IProgramClassesPaperAssessmentsState) => {
    state.filter = cloneDeep(programClassesPaperAssessmentsInitialState.filter);
  },

  setSortOrderPaperAssessments: (
    state: IProgramClassesPaperAssessmentsState,
    action: IActionFulfilled<null, IProgramClassesPaperAssessmentsSortOrder>
  ) => {
    state.sortOrder = action.payload;
  },
};

const resetPagination = (state: IProgramClassesPaperAssessmentsState) => {
  state.pagination.currentPage = programClassesPaperAssessmentsInitialState.pagination.currentPage;
  state.pagination.totalCount = programClassesPaperAssessmentsInitialState.pagination.totalCount;
};

import React from 'react';
import {ButtonBase} from './ButtonBase';
import {IButtonBaseProps} from './IButtonBaseProps';

interface IButtonPrimaryProps extends IButtonBaseProps {
  isFirst?: boolean;
  isLast?: boolean;
}

export function ButtonPrimary({
  children,
  clickHandler,
  disabled = false,
  endIcon = null,
  size = 'large',
  type = 'button',
  isFirst = false,
  isLast = false,
  className = '',
}: IButtonPrimaryProps) {
  return (
    <ButtonBase
      variant="contained"
      color="primary"
      clickHandler={clickHandler}
      disabled={disabled}
      endIcon={endIcon}
      size={size}
      type={type}
      isFirst={isFirst}
      isLast={isLast}
      className={className}
    >
      {children}
    </ButtonBase>
  );
}

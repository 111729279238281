import React, {forwardRef, Fragment} from 'react';
import {IMenuItem} from 'models/common';
import {MenuItem} from './MenuItem';

interface IMenuItemListProps {
  items: IMenuItem[];
  closeClickHandler: () => void;
}

export const MenuItemList = forwardRef(({items, closeClickHandler}: IMenuItemListProps, ref) => {
  return (
    <Fragment>
      {items.length &&
        items?.map((item, idx: number) => (
          <MenuItem ref={ref} key={idx} item={item} closeClickHandler={closeClickHandler}>
            {item.text}
          </MenuItem>
        ))}
    </Fragment>
  );
});

MenuItemList.displayName = 'MenuItemList';

import React, {memo} from 'react';
import {ReactComponent as PageNotFoundImage} from 'resources/images/errorPage/Illustration_Error_404.svg';
import {makeStyles} from '@material-ui/core';
import {Button, Container, Row, Text} from 'components-lib';
import {useNavigate} from 'hooks';
import {paths} from 'paths';
import {layoutConfig} from 'layout';
interface IErrorPageProps {
  returnHomeClickHandler?: () => void;
}

export function ErrorPage({returnHomeClickHandler}: IErrorPageProps) {
  const {push} = useNavigate();
  const classes = useStyles();

  function returnToHome() {
    push(paths.root);
  }

  return (
    <Container classes={{root: classes.container}}>
      <Container>
        <Row>
          <PageNotFoundImage />
        </Row>
        <Row>
          <Text.Heading as="h1">Page Not Found!</Text.Heading>
        </Row>
        <Row>
          <Text.Heading as="h3">
            The page you are looking for might have been removed, had its name changed,
            <br /> or it&apos;s temporarily unavailable.
          </Text.Heading>
        </Row>
        <Row>
          <div className={classes.returnButton}>
            <Button.Secondary clickHandler={returnHomeClickHandler ? returnHomeClickHandler : returnToHome}>
              Return To Home Page
            </Button.Secondary>
          </div>
        </Row>
      </Container>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    height: `calc(100vh - ${layoutConfig.scrollableAreaWithoutSecondaryNavigationSubtrahend}px)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  h1: {
    color: theme.palette.grey[700],
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  h3: {
    color: theme.palette.grey[700],
  },
  returnButton: {
    marginTop: theme.spacing(4),
  },
}));

export default memo(ErrorPage);

import React, {Fragment, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Wrapper} from 'components-lib';
import {AuditLogs, IntegrationDetails} from 'admin/integrations';

export const IntegrationDetailsContent = () => {
  const classes = useStyles();

  const content = useMemo(
    () => (
      <Fragment>
        <Wrapper className={classes.detailsContainer}>
          <IntegrationDetails />
        </Wrapper>
        <AuditLogs />
      </Fragment>
    ),
    [classes.detailsContainer]
  );

  return <Wrapper>{content}</Wrapper>;
};

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    marginBottom: theme.spacing(3),
  },
}));

import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ItemsAllSortBy, ItemsAllFilters} from './components';
import {ButtonPrimaryWithAdminPermissions} from '../components';
import {PageLayoutTwoCol, PageLayoutWithFixedAreas} from 'layout';
import {Button, Checkbox, Loading, PageViewHeader, Table, Tag, PaginationLocal} from 'components-lib';
import {useNavigate} from 'hooks';
import {useItems, useItemFilters} from '../hooks';
import {IItemsFilter} from 'models';
import {pages} from 'paths';
import {ItemFilterNamesEnum} from '../enums';
import {itemsActions as actions} from '../store/items.slice';
import {itemAllColumns, itemsFilterList, itemFilterToTilterNameMap, maxSymbols} from './utils';

export function ItemsAllView() {
  const dispatch = useDispatch();
  const {push} = useNavigate();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const {
    pageLoading,
    filter,
    filterEntities,
    currentPage,
    perPage,
    totalCount,
    itemsList,
    fetchItems,
    setCurrentPage,
    setPerPage,
  } = useItems();

  useItemFilters();

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const viewItemDetailsClickHandler = useCallback(
    (id: number) => {
      const questionIdQueryParam = `?questionId=${id}`;
      push(`${pages.adminPortal.items.create}${questionIdQueryParam}`);
    },
    [push]
  );

  const rows = useMemo(() => {
    const changeSelection = (id: string, selected: boolean) => {
      if (selected === true) {
        setSelectedIds((prevSelected) => prevSelected.concat(id));
      } else {
        setSelectedIds((prevSelected) => {
          const indexOfCurrent = prevSelected.indexOf(id);
          if (indexOfCurrent !== -1) {
            return prevSelected.filter((selectedId) => selectedId !== id);
          }
        });
      }
    };

    if (itemsList && itemsList.result.length > 0) {
      const itemIds = itemsList?.result;
      return itemIds.map((itemId) => {
        const item = itemsList.entities.items[itemId];
        const {questionId, questionText, questionType} = item;
        return {
          checkbox: (
            <Checkbox
              key={`items-list-item-${questionId}`}
              changeHandler={(_event, checked) => changeSelection(questionId.toString(), checked)}
              checked={selectedIds.indexOf(questionId.toString()) > -1}
            />
          ),
          item: questionText?.length <= maxSymbols ? questionText : questionText?.substr(0, maxSymbols) + '...',
          questionType: questionType,
          actions: (
            <Button.Secondary
              size="small"
              clickHandler={() => {
                viewItemDetailsClickHandler(questionId);
              }}
            >
              View
            </Button.Secondary>
          ),
        };
      });
    } else {
      return [];
    }
  }, [itemsList, selectedIds, viewItemDetailsClickHandler]);

  const contentFooter = useMemo(() => {
    let isPaginationVisible: boolean;

    if (selectedIds.length === 0) {
      isPaginationVisible = true;
    } else {
      isPaginationVisible = false;
    }
    return (
      <PaginationLocal
        onPageChange={setCurrentPage}
        onPerPageChange={setPerPage}
        totalCount={totalCount}
        perPage={perPage}
        currentPage={currentPage}
        isVisible={isPaginationVisible}
        disabled={pageLoading}
      />
    );
  }, [selectedIds.length, setCurrentPage, setPerPage, totalCount, perPage, currentPage, pageLoading]);

  const renderSortOrder = useMemo(() => <ItemsAllSortBy />, []);

  const appliedFilters = useMemo(() => {
    const chips: any[] = [];

    const resetDropdownFilter = (propName: string, id: string | number) => {
      const updated: IItemsFilter = {...filter};

      updated[propName] = updated[propName].filter((pId: number) => pId !== id);
      dispatch(actions.setFilterItems(updated));
    };

    itemsFilterList.forEach((itemFilter, idx) => {
      const mappedFilterName: ItemFilterNamesEnum = itemFilterToTilterNameMap[itemFilter];
      const current = filter[mappedFilterName];

      if (current instanceof Array && current.length > 0) {
        current.forEach((id) => {
          const filterName = mappedFilterName.replace(/(filter)/gi, '');
          const currentFilter = filterEntities[filterName][id];
          const label = currentFilter?.displayText ? currentFilter?.displayText : currentFilter?.name;

          chips.push(
            <Tag
              withFilterChip
              key={`${mappedFilterName}-${id}`}
              label={label}
              deleteHandler={() => resetDropdownFilter(mappedFilterName, currentFilter.id)}
            />
          );
        });
      }

      if (typeof current === 'string' && !!current) {
        chips.push(
          <Tag
            withFilterChip
            key={`${mappedFilterName}-${idx}`}
            label={current}
            deleteHandler={() => {
              const updated: any = {...filter};
              updated[mappedFilterName] = '';
              dispatch(actions.setFilterItems(updated));
            }}
          />
        );
      }
    });

    if (!!filter.searchByName) {
      const deleteSearchFilter = () => {
        const updated = {...filter, [ItemFilterNamesEnum.SEARCH_BY_NAME]: ''};
        dispatch(actions.setFilterItems(updated));
      };
      chips.push(
        <Tag
          withFilterChip
          key={`search-${filter.searchByName}`}
          label={filter.searchByName}
          deleteHandler={deleteSearchFilter}
        />
      );
    }

    return chips;
  }, [dispatch, filter, filterEntities]);

  const header = useMemo(
    () => (
      <PageViewHeader
        heading="Questions"
        action={
          <Fragment>
            <ButtonPrimaryWithAdminPermissions clickHandler={() => push(`${pages.adminPortal.items.create}`)}>
              New Item
            </ButtonPrimaryWithAdminPermissions>
          </Fragment>
        }
        sort={renderSortOrder}
        filters={appliedFilters}
      />
    ),
    [appliedFilters, push, renderSortOrder]
  );

  const content = useMemo(
    () => (
      <PageLayoutWithFixedAreas header={header} footer={contentFooter}>
        {pageLoading ? (
          <Loading />
        ) : !!rows.length ? (
          <Table rows={rows} columns={itemAllColumns} />
        ) : (
          'No results found'
        )}
      </PageLayoutWithFixedAreas>
    ),
    [rows, contentFooter, pageLoading, header]
  );

  return <PageLayoutTwoCol sidebar={<ItemsAllFilters />} content={content} />;
}

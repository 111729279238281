import {createSlice} from '@reduxjs/toolkit';
import {programClassDetailsInitialState} from './programClassDetails.initialState';
import {programClassDetailsReducer} from './programClassDetails.reducer';
import {programClassDetailsExtraReducer} from './programClassDetailsExtra.reducer';

export const programClassDetailsSlice = createSlice({
  name: 'programClassDetails',
  initialState: programClassDetailsInitialState,
  reducers: programClassDetailsReducer,
  extraReducers: programClassDetailsExtraReducer,
});

export const {actions: programClassDetailsActions} = programClassDetailsSlice;

import React from 'react';
import {FormNameEnum} from 'filters-selections/enums';
import {RangeSelect} from './RangeSelect';
import {useFormikContext} from 'formik';
import {BUTTON_NUM_LIST} from 'utils';

export const RangeToSelect = () => {
  const {values} = useFormikContext();
  const rangeFrom = values[FormNameEnum.rangeFrom];
  const rangeFromIdx = BUTTON_NUM_LIST.indexOf(Number(rangeFrom));
  const rangeToList = BUTTON_NUM_LIST.slice(rangeFromIdx + 1);

  return <RangeSelect name={FormNameEnum.rangeTo} label="To" disabled={false} list={rangeToList} />;
};

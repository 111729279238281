import React, {Fragment, useCallback, useContext} from 'react';
import {batch} from 'react-redux';
import {SessionListDragItem} from '..';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';
import {useLearningPathBuilderItemsEntities} from 'admin/resources/learning-paths/views/session-builder';
import {
  useSessionBuilderMode,
  useSessionBuilderStoreActions,
} from 'admin/resources/learning-paths/views/session-builder';
import {SessionBuilderEditModeEnum} from 'enums/learning-paths';
import {FormContext} from 'forms/formik/FormContextProvider';

export const SessionList = () => {
  const {builderSessionItemsResult, builderSessionItemsEntities} = useLearningPathBuilderItemsEntities();
  const {getSessionBuilderMode, setSessionBuilderMode} = useSessionBuilderMode();
  const {hasPermission} = usePermissions();
  const {collapseSessionBuilderItem, moveSessionItem} = useSessionBuilderStoreActions();
  const {setIsDirty} = useContext(FormContext);

  const hasAdminPermission = hasPermission(USAAndSuperAdminRoles);

  const moveSession = useCallback(
    (movedItemIdx: number, newPosition: number) => {
      return batch(() => {
        moveSessionItem(movedItemIdx, newPosition);
        setIsDirty(true);
      });
    },
    [moveSessionItem, setIsDirty]
  );

  return (
    <Fragment>
      {builderSessionItemsResult.length
        ? builderSessionItemsResult?.map((id: string, index: number) => {
            const item = builderSessionItemsEntities[id];
            const sessionBuilderMode = getSessionBuilderMode(item);
            const isNew = !item?.title?.length;
            const shouldExpand = item?.isAccordionExpanded;
            const sessionMode = isNew ? SessionBuilderEditModeEnum.Edit : sessionBuilderMode;

            return (
              <SessionListDragItem
                key={id}
                index={index}
                item={item}
                disabled={!hasAdminPermission}
                moveSession={moveSession}
                sessionMode={sessionMode}
                shouldExpand={shouldExpand}
                setSessionBuilderMode={setSessionBuilderMode}
                collapseSessionBuilderItem={collapseSessionBuilderItem}
              />
            );
          })
        : 'No Sessions'}
    </Fragment>
  );
};

import {SortingOrderEnum} from 'enums';
import {GoogleSitesDetailOrderingEnum} from '../../enums';

export const googleSiteDetailsOrderingMap = {
  [GoogleSitesDetailOrderingEnum.AreaName]: {
    [SortingOrderEnum.ASC]: 'Area Name: A-Z',
    [SortingOrderEnum.DESC]: 'Area Name: Z-A',
  },
  [GoogleSitesDetailOrderingEnum.LastCopiedDate]: {
    [SortingOrderEnum.ASC]: 'Last Copied Date: Asc',
    [SortingOrderEnum.DESC]: 'Last Copied Date: Desc',
  },
};

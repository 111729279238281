import {createAsyncThunk} from '@reduxjs/toolkit';
import {addIntegrationMessages} from 'admin/integrations/add-integration';
import {integrationsApi} from 'api';
import {ICreateMicrosoftTeamsVirtualClassPayload} from 'models/integrations';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const createMicrosoftTeamsThunk: any = createAsyncThunk(
  `${prefix} createMicrosoftTeamsThunk`,
  async (payload: ICreateMicrosoftTeamsVirtualClassPayload, {dispatch, rejectWithValue}) => {
    try {
      const {data} = await integrationsApi.createMicrosoftTeamsVirtualClass(payload);
      dispatch(toastNotificationManager.createSuccessToastAction(addIntegrationMessages.createIntegrationSuccess));
      return data;
    } catch (error) {
      dispatch(
        toastNotificationManager.createErrorToastAction(
          error.response.data.messages[0] ?? addIntegrationMessages.unknownIntegrationError
        )
      );
      return rejectWithValue({error});
    }
  }
);

import React, {FC, Fragment, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {SubtitlePageViewHeader, UploadSingleFile} from 'admin/components';
import {Wrapper} from 'components-lib';
import {FormNameEnum} from 'enums/form';
import {useAddIntegration} from '../../hooks';
import {heightThreeStepsActivity} from 'utils/layout';
import {IWithDisabled} from 'models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUploadCertificateProps extends IWithDisabled {}

export const UploadCertificate: FC<IUploadCertificateProps> = ({disabled = false}) => {
  const classes = useStyles();
  const {loading} = useAddIntegration();

  const subTitle = useMemo(
    () => (
      <SubtitlePageViewHeader withColor={false} as="h3" color="textPrimary">
        <Fragment>Upload certificate</Fragment>
      </SubtitlePageViewHeader>
    ),
    []
  );

  const content = useMemo(() => {
    return (
      <Fragment>
        {subTitle}
        <Wrapper className={classes.content}>
          <UploadSingleFile
            disabled={disabled}
            title="Upload Certificate"
            position="vertical"
            name={FormNameEnum.certificate}
            loading={loading}
          />
        </Wrapper>
      </Fragment>
    );
  }, [classes.content, subTitle, loading, disabled]);

  return <Fragment>{content}</Fragment>;
};

const useStyles = makeStyles(() => ({
  content: () => {
    return {
      height: heightThreeStepsActivity,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
  },
}));

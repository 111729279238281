import {createSlice} from '@reduxjs/toolkit';
import {programsAllInitialState} from './programsAll.initialState';
import {programsAllReducer} from './programsAll.reducer';
import {programsAllExtraReducer} from './programsAllExtra.reducer';

export const programsAllSlice = createSlice({
  name: 'programs',
  initialState: programsAllInitialState,
  reducers: programsAllReducer,
  extraReducers: programsAllExtraReducer,
});

export const {actions: programsAllActions} = programsAllSlice;

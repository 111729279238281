import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {ITemplateRolloverDto} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const rolloverTemplatesThunk: any = createAsyncThunk<any, ITemplateRolloverDto>(
  `${prefix} rolloverTemplates`,
  async (templateRolloverData: ITemplateRolloverDto, {dispatch}) => {
    try {
      const {parsedBody} = await instrumentsApi.postRolloverTemplates(templateRolloverData);
      dispatch(toastNotificationManager.createSuccessToastAction('The instrument rollover completed successfully.'));
      return parsedBody;
    } catch (err) {
      throw new Error(err.parsedBody);
    }
  }
);

import {fetchClassPaperAssessmentsPagedThunk, uploadPaperAssessmentsThunk} from './thunks';
import {IProgramClassesPaperAssessmentsState} from './IProgramClassesPaperAssessmentsState';
import {IActionFulfilled, IGetPageOfPaperAssessmentsResponse} from 'models';
import {isObject} from 'utils/functions';
import {normalize} from 'normalizr';
import {paperAssessmentListSchema} from './normalizationSchemas';
import {EntitiesPerPageEnum} from 'enums';

export const programClassesPaperAssessmentsExtraReducer = {
  //uploadPaperAssessmentsThunk
  [uploadPaperAssessmentsThunk.fulfilled]: (state: IProgramClassesPaperAssessmentsState) => {
    state.upload.loading = false;
  },
  [uploadPaperAssessmentsThunk.pending]: (state: IProgramClassesPaperAssessmentsState) => {
    state.upload.loading = true;
  },
  [uploadPaperAssessmentsThunk.rejected]: (state: IProgramClassesPaperAssessmentsState) => {
    state.upload.loading = false;
  },

  //fetchClassPaperAssessmentsPagedThunk
  [fetchClassPaperAssessmentsPagedThunk.fulfilled]: (
    state: IProgramClassesPaperAssessmentsState,
    action: IActionFulfilled<null, IGetPageOfPaperAssessmentsResponse>
  ) => {
    if (isObject(action.payload)) {
      const {
        data,
        metadata: {totalCount, pageNumber, pageSize},
      } = action.payload;

      state.paperAssessmentList = normalize(data, paperAssessmentListSchema);
      state.pagination.totalCount = totalCount;
      state.pagination.currentPage = pageNumber;
      state.pagination.perPage =
        pageSize < EntitiesPerPageEnum.TWENTY_FIVE ? EntitiesPerPageEnum.TWENTY_FIVE : pageSize;
    }

    state.page.loading = false;
  },
  [fetchClassPaperAssessmentsPagedThunk.pending]: (state: IProgramClassesPaperAssessmentsState) => {
    state.page.loading = true;
  },
  [fetchClassPaperAssessmentsPagedThunk.rejected]: (state: IProgramClassesPaperAssessmentsState) => {
    state.page.loading = false;
  },
};

import {ILearningPathsEditStoreState} from './ILearningPathsEditStoreState';
import {
  deleteLearningPathThunk,
  exportLearningPathThunk,
  fetchLearningPathByIdThunk,
  publishLearningPathThunk,
  updateLearningPathThunk,
} from './thunks';
import {IActionFulfilled, IGetLearningPathByIdApiResponse} from 'models';
import {isObject} from 'utils/functions';

export const learningPathEditExtraReducer = {
  // fetchLearningPathByIdThunk
  [fetchLearningPathByIdThunk.fulfilled]: (
    state: ILearningPathsEditStoreState,
    action: IActionFulfilled<null, IGetLearningPathByIdApiResponse>
  ) => {
    state.page.loading = false;
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.learningPath = {...data};
    }
  },
  [fetchLearningPathByIdThunk.pending]: (state: ILearningPathsEditStoreState) => {
    state.page.loading = true;
  },
  [fetchLearningPathByIdThunk.rejected]: (state: ILearningPathsEditStoreState) => {
    state.page.loading = false;
  },

  // updateLearningPathThunk
  [updateLearningPathThunk.fulfilled]: (
    state: ILearningPathsEditStoreState,
    action: IActionFulfilled<null, IGetLearningPathByIdApiResponse>
  ) => {
    state.page.loading = false;
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.learningPath = {...data};
    }
  },
  [updateLearningPathThunk.pending]: (state: ILearningPathsEditStoreState) => {
    state.page.loading = true;
  },
  [updateLearningPathThunk.rejected]: (state: ILearningPathsEditStoreState) => {
    state.page.loading = false;
  },

  // deleteLearningPathThunk
  [deleteLearningPathThunk.fulfilled]: (state: ILearningPathsEditStoreState) => {
    state.page.loading = false;
  },
  [deleteLearningPathThunk.pending]: (state: ILearningPathsEditStoreState) => {
    state.page.loading = true;
  },
  [deleteLearningPathThunk.rejected]: (state: ILearningPathsEditStoreState) => {
    state.page.loading = false;
  },

  // publishLearningPathThunk
  [publishLearningPathThunk.fulfilled]: (state: ILearningPathsEditStoreState) => {
    state.page.loading = false;
  },
  [publishLearningPathThunk.pending]: (state: ILearningPathsEditStoreState) => {
    state.page.loading = true;
  },
  [publishLearningPathThunk.rejected]: (state: ILearningPathsEditStoreState) => {
    state.page.loading = false;
  },

  // exportLearningPathThunk
  [exportLearningPathThunk.fulfilled]: (state: ILearningPathsEditStoreState) => {
    state.page.exporting = false;
  },
  [exportLearningPathThunk.pending]: (state: ILearningPathsEditStoreState) => {
    state.page.exporting = true;
  },
  [exportLearningPathThunk.rejected]: (state: ILearningPathsEditStoreState) => {
    state.page.exporting = false;
  },
};

import {IUploadScormFormBaseValues, mapUploadResourceMetadata} from 'admin/resources';

export const mapUploadResourceScorm = (values: IUploadScormFormBaseValues) => {
  const data = new FormData();

  const metadata = {
    ...mapUploadResourceMetadata(values),
    lengthInMinutes: values.time,
  };

  data.append('file', values.file);
  data.append('displayName', values.displayName);
  data.append('briefDescription', values.briefDescription);
  data.append('metadata', JSON.stringify(metadata));

  return data;
};

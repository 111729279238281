import {makeStyles} from '@material-ui/core';
import {Button, Icon, Text} from 'components-lib';
import React, {SyntheticEvent} from 'react';
import {errorMessages, isString} from 'utils';
import {ToastNotificationTypeEnum} from '../../enums';
import {ToastIcon} from '../ToastIcon/ToastIcon';

interface INotificationProps {
  type: ToastNotificationTypeEnum;
  message: string;
  closeHandler: (ev: SyntheticEvent) => void;
}

export function Notification({type, message, closeHandler}: INotificationProps) {
  const classes = useStyles();
  return (
    <div className={classes.root} title={message}>
      <div className={classes.iconContainer}>
        <ToastIcon type={type} />
      </div>
      <Text.Paragraph>{!isString(message) ? errorMessages.defaultErrorMessage : sliceMessage(message)}</Text.Paragraph>
      <div className={classes.closeIcon}>
        <Button.Icon clickHandler={closeHandler}>
          <Icon.CloseIcon />
        </Button.Icon>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'auto',
    minWidth: 320,
    maxWidth: 420,
    maxHeight: 66,
    marginBottom: theme.spacing(2),
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.common.white,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 66,
    height: 66,
  },
  closeIcon: {
    marginLeft: 'auto',
    marginRight: theme.spacing(3),
  },
}));

const maxChar = 80;

function sliceMessage(message: string) {
  const sliced = message.slice(0, maxChar);
  const shouldRenderDots = message.length > maxChar;
  return `${sliced} ${shouldRenderDots ? '...' : ''}`;
}

export enum IResourceContentTypeNameEnum {
  ScormResource = 'SCORM Package',
  FileResource = 'File',
  WebLinkResource = 'Web Link',
  Image = 'Image',
  Video = 'Video',
  Audio = 'Audio',
  Document = 'Document',
  Pdf = 'Pdf',
  FillablePdf = 'Fillable Pdf',
}

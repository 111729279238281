import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch} from 'hooks';
import {uploadScormPageLoadingSelector, uploadScormThunk} from 'admin';

export const useUploadScorm = () => {
  const dispatch = useAppDispatch();
  const loading = useSelector(uploadScormPageLoadingSelector) as boolean;
  const uploadScormPackage = useCallback((payload: FormData) => dispatch(uploadScormThunk(payload)), [dispatch]);

  return {loading, uploadScormPackage};
};

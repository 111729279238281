import React, {FC} from 'react';
import {IWithDisabled} from 'models';
import {FutureProgramYearSelect} from '../FutureProgramYear/FutureProgramYearSelect';
import {FormNameEnum} from 'filters-selections';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IFutureProgramYearSingleSelect extends IWithDisabled {}

export const FutureProgramYearSingleSelect: FC<IFutureProgramYearSingleSelect> = ({disabled}) => {
  return <FutureProgramYearSelect selectionType="single" disabled={disabled} name={FormNameEnum.futureProgramYear} />;
};

import React, {Fragment, useCallback} from 'react';
import {IQuestionAnswerStack, IWithModal} from 'models';
import {Accordion, Button, Modal, Row} from 'components-lib';
import {useFormikContext} from 'formik';
import {borderGeneral} from 'styles';

import {Form} from 'forms';
import {CardQuestionSimple} from '..';
import {makeStyles} from '@material-ui/core';
import {AnswersView} from 'admin/library/components/Answers/AnswersView';
import {FormNameEnum} from '../../enums';
import {ZERO_NUMBER} from 'utils/constants';
import {usePretestItem} from 'admin/library/instruments/hooks';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IModalLinkItemsProps extends IWithModal {
  unlinkHandler?: () => void;
}

export const ModalLinkItems = ({isOpen, setIsOpen, unlinkHandler}: IModalLinkItemsProps) => {
  const {possiblePretestItems} = usePretestItem();
  const {values, resetForm} = useFormikContext();
  const classes = useStyles();

  const stackGroup = values[FormNameEnum.linkedQuestionAnswerStackId];
  const stackGroupLength = stackGroup?.length;
  const isStackGroupLengthNotEmpty = stackGroupLength > ZERO_NUMBER;
  const selectedStack = stackGroup[ZERO_NUMBER];

  const closeHandler = useCallback(() => {
    resetForm();
    setIsOpen(false);
  }, [resetForm, setIsOpen]);

  const handleSelectElement = useCallback((item, selectedStack) => {
    const selectedItem = item.questionStackChoices.find(
      (stack: IQuestionAnswerStack) => stack.questionAnswerStackId === selectedStack
    );
    const isChecked = selectedStack === selectedItem?.questionAnswerStackId;

    return {
      selectedItem,
      isChecked,
    };
  }, []);

  return (
    <Modal isOpen={isOpen} handleClose={closeHandler} title="Link Pre- and Post item" size="large">
      {possiblePretestItems?.length ? (
        possiblePretestItems.map((item) => {
          const {isChecked} = handleSelectElement(item, selectedStack);
          return (
            <Accordion
              key={item?.id}
              heading={<CardQuestionSimple type={item.questionType} text={item.questionText} />}
              className={classes.root}
              initialIsExpanded={isStackGroupLengthNotEmpty && isChecked}
            >
              <Row justify="flex-start">
                {item.questionStackChoices.map((stack: IQuestionAnswerStack, idx: number) => (
                  <div className={classes.answerStackContainer} key={`choice-${idx}-${stack.questionAnswerStackId}`}>
                    <Form.Checkbox
                      name={FormNameEnum.linkedQuestionAnswerStackId}
                      disabled={isStackGroupLengthNotEmpty && !isChecked}
                      value={stack?.questionAnswerStackId}
                    />
                    <div className={classes.answersContainer}>
                      <AnswersView answers={stack.choices} />
                    </div>
                  </div>
                ))}
              </Row>
            </Accordion>
          );
        })
      ) : (
        <Fragment>No link items loaded.</Fragment>
      )}

      <Fragment>
        <Button.Secondary clickHandler={unlinkHandler} size="small">
          Unlink
        </Button.Secondary>
        <Button.Secondary clickHandler={closeHandler} size="small">
          Cancel
        </Button.Secondary>
        <Form.ButtonSubmit text="Apply" size="small" />
      </Fragment>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    ...borderGeneral,
    padding: theme.spacing(2),
  },
  answerStackContainer: {
    width: '100%',
    margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(3)}px ${theme.spacing(0)}px`,
    display: 'flex',
    justifyContent: 'space-between',
  },
  answersContainer: {
    width: '100%',
    padding: theme.spacing(3),
    margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(2)}px`,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 4,
  },
}));

import React, {Fragment} from 'react';
import {useFormikContext} from 'formik';
import {IAnswerStack} from 'models';
import {Form} from 'forms';
import {FormNameEnum} from 'filters-selections';
import {ItemAnswerStack} from '..';
import {Loading, Text} from 'components-lib';
import {IModalSelectAnswerStackFormValues} from '../ModalSelectAnswerStack/modalSelectAnswerStackValidationSchema';

const isSelectedProperty = 'isSelected';
const validationName = 'customValidation';

const getIsSomeAnswerStackChecked = (answerStacks: IAnswerStack[]) =>
  answerStacks.some((el: IAnswerStack) => el.hasOwnProperty(isSelectedProperty) && el.isSelected === true);

export const DefaultAnswerStacks = () => {
  const {values} = useFormikContext<IModalSelectAnswerStackFormValues>();
  const answerStacks = values[FormNameEnum.answerStacks];

  return (
    <Form.Array name={FormNameEnum.answerStacks}>
      {(props) => {
        const error = props.form.errors[validationName];

        return (
          <Fragment>
            <Fragment>{error && <Text.Error>{error}</Text.Error>}</Fragment>
            {answerStacks.length ? (
              answerStacks?.map((answerStack: IAnswerStack, idx: number) => {
                const isCheckedSomeAnswerStack = getIsSomeAnswerStackChecked(answerStacks);
                const isSelectedPropertyDoNotExists = !answerStacks[idx].hasOwnProperty(isSelectedProperty);
                const isNotSelected = answerStacks[idx].isSelected === false;
                const isNotSelectedStack = isSelectedPropertyDoNotExists || isNotSelected;

                return (
                  <Fragment key={`${idx}-${answerStack.answerTypeName}`}>
                    <ItemAnswerStack
                      idx={idx}
                      answerStack={answerStack}
                      disabled={isCheckedSomeAnswerStack && isNotSelectedStack}
                    />
                  </Fragment>
                );
              })
            ) : (
              <Loading size={20} />
            )}
          </Fragment>
        );
      }}
    </Form.Array>
  );
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {pamApi} from 'api';
import {pages} from 'paths';
import {IGetFileByIdApiResponse, IGetFileByIdPayload} from 'models/resources';
import {toastNotificationManager} from 'toast-notifications';
import {redirectWhenErrorStatusCode} from 'utils';
import {prefix} from './config';
import {ErrorStatusCodesEnum} from 'enums';

export const fetchFileByIdThunk: any = createAsyncThunk<IGetFileByIdApiResponse, IGetFileByIdPayload>(
  `${prefix} fetchFileById`,
  async ({id, push}, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await pamApi.getFileById(id);
      return parsedBody;
    } catch (error) {
      if (error.status === ErrorStatusCodesEnum.NotFound) {
        redirectWhenErrorStatusCode(error.status, pages.fileNotFound.root, push);
      } else {
        dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      }
      return rejectWithValue({error});
    }
  }
);

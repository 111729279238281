import {FormNameEnum} from 'filters-selections';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {
  fetchSkillsAndBehaviorsThunk,
  filtersSkillsAndBehaviorsEntitiesCollectionSelector,
} from 'filters-selections/store';
import {Form} from 'forms';
import {useAppDispatch} from 'hooks';
import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

export function SkillsAndBehaviorsSelect() {
  const dispatch = useAppDispatch();
  const skillsAndBehaviors = useSelector(filtersSkillsAndBehaviorsEntitiesCollectionSelector);

  useEffect(() => {
    if (!skillsAndBehaviors.length) {
      dispatch(fetchSkillsAndBehaviorsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const options = useMemo(
    () =>
      skillsAndBehaviors.map((skillsAndBehaviors) => ({
        value: skillsAndBehaviors.id,
        label: skillsAndBehaviors.name,
      })),
    [skillsAndBehaviors]
  );

  return (
    <ItemWithPlaceholder label="Skills and Behaviors" hasItems={!!skillsAndBehaviors.length}>
      <Form.SelectAutocomplete name={FormNameEnum.skillsAndBehaviors} options={options} multiple />
    </ItemWithPlaceholder>
  );
}

import React, {FC, ReactNode, useMemo} from 'react';
import {IconButton, makeStyles} from '@material-ui/core';
import {CopyToClipboardToolTip, Icon} from 'components-lib';
import {IWithDisabled} from 'models';

interface IButtonCopyLinkProps extends IWithDisabled {
  text: string;
  title?: string;
  icon?: ReactNode;
}

export const ButtonCopyLink: FC<IButtonCopyLinkProps> = ({text, icon, disabled = false, title}) => {
  const classes = useStyles();

  const defaultIcon = useMemo(
    () => (
      <IconButton classes={{disabled: classes.disabledFilter}} aria-label="link" disabled={disabled}>
        <Icon.CopyLinkIcon />
      </IconButton>
    ),
    [classes.disabledFilter, disabled]
  );

  return (
    <CopyToClipboardToolTip title={title} text={text}>
      {icon ? icon : defaultIcon}
    </CopyToClipboardToolTip>
  );
};

export const useStyles = makeStyles(() => ({
  disabledFilter: {
    filter: `grayscale(1)`,
  },
}));

export const colors = {
  primary: '#285f74',
  primary20: '#c3edef',
  primary40: '#99d9df',
  primary60: '#00a0af',
  primary80: '#285f74',
  primary100: '#22404d',

  secondary: '#00a0af',
  secondary20: '#c3edef',
  secondary40: '#99d9df',
  secondary60: '#00C0CA',
  secondary80: '#00a0af',
  secondary100: '#28708b',

  error: '#FF3B46',
  success: '#1DB46C',
  info: '#4292D7',
  warning: '#FDB614',

  white: '#FFFFFF',
  gray: '#F9F9F9',
  gray00: '#F5F8FA',
  gray05: '#E5E9ED',
  gray10: '#D5DAE0',
  gray20: '#C6CCD3',
  gray40: '#A7AFB9',
  gray60: '#697586',
  gray80: '#464E59',
  gray100: '#171A1D',
};

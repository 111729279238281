import {createAsyncThunk} from '@reduxjs/toolkit';
import {uploadResourcesApi} from 'api';
import {IUploadScormApiResponse, IUploadScormPayload} from 'models/resources';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const uploadScormThunk: any = createAsyncThunk<IUploadScormApiResponse, IUploadScormPayload>(
  `${prefix} uploadScorm`,
  async (payload: IUploadScormPayload, {dispatch, rejectWithValue}) => {
    try {
      const response = await uploadResourcesApi.uploadScorm(payload);
      dispatch(toastNotificationManager.createSuccessToastAction('Scorm package was uploaded successfully'));
      return response.data;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.messages));
      return rejectWithValue({error});
    }
  }
);

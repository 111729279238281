import {EntitiesPerPageEnum, SortingOrderEnum} from 'enums';
import {IFilesAllFilter} from 'models/resources/files';
import {FilesAllFilterNamesEnum, FilesOrderingAllEnum} from '../enums';
import {IFilesAllStoreState} from './IFilesAllStoreState';

const filesAllpaginationDefault = {
  currentPage: 1,
  totalCount: 0,
  perPage: EntitiesPerPageEnum.FIFTY,
};

const filesAllDefault = {
  result: [],
  entities: {
    items: {},
  },
};

const filterDefault: IFilesAllFilter = {
  [FilesAllFilterNamesEnum.SEARCH_BY_QUERY]: '',
  [FilesAllFilterNamesEnum.FILE_TYPES]: [],
  [FilesAllFilterNamesEnum.PROGRAMS]: [],
  [FilesAllFilterNamesEnum.GRADE_LEVELS]: [],
  [FilesAllFilterNamesEnum.PILLARS]: [],
  [FilesAllFilterNamesEnum.COMPETENCIES]: [],
  [FilesAllFilterNamesEnum.KSAS]: [],
  [FilesAllFilterNamesEnum.LANGUAGES]: [],
  [FilesAllFilterNamesEnum.FILE_STATUSES]: [],
  [FilesAllFilterNamesEnum.IS_ACCESSIBLE_TO_STUDENTS]: null,
  [FilesAllFilterNamesEnum.PUBLISHED_BY]: null,
};

export const filesAllInitialState: IFilesAllStoreState = {
  page: {
    loading: false,
  },
  pagination: filesAllpaginationDefault,
  sortOrder: {
    order: FilesOrderingAllEnum.Created,
    sort: SortingOrderEnum.DESC,
  },
  filter: filterDefault,
  file: null,
  fileDownload: {
    id: null,
    downloading: false,
  },
  files: filesAllDefault,
};

import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Container} from 'components-lib';
import {Subtitle} from 'admin/components';
import {SessionDetailsEdit, SessionDetailsPreview} from '..';
import {ILearningPathSessionItem} from 'models/resources';

interface ISessionResourcesDetailsProps {
  isEditMode: boolean;
  item: ILearningPathSessionItem;
}

export const SessionDetails: FC<ISessionResourcesDetailsProps> = ({isEditMode, item}) => {
  const classes = useStyles();

  return (
    <Container>
      <Subtitle withColor={false} as="h3" color="textPrimary" title="Details" />
      <Container disableGutters classes={{root: classes.root}}>
        {isEditMode ? <SessionDetailsEdit /> : <SessionDetailsPreview item={item} />}
      </Container>
    </Container>
  );
};

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
  },
}));

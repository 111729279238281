import React, {FC, useCallback} from 'react';
import {Container, Button, Row, Column} from 'components-lib';
import {Subtitle} from 'admin/components';
import {AddSessionInstruments, AddSessionResources, SessionInstrumentListEdit, SessionResourceListEdit} from '..';
import {ILearningPathSessionItem} from 'models/resources';
import {useDrawer} from 'hooks';

interface IAdditionalResourcesListProps {
  item: ILearningPathSessionItem;
  title: string;
  isEditMode: boolean;
  isResourceList: boolean;
  openDrawerButtonText: string;
}
export const AdditionalResourcesList: FC<IAdditionalResourcesListProps> = ({
  item,
  title,
  openDrawerButtonText,
  isEditMode,
  isResourceList,
}) => {
  const {isOpen: isAddResourcesDrawerOpen, setIsOpen: setIsAddResourcesDrawerOpen} = useDrawer();
  const {isOpen: isAddInstrumentsDrawerOpen, setIsOpen: setIsAddInstrumentsDrawerOpen} = useDrawer();

  const openDrawerResourcesClickHandler = useCallback(() => setIsAddResourcesDrawerOpen(true), [
    setIsAddResourcesDrawerOpen,
  ]);

  const openDrawerInstrumentsClickHandler = useCallback(() => setIsAddInstrumentsDrawerOpen(true), [
    setIsAddInstrumentsDrawerOpen,
  ]);

  return (
    <Container>
      <Subtitle withColor={false} as="h3" color="textPrimary" title={title} />
      <Row justify="flex-start">
        <Column sm={12} md={12} lg={12}>
          {isResourceList ? (
            <SessionResourceListEdit item={item} isEditMode={isEditMode} isResource={isResourceList} />
          ) : (
            <SessionInstrumentListEdit item={item} isEditMode={isEditMode} />
          )}
        </Column>
        {isEditMode && (
          <Button.Secondary
            isFirst
            size="small"
            clickHandler={isResourceList ? openDrawerResourcesClickHandler : openDrawerInstrumentsClickHandler}
          >
            {openDrawerButtonText}
          </Button.Secondary>
        )}
        {isResourceList ? (
          <AddSessionResources
            id={item?.id}
            isOpen={isAddResourcesDrawerOpen}
            setIsOpen={setIsAddResourcesDrawerOpen}
          />
        ) : (
          <AddSessionInstruments
            id={item?.id}
            isOpen={isAddInstrumentsDrawerOpen}
            setIsOpen={setIsAddInstrumentsDrawerOpen}
          />
        )}
      </Row>
    </Container>
  );
};

import React, {FC, Fragment} from 'react';
import {makeStyles} from '@material-ui/core';
import {Subtitle} from 'admin';
import {Column, Container, Row} from 'components-lib';
import {IWithChildren} from 'models';
interface IResourceDetailsProps extends IWithChildren {
  withSubtitle?: boolean;
  withBorderBottom?: boolean;
}

export const ResourceDetails: FC<IResourceDetailsProps> = ({
  children,
  withSubtitle = true,
  withBorderBottom = true,
}) => {
  const classes = useStyles({withBorderBottom});

  return (
    <Fragment>
      {withSubtitle && <Subtitle withColor={false} as="h3" color="textPrimary" title="Details" />}
      <Container disableGutters classes={{root: classes.detailsContent}}>
        <Row align="flex-start" justify="flex-start">
          <Column sm={8} md={8} lg={8} xl={9}>
            <Row align="flex-start" justify="flex-start">
              {children}
            </Row>
          </Column>
          <Column sm={4} md={4} lg={4} xl={3} />
        </Row>
      </Container>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  detailsContent: ({withBorderBottom}: Pick<IResourceDetailsProps, 'withBorderBottom'>) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
    borderBottom: withBorderBottom ? `1px solid ${theme.palette.grey[200]}` : 'none',
  }),
}));

import React, {useEffect} from 'react';
import {useFormikContext} from 'formik';
import {AreaSingleSelectBasedGoogleSites} from './AreaSingleSelectBasedGoogleSites';
import {IAddIntegrationFormBaseValues} from 'models/integrations';
import {FormNameEnum} from 'filters-selections';

export const AreaSingleBasedOnDistrictAndGoogleSites = () => {
  const {setFieldValue, values} = useFormikContext<IAddIntegrationFormBaseValues>();
  const shouldDisableAreas = !!values.schoolDistrict;

  useEffect(() => {
    if (values.area) {
      setFieldValue(FormNameEnum.schoolDistrict, null);
    }
  }, [values.area, setFieldValue]);

  return <AreaSingleSelectBasedGoogleSites disabled={shouldDisableAreas} />;
};

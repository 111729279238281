import {IItemsImport, IItemsImportMapping, IItemsImportPage, IItemsImportStoreState} from 'admin';
import {IItemBankPreview} from 'models/library/items-import';

const itemsImportDefault = {} as IItemsImport;

export const itemsImportMappingDefault = {
  fieldList: [],
  fileName: null,
  hasRequiredFieldErrors: false,
  itemImportId: null,
} as IItemsImportMapping;

export const itemsImportPageDefault = {
  loading: false,
  saving: false,
  downloading: false,
  uploading: false,
  importing: false,
} as IItemsImportPage;

export const itemsImportPreviewDefault = {
  loading: false,
  criticalIssueCount: 0,
  downloadingCriticalIssues: false,
  previewRows: [],
} as IItemBankPreview;

export const itemsImportInitialState: IItemsImportStoreState = {
  itemsImport: itemsImportDefault,
  templateInstructionalText: null,
  mapping: itemsImportMappingDefault,
  page: itemsImportPageDefault,
  preview: itemsImportPreviewDefault,
};

import React, {useEffect} from 'react';
import {List} from '@material-ui/core';
import {useFormikContext} from 'formik';
import {Column, Row} from 'components-lib';
import {IAddSessionInstrumentsFormValues} from '../AddSessionInstrumentsForm/config';
import {FormNameEnum} from 'enums/form';
import {uniq} from 'utils/functions';
import {getInstrumentsCollectionByIds} from 'admin/resources/learning-paths/views/create';
import {useLearningPathBuilderItemsEntities} from 'admin/resources/learning-paths/views/session-builder';
import {AddInstrumentsDrawerListItem} from '..';

export const AddInstrumentsDrawerList = () => {
  const {
    values: {instruments, selectedInstruments, selectedInstrumentsIds},
    setFieldValue,
  } = useFormikContext<IAddSessionInstrumentsFormValues>();
  const {instrumentsConcatenatedBaseEntitiesCollection} = useLearningPathBuilderItemsEntities();

  useEffect(() => {
    if (instruments.length > 0) {
      const uniqueCollectionIds = uniq([...instruments, ...selectedInstrumentsIds]) as number[];

      const filteredInstruments = getInstrumentsCollectionByIds(
        uniqueCollectionIds,
        instrumentsConcatenatedBaseEntitiesCollection
      );

      setFieldValue(FormNameEnum.selectedInstrumentsIds, uniqueCollectionIds);
      setFieldValue(FormNameEnum.selectedInstruments, filteredInstruments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instruments, instrumentsConcatenatedBaseEntitiesCollection]);

  return (
    <Row justify="center">
      <Column>
        <List>
          {selectedInstruments?.length > 0 &&
            selectedInstruments.map((item) => (
              <AddInstrumentsDrawerListItem key={item?.id} id={item?.id} name={item?.name} />
            ))}
        </List>
      </Column>
    </Row>
  );
};

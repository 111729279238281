import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {IFetchPoolItemsPayload, IGetItemsApiResponse} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {isFunction} from 'utils';
import {prefix} from './config';

export const fetchPoolItemsThunk: any = createAsyncThunk<IGetItemsApiResponse, IFetchPoolItemsPayload>(
  `${prefix} fetchPoolItems`,
  async ({pageNumber, builderFilter, setSubmitting}, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await instrumentsApi.getItems(pageNumber, builderFilter);
      isFunction(setSubmitting) && setSubmitting(false);
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      isFunction(setSubmitting) && setSubmitting(false);
      return rejectWithValue({error: err});
    }
  }
);

import {useSelector} from 'react-redux';
import {builderPoolItemsEntitiesSelector} from '../../store';

export function useBuilderPoolItemsEntities() {
  const builderPoolItemsEntities = useSelector(builderPoolItemsEntitiesSelector);

  return {
    builderPoolItemsEntities,
  };
}

import {useSelector} from 'react-redux';
import {
  poolSelector,
  fetchProgramClassInstrumentsThunk,
  pageLoadingSelector,
  filterSelector,
  paginationSelector,
  programClassInstrumentsSortOrderSelector,
} from '../store';
import {useCallback} from 'react';
import {useAppDispatch} from 'hooks';

import {programClassInstrumentsActions as actions} from '../store/programClassInstruments.slice';
import {IProgramClassInstrumentsFilter, IProgramClassInstrumentsSortOrder} from 'models';
import {filtersProgramClassInstrumentsEntitiesSelector} from 'filters-selections';
import {getIsAscSortOrder, getSortingOptions} from 'utils';

export function useProgramClassInstruments(classId: number) {
  const dispatch = useAppDispatch();
  const loading = useSelector(pageLoadingSelector);
  const instruments = useSelector(poolSelector);
  const filter = useSelector(filterSelector);
  const filterEntities = useSelector(filtersProgramClassInstrumentsEntitiesSelector);
  const sortOrder = useSelector(programClassInstrumentsSortOrderSelector);

  const {currentPage, perPage, totalCount} = useSelector(paginationSelector);

  const setCurrentPage = useCallback((newPage: number) => dispatch(actions.setCurrentPage(newPage)), [dispatch]);
  const setPerPage = useCallback((perPage: number) => dispatch(actions.setPerPage(perPage)), [dispatch]);

  const fetchClassInstruments = useCallback(() => {
    const sortAsc = getIsAscSortOrder(sortOrder?.sort);
    const sortOptions = getSortingOptions(sortOrder?.order, sortAsc);

    return dispatch(
      fetchProgramClassInstrumentsThunk({
        filter,
        classId,
        sortOptions,
        pagination: {
          pageNumber: currentPage,
          pageSize: perPage,
        },
      })
    );
  }, [dispatch, filter, classId, currentPage, perPage, sortOrder.sort, sortOrder.order]);

  const setFilter = useCallback((filter: IProgramClassInstrumentsFilter) => dispatch(actions.setFilter(filter)), [
    dispatch,
  ]);
  const resetFilter = useCallback(() => dispatch(actions.resetFilter()), [dispatch]);
  const setSortOrder = useCallback(
    (sortOrder: IProgramClassInstrumentsSortOrder) => dispatch(actions.setSortOrder(sortOrder)),
    [dispatch]
  );

  return {
    loading,
    filter,
    instruments,
    filterEntities,
    fetchClassInstruments,
    setFilter,
    resetFilter,
    setSortOrder,
    setCurrentPage,
    setPerPage,
    totalCount,
    perPage,
    currentPage,
  };
}

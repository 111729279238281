import React, {Fragment, useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Button, Column, Icon, Modal, Row, Text, Wrapper} from 'components-lib';
import {IWithModal} from 'models';
import {Form} from 'forms';
import {splitStringByNewLine} from 'utils/common';

interface IModalUpdateProps extends IWithModal {
  title?: string;
  text: string;
  loading?: boolean;
  withNewLineText?: boolean;
  withSubmitButton?: boolean;
  confirmHandler: () => void;
}

export function ModalUpdate({
  isOpen,
  setIsOpen,
  confirmHandler,
  text,
  title = 'Update Item',
  loading,
  withNewLineText,
  withSubmitButton,
}: IModalUpdateProps) {
  const classes = useStyles();
  const confirmButtonText = loading ? 'Loading...' : 'Update';
  const splittedText = splitStringByNewLine(text);

  const newLineText = useMemo(
    () =>
      splittedText.map((paragraph: string, idx: number) => (
        <Text.Paragraph key={`paragraph-${idx}`}>{paragraph}</Text.Paragraph>
      )),
    [splittedText]
  );

  const closeHandler = useCallback(() => setIsOpen(false), [setIsOpen]);

  const confirmActionHandler = useCallback(() => {
    confirmHandler();
    setIsOpen(false);
  }, [confirmHandler, setIsOpen]);

  return (
    <Modal isOpen={isOpen} handleClose={closeHandler} title={title} size="medium">
      <Column>
        <Row align="center" justify="center">
          <Icon.SettingsIcon className={classes.icon} />
          <Fragment>{withNewLineText ? newLineText : <Text.Paragraph>{text}</Text.Paragraph>}</Fragment>
        </Row>
      </Column>
      <Wrapper>
        <Button.Secondary clickHandler={closeHandler} size="small">
          Cancel
        </Button.Secondary>
        {withSubmitButton ? (
          <Form.ButtonSubmit text={confirmButtonText} size="small" />
        ) : (
          <Button.Primary size="small" clickHandler={confirmActionHandler}>
            {confirmButtonText}
          </Button.Primary>
        )}
      </Wrapper>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginBottom: theme.spacing(4),
  },
}));

import React, {ChangeEvent, FC, ReactNode} from 'react';
import {Button, FileInput, Icon, Link, Wrapper, Text} from 'components-lib';
import {makeStyles} from '@material-ui/core';
import {IInputFile, IWithDisabled} from 'models';

interface IVerticalFileUploaderProps extends IWithDisabled {
  attachmentArea: ReactNode;
  fileInputName: string;
  accept: string;
  file: IInputFile;
  fileInputRef?: any;
  error: string;
  downloadLinkTitle?: string;
  downloadCallback?: () => void;
  addFileCallback?: (event: ChangeEvent<HTMLInputElement>) => void;
  openFileDialogClickHandler?: () => void;
}

export const Vertical: FC<IVerticalFileUploaderProps> = ({
  disabled,
  downloadLinkTitle = 'Download Template',
  attachmentArea,
  fileInputName,
  accept,
  file,
  fileInputRef,
  error,
  downloadCallback,
  addFileCallback,
  openFileDialogClickHandler,
}) => {
  const classes = useStyles();
  const shouldRenderLink = downloadLinkTitle && downloadCallback;

  return (
    <Wrapper>
      {file && <Wrapper>{attachmentArea}</Wrapper>}
      {!file && (
        <Wrapper className={classes.droppableArea}>
          <Icon.FolderIcon disabled={disabled} />
          <Wrapper className={classes.text}>Drag and drop a file here or</Wrapper>
          <Wrapper className={classes.buttonContainer}>
            <FileInput name={fileInputName} inputRef={fileInputRef} accept={accept} clickHandler={addFileCallback} />
            <Button.Secondary disabled={disabled} clickHandler={openFileDialogClickHandler}>
              Browse
            </Button.Secondary>
            {error && <Text.Error>{error}</Text.Error>}
          </Wrapper>
          {shouldRenderLink && <Link clickHandler={downloadCallback}>{downloadLinkTitle}</Link>}
        </Wrapper>
      )}
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  droppableArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: `${theme.spacing(0)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  text: {
    padding: theme.spacing(1),
  },
}));

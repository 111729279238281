import React, {FC} from 'react';
import {FormNameEnum} from 'filters-selections';
import {TextInputSelection} from '../TextInputSelection/TextInputSelection';
import {IWithDisabled} from 'models/common';

type ILearningPathNameTextInputProps = IWithDisabled;

export const LearningPathNameTextInput: FC<ILearningPathNameTextInputProps> = ({disabled}) => (
  <TextInputSelection name={FormNameEnum.learningPathName} label="Name" disabled={disabled} autoFocus />
);

import {FiltersEnum} from 'filters-selections';
import {IntegrationsFilterNamesEnum} from '../../../enums/IntegrationsFilterNames.enum';

export const integrationFilterToFilterNameMap = {
  [FiltersEnum.IntegrationTypeSingle]: IntegrationsFilterNamesEnum.INTEGRATION_TYPE,
  [FiltersEnum.IntegrationStatusMulti]: IntegrationsFilterNamesEnum.INTEGRATION_STATUSES,
  [FiltersEnum.AreasMultiAutocompleteExternalData]: IntegrationsFilterNamesEnum.AREAS,
  [FiltersEnum.SchoolNameBasedOnAreaAndDistrict]: IntegrationsFilterNamesEnum.SCHOOL_NAMES,
  [FiltersEnum.SchoolDistrictMulti]: IntegrationsFilterNamesEnum.SCHOOL_DISTRICTS,
};

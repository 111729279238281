import {UserManager} from 'oidc-client';
import {createUserManager} from 'redux-oidc';
import {redirectWindowLocation, UserStore} from 'utils';
import {config} from '../../config';

class AuthenticationManager {
  #userManager: UserManager;
  #userStore: UserStore;

  constructor() {
    this.#userManager = createUserManager(config.oidc);
    this.#userStore = new UserStore();
  }

  public getUserManager() {
    return this.#userManager;
  }

  public logout() {
    this.#userManager.clearStaleState();
    this.#userManager.removeUser();
    this.#userManager.revokeAccessToken();
    this.#userStore.removeAllItems();
    redirectWindowLocation(config.authentication.endSessionUrl);
  }
}

export const authenticationManager = new AuthenticationManager();

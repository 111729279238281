import {FiltersEnum} from 'filters-selections';
import {ItemFilterNamesEnum} from '../../enums/ItemFilterNamesEnum';

export const itemFilterToTilterNameMap = {
  [FiltersEnum.Pillar]: ItemFilterNamesEnum.PILLARS,
  [FiltersEnum.EvaluationType]: ItemFilterNamesEnum.EVALUATION_TYPES,
  [FiltersEnum.Domain]: ItemFilterNamesEnum.DOMAINS,
  [FiltersEnum.Category]: ItemFilterNamesEnum.CATEGORY,
  [FiltersEnum.ProgramMulti]: ItemFilterNamesEnum.PROGRAMS,
  [FiltersEnum.Competency]: ItemFilterNamesEnum.COMPETENCIES,
  [FiltersEnum.Concept]: ItemFilterNamesEnum.CONCEPTS,
  [FiltersEnum.Administration]: ItemFilterNamesEnum.ADMINISTRATIONS,
  [FiltersEnum.SchoolLevel]: ItemFilterNamesEnum.SCHOOL_LEVELS,
  [FiltersEnum.ItemType]: ItemFilterNamesEnum.ITEM_TYPES,
  [FiltersEnum.Areas]: ItemFilterNamesEnum.AREAS,
  [FiltersEnum.LearningObjective]: ItemFilterNamesEnum.LEARNING_OBJECTIVE,
};

import {SortingOrderEnum} from 'enums';
import {IProgramClassesSortOrder} from 'models';
import {ProgramClassesDropdownNameEnum, ProgramsClassesOrderingEnum} from 'admin/programs/details';

export const getProgramClassesMenuItemsList = (
  itemClickHandler: () => void,
  handleItemClick: (handleItemSortObj: IProgramClassesSortOrder) => void
) => {
  const onClick = (
    itemClickHandler: () => void,
    handleItemClick: (handleItemSortObj: IProgramClassesSortOrder) => void,
    handleItemSortObj: IProgramClassesSortOrder
  ) => {
    return () => {
      itemClickHandler();
      handleItemClick(handleItemSortObj);
    };
  };

  return [
    {
      name: ProgramClassesDropdownNameEnum.Class_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramsClassesOrderingEnum.Class,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: ProgramClassesDropdownNameEnum.Class_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramsClassesOrderingEnum.Class,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: ProgramClassesDropdownNameEnum.BCRMClassNumber_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramsClassesOrderingEnum.BCRMClassNumber,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: ProgramClassesDropdownNameEnum.BCRMClassNumber_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramsClassesOrderingEnum.BCRMClassNumber,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: ProgramClassesDropdownNameEnum.SchoolName_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramsClassesOrderingEnum.SchoolName,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: ProgramClassesDropdownNameEnum.SchoolName_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramsClassesOrderingEnum.SchoolName,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: ProgramClassesDropdownNameEnum.NumberOfStudents_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramsClassesOrderingEnum.NumberOfStudents,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: ProgramClassesDropdownNameEnum.NumberOfStudents_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramsClassesOrderingEnum.NumberOfStudents,
        sort: SortingOrderEnum.DESC,
      }),
    },
  ];
};

import {useAppDispatch, useNavigate} from 'hooks';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {activeEntitySelector, fetchProgramThunk, loadingSelector, programDetailsActions as actions} from '../store';

export function useProgram(programId: number) {
  const dispatch = useAppDispatch();
  const program = useSelector(activeEntitySelector);
  const loading = useSelector(loadingSelector);
  const {push} = useNavigate();

  const fetchProgram = useCallback(
    (optional?: boolean) => {
      if (!loading && (!optional || !program || program.id !== programId)) {
        dispatch(actions.resetActiveEntry());
        dispatch(fetchProgramThunk({programId, push}));
      }
    },
    [dispatch, programId, loading, program, push]
  );

  return {loading, program, fetchProgram, reset: actions.resetActiveEntry};
}

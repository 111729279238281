import {ISessionBuilderStoreState} from './ISessionBuilderStoreState';

const builderSessionItemsDefault = {
  result: [],
  entities: {
    items: {},
  },
};

export const sessionBuilderInitialState: ISessionBuilderStoreState = {
  builder: {
    builderSessionItems: builderSessionItemsDefault,
    isAllSessionsExpanded: false,
    isSomeOfSessionsInEditMode: false,
    isSomeOfSessionsWithEmptyName: false,
  },
};

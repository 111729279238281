import {
  IAnswerStackDetails,
  ICustomAnswerStackPayload,
  IDefaultAnswerStackPayload,
  IUpdateAnswerStackPayload,
  IUpdateItemPayload,
} from 'models';

export function mapItemFormDataToUpdatePayload(data: IUpdateItemPayload) {
  return {
    questionId: data.questionId,
    text: data.text,
    questionTextHtml: data.questionTextHtml,
    isDeleted: false,
  };
}

export function mapItemFormDataPayload(data): ICustomAnswerStackPayload | IDefaultAnswerStackPayload {
  const metaData = buildMetadataPayload(data);

  return {
    answerTypeName: data.answerTypeName,
    choices: data.choices.map((choice, idx) => {
      delete choice.id;
      choice.position = idx + 1;
      return choice;
    }),
    defaultAnswerStackId: data.defaultAnswerStackId,
    metaData,
    questionId: data.questionId,
    questionType: data.questionType,
    rangeFrom: data.rangeFrom,
    rangeTo: data.rangeTo,
    minimumRateDescription: data.minimumRateDescription,
    maximumRateDescription: data.maximumRateDescription,
    positiveScores: data.positiveScores,
  };
}

export function mapItemDefaultAnswerStacksPayload(data): IDefaultAnswerStackPayload {
  const metaData = buildMetadataPayload(data);
  const {answerTypeName, defaultAnswerStackId, choices} = getDefaultAnswerStackFormData(data);

  return {
    questionId: data.questionId,
    defaultAnswerStackId,
    answerTypeName,
    choices,
    metaData,
  };
}

export function getDefaultAnswerStackFormData(data) {
  const searchProperty = 'isSelected';

  const {answerTypeName, defaultAnswerStackId, choices} = data.answerStacks.filter((stack) =>
    stack.hasOwnProperty(searchProperty)
  )[0];

  return {
    answerTypeName,
    defaultAnswerStackId,
    choices,
  };
}

export function mapItemFormDataToUpdateAnswerStackPayload(data): IUpdateAnswerStackPayload {
  const metaData = buildMetadataPayload(data);

  return {
    questionAnswerStackId: data.questionAnswerStackId,
    isDeleted: data.isDeleted,
    choices: data.choices,
    rangeFrom: data.rangeFrom,
    rangeTo: data.rangeTo,
    positiveScores: data.positiveScores,
    minimumRateDescription: data.minimumRateDescription,
    maximumRateDescription: data.maximumRateDescription,
    metaData,
  };
}

export function buildMetadataPayload(data) {
  return {
    competencies: mapId(data.competencies),
    concepts: mapId(data.concepts),
    evaluationTypes: mapId(data.evaluationTypes),
    pillars: mapId(data.pillars),
    programs: mapId(data.programs),
    schoolLevels: mapId(data.schoolLevels),
    domain: {id: data.domain},
    administration: {id: data.administration},
    learningObjective: data.learningObjective,
    category: data.category,
  };
}

export function mapId(collection: any[]) {
  if (!collection) {
    return [];
  }

  if (!Array.isArray(collection)) {
    const updatedCollection = [collection];
    return updatedCollection.map((id) => ({id}));
  }

  return collection.map((id) => ({id}));
}

export function getIsJaUSA(details: IAnswerStackDetails) {
  const jaUSA = 'JA USA';
  return details ? details.creatorArea.includes(jaUSA) : false;
}

import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {FormNameEnum} from 'filters-selections';
import {IInstrumentDetails, IInstrumentDropdown, IInstrumentsAllFilter} from 'models';
import {pages} from 'paths';
import {getLocationOrigin} from 'utils';
import {getInstrumentIcon} from '../utils';
import {Button, Loading, PageViewHeader, Table, Tag, PaginationLocal} from 'components-lib';
import {InstrumentsAllFilters, InstrumentsAllSortBy} from './components';
import {PageLayoutTwoCol, PageLayoutWithFixedAreas} from 'layout';
import {ButtonPrimaryWithAdminAndLocalAreaPermissions, ModalCreateNewInstrumentWithForm} from '../components';
import {useModal, useNavigate} from 'hooks';
import {useUserId} from 'user';
import {useInstruments} from '../hooks';
import {instrumentsActions as actions} from '../store/instruments.slice';
import {InstrumentViewTypeEnum} from 'admin/library/items/enums';
import {InstrumentsFilterNamesEnum} from '../enums';
import {EntityStatusEnum} from 'enums';
import {instrumentAllFilterList, instrumentAllFilterToFilterNameMap, instrumentsAllColumns} from './utils';

const locationOrigin = getLocationOrigin();

export function InstrumentsAllView() {
  const dispatch = useDispatch();
  const {push} = useNavigate();
  const {isOpen, setIsOpen} = useModal();
  const {userAreaId} = useUserId();
  const {
    filter,
    filterEntities,
    pageLoading,
    currentPage,
    perPage,
    totalCount,
    instrumentsList,
    fetchInstruments,
    setCurrentPage,
    setPerPage,
  } = useInstruments();

  useEffect(() => {
    fetchInstruments();
  }, [fetchInstruments]);

  const viewInstrumentDetailsClickHandler = useCallback(
    (id: number) => {
      push(`${pages.adminPortal.instruments.all}/${id}`);
    },
    [push]
  );

  const rows = useMemo(() => {
    if (!instrumentsList.result.length) {
      return [];
    }

    return instrumentsList.result.map((key: number) => {
      const instrument: IInstrumentDetails = instrumentsList.entities.items[key];
      const {
        isMasterTemplate,
        isGeneralTemplate,
        audience,
        type,
        phase,
        status,
        templateId,
        templateName,
        programs,
      } = instrument;

      const icon = getInstrumentIcon(!!isGeneralTemplate, isMasterTemplate);
      const surveyUrl = `${locationOrigin}${pages.surveyGeneral.takerLink}/${templateId}?aid=${userAreaId}`;
      const isDraftTemplate = instrument.status === EntityStatusEnum.draft;

      const share = {
        text: 'Share',
        isCopyLink: true,
        copyText: surveyUrl,
      };

      const dropdownItems: IInstrumentDropdown[] = [
        {
          text: 'View',
          isCopyLink: false,
          clickHandler: () => viewInstrumentDetailsClickHandler(templateId),
        },
      ];

      if (isGeneralTemplate && !isDraftTemplate) {
        dropdownItems.push(share);
      }

      return {
        mtIcon: icon,
        id: templateId,
        name: templateName,
        programsList: programs?.map(({name}) => name).join('; '),
        audience: audience?.displayText,
        type: type?.displayText,
        phase: phase?.displayText,
        status: status === EntityStatusEnum.live ? EntityStatusEnum.published : status,
        rowRef: React.createRef(),
        actions: <Button.Dropdown items={dropdownItems} />,
      };
    });
  }, [instrumentsList, viewInstrumentDetailsClickHandler, userAreaId]);

  const contentFooter = useMemo(
    () => (
      <PaginationLocal
        onPageChange={setCurrentPage}
        onPerPageChange={setPerPage}
        totalCount={totalCount}
        perPage={perPage}
        currentPage={currentPage}
        disabled={pageLoading}
      />
    ),
    [currentPage, perPage, setCurrentPage, setPerPage, totalCount, pageLoading]
  );

  const appliedFilters = useMemo(() => {
    const chips: any[] = [];

    const resetDropdownFilter = (propName: string, id: string | number) => {
      const updated: IInstrumentsAllFilter = {...filter};

      updated[propName] = updated[propName].filter((pId: number) => pId !== id);
      dispatch(actions.setFilterInstruments(updated));
    };

    instrumentAllFilterList.forEach((itemFilter) => {
      const mappedFilterName: InstrumentsFilterNamesEnum = instrumentAllFilterToFilterNameMap[itemFilter];
      const current = filter[mappedFilterName];

      if (current instanceof Array && current.length > 0) {
        current.forEach((id) => {
          const currentFilter = filterEntities[mappedFilterName][id];

          chips.push(
            <Tag
              withFilterChip
              key={`${mappedFilterName}-${id}`}
              label={currentFilter.name === 'Live' ? 'Published' : currentFilter.name}
              deleteHandler={() => resetDropdownFilter(mappedFilterName, currentFilter.id)}
            />
          );
        });
      }
    });

    if (!!filter.name) {
      const deleteSearchFilter = () => {
        const updated = {...filter, [FormNameEnum.name]: ''};
        dispatch(actions.setFilterInstruments(updated));
      };
      chips.push(
        <Tag withFilterChip key={`name-${filter.name}`} label={filter.name} deleteHandler={deleteSearchFilter} />
      );
    }

    if (filter.isGeneralTemplate !== null) {
      const deleteHandler = () => {
        const updated = {...filter, [InstrumentsFilterNamesEnum.INSTRUMENT_VIEW_TYPE]: null};
        dispatch(actions.setFilterInstruments(updated));
      };

      chips.push(
        <Tag
          withFilterChip
          key={FormNameEnum.instrumentViewType}
          label={InstrumentViewTypeEnum[filter.isGeneralTemplate]}
          deleteHandler={deleteHandler}
        />
      );
    }

    return chips;
  }, [dispatch, filter, filterEntities]);

  const renderSortOrder = useMemo(() => <InstrumentsAllSortBy />, []);

  const contentHeader = useMemo(
    () => (
      <PageViewHeader
        heading="Instruments"
        action={
          <ButtonPrimaryWithAdminAndLocalAreaPermissions clickHandler={() => setIsOpen(true)}>
            New Instrument
          </ButtonPrimaryWithAdminAndLocalAreaPermissions>
        }
        sort={renderSortOrder}
        filters={appliedFilters}
      />
    ),
    [appliedFilters, renderSortOrder, setIsOpen]
  );

  const content = useMemo(() => {
    return (
      <PageLayoutWithFixedAreas header={contentHeader} footer={contentFooter}>
        {pageLoading ? (
          <Loading />
        ) : !!rows.length ? (
          <Table rows={rows} columns={instrumentsAllColumns} />
        ) : (
          'No results found'
        )}
      </PageLayoutWithFixedAreas>
    );
  }, [contentHeader, contentFooter, pageLoading, rows]);

  return (
    <>
      <ModalCreateNewInstrumentWithForm isOpen={isOpen} setIsOpen={setIsOpen} />
      <PageLayoutTwoCol sidebar={<InstrumentsAllFilters />} content={content} />
    </>
  );
}

import React, {FC, SyntheticEvent, useMemo} from 'react';
import {IconButton} from '@material-ui/core';
import {Icon} from 'components-lib';
import {Input} from '../Input/Input';
import {IWithDisabled} from 'models';
import {usePasswordInput} from 'hooks';
import {InputTypeEnum} from 'enums/form';

interface IPasswordInputProps extends IWithDisabled {
  value: string | null;
  changeHandler: (ev: SyntheticEvent) => void;
  withIcon?: boolean;
}

export const PasswordInput: FC<IPasswordInputProps> = ({
  value,
  changeHandler,
  disabled = false,
  withIcon = true,
  ...rest
}) => {
  const {type, toggleIconClickHandler} = usePasswordInput();

  const icon = useMemo(
    () => (
      <IconButton disabled={disabled} onClick={toggleIconClickHandler}>
        {type === InputTypeEnum.Password ? <Icon.VisibilityOnIcon /> : <Icon.VisibilityOffIcon />}
      </IconButton>
    ),
    [disabled, toggleIconClickHandler, type]
  );

  return (
    <Input
      {...rest}
      value={value}
      changeHandler={changeHandler}
      withIcon={withIcon}
      iconPosition="end"
      icon={icon}
      disabled={disabled}
      type={type}
    />
  );
};

import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {IComponentWithClassName} from 'models';
import React from 'react';
import {IButtonBaseProps} from './IButtonBaseProps';

interface IButtonBaseComponentProps extends IButtonBaseProps, IComponentWithClassName {
  color: 'primary' | 'secondary' | 'inherit';
  variant: 'contained' | 'outlined' | 'text';
  isFirst?: boolean;
  isLast?: boolean;
}

export function ButtonBase({
  children,
  clickHandler,
  disabled = false,
  endIcon = null,
  startIcon = null,
  size = 'large',
  color,
  variant,
  className = '',
  type = 'button',
  isFirst = false,
  isLast = false,
}: IButtonBaseComponentProps) {
  const classes = useStyles({size, isFirst, isLast});
  return (
    <Button
      variant={variant}
      color={color}
      onClick={clickHandler}
      disabled={disabled}
      endIcon={endIcon}
      startIcon={startIcon}
      className={classnames(classes.root, className)}
      disableElevation
      type={type}
    >
      {children}
    </Button>
  );
}

const useStyles = makeStyles((theme) => ({
  root: ({size, isFirst, isLast}: Pick<IButtonBaseComponentProps, 'size' | 'isFirst' | 'isLast'>) => {
    const isLarge = size === 'large';
    const space = theme.spacing(2);

    let margin = `${space}px`;

    if (isFirst) {
      margin = `${space}px ${space}px ${space}px 0`;
    }

    if (isLast) {
      margin = `${space}px 0 ${space}px ${space}px`;
    }

    return {
      minWidth: isLarge ? 80 : 48,
      margin,
      padding: isLarge ? '12px 24px' : '4px 8px',
      textTransform: 'none',
    };
  },
}));

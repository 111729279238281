import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';

const getLearningPathsState = (state: TStateStore) => state.resources.learningPaths;

export const getLearningPathsCreateState = createSelector(
  getLearningPathsState,
  (learningPaths) => learningPaths.create
);

export const learningPathCreatePageLoadingSelector = createSelector(
  getLearningPathsCreateState,
  (learningPathCreate) => learningPathCreate.page.loading
);

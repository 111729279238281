import React, {Fragment} from 'react';
import {LearningPathDetailsFirstCol, LearningPathDetailsSecondCol} from 'admin/resources/learning-paths/components';

export const DetailsFields = () => {
  return (
    <Fragment>
      <LearningPathDetailsFirstCol />
      <LearningPathDetailsSecondCol />
    </Fragment>
  );
};

import React, {useCallback, useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {Tooltip} from '@material-ui/core';
import {unwrapResult} from '@reduxjs/toolkit';
import {SubtitlePageViewHeader} from 'admin/components';
import {InstrumentStatusNamesEnum} from 'admin/library/instruments/enums';
import {duplicateInstrumentThunk} from 'admin/library/instruments/store';
import {InstrumentViewTypeEnum} from 'admin/library/items/enums';
import {useProgram} from 'admin/programs/details/hooks';
import {Button, Icon, Loading, PageViewHeader, PaginationLocal, Table, Tag} from 'components-lib';
import {useAppDispatch, useNavigate} from 'hooks';
import {PageLayoutTwoCol, PageLayoutWithFixedAreas} from 'layout';
import {IBindBaseEntity, IBindBaseEnumEntity, IProgramClassInstrumentBase} from 'models';
import {pages} from 'paths';
import {AdminWithoutStaffRoles, AllRolesWithoutStudent, usePermissions} from 'permissions';
import {cloneDeep, getLocationOrigin, MIN_INSTRUMENT_NAME_LENGTH, toNumber} from 'utils';
import {useProgramClass} from '../hooks';
import {ProgramsClassInstrumentsSortBy, SidebarFilters} from './components';
import {FilterBackendNamesEnum} from './enums';
import {useProgramClassInstruments} from './hooks';

const locationOrigin = getLocationOrigin();

export function ProgramClassInstrumentsView() {
  const dispatch = useAppDispatch();
  const {push} = useNavigate();
  const {hasPermission} = usePermissions();
  const hasAdminPermission = hasPermission(AdminWithoutStaffRoles);
  const hasAllRolesWithoutStudentPermission = hasPermission(AllRolesWithoutStudent);

  const {programId: programIdRaw, classId: classIdRaw} = useParams<{
    programId: string;
    classId: string;
  }>();

  const programId = toNumber(programIdRaw);
  const classId = toNumber(classIdRaw);

  const {program, fetchProgram} = useProgram(programId);

  const {currentClass, fetchClass} = useProgramClass(classId);

  const {
    loading,
    instruments,
    filter,
    filterEntities,
    fetchClassInstruments,
    setFilter,
    setCurrentPage,
    setPerPage,
    totalCount,
    perPage,
    currentPage,
  } = useProgramClassInstruments(classId);

  useEffect(() => {
    fetchProgram(true);
  }, [fetchProgram]);

  useEffect(() => {
    fetchClass(true);
  }, [fetchClass]);

  useEffect(() => {
    fetchClassInstruments();
  }, [fetchClassInstruments]);

  const viewInstrumentDetails = useCallback(
    (id: number) => {
      push(`${pages.adminPortal.instruments.detailsRoot}/${id}`, {
        prevPath: window.location.pathname,
      });
    },
    [push]
  );

  const duplicateMasterTemplate = useCallback(
    (id: number) =>
      dispatch(
        duplicateInstrumentThunk({
          instrumentId: id,
          programSessionId: classId,
        })
      )
        .then(unwrapResult)
        .then((newId: number) => {
          push(`${pages.adminPortal.instruments.detailsRoot}/${newId}`, {
            prevPath: window.location.pathname,
          });
        }),
    [dispatch, classId, push]
  );

  const rows = useMemo(() => {
    return instruments.result.map((key: string) => {
      const instrument: IProgramClassInstrumentBase = instruments.entities.byId[key];
      const surveyUrl = `${locationOrigin}${pages.survey.takerLink}/${programId}/${instrument?.id}`;
      const surveyGeneralUrl = `${locationOrigin}${pages.surveyGeneral.takerLink}/${instrument?.id}`;
      const isMasterTemplate = !instrument?.parentId;
      const isDraftTemplate = instrument?.status === InstrumentStatusNamesEnum.DRAFT;
      let dropdownItems = [];

      const masterTemplateDropdownItems = [
        {
          text: 'View',
          isCopyLink: false,
          clickHandler: () => viewInstrumentDetails(instrument.id),
        },
        {
          text: 'Share',
          isCopyLink: true,
          copyText: surveyUrl,
        },
      ];

      const otherDropdownItems = [
        {
          text: 'View',
          isCopyLink: false,
          clickHandler: () => viewInstrumentDetails(instrument.id),
        },
        {
          text: 'Direct Link',
          isCopyLink: true,
          copyText: surveyGeneralUrl,
        },
      ];

      if (isMasterTemplate) {
        dropdownItems = masterTemplateDropdownItems;

        if (hasAdminPermission) {
          const additionalDropdownItems = [
            {
              text: 'Duplicate',
              isCopyLink: false,
              clickHandler: () => duplicateMasterTemplate(instrument.id),
            },
          ];

          dropdownItems = [...dropdownItems, ...additionalDropdownItems];
        }
      } else {
        dropdownItems = otherDropdownItems;

        if (hasAllRolesWithoutStudentPermission) {
          const additionalDropdownItems = [
            {
              text: 'Print',
              isCopyLink: false,
              clickHandler: () =>
                window.open(`${pages.adminPortal.instruments.printPreviewLink}/${instrument.id}`, '_blank'),
            },
          ];

          dropdownItems = [...dropdownItems, ...additionalDropdownItems];
        }
      }

      if (isDraftTemplate) {
        dropdownItems = dropdownItems.splice(0, 1);
      }

      return {
        code: instrument?.id,
        mtIcon: isMasterTemplate ? <Icon.MasterTemplateIcon /> : '',
        name: (
          <Tooltip
            disableHoverListener={instrument?.name?.length < MIN_INSTRUMENT_NAME_LENGTH}
            title={instrument?.name}
          >
            <span>{instrument?.name}</span>
          </Tooltip>
        ),
        audience: instrument?.audience,
        surveyType: instrument?.surveyType,
        phase: instrument?.phase,
        status: instrument?.status,
        viewBtn: <Button.Dropdown items={dropdownItems} />,
      };
    });
  }, [
    instruments.result,
    instruments.entities.byId,
    programId,
    hasAdminPermission,
    hasAllRolesWithoutStudentPermission,
    viewInstrumentDetails,
    duplicateMasterTemplate,
  ]);

  const headerSubheading = useMemo(
    () =>
      program && (
        <SubtitlePageViewHeader>
          {' '}
          / {program?.name} / {currentClass?.name}
        </SubtitlePageViewHeader>
      ),
    [currentClass, program]
  );

  const headerSortOrder = useMemo(() => <ProgramsClassInstrumentsSortBy />, []);

  const filters = useMemo(() => {
    const chips: any[] = [];

    const deleteHandler = (key: string, item: number | string | null) => {
      const updated = cloneDeep(filter);

      if (item === null) {
        updated[key] = item;
      } else {
        const idx = filter[key].indexOf(item);

        if (idx > -1) {
          updated[key].splice(idx, 1);
        }
      }

      setFilter(updated);
    };

    if (!!filter.name) {
      chips.push(
        <Tag
          withFilterChip
          key={filter.name}
          label={filter.name}
          deleteHandler={() => setFilter({...filter, name: ''})}
        />
      );
    }

    if (!!filter.audiences) {
      filter.audiences.forEach((id) => {
        const option: IBindBaseEntity = filterEntities.audiences[id];
        option &&
          chips.push(<Tag withFilterChip label={option.name} deleteHandler={() => deleteHandler('audiences', id)} />);
      });
    }

    if (!!filter.phases) {
      filter.phases.forEach((id) => {
        const option: IBindBaseEntity = filterEntities.phases[id];
        option &&
          chips.push(<Tag withFilterChip label={option.name} deleteHandler={() => deleteHandler('phases', id)} />);
      });
    }

    if (!!filter.types) {
      filter.types.forEach((id) => {
        const option: IBindBaseEnumEntity = filterEntities.types[id];
        option &&
          chips.push(
            <Tag withFilterChip label={option.displayText} deleteHandler={() => deleteHandler('types', id)} />
          );
      });
    }

    if (!!filter.statuses) {
      filter.statuses.forEach((id) => {
        const option: IBindBaseEnumEntity = filterEntities.statuses[id];
        option &&
          chips.push(
            <Tag withFilterChip label={option.displayText} deleteHandler={() => deleteHandler('statuses', id)} />
          );
      });
    }

    if (!!filter.pillars) {
      filter.pillars.forEach((id) => {
        const option: IBindBaseEnumEntity = filterEntities.pillars[id];
        option &&
          chips.push(<Tag withFilterChip label={option.name} deleteHandler={() => deleteHandler('pillars', id)} />);
      });
    }

    if (filter.isGeneralTemplate !== null) {
      chips.push(
        <Tag
          withFilterChip
          key={FilterBackendNamesEnum.INSTRUMENT_VIEW_TYPE}
          label={InstrumentViewTypeEnum[filter.isGeneralTemplate]}
          deleteHandler={() => deleteHandler('isGeneralTemplate', null)}
        />
      );
    }

    return chips;
  }, [
    filter,
    filterEntities.audiences,
    filterEntities.phases,
    filterEntities.types,
    filterEntities.statuses,
    filterEntities.pillars,
    setFilter,
  ]);

  const header = useMemo(() => {
    return (
      <PageViewHeader
        heading="Programs"
        subHeading={headerSubheading}
        filters={filters}
        sort={headerSortOrder}
        withBackButton
        backButtonClickHandler={() => push(`${pages.adminPortal.programs.detailsLink}/${programId}`)}
      />
    );
  }, [headerSubheading, filters, push, programId, headerSortOrder]);

  const footer = useMemo(() => {
    return (
      <PaginationLocal
        onPageChange={setCurrentPage}
        onPerPageChange={setPerPage}
        totalCount={totalCount}
        perPage={perPage}
        currentPage={currentPage}
        disabled={loading}
      />
    );
  }, [currentPage, perPage, setCurrentPage, setPerPage, totalCount, loading]);

  const sidebar = useMemo(() => {
    return <SidebarFilters />;
  }, []);

  const content = useMemo(() => {
    return (
      <PageLayoutWithFixedAreas withSecondaryNavigation={false} header={header} footer={footer}>
        {loading ? <Loading /> : !!rows.length ? <Table rows={rows} columns={columns} /> : 'No results found'}
      </PageLayoutWithFixedAreas>
    );
  }, [header, loading, rows, footer]);

  return <PageLayoutTwoCol withSecondaryNavigation={false} sidebar={sidebar} content={content} />;
}

const columns: any[] = [
  {id: 'mtIcon', label: ''},
  {id: 'name', label: 'Instrument'},
  {id: 'audience', label: 'Audience'},
  {id: 'surveyType', label: 'Type'},
  {id: 'phase', label: 'Phase'},
  {id: 'status', label: 'Status'},
  {id: 'viewBtn', label: '', align: 'center'},
];

import {getApiMultipartFormDataHeadersConfig, returnQueryStringWithParams} from 'utils';
import {config} from 'config';
import {ApiPathsEnum} from 'enums';
import {del, get, patch, post} from './http-client';
import {IHttpResponse} from './models/IHttpResponse';
import {
  IGetIntegrationsQueryFilter,
  IGoogleSitesSourceHistoryApiResponse,
  IIntegrationItem,
  IIntegrationListDropdown,
  IIntegrationStatus,
  IIntegrationType,
  IPaginationOptions,
  ISortingOptions,
} from '../models';
import {
  ICreateGoogleSitesDestinationPayload,
  ICreateGoogleSitesDestinationResponse,
  ICreateGoogleSitesSourceVirtualClassPayload,
  ICreateGoogleSitesSourceVirtualClassResponse,
  ICreateGoogleVirtualClassPayload,
  ICreateGoogleVirtualClassResponse,
  IIntegrationSearchSchools,
  ISearchSchoolsPayload,
  ICreateMicrosoftTeamsVirtualClassPayload,
  ICreateMicrosoftTeamsVirtualClassResponse,
  IGetServiceEmailResponse,
  IIntegrationDetails,
  IUpdateIntegrationPayload,
  IUpdateIntegrationApiResponse,
  IIntegrationAuditLogApiResponse,
  IGetAuditLogResponse,
  IUpdateMicrosoftTeamsIntegrationPayload,
  ITestMicrosoftTeamsIntegrationApiResponse,
  ITestGoogleClassroomIntegrationApiResponse,
  IUpdateGoogleClassroomIntegrationPayload,
  IUpdateGoogleSitesSourceIntegrationPayload,
  ITestGoogleSitesSourceIntegrationApiResponse,
  IUpdateGoogleSitesDestinationIntegrationPayload,
  IGetPublishedApiSourceDetails,
} from './../models/integrations';
import {axiosApiInstance} from 'api';

const {jaEeApi: apiConfig} = config;

export const integrationsApi = {
  async getIntegrations(
    pagination: IPaginationOptions,
    sortOptions: ISortingOptions,
    filters: IGetIntegrationsQueryFilter
  ): Promise<IHttpResponse<IIntegrationItem[]>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.INTEGRATION}/List`;

    const queryString = returnQueryStringWithParams(url, {
      ...pagination,
      ...sortOptions,
      ...filters,
    });
    return await get<IIntegrationItem[]>(queryString);
  },

  async getDetailsIntegration(integrationId: string): Promise<IHttpResponse<IIntegrationDetails>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.INTEGRATION}/${integrationId}/Details`;
    return await get<IIntegrationDetails>(url);
  },

  async updateIntegration(
    integrationId: string,
    payload: IUpdateIntegrationPayload
  ): Promise<IHttpResponse<IUpdateIntegrationApiResponse>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.INTEGRATION}/${integrationId}/Update`;
    return await get<IUpdateIntegrationApiResponse>(url, payload);
  },

  async getIntegrationAuditLog(integrationId: string): Promise<IHttpResponse<IIntegrationAuditLogApiResponse>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.INTEGRATION}/${integrationId}/AuditLog`;
    return await get<IIntegrationAuditLogApiResponse>(url);
  },

  async deleteIntegration(integrationId: string): Promise<IHttpResponse<IIntegrationDetails>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.INTEGRATION}/${integrationId}/Remove`;
    return await del<IIntegrationDetails>(url, {});
  },

  async publishIntegration(integrationId: string): Promise<IHttpResponse<IIntegrationDetails>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.INTEGRATION}/${integrationId}/Publish`;
    return await post<IIntegrationDetails>(url, {});
  },

  async getIntegrationTypes(): Promise<IHttpResponse<IIntegrationType[]>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.ENUMERATORS}/GetIntegrationTypes`;
    return await get<IIntegrationType[]>(url);
  },

  async getIntegrationStatuses(): Promise<IHttpResponse<IIntegrationStatus[]>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.ENUMERATORS}/GetIntegrationStatuses`;
    return await get<IIntegrationStatus[]>(url);
  },

  async getIntegrationListDropdownData(): Promise<IHttpResponse<IIntegrationListDropdown>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.ENUMERATORS}/GetIntegrationListDropdownData`;
    return await get<IIntegrationListDropdown>(url);
  },

  async searchSchools(query: ISearchSchoolsPayload): Promise<IHttpResponse<IIntegrationSearchSchools>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.ENUMERATORS}/SearchSchools`;
    return await post<IIntegrationSearchSchools>(url, query);
  },

  // Google Classroom

  async createGoogleVirtualClass(
    payload: ICreateGoogleVirtualClassPayload
  ): Promise<IHttpResponse<ICreateGoogleVirtualClassResponse>> {
    const config = getApiMultipartFormDataHeadersConfig();
    const baseUrl = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.VIRTUAL_CLASS}/${ApiPathsEnum.GOOGLE_CLASSROOM}/${ApiPathsEnum.CREATE}`;
    return axiosApiInstance.put(baseUrl, payload, config);
  },

  async updateGoogleClassroomIntegration(
    integrationId: string,
    payload: IUpdateGoogleClassroomIntegrationPayload
  ): Promise<IHttpResponse<IUpdateIntegrationApiResponse>> {
    const config = getApiMultipartFormDataHeadersConfig();
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.VIRTUAL_CLASS}/${ApiPathsEnum.GOOGLE_CLASSROOM}/${integrationId}/Update`;
    return axiosApiInstance.patch(url, payload, config);
  },

  async testGoogleClassroomIntegration(
    integrationId: string
  ): Promise<IHttpResponse<ITestGoogleClassroomIntegrationApiResponse>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.VIRTUAL_CLASS}/${ApiPathsEnum.GOOGLE_CLASSROOM}/${integrationId}/Test`;
    return await post<ITestGoogleClassroomIntegrationApiResponse>(url, {});
  },

  // Google Site Source

  async createGoogleSitesSource(
    payload: ICreateGoogleSitesSourceVirtualClassPayload
  ): Promise<IHttpResponse<ICreateGoogleSitesSourceVirtualClassResponse>> {
    const config = getApiMultipartFormDataHeadersConfig();
    const baseUrl = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.GOOGLE_SITES}/${ApiPathsEnum.SOURCE}/Create`;
    return axiosApiInstance.put(baseUrl, payload, config);
  },

  async updateGoogleSiteSourceIntegration(
    integrationId: string,
    payload: IUpdateGoogleSitesSourceIntegrationPayload
  ): Promise<IHttpResponse<IUpdateIntegrationApiResponse>> {
    const config = getApiMultipartFormDataHeadersConfig();
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.GOOGLE_SITES}/${ApiPathsEnum.SOURCE}/${integrationId}/Update`;
    return axiosApiInstance.patch(url, payload, config);
  },

  async testGoogleSitesSourceIntegration(
    integrationId: string
  ): Promise<IHttpResponse<ITestGoogleSitesSourceIntegrationApiResponse>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.GOOGLE_SITES}/${ApiPathsEnum.SOURCE}/${integrationId}/Test`;
    return await post<ITestGoogleSitesSourceIntegrationApiResponse>(url, {});
  },

  async getGoogleSitesSourceHistory(
    googleSiteId: string
  ): Promise<IHttpResponse<IGoogleSitesSourceHistoryApiResponse>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.GOOGLE_SITES}/${ApiPathsEnum.SOURCE}/${ApiPathsEnum.SITE}/${googleSiteId}/History`;
    return await get<IGoogleSitesSourceHistoryApiResponse>(url);
  },

  // Google Site Destination

  async createGoogleSitesDestination(
    payload: ICreateGoogleSitesDestinationPayload
  ): Promise<IHttpResponse<ICreateGoogleSitesDestinationResponse>> {
    const config = getApiMultipartFormDataHeadersConfig();
    const baseUrl = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.GOOGLE_SITES}/${ApiPathsEnum.DESTINATION}/Create`;
    return axiosApiInstance.put(baseUrl, payload, config);
  },

  async updateGoogleSiteDestinationIntegration(
    integrationId: string,
    payload: IUpdateGoogleSitesDestinationIntegrationPayload
  ): Promise<IHttpResponse<IUpdateIntegrationApiResponse>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.GOOGLE_SITES}/${ApiPathsEnum.DESTINATION}/${integrationId}/Update`;
    return await patch<IUpdateIntegrationApiResponse>(url, payload);
  },

  async testGoogleSiteDestinationIntegration(
    integrationId: string
  ): Promise<IHttpResponse<ITestMicrosoftTeamsIntegrationApiResponse>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.GOOGLE_SITES}/${ApiPathsEnum.DESTINATION}/${integrationId}/Test`;
    return await post<ITestMicrosoftTeamsIntegrationApiResponse>(url, {});
  },

  async getServiceEmail(): Promise<IHttpResponse<IGetServiceEmailResponse>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.GOOGLE_SITES}/${ApiPathsEnum.SOURCE}/GetServiceEmail`;
    return await get<IGetServiceEmailResponse>(url);
  },

  async getPublishedSourceDetails(): Promise<IHttpResponse<IGetPublishedApiSourceDetails>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.GOOGLE_SITES}/${ApiPathsEnum.SOURCE}/${ApiPathsEnum.PUBLISHED}/Details`;
    return await get<IGetPublishedApiSourceDetails>(url);
  },

  // Microsoft teams

  async createMicrosoftTeamsVirtualClass(
    payload: ICreateMicrosoftTeamsVirtualClassPayload
  ): Promise<IHttpResponse<ICreateMicrosoftTeamsVirtualClassResponse>> {
    const config = getApiMultipartFormDataHeadersConfig();
    const baseUrl = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.VIRTUAL_CLASS}/${ApiPathsEnum.MICROSOFT_TEAMS}/Create`;
    return axiosApiInstance.put(baseUrl, payload, config);
  },

  async updateMicrosoftTeamsIntegration(
    integrationId: string,
    payload: IUpdateMicrosoftTeamsIntegrationPayload
  ): Promise<IHttpResponse<IUpdateIntegrationApiResponse>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.VIRTUAL_CLASS}/${ApiPathsEnum.MICROSOFT_TEAMS}/${integrationId}/Update`;
    return await patch<IUpdateIntegrationApiResponse>(url, payload);
  },

  async testMicrosoftTeamsIntegration(
    integrationId: string
  ): Promise<IHttpResponse<ITestMicrosoftTeamsIntegrationApiResponse>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.VIRTUAL_CLASS}/${ApiPathsEnum.MICROSOFT_TEAMS}/${integrationId}/Test`;
    return await post<ITestMicrosoftTeamsIntegrationApiResponse>(url, {});
  },

  // Audit Log

  async getAuditLog(integrationId: string): Promise<IHttpResponse<IGetAuditLogResponse>> {
    const url = `${apiConfig.baseUrlIntegrations}/${ApiPathsEnum.INTEGRATION}/${integrationId}/AuditLog`;
    return await get<IGetAuditLogResponse>(url);
  },
};

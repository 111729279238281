import {IActionFulfilled} from 'models';
import {IProgramDetailsState} from './IProgramDetailsState';
import {programDetailsInitialState} from './programDetails.initialState';

export const programDetailsReducer = {
  resetActiveEntry: (state: IProgramDetailsState) => {
    state.activeEntity = programDetailsInitialState.activeEntity;
  },

  clearLevelOfReports(state: IProgramDetailsState) {
    state.levelOfReports = {...programDetailsInitialState.levelOfReports};
  },

  setSelectedId: (state: IProgramDetailsState, action: IActionFulfilled<null, number>) => {
    state.levelOfReports.selectedId = action.payload;
  },
};

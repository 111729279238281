import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {IMetaDataDto} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

interface ICloneInstrumentPayload {
  instrumentId: number;
  metaData: IMetaDataDto;
  isGeneral: boolean;
}

export const cloneInstrumentThunk: any = createAsyncThunk<number, ICloneInstrumentPayload>(
  `${prefix} cloneInstrument`,
  async (payload: ICloneInstrumentPayload, {dispatch}) => {
    try {
      const {parsedBody} = await instrumentsApi.postCloneTemplate(
        payload.instrumentId,
        payload.metaData,
        payload.isGeneral
      );
      dispatch(toastNotificationManager.createSuccessToastAction('The instrument was cloned successfully.'));
      return parsedBody;
    } catch (err) {
      throw new Error(err.parsedBody);
    }
  }
);

import {createAsyncThunk} from '@reduxjs/toolkit';
import {integrationsApi} from 'api';
import {ISearchSchoolsPayload} from 'models/integrations';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const searchSchoolsThunk: any = createAsyncThunk<any, any>(
  `${prefix} searchSchools`,
  async (query: ISearchSchoolsPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await integrationsApi.searchSchools(query);
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

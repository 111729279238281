import React, {Fragment, useCallback, useContext, useEffect} from 'react';
import {batch, useSelector} from 'react-redux';
import {useFormikContext} from 'formik';
import {FormContext} from 'forms/formik/FormContextProvider';
import {IWithModal} from 'models';
import {Button, Modal} from 'components-lib';
import {useItemStateActions} from 'admin/library/items/hooks';
import {usePermissions} from 'permissions';
import {AdminRolesItems} from 'admin/library/items/enums';
import {useQueryParams} from 'hooks';
import {useDebounce} from 'hooks';
import {savingSelector} from 'admin/library/items/store';
import {ModalSelectAnswerStackForm} from '..';
import {ModalSelectAnswerStackContent} from './ModalSelectAnswerStackContent';

interface IModalSelectAnswerStackProps extends IWithModal {
  text?: string;
  confirmHandler?: () => void;
}

export const ModalSelectAnswerStack = ({isOpen, setIsOpen}: IModalSelectAnswerStackProps) => {
  const queryParams = useQueryParams();
  const {fetchPossibleDefaultAnswerStacks} = useItemStateActions();
  const {resetForm} = useFormikContext();
  const {hasPermission} = usePermissions();
  const debouncedReset = useDebounce(() => resetForm());
  const {setIsDirty, isDirty, formikFormRef, submitFormikForm} = useContext(FormContext);

  const saving = useSelector(savingSelector);

  const questionId = queryParams.questionId;
  const hasAdminPermission = hasPermission(AdminRolesItems);

  useEffect(() => {
    if (questionId && hasAdminPermission) {
      fetchPossibleDefaultAnswerStacks(questionId);
    }
  }, [questionId, hasAdminPermission, fetchPossibleDefaultAnswerStacks]);

  const closeHandler = useCallback(
    () =>
      batch(() => {
        debouncedReset();
        setIsOpen(false);
      }),
    [setIsOpen, debouncedReset]
  );

  const submitFormClickHandler = useCallback(() => formikFormRef?.current && submitFormikForm(), [
    formikFormRef,
    submitFormikForm,
  ]);

  return (
    <Modal
      disableBackdropClick
      isOpen={isOpen}
      handleClose={closeHandler}
      title="Add Tags and Select an Answer Stack"
      size="medium"
    >
      <ModalSelectAnswerStackForm setIsOpen={setIsOpen}>
        <ModalSelectAnswerStackContent isDirty={isDirty} setIsDirtyCallback={setIsDirty} />
      </ModalSelectAnswerStackForm>
      <Fragment>
        <Button.Secondary clickHandler={closeHandler} size="small">
          Cancel
        </Button.Secondary>
        <Button.Primary clickHandler={submitFormClickHandler} size="small" disabled={!isDirty || saving}>
          {saving ? 'Loading...' : 'Done'}
        </Button.Primary>
      </Fragment>
    </Modal>
  );
};

import React from 'react';

import {PaginationLocal} from 'components-lib';
import {useFilesAll, useFilesAllStoreActions} from '../../hooks';

export const FilesViewAllFooter = () => {
  const {loading} = useFilesAll();
  const {currentPage, perPage, totalCount, setCurrentPage, setPerPage} = useFilesAllStoreActions();

  return (
    <PaginationLocal
      onPageChange={setCurrentPage}
      onPerPageChange={setPerPage}
      totalCount={totalCount}
      perPage={perPage}
      currentPage={currentPage}
      disabled={loading}
    />
  );
};

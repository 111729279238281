import React, {FC} from 'react';
import {Button, Container, Row} from 'components-lib';
import {Form} from 'forms';
interface IResourceFooterButtonsProps {
  loading: boolean;
  withSubmitButton?: boolean;
  withPublishButton?: boolean;
  withDeleteButton?: boolean;
  withCancelButton?: boolean;
  withSaveButton?: boolean;
  disablePublish?: boolean;
  disableDelete?: boolean;
  disableSubmit?: boolean;
  disableSave?: boolean;
  submitButtonText?: string;
  cancelClickHandler?: () => void;
  publishClickHandler?: () => void;
  deleteClickHandler?: () => void;
  saveClickHandler?: () => void;
}

export const ResourceFooterButtons: FC<IResourceFooterButtonsProps> = ({
  loading,
  withSubmitButton = true,
  withPublishButton = false,
  withDeleteButton = false,
  withCancelButton = true,
  withSaveButton = false,
  disablePublish = false,
  disableDelete = false,
  disableSubmit = false,
  disableSave = false,
  submitButtonText = 'Save',
  cancelClickHandler,
  publishClickHandler,
  deleteClickHandler,
  saveClickHandler,
}) => (
  <Container disableGutters>
    <Row align="center" justify="flex-end">
      {withCancelButton && (
        <Button.Secondary clickHandler={cancelClickHandler} disabled={loading}>
          Cancel
        </Button.Secondary>
      )}
      {withDeleteButton && (
        <Button.Secondary clickHandler={deleteClickHandler} disabled={loading || disableDelete}>
          Delete
        </Button.Secondary>
      )}
      {withSubmitButton && (
        <Form.ButtonSubmit size="large" text={submitButtonText} disabled={disableSubmit || loading} />
      )}
      {withSaveButton && (
        <Button.Primary clickHandler={saveClickHandler} disabled={loading || disableSave}>
          Save
        </Button.Primary>
      )}
      {withPublishButton && (
        <Button.Primary clickHandler={publishClickHandler} disabled={loading || disablePublish}>
          Publish
        </Button.Primary>
      )}
    </Row>
  </Container>
);

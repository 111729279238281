import {useFormikContext} from 'formik';
import {FormNameEnum} from '../enums';

export function useIsProgramSelected() {
  const {values} = useFormikContext();

  const programIds = values[FormNameEnum.programs];
  const isProgramSelected = programIds.length ? true : false;

  return {isProgramSelected, programIds, formValues: values};
}

import {createSlice} from '@reduxjs/toolkit';
import {filtersReducer} from './filters.reducer';
import {filtersSelectionExtra} from './filtersSelectionExtra.reducer';
import {filtersSelectionsInitialState} from './filtersSelections.initialState';

export const filtersSelectionsSlice = createSlice({
  name: 'filtersSelections',
  initialState: filtersSelectionsInitialState,
  reducers: filtersReducer,
  extraReducers: filtersSelectionExtra,
});

export const {actions: filtersActions, reducer: filtersSelectionsReducer} = filtersSelectionsSlice;

import {AddIntegrationLabelEnum} from 'admin/integrations/add-integration/enums';

export const getDefaultPreviewFirstColumnList = ({
  integrationTypeStringValue,
  areaName,
  schoolNameStringValue,
  certificateName,
}) => {
  return [
    {
      label: AddIntegrationLabelEnum.IntegrationType,
      value: integrationTypeStringValue ?? '',
      options: {
        withForm: false,
        disabled: true,
      },
    },
    {
      label: AddIntegrationLabelEnum.Area,
      value: areaName ?? '',
      options: {
        withForm: false,
        disabled: true,
      },
    },
    {
      label: AddIntegrationLabelEnum.SchoolName,
      value: schoolNameStringValue ?? '',
      options: {
        withForm: false,
        disabled: true,
      },
    },
    {
      label: AddIntegrationLabelEnum.Certificate,
      value: certificateName ?? '',
      options: {
        withForm: false,
        disabled: true,
      },
    },
  ];
};

import {config} from 'config';
import {ApiPathsEnum} from 'enums';
import {IHttpResponse} from './models';
import {get} from './http-client';
import {axiosApiInstance} from 'api';

import {
  IGetCriticalIssuesApiResponse,
  TGetImportInstructionalTextApiResponse,
  TGetImportTemplateApiResponse,
  TGetItemBankImportApiResponse,
  TGetItemBankVerificationApiResponse,
  TGetUploadItemBankApiResponse,
} from '../models/library/items-import';
import {getApiMultipartFormDataHeadersConfig, returnQueryStringWithParams} from 'utils';

const {jaEeApi: apiConfig} = config;

export const itemImportApi = {
  async getImportTemplate(): Promise<IHttpResponse<TGetImportTemplateApiResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM_IMPORT}/GetImportTemplate`;
    return await get<TGetImportTemplateApiResponse>(url);
  },

  async getImportInstructionalText(): Promise<IHttpResponse<TGetImportInstructionalTextApiResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM_IMPORT}/GetInstructionalText`;
    return await get<TGetImportInstructionalTextApiResponse>(url);
  },

  async getImportCriticalIssues(itemImportId: number): Promise<IHttpResponse<IGetCriticalIssuesApiResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM_IMPORT}/GetCriticalIssues`;
    const queryString = returnQueryStringWithParams(url, {
      itemImportId,
    });

    return await get<IGetCriticalIssuesApiResponse>(queryString);
  },

  async uploadItemBank(data: FormData): Promise<IHttpResponse<TGetUploadItemBankApiResponse>> {
    const config = getApiMultipartFormDataHeadersConfig();
    const baseUrl = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM_IMPORT}/UploadItemBank`;
    return axiosApiInstance.post(baseUrl, data, config);
  },

  async getItemBankVerification(itemImportId: number): Promise<IHttpResponse<TGetItemBankVerificationApiResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM_IMPORT}/VerifyItemBank`;
    const queryString = returnQueryStringWithParams(url, {
      itemImportId,
    });

    return await get<TGetItemBankVerificationApiResponse>(queryString);
  },

  async startItemBankImport(itemImportId: number): Promise<IHttpResponse<TGetItemBankImportApiResponse>> {
    const url = `${apiConfig.baseUrlSurvey}/${ApiPathsEnum.ITEM_IMPORT}/StartItemImport`;
    const queryString = returnQueryStringWithParams(url, {
      itemImportId,
    });

    return await get<TGetItemBankImportApiResponse>(queryString);
  },
};

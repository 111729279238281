import React, {useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';

import {Answers} from 'admin/library/components';
import {BuilderItemDragTypeEnum} from 'admin/library/enums';
import {IBuilderDragItem} from 'admin/library/models';
import {Card, Row, Text} from 'components-lib';
import {DropContainer} from 'dragAndDrop';
import {IInstrumentItem} from 'models';
import {useBuilderDrop, useBuilderItemsEntities} from '../../hooks';
import {CardBody} from '../CardItem/CardBody';
import {DroppableArea} from '../DroppableArea/DroppableArea';
import {MatrixQuestionItem} from '../MatrixQuestionItem/MatrixQuestionItem';
import {cloneDeep} from 'utils';

interface IBuilderMatrixProps {
  id: number;
  position: number;
  applyClickHandler: () => void;
  moveRowHandler: (id: number) => (dragIdx: number, hoverIdx: number) => void;
  deleteRowHandler: (id: number) => (rowIdx: number) => void;
  handleOpenModalLink?: () => void;
  handleOpenInfoLinkModal?: () => void;
  openInfoLinkClickHandler?: () => void;
  setCurrentBuilderItem?: (item: IBuilderDragItem) => void;
  currentBuilderItem?: IBuilderDragItem;
}

export function BuilderMatrix({
  id,
  position,
  applyClickHandler,
  moveRowHandler,
  deleteRowHandler,
  handleOpenModalLink,
  setCurrentBuilderItem,
  handleOpenInfoLinkModal,
}: IBuilderMatrixProps) {
  const classes = useStyles();
  const {builderItemsEntities} = useBuilderItemsEntities();
  const item: IInstrumentItem = builderItemsEntities[id];
  const {drop, isActive} = useBuilderDrop();

  const rowsBuilderItems: IBuilderDragItem[] = useMemo(
    () =>
      item.rows
        ? item.rows.map((row) => ({
            questionId: row.questionId,
            questionType: null, // TODO row.questionType
            answerStackId: row.questionAnswerStackId,
            type: BuilderItemDragTypeEnum.builderItem,
            text: row.text,
            position: row.position,
            linkedQuestionAnswerStackId: row.linkedQuestionAnswerStackId,
          }))
        : [],
    [item.rows]
  );

  const droppableAreaText = useMemo(
    () => (
      <>
        <Text.Heading as="h3">Drag and Drop child items here</Text.Heading>
        <Text.Caption>(child items must have the same rating scale as the Matrix Header)</Text.Caption>
      </>
    ),
    []
  );

  const handleLinkClickHandler = (itemRow: IBuilderDragItem) => {
    const parentItem = cloneDeep(builderItemsEntities[id]);
    parentItem['childQuestionAnswerStackId'] = itemRow.questionId;

    setCurrentBuilderItem(parentItem);
    handleOpenModalLink();
  };

  const openInfoLinkHandler = useCallback(
    (item) => {
      setCurrentBuilderItem(item);
      handleOpenInfoLinkModal();
    },
    [setCurrentBuilderItem, handleOpenInfoLinkModal]
  );

  return (
    <>
      {item && (
        <Card className={classes.root}>
          <CardBody
            id={item.questionAnswerStackId}
            title={item.title}
            type={item.type}
            position={position}
            withApply
            applyClickHandler={applyClickHandler}
          />
          <Row justify="flex-start" classes={{root: classes.answers}}>
            <Answers answers={item.columns} questionId={item.questionId} type={item.type} />
          </Row>
          <DropContainer drop={drop}>
            {!item.rows || !item.rows.length ? (
              <DroppableArea isActive={isActive}>{droppableAreaText}</DroppableArea>
            ) : (
              <>
                <div className={classes.droppableArea}>
                  <DroppableArea isActive={isActive}>
                    {rowsBuilderItems.map((itemRow: IBuilderDragItem, idx: number) => (
                      <MatrixQuestionItem
                        key={idx}
                        matrixItem={{...itemRow, position: idx}}
                        position={idx}
                        moveHandler={moveRowHandler(item.questionAnswerStackId)}
                        deleteHandler={deleteRowHandler(item.questionAnswerStackId)}
                        withLink={!!handleOpenModalLink}
                        linkClickHandler={handleLinkClickHandler}
                        openInfoLinkClickHandler={() => {
                          !!setCurrentBuilderItem && openInfoLinkHandler(itemRow);
                        }}
                      />
                    ))}
                    {droppableAreaText}
                  </DroppableArea>
                </div>
              </>
            )}
          </DropContainer>
        </Card>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
  answers: (isMatrix: boolean) => ({
    marginLeft: isMatrix ? 0 : theme.spacing(4),
  }),
  droppableArea: {
    position: 'relative',
  },
}));

import React, {FC, Fragment, useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Prompt} from 'react-router-dom';
import {useBeforeunload} from 'react-beforeunload';
import {importItemsMessages, useItemsImport} from 'admin/items-import';
import {Loading, Wrapper} from 'components-lib';
import {Subtitle} from 'admin/components';
import {DownloadIssuesButton, FilePreviewTable, IssuesWarningBox} from './components';
import {footerHeight, navHeight} from 'utils/layout';

export const ItemsImportFilePreview: FC = () => {
  const classes = useStyles();
  const {importing, fetchItemBankPreviewTableRows, previewLoading, itemImportCriticalIssueCount} = useItemsImport();
  const shouldRenderDownloadCriticalIssuesButton = itemImportCriticalIssueCount > 0;

  useEffect(() => {
    fetchItemBankPreviewTableRows();
  }, [fetchItemBankPreviewTableRows]);

  useBeforeunload(() => importItemsMessages.warning);

  const LoadingMemoized = useMemo(
    () => () => (
      <Wrapper className={classes.loadingContainer}>
        <Loading />
      </Wrapper>
    ),
    [classes.loadingContainer]
  );

  const actionButton = useMemo(
    () => <Fragment>{shouldRenderDownloadCriticalIssuesButton && <DownloadIssuesButton />}</Fragment>,
    [shouldRenderDownloadCriticalIssuesButton]
  );

  return (
    <Wrapper className={classes.itemsImportFilePreviewContainer}>
      <Prompt when={true} message={importItemsMessages.itemsImportWarning} />
      {previewLoading ? (
        <LoadingMemoized />
      ) : (
        <Fragment>
          {shouldRenderDownloadCriticalIssuesButton && <IssuesWarningBox />}
          <Subtitle withColor={false} as="h3" color="textPrimary" title="File preview" actions={actionButton} />
          <Prompt when={importing} message={importItemsMessages.itemsImportWarning} />
          <FilePreviewTable />
        </Fragment>
      )}
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    height: `calc(100vh - ${navHeight}px - ${footerHeight}px)`,
    width: '100%',
  },
  itemsImportFilePreviewContainer: {
    paddingRight: theme.spacing(3),
  },
}));

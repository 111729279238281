import React, {useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {Accordion, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {Form} from 'forms';
import {useAppDispatch} from 'hooks';
import {IWithDisabled} from 'models';
import {fetchLanguagesThunk, filtersLanguagesEntitiesCollectionSelector} from 'filters-selections/store';

export function LanguagesFilter({disabled}: IWithDisabled) {
  const dispatch = useAppDispatch();
  const languages = useSelector(filtersLanguagesEntitiesCollectionSelector);

  useEffect(() => {
    if (!languages.length) {
      dispatch(fetchLanguagesThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const options = useMemo(() => languages.map((language) => ({value: language.id, label: language.name})), [languages]);

  return (
    <Accordion heading="Languages">
      {languages && languages.length ? (
        <Form.SelectAutocomplete name={FormNameEnum.languages} options={options} multiple disabled={disabled} />
      ) : (
        <Text.Caption>No Data</Text.Caption>
      )}
    </Accordion>
  );
}

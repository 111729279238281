import React, {FC, useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {Form} from 'forms';
import {Filters} from 'components-lib';
import {FiltersList} from 'filters-selections';
import {integrationFilterList} from '../../utils';
import {IntegrationsFilterNamesEnum, useIntegrations} from 'admin';
import {integrationsActions as actions} from '../../../store/integrations.slice';
import {IIntegratiosAllFilter} from 'models';
import {IIntegrationsAllFiltersFormValues, integrationsAllValidationSchema} from './config';

export const SidebarIntegrationsAll: FC = () => {
  const dispatch = useDispatch();
  const {filter, pageLoading} = useIntegrations();

  const initialValues: IIntegrationsAllFiltersFormValues = useMemo(
    () => ({
      [IntegrationsFilterNamesEnum.INTEGRATION_TYPE]: filter[IntegrationsFilterNamesEnum.INTEGRATION_TYPE],
      [IntegrationsFilterNamesEnum.INTEGRATION_STATUSES]: filter[IntegrationsFilterNamesEnum.INTEGRATION_STATUSES],
      [IntegrationsFilterNamesEnum.AREAS]: filter[IntegrationsFilterNamesEnum.AREAS],
      [IntegrationsFilterNamesEnum.SCHOOL_NAMES]: filter[IntegrationsFilterNamesEnum.SCHOOL_NAMES],
      [IntegrationsFilterNamesEnum.SCHOOL_DISTRICTS]: filter[IntegrationsFilterNamesEnum.SCHOOL_DISTRICTS],
      [IntegrationsFilterNamesEnum.SEARCH_BY_NAME]: filter[IntegrationsFilterNamesEnum.SEARCH_BY_NAME],
    }),
    [filter]
  );

  const resetHandler = useCallback(() => {
    dispatch(actions.resetFilterIntegrations());
  }, [dispatch]);

  const mapIntegrationAllFilters = useCallback(
    ({
      integrationType,
      integrationStatuses,
      areas,
      schoolNames,
      schoolDistricts,
      name,
    }: IIntegrationsAllFiltersFormValues): IIntegratiosAllFilter => ({
      [IntegrationsFilterNamesEnum.INTEGRATION_TYPE]: integrationType,
      [IntegrationsFilterNamesEnum.INTEGRATION_STATUSES]: integrationStatuses,
      [IntegrationsFilterNamesEnum.AREAS]: areas,
      [IntegrationsFilterNamesEnum.SCHOOL_NAMES]: schoolNames,
      [IntegrationsFilterNamesEnum.SCHOOL_DISTRICTS]: schoolDistricts,
      [IntegrationsFilterNamesEnum.SEARCH_BY_NAME]: name,
    }),
    []
  );

  const submitHandler = async (formValues: IIntegrationsAllFiltersFormValues) => {
    const filter: IIntegratiosAllFilter = mapIntegrationAllFilters(formValues);
    dispatch(actions.setFilterIntegrations(filter));
  };

  const renderFiltersList = useMemo(() => <FiltersList disableAll={pageLoading} filters={integrationFilterList} />, [
    pageLoading,
  ]);

  return (
    <Form.Form<IIntegrationsAllFiltersFormValues>
      initialValues={initialValues}
      validationSchema={integrationsAllValidationSchema}
      submitHandler={submitHandler}
    >
      <Filters.FiltersPanel
        clearButtonClickHandler={resetHandler}
        searchFieldName={IntegrationsFilterNamesEnum.SEARCH_BY_NAME}
        filters={renderFiltersList}
      />
    </Form.Form>
  );
};

import {IActionFulfilled, IProgramClassesFilter, IProgramClassesSortOrder} from 'models';
import {cloneDeep} from 'utils';

import {IProgramClassesState} from './IProgramClassesState';
import {programClassesInitialState} from './programClasses.initialState';

export const programClassesReducer = {
  setFilter: (state: IProgramClassesState, action: IActionFulfilled<null, IProgramClassesFilter>) => {
    state.filter = action.payload;
    resetPagination(state);
  },

  resetFilter: (state: IProgramClassesState) => {
    state.filter = cloneDeep(programClassesInitialState.filter);
    resetPagination(state);
  },

  setCurrentPage: (state: IProgramClassesState, action: IActionFulfilled<null, number>) => {
    state.pagination.currentPage = action.payload;
  },

  setPerPage: (state: IProgramClassesState, action: IActionFulfilled<null, number>) => {
    state.pagination.perPage = action.payload;
    resetPagination(state);
  },

  setSortOrderProgramClasses: (
    state: IProgramClassesState,
    action: IActionFulfilled<null, IProgramClassesSortOrder>
  ) => {
    state.sortOrder = action.payload;
    resetPagination(state);
  },
};

const resetPagination = (state: IProgramClassesState) => {
  state.pagination.currentPage = programClassesInitialState.pagination.currentPage;
  state.pagination.totalCount = programClassesInitialState.pagination.totalCount;
};

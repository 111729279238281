import React, {FC, useEffect} from 'react';
import {
  IntegrationConfirmCheckbox,
  useIntegration,
  getEditorPermissionCheckboxText,
  getIsNotIntegrationFailedOrHasError,
} from 'admin/integrations';
import {FormNameEnum} from 'enums/form';
import {useFormikContext} from 'formik';
import {IIntegrationDetailsFormBaseValues} from 'models/integrations';
import {IWithDisabled} from 'models';

type IEditorPermissionsCheckboxProps = IWithDisabled;

export const EditorPermissionsCheckbox: FC<IEditorPermissionsCheckboxProps> = ({disabled}) => {
  const {
    values: {googleDriveSourceFolderLink, googleDriveDestinationFolderLink, serviceEmail},
    setFieldValue,
  } = useFormikContext<IIntegrationDetailsFormBaseValues>();
  const {integration} = useIntegration();

  const checkboxText = getEditorPermissionCheckboxText(serviceEmail);
  const isNotTestingFailedOrErrorOrPending = getIsNotIntegrationFailedOrHasError(integration.status);
  const shouldDisableConfirmCheckbox = disabled || isNotTestingFailedOrErrorOrPending;

  useEffect(() => {
    return () => {
      if (serviceEmail) {
        setFieldValue(FormNameEnum.canGiveEditorPermissions, false);
      }
    };
  }, [serviceEmail, setFieldValue]);

  useEffect(() => {
    return () => {
      if (googleDriveSourceFolderLink || googleDriveDestinationFolderLink) {
        setFieldValue(FormNameEnum.canGiveEditorPermissions, false);
      }
    };
  }, [googleDriveSourceFolderLink, googleDriveDestinationFolderLink, setFieldValue]);

  return (
    <IntegrationConfirmCheckbox
      text={checkboxText}
      name={FormNameEnum.canGiveEditorPermissions}
      showError={false}
      disabled={shouldDisableConfirmCheckbox}
    />
  );
};

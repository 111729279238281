import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Form} from 'forms';
import {Accordion, Column, Text} from 'components-lib';
import {IOption, IWithDisabled} from 'models';
import {FormNameEnum, filtersAreasEntitiesCollectionSelector, fetchAreasThunk} from 'filters-selections';
import {useAppDispatch} from 'hooks';

type IAreasMultiAutocompleteFilterProps = IWithDisabled;

export const AreasMultiAutocompleteFilter = ({disabled}: IAreasMultiAutocompleteFilterProps) => {
  const dispatch = useAppDispatch();
  const areas = useSelector(filtersAreasEntitiesCollectionSelector);

  useEffect(() => {
    if (!areas.length) {
      dispatch(fetchAreasThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const options: IOption[] = useMemo(() => {
    return areas.map((area) => ({value: area.id, label: area.name}));
  }, [areas]);

  return (
    <Accordion heading="JA Areas">
      <Column>
        {areas.length ? (
          <Form.SelectAutocomplete name={FormNameEnum.areas} options={options} multiple disabled={disabled} />
        ) : (
          <Text.Caption>No Areas</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
};

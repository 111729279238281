import React, {Fragment, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Subtitle} from 'admin';
import {Column, Container, Row, Wrapper} from 'components-lib';
import {MicrosoftTeamsContent} from './components';
import {heightThreeStepsActivity} from 'utils/layout';

export const AddIntegrationMicrosoftTeams = () => {
  const classes = useStyles();

  const content = useMemo(() => {
    return (
      <Fragment>
        <Subtitle withColor={false} as="h3" color="textPrimary" title="Step 2: Microsoft Teams" />
        <Container disableGutters classes={{root: classes.content}}>
          <Row align="flex-start" justify="flex-start" classes={{root: classes.row}}>
            <Column sm={8} md={8} lg={8} xl={9} className={classes.col}>
              <Row align="flex-start" justify="flex-start">
                <MicrosoftTeamsContent />
              </Row>
            </Column>
          </Row>
        </Container>
      </Fragment>
    );
  }, [classes.row, classes.col, classes.content]);

  return <Wrapper className={classes.content}>{content}</Wrapper>;
};

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(2),
  },
  content: {
    height: heightThreeStepsActivity,
  },
  col: {
    paddingRight: theme.spacing(4),
  },
}));

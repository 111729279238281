import React, {FC, useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import {Wrapper} from 'components-lib';
import {ActiveHeader} from '../ActiveHeader/ActiveHeader';

interface INavigationProgressBarProps {
  isFirstStep: boolean;
  isSecondStep: boolean;
  isThirdStep: boolean;
  stepOneText: string;
  stepTwoText: string;
  stepThreeText: string;
}

export const NavigationProgressBar: FC<INavigationProgressBarProps> = ({
  isFirstStep,
  isSecondStep,
  isThirdStep,
  stepOneText,
  stepTwoText,
  stepThreeText,
}) => {
  const classes = useStyles();
  const addSpacingToText = useCallback((text: string) => `\u00a0 ${text} \u00a0`, []);

  const stepOne = addSpacingToText(stepOneText);
  const stepTwo = addSpacingToText(stepTwoText);
  const stepThree = addSpacingToText(stepThreeText);

  return (
    <Wrapper className={classes.progressBar}>
      {'\u00a0 /'}
      <ActiveHeader isActive={isFirstStep}>{stepOne}</ActiveHeader>
      {'/'}
      <ActiveHeader isActive={isSecondStep}>{stepTwo}</ActiveHeader>
      {'/'}
      <ActiveHeader isActive={isThirdStep}>{stepThree}</ActiveHeader>
    </Wrapper>
  );
};

const useStyles = makeStyles(() => ({
  progressBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

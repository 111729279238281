import React, {FC, Dispatch, SetStateAction, useCallback, useMemo, MutableRefObject} from 'react';
import {batch} from 'react-redux';
import {Form} from 'forms';
import {FormikHelpers, FormikProps, FormikValues} from 'formik';
import {IWithChildren} from 'models/common';
import {learningPathViewEditFormBaseValidationSchema, ILearningPathViewEditFormBaseValues} from './config';
import {useEditLearningPath} from '../../hooks';
import {mapEditLearningPathPayload} from '../../utils';

interface IEditLearningPathDetailsFormProps extends IWithChildren {
  setIsDirtyCallback: Dispatch<SetStateAction<boolean>>;
  formikFormRef: MutableRefObject<FormikProps<FormikValues>>;
}

export const EditLearningPathDetailsForm: FC<IEditLearningPathDetailsFormProps> = ({
  children,
  formikFormRef,
  setIsDirtyCallback,
}) => {
  const {learningPathDetails, futureProgramYearsCollection, updateLearningPath, sessions} = useEditLearningPath();

  const submitHandler = useCallback(
    (formValues: ILearningPathViewEditFormBaseValues, {resetForm, setSubmitting}: FormikHelpers<any>) => {
      setIsDirtyCallback(false);
      batch(() => {
        updateLearningPath(formValues);
        resetForm();
        setSubmitting(false);
      });
    },
    [updateLearningPath, setIsDirtyCallback]
  );

  const initialValues = useMemo(() => {
    const initialFormVals =
      learningPathDetails &&
      futureProgramYearsCollection &&
      mapEditLearningPathPayload(learningPathDetails, futureProgramYearsCollection, sessions);
    return initialFormVals;
  }, [learningPathDetails, futureProgramYearsCollection, sessions]);

  return (
    <Form.Form<ILearningPathViewEditFormBaseValues>
      initialValues={initialValues}
      validationSchema={learningPathViewEditFormBaseValidationSchema}
      submitHandler={submitHandler}
      formikRef={formikFormRef}
    >
      {children}
    </Form.Form>
  );
};

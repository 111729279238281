import React from 'react';
import {Files} from 'admin/resources/files';
import {NoPermissionsPage} from 'pages';
import {pages, paths} from 'paths';
import {AdminRoles, USAAndSuperAdminRoles, withRole} from 'permissions';
import {Redirect, Route, Switch} from 'react-router-dom';
import {ResourcesNavBar} from './components';
import {GoogleSites} from './google-sites';
import {LearningPaths} from './learning-paths';
import {UploadResources} from './upload';

const AdminPortalGoogleSitesAllEnhanced = withRole(
  USAAndSuperAdminRoles,
  `${paths.adminPortal.root}${paths.noPermission}`
)(GoogleSites);

const AdminPortalLearningPathsAllEnhanced = withRole(
  AdminRoles,
  `${paths.adminPortal.root}${paths.noPermission}`
)(LearningPaths);

export function Resources() {
  return (
    <>
      <ResourcesNavBar />
      <Switch>
        <Route exact path={pages.adminPortal.resources} render={() => <Redirect to={pages.adminPortal.files.root} />} />
        <Route path={pages.adminPortal.files.root} component={Files} />
        <Route path={pages.adminPortal.learningPaths.root}>
          <AdminPortalLearningPathsAllEnhanced />
          <Route path={`${pages.adminPortal.learningPaths.root}${paths.noPermission}`}>
            <NoPermissionsPage />
          </Route>
        </Route>
        <Route path={pages.adminPortal.googleSites.root}>
          <AdminPortalGoogleSitesAllEnhanced />
          <Route path={`${pages.adminPortal.googleSites.root}${paths.noPermission}`}>
            <NoPermissionsPage />
          </Route>
        </Route>
        <Route path={pages.adminPortal.upload.root} component={UploadResources} />
      </Switch>
    </>
  );
}

import {FormHelperText, makeStyles} from '@material-ui/core';
import {IComponentWithChildren} from 'models';
import React from 'react';
interface IErrorTextProps extends IComponentWithChildren {
  withBottomMargin?: boolean;
}

export function ErrorText({children, withBottomMargin = true}: IErrorTextProps) {
  const classes = useStyles({withBottomMargin});
  return <FormHelperText classes={classes}>{children}</FormHelperText>;
}

const useStyles = makeStyles((theme) => ({
  root: ({withBottomMargin}: {withBottomMargin: boolean}) => {
    let styles: any = {
      color: theme.palette.error.main,
    };

    if (withBottomMargin) {
      styles = {...styles, marginBottom: theme.spacing(2)};
    }

    return styles;
  },
}));

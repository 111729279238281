import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {DragAndDropProvider} from 'dragAndDrop';
import {pages, paths} from 'paths';
import {AllRolesWithoutStudent, withRole} from 'permissions';
import {InstrumentsAllView} from './all';
import {InstrumentBuilderView} from './builder';
import {InstrumentDetailsView} from './details';
import {AdminRolesWithLocalAreaInstruments} from './enums';
import {InstrumentPreviewView} from './preview';
import {InstrumentPreviewWithAnswersView} from './previewWithAnswers';

const InstrumentBuilderViewEnhanced = withRole(
  AdminRolesWithLocalAreaInstruments,
  `${paths.adminPortal.root}${paths.noPermission}`
)(InstrumentBuilderView);

const InstrumentPreviewWithAnswersEnhanced = withRole(AllRolesWithoutStudent, '')(InstrumentPreviewWithAnswersView);

export function Instruments() {
  return (
    <DragAndDropProvider>
      <Switch>
        <Route exact path={pages.adminPortal.instruments.all} component={InstrumentsAllView} />
        <Route path={pages.adminPortal.instruments.preview} component={InstrumentPreviewView} />
        <Route
          path={pages.adminPortal.instruments.previewWithAnswers}
          component={InstrumentPreviewWithAnswersEnhanced}
        />
        <Route path={pages.adminPortal.instruments.build} component={InstrumentBuilderViewEnhanced} />
        <Route path={pages.adminPortal.instruments.details} component={InstrumentDetailsView} />
      </Switch>
    </DragAndDropProvider>
  );
}

import React, {FC, Fragment, useCallback} from 'react';
import {SessionResourceDragListItem} from '..';
import {ILearningPathSessionItem} from 'models/resources';
import {NoResources} from 'admin/resources/learning-paths/components';
import {useSessionBuilderStoreActions} from 'admin/resources/learning-paths/views/session-builder';

interface ISessionResourceListEditProps {
  item: ILearningPathSessionItem;
  isEditMode: boolean;
  isResource: boolean;
}

export const SessionResourceListEdit: FC<ISessionResourceListEditProps> = ({item, isEditMode, isResource = false}) => {
  const {moveSessionResourceItem} = useSessionBuilderStoreActions();

  const moveResource = useCallback(
    (movedItemIdx: number, newPosition: number) => moveSessionResourceItem(movedItemIdx, newPosition, item.id),
    [moveSessionResourceItem, item.id]
  );

  return (
    <Fragment>
      {item?.resources?.length ? (
        item?.resources?.map((resourceItem, idx: number) => (
          <SessionResourceDragListItem
            key={idx}
            index={idx}
            itemId={item?.id}
            resourceItem={resourceItem}
            canDelete={isEditMode && true}
            isEditMode={isEditMode}
            isResource={isResource}
            moveResource={moveResource}
          />
        ))
      ) : (
        <NoResources />
      )}
    </Fragment>
  );
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {itemsApi} from 'api';
import {IDefaultAnswerStackPayload, IQuestionBase} from 'models';

import {toastNotificationManager} from 'toast-notifications';
import {itemsMessages} from '../../utils';
import {prefix} from './config';

export const addDefaultAnswerStackThunk: any = createAsyncThunk<IQuestionBase, IDefaultAnswerStackPayload>(
  `${prefix} addDefaultAnswerStack`,
  async (payload: IDefaultAnswerStackPayload, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await itemsApi.addDefaultAnswerStack(payload);
      dispatch(toastNotificationManager.createSuccessToastAction(itemsMessages.updateSuccess));
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

import {ToastNotificationTypeEnum, useToastNotifications} from 'toast-notifications';
import {useEffect} from 'react';
import {QuestionAnswersTypeEnum} from 'enums';

export function useEntityCard(answersComponentMap: any, type: QuestionAnswersTypeEnum, questionId: number) {
  const {addToastNotification} = useToastNotifications();

  useEffect(() => {
    if (!answersComponentMap[type]) {
      const message = `Item ID: ${questionId} Unknown question answer type: ${type}`;

      addToastNotification(message, ToastNotificationTypeEnum.error);
    }
  }, [questionId, type, addToastNotification, answersComponentMap]);

  return;
}

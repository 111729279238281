import {makeStyles} from '@material-ui/core';
import {Accordion, Button, Checkbox, Column, Icon, Row, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections';
import {ArrayHelpers} from 'formik';
import {Form} from 'forms';
import {IAnswerStackCustom} from 'models';
import React, {SyntheticEvent} from 'react';
import {createEmptyAnswer} from '../../utils';

interface IItemAnswerStackCustomProps {
  stack: IAnswerStackCustom;
  idx: number;
  removeClickHandler: (event: SyntheticEvent) => void;
}

export function ItemAnswerStackCustom({stack, idx, removeClickHandler}: IItemAnswerStackCustomProps) {
  const classes = useStyles();

  return (
    <Accordion
      heading={<Text.Heading as="h4">{stack.title || 'Custom Stack'}</Text.Heading>}
      headingActionLeft={<Checkbox checked disabled />}
      headingActionRight={
        <Button.Icon clickHandler={removeClickHandler}>
          <Icon.DeleteIcon />
        </Button.Icon>
      }
      withBorder
      initialIsExpanded
    >
      <Column sm={11} md={11} lg={11} xl={11}>
        <Column className={classes.section}>
          <Text.Caption>Response Type</Text.Caption>
          <Form.RadioGroup name={`${FormNameEnum.customStacks}.${idx}.questionType`}>
            <Row justify="flex-start">
              <Form.Radio value={10} label="Single Select" />
              <Form.Radio value={20} label="Multiple Select" />
            </Row>
          </Form.RadioGroup>
          <Text.Paragraph color="primary">
            You should provide text to all answers and select the correct one.
          </Text.Paragraph>
        </Column>

        <Form.Array name={`${FormNameEnum.customStacks}[${idx}].choices`}>
          {(arrayHelpers: ArrayHelpers) => (
            <>
              {stack.choices.map((choice, choiceIdx) => {
                const choiceNameBase = `${FormNameEnum.customStacks}[${idx}].choices[${choiceIdx}]`;

                return (
                  <Row key={choiceIdx} justify="flex-start" classes={{root: classes.answerRow}}>
                    <Form.Checkbox name={`${choiceNameBase}.isCorrect`} checked={choice?.isCorrect} />

                    <div className={classes.choiceInput}>
                      <Form.Input name={`${choiceNameBase}.text`} value={choice.text} />
                    </div>
                    <Button.Icon
                      clickHandler={() => arrayHelpers.remove(choiceIdx)}
                      disabled={stack.choices.length === 1}
                    >
                      <Icon.DeleteIcon />
                    </Button.Icon>
                  </Row>
                );
              })}
              <div className={classes.addAnswerButton}>
                <Button.Secondary
                  size="small"
                  clickHandler={() => arrayHelpers.insert(stack.choices.length, createEmptyAnswer())}
                >
                  Add Answer
                </Button.Secondary>
              </div>
            </>
          )}
        </Form.Array>
      </Column>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(4),
  },
  titleInput: {
    display: 'flex',
    flexGrow: 2,
    width: '100%',
    marginTop: theme.spacing(1),
    paddingRight: 26,
  },
  choiceInput: {
    display: 'flex',
    flexGrow: 2,
  },
  addButton: {
    marginTop: theme.spacing(1),
    marginLeft: 42,
  },
  answerRow: {
    marginBottom: theme.spacing(2),
  },
  addAnswerButton: {
    width: 130,
    marginTop: theme.spacing(2),
  },
}));

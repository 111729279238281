import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {IAnswer, IToggleOption} from 'models';
import {mapToggleButtonOptions} from 'admin/library/utils';
import {ToggleButtonGroup, ToggleButton} from '@material-ui/lab';

interface IAnswerBuilderRatingProps {
  answers: IAnswer[];
  name?: string;
  withSmallButtons?: boolean;
}

export const AnswerBuilderRating: FC<IAnswerBuilderRatingProps> = ({answers, withSmallButtons}) => {
  const classes = useStyles({withSmallButtons});
  const options = mapToggleButtonOptions(answers) as IToggleOption[];

  return (
    <div className={classes.ratingAnswer}>
      <ToggleButtonGroup aria-label="toggle">
        {options.map((option, idx: number) => (
          <ToggleButton
            className={classes.toggleButton}
            key={`button-${idx}`}
            value={option.value}
            aria-label={option.label}
            disabled
            selected={option.selected}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  ratingAnswer: {
    height: theme.spacing(5),
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  toggleButton: ({withSmallButtons}: Pick<IAnswerBuilderRatingProps, 'withSmallButtons'>) => {
    let styles = {
      padding: 7.7,
      color: theme.palette.grey[900],
      fontWeight: 700,
      border: `1px solid ${theme.palette.grey[300]}`,
      '&.Mui-selected': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.secondary.main,
      },
      '&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.secondary.main,
      },
      '& span': {
        width: 10,
      },
    };

    if (withSmallButtons) {
      styles = {...styles, padding: 5};
    }

    return styles;
  },
}));

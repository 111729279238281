import {useState} from 'react';
import {BuilderEditModeEnum} from '../enums';

export interface IBuilderEditMode {
  questionAnswerStackId: number | null;
  defaultAnswerStackId: number | null;
  position: number | null;
  mode: BuilderEditModeEnum;
}

const defaultBuilderEditModeAllTypes = {
  questionAnswerStackId: null,
  defaultAnswerStackId: null,
  position: null,
  mode: BuilderEditModeEnum.allTypes,
};

export function useBuilderEditMode() {
  const [builderEditMode, setBuilderEditMode] = useState<IBuilderEditMode>(defaultBuilderEditModeAllTypes);

  const isAllTypesMode = builderEditMode.mode === BuilderEditModeEnum.allTypes;
  const isEditMatrixMode = builderEditMode.mode === BuilderEditModeEnum.matrix;
  const isEditInstructionalText = builderEditMode.mode === BuilderEditModeEnum.instructionalText;

  return {
    defaultBuilderEditModeAllTypes,
    builderEditMode,
    setBuilderEditMode,
    isAllTypesMode,
    isEditMatrixMode,
    isEditInstructionalText,
  };
}

import {
  fetchImportCriticalIssuesThunk,
  fetchImportInstructionalTextThunk,
  fetchImportItemsTemplateThunk,
  fetchItemBankPreviewTableRowsThunk,
  importItemsThunk,
  uploadItemBankThunk,
} from './thunks';
import {IItemsImportStoreState} from './IItemsImportStoreState';
import {
  IActionFulfilled,
  IGetUpdateItemBankApiResponse,
  IImportInstructionalText,
  IItemsImportFieldDetails,
  TGetItemBankVerificationApiResponse,
} from 'models';
import {isObject} from 'utils';

export const itemsImportExtraReducer = {
  //fetchImportItemsTemplateThunk
  [fetchImportItemsTemplateThunk.fulfilled]: (state: IItemsImportStoreState) => {
    state.page.downloading = false;
  },
  [fetchImportItemsTemplateThunk.pending]: (state: IItemsImportStoreState) => {
    state.page.downloading = true;
  },
  [fetchImportItemsTemplateThunk.rejected]: (state: IItemsImportStoreState) => {
    state.page.downloading = false;
  },

  //fetchImportInstructionalTextThunk
  [fetchImportInstructionalTextThunk.fulfilled]: (
    state: IItemsImportStoreState,
    action: IActionFulfilled<null, IImportInstructionalText>
  ) => {
    if (isObject(action.payload)) {
      state.templateInstructionalText = action.payload.value;
    }

    state.page.loading = false;
  },
  [fetchImportInstructionalTextThunk.pending]: (state: IItemsImportStoreState) => {
    state.page.loading = true;
  },
  [fetchImportInstructionalTextThunk.rejected]: (state: IItemsImportStoreState) => {
    state.page.loading = false;
  },

  //uploadItemBankThunk
  [uploadItemBankThunk.fulfilled]: (
    state: IItemsImportStoreState,
    action: IActionFulfilled<null, IGetUpdateItemBankApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {validationResults, fileName, itemImportId} = action.payload;
      const requiredFieldErrors = getRequiredFieldErrors(validationResults);
      const hasRequiredFieldErrors = !!requiredFieldErrors && requiredFieldErrors.length > 0;

      state.mapping.itemImportId = itemImportId;
      state.mapping.fieldList = validationResults;
      state.mapping.fileName = fileName;
      state.mapping.hasRequiredFieldErrors = !!validationResults && hasRequiredFieldErrors;
    }

    state.page.uploading = false;
  },
  [uploadItemBankThunk.pending]: (state: IItemsImportStoreState) => {
    state.page.uploading = true;
  },
  [uploadItemBankThunk.rejected]: (state: IItemsImportStoreState) => {
    state.page.uploading = false;
  },

  // fetchItemBankPreviewTableRowsThunk
  [fetchItemBankPreviewTableRowsThunk.fulfilled]: (
    state: IItemsImportStoreState,
    action: IActionFulfilled<null, TGetItemBankVerificationApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {criticalIssueCount, previewRows} = action.payload;

      state.preview.criticalIssueCount = criticalIssueCount;
      state.preview.previewRows = [...previewRows];
    }

    state.preview.loading = false;
  },
  [fetchItemBankPreviewTableRowsThunk.pending]: (state: IItemsImportStoreState) => {
    state.preview.loading = true;
  },
  [fetchItemBankPreviewTableRowsThunk.rejected]: (state: IItemsImportStoreState) => {
    state.preview.loading = false;
  },

  // fetchImportCriticalIssuesThunk
  [fetchImportCriticalIssuesThunk.fulfilled]: (state: IItemsImportStoreState) => {
    state.preview.downloadingCriticalIssues = false;
  },
  [fetchImportCriticalIssuesThunk.pending]: (state: IItemsImportStoreState) => {
    state.preview.downloadingCriticalIssues = true;
  },
  [fetchImportCriticalIssuesThunk.rejected]: (state: IItemsImportStoreState) => {
    state.preview.downloadingCriticalIssues = false;
  },

  // importItemsThunk
  [importItemsThunk.fulfilled]: (state: IItemsImportStoreState) => {
    state.page.importing = false;
  },
  [importItemsThunk.pending]: (state: IItemsImportStoreState) => {
    state.page.importing = true;
  },
  [importItemsThunk.rejected]: (state: IItemsImportStoreState) => {
    state.page.importing = false;
  },
};

function getRequiredFieldErrors(validationResults) {
  const fieldErrors = validationResults.filter(
    (result: IItemsImportFieldDetails) => !!result.isRequired && !!result.validationError
  );

  return fieldErrors;
}

import React, {FC, Fragment, ReactNode, useMemo} from 'react';
import {FilesEditResource, FilesEditScorm, FilesEditWebLink} from '..';
import {useFileType} from '../../hooks';

export const PickFileMetadata: FC = () => {
  const {isWebLink, isResource, isScormPackage} = useFileType();

  const pickFileTypeMetadata = useMemo(() => {
    let metataComponent: ReactNode = <div />;

    if (isResource) {
      metataComponent = <FilesEditResource />;
    }

    if (isScormPackage) {
      metataComponent = <FilesEditScorm />;
    }

    if (isWebLink) {
      metataComponent = <FilesEditWebLink />;
    }
    return metataComponent;
  }, [isWebLink, isResource, isScormPackage]);

  return <Fragment>{pickFileTypeMetadata}</Fragment>;
};

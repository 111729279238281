import {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAppDispatch} from 'hooks';
import {ALL_AREAS_INDEX} from 'utils';
import {getExportTypes, getReportTypes} from '..';
import {fetchLevelsOfReportingListThunk} from '../store';
import {IProgramClassExportParams} from 'models';

export const useProgramReports = () => {
  const [loading, setIsLoading] = useState<boolean>(false);
  const [selectedAreaId, setSelectedAreaId] = useState<number>(ALL_AREAS_INDEX);

  const {programId, classId} = useParams<IProgramClassExportParams>();
  const dispatch = useAppDispatch();
  const reportTypes = getReportTypes();
  const exportTypes = getExportTypes();

  const fetchProgramLevelsOfReporting = useCallback(
    () => dispatch(fetchLevelsOfReportingListThunk({programId})),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [programId]
  );

  const fetchProgramClassLevelsOfReporting = useCallback(
    () => dispatch(fetchLevelsOfReportingListThunk({classId})),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [programId, classId]
  );

  return {
    loading,
    setIsLoading,
    selectedAreaId,
    setSelectedAreaId,
    reportTypes,
    exportTypes,

    fetchProgramLevelsOfReporting,
    fetchProgramClassLevelsOfReporting,
  };
};

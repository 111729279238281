import {useSelector} from 'react-redux';
import {useCallback} from 'react';
import {useAppDispatch} from 'hooks';
import {googleSitesActions as actions} from '../..';
import {IGoogleSiteDetails} from 'models';
import {
  fetchGoogleSiteListThunk,
  googleSitesEntitiesBaseCollectionSelector,
  googleSitesEntitiesSelector,
  googleSitesListItemIdsSelector,
  pageLoadingSelector,
  paginationSelector,
} from '../../store';

export const useGoogleSitesAll = () => {
  const loading = useSelector(pageLoadingSelector);
  const dispatch = useAppDispatch();

  const {currentPage, perPage, totalCount} = useSelector(paginationSelector);
  const googleSitesListItemIds = useSelector(googleSitesListItemIdsSelector);
  const googleSitesEntities = useSelector(googleSitesEntitiesSelector);
  const googleSitesList = useSelector(googleSitesEntitiesBaseCollectionSelector) as IGoogleSiteDetails[];

  const fetchGoogleSiteList: () => void = useCallback(() => dispatch(fetchGoogleSiteListThunk()), [dispatch]);

  const setCurrentPage: (newPage: number) => void = useCallback(
    (newPage: number) => dispatch(actions.setCurrentPageGoogleSites(newPage)),
    [dispatch]
  );

  const setPerPage: (perPage: number) => void = useCallback(
    (perPage: number) => dispatch(actions.setPerPageGoogleSites(perPage)),
    [dispatch]
  );

  const setTotalCount: (totalCount: number) => void = useCallback(
    (totalCount: number) => dispatch(actions.setTotalCountGoogleSites(totalCount)),
    [dispatch]
  );

  return {
    loading,
    googleSitesList,
    fetchGoogleSiteList,
    googleSitesListItemIds,
    googleSitesEntities,
    setCurrentPage,
    setPerPage,
    setTotalCount,
    currentPage,
    perPage,
    totalCount,
  };
};

import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {useAppDispatch, useNavigate} from 'hooks';
import {pages} from 'paths';
import {useClassPaperAssessmentsStoreActions, useProgramClassDetails} from 'admin/programs/details';
import {
  pageLoadingSelector,
  paperAssessmentsEntitiesCollectionSelector,
  paperAssessmentsEntitiesSelector,
  paperAssessmentsIdsSelector,
} from '../store/programClassesPaperAssessments.selectors';
import {IUploadPaperAssessmentFormBaseValues} from '../components';
import {mapFetchClassPaperAssessmentsPayload, mapUploadPaperAssessmentPayload} from '../utils';
import {fetchClassPaperAssessmentsPagedThunk, uploadPaperAssessmentsThunk, paginationSelector} from '../store';
import {
  IFetchClassPaperAssessmentRawPayload,
  IProgramClassPaperAssessmentListItem,
  IUploadPaperAssessmentsApiResponse,
} from 'models';
import {openFileInNewWindow} from 'utils';

export const useClassPaperAssessments = () => {
  const loading = useSelector(pageLoadingSelector);
  const {currentPage, perPage} = useSelector(paginationSelector);
  const paperAssessmentsIds = useSelector(paperAssessmentsIdsSelector);
  const paperAssessmentsEntities = useSelector(paperAssessmentsEntitiesSelector);
  const paperAssessmentsEntitiesCollection = useSelector(
    paperAssessmentsEntitiesCollectionSelector
  ) as IProgramClassPaperAssessmentListItem[];

  const {push} = useNavigate();
  const dispatch = useAppDispatch();
  const {sortOrder} = useClassPaperAssessmentsStoreActions();
  const {program, currentClass, programId, classId} = useProgramClassDetails();

  const redirectToProgram: () => void = useCallback(
    () => push(`${pages.adminPortal.programs.detailsLink}/${programId}`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [programId]
  );

  const redirectToPaperAssessments: () => void = useCallback(
    () => push(pages.adminPortal.programs.classPaperAssessments),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const uploadPaperAssessment: (
    values: IUploadPaperAssessmentFormBaseValues
  ) => Promise<IUploadPaperAssessmentsApiResponse> = useCallback(
    (values: IUploadPaperAssessmentFormBaseValues) => {
      const payload = mapUploadPaperAssessmentPayload({values, programId, classId});
      return dispatch(uploadPaperAssessmentsThunk(payload)).then(unwrapResult);
    },
    [dispatch, classId, programId]
  );

  const downloadPaperAssessment: (url: string) => void = useCallback((url: string) => openFileInNewWindow(url), []);

  const fetchClassPaperAssessmentsPaged: () => void = useCallback(() => {
    const data = {
      sortOrder,
      pageNumber: currentPage,
      pageSize: perPage,
      classId,
      programId,
    } as IFetchClassPaperAssessmentRawPayload;

    const payload = mapFetchClassPaperAssessmentsPayload(data);

    return dispatch(fetchClassPaperAssessmentsPagedThunk(payload));
  }, [dispatch, currentPage, sortOrder, perPage, classId, programId]);

  return {
    loading,
    paperAssessmentsIds,
    paperAssessmentsEntities,
    paperAssessmentsEntitiesCollection,
    program,
    currentClass,
    redirectToProgram,
    uploadPaperAssessment,
    downloadPaperAssessment,
    redirectToPaperAssessments,
    fetchClassPaperAssessmentsPaged,
  };
};

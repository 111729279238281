import {withStyles} from '@material-ui/core/styles';
import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';

export const GlobalCss = withStyles({
  '@global': {
    'html, body': {
      height: '100vh',
      overflow: 'hidden',
    },
  },
})(() => null);

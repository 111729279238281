import {SelectionEnum} from 'filters-selections';
import {IEditFileMetadataFormBaseValues} from '../components/FilesEditResourceForm/config';

export const getEditFileFirstColumnExtendedList = (list: SelectionEnum[], values: IEditFileMetadataFormBaseValues) => {
  const extendedList = [...list];

  if (!extendedList.includes(SelectionEnum.AdminPermissionCheckbox) && values?.file) {
    extendedList.push(SelectionEnum.AdminPermissionCheckbox);
  } else if (extendedList.includes(SelectionEnum.AdminPermissionCheckbox) && !values?.file) {
    extendedList.pop();
  }

  return extendedList;
};

import {SortingOrderEnum} from 'enums';
import {IFetchFilesAllPagedThunkPayload, IFilesAllFilter, IFilesAllSortOrder} from 'models/resources/files';

export const mapFetchFilesAllPayload = (
  sortOrder: IFilesAllSortOrder,
  pageNumber: number,
  pageSize: number,
  filter: IFilesAllFilter
): IFetchFilesAllPagedThunkPayload => {
  let filterFilesAllPayload = {};

  if (filter.query) {
    filterFilesAllPayload = {query: filter.query};
  }

  if (filter.fileTypes.length > 0) {
    filterFilesAllPayload = {...filterFilesAllPayload, contentTypes: filter.fileTypes};
  }

  if (filter.programs.length > 0) {
    filterFilesAllPayload = {...filterFilesAllPayload, programs: filter.programs};
  }

  if (filter.gradeLevels.length > 0) {
    filterFilesAllPayload = {...filterFilesAllPayload, gradeLevels: filter.gradeLevels};
  }

  if (filter.pillars.length > 0) {
    filterFilesAllPayload = {...filterFilesAllPayload, pillars: filter.pillars};
  }

  if (filter.competencies.length > 0) {
    filterFilesAllPayload = {...filterFilesAllPayload, competencies: filter.competencies};
  }

  if (filter.knowledgeSkillsAndAbilities.length > 0) {
    filterFilesAllPayload = {...filterFilesAllPayload, kSAs: filter.knowledgeSkillsAndAbilities};
  }

  if (filter.languages.length > 0) {
    filterFilesAllPayload = {...filterFilesAllPayload, languages: filter.languages};
  }

  if (filter.fileStatuses.length > 0) {
    filterFilesAllPayload = {...filterFilesAllPayload, statuses: filter.fileStatuses};
  }

  if (!!filter.isAccessibleToStudents || filter.isAccessibleToStudents === 0) {
    filterFilesAllPayload = {...filterFilesAllPayload, isAccessibleToStudents: filter.isAccessibleToStudents};
  }

  if (filter.publishedBy) {
    filterFilesAllPayload = {...filterFilesAllPayload, publishedBy: filter.publishedBy};
  }

  return {
    sortOrder: {
      sortBy: sortOrder.order,
      sortAsc: sortOrder.sort === SortingOrderEnum.ASC,
    },
    pagination: {
      pageNumber,
      pageSize,
    },
    filter: filterFilesAllPayload,
  };
};

import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch} from 'hooks';
import {
  poolSelector,
  paginationSelector,
  fetchProgramClassesPagedThunk,
  pageLoadingSelector,
  filterSelector,
  programsClassesSortOrderSelector,
} from '../store';
import {programClassesActions as actions} from '../store/programClasses.slice';
import {IProgramClassesFilter} from 'models';
import {fetchProgramClassesFiltersThunk} from 'filters-selections/store/thunks/programs/fetchProgramClassesFilters.thunk';
import {filtersClassEntitiesCollectionSelector, filtersClassEntitiesSelector} from 'filters-selections';
import {mapProgramClassesPayload} from '../utils';

export const useProgramClasses = (programId: number) => {
  const dispatch = useAppDispatch();
  const loading = useSelector(pageLoadingSelector) as boolean;
  const programClasses = useSelector(poolSelector);
  const filter = useSelector(filterSelector) as IProgramClassesFilter;
  const filterEntities = useSelector(filtersClassEntitiesSelector);
  const filterEntitiesCollection = useSelector(filtersClassEntitiesCollectionSelector);
  const {currentPage, perPage, totalCount} = useSelector(paginationSelector);
  const sortOrder = useSelector(programsClassesSortOrderSelector);

  const fetchProgramClasses: () => void = useCallback(() => {
    const payload = mapProgramClassesPayload({
      programId,
      filter,
      currentPage,
      perPage,
      sortOrder,
      programYearsCollection: filterEntitiesCollection.programYears,
    });

    return dispatch(fetchProgramClassesPagedThunk(payload));
  }, [currentPage, dispatch, filter, perPage, programId, sortOrder, filterEntitiesCollection.programYears]);

  const setFilter: (filter: IProgramClassesFilter) => void = useCallback(
    (filter: IProgramClassesFilter) => dispatch(actions.setFilter(filter)),
    [dispatch]
  );

  const resetFilter: () => void = useCallback(() => dispatch(actions.resetFilter()), [dispatch]);

  const setCurrentPage: (newPage: number) => void = useCallback(
    (newPage: number) => dispatch(actions.setCurrentPage(newPage)),
    [dispatch]
  );

  const setPerPage: (perPage: number) => void = useCallback(
    (perPage: number) => dispatch(actions.setPerPage(perPage)),
    [dispatch]
  );

  const fetchFilterEntities: () => void = useCallback(() => dispatch(fetchProgramClassesFiltersThunk(programId)), [
    dispatch,
    programId,
  ]);

  return {
    loading,
    filter,
    programClasses,
    currentPage,
    perPage,
    totalCount,
    filterEntities,
    filterEntitiesCollection,
    fetchFilterEntities,
    fetchProgramClasses,
    setCurrentPage,
    setPerPage,
    setFilter,
    resetFilter,
  };
};

import React, {Children, createContext, forwardRef, useContext, useEffect, useRef} from 'react';
import {ListChildComponentProps, VariableSizeList} from 'react-window';

const ITEM_SIZE = 36;
const ADDITIONAL_ITEMS_HEIGHT = 10;
const DEFAULT_LIST_HEIGHT = 350;
const MIN_ITEMS_TO_APPLY_CUSTOM_HEIGHT = 10;

const OuterElementContext = createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow(props: ListChildComponentProps) {
  const {data, index, style} = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top as number,
    },
  });
}

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);

  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);

  return ref;
}

export const ListboxComponent = forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
  const {children, ...rest} = props;
  const itemData = Children.toArray(children);
  const itemCount = itemData.length;
  const gridRef = useResetCache(itemCount);
  const height =
    itemCount < MIN_ITEMS_TO_APPLY_CUSTOM_HEIGHT
      ? itemCount * ITEM_SIZE + ADDITIONAL_ITEMS_HEIGHT
      : DEFAULT_LIST_HEIGHT;

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={rest}>
        <VariableSizeList
          ref={gridRef}
          height={height}
          width="100%"
          innerElementType="ul"
          itemData={itemData}
          outerElementType={OuterElementType}
          itemSize={() => 42}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

import React, {FC, Fragment, useCallback, useEffect, useMemo} from 'react';
import {PageLayoutTwoCol, PageLayoutWithFixedAreas} from 'layout';
import {PageViewHeader, PaginationLocal, Table as TableLib, Button, Loading, Tag} from 'components-lib';
import {ButtonPrimaryWithAdminAndLocalAreaPermissions} from 'admin/library/instruments/components';
import {columns, integrationFilterList, integrationFilterToFilterNameMap} from './utils';
import {IntegrationsAllSortBy, SidebarIntegrationsAll} from './components';
import {pages} from 'paths';
import {useNavigate} from 'hooks';
import {IntegrationsFilterNamesEnum, useIntegrations} from 'admin';
import {IIntegrationDetails, IIntegratiosAllFilter} from 'models';
import {cloneDeep} from 'utils/functions';

type IIntegrationFilterEmptyValue = null | undefined | '';

export const IntegrationsAllView: FC = () => {
  const {push} = useNavigate();
  const {
    filter,
    filterEntities,
    setCurrentPage,
    setPerPage,
    currentPage,
    perPage,
    totalCount,
    fetchIntegrations,
    fetchIntegrationListDropdown,
    pageLoading,
    integrationsList,
    setIntegrationFilterItems,
  } = useIntegrations();

  useEffect(() => {
    fetchIntegrationListDropdown();
  }, [fetchIntegrationListDropdown]);

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  const handleAddIntegration = useCallback(() => push(`${pages.adminPortal.integrations.add}`), [push]);
  const handleViewIntegrationDetails = useCallback(
    (integrationId: string) => push(`${pages.adminPortal.integrations.detailsLink}/${integrationId}`),
    [push]
  );

  const sidebar = useMemo(() => <SidebarIntegrationsAll />, []);

  const rows = useMemo(() => {
    if (!integrationsList.result.length) {
      return [];
    }

    return integrationsList.result.map((key: number) => {
      const integration: IIntegrationDetails = integrationsList.entities.items[key];
      const {id, name, integrationType, areaName, schoolName, status, districtName} = integration;
      const integrationTypeEntity = filterEntities?.integrationTypes[integrationType];
      const type = integrationTypeEntity?.displayText;
      const integrationStatusEntity = filterEntities?.integrationStatuses[status];
      const integrationStatus = integrationStatusEntity?.displayText;

      return {
        id,
        name,
        integrationType: type,
        area: areaName,
        districtName,
        schoolName,
        status: integrationStatus,
        actions: (
          <Button.Secondary size="small" clickHandler={() => handleViewIntegrationDetails(id)}>
            View
          </Button.Secondary>
        ),
      };
    });
  }, [
    integrationsList,
    filterEntities?.integrationStatuses,
    filterEntities?.integrationTypes,
    handleViewIntegrationDetails,
  ]);

  const renderSortOrder = useMemo(() => <IntegrationsAllSortBy />, []);

  const appliedFilters = useMemo(() => {
    const chips: any[] = [];

    const resetDropdownFilter = (propName: string, id: string | number) => {
      const updated: IIntegratiosAllFilter = cloneDeep(filter);
      if (propName === IntegrationsFilterNamesEnum.AREAS) {
        updated[IntegrationsFilterNamesEnum.SCHOOL_NAMES] = [];
      }
      updated[propName] = updated[propName].filter((pId: number) => pId !== id);
      setIntegrationFilterItems(updated);
    };

    const deleteClickHandler = (name: IntegrationsFilterNamesEnum) => {
      let value = null as IIntegrationFilterEmptyValue;

      if (name === IntegrationsFilterNamesEnum.SEARCH_BY_NAME) {
        value = '';
      }

      const updated: IIntegratiosAllFilter = {
        ...filter,
        [name]: value,
      };
      setIntegrationFilterItems(updated);
    };

    // string[] filters
    integrationFilterList.forEach((integrationFilter) => {
      const mappedFilterName = integrationFilterToFilterNameMap[integrationFilter];
      const current = filter[mappedFilterName];

      if (current instanceof Array && current.length > 0) {
        current.forEach((id) => {
          const currentFilter = filterEntities[mappedFilterName][id];

          const label = currentFilter?.displayText ? currentFilter?.displayText : currentFilter?.name;

          !!currentFilter &&
            chips.push(
              <Tag
                withFilterChip
                key={`${mappedFilterName}-${id}`}
                label={label}
                deleteHandler={() => resetDropdownFilter(mappedFilterName, currentFilter.id)}
              />
            );
        });
      }
    });

    if (filter.integrationType !== null) {
      const integrationTypeObj = filterEntities[IntegrationsFilterNamesEnum.INTEGRATION_TYPES][filter.integrationType];

      chips.push(
        <Tag
          withFilterChip
          key={filter.integrationType}
          label={integrationTypeObj.displayText}
          deleteHandler={() => deleteClickHandler(IntegrationsFilterNamesEnum.INTEGRATION_TYPE)}
        />
      );
    }

    if (!!filter.name) {
      chips.push(
        <Tag
          withFilterChip
          key={filter.name}
          label={filter.name}
          deleteHandler={() => deleteClickHandler(IntegrationsFilterNamesEnum.SEARCH_BY_NAME)}
        />
      );
    }

    return chips;
  }, [filter, filterEntities, setIntegrationFilterItems]);

  const contentHeader = useMemo(
    () => (
      <PageViewHeader
        heading="Integrations"
        action={
          <ButtonPrimaryWithAdminAndLocalAreaPermissions clickHandler={handleAddIntegration}>
            Add Integration
          </ButtonPrimaryWithAdminAndLocalAreaPermissions>
        }
        sort={renderSortOrder}
        filters={appliedFilters}
      />
    ),
    [handleAddIntegration, renderSortOrder, appliedFilters]
  );

  const contentFooter = useMemo(() => {
    return (
      <PaginationLocal
        onPageChange={setCurrentPage}
        onPerPageChange={setPerPage}
        totalCount={totalCount}
        perPage={perPage}
        currentPage={currentPage}
        disabled={pageLoading}
      />
    );
  }, [currentPage, perPage, setCurrentPage, setPerPage, totalCount, pageLoading]);

  const hasRows = !!rows.length;
  const Table = useMemo(
    () => () => (hasRows ? <TableLib rows={rows} columns={columns} /> : <Fragment>No results found</Fragment>),
    [hasRows, rows]
  );

  const content = useMemo(
    () => (
      <PageLayoutWithFixedAreas withSecondaryNavigation={false} header={contentHeader} footer={contentFooter}>
        {pageLoading ? <Loading /> : <Table />}
      </PageLayoutWithFixedAreas>
    ),
    [contentHeader, contentFooter, pageLoading, Table]
  );

  return (
    <Fragment>
      <PageLayoutTwoCol withSecondaryNavigation={false} sidebar={sidebar} content={content} />
    </Fragment>
  );
};

import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormNameEnum} from 'filters-selections';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {filtersCompetenciesEntitiesCollectionSelector, fetchCompetenciesThunk} from 'filters-selections/store';
import {Form} from 'forms';
import {IWithDisabled, IOption} from 'models';
import {useIsPillarSelected} from 'filters-selections/hooks';
interface ICompetenciesEntity {
  id: number;
  name: string;
  pillarId?: number;
}

interface ICompetencySelectProps extends IWithDisabled {
  infoText?: string;
}

export function CompetenciesByPillarSelect({disabled, infoText}: ICompetencySelectProps) {
  const dispatch = useDispatch();
  const competencies = useSelector(filtersCompetenciesEntitiesCollectionSelector);
  const {hasPillarsSelected, formValues, setFieldValue} = useIsPillarSelected();

  const pillarIds = formValues[FormNameEnum.pillars];

  useEffect(() => {
    if (!competencies.length) {
      dispatch(fetchCompetenciesThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasPillarsSelected && formValues.pillars.length === 0 && formValues.competencies.length > 0) {
      setFieldValue(FormNameEnum.competencies, []);
    }
  }, [hasPillarsSelected, formValues.competencies, formValues.pillars, setFieldValue]);

  const options = useMemo(() => mapOptions(competencies, pillarIds), [competencies, pillarIds]);

  return (
    <ItemWithPlaceholder label="Competencies" hasItems={!!competencies.length} infoText={infoText}>
      <Form.SelectAutocomplete
        name={FormNameEnum.competencies}
        options={options}
        multiple
        disabled={!hasPillarsSelected || disabled}
      />
    </ItemWithPlaceholder>
  );
}

const mapOptions = (competencies: ICompetenciesEntity[], pillarId: number | number[]): IOption[] => {
  let filteredRecords = [];

  if (Array.isArray(pillarId)) {
    let filteredList = [];

    pillarId.forEach((id) => {
      filteredList = [...filteredList, ...competencies.filter((competency) => competency.pillarId === id)];
    });
    filteredRecords = [...filteredList];
  } else {
    filteredRecords = competencies.filter((competency) => competency.pillarId === pillarId);
  }

  return filteredRecords.map((c: ICompetenciesEntity) => ({value: c.id, label: c.name}));
};

import React, {Fragment, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useFormikContext} from 'formik';
import {unwrapResult} from '@reduxjs/toolkit';
import {Label} from 'components-lib';
import {IAddIntegrationFormBaseValues, IOption, IWithDisabled} from 'models';
import {
  FormNameEnum,
  schoolNamesEntitiesCollectionSelector,
  searchSchoolsThunk,
  getLoadingStateSchoolNames,
  minLength,
  delayTime,
  dynamicSearchMessages,
} from 'filters-selections';
import {Form} from 'forms';
import {useAppDispatch, useDebounce} from 'hooks';

type ISchoolNameBasedOnAreaSelectProps = IWithDisabled;

export const SchoolNameBasedOnAreaSelect = ({disabled}: ISchoolNameBasedOnAreaSelectProps) => {
  const dispatch = useAppDispatch();
  const {setFieldValue, values} = useFormikContext<IAddIntegrationFormBaseValues>();
  const shouldDisable = !!values.area || values.schoolDistrict;
  const loading = useSelector(getLoadingStateSchoolNames);
  const schoolNames = useSelector(schoolNamesEntitiesCollectionSelector);

  const options: IOption[] = useMemo(() => {
    if (loading) {
      return [];
    }

    return schoolNames.length > minLength
      ? schoolNames.map((schoolName) => ({
          value: schoolName.id,
          label: schoolName.name,
        }))
      : [];
  }, [schoolNames, loading]);

  const debouncedSearchSchools = useDebounce(
    (nextValue: string, areaIds: string, districtIds: string) =>
      dispatch(searchSchoolsThunk({schoolNameQuery: nextValue, areaIds, districtIds}))
        .then(unwrapResult)
        .then(({data}) => {
          const schoolNames = data.reduce((obj, item) => Object.assign(obj, {[item.id]: item.name}), {});
          return setFieldValue(FormNameEnum.schoolNames, schoolNames);
        }),
    delayTime
  );

  const handleInputChange = useCallback(
    (value: string) => {
      const areaIds = values.area ? values.area : '';
      const districtIds = values.schoolDistrict ? values.schoolDistrict : '';
      const nextValue = value.trim();

      return !!nextValue && debouncedSearchSchools(nextValue, areaIds, districtIds);
    },
    [values.area, values.schoolDistrict, debouncedSearchSchools]
  );

  return (
    <Fragment>
      <Label label="School Name" />
      <Form.SearchSelectAutocomplete
        loading={loading}
        multiple={false}
        name={FormNameEnum.schoolName}
        options={options}
        disabled={disabled || loading || !shouldDisable}
        noOptionsText={dynamicSearchMessages.noOptionsSchoolName}
        inputChangeHandler={handleInputChange}
        withFontWeightBold={true}
        withCheckboxRenderOption={false}
      />
    </Fragment>
  );
};

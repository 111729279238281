import {AnswerOpenEnded} from '../components/AnswerOpenEnded/AnswerOpenEnded';
import {AnswersRating} from '../components/Rating/AnswersRating/AnswerRating';
import {QuestionAnswersTypeEnum} from 'enums';
import {IToggleOption} from 'models';
import {AnswerBuilderRating, Answers, AnswersMatrix, AnswerInstructionaText} from '../components';

export const getAnswersComponentMap = (withBuilder = false) => {
  return {
    0: Answers,
    '': Answers,
    [QuestionAnswersTypeEnum.checkbox]: Answers,
    [QuestionAnswersTypeEnum.radioGroup]: Answers,
    [QuestionAnswersTypeEnum.dropdown]: Answers,
    [QuestionAnswersTypeEnum.text]: withBuilder ? Answers : AnswerOpenEnded,
    [QuestionAnswersTypeEnum.openEndedAnswerEnum]: AnswerOpenEnded,
    [QuestionAnswersTypeEnum.rating]: withBuilder ? AnswerBuilderRating : AnswersRating,
    [QuestionAnswersTypeEnum.questionAnswerEnum]: AnswersMatrix,
    [QuestionAnswersTypeEnum.matrix]: AnswersMatrix,
    [QuestionAnswersTypeEnum.matrixEnum]: AnswersMatrix,
    [QuestionAnswersTypeEnum.html]: AnswerInstructionaText,
  };
};

export const mapToggleButtonOptions = (options): IToggleOption[] => {
  return options.map(({text, isCorrect}) => {
    return {value: Number(text), label: text, selected: isCorrect};
  });
};

import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Form} from 'forms';
import {Row} from 'components-lib';
import {FormNameEnum} from 'filters-selections';
import {IAnswer} from 'models';
import {useFormikContext} from 'formik';

export const EditAnswerStack = () => {
  const classes = useStyles();
  const {values} = useFormikContext<any>();

  return (
    <Form.Array name={FormNameEnum.choices}>
      {() => {
        return values?.choices?.map((choice: IAnswer, choiceIdx: number) => {
          const choiceNameBase = `${FormNameEnum.choices}[${choiceIdx}]`;
          return (
            <Row
              key={`${choice.answerId}-${choiceIdx}`}
              classes={{
                root: classes.answer,
              }}
              justify="flex-start"
            >
              <Form.Checkbox name={`${choiceNameBase}.isCorrect`} label={choice.text} checked={choice?.isCorrect} />
            </Row>
          );
        });
      }}
    </Form.Array>
  );
};

const useStyles = makeStyles((theme) => ({
  answer: {
    paddingLeft: theme.spacing(4),
  },
}));

import React, {FC, Fragment, ReactNode, useMemo} from 'react';
import {ActiveStepEnum} from 'enums/common';

interface IActiveStepPageProps {
  activeStep: ActiveStepEnum;
  pageLayoutFirst: ReactNode;
  pageLayoutSecond: ReactNode;
  pageLayoutThird: ReactNode;
}

export const ActiveStepPage: FC<IActiveStepPageProps> = ({
  activeStep,
  pageLayoutFirst,
  pageLayoutSecond,
  pageLayoutThird,
}) => {
  const renderActiveStep = useMemo(() => {
    const steps = {
      [ActiveStepEnum.First]: pageLayoutFirst,
      [ActiveStepEnum.Second]: pageLayoutSecond,
      [ActiveStepEnum.Third]: pageLayoutThird,
    };

    return steps[activeStep];
  }, [activeStep, pageLayoutFirst, pageLayoutSecond, pageLayoutThird]);

  return <Fragment>{renderActiveStep}</Fragment>;
};

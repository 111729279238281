import {mapResourceMetadata} from './mapResourceMetadata';
import {mapUpdateResourceBasePayload} from './mapUpdateResourceBasePayload';
import {IEditFileMetadataFormBaseValues} from '../components/FilesEditResourceForm/config';

export const mapUpdateResourceFilePayload = (fileId: string, formValues: IEditFileMetadataFormBaseValues) => {
  const metadata = mapResourceMetadata(formValues);
  const data = mapUpdateResourceBasePayload({data: new FormData(), fileId, metadata}, formValues);

  data.append('fileTypeId', formValues.fileType.toString());

  return data;
};

import {ItemQuestionAnswersTypeEnum as answerTypeEnum} from 'admin/library/items/enums';

export const getAnswerType = (questionType) => {
  const isAnswerTypeSingleChoice = questionType === answerTypeEnum.singleSelectAnswerEnum;
  const isAnswerTypeMultiChoice = questionType === answerTypeEnum.matrixMultiSelectAnswerEnum;
  const isAnswerTypeDropdown = questionType === answerTypeEnum.dropdownAnswerEnum;
  const isAnswerTypeSingleOrMultiOrDropdownChoice =
    isAnswerTypeSingleChoice || isAnswerTypeMultiChoice || isAnswerTypeDropdown;
  const isAnswerTypeSingleDropdownChoice = isAnswerTypeSingleChoice || isAnswerTypeDropdown;
  const isAnswerTypeOpenEnded = questionType === answerTypeEnum.openEndedAnswerEnum;
  const isAnswerTypeNumericScale = questionType === answerTypeEnum.ratingAnswerEnum;

  return {
    isAnswerTypeSingleChoice,
    isAnswerTypeMultiChoice,
    isAnswerTypeDropdown,
    isAnswerTypeSingleOrMultiOrDropdownChoice,
    isAnswerTypeSingleDropdownChoice,
    isAnswerTypeOpenEnded,
    isAnswerTypeNumericScale,
  };
};

import React, {useEffect, useMemo, Fragment} from 'react';
import {useParams} from 'react-router-dom';
import {ISurvey} from 'survey-react';
import {SurveyTakerPreview} from 'survey-taker';
import {toNumber} from 'utils';
import {Loading} from 'components-lib';
import {useInstrument} from '../details/hooks';

export function InstrumentPreviewView() {
  const urlParams = useParams<{id: string}>();
  const instrumentId = toNumber(urlParams?.id);
  const {
    surveyForPreviewTemplate,
    fetchInstrumentForPreviewById,
    pageLoading,
    isRoutePreviewWithAnswers,
  } = useInstrument(instrumentId);

  useEffect(() => {
    fetchInstrumentForPreviewById(instrumentId);
  }, [fetchInstrumentForPreviewById, instrumentId]);

  const survey: ISurvey = useMemo(
    () => ({
      ...surveyForPreviewTemplate,
      showCompletedPage: false,
      mode: 'display',
    }),

    [surveyForPreviewTemplate]
  );

  return pageLoading ? (
    <Loading fullHeight />
  ) : (
    <Fragment>
      {!!surveyForPreviewTemplate && (
        <SurveyTakerPreview survey={survey} isPreviewWithAnswers={isRoutePreviewWithAnswers} />
      )}
    </Fragment>
  );
}

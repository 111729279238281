import {SortDropdownNameEnum, SortingOrderEnum} from 'enums';
import {IItemsAllSortOrder} from 'models';
import {ItemsOrderingEnum} from '../items/enums';

export const getSortMenuItemsList = (
  itemClickHandler: () => void,
  handleItemClick: (handleItemSortObj: IItemsAllSortOrder) => void
) => {
  const onClick = (
    itemClickHandler: () => void,
    handleItemClick: (handleItemSortObj: IItemsAllSortOrder) => void,
    handleItemSortObj: IItemsAllSortOrder
  ) => {
    return () => {
      itemClickHandler();
      handleItemClick(handleItemSortObj);
    };
  };

  return [
    {
      name: SortDropdownNameEnum.NAME_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ItemsOrderingEnum.Name,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: SortDropdownNameEnum.NAME_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ItemsOrderingEnum.Name,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: SortDropdownNameEnum.CREATED_NEWEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ItemsOrderingEnum.Created,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: SortDropdownNameEnum.CREATED_OLDEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ItemsOrderingEnum.Created,
        sort: SortingOrderEnum.ASC,
      }),
    },
  ];
};

import {useCallback} from 'react';
import {useAppDispatch} from 'hooks';
import {IBaseResourceItem} from 'models/resources';
import {
  IAddInstrumentItemsPayload,
  IAddResourceItemsPayload,
  ILearningPathSessionItem,
} from 'models/resources/learning-paths';
import {ILearningPathSessionsFormBaseValues} from '../utils';
import {sessionBuilderSliceSitesActions as actions} from '../store';
import {useLearningPathBuilderItemsEntities} from './useLearningPathBuilderItemsEntities';
import {SessionBuilderEditModeEnum} from 'enums/learning-paths';

export const useSessionBuilderStoreActions = () => {
  const dispatch = useAppDispatch();
  const {
    isAllSessionsExpanded,
    builderSessionItemsCollection,
    builderSessionItemsEntities,
  } = useLearningPathBuilderItemsEntities();

  const addSessionBuilderItem: (item: ILearningPathSessionItem) => void = useCallback(
    (item: ILearningPathSessionItem) => dispatch(actions.addSessionBuilderItem(item)),
    [dispatch]
  );

  const deleteSessionBuilderItem: (item: ILearningPathSessionItem) => void = useCallback(
    (item: ILearningPathSessionItem) => dispatch(actions.removeSessionBuilderItem(item)),
    [dispatch]
  );

  const clearBuilder: () => void = useCallback(() => dispatch(actions.clearBuilder()), [dispatch]);

  const mapUpdateSessionBuilderItem: (
    item: ILearningPathSessionItem,
    formValues: ILearningPathSessionsFormBaseValues
  ) => ILearningPathSessionItem = useCallback(
    (item: ILearningPathSessionItem, formValues: ILearningPathSessionsFormBaseValues) => ({
      ...item,
      id: item.id,
      title: formValues.sessionName && formValues.sessionName.trim(),
      description: formValues.sessionDescription && formValues.sessionDescription.trim(),
      isAccordionExpanded: false,
    }),
    []
  );

  const updateSessionBuilderItem = useCallback(
    (item: ILearningPathSessionItem, formValues: ILearningPathSessionsFormBaseValues) => {
      const payload = mapUpdateSessionBuilderItem(item, formValues);
      return dispatch(actions.updateSessionBuilderItem(payload));
    },
    [dispatch, mapUpdateSessionBuilderItem]
  );

  const updateSessionBuilderItemMode = useCallback(
    (item: ILearningPathSessionItem) => {
      return dispatch(
        actions.updateSessionBuilderItem({
          ...item,
          mode:
            item.mode !== SessionBuilderEditModeEnum.Edit
              ? SessionBuilderEditModeEnum.Edit
              : SessionBuilderEditModeEnum.View,
        })
      );
    },
    [dispatch]
  );

  const collapseSessionBuilderItem = useCallback(
    (item: ILearningPathSessionItem) => {
      return dispatch(actions.collapseSessionBuilderItem(item));
    },
    [dispatch]
  );

  const collapseAllSessionBuilderItems = useCallback(() => {
    return dispatch(actions.collapseAllSessionBuilderItems(!isAllSessionsExpanded));
  }, [dispatch, isAllSessionsExpanded]);

  const addSessionBuilderResourceItem = useCallback(
    (item: ILearningPathSessionItem, resourceItem: IBaseResourceItem) => {
      const {id} = item;
      return dispatch(actions.addSessionBuilderResourceItem({id, resourceItem}));
    },
    [dispatch]
  );

  const addSessionBuilderResourceItems = useCallback(
    (payload: IAddResourceItemsPayload) => {
      return dispatch(actions.addSessionBuilderResourceItems(payload));
    },
    [dispatch]
  );

  const addSessionBuilderInstrumentItems = useCallback(
    (payload: IAddInstrumentItemsPayload) => {
      return dispatch(actions.addSessionBuilderInstrumentItems(payload));
    },
    [dispatch]
  );

  const deleteSessionBuilderResourceItem = useCallback(
    (contentItemId: string, itemId: string) =>
      dispatch(actions.removeSessionResourceBuilderItem({contentItemId, itemId})),
    [dispatch]
  );

  const deleteSessionBuilderInstrumentItem = useCallback(
    (instrumentId: number, itemId: string) =>
      dispatch(actions.removeSessionInstrumentBuilderItem({instrumentId, itemId})),
    [dispatch]
  );

  const clearBuilderItems = useCallback(() => dispatch(actions.clearBuilder()), [dispatch]);

  const checkIfEmptySessionExist = useCallback(() => dispatch(actions.checkIfEmptySession()), [dispatch]);

  const moveSessionItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = builderSessionItemsCollection[dragIndex];
      dispatch(
        actions.moveSessionItem({
          dragItem,
          from: dragIndex,
          to: hoverIndex,
        })
      );
    },
    [dispatch, builderSessionItemsCollection]
  );

  const moveSessionResourceItem = useCallback(
    (dragIndex: number, hoverIndex: number, itemId: string) => {
      const resourcesCollection = builderSessionItemsEntities[itemId]
        ? builderSessionItemsEntities[itemId].resources
        : [];

      const dragItem = resourcesCollection[dragIndex];
      dispatch(
        actions.moveSessionResourceItem({
          dragItem,
          from: dragIndex,
          to: hoverIndex,
          itemId,
        })
      );
    },
    [dispatch, builderSessionItemsEntities]
  );

  return {
    addSessionBuilderItem,
    deleteSessionBuilderItem,
    updateSessionBuilderItem,
    updateSessionBuilderItemMode,
    clearBuilder,
    collapseSessionBuilderItem,
    collapseAllSessionBuilderItems,
    addSessionBuilderResourceItem,
    addSessionBuilderResourceItems,
    addSessionBuilderInstrumentItems,
    deleteSessionBuilderResourceItem,
    deleteSessionBuilderInstrumentItem,
    clearBuilderItems,
    checkIfEmptySessionExist,
    moveSessionItem,
    moveSessionResourceItem,
  };
};

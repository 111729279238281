import {createSlice} from '@reduxjs/toolkit';
import {programDetailsInitialState} from './programDetails.initialState';
import {programDetailsReducer} from './programDetails.reducer';
import {programDetailsExtraReducer} from './programDetailsExtra.reducer';

export const programDetailsSlice = createSlice({
  name: 'programDetails',
  initialState: programDetailsInitialState,
  reducers: programDetailsReducer,
  extraReducers: programDetailsExtraReducer,
});

export const {actions: programDetailsActions} = programDetailsSlice;

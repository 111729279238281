import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Container, Input, Label} from 'components-lib';
import {IWithDisabled} from 'models';

interface IResourceInputProps extends IWithDisabled {
  value: string;
  label: string;
  type?: string;
}

export const ResourceInput: FC<IResourceInputProps> = ({label, value, disabled, type = 'text'}) => {
  const classes = useStyles();

  return (
    <Container disableGutters classes={{root: classes.input}}>
      <Label label={label} />
      <Input value={value} disabled={disabled} type={type} />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

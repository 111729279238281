import {IActionFulfilled, IGetPageOfProgramClassesResponse} from 'models';
import {normalize} from 'normalizr';
import {isObject} from 'utils';
import {IProgramClassesState} from './IProgramClassesState';
import {poolListSchema} from './normalizationSchemas';
import {fetchProgramClassesPagedThunk} from './thunks';

export const programClassesExtraReducer = {
  [fetchProgramClassesPagedThunk.fulfilled]: (
    state: IProgramClassesState,
    action: IActionFulfilled<null, IGetPageOfProgramClassesResponse>
  ) => {
    if (isObject(action.payload)) {
      state.pool = normalize(action.payload.classes, poolListSchema);
      state.pagination.totalCount = action.payload.totalCount;
    }

    state.page.loading = false;
  },
  [fetchProgramClassesPagedThunk.pending]: (state: IProgramClassesState) => {
    state.page.loading = true;
  },
  [fetchProgramClassesPagedThunk.rejected]: (state: IProgramClassesState) => {
    // TODO handle errors
    state.page.loading = false;
  },
};

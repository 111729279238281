import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import {IntegrationStatusNum} from 'enums/integrations';
import {useIntegration} from 'admin/integrations';
import {useModal} from 'hooks';
import {Button, Wrapper} from 'components-lib';
import {
  useIntegrationDetailsFormValues,
  useIntegrationDetailType,
  useIntegrationIdParam,
} from 'admin/integrations/details';
import {ModalDelete, ModalUpdate} from 'admin/components';
import {IIntegrationDetailsFormBaseValues} from 'models/integrations';
import {useFormikContext} from 'formik';

export const SubheadingActions = () => {
  const classes = useStyles();
  const {integrationId} = useIntegrationIdParam();
  const {integration, loading, deleteIntegration, publishIntegration, updateIntegration} = useIntegration();
  const {isOpen: isUpdateIntegrationModalOpen, setIsOpen: setIsUpdateIntegrationModalOpen} = useModal();
  const {isOpen: isModalDeleteIntegration, setIsOpen: setIsDeleteIntegrationModal} = useModal();
  const {values, isValid, dirty, isSubmitting} = useFormikContext<IIntegrationDetailsFormBaseValues>();
  const {hasOnlyIntegrationNameChanged} = useIntegrationDetailsFormValues();
  const {isGoogleSitesSource, isGoogleClassRoom, isGoogleSitesDestination} = useIntegrationDetailType(
    integration?.integrationType
  );

  const shouldDisableDelete = loading;
  const shouldDisablePublish = integration?.status !== IntegrationStatusNum.TEST_SUCCESS;

  /*
    Enable Update button only if some of the fields are changed
  */

  const shouldDisableUpdateBasedOnGoogleIntegrations =
    (isGoogleSitesSource || isGoogleClassRoom || isGoogleSitesDestination) && !values.canGiveEditorPermissions;
  const shouldDisableUpdateBase = isSubmitting || !isValid;
  const shouldDisableUpdateButton = shouldDisableUpdateBase || !dirty || shouldDisableUpdateBasedOnGoogleIntegrations;

  /*
    Upon click a pop-up is shown “Are you sure you want to update the integration? It will be re-tested again.”
    Pop-up is not shown if only Integration name is changed
    Upon click on Update, the integration is tested again

    If testing is successful and the integration was previously published > automatically publish after testing 
  */

  const openDeleteModalClickHandler = useCallback(() => setIsDeleteIntegrationModal(true), [
    setIsDeleteIntegrationModal,
  ]);

  const openUpdateIntegrationModalHandler = useCallback(() => setIsUpdateIntegrationModalOpen(true), [
    setIsUpdateIntegrationModalOpen,
  ]);

  const publishClickHandler = useCallback(() => publishIntegration(integrationId), [integrationId, publishIntegration]);

  const updateClickIntegrationHandler = useCallback(() => updateIntegration(integrationId, values), [
    integrationId,
    updateIntegration,
    values,
  ]);

  const updateClickHandler = useCallback(() => {
    if (hasOnlyIntegrationNameChanged) {
      return updateClickIntegrationHandler();
    }

    return openUpdateIntegrationModalHandler();
  }, [openUpdateIntegrationModalHandler, hasOnlyIntegrationNameChanged, updateClickIntegrationHandler]);

  const deleteIntegrationClickHandler = useCallback(() => deleteIntegration(integrationId), [
    integrationId,
    deleteIntegration,
  ]);

  return (
    <Wrapper className={classes.actions}>
      <Button.Secondary disabled={loading || shouldDisableUpdateButton} clickHandler={updateClickHandler}>
        Update
      </Button.Secondary>
      <Button.Secondary disabled={shouldDisableDelete} clickHandler={openDeleteModalClickHandler}>
        Delete
      </Button.Secondary>

      <Button.Primary disabled={shouldDisablePublish || loading} clickHandler={publishClickHandler}>
        Publish
      </Button.Primary>
      <ModalUpdate
        loading={loading}
        title="Update Integration"
        text="Are you sure you want to update the existing integration? It will be re-tested again."
        isOpen={isUpdateIntegrationModalOpen}
        setIsOpen={setIsUpdateIntegrationModalOpen}
        confirmHandler={updateClickIntegrationHandler}
      />
      <ModalDelete
        loading={loading}
        title="Delete Integration"
        text="Are you sure you want to delete the existing integration?"
        isOpen={isModalDeleteIntegration}
        setIsOpen={setIsDeleteIntegrationModal}
        confirmHandler={deleteIntegrationClickHandler}
      />
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  actions: {
    marginRight: theme.spacing(3),
  },
}));

import {IDigitalResourceFileMetaData} from 'models';

export const mapFileToUpdate = (
  id: string,
  name: string,
  digitalResourceGuid: string,
  streamingUrl: string,
  drmExpirationDate: string,
  metadata: IDigitalResourceFileMetaData
) => {
  return {
    id,
    name,
    digitalResourceGuid,
    json: '',
    streamingUrl,
    drmExpirationDate,
    metadata,
  };
};

export const mapFileToUpdateMetadata = (
  programs: number[],
  sessions: number[],
  pillars: number[],
  competencies: number[],
  gradeLevels: number[],
  skillsAndBehaviors: number[],
  languages: number[],
  keyTerms: string[],
  concepts: string[],
  learningObjectives: string[],
  drmExpirationDate: string
) => {
  return {
    programs,
    sessions,
    pillars,
    competencies,
    gradeLevels,
    skillsAndBehaviors,
    languages,
    keyTerms,
    concepts,
    learningObjectives,
    drmExpirationDate,
  };
};

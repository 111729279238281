import {IBindBaseEnumEntity} from 'models';

export const getPublishedByExtendedEntities = (
  userArea: number,
  filterPublishedByEntities: {[id: number]: IBindBaseEnumEntity}
) => ({
  ...filterPublishedByEntities,
  [userArea]: {
    id: userArea,
    name: userArea.toString(),
    displayText: 'Area',
  },
});

import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useFormikContext} from 'formik';
import {Form} from 'forms';
import {IOption} from 'models';
import {
  FormNameEnum,
  ItemWithPlaceholder,
  fetchInstrumentTypesThunk,
  filtersInstrumentTypesEntitiesCollectionSelector,
  hasPreAndPostSessionSelected,
  filterSubTypesCondition,
} from 'filters-selections';

export function InstrumentSubTypeSelect() {
  const dispatch = useDispatch();
  const {values} = useFormikContext();
  const type = values[FormNameEnum.instrumentType];
  const types = useSelector(filtersInstrumentTypesEntitiesCollectionSelector);
  const hasTypeSelected = !!type;
  const hasTypePreAndPostSession = hasPreAndPostSessionSelected(hasTypeSelected, type);
  const shouldDisabale = !hasTypePreAndPostSession;

  useEffect(() => {
    if (!types.length) {
      dispatch(fetchInstrumentTypesThunk());
    }
  }, [types.length, dispatch]);

  /**
   * Only available when type = Pre-Session OR Post-Session
   */

  const subTypes = useMemo(() => {
    if (hasTypePreAndPostSession && types) {
      return types?.filter((t) => filterSubTypesCondition(type, t));
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTypePreAndPostSession]);

  const options: IOption[] = useMemo(
    () =>
      subTypes.map((subType) => ({
        value: subType.id,
        label: subType.displayText,
      })),
    [subTypes]
  );

  return (
    <ItemWithPlaceholder label="Sub Type" hasItems={!!types.length}>
      <Form.Select name={FormNameEnum.instrumentSubType} options={options} disabled={shouldDisabale} />
    </ItemWithPlaceholder>
  );
}

import {
  filtersDomainsEntitiesCollectionSelector,
  filtersDomainsEntitiesSelector,
  fetchDomainsThunk,
  FormNameEnum,
} from 'filters-selections';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {useFormikContext} from 'formik';
import {IWithDisabled} from 'models';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isDomainKnowledge} from 'utils';
import {Select, Text} from 'components-lib';
import {IItemFormBaseValues} from 'admin/library/items/builder/views/itemBuilderFormBaseConfig';
import {IOption} from 'models';

type IDomainSelectProps = IWithDisabled;

export function DomainSelect({disabled}: IDomainSelectProps) {
  const dispatch = useDispatch();
  const {values, errors, setValues} = useFormikContext<IItemFormBaseValues>();
  const currentDomainValue = values[FormNameEnum.domain];
  const currentProgramsValue: number | number[] = values[FormNameEnum.programs];
  const error = errors[FormNameEnum.domain];
  const domains = useSelector(filtersDomainsEntitiesCollectionSelector);
  const domainsEntities = useSelector(filtersDomainsEntitiesSelector);
  const currentDomainEntity = domainsEntities[currentDomainValue];

  const options: IOption[] = useMemo(() => domains.map((domain) => ({value: domain.id, label: domain.displayText})), [
    domains,
  ]);
  const currentOption: IOption = useMemo(
    () => currentDomainEntity && {value: currentDomainEntity.id, label: currentDomainEntity.name},
    [currentDomainEntity]
  );

  useEffect(() => {
    if (!domains.length) {
      dispatch(fetchDomainsThunk());
    }
  }, [dispatch, domains.length]);

  const changeHandler = useCallback(
    (value: number) => {
      if (value === currentDomainValue) {
        return;
      }

      const isCurrentDomainKnowledge = isDomainKnowledge(currentDomainEntity);
      const isNextDomainKnowledge = isDomainKnowledge(domainsEntities[value]);

      const newValues = {
        ...values,
        [FormNameEnum.domain]: value,
      };

      if (isCurrentDomainKnowledge) {
        newValues[FormNameEnum.programs] = !Array.isArray(currentProgramsValue) ? [currentProgramsValue] : [];
        newValues[FormNameEnum.competencies] = [];
        newValues[FormNameEnum.concepts] = [];
      }

      if (isNextDomainKnowledge) {
        newValues[FormNameEnum.programs] = Array.isArray(currentProgramsValue) ? currentProgramsValue.shift() : null;
      }

      setValues(newValues, true);
    },
    [currentDomainEntity, domainsEntities, currentDomainValue, values, setValues, currentProgramsValue]
  );

  return (
    <ItemWithPlaceholder label="Domain" hasItems={!!domains.length}>
      <Select options={options} changeHandler={changeHandler} value={currentOption} disabled={disabled} />
      {error && <Text.Error>{error}</Text.Error>}
    </ItemWithPlaceholder>
  );
}

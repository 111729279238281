import {createAsyncThunk} from '@reduxjs/toolkit';
import {itemsApi} from 'api';
import {IItemDetails, IWithNavigationPush} from 'models';
import {pages} from 'paths';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export interface IFetchItemPayload extends IWithNavigationPush {
  id: number;
  fullPageLoad: boolean;
}

export const fetchItemThunk: any = createAsyncThunk<IItemDetails, IFetchItemPayload>(
  `${prefix} fetchItem`,
  async ({id, push}: IFetchItemPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await itemsApi.getItemById(id);
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      push(pages.error);
      return rejectWithValue({error: err});
    }
  }
);

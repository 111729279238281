import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Column, Container, Row} from 'components-lib';
import {layoutConfig} from 'layout';
import {IComponentWithChildren} from 'models';

interface ISidebarBase extends IComponentWithChildren {
  withHeader?: boolean;
}

export function SidebarBase({children, withHeader = true}: ISidebarBase) {
  const classes = useStyles(withHeader);

  return (
    <Container classes={{root: classes.root}}>
      <Column>
        <Row justify="flex-start" align="flex-start" classes={{root: classes.scrollableContainer}}>
          <div>{children}</div>
        </Row>
      </Column>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    padding: `${theme.spacing(3)}px 0 ${theme.spacing(3)}px ${theme.spacing(3)}px `,
  },
  scrollableContainer: (withHeader: boolean) => ({
    height: `calc(100vh - ${layoutConfig.scrollableAreaSubtrahend - (withHeader ? 95 : 0)}px)`,
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingBottom: theme.spacing(5),
    '& > div': {
      width: '100%',
    },
  }),
}));

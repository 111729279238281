import React, {useCallback, useEffect} from 'react';
import {Form} from 'forms';
import {FormNameEnum} from 'filters-selections';
import {IWithDisabled} from 'models';
import {useFormikContext} from 'formik';
import {IGoogleSitesAreasFormValues} from 'admin/resources/google-sites';

export const GoogleSitesAreasSearch = ({disabled}: IWithDisabled) => {
  const {
    values: {historyList, searchTerm},
    setFieldValue,
  } = useFormikContext<IGoogleSitesAreasFormValues>();
  const hasSearchTerm = searchTerm.length > 0;

  const isIncludesTerm = useCallback((searchWord: string, fieldVal: string) => {
    if (searchWord && fieldVal) {
      return fieldVal.toLowerCase().includes(searchWord.toLowerCase());
    }
    return false;
  }, []);

  useEffect(() => {
    if (hasSearchTerm) {
      const clonedList = [...historyList];
      const filteredList = clonedList.filter((item) => {
        const hasIncludesTermInName = isIncludesTerm(searchTerm, item?.integrationName);
        const hasIncludesTermInAreaName = isIncludesTerm(searchTerm, item.areaName);

        return hasIncludesTermInName || hasIncludesTermInAreaName;
      });
      setFieldValue(FormNameEnum.historyFilteredList, filteredList);
    } else {
      setFieldValue(FormNameEnum.historyFilteredList, historyList);
    }
  }, [historyList, searchTerm, setFieldValue, hasSearchTerm, isIncludesTerm]);

  return <Form.Search name={FormNameEnum.searchTerm} disabled={disabled} withIcon />;
};

import React from 'react';
import {Accordion} from 'components-lib';
import {Form} from 'forms';
import {FormNameEnum} from '../../../enums';
import {IWithDisabled} from 'models';

interface ILearningObjectiveFilterProps extends IWithDisabled {
  value?: string;
}

export const LearningObjectiveFilter = ({disabled}: ILearningObjectiveFilterProps) => {
  return (
    <Accordion heading="Learning Objective">
      <Form.Search name={FormNameEnum.learningObjective} disabled={disabled} withIcon={false} />
    </Accordion>
  );
};

import React from 'react';
import {SidebarTitle} from 'admin/components';
import {AdminRolesWithLocalAreaInstruments} from 'admin/library/instruments/enums';
import {Sidebar} from 'components-lib';
import {ActiveModeEnum} from 'hooks';
import {IInstrumentDetails} from 'models';
import {usePermissions} from 'permissions';
import {SidebarInstrumentDetailsEdit} from './SidebarInstrumentDetailsEdit';
import {SidebarInstrumentDetailsView} from './SidebarInstrumentDetailsView';

interface ISidebarInstrumentsDetails {
  instrument: IInstrumentDetails;
  activeMode: ActiveModeEnum;
  disableAll?: boolean;
}

export function SidebarInstrumentDetails({instrument, activeMode, disableAll = false}: ISidebarInstrumentsDetails) {
  const {hasPermission} = usePermissions();

  if (!instrument) {
    return null;
  }

  const isEditMode = activeMode === ActiveModeEnum.edit;
  const {isMasterTemplate, isGeneralTemplate} = instrument;
  const canEdit = hasPermission(AdminRolesWithLocalAreaInstruments);
  const shouldRenderEdit = isMasterTemplate && isEditMode && canEdit;

  return (
    <Sidebar.Base>
      <SidebarTitle text="Tags" />
      {shouldRenderEdit ? (
        <SidebarInstrumentDetailsEdit isGeneralTemplate={isGeneralTemplate} disableAll={disableAll} />
      ) : (
        <SidebarInstrumentDetailsView instrument={instrument} />
      )}
    </Sidebar.Base>
  );
}

import React, {Fragment, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Column, Row} from 'components-lib';
import {heightThreeStepsActivity} from 'utils/layout';
import {Subtitle} from '..';
import {SelectionEnum, SelectionListItems} from 'filters-selections';

const list = [SelectionEnum.ServiceEmailInput, SelectionEnum.UserToImpersonateInput];

export const AddIntegrationGoogleClassRoom = () => {
  const classes = useStyles();

  const content = useMemo(() => {
    return (
      <Fragment>
        <Subtitle withColor={false} as="h3" color="textPrimary" title="Step 2: Google Classroom" />
        <Row classes={{root: classes.row}} align="flex-start" justify="flex-start">
          <Column sm={8} md={8} lg={8} xl={6}>
            <SelectionListItems list={list} />
          </Column>
          <Column sm={4} md={4} lg={4} xl={6} />
        </Row>
      </Fragment>
    );
  }, [classes.row]);

  return <Fragment>{content}</Fragment>;
};

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(2),
  },
  content: {
    height: heightThreeStepsActivity,
    paddingLeft: theme.spacing(2),
  },
}));

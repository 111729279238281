import {makeStyles} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {Tag} from 'components-lib';
import {ItemWithPlaceholder} from 'filters-selections';
import {FieldProps} from 'formik';
import React, {FC, SyntheticEvent, useCallback} from 'react';
import {Field} from '../../forms/components/Field';

interface IFormProps {
  name: string;
  options?: any;
  placeholder?: string;
}

export function FormInputWithChip({name, options, placeholder}: IFormProps) {
  return <Field name={name} options={options} placeholder={placeholder} Component={TagInput} />;
}

const enterKey = 'Enter';

interface ITagInputProps extends FieldProps, IFormProps {
  value: string | null;
  changeHandler: (ev: SyntheticEvent) => void;
  disabled?: boolean;
  placeholder?: string;
  options?: string[];
}

export const TagInput: FC<ITagInputProps> = ({
  field: {name},
  form: {errors, values, setFieldValue},
  disabled = false,
  placeholder,
}) => {
  const classes = useStyles();

  const error = errors[name];
  const value = values[name];

  let props: any = {
    name,
    value,
  };

  if (error) {
    props = {
      ...props,
      error: true,
      helperText: error,
    };
  }

  const removeTags = useCallback(
    (indexToRemove) => {
      const newValues = value.filter((_, idx: number) => idx !== indexToRemove);
      setFieldValue(name, newValues);
    },
    [value, setFieldValue, name]
  );

  const onPressEnter = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();

    if (event['key'] === enterKey) {
      const updatedChips = [...value, event.target['value']];
      setFieldValue(name, updatedChips);

      event.target['value'] = '';
    }
  };

  return (
    <div className={classes.container}>
      <ItemWithPlaceholder label={placeholder} hasItems={true}>
        <div className={classes.chipsContainer}>
          <div id="tags" className={classes.chips}>
            {value.map((chip: string, idx: number) => (
              <Tag key={idx} label={chip} deleteHandler={() => removeTags(idx)} />
            ))}
          </div>
          <TextField
            name={props.name}
            error={props.error}
            helperText={props.helperText}
            className={classes.input}
            fullWidth
            placeholder={''}
            disabled={disabled}
            onKeyUp={onPressEnter}
            InputProps={{disableUnderline: true}}
          />
        </div>
      </ItemWithPlaceholder>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  chipsContainer: {
    display: 'flex',
    alignItems: 'flexStart',
    flexWrap: 'wrap',
    minHeight: '45px',
    width: '100%',
    padding: '0 8px',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '5px',
    '&:focusWithin': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    '@media screen and (max-width: 567px)': {
      chipsContainer: {
        width: `calc(100vw - ${theme.spacing(4)})`,
      },
    },
    '&:hover': {
      border: `1px solid ${theme.palette.grey[800]}`,
    },
  },
  input: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(0),
  },
}));

export enum ResourceTypeEnum {
  Video = 'Video',
  Audio = 'Audio',
  Image = 'Image',
  Document = 'Document',
  PDF = 'PDF',
  FillablePDF = 'FillablePDF',
  File = 'File',
  ScormPackage = 'SCORMPackage',
  WebLink = 'WebLink',
  Excel = 'Excel',
  Archive = 'Archive',
}

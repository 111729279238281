import React from 'react';
import {Icon} from 'components-lib';
import {makeStyles} from '@material-ui/core';

export const ErrorIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.icon}>
      <Icon.ErrorIcon />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
    paddingTop: 6,
  },
}));

import {createAsyncThunk} from '@reduxjs/toolkit';
import {filtersApi} from 'api';
import {IBindBaseEntity} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchProgramYearsThunk: any = createAsyncThunk<IBindBaseEntity[]>(
  `${prefix} fetchProgramYears`,
  async (_, {rejectWithValue, dispatch}) => {
    try {
      // Use this line if you want to use Programs in UMS instead of LearningPath data for Years
      const {parsedBody} = await filtersApi.getProgramYearsExt();

      // Use this line if you want to use LearningPath data instead of Programs in UMS for Years
      //const {parsedBody} = await filtersApi.getFilterProgramYears();
      return parsedBody;
    } catch (error) {
      dispatch(
        toastNotificationManager.createErrorToastAction(
          error.parsedBody || error.statusText || 'Failed to fetch program. Unknown error occurred.'
        )
      );
      return rejectWithValue(error);
    }
  }
);

import {IActionFulfilled, IProgramClassBase} from 'models';
import {IProgramClassDetailsState} from './IProgramClassDetailsState';
import {fetchProgramClassThunk} from './thunks';

export const programClassDetailsExtraReducer = {
  //fetchProgramClassThunk
  [fetchProgramClassThunk.fulfilled]: (
    state: IProgramClassDetailsState,
    action: IActionFulfilled<null, IProgramClassBase>
  ) => {
    if (action.payload?.id) {
      state.activeEntity = {...action.payload};
    }

    state.loading = false;
  },
  [fetchProgramClassThunk.pending]: (state: IProgramClassDetailsState) => {
    state.loading = true;
  },
  [fetchProgramClassThunk.rejected]: (state: IProgramClassDetailsState) => {
    state.loading = false;
  },
};

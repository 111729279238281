import React, {FC, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {FormNameEnum} from 'filters-selections';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {fetchLanguagesThunk, filtersLanguagesEntitiesCollectionSelector} from 'filters-selections/store';
import {useAppDispatch} from 'hooks';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
import {IWithMultiSelectOption} from 'filters-selections/models';
interface ILanguagesSelectProps extends IWithMultiSelectOption, IWithDisabled {
  label?: string;
  name?: string;
}

export const LanguagesSelect: FC<ILanguagesSelectProps> = ({
  selectionType,
  disabled,
  label = 'Languages',
  name = FormNameEnum.languages,
}) => {
  const dispatch = useAppDispatch();
  const languages = useSelector(filtersLanguagesEntitiesCollectionSelector);
  const isMultiSelect = selectionType === 'multi';

  useEffect(() => {
    if (!languages.length) {
      dispatch(fetchLanguagesThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const options = useMemo(
    () =>
      languages.map((language) => {
        return {value: language?.id, label: language?.name};
      }),
    [languages]
  );

  return (
    <ItemWithPlaceholder label={label} hasItems={!!languages.length}>
      {isMultiSelect ? (
        <Form.SelectAutocomplete name={name} options={options} multiple disabled={disabled} />
      ) : (
        <Form.Select name={name} options={options} disabled={disabled} />
      )}
    </ItemWithPlaceholder>
  );
};

import {IUploadStoreState} from './IUploadStoreState';

const fileDefaultState = {
  page: {
    loading: false,
  },
};

const scormPackageDefaultState = {
  page: {
    loading: false,
  },
};

const webLinkDefaultState = {
  page: {
    loading: false,
  },
};

export const uploadInitialState: IUploadStoreState = {
  file: fileDefaultState,
  scormPackage: scormPackageDefaultState,
  webLink: webLinkDefaultState,
};

import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {pages} from 'paths';
import {GoogleSitesViewDetails} from './details';
import {GoogleSitesAllView} from './all';

export const GoogleSites = () => {
  return (
    <Switch>
      <Route
        exact
        path={pages.adminPortal.googleSites.root}
        render={() => <Redirect to={pages.adminPortal.googleSites.all} />}
      />
      <Route path={pages.adminPortal.googleSites.all} component={GoogleSitesAllView} />
      <Route path={pages.adminPortal.googleSites.details} component={GoogleSitesViewDetails} />
    </Switch>
  );
};

import React, {Fragment, useCallback, useMemo} from 'react';
import {IconButton, makeStyles} from '@material-ui/core';

import {Answers} from 'admin/library/components';
import {IBuilderDragItem} from 'admin/library/models';
import {Accordion, Row, Icon} from 'components-lib';
import {CardQuestionSimple} from '../CardQuestionSimple/CardQuestionSimple';
import {ModalStackRelatedFilters} from '..';

import {QuestionAnswersTypeEnum} from 'enums';
import {borderGeneral} from 'styles';
import {toNumber} from 'utils';
import {useModal} from 'hooks';
import {useBuilderPoolItemsEntities} from '../../hooks';

interface IPoolItemProps {
  itemId: number;
  dropHandler: (position: number) => (item: IBuilderDragItem) => void;
  type: QuestionAnswersTypeEnum;
  builderItems: IBuilderDragItem[];
}

export function PoolItem({itemId, type, dropHandler, builderItems}: IPoolItemProps) {
  const classes = useStyles();
  const {builderPoolItemsEntities} = useBuilderPoolItemsEntities();
  const item = builderPoolItemsEntities[itemId];
  const disabled = shouldItemBeDisabled(toNumber(itemId), builderItems);

  const {isOpen: isStackRelatedFiltersModalOpen, setIsOpen: setIsStackRelatedFiltersModalOpen} = useModal();
  const handleOpenStackRelatedFiltersModal = useCallback(() => setIsStackRelatedFiltersModalOpen(true), [
    setIsStackRelatedFiltersModalOpen,
  ]);

  const headingActions = useMemo(
    () => (
      <IconButton onClick={handleOpenStackRelatedFiltersModal}>
        <Icon.FilterListIcon />
      </IconButton>
    ),
    [handleOpenStackRelatedFiltersModal]
  );

  if (!item) return null;

  return (
    <Accordion heading={<CardQuestionSimple type={type} text={item.text} />} className={classes.root}>
      <Row justify="flex-start">
        {item.elements?.map(
          ({questionAnswerStackId, questionType, choices, details, hasMatchedMetadata, metaData}, idx: number) => (
            <Fragment key={idx}>
              <Answers
                answers={choices}
                type={questionType}
                draggable
                questionId={toNumber(itemId)}
                stackId={questionAnswerStackId}
                dropHandler={dropHandler(idx)}
                disabled={disabled}
                details={details}
                headingActions={headingActions}
                hasMatchedMetadata={hasMatchedMetadata}
              />
              {isStackRelatedFiltersModalOpen && (
                <ModalStackRelatedFilters
                  metaData={metaData}
                  area={details?.creatorArea}
                  isOpen={isStackRelatedFiltersModalOpen}
                  setIsOpen={setIsStackRelatedFiltersModalOpen}
                />
              )}
            </Fragment>
          )
        )}
      </Row>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...borderGeneral,
    padding: theme.spacing(2),
  },
}));

function shouldItemBeDisabled(itemId: number, builderItems: IBuilderDragItem[]) {
  return !!builderItems.find((item) => itemId === item.questionId);
}

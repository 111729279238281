import {DropdownItemTextEnum} from '../enums';

export const filesDropdownList = [
  {
    text: DropdownItemTextEnum.open,
    isOpenInNewWindow: true,
  },
  {
    text: DropdownItemTextEnum.copyLink,
    isCopyLink: true,
  },
  {
    text: DropdownItemTextEnum.download,
    isDownload: true,
  },
];

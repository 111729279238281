import React from 'react';
import {useBeforeunload} from 'react-beforeunload';
import {useUploadActionButtons} from 'admin/resources/upload/hooks';
import {uploadMessages} from 'admin/upload/utils';
import {UploadFooter} from 'admin/resources/upload/components';

export const UploadFileFooter = () => {
  const {hasOneOfUploadRequiredFields, shouldDisableFileUpload, redirectToViewListOfFiles} = useUploadActionButtons();

  useBeforeunload(() => {
    if (hasOneOfUploadRequiredFields) {
      return uploadMessages.warning;
    }
  });

  return (
    <UploadFooter
      showPrompt={hasOneOfUploadRequiredFields}
      redirectCallback={redirectToViewListOfFiles}
      disableSubmit={shouldDisableFileUpload}
    />
  );
};

import React, {FC, MutableRefObject, useCallback, useMemo} from 'react';
import {batch} from 'react-redux';
import {FormikHelpers, FormikProps, FormikValues} from 'formik';
import {IWithChildren} from 'models/common';
import {Form} from 'forms';
import {FormNameEnum} from 'enums/form';
import {mapAddResourceItemsPayload} from 'admin/resources/learning-paths/views/create/utils';
import {IAddSessionResourcesFormValues, addSessionResourcesFormValidationSchema} from './config';
import {
  useLearningPathBuilderItemsEntities,
  useSessionBuilderStoreActions,
} from 'admin/resources/learning-paths/views/session-builder';

interface IAddResourcesFormProps extends IWithChildren {
  id: string;
  formikFormRef: MutableRefObject<FormikProps<FormikValues>>;
  closeDrawerClickHandler?: () => void;
  resetIsDirty: () => void;
}

export const AddSessionResourcesForm: FC<IAddResourcesFormProps> = ({
  id,
  formikFormRef,
  children,
  closeDrawerClickHandler,
  resetIsDirty,
}) => {
  const {addSessionBuilderResourceItems} = useSessionBuilderStoreActions();
  const {resourcesConcatenatedBaseEntitiesCollection} = useLearningPathBuilderItemsEntities();

  const submitHandler = useCallback(
    ({selectedResourcesIds}, {resetForm}: FormikHelpers<any>) => {
      const payload = mapAddResourceItemsPayload(id, selectedResourcesIds, resourcesConcatenatedBaseEntitiesCollection);

      return batch(() => {
        addSessionBuilderResourceItems(payload);
        closeDrawerClickHandler();
        resetForm();
        resetIsDirty();
      });
    },
    [
      id,
      addSessionBuilderResourceItems,
      closeDrawerClickHandler,
      resourcesConcatenatedBaseEntitiesCollection,
      resetIsDirty,
    ]
  );

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.resources]: [],
      [FormNameEnum.selectedResourcesIds]: [],
      [FormNameEnum.selectedResources]: [],
    }),
    []
  );

  return (
    <Form.Form<IAddSessionResourcesFormValues>
      formikRef={formikFormRef}
      initialValues={initialValues}
      validationSchema={addSessionResourcesFormValidationSchema}
      submitHandler={submitHandler}
    >
      {children}
    </Form.Form>
  );
};

import {makeStyles} from '@material-ui/core';
import {Container, Row, Text} from 'components-lib';
import React, {memo} from 'react';
import {ReactComponent as ThankYouImage} from 'resources/images/successPage/thankYou.svg';

export function ViewSuccess() {
  const classes = useStyles();
  return (
    <Container classes={{root: classes.container}}>
      <Container>
        <Row>
          <ThankYouImage />
        </Row>

        <Row>
          <Text.Heading as="h1" classes={{root: classes.h1}}>
            Thank you for taking this survey!
          </Text.Heading>
        </Row>
      </Container>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
  h1: {
    color: theme.palette.grey[700],
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  h3: {
    color: theme.palette.grey[700],
  },
}));

export default memo(ViewSuccess);

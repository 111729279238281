import React from 'react';
import {Column, Row} from 'components-lib';
import {ErrorBox} from 'admin/components/ErrorBox/ErrorBox';
import {makeStyles} from '@material-ui/core';

export const IssuesWarningBox = () => {
  const classes = useStyles();

  return (
    <Row justify="flex-start" align="center" classes={{root: classes.issuesWarningBox}}>
      <Column sm={1} md={1} lg={2} xl={2}>
        <ErrorBox
          withMarginLeft={false}
          errorMessages="There are some issues with the file. Please check them by clicking on Issues and re-upload the file to the system."
        />
      </Column>
    </Row>
  );
};

const useStyles = makeStyles((theme) => ({
  issuesWarningBox: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
  },
}));

import React, {FC, Fragment} from 'react';
import {ILearningPathSessionItem} from 'models/resources';
import {ResourceInput, ResourceTextArea} from 'admin/resources';

interface ISessionResourcesDetailsPreviewProps {
  item: ILearningPathSessionItem;
}

export const SessionDetailsPreview: FC<ISessionResourcesDetailsPreviewProps> = ({item}) => {
  return (
    <Fragment>
      <ResourceInput label="Name" value={item.title ?? ''} disabled />
      <ResourceTextArea label="Description" value={item.description ?? ''} disabled rows={6} />
    </Fragment>
  );
};

import React, {Fragment, useContext, useEffect, useMemo} from 'react';
import {Subtitle} from 'admin/components';
import {SelectionEnum, SelectionListItems} from 'filters-selections';
import {useFormikContext} from 'formik';
import {ICloneLearningPathFormBaseValues} from './cloneLearningPathBaseConfig';
import {FormContext} from 'forms/formik/FormContextProvider';

export const ModalCloneContent = () => {
  const {setIsDirty, isDirty} = useContext(FormContext);
  const {dirty, isValid} = useFormikContext<ICloneLearningPathFormBaseValues>();

  // TODO: Update context provider properly
  useEffect(() => {
    if (dirty && isValid && !isDirty) {
      setIsDirty(true);
    } else if (!isValid) {
      setIsDirty(false);
    }
  }, [dirty, isDirty, isValid, setIsDirty]);

  const selectionList = useMemo(() => [SelectionEnum.ProgramsSingle, SelectionEnum.ExpirationDatePicker], []);

  return (
    <Fragment>
      <Subtitle
        withColor={false}
        as="h4"
        color="primary"
        title="The chosen metadata will be saved for the cloned learning path and all its resources."
      />
      <SelectionListItems list={selectionList} disableAll={false} />
    </Fragment>
  );
};

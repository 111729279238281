import React, {FC, Fragment, memo, ReactNode, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Container, Loading, Row, Wrapper} from 'components-lib';
import {footerHeight, navHeight, secondaryNavigation, secondaryNavigationLarge} from 'utils/layout';
interface IPageLayoutHorizontalRowsProps {
  headerSize?: 'small' | 'large';
  header?: ReactNode;
  contentUp?: ReactNode;
  contentDown?: ReactNode;
  contentMiddle?: ReactNode;
  footer?: ReactNode;
  withSecondaryNavigation?: boolean;
  loading?: boolean;
}

export const PageLayoutHorizontalRows: FC<IPageLayoutHorizontalRowsProps> = memo(
  ({
    header,
    contentUp,
    contentDown,
    contentMiddle,
    footer,
    withSecondaryNavigation = true,
    headerSize = 'small',
    loading,
  }) => {
    const navigation = headerSize === 'small' ? 45 : 94;
    let height = `calc(100vh - ${navHeight}px - ${footerHeight}px - ${withSecondaryNavigation ? navigation : 0}px)`;

    if (!footer) {
      height = `calc(100vh - ${navHeight}px)`;
    }

    const classes = useStyles({height, footer, headerSize});

    const content = useMemo(
      () => (
        <Fragment>
          {contentUp && <Row justify="flex-start">{contentUp}</Row>}
          {contentMiddle && <Row justify="flex-start">{contentMiddle}</Row>}
          {contentDown && <Row justify="flex-start">{contentDown}</Row>}
        </Fragment>
      ),
      [contentUp, contentDown, contentMiddle]
    );

    return (
      <Container classes={{root: classes.root}} disableGutters>
        <Row classes={{root: classes.secondaryNavigation}}>{header}</Row>

        <Wrapper className={classes.content}>{loading ? <Loading /> : content}</Wrapper>

        {footer && (
          <Row justify="flex-end" align="center" classes={{root: classes.footer}}>
            {footer}
          </Row>
        )}
      </Container>
    );
  }
);
interface IContentStyles {
  height: string;
  footer?: ReactNode;
  headerSize?: 'small' | 'large';
}

export const useStyles = makeStyles((theme) => ({
  root: ({height}: IContentStyles) => ({
    flexGrow: 1,
    maxHeight: height,
  }),
  secondaryNavigation: ({headerSize}: IContentStyles) => ({
    paddingLeft: theme.spacing(4),
    height: headerSize === 'small' ? secondaryNavigation : secondaryNavigationLarge,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    background: theme.palette.common.white,
  }),
  content: ({height, footer}: IContentStyles) => ({
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: !footer && theme.spacing(4),
    background: theme.palette.common.white,
    alignItems: 'stretch',
    height: height,
    overflow: 'auto',
    width: '100%',
  }),
  footer: {
    height: footerHeight,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    background: theme.palette.common.white,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

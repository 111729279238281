import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Text} from 'components-lib';

interface IAreaTitleProps {
  creatorArea: string;
}

export const AreaTitle: FC<IAreaTitleProps> = ({creatorArea = ''}) => {
  const classes = useStyles();

  return (
    <Text.Heading classes={{root: classes.root}} as="h4">
      {creatorArea}
    </Text.Heading>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[700],
    marginLeft: theme.spacing(3),
  },
}));

export const learningPathsMock = [
  {
    id: 1,
    name: 'Learning Path 1',
    programYear: '2020 - 2021',
  },
  {
    id: 2,
    name: 'Learning Path 2',
    programYear: '2020 - 2021',
  },
  {
    id: 3,
    name: 'Learning Path 3',
    programYear: '2020 - 2021',
  },
];

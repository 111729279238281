import React from 'react';
import {useFormikContext} from 'formik';
import {values} from 'utils';
import {ErrorBox} from './ErrorBox';

export function FormErrorBox() {
  const {errors} = useFormikContext();
  const errorMessages = values(errors);

  return errorMessages.length ? (
    <ErrorBox errorMessages={errorMessages} defaultMessage="One or more fields are required." />
  ) : null;
}

import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {useAppDispatch, useNavigate} from 'hooks';
import {pages} from 'paths';
import {
  fetchGoogleSitesSourceDetailsThunk,
  googleSiteIntegrationDetailsSelector,
  pageLoadingSelector,
} from '../../store';
import {openFileInNewWindow} from 'utils';

export const useGoogleSitesDetails = () => {
  const {push} = useNavigate();
  const dispatch = useAppDispatch();

  const loading = useSelector(pageLoadingSelector);
  const googleSite = useSelector(googleSiteIntegrationDetailsSelector);

  const redirectToViewGoogleSiteDetails = useCallback(
    (id) => {
      push(`${pages.adminPortal.googleSites.detailsLink}/${id}`);
    },
    [push]
  );

  const redirecToGoogleSite = useCallback((siteUrl: string) => openFileInNewWindow(siteUrl), []);

  const fetchGoogleSiteDetails = useCallback(
    (googleSiteId) => dispatch(fetchGoogleSitesSourceDetailsThunk(googleSiteId)),
    [dispatch]
  );

  return {
    loading,
    googleSite,

    redirectToViewGoogleSiteDetails,
    redirecToGoogleSite,
    fetchGoogleSiteDetails,
  };
};

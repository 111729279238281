import {makeStyles} from '@material-ui/core';
import classnames from 'classnames';
import {IComponentWithClassName} from 'models';
import React from 'react';
import {ButtonBase} from './ButtonBase';
import {IButtonBaseProps} from './IButtonBaseProps';

interface IButtonTextProps extends IButtonBaseProps, IComponentWithClassName {
  color?: 'primary' | 'secondary' | 'inherit';
}

export function ButtonText({
  children,
  clickHandler,
  disabled = false,
  endIcon = null,
  startIcon = null,
  size = 'large',
  color = 'inherit',
  className = '',
}: IButtonTextProps) {
  const classes = useStyles();
  return (
    <ButtonBase
      variant="text"
      color={color}
      clickHandler={clickHandler}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      className={classnames(className, classes.root)}
    >
      {children}
    </ButtonBase>
  );
}

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
});

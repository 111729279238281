import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Wrapper} from 'components-lib';
import {ActiveHeader} from 'admin/components';
import {useIntegration} from 'admin/integrations';
import {SubheadingActions} from './components';

const emptySpaceWithSlash = `\u00a0 / \u00a0`;

export const Subheading = () => {
  const classes = useStyles();
  const {integration, loading} = useIntegration();
  const integrationName = integration?.name ?? '';
  const activeHeaderText = loading ? '' : emptySpaceWithSlash + integrationName;

  return (
    <Wrapper className={classes.subheading}>
      <Wrapper className={classes.activeHeaderContainer}>
        <ActiveHeader isActive={false}>{activeHeaderText}</ActiveHeader>
      </Wrapper>
      <SubheadingActions />
    </Wrapper>
  );
};

const useStyles = makeStyles(() => ({
  subheading: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  activeHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

import {IItemsImportStoreState} from 'admin';
import {itemsImportInitialState, itemsImportMappingDefault} from './itemsImport.initialState';

export const itemsImportReducer = {
  clear(state: IItemsImportStoreState) {
    state.itemsImport = {...itemsImportInitialState.itemsImport};
  },

  resetMapping(state: IItemsImportStoreState) {
    state.mapping = {...itemsImportMappingDefault};
  },
};

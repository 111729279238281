import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {IBindStringResponse} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

interface IDeleteInstrumentPayload {
  instrumentId: number;
}

export const deleteInstrumentThunk: any = createAsyncThunk<IBindStringResponse, IDeleteInstrumentPayload>(
  `${prefix} deleteInstrument`,
  async ({instrumentId}: IDeleteInstrumentPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await instrumentsApi.postDeleteTemplate(instrumentId);
      dispatch(toastNotificationManager.createSuccessToastAction('The instrument was deleted successfully.'));
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction('There was an error while deleting the instrument.'));
      return rejectWithValue(err);
    }
  }
);

import React, {FC, useMemo} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {Avatar, Button, Container, Link, Tooltip, Wrapper} from 'components-lib';
import classnames from 'classnames';
interface IBoxContentProps {
  content: string;
  avatarUrl?: string;
  userInitials?: string;
  size?: 'small' | 'large';
  withLink?: boolean;
  withButtonLink?: boolean;
  linkCallback?: () => void;
}

export const BoxContent: FC<IBoxContentProps> = ({
  size = 'large',
  content,
  userInitials,
  withLink,
  withButtonLink,
  linkCallback,
  avatarUrl,
}) => {
  const classes = useStyles();
  const shouldRenderAvatart = !!userInitials || !!avatarUrl;

  const linkContent = useMemo(
    () => (
      <Wrapper className={classnames(classes.cropText, classes.linkContent)}>
        <Link withNewTab clickHandler={linkCallback}>
          {content && content}
        </Link>
      </Wrapper>
    ),
    [classes.linkContent, classes.cropText, content, linkCallback]
  );

  return (
    <Container disableGutters>
      {withLink ? (
        <Container disableGutters classes={{root: classes.container}}>
          <Box pr={1}>{linkContent}</Box>
          {withButtonLink && <Button.CopyLink text={content} />}
        </Container>
      ) : (
        <Container disableGutters classes={{root: classes.container}}>
          {shouldRenderAvatart && (
            <Avatar size={size} avatarUrl={avatarUrl}>
              {userInitials}
            </Avatar>
          )}
          <Wrapper className={classes.cropText}>
            <Tooltip title={content}>{content}</Tooltip>
          </Wrapper>
        </Container>
      )}
    </Container>
  );
};

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  cropText: {
    width: 230,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  linkContent: {
    width: theme.spacing(7),
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

import * as Yup from 'yup';
import {FormNameEnum} from 'filters-selections/enums';
import {errorMessages} from 'utils';
import {IChoice} from 'models/library/common/IAnswer';
import {ItemQuestionAnswersTypeEnum as answerTypeEnum} from 'admin/library/items/enums';

export interface IModalCreateAnswerStackFormValues {
  [FormNameEnum.choices]: IChoice[];
  [FormNameEnum.pillars]: number[];
  [FormNameEnum.evaluationTypes]: number[];
  [FormNameEnum.domain]: number;
  [FormNameEnum.programs]: number[] | number | null;
  [FormNameEnum.competencies]: number[];
  [FormNameEnum.concepts]: number[];
  [FormNameEnum.administration]: number;
  [FormNameEnum.schoolLevels]: number[];
  [FormNameEnum.learningObjective]: string | '';
  [FormNameEnum.category]: string;
}

export const modalCreateAnswerStackFormValidationSchema = Yup.object().shape({
  [FormNameEnum.choices]: Yup.array().of(
    Yup.object().shape({
      text: Yup.string(),
      isCorrect: Yup.boolean(),
      position: Yup.number(),
    })
  ),
  [FormNameEnum.pillars]: Yup.array().of(Yup.number()).required(errorMessages.requiredWithFieldName('a Pillar')),
  [FormNameEnum.evaluationTypes]: Yup.array()
    .of(Yup.number())
    .required(errorMessages.requiredWithFieldName('an Evaluation Type')),
  [FormNameEnum.domain]: Yup.number().nullable().required(errorMessages.requiredWithFieldName('a Domain')),
  [FormNameEnum.competencies]: Yup.array()
    .of(Yup.number())
    .when(FormNameEnum.domain, {
      is: 10,
      then: Yup.array().of(Yup.number()).required(errorMessages.requiredWithFieldName('a Competency')),
    }),
  [FormNameEnum.concepts]: Yup.array()
    .of(Yup.number())
    .when(FormNameEnum.domain, {
      is: 10,
      then: Yup.array().of(Yup.number()).required(errorMessages.requiredWithFieldName('a KSA')),
    }),
  [FormNameEnum.administration]: Yup.number()
    .nullable()
    .required(errorMessages.requiredWithFieldName('an Administration')),
  [FormNameEnum.schoolLevels]: Yup.array()
    .of(Yup.number())
    .required(errorMessages.requiredWithFieldName('a School Level')),
  [FormNameEnum.learningObjective]: Yup.string().ensure(),
  [FormNameEnum.category]: Yup.string().ensure(),
  [FormNameEnum.customValidation]: Yup.boolean()
    .test('has-text', 'You should add text to all answers!', function () {
      const {choices, questionType} = this.parent;
      const isSingleChoice = isAnswerTypeSingleChoice(questionType, answerTypeEnum.singleSelectAnswerEnum);
      const isMultiChoice = isAnswerTypeMultiChoice(questionType, answerTypeEnum.matrixMultiSelectAnswerEnum);
      const isDropdown = isAnswerTypeDropdown(questionType, answerTypeEnum.dropdownAnswerEnum);
      const isSelectedAnswerType = isSingleChoice || isMultiChoice || isDropdown;

      if (isSelectedAnswerType) {
        const hasText = choices.every((choice: IChoice) => !!choice.text);

        return hasText;
      }

      return true;
    })
    .test('do-not-allow-same-choices', 'You cannot use same answer more than once!', function () {
      const {choices, questionType} = this.parent;
      const isSingleChoice = isAnswerTypeSingleChoice(questionType, answerTypeEnum.singleSelectAnswerEnum);
      const isMultiChoice = isAnswerTypeMultiChoice(questionType, answerTypeEnum.matrixMultiSelectAnswerEnum);
      const isDropdown = isAnswerTypeDropdown(questionType, answerTypeEnum.dropdownAnswerEnum);
      const isSelectedAnswerType = isSingleChoice || isMultiChoice || isDropdown;

      function getUniqueChoices(choices: IChoice[]) {
        const choicesList = choices.map((c) => c.text);
        return [...new Set(choicesList)];
      }

      function isUnique(choices: IChoice[]) {
        const uniqueChoices = getUniqueChoices(choices);
        return uniqueChoices.length === choices.length;
      }

      if (isSelectedAnswerType && choices.length > 1) {
        return isUnique(choices);
      }

      return true;
    }),
});

const isAnswerTypeSingleChoice = (questionType: number, singleSelectAnswerEnum: number) =>
  questionType === singleSelectAnswerEnum;
const isAnswerTypeMultiChoice = (questionType: number, matrixMultiSelectAnswerEnum: number) =>
  questionType === matrixMultiSelectAnswerEnum;
const isAnswerTypeDropdown = (questionType: number, dropdownAnswerEnum: number) => questionType === dropdownAnswerEnum;

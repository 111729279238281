import {isInstructionalTextQuestion} from 'admin/library/instruments/builder/utils';
import {Card} from 'components-lib';
import {IInstrumentItem} from 'models';
import React, {ReactNode} from 'react';
import {toNumber} from 'utils';
import {EntityCardAnswers} from './EntityCardAnswers';
import {EntityCardBody} from './EntityCardBody';

interface ICardItemProps {
  item: IInstrumentItem;
  position: number;
  itemPrefixText?: string;
  withTitle?: boolean;
  actions?: ReactNode;
  openInfoLinkClickHandler?: () => void;
}

export function EntityCard({
  position,
  item,
  itemPrefixText,
  withTitle = true,
  actions,
  openInfoLinkClickHandler,
}: ICardItemProps) {
  const {
    id,
    name,
    type,
    questionOptionsTypeId,
    questionAnswerStackId,
    questionAnswerStackIdOriginal,
    linkedQuestionAnswerStackId,
    title,
    choices,
    columns,
    rows,
    html,
  } = item;
  const entityType = type || questionOptionsTypeId;
  const questionId = name || id || questionAnswerStackIdOriginal || questionAnswerStackId;
  const isInstructionalTextType = isInstructionalTextQuestion(entityType);
  const entityId = !isInstructionalTextType ? questionId.toString() : '';

  return (
    <Card>
      <EntityCardBody
        id={entityId}
        type={entityType}
        title={withTitle ? title : ''}
        position={position}
        itemPrefixText={itemPrefixText}
        withItemInfo={!!openInfoLinkClickHandler}
        linkedQuestionAnswerStackId={linkedQuestionAnswerStackId}
        openInfoLinkClickHandler={openInfoLinkClickHandler}
        actions={actions}
      />
      <EntityCardAnswers
        type={entityType}
        choices={choices}
        columns={columns}
        rows={rows}
        questionId={toNumber(entityId)}
        html={html}
      />
    </Card>
  );
}

import {useSelector} from 'react-redux';
import {
  instrumentsPageSelector,
  paginationSelector,
  instrumentsAllFilterSelector,
  instrumentsAllSortOrderSelector,
  fetchInstrumentsAllPagedThunk,
  instrumentsAllSelector,
  savingSelector,
  builderPoolItemsLoadingSelector,
  deletingSelector,
} from '../store';
import {useAppDispatch} from 'hooks';
import {useCallback} from 'react';
import {instrumentsActions as actions} from '../store/instruments.slice';
import {instrumentsFiltersEntitiesSelector} from 'filters-selections';
import {getIsAscSortOrder, getSortingOptions} from 'utils/common';

export function useInstruments() {
  const dispatch = useAppDispatch();
  const {loading} = useSelector(instrumentsPageSelector);
  const saving = useSelector(savingSelector);
  const deleting = useSelector(deletingSelector);

  const poolItemsLoading = useSelector(builderPoolItemsLoadingSelector);

  const filter = useSelector(instrumentsAllFilterSelector);
  const sortOrder = useSelector(instrumentsAllSortOrderSelector);

  const {currentPage, perPage, totalCount} = useSelector(paginationSelector);

  const setCurrentPage = useCallback((newPage: number) => dispatch(actions.setCurrentPage(newPage)), [dispatch]);
  const setPerPage = useCallback((perPage: number) => dispatch(actions.setPerPage(perPage)), [dispatch]);

  const filterEntities = useSelector(instrumentsFiltersEntitiesSelector);
  const instrumentsList = useSelector(instrumentsAllSelector);

  const fetchInstruments = useCallback(() => {
    const sortAsc = getIsAscSortOrder(sortOrder?.sort);
    const sortOptions = getSortingOptions(sortOrder?.order, sortAsc);

    return dispatch(
      fetchInstrumentsAllPagedThunk({
        filter,
        pagination: {
          pageNumber: currentPage - 1,
          pageSize: perPage,
        },
        sortOptions,
      })
    );
  }, [currentPage, dispatch, filter, perPage, sortOrder]);

  return {
    pageLoading: loading,
    pageSaving: saving,
    pageDeleting: deleting,
    filter,
    filterEntities,
    sortOrder,
    currentPage,
    perPage,
    totalCount,
    instrumentsList,
    fetchInstruments,
    setCurrentPage,
    setPerPage,
    poolItemsLoading,
  };
}

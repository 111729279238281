import React, {useCallback, useMemo} from 'react';
import {Filters} from 'components-lib';
import {FiltersEnum, FiltersList, FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import {usePrograms} from 'admin/programs/all/hooks';
import {filtersFormValidationSchema, IProgramsAllSidebarFiltersForm} from './config';
import {mapProgramsAllPayload} from '../../utils';

export const ProgramsAllSidebarFilters = () => {
  const {
    loading,
    filter,
    setFilter,
    resetFilter,
    filterEntities: {years},
  } = usePrograms();

  const initialValues: IProgramsAllSidebarFiltersForm = useMemo(
    () => ({
      [FormNameEnum.gradeLevels]: filter.gradeLevelsFilter,
      [FormNameEnum.programYears]: filter.programYearsFilter,
      [FormNameEnum.search]: filter.searchByName,
    }),
    [filter]
  );

  const renderFiltersList = useMemo(() => {
    const filters = [FiltersEnum.ProgramYearMulti, FiltersEnum.GradeLevel];
    return <FiltersList filters={filters} disableAll={loading} />;
  }, [loading]);

  const submitClickHandler = useCallback(
    (newFilter: IProgramsAllSidebarFiltersForm) => {
      const payload = mapProgramsAllPayload(filter, newFilter, years.entities.years);

      setFilter(payload);
    },
    [filter, setFilter, years]
  );

  return (
    <Form.Form<IProgramsAllSidebarFiltersForm>
      initialValues={initialValues}
      validationSchema={filtersFormValidationSchema}
      submitHandler={submitClickHandler}
    >
      <Filters.FiltersPanel
        clearButtonClickHandler={resetFilter}
        searchFieldName="search"
        filters={renderFiltersList}
        disableAll={loading}
      />
    </Form.Form>
  );
};

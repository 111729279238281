import React, {useEffect, useMemo} from 'react';
import {useItemStateActions, usePageLoading} from 'admin/library/items/hooks';
import {ItemBaseView} from 'admin/library/items/views';
import {useQueryParams} from 'hooks';
import {ActiveModeEnum} from 'admin/library/items/enums';
import {IItemDetails} from 'models';
import {SidebarBuilderCreateItem} from '../../../../components';
import {ItemBuilderCreateContent} from '../ItemBuilderCreateContent/ItemBuilderCreateContent';

export function ItemBuilderCreate() {
  const queryParams = useQueryParams();
  const questionId = Number(queryParams.questionId);
  const {loadingPageSection, pageLoading} = usePageLoading();
  const {fetchItem} = useItemStateActions();

  useEffect(() => {
    if (questionId && questionId > 0) {
      fetchItem(questionId, false);
    }
  }, [fetchItem, questionId]);

  const content = useMemo(
    () => (item: IItemDetails) => (
      <ItemBuilderCreateContent
        item={item}
        loadingPageSection={loadingPageSection}
        questionId={questionId}
        pageLoading={pageLoading}
      />
    ),
    [loadingPageSection, pageLoading, questionId]
  );

  return (
    <ItemBaseView Sidebar={SidebarBuilderCreateItem} editMode={ActiveModeEnum.create}>
      {content}
    </ItemBaseView>
  );
}

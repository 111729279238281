import {createAsyncThunk} from '@reduxjs/toolkit';
import {integrationsApi} from 'api';
import {IUpdateGoogleSitesDestinationIntegrationPayload} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {integrationDetailsMessages} from '../../details/utils/constants';
import {prefix} from './config';

interface IUpdateGoogleSitesDestination {
  integrationId: string;
  payload: IUpdateGoogleSitesDestinationIntegrationPayload;
}

export const updateGoogleSitesDestinationIntegrationThunk: any = createAsyncThunk(
  `${prefix} updateGoogleSitesSDestinationIntegration`,
  async ({integrationId, payload}: IUpdateGoogleSitesDestination, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await integrationsApi.updateGoogleSiteDestinationIntegration(integrationId, payload);
      dispatch(toastNotificationManager.createSuccessToastAction(integrationDetailsMessages.updateIntegrationSuccess));
      return parsedBody;
    } catch (error) {
      dispatch(
        toastNotificationManager.createErrorToastAction(
          error.messages ?? integrationDetailsMessages.unknownIntegrationError
        )
      );
      return rejectWithValue({error});
    }
  }
);

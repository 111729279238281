import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';
import {values} from 'utils/functions';

const getFilesState = (state: TStateStore) => state.resources.files;

export const getFilesAllState = createSelector(getFilesState, (files) => files.all);

export const pageLoadingSelector = createSelector(getFilesAllState, (filesAll) => filesAll.page.loading);
export const paginationSelector = createSelector(getFilesAllState, (filesAll) => filesAll.pagination);
export const filesAllSortOrderSelector = createSelector(getFilesAllState, (filesAll) => filesAll.sortOrder);

export const filesAllFilterSelector = createSelector(getFilesAllState, (filesAll) => filesAll.filter);

export const filesAllSelector = createSelector(getFilesAllState, (filesAll) => filesAll.files);
export const filesAllIdsSelector = createSelector(getFilesAllState, (filesAll) => filesAll.files.result);
export const filesAllEntitiesSelector = createSelector(getFilesAllState, (filesAll) => filesAll.files.entities.items);
export const filesAllEntitiesCollectionSelector = createSelector(getFilesAllState, (filesAll) =>
  filesAll.files.entities.items ? values(filesAll.files.entities?.items) : []
);

export const fileDetailsSelector = createSelector(getFilesAllState, (filesAll) => filesAll.file);

export const fileDownloadingSelector = createSelector(
  getFilesAllState,
  (filesAll) => filesAll.fileDownload.downloading
);

export const downloadingIdSelector = createSelector(getFilesAllState, (filesAll) => filesAll.fileDownload.id);

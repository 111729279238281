import React, {FC, Fragment} from 'react';
import {ResourceDetails} from 'admin/resources';
import {FilesEditMetadata, FilesEditUserDetails, FilesEditDetailsHeader} from '..';

export const FilesEditDetails: FC = () => {
  return (
    <Fragment>
      <ResourceDetails withSubtitle={false} withBorderBottom={false}>
        <FilesEditDetailsHeader />
        <FilesEditUserDetails />
      </ResourceDetails>
      <FilesEditMetadata />
    </Fragment>
  );
};

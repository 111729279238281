import {createAsyncThunk} from '@reduxjs/toolkit';
import {pamApi} from 'api';
import {IFetchResourcesThunkPayload, IGetResourcesApiResponse} from 'models/resources';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchResourcesThunk: any = createAsyncThunk<IGetResourcesApiResponse, null>(
  `${prefix} fetchResourcesThunk`,
  async (payload: IFetchResourcesThunkPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await pamApi.getAllResources(payload);
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {FormNameEnum} from 'filters-selections';
import {TextAreaSelection} from '../TextAreaSelection/TextAreaSelection';
import {IWithDisabled} from 'models/common';
import {Container} from 'components-lib';

type IBriefDescriptionTextAreaProps = IWithDisabled;

export const BriefDescriptionTextArea: FC<IBriefDescriptionTextAreaProps> = ({disabled}) => {
  const classes = useStyles();
  return (
    <Container disableGutters classes={{root: classes.root}}>
      <TextAreaSelection name={FormNameEnum.briefDescription} label="Brief Description" disabled={disabled} rows={6} />
    </Container>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: '2px',
  },
}));

import {EntitiesPerPageEnum, SortingOrderEnum} from 'enums';
import {IProgramClassesFilter} from 'models';
import {FilterNamesEnum, ProgramsClassesOrderingEnum} from '../enums';
import {IProgramClassesState} from './IProgramClassesState';

const filterDefault: IProgramClassesFilter = {
  [FilterNamesEnum.SEARCH_BY_NAME]: '',
  [FilterNamesEnum.AREAS]: [],
  [FilterNamesEnum.EDUCATORS]: [],
  [FilterNamesEnum.SCHOOL_NAMES]: [],
  [FilterNamesEnum.DISTRICTS]: [],
  [FilterNamesEnum.START_DATE]: null,
  [FilterNamesEnum.END_DATE]: null,
  [FilterNamesEnum.YEARS]: [],
  [FilterNamesEnum.ONLY_VIRTUAL_CLASSES]: null,
};

export const programClassesInitialState: IProgramClassesState = {
  pool: {
    result: [],
    entities: {byId: {}},
  },
  filter: filterDefault,
  page: {
    loading: false,
  },
  sortOrder: {
    order: ProgramsClassesOrderingEnum.Class,
    sort: SortingOrderEnum.ASC,
  },
  pagination: {
    currentPage: 1,
    totalCount: 0,
    perPage: EntitiesPerPageEnum.FIFTY,
  },
};

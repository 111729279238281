import React, {FC} from 'react';

import {FormNameEnum} from 'filters-selections';
import {RatingToggleGroup} from 'admin/library/components';
import {useRangeAnswerStack} from 'admin/library/items/builder/hooks';
interface IRangeButtonsProps {
  disabled?: boolean;
  list?: number[];
}

export const RangeButtons: FC<IRangeButtonsProps> = () => {
  const {list} = useRangeAnswerStack();

  return <RatingToggleGroup name={FormNameEnum.positiveScores} withLabel withForm list={list} disabled={false} />;
};

import {integrationsInitialState} from './integrations.initialState';
import {IActionFulfilled, IIntegrationsAllSortOrder, IIntegratiosAllFilter} from 'models';
import {IIntegrationStoreState} from './IIntegrationsStoreState';
import {cloneDeep} from 'utils';

export const integrationsReducer = {
  clear(state: IIntegrationStoreState) {
    state.integration = {...integrationsInitialState.integration};
  },

  clearAuditLogs(state: IIntegrationStoreState) {
    state.details.auditLogs = {...integrationsInitialState.details.auditLogs};
  },

  setCurrentPage: (state: IIntegrationStoreState, action: IActionFulfilled<null, number>) => {
    state.pagination.currentPage = action.payload;
  },

  setPerPage: (state: IIntegrationStoreState, action: IActionFulfilled<null, number>) => {
    state.pagination.perPage = action.payload;
    resetPagination(state);
  },

  setSortOrderIntegrationsAll: (
    state: IIntegrationStoreState,
    action: IActionFulfilled<null, IIntegrationsAllSortOrder>
  ) => {
    state.sortOrder = action.payload;
  },

  setFilterIntegrations: (state: IIntegrationStoreState, action: IActionFulfilled<null, IIntegratiosAllFilter>) => {
    state.filter = action.payload;
    resetPagination(state);
  },

  resetFilterIntegrations: (state: IIntegrationStoreState) => {
    state.filter = cloneDeep(integrationsInitialState.filter);
    resetPagination(state);
  },
};

const resetPagination = (state: IIntegrationStoreState) => {
  state.pagination.currentPage = integrationsInitialState.pagination.currentPage;
  state.pagination.totalCount = integrationsInitialState.pagination.totalCount;
};

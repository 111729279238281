import React, {FC} from 'react';
import {IWithDisabled} from 'models';
import {ProgramYearFilter} from './ProgramYearFilter';
import {FormNameEnum} from 'filters-selections';

type IProgramYearMultiFilterProps = IWithDisabled;

export const ProgramYearMultiFilter: FC<IProgramYearMultiFilterProps> = ({disabled}) => {
  return <ProgramYearFilter selectionType="multi" disabled={disabled} name={FormNameEnum.programYears} />;
};

import {IActionFulfilled} from 'models';
import {normalize, schema as Schema} from 'normalizr';
import {values} from 'utils';
import {IAddToastNotificationActionPayload, IRemoveToastNotificationActionPayload, IToastNotification} from '../models';
import {IToastNotificationsStoreState} from './IToastNotificationsStoreState';

const notificationEntity = new Schema.Entity('notifications', {}, {idAttribute: 'id'});
const notificationsSchema = new Schema.Array(notificationEntity);

export const toastNotificationsReducer = {
  add(state: IToastNotificationsStoreState, action: IActionFulfilled<null, IAddToastNotificationActionPayload>) {
    const {payload} = action;
    const notificationsValues = [...values(state.notifications.entities.notifications || {}), payload];
    state.notifications = normalize(notificationsValues, notificationsSchema);
  },

  remove(state: IToastNotificationsStoreState, action: IActionFulfilled<null, IRemoveToastNotificationActionPayload>) {
    const {
      payload: {id},
    } = action;

    let notificationsValues: IToastNotification[] = [];

    if (state.notifications.entities.notifications) {
      notificationsValues = values(state.notifications.entities.notifications).filter(
        (notification: IToastNotification) => notification.id !== id
      );
    }
    state.notifications = normalize(notificationsValues, notificationsSchema);
  },
};

import {IWithDisabled} from 'models';
import React from 'react';
import {ProgramSelect} from './ProgramSelect';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProgramMultiSelectProps extends IWithDisabled {}

export function ProgramMultiSelect({disabled}: IProgramMultiSelectProps) {
  return <ProgramSelect selectionType="multi" disabled={disabled} />;
}

import React from 'react';
import {PageLayoutHorizontalRows} from 'layout/components/page';
import {Wrapper} from 'components-lib';
import {GoogleSiteAreasDetails, GoogleSitesDetails, Header} from './components';

export const GoogleSitesViewDetails = () => {
  return (
    <Wrapper>
      <PageLayoutHorizontalRows
        header={<Header />}
        contentUp={<GoogleSitesDetails />}
        contentDown={<GoogleSiteAreasDetails />}
      />
    </Wrapper>
  );
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {prefix} from './config';
import {programsApi} from 'api';
import {IGetPageOfProgramClassInstrumentsResponse, IGetPageOfProgramClassInstrumentsPayload} from 'models';
import {toastNotificationManager} from 'toast-notifications';

export const fetchProgramClassInstrumentsThunk: any = createAsyncThunk<
  IGetPageOfProgramClassInstrumentsResponse,
  IGetPageOfProgramClassInstrumentsPayload
>(
  `${prefix} fetchProgramClassInstrumentsPaged`,
  async (payload: IGetPageOfProgramClassInstrumentsPayload, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await programsApi.getProgramClassInstrumentsPaged(payload);

      return parsedBody;
    } catch (err) {
      dispatch(
        toastNotificationManager.createErrorToastAction(err.parsedBody || err.statusText || 'Unknown error occurred.')
      );
      return rejectWithValue({error: err});
    }
  }
);

import {Icon} from 'components-lib';
import React from 'react';
import {ToastNotificationTypeEnum} from '../../enums';

interface IToastIconProps {
  type: ToastNotificationTypeEnum;
}

export function ToastIcon({type}: IToastIconProps) {
  const iconsMap = {
    [ToastNotificationTypeEnum.info]: Icon.InfoIcon,
    [ToastNotificationTypeEnum.success]: Icon.CheckIcon,
    [ToastNotificationTypeEnum.warning]: Icon.WarningIcon,
    [ToastNotificationTypeEnum.error]: Icon.ErrorIcon,
  };

  let IconComponent = iconsMap[type];
  if (!IconComponent) {
    IconComponent = iconsMap[ToastNotificationTypeEnum.info];
  }

  return <IconComponent />;
}

import {createSlice} from '@reduxjs/toolkit';
import {uploadReducer} from './upload.reducer';
import {uploadInitialState} from './upload.initialState';
import {uploadExtraReducer} from './uploadExtra.reducer';

export const uploadSlice = createSlice({
  name: 'upload',
  initialState: uploadInitialState,
  reducers: uploadReducer,
  extraReducers: uploadExtraReducer,
});

import {SvgIcon} from '@material-ui/core';
import React, {FC} from 'react';
import {IComponentWithClassName} from 'models';
import {ReactComponent as IllustrationFolderImg} from 'resources/images/uploader/upload_file_icon.svg';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUploadFileIconProps extends IComponentWithClassName {}

export const UploadFileIcon: FC<IUploadFileIconProps> = ({className}) => {
  return (
    <SvgIcon viewBox="0 0 23.593 29.701" className={className}>
      {<IllustrationFolderImg />}
    </SvgIcon>
  );
};

import {IActionFulfilled, IUserRole} from 'models';
import {IPermissionsStoreState} from './IPermissionsStoreState';
import {fetchUserActivityExportThunk, fetchUserRoleListThunk, fetchUserRoleThunk} from './thunks';

export const permissionsExtraReducer = {
  // fetchUserRoleThunk
  [fetchUserRoleThunk.fulfilled]: (state: IPermissionsStoreState) => {
    state.loading = false;
  },
  [fetchUserRoleThunk.pending]: (state: IPermissionsStoreState) => {
    state.loading = true;
    state.requestTriggered = true;
  },
  [fetchUserRoleThunk.rejected]: (state: IPermissionsStoreState) => {
    state.loading = false;
  },

  //fetchUserRoleListThunk
  [fetchUserRoleListThunk.fulfilled]: (state: IPermissionsStoreState, action: IActionFulfilled<null, IUserRole[]>) => {
    state.roleList = action.payload;
  },

  // fetchUserActivityExportThunk
  [fetchUserActivityExportThunk.fulfilled]: (state: IPermissionsStoreState) => {
    state.exporting = false;
  },
  [fetchUserActivityExportThunk.pending]: (state: IPermissionsStoreState) => {
    state.exporting = true;
  },
  [fetchUserActivityExportThunk.rejected]: (state: IPermissionsStoreState) => {
    state.exporting = false;
  },
};

import * as Yup from 'yup';
import {checkIfTypeStartsWithPreOrPostSessionFirstDigit, FormNameEnum} from 'filters-selections';
import {
  allowedFooterLogoDimensions,
  allowedHeaderLogoDimensions,
  ALLOWED_INSTRUCTIONAL_TEXT_LENGTH,
  ALLOWED_LOGO_SIZE,
  audienceStudentId,
  errorMessages,
  MAX_ALLOWED_SIZE,
  SUPPORTED_LOGO_FORMATS,
  typeFeedbackId,
} from 'utils';

export const instrumentDetailsFormBaseValidationSchema = Yup.object().shape({
  [FormNameEnum.templateName]: Yup.string().trim().required(errorMessages.instrumentNameRequired),
  [FormNameEnum.instructionText]: Yup.string()
    .trim()
    .test('has-allowed-chars', 'Some characters are not allowed: + - = * #', (value) => {
      const regex = /^[a-zA-Z0-9 .:;,'"~`!?@$%^&_|<>(){}]+$/;
      if (value) return regex.test(value);
      return true;
    })
    .test('has-allowed-len', 'Instructional text length must be less than 500 characters!', (value) => {
      if (value) return value.length <= ALLOWED_INSTRUCTIONAL_TEXT_LENGTH;
      return true;
    }),
  [FormNameEnum.pillars]: Yup.number()
    .nullable()
    .when(FormNameEnum.isGeneralInstrument, {
      is: true,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().nullable().required(errorMessages.pillarRequired),
    }),
  [FormNameEnum.programs]: Yup.array()
    .of(Yup.number())
    .when(FormNameEnum.isGeneralInstrument, {
      is: true,
      then: Yup.array().of(Yup.number()).notRequired(),
      otherwise: Yup.array().of(Yup.number()).required(errorMessages.programRequired),
    }),
  [FormNameEnum.phase]: Yup.number()
    .nullable()
    .when(FormNameEnum.isGeneralInstrument, {
      is: true,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().nullable().required(errorMessages.phaseRequired),
    }),
  [FormNameEnum.instrumentType]: Yup.number()
    .nullable()
    .when(FormNameEnum.isGeneralInstrument, {
      is: true,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().nullable().required(errorMessages.instrumentTypeRequired),
    })
    .test('instrument-type-test', errorMessages.onlyGeneralAllowed, function (type) {
      const audience = this.parent[FormNameEnum.audience];
      const isGeneral = this.parent[FormNameEnum.isGeneralInstrument];

      if (!audience || isGeneral) return true;

      return !(audience !== audienceStudentId && type !== typeFeedbackId);
    }),
  [FormNameEnum.instrumentSubType]: Yup.number()
    .nullable()
    .when(FormNameEnum.isGeneralInstrument, {
      is: true,
      then: Yup.number(),
      otherwise: Yup.number().nullable(),
    })
    .test('instrument-sub-type-test', errorMessages.subTypeRequired, function () {
      const type = this.parent[FormNameEnum.instrumentType];
      const subType = this.parent[FormNameEnum.instrumentSubType];
      const isGeneral = this.parent[FormNameEnum.isGeneralInstrument];

      if (!isGeneral) {
        const isPreOrPostSession = !!type && checkIfTypeStartsWithPreOrPostSessionFirstDigit(type?.toString());

        if (isPreOrPostSession) {
          return !!subType;
        }
      }

      this.parent[FormNameEnum.instrumentSubType] = null;
      return true;
    }),
  [FormNameEnum.audience]: Yup.number()
    .nullable()
    .when(FormNameEnum.isGeneralInstrument, {
      is: true,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().nullable().required(errorMessages.audienceRequired),
    }),

  [FormNameEnum.headerLogo]: Yup.mixed()
    .nullable()
    .test('has-correct-format', 'Unsupported Format!', (value) =>
      value ? SUPPORTED_LOGO_FORMATS.includes(value.type) : true
    )
    .test('has-correct-size', 'File you want to upload is with incorrect size!', (value) =>
      value ? value.size / MAX_ALLOWED_SIZE <= ALLOWED_LOGO_SIZE : true
    )
    .test('has-proper-dimensions', 'File you want to upload is with incorrect dimensions!', (value) => {
      if (value?.dimensions) {
        return (
          allowedHeaderLogoDimensions.width === value.dimensions.width &&
          allowedHeaderLogoDimensions.height === value.dimensions.height
        );
      }
      return true;
    }),

  [FormNameEnum.footerLogo]: Yup.mixed()
    .nullable()
    .test('has-correct-format', 'Unsupported Format!', (value) =>
      value ? SUPPORTED_LOGO_FORMATS.includes(value.type) : true
    )
    .test('has-correct-size', 'File you want to upload is with incorrect size!', (value) =>
      value ? value.size / MAX_ALLOWED_SIZE <= ALLOWED_LOGO_SIZE : true
    )
    .test('has-proper-dimensions', 'File you want to upload is with incorrect dimensions!', (value) => {
      if (value?.dimensions) {
        return (
          allowedFooterLogoDimensions.width === value.dimensions.width &&
          allowedFooterLogoDimensions.height === value.dimensions.height
        );
      }
      return true;
    }),
});

export interface IInstrumentDetailsFormBaseValues {
  [FormNameEnum.templateName]: string;
  [FormNameEnum.instructionText]: string;
  [FormNameEnum.headerLogo]: File;
  [FormNameEnum.footerLogo]: File;
  [FormNameEnum.pillars]: number;
  [FormNameEnum.programs]: number[];
  [FormNameEnum.phase]: number;
  [FormNameEnum.instrumentType]: number;
  [FormNameEnum.instrumentSubType]: number;
  [FormNameEnum.audience]: number;
  [FormNameEnum.isGeneralInstrument]: boolean;
}

import React, {FC} from 'react';
import {
  makeStyles,
  TableCell as MUITableCell,
  TableHead as MUITableHead,
  TableRow as MUITableRow,
} from '@material-ui/core';
import classnames from 'classnames';
import {IColumn} from 'models';

interface ITableHeaderProps {
  columns: IColumn[];
}

export const TableHeader: FC<ITableHeaderProps> = ({columns}) => {
  const {header, cell} = useStyles();

  return (
    <MUITableHead>
      <MUITableRow>
        {columns.map((column) => (
          <MUITableCell key={column.id} size="small" align={column.align} className={classnames(header, cell)}>
            {column.label}
          </MUITableCell>
        ))}
      </MUITableRow>
    </MUITableHead>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.grey[700],
    fontWeight: 600,
    backgroundColor: theme.palette.common.white,
  },

  /**
   *  Resizable feature is available in the MUI Grid component (see: @mui/x-data-grid-pro - under Commercial License)
   *  https://material-ui.com/store/items/material-ui-pro/
   *  React table supports MUI lib but there isn't enough information in the docs, please see the github issue for more information:
   *  https://github.com/tannerlinsley/react-table/issues/3314
   */

  cell: {
    border: 0,
    /*
      Not optimized version

    '&:hover, &:active': {
      resize: 'horizontal',
      overflow: 'hidden',
    }, */
  },
}));

import React from 'react';
import {Form} from 'forms';
import {InputTypeEnum} from 'enums/form';

export const IntegrationFormInput = ({name, type = 'text', disabled, withIcon, iconPosition, icon, value}) => {
  if (type === InputTypeEnum.Password) {
    return <Form.PasswordInput name={name} disabled={disabled} value="" />;
  }

  return (
    <Form.InputWithField
      name={name}
      value={value}
      type={type}
      disabled={disabled}
      withIcon={withIcon}
      iconPosition={iconPosition}
      icon={icon}
    />
  );
};

import {createSlice} from '@reduxjs/toolkit';
import {permissionsInitialState} from './permissions.initialState';
import {permissionsExtraReducer} from './permissionsExtra.reducer';

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: permissionsInitialState,
  reducers: {},
  extraReducers: permissionsExtraReducer,
});

export const {actions: permissionsActions, reducer: permissionsReducer} = permissionsSlice;

import {FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import {IComponentWithChildren} from 'models';
import React from 'react';
import {usePermissions} from 'permissions';
import {checkIfSuperAdmin} from 'admin/library/instruments/details';

export interface IRolloverProgramsFormData {
  [FormNameEnum.programs]: number[];
}

interface IRolloverProgramsProps extends IComponentWithChildren {
  submitHandler: (data: IRolloverProgramsFormData, actions: any) => void;
}

export function RolloverProgramsForm({children, submitHandler}: IRolloverProgramsProps) {
  // const {hasPermission} = usePermissions();
  // const isSuperAdmin = checkIfSuperAdmin(hasPermission);

  const initialValues: IRolloverProgramsFormData = {
    [FormNameEnum.programs]: [],
  };

  return (
    <Form.Form<IRolloverProgramsFormData>
      initialValues={initialValues}
      validationSchema={null}
      submitHandler={submitHandler}
    >
      {children}
    </Form.Form>
  );
}

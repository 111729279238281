import {useNavigate} from 'hooks';
import {paths} from 'paths';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {userProfileLoadingSelector, userProfileSelector} from 'user';
import {isObject} from 'utils';
import {ErrorPage} from '../ErrorPage/ErrorPage';

export function AuthFailedPage() {
  const {push} = useNavigate();
  const userProfile = useSelector(userProfileSelector);
  const userStateLoading = useSelector(userProfileLoadingSelector);

  const returnHomeClickHandler = () => {
    // use window location instead of 'push', because of an issue with oidc library
    window.location.replace(paths.root);
  };

  useEffect(() => {
    if (!userStateLoading && isObject(userProfile)) {
      push(paths.root);
    }
  }, [userStateLoading, userProfile, push]);

  return <ErrorPage returnHomeClickHandler={returnHomeClickHandler} />;
}

import {IActionFulfilled} from 'models/common';
import {cloneDeep} from 'utils';
import {learningPathsAllInitialState} from '.';
import {ILearningPathsAllFilter, ILearningPathsAllSortOrder} from 'models/resources/learning-paths';
import {ILearningPathsAllStoreState} from './ILearningPathsAllStoreState';

export const learningPathsAllReducer = {
  clear(state: ILearningPathsAllStoreState) {
    state.list = {...learningPathsAllInitialState.list};
  },

  setCurrentPageLearningPaths: (state: ILearningPathsAllStoreState, action: IActionFulfilled<null, number>) => {
    state.pagination.currentPage = action.payload;
  },

  setPerPageLearningPaths: (state: ILearningPathsAllStoreState, action: IActionFulfilled<null, number>) => {
    state.pagination.perPage = action.payload;

    resetPagination(state);
  },

  setTotalCountLearningPaths: (state: ILearningPathsAllStoreState, action: IActionFulfilled<null, number>) => {
    state.pagination.totalCount = action.payload;
  },

  setSortOrderFilesAll: (
    state: ILearningPathsAllStoreState,
    action: IActionFulfilled<null, ILearningPathsAllSortOrder>
  ) => {
    state.sortOrder = action.payload;
  },

  setFilterLearningPathsAll: (
    state: ILearningPathsAllStoreState,
    action: IActionFulfilled<null, ILearningPathsAllFilter>
  ) => {
    state.filter = action.payload;
    resetPagination(state);
  },

  resetFilterLearningPathsAll: (state: ILearningPathsAllStoreState) => {
    state.filter = cloneDeep(learningPathsAllInitialState.filter);
    resetPagination(state);
  },
};

const resetPagination = (state: ILearningPathsAllStoreState) => {
  state.pagination.currentPage = learningPathsAllInitialState.pagination.currentPage;
};

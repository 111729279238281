import React, {FC, ReactNode, useCallback} from 'react';
import {IWithModal} from 'models';
import {Button, Column, Modal, Row, Text, Wrapper} from 'components-lib';
import {Form} from 'forms';
interface IModalUploadProps extends IWithModal {
  title?: string;
  text?: string;
  loading?: boolean;
  content?: ReactNode;
  withSubmitButton?: boolean;
  buttonsSize?: 'small' | 'large';
  disableSubmit?: boolean;
  disableBackdropClick?: boolean;
  confirmHandler: () => void;
  closeHandler?: () => void;
}

export const ModalUpload: FC<IModalUploadProps> = ({
  isOpen,
  loading,
  withSubmitButton,
  content,
  text,
  title = 'Upload Resource',
  buttonsSize = 'small',
  disableSubmit = false,
  disableBackdropClick,
  confirmHandler,
  closeHandler,
}) => {
  const confirmButtonText = loading ? 'Loading...' : 'Upload';

  const confirmActionHandler = useCallback(() => confirmHandler(), [confirmHandler]);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={closeHandler}
      title={title}
      size="medium"
      disableBackdropClick={disableBackdropClick}
    >
      <Column>
        {text && (
          <Row align="center" justify="center">
            <Text.Paragraph>{text}</Text.Paragraph>
          </Row>
        )}
        {content && content}
      </Column>
      <Wrapper>
        <Button.Secondary clickHandler={closeHandler} size={buttonsSize}>
          Cancel
        </Button.Secondary>
        {withSubmitButton ? (
          <Form.ButtonSubmit text={confirmButtonText} size={buttonsSize} />
        ) : (
          <Button.Primary disabled={disableSubmit} size={buttonsSize} clickHandler={confirmActionHandler}>
            {confirmButtonText}
          </Button.Primary>
        )}
      </Wrapper>
    </Modal>
  );
};

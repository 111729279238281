import {SortingOrderEnum} from 'enums';

import {IProgramsAllSortOrder} from 'models';
import {ProgramDropdownNameEnum, ProgramsAllOrderingEnum} from '../all/enums';

export const getProgramsMenuItemsList = (
  itemClickHandler: () => void,
  handleItemClick: (handleItemSortObj: IProgramsAllSortOrder) => void
) => {
  const onClick = (
    itemClickHandler: () => void,
    handleItemClick: (handleItemSortObj: IProgramsAllSortOrder) => void,
    handleItemSortObj: IProgramsAllSortOrder
  ) => {
    return () => {
      itemClickHandler();
      handleItemClick(handleItemSortObj);
    };
  };

  return [
    {
      name: ProgramDropdownNameEnum.NAME_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramsAllOrderingEnum.Name,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: ProgramDropdownNameEnum.NAME_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramsAllOrderingEnum.Name,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: ProgramDropdownNameEnum.CREATED_NEWEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramsAllOrderingEnum.ProgramYear,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: ProgramDropdownNameEnum.CREATED_OLDEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: ProgramsAllOrderingEnum.ProgramYear,
        sort: SortingOrderEnum.ASC,
      }),
    },
  ];
};

import React, {useEffect, useMemo, useState} from 'react';
import {unwrapResult} from '@reduxjs/toolkit';

import {FormNameEnum} from 'filters-selections';
import {fetchSessionsByProgramIdsThunk} from 'filters-selections/store';
import {useAppDispatch} from 'hooks';
import {useIsProgramSelected} from 'filters-selections/hooks';
import {IOption, IWithDisabled} from 'models';

import {Form} from 'forms';
import {Column, Label} from 'components-lib';

interface ISessionsEntity {
  id: number;
  name: string;
  programId?: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProgramSelectProps extends IWithDisabled {}

export function SessionByProgramSelect({disabled}: IProgramSelectProps) {
  const dispatch = useAppDispatch();
  const {isProgramSelected, formValues} = useIsProgramSelected();
  const [sessions, setSessions] = useState([]);
  const programIds = formValues[FormNameEnum.programs];

  useEffect(() => {
    if (programIds.length) {
      dispatch(fetchSessionsByProgramIdsThunk(programIds))
        .then(unwrapResult)
        .then((response: ISessionsEntity[]) => {
          setSessions(response);
        });
    }
    return () => setSessions([]);
  }, [dispatch, programIds]);

  const options = useMemo(() => {
    if (!sessions.length) {
      return [];
    } else {
      return sessions.length && mapOptions(sessions);
    }
  }, [sessions]);

  return (
    <Column>
      <React.Fragment>
        <Label label="Sessions" />
        <Form.SelectAutocomplete
          name={FormNameEnum.sessions}
          options={options}
          multiple
          disabled={!isProgramSelected || disabled}
        />
      </React.Fragment>
    </Column>
  );
}

const mapOptions = (sessions: ISessionsEntity[]): IOption[] => {
  return sessions?.map((f) => ({value: f.id, label: f.name}));
};

import {FiltersEnum} from 'filters-selections';

export const itemsFilterList = [
  FiltersEnum.ProgramMulti,
  FiltersEnum.Pillar,
  FiltersEnum.EvaluationType,
  FiltersEnum.Domain,
  FiltersEnum.LearningObjective,
  FiltersEnum.Category,
  FiltersEnum.Competency,
  FiltersEnum.Concept,
  FiltersEnum.Administration,
  FiltersEnum.SchoolLevel,
  FiltersEnum.AreasAutocompleteMulti,
];

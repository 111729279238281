import React, {FC, Fragment, useCallback, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {IOption, IWithDisabled} from 'models';
import {
  FormNameEnum,
  delayTime,
  fetchInstrumentsThunk,
  loadingStateInstruments,
  filtersActions as actions,
  initialMappedInstrumentsCollectionSelector,
  dynamicSearchMessages,
} from 'filters-selections';
import {Form} from 'forms';
import {useAppDispatch, useDebounce} from 'hooks';
import {Label} from 'components-lib';

type InstrumentsSelectProps = IWithDisabled;

export const InstrumentsSelect: FC<InstrumentsSelectProps> = ({disabled}) => {
  const dispatch = useAppDispatch();
  const instruments = useSelector(initialMappedInstrumentsCollectionSelector);
  const loading = useSelector(loadingStateInstruments);

  useEffect(() => {
    if (!instruments.length) {
      dispatch(
        fetchInstrumentsThunk({
          filter: {
            query: '',
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(actions.clearInstruments());
    };
  }, [dispatch]);

  const options: IOption[] = useMemo(() => {
    if (loading) {
      return [];
    }

    return instruments.length > 0
      ? instruments.map(({id, displayText}) => ({
          value: id,
          label: displayText,
        }))
      : [];
  }, [instruments, loading]);

  const debouncedSearch = useDebounce(
    (query: string) =>
      dispatch(
        fetchInstrumentsThunk({
          filter: {
            query,
          },
        })
      ),
    delayTime
  );

  const handleInputChange = useCallback(
    (value: string) => {
      const newValue = value.trim();
      return newValue && debouncedSearch(newValue);
    },
    [debouncedSearch]
  );

  return (
    <Fragment>
      <Label label="Existing Instruments" />
      <Form.SearchSelectAutocomplete
        loading={loading}
        name={FormNameEnum.instruments}
        options={options}
        disabled={disabled || loading}
        noOptionsText={dynamicSearchMessages.noOptionsInstrument}
        inputChangeHandler={handleInputChange}
        withFontWeightBold={true}
        withCheckboxRenderOption={false}
      />
    </Fragment>
  );
};

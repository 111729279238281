import {ClassTypes} from 'admin/programs/details/enums';
import {Accordion, Column} from 'components-lib';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
import React from 'react';
import {FormNameEnum} from '../../../enums';

export function ClassIsVirtualFilter({disabled}: IWithDisabled) {
  return (
    <Accordion heading="Class Type">
      <Column>
        <Form.RadioGroup name={FormNameEnum.onlyVirtualClass}>
          {ClassTypes.map((type, key) => (
            <Form.Radio key={`classType-${key}`} value={key} label={type} disabled={disabled} />
          ))}
        </Form.RadioGroup>
      </Column>
    </Accordion>
  );
}

import React from 'react';
import {SidebarTag} from '../SidebarTag/SidebarTag';

interface ISidebarTagWithValueProps {
  tag: string;
  text: string;
}

export function SidebarTagWithValue({tag, text}: ISidebarTagWithValueProps) {
  return text ? <SidebarTag text={`${tag}: ${text}`} /> : null;
}

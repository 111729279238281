import React, {FC, ReactNode} from 'react';
import {Text} from 'components-lib';
import {FastField as FormikFastField, Field as FormikField, FieldProps} from 'formik';
import {IComponentWithChildren, IOption} from 'models';

interface IFieldProps<T> extends IComponentWithChildren {
  name: string;
  loading?: boolean;
  value?: string | number | Date;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  toolbarHidden?: boolean;
  checked?: boolean;
  Component: FC<any>;
  options?: IOption[];
  multiple?: boolean;
  withMinDate?: boolean;
  withMaxDate?: boolean;
  maxWidth?: boolean;
  minDateFormField?: string;
  maxDateFormField?: string;
  minDateMessage?: string;
  maxDateMessage?: string;
  withDefaultChangeHandler?: boolean;
  withEdit?: boolean;
  withIcon?: boolean;
  customValueChangeHandler?: T;
  debounceDelay?: number;
  withInstructionalText?: boolean;
  noOptionsText?: string;
  type?: string;
  min?: string;
  max?: string;
  withCheckboxRenderOption?: boolean;
  withSearchOnType?: boolean;
  withFontWeightBold?: boolean;
  disableCloseOnSelect?: boolean;
  color?: 'primary' | 'secondary';
  iconPosition?: string;
  icon?: ReactNode;
  rows?: number;
  disableFuture?: boolean;
  disablePast?: boolean;
  format?: string;
  isFilter?: boolean;
  open?: string | boolean;
  submitDisabled?: boolean;
  filterOptions?: (options: IOption[]) => IOption[];
  autoFocus?: boolean;
  inputChangeHandler?: (value: string) => void;
  onOpen?: (value: boolean) => void;
}

export function Field<T = any>({name, Component, children, ...rest}: IFieldProps<T>) {
  return (
    <FormikField id={name} name={name}>
      {(props: FieldProps) => {
        const error: any = props.form.errors[name];
        return (
          <>
            <Component name={name} onBlur={props.field.onBlur} {...props} {...rest}>
              {children}
            </Component>
            {error && <Text.Error>{error}</Text.Error>}
          </>
        );
      }}
    </FormikField>
  );
}

export function FastField<T = any>({name, Component, children, ...rest}: IFieldProps<T>) {
  return (
    <FormikFastField id={name} name={name}>
      {(props: FieldProps) => {
        const error: any = props.form.errors[name];
        return (
          <>
            <Component name={name} onBlur={props.field.onBlur} {...props} {...rest}>
              {children}
            </Component>
            {error && <Text.Error>{error}</Text.Error>}
          </>
        );
      }}
    </FormikFastField>
  );
}

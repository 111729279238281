import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';
import {values} from 'utils';

const getPrograms = (state: TStateStore) => state.filtersSelections.programs;
export const programsEntitiesSelector = createSelector(getPrograms, (programs) => programs.entities.programs);
export const programsEntitiesCollectionSelector = createSelector(getPrograms, (programs) => {
  return values(programs.entities.programs);
});

const getSchoolNames = (state: TStateStore) => state.filtersSelections.schoolNames;
export const schoolNamesEntitiesSelector = createSelector(
  getSchoolNames,
  (schoolNames) => schoolNames.entities.schoolNames
);
export const schoolNamesEntitiesCollectionSelector = createSelector(getSchoolNames, (schoolNames) => {
  return values(schoolNames.entities.schoolNames);
});
export const getLoadingStateSchoolNames = (state: TStateStore) => state.filtersSelections.selection.loadingSchoolNames;

const getResources = (state: TStateStore) => state.filtersSelections.resources;
export const resourcesEntitiesSelector = createSelector(getResources, (resources) => resources.entities.resources);
export const resourcesEntitiesCollectionSelector = createSelector(getResources, (resources) => {
  return values(resources.entities.resources);
});
export const loadingStateResources = (state: TStateStore) => state.filtersSelections.selection.loadingResources;

const getResourcesBase = (state: TStateStore) => state.filtersSelections.resourcesBase;
export const resourcesBaseEntitiesSelector = createSelector(
  getResourcesBase,
  (resourcesBase) => resourcesBase.entities.resourcesBase
);
export const resourcesBaseEntitiesCollectionSelector = createSelector(getResourcesBase, (resourcesBase) => {
  return values(resourcesBase.entities.resourcesBase);
});

const getResourcesConcatenatedBase = (state: TStateStore) => state.filtersSelections.resourcesConcatenatedBase;
export const resourcesConcatenatedBaseEntitiesSelector = createSelector(
  getResourcesConcatenatedBase,
  (resourcesConcatenatedBase) => resourcesConcatenatedBase.entities.resourcesConcatenatedBase
);
export const resourcesConcatenatedBaseEntitiesCollectionSelector = createSelector(
  getResourcesConcatenatedBase,
  (resourcesConcatenatedBase) => {
    return values(resourcesConcatenatedBase.entities.resourcesConcatenatedBase);
  }
);

const getInstruments = (state: TStateStore) => state.filtersSelections.instruments;
export const instrumentsEntitiesSelector = createSelector(
  getInstruments,
  (instruments) => instruments.entities.instruments
);
export const instrumentsEntitiesCollectionSelector = createSelector(getInstruments, (instruments) => {
  return values(instruments.entities.instruments);
});
export const loadingStateInstruments = (state: TStateStore) => state.filtersSelections.selection.loadingInstruments;

const getInitialMappedInstruments = (state: TStateStore) => state.filtersSelections.initialMappedInstruments;
export const initialMappedInstrumentsCollectionSelector = createSelector(
  getInitialMappedInstruments,
  (initialMappedInstruments) => initialMappedInstruments
);

const getInstrumentsBase = (state: TStateStore) => state.filtersSelections.instrumentsBase;
export const instrumentsBaseEntitiesSelector = createSelector(
  getInstrumentsBase,
  (instrumentsBase) => instrumentsBase.entities.instrumentsBase
);
export const instrumentsBaseEntitiesCollectionSelector = createSelector(getInstrumentsBase, (instrumentsBase) => {
  return values(instrumentsBase.entities.instrumentsBase);
});

const getInstrumentsConcatenatedBase = (state: TStateStore) => state.filtersSelections.instrumentsConcatenatedBase;
export const instrumentsConcatenatedBaseEntitiesSelector = createSelector(
  getInstrumentsConcatenatedBase,
  (instrumentsConcatenatedBase) => instrumentsConcatenatedBase.entities.instrumentsConcatenatedBase
);
export const instrumentsConcatenatedBaseEntitiesCollectionSelector = createSelector(
  getInstrumentsConcatenatedBase,
  (instrumentsConcatenatedBase) => {
    return values(instrumentsConcatenatedBase.entities.instrumentsConcatenatedBase);
  }
);

const getFiltersYears = (state: TStateStore) => state.filtersSelections.years;
export const filtersYearsSelector = createSelector(getFiltersYears, (years) => years);
export const filtersYearsEntitiesSelector = createSelector(filtersYearsSelector, (years) => years.entities.years);
export const filtersYearsEntitiesCollectionSelector = createSelector(getFiltersYears, (years) => {
  return values(years.entities.years);
});

const getFiltersFutureYears = (state: TStateStore) => state.filtersSelections.futureYears;
export const filtersFutureYearsSelector = createSelector(getFiltersFutureYears, (futureYears) => futureYears);
export const filtersFutureYearsEntitiesSelector = createSelector(
  filtersFutureYearsSelector,
  (futureYears) => futureYears.entities.futureYears
);
export const filtersFutureYearsEntitiesCollectionSelector = createSelector(getFiltersFutureYears, (futureYears) => {
  return values(futureYears.entities.futureYears);
});

const getFiltersGradeLevels = (state: TStateStore) => state.filtersSelections.gradeLevels;
export const filtersGradeLevelsEntitiesSelector = createSelector(
  getFiltersGradeLevels,
  (gradeLevels) => gradeLevels.entities.gradeLevels
);
export const filtersGradeLevelsEntitiesCollectionSelector = createSelector(getFiltersGradeLevels, (gradeLevels) => {
  return values(gradeLevels.entities.gradeLevels);
});

export const filtersEntitiesSelector = createSelector(
  [programsEntitiesSelector, filtersYearsSelector, filtersGradeLevelsEntitiesSelector],
  (programs, years, gradeLevels) => {
    return {programs, years, gradeLevels};
  }
);

export const filtersEntitiesCollectionSelector = createSelector(
  [
    programsEntitiesCollectionSelector,
    filtersYearsEntitiesCollectionSelector,
    filtersGradeLevelsEntitiesCollectionSelector,
  ],
  (programs, years, gradeLevels) => {
    return {programs, years, gradeLevels};
  }
);

const getFiltersAreas = (state: TStateStore) => state.filtersSelections.areas;
export const filtersAreasSelector = createSelector(getFiltersAreas, (areas) => areas);
export const filtersAreasEntitiesSelector = createSelector(getFiltersAreas, (areas) => areas.entities.areas);
export const filtersAreasEntitiesCollectionSelector = createSelector(getFiltersAreas, (areas) => {
  return values(areas.entities.areas);
});

const getFiltersEducators = (state: TStateStore) => state.filtersSelections.educators;
export const filtersEducatorsEntitiesSelector = createSelector(
  getFiltersEducators,
  (educators) => educators.entities.educators
);
export const filtersEducatorsEntitiesCollectionSelector = createSelector(getFiltersEducators, (educators) => {
  return educators.entities.educators ? values(educators.entities.educators) : [];
});

const getFiltersPeriod = (state: TStateStore) => state.filtersSelections.period;
export const filtersPeriodSelector = createSelector(getFiltersPeriod, (period) => period);

const getFiltersSchools = (state: TStateStore) => state.filtersSelections.schools;
export const filtersSchoolsEntitiesCollectionSelector = createSelector(getFiltersSchools, (schools) => {
  return schools.entities.schools ? values(schools.entities.schools) : [];
});

const getFiltersDistricts = (state: TStateStore) => state.filtersSelections.districts;
export const filtersDistrictsSelector = createSelector(getFiltersDistricts, (districts) => districts);

export const filtersClassEntitiesCollectionSelector = createSelector(
  [
    filtersAreasEntitiesCollectionSelector,
    filtersEducatorsEntitiesCollectionSelector,
    schoolNamesEntitiesCollectionSelector,
    filtersDistrictsSelector,
    filtersYearsEntitiesCollectionSelector,
  ],
  (areas, educators, schoolNames, districts, programYears) => {
    return {areas, educators, schoolNames, districts, programYears};
  }
);

export const filtersClassEntitiesSelector = createSelector(
  [
    filtersAreasEntitiesSelector,
    filtersEducatorsEntitiesSelector,
    schoolNamesEntitiesSelector,
    filtersDistrictsSelector,
    filtersYearsEntitiesSelector,
  ],
  (areas, educators, schoolNames, districts, programYears) => {
    return {areas, educators, schoolNames, districts, programYears};
  }
);

const getFiltersAudiences = (state: TStateStore) => state.filtersSelections.audiences;
export const filtersAudiencesEntitiesSelector = createSelector(
  getFiltersAudiences,
  (audiences) => audiences.entities.audiences
);
export const filtersAudiencesEntitiesCollectionSelector = createSelector(getFiltersAudiences, (audiences) => {
  return values(audiences.entities.audiences);
});

const getFiltersPhases = (state: TStateStore) => state.filtersSelections.phases;
export const filtersPhasesEntitiesSelector = createSelector(getFiltersPhases, (phases) => phases.entities.phases);
export const filtersPhasesEntitiesCollectionSelector = createSelector(getFiltersPhases, (phases) => {
  return values(phases.entities.phases);
});

export const filtersMasterInstrumentsEntitiesSelector = createSelector(
  [filtersAudiencesEntitiesSelector, filtersPhasesEntitiesSelector],
  (audiences, phases) => {
    return {audiences, phases};
  }
);

const getFiltersInstrumentTypes = (state: TStateStore) => state.filtersSelections.instrumentTypes;
export const filtersInstrumentTypesEntitiesSelector = createSelector(
  getFiltersInstrumentTypes,
  (instrumentTypes) => instrumentTypes.entities.instrumentTypes
);
export const filtersInstrumentTypesEntitiesCollectionSelector = createSelector(
  getFiltersInstrumentTypes,
  (instrumentTypes) => {
    return values(instrumentTypes.entities.instrumentTypes);
  }
);

const getFiltersInstrumentMainTypes = (state: TStateStore) => state.filtersSelections.instrumentMainTypes;
export const filtersInstrumentMainTypesEntitiesSelector = createSelector(
  getFiltersInstrumentMainTypes,
  (instrumentMainTypes) => instrumentMainTypes.entities.instrumentMainTypes
);
export const filtersInstrumentMainTypesEntitiesCollectionSelector = createSelector(
  getFiltersInstrumentMainTypes,
  (instrumentMainTypes) => {
    return values(instrumentMainTypes.entities.instrumentMainTypes);
  }
);

const getFiltersInstrumentStatuses = (state: TStateStore) => state.filtersSelections.instrumentStatuses;
export const filtersInstrumentStatusesEntitiesSelector = createSelector(
  getFiltersInstrumentStatuses,
  (instrumentStatuses) => instrumentStatuses.entities.instrumentStatuses
);
export const filtersInstrumentStatusesEntitiesCollectionSelector = createSelector(
  getFiltersInstrumentStatuses,
  (instrumentStatuses) => {
    return values(instrumentStatuses.entities.instrumentStatuses);
  }
);

const getPillars = (state: TStateStore) => state.filtersSelections.pillars;
export const pillarsEntitiesSelector = createSelector(getPillars, (pillars) => pillars.entities.pillars);
export const pillarsEntitiesCollectionSelector = createSelector(getPillars, (pillars) => {
  return values(pillars.entities.pillars);
});

const getFiltersDomains = (state: TStateStore) => state.filtersSelections.domains;
export const filtersDomainsEntitiesSelector = createSelector(getFiltersDomains, (domains) => domains.entities.domains);
export const filtersDomainsEntitiesCollectionSelector = createSelector(getFiltersDomains, (domains) => {
  return values(domains.entities.domains);
});

const getFiltersEvaluationType = (state: TStateStore) => state.filtersSelections.evaluationTypes;
export const filtersEvaluationTypeEntitiesSelector = createSelector(
  getFiltersEvaluationType,
  (evaluationTypes) => evaluationTypes.entities.evaluationTypes
);
export const filtersEvaluationTypeEntitiesCollectionSelector = createSelector(
  getFiltersEvaluationType,
  (evaluationTypes) => {
    return values(evaluationTypes.entities.evaluationTypes);
  }
);

const getFiltersCompetencies = (state: TStateStore) => state.filtersSelections.competencies;
export const filtersCompetenciesEntitiesSelector = createSelector(
  getFiltersCompetencies,
  (competencies) => competencies.entities.competencies
);
export const filtersCompetenciesEntitiesCollectionSelector = createSelector(getFiltersCompetencies, (competencies) => {
  return values(competencies.entities.competencies);
});

const getFiltersConcepts = (state: TStateStore) => state.filtersSelections.concepts;
export const filtersConceptsEntitiesSelector = createSelector(
  getFiltersConcepts,
  (concepts) => concepts.entities.concepts
);
export const filtersConceptsEntitiesCollectionSelector = createSelector(getFiltersConcepts, (concepts) => {
  return values(concepts.entities.concepts);
});

const getDigitalResourcesFiltersConcepts = (state: TStateStore) => state.filtersSelections.digitalResourcesConcepts;
export const DigitalResourcesFiltersConceptsSelector = createSelector(
  getDigitalResourcesFiltersConcepts,
  (concept) => concept
);

const getFiltersKeyTerms = (state: TStateStore) => state.filtersSelections.keyTerms;
export const KeyTermsSelector = createSelector(getFiltersKeyTerms, (keyTerm) => keyTerm);

const getFiltersLearningObjectives = (state: TStateStore) => state.filtersSelections.learningObjectives;
export const LearningObjectivesSelector = createSelector(getFiltersLearningObjectives, (objective) => objective);

const getFiltersAdministrations = (state: TStateStore) => state.filtersSelections.administrations;
export const filtersAdministrationsEntitiesSelector = createSelector(
  getFiltersAdministrations,
  (administrations) => administrations.entities.administrations
);
export const filtersAdministrationsEntitiesCollectionSelector = createSelector(
  getFiltersAdministrations,
  (administrations) => {
    return values(administrations.entities.administrations);
  }
);

const getFiltersSchoolLevels = (state: TStateStore) => state.filtersSelections.schoolLevel;
export const filtersSchoolLevelsEntitiesSelector = createSelector(
  getFiltersSchoolLevels,
  (schoolLevel) => schoolLevel.entities.schoolLevel
);
export const filtersSchoolLevelsEntitiesCollectionSelector = createSelector(getFiltersSchoolLevels, (schoolLevel) => {
  return values(schoolLevel.entities.schoolLevel);
});

const getFiltersItemTypes = (state: TStateStore) => state.filtersSelections.itemTypes;
export const filtersItemTypesEntitiesSelector = createSelector(
  getFiltersItemTypes,
  (itemTypes) => itemTypes.entities.itemTypes
);
export const filtersItemTypesEntitiesCollectionSelector = createSelector(getFiltersItemTypes, (itemTypes) => {
  return values(itemTypes.entities.itemTypes);
});

const getFiltersItemStatuses = (state: TStateStore) => state.filtersSelections.itemStatuses;
export const filtersItemStatusesEntitiesSelector = createSelector(
  getFiltersItemStatuses,
  (itemStatuses) => itemStatuses.entities.itemStatuses
);
export const filtersItemStatusesEntitiesCollectionSelector = createSelector(getFiltersItemStatuses, (itemStatuses) => {
  return values(itemStatuses.entities.itemStatuses);
});

const getFiltersSessions = (state: TStateStore) => state.filtersSelections.sessions;
export const filtersSessionsEntitiesSelector = createSelector(
  getFiltersSessions,
  (sessions) => sessions.entities.sessions
);
export const filtersSessionsEntitiesCollectionSelector = createSelector(getFiltersSessions, (sessions) => {
  return values(sessions.entities.sessions);
});

const getFiltersTagGradeLevels = (state: TStateStore) => state.filtersSelections.tagGradeLevels;
export const filtersTagGradeLevelsEntitiesSelector = createSelector(
  getFiltersTagGradeLevels,
  (tagGradeLevels) => tagGradeLevels.entities.tagGradeLevels
);
export const filtersTagGradeLevelsEntitiesCollectionSelector = createSelector(
  getFiltersTagGradeLevels,
  (tagGradeLevels) => {
    return values(tagGradeLevels.entities.tagGradeLevels);
  }
);

const getFiltersSkillsAndBehaviors = (state: TStateStore) => state.filtersSelections.skillsAndBehaviors;
export const filtersSkillsAndBehaviorsEntitiesSelector = createSelector(
  getFiltersSkillsAndBehaviors,
  (skillsAndBehaviors) => skillsAndBehaviors.entities.skillsAndBehaviors
);
export const filtersSkillsAndBehaviorsEntitiesCollectionSelector = createSelector(
  getFiltersSkillsAndBehaviors,
  (skillsAndBehaviors) => {
    return values(skillsAndBehaviors.entities.skillsAndBehaviors);
  }
);

const getFiltersLanguages = (state: TStateStore) => state.filtersSelections.languages;
export const filtersLanguagesEntitiesSelector = createSelector(
  getFiltersLanguages,
  (languages) => languages.entities.languages
);
export const filtersLanguagesEntitiesCollectionSelector = createSelector(getFiltersLanguages, (languages) => {
  return values(languages.entities.languages);
});

const getFiltersDefaultKeyTerms = (state: TStateStore) => state.filtersSelections.defaultKeyTerms;
export const filtersKeyTermsEntitiesSelector = createSelector(
  getFiltersDefaultKeyTerms,
  (keyTerms) => keyTerms.entities.keyTerms
);
export const filtersDefaultKeyTermsEntitiesCollectionSelector = createSelector(
  getFiltersDefaultKeyTerms,
  (keyTerms) => {
    return values(keyTerms.entities.keyTerms);
  }
);

const getDigitalResourceTypes = (state: TStateStore) => state.filtersSelections.digitalResourceTypes;
export const filtersDigitalResourceTypesSelector = createSelector(
  getDigitalResourceTypes,
  (digitalResourceTypes) => digitalResourceTypes.entities.digitalResourceTypes
);
export const filtersDigitalResourceTypesEntitiesCollectionSelector = createSelector(
  getDigitalResourceTypes,
  (digitalResourceTypes) => {
    return values(digitalResourceTypes.entities.digitalResourceTypes);
  }
);

const getUploadFileTypes = (state: TStateStore) => state.filtersSelections.uploadFileTypes;
export const filtersUploadFileTypesSelector = createSelector(
  getUploadFileTypes,
  (uploadFileTypes) => uploadFileTypes.entities.uploadFileTypes
);
export const filtersUploadFileTypesEntitiesCollectionSelector = createSelector(
  getUploadFileTypes,
  (uploadFileTypes) => {
    return values(uploadFileTypes.entities.uploadFileTypes);
  }
);

const getIntegrationTypes = (state: TStateStore) => state.filtersSelections.integrationTypes;
export const filterIntegrationTypesEntitiesSelector = createSelector(
  getIntegrationTypes,
  (integrationTypes) => integrationTypes.entities.integrationTypes
);
export const filterIntegrationTypesEntitiesCollectionSelector = createSelector(
  getIntegrationTypes,
  (integrationTypes) => {
    return values(integrationTypes.entities.integrationTypes);
  }
);

const getAccessibleToStudents = (state: TStateStore) => state.filtersSelections.accessibleToStudents;
export const filterAccessibleToStudentsEntitiesSelector = createSelector(
  getAccessibleToStudents,
  (accessibleToStudents) => accessibleToStudents.entities.accessibleToStudents
);
export const filterAccessibleToStudentsEntitiesCollectionSelector = createSelector(
  getAccessibleToStudents,
  (accessibleToStudents) => {
    return values(accessibleToStudents.entities.accessibleToStudents) ?? [];
  }
);

const getPublishedBy = (state: TStateStore) => state.filtersSelections.publishedBy;
export const filterPublishedByEntitiesSelector = createSelector(
  getPublishedBy,
  (publishedBy) => publishedBy.entities.publishedBy
);
export const filterPublishedByEntitiesCollectionSelector = createSelector(getPublishedBy, (publishedBy) => {
  return values(publishedBy.entities.publishedBy) ?? [];
});

const getFileTypes = (state: TStateStore) => state.filtersSelections.fileTypes;
export const filterFileTypesEntitiesSelector = createSelector(
  getFileTypes,
  (fileTypes) => fileTypes.entities.fileTypes
);
export const filterFileTypesEntitiesCollectionSelector = createSelector(getFileTypes, (fileTypes) => {
  return values(fileTypes.entities.fileTypes) ?? [];
});

const getIntegrationStatuses = (state: TStateStore) => state.filtersSelections.integrationStatuses;
export const filterIntegrationStatusesEntitiesSelector = createSelector(
  getIntegrationStatuses,
  (integrationStatuses) => integrationStatuses.entities.integrationStatuses
);
export const filterIntegrationStatusesEntitiesCollectionSelector = createSelector(
  getIntegrationStatuses,
  (integrationStatuses) => {
    return values(integrationStatuses.entities.integrationStatuses);
  }
);

const getSchoolDistricts = (state: TStateStore) => state.filtersSelections.schoolDistricts;
export const schoolDistrictsEntitiesSelector = createSelector(
  getSchoolDistricts,
  (schoolDistricts) => schoolDistricts.entities.schoolDistricts
);
export const schoolDistrictsEntitiesCollectionSelector = createSelector(getSchoolDistricts, (schoolDistricts) => {
  return values(schoolDistricts.entities.schoolDistricts);
});
export const loadingStateDistricts = (state: TStateStore) => state.filtersSelections.selection.loadingDistricts;

const getFiltersLearningPathStatuses = (state: TStateStore) => state.filtersSelections.learningPathStatuses;
export const filtersLearningPathStatusesSelector = createSelector(
  getFiltersLearningPathStatuses,
  (learningPathStatuses) => learningPathStatuses
);
export const filtersLearningPathStatusesEntitiesSelector = createSelector(
  filtersLearningPathStatusesSelector,
  (learningPathStatuses) => learningPathStatuses.entities.learningPathStatuses
);
export const filtersLearningPathStatusesEntitiesCollectionSelector = createSelector(
  getFiltersLearningPathStatuses,
  (learningPathStatuses) => {
    return values(learningPathStatuses.entities.learningPathStatuses);
  }
);

const getFiltersFileStatuses = (state: TStateStore) => state.filtersSelections.fileStatuses;
export const filtersFileStatusesSelector = createSelector(getFiltersFileStatuses, (fileStatuses) => fileStatuses);
export const filtersFileStatusesEntitiesSelector = createSelector(
  filtersFileStatusesSelector,
  (fileStatuses) => fileStatuses.entities.fileStatuses
);
export const filtersFileStatusesEntitiesCollectionSelector = createSelector(getFiltersFileStatuses, (fileStatuses) => {
  return values(fileStatuses.entities.fileStatuses);
});

export const filtersProgramClassInstrumentsEntitiesSelector = createSelector(
  [
    filtersAudiencesEntitiesSelector,
    filtersPhasesEntitiesSelector,
    filtersInstrumentTypesEntitiesSelector,
    filtersInstrumentStatusesEntitiesSelector,
    pillarsEntitiesSelector,
  ],
  (audiences, phases, types, statuses, pillars) => {
    return {audiences, phases, types, statuses, pillars};
  }
);

export const itemFiltersEntitiesSelector = createSelector(
  [
    pillarsEntitiesSelector,
    filtersEvaluationTypeEntitiesSelector,
    filtersDomainsEntitiesSelector,
    programsEntitiesSelector,
    filtersCompetenciesEntitiesSelector,
    filtersConceptsEntitiesSelector,
    filtersAdministrationsEntitiesSelector,
    filtersSchoolLevelsEntitiesSelector,
    filtersItemTypesEntitiesSelector,
    filtersItemStatusesEntitiesSelector,
    filtersAreasEntitiesSelector,
  ],
  (
    pillars,
    evaluationTypes,
    domains,
    programs,
    competencies,
    concepts,
    administrations,
    schoolLevels,
    itemTypes,
    itemStatuses,
    areas
  ) => {
    return {
      pillars,
      evaluationTypes,
      domains,
      programs,
      competencies,
      concepts,
      administrations,
      schoolLevels,
      itemTypes,
      itemStatuses,
      areas,
    };
  }
);

export const instrumentsFiltersEntitiesSelector = createSelector(
  [
    pillarsEntitiesSelector,
    programsEntitiesSelector,
    filtersInstrumentTypesEntitiesSelector,
    filtersInstrumentStatusesEntitiesSelector,
    filtersAudiencesEntitiesSelector,
    filtersPhasesEntitiesSelector,
  ],
  (pillars, programs, types, statuses, audiences, phases) => ({
    pillars,
    programs,
    types,
    statuses,
    audiences,
    phases,
  })
);

export const instrumentsBuilderFiltersEntitiesSelector = createSelector(
  [
    pillarsEntitiesSelector,
    filtersAreasEntitiesSelector,
    filtersEvaluationTypeEntitiesSelector,
    filtersDomainsEntitiesSelector,
    programsEntitiesSelector,
    filtersCompetenciesEntitiesSelector,
    filtersConceptsEntitiesSelector,
    filtersAdministrationsEntitiesSelector,
    filtersSchoolLevelsEntitiesSelector,
  ],
  (pillars, areas, evaluationType, domain, programs, competency, concept, administration, schoolLevel) => ({
    pillars,
    areas,
    evaluationType,
    domain,
    programs,
    competency,
    concept,
    administration,
    schoolLevel,
  })
);

export const integrationFiltersEntitiesSelector = createSelector(
  [
    filterIntegrationTypesEntitiesSelector,
    filterIntegrationStatusesEntitiesSelector,
    schoolNamesEntitiesSelector,
    schoolDistrictsEntitiesSelector,
    filtersAreasEntitiesSelector,
  ],
  (integrationTypes, integrationStatuses, schoolNames, schoolDistricts, areas) => ({
    integrationTypes,
    integrationStatuses,
    schoolNames,
    schoolDistricts,
    areas,
  })
);

export const filesAllFiltersEntitiesSelector = createSelector(
  [
    filterFileTypesEntitiesSelector,
    programsEntitiesSelector,
    filtersTagGradeLevelsEntitiesSelector,
    pillarsEntitiesSelector,
    filtersCompetenciesEntitiesSelector,
    filtersConceptsEntitiesSelector,
    filterAccessibleToStudentsEntitiesSelector,
    filterPublishedByEntitiesSelector,
    filtersLanguagesEntitiesSelector,
    filtersFileStatusesEntitiesSelector,
  ],
  (
    fileTypes,
    programs,
    gradeLevels,
    pillars,
    competencies,
    knowledgeSkillsAndAbilities,
    accessibleToStudents,
    publishedBy,
    languages,
    fileStatuses
  ) => ({
    fileTypes,
    programs,
    gradeLevels,
    pillars,
    competencies,
    knowledgeSkillsAndAbilities,
    accessibleToStudents,
    publishedBy,
    languages,
    fileStatuses,
  })
);

export const learningPathsAllFiltersEntitiesSelector = createSelector(
  [
    pillarsEntitiesSelector,
    programsEntitiesSelector,
    filtersTagGradeLevelsEntitiesSelector,
    filtersLanguagesEntitiesSelector,
    filtersYearsEntitiesSelector,
    filtersLearningPathStatusesEntitiesSelector,
  ],
  (pillars, programs, gradeLevels, languages, programYears, learningPathStatuses) => ({
    pillars,
    programs,
    gradeLevels,
    languages,
    programYears,
    learningPathStatuses,
  })
);

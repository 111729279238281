export const UploaderActionTypes = {
  SET_OPEN_UPLOAD_MODAL: 'open:upload:modal',
  SET_CLOSE_UPLOAD_MODAL: 'close:upload:modal',
  SET_FILE_UPLOAD_PERCENT: 'set:file:upload:percent',
  SET_UPLOAD_FILE_PROGRESS: 'set:upload:file:progress',
  SET_UPLOADED_FILE_STATUS: 'set:uploaded:file:status',
  REMOVE_FILE_FROM_UPLOAD_MANAGER: 'remove:file:from:upload:manager',
  SET_CLOSE_NOTIFICATION_MANAGER: 'close:notification:manager',
  SET_RESOURCE_TYPE_UPLOAD_MODAL: 'set:resource:upload:modal',
  SET_IS_FILE_ATTACHED: 'set:is:file:attached',
  SET_FILE_FOR_UPLOAD: 'set:file:for:upload',
  SET_FILE_TO_UPLOAD: 'set:file:to:upload',
  RESET_FILE_FOR_UPLOAD: 'reset:file:for:upload',
  RESET_IS_FILE_ATTACHED: 'reset:is:file:attached',
  RESET_RESOURCE_TYPE_UPLOAD_MODAL: 'reset:resource:upload:modal',
  CANCEL_FILE_UPLOAD: 'cancel:file:upload',

  POST_UPLOAD_FILE_REQUEST: 'upload:file:request',
  POST_UPLOAD_FILE_SUCCESS: 'upload:file:success',
  POST_UPLOAD_FILE_ERROR: 'upload:file:error',
};

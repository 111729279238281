import React, {useEffect, useMemo} from 'react';
import {Loading} from 'components-lib';
import {useParams} from 'react-router-dom';
import {SurveyTakerPreview} from 'survey-taker';
import {toNumber} from 'utils';
import {useInstrument} from '../details/hooks';

export const InstrumentPreviewWithAnswersView = () => {
  const urlParams = useParams<{id: string}>();
  const instrumentId = toNumber(urlParams?.id);
  const {activeTemplate, fetchInstrumentById, pageLoading, isRoutePreviewWithAnswers} = useInstrument(instrumentId);

  useEffect(() => {
    if (!activeTemplate?.templateId) {
      fetchInstrumentById(instrumentId);
    }
  }, [activeTemplate, fetchInstrumentById, instrumentId]);

  const survey: any = useMemo(
    () => ({
      showCompletedPage: false,
      mode: 'display',
      logo: activeTemplate?.logo,
      logoPosition: activeTemplate?.logoPosition,
      logoWidth: activeTemplate?.logoWidth,
      logoHeight: activeTemplate?.logoHeight,
      footerUrl: activeTemplate?.footer?.url,
      pages: [
        {
          title: activeTemplate?.templateName,
          description: activeTemplate?.description,
          elements: activeTemplate?.elements,
        },
      ],
    }),

    [activeTemplate]
  );

  return pageLoading ? (
    <Loading fullHeight />
  ) : (
    <SurveyTakerPreview survey={survey} isPreviewWithAnswers={isRoutePreviewWithAnswers} />
  );
};

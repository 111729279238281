import * as Yup from 'yup';
import {FilesAllFilterNamesEnum} from '../../../enums';

export interface IFilesAllFiltersFormValues {
  [FilesAllFilterNamesEnum.SEARCH_BY_QUERY]: string;
  [FilesAllFilterNamesEnum.FILE_TYPES]: number[];
  [FilesAllFilterNamesEnum.PROGRAMS]: number[];
  [FilesAllFilterNamesEnum.GRADE_LEVELS]: number[];
  [FilesAllFilterNamesEnum.PILLARS]: number[];
  [FilesAllFilterNamesEnum.COMPETENCIES]: number[];
  [FilesAllFilterNamesEnum.KSAS]: number[];
  [FilesAllFilterNamesEnum.IS_ACCESSIBLE_TO_STUDENTS]: number;
  [FilesAllFilterNamesEnum.PUBLISHED_BY]: number;
  [FilesAllFilterNamesEnum.LANGUAGES]: number[];
  [FilesAllFilterNamesEnum.FILE_STATUSES]: number[];
}

export const filesAllSidebarValidationSchema = Yup.object().shape({});

import {AddIntegrationLabelEnum} from './../../enums';
import {
  getDefaultPreviewFirstColumnList,
  getIsMicrosoftTeams,
  getIsGoogleSitesSource,
  getIsGoogleSitesDestination,
} from 'admin/integrations/utils/helpers';
import {getIsGoogleClassRoom} from 'admin/integrations';

export const getPreviewFirstColumnList = (
  type: number,
  {integrationTypeStringValue, areaName, schoolNameStringValue, certificateName, clientId}
) => {
  const isGoogleClassroom = getIsGoogleClassRoom(type);
  const isGoogleSitesSource = getIsGoogleSitesSource(type);
  const isGoogleSitesDestination = getIsGoogleSitesDestination(type);
  const isMicrosoftTeams = getIsMicrosoftTeams(type);
  const defaultList = getDefaultPreviewFirstColumnList({
    integrationTypeStringValue,
    areaName,
    schoolNameStringValue,
    certificateName,
  });
  let list = [];

  if (isGoogleClassroom || isGoogleSitesSource) {
    list = [...defaultList];
  }

  if (isGoogleSitesDestination) {
    const googleSitesDestinationList = defaultList.slice(0, defaultList.length - 1);
    list = [...googleSitesDestinationList];
  }

  if (isMicrosoftTeams) {
    const microsoftTeamsList = defaultList.slice(0, defaultList.length - 1);
    list = [
      ...microsoftTeamsList,
      {
        label: AddIntegrationLabelEnum.ClientID,
        value: clientId ?? '',
        options: {
          withForm: false,
          disabled: true,
        },
      },
    ];
  }

  return list;
};

import {fetchPhasesThunk} from 'filters-selections';
import {FormNameEnum} from 'filters-selections/enums';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {filtersPhasesEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPhaseSelectProps extends IWithDisabled {}

export function PhaseSelect({disabled}: IPhaseSelectProps) {
  const dispatch = useDispatch();
  const phases = useSelector(filtersPhasesEntitiesCollectionSelector);

  useEffect(() => {
    if (!phases.length) {
      dispatch(fetchPhasesThunk());
    }
  }, [phases.length, dispatch]);

  const options: any = useMemo(() => {
    return phases.map((phase) => ({value: phase.id, label: phase.displayText}));
  }, [phases]);

  return (
    <ItemWithPlaceholder label="Phase" hasItems={!!phases.length}>
      <Form.Select name={FormNameEnum.phase} options={options} disabled={disabled} />
    </ItemWithPlaceholder>
  );
}

import React from 'react';
import MUIMenuIcon from '@material-ui/icons/Menu';

interface IExpandIconProps {
  color?: 'primary' | 'secondary' | 'inherit';
}

export const MenuIcon = ({color = 'inherit'}: IExpandIconProps) => {
  return <MUIMenuIcon color={color} />;
};

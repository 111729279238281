import {
  IActionFulfilled,
  IAnswerStack,
  IBindBaseEntity,
  IBindItemsPage,
  IItemDetails,
  IQuestionBase,
  IUpdateItemPayload,
  TGetQuestionsApiResponse,
} from 'models';
import {normalize} from 'normalizr';
import {isObject, values} from 'utils';
import {IItemsStoreState} from './IItemsStoreState';
import {itemListSchema, itemQuestionsSchema} from './normalizationSchemas';
import {
  createItemThunk,
  createQuestionThunk,
  deleteItemThunk,
  fetchItemThunk,
  fetchListItemsPagedThunk,
  fetchNewItemThunk,
  fetchQuestionsThunk,
  fetchSortByColumnsThunk,
  fetchSortOrderTypesThunk,
  IFetchItemPayload,
  publishAllAnswerStacksForQuestionThunk,
  publishItemAnswerStackThunk,
  unPublishItemAnswerStackThunk,
  createUpdateItemThunk,
  addDefaultAnswerStackThunk,
  fetchPossibleDefaultAnswerStacksThunk,
  updateItemThunk,
  updateAnswerStackThunk,
  addCustomAnswerStackThunk,
} from './thunks';
import {promoteItemAnswerStackThunk} from './thunks/promoteItemAnswerStack.thunk';

export const itemsExtraReducer = {
  //fetchListItemsPagedThunk
  [fetchListItemsPagedThunk.fulfilled]: (state: IItemsStoreState, action: IActionFulfilled<null, IBindItemsPage>) => {
    if (isObject(action.payload)) {
      state.itemList = normalize(action.payload.items, itemListSchema);
      state.pagination.totalCount = action.payload.totalCount;
    }

    state.page.loading = false;
  },
  [fetchListItemsPagedThunk.pending]: (state: IItemsStoreState) => {
    state.page.loading = true;
  },
  [fetchListItemsPagedThunk.rejected]: (state: IItemsStoreState) => {
    state.page.loading = false;
  },

  // fetchNewItemThunk
  [fetchNewItemThunk.fulfilled]: (state: IItemsStoreState, action: IActionFulfilled<number, IAnswerStack[]>) => {
    if (!action.payload) {
      return;
    }

    state.item.answerStacks = [...updateAnswerStackQuestionId(action.payload)];
    state.page.loading = false;
  },
  [fetchNewItemThunk.pending]: (state: IItemsStoreState) => {
    state.page.loading = true;
  },
  [fetchNewItemThunk.rejected]: (state: IItemsStoreState) => {
    state.page.loading = false;
  },

  // fetchItemThunk
  [fetchItemThunk.fulfilled]: (state: IItemsStoreState, action: IActionFulfilled<IFetchItemPayload, IItemDetails>) => {
    state.item = {
      ...state.item,
      id: action.payload.questionId,
      questionId: action.payload.questionId,
      createdBy: action.payload.details.createdBy,
      createdOn: action.payload.details.createdOn,
      creatorArea: action.payload.details.creatorArea,
      questionOptionsTypeId: action.payload.details.questionOptionsTypeId
        ? action.payload.details.questionOptionsTypeId
        : action.payload.questionTypeId,
      questionOptionsType: action.payload.details.questionOptionsType
        ? action.payload.details.questionOptionsType
        : action.payload.questionType,
      questionTextHtml: action.payload.questionTextHtml,
      text: action.payload.text,
      canEditQuestionText: action.payload.canEditQuestionText,
      elements: action.payload.elements,
    };

    state.page.loading = false;
    state.page.loadingPageSection = false;
  },
  [fetchItemThunk.pending]: (state: IItemsStoreState, action: IActionFulfilled<IFetchItemPayload, IItemDetails>) => {
    const {
      meta: {
        arg: {fullPageLoad},
      },
    } = action;

    if (fullPageLoad) {
      state.page.loading = true;
    } else {
      state.page.loadingPageSection = true;
    }
  },
  [fetchItemThunk.rejected]: (state: IItemsStoreState) => {
    state.page.loading = false;
    state.page.loadingPageSection = false;
  },

  // fetchQuestionsThunk
  [fetchQuestionsThunk.fulfilled]: (
    state: IItemsStoreState,
    action: IActionFulfilled<boolean, TGetQuestionsApiResponse>
  ) => {
    if (!isObject(action.payload)) {
      return;
    }

    state.itemQuestions = normalize(action.payload, itemQuestionsSchema);
  },
  [fetchQuestionsThunk.rejected]: (state: IItemsStoreState) => {
    state.page.loading = false;
  },

  // fetchPossibleDefaultAnswerStacksThunk
  [fetchPossibleDefaultAnswerStacksThunk.fulfilled]: (
    state: IItemsStoreState,
    action: IActionFulfilled<boolean, any>
  ) => {
    state.page.loading = false;
    state.item.answerStacks = [...action.payload];
    state.item.defaultAnswerStacks = [...action.payload];
  },
  [fetchPossibleDefaultAnswerStacksThunk.rejected]: (state: IItemsStoreState) => {
    state.page.loading = true;
  },
  [fetchPossibleDefaultAnswerStacksThunk.rejected]: (state: IItemsStoreState) => {
    state.page.loading = false;
  },

  // createQuestionThunk
  [createQuestionThunk.fulfilled]: (state: IItemsStoreState, action: IActionFulfilled<boolean, IQuestionBase>) => {
    if (isObject(action.payload)) {
      const questionsValues = [...values(state.itemQuestions.entities.questions), action.payload];
      state.itemQuestions = normalize(questionsValues, itemQuestionsSchema);
    }
  },
  [createQuestionThunk.rejected]: (state: IItemsStoreState) => {
    state.page.loading = false;
  },

  // fetchSortByColumnsThunk
  [fetchSortByColumnsThunk.fulfilled]: (state: IItemsStoreState, action: IActionFulfilled<null, IBindBaseEntity[]>) => {
    if (action.payload) {
      state.sortByColumns = action.payload;
    }
  },

  [fetchSortOrderTypesThunk.fulfilled]: (
    state: IItemsStoreState,
    action: IActionFulfilled<null, IBindBaseEntity[]>
  ) => {
    if (action.payload) {
      state.sortOrderTypes = action.payload;
    }
  },

  // publishItemAnswerStackThunk
  [publishItemAnswerStackThunk.fulfilled]: (state: IItemsStoreState) => {
    state.page.publishingStack = false;
  },
  [publishItemAnswerStackThunk.pending]: (state: IItemsStoreState) => {
    state.page.publishingStack = true;
  },
  [publishItemAnswerStackThunk.rejected]: (state: IItemsStoreState) => {
    state.page.publishingStack = false;
  },

  // unPublishItemAnswerStackThunk
  [unPublishItemAnswerStackThunk.fulfilled]: (state: IItemsStoreState) => {
    state.page.publishingStack = false;
  },
  [unPublishItemAnswerStackThunk.pending]: (state: IItemsStoreState) => {
    state.page.publishingStack = true;
  },
  [unPublishItemAnswerStackThunk.rejected]: (state: IItemsStoreState) => {
    state.page.publishingStack = false;
  },

  // promoteItemAnswerStackThunk
  [promoteItemAnswerStackThunk.fulfilled]: (state: IItemsStoreState) => {
    state.page.promotingStack = false;
  },
  [promoteItemAnswerStackThunk.pending]: (state: IItemsStoreState) => {
    state.page.promotingStack = true;
  },
  [promoteItemAnswerStackThunk.rejected]: (state: IItemsStoreState) => {
    state.page.promotingStack = false;
  },

  // publishAllAnswerStacksForQuestionThunk
  [publishAllAnswerStacksForQuestionThunk.fulfilled]: (state: IItemsStoreState) => {
    state.item = {
      ...state.item,
      isApproved: true,
      answerStacks: [
        ...state.item.answerStacks.map((stack) => {
          if (stack.questionId > -1) {
            return {...stack, isApproved: true};
          }
          return stack;
        }),
      ],
    };
    state.page.publishingStack = false;
  },
  [publishAllAnswerStacksForQuestionThunk.pending]: (state: IItemsStoreState) => {
    state.page.publishingStack = true;
  },
  [publishAllAnswerStacksForQuestionThunk.pending]: (state: IItemsStoreState) => {
    state.page.publishingStack = false;
  },

  // createItemThunk
  [createItemThunk.fulfilled]: (state: IItemsStoreState) => {
    state.page.saving = false;
  },
  [createItemThunk.pending]: (state: IItemsStoreState) => {
    state.page.saving = true;
  },
  [createItemThunk.rejected]: (state: IItemsStoreState) => {
    state.page.saving = false;
  },

  // updateItemThunk
  [updateItemThunk.fulfilled]: (
    state: IItemsStoreState,
    action: IActionFulfilled<IUpdateItemPayload, IItemDetails>
  ) => {
    state.item = {...state.item, ...action.payload};
    state.page.saving = false;
  },
  [updateItemThunk.pending]: (state: IItemsStoreState) => {
    state.page.saving = true;
  },

  [updateItemThunk.rejected]: (state: IItemsStoreState) => {
    state.page.saving = false;
  },

  // updateAnswerStackThunk
  [updateAnswerStackThunk.fulfilled]: (state: IItemsStoreState) => {
    state.page.saving = false;
  },
  [updateAnswerStackThunk.pending]: (state: IItemsStoreState) => {
    state.page.saving = true;
  },
  [updateAnswerStackThunk.rejected]: (state: IItemsStoreState) => {
    state.page.saving = false;
  },

  // createUpdateItemThunk
  [createUpdateItemThunk.fulfilled]: (
    state: IItemsStoreState,
    action: IActionFulfilled<IUpdateItemPayload, IItemDetails>
  ) => {
    state.item = {...action.payload};
    state.item.answerStacks = [...updateAnswerStackQuestionId(action.payload.answerStacks)];
    state.page.saving = false;
  },
  [createUpdateItemThunk.pending]: (state: IItemsStoreState) => {
    state.page.saving = true;
  },
  [createUpdateItemThunk.rejected]: (state: IItemsStoreState) => {
    state.page.saving = false;
  },

  // deleteItemThunk
  [deleteItemThunk.fulfilled]: (state: IItemsStoreState) => {
    state.page.deleting = false;
  },
  [deleteItemThunk.pending]: (state: IItemsStoreState) => {
    state.page.deleting = true;
  },
  [deleteItemThunk.rejected]: (state: IItemsStoreState) => {
    state.page.deleting = false;
  },

  // addDefaultAnswerStackThunk
  [addDefaultAnswerStackThunk.fulfilled]: (state: IItemsStoreState, action: IActionFulfilled<number, IItemDetails>) => {
    state.item = {
      ...state.item,
      id: action.payload.questionId,
      ...action.payload,
    };
    state.page.saving = false;
  },
  [addDefaultAnswerStackThunk.pending]: (state: IItemsStoreState) => {
    state.page.saving = true;
  },
  [addDefaultAnswerStackThunk.rejected]: (state: IItemsStoreState) => {
    state.page.saving = false;
  },

  // addCustomAnswerStackThunk
  [addCustomAnswerStackThunk.fulfilled]: (state: IItemsStoreState, action: IActionFulfilled<number, IItemDetails>) => {
    state.item = {
      ...state.item,
      id: action.payload.questionId,
      ...action.payload,
    };
    state.page.saving = false;
  },
  [addCustomAnswerStackThunk.pending]: (state: IItemsStoreState) => {
    state.page.saving = true;
  },
  [addCustomAnswerStackThunk.rejected]: (state: IItemsStoreState) => {
    state.page.saving = false;
  },
};

////
function updateAnswerStackQuestionId(answerStacks: IAnswerStack[]) {
  return answerStacks.map((answerStack, idx) => ({
    ...answerStack,
    questionIdOriginal: answerStack.questionId,
    questionId: idx + 1,
    questionAnswerStackIdOriginal: answerStack.questionAnswerStackId,
    questionAnswerStackId: idx + 1,
    choices: answerStack.choices.map((choice) => ({
      ...choice,
      answerQuestionAnswerStackIdOriginal: choice.answerQuestionAnswerStackId,
      answerQuestionAnswerStackId: idx + 1,
    })),
  }));
}

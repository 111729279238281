import React, {Fragment, ReactNode} from 'react';
import {CircularProgress, InputAdornment, makeStyles} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {IComponentWithClassName} from 'models';

interface IInputProps extends IComponentWithClassName {
  loading?: boolean;
  withIcon?: boolean;
  iconPosition?: 'start' | 'end';
  icon?: ReactNode;
  value?: string | null;
  name?: string | null;
  changeHandler?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string | null;
  placeholder?: string;
  withMargin?: boolean;
  disabled?: boolean;
  onKeyUp?: any;
  color?: string;
  type?: string;
  autoFocus?: boolean;
  fullWidth?: boolean;
}

export function Input({
  value,
  name,
  changeHandler,
  withIcon,
  iconPosition,
  icon,
  error = false,
  helperText,
  placeholder,
  withMargin = true,
  disabled = false,
  onKeyUp,
  type,
  color,
  loading = false,
  autoFocus,
  fullWidth = true,
  ...rest
}: IInputProps) {
  const {root} = useStyles({withMargin, color});

  let props: any = {
    variant: 'outlined',
    size: 'small',
  };

  if (withIcon || loading) {
    const positionProp = iconPosition === 'start' ? 'startAdornment' : 'endAdornment';

    props = {
      ...props,
      InputProps: {
        [positionProp]: (
          <Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {icon && <InputAdornment position={iconPosition}>{icon}</InputAdornment>}
          </Fragment>
        ),
      },
    };
  }

  return (
    <TextField
      name={name}
      value={value}
      onChange={changeHandler}
      className={root}
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      disabled={disabled}
      onKeyUp={onKeyUp}
      type={type}
      autoFocus={autoFocus}
      {...props}
      {...rest}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: ({withMargin, color}: {withMargin: boolean; color: string}) => {
    const margin = theme.spacing(3);
    return {
      margin: withMargin ? `${margin} 0 ${margin} 0` : 0,
      backgroundColor: color ? color : theme.palette.common.white,
    };
  },
}));

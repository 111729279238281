import React, {FC} from 'react';
import {useLearningPaths} from '../../hooks';
import {IWithDisabled} from 'models';
import {Button, Row} from 'components-lib';
import {LearningPathsDropdown} from './LearningPathsDropdown';

interface ILearningPathsAllActionButtonProps extends IWithDisabled {
  id: string;
  openModalCloneClickHandler: () => void;
}

export const LearningPathsActionButtons: FC<ILearningPathsAllActionButtonProps> = ({
  id,
  disabled = false,
  openModalCloneClickHandler,
}) => {
  const {redirectToEditLearningPath} = useLearningPaths();
  const showDropdown = !disabled;

  return (
    <Row justify="flex-end">
      <Button.Secondary size="small" disabled={disabled} clickHandler={() => redirectToEditLearningPath(id)}>
        View
      </Button.Secondary>
      {showDropdown && <LearningPathsDropdown id={id} openModalCloneClickHandler={openModalCloneClickHandler} />}
    </Row>
  );
};

import {Accordion} from 'components-lib';
import {Form} from 'forms';
import React from 'react';
import {FormNameEnum} from '../../../enums';
import {IWithDisabled} from 'models';

export function DrmExpirationDateFilter({disabled}: IWithDisabled) {
  return (
    <Accordion heading="DRM Expiration Date">
      <Form.DatePicker
        name={FormNameEnum.drmExpirationDate}
        label=""
        value={null}
        disabled={disabled}
        maxWidth={true}
      />
    </Accordion>
  );
}

import React, {Dispatch, FC, Fragment, SetStateAction, useEffect} from 'react';
import {useFormikContext} from 'formik';
import {DetailsFields, UserDetails} from '..';
import {ILearningPathViewEditFormBaseValues} from '../EditLearningPathDetailsForm/config';
import {LearningPathDetails} from 'admin/resources/learning-paths/components';
import {Divider} from 'components-lib';
import {useLearningPathBuilderItemsEntities} from 'admin/resources/learning-paths/views/session-builder';

interface IEditLearningPathDetailsProps {
  isDirty: boolean;
  setIsDirtyCallback: Dispatch<SetStateAction<boolean>>;
}

export const EditLearningPathDetails: FC<IEditLearningPathDetailsProps> = ({isDirty, setIsDirtyCallback}) => {
  const {dirty, isValid} = useFormikContext<ILearningPathViewEditFormBaseValues>();
  const {isSomeOfSessionsWithEmptyName} = useLearningPathBuilderItemsEntities();

  useEffect(() => {
    if ((dirty && isValid && !isDirty) || isSomeOfSessionsWithEmptyName) {
      setIsDirtyCallback(true);
    } else if (!isValid) {
      setIsDirtyCallback(false);
    }
  }, [isDirty, setIsDirtyCallback, dirty, isValid, isSomeOfSessionsWithEmptyName]);

  return (
    <Fragment>
      <LearningPathDetails withSubtitle={false}>
        <UserDetails />
        <Divider.Horizontal fullWidth />
        <DetailsFields />
      </LearningPathDetails>
    </Fragment>
  );
};

import React, {Fragment, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {useFormikContext} from 'formik';
import {Subtitle} from 'admin/components';
import {Column, Row, Wrapper} from 'components-lib';
import {IntegrationConfirmCheckbox} from 'admin/integrations/components';
import {AddIntegrationInputList} from '..';
import {IAddIntegrationFormBaseValues} from 'models/integrations';
import {heightThreeStepsActivity} from 'utils/layout';
import {FormNameEnum} from 'enums/form';
import {EXAMPLE_EMAIL, getEditorPermissionCheckboxText} from 'admin/integrations/utils';
import {getAddIntegrationGoogleSitesList} from '../../utils/helpers';

export const AddIntegrationGoogleSitesSource = () => {
  const classes = useStyles();
  const {
    values: {email, canGiveEditorPermissions, integrationType},
  } = useFormikContext<IAddIntegrationFormBaseValues>();
  const type = Number(integrationType);
  const inputList = getAddIntegrationGoogleSitesList(type);
  const shouldShowError = canGiveEditorPermissions === false;
  const checkboxText = getEditorPermissionCheckboxText(email ?? EXAMPLE_EMAIL);

  const content = useMemo(() => {
    return (
      <Fragment>
        <Subtitle withColor={false} as="h3" color="textPrimary" title="Step 2: Google Sites Source" />
        <Row classes={{root: classes.row}} align="flex-start" justify="flex-start">
          <Column sm={8} md={8} lg={8} xl={6} className={classes.col}>
            <AddIntegrationInputList list={inputList} withHeightThreeStepsActivity={false} />
            <Wrapper className={classes.checkbox}>
              <IntegrationConfirmCheckbox
                text={checkboxText}
                name={FormNameEnum.canGiveEditorPermissions}
                showError={shouldShowError}
              />
            </Wrapper>
          </Column>
        </Row>
      </Fragment>
    );
  }, [classes.row, classes.checkbox, classes.col, inputList, checkboxText, shouldShowError]);

  return <Wrapper className={classes.content}>{content}</Wrapper>;
};

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(2),
  },
  content: {
    height: heightThreeStepsActivity,
  },
  checkbox: {
    paddingLeft: theme.spacing(2),
  },
  col: {
    paddingRight: theme.spacing(2) + theme.spacing(3),
  },
}));

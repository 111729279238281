import * as Yup from 'yup';
import {FormNameEnum} from 'filters-selections';
export interface IProgramsAllSidebarFiltersForm {
  [FormNameEnum.gradeLevels]: number[];
  [FormNameEnum.programYears]: number[];
  [FormNameEnum.search]: string;
}

export const filtersFormValidationSchema = Yup.object().shape({
  [FormNameEnum.gradeLevels]: Yup.array().of(Yup.number()),
  [FormNameEnum.programYears]: Yup.array().of(Yup.string()),
  [FormNameEnum.search]: Yup.string(),
});

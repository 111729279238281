import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Icon, Row, Text} from 'components-lib';
interface IViewErrorProps {
  text: string;
}

export function ViewError({text}: IViewErrorProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Row justify="center">
        <div className={classes.icon}>
          <Icon.ErrorIcon />
        </div>
        <Text.Heading as="h3" classes={{root: classes.text}}>
          {text}
        </Text.Heading>
      </Row>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
  icon: {
    marginRight: theme.spacing(2),
    paddingTop: 4,
  },
  text: {
    maxWidth: '80%',
  },
}));

import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Container, Row, Text} from 'components-lib';
import {FormNameEnum} from '../../../enums';
import {getAccessibleToStudentsOptions} from 'admin/resources/upload';
import {Form} from 'forms';

interface IAccessibleToStudentsOption {
  label: string;
  value: number | string | boolean;
}

export const AccessibleToStudentsRadioGroup = () => {
  const classes = useStyles();

  const accessibleToStudentsOptions: IAccessibleToStudentsOption[] = useMemo(
    () => getAccessibleToStudentsOptions(),
    []
  );

  return (
    <Container disableGutters>
      <Text.Heading as="h4" classes={{root: classes.label}}>
        Accessible to students?
      </Text.Heading>
      <Container disableGutters classes={{root: classes.radioGroup}}>
        <Form.RadioGroup name={FormNameEnum.isAccessibleToStudents}>
          <Row justify="flex-start">
            {accessibleToStudentsOptions.map((option: IAccessibleToStudentsOption, idx: number) => (
              <Form.Radio key={idx} value={option.value} label={option.label} />
            ))}
          </Row>
        </Form.RadioGroup>
      </Container>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.grey[700],
  },
  radioGroup: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

import {isEmpty} from 'utils';
import {useAppDispatch} from 'hooks';
import {IGoogleSiteCopyPayload} from 'models/resources/google-sites';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {
  googleSiteDetailsHistoryItemsCollectionSelector,
  googleSiteDetailsHistoryItemsEntitiesSelector,
  googleSiteDetailsHistoryItemsResultSelector,
  googleSiteDetailsHistoryListLoadingSelector,
  fetchGoogleSitesSourceHistoryThunk,
  fetchGoogleSitesCopySiteThunk,
  googleSiteDetailsHistoryListItemCopyingSelector,
  googleSiteDetailsHistoryListItemCopyIntegrationIdSelector,
} from './../../store';
import {useGoogleSiteIdParam} from './useGoogleSiteIdParam';

export const useGoogleSiteHistory = () => {
  const dispatch = useAppDispatch();
  const {googleSiteId} = useGoogleSiteIdParam();
  const loadingHistory = useSelector(googleSiteDetailsHistoryListLoadingSelector);
  const copyingGoogleSite = useSelector(googleSiteDetailsHistoryListItemCopyingSelector);
  const copyingHistoryItemIntegrationId = useSelector(googleSiteDetailsHistoryListItemCopyIntegrationIdSelector);

  const googleSiteHistoryItemsEntities = useSelector(googleSiteDetailsHistoryItemsEntitiesSelector);
  const googleSiteHistoryItemsResult = useSelector(googleSiteDetailsHistoryItemsResultSelector);
  const googleSiteHistoryItemsEntitiesCollection = useSelector(googleSiteDetailsHistoryItemsCollectionSelector);

  const fetchGoogleSitesSourceHistory = useCallback(
    (googleSiteId) => dispatch(fetchGoogleSitesSourceHistoryThunk(googleSiteId)),
    [dispatch]
  );

  const fetchGoogleSitesCopySite = useCallback(
    (payload: IGoogleSiteCopyPayload) =>
      dispatch(fetchGoogleSitesCopySiteThunk(payload)).then(() => {
        if (!isEmpty(googleSiteId)) {
          fetchGoogleSitesSourceHistory(googleSiteId);
        }
      }),
    [dispatch, fetchGoogleSitesSourceHistory, googleSiteId]
  );

  return {
    loadingHistory,
    copyingGoogleSite,
    copyingHistoryItemIntegrationId,
    googleSiteHistoryItemsEntities,
    googleSiteHistoryItemsResult,
    googleSiteHistoryItemsEntitiesCollection,

    fetchGoogleSitesSourceHistory,
    fetchGoogleSitesCopySite,
  };
};

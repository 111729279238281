import {useCallback} from 'react';
import {useNavigate} from './useNavigate';
import {pages} from '../paths';

export const useMainNavigation = () => {
  const {push} = useNavigate();

  const redirectToResources = useCallback(() => push(pages.adminPortal.resources), [push]);
  const redirectToPrograms = useCallback(() => push(pages.adminPortal.programs.root), [push]);
  const redirectToLibrary = useCallback(() => push(pages.adminPortal.library), [push]);

  return {redirectToResources, redirectToPrograms, redirectToLibrary};
};

import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Text} from 'components-lib';
import {IComponentWithChildren} from 'models';
interface ISubtitlePageViewHeaderProps extends IComponentWithChildren {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  color?: 'primary' | 'secondary' | 'textPrimary' | 'error';
  withMargin?: boolean;
  withColor?: boolean;
}

export function SubtitlePageViewHeader({
  children,
  color,
  withColor = true,
  withMargin = true,
  as = 'h4',
}: ISubtitlePageViewHeaderProps) {
  const classes = useStyles({withColor, withMargin});
  return (
    <Text.Heading as={as} color={color} classes={{root: classes.subheading}}>
      {children}
    </Text.Heading>
  );
}

const useStyles = makeStyles((theme) => ({
  subheading: ({withColor, withMargin}: Pick<ISubtitlePageViewHeaderProps, 'withColor' | 'withMargin'>) => {
    let styles: any = {
      color: theme.palette.grey[700],

      '@media screen and (max-width: 1200px)': {
        maxWidth: 400,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },

      '@media screen and (max-width: 1000px)': {
        maxWidth: 300,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    };

    if (withMargin) {
      styles = {...styles, marginLeft: theme.spacing(2), marginRight: theme.spacing(2)};
    }

    if (!withColor) {
      styles = {...styles, color: theme.palette.grey[900]};
    }

    return styles;
  },
}));

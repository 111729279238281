import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {ISaveGeneralSurveyApiResponse, ISaveGeneralSurveyPayload} from 'models/library/instruments';
import {prefix} from './config';

export const saveGeneralSurveyThunk: any = createAsyncThunk<ISaveGeneralSurveyApiResponse, ISaveGeneralSurveyPayload>(
  `${prefix} saveGeneralSurvey`,
  async (payload) => {
    try {
      const {parsedBody} = await instrumentsApi.saveGeneralSurvey(payload);
      return parsedBody;
    } catch (err) {
      throw new Error(err.parsedBody);
    }
  }
);

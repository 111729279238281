import {FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import {IComponentWithChildren} from 'models';
import React from 'react';
import {cloneInstrumentValidationSchema} from './cloneInstrumentValidationSchema';
import {usePermissions} from 'permissions';
import {checkIfAreaAdmin} from '../../details/utils';
import {ICreateInstrumentFormData} from '../CreateInstrumentForm';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICloneInstrumentFormData extends ICreateInstrumentFormData {}

interface ICloneInstrumentFormProps extends IComponentWithChildren {
  submitHandler: (data: ICloneInstrumentFormData, actions: any) => void;
}

export function CloneInstrumentForm({children, submitHandler}: ICloneInstrumentFormProps) {
  const {hasPermission} = usePermissions();
  const isAreaAdmin = checkIfAreaAdmin(hasPermission);

  const initialValues: ICloneInstrumentFormData = {
    [FormNameEnum.instrumentName]: '',
    [FormNameEnum.phase]: null,
    [FormNameEnum.instrumentType]: null,
    [FormNameEnum.instrumentSubType]: null,
    [FormNameEnum.audience]: null,
    [FormNameEnum.pillars]: null,
    [FormNameEnum.programs]: [],
    [FormNameEnum.isGeneralInstrument]: isAreaAdmin ? true : false,
  };

  return (
    <Form.Form<ICloneInstrumentFormData>
      initialValues={initialValues}
      validationSchema={cloneInstrumentValidationSchema}
      submitHandler={submitHandler}
      validateOnChange
    >
      {children}
    </Form.Form>
  );
}

import {EntitiesPerPageEnum, SortingOrderEnum} from 'enums';
import {IProgramsAllFilter} from 'models';

import {IProgramsAllState} from './IProgramsAllState';
import {FilterNamesEnum, ProgramsAllOrderingEnum} from '../enums';

const filterDefault: IProgramsAllFilter = {
  [FilterNamesEnum.SEARCH_BY_NAME]: '',
  [FilterNamesEnum.PROGRAMS]: [],
  [FilterNamesEnum.GRADE_LEVELS]: [],
  [FilterNamesEnum.YEARS]: [],
  [FilterNamesEnum.ORDER_BY]: 'ProgramName',
  [FilterNamesEnum.ORDER_ASC]: true,
};

export const programsAllInitialState: IProgramsAllState = {
  programs: {
    result: [],
    entities: {byId: {}},
  },
  filter: filterDefault,
  page: {
    loading: false,
  },
  sortOrder: {
    order: ProgramsAllOrderingEnum.ProgramYear,
    sort: SortingOrderEnum.DESC,
  },
  pagination: {
    currentPage: 1,
    totalCount: 0,
    perPage: EntitiesPerPageEnum.FIFTY,
  },
};

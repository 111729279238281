export class TimeSpentTracker {
  #startDate: Date;

  public start() {
    this.#startDate = new Date();
  }

  // returns ms
  public end(): number {
    const delta = new Date().getTime() - this.#startDate.getTime();
    this.#startDate = null;
    return delta;
  }
}

import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Text} from 'components-lib';
import {ActiveModeEnum} from 'admin/library/items/enums';
import {IComponentWithChildren} from 'models';
import {SectionContainer} from '..';

interface IItemQuestionProps extends IComponentWithChildren {
  mode: ActiveModeEnum;
}

export function ItemQuestion({children, mode}: IItemQuestionProps) {
  const classes = useStyles();
  const isCreateMode = mode === ActiveModeEnum.create;

  return (
    <SectionContainer>
      <div className={classes.info}>{isCreateMode && <Text.Heading as="h3">Item stem</Text.Heading>}</div>
      {children}
    </SectionContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  info: {
    marginBottom: theme.spacing(2),
  },
  addButton: {
    marginTop: -8,
  },
}));

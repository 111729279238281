import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {unwrapResult} from '@reduxjs/toolkit';

import {mapUserActivityExport} from '../utils/mapUserActivityExport';
import {IGetUserActivityExportResponse} from './../../models/permissions/IGetUserActivityExportResponse';
import {fetchUserActivityExportThunk} from './../store/thunks/fetchUserActivityExport.thunk';
import {createLinkForDownload} from 'utils';
import {useAppDispatch} from 'hooks';
import {exportingSelector, RolesEnum} from 'permissions';

export const useUserActivity = () => {
  const dispatch = useAppDispatch();
  const exporting = useSelector(exportingSelector);

  const getUserActivityExport = useMemo(
    () => (from: Date, to: Date, roleList: RolesEnum[]) =>
      dispatch(fetchUserActivityExportThunk(mapUserActivityExport(from, to, roleList)))
        .then(unwrapResult)
        .then((parsedBody: IGetUserActivityExportResponse) => {
          const {mimeType, data, fileName} = parsedBody;
          createLinkForDownload(`data:${mimeType};base64,${data}`, fileName);
        }),
    [dispatch]
  );

  return {
    exporting,
    getUserActivityExport,
  };
};

import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';
import {values} from 'utils';
import {IToastNotification} from '../models';
import {INotifications} from './IToastNotificationsStoreState';

export interface INotificationsSelectorValue {
  notifications: IToastNotification[];
  ids: string[];
}

const getToastNotifications = (state: TStateStore) => state.toastNotifications.notifications;
export const notificationsSelector = createSelector(getToastNotifications, (state: INotifications) => {
  const {entities, result} = state;
  const {notifications} = entities;

  return {
    notifications: notifications ? values(notifications) : [],
    ids: result,
  };
});

import React, {useCallback, useMemo} from 'react';
import {Form} from 'forms';
import {Filters} from 'components-lib';
import {FilesAllFilterNamesEnum} from '../../enums';
import {useFilesAll, useFilesAllStoreActions} from '../../hooks';
import {filesAllSidebarValidationSchema, IFilesAllFiltersFormValues} from './config';
import {IFilesAllFilter} from 'models/resources/files';
import {filesAllFilterList, mapFilesAllFilters} from '../../utils';
import {FiltersEnum, FiltersList} from 'filters-selections';
import {AreaAdminRole, USAAndSuperAdminRoles, usePermissions} from 'permissions';

export const FilesViewAllSidebar = () => {
  const {filter, loading} = useFilesAll();
  const {setFilterFilesAll, resetFilterFilesAll} = useFilesAllStoreActions();
  const {hasPermission} = usePermissions();
  const hasAreaAdminPermission = hasPermission(AreaAdminRole);
  const hasUSAAndSuperAdminPermission = hasPermission(USAAndSuperAdminRoles);

  const shouldRemovePublishedByFilter = !hasAreaAdminPermission || hasUSAAndSuperAdminPermission;
  const shouldRemoveFileStatusesFilter = !hasUSAAndSuperAdminPermission;

  const submitHandler = useCallback(
    (formValues: IFilesAllFiltersFormValues) => {
      const filter: IFilesAllFilter = mapFilesAllFilters(formValues);
      setFilterFilesAll(filter);
    },
    [setFilterFilesAll]
  );

  const resetHandler = useCallback(() => resetFilterFilesAll(), [resetFilterFilesAll]);

  const initialValues = useMemo(
    () => ({
      [FilesAllFilterNamesEnum.SEARCH_BY_QUERY]: filter[FilesAllFilterNamesEnum.SEARCH_BY_QUERY],
      [FilesAllFilterNamesEnum.FILE_TYPES]: filter[FilesAllFilterNamesEnum.FILE_TYPES],
      [FilesAllFilterNamesEnum.PROGRAMS]: filter[FilesAllFilterNamesEnum.PROGRAMS],
      [FilesAllFilterNamesEnum.GRADE_LEVELS]: filter[FilesAllFilterNamesEnum.GRADE_LEVELS],
      [FilesAllFilterNamesEnum.PILLARS]: filter[FilesAllFilterNamesEnum.PILLARS],
      [FilesAllFilterNamesEnum.COMPETENCIES]: filter[FilesAllFilterNamesEnum.COMPETENCIES],
      [FilesAllFilterNamesEnum.KSAS]: filter[FilesAllFilterNamesEnum.KSAS],
      [FilesAllFilterNamesEnum.IS_ACCESSIBLE_TO_STUDENTS]: filter[FilesAllFilterNamesEnum.IS_ACCESSIBLE_TO_STUDENTS],
      [FilesAllFilterNamesEnum.PUBLISHED_BY]: filter[FilesAllFilterNamesEnum.PUBLISHED_BY],
      [FilesAllFilterNamesEnum.LANGUAGES]: filter[FilesAllFilterNamesEnum.LANGUAGES],
      [FilesAllFilterNamesEnum.FILE_STATUSES]: filter[FilesAllFilterNamesEnum.FILE_STATUSES],
    }),
    [filter]
  );

  const renderFiltersList = useMemo(() => {
    if (shouldRemovePublishedByFilter && filesAllFilterList.includes(FiltersEnum.PublishedByRadio)) {
      filesAllFilterList.pop();
    }

    if (shouldRemoveFileStatusesFilter && filesAllFilterList.includes(FiltersEnum.FileStatusesCheckbox)) {
      filesAllFilterList.pop();
    }

    return <FiltersList disableAll={loading} filters={filesAllFilterList} />;
  }, [loading, shouldRemovePublishedByFilter, shouldRemoveFileStatusesFilter]);

  return (
    <Form.Form<IFilesAllFiltersFormValues>
      initialValues={initialValues}
      validationSchema={filesAllSidebarValidationSchema}
      submitHandler={submitHandler}
    >
      <Filters.FiltersPanel
        disableAll={loading}
        clearButtonClickHandler={resetHandler}
        searchFieldName={FilesAllFilterNamesEnum.SEARCH_BY_QUERY}
        filters={renderFiltersList}
      />
    </Form.Form>
  );
};

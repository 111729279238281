import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import {colors} from './colors';

export const muiTheme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: colors.primary,
        dark: colors.primary100,
        light: colors.primary20,
        contrastText: colors.white,
      },
      secondary: {
        main: colors.secondary,
        dark: colors.secondary100,
        light: colors.secondary20,
        contrastText: colors.white,
      },
      error: {
        main: colors.error,
      },
      warning: {
        main: colors.warning,
      },
      info: {
        main: colors.info,
      },
      success: {
        main: colors.success,
      },
      grey: {
        50: colors.gray00,
        100: colors.gray05,
        200: colors.gray10,
        300: colors.gray20,
        500: colors.gray40,
        700: colors.gray60,
        800: colors.gray80,
        900: colors.gray100,
      },
      text: {
        primary: colors.gray100,
        secondary: colors.gray60,
        disabled: colors.gray40,
        hint: colors.gray60,
      },
      divider: colors.gray10,
    },
    typography: {
      fontFamily: 'Open Sans, sans-serif',
      h1: {
        fontSize: '1.5rem',
        lineHeight: 1.25,
        fontWeight: 600,
        color: colors.gray80,
        marginTop: 16,
        marginBottom: 16,
      },
      h2: {
        fontSize: '1.125rem',
        lineHeight: 1.25,
        fontWeight: 600,
        color: colors.gray80,
        marginTop: 16,
        marginBottom: 16,
      },
      h3: {
        fontSize: '1rem',
        lineHeight: 1.25,
        fontWeight: 600,
        color: colors.gray80,
        marginTop: 16,
        marginBottom: 16,
      },
      h4: {
        fontSize: '0.8125rem',
        lineHeight: 1.25,
        fontWeight: 600,
        color: colors.gray80,
        marginTop: 16,
        marginBottom: 16,
      },
      h5: {
        fontSize: '0.7rem',
        lineHeight: 1.25,
        fontWeight: 600,
        color: colors.gray80,
        marginTop: 16,
        marginBottom: 16,
      },
      h6: {
        fontSize: '0.6rem',
        lineHeight: 1.25,
        fontWeight: 600,
        color: colors.gray80,
        marginTop: 8,
        marginBottom: 8,
      },
      body1: {
        fontSize: '0.875rem',
        lineHeight: 1.5,
        color: colors.gray80,
      },
      body2: {
        fontSize: '0.875rem',
        lineHeight: 1.5,
        color: colors.gray60,
      },
      caption: {
        fontSize: '0.75rem',
        lineHeight: 1.25,
        color: colors.gray60,
      },
    },
    spacing: [0, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048],
    overrides: {
      MuiOutlinedInput: {
        root: {
          '&&[class*="MuiOutlinedInput-root"]': {
            padding: 1.8,
            backgroundColor: 'white',
          },
          '& .MuiOutlinedInput-input ': {
            fontSize: '0.8125rem',
            fontWeight: 600,
            padding: '11px 9px',
            color: colors.gray100,
          },
          '& .Mui-disabled': {
            fontSize: '0.8125rem',
            color: colors.gray60,
          },
          position: 'relative',
          '& $notchedOutline': {
            borderColor: colors.gray20, // gray10
          },
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: colors.gray80, // primary80
            '@media (hover: none)': {
              borderColor: colors.gray80,
            },
          },
          '&$focused $notchedOutline': {
            borderColor: colors.secondary, // secondary80
            borderWidth: 1,
          },
        },
      },
      MuiChip: {
        root: {
          backgroundColor: colors.white,
          border: `1px solid ${colors.gray10}`,
          borderRadius: 6,
          height: 24,
          position: 'relative',
          zIndex: 0,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&:hover': {
            '& .MuiChip-deleteIcon': {
              color: colors.white,
            },
          },
        },
        label: {
          color: colors.gray100,
          backgroundColor: colors.white,
          fontWeight: 'bold',
        },
        deleteIcon: {
          color: colors.white,
        },
      },
      MuiInputBase: {
        root: {
          color: colors.gray100,
        },
      },
    },
  })
);

import React, {FC} from 'react';
import {FormNameEnum} from 'filters-selections';
import {ProgramSelect} from './ProgramSelect';
import {IWithDisabled} from 'models/common';

type IProgramSingleSelectProps = IWithDisabled;

export const ProgramSingleSelect: FC<IProgramSingleSelectProps> = ({disabled}) => {
  return <ProgramSelect selectionType="single" label="Program" disabled={disabled} name={FormNameEnum.program} />;
};

import * as Yup from 'yup';
import {checkIfTypeStartsWithPreOrPostSessionFirstDigit, FormNameEnum} from 'filters-selections';
import {audienceStudentId, errorMessages, typeFeedbackId} from 'utils';

export const cloneInstrumentValidationSchema = Yup.object().shape({
  [FormNameEnum.instrumentName]: Yup.string().trim().required(errorMessages.instrumentNameRequired),
  [FormNameEnum.pillars]: Yup.number()
    .nullable()
    .when(FormNameEnum.isGeneralInstrument, {
      is: true,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().nullable().required(errorMessages.pillarRequired),
    }),
  [FormNameEnum.instrumentType]: Yup.number()
    .nullable()
    .when(FormNameEnum.isGeneralInstrument, {
      is: true,
      then: Yup.number(),
      otherwise: Yup.number().nullable().required(errorMessages.instrumentTypeRequired),
    })
    .test('instrument-type-test', errorMessages.onlyGeneralAllowed, function (type) {
      const audience = this.parent[FormNameEnum.audience];
      const isGeneral = this.parent[FormNameEnum.isGeneralInstrument];

      if (!audience || isGeneral) return true;

      return !(audience !== audienceStudentId && type !== typeFeedbackId);
    }),
  [FormNameEnum.instrumentSubType]: Yup.number()
    .nullable()
    .when(FormNameEnum.isGeneralInstrument, {
      is: true,
      then: Yup.number(),
      otherwise: Yup.number().nullable(),
    })
    .test('instrument-sub-type-test', errorMessages.subTypeRequired, function () {
      const type = this.parent[FormNameEnum.instrumentType];
      const subType = this.parent[FormNameEnum.instrumentSubType];
      const isGeneral = this.parent[FormNameEnum.isGeneralInstrument];

      if (!isGeneral) {
        const isPreOrPostSession = !!type && checkIfTypeStartsWithPreOrPostSessionFirstDigit(type?.toString());

        if (isPreOrPostSession) {
          return !!subType;
        }
      }
      return true;
    }),
  [FormNameEnum.audience]: Yup.number()
    .nullable()
    .when(FormNameEnum.isGeneralInstrument, {
      is: true,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().nullable().required(errorMessages.audienceRequired),
    }),
  [FormNameEnum.phase]: Yup.number()
    .nullable()
    .when(FormNameEnum.isGeneralInstrument, {
      is: true,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().nullable().required(errorMessages.phaseRequired),
    }),
  [FormNameEnum.programs]: Yup.array()
    .of(Yup.number())
    .when(FormNameEnum.isGeneralInstrument, {
      is: true,
      then: Yup.array().of(Yup.number()).notRequired(),
      otherwise: Yup.array().of(Yup.number()).required(errorMessages.programRequired),
    }),
  [FormNameEnum.isGeneralInstrument]: Yup.boolean().notRequired(),
});

import * as Yup from 'yup';
import {FormNameEnum} from 'filters-selections/enums';
import {RolesEnum} from 'permissions';
import {errorMessages} from 'utils';

export interface IModalUserActivityFormValues {
  [FormNameEnum.from]: Date;
  [FormNameEnum.to]: Date;
  [FormNameEnum.role]: RolesEnum[];
}

export const modalUserActivityFormValidationSchema = Yup.object().shape({
  [FormNameEnum.from]: Yup.date().required(errorMessages.requiredWithFieldName('a Date')).nullable().typeError(''),
  /**
   * Type Error option
   * See: https://github.com/jquense/yup/issues/764
   */
  [FormNameEnum.to]: Yup.date().required(errorMessages.requiredWithFieldName('a Date')).nullable().typeError(''),
  [FormNameEnum.role]: Yup.array().of(Yup.number()).required(errorMessages.requiredWithFieldName('a Role')),
  [FormNameEnum.customValidation]: Yup.boolean()
    .test('is-end-date-earlier-than-start-date', 'The start date must be before the end date!', function () {
      /**
       * Dependency: To date cannot be earlier than From date
       */

      const {from, to} = this.parent;

      function isBefore(startDate: Date, endDate: Date): boolean {
        if (startDate > endDate) {
          return false;
        }
        return true;
      }

      if (from instanceof Date && to instanceof Date) {
        return isBefore(from, to);
      }
    })
    .test('has-max-31-days-between-two-dates', 'Please select at most one month period at the time!', function () {
      /**
       * Constraint for From and To fields is to have a maximum of 31 days (one month) between the two dates
       */

      const {from, to} = this.parent;

      function largeThanOneMonth(startDate: Date, endDate: Date): boolean {
        /**
         * For reference, please see: https://mentormate.atlassian.net/browse/JADF-618
         */

        const ONE_MONTH = 1;

        const copiedDate = new Date(startDate.getTime());
        const oneMonthAfter = new Date(copiedDate.setMonth(copiedDate.getMonth() + ONE_MONTH));
        const isSameMonth = startDate.getMonth() === endDate.getMonth();
        const isOneMonthDifference = oneMonthAfter.getTime() === endDate.getTime();

        if (isOneMonthDifference || isSameMonth) {
          return true;
        }

        return false;
      }

      if (from instanceof Date && to instanceof Date) {
        return largeThanOneMonth(from, to);
      }

      return false;
    }),
});

import {FormNameEnum} from 'filters-selections';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {fetchGradeLevelsThunk, filtersGradeLevelsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {useAppDispatch} from 'hooks';
import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

export function GradeLevelsSelect() {
  const dispatch = useAppDispatch();
  const levels = useSelector(filtersGradeLevelsEntitiesCollectionSelector);

  useEffect(() => {
    if (!levels.length) {
      dispatch(fetchGradeLevelsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const options = useMemo(() => levels.map((level) => ({value: level.id, label: level.name})), [levels]);

  return (
    <ItemWithPlaceholder label="Level" hasItems={!!levels.length}>
      <Form.SelectAutocomplete name={FormNameEnum.levels} options={options} multiple />
    </ItemWithPlaceholder>
  );
}

import {useCallback} from 'react';
import {ISurvey} from 'survey-react';

import {IAnswer} from 'models';
import {QuestionAnswersTypeEnum} from 'enums';
import {ISurveyItem} from 'admin/library/models';
import {QuestionColumnCellEnum} from 'admin/library/enums';

export function useSurvey() {
  const getCorrectAnswers = useCallback(
    (answers: IAnswer[]) => answers.filter((answer: IAnswer) => answer.isCorrect === true),
    []
  );

  const getAnswersField = useCallback(
    (isMatrixType: boolean, {columns, choices}: any) => (isMatrixType ? columns : choices),
    []
  );

  const setMatrixDefaultValue = (answers: string[], rows: string[]) => {
    return rows.reduce((map, obj) => {
      map[obj] = {
        col: answers,
      };

      return map;
    }, {});
  };

  const getSurveyPagesLength = (survey: ISurvey) => survey.pages.length;

  const checkIfSurveyHasSinglePage = (surveyPagesLen: number) => surveyPagesLen > 0 && surveyPagesLen < 2;

  const updateDropdownMatrixQuestion = useCallback(
    (matrix: ISurveyItem, correctAnswers: string[], rowValues: string[]) => {
      const columnName = 'col';

      matrix.type = QuestionAnswersTypeEnum.matrixdropdown;
      matrix.defaultValue = setMatrixDefaultValue(correctAnswers, rowValues);
      matrix.columns = [
        {
          name: columnName,
          cellType: QuestionColumnCellEnum.checkbox,
          showInMultipleColumns: true,
          choices: matrix.columns,
        },
      ];
    },
    []
  );

  const calculatePageColCount = (answersLen: number) => (answersLen >= 4 ? 4 : 1);

  const updateQuestionItem = useCallback(
    (elements: any) =>
      elements.map((pageElement: any) => {
        const isRadioGroup = pageElement.type.startsWith(QuestionAnswersTypeEnum.radioGroup) as boolean;
        const isDropdown = pageElement.type.startsWith(QuestionAnswersTypeEnum.dropdown) as boolean;
        const isMatrix = pageElement.type.startsWith(QuestionAnswersTypeEnum.matrix) as boolean;
        const answersField = getAnswersField(isMatrix, pageElement) as IAnswer[];
        const answers = answersField && getCorrectAnswers(answersField);
        const correctAnswers = answers && (answers.map((answer: IAnswer) => answer.value) as string[]);
        const rowValues = pageElement?.rows?.map((row: IAnswer) => row.value) as string[];

        if (!answers) return pageElement;

        if (!isMatrix) {
          if (isRadioGroup || isDropdown) {
            pageElement.type = QuestionAnswersTypeEnum.checkbox;

            if (isDropdown) {
              pageElement.colCount = calculatePageColCount(pageElement.choices.length);
            }
            pageElement.defaultValue = correctAnswers;
          }

          if (!isRadioGroup) {
            pageElement.defaultValue = correctAnswers;
          }
        }

        if (isMatrix) {
          pageElement = updateDropdownMatrixQuestion(pageElement, correctAnswers, rowValues);
        }

        return pageElement;
      }),
    [getCorrectAnswers, getAnswersField, updateDropdownMatrixQuestion]
  );

  const setDefaultAnswersForPreview = useCallback(
    (survey: ISurvey, isSinglePage: boolean) => {
      const singlePage = survey.pages[0];

      if (!isSinglePage) {
        return survey.pages.forEach((page: any) => updateQuestionItem(page.elements));
      }
      return updateQuestionItem(singlePage.elements);
    },

    [updateQuestionItem]
  );

  const getFooterLogo = useCallback((survey, isSinglePage: boolean) => {
    if (isSinglePage) {
      return survey.footerUrl;
    }
  }, []);

  const calculateInputChars = useCallback((currentElement, maxCharsLength: number) => {
    const div = document.createElement('div');
    div.style.textAlign = 'right';
    div.style.marginRight = '8px';
    div.style.marginTop = '8px';
    currentElement.parentNode.append(div);

    const calculateMaxCharsHandler = function () {
      const currLength = currentElement.value.length;
      div.innerText = 'Characters: ' + currLength + ' / ' + maxCharsLength;
    };
    calculateMaxCharsHandler();
    currentElement.onkeyup = calculateMaxCharsHandler;
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const appendMaxCharsToTextField = useCallback((options, _) => {
    if (options.question.getType() !== 'text') return;

    const HTMLCollection = options.htmlElement.getElementsByClassName('input-field');
    /**
     * For each question there will be single item in HTMLCollection
     */
    const element = HTMLCollection[0];
    const maxLength = element?.maxLength;

    if (!!maxLength && maxLength > -1) {
      calculateInputChars(element, maxLength);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    setDefaultAnswersForPreview,
    getSurveyPagesLength,
    checkIfSurveyHasSinglePage,
    getFooterLogo,
    appendMaxCharsToTextField,
  };
}

import {FormNameEnum} from 'filters-selections';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {fetchSessionsThunk, filtersSessionsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {useAppDispatch} from 'hooks';
import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

export function SessionSelect() {
  const dispatch = useAppDispatch();
  const sessions = useSelector(filtersSessionsEntitiesCollectionSelector);

  useEffect(() => {
    if (!sessions.length) {
      dispatch(fetchSessionsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const options = useMemo(() => sessions.map((session) => ({value: session.id, label: session.name})), [sessions]);

  return (
    <ItemWithPlaceholder label="Sessions" hasItems={!!sessions.length}>
      <Form.SelectAutocomplete name={FormNameEnum.sessions} options={options} multiple />
    </ItemWithPlaceholder>
  );
}

import React, {FC, useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import {Form} from 'forms';
import {Wrapper, Button} from 'components-lib';
import {useFormikContext} from 'formik';
import {IAddIntegrationFormBaseValues} from 'models/integrations';
import {useAddIntegration} from '../../hooks';

interface IAddIntegrationContentFooterProps {
  isFirstStep: boolean;
  isSecondStep: boolean;
  isThirdStep: boolean;
  shouldDisableCancelButton: boolean;
  shouldDisableNextButton: boolean;
  nextClickHandler: (formValues: any, resetForm: any) => void;
  openModalCancelAddIntegrationHandler: () => void;
}

export const AddIntegrationContentFooter: FC<IAddIntegrationContentFooterProps> = ({
  isFirstStep,
  isSecondStep,
  isThirdStep,
  shouldDisableCancelButton,
  shouldDisableNextButton,
  nextClickHandler,
  openModalCancelAddIntegrationHandler,
}) => {
  const classes = useStyles();
  const {loading, canShowGoogleSitesErrorBox} = useAddIntegration();
  const formikContext = useFormikContext<IAddIntegrationFormBaseValues>();
  const {
    values: {
      integrationType,
      area,
      integrationName,
      schoolDistrict,
      schoolName,
      email,
      certificate,
      googleDriveSourceFolderLink,
      googleDriveDestinationFolderLink,
      canGiveEditorPermissions,
      clientId,
      clientSecret,
      authorityUrl,
      isGoogleClassroom,
      isGoogleSitesSource,
      isGoogleSitesDestination,
      isMicrosoftTeams,
    },
    resetForm,
    errors,
  } = formikContext;

  const hasFormikFields = !!integrationType && !!integrationName && (!!area || !!schoolDistrict || !!schoolName);
  const hasGoogleClassRoomFields = !!email && !!certificate;
  const hasGoogleClassRoomFieldsErrors = !!errors.email || !!errors.certificate || !!errors.masterClassroomEmailAccount;
  const hasGoogleSitesSourceFields =
    !!email && !!googleDriveSourceFolderLink && canGiveEditorPermissions !== false && !!certificate;
  const hasGoogleSitesSourceFieldsErrors =
    !!errors.email || !!errors.certificate || !!errors.canGiveEditorPermissions || !!errors.googleDriveSourceFolderLink;
  const hasGoogleSitesDestinationFields = !!googleDriveDestinationFolderLink && canGiveEditorPermissions !== false;
  const hasGoogleSitesDestinationFieldsErrors =
    !!errors.canGiveEditorPermissions || !!errors.googleDriveDestinationFolderLink;
  const hasMicrosoftTeamsFields = !!clientId && !!clientSecret && !!authorityUrl;
  const hasMicrosoftTeamsFieldsErrors = !!errors.clientId || !!errors.clientSecret || !!errors.authorityUrl;

  const canDisableNextButton = useCallback(() => {
    let shouldDisable = false;

    if (isGoogleClassroom) {
      shouldDisable = !hasGoogleClassRoomFields || hasGoogleClassRoomFieldsErrors;
    } else if (isMicrosoftTeams) {
      shouldDisable = !hasMicrosoftTeamsFields || hasMicrosoftTeamsFieldsErrors;
    } else if (isGoogleSitesSource) {
      shouldDisable = !hasGoogleSitesSourceFields || hasGoogleSitesSourceFieldsErrors;
    } else if (isGoogleSitesDestination) {
      shouldDisable = !hasGoogleSitesDestinationFields || hasGoogleSitesDestinationFieldsErrors;
    }
    return shouldDisable;
  }, [
    isGoogleClassroom,
    isMicrosoftTeams,
    isGoogleSitesSource,
    isGoogleSitesDestination,
    hasGoogleClassRoomFieldsErrors,
    hasGoogleClassRoomFields,
    hasGoogleSitesSourceFields,
    hasGoogleSitesSourceFieldsErrors,
    hasGoogleSitesDestinationFields,
    hasGoogleSitesDestinationFieldsErrors,
    hasMicrosoftTeamsFields,
    hasMicrosoftTeamsFieldsErrors,
  ]);

  const shouldDisableSecondStepNextButton = canDisableNextButton();

  return (
    <Wrapper className={classes.actionButtons}>
      <Button.Secondary disabled={shouldDisableCancelButton} clickHandler={openModalCancelAddIntegrationHandler}>
        Cancel
      </Button.Secondary>
      {isFirstStep && (
        <Button.Primary
          size="large"
          disabled={shouldDisableNextButton || !hasFormikFields || canShowGoogleSitesErrorBox}
          clickHandler={() =>
            nextClickHandler({integrationType, area, integrationName, schoolDistrict, schoolName}, resetForm)
          }
        >
          Next
        </Button.Primary>
      )}
      {isSecondStep && (
        <Button.Primary
          size="large"
          disabled={shouldDisableNextButton || shouldDisableSecondStepNextButton}
          clickHandler={() =>
            nextClickHandler(
              {
                integrationType,
                area,
                integrationName,
                schoolDistrict,
                schoolName,
                email,
                clientId,
                clientSecret,
                authorityUrl,
                certificate,
              },
              resetForm
            )
          }
        >
          Next
        </Button.Primary>
      )}
      {isThirdStep && <Form.ButtonSubmit size="large" text={loading ? 'Loading...' : 'Test'} />}
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
}));

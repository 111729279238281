import {getIsMicrosoftTeams, getIsGoogleClassRoom, getIsGoogleSitesSource, getIsGoogleSitesDestination} from 'admin';

export const useIntegrationDetailType = (type: number) => {
  const isGoogleClassRoom = getIsGoogleClassRoom(type);
  const isMicrosoftTeams = getIsMicrosoftTeams(type);
  const isGoogleSitesSource = getIsGoogleSitesSource(type);
  const isGoogleSitesDestination = getIsGoogleSitesDestination(type);

  return {isGoogleClassRoom, isMicrosoftTeams, isGoogleSitesSource, isGoogleSitesDestination};
};

import React, {FC, useEffect, useMemo} from 'react';
import {fetchAreasThunk, filtersAreasEntitiesCollectionSelector} from 'filters-selections/store';
import {useDispatch, useSelector} from 'react-redux';
import {IBindBaseEntity, IOption, IWithDisabled} from 'models';
import {IWithMultiSelectOption} from 'filters-selections/models';
import {useSortOrder} from 'admin/programs/details/hooks';
import {SortingOrderEnum} from 'enums';
import {FormNameEnum, ItemWithPlaceholder} from 'filters-selections';
import {Form} from 'forms';

interface IAreaSelectProps extends IWithDisabled, IWithMultiSelectOption {}

export const AreaSelect: FC<IAreaSelectProps> = ({disabled, selectionType = 'single'}: IAreaSelectProps) => {
  const dispatch = useDispatch();
  const areas: IBindBaseEntity[] = useSelector(filtersAreasEntitiesCollectionSelector);
  const isMultiSelect = selectionType === 'multi';
  const {sortBy} = useSortOrder();

  const sortedOptions = sortBy(areas, 'name', SortingOrderEnum.ASC);

  const options: IOption[] = useMemo(() => {
    return sortedOptions.map((area) => ({value: area.id, label: area.name}));
  }, [sortedOptions]);

  useEffect(() => {
    if (!areas.length) {
      dispatch(fetchAreasThunk());
    }
  }, [dispatch, areas.length]);

  return (
    <ItemWithPlaceholder label="JA Area" hasItems={!!sortedOptions.length}>
      {isMultiSelect ? (
        <Form.SelectAutocomplete name={FormNameEnum.areas} options={options} multiple disabled={disabled} />
      ) : (
        <Form.Select name={FormNameEnum.area} options={options} disabled={disabled} />
      )}
    </ItemWithPlaceholder>
  );
};

import {IntegrationLabel} from 'admin/integrations/enums';
import {IIntegrationDetails} from 'models/integrations';
import {toNumber} from 'utils';
import {
  getDefaultPreviewSecondColumnList,
  getIsGoogleClassRoom,
  getIsGoogleSitesDestination,
  getIsGoogleSitesSource,
  getIsMicrosoftTeams,
} from '../../../utils/helpers';
import {FormNameEnum} from 'enums/form';
import {IntegrationStatusNum} from 'enums/integrations';
import {IntegrationStatusObj} from 'admin/integrations/utils';

export const getIntegrationDetailsSecondColumnList = ({
  integrationType,
  name,
  configuration: {serviceEmailAccount, authorityUrl},
  schoolDistrict,
  status,
}: IIntegrationDetails) => {
  let list = [];
  const type = toNumber(integrationType);
  const integrationStatus = IntegrationStatusObj[status];
  const isGoogleClassroom = getIsGoogleClassRoom(type);
  const isMicrosoftTeams = getIsMicrosoftTeams(type);
  const isGoogleSitesSource = getIsGoogleSitesSource(type);
  const isGoogleSitesDestination = getIsGoogleSitesDestination(type);

  const shouldDisableFormIntegrationInput =
    status !== IntegrationStatusNum.TEST_FAILED &&
    status !== IntegrationStatusNum.ERROR &&
    status !== IntegrationStatusNum.TEST_PENDING;

  const defaultList = getDefaultPreviewSecondColumnList({
    integrationName: name ?? '',
    schoolDistrictStringValue: schoolDistrict ?? '',
    email: serviceEmailAccount ?? '',
    googleDriveSourceFolderLink: '',
  });

  if (isGoogleClassroom) {
    const googleClassroomList = defaultList.slice(1, defaultList.length - 2);

    list = [
      {
        name: FormNameEnum.integrationName,
        label: IntegrationLabel.IntegrationName,
        value: name ?? '-',
        options: {
          withForm: true,
          disabled: false,
        },
      },
      ...googleClassroomList,
      {
        name: FormNameEnum.serviceEmail,
        label: IntegrationLabel.ServiceEmailAdress,
        value: serviceEmailAccount ?? '-',
        options: {
          withForm: true,
          disabled: shouldDisableFormIntegrationInput,
        },
      },
      {
        label: IntegrationLabel.Status,
        value: integrationStatus ?? '-',
        options: {
          withForm: false,
          disabled: true,
        },
      },
    ];
  }

  if (isMicrosoftTeams) {
    const microsoftTeamsList = defaultList.slice(1, defaultList.length - 2);

    list = [
      {
        name: FormNameEnum.integrationName,
        label: IntegrationLabel.IntegrationName,
        value: name ?? '-',
        options: {
          withForm: true,
          disabled: false,
        },
      },
      ...microsoftTeamsList,
      {
        name: FormNameEnum.authorityUrl,
        label: IntegrationLabel.AuthorityUrl,
        value: authorityUrl ?? '',
        options: {
          withForm: true,
          disabled: shouldDisableFormIntegrationInput,
        },
      },
      {
        label: IntegrationLabel.Status,
        value: integrationStatus ?? '-',
        options: {
          withForm: false,
          disabled: true,
        },
      },
    ];

    return list;
  }

  if (isGoogleSitesSource) {
    const googleSitesSourceList = defaultList.slice(1, defaultList.length - 2);

    list = [
      {
        name: FormNameEnum.integrationName,
        label: IntegrationLabel.IntegrationName,
        value: name ?? '-',
        options: {
          withForm: true,
          disabled: false,
        },
      },
      ...googleSitesSourceList,
      {
        name: FormNameEnum.serviceEmail,
        label: IntegrationLabel.ServiceEmailAdress,
        value: serviceEmailAccount ?? '-',
        options: {
          withForm: true,
          disabled: shouldDisableFormIntegrationInput,
        },
      },
      {
        label: IntegrationLabel.Status,
        value: integrationStatus ?? '-',
        options: {
          withForm: false,
          disabled: true,
        },
      },
    ];

    return list;
  }

  if (isGoogleSitesDestination) {
    const googleSitesDestinationList = defaultList.slice(1, defaultList.length - 2);

    list = [
      {
        name: FormNameEnum.integrationName,
        label: IntegrationLabel.IntegrationName,
        value: name ?? '-',
        options: {
          withForm: true,
          disabled: false,
        },
      },
      ...googleSitesDestinationList,
      {
        label: IntegrationLabel.Status,
        value: integrationStatus ?? '-',
        options: {
          withForm: false,
          disabled: true,
        },
      },
    ];

    return list;
  }

  return list;
};

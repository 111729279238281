import React, {FC, Fragment, useCallback, useContext, useEffect} from 'react';
import {useFormikContext} from 'formik';
import {useSelector} from 'react-redux';
import {Button, Modal} from 'components-lib';
import {FormNameEnum} from 'filters-selections';
import {ModalCreateAnswerStackForm} from '..';
import {IWithModal} from 'models';
import {savingSelector} from '../../../store';
import {IModalCreateAnswerStackFormValues} from './config/modalCreateAnswerStackValidationSchema';
import {createEmptyFourAnswers, getAnswerType} from '../../utils';
import {useDebounce} from 'hooks';
import {ModalCreateAnswerStackContent} from './ModalCreateAnswerStackContent';
import {FormContext} from 'forms/formik/FormContextProvider';

export const ModalCreateAnswerStack: FC<IWithModal> = ({isOpen, setIsOpen}) => {
  const saving = useSelector(savingSelector);
  const {setIsDirty, isDirty, formikFormRef, submitFormikForm} = useContext(FormContext);
  const {values, resetForm, setFieldValue} = useFormikContext<IModalCreateAnswerStackFormValues>();
  const debouncedReset = useDebounce(() => resetForm());

  const {isAnswerTypeSingleOrMultiOrDropdownChoice} = getAnswerType(values[FormNameEnum.questionType]);

  useEffect(() => {
    const emptyChoices = createEmptyFourAnswers();

    if (!isAnswerTypeSingleOrMultiOrDropdownChoice) {
      setFieldValue(FormNameEnum.choices, []);
    } else {
      setFieldValue(FormNameEnum.choices, [...emptyChoices]);
    }
  }, [isAnswerTypeSingleOrMultiOrDropdownChoice, setFieldValue]);

  const submitFormClickHandler = useCallback(() => formikFormRef?.current && submitFormikForm(), [
    formikFormRef,
    submitFormikForm,
  ]);

  const closeHandler = useCallback(() => {
    setIsOpen(false);
    debouncedReset();
  }, [setIsOpen, debouncedReset]);

  return (
    <Modal
      disableBackdropClick
      isOpen={isOpen}
      handleClose={closeHandler}
      title="Add Tags and Create an Answer Stack"
      size="medium"
    >
      <ModalCreateAnswerStackForm setIsOpen={setIsOpen} setIsDirtyCallback={setIsDirty}>
        <ModalCreateAnswerStackContent isDirty={isDirty} setIsDirtyCallback={setIsDirty} />
      </ModalCreateAnswerStackForm>
      <Fragment>
        <Button.Secondary clickHandler={closeHandler} size="small">
          Cancel
        </Button.Secondary>
        <Button.Primary clickHandler={submitFormClickHandler} size="small" disabled={!isDirty || saving}>
          {saving ? 'Loading...' : 'Done'}
        </Button.Primary>
      </Fragment>
    </Modal>
  );
};

import {IToastNotificationsStoreState} from './IToastNotificationsStoreState';

export const toastNotificationsInitialState: IToastNotificationsStoreState = {
  notifications: {
    entities: {
      notifications: {},
    },
    result: [],
  },
};

import React, {useMemo} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {AdminPortal, AdminPortalNavigation} from 'admin';
import {InstrumentPrintPreviewView} from 'admin/library/instruments/printPreview';
import {Layout} from 'layout';
import {ErrorPage, NoPermissionsPage} from 'pages';
import {pages, paths} from 'paths';
import {AllRolesWithoutStudent, withRole} from 'permissions';
import {SurveyTakerMasterInstrument} from 'survey-taker';

const AdminPortalEnhanced = withRole(
  AllRolesWithoutStudent,
  `${paths.adminPortal.root}${paths.noPermission}`
)(AdminPortal);

export function Routes() {
  const navigation = useMemo(() => <AdminPortalNavigation />, []);

  return (
    <>
      <Switch>
        <Route exact path={paths.root} render={() => <Redirect to={paths.adminPortal.root} />} />
        <Route path={pages.adminPortal.instruments.printPreview} component={InstrumentPrintPreviewView} />
        <Route path={paths.adminPortal.root}>
          <Layout navigation={navigation}>
            <AdminPortalEnhanced />
            <Route path={`${paths.adminPortal.root}${paths.noPermission}`}>
              <NoPermissionsPage />
            </Route>
          </Layout>
        </Route>
        <Route exact path={pages.survey.taker}>
          <Layout navigation={navigation}>
            <SurveyTakerMasterInstrument />
          </Layout>
        </Route>
        <Route path={pages.survey.takerLink}>
          <Layout navigation={navigation}>
            <Route path={`${pages.survey.takerLink}${paths.noPermission}`}>
              <NoPermissionsPage />
            </Route>
          </Layout>
        </Route>
        {/* we want to redirect explicitly because the react-router history and react-oidc history are out of sync in some situations */}
        <Route path={pages.authentication.callback} exact render={() => <Redirect to={window.location.pathname} />} />
        <Route exact path={paths.error} component={ErrorPage} />
        <Route exact path={paths.noPermission} component={NoPermissionsPage} />
        <Route component={ErrorPage} />
      </Switch>
    </>
  );
}

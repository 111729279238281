import * as Yup from 'yup';

import {FormNameEnum} from 'filters-selections';
import {IChoice} from 'models/library/common/IAnswer';
import {errorMessages} from 'utils';
import {checkCorrectAnswer, getTypeCheckbox, getTypeDropdown, getTypeRadioGroup} from '../../utils';
export interface IItemEditFormValues {
  [FormNameEnum.id]: number;
  [FormNameEnum.questionAnswerStackId]: number;
  [FormNameEnum.isDeleted]: boolean;
  [FormNameEnum.questionTextHtml]: string;
  [FormNameEnum.pillars]: number[];
  [FormNameEnum.evaluationTypes]: number[];
  [FormNameEnum.domain]: number;
  [FormNameEnum.programs]: number[] | number | null;
  [FormNameEnum.competencies]: number[];
  [FormNameEnum.concepts]: number[];
  [FormNameEnum.administration]: number;
  [FormNameEnum.schoolLevels]: number[];
  [FormNameEnum.category]: string;
  [FormNameEnum.choices]: any[];
  [FormNameEnum.rangeFrom]: number;
  [FormNameEnum.rangeTo]: number;
  [FormNameEnum.positiveScores]: number[];
  [FormNameEnum.minimumRateDescription]: string;
  [FormNameEnum.maximumRateDescription]: string;
  [FormNameEnum.questionTypeAnswerStack]: number;
}

export const itemEditFormValidationSchema = Yup.object().shape({
  [FormNameEnum.questionTextHtml]: Yup.string().trim().required(errorMessages.questionTextRequired),
  [FormNameEnum.pillars]: Yup.array().of(Yup.number()).required(errorMessages.requiredWithFieldName('a Pillar')),
  [FormNameEnum.evaluationTypes]: Yup.array()
    .of(Yup.number())
    .required(errorMessages.requiredWithFieldName('an Evaluation Type')),
  [FormNameEnum.domain]: Yup.number().nullable().required(errorMessages.requiredWithFieldName('a Domain')),
  [FormNameEnum.competencies]: Yup.array()
    .of(Yup.number())
    .when(FormNameEnum.domain, {
      is: 10, // Domain Knowledge id --> TODO better way to handle validation - rules on the backend
      then: Yup.array().of(Yup.number()).required(errorMessages.requiredWithFieldName('a Competency')),
    }),
  [FormNameEnum.concepts]: Yup.array()
    .of(Yup.number())
    .when(FormNameEnum.domain, {
      is: 10, // Domain Knowledge id --> TODO better way to handle validation - rules on the backend
      then: Yup.array().of(Yup.number()).required(errorMessages.requiredWithFieldName('a KSA')),
    }),
  [FormNameEnum.administration]: Yup.number()
    .nullable()
    .required(errorMessages.requiredWithFieldName('an Administration')),
  [FormNameEnum.schoolLevels]: Yup.array()
    .of(Yup.number())
    .required(errorMessages.requiredWithFieldName('a School Level')),
  [FormNameEnum.category]: Yup.string().ensure(),
  [FormNameEnum.choices]: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.mixed(),
        text: Yup.string(),
        isCorrect: Yup.boolean(),
        position: Yup.number(),
      })
    )
    .test('has-text', 'You should add text to all answers!', function () {
      const {choices} = this.parent;
      const hasText = choices?.every((choice: IChoice) => !!choice.text);
      return hasText;
    })
    .when(FormNameEnum.domain, {
      is: 10, // Domain Knowledge id --> TODO better way to handle validation - rules on the backend
      then: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.mixed(),
            text: Yup.string(),
            isCorrect: Yup.boolean(),
            position: Yup.number(),
          })
        )
        .test('is-dropdown-with-positive', 'Please add at least one positive answer!', function () {
          const choices = this.parent[FormNameEnum.choices] as IChoice[];
          const questionTypeAnswerStack = this.parent[FormNameEnum.questionTypeAnswerStack];
          const isTypeDropdown = getTypeDropdown(questionTypeAnswerStack);

          if (!!choices && choices?.length && isTypeDropdown) {
            return checkCorrectAnswer(choices);
          }

          return true;
        }),
    })
    .test('is-dropdown-with-positive', 'Please add at least one positive answer!', function () {
      const choices = this.parent[FormNameEnum.choices] as IChoice[];
      const questionTypeAnswerStack = this.parent[FormNameEnum.questionTypeAnswerStack];
      const isTypeRadioGroup = getTypeRadioGroup(questionTypeAnswerStack);
      const isTypeCheckbox = getTypeCheckbox(questionTypeAnswerStack);

      if (!!choices && choices?.length && (isTypeRadioGroup || isTypeCheckbox)) {
        return checkCorrectAnswer(choices);
      }
      return true;
    }),
});

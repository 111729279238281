import {createAsyncThunk} from '@reduxjs/toolkit';
import {filtersApi} from 'api';
import {IProgramClassesFilterOptions} from 'models';
import {prefix} from './config';
import {toastNotificationManager} from 'toast-notifications';

export const fetchProgramClassesFiltersThunk: any = createAsyncThunk<IProgramClassesFilterOptions, number>(
  `${prefix} fetchClassFilters`,
  async (programId: number, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await filtersApi.getClassFilterOptions(programId);
      return parsedBody;
    } catch (error) {
      dispatch(
        toastNotificationManager.createErrorToastAction(
          error.parsedBody || error.statusText || 'Unknown error occurred.'
        )
      );

      return rejectWithValue(error);
    }
  }
);

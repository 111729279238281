import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Column, Container, Divider, Row} from 'components-lib';
import {PickFileMetadata} from '..';

export const FilesEditMetadata: FC = () => {
  const classes = useStyles();

  return (
    <Container disableGutters>
      <Row justify="flex-start" align="flex-start" classes={{root: classes.metadata}}>
        <Column sm={12} md={12} lg={12} xl={12}>
          <Container disableGutters>
            <PickFileMetadata />
            <Divider.Horizontal as="div" fullWidth />
          </Container>
        </Column>
      </Row>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  metadata: {
    paddingLeft: theme.spacing(2),
  },
}));

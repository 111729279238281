import React from 'react';
import {useFormikContext} from 'formik';
import {IAddIntegrationFormBaseValues} from 'models/integrations';
import {SchoolNameBasedOnAreaSelect} from './SchoolNameBasedOnAreaSelect';
import {getIsGoogleSitesSource} from 'admin/integrations/utils';

export const SchoolNameBasedOnAreaAndGoogleSitesSelect = () => {
  const {values} = useFormikContext<IAddIntegrationFormBaseValues>();
  const type = Number(values.integrationType);

  const isGoogleSitesSource = getIsGoogleSitesSource(type);

  return <SchoolNameBasedOnAreaSelect disabled={isGoogleSitesSource} />;
};

import {makeStyles} from '@material-ui/core';
import React, {FC} from 'react';
import {SelectionEnum} from '../../../enums';
import {AdministrationSelect} from '../AdministrationSelect/AdministrationSelect';
import {AudienceSelect} from '../AudienceSelect/AudienceSelect';
import {CategoryTags} from '../CategoryTags/CategoryTags';
import {CompetencySelect} from '../CompetencySelect/CompetencySelect';
import {CompetenciesByPillarWithInfoSelect} from '../CompetencySelect/CompetenciesByPillarWithInfoSelect';
import {CompetenciesByPillarSelect} from '../CompetencySelect/CompetencyByPillarSelect';
import {CompetencyByDomainSelect} from '../CompetencySelect/CompetencyByDomainSelect';
import {ConceptSelect} from '../ConceptSelect/ConceptSelect';
import {DomainSelect} from '../DomainSelect/DomainSelect';
import {EvaluationTypeSelect} from '../EvaluationTypeSelect/EvaluationTypeSelect';
import {InstrumentNameTextInput} from '../InstrumentNameTextInput/InstrumentNameTextInput';
import {InstrumentTypeSelect} from '../InstrumentTypeSelect/InstrumentTypeSelect';
import {LanguagesSelect} from '../LanguagesSelect/LanguagesSelect';
import {PhaseSelect} from '../PhaseSelect/PhaseSelect';
import {PillarMultiSelect} from '../PillarSelect/PillarMultiSelect';
import {PillarSelect} from '../PillarSelect/PillarSelect';
import {ProgramMultiSelect} from '../ProgramSelect/ProgramMultiSelect';
import {ProgramSelectWithDomainDependency} from '../ProgramSelect/ProgramSelectWithDomainDependency';
import {SchoolLevelSelect} from '../SchoolLevelSelect/SchoolLevelSelect';
import {SessionSelect} from '../SessionSelect/SessionSelect';
import {SkillsAndBehaviorsSelect} from '../SkillsAndBehaviorsSelect/SkillsAndBehaviorsSelect';
import {TagGradeLevelSelect} from '../TagGradeLevelSelect/TagGradeLevelSelect';
import {GradeLevelsSelect} from '../GradeLevelsSelect/GradeLevelsSelect';
import {K12KeyTermsSelect} from '../K12KeyTermsSelect/K12KeyTermsSelect';
import {SessionByProgramSelect} from '../SessionSelect/SessionByProgramSelect';
import {DomainWithoutKnowledgeSelect} from '../DomainWithoutKnowledgeSelect/DomainWithoutKnowledgeSelect';
import {RangeFromSelect} from '../RangeSelect/RangeFromSelect';
import {RangeToSelect} from '../RangeSelect/RangeToSelect';
import {LearningObjectiveSelect} from '../LearningObjectiveSelect/LearningObjectiveSelect';
import {LearningObjectiveWithDomainDependencySelect} from '../LearningObjectiveSelect/LearningObjectiveWithDomainDependencySelect';
import {InstrumentSubTypeSelect} from '../InstrumentSubTypeSelect/InstrumentSubTypeSelect';
import {RoleSelect} from '../RoleSelect/RoleSelect';
import {IntegrationTypeSelect} from '../IntegrationTypeSelect/IntegrationTypeSelect';
import {AreaSelect} from '../AreaSelect/AreaSelect';
import {AreaMultiSelect} from '../AreaSelect/AreaMultiSelect';
import {SchoolNameBasedOnAreaSelect} from '../SchoolNameSelect/SchoolNameBasedOnAreaSelect';
import {SchoolDistrictSelect} from '../SchoolDistrictSelect/SchoolDistrictSelect';
import {SchoolDistrictBasedOnGoogleSitesSelect} from '../SchoolDistrictSelect/SchoolDistrictBasedOnGoogleSitesSelect';
import {AreaSingleSelectBasedGoogleSites} from '../AreaSelect/AreaSingleSelectBasedGoogleSites';
import {SchoolNameBasedOnAreaAndGoogleSitesSelect} from '../SchoolNameSelect/SchoolNameBasedOnAreaSelectAndGoogleSites';
import {KnowledgeSkillsAndAbilitiesSelect} from '../KnowledgeSkillsAndAbilitiesSelect/KnowledgeSkillsAndAbilitiesSelect';
import {FileTypeSelectBasedOnAttachedFileSelect} from '../FileTypeSelect/FileTypeSelectBasedOnAttachedFile';
import {PrimaryPillarSelect} from '../PillarSelect/PrimaryPillarSelect';
import {SecondaryPillarSelect} from '../PillarSelect/SecondaryPillarSelect';
import {ProgramYearMultiSelect} from '../ProgramYear/ProgramYearMultiSelect';
import {ProgramYearSingleSelect} from '../ProgramYear/ProgramYearSingleSelect';
import {LanguagesSingleSelect} from '../LanguagesSelect/LanguagesSingleSelect';
import {LanguagesMultiSelect} from '../LanguagesSelect/LanguagesMultiSelect';
import {ProgramSingleSelect} from '../ProgramSelect/ProgramSingleSelect';
import {FutureProgramYearSingleSelect, FutureProgramYearMultiSelect} from '../FutureProgramYearSelect';
import {ResourcesSelect} from '../ResourcesSelect/ResourcesSelect';
import {InstrumentsSelect} from '../InstrumentsSelect/InstrumentsSelect';
import {LearningPathNameTextInput} from '../LearningPathNameTextInput/LearningPathNameTextInput';
import {KeyTermsTextInput} from '../KeyTermsTextInput/KeyTermsTextInput';
import {KeyTermsWithInfoTextInput} from '../KeyTermsTextInput/KeyTermsWithInfoTextInput';
import {BriefDescriptionTextArea} from '../BriefDescriptionTextArea/BriefDescriptionTextArea';
import {LearningObjectiveTextInput} from '../LearningObjectiveTextInput/LearningObjectiveTextInput';
import {LearningObjectiveWithInfoTextInput} from '../LearningObjectiveTextInput/LearningObjectiveWithInfoTextInput';
import {ExpirationDatePicker} from '../ExpirationDatePicker/ExpirationDatePicker';
import {EditableExpirationDatePicker} from '../ExpirationDatePicker/EditableExpirationDatePicker';
import {ExpirationWithInfoDatePicker} from '../ExpirationDatePicker/ExpirationWithInfoDatePicker';
import {SchoolDistrictBasedOnAreaSelect} from '../SchoolDistrictSelect/SchoolDistrictBasedOnAreaSelect';
import {SchoolNameBasedOnAreaAndDistrict} from '../SchoolNameSelect/SchoolNameBasedOnAreaAndDistrict';
import {AreaSingleBasedOnDistrictAndGoogleSites} from '../AreaSelect/AreaSingleBasedOnDistrictAndGoogleSites';
import {IntegrationNameTextInput} from '../IntegrationNameTextInput/IntegrationNameTextInput';
import {UserToImpersonateTextInput} from '../UserToImpersonateTextInput/UserToImpersonateTextInput';
import {ServiceEmailTextInput} from '../ServiceEmailTextInput/ServiceEmailTextInput';
import {DisplayNameTextInput} from '../DisplayNameTextInput/DisplayNameTextInput';
import {WebLinkTextInput} from '../WebLinkTextInput/WebLinkTextInput';
import {TimeTextInput} from '../TimeTextInput/TimeTextInput';
import {TimeWithInfoTextInput} from '../TimeTextInput/TimeWithInfoTextInput';
import {PermissionCheckbox} from '../PermissionCheckbox/PermissionCheckbox';
import {AdminPermissionCheckbox} from '../PermissionCheckbox/AdminPermissionCheckbox';
import {AccessibleToStudentsRadioGroup} from '../AccessibleToStudentsRadioGroup/AccessibleToStudentsRadioGroup';
import {VersionFileUpload} from '../VersionFileUpload/VersionFileUpload';
import {AccessibleToStudentsWithInfoRadioGroup} from '../AccessibleToStudentsRadioGroup/AccessibleToStudentsWithInfoRadioGroup';
import {KnowledgeSkillsAndAbilitiesWithInfoSelect} from '../KnowledgeSkillsAndAbilitiesSelect/KnowledgeSkillsAndAbilitiesWithInfoSelect';
import {ToDatePicker} from '../ToDatePicker/ToDatePicker';
import {FromDatePicker} from '../FromDatePicker/FromDatePicker';

const selectionComponentMap = {
  [SelectionEnum.Audience]: AudienceSelect,
  [SelectionEnum.Pillar]: PillarSelect,
  [SelectionEnum.PillarMultiSelect]: PillarMultiSelect,
  [SelectionEnum.EvaluationType]: EvaluationTypeSelect,
  [SelectionEnum.Domain]: DomainSelect,
  [SelectionEnum.ProgramMultiSelection]: ProgramMultiSelect,
  [SelectionEnum.ProgramSelectWithDomainDependency]: ProgramSelectWithDomainDependency,
  [SelectionEnum.Competencies]: CompetencySelect,
  [SelectionEnum.Concepts]: ConceptSelect,
  [SelectionEnum.AdministrationType]: AdministrationSelect,
  [SelectionEnum.SchoolLevel]: SchoolLevelSelect,
  [SelectionEnum.InstrumentType]: InstrumentTypeSelect,
  [SelectionEnum.Phase]: PhaseSelect,
  [SelectionEnum.Sessions]: SessionSelect,
  [SelectionEnum.Languages]: LanguagesSelect,
  [SelectionEnum.SkillsAndBehaviors]: SkillsAndBehaviorsSelect,
  [SelectionEnum.TagGradeLevels]: TagGradeLevelSelect,
  [SelectionEnum.Category]: CategoryTags,
  [SelectionEnum.InstrumentName]: InstrumentNameTextInput,
  [SelectionEnum.GradeLevels]: GradeLevelsSelect,
  [SelectionEnum.CompetenciesByPillar]: CompetenciesByPillarSelect,
  [SelectionEnum.K12KeyTerms]: K12KeyTermsSelect,
  [SelectionEnum.CompetencyByDomain]: CompetencyByDomainSelect,
  [SelectionEnum.SessionsByProgram]: SessionByProgramSelect,
  [SelectionEnum.DomainWithoutKnowledge]: DomainWithoutKnowledgeSelect,
  [SelectionEnum.RangeFrom]: RangeFromSelect,
  [SelectionEnum.RangeTo]: RangeToSelect,
  [SelectionEnum.LearningObjective]: LearningObjectiveSelect,
  [SelectionEnum.LearningObjectiveWithDomainDependency]: LearningObjectiveWithDomainDependencySelect,
  [SelectionEnum.InstrumentSubType]: InstrumentSubTypeSelect,
  [SelectionEnum.Role]: RoleSelect,
  [SelectionEnum.IntegrationTypeSelect]: IntegrationTypeSelect,
  [SelectionEnum.AreasSingleSelection]: AreaSelect,
  [SelectionEnum.AreasMultiSelection]: AreaMultiSelect,
  [SelectionEnum.SchoolNameBasedOnArea]: SchoolNameBasedOnAreaSelect,
  [SelectionEnum.SchoolDistrict]: SchoolDistrictSelect,
  [SelectionEnum.SchoolDistrictBasedOnGoogleSites]: SchoolDistrictBasedOnGoogleSitesSelect,
  [SelectionEnum.AreaSingleBasedOnGoogleSites]: AreaSingleSelectBasedGoogleSites,
  [SelectionEnum.SchoolNameBasedOnAreaSelectAndGoogleSites]: SchoolNameBasedOnAreaAndGoogleSitesSelect,
  [SelectionEnum.KnowledgeSkillsAndAbilities]: KnowledgeSkillsAndAbilitiesSelect,
  [SelectionEnum.FileTypeSelectBasedOnAttachedFile]: FileTypeSelectBasedOnAttachedFileSelect,
  [SelectionEnum.PrimaryPillar]: PrimaryPillarSelect,
  [SelectionEnum.SecondaryPillar]: SecondaryPillarSelect,
  [SelectionEnum.ProgramYearMulti]: ProgramYearMultiSelect,
  [SelectionEnum.ProgramYearSingle]: ProgramYearSingleSelect,
  [SelectionEnum.LanguagesSingle]: LanguagesSingleSelect,
  [SelectionEnum.LanguagesMulti]: LanguagesMultiSelect,
  [SelectionEnum.ProgramsSingle]: ProgramSingleSelect,
  [SelectionEnum.FutureProgramYearSingle]: FutureProgramYearSingleSelect,
  [SelectionEnum.FutureProgramYearMultiple]: FutureProgramYearMultiSelect,
  [SelectionEnum.Resources]: ResourcesSelect,
  [SelectionEnum.Instruments]: InstrumentsSelect,
  [SelectionEnum.LearningPathName]: LearningPathNameTextInput,
  [SelectionEnum.KeyTermsInput]: KeyTermsTextInput,
  [SelectionEnum.KeyTermsWithInfo]: KeyTermsWithInfoTextInput,
  [SelectionEnum.BriefDescriptionTextArea]: BriefDescriptionTextArea,
  [SelectionEnum.LearningObjectiveInput]: LearningObjectiveTextInput,
  [SelectionEnum.LearningObjectiveInputWithInfo]: LearningObjectiveWithInfoTextInput,
  [SelectionEnum.ExpirationDatePicker]: ExpirationDatePicker,
  [SelectionEnum.EditableExpirationDatePicker]: EditableExpirationDatePicker,
  [SelectionEnum.ExpirationWithInfoDatePicker]: ExpirationWithInfoDatePicker,
  [SelectionEnum.SchoolDistrictBasedOnArea]: SchoolDistrictBasedOnAreaSelect,
  [SelectionEnum.SchoolNameBasedOnAreaAndDistrict]: SchoolNameBasedOnAreaAndDistrict,
  [SelectionEnum.AreaSingleBasedOnDistrictAndGoogleSites]: AreaSingleBasedOnDistrictAndGoogleSites,
  [SelectionEnum.IntegrationNameInput]: IntegrationNameTextInput,
  [SelectionEnum.UserToImpersonateInput]: UserToImpersonateTextInput,
  [SelectionEnum.ServiceEmailInput]: ServiceEmailTextInput,
  [SelectionEnum.DisplayNameInput]: DisplayNameTextInput,
  [SelectionEnum.WebLinkInput]: WebLinkTextInput,
  [SelectionEnum.TimeInput]: TimeTextInput,
  [SelectionEnum.TimeWithInfoInput]: TimeWithInfoTextInput,
  [SelectionEnum.PermissionCheckbox]: PermissionCheckbox,
  [SelectionEnum.AdminPermissionCheckbox]: AdminPermissionCheckbox,
  [SelectionEnum.AccessibleToStudentsRadioGroup]: AccessibleToStudentsRadioGroup,
  [SelectionEnum.VersionFileUpload]: VersionFileUpload,
  [SelectionEnum.AccessibleToStudentsWithInfo]: AccessibleToStudentsWithInfoRadioGroup,
  [SelectionEnum.CompetenciesByPillarWithInfo]: CompetenciesByPillarWithInfoSelect,
  [SelectionEnum.KnowledgeSkillsAndAbilitiesWithInfo]: KnowledgeSkillsAndAbilitiesWithInfoSelect,
  [SelectionEnum.ToDatePicker]: ToDatePicker,
  [SelectionEnum.FromDatePicker]: FromDatePicker,
};
interface ISelectionListItemsProps {
  list: SelectionEnum[];
  disableAll?: boolean;
  withFullWidth?: boolean;
  withMargin?: boolean;
  className?: string;
}

export function SelectionListItems({
  list,
  disableAll = false,
  withFullWidth = false,
  withMargin = true,
  className = '',
}: ISelectionListItemsProps) {
  const classes = useStyles({withFullWidth, withMargin, className});
  return (
    <>
      {list.map((item, idx) => {
        const SelectionComponent: FC<any> = selectionComponentMap[item];
        return SelectionComponent ? (
          <div className={`${classes.selection} ${className}`} key={idx}>
            <SelectionComponent disabled={disableAll} />
          </div>
        ) : null;
      })}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  selection: ({withMargin, withFullWidth}: {withFullWidth: boolean; withMargin: boolean}) => {
    let styles: any = {};

    if (withMargin) {
      styles = {
        ...styles,
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
      };
    }

    if (withFullWidth) {
      styles = {...styles, width: '100%'};
    }
    return styles;
  },
}));

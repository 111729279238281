import {IntegrationsOrderingEnum} from 'admin/integrations/enums/IntegrationsOrdering.enum';
import {InstrumentsOrderingEnum} from 'admin/library/instruments/enums/InstrumentsOrderingEnum';
import {ProgramsAllOrderingEnum} from 'admin/programs/all/enums/ProgramsAllOrdering.enum';
import {ProgramsClassesOrderingEnum} from 'admin/programs/details/classes/all/enums';
import {ProgramClassInstrumentsOrderingEnum} from 'admin/programs/details/classes/details/instruments/enums';
import {ProgramsMasterInstrumentsOrderingEnum} from 'admin/programs/details/masterInstruments/enums';
import {DigitalResourcesOrderingEnum} from 'admin/resources/files/views/all';

import {ISortingOptions} from 'models';

export const getSortingOptions = (
  order:
    | ProgramsAllOrderingEnum
    | IntegrationsOrderingEnum
    | DigitalResourcesOrderingEnum
    | InstrumentsOrderingEnum
    | ProgramsClassesOrderingEnum
    | ProgramsMasterInstrumentsOrderingEnum
    | InstrumentsOrderingEnum
    | ProgramClassInstrumentsOrderingEnum,
  sortAsc: boolean
): ISortingOptions => ({
  sortBy: order,
  sortAsc,
});

import {IActionFulfilled} from 'models/common';
import {googleSitesInitialState} from '.';
import {IGoogleSitesStoreState} from './IGoogleSitesStoreState';
import {IGoogleSiteDetailsSortOrder, IGoogleSiteListSortOrder} from 'models/resources/google-sites';

export const googleSitesReducer = {
  clear(state: IGoogleSitesStoreState) {
    state.googleSite = {...googleSitesInitialState.googleSite};
  },

  clearSortOrder(state: IGoogleSitesStoreState) {
    state.details.sortOrder = {...googleSitesInitialState.details.sortOrder};
  },

  clearHistory(state: IGoogleSitesStoreState) {
    state.details.history = {...googleSitesInitialState.details.history};
  },

  clearHistoryCopyIntegrationId(state: IGoogleSitesStoreState) {
    state.details.history.copyData = {...googleSitesInitialState.details.history.copyData};
  },

  setCopyIntegrationId(state: IGoogleSitesStoreState, action: IActionFulfilled<null, string>) {
    state.details.history.copyData.integrationId = action.payload;
  },

  setSortOrderGoogleSiteDetails: (
    state: IGoogleSitesStoreState,
    action: IActionFulfilled<null, IGoogleSiteDetailsSortOrder>
  ) => {
    state.details.sortOrder = action.payload;
  },

  setSortOrderGoogleSites: (
    state: IGoogleSitesStoreState,
    action: IActionFulfilled<null, IGoogleSiteListSortOrder>
  ) => {
    state.googleSites.sortOrder = action.payload;
  },

  setCurrentPageGoogleSites: (state: IGoogleSitesStoreState, action: IActionFulfilled<null, number>) => {
    state.googleSites.pagination.currentPage = action.payload;
  },

  setPerPageGoogleSites: (state: IGoogleSitesStoreState, action: IActionFulfilled<null, number>) => {
    state.googleSites.pagination.perPage = action.payload;

    resetPagination(state);
  },

  setTotalCountGoogleSites: (state: IGoogleSitesStoreState, action: IActionFulfilled<null, number>) => {
    state.googleSites.pagination.totalCount = action.payload;
  },
};

const resetPagination = (state: IGoogleSitesStoreState) => {
  state.pagination.currentPage = googleSitesInitialState.pagination.currentPage;
};

import {ItemStatusEnum, ItemStatusNamesEnum} from 'admin/library/items/enums';
import {Accordion, Column} from 'components-lib';
import {Form} from 'forms';
import React from 'react';
import {FormNameEnum} from '../../../enums';
import {IWithDisabled} from 'models';

export function ItemStatusFilter({disabled}: IWithDisabled) {
  return (
    <Accordion heading="Item Status">
      <Column>
        <Form.RadioGroup name={FormNameEnum.itemStatus}>
          {Object.keys(ItemStatusNamesEnum).map((key) => (
            <Form.Radio
              key={`${FormNameEnum.itemStatus}-${key}`}
              value={ItemStatusEnum[key]}
              label={ItemStatusNamesEnum[key]}
              disabled={disabled}
            />
          ))}
        </Form.RadioGroup>
      </Column>
    </Accordion>
  );
}

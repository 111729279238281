import {useAppDispatch} from 'hooks';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {builderPossiblePretestEntitiesBaseCollectionSelector, fetchPossiblePretestItemsThunk} from '../store';

export function usePretestItem() {
  const dispatch = useAppDispatch();
  const possiblePretestItems = useSelector(builderPossiblePretestEntitiesBaseCollectionSelector);

  const getPossiblePretestItems = useCallback(
    (templateId) => {
      dispatch(fetchPossiblePretestItemsThunk(templateId));
    },
    [dispatch]
  );

  return {
    getPossiblePretestItems,
    possiblePretestItems,
  };
}

export enum RolesEnum {
  SuperAdmin = 1,
  USAAdmin = 2,
  AreaAdmin = 3,
  Staff = 4,
  Educator = 5,
  Volunteer = 6,
  Student = 7,
  All = 8,
}

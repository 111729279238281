import React, {FC, useCallback} from 'react';
import {filterResourceCollectionById} from 'admin/resources/learning-paths/views/create';
import {useFormikContext} from 'formik';
import {IAddSessionResourcesFormValues} from '../AddSessionResourcesForm/config';
import {FormNameEnum} from 'enums/form';
import {IBaseResourceItem} from 'models/resources';
import {AddBaseDrawerListItem} from '..';
interface IAddResourcesDrawerListItemProps {
  id: string;
  name: string;
}

export const AddResourcesDrawerListItem: FC<IAddResourcesDrawerListItemProps> = ({id, name}) => {
  const {
    values: {selectedResources},
    setFieldValue,
  } = useFormikContext<IAddSessionResourcesFormValues>();

  const deleteDrawerItemClickHandler = useCallback(() => {
    const filteredResources = filterResourceCollectionById(selectedResources, id);
    const filteredResouceIds = filteredResources.map((resource: IBaseResourceItem) => resource.contentItemId);

    setFieldValue(FormNameEnum.selectedResources, filteredResources);
    setFieldValue(FormNameEnum.selectedResourcesIds, filteredResouceIds);
  }, [id, selectedResources, setFieldValue]);

  return <AddBaseDrawerListItem id={id} name={name} deleteDrawerItemClickHandler={deleteDrawerItemClickHandler} />;
};

import React, {FC} from 'react';
import {IWithChildren} from 'models';
import {ResourceDetails} from 'admin/resources';
interface ILearningPathDetailsProps extends IWithChildren {
  withSubtitle?: boolean;
}

export const LearningPathDetails: FC<ILearningPathDetailsProps> = ({children, withSubtitle = true}) => {
  return <ResourceDetails withSubtitle={withSubtitle}>{children}</ResourceDetails>;
};

import React from 'react';
import {Column, Label} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {Form} from 'forms';
import {IWithDisabled} from 'models';

interface ILearningObjectiveSelectProps extends IWithDisabled {
  value?: string;
}

export const LearningObjectiveSelect = ({disabled, value}: ILearningObjectiveSelectProps) => {
  return (
    <Column>
      <Label label="Learning Objective" />
      <Form.InputWithField name={FormNameEnum.learningObjective} value={value} disabled={disabled} />
    </Column>
  );
};

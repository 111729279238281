import {getProgramYearsCollectionString} from 'admin/programs/details';
import {SortingOrderEnum} from 'enums';
import {IFetchProgramsAllFilters, ISortingOptions} from 'models';

export const mapProgramsPayload = ({
  filter,
  currentPage,
  perPage,
  sortOrder: {order, sort},
  programYearsCollection,
}: IFetchProgramsAllFilters) => {
  const sortAsc = sort === SortingOrderEnum.ASC;
  const sortOptions = {sortBy: order, sortAsc} as ISortingOptions;

  const filterProgramYears = filter.programYearsFilter.length
    ? getProgramYearsCollectionString(programYearsCollection, filter.programYearsFilter)
    : [];

  const updatedFilter = {...filter, programYearsFilter: [...filterProgramYears]};

  return {
    sortOptions,
    pagination: {
      page: currentPage,
      itemsPerPage: perPage,
    },
    filter: updatedFilter,
  };
};

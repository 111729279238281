import React, {useCallback, useEffect, useMemo} from 'react';
import {Subtitle, UploadDraggableArea} from 'admin/components';
import {Container} from 'components-lib';
import {SingleFileUpload} from 'admin/resources/upload/components';
import {FormNameEnum} from '../../../enums';
import {useFormikContext} from 'formik';
import {IUploadFileFormBaseValues} from 'admin/resources/upload';
import {ALLOWED_MIMETYPE_FORMATS, getFileName, getFileType, mapFileTypeNumber} from 'admin/resources/upload/utils';

export const UploadFileContentStart = () => {
  const {
    values: {file, displayName},
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IUploadFileFormBaseValues>();

  const allowedFormats = useMemo(() => ALLOWED_MIMETYPE_FORMATS.join(', '), []);
  const fileName = useMemo(() => (file ? getFileName(file.name) : ''), [file]);

  const setFileType = useCallback(
    (fileTypeNum: number) => {
      setFieldValue(FormNameEnum.fileType, fileTypeNum ?? null);
      // (setFieldTouched) See: https://github.com/formium/formik/issues/456
      setFieldTouched(FormNameEnum.fileType, true, true);
      setFieldValue(FormNameEnum.shouldDisableFileType, true);
    },
    [setFieldValue, setFieldTouched]
  );

  const deleteFileType = useCallback(() => {
    setFieldValue(FormNameEnum.fileType, null);
    setFieldValue(FormNameEnum.displayName, null);
    setFieldValue(FormNameEnum.shouldDisableFileType, false);
  }, [setFieldValue]);

  useEffect(() => {
    if (!!file) {
      if (!displayName) {
        setFieldValue(FormNameEnum.displayName, fileName);
        setFieldTouched(FormNameEnum.displayName, true, true);
      }

      const fileType = file && getFileType(file.type, file.name);
      const fileTypeNum = mapFileTypeNumber(fileType);

      // Map File type number based on the string

      if (!!fileTypeNum) {
        setFileType(fileTypeNum);
      }
    }
  }, [file, setFieldValue, setFieldTouched, fileName, setFileType, displayName]);

  return (
    <UploadDraggableArea name={FormNameEnum.file}>
      <Container disableGutters>
        <Subtitle withColor={false} as="h3" color="textPrimary" title="Upload File" />
        <SingleFileUpload name={FormNameEnum.file} accept={allowedFormats} deleteCallback={deleteFileType} />
      </Container>
    </UploadDraggableArea>
  );
};

import React, {FC, Fragment} from 'react';
import {makeStyles} from '@material-ui/core';

import {Text} from 'components-lib';

interface IFooterLogoProps {
  footerUrl: string;
}

export const FooterLogo: FC<IFooterLogoProps> = ({footerUrl}) => {
  const classes = useStyles();

  return (
    <Fragment>
      {!footerUrl ? (
        <Text.Caption>Loading...</Text.Caption>
      ) : (
        <img src={footerUrl} className={classes.footerLogo} alt="Footer logo" loading="lazy" />
      )}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  footerLogo: {
    height: '60px',
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(2),
  },
}));

export enum DigitalResourceMetaDataEnum {
  PROGRAMS = 'programs',
  SESSIONS = 'sessions',
  PILLARS = 'pillars',
  COMPETENCIES = 'competencies',
  SKILLSANDBEHAVIORS = 'skillsAndBehaviors',
  LANGUAGES = 'languages',
  GRADELEVELS = 'gradeLevels',
  LEVELS = 'levels',
  KEYTERMS = 'keyTerms',
  CONCEPTS = 'concepts',
  LEARNINGOBJECTIVES = 'learningObjectives',
  TYPES = 'digitalResourceTypes',
  FILENAME = 'fileName',
  DRMDATE = 'drmDate',
  DRM_EXPIRATION_DATE = 'drmExpirationDate',
}

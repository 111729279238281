import {NavigationProgressBarTextEnum} from 'admin/integrations/enums';
import {
  getIsGoogleClassRoom,
  getIsMicrosoftTeams,
  getIsGoogleSitesSource,
  getIsGoogleSitesDestination,
} from 'admin/integrations';

export const useAddIntegrationType = (integrationType: number) => {
  const type = Number(integrationType);

  const isGoogleClassRoom = getIsGoogleClassRoom(type);
  const isMicrosoftTeams = getIsMicrosoftTeams(type);
  const isGoogleSitesSource = getIsGoogleSitesSource(type);
  const isGoogleSitesDestination = getIsGoogleSitesDestination(type);

  return {
    integrationTextType: NavigationProgressBarTextEnum.Step2Text,
    isGoogleClassRoom,
    isMicrosoftTeams,
    isGoogleSitesSource,
    isGoogleSitesDestination,
  };
};

import {useCallback, useRef} from 'react';
import {ResourceTypeNumEnum} from 'admin/resources/enums';
import {useFileEdit} from './useFileEdit';

interface IGetFileType {
  isResource: boolean;
  isScormPackage: boolean;
  isWebLink: boolean;
  isArchive: boolean;
  isVideo: boolean;
  isAudio: boolean;
  isImage: boolean;
  isPDF: boolean;
  isDocument: boolean;
  isExcel: boolean;
}

export const useFileType = () => {
  const typeRef = useRef(null);
  const {fileDetails} = useFileEdit();

  const getType: () => IGetFileType = useCallback(() => {
    if (!fileDetails) {
      return {
        isResource: false,
        isScormPackage: false,
        isWebLink: false,
        isArchive: false,
        isVideo: false,
        isAudio: false,
        isImage: false,
        isPDF: false,
        isDocument: false,
        isExcel: false,
      };
    }

    // Generic
    const isResource =
      fileDetails?.contentType !== ResourceTypeNumEnum.ScormPackage &&
      fileDetails?.contentType !== ResourceTypeNumEnum.WebLink;
    const isScormPackage = fileDetails?.contentType === ResourceTypeNumEnum.ScormPackage;
    const isWebLink = fileDetails?.contentType === ResourceTypeNumEnum.WebLink;

    // Resource - Others
    const isVideo = isResource && fileDetails?.contentType === ResourceTypeNumEnum.Video;
    const isAudio = isResource && fileDetails?.contentType === ResourceTypeNumEnum.Audio;
    const isImage = isResource && fileDetails?.contentType === ResourceTypeNumEnum.Image;
    const isPDF =
      isResource &&
      (fileDetails?.contentType === ResourceTypeNumEnum.PDF ||
        fileDetails?.contentType === ResourceTypeNumEnum.FillablePDF);
    const isExcel = isResource && fileDetails?.contentType === ResourceTypeNumEnum.Excel;
    const isDocument = isResource && fileDetails?.contentType === ResourceTypeNumEnum.Document;
    const isArchive = isResource && fileDetails?.contentType === ResourceTypeNumEnum.Archive;

    typeRef.current = {
      isResource,
      isScormPackage,
      isWebLink,
      isArchive,
      isVideo,
      isAudio,
      isImage,
      isPDF,
      isDocument,
      isExcel,
    };

    return typeRef.current;
  }, [fileDetails]);

  const {
    isResource,
    isScormPackage,
    isWebLink,
    isArchive,
    isVideo,
    isAudio,
    isImage,
    isPDF,
    isDocument,
    isExcel,
  } = getType();

  return {
    isResource,
    isScormPackage,
    isWebLink,
    isArchive,
    isVideo,
    isAudio,
    isImage,
    isPDF,
    isDocument,
    isExcel,
  };
};

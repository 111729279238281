import {ILearningPathDetails} from 'models/resources/learning-paths';
import {ILearningPathsEditStoreState} from './ILearningPathsEditStoreState';

const learningPathDefault = {
  id: null,
  title: null,
  description: null,
  createdUtc: null,
  sessions: [],
} as ILearningPathDetails;

export const learningPathEditInitialState: ILearningPathsEditStoreState = {
  page: {
    loading: false,
    exporting: false,
  },
  learningPath: learningPathDefault,
};

import React, {FC} from 'react';
import {FormNameEnum} from 'filters-selections';
import {TextInputSelection} from '../TextInputSelection/TextInputSelection';
import {IWithDisabled} from 'models/common';

type IIntegrationNameTextInputProps = IWithDisabled;

export const IntegrationNameTextInput: FC<IIntegrationNameTextInputProps> = ({disabled}) => (
  <TextInputSelection name={FormNameEnum.integrationName} label="Integration Name" disabled={disabled} />
);

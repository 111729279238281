import {RolesEnum} from 'permissions';

export const SuperAdminRole = [RolesEnum.SuperAdmin];
export const AreaAdminRole = [RolesEnum.AreaAdmin];
export const USAAdminRole = [RolesEnum.USAAdmin];
export const StaffRole = [RolesEnum.Staff];
export const EducatorRole = [RolesEnum.Educator];
export const VolunteerRole = [RolesEnum.Volunteer];
export const StudentRole = [RolesEnum.Student];
export const SuperAndNationalAdminroles = [RolesEnum.USAAdmin, RolesEnum.SuperAdmin];
export const AdminRoles = [RolesEnum.USAAdmin, RolesEnum.SuperAdmin, RolesEnum.AreaAdmin];
export const AllRolesWithoutStudent = [
  RolesEnum.AreaAdmin,
  RolesEnum.Educator,
  RolesEnum.Staff,
  RolesEnum.SuperAdmin,
  RolesEnum.USAAdmin,
  RolesEnum.Volunteer,
];

import {IEntityBase} from 'models/common';

export const getAreaStringValue = (
  area: number,
  areas: {
    [id: string]: IEntityBase;
  }
) => {
  return areas[area]?.name ?? '';
};

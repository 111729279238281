import React, {FC, useRef} from 'react';
import {LearningPathSessionsForm, LearningPathSessionsListItem} from '..';
import {ILearningPathSessionDragItem, ILearningPathSessionItem, IWithDisabled} from 'models';
import {SessionBuilderEditModeEnum} from 'enums';
import {BuilderItemDragTypeEnum} from 'admin/library/enums';
import {DragItem, useItemDrag, useItemDrop} from 'dragAndDrop';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';

interface ISessionListDragItemProps extends IWithDisabled {
  index: number;
  item: ILearningPathSessionItem;
  moveSession: (movedItemIdx: number, newPosition: number) => void;
  sessionMode: SessionBuilderEditModeEnum;
  shouldExpand: boolean;
  collapseSessionBuilderItem: (
    item: ILearningPathSessionItem
  ) => {
    payload: ILearningPathSessionItem;
    type: string;
  };
  setSessionBuilderMode: (item: ILearningPathSessionItem) => void;
}

export const SessionListDragItem: FC<ISessionListDragItemProps> = ({
  index,
  item,
  moveSession,
  sessionMode,
  shouldExpand,
  disabled,
  collapseSessionBuilderItem,
  setSessionBuilderMode,
}) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const itemDrag = {
    ...item,
    index,
    type: BuilderItemDragTypeEnum.sessionItem,
  } as ILearningPathSessionDragItem;

  const {hasPermission} = usePermissions();
  const canDrag = hasPermission(USAAndSuperAdminRoles);
  const {drag} = useItemDrag(itemDrag, canDrag);
  const {drop, handlerId} = useItemDrop(moveSession, BuilderItemDragTypeEnum.sessionItem, itemRef, index);

  drag(drop(itemRef));

  return (
    <LearningPathSessionsForm item={item}>
      <DragItem drag={itemRef} handlerId={handlerId}>
        <LearningPathSessionsListItem
          sessionBuilderMode={sessionMode}
          setSessionBuilderMode={() => setSessionBuilderMode(item)}
          initialIsExpanded={shouldExpand}
          disabled={disabled}
          item={item}
          collapseAccordionCallback={() => collapseSessionBuilderItem(item)}
        />
      </DragItem>
    </LearningPathSessionsForm>
  );
};

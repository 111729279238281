export enum LearningPathLabelEnum {
  Name = 'Name',
  SessionName = 'Session Name',
  SessionDescription = 'Session Description',
  GradeLevel = 'Grade Level',
  Program = 'Program',
  PrimaryPillar = 'Primary Pillar',
  SecondaryPillar = 'Secondary Pillar',
  KnowledgeSkillsAndAbilities = 'KSAs',
  KeyTerms = 'Key Terms',
  BriefDescription = 'Brief Description',
  Competency = 'Competency',
  Competencies = 'Competencies',
  Language = 'Language',
  ExpirationDate = 'Expiration Date',
  LearningObjectives = 'Learning Objectives',
}

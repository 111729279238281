import {IFetchMergedExportQueryParams} from '../../models';

export const mapMergedExport = (
  pasProgramId: number,
  d2lProgramId: number,
  areaId?: number,
  sessionId?: number
): IFetchMergedExportQueryParams => {
  return {
    pasProgramId,
    d2lProgramId,
    areaId,
    sessionId,
  };
};

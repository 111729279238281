import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {IInstrumentDetails, IUpdateInstrumentInfoPayload} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const updateInstrumentInfoThunk: any = createAsyncThunk<IInstrumentDetails, IUpdateInstrumentInfoPayload>(
  `${prefix} updateInstrumentName`,
  async (payload: IUpdateInstrumentInfoPayload, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await instrumentsApi.updateInstrumentInfo(payload);
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

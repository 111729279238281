import {SortingOrderEnum} from 'enums';
import {IntegrationDropdownNameEnum} from 'admin/integrations';
import {IFilesAllSortOrder} from 'models';
import {FilesOrderingAllEnum} from '../enums';

export const getFilesAllMenuItemsList = (
  itemClickHandler: () => void,
  handleItemClick: (handleItemSortObj: IFilesAllSortOrder) => void
) => {
  const onClick = (
    itemClickHandler: () => void,
    handleItemClick: (handleItemSortObj: IFilesAllSortOrder) => void,
    handleItemSortObj: IFilesAllSortOrder
  ) => {
    return () => {
      itemClickHandler();
      handleItemClick(handleItemSortObj);
    };
  };

  return [
    {
      name: IntegrationDropdownNameEnum.NAME_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: FilesOrderingAllEnum.Name,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: IntegrationDropdownNameEnum.NAME_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: FilesOrderingAllEnum.Name,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: IntegrationDropdownNameEnum.CREATED_NEWEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: FilesOrderingAllEnum.Created,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: IntegrationDropdownNameEnum.CREATED_OLDEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: FilesOrderingAllEnum.Created,
        sort: SortingOrderEnum.ASC,
      }),
    },
  ];
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {prefix} from './config';
import {programsApi} from 'api';
import {IGetPageOfProgramMasterInstrumentsResponse, IGetPageOfProgramMasterInstrumentsPayload} from 'models';
import {toastNotificationManager} from 'toast-notifications';

export const fetchProgramMasterInstrumentsPagedThunk: any = createAsyncThunk<
  IGetPageOfProgramMasterInstrumentsResponse,
  IGetPageOfProgramMasterInstrumentsPayload
>(
  `${prefix} fetchProgramMasterInstrumentsPaged`,
  async (payload: IGetPageOfProgramMasterInstrumentsPayload, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await programsApi.getProgramMasterInstrumentsPaged(payload);

      return parsedBody;
    } catch (err) {
      dispatch(
        toastNotificationManager.createErrorToastAction(err.parsedBody || err.statusText || 'Unknown error occurred.')
      );
      return rejectWithValue({error: err});
    }
  }
);

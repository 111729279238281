import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {pages} from 'paths';
import {ProgramsAllView} from './all';
import {
  ProgramClassesView,
  ProgramClassInstrumentsView,
  ProgramClassReportsAndExportsView,
  ProgramMasterInstrumentsView,
  ProgramReportsAndExportsView,
  ProgramClassPaperAssessmentsView,
} from './details';

export function Programs() {
  return (
    <Switch>
      <Route exact path={pages.adminPortal.programs.root} component={ProgramsAllView} />
      <Route path={pages.adminPortal.programs.details} component={ProgramClassesView} />
      <Route path={pages.adminPortal.programs.reports} component={ProgramReportsAndExportsView} />
      <Route path={pages.adminPortal.programs.masterInstruments} component={ProgramMasterInstrumentsView} />
      <Route path={pages.adminPortal.programs.class} component={ProgramClassInstrumentsView} />
      <Route path={pages.adminPortal.programs.classReports} component={ProgramClassReportsAndExportsView} />
      <Route path={pages.adminPortal.programs.classPaperAssessments} component={ProgramClassPaperAssessmentsView} />
    </Switch>
  );
}

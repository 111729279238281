import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchConceptsThunk, filtersConceptsEntitiesCollectionSelector, FormNameEnum} from 'filters-selections';
import {useIsDomainKnowledgeSelected} from 'filters-selections/hooks';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
interface IConceptSelectProps extends IWithDisabled {
  infoText?: string;
}

export function ConceptSelect({disabled, infoText}: IConceptSelectProps) {
  const dispatch = useDispatch();
  const concepts = useSelector(filtersConceptsEntitiesCollectionSelector);
  const {isDomainKnowledgeSelected, formValues} = useIsDomainKnowledgeSelected();

  const competencies = formValues[FormNameEnum.competencies];

  useEffect(() => {
    if (competencies?.length) {
      dispatch(fetchConceptsThunk(competencies));
    }
  }, [competencies, dispatch]);

  const options = useMemo(
    () =>
      concepts.map((concept) => ({
        value: concept.id,
        label: concept.name,
      })),
    [concepts]
  );

  const isDisabled = !isDomainKnowledgeSelected || disabled || !competencies.length;

  return (
    <ItemWithPlaceholder label="KSAs" hasItems infoText={infoText}>
      <Form.SelectAutocomplete name={FormNameEnum.concepts} options={options} multiple disabled={isDisabled} />
    </ItemWithPlaceholder>
  );
}

import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {ISurvey} from 'survey-react';
import {prefix} from './config';

export const getGeneralSurveyThunk: any = createAsyncThunk<ISurvey, number>(
  `${prefix} getGeneralSurvey`,
  async (instrumentId) => {
    try {
      const {parsedBody} = await instrumentsApi.getGeneralSurvey(instrumentId);
      return parsedBody;
    } catch (err) {
      throw new Error(err.parsedBody);
    }
  }
);

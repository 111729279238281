import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';
import {values} from 'utils/functions';

const getLearningPathsState = (state: TStateStore) => state.resources.learningPaths;

const getLearningPathsAllState = createSelector(getLearningPathsState, (learningPaths) => learningPaths.all);
const getLearningPathState = createSelector(getLearningPathsAllState, (learningPaths) => learningPaths.learningPath);

export const learningPathsAllPageLoadingSelector = createSelector(
  getLearningPathsAllState,
  (learningPathsAll) => learningPathsAll.page.loading
);

export const learningPathsAllPaginationSelector = createSelector(
  getLearningPathsAllState,
  (learniningPaths) => learniningPaths.pagination
);

export const learningPathsAllSortOrderSelector = createSelector(
  getLearningPathsAllState,
  (learniningPaths) => learniningPaths.sortOrder
);

export const learningPathsAllListSelector = createSelector(
  getLearningPathsAllState,
  (learningPathsAll) => learningPathsAll.list
);

export const learningPathsAllIdsSelector = createSelector(learningPathsAllListSelector, (list) => list.result);

export const learningPathsAllListSelectorEntitiesSelector = createSelector(
  learningPathsAllListSelector,
  (list) => list.entities.items
);

export const learningPathsAllListSelectorEntitiesCollectionSelector = createSelector(
  learningPathsAllListSelector,
  (list) => (list.entities.items ? values(list.entities?.items) : [])
);

export const learningPathsAllFilterSelector = createSelector(
  getLearningPathsAllState,
  (learningPaths) => learningPaths.filter
);

export const exportingLearningPathsSelector = createSelector(
  getLearningPathState,
  (learningPath) => learningPath.exporting
);

export const cloningLearningPathsSelector = createSelector(
  getLearningPathState,
  (learningPath) => learningPath.cloning
);

export const selectedLearningPathIdSelector = createSelector(getLearningPathState, (learningPath) => learningPath.id);

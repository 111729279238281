import React, {FC, Fragment, useCallback, useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {useFormikContext} from 'formik';
import {Accordion, Column, Row, Wrapper, Text, Icon} from 'components-lib';
import {AdditionalResourcesList, SessionDetails, SessionDetailsDivider, SessionsListItemActionButtons} from '..';
import {SessionBuilderEditModeEnum} from 'enums/learning-paths';
import {ILearningPathSessionsFormBaseValues} from 'admin/resources/learning-paths/views/session-builder/utils';
import {ILearningPathSessionItem} from 'models/resources';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';
import {FormNameEnum} from 'enums/form';

interface ILearningPathSessionsListItemProps {
  item: ILearningPathSessionItem;
  headingActionLeft?: string;
  initialIsExpanded?: boolean;
  disabled?: boolean;
  withBorder?: boolean;
  sessionBuilderMode?: SessionBuilderEditModeEnum;
  collapseAccordionCallback: () => void;
  setSessionBuilderMode: (mode: SessionBuilderEditModeEnum) => void;
}

export const LearningPathSessionsListItem: FC<ILearningPathSessionsListItemProps> = ({
  item,
  headingActionLeft = '',
  initialIsExpanded = false,
  disabled = false,
  withBorder = true,
  sessionBuilderMode,
  setSessionBuilderMode,
}) => {
  const classes = useStyles();
  const {hasPermission} = usePermissions();
  const canDrag = hasPermission(USAAndSuperAdminRoles);

  const {
    values: {sessionName},
    dirty,
    setFieldTouched,
  } = useFormikContext<ILearningPathSessionsFormBaseValues>();

  const hasItemAssets = item.resources.length || item.instruments.length || !!item.title;

  const markFieldAsTouched = useCallback(() => {
    if (item.resources.length) {
      setFieldTouched(FormNameEnum.resources, true, true);
    }

    if (item.instruments.length) {
      setFieldTouched(FormNameEnum.instruments, true, true);
    }

    if (item.title) {
      setFieldTouched(FormNameEnum.sessionName, true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.resources, item.instruments, item.title]);

  useEffect(() => {
    if (hasItemAssets && !dirty) {
      markFieldAsTouched();
    }
  }, [hasItemAssets, dirty, markFieldAsTouched]);

  const isEditMode = sessionBuilderMode === SessionBuilderEditModeEnum.Edit;

  const header = useMemo(
    () => (
      <Fragment>
        <Wrapper className={classes.icon}>
          <Icon.DragHandleIcon color="inherit" />
        </Wrapper>
        <Text.Heading as="h4" classes={{root: classes.heading}}>
          {sessionName ?? item?.title}
        </Text.Heading>
      </Fragment>
    ),
    [classes.icon, classes.heading, item, sessionName]
  );

  return (
    <Accordion
      heading={header}
      headingActionLeft={headingActionLeft}
      initialIsExpanded={initialIsExpanded}
      withBorder={withBorder}
      withGrayBackground
      draggable={canDrag}
    >
      <Row justify="flex-start" align="flex-start" classes={{root: classes.row}}>
        <SessionDetailsDivider />
        <Row justify="flex-start" align="flex-start">
          <Column sm={4} md={4} lg={3} xl={3}>
            <SessionDetails item={item} isEditMode={isEditMode} />
          </Column>
          <Column sm={4} md={4} lg={3} xl={3}>
            <AdditionalResourcesList
              item={item}
              isEditMode={isEditMode}
              title="Instruments"
              isResourceList={false}
              openDrawerButtonText="Add Instruments"
            />
          </Column>
          <Column sm={4} md={4} lg={3} xl={3}>
            <AdditionalResourcesList
              item={item}
              isEditMode={isEditMode}
              title="Resources"
              isResourceList={true}
              openDrawerButtonText="Add Resources"
            />
          </Column>
        </Row>
        <Row justify="flex-end" align="center">
          <SessionsListItemActionButtons
            id={item?.id}
            isEditMode={isEditMode}
            setSessionBuilderModeCallback={setSessionBuilderMode}
            disabled={disabled}
          />
        </Row>
      </Row>
    </Accordion>
  );
};

const useStyles = makeStyles((theme) => ({
  row: {
    width: '100%',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  heading: {
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
  emptyContainer: {
    height: 50,
  },
  test: {
    border: '1px solid grey',
    width: 400,
  },
}));

import React from 'react';
import {InstrumentViewTypeEnum} from 'admin/library/items/enums';
import {Accordion, Column} from 'components-lib';
import {Form} from 'forms';
import {FormNameEnum} from '../../../enums';
import {IWithDisabled} from 'models';

export function InstrumentViewTypeFilter({disabled}: IWithDisabled) {
  return (
    <Accordion heading="Instrument Type">
      <Column>
        <Form.RadioGroup name={FormNameEnum.instrumentViewType}>
          {InstrumentViewTypeEnum.map((type, key) => (
            <Form.Radio key={`instrumentViewType-${key}`} value={key} label={type} disabled={disabled} />
          ))}
        </Form.RadioGroup>
      </Column>
    </Accordion>
  );
}

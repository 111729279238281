import {createAsyncThunk} from '@reduxjs/toolkit';
import {itemsApi} from 'api';
import {IAnswerStack} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchNewItemThunk: any = createAsyncThunk<IAnswerStack[], null>(
  `${prefix} fetchNewItem`,
  async (_, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await itemsApi.getNewItem();
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

import {useParams} from 'react-router-dom';
import {toNumber} from 'utils';

interface IInstrumentIdParams {
  id: string;
}

export const useInstrumentIdParams = () => {
  const {id} = useParams<IInstrumentIdParams>();
  const instrumentId = toNumber(id);

  return {instrumentId};
};

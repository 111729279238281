import {makeStyles} from '@material-ui/core';
import React, {ReactNode} from 'react';

interface IProps {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
}

// eslint-disable-next-line react/display-name
const SurveyPage = React.forwardRef(({header, footer, children}: IProps, ref: any) => {
  const classes = useStyles();

  return (
    <div className={classes.surveyPage}>
      <div className={classes.surveyPageContainer}>
        <div className={classes.surveyPageHeader}>{header}</div>
        <div className={classes.surveyPageContent} ref={ref}>
          {children}
        </div>
        <div className={classes.surveyPageHeader}>{footer}</div>
      </div>
    </div>
  );
});

const useStyles = makeStyles(() => ({
  surveyPage: {
    border: 'solid black 1px',
    paddingTop: '128%' /* 1:1.3125 ('letter' paper format) */,
    position: 'relative',
    pageBreakInside: 'avoid',
    margin: '16px 0',
    '@media print': {
      border: 'none',
      margin: 0,
    },
  },
  surveyPageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  surveyPageContent: {
    width: '100%',
    flexGrow: 1,
    overflow: 'hidden',
  },
  surveyPageHeader: {
    width: '100%',
  },
  surveyPageFooter: {
    width: '100%',
  },
}));

export default SurveyPage;

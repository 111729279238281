export enum ModalTypes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum ModalWidth {
  small = '350px',
  medium = '500px',
  large = '650px',
}

export enum TagIndicator {
  drmExpirationDate = 'drmExpirationDate',
  keyTerms = 'keyTerms',
  concepts = 'concepts',
  learningObjective = 'learningObjective',
}

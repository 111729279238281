import React, {ChangeEvent, Fragment, useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Checkbox, Input} from 'components-lib';
import {ListboxComponent} from './ListboxComponent';
import {colors} from 'styles';
import {IOption} from 'models';
import {isFunction, updateSelectLabel} from 'utils';

interface ISelectAutocompleteProps {
  loading?: boolean;
  multiple: boolean;
  disabled?: boolean;
  options: IOption[];
  values: IOption[];
  placeholder?: string;
  noOptionsText?: string;
  withCheckboxRenderOption?: boolean;
  withFontWeightBold?: boolean;
  disableCloseOnSelect?: boolean;
  filterOptions?: (options: IOption[]) => IOption[];
  changeHandler: (option: IOption | IOption[]) => void;
  inputChangeHandler?: (value: string) => void;
  blurHandler?: () => void;
}

export function SelectAutocomplete({
  multiple,
  options,
  changeHandler,
  inputChangeHandler,
  values,
  placeholder,
  disabled = false,
  loading = false,
  blurHandler,
  noOptionsText,
  withCheckboxRenderOption = true,
  withFontWeightBold = false,
  disableCloseOnSelect = false,
  filterOptions,
}: ISelectAutocompleteProps) {
  const classes = useStyles({withFontWeightBold});

  const changeHandlerInner = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: any) => {
      changeHandler(value);
    },
    [changeHandler]
  );

  const getSingleSelectOption = useCallback((label: string) => {
    const {updatedLabel} = updateSelectLabel(label);

    return (
      <div style={{lineHeight: 1}} title={label}>
        {updatedLabel}
      </div>
    );
  }, []);

  const getMultiSelectOption = useCallback(
    (label: string, selected: boolean) => {
      const {updatedLabel} = updateSelectLabel(label);

      return (
        <Fragment>
          {withCheckboxRenderOption && <Checkbox checked={selected} />}
          {updatedLabel}
        </Fragment>
      );
    },
    [withCheckboxRenderOption]
  );

  const SingleOptionAutoComplete = useMemo(
    () => () => (
      <Autocomplete
        classes={classes}
        loading={loading}
        multiple={false}
        disabled={disabled}
        disableCloseOnSelect={disableCloseOnSelect}
        ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
        options={options}
        filterOptions={filterOptions} // use for async requests
        noOptionsText={noOptionsText}
        getOptionLabel={(option) => option.label}
        onChange={changeHandlerInner}
        onBlur={blurHandler}
        onInputChange={(event: any, value: string, reason: string) => {
          if (reason === 'input') {
            isFunction(inputChangeHandler) && inputChangeHandler(value);
          }
        }}
        renderInput={(params) => <Input {...params} placeholder={placeholder} withMargin={false} loading={loading} />}
        renderOption={(option: IOption, _) => getSingleSelectOption(option.label)}
      />
    ),
    [
      classes,
      loading,
      disabled,
      filterOptions,
      inputChangeHandler,
      noOptionsText,
      options,
      placeholder,
      blurHandler,
      changeHandlerInner,
      disableCloseOnSelect,
      getSingleSelectOption,
    ]
  );

  if (!multiple) {
    return <SingleOptionAutoComplete />;
  }

  return (
    <Autocomplete
      classes={classes}
      loading={loading}
      multiple={multiple}
      disabled={disabled}
      value={values}
      disableListWrap // If true, the list box in the popup will not wrap focus.
      disableCloseOnSelect={true} // applicable only for multi select dropdown
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      options={options} // list of options received by the filter
      noOptionsText={noOptionsText}
      getOptionLabel={(option) => option.label} // list of options received by the filter
      onChange={changeHandlerInner}
      onBlur={blurHandler}
      onInputChange={(event: any, value: string, reason: string) => {
        if (reason === 'input') {
          isFunction(inputChangeHandler) && inputChangeHandler(value);
        }
      }}
      renderInput={(params) => <Input {...params} placeholder={placeholder} withMargin={false} disabled={false} />}
      renderOption={(option: IOption, {selected}) => getMultiSelectOption(option.label, selected)}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
  tag: {
    margin: theme.spacing(1),

    '&:after': {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: 'absolute',
      backgroundColor: theme.palette.primary.main,
      zIndex: -1,
    },
  },
  inputRoot: ({withFontWeightBold}: {withFontWeightBold: boolean}) => {
    const muiAutocompleteInputFirstChildStyles: any = withFontWeightBold && {
      fontSize: 13,
      fontWeight: 600,
      color: theme.palette.grey[900],
    };

    return {
      '& .Mui-disabled': {
        color: theme.palette.grey[700],
        backgroundColor: colors.gray,
      },
      '&&[class*="MuiOutlinedInput-root"]': {
        padding: 3.3,
        '& .MuiAutocomplete-input:first-child': muiAutocompleteInputFirstChildStyles,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[200],
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[900],
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
      },
    };
  },
}));

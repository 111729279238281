import {RolesEnum} from 'permissions';

export const checkIfAreaAdmin = (hasPermission) => {
  const isAreaAdmin =
    hasPermission([RolesEnum.AreaAdmin]) &&
    !hasPermission([RolesEnum.USAAdmin]) &&
    !hasPermission([RolesEnum.SuperAdmin]);
  return isAreaAdmin;
};

export const checkIfSuperAdmin = (hasPermission) => {
  return hasPermission([RolesEnum.SuperAdmin]);
};

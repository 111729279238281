import React, {ChangeEvent, FC, Fragment, useCallback, useMemo, useRef} from 'react';
import {IconButton, makeStyles} from '@material-ui/core';
import {Button, Icon, Wrapper, Link, FileInput, Text} from 'components-lib';
import {importItemsMessages, Subtitle, useItemsImport} from 'admin';
import {useFormikContext} from 'formik';
import {FormNameEnum} from 'enums/form';
import {Loading} from 'components-lib';
import {heightThreeStepsActivity} from 'utils/layout';
import {useBeforeunload} from 'react-beforeunload';
import {Prompt} from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUploadFileProps {}

export const UploadFile: FC<IUploadFileProps> = () => {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const {values, setFieldValue, errors} = useFormikContext();
  const {loading, downloading, downloadTemplate} = useItemsImport();

  const file = values[FormNameEnum.templateImportFile];
  const error = errors[FormNameEnum.templateImportFile] as string;

  useBeforeunload(() => {
    if (file) {
      return importItemsMessages.warning;
    }
  });

  const openFileDialog = useCallback(() => fileInputRef.current.click(), []);

  const handleDownloadTemplate = useCallback(() => downloadTemplate(), [downloadTemplate]);

  const handleAddFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newFile = event.target.files[0];
      setFieldValue(FormNameEnum.templateImportFile, newFile);
    },
    [setFieldValue]
  );
  const handleDelete = useCallback(() => setFieldValue(FormNameEnum.templateImportFile, null), [setFieldValue]);

  const attachmentArea = useMemo(
    () => (
      <Wrapper className={classes.attachmentArea}>
        <Wrapper className={classes.fileIconContainer}>
          <Icon.FileIcon />
        </Wrapper>
        {!error && (
          <Text.Heading classes={{root: classes.fileName}} as="h4" color="inherit">
            {file?.name}
          </Text.Heading>
        )}
        {error && <Text.Error withBottomMargin={false}>{error}</Text.Error>}
        <IconButton disabled={loading} onClick={handleDelete}>
          <Icon.DeleteIcon color={loading ? 'disabled' : 'error'} />
        </IconButton>
      </Wrapper>
    ),
    [classes.fileName, classes.fileIconContainer, classes.attachmentArea, file, error, handleDelete, loading]
  );

  const DroppableArea = useMemo(
    () => () => {
      return (
        <Wrapper>
          <Prompt when={file} message={importItemsMessages.itemsImportWarning} />
          {file && <Wrapper>{attachmentArea}</Wrapper>}
          {!file && (
            <Wrapper className={classes.droppableArea}>
              <Icon.FolderIcon disabled={false} />
              <Wrapper className={classes.text}>Drag and drop a file here or</Wrapper>
              <Wrapper className={classes.buttonContainer}>
                {!error && (
                  <FileInput
                    name={FormNameEnum.templateImportFile}
                    inputRef={fileInputRef}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    clickHandler={handleAddFile}
                  />
                )}

                <Button.Secondary disabled={false} clickHandler={openFileDialog}>
                  Browse
                </Button.Secondary>
              </Wrapper>
              <Link clickHandler={handleDownloadTemplate}>Download Template</Link>
            </Wrapper>
          )}
        </Wrapper>
      );
    },
    [
      classes.droppableArea,
      classes.buttonContainer,
      classes.text,
      openFileDialog,
      handleDownloadTemplate,
      handleAddFile,
      attachmentArea,
      file,
      error,
    ]
  );

  const content = useMemo(() => {
    return (
      <Fragment>
        <Subtitle withColor={false} as="h3" color="textPrimary" title="Upload file" />
        <Wrapper className={classes.content}>{downloading ? <Loading /> : <DroppableArea />}</Wrapper>
      </Fragment>
    );
  }, [classes.content, DroppableArea, downloading]);

  return <Fragment>{content}</Fragment>;
};

const useStyles = makeStyles((theme) => ({
  content: () => {
    return {
      height: heightThreeStepsActivity,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
  },
  droppableArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  buttonContainer: {
    padding: `${theme.spacing(0)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  text: {
    padding: theme.spacing(1),
  },
  attachmentArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: theme.spacing(1) + theme.spacing(2),
  },
  fileName: {
    maxWidth: 300,
    fontWeight: 700,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {IFetchPoolItemsPayload, IGetItemsApiResponse} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {isFunction} from 'utils';
import {prefix} from './config';

export const fetchMatrixRelatedPoolItemsThunk: any = createAsyncThunk<IGetItemsApiResponse, IFetchPoolItemsPayload>(
  `${prefix} fetchMatrixRelatedPoolItems`,
  async (
    {defaultAnswerStackId, pageNumber, builderFilter, searchText = '', setSubmitting},
    {rejectWithValue, dispatch}
  ) => {
    try {
      const {parsedBody} = await instrumentsApi.getMatrixRelatedItems(
        defaultAnswerStackId,
        pageNumber,
        builderFilter,
        searchText
      );

      isFunction(setSubmitting) && setSubmitting(false);
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody || err.message));
      isFunction(setSubmitting) && setSubmitting(false);
      return rejectWithValue({error: err});
    }
  }
);

import {Box, makeStyles} from '@material-ui/core';
import {Button, Column, Modal, Row, Text} from 'components-lib';
import {IWithModal} from 'models';
import React, {useCallback} from 'react';

interface IModalEditClassRelatedInstrumentProps extends IWithModal {
  confirmHandler: () => void;
}

export function ModalEditClassRelatedInstrument({
  isOpen,
  setIsOpen,
  confirmHandler,
}: IModalEditClassRelatedInstrumentProps) {
  const classes = useStyles();
  const closeHandler = useCallback(() => setIsOpen(false), [setIsOpen]);
  const confirmActionHandler = useCallback(() => {
    confirmHandler();
    setIsOpen(false);
  }, [confirmHandler, setIsOpen]);

  return (
    <Modal isOpen={isOpen} handleClose={closeHandler} title="Edit Instrument" size="medium">
      <Column>
        <Row align="flex-start" justify="flex-start" classes={{root: classes.textRow}}>
          <Text.Paragraph variant="body1">
            If you edit the instrument the status will automatically become draft and it won’t be available for users.
          </Text.Paragraph>
          <Box marginTop={2}>
            <Text.Paragraph>Are you sure that you want to customize this instrument?</Text.Paragraph>
          </Box>
        </Row>
      </Column>
      <div>
        <Button.Secondary clickHandler={closeHandler} size="small">
          No
        </Button.Secondary>
        <Button.Primary size="small" clickHandler={confirmActionHandler}>
          Yes
        </Button.Primary>
      </div>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  textRow: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

import {Accordion, Column, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {fetchInstrumentTypesThunk, filtersInstrumentTypesEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IOption, IWithDisabled} from 'models';

export function InstrumentTypeFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const types = useSelector(filtersInstrumentTypesEntitiesCollectionSelector);

  useEffect(() => {
    if (!types.length) {
      dispatch(fetchInstrumentTypesThunk());
    }
  }, [types.length, dispatch]);

  const options: IOption[] = useMemo(() => {
    return types.map((type) => ({
      value: type.id,
      label: type.displayText,
    }));
  }, [types]);

  return (
    <Accordion heading="Type">
      <Column>
        {types.length ? (
          <Form.SelectAutocomplete name={FormNameEnum.instrumentType} options={options} multiple disabled={disabled} />
        ) : (
          <Text.Caption>No Types</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
}

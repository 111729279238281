import {FormNameEnum, InputTypeEnum} from 'enums/form';
import {AddIntegrationLabelEnum} from '../../enums';

export const getAddIntegrationMicrosoftTeamsList = () => [
  {
    label: AddIntegrationLabelEnum.ClientID,
    name: FormNameEnum.clientId,
    options: {
      withMargin: false,
      withIcon: false,
    },
  },
  {
    label: AddIntegrationLabelEnum.ClientSecret,
    name: FormNameEnum.clientSecret,
    options: {
      withMargin: false,
      withIcon: false,
    },
    type: InputTypeEnum.Password,
  },
  {
    label: AddIntegrationLabelEnum.AuthorityUrl,
    name: FormNameEnum.authorityUrl,
    options: {
      withMargin: false,
      withIcon: false,
    },
  },
];

import {makeStyles} from '@material-ui/core';
import {IComponentWithChildren} from 'models';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISectionContainerProps extends IComponentWithChildren {}

export function SectionContainer({children}: ISectionContainerProps) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

import {SortingOrderEnum, EntitiesPerPageEnum} from 'enums';
import {EntityStatusEnum, QuestionAnswersTypeEnum} from 'enums/instruments';
import {ItemFilterNamesEnum, ItemsOrderingEnum} from '../enums';
import {IItemsStoreState} from './IItemsStoreState';

const itemDefault = {
  id: null,
  questionId: null,
  text: '',
  questionTextHtml: '',
  isApproved: false,
  status: EntityStatusEnum.draft,
  canEditQuestionText: false,
  questionOptionsTypeId: QuestionAnswersTypeEnum.questionAnswerEnum,
  questionOptionsType: {
    id: -1,
    displayText: '',
    name: '',
  },
  elements: [],
  answerStacks: [],
  defaultAnswerStacks: [],
  customStacks: [],
  isDeleted: false,
  createdOn: '',
  createdBy: '',
  creatorArea: '',
  metaData: {
    administration: null,
    competencies: [],
    concepts: [],
    domain: null,
    evaluationTypes: [],
    instruments: [],
    pillars: [],
    schoolLevels: [],
    programs: [],
    category: '',
  },
};

const filterDefault = {
  [ItemFilterNamesEnum.SEARCH_BY_NAME]: '',
  [ItemFilterNamesEnum.ADMINISTRATIONS]: [],
  [ItemFilterNamesEnum.PILLARS]: [],
  [ItemFilterNamesEnum.CONCEPTS]: [],
  [ItemFilterNamesEnum.DOMAINS]: [],
  [ItemFilterNamesEnum.COMPETENCIES]: [],
  [ItemFilterNamesEnum.CATEGORY]: '',
  [ItemFilterNamesEnum.LEARNING_OBJECTIVE]: '',
  [ItemFilterNamesEnum.EVALUATION_TYPES]: [],
  [ItemFilterNamesEnum.ITEM_TYPES]: [],
  [ItemFilterNamesEnum.PROGRAMS]: [],
  [ItemFilterNamesEnum.SCHOOL_LEVELS]: [],
  [ItemFilterNamesEnum.AREAS]: [],
  [ItemFilterNamesEnum.ITEM_STATUSES]: null,
  [ItemFilterNamesEnum.SORT_BY]: null,
  [ItemFilterNamesEnum.ORDER_BY]: null,
};

const pageDefault = {
  loading: false,
  publishingStack: false,
  saving: false,
  loadingPageSection: false,
  deleting: false,
  promotingStack: false,
};

export const itemsInitialState: IItemsStoreState = {
  item: itemDefault,
  itemList: {
    result: [],
    entities: {
      items: {},
    },
  },
  itemQuestions: {
    result: [],
    entities: {
      questions: {},
    },
  },
  page: pageDefault,
  sortOrder: {
    order: ItemsOrderingEnum.Name,
    sort: SortingOrderEnum.ASC,
  },
  filter: filterDefault,
  pagination: {
    currentPage: 1,
    totalCount: 0,
    perPage: EntitiesPerPageEnum.FIFTY,
  },
};

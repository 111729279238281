import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Button, Container, Logo, Row, Wrapper} from 'components-lib';
import {Form} from 'forms';
import {uploadMessages} from 'admin/upload/utils';
import {Prompt} from 'react-router-dom';

interface IUploadFooterProps {
  showPrompt: boolean;
  disableSubmit: boolean;
  redirectCallback: () => void;
}

export const UploadFooter: FC<IUploadFooterProps> = ({showPrompt, redirectCallback, disableSubmit}) => {
  const classes = useStyles();

  return (
    <Container disableGutters classes={{root: classes.root}}>
      <Prompt when={showPrompt} message={uploadMessages.warning} />
      <Row align="center" justify="space-between">
        <Logo.CititizensPoweredBy />
        <Wrapper>
          <Button.Secondary size="large" clickHandler={redirectCallback}>
            Cancel
          </Button.Secondary>
          <Form.ButtonSubmit disabled={disableSubmit} size="large" text="Upload" />
        </Wrapper>
      </Row>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
}));

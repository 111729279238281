import {EntitiesPerPageEnum, SortingOrderEnum} from 'enums';
import {
  ProgramClassesPaperAssessmentsFilterNamesEnum,
  ProgramClassPaperAssessmentsOrderingEnum,
} from 'enums/paper-assessments';
import {IPagination} from 'models';
import {IProgramClassesPaperAssessmentsFilter} from 'models/programs';
import {IProgramClassesPaperAssessmentsState} from './IProgramClassesPaperAssessmentsState';

const filterDefault: IProgramClassesPaperAssessmentsFilter = {
  [ProgramClassesPaperAssessmentsFilterNamesEnum.SEARCH]: '',
};

const paginationDefault: IPagination = {
  currentPage: 1,
  totalCount: 0,
  perPage: EntitiesPerPageEnum.FIFTY,
};

export const programClassesPaperAssessmentsInitialState: IProgramClassesPaperAssessmentsState = {
  page: {
    loading: false,
  },
  upload: {
    loading: false,
  },
  paperAssessmentList: {
    result: [],
    entities: {
      items: {},
    },
  },
  sortOrder: {
    order: ProgramClassPaperAssessmentsOrderingEnum.Created,
    sort: SortingOrderEnum.DESC,
  },
  filter: filterDefault,
  pagination: paginationDefault,
};

import {StylesProvider, ThemeProvider} from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'react-moment';
import {Provider} from 'react-redux';
import 'resources/fonts/OpenSans-Regular.ttf';
import 'resources/fonts/OpenSans-SemiBold.ttf';
import {store} from 'store';
import {muiTheme} from 'styles';
import {dateFormat} from 'utils';
import App from './App';

Moment.globalFormat = dateFormat;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <App />
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

import {Switch} from 'components-lib';
import {FieldProps} from 'formik';
import React from 'react';
import {FastField} from './Field';

interface ISwitchProps {
  name: string;
  value?: boolean;
  label?: string;
  disabled?: boolean;
}

export function FormSwitch({name, label, disabled = false}: ISwitchProps) {
  return <FastField name={name} label={label} disabled={disabled} Component={SwitchDecorated} />;
}

interface ISwitchDecoratedProps extends FieldProps, ISwitchProps {}

function SwitchDecorated({field: {name, onChange}, form: {values}, label, disabled}: ISwitchDecoratedProps) {
  const value = values[name];
  return <Switch name={name} label={label} disabled={disabled} checked={value} changeHandler={onChange} />;
}

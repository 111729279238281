import React, {FC, RefObject} from 'react';
import {makeStyles} from '@material-ui/core';

interface IFileInputProps {
  name: string;
  inputRef: RefObject<HTMLInputElement>;
  type?: string;
  accept: string;
  clickHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FileInput: FC<IFileInputProps> = ({name, inputRef, type = 'file', accept, clickHandler}) => {
  const {root} = useStyles();

  return <input name={name} className={root} ref={inputRef} type={type} onChange={clickHandler} accept={accept} />;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'none',
  },
}));

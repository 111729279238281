import {IBuilderDragItem} from 'admin/library/models';
import {IDropResult} from 'dragAndDrop';
import {DragLayerMonitor, DragSourceMonitor, useDrag} from 'react-dnd';

export function useBuilderItemDrag(itemDrag: IBuilderDragItem, dropHandler?: (item: IBuilderDragItem) => void) {
  const [{isDragging}, drag] = useDrag({
    item: itemDrag,
    end(target: any | undefined, monitor: DragSourceMonitor) {
      const dropResult: IDropResult = monitor.getDropResult();
      if (itemDrag && dropResult) {
        dropHandler && dropHandler(target);
      }
    },
    collect: (monitor: DragLayerMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return {drag, isDragging};
}

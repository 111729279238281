import React, {FC, Fragment, useCallback} from 'react';
import {TableRow as MUITableRow, TableCell as MUITableCell, makeStyles} from '@material-ui/core';
import classnames from 'classnames';
import {MuiTableCellEnum} from 'enums/common';
import {IColumn} from 'models';

interface ITableRowProps {
  role: string;
  tabIndex: number;
  columns: IColumn[];
  row: any;
}

export const TableRow: FC<ITableRowProps> = ({role, tabIndex, columns, row}) => {
  const {cell, textError} = useStyles();

  const calculateCellValue = useCallback(
    (column, value) => (column.format && typeof value === 'number' ? column.format(value) : value),
    []
  );

  return (
    <MUITableRow role={role} tabIndex={tabIndex}>
      {columns.map((column) => {
        const value = row[column[MuiTableCellEnum.Id]] as number;
        const errorMsg = row[MuiTableCellEnum.Error];
        const hasError = column[MuiTableCellEnum.HasError];
        const className = column[MuiTableCellEnum.ClassName];

        return (
          <Fragment key={column.id}>
            <MUITableCell
              align={column.align}
              className={classnames(cell, errorMsg && hasError && textError, className)}
            >
              {calculateCellValue(column, value)}
            </MUITableCell>
          </Fragment>
        );
      })}
    </MUITableRow>
  );
};

const useStyles = makeStyles((theme) => ({
  cell: () => ({
    border: 0,
  }),
  textError: {
    color: theme.palette.error.main,
  },
}));

import {createAsyncThunk} from '@reduxjs/toolkit';
import {itemsApi} from 'api';
import {IUpdateAnswerStackPayload, IUpdateItemResponse, IWithNavigationPush} from 'models';
import {prefix} from './config';
import {itemsMessages} from '../../utils';
import {toastNotificationManager} from 'toast-notifications';

interface IUpdatedAnswerStackPayload extends IUpdateAnswerStackPayload, IWithNavigationPush {}

export const updateAnswerStackThunk: any = createAsyncThunk<IUpdateItemResponse, IUpdatedAnswerStackPayload>(
  `${prefix} updateAnswerStack`,
  async (payload: IUpdateAnswerStackPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await itemsApi.updateAnswerStack(payload);
      dispatch(toastNotificationManager.createSuccessToastAction(itemsMessages.updateSuccess));
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

/* eslint-disable react/display-name */
import {makeStyles} from '@material-ui/core';
import React, {ReactNode, useEffect, useRef} from 'react';
import {PaperNodeTypeEnum} from '../../enums/PaperNodeTypeEnum';
import {IPaperQuestion} from '../../models/IPaperQuestion';
import {ReactComponent as SVGCheckbox} from '../../resources/checkbox.svg';

interface IProps {
  text?: string;
  id: string;
  columns?: Array<any>;
  rows?: Array<any>;
  afterMount?: (questionInfo: IPaperQuestion) => void;
}

const MatrixQuestion = ({text, id, columns, rows, afterMount}: IProps) => {
  const classes = useStyles();
  const mainRef = useRef(null);
  const textRowRef = useRef(null);
  const headerRowRefs = useRef([]);
  const questionRowRefs = useRef([]);

  useEffect(() => {
    if (!afterMount) return;
    afterMount({
      type: PaperNodeTypeEnum.matrix,
      heights: {
        total: mainRef?.current.clientHeight,
        textArea: textRowRef?.current.clientHeight,
        headerArea: headerRowRefs?.current.reduce((height, element) => element.clientHeight + height, 0),
        questionAreas: questionRowRefs?.current.map((element) => element.clientHeight),
      },
      text: text,
      id: id,
      columns: columns,
      rows: rows,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MatrixQuestionTextCell = ({children = undefined, colSpan = undefined}) => (
    <td className={classes.matrixQuestionText} colSpan={colSpan}>
      {children}
    </td>
  );
  const MatrixQuestionLeftId = ({children = undefined}) => <td className={classes.matrixQuestionLeftId}>{children}</td>;
  const MatrixQuestionTopRow = ({children = undefined}) => (
    <tr ref={(ref) => (textRowRef.current = ref)} className={classes.matrixQuestionTopRow}>
      {children}
    </tr>
  );

  const MatrixAnswerLeftIdCell = ({children = undefined}) => <td className={classes.matrixAnswerLeftId}>{children}</td>;

  const MatrixQuestionRow = ({children = undefined}) => (
    <tr ref={(ref) => questionRowRefs.current.push(ref)} className={classes.matrixQuestionRow}>
      {children}
    </tr>
  );
  const MatrixQuestionCell = ({children = undefined}) => <td className={classes.matrixQuestionCell}>{children}</td>;

  const MatrixHeaderRow = ({children = undefined}) => (
    <tr ref={(ref) => headerRowRefs.current.push(ref)} className={classes.matrixHeaderRow}>
      {children}
    </tr>
  );
  const MatrixScaleIDCell = ({children = undefined}) => <td className={classes.matrixScaleIdCell}>{children}</td>;
  const MatrixEmptyIDCell = ({children = undefined}) => <td className={classes.matrixEmptyIdCell}>{children}</td>;
  const MatrixScaleTextCell = ({children = undefined}) => <td className={classes.matrixScaleTextCell}>{children}</td>;
  const MatrixScaleCheckboxCell = ({children = undefined}) => (
    <td className={classes.matrixScaleCheckboxCell}>{children}</td>
  );

  const colspan = columns?.length + 1;

  const topRow =
    text && text.length > 0 ? (
      <MatrixQuestionTopRow>
        <MatrixQuestionLeftId>{id}</MatrixQuestionLeftId>
        <MatrixQuestionTextCell colSpan={colspan}>{text}</MatrixQuestionTextCell>
      </MatrixQuestionTopRow>
    ) : null;

  // create header
  const headerIdCells = columns?.map((col) => {
    return <MatrixScaleIDCell key={col.value}>{col.value}</MatrixScaleIDCell>;
  });

  const headerTextCols = columns?.map((col) => {
    return <MatrixScaleTextCell key={col.value}>{col.text}</MatrixScaleTextCell>;
  });

  const headerRows = (
    <>
      <MatrixHeaderRow>
        <MatrixEmptyIDCell></MatrixEmptyIDCell> {/* placeholder for the left id column */}
        <MatrixScaleTextCell></MatrixScaleTextCell> {/* placeholder for the answer column */}
        {headerIdCells}
      </MatrixHeaderRow>
      <MatrixHeaderRow>
        <MatrixEmptyIDCell></MatrixEmptyIDCell> {/* placeholder for the left id column */}
        <MatrixScaleTextCell></MatrixScaleTextCell> {/* For the answer column */}
        {headerTextCols}
      </MatrixHeaderRow>
    </>
  );

  // create answers
  const answerRows = rows?.map((row) => {
    const tds = new Array<ReactNode>();
    // eslint-disable-next-line array-callback-return
    columns?.map((col) => {
      tds.push(
        <MatrixScaleCheckboxCell key={col.value}>
          <SVGCheckbox className={classes.svgCheckbox} />
        </MatrixScaleCheckboxCell>
      );
    });

    return (
      <MatrixQuestionRow key={row.questionId}>
        <MatrixAnswerLeftIdCell>{row.questionId}</MatrixAnswerLeftIdCell>
        <MatrixQuestionCell>{row.text}</MatrixQuestionCell>
        {tds}
      </MatrixQuestionRow>
    );
  });

  return (
    <div className={classes.matrixQuestionRoot} ref={mainRef}>
      <table className={classes.matrixQuestionTable}>
        <tbody>
          {topRow}
          {headerRows}
          {answerRows}
        </tbody>
      </table>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  matrixQuestionRoot: {
    padding: '0.75em 0',
    pageBreakInside: 'avoid',
  },
  matrixQuestionTable: {
    width: '100%',
    textAlign: 'left',
    borderCollapse: 'collapse',
  },
  matrixQuestionText: {
    fontWeight: 'bold',
    textAlign: 'left',
    paddingLeft: '0.5em',
  },
  matrixQuestionLeftId: {
    minWidth: '5%',
    width: '6%',
    '&:before': {
      content: '"#"',
    },
    '&:after': {
      content: '"#"',
    },
    verticalAlign: 'middle',
    paddingRight: '1em',
    fontWeight: 'normal',
    fontSize: '75%',
    textAlign: 'right',
    borderRight: 'solid lightgray 1px',
  },
  matrixQuestionTopRow: {
    borderBottom: 'solid lightgray 1px',
    minHeight: '2.5em',
  },
  matrixAnswerLeftId: {
    '&:before': {
      content: '"*"',
    },
    '&:after': {
      content: '"*"',
    },
    verticalAlign: 'middle',
    paddingRight: '1em',
    fontWeight: 'normal',
    fontSize: '75%',
    textAlign: 'right',
    borderRight: 'solid lightgray 1px',
  },
  matrixQuestionRow: {
    height: '3em',
    '&:nth-child(even)': {
      backgroundColor: 'whitesmoke',
    },
  },
  matrixQuestionCell: {
    verticalAlign: 'middle',
    textAlign: 'left',
    width: '50%',
    padding: '0.25em 0.5em',
  },
  matrixHeaderRow: {
    border: 'solid lightgray 1px',
  },
  matrixScaleIdCell: {
    '&:before': {
      content: '"+"',
    },
    '&:after': {
      content: '"+"',
    },
    fontWeight: 'normal',
    fontSize: '75%',
    textAlign: 'center',
    border: 'solid lightgray 1px',
  },
  matrixEmptyIdCell: {
    border: 'none',
  },
  matrixScaleTextCell: {
    border: 'solid lightgray 1px',
    verticalAlign: 'bottom',
    textAlign: 'center',
    padding: '0.25em',
  },
  matrixScaleCheckboxCell: {
    verticalAlign: 'middle',
    textAlign: 'center',
  },
  svgCheckbox: {
    fill: 'gray',
    opacity: 0.8,
    verticalAlign: 'middle',
  },
}));

export default MatrixQuestion;

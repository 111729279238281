import React from 'react';
import classnames from 'classnames';
import {IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';

import {IComponentWithClassName} from 'models';

interface IButtonBackProps extends IComponentWithClassName {
  clickHandler: () => void;
  disabled?: boolean;
}

export const ButtonBack = ({clickHandler, className, disabled = false}: IButtonBackProps) => {
  const {root} = useStyles();
  return (
    <IconButton disabled={disabled} onClick={clickHandler} className={classnames(root, className)}>
      <KeyboardBackspaceRoundedIcon />
    </IconButton>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

import {IColumn} from 'models';

export const getGoogleSitesListColumns = (): IColumn[] => [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    minWidth: 300,
  },
  {
    id: 'lastModifiedTime',
    label: 'Last Modified Date (UTC)',
    align: 'left',
  },
  {
    id: 'actions',
    label: '',
    align: 'right',
  },
];

import {createSlice} from '@reduxjs/toolkit';
import {fileDetailsReducer} from './fileDetails.reducer';
import {fileDetailsExtraReducer} from './fileDetailsExtra.reducer';
import {fileDetailsInitialState} from './fileDetails.initialState';

export const fileDetailsSlice = createSlice({
  name: 'fileDetails',
  initialState: fileDetailsInitialState,
  reducers: fileDetailsReducer,
  extraReducers: fileDetailsExtraReducer,
});

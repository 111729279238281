import React, {useCallback} from 'react';
import {SortByDropdown} from 'admin/components';
import {useSelector} from 'react-redux';
import {instrumentsAllSortOrderSelector, instrumentsActions as actions} from 'admin/library/instruments/store';
import {useAppDispatch} from 'hooks';
import {IInstrumentsAllSortOrder} from 'models';
import {getSortInstrumentMenuItemsList} from 'admin/library/instruments/utils';
import {instrumentAllOrderingMap} from 'admin/library/instruments/all/utils';

export function InstrumentsAllSortBy() {
  const dispatch = useAppDispatch();

  const sortOrder = useSelector(instrumentsAllSortOrderSelector);

  const handleItemClick = useCallback(
    (sortOrder: IInstrumentsAllSortOrder) => {
      dispatch(actions.setSortOrderInstrumentsAll(sortOrder));
    },
    [dispatch]
  );

  return (
    <SortByDropdown
      label="Sort By: "
      handleItemClick={handleItemClick}
      getMenuItemsList={getSortInstrumentMenuItemsList}
      sortOrder={sortOrder}
      map={instrumentAllOrderingMap}
    />
  );
}

import React, {Fragment, useMemo, ReactNode} from 'react';
import {makeStyles} from '@material-ui/core';

import {QuestionAnswersTypeEnum} from 'enums';
import {IAnswer, IQuestionAnswerStackDetails} from 'models';
import {borderGeneral} from 'styles';
import {Column, Icon, Row, Text} from 'components-lib';
import {AnswerBuilderRating, AnswerOpenEnded} from '..';
import {Answer} from '../Answer/Answer';
import {AnswerHeader} from './AnswerHeader';
import {AnswerComponentWrapper} from './AnswerComponentWrapper';
interface IAnswersViewProps {
  answers: IAnswer[];
  draggable?: boolean;
  isDragging?: boolean;
  disabled?: boolean;
  questionType?: QuestionAnswersTypeEnum;
  details?: IQuestionAnswerStackDetails;
  headingActions?: ReactNode;
  hasMatchedMetadata?: boolean;
}

export function AnswersView({
  answers = [],
  draggable = false,
  isDragging = false,
  disabled = false,
  questionType,
  details,
  headingActions,
  hasMatchedMetadata = false,
}: IAnswersViewProps) {
  const classes = useStyles({draggable, disabled, isDragging, hasMatchedMetadata});
  const showRating = questionType === QuestionAnswersTypeEnum.rating;
  const showOtherTypes = questionType !== (QuestionAnswersTypeEnum.rating || QuestionAnswersTypeEnum.text);

  // Items without answers
  const showNoChoices = answers?.length < 1 && questionType !== QuestionAnswersTypeEnum.text && !!questionType;
  const showOpenEnded = questionType !== QuestionAnswersTypeEnum.text || !!questionType;

  const NoChoices = useMemo(
    () => () => {
      return (
        <div className={classes.noChoices}>
          <Text.Caption>No choices loaded.</Text.Caption>
        </div>
      );
    },
    [classes]
  );

  const AnswerComponent = useMemo(
    () => () => {
      return (
        <div>
          {showOtherTypes && answers.map((answer, idx) => <Answer key={idx} answer={answer} />)}
          {showRating && <AnswerBuilderRating answers={answers} withSmallButtons />}
        </div>
      );
    },
    [answers, showOtherTypes, showRating]
  );

  return (
    <Row justify="flex-start" classes={{root: classes.row}}>
      <Column sm={draggable ? 11 : 12} md={draggable ? 11 : 12} lg={draggable ? 11 : 12} xl={draggable ? 11 : 12}>
        <AnswerHeader creatorArea={details?.creatorArea} headingActions={headingActions} />
        <AnswerComponentWrapper>
          {answers?.length ? (
            <AnswerComponent />
          ) : (
            <Fragment>
              {showNoChoices && <NoChoices />}
              {showOpenEnded && <AnswerOpenEnded />}
            </Fragment>
          )}
        </AnswerComponentWrapper>
      </Column>
      {draggable && (
        <Column sm={1} md={1} lg={1} xl={1}>
          <Icon.DragIndicatorIcon />
        </Column>
      )}
    </Row>
  );
}

type TStyleProps = Pick<IAnswersViewProps, 'draggable' | 'disabled'> & {
  isDragging: boolean;
  hasMatchedMetadata: boolean;
};

const useStyles = makeStyles((theme) => ({
  row: ({draggable, disabled, isDragging, hasMatchedMetadata}: TStyleProps) => {
    const base: any = {
      cursor: draggable ? 'grab' : 'pointer',
      marginBottom: draggable && !disabled ? 0 : theme.spacing(2),
      opacity: disabled || isDragging ? 0.5 : 1,
      pointerEvents: disabled ? 'none' : 'all',
    };

    if (disabled) {
      return {
        ...borderGeneral,
        ...base,
      };
    }

    if (draggable) {
      if (hasMatchedMetadata) {
        return {...borderGeneral, borderColor: theme.palette.secondary.main, ...base};
      }

      return {
        ...borderGeneral,
        ...base,
        borderStyle: isDragging ? 'dashed' : 'solid',
      };
    }

    return base;
  },
  noChoices: {
    padding: theme.spacing(3),
  },
  openEnded: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  ratingAnswer: {
    height: theme.spacing(5),
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
}));

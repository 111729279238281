import React from 'react';
import {ReactComponent as PermissionsRequired} from 'resources/images/permissionsRequired.svg';
import {makeStyles} from '@material-ui/core';
import {Column, Container, Text} from 'components-lib';
import {layoutConfig} from 'layout';

export function NoPermissionsPage() {
  const classes = useStyles();
  return (
    <Container classes={{root: classes.container}}>
      <Column className={classes.column}>
        <PermissionsRequired className={classes.withMargin} />
        <Text.Heading as="h1">Permissions Needed</Text.Heading>
        <Text.Paragraph>The page requires access level that you do not have!</Text.Paragraph>
      </Column>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    height: `calc(100vh - ${layoutConfig.scrollableAreaWithoutSecondaryNavigationSubtrahend}px)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  column: {
    alignItems: 'center',
    maxWidth: 510,
  },
  withMargin: {
    marginBottom: theme.spacing(5),
  },
}));

import React, {useMemo} from 'react';
import {SelectionEnum} from 'filters-selections';
import {ResourceListColumn} from 'admin/resources';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';

export const LearningPathDetailsFirstCol = () => {
  const {hasPermission} = usePermissions();
  const shouldDisableList = !hasPermission(USAAndSuperAdminRoles);

  const list = useMemo(
    () => [
      SelectionEnum.LearningPathName,
      SelectionEnum.FutureProgramYearSingle,
      SelectionEnum.PrimaryPillar,
      SelectionEnum.LanguagesSingle,
      SelectionEnum.KeyTermsInput,
      SelectionEnum.BriefDescriptionTextArea,
    ],
    []
  );

  return <ResourceListColumn sm={6} md={6} lg={6} xl={4} list={list} disableAll={shouldDisableList} />;
};

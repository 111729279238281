import {makeStyles} from '@material-ui/core';
import {Bullet, Icon, Row, Text} from 'components-lib';
import {IAnswer} from 'models';
import React from 'react';

interface IAnswerProps {
  answer: IAnswer;
}

export function Answer({answer: {isCorrect, text}}: IAnswerProps) {
  const classes = useStyles();
  return (
    <Row justify="space-between">
      <div className={classes.answer}>
        <div className={classes.icon}>{isCorrect ? <Icon.CheckIcon /> : ''}</div>
        <Bullet />
        <Text.Caption>{text}</Text.Caption>
      </div>
    </Row>
  );
}

const useStyles = makeStyles((theme) => ({
  answer: {
    display: 'flex',
    flex: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: theme.spacing(4),
    minHeight: theme.spacing(4),
    marginRight: theme.spacing(3),
  },
}));

import {SortingOrderEnum} from 'enums';
import {IntegrationDropdownNameEnum, IntegrationsOrderingEnum} from 'admin/integrations';
import {IIntegrationsAllSortOrder} from 'models';

export const getMenuItemsList = (
  itemClickHandler: () => void,
  handleItemClick: (handleItemSortObj: IIntegrationsAllSortOrder) => void
) => {
  const onClick = (
    itemClickHandler: () => void,
    handleItemClick: (handleItemSortObj: IIntegrationsAllSortOrder) => void,
    handleItemSortObj: IIntegrationsAllSortOrder
  ) => {
    return () => {
      itemClickHandler();
      handleItemClick(handleItemSortObj);
    };
  };

  return [
    {
      name: IntegrationDropdownNameEnum.NAME_ASC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: IntegrationsOrderingEnum.Name,
        sort: SortingOrderEnum.ASC,
      }),
    },
    {
      name: IntegrationDropdownNameEnum.NAME_DESC,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: IntegrationsOrderingEnum.Name,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: IntegrationDropdownNameEnum.CREATED_NEWEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: IntegrationsOrderingEnum.Created,
        sort: SortingOrderEnum.DESC,
      }),
    },
    {
      name: IntegrationDropdownNameEnum.CREATED_OLDEST,
      clickHandler: onClick(itemClickHandler, handleItemClick, {
        order: IntegrationsOrderingEnum.Created,
        sort: SortingOrderEnum.ASC,
      }),
    },
  ];
};

import React, {SyntheticEvent} from 'react';
import {Avatar as AvatarMui, makeStyles} from '@material-ui/core';
import {IComponentWithChildren} from 'models';
import {isFunction} from 'utils';

interface IAvatarProps extends IComponentWithChildren {
  clickHandler?: (event: SyntheticEvent) => void;
  avatarUrl?: string;
  size?: 'small' | 'large';
  variant?: 'circle' | 'rounded' | 'square';
}

export function Avatar({children, clickHandler, avatarUrl, size = 'large'}: IAvatarProps) {
  const classes = useStyles({size, clickHandler});
  return (
    <AvatarMui src={avatarUrl} onClick={clickHandler} classes={{root: classes.root}}>
      {children}
    </AvatarMui>
  );
}

const useStyles = makeStyles((theme) => ({
  root: ({size, clickHandler}: Pick<IAvatarProps, 'size' | 'clickHandler'>) => {
    const isSmall = size === 'small';
    const isClickable = isFunction(clickHandler);

    let additionalStyles = {};

    if (isSmall) {
      additionalStyles = {
        width: 24,
        height: 24,
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
        fontSize: 13,
      };
    }

    return {
      cursor: isClickable ? 'pointer' : 'auto',
      background: theme.palette.primary.main,
      ...additionalStyles,
    };
  },
}));

import {createAsyncThunk} from '@reduxjs/toolkit';
import {pamApi} from 'api';
import {IUpdateWebLinkResourceApiResponse, IUpdateWebLinkResourcePayload} from 'models/resources';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const updateWebLinkResourceThunk: any = createAsyncThunk<
  IUpdateWebLinkResourceApiResponse,
  IUpdateWebLinkResourcePayload
>(`${prefix} updateWebLinkResource`, async (payload: IUpdateWebLinkResourcePayload, {dispatch, rejectWithValue}) => {
  try {
    const {parsedBody} = await pamApi.updateWebLinkResource(payload);
    dispatch(toastNotificationManager.createSuccessToastAction('Web link was updated successfully!'));
    return parsedBody;
  } catch (error) {
    dispatch(toastNotificationManager.createErrorToastAction(error.response.data.messages.join('')));
    return rejectWithValue({error});
  }
});

import React, {ReactNode, useCallback, useState, useEffect} from 'react';
import {Accordion as AccordionMui, AccordionDetails, AccordionSummary, makeStyles} from '@material-ui/core';
import classnames from 'classnames';
import {Icon, Row, Text} from 'components-lib';
import {IComponentWithChildren, IComponentWithClassName} from 'models';
import {borderGeneral} from 'styles';
interface IAccordionProps extends IComponentWithChildren, IComponentWithClassName {
  heading: string | ReactNode;
  headingAs?: 'h2' | 'h3' | 'h4';
  headingActionLeft?: ReactNode;
  headingActionRight?: ReactNode;
  withBorder?: boolean;
  disabled?: boolean;
  draggable?: boolean;
  initialIsExpanded?: boolean;
  withGrayBackground?: boolean;
}

export function Accordion({
  heading,
  children,
  className,
  headingAs = 'h4',
  headingActionLeft,
  headingActionRight,
  withBorder = false,
  disabled = false,
  initialIsExpanded = false,
  withGrayBackground = false,
  draggable = false,
}: IAccordionProps) {
  const [isExpanded, setIsExpanded] = useState(initialIsExpanded);
  const classes = useStyles({isExpanded, withBorder, withGrayBackground, draggable});

  const clickHandler = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const isHeadingString = typeof heading === 'string';

  useEffect(() => {
    setIsExpanded(initialIsExpanded);
  }, [initialIsExpanded]);

  return (
    <div className={classnames(classes.root, className)}>
      <AccordionMui
        expanded={isExpanded}
        classes={{root: classes.rootOverride, expanded: classes.expandedOverride, disabled: classes.disabled}}
        disabled={disabled}
      >
        <AccordionSummary
          expandIcon={isExpanded ? <Icon.MinimizeIcon /> : <Icon.AddIcon />}
          onClick={clickHandler}
          classes={{root: classes.rootOverride, expanded: classes.expandedOverride}}
        >
          <Row justify="flex-start">
            {headingActionLeft && headingActionLeft}
            {isHeadingString ? (
              <Text.Heading as={headingAs} classes={{root: classes.heading}}>
                {heading}
              </Text.Heading>
            ) : (
              heading
            )}
            {headingActionRight && <div className={classes.headingActionRight}>{headingActionRight}</div>}
          </Row>
        </AccordionSummary>
        {isExpanded && <AccordionDetails>{children}</AccordionDetails>}
      </AccordionMui>
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  const minHeight = theme.spacing(2) + theme.spacing(3);

  return {
    root: ({withBorder, withGrayBackground}: Pick<IAccordionProps, 'withBorder' | 'withGrayBackground'>) => {
      let styles = {
        width: '100%',
        margin: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px 0`,
        backgroundColor: withGrayBackground ? theme.palette.grey[50] : 'transparent',
      };

      if (withBorder) {
        styles = {...styles, ...borderGeneral};
      }

      return styles;
    },
    rootOverride: ({isExpanded, draggable}: {isExpanded: boolean; draggable: boolean}) => ({
      minHeight,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '& > .MuiButtonBase-root': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: `0 &{theme.spacing(2)} 0 0`,
      },
      '& > .MuiAccordionSummary-content': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        cursor: draggable ? 'move' : 'pointer',
      },
      '& > .MuiAccordionSummary-expandIcon': {
        transform: isExpanded ? 'translateY(-8px)' : 'translateY(0)',
      },
    }),
    expandedOverride: {
      minHeight: `${minHeight}px!important`,
      marginTop: '0!important',
      marginBottom: '0!important',
    },
    heading: {
      margin: '0!important',
    },
    headingActionRight: {
      marginLeft: 'auto',
    },
    disabled: {
      backgroundColor: 'rgba(204,203,203,0.12)!important',
    },
  };
});

import {createSlice} from '@reduxjs/toolkit';
import {programClassesPaperAssessmentsInitialState} from './programClassesPaperAssessments.initialState';
import {programClassesPaperAssessmentsReducer} from './programClassesPaperAssessments.reducer';
import {programClassesPaperAssessmentsExtraReducer} from './programClassesPaperAssessmentsExtra.reducer';

export const programClassesPaperAssessmentsSlice = createSlice({
  name: 'programClassesPaperAssessments',
  initialState: programClassesPaperAssessmentsInitialState,
  reducers: programClassesPaperAssessmentsReducer,
  extraReducers: programClassesPaperAssessmentsExtraReducer,
});

export const {actions: programClassesPaperAssessmentsActions} = programClassesPaperAssessmentsSlice;

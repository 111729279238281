import {ModalDelete} from 'admin/components';
import React from 'react';
import {useItem} from '../../hooks';

interface IModalDeleteItemProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

export function ModalDeleteItem({isOpen, setIsOpen}: IModalDeleteItemProps) {
  const {deleteItem} = useItem();

  return (
    <ModalDelete
      text="This action will delete the question and all related answer stacks. Are you sure you want to delete this item?"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      confirmHandler={deleteItem}
    />
  );
}

import {IBuilderDragItem, IBuilderLinkItem} from 'admin/library/models';
import {IBuilderFilter} from '../models';
import {QuestionAnswersTypeEnum, QuestionDomainNumEnum, QuestionPropertiesType} from 'enums';
import {IFetchPoolItemsFilterPayload} from 'models/library/instruments/IFetchPoolItemsPayload';
import {IInstrumentItem} from 'models';
import {NEGATIVE_ONE, RANDOM_COLLECTIVELY, RANDOM_MULTIPLIER} from 'utils';
import {InstrumentTypeEnum} from '../../enums';

export function isMatrixQuestion(questionType: QuestionAnswersTypeEnum): boolean {
  return questionType === QuestionAnswersTypeEnum.matrix || questionType === QuestionAnswersTypeEnum.matrixEnum;
}

export const isInstructionalTextQuestion = (questionType: QuestionAnswersTypeEnum): boolean => {
  return questionType === QuestionAnswersTypeEnum.html;
};

export const getLinkedItem = (builderPossiblePretestItems: IBuilderLinkItem[], linkedQuestionAnswerStackId: number) =>
  builderPossiblePretestItems.filter((item) => item.questionAnswerStackId === linkedQuestionAnswerStackId)[0];

export const getChildQuestion = (builderItem, isMatrix: boolean) =>
  isMatrix &&
  builderItem?.rows.find(
    (childQuestion: IBuilderDragItem) => childQuestion.questionId === builderItem.childQuestionAnswerStackId
  );

export const mapInitialValues = (isMatrix: boolean, parentItem: IBuilderDragItem, childItem: IBuilderDragItem) => {
  let newLinkedQuestionAnswerStackId = [];
  const matrixInitialLinkedQuestionAnswerStackIdList = [childItem[QuestionPropertiesType.linkedQuestionAnswerStackId]];

  if (isMatrix && childItem?.linkedQuestionAnswerStackId) {
    newLinkedQuestionAnswerStackId = matrixInitialLinkedQuestionAnswerStackIdList;
  } else if (parentItem?.linkedQuestionAnswerStackId) {
    newLinkedQuestionAnswerStackId = [parentItem?.linkedQuestionAnswerStackId];
  }

  return {
    linkedQuestionAnswerStackId: newLinkedQuestionAnswerStackId,
  };
};

export const checkIfValidFormValues = (value: number): boolean => !!value;

export const applyItemLinkChanges = (
  isMatrix: boolean,
  parentItem: IBuilderDragItem,
  childItem: IBuilderDragItem,
  value: number
) => {
  const isValid = checkIfValidFormValues(value);

  if (isMatrix && childItem) {
    childItem[QuestionPropertiesType.linkedQuestionAnswerStackId] = isValid ? value : null;
  } else {
    parentItem[QuestionPropertiesType.linkedQuestionAnswerStackId] = isValid ? value : null;
  }
  return parentItem;
};

export const mapBuilderFilter = (
  search: string,
  areas: number[],
  pillars: string[],
  evaluationType: number[],
  domain: number[],
  programs: number[],
  category: string,
  learningObjective: string,
  concept: number[],
  competency: number[],
  administration: number[],
  schoolLevel: number[]
): IBuilderFilter => {
  return {
    search,
    areas,
    pillars,
    evaluationType,
    domain,
    programs,
    category,
    learningObjective,
    concept,
    competency,
    administration,
    schoolLevel,
  };
};

export const mapBuilderFilterPayload = (builderFilter: IFetchPoolItemsFilterPayload) => ({
  text: builderFilter.search ? builderFilter.search.trim() : '',
  areasFilter: builderFilter.areas ?? [],
  pillarsFilter: builderFilter.pillars ?? [],
  evaluationTypesFilter: builderFilter.evaluationType ?? [],
  domainsFilter: builderFilter.domain ?? [],
  programsFilter: builderFilter.programs ?? [],
  categoryFilter: builderFilter.category ?? [],
  learningObjectiveFilter: builderFilter.learningObjective ?? [],
  conceptsFilter: builderFilter.concept ?? [],
  competenciesFilter: builderFilter.competency ?? [],
  administrationsFilter: builderFilter.administration ?? [],
  schoolLevelsFilter: builderFilter.schoolLevel ?? [],
});

export const generateRandomQuestionId = () =>
  (Math.floor(Math.random() * RANDOM_MULTIPLIER) + RANDOM_COLLECTIVELY) * NEGATIVE_ONE;

export const mapBuilderItemInstructionalText = (item: IInstrumentItem, html: string) => ({
  ...item,
  html,
});

const getIsAllowedInstrumentLink = (instrumentTypeId: number) => {
  const isPostSessionSurvey =
    instrumentTypeId > InstrumentTypeEnum.PostSessionSurvey && instrumentTypeId < InstrumentTypeEnum.Feedback;

  return (
    instrumentTypeId === InstrumentTypeEnum.PostProgramSurvey ||
    instrumentTypeId === InstrumentTypeEnum.PostSessionSurvey ||
    isPostSessionSurvey
  );
};

export const getCanLinkItem = (item: IBuilderDragItem, instrumentTypeId: number) => {
  const isLinkAllowed = getIsAllowedInstrumentLink(instrumentTypeId);

  if (item.domain) {
    return isLinkAllowed && item.domain !== QuestionDomainNumEnum.Knowledge;
  }

  return false;
};

import React, {FC} from 'react';
import {Form} from 'forms';
import {ItemWithPlaceholder} from '../ItemWithPlaceholder';
import {IWithDisabled} from 'models';

interface IDatePickerSelectionProps extends IWithDisabled {
  label: string;
  name: string;
  value?: any;
  maxWidth?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  infoText?: string;
  minDateMessage?: string;
  maxDateMessage?: string;
}

export const DatePickerSelection: FC<IDatePickerSelectionProps> = ({
  label,
  name,
  value = null,
  disabled = false,
  maxWidth = true,
  disablePast = false,
  disableFuture = false,
  infoText,
  minDateMessage,
  maxDateMessage,
}) => (
  <ItemWithPlaceholder label={label} hasItems infoText={infoText}>
    <Form.DatePicker
      name={name}
      value={value}
      disabled={disabled}
      maxWidth={maxWidth}
      disablePast={disablePast}
      disableFuture={disableFuture}
      format="MM/dd/yyyy"
      minDateMessage={minDateMessage}
      maxDateMessage={maxDateMessage}
    />
  </ItemWithPlaceholder>
);

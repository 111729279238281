import {createAsyncThunk} from '@reduxjs/toolkit';
import {filtersApi} from 'api';
import {IEntityBase} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchTagGradeLevelsThunk: any = createAsyncThunk<IEntityBase[]>(
  `${prefix} fetchTaggingGradeLevels`,
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await filtersApi.getTaggingGradeLevels();
      return parsedBody;
    } catch (error) {
      dispatch(
        toastNotificationManager.createErrorToastAction(
          error.parsedBody || error.statusText || 'Failed to fetch grader levels. Unknown error occurred.'
        )
      );
      return rejectWithValue(error);
    }
  }
);

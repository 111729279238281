import {IActionFulfilled, IGetPageOfProgramClassInstrumentsResponse} from 'models';
import {normalize} from 'normalizr';
import {isObject} from 'utils';
import {IProgramClassInstrumentsState} from './IProgramClassInstrumentsState';
import {poolListSchema} from './normalizationSchemas';
import {fetchProgramClassInstrumentsThunk} from './thunks';
import {fetchAreaProgramClassExportThunk} from 'admin/library/instruments';

export const programClassInstrumentsExtraReducer = {
  //fetchProgramClassInstrumentsThunk
  [fetchProgramClassInstrumentsThunk.fulfilled]: (
    state: IProgramClassInstrumentsState,
    action: IActionFulfilled<null, IGetPageOfProgramClassInstrumentsResponse>
  ) => {
    if (isObject(action.payload)) {
      state.pool = normalize(action.payload.programTemplates, poolListSchema);
      state.pagination.totalCount = action.payload.totalCount;
    }

    state.page.loading = false;
  },
  [fetchProgramClassInstrumentsThunk.pending]: (state: IProgramClassInstrumentsState) => {
    state.page.loading = true;
  },
  [fetchProgramClassInstrumentsThunk.rejected]: (state: IProgramClassInstrumentsState) => {
    // TODO handle errors
    state.page.loading = false;
  },

  //fetchAreaProgramClassExportThunk
  [fetchAreaProgramClassExportThunk.fulfilled]: (state: IProgramClassInstrumentsState) => {
    state.page.loading = false;
  },
  [fetchAreaProgramClassExportThunk.pending]: (state: IProgramClassInstrumentsState) => {
    state.page.loading = true;
  },
  [fetchAreaProgramClassExportThunk.rejected]: (state: IProgramClassInstrumentsState) => {
    state.page.loading = false;
  },
};

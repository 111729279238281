import {createAsyncThunk} from '@reduxjs/toolkit';
import {itemsApi} from 'api';
import {IBindStringResponse, IDeleteItemPayload} from 'models';
import {pages} from 'paths';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const deleteItemThunk: any = createAsyncThunk<IBindStringResponse, IDeleteItemPayload>(
  `${prefix} deleteItem`,
  async ({itemId, push}: IDeleteItemPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await itemsApi.deleteItem(itemId);
      dispatch(toastNotificationManager.createSuccessToastAction('The item was deleted successfully.'));
      push(pages.adminPortal.items.root);
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction('There was an error while deleting the item.'));
      return rejectWithValue(err);
    }
  }
);

import React, {FC, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';

interface IInstructionalTextProps {
  instructionText: string;
}

export const InstructionalText: FC<IInstructionalTextProps> = ({instructionText}) => {
  const classes = useStyles();

  const Marker = useMemo(() => () => <span className={classes.marker}>X</span>, [classes]);
  const InstructionText = useMemo(() => () => <span>{instructionText}</span>, [instructionText]);

  const DefaultText = useMemo(
    () => () => (
      <div>
        Please mark an <Marker /> in the box next to your answer choice like this:{' '}
        <span className={classes.ballotBoxWithX}>&#9746;</span>
      </div>
    ),
    [Marker, classes]
  );

  return (
    <div className={classes.root}>
      <DefaultText />
      {instructionText && <InstructionText />}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    padding: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
  },
  marker: {
    fontWeight: 'bold',
  },
  ballotBoxWithX: {
    fontSize: '1.75em',
  },
}));

import {pages, paths} from 'paths';
import {withRole} from 'permissions';
import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {AdminRolesItems, AdminWithStaffRolesItems} from '../enums';
import {ItemBuilderCreateView, ItemBuilderEditAnswerStackView} from './views';

const ItemBuilderCreateViewEnhanced = withRole(
  AdminWithStaffRolesItems,
  `${paths.adminPortal.root}${paths.noPermission}`
)(ItemBuilderCreateView);

const ItemBuilderEditAnswerStackViewEnhanced = withRole(
  AdminRolesItems,
  `${paths.adminPortal.root}${paths.noPermission}`
)(ItemBuilderEditAnswerStackView);

export function ItemBuilderView() {
  return (
    <Switch>
      <Route
        exact
        path={pages.adminPortal.items.build}
        render={() => <Redirect to={pages.adminPortal.items.create} />}
      />
      <Route path={pages.adminPortal.items.create} component={ItemBuilderCreateViewEnhanced} />
      <Route path={pages.adminPortal.items.editAnswerStack} component={ItemBuilderEditAnswerStackViewEnhanced} />
    </Switch>
  );
}

import React from 'react';
import {SvgIcon} from '@material-ui/core';
import {ReactComponent as UploadIconSvg} from 'resources/icons/icon_upload.svg';

export function UploadIcon() {
  return (
    <SvgIcon>
      <UploadIconSvg />
    </SvgIcon>
  );
}

import React, {useCallback} from 'react';
import {useAppDispatch} from 'hooks';
import {useSelector} from 'react-redux';
import {IProgramsMasterInstrumentsSortOrder} from 'models/programs';
import {programsMasterInstrumentsSortOrderSelector, programMasterInstrumentsActions as actions} from '../../store';
import {SortByDropdown} from 'admin';
import {getProgramMasterInstrumentsMenuItemsList, programMasterInstrumentsOrderingMap} from '../../utils';

export const ProgramsMasterInstrumentsSortBy = () => {
  const dispatch = useAppDispatch();
  const sortOrder = useSelector(programsMasterInstrumentsSortOrderSelector);

  const handleItemClick = useCallback(
    (sortOrder: IProgramsMasterInstrumentsSortOrder) => {
      dispatch(actions.setSortOrderProgramClasses(sortOrder));
    },
    [dispatch]
  );

  return (
    <SortByDropdown
      label="Sort By: "
      handleItemClick={handleItemClick}
      getMenuItemsList={getProgramMasterInstrumentsMenuItemsList}
      sortOrder={sortOrder}
      map={programMasterInstrumentsOrderingMap}
    />
  );
};

import {IFetchLearningPathsAllQueryFilters, IGetLearningPathsPayload} from 'models/resources/learning-paths';
import {SortingOrderEnum} from 'enums';

export const mapFetchLearningPathsAllPayload = ({
  sortOrder,
  currentPage,
  perPage,
  filter,
  programYearsCollection,
}: IFetchLearningPathsAllQueryFilters): IGetLearningPathsPayload => {
  let filterFilesAllPayload = {};

  if (filter.query) {
    filterFilesAllPayload = {...filterFilesAllPayload, query: filter.query};
  }

  if (filter.programs.length) {
    filterFilesAllPayload = {...filterFilesAllPayload, programs: filter.programs};
  }

  if (filter.programYears.length) {
    const filteredProgramYears = programYearsCollection.filter((programYear) =>
      filter.programYears.includes(programYear.id)
    );
    const programYearNames = filteredProgramYears.map((programYear) => programYear.displayText);

    filterFilesAllPayload = {...filterFilesAllPayload, programYears: programYearNames};
  }

  if (filter.gradeLevels.length) {
    filterFilesAllPayload = {...filterFilesAllPayload, gradeLevels: filter.gradeLevels};
  }

  if (filter.pillars.length) {
    filterFilesAllPayload = {...filterFilesAllPayload, pillars: filter.pillars};
  }

  if (filter.languages.length) {
    filterFilesAllPayload = {...filterFilesAllPayload, languages: filter.languages};
  }

  if (filter.learningPathStatuses.length) {
    filterFilesAllPayload = {...filterFilesAllPayload, statuses: filter.learningPathStatuses};
  }

  return {
    sortOrder: {
      sortBy: sortOrder.order,
      sortAsc: sortOrder.sort === SortingOrderEnum.ASC,
    },
    pagination: {
      pageNumber: currentPage,
      pageSize: perPage,
    },
    filter: filterFilesAllPayload,
  };
};

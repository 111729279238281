import {axiosApiInstance, IHttpResponse} from 'api';
import {ApiPathsEnum} from 'enums';
import {config} from 'config';
import {get} from './http-client';
import {
  ICreateFileApiResponse,
  ICreateWebLinkPayload,
  IGetAllFileTypeOptionsApiResponse,
  IUploadScormApiResponse,
  IUploadScormPayload,
  ICreateWebLinkApiResponse,
  IUploadPaperAssessmentsPayload,
  IUploadPaperAssessmentsApiResponse,
} from 'models';
import {getApiMultipartFormDataHeadersConfig} from 'utils/api';

const {jaEeApi: apiConfig} = config;

export const uploadResourcesApi = {
  async getUploadFileTypeOptions(): Promise<IHttpResponse<IGetAllFileTypeOptionsApiResponse>> {
    const url = `${apiConfig.baseUrlPam}/${ApiPathsEnum.HELPERS}/GetUploadFileTypeOptions`;
    return await get<IGetAllFileTypeOptionsApiResponse>(url);
  },

  // File

  async uploadFile(
    payload: FormData,
    onUploadProgress: (progressEvent: ProgressEvent) => void,
    cancelToken: any
  ): Promise<IHttpResponse<ICreateFileApiResponse>> {
    const configHeaders = getApiMultipartFormDataHeadersConfig();
    const baseUrl = `${apiConfig.baseUrlPam}/${ApiPathsEnum.RESOURCES}/${ApiPathsEnum.FILE}/Upload`;
    return axiosApiInstance.post(baseUrl, payload, {
      ...configHeaders,
      onUploadProgress,
      cancelToken,
    });
  },

  // Scorm

  async uploadScorm(payload: IUploadScormPayload): Promise<IHttpResponse<IUploadScormApiResponse>> {
    const config = getApiMultipartFormDataHeadersConfig();
    const baseUrl = `${apiConfig.baseUrlPam}/${ApiPathsEnum.RESOURCES}/${ApiPathsEnum.SCORM}/Upload`;
    return axiosApiInstance.post(baseUrl, payload, config);
  },

  // WebLink

  async createWebLink(payload: ICreateWebLinkPayload): Promise<IHttpResponse<ICreateWebLinkApiResponse>> {
    const config = getApiMultipartFormDataHeadersConfig();
    const baseUrl = `${apiConfig.baseUrlPam}/${ApiPathsEnum.RESOURCES}/${ApiPathsEnum.WEB_LINK}/Create`;
    return axiosApiInstance.post(baseUrl, payload, config);
  },

  // PaperAssessments

  async uploadPaperAssessment(
    payload: IUploadPaperAssessmentsPayload
  ): Promise<IHttpResponse<IUploadPaperAssessmentsApiResponse>> {
    const config = getApiMultipartFormDataHeadersConfig();
    const baseUrl = `${apiConfig.baseUrlPam}/${ApiPathsEnum.PAPER_ASSESSMENTS}/UploadZip`;
    return axiosApiInstance.post(baseUrl, payload, config);
  },
};

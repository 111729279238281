import React, {Fragment, useCallback, useEffect, useState, useMemo} from 'react';
import {Prompt, useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {useBeforeunload} from 'react-beforeunload';
import {IHttpResponse} from 'api';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';
import {useAppDispatch, useNavigate} from 'hooks';
import {useInstrument} from 'admin/library/instruments/details/hooks';
import {useProgram, useProgramReports, useProgramReportsStoreActions} from '../hooks';
import {toastNotificationManager} from 'toast-notifications';
import {pages} from 'paths';
import {Button, Container, Loading, PageViewHeader, Row, Table, Text} from 'components-lib';
import {SidebarBase} from 'components-lib/sidebar/SidebarBase';
import {programsMessages, SubtitlePageViewHeader} from 'admin';
import {PageLayoutTwoCol, PageLayoutWithFixedAreas} from 'layout';
import {LevelReportingMenu, ViewExpansionPanel} from '../components';
import {AreaSelect} from '../components/AreaSelect';
import {IGetProgramReportResponse} from 'models';
import {ALL_AREAS_INDEX, createLinkForDownload, toNumber} from 'utils';
import {columns, generateReport, IProgramClassReportsQueryParams} from '..';
import {ExportTypesEnum, ReportExportFormatsEnum, ReportTypesEnum, ReportTypeTermsEnum} from '../enums';
import {reportMessages} from '../utils';
import {defaultProgramMenuOptionId} from '../store/programDetails.initialState';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {IProgramComparisonResult} from './IProgramComparisonResult';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {useUserId} from 'user';
import {IColumn} from 'models/library/common';

type IProgramReportsQueryParams = IProgramClassReportsQueryParams;

export const ProgramReportsAndExportsView = () => {
  const {userAreaId} = useUserId();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const {reportTypes, exportTypes, loading, setIsLoading, selectedAreaId, setSelectedAreaId} = useProgramReports();
  const {isProgramLevelAllOption, selectedItem, selectedId, clearProgramReportsState} = useProgramReportsStoreActions();
  const {programId} = useParams<{programId: string}>();
  const programIdParam = toNumber(programId);
  const {hasPermission} = usePermissions();
  const {push} = useNavigate();
  const {program, fetchProgram} = useProgram(programIdParam);
  const [pasId, setPasId] = useState<number>(programIdParam);
  const [d2lId, setD2lId] = useState<number>(programIdParam);
  const [pasMergeId, setPasMergeId] = useState<number>(programIdParam);
  const [d2lMergeId, setD2lMergeId] = useState<number>(programIdParam);
  const LoadingComponent = () => <Loading />;
  const compareColumns: IColumn[] = [
    {id: 'textField', label: '', align: 'left'},
    {id: 'compareBtn', label: '', className: 'btn-col', align: 'right'},
  ];

  // Modal
  const [compareResult, setCompareResult] = useState<IProgramComparisonResult>({
    pasProgramName: program?.name,
    d2lProgramName: program?.name,
    pasColumns: '',
    d2lColumns: '',
    className: null,
    areaId: userAreaId?.toString(),
    numColsOnlyInPAS: 0,
    numColsOnlyInD2L: 0,
    numColsInBoth: 0,
    valuesOnlyInPAS: [],
    valuesOnlyInD2L: [],
    valuesInBoth: [],
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalLoading, setIsModalLoading] = useState<boolean>(false);
  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    height: '90%',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '20px',
  };

  const {
    getAreaProgramClassExport,
    getAreaProgramClassDetailedExport,
    getAreaProgramClassAggregatedExport,
    getProgramComparison,
    getMergedProgramExport,
  } = useInstrument();

  useBeforeunload(() => {
    if (loading) {
      return reportMessages.progress;
    }
  });

  const shouldDisableAchievementReports = !isProgramLevelAllOption;

  useEffect(() => {
    fetchProgram(true);
  }, [fetchProgram]);

  useEffect(() => {
    return () => {
      clearProgramReportsState();
    };
  }, [clearProgramReportsState]);

  const filteredReportTypes = useMemo(() => {
    if (isProgramLevelAllOption) {
      return reportTypes;
    }

    return reportTypes.filter(
      (item) => !item.name.toLowerCase().includes(ReportTypeTermsEnum.Achievement.toLowerCase())
    );
  }, [reportTypes, isProgramLevelAllOption]);

  const reportRows = useMemo(() => {
    const generateReportClickHandler = async (type: ReportTypesEnum, exportFormat: ReportExportFormatsEnum) => {
      setIsLoading(true);

      const params: IProgramReportsQueryParams = {
        programId: programIdParam,
        type,
        exportFormat,
      };

      if (selectedAreaId > ALL_AREAS_INDEX) {
        params.areaId = selectedAreaId;
      }

      if (selectedId > defaultProgramMenuOptionId) {
        params.matchedSurveyTypesId = selectedId;
      }

      try {
        const response: IHttpResponse<IGetProgramReportResponse> = await generateReport(type, params);

        if (response.ok) {
          const {mimeType, data, fileName} = response.parsedBody;
          createLinkForDownload(`data:${mimeType};base64,${data}`, fileName);
          dispatch(toastNotificationManager.createSuccessToastAction(programsMessages.reportSuccess));
        }
      } catch (err) {
        dispatch(toastNotificationManager.createErrorToastAction(err.statusText || programsMessages.reportError));
      } finally {
        setIsLoading(false);
      }
    };

    return filteredReportTypes.map(({name, type}) => ({
      name,
      viewBtn: (
        <Fragment>
          <Button.Secondary
            size="small"
            clickHandler={() => generateReportClickHandler(type, ReportExportFormatsEnum.PDF)}
            disabled={loading}
          >
            PDF Report
          </Button.Secondary>
          <Button.Secondary
            size="small"
            clickHandler={() => generateReportClickHandler(type, ReportExportFormatsEnum.CSV)}
            disabled={loading}
          >
            Excel Report
          </Button.Secondary>
        </Fragment>
      ),
    }));
  }, [dispatch, loading, programIdParam, selectedAreaId, filteredReportTypes, setIsLoading, selectedId]);

  // Raw Data Export

  const getRawDataExportForParticularArea = useCallback(
    () => getAreaProgramClassExport(programIdParam, null, selectedAreaId),
    [getAreaProgramClassExport, programIdParam, selectedAreaId]
  );

  const getDetailedDataExportForParticularArea = useCallback(
    () => getAreaProgramClassDetailedExport(programIdParam, null, selectedAreaId),
    [getAreaProgramClassDetailedExport, programIdParam, selectedAreaId]
  );

  const getAggregatedDataExportForParticularArea = useCallback(
    () => getAreaProgramClassAggregatedExport(programIdParam, null, selectedAreaId),
    [getAreaProgramClassAggregatedExport, programIdParam, selectedAreaId]
  );

  const getRawExportForParticularArea = useCallback(
    (type: ExportTypesEnum) => {
      switch (type) {
        case ExportTypesEnum.DataFileAggregated:
          return getAggregatedDataExportForParticularArea();
        case ExportTypesEnum.DataFileDetailed:
          return getDetailedDataExportForParticularArea();
        default:
          return getRawDataExportForParticularArea();
      }
    },
    [
      getRawDataExportForParticularArea,
      getDetailedDataExportForParticularArea,
      getAggregatedDataExportForParticularArea,
    ]
  );

  // Raw Data Export (Detailed)

  const getRawDataExportForAllAreas = useCallback(() => getAreaProgramClassExport(programIdParam, null, null), [
    getAreaProgramClassExport,
    programIdParam,
  ]);

  const getDetailedDataExportForAllAreas = useCallback(
    () => getAreaProgramClassDetailedExport(programIdParam, null, null),
    [getAreaProgramClassDetailedExport, programIdParam]
  );

  const getAggregatedDataExportForAllAreas = useCallback(
    () => getAreaProgramClassAggregatedExport(programIdParam, null, null),
    [getAreaProgramClassAggregatedExport, programIdParam]
  );

  const getRawExportForAllAreas = useCallback(
    (type: ExportTypesEnum) => {
      switch (type) {
        case ExportTypesEnum.DataFileAggregated:
          return getAggregatedDataExportForAllAreas();
        case ExportTypesEnum.DataFileDetailed:
          return getDetailedDataExportForAllAreas();
        default:
          return getRawDataExportForAllAreas();
      }
    },
    [getRawDataExportForAllAreas, getDetailedDataExportForAllAreas, getAggregatedDataExportForAllAreas]
  );

  const exportRows = useMemo(() => {
    const generateExportClickHandler = async (type: ExportTypesEnum) => {
      setIsLoading(true);

      if (selectedAreaId > ALL_AREAS_INDEX) {
        getRawExportForParticularArea(type)
          .then(() => setIsLoading(false))
          .finally(() => setIsLoading(false));
      } else {
        getRawExportForAllAreas(type)
          .then(() => setIsLoading(false))
          .finally(() => setIsLoading(false));
      }
    };

    if (!hasPermission(USAAndSuperAdminRoles)) {
      exportTypes.pop();
    }

    return exportTypes.map(({name, type}) => ({
      name,
      viewBtn: (
        <Fragment>
          <Button.Secondary
            size="small"
            clickHandler={() => generateExportClickHandler(type)}
            disabled={loading || shouldDisableAchievementReports}
          >
            CSV Export
          </Button.Secondary>
        </Fragment>
      ),
    }));
  }, [
    loading,
    exportTypes,
    shouldDisableAchievementReports,
    setIsLoading,
    selectedAreaId,
    getRawExportForAllAreas,
    getRawExportForParticularArea,
  ]);

  const getComparison = useCallback(() => getProgramComparison(pasId, d2lId, userAreaId, null), [
    getProgramComparison,
    pasId,
    d2lId,
    userAreaId,
  ]);

  const getMergedExport = useCallback(() => getMergedProgramExport(pasId, d2lId, userAreaId, null), [
    getMergedProgramExport,
    pasId,
    d2lId,
    userAreaId,
  ]);

  const compareRows = useMemo(() => {
    const generateMergeClickHandler = async () => {
      setIsLoading(true);
      getMergedExport()
        .then(() => setIsLoading(false))
        .finally(() => setIsLoading(false));
    };

    const generateComparisonClickHandler = async () => {
      handleOpen();
      setIsModalLoading(true);
      getComparison()
        .then((result) => {
          setCompareResult(result.payload);
          setIsModalLoading(false);
        })
        .finally(() => {
          setIsModalLoading(false);
        });
    };

    return [
      {
        textField: <Container> PAS/D2L Combined CSV Export</Container>,
        compareBtn: (
          <Fragment>
            <Button.Secondary size="small" clickHandler={() => generateMergeClickHandler()}>
              CSV Export
            </Button.Secondary>
          </Fragment>
        ),
      },
      {
        textField: (
          <Fragment>
            <label>
              <TextField
                label="PAS Program Id"
                variant="outlined"
                value={pasId}
                type="number"
                onChange={(e) => setPasId(toNumber(e.target.value))}
                style={{marginRight: '18px'}}
              />
            </label>
            <label>
              <TextField
                label="D2L Program Id"
                variant="outlined"
                value={d2lId}
                type="number"
                onChange={(e) => setD2lId(toNumber(e.target.value))}
              />
            </label>
          </Fragment>
        ),
        compareBtn: (
          <Fragment>
            <Button.Secondary size="small" clickHandler={() => generateComparisonClickHandler()}>
              Compare Programs
            </Button.Secondary>
          </Fragment>
        ),
      },
    ];
  }, [userAreaId, pasId, d2lId, pasMergeId, d2lMergeId]);

  const headerSubheading = useMemo(
    () => program && <SubtitlePageViewHeader> / {program?.name}</SubtitlePageViewHeader>,
    [program]
  );

  const header = useMemo(() => {
    return (
      <PageViewHeader
        heading="Programs"
        subHeading={headerSubheading}
        withBackButton
        backButtonClickHandler={() => push(`${pages.adminPortal.programs.root}`)}
      />
    );
  }, [headerSubheading, push]);

  const sidebar = useMemo(() => {
    const shouldRenderAreaFilter = hasPermission(USAAndSuperAdminRoles);

    return (
      <SidebarBase>
        <ViewExpansionPanel disabled={loading} />
        {!!shouldRenderAreaFilter && (
          <AreaSelect selectedArea={selectedAreaId} onSelectArea={setSelectedAreaId} disabled={loading} />
        )}
      </SidebarBase>
    );
  }, [hasPermission, loading, selectedAreaId, setSelectedAreaId]);

  const content = useMemo(() => {
    return (
      <PageLayoutWithFixedAreas header={header} headerSize="small" withSecondaryNavigation={false}>
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <Container disableGutters classes={{root: classes.exports}}>
              <Row justify="flex-start">
                <Text.Heading as="h3">Exports</Text.Heading>
              </Row>
              <Container disableGutters classes={{root: classes.exportsTable}}>
                <Text.Heading as="h4">Aggregated program-level data</Text.Heading>
                <Table columns={columns} rows={exportRows} />
              </Container>
            </Container>

            <Container disableGutters classes={{root: classes.exports}}>
              <Row justify="space-between">
                <Text.Heading as="h3">Reports</Text.Heading>
                <LevelReportingMenu />
              </Row>
              {selectedItem && <Text.Heading as="h4">{selectedItem?.displayText}</Text.Heading>}
              <Table columns={columns} rows={reportRows} />
            </Container>
            {hasPermission(USAAndSuperAdminRoles) && (
              <Container disableGutters classes={{root: classes.exports}}>
                <Row justify="flex-start">
                  <Text.Heading as="h3">Compare PAS and D2L Data</Text.Heading>
                </Row>
                <Container disableGutters classes={{root: classes.exportsTable}}>
                  <Table columns={compareColumns} rows={compareRows} />
                </Container>
              </Container>
            )}
          </Fragment>
        )}
        <Prompt when={loading} message={reportMessages.progress} />

        {hasPermission(USAAndSuperAdminRoles) && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box css={style}>
              {modalLoading ? (
                <Loading />
              ) : (
                <Container>
                  <Typography id="modal-modal-title" variant="h1">
                    Program Comparison
                  </Typography>

                  {compareResult.className && (
                    <Typography variant="h2">Class Name: {compareResult.className}</Typography>
                  )}

                  {compareResult.areaId && <Typography variant="h2">Area Id: {compareResult.areaId}</Typography>}

                  <Grid container className={classes.grid} spacing={4}>
                    <Grid item xs={4} className={classes.item}>
                      <Typography variant="h3">PAS</Typography>
                      <Typography variant="h4" style={{marginBottom: '0px'}}>
                        {compareResult.pasProgramName}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.item}>
                      <Typography variant="h3">D2L</Typography>
                      <Typography variant="h4" style={{marginBottom: '0px'}}>
                        {compareResult.d2lProgramName}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.item}>
                      <Typography variant="h3">Both</Typography>
                    </Grid>

                    <Grid item xs={4} zeroMinWidth>
                      {compareResult.valuesOnlyInPAS?.map((item, index) => {
                        return (
                          <Typography
                            variant="caption"
                            component="div"
                            key={index}
                            style={{overflowWrap: 'break-word', marginBottom: '4px'}}
                          >
                            {item}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Grid item xs={4} zeroMinWidth>
                      {compareResult.valuesOnlyInD2L?.map((item, index) => {
                        return (
                          <Typography
                            variant="caption"
                            component="div"
                            key={index}
                            style={{overflowWrap: 'break-word', marginBottom: '4px'}}
                          >
                            {item}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Grid item xs={4} zeroMinWidth>
                      {compareResult.valuesInBoth?.map((item, index) => {
                        return (
                          <Typography
                            variant="caption"
                            component="div"
                            key={index}
                            style={{overflowWrap: 'break-word', marginBottom: '4px'}}
                          >
                            {item}
                          </Typography>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Container>
              )}
            </Box>
          </Modal>
        )}
      </PageLayoutWithFixedAreas>
    );
  }, [
    classes.exports,
    classes.exportsTable,
    header,
    reportRows,
    exportRows,
    compareRows,
    compareColumns,
    compareResult,
    style,
    open,
    loading,
    selectedItem,
  ]);

  return <PageLayoutTwoCol withSecondaryNavigation={false} sidebar={sidebar} content={content} />;
};

const useStyles = makeStyles((theme) => ({
  exports: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    marginBottom: theme.spacing(3),
  },
  exportsTable: {
    paddingBottom: theme.spacing(2),
  },
  grid: {
    height: '100%',
  },
  item: {
    paddingBottom: '0px!important',
  },
}));

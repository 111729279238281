import React from 'react';
import {useFormikContext} from 'formik';
import {IIntegrationsAllFiltersFormValues} from 'admin/integrations/all/components/SidebarIntegrationsAll';
import {useAddIntegration} from 'admin/integrations/add-integration';
import {SchoolDistrictSelect} from './SchoolDistrictSelect';
import {getIsGoogleSitesDestination, getIsGoogleSitesSource} from 'admin/integrations/utils';

export const SchoolDistrictBasedOnGoogleSitesSelect = () => {
  const {values} = useFormikContext<IIntegrationsAllFiltersFormValues>();
  const type = Number(values.integrationType);
  const {serviceSourceEmail} = useAddIntegration();

  const isGoogleSitesSource = getIsGoogleSitesSource(type);
  const isGoogleSitesDestination = getIsGoogleSitesDestination(type);

  return <SchoolDistrictSelect disabled={(isGoogleSitesSource && !!serviceSourceEmail) || isGoogleSitesDestination} />;
};

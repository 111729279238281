import React, {FC} from 'react';
import {IPaperQuestion} from '../../models/IPaperQuestion';
import PaperSurveyBaseItem from '../PaperSurveyBaseItem/PaperSurveyBaseItem';

interface IQuestionProps {
  text: string;
  htmlStr?: string;
  id: string;
  choices: Array<any>;
  afterMount?: (questionInfo: IPaperQuestion) => void;
  withId?: boolean;
}

export const Question: FC<IQuestionProps> = ({text, htmlStr, id, choices, afterMount}) => {
  return <PaperSurveyBaseItem withId text={text} htmlStr={htmlStr} id={id} choices={choices} afterMount={afterMount} />;
};

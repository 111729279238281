import {Dispatch, SetStateAction, useState} from 'react';

interface IUseDrawer {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const useDrawer: () => IUseDrawer = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return {isOpen, setIsOpen};
};

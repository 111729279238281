import {EntitiesPerPageEnum, SortingOrderEnum} from 'enums';
import {IProgramMasterInstrumentsFilter} from 'models';

import {IProgramMasterInstrumentsState} from './IProgramMasterInstrumentsState';
import {FilterBackendNamesEnum, ProgramsMasterInstrumentsOrderingEnum} from '../enums';

const filterDefault: IProgramMasterInstrumentsFilter = {
  [FilterBackendNamesEnum.SEARCH_BY_NAME]: '',
  [FilterBackendNamesEnum.AUDIENCES]: [],
  [FilterBackendNamesEnum.PHASES]: [],
  [FilterBackendNamesEnum.STATUSES]: [1],
};

export const programMasterInstrumentsInitialState: IProgramMasterInstrumentsState = {
  pool: {
    result: [],
    entities: {byId: {}},
  },

  filter: filterDefault,
  page: {
    loading: false,
  },
  orderBy: 't.Name ASC',
  pagination: {
    currentPage: 1,
    totalCount: 0,
    perPage: EntitiesPerPageEnum.FIFTY,
  },
  sortOrder: {
    order: ProgramsMasterInstrumentsOrderingEnum.Name,
    sort: SortingOrderEnum.ASC,
  },
};

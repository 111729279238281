import {IActionFulfilled, IGetAllFilesApiResponse} from 'models';
import {normalize} from 'normalizr';
import {isObject} from 'utils';
import {IFilesAllStoreState} from './IFilesAllStoreState';
import {downloadFileThunk, fetchFilesAllPagedThunk} from './thunks';
import {filesAllSchema} from './normalizationSchemas';

export const filesAllExtraReducer = {
  // fetchFilesAllPagedThunk
  [fetchFilesAllPagedThunk.fulfilled]: (
    state: IFilesAllStoreState,
    action: IActionFulfilled<null, IGetAllFilesApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {
        data,
        metadata: {totalCount, pageNumber, pageSize},
      } = action.payload;

      state.files = normalize(data, filesAllSchema);
      state.pagination.totalCount = totalCount;
      state.pagination.currentPage = pageNumber;
      state.pagination.perPage = pageSize;
    }

    state.page.loading = false;
  },
  [fetchFilesAllPagedThunk.pending]: (state: IFilesAllStoreState) => {
    state.page.loading = true;
  },
  [fetchFilesAllPagedThunk.rejected]: (state: IFilesAllStoreState) => {
    state.page.loading = false;
  },

  // downloadFileThunk
  [downloadFileThunk.fulfilled]: (state: IFilesAllStoreState) => {
    state.fileDownload.id = null;
    state.fileDownload.downloading = false;
  },
  [downloadFileThunk.pending]: (state: IFilesAllStoreState, action: IActionFulfilled<null, any>) => {
    state.fileDownload.id = action.meta.arg;
    state.fileDownload.downloading = true;
  },
  [downloadFileThunk.rejected]: (state: IFilesAllStoreState) => {
    state.fileDownload.id = null;
    state.fileDownload.downloading = false;
  },
};

import {ErrorStatusCodesEnum} from 'enums';

export const redirectWhenErrorStatusCode = (status: number, path: string, redirectCallback: (path: string) => void) => {
  switch (status) {
    case ErrorStatusCodesEnum.BadRequest:
    case ErrorStatusCodesEnum.NotFound:
      return redirectCallback(path);
    default:
      return;
  }
};

import React from 'react';
import {makeStyles} from '@material-ui/core';
import {IComponentWithChildren} from 'models';

interface IPageButtonProps extends IComponentWithChildren {
  clickHandler?: () => void;
  variant?: 'primary' | 'light';
  disabled?: boolean;
}

export function PageButton({children, variant = 'light', clickHandler, disabled = false}: IPageButtonProps) {
  const classes = useStyles({variant, disabled});
  return (
    <div onClick={clickHandler} className={classes.root}>
      {children}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: ({variant, disabled}: Pick<IPageButtonProps, 'variant' | 'disabled'>) => {
    const isPrimary = variant === 'primary';

    return {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      background: isPrimary ? theme.palette.primary.main : 'transparent',
      color: isPrimary ? theme.palette.common.white : theme.palette.grey[400],
      borderRadius: theme.spacing(1),
      cursor: isPrimary ? 'default' : 'pointer',
      pointerEvents: disabled ? 'none' : 'auto',
    };
  },
}));

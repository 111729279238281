import {IFetchProgramClassesAllFilters} from 'models';
import {getIsAscSortOrder, getSortingOptions} from 'utils';
import {getProgramYearsCollectionString} from './getProgramYearsCollectionString';

export const mapProgramClassesPayload = ({
  programId,
  filter,
  currentPage,
  perPage,
  sortOrder,
  programYearsCollection,
}: IFetchProgramClassesAllFilters) => {
  const sortAsc = getIsAscSortOrder(sortOrder?.sort);
  const sortOptions = getSortingOptions(sortOrder?.order, sortAsc);

  const filterProgramYears = filter.programYearsFilter.length
    ? getProgramYearsCollectionString(programYearsCollection, filter.programYearsFilter)
    : [];

  const updatedFilter = {...filter, programYearsFilter: [...filterProgramYears]};

  return {
    programId,
    filter: updatedFilter,
    pagination: {
      page: currentPage,
      itemsPerPage: perPage,
    },
    sortOptions,
  };
};

import {useState} from 'react';

export function useInstrumentItem() {
  const [currentItem, setCurrentItem] = useState(null);

  return {
    currentItem,
    setCurrentItem,
  };
}

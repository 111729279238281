import {createAsyncThunk} from '@reduxjs/toolkit';
import {learningPathsApi} from 'api';
import {ICreateUpdateLearningPathApiResponse, ICreateLearningPathPayload} from 'models/resources/learning-paths';

import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const createLearningPathThunk: any = createAsyncThunk<
  ICreateUpdateLearningPathApiResponse,
  ICreateLearningPathPayload
>(`${prefix} createLearningPath`, async (payload: ICreateLearningPathPayload, {dispatch, rejectWithValue}) => {
  try {
    const response = await learningPathsApi.createLearningPath(payload);
    dispatch(toastNotificationManager.createSuccessToastAction('Learning path was created successfully'));
    return response.data;
  } catch (error) {
    dispatch(toastNotificationManager.createErrorToastAction(error.messages));
    return rejectWithValue({error});
  }
});

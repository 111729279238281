export const recognizeTypeByFileMimeType = (type: string) => {
  switch (type) {
    // Video
    case 'video/x-msvideo':
    case 'video/mp4':
    case 'video/mpeg':
    case 'video/ogg':
    case 'video/mp2t':
    case 'video/3gpp':
    case 'video/3gpp2':
    case 'video/webm':
      return 'Video';

    // Audio
    case 'audio/aac':
    case 'audio/midi':
    case 'audio/x-midi':
    case 'audio/mpeg':
    case 'audio/ogg':
    case 'audio/opus':
    case 'audio/wav':
    case 'audio/webm':
    case 'audio/3gpp':
    case 'audio/3gpp2':
      return 'Audio';

    // Image
    case 'image/png':
    case 'image/jpeg':
    case 'image/gif':
    case 'image/bmp':
    case 'image/svg+xml':
    case 'image/webp':
    case 'image/vnd.microsoft.icon':
      return 'Image';

    // PDF
    case 'application/pdf':
      return 'PDF';

    // Document
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/rtf':
    case 'application/vnd.oasis.opendocument.text':
    case 'text/plain':
    case 'text/csv':
    case 'application/vnd.oasis.opendocument.presentation':
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'application/vnd.visio':
      return 'Document';

    // Excel
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'Excel';

    // Zip
    case 'application/zip':
    case 'application/octet-stream':
    case 'application/x-zip-compressed':
    case 'multipart/x-zip':
      return 'Archive';

    default:
      return null;
  }
};

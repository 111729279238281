import {SortingOrderEnum} from 'enums';
import {ProgramClassPaperAssessmentsOrderingEnum} from 'enums/paper-assessments';

export const paperAssessmentsOrderingMap = {
  [ProgramClassPaperAssessmentsOrderingEnum.Name]: {
    [SortingOrderEnum.ASC]: 'Name: A-Z',
    [SortingOrderEnum.DESC]: 'Name: Z-A',
  },
  [ProgramClassPaperAssessmentsOrderingEnum.Created]: {
    [SortingOrderEnum.ASC]: 'Created: Oldest First',
    [SortingOrderEnum.DESC]: 'Created: Newest First',
  },
};

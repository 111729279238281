import React, {useMemo} from 'react';
import {Accordion, Row, Text} from 'components-lib';
import {Form} from 'forms';
import {
  filterPublishedByEntitiesCollectionSelector,
  FormNameEnum,
  getPublishedByExtendedList,
} from 'filters-selections';
import {IBindBaseStringIdEnumEntity, IWithDisabled} from 'models';
import {useSelector} from 'react-redux';
import {userAreaSelector} from 'user';

interface IPublishedByOption {
  label: string;
  value: number | string | boolean;
}

export const PublishedByRadioFilter = ({disabled}: IWithDisabled) => {
  const userArea = useSelector(userAreaSelector);
  const publishedBy = useSelector(filterPublishedByEntitiesCollectionSelector) as IBindBaseStringIdEnumEntity[];

  const publishedByExtendedList = useMemo(() => getPublishedByExtendedList(userArea, publishedBy[0]), [
    publishedBy,
    userArea,
  ]);

  const publishedByOptions: IPublishedByOption[] = useMemo(
    () => publishedByExtendedList.map((option) => ({value: option.name, label: option.displayText})),
    [publishedByExtendedList]
  );

  return (
    <Accordion heading="Published by">
      {publishedByOptions && publishedByOptions.length ? (
        <Form.RadioGroup name={FormNameEnum.publishedBy}>
          <Row justify="flex-start">
            {publishedByOptions.map((option: IPublishedByOption, idx: number) => (
              <Form.Radio key={idx} value={option.value} label={option.label} disabled={disabled} />
            ))}
          </Row>
        </Form.RadioGroup>
      ) : (
        <Text.Caption>No Data</Text.Caption>
      )}
    </Accordion>
  );
};

import {IHttpResponse} from 'api/models';
import {IGetFileByResourceIdApiResponse} from 'models';

const mapFileName = (list: string[]) => {
  const rawData = list[1].trim();
  const fileName = rawData.replace(/[=;"']/gi, '');
  return fileName;
};

export const getContentResponseHeaders = (response: IHttpResponse<IGetFileByResourceIdApiResponse>) => {
  const mimeType = response.headers['content-type'];
  const contentDisposition = response.headers['content-disposition'];
  const contentDispositionList = contentDisposition.split('filename');
  const fileName = mapFileName(contentDispositionList);

  return {mimeType, fileName};
};

import {IProgramClassReportMenuListOption} from 'models';
import {DefaultProgramMenuItemOptionEnum} from '../enums';
import {IProgramDetailsState} from './IProgramDetailsState';

export const defaultProgramMenuOptionId = 1 as number;

export const defaultProgramMenuItemOption = {
  id: defaultProgramMenuOptionId,
  displayText: DefaultProgramMenuItemOptionEnum.ProgramLevel,
} as IProgramClassReportMenuListOption;

export const defaultProgramClassMenuItemOption = {
  id: defaultProgramMenuOptionId,
  displayText: DefaultProgramMenuItemOptionEnum.ClassLevel,
} as IProgramClassReportMenuListOption;

const levelOfReportsInitial = {
  loading: false,
  selectedId: defaultProgramMenuItemOption.id,
  menuItems: [],
};

export const programDetailsInitialState: IProgramDetailsState = {
  loading: false,
  levelOfReports: levelOfReportsInitial,
};

import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';
import {values} from 'utils/functions';

const getPaperAssessmentsState = (state: TStateStore) => state.programs.paperAssessments;

export const pageSelector = createSelector(getPaperAssessmentsState, (paperAssessments) => paperAssessments.page);
export const pageLoadingSelector = createSelector(
  getPaperAssessmentsState,
  (paperAssessments) => paperAssessments.page.loading
);

export const paginationSelector = createSelector(
  getPaperAssessmentsState,
  (paperAssessments) => paperAssessments.pagination
);

export const paperAssessmentsSelector = createSelector(
  getPaperAssessmentsState,
  (paperAssessments) => paperAssessments.paperAssessmentList
);

export const paperAssessmentsIdsSelector = createSelector(
  paperAssessmentsSelector,
  (paperAssessmentList) => paperAssessmentList.result
);
export const paperAssessmentsEntitiesSelector = createSelector(
  paperAssessmentsSelector,
  (paperAssessmentList) => paperAssessmentList.entities.items
);
export const paperAssessmentsEntitiesCollectionSelector = createSelector(
  paperAssessmentsSelector,
  (paperAssessmentList) => (paperAssessmentList.entities.items ? values(paperAssessmentList.entities?.items) : [])
);

export const filterPaperAssessmentsSelector = createSelector(
  getPaperAssessmentsState,
  (paperAssessments) => paperAssessments.filter
);

export const paperAssessmentsSortOrderSelector = createSelector(
  getPaperAssessmentsState,
  (paperAssessments) => paperAssessments.sortOrder
);

export const uploadLoadingSelector = createSelector(
  getPaperAssessmentsState,
  (paperAssessments) => paperAssessments.upload.loading
);

import React, {Fragment, useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Button, Modal} from 'components-lib';
import {IWithModal} from 'models';
import {StackFilter} from 'admin/components';
interface IModalStackRelatedFiltersProps extends IWithModal {
  metaData?: any;
  area: string;
}

export const ModalStackRelatedFilters = ({
  metaData: {
    pillars,
    evaluationTypes,
    domain,
    programs,
    learningObjective,
    category,
    competencies,
    administration,
    schoolLevels,
  },
  area,
  isOpen,
  setIsOpen,
}: IModalStackRelatedFiltersProps) => {
  const classes = useStyles();

  const closeHandler = useCallback(() => setIsOpen(false), [setIsOpen]);

  const StackList = useMemo(
    () => () => (
      <div className={classes.container}>
        <StackFilter label="Pillar" list={pillars} withBottomMargin />
        <StackFilter label="Evaluation Type" list={evaluationTypes} withBottomMargin />
        <StackFilter label="Domain" text={domain?.name ?? '-'} isSelected={domain?.isSelected} withBottomMargin />
        <StackFilter label="Program" list={programs} withBottomMargin />
        <StackFilter
          label="Learning Objective"
          text={learningObjective ?? '-'}
          isSelected={learningObjective}
          withBottomMargin
        />
        <StackFilter label="Category" text={category ?? '-'} withBottomMargin />
        <StackFilter label="Competency" list={competencies} withBottomMargin />
        <StackFilter
          label="Administration"
          text={administration?.name}
          isSelected={administration?.isSelected}
          withBottomMargin
        />
        <StackFilter label="School Level" list={schoolLevels} withBottomMargin />
        <StackFilter label="JA Area" text={area} withBottomMargin />
      </div>
    ),
    [
      classes,
      pillars,
      evaluationTypes,
      domain,
      programs,
      learningObjective,
      category,
      competencies,
      administration,
      schoolLevels,
      area,
    ]
  );

  return (
    <Modal isOpen={isOpen} handleClose={closeHandler} title="Select related filters" size="medium">
      <StackList />
      <Fragment>
        <Button.Primary clickHandler={closeHandler} size="small">
          Ok
        </Button.Primary>
      </Fragment>
    </Modal>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

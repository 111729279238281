import React, {FC} from 'react';
import {ReactComponent as SettingsSvg} from 'resources/images/instruments/Illustration_settings.svg';
import {IComponentWithClassName} from 'models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISettingsIconProps extends IComponentWithClassName {}

export const SettingsIcon: FC<ISettingsIconProps> = ({className}) => {
  return <SettingsSvg className={className} />;
};

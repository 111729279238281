import React, {Dispatch, FC, Fragment, ReactNode, SetStateAction, useCallback, useMemo} from 'react';
import {Drawer as MUIDrawer, makeStyles} from '@material-ui/core';
import {IWithChildren} from 'models/common';
import {Button, Column, Container, Row, Icon, Text} from 'components-lib';
import {footerHeight, navHeight} from 'utils';

interface IDrawerProps extends IWithChildren {
  variant?: 'persistent' | 'permanent' | 'temporary';
  isOpen: boolean;
  actionButtons?: ReactNode;
  confirmButtonTitle?: string;
  disableConfirmButton?: boolean;
  title?: string | ReactNode;
  confirmButtonCallback?: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const Drawer: FC<IDrawerProps> = ({
  variant = 'temporary',
  children,
  isOpen,
  actionButtons,
  title,
  setIsOpen,
  confirmButtonTitle = 'Confirm',
  confirmButtonCallback,
  disableConfirmButton,
}) => {
  const classes = useStyles();

  const closeDrawerClickHandler = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const drawerHeader = useMemo(
    () => (
      <Row align="center" justify="space-between" classes={{root: classes.drawerHeader}}>
        {title && (
          <Text.Heading color="textPrimary" classes={{root: classes.drawerHeaderTitle}} as="h3">
            {title}
          </Text.Heading>
        )}
        <Icon.Button clickHandler={closeDrawerClickHandler}>
          <Icon.CloseIcon />
        </Icon.Button>
      </Row>
    ),
    [classes.drawerHeader, classes.drawerHeaderTitle, title, closeDrawerClickHandler]
  );

  const drawerFooter = useMemo(
    () => (
      <Fragment>
        {actionButtons ? (
          actionButtons
        ) : (
          <Row justify="flex-end" classes={{root: classes.drawerActionButtons}}>
            <Button.Secondary disabled={false} size="large" clickHandler={closeDrawerClickHandler}>
              Cancel
            </Button.Secondary>
            <Button.Primary disabled={disableConfirmButton ?? false} size="large" clickHandler={confirmButtonCallback}>
              {confirmButtonTitle}
            </Button.Primary>
          </Row>
        )}
      </Fragment>
    ),
    [
      classes.drawerActionButtons,
      actionButtons,
      closeDrawerClickHandler,
      confirmButtonCallback,
      confirmButtonTitle,
      disableConfirmButton,
    ]
  );

  return (
    <MUIDrawer
      className={classes.drawer}
      variant={variant}
      anchor="right"
      open={isOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Row justify="space-between">
        <Column>
          <Container disableGutters classes={{root: classes.root}}>
            {drawerHeader}
            {children}
            {drawerFooter}
          </Container>
        </Column>
      </Row>
    </MUIDrawer>
  );
};

const useStyles = makeStyles((theme) => {
  const DRAWER_WIDTH = '450px';
  const DRAWER_HEIGHT = `calc(100vh - ${navHeight}px - ${footerHeight}px - 45px)`;

  return {
    root: {
      height: DRAWER_HEIGHT,
    },
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
    },
    drawerHeader: {
      padding: theme.spacing(3, 3),
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    drawerHeaderTitle: {
      paddingLeft: theme.spacing(2),
    },
    drawerActionButtons: {
      padding: theme.spacing(2, 3),
      borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
  };
});

import {createSlice} from '@reduxjs/toolkit';
import {programMasterInstrumentsInitialState} from './programMasterInstruments.initialState';
import {programMasterInstrumentsReducer} from './programMasterInstruments.reducer';
import {programMasterInstrumentsExtraReducer} from './programMasterInstrumentsExtra.reducer';

export const programMasterInstrumentsSlice = createSlice({
  name: 'masterInstruments',
  initialState: programMasterInstrumentsInitialState,
  reducers: programMasterInstrumentsReducer,
  extraReducers: programMasterInstrumentsExtraReducer,
});

export const {actions: programMasterInstrumentsActions} = programMasterInstrumentsSlice;

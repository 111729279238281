import {IBindStringResponse} from 'models';

export function returnQueryStringWithParams(queryString: string, params: any) {
  if (!params || typeof params !== 'object' || Object.keys(params).length === 0) {
    return queryString;
  }

  return (
    queryString +
    '?' +
    Object.keys(params)
      .map((key) => key + '=' + params[key])
      .join('&')
  );
}

export function parseToJsonObject(str: string): any {
  let result: any;

  try {
    result = JSON.parse(str);
  } catch (e) {
    const strJson: IBindStringResponse = {
      value: str,
    };

    result = strJson;
  }

  return result;
}

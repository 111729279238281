import React, {FC} from 'react';
import {Card, CardContent, makeStyles} from '@material-ui/core';
import {ErrorBoxMessage, ErrorBoxMessageList} from './components';

interface IErrorBoxProps {
  errorMessages: string | string[];
  defaultMessage?: string;
  withMarginLeft?: boolean;
}

export const ErrorBox: FC<IErrorBoxProps> = ({
  errorMessages,
  defaultMessage = 'Something went wrong!',
  withMarginLeft = true,
}) => {
  const classes = useStyles({withMarginLeft});
  const hasMultipleErrorsList = Array.isArray(errorMessages);

  return errorMessages.length ? (
    <Card variant="outlined" classes={{root: classes.root}}>
      <CardContent>
        {hasMultipleErrorsList && <ErrorBoxMessageList errorMessages={errorMessages} defaultMessage={defaultMessage} />}
        {!hasMultipleErrorsList && <ErrorBoxMessage errorMessage={errorMessages} defaultMessage={defaultMessage} />}
      </CardContent>
    </Card>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  root: ({withMarginLeft}: Pick<IErrorBoxProps, 'withMarginLeft'>) => {
    return {
      marginLeft: withMarginLeft && theme.spacing(3),
    };
  },
}));

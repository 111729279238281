import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {Accordion} from 'components-lib';
import {fetchProgramsThunk} from 'filters-selections';
import {FormNameEnum} from 'filters-selections/enums';
import {programsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
interface IProgramSelectProps extends IWithDisabled {
  selectionType?: 'single' | 'multi';
}

export function ProgramFilter({selectionType = 'multi', disabled}: IProgramSelectProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const programs = useSelector(programsEntitiesCollectionSelector);
  const isMultiSelect = selectionType === 'multi';

  useEffect(() => {
    if (!programs.length) {
      dispatch(fetchProgramsThunk());
    }
  }, [dispatch, programs.length]);

  const options: any = useMemo(() => {
    return programs.map((program) => ({value: program.id, label: program.name}));
  }, [programs]);

  return (
    <Accordion heading="Programs">
      {isMultiSelect ? (
        <Form.SelectAutocomplete name={FormNameEnum.programs} options={options} multiple disabled={disabled} />
      ) : (
        <div className={classes.select}>
          <Form.Select name={FormNameEnum.programs} options={options} disabled={disabled} />
        </div>
      )}
    </Accordion>
  );
}

const useStyles = makeStyles({
  select: {
    width: '100%',
  },
});

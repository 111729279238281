import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {useAppDispatch} from 'hooks';
import {programsAllActions as actions, programsAllSortOrderSelector} from 'admin/programs/all/store';
import {getProgramsMenuItemsList, programsOrderingMap, SortByDropdown} from 'admin';
import {IProgramsAllSortOrder} from 'models/programs';

export const ProgramsAllSortBy = () => {
  const dispatch = useAppDispatch();
  const sortOrder = useSelector(programsAllSortOrderSelector);

  const handleItemClick = useCallback(
    (sortOrder: IProgramsAllSortOrder) => {
      dispatch(actions.setSortOrderProgramsAll(sortOrder));
    },
    [dispatch]
  );

  return (
    <SortByDropdown
      label="Sort By: "
      handleItemClick={handleItemClick}
      getMenuItemsList={getProgramsMenuItemsList}
      sortOrder={sortOrder}
      map={programsOrderingMap}
    />
  );
};

import {mapResourceMetadata} from './mapResourceMetadata';
import {mapUpdateResourceBasePayload} from './mapUpdateResourceBasePayload';
import {IEditFileMetadataFormBaseValues} from '../components/FilesEditResourceForm/config';

export const mapUpdateResourceScormPayload = (fileId: string, formValues: IEditFileMetadataFormBaseValues) => {
  const metadata = {...mapResourceMetadata(formValues), lengthInMinutes: formValues.time};
  const baseData = {data: new FormData(), fileId, metadata};
  const data = mapUpdateResourceBasePayload(baseData, formValues);

  return data;
};

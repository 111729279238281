import React, {FC} from 'react';
import {FormNameEnum} from 'filters-selections';
import {Button, Icon, Row} from 'components-lib';
import {createEmptyAnswer} from '../../utils';
import {Form} from 'forms';
import {ArrayHelpers, useFormikContext} from 'formik';
import {IModalCreateAnswerStackFormValues} from '../ModalCreateAnswerStack/config/modalCreateAnswerStackValidationSchema';
import {makeStyles} from '@material-ui/core/styles';
import {IAnswer, IChoice} from 'models/library/common/IAnswer';
import {INITIAL_ANSWER_POSITION, ONE_ANSWER_POSITION, SINGLE_ANSWER} from 'utils/items';
interface IItemAnswersCustomProps {
  choices: IAnswer[];
  withEdit?: boolean;
  withRightButton?: boolean;
  canEditAnswerInAnswerStack?: boolean;
  disableAdd?: boolean;
  disableDelete?: boolean;
}

export const ItemAnswersCustom: FC<IItemAnswersCustomProps> = ({
  choices,
  withEdit = false,
  withRightButton,
  canEditAnswerInAnswerStack = false,
  disableAdd = false,
  disableDelete = false,
}) => {
  const classes = useStyles({withRightButton});
  const {errors} = useFormikContext<IModalCreateAnswerStackFormValues>();
  const answerStackErrors = errors[FormNameEnum.customValidation];
  const answerPosition = withEdit ? choices?.length + ONE_ANSWER_POSITION : INITIAL_ANSWER_POSITION;
  const hasSingleAsnwer = choices.length === SINGLE_ANSWER;
  const hasEditPermissions = withEdit && !canEditAnswerInAnswerStack;

  return (
    <Form.Array name={FormNameEnum.choices}>
      {(arrayHelpers: ArrayHelpers) => (
        <>
          {choices.map((choice: IChoice, choiceIdx: number) => {
            const choiceNameBase: any = `${FormNameEnum.choices}[${choiceIdx}]`;

            return (
              <Row key={`choice-${choiceIdx}`} justify="flex-start" classes={{root: classes.answerRow}}>
                <Form.Checkbox name={`${choiceNameBase}.isCorrect`} checked={choice?.isCorrect} />

                <div className={classes.choiceInput}>
                  <Form.Input name={`${choiceNameBase}.text`} value={choice.text} disabled={hasEditPermissions} />
                </div>
                <Button.Icon
                  clickHandler={() => arrayHelpers.remove(choiceIdx)}
                  disabled={hasSingleAsnwer || hasEditPermissions || disableDelete}
                >
                  <Icon.DeleteIcon />
                </Button.Icon>
              </Row>
            );
          })}
          <div className={classes.addAnswerButton}>
            <Button.Secondary
              size="small"
              clickHandler={() => arrayHelpers.insert(choices.length, createEmptyAnswer(false, answerPosition))}
              disabled={!!answerStackErrors || disableAdd || hasEditPermissions}
            >
              Add Answer
            </Button.Secondary>
          </div>
        </>
      )}
    </Form.Array>
  );
};

const useStyles = makeStyles((theme) => ({
  choiceInput: {
    display: 'flex',
    flexGrow: 2,
    marginRight: theme.spacing(1),
  },
  answerRow: {
    marginBottom: theme.spacing(2),
  },
  addAnswerButton: ({withRightButton}: {withRightButton: boolean}) => {
    let styles: any = {width: '100%', marginTop: theme.spacing(2)};

    if (withRightButton) {
      styles = {
        ...styles,
        paddingRight: theme.spacing(3) + theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-end',
      };
    }

    return {
      ...styles,
    };
  },
}));

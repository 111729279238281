import React from 'react';
import {makeStyles} from '@material-ui/core';
import {TextArea} from 'components-lib';

export const AnswerOpenEnded = () => {
  const classes = useStyles();

  return (
    <div className={classes.openEnded}>
      <TextArea placeholder={'...'} rows={2} disabled />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  openEnded: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

import * as Yup from 'yup';
import {FormNameEnum} from 'enums/form';
import {IGoogleFileBaseResponse} from 'models/resources/google-sites';

export interface IGoogleSitesFormValues {
  [FormNameEnum.searchTerm]: string;
  [FormNameEnum.googleSitesList]: IGoogleFileBaseResponse[];
  [FormNameEnum.googleSitesFilteredList]: IGoogleFileBaseResponse[];
}

export const googleSitesFormValidationSchema = Yup.object().shape({
  [FormNameEnum.searchTerm]: Yup.string().nullable(),
});

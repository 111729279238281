import {QuestionAnswersTypeEnum} from 'enums';
import {IAnswerStack} from '../../../../../models/library/common/IAnswerStack';

export const useEditAnswerStack = (answerStack: IAnswerStack) => {
  const isTypeRating = answerStack?.questionTypeId === QuestionAnswersTypeEnum.ratingAnswerEnum;
  const isTypeDropdown = answerStack?.questionTypeId === QuestionAnswersTypeEnum.dropdownAnswerEnum;
  const isTypeOpenEnded = answerStack?.questionTypeId === QuestionAnswersTypeEnum.openEndedAnswerEnum;
  const isTypeMultiSelect = answerStack?.questionType === QuestionAnswersTypeEnum.checkbox;
  const isOtherType = !isTypeRating && !isTypeOpenEnded;

  return {isTypeRating, isTypeDropdown, isTypeOpenEnded, isOtherType, isTypeMultiSelect};
};

import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';
import {values} from 'utils';

const getGoogleSitesState = (state: TStateStore) => state.resources.googleSites;
const getGoogleSiteDetailsState = createSelector(getGoogleSitesState, (googleSites) => googleSites.details);
const getGoogleSitesAllState = createSelector(getGoogleSitesState, (googleSites) => googleSites.googleSites);

export const paginationSelector = createSelector(getGoogleSitesAllState, (googleSites) => googleSites.pagination);
export const pageLoadingSelector = createSelector(getGoogleSitesState, (googleSites) => googleSites.page.loading);

export const googleSitesListSelector = createSelector(getGoogleSitesAllState, (googleSitesAll) => googleSitesAll.list);

export const googleSitesListItemIdsSelector = createSelector(googleSitesListSelector, (list) => list.result);
export const googleSitesEntitiesSelector = createSelector(googleSitesListSelector, (list) => list.entities.items ?? {});
export const googleSitesEntitiesBaseCollectionSelector = createSelector(
  [googleSitesListItemIdsSelector, googleSitesEntitiesSelector],
  (ids, listItems) => {
    return ids.map((id) => {
      const item = listItems[id];

      return {
        id,
        createdTime: item?.createdTime,
        lastModifiedTime: item?.lastModifiedTime,
        mimetype: item?.mimetype,
        name: item?.name,
      };
    });
  }
);

export const googleSitesListSortOrderSelector = createSelector(
  getGoogleSitesAllState,
  (googleSites) => googleSites.sortOrder
);

export const googleSiteIntegrationDetailsSelector = createSelector(
  getGoogleSitesState,
  (googleSites) => googleSites.googleSite
);

export const googleSiteDetailsSortOrderSelector = createSelector(
  getGoogleSiteDetailsState,
  (googleSiteDetails) => googleSiteDetails.sortOrder
);

export const googleSiteDetailsHistorySelector = createSelector(
  getGoogleSiteDetailsState,
  (googleSiteDetails) => googleSiteDetails.history
);

export const googleSiteDetailsHistoryListSelector = createSelector(
  googleSiteDetailsHistorySelector,
  (history) => history.historyList
);

export const googleSiteDetailsHistoryItemsEntitiesSelector = createSelector(
  googleSiteDetailsHistoryListSelector,
  (historyList) => historyList.entities.items ?? {}
);

export const googleSiteDetailsHistoryItemsResultSelector = createSelector(
  googleSiteDetailsHistoryListSelector,
  (historyList) => historyList.result ?? []
);

export const googleSiteDetailsHistoryItemsCollectionSelector = createSelector(
  googleSiteDetailsHistoryListSelector,
  (historyList) => (historyList.entities.items ? values(historyList.entities.items) : [])
);

export const googleSiteDetailsHistoryListLoadingSelector = createSelector(
  googleSiteDetailsHistorySelector,
  (googleSiteHistory) => googleSiteHistory.loading
);

export const googleSiteDetailsHistoryListItemCopyDataSelector = createSelector(
  googleSiteDetailsHistorySelector,
  (googleSiteHistory) => googleSiteHistory.copyData
);

export const googleSiteDetailsHistoryListItemCopyingSelector = createSelector(
  googleSiteDetailsHistoryListItemCopyDataSelector,
  (copyData) => copyData.copying
);

export const googleSiteDetailsHistoryListItemCopyIntegrationIdSelector = createSelector(
  googleSiteDetailsHistoryListItemCopyDataSelector,
  (copyData) => copyData.integrationId
);

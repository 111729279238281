import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useBeforeunload} from 'react-beforeunload';
import {Prompt} from 'react-router-dom';
import {Loading, Table} from 'components-lib';
import {PageLayoutWithFixedAreas} from 'layout/components';
import {LearningPathContentFooter, LearningPathContentHeader} from './components';
import {getFilteredLPColumns, learningPathsColumns, learningPathsMessages} from '../../utils';
import {LearningPathsActionButtons, ModalClone} from '..';
import {useLearningPaths, useLearningPathsAllStoreActions} from '../../hooks';
import {ILearningPathListItem} from 'models';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';
import {useModal} from 'hooks';
import {FormContextProvider} from 'forms/formik/FormContextProvider';

export const LearningPathsContent = () => {
  const {loading, learningPathsAllIds, learningPathsAllEntities, exporting, fetchLearningPaths} = useLearningPaths();
  const {clearLearningPaths} = useLearningPathsAllStoreActions();
  const {hasPermission} = usePermissions();
  const {isOpen: isCloneModalOpen, setIsOpen: setIsCloneModalOpen} = useModal();

  const shouldFilterColumns = !hasPermission(USAAndSuperAdminRoles);
  const hasSuperAndUSAAdminPermission = hasPermission(USAAndSuperAdminRoles);
  const cloneLearningPathIdRef = useRef<string>(null);

  useEffect(() => {
    fetchLearningPaths();

    return () => {
      clearLearningPaths();
    };
  }, [fetchLearningPaths, clearLearningPaths]);

  useBeforeunload(() => {
    if (exporting) {
      return learningPathsMessages.warningUnsavedChanges;
    }
  });

  const openModalCloneClickHandler = useCallback(
    (id: string) => {
      cloneLearningPathIdRef.current = id;
      setIsCloneModalOpen(true);
    },
    [setIsCloneModalOpen]
  );

  const columns = useMemo(
    () => (shouldFilterColumns ? getFilteredLPColumns(learningPathsColumns) : learningPathsColumns),
    [shouldFilterColumns]
  );

  const rows = useMemo(() => {
    if (!learningPathsAllIds.length) {
      return [];
    }

    return learningPathsAllIds.map((key: string) => {
      const learningPath = learningPathsAllEntities[key] as ILearningPathListItem;
      const {id, title, programYear, status, resourcesCount} = learningPath;

      return {
        name: title,
        programYear: programYear ?? '',
        status: status ?? '',
        resourcesCount: resourcesCount ?? '',
        actions: (
          <LearningPathsActionButtons
            id={id}
            disabled={!hasSuperAndUSAAdminPermission}
            openModalCloneClickHandler={() => openModalCloneClickHandler(id)}
          />
        ),
      };
    });
  }, [learningPathsAllEntities, learningPathsAllIds, hasSuperAndUSAAdminPermission, openModalCloneClickHandler]);

  const hasRows = !!rows.length;

  return (
    <PageLayoutWithFixedAreas header={<LearningPathContentHeader />} footer={<LearningPathContentFooter />}>
      {loading ? <Loading /> : <Table rows={rows} columns={columns} hasRows={hasRows} />}
      {isCloneModalOpen && (
        <FormContextProvider>
          <ModalClone
            learningPathId={cloneLearningPathIdRef.current}
            isOpen={isCloneModalOpen}
            setIsOpen={setIsCloneModalOpen}
            withSubmitButton={false}
          />
        </FormContextProvider>
      )}
      <Prompt when={exporting} message={learningPathsMessages.warningUnsavedChanges} />
    </PageLayoutWithFixedAreas>
  );
};

import {PaginationNumEnum} from 'enums';
import {FormNameEnum} from 'enums/form';
import {IGoogleFileBaseResponse, IGoogleSite} from 'models/resources/google-sites';
import {useCallback, useEffect} from 'react';
import {useGoogleSitesAll} from '.';
import {MIN_LENGTH} from '../../utils';
import {calculatePaginationResult, filterGoogleSites} from '../utils';
import {useGoogleSitesSortOrder} from './useGoogleSitesSortOrder';

export const useGoogleSitesSearch = () => {
  const {searchTerm, setFieldValue} = useGoogleSitesSortOrder();
  const {googleSitesList, currentPage, setCurrentPage, perPage, setTotalCount} = useGoogleSitesAll();

  const hasSearchTerm = searchTerm.trim().length > MIN_LENGTH;
  const cloneList = useCallback((list: IGoogleSite[]) => [...list], []);

  const setFieldValues: (filteredList: IGoogleFileBaseResponse[]) => void = useCallback(
    (filteredList) => {
      setTotalCount(filteredList.length);
      const resultList = calculatePaginationResult(filteredList, currentPage, perPage);
      setFieldValue(FormNameEnum.googleSitesFilteredList, resultList);
    },
    [perPage, currentPage, setFieldValue, setTotalCount]
  );

  useEffect(() => {
    if (hasSearchTerm) {
      if (currentPage > PaginationNumEnum.ONE) {
        setCurrentPage(PaginationNumEnum.ONE);
      }

      const clonedList = cloneList(googleSitesList) as IGoogleSite[];
      const filteredList = filterGoogleSites(clonedList, searchTerm) as IGoogleSite[];

      setFieldValues(filteredList);
    } else {
      setFieldValues(googleSitesList);
    }
  }, [
    searchTerm,
    hasSearchTerm,
    googleSitesList,
    setFieldValue,
    currentPage,
    setCurrentPage,
    perPage,
    setTotalCount,
    cloneList,
    setFieldValues,
  ]);
};

import React from 'react';
import {FormNameEnum} from 'enums/form';
import {Form} from 'forms';
import {useGoogleSitesSearch} from 'admin/resources/google-sites';

export const GoogleSitesAllHeaderSearch = () => {
  useGoogleSitesSearch();

  return <Form.Search name={FormNameEnum.searchTerm} disabled={false} withIcon />;
};

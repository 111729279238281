import React from 'react';
import {Wrapper} from 'components-lib';
import {MenuItem} from '../MenuItem/MenuItem';

export const MenuItemList = ({itemClickHandler, handleItemClick, getMenuItemsList}) => {
  const list = getMenuItemsList(itemClickHandler, handleItemClick);
  return (
    <Wrapper>
      {list.map(({clickHandler, name}: any, index: number) => (
        <MenuItem key={index} clickHandlerCallback={clickHandler}>
          {name}
        </MenuItem>
      ))}
    </Wrapper>
  );
};

import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';
import {values} from 'utils';

const getItemsState = (state: TStateStore) => state.library.items;

export const itemPageLoadingStatusSelector = createSelector(getItemsState, (items) => items.page);
export const itemsForPreviewSelector = createSelector(getItemsState, (items) => items.itemList);

export const activeItemSelector = createSelector(getItemsState, (items) => items.item);
export const publishingStackSelector = createSelector(getItemsState, (items) => items.page.publishingStack);
export const savingSelector = createSelector(getItemsState, (items) => items.page.saving);
export const deletingSelector = createSelector(getItemsState, (items) => items.page.deleting);
export const promotingStackSelector = createSelector(getItemsState, (items) => items.page.promotingStack);

export const activeItemQuestionsSelector = createSelector(
  getItemsState,
  (items) => items.itemQuestions.entities.questions
);

export const activeItemQuestionsEntitiesCollectionSelector = createSelector(getItemsState, (items) =>
  values(items.itemQuestions.entities.questions)
);

export const itemsFilterSelector = createSelector(getItemsState, (items) => items.filter);
export const itemsSortByColumnsSelector = createSelector(getItemsState, (items) => items.sortByColumns);
export const itemSortOrderTypesSelector = createSelector(getItemsState, (items) => items.sortOrderTypes);
export const itemSortOrderSelector = createSelector(getItemsState, (items) => items.sortOrder);

export const paginationSelector = createSelector(getItemsState, (items) => items.pagination);

import React, {Fragment, useCallback, MouseEvent, FC} from 'react';
import {makeStyles, Menu} from '@material-ui/core';
import {Button, Divider, Icon, Loading, Row, Tooltip, Wrapper} from 'components-lib';
import {useMenu} from 'hooks';
import {menuConfig} from './config';
import {LevelReportingMenuForm, LevelReportingMenuItemList} from '..';
import {FormNameEnum} from 'enums/form';
import {Form} from 'forms';
import {useProgramReportsStoreActions} from '../../hooks';
import {ProgramDetailsViewTypeEnum} from '../..';
interface ILevelReportingMenuProps {
  programViewType?: ProgramDetailsViewTypeEnum;
}

export const LevelReportingMenu: FC<ILevelReportingMenuProps> = ({
  programViewType = ProgramDetailsViewTypeEnum.Program,
}) => {
  const classes = useStyles();
  const {anchorEl, setAnchorEl} = useMenu();
  const {selectedItem, loading} = useProgramReportsStoreActions();
  const isOpen = Boolean(anchorEl);

  const openMenuClickHandler = useCallback((e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget), [
    setAnchorEl,
  ]);

  const closeMenuClickHandler = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  return (
    <Fragment>
      <Button.Secondary
        size="small"
        aria-controls="level-reporting"
        endIcon={<Icon.ExpandIcon color="primary" />}
        clickHandler={openMenuClickHandler}
        className={classes.button}
      >
        <Tooltip title={selectedItem?.displayText}>
          <Wrapper className={classes.buttonText}>{selectedItem?.displayText}</Wrapper>
        </Tooltip>
      </Button.Secondary>
      <Menu
        id="level-reporting"
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={closeMenuClickHandler}
        PaperProps={menuConfig}
      >
        <LevelReportingMenuForm programViewType={programViewType}>
          <Form.Search name={FormNameEnum.searchTerm} disabled={loading} withIcon submitDisabled />
          <Divider.Horizontal />
          {loading ? (
            <Row>
              <Loading />
            </Row>
          ) : (
            <LevelReportingMenuItemList closeMenuClickHandler={closeMenuClickHandler} />
          )}
        </LevelReportingMenuForm>
      </Menu>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2) + theme.spacing(3),
  },
  buttonText: {
    maxWidth: 106,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

import React, {Dispatch, FC, MutableRefObject, SetStateAction, useCallback, useMemo} from 'react';
import {batch} from 'react-redux';
import {FormikHelpers, FormikProps, FormikValues} from 'formik';
import {Form} from 'forms';
import {FormNameEnum} from 'enums/form';
import {ILearningPathViewCreateFormBaseValues, learningPathViewCreateFormBaseValidationSchema} from '../../utils';
import {useCreateLearningPath} from '../..';
import {IWithChildren} from 'models/common';
import {useSessionBuilderStoreActions} from 'admin/resources/learning-paths/views/session-builder';

interface ICreateLearningPathDetailsFormProps extends IWithChildren {
  setIsDirtyCallback: Dispatch<SetStateAction<boolean>>;
  formikFormRef: MutableRefObject<FormikProps<FormikValues>>;
}

export const CreateLearningPathDetailsForm: FC<ICreateLearningPathDetailsFormProps> = ({
  children,
  formikFormRef,
  setIsDirtyCallback,
}) => {
  const {createLearningPath, redirectToViewListOLearningPaths} = useCreateLearningPath();
  const {clearBuilderItems} = useSessionBuilderStoreActions();

  const submitHandler = useCallback(
    (formValues: ILearningPathViewCreateFormBaseValues, {resetForm, setSubmitting}: FormikHelpers<any>) => {
      batch(() => {
        setIsDirtyCallback(false);
        createLearningPath(formValues).then(() => {
          clearBuilderItems();
          redirectToViewListOLearningPaths();
        });
        resetForm();
        setSubmitting(false);
      });
    },
    [createLearningPath, clearBuilderItems, redirectToViewListOLearningPaths, setIsDirtyCallback]
  );

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.learningPathName]: '',
      [FormNameEnum.briefDescription]: '',
      [FormNameEnum.futureProgramYear]: null,
      [FormNameEnum.primaryPillar]: null,
      [FormNameEnum.secondaryPillar]: null,
      [FormNameEnum.language]: null,
      [FormNameEnum.keyTerms]: null,
      [FormNameEnum.program]: null,
      [FormNameEnum.gradeLevels]: [],
      [FormNameEnum.expirationDate]: null,
      [FormNameEnum.learningObjectives]: null,
    }),
    []
  );

  return (
    <Form.Form<ILearningPathViewCreateFormBaseValues>
      formikRef={formikFormRef}
      initialValues={initialValues}
      validationSchema={learningPathViewCreateFormBaseValidationSchema}
      submitHandler={submitHandler}
    >
      {children}
    </Form.Form>
  );
};

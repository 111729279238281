import React, {FC, Fragment, useMemo} from 'react';
import {Accordion, Column, Text} from 'components-lib';
import {useSelector} from 'react-redux';
import {IOption, IWithDisabled} from 'models';
import {FormNameEnum, filterIntegrationTypesEntitiesCollectionSelector} from 'filters-selections';
import {Form} from 'forms';

interface IIntegrationTypeFilterProps extends IWithDisabled {
  selectionType?: 'single' | 'multi';
  name?: FormNameEnum;
}

export const IntegrationTypeFilter: FC<IIntegrationTypeFilterProps> = ({
  selectionType = 'multi',
  disabled,
  name = FormNameEnum.integrationTypes,
}) => {
  const isMultiSelectFilter = selectionType === 'multi';
  const integrationTypes = useSelector(filterIntegrationTypesEntitiesCollectionSelector);

  const options: IOption[] = useMemo(() => {
    return integrationTypes.map((integrationType) => ({
      value: integrationType.id,
      label: integrationType.displayText,
    }));
  }, [integrationTypes]);

  const content = useMemo(
    () => (
      <Fragment>
        {isMultiSelectFilter ? (
          <Form.SelectAutocomplete name={name} options={options} multiple disabled={disabled} />
        ) : (
          <Form.SelectAutocomplete name={name} options={options} multiple={false} disabled={disabled} />
        )}
      </Fragment>
    ),
    [isMultiSelectFilter, disabled, options, name]
  );

  return (
    <Accordion heading="Integration Type">
      <Column>
        {integrationTypes?.length ? <Fragment>{content}</Fragment> : <Text.Caption>No Types</Text.Caption>}
      </Column>
    </Accordion>
  );
};

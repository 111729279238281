export enum ProgramClassesDropdownNameEnum {
  Class_ASC = 'Class: A-Z',
  Class_DESC = 'Class: Z-A',
  BCRMClassNumber_ASC = 'BCRM Class Number: A-Z',
  BCRMClassNumber_DESC = 'BCRM Class Number: Z-A',
  SchoolName_ASC = 'School Name: A-Z',
  SchoolName_DESC = 'School Name: Z-A',
  NumberOfStudents_ASC = 'Number of Students: Asc',
  NumberOfStudents_DESC = 'Number of Students: Desc',
}

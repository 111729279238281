import {createAsyncThunk} from '@reduxjs/toolkit';
import {permissionsApi} from 'api';
import {IWithNavigationPush, TUserRolesApiResponse} from 'models';
import {paths} from 'paths';
import {permissionsManager} from '../../services';
import {prefix} from './config';

export const fetchUserRoleThunk: any = createAsyncThunk<TUserRolesApiResponse, IWithNavigationPush>(
  `${prefix} fetchUserRole`,
  async (payload: IWithNavigationPush, {rejectWithValue}) => {
    try {
      const {parsedBody: roles} = await permissionsApi.getUserRole();
      permissionsManager.setActiveUserRoles(roles.map((role) => role.value));
      return roles;
    } catch (err) {
      const {push} = payload;
      push(paths.error);
      return rejectWithValue(err);
    }
  }
);

import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/core';

import {Column, Row, Text} from 'components-lib';
import {QuestionAnswersTypeEnum} from 'enums';
import {cardTypeMap} from 'admin/library/enums';
import {borderGeneral} from 'styles';

interface ICardQuestionSimpleProps {
  type: QuestionAnswersTypeEnum;
  text: string;
  actions?: ReactNode;
  withBorder?: boolean;
  withExtraPadding?: boolean;
}

export function CardQuestionSimple({
  type,
  text,
  actions,
  withBorder = false,
  withExtraPadding = false,
}: ICardQuestionSimpleProps) {
  const classes = useStyles({withBorder, withExtraPadding});

  return (
    <Column className={classes.root}>
      <Row>
        <Column sm={10} md={10} lg={10} xl={10}>
          <Text.Paragraph variant="body2">{cardTypeMap.get(type)}</Text.Paragraph>
          <Text.Heading as="h4">{text}</Text.Heading>
        </Column>
        {actions && (
          <div className={classes.actions}>
            <Row>{actions}</Row>
          </div>
        )}
      </Row>
    </Column>
  );
}

const useStyles = makeStyles((theme) => ({
  root: ({withBorder, withExtraPadding}: Pick<ICardQuestionSimpleProps, 'withBorder' | 'withExtraPadding'>) => {
    let styles = {
      padding: withExtraPadding ? `${theme.spacing(3)}px ${theme.spacing(2) + theme.spacing(3)}px` : 0,
    };

    if (withBorder) {
      styles = {...styles, ...borderGeneral};
    }

    return styles;
  },
  actions: {
    marginLeft: 'auto',
  },
}));

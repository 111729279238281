import {createAsyncThunk} from '@reduxjs/toolkit';
import {instrumentsApi} from 'api';
import {IMetaDataDto} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

interface ICreateNewInstrumentPayload {
  metaData: IMetaDataDto;
  isGeneral: boolean;
}

export const createNewInstrumentThunk: any = createAsyncThunk<number, ICreateNewInstrumentPayload>(
  `${prefix} createNewInstrument`,
  async (payload: ICreateNewInstrumentPayload, {dispatch}) => {
    try {
      const {parsedBody} = await instrumentsApi.createNewTemplate(payload.metaData, payload.isGeneral);
      dispatch(toastNotificationManager.createSuccessToastAction('The instrument was created successfully.'));
      return parsedBody;
    } catch (err) {
      throw new Error(err.parsedBody);
    }
  }
);

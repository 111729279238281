import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Icon, Container, Row, Text, Column} from 'components-lib';
import {layoutConfig} from 'layout';

export const FileNotFoundPage = () => {
  const classes = useStyles();

  return (
    <Container classes={{root: classes.container}}>
      <Column>
        <Row>
          <Icon.ErrorNotFound />
        </Row>
        <Row>
          <Text.Heading as="h1">File not found!</Text.Heading>
        </Row>
        <Row>
          <Text.Heading as="h3">
            The file you are looking for might have been removed, had its name changed,
            <br /> or it&apos;s temporarily unavailable.
          </Text.Heading>
        </Row>
      </Column>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: `calc(100vh - ${layoutConfig.scrollableAreaWithoutSecondaryNavigationSubtrahend}px)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  h1: {
    color: theme.palette.grey[700],
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  h3: {
    color: theme.palette.grey[700],
  },
  returnButton: {
    marginTop: theme.spacing(4),
  },
}));

import {useCallback} from 'react';
import {useAppDispatch} from 'hooks';
import {googleSitesActions as actions} from '../../store';

export const useGoogleSiteStoreState = () => {
  const dispatch = useAppDispatch();

  const clearGoogleSiteState = useCallback(() => dispatch(actions.clear()), [dispatch]);

  const clearSortOrderState = useCallback(() => dispatch(actions.clearSortOrder()), [dispatch]);

  const clearHistoryState = useCallback(() => dispatch(actions.clearHistory()), [dispatch]);

  const clearHistoryCopyIntegrationIdState = useCallback(() => dispatch(actions.clearHistoryCopyIntegrationId()), [
    dispatch,
  ]);

  const setCopyIntegrationIdState = useCallback(
    (integrationId: string) => dispatch(actions.setCopyIntegrationId(integrationId)),
    [dispatch]
  );

  return {
    clearHistoryState,
    clearGoogleSiteState,
    clearSortOrderState,
    setCopyIntegrationIdState,
    clearHistoryCopyIntegrationIdState,
  };
};

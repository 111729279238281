export enum SelectionEnum {
  Audience,
  Pillar,
  PillarMultiSelect,
  EvaluationType,
  Domain,
  ProgramMultiSelection,
  ProgramSelectWithDomainDependency,
  Competencies,
  Concepts,
  AdministrationType,
  InstrumentType,
  Phase,
  SchoolLevel,
  Category,
  InstrumentName,
  Sessions,
  TagGradeLevels,
  SkillsAndBehaviors,
  Languages,
  GradeLevels,
  CompetenciesByPillar,
  K12KeyTerms,
  CompetencyByDomain,
  SessionsByProgram,
  DomainWithoutKnowledge,
  RangeFrom,
  RangeTo,
  LearningObjective,
  LearningObjectiveWithDomainDependency,
  InstrumentSubType,
  Role,
  IntegrationTypeSelect,
  AreasSingleSelection,
  AreasMultiSelection,
  SchoolNameBasedOnArea,
  SchoolDistrict,
  SchoolDistrictBasedOnGoogleSites,
  AreaSingleBasedOnGoogleSites,
  SchoolNameBasedOnAreaSelectAndGoogleSites,
  KnowledgeSkillsAndAbilities,
  FileType,
  FileTypeSelectBasedOnAttachedFile,
  PrimaryPillar,
  SecondaryPillar,
  ProgramYearMulti,
  ProgramYearSingle,
  LanguagesSingle,
  LanguagesMulti,
  ProgramsSingle,
  FutureProgramYearSingle,
  FutureProgramYearMultiple,
  Resources,
  Instruments,
  LearningPathName,
  KeyTermsInput,
  LearningObjectiveInput,
  BriefDescriptionTextArea,
  ExpirationDatePicker,
  SchoolDistrictBasedOnArea,
  SchoolNameBasedOnAreaAndDistrict,
  AreaSingleBasedOnDistrictAndGoogleSites,
  IntegrationNameInput,
  UserToImpersonateInput,
  ServiceEmailInput,
  DisplayNameInput,
  WebLinkInput,
  TimeInput,
  PermissionCheckbox,
  AdminPermissionCheckbox,
  AccessibleToStudentsRadioGroup,
  VersionFileUpload,
  CompetenciesByPillarWithInfo,
  KeyTermsWithInfo,
  LearningObjectiveInputWithInfo,
  ExpirationWithInfoDatePicker,
  KnowledgeSkillsAndAbilitiesWithInfo,
  AccessibleToStudentsWithInfo,
  TimeWithInfoInput,
  EditableExpirationDatePicker,
  ToDatePicker,
  FromDatePicker,
}

import {makeStyles} from '@material-ui/core';
import classnames from 'classnames';
import {IComponentWithClassName} from 'models';
import React from 'react';
import {ButtonBase} from './ButtonBase';
import {IButtonBaseProps} from './IButtonBaseProps';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IButtonSecondaryProps extends IButtonBaseProps, IComponentWithClassName {
  isFirst?: boolean;
}

export function ButtonSecondary({
  children,
  clickHandler,
  disabled = false,
  endIcon = null,
  size = 'large',
  type = 'button',
  isFirst = false,
  className,
}: IButtonSecondaryProps) {
  const classes = useStyles();
  return (
    <ButtonBase
      variant="outlined"
      color="secondary"
      clickHandler={clickHandler}
      disabled={disabled}
      endIcon={endIcon}
      isFirst={isFirst}
      size={size}
      type={type}
      className={classnames(classes.root, className)}
    >
      {children}
    </ButtonBase>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

import {useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {useNavigate, useAppDispatch} from 'hooks';
import {pages} from 'paths';
import {useCallback, useMemo} from 'react';
import {
  cloningLearningPathsSelector,
  duplicateLearningPathThunk,
  selectedLearningPathIdSelector,
  exportingLearningPathsSelector,
  fetchExportLearningPathThunk,
  fetchLearningPathsPagedThunk,
  learningPathsAllFilterSelector,
  learningPathsAllIdsSelector,
  learningPathsAllListSelectorEntitiesSelector,
  learningPathsAllPageLoadingSelector,
  learningPathsAllPaginationSelector,
  learningPathsAllSortOrderSelector,
} from 'admin/resources/learning-paths';
import {EntitiesPerPageEnum} from 'enums';
import {
  IDuplicateLearningPathThunkPayload,
  IFetchLearningPathsAllQueryFilters,
  ILearningPathsAllFilter,
  ILearningPathsAllSortOrder,
} from 'models/resources';
import {mapFetchLearningPathsAllPayload} from '../utils';
import {filtersYearsEntitiesCollectionSelector, learningPathsAllFiltersEntitiesSelector} from 'filters-selections';
import {createLinkForDownload} from 'utils';

export const useLearningPaths = () => {
  const {push} = useNavigate();
  const dispatch = useAppDispatch();

  const loading = useSelector(learningPathsAllPageLoadingSelector) as boolean;
  const learningPathsAllIds = useSelector(learningPathsAllIdsSelector) as string[];
  const learningPathsAllEntities = useSelector(learningPathsAllListSelectorEntitiesSelector);
  const exporting = useSelector(exportingLearningPathsSelector);
  const selectedLearningPathId = useSelector(selectedLearningPathIdSelector);
  const cloning = useSelector(cloningLearningPathsSelector);

  const filter = useSelector(learningPathsAllFilterSelector) as ILearningPathsAllFilter;
  const sortOrder = useSelector(learningPathsAllSortOrderSelector) as ILearningPathsAllSortOrder;
  const filterEntities = useSelector(learningPathsAllFiltersEntitiesSelector);
  const programYearsCollection = useSelector(filtersYearsEntitiesCollectionSelector);

  const {
    currentPage,
    perPage,
    totalCount,
  }: {
    currentPage: number;
    perPage: EntitiesPerPageEnum;
    totalCount: number;
  } = useSelector(learningPathsAllPaginationSelector);

  const redirectToCreateLearningPath: () => void = useCallback(() => {
    push(`${pages.adminPortal.learningPaths.create}`);
  }, [push]);

  const redirectToEditLearningPath: (id: string) => void = useCallback(
    (id: string) => {
      push(`${pages.adminPortal.learningPaths.editLink}/${id}`);
    },
    [push]
  );

  const fetchLearningPaths: () => void = useMemo(
    () => () => {
      const queryFilters = {
        sortOrder,
        currentPage,
        perPage,
        filter,
        programYearsCollection,
      } as IFetchLearningPathsAllQueryFilters;

      const payload = mapFetchLearningPathsAllPayload(queryFilters);

      return dispatch(fetchLearningPathsPagedThunk(payload));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, currentPage, perPage, sortOrder]
  );

  const fetchExportLearningPath: (id: string) => void = useCallback((id) => {
    return dispatch(fetchExportLearningPathThunk(id))
      .then(unwrapResult)
      .then(({blob, fileName}) => {
        const objectUrl = window.URL.createObjectURL(blob);
        createLinkForDownload(objectUrl, fileName);
        // Freeing resources in memory
        window.URL.revokeObjectURL(objectUrl);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cloneLearningPath = useCallback(
    (payload: IDuplicateLearningPathThunkPayload) => {
      return dispatch(duplicateLearningPathThunk(payload));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    loading,
    exporting,
    selectedLearningPathId,
    cloning,
    currentPage,
    perPage,
    totalCount,
    learningPathsAllIds,
    learningPathsAllEntities,
    filter,
    sortOrder,
    filterEntities,

    redirectToCreateLearningPath,
    redirectToEditLearningPath,
    fetchLearningPaths,
    fetchExportLearningPath,
    cloneLearningPath,
  };
};

import {useState} from 'react';
import {SortingOrderEnum} from 'enums';
import {IBindBaseEntity} from 'models';

export const useSortOrder = () => {
  const EN = 'en';
  const SENSITIVITY = 'base';
  const [order, setOrder] = useState(SortingOrderEnum.ASC);

  const sortBy = (list: IBindBaseEntity[], prop: string, order: SortingOrderEnum) => {
    const sorted = list.sort((a, b) => a[prop].localeCompare(b[prop], EN, {sensitivity: SENSITIVITY}));
    return order.toUpperCase() === SortingOrderEnum.ASC ? sorted : sorted.reverse();
  };

  return {order, setOrder, sortBy};
};

import {replaceWithNullIfEmptyString} from 'admin';
import {IIntegrationDetailsFormBaseValues} from 'models/integrations';

export const mapGoogleSitesSourcePayload = ({
  integrationName,
  certificate,
  serviceEmail,
  googleDriveSourceFolderLink,
}: IIntegrationDetailsFormBaseValues) => {
  const data = new FormData();

  const configServiceEmail = replaceWithNullIfEmptyString(serviceEmail);
  const configDefaultFolderLink = replaceWithNullIfEmptyString(googleDriveSourceFolderLink);
  const configIntegrationName = replaceWithNullIfEmptyString(integrationName);

  data.append('certificate', certificate ?? null);
  data.append('name', configIntegrationName);
  data.append('serviceEmailAccount', configServiceEmail);
  data.append('defaultFolderLink', configDefaultFolderLink);

  return data;
};

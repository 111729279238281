import {useSelector} from 'react-redux';
import {filtersAreasEntitiesSelector} from 'filters-selections';
import {useFormikContext} from 'formik';
import {IAddIntegrationFormBaseValues} from 'models/integrations';
import {
  getAreaStringValue,
  getIntegrationTypeStringValue,
  getSchoolDistrictStringValue,
  getSchoolNameStringValue,
} from '../utils/helpers';
import {
  addIntegrationErrorMessageSelector,
  pageLoadingSelector,
  serviceSourceEmailSelector,
} from './../../store/integrations.selectors';

export const useAddIntegration = () => {
  const {values} = useFormikContext<IAddIntegrationFormBaseValues>();
  const areas = useSelector(filtersAreasEntitiesSelector);
  const loading = useSelector(pageLoadingSelector);
  const serviceSourceEmail = useSelector(serviceSourceEmailSelector);
  const googleSitesSourceErrorMessage = useSelector(addIntegrationErrorMessageSelector);

  const {
    area,
    certificate,
    email,
    integrationName,
    integrationType,
    schoolDistrict,
    schoolName,
    clientId,
    clientSecret,
    authorityUrl,
    googleDriveSourceFolderLink,
    googleDriveDestinationFolderLink,
    masterClassroomEmailAccount,
  } = values ?? {
    area: null,
    certificate: null,
    clientId: null,
    clientSecret: null,
    email: null,
    integrationName: null,
    integrationType: null,
    schoolDistrict: null,
    schoolName: null,
    googleDriveSourceFolderLink: null,
    googleDriveDestinationFolderLink: null,
    masterClassroomEmailAccount: null,
  };

  const certificateName = certificate?.name ?? '';
  const areaName = getAreaStringValue(area, areas);
  const integrationTypeStringValue = getIntegrationTypeStringValue(integrationType);
  const schoolDistrictStringValue = getSchoolDistrictStringValue(schoolDistrict);
  const schoolNameStringValue = getSchoolNameStringValue(schoolName, values?.schoolNames);
  const hasServiceSourceEmail = !!serviceSourceEmail;
  const isGoogleSitesDestination = values?.isGoogleSitesDestination;
  const canShowGoogleSitesErrorBox =
    !hasServiceSourceEmail && googleSitesSourceErrorMessage && isGoogleSitesDestination;

  return {
    loading,
    email,
    integrationName,
    certificateName,
    areaName,
    integrationTypeStringValue,
    schoolDistrictStringValue,
    schoolNameStringValue,
    clientId,
    clientSecret,
    authorityUrl,
    googleDriveSourceFolderLink,
    googleDriveDestinationFolderLink,
    serviceSourceEmail,
    googleSitesSourceErrorMessage,
    hasServiceSourceEmail,
    isGoogleSitesDestination,
    canShowGoogleSitesErrorBox,
    masterClassroomEmailAccount,
  };
};

import {getFilesNotificationManager, IUploadFile} from 'admin';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {UploadFileStatusEnum} from '../enums';
import {getUploadingFileNumber} from '../utils';

export const useUploadManager = () => {
  const uploadFiles = useSelector(getFilesNotificationManager);
  const uploadingFilesNum = getUploadingFileNumber(uploadFiles, UploadFileStatusEnum.Uploading);
  const uploadingText = `Uploading files (${uploadingFilesNum} of ${uploadFiles?.length})`;
  const getFileManagerUploadingStatus = useCallback(
    (files) => files?.some((file: IUploadFile) => file.status === UploadFileStatusEnum.Uploading),
    []
  );

  return {
    uploadFiles,
    uploadingText,
    uploadingFilesNum,
    getFileManagerUploadingStatus,
  };
};

import {useIsDomainKnowledgeSelected} from 'filters-selections/hooks';
import {IWithDisabled} from 'models';
import React from 'react';
import {ProgramSelect} from './ProgramSelect';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProgramSelectWithDomainDependencyProps extends IWithDisabled {}

export function ProgramSelectWithDomainDependency({disabled}: IProgramSelectWithDomainDependencyProps) {
  const {isDomainKnowledgeSelected} = useIsDomainKnowledgeSelected();
  return <ProgramSelect selectionType={isDomainKnowledgeSelected ? 'single' : 'multi'} disabled={disabled} />;
}

import {FormControlLabel, makeStyles, Radio, RadioGroup} from '@material-ui/core';
import {Column, Text} from 'components-lib';
import {ProgramsSectionNavigatorEnum} from 'enums';
import {useNavigate} from 'hooks';
import {IWithDisabled} from 'models';
import {pages} from 'paths';
import {RolesEnum, usePermissions} from 'permissions';
import React, {useCallback} from 'react';
import {useParams, useRouteMatch} from 'react-router-dom';
import {toNumber} from 'utils';

const adminOptions = [
  {label: 'Show Classes', value: ProgramsSectionNavigatorEnum.CLASSES},
  {label: 'Show Master Instruments', value: ProgramsSectionNavigatorEnum.MASTER_INSTRUMENTS},
  {label: 'Show Exports & Reports', value: ProgramsSectionNavigatorEnum.REPORTS},
];

const otherOptions = [
  {label: 'Show Classes', value: ProgramsSectionNavigatorEnum.CLASSES},
  {label: 'Show Master Instruments', value: ProgramsSectionNavigatorEnum.MASTER_INSTRUMENTS},
];

const pathToViewMap = {
  [pages.adminPortal.programs.details]: ProgramsSectionNavigatorEnum.CLASSES,
  [pages.adminPortal.programs.masterInstruments]: ProgramsSectionNavigatorEnum.MASTER_INSTRUMENTS,
  [pages.adminPortal.programs.reports]: ProgramsSectionNavigatorEnum.REPORTS,
};

const viewToLinkMap = {
  [ProgramsSectionNavigatorEnum.CLASSES]: pages.adminPortal.programs.detailsLink,
  [ProgramsSectionNavigatorEnum.MASTER_INSTRUMENTS]: pages.adminPortal.programs.masterInstrumentsLink,
  [ProgramsSectionNavigatorEnum.REPORTS]: pages.adminPortal.programs.reportsLink,
};

export function ViewExpansionPanel({disabled}: IWithDisabled) {
  const {programId} = useParams<{programId: string}>();
  const {push} = useNavigate();
  const {path} = useRouteMatch();
  const classes = useStyles();
  const {hasPermission} = usePermissions();
  const hasAdminPermission = hasPermission([RolesEnum.SuperAdmin, RolesEnum.USAAdmin, RolesEnum.AreaAdmin]);
  const viewButtonOptions = hasAdminPermission ? adminOptions : otherOptions;

  const onChange = useCallback((_event: any, value: any) => push(`${viewToLinkMap[toNumber(value)]}/${programId}`), [
    programId,
    push,
  ]);

  return (
    <Column className={classes.root}>
      <Text.Heading as="h4">View</Text.Heading>
      <RadioGroup aria-label="choose_view" name="choose_view" value={pathToViewMap[path]} onChange={onChange}>
        {viewButtonOptions.map((option: any, i: number) => (
          <FormControlLabel
            value={option.value}
            control={<Radio color="primary" />}
            label={option.label}
            key={i}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </Column>
  );
}

const useStyles = makeStyles({
  root: {
    paddingLeft: 10,
  },
});

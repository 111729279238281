import {createAsyncThunk} from '@reduxjs/toolkit';
import {programsApi} from 'api';
import {IProgramBase, IWithNavigationPush} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {pages} from 'paths';

interface IFetchProgramClassPayload extends IWithNavigationPush {
  classId: number;
}

export const fetchProgramClassThunk: any = createAsyncThunk<IProgramBase, IFetchProgramClassPayload>(
  `${prefix} fetchProgramClass`,
  async ({classId, push}, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await programsApi.getProgramClassById(classId);

      return parsedBody;
    } catch (err) {
      dispatch(
        toastNotificationManager.createErrorToastAction(err.parsedBody || err.statusText || 'Unknown error occurred.')
      );

      push(pages.error);

      return rejectWithValue({error: err});
    }
  }
);

import {createAsyncThunk} from '@reduxjs/toolkit';
import {prefix} from './config';
import {programsApi} from 'api';
import {IGetPageOfProgramClassesResponse, IGetPageOfProgramClassesPayload} from 'models';
import {toastNotificationManager} from 'toast-notifications';

export const fetchProgramClassesPagedThunk: any = createAsyncThunk<
  IGetPageOfProgramClassesResponse,
  IGetPageOfProgramClassesPayload
>(
  `${prefix} fetchProgramClassesPaged`,
  async (payload: IGetPageOfProgramClassesPayload, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await programsApi.getProgramClassesPaged(payload);
      return parsedBody;
    } catch (err) {
      dispatch(
        toastNotificationManager.createErrorToastAction(err.parsedBody || err.statusText || 'Unknown error occurred.')
      );
      return rejectWithValue({error: err});
    }
  }
);

import React, {useCallback, useState} from 'react';
import {unwrapResult} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'hooks';
import {IWithModal, IWithSetSubmitting} from 'models';
import {pages} from 'paths';
import {cloneInstrumentThunk} from '../../store';
import {mapInstrumentCreateModalMetadata} from '../../utils';
import {ICreateInstrumentFormData} from '../CreateInstrumentForm';
import {ModalCloneInstrument} from './ModalCloneInstrument';
import {CloneInstrumentForm} from '../CloneInstrumentForm';

interface IModalCloneInstrumentProps extends IWithModal {
  instrumentId: number;
}

export function ModalCloneInstrumentWithForm({instrumentId, isOpen, setIsOpen}: IModalCloneInstrumentProps) {
  const dispatch = useDispatch();
  const {push, locationState} = useNavigate();
  const [error, setError] = useState<string>();

  const closeHandler = useCallback(() => setIsOpen(false), [setIsOpen]);

  const submitClickHandler = useCallback(
    (
      {
        instrumentName,
        audience,
        phase,
        pillars,
        programs,
        type,
        subType,
        isGeneralInstrument,
      }: ICreateInstrumentFormData,
      actions: IWithSetSubmitting
    ) => {
      const metaData = mapInstrumentCreateModalMetadata({
        instrumentName,
        audience,
        phase,
        pillars,
        programs,
        type,
        subType,
        isGeneralInstrument,
      });

      setError('');

      dispatch(cloneInstrumentThunk({instrumentId, metaData, isGeneral: isGeneralInstrument}))
        .then(unwrapResult)
        .then((response: number) => {
          setIsOpen(false);
          push(`${pages.adminPortal.instruments.detailsRoot}/${response}`, locationState);
          actions.setSubmitting(false);
        })
        .catch((error: Error) => {
          actions.setSubmitting(false);
          setError(error.message);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [instrumentId, locationState, setIsOpen]
  );

  return (
    <CloneInstrumentForm submitHandler={submitClickHandler}>
      <ModalCloneInstrument isOpen={isOpen} error={error} closeHandler={closeHandler} />
    </CloneInstrumentForm>
  );
}

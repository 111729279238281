import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/core';
import {Row, Text} from 'components-lib';
import {Form} from 'forms';

interface IFiltersHeaderProps {
  clearButtonClickHandler: () => void;
  searchFieldName?: string;
  customSearch?: ReactNode;
  disabled?: boolean;
}

export function FiltersHeader({
  clearButtonClickHandler,
  searchFieldName,
  customSearch = null,
  disabled,
}: IFiltersHeaderProps) {
  const classes = useStyles();
  return (
    <Row justify="flex-start" classes={{root: classes.root}}>
      <Row justify="space-between" classes={{root: classes.buttonsRow}}>
        <Text.Heading as="h2">Filter</Text.Heading>
        <div>
          <Form.ButtonClear clickHandler={clearButtonClickHandler} />
          <Form.ButtonSubmit text="Apply" isLast />
        </div>
      </Row>
      <Row justify="flex-start" classes={{root: classes.searchRow}}>
        {!!customSearch ? customSearch : <Form.Search debounceDelay={100} name={searchFieldName} disabled={disabled} />}
      </Row>
    </Row>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  buttonsRow: {
    paddingTop: theme.spacing(3),
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
  searchRow: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

import {createAsyncThunk} from '@reduxjs/toolkit';
import {uploadResourcesApi} from 'api';
import {IGetAllFileTypeOptionsApiResponse} from 'models';
import {prefix} from './config';

export const fetchUploadFileTypeOptionsThunk: any = createAsyncThunk<IGetAllFileTypeOptionsApiResponse>(
  `${prefix} fetchUploadFileTypeOptions`,
  async (_, {rejectWithValue}) => {
    try {
      const {parsedBody} = await uploadResourcesApi.getUploadFileTypeOptions();
      return parsedBody;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

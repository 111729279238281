import {useParams} from 'react-router';
import {toNumber} from 'utils';
import {useProgram} from 'admin/programs/details/hooks';
import {useProgramClass} from '../../hooks';

export const useProgramClassDetails = () => {
  const {programId: programIdRaw, classId: classIdRaw} = useParams<{
    programId: string;
    classId: string;
  }>();

  const programId = toNumber(programIdRaw);
  const classId = toNumber(classIdRaw);

  const {program, fetchProgram} = useProgram(programId);
  const {currentClass, fetchClass} = useProgramClass(classId);

  return {program, classId, currentClass, programId, fetchProgram, fetchClass};
};

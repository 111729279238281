import {IEntityBase} from 'models';

export const idProp = 'id';

export function getFormValue(collection: IEntityBase[] | IEntityBase, prop: string): number[] {
  if (Array.isArray(collection)) {
    return collection.map((item) => item[prop]);
  }

  return collection ? [collection.id] : [];
}

import React, {useCallback, useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {FormErrorBox} from 'admin/components';
import {getFormValue, idProp} from 'admin/library/utils';
import {Column, Loading, Row, Wrapper} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {Form} from 'forms';
import {ActiveModeEnum} from 'hooks';
import {useItem, useItemIdParam, useItemStateActions} from '../../../hooks';
import {ItemBaseView} from '../../../views';
import {ItemEditAnswerStack, ItemQuestion, ItemQuestionName, SidebarItem} from '../../components';
import {getAnswerStackData} from '../../utils';
import {IItemEditFormValues, itemEditFormValidationSchema} from './itemBuilderEditForm';
import {isDomainKnowledge} from 'utils';

export function ItemBuilderEditAnswerStackView() {
  let answerStack = null;
  const classes = useStyles();
  const {itemId, answerStackId} = useItemIdParam();
  const {updateAnswerStack} = useItemStateActions();
  const {item, fetchItem, pageLoading, saving} = useItem();

  if (item) {
    answerStack = getAnswerStackData(item, answerStackId);
  }

  useEffect(() => {
    if (item?.id !== itemId) {
      fetchItem(itemId);
    }
  }, [fetchItem, item, itemId]);

  const initialValues: IItemEditFormValues = useMemo(() => {
    const {id, questionTextHtml, text, questionOptionsTypeId} = item;
    const isDomainKnowledgeSelected = isDomainKnowledge(answerStack?.metaData?.domain);
    return {
      [FormNameEnum.id]: id,
      [FormNameEnum.questionAnswerStackId]: answerStack?.questionAnswerStackId,
      [FormNameEnum.isDeleted]: answerStack?.isDeleted,
      [FormNameEnum.questionType]: questionOptionsTypeId,
      [FormNameEnum.questionTypeAnswerStack]: answerStack?.questionType,
      [FormNameEnum.text]: text,
      [FormNameEnum.questionTextHtml]: questionTextHtml,
      [FormNameEnum.pillars]: getFormValue(answerStack?.metaData?.pillars, idProp),
      [FormNameEnum.evaluationTypes]: getFormValue(answerStack?.metaData?.evaluationTypes, idProp),
      [FormNameEnum.domain]: answerStack?.metaData?.domain?.id,
      [FormNameEnum.programs]: isDomainKnowledgeSelected
        ? answerStack?.metaData.programs[0]?.id
        : getFormValue(answerStack?.metaData?.programs, idProp),
      [FormNameEnum.competencies]: getFormValue(answerStack?.metaData?.competencies, idProp),
      [FormNameEnum.concepts]: getFormValue(answerStack?.metaData?.concepts, idProp),
      [FormNameEnum.administration]: answerStack?.metaData?.administration?.id,
      [FormNameEnum.schoolLevels]: getFormValue(answerStack?.metaData?.schoolLevels, idProp),
      [FormNameEnum.learningObjective]: answerStack?.metaData?.learningObjective ?? '',
      [FormNameEnum.category]: answerStack?.metaData?.category ?? '',
      [FormNameEnum.choices]: answerStack?.choices,
      [FormNameEnum.rangeFrom]: answerStack?.rangeFrom?.toString(),
      [FormNameEnum.rangeTo]: answerStack?.rangeTo?.toString(),
      [FormNameEnum.positiveScores]: answerStack?.positiveScores,
      [FormNameEnum.minimumRateDescription]: answerStack?.minimumRateDescription,
      [FormNameEnum.maximumRateDescription]: answerStack?.maximumRateDescription,
    };
  }, [item, answerStack]);

  const submitHandler = useCallback(
    async (data) => {
      updateAnswerStack(data).then(() => fetchItem(itemId));
    },
    [itemId, fetchItem, updateAnswerStack]
  );

  const content = useMemo(
    () => () => {
      return (
        <Column>
          {pageLoading || saving ? (
            <Loading fullHeight />
          ) : (
            <Row align="flex-start">
              <Column sm={9} md={9} lg={9} xl={9}>
                <Wrapper className={classes.questionContainer}>
                  <ItemQuestion mode={ActiveModeEnum.view}>
                    <ItemQuestionName />
                  </ItemQuestion>
                </Wrapper>
                {answerStack && <ItemEditAnswerStack answerStack={answerStack} />}
              </Column>
              <Column sm={3} md={3} lg={3} xl={3}>
                <Wrapper className={classes.errorBox}>
                  <FormErrorBox />
                </Wrapper>
              </Column>
            </Row>
          )}
        </Column>
      );
    },
    [pageLoading, saving, answerStack, classes.errorBox, classes.questionContainer]
  );

  return (
    <Form.Form<IItemEditFormValues>
      initialValues={initialValues}
      validationSchema={itemEditFormValidationSchema}
      submitHandler={submitHandler}
      validateOnMount={true}
    >
      <ItemBaseView Sidebar={SidebarItem} editMode={ActiveModeEnum.edit}>
        {content}
      </ItemBaseView>
    </Form.Form>
  );
}

const useStyles = makeStyles((theme) => ({
  errorBox: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  questionContainer: {
    marginRight: theme.spacing(3),
  },
}));

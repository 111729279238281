import React, {FC, Fragment} from 'react';
import {ReactComponent as CopyLinkSmallSvg} from 'resources/icons/copy_link_small.svg';
import {ReactComponent as CopyLinkSmallDisabledSvg} from 'resources/icons/copy_link_small_disabled.svg';
import {IWithDisabled} from 'models';

type ICopyLinkSmallIconProps = IWithDisabled;

export const CopyLinkSmallIcon: FC<ICopyLinkSmallIconProps> = ({disabled = false}) => {
  return <Fragment>{disabled ? <CopyLinkSmallDisabledSvg /> : <CopyLinkSmallSvg />}</Fragment>;
};

import React, {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import {ItemFilterNamesEnum} from 'admin/library/items/enums';
import {itemsActions as actions} from 'admin/library/items/store';
import {FiltersPanelItems} from '../FiltersPanelItems/FiltersPanelItems';
import {useItems} from 'admin/library/items/hooks';
import {IItemsAllFiltersFormValues, itemsAllFiltersFormValidationSchema} from './config';
import {itemsFilterList} from '../../utils';

export function ItemsAllFilters() {
  const dispatch = useDispatch();
  const {filter, pageLoading} = useItems();

  const initialValues: IItemsAllFiltersFormValues = useMemo(
    () => ({
      [FormNameEnum.pillars]: filter[ItemFilterNamesEnum.PILLARS],
      [FormNameEnum.evaluationType]: filter[ItemFilterNamesEnum.EVALUATION_TYPES],
      [FormNameEnum.domain]: filter[ItemFilterNamesEnum.DOMAINS],
      [FormNameEnum.programs]: filter[ItemFilterNamesEnum.PROGRAMS],
      [FormNameEnum.learningObjective]: filter[ItemFilterNamesEnum.LEARNING_OBJECTIVE],
      [FormNameEnum.category]: filter[ItemFilterNamesEnum.CATEGORY],
      [FormNameEnum.competency]: filter[ItemFilterNamesEnum.COMPETENCIES],
      [FormNameEnum.concept]: filter[ItemFilterNamesEnum.CONCEPTS],
      [FormNameEnum.administration]: filter[ItemFilterNamesEnum.ADMINISTRATIONS],
      [FormNameEnum.schoolLevel]: filter[ItemFilterNamesEnum.SCHOOL_LEVELS],
      [FormNameEnum.areas]: filter[ItemFilterNamesEnum.AREAS],
      [FormNameEnum.searchByName]: filter[ItemFilterNamesEnum.SEARCH_BY_NAME],
    }),
    [filter]
  );

  const submitHandler = ({
    pillars,
    evaluationType,
    domain,
    programs,
    learningObjective,
    category,
    competency,
    concept,
    administration,
    schoolLevel,
    areas,
    searchByName,
  }: IItemsAllFiltersFormValues) => {
    const programsFilter: any[] = programs instanceof Array ? programs : [programs]; // for single/multi choice in programs filter

    dispatch(
      actions.setFilterItems({
        [ItemFilterNamesEnum.PILLARS]: pillars,
        [ItemFilterNamesEnum.EVALUATION_TYPES]: evaluationType,
        [ItemFilterNamesEnum.DOMAINS]: domain,
        [ItemFilterNamesEnum.PROGRAMS]: programsFilter,
        [ItemFilterNamesEnum.LEARNING_OBJECTIVE]: learningObjective,
        [ItemFilterNamesEnum.CATEGORY]: category,
        [ItemFilterNamesEnum.COMPETENCIES]: competency,
        [ItemFilterNamesEnum.CONCEPTS]: concept,
        [ItemFilterNamesEnum.ADMINISTRATIONS]: administration,
        [ItemFilterNamesEnum.SCHOOL_LEVELS]: schoolLevel,
        [ItemFilterNamesEnum.AREAS]: areas,
        [ItemFilterNamesEnum.SEARCH_BY_NAME]: searchByName.trim().replace(/\s+/g, ' '), // trim and replace multiple spaces (works on on the actual search, the form is not updated)
      })
    );
  };

  const resetHandler = useCallback(() => {
    dispatch(actions.resetFilterItems());
  }, [dispatch]);

  return (
    <Form.Form<IItemsAllFiltersFormValues>
      initialValues={initialValues}
      validationSchema={itemsAllFiltersFormValidationSchema}
      submitHandler={submitHandler}
    >
      <FiltersPanelItems
        filterList={itemsFilterList}
        clearButtonClickHandler={resetHandler}
        searchFieldName={ItemFilterNamesEnum.SEARCH_BY_NAME}
        disableAll={pageLoading}
      />
    </Form.Form>
  );
}

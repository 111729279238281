import React, {Fragment} from 'react';
import {FormNameEnum} from 'enums/form';
import {ResourcesFormInput, ResourcesFormTextArea} from 'admin/resources';

export const SessionDetailsEdit = () => {
  return (
    <Fragment>
      <ResourcesFormInput name={FormNameEnum.sessionName} label="Name" autoFocus />
      <ResourcesFormTextArea name={FormNameEnum.sessionDescription} label="Description" />
    </Fragment>
  );
};

import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Text} from 'components-lib';

interface INoResourcesProps {
  text?: string;
}

export const NoResources: FC<INoResourcesProps> = ({text}) => {
  const classes = useStyles();
  return <Text.Paragraph className={classes.text}>{text ? text : 'No Resources'}</Text.Paragraph>;
};

const useStyles = makeStyles((theme) => ({
  text: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

import React, {FC, Fragment, useMemo} from 'react';
import {MappingTable} from '..';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IFileFieldNameProps {}

export const FileFieldName: FC<IFileFieldNameProps> = () => {
  const content = useMemo(() => <MappingTable />, []);

  return <Fragment>{content}</Fragment>;
};

export const fileEditMessages = {
  webLink: 'The Web Link',
  scormPackage: 'The SCORM package',
  file: 'The File',
  cannotOpenArchive: 'Archives cannot be open. Please download.',
  hasNotShortUrl: ' is not yet ready.',
  clickToOpen: 'Click here to open.',
  clickToDownload: 'Click here to download.',
  clickToCopy: 'Click here to copy.',
  copied: 'The resource link has been copied.',
  expiredResource: 'This resource has expired and the URL cannot be copied and shared.',
  fileDownload: 'File was downloaded successfully.',
  warningUnsavedChanges:
    'Are you sure you want to leave this page? You have unsaved changes. If you leave the page, these changes will be lost!',
  openInNewTab: 'The resource is being opened in a new tab.',
};

import React, {FC} from 'react';
import {FormNameEnum} from 'filters-selections';
import {TextInputSelection} from '../TextInputSelection/TextInputSelection';
import {IWithDisabled} from 'models/common';
interface ITimeTextInputProps extends IWithDisabled {
  infoText?: string;
}

export const TimeTextInput: FC<ITimeTextInputProps> = ({disabled, infoText}) => (
  <TextInputSelection name={FormNameEnum.time} label="Time (in min)" disabled={disabled} infoText={infoText} />
);

import {IActionFulfilled, IItemsAllSortOrder, IItemsFilter} from 'models';
import {cloneDeep} from 'utils';
import {IItemsStoreState} from './IItemsStoreState';
import {itemsInitialState} from './items.initialState';

export const itemReducer = {
  clear(state: IItemsStoreState) {
    state.item = {...itemsInitialState.item};
  },

  setFilterItems: (state: IItemsStoreState, action: IActionFulfilled<null, IItemsFilter>) => {
    state.filter = action.payload;
    resetPagination(state);
  },

  resetFilterItems: (state: IItemsStoreState) => {
    state.filter = cloneDeep(itemsInitialState.filter);
    resetPagination(state);
  },

  setCurrentPage: (state: IItemsStoreState, action: IActionFulfilled<null, number>) => {
    state.pagination.currentPage = action.payload;
  },

  setPerPage: (state: IItemsStoreState, action: IActionFulfilled<null, number>) => {
    state.pagination.perPage = action.payload;
    resetPagination(state);
  },

  setSortOrderItemsAll: (state: IItemsStoreState, action: IActionFulfilled<null, IItemsAllSortOrder>) => {
    state.sortOrder = action.payload;
    resetPagination(state);
  },
};

const resetPagination = (state: IItemsStoreState) => {
  state.pagination.currentPage = itemsInitialState.pagination.currentPage;
  state.pagination.totalCount = itemsInitialState.pagination.totalCount;
};

import * as Yup from 'yup';
import {FormNameEnum} from 'enums/form';
import {IBaseInstrumentItem} from 'models/resources';

export interface IAddSessionInstrumentsFormValues {
  [FormNameEnum.instruments]: string[];
  [FormNameEnum.selectedInstrumentsIds]: number[];
  [FormNameEnum.selectedInstruments]: IBaseInstrumentItem[];
}

export const addSessionInstrumentsFormValidationSchema = Yup.object().shape({
  [FormNameEnum.instruments]: Yup.array().of(Yup.number()),
  [FormNameEnum.selectedInstrumentsIds]: Yup.array().of(Yup.number()),
  [FormNameEnum.selectedInstruments]: Yup.mixed(),
});

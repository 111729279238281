import {createAsyncThunk} from '@reduxjs/toolkit';
import {uploadResourcesApi} from 'api';
import {ICreateWebLinkApiResponse, ICreateWebLinkPayload} from 'models/resources';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const createWebLinkThunk: any = createAsyncThunk<ICreateWebLinkApiResponse, ICreateWebLinkPayload>(
  `${prefix} createWebLink`,
  async (payload: ICreateWebLinkPayload, {dispatch, rejectWithValue}) => {
    try {
      const response = await uploadResourcesApi.createWebLink(payload);
      dispatch(toastNotificationManager.createSuccessToastAction('Web link was created successfully'));
      return response.data;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.messages));
      return rejectWithValue({error});
    }
  }
);

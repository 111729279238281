import {createAsyncThunk} from '@reduxjs/toolkit';
import {programsApi} from 'api';
import {IBindBaseEntity} from 'models';
import {prefix} from './config';
import {toastNotificationManager} from 'toast-notifications';

export const fetchSortByColumnsThunk: any = createAsyncThunk<IBindBaseEntity[]>(
  `${prefix} fetchSortByColumns`,
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await programsApi.getSortOrderColumns();
      return parsedBody;
    } catch (err) {
      dispatch(
        toastNotificationManager.createErrorToastAction(err.parsedBody || err.statusText || 'Unknown error occurred.')
      );
      return rejectWithValue({error: err});
    }
  }
);

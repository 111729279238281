import React, {useMemo} from 'react';
import {MenuItem} from '@material-ui/core';
import {Dropdown} from 'admin';
import {EntitiesPerPageEnum} from 'enums';
import {toNumber} from 'utils';

interface IEntitiesPerPageDropdownProps {
  entitiesPerPage: EntitiesPerPageEnum;
  onEntitiesPerPageChosen: (num: number) => void;
  disabled?: boolean;
}

export function EntitiesPerPageDropdown({
  entitiesPerPage,
  onEntitiesPerPageChosen,
  disabled = false,
}: IEntitiesPerPageDropdownProps) {
  const menuItems = useMemo(
    () => Object.keys(EntitiesPerPageEnum).filter((key) => !isNaN(toNumber(EntitiesPerPageEnum[key]))),
    []
  );

  return (
    <Dropdown text={`${entitiesPerPage} per page`} label="Show: ">
      {(itemClickHandler: () => void) =>
        menuItems.map((key: string) => {
          const value = toNumber(EntitiesPerPageEnum[key]);
          return (
            <MenuItem
              disabled={disabled}
              key={key}
              onClick={() => {
                itemClickHandler();
                onEntitiesPerPageChosen(value);
              }}
            >
              {value}
            </MenuItem>
          );
        })
      }
    </Dropdown>
  );
}

import {FormNameEnum} from 'enums/form';
import {IFileDetails, IFileTypeRecognition, IResourceMetadata} from 'models/resources';

export const mapEditFileInitialValues = (
  fileDetails: IFileDetails,
  fileMetadata: IResourceMetadata,
  fileTypeRecognition: IFileTypeRecognition,
  hasUploadPermissions: boolean
) => ({
  [FormNameEnum.fileType]: fileDetails?.contentType ?? null,
  [FormNameEnum.displayName]: fileDetails?.name ?? null,
  [FormNameEnum.gradeLevels]: fileMetadata?.gradeLevels ?? [],
  [FormNameEnum.competencies]: fileMetadata?.competencies ?? [],
  [FormNameEnum.languages]: fileMetadata?.languages ?? [],
  [FormNameEnum.keyTerms]: fileMetadata?.keyTerms ?? null,
  [FormNameEnum.isAccessibleToStudents]: fileMetadata?.isAccessibleToStudents?.toString() ?? null,
  [FormNameEnum.briefDescription]: fileDetails?.description ?? '',
  [FormNameEnum.programs]: fileMetadata?.programs ?? [],
  [FormNameEnum.pillars]: fileMetadata?.pillars ?? [],
  [FormNameEnum.knowledgeSkillsAndAbilities]: fileMetadata?.ksAs ?? [],
  [FormNameEnum.expirationDate]: fileMetadata?.expirationDate ?? null,
  [FormNameEnum.learningObjectives]: fileMetadata?.learningObjectives ?? '',
  [FormNameEnum.shouldDisableFileType]: true,
  [FormNameEnum.webLink]: fileDetails?.url ?? null,
  [FormNameEnum.file]: fileDetails?.file ?? null,
  [FormNameEnum.fileVersion]: fileDetails?.versionNumber ?? null,
  [FormNameEnum.versions]: fileDetails?.versionDetails?.versions,
  [FormNameEnum.hasUploadPermissions]: hasUploadPermissions ? hasUploadPermissions : false,
  [FormNameEnum.canEdit]: fileDetails?.canEdit ?? false,
  [FormNameEnum.time]: fileMetadata?.lengthInMinutes ?? null,
  [FormNameEnum.fileTypeRecognition]: fileTypeRecognition ?? {
    isResource: false,
    isScormPackage: false,
    isWebLink: false,
    isArchive: false,
    isVideo: false,
    isAudio: false,
    isImage: false,
    isPDF: false,
    isFillablePdf: false,
    isDocument: false,
    isExcel: false,
  },
});

import {IActionFulfilled} from 'models/common';
import {
  IUpdateFileResourceApiResponse,
  IUpdateScormResourceApiResponse,
  IUpdateWebLinkResourceApiResponse,
} from 'models/resources';
import {isObject} from 'utils/functions';
import {IFileDetailsStoreState} from './IFileDetailsStoreState';
import {
  deleteFileThunk,
  downloadFileByIdThunk,
  fetchFileByIdThunk,
  updateResourcesFileThunk,
  updateScormResourceThunk,
  updateWebLinkResourceThunk,
} from './thunks';

export const fileDetailsExtraReducer = {
  // fetchFileByIdThunk
  [fetchFileByIdThunk.fulfilled]: (state: IFileDetailsStoreState, action: IActionFulfilled<null, any>) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.fileDetails = data;
    }
    state.page.loading = false;
  },
  [fetchFileByIdThunk.pending]: (state: IFileDetailsStoreState) => {
    state.page.loading = true;
  },
  [fetchFileByIdThunk.rejected]: (state: IFileDetailsStoreState) => {
    state.page.loading = false;
  },

  // deleteFileThunk
  [deleteFileThunk.fulfilled]: (state: IFileDetailsStoreState) => {
    state.page.loading = false;
  },
  [deleteFileThunk.pending]: (state: IFileDetailsStoreState) => {
    state.page.loading = true;
  },
  [deleteFileThunk.rejected]: (state: IFileDetailsStoreState) => {
    state.page.loading = false;
  },

  // updateResourcesFileThunk
  [updateResourcesFileThunk.fulfilled]: (
    state: IFileDetailsStoreState,
    action: IActionFulfilled<null, IUpdateFileResourceApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.fileDetails = {...state.fileDetails, ...data, file: null};
      state.page.loading = false;
    }
  },
  [updateResourcesFileThunk.pending]: (state: IFileDetailsStoreState) => {
    state.page.loading = true;
  },
  [updateResourcesFileThunk.rejected]: (state: IFileDetailsStoreState) => {
    state.page.loading = false;
  },

  // updateScormResourceThunk
  [updateScormResourceThunk.fulfilled]: (
    state: IFileDetailsStoreState,
    action: IActionFulfilled<null, IUpdateScormResourceApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.fileDetails = {...state.fileDetails, ...data};
      state.page.loading = false;
    }
  },
  [updateScormResourceThunk.pending]: (state: IFileDetailsStoreState) => {
    state.page.loading = true;
  },
  [updateScormResourceThunk.rejected]: (state: IFileDetailsStoreState) => {
    state.page.loading = false;
  },

  // updateWebLinkResourceThunk
  [updateWebLinkResourceThunk.fulfilled]: (
    state: IFileDetailsStoreState,
    action: IActionFulfilled<null, IUpdateWebLinkResourceApiResponse>
  ) => {
    if (isObject(action.payload)) {
      const {data} = action.payload;
      state.fileDetails = {...state.fileDetails, ...data};
      state.page.loading = false;
    }
  },
  [updateWebLinkResourceThunk.pending]: (state: IFileDetailsStoreState) => {
    state.page.loading = true;
  },
  [updateWebLinkResourceThunk.rejected]: (state: IFileDetailsStoreState) => {
    state.page.loading = false;
  },

  // downloadFileByIdThunk
  [downloadFileByIdThunk.fulfilled]: (state: IFileDetailsStoreState) => {
    state.page.fileDownloading = false;
  },
  [downloadFileByIdThunk.pending]: (state: IFileDetailsStoreState) => {
    state.page.fileDownloading = true;
  },
  [downloadFileByIdThunk.rejected]: (state: IFileDetailsStoreState) => {
    state.page.fileDownloading = false;
  },
};

import React, {FC, useCallback, useMemo} from 'react';
import {FormikHelpers} from 'formik';
import {batch} from 'react-redux';
import * as Yup from 'yup';

import {Form} from 'forms';
import {IBuilderDragItem} from 'admin/library/models';
import {applyItemLinkChanges, getChildQuestion, isMatrixQuestion, mapInitialValues} from '../../utils';
import {cloneDeep, ZERO_NUMBER} from 'utils';
import {FormNameEnum} from '../../enums';

interface IModalLinkFormBaseValues {
  linkedQuestionAnswerStackId: number[];
}

interface IModalLinkItemsFormProps {
  currentBuilderItem: IBuilderDragItem;
  handleClose: () => void;
  linkBuilderItemHandler: (item: any) => void;
}

export const ModalLinkItemsForm: FC<IModalLinkItemsFormProps> = ({
  children,
  currentBuilderItem,
  handleClose,
  linkBuilderItemHandler,
}) => {
  const builderItem = cloneDeep(currentBuilderItem);
  const isMatrix = isMatrixQuestion(builderItem?.type);
  const childItem = getChildQuestion(builderItem, isMatrix);

  const initialValues: IModalLinkFormBaseValues = useMemo(() => {
    const values = mapInitialValues(isMatrix, builderItem, childItem);
    return values;
  }, [builderItem, isMatrix, childItem]);

  const submitHandler = useCallback(
    (formValues, {resetForm}: FormikHelpers<any>) => {
      const updatedItem = applyItemLinkChanges(
        isMatrix,
        builderItem,
        childItem,
        formValues[FormNameEnum.linkedQuestionAnswerStackId][ZERO_NUMBER]
      );

      batch(() => {
        linkBuilderItemHandler(updatedItem);
        handleClose();
        resetForm();
      });
    },
    [handleClose, linkBuilderItemHandler, builderItem, childItem, isMatrix]
  );

  return (
    <Form.Form
      initialValues={initialValues}
      validationSchema={modalLinkFormValidationSchema}
      submitHandler={submitHandler}
    >
      {children}
    </Form.Form>
  );
};

const modalLinkFormValidationSchema = Yup.object().shape({
  linkedQuestionAnswerStackId: Yup.array().of(Yup.number()),
});

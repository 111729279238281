import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {IProgramClassesPaperAssessmentsFilter, IProgramClassesPaperAssessmentsSortOrder} from 'models';
import {useAppDispatch} from 'hooks';
import {programClassesPaperAssessmentsActions as actions} from '../store/programClassesPaperAssessments.slice';
import {
  filterPaperAssessmentsSelector,
  paginationSelector,
  paperAssessmentsSortOrderSelector,
} from '../store/programClassesPaperAssessments.selectors';

export const useClassPaperAssessmentsStoreActions = () => {
  const dispatch = useAppDispatch();

  const filter = useSelector(filterPaperAssessmentsSelector);
  const sortOrder = useSelector(paperAssessmentsSortOrderSelector);
  const {currentPage, perPage, totalCount} = useSelector(paginationSelector);

  const setFilter: (filter: IProgramClassesPaperAssessmentsFilter) => void = useCallback(
    (filter: IProgramClassesPaperAssessmentsFilter) => dispatch(actions.setFilter(filter)),
    [dispatch]
  );

  const resetFilter: () => void = useCallback(() => dispatch(actions.resetFilter()), [dispatch]);

  const setCurrentPage: (newPage: number) => void = useCallback(
    (newPage: number) => dispatch(actions.setCurrentPage(newPage)),
    [dispatch]
  );

  const setPerPage: (perPage: number) => void = useCallback(
    (perPage: number) => dispatch(actions.setPerPage(perPage)),
    [dispatch]
  );

  const setSortOrder: (sortOrder: IProgramClassesPaperAssessmentsSortOrder) => void = useCallback(
    (sortOrder: IProgramClassesPaperAssessmentsSortOrder) => dispatch(actions.setSortOrderPaperAssessments(sortOrder)),
    [dispatch]
  );

  const clearPaperAssessments: () => void = useCallback(() => dispatch(actions.clear()), [dispatch]);

  return {
    setFilter,
    resetFilter,
    filter,
    currentPage,
    perPage,
    totalCount,
    setCurrentPage,
    setPerPage,
    setSortOrder,
    sortOrder,
    clearPaperAssessments,
  };
};

import {FiltersEnum} from 'filters-selections';

export const instrumentAllFilterList = [
  FiltersEnum.ProgramMulti,
  FiltersEnum.Pillar,
  FiltersEnum.InstrumentStatus,
  FiltersEnum.Audience,
  FiltersEnum.InstrumentType,
  FiltersEnum.Phase,
  FiltersEnum.InstrumentViewType,
];

import {RolesEnum} from 'permissions';

export const AdminRolesItems = [RolesEnum.SuperAdmin, RolesEnum.USAAdmin, RolesEnum.AreaAdmin];
export const AdminRolesItemsWithoutArea = [RolesEnum.SuperAdmin, RolesEnum.USAAdmin];
export const AdminWithStaffRolesItems = [
  RolesEnum.SuperAdmin,
  RolesEnum.USAAdmin,
  RolesEnum.AreaAdmin,
  RolesEnum.Staff,
];
export const SuperAdminRolesItems = [RolesEnum.SuperAdmin];

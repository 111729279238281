import {makeStyles} from '@material-ui/core';
import {Text} from 'components-lib';
import React from 'react';

interface ISidebarTitleProps {
  text: string;
}

export function SidebarTitle({text}: ISidebarTitleProps) {
  const classes = useStyles();
  return (
    <Text.Heading as="h4" classes={{root: classes.titles}}>
      {text}
    </Text.Heading>
  );
}

const useStyles = makeStyles((theme) => ({
  titles: {
    marginBottom: theme.spacing(3),
  },
}));

import React from 'react';
import {useFormikContext} from 'formik';
import {Row} from 'components-lib';

export const DynamicCharacters = ({maxCharacters = 500}) => {
  const {
    values: {instructionText},
  } = useFormikContext();

  const instructionTextLen = instructionText ? instructionText?.length : 0;
  const text = `Characters: ${instructionTextLen} / ${maxCharacters}`;

  return <Row justify="flex-end">{text}</Row>;
};

import {useFormikContext} from 'formik';
import {useSelector} from 'react-redux';
import {FormNameEnum} from '../enums';
import {pillarsEntitiesCollectionSelector} from '../store';
import {IUploadResourceFormBaseValues} from 'admin/resources/upload/models';

const getHasPillarsSelected = (pillars: number | number[]) => {
  if (Array.isArray(pillars)) {
    return pillars.length > 0;
  } else {
    return pillars ? true : false;
  }
};

export function useIsPillarSelected() {
  const {values, setFieldValue} = useFormikContext<IUploadResourceFormBaseValues>();
  const pillars = useSelector(pillarsEntitiesCollectionSelector);
  const pillarId = values[FormNameEnum.pillars] as any;
  const isPillarArray = Array.isArray(pillarId);
  const selectedPillars = !isPillarArray ? pillars[pillarId] : pillars.filter(({id}) => pillarId.includes(id));
  const hasPillarsSelected = getHasPillarsSelected(selectedPillars);

  return {hasPillarsSelected, pillarId, formValues: values, setFieldValue};
}

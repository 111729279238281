import {Accordion, Column, Text} from 'components-lib';
import {Form} from 'forms';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormNameEnum} from '../../../enums';
import {fetchEvaluationTypesThunk, filtersEvaluationTypeEntitiesCollectionSelector} from '../../../store';
import {IWithDisabled} from 'models';

export function EvaluationTypeFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const evaluationTypes = useSelector(filtersEvaluationTypeEntitiesCollectionSelector);

  useEffect(() => {
    if (!evaluationTypes.length) {
      dispatch(fetchEvaluationTypesThunk());
    }
  }, [evaluationTypes.length, dispatch]);

  return (
    <Accordion heading="Evaluation Type">
      <Column>
        {evaluationTypes.length ? (
          evaluationTypes.map((evaluationType) => (
            <Form.Checkbox
              key={evaluationType.id}
              name={FormNameEnum.evaluationType}
              value={evaluationType.id}
              label={evaluationType.displayText}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>No Data</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
}

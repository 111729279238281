import React, {useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {Accordion, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {Form} from 'forms';
import {useAppDispatch} from 'hooks';
import {IWithDisabled} from 'models';
import {fetchTagGradeLevelsThunk, filtersTagGradeLevelsEntitiesCollectionSelector} from 'filters-selections/store';

export function TagGradeLevelsFilter({disabled}: IWithDisabled) {
  const dispatch = useAppDispatch();
  const levels = useSelector(filtersTagGradeLevelsEntitiesCollectionSelector);

  useEffect(() => {
    if (!levels.length) {
      dispatch(fetchTagGradeLevelsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const options = useMemo(() => levels.map((level) => ({value: level.id, label: level.name})), [levels]);

  return (
    <Accordion heading="Grade Levels">
      {levels && levels.length ? (
        <Form.SelectAutocomplete name={FormNameEnum.gradeLevels} options={options} multiple disabled={disabled} />
      ) : (
        <Text.Caption>No Data</Text.Caption>
      )}
    </Accordion>
  );
}

import React, {FC} from 'react';
import {IWithDisabled} from 'models';
import {ProgramYearSelect} from './ProgramYearSelect';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProgramYearMultiSelectProps extends IWithDisabled {}

export const ProgramYearMultiSelect: FC<IProgramYearMultiSelectProps> = ({disabled}) => {
  return <ProgramYearSelect selectionType="multi" disabled={disabled} />;
};

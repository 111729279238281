import React from 'react';
import {Column, Container, Row} from 'components-lib';
import {FilesDetailsActions} from '..';
import {Subtitle} from 'admin/components';

export const FilesEditDetailsHeader = () => {
  return (
    <Row justify="flex-start">
      <Column sm={12} md={12} lg={12} xl={8}>
        <Container disableGutters>
          <Row justify="space-between">
            <Column sm={9} md={9} lg={3} xl={2}>
              <Subtitle withColor={false} withMargin={false} as="h3" color="textPrimary" title="Details" />
            </Column>
            <Column sm={3} md={3} lg={3} xl={2}>
              <FilesDetailsActions />
            </Column>
          </Row>
        </Container>
      </Column>
    </Row>
  );
};

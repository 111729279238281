import React, {FC} from 'react';
import {IconButton} from '@material-ui/core';
import {Icon, Tooltip} from 'components-lib';

interface ICardLinkInfoTooltipProps {
  clickHandler: () => void;
}

export const CardLinkInfoTooltip: FC<ICardLinkInfoTooltipProps> = ({clickHandler}) => {
  return (
    <Tooltip title="Linked Item Info">
      <IconButton aria-label="info" onClick={clickHandler}>
        <Icon.InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

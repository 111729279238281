import React, {FC, useMemo} from 'react';
import {Dropdown, MenuItem} from 'admin/components';
import {IDropdownListOptions} from 'models';
import {usePermissions} from 'permissions';
import {AllRolesExceptStudent} from 'admin/library/instruments/enums';

interface IPreviewDropdownProps {
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  previewClickHandler: () => void;
  previewWithAnswersClickHandler: () => void;
}

export const PreviewDropdown: FC<IPreviewDropdownProps> = ({
  justify = 'flex-start',
  previewClickHandler,
  previewWithAnswersClickHandler,
}) => {
  const {hasPermission} = usePermissions();
  const shouldRenderPreviewWithAnswers = hasPermission(AllRolesExceptStudent);

  const previewListOptions = useMemo(
    () => [
      {
        name: 'Preview',
        clickHandler: () => previewClickHandler(),
      },
    ],
    [previewClickHandler]
  ) as IDropdownListOptions[];

  if (shouldRenderPreviewWithAnswers && previewListOptions.length < 2) {
    previewListOptions.push({
      name: 'Preview with answers',
      clickHandler: () => previewWithAnswersClickHandler(),
    });
  }

  return (
    <Dropdown text="Preview" justify={justify} withTextButton={false} withSecondaryButton withRow={false}>
      {() =>
        previewListOptions.map(({name, clickHandler}: IDropdownListOptions, index: number) => (
          <MenuItem key={index} clickHandlerCallback={clickHandler}>
            {name}
          </MenuItem>
        ))
      }
    </Dropdown>
  );
};

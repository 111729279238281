import React, {FC, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Accordion, Row, Text} from 'components-lib';
import {FormNameEnum} from '../../../enums';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
import {filterAccessibleToStudentsEntitiesCollectionSelector} from 'filters-selections';

interface IAccessibleToStudentsOption {
  label: string;
  value: number | string | boolean;
}

type IAccessibleToStudentsRadioFilterProps = IWithDisabled;

export const AccessibleToStudentsRadioFilter: FC<IAccessibleToStudentsRadioFilterProps> = ({disabled}) => {
  const accessibleToStudents = useSelector(filterAccessibleToStudentsEntitiesCollectionSelector);

  const accessibleToStudentsOptions: IAccessibleToStudentsOption[] = useMemo(
    () => accessibleToStudents.map((option) => ({value: option.id, label: option.displayText})).reverse(),
    [accessibleToStudents]
  );

  return (
    <Accordion heading="Accessible to students">
      {accessibleToStudentsOptions && accessibleToStudentsOptions.length ? (
        <Form.RadioGroup name={FormNameEnum.isAccessibleToStudents}>
          <Row justify="flex-start">
            {accessibleToStudentsOptions.map((option: IAccessibleToStudentsOption, idx: number) => (
              <Form.Radio key={idx} value={option.value} label={option.label} disabled={disabled} />
            ))}
          </Row>
        </Form.RadioGroup>
      ) : (
        <Text.Caption>No Data</Text.Caption>
      )}
    </Accordion>
  );
};

import {createAsyncThunk} from '@reduxjs/toolkit';
import {learningPathsApi} from 'api';
import {prefix} from './config';
import {toastNotificationManager} from 'toast-notifications';
import {IGetLearningPathByIdApiResponse} from 'models/resources/learning-paths';

export const fetchLearningPathByIdThunk: any = createAsyncThunk<IGetLearningPathByIdApiResponse, string>(
  `${prefix} fetchLearningPathByIdThunk`,
  async (id: string, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await learningPathsApi.getLearningPathById(id);
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

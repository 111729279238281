import {
  FEEDBACK_NUM,
  POST_SESSION_FIRST_DIGIT,
  POST_SESSION_NUM,
  PRE_SESSION_FIRST_DIGIT,
  PRE_SESSION_NUM,
} from 'utils';

export const checkIfTypeStartsWithPreOrPostSessionFirstDigit = (type: string) =>
  type.startsWith(PRE_SESSION_FIRST_DIGIT.toString()) || type.startsWith(POST_SESSION_FIRST_DIGIT.toString());

export const hasPreAndPostSessionSelected = (hasTypeSelected: boolean, type: number) => {
  const isStartsWithDigit = type && checkIfTypeStartsWithPreOrPostSessionFirstDigit(type?.toString());

  if (hasTypeSelected) {
    if (isStartsWithDigit) {
      return true;
    }
  }
  return false;
};

export const filterSubTypesCondition = (selectedType: number, type: any) => {
  switch (selectedType) {
    case PRE_SESSION_NUM:
      return type.id > PRE_SESSION_NUM && type.id < POST_SESSION_NUM;
    case POST_SESSION_NUM:
      return type.id > POST_SESSION_NUM && type.id < FEEDBACK_NUM;
  }
};

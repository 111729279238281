import {createAsyncThunk} from '@reduxjs/toolkit';
import {addIntegrationMessages} from 'admin/integrations/add-integration';
import {integrationsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {integrationDetailsMessages} from '../../details/utils/constants';
import {prefix} from './config';

export const testMicrosoftTeamsThunk: any = createAsyncThunk(
  `${prefix} testMicrosoftTeamsThunk`,
  async (integrationId: string, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await integrationsApi.testMicrosoftTeamsIntegration(integrationId);
      dispatch(toastNotificationManager.createSuccessToastAction(integrationDetailsMessages.testIntegrationStarting));
      return parsedBody;
    } catch (error) {
      dispatch(
        toastNotificationManager.createErrorToastAction(
          error.parsedBody ?? addIntegrationMessages.unknownIntegrationError
        )
      );
      return rejectWithValue({error});
    }
  }
);

import React, {SyntheticEvent, DragEvent, useCallback, FC} from 'react';
import {useFormikContext} from 'formik';
import {makeStyles} from '@material-ui/core';
import {FormNameEnum} from 'enums/form';
import {DraggableArea, Wrapper} from 'components-lib';
import {IWithDisabled} from 'models/common';
import {UploadSingleFile} from 'admin/components';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEditIntegrationCertificateProps extends IWithDisabled {}

export const EditIntegrationCertificate: FC<IEditIntegrationCertificateProps> = ({disabled = false}) => {
  const classes = useStyles();
  const {setFieldValue} = useFormikContext<any>();

  const handleFileDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();

      const certificate = event.dataTransfer.files[0];
      setFieldValue(FormNameEnum.certificate, certificate);
    },
    [setFieldValue]
  );

  const handleDragOver = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  return (
    <Wrapper className={classes.content}>
      <DraggableArea onDrop={handleFileDrop} onDragOver={handleDragOver} disabled={disabled}>
        <UploadSingleFile
          disabled={disabled}
          title="Upload Certificate"
          position="horizontal"
          name={FormNameEnum.certificate}
          loading={false}
        />
      </DraggableArea>
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    marginBottom: theme.spacing(3),
  },
}));

import {Divider, makeStyles} from '@material-ui/core';
import React from 'react';

interface IDividerHorizontalProps {
  as?: 'div' | 'li';
  fullWidth?: boolean;
}

export function DividerHorizontal({as = 'div', fullWidth = false}: IDividerHorizontalProps) {
  const classes = useStyles({fullWidth});
  return <Divider component={as} className={classes.root} />;
}

const useStyles = makeStyles((theme) => {
  const margin = theme.spacing(3);
  return {
    root: ({fullWidth}: Pick<IDividerHorizontalProps, 'fullWidth'>) => ({
      width: fullWidth && '100%',
      marginTop: margin,
      marginBottom: margin,
    }),
  };
});

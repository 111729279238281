import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';

import {Text} from 'components-lib';

interface IEntityHeaderInstructionalTextProps {
  instructionText: string;
}

export const EntityHeaderInstructionalText: FC<IEntityHeaderInstructionalTextProps> = ({instructionText}) => {
  const classes = useStyles();

  return (
    <div className={classes.instructionText}>
      <Text.Heading as="h3">{instructionText}</Text.Heading>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  instructionText: {
    display: 'flex',
    width: '100%',
  },
}));

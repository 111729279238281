import React, {Ref} from 'react';
import {makeStyles} from '@material-ui/core';
import {Identifier} from 'dnd-core';
import {IComponentWithChildren} from 'models';
interface IDragItemProps extends IComponentWithChildren {
  drag: Ref<any>;
  handlerId?: Identifier;
}

export function DragItem({children, drag, handlerId}: IDragItemProps) {
  const classes = useStyles();
  return (
    <div ref={drag} data-handler-id={handlerId} className={classes.root}>
      {children}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';

import {toNumber} from 'utils';
import {pages as UIPages} from 'paths';
import {instrumentsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {useAppDispatch} from 'hooks';
import {IInstrumentDetailsAndParticipantSummary} from 'models';
import {PaperNodeTypeEnum} from './enums/PaperNodeTypeEnum';

import {Button} from 'components-lib';
import SurveyForPrint from './components/SurveyForPrint/SurveyForPrint';
import Footer from './components/Footer/Footer';
import HeaderLogo from './components/Header/HeaderLogo';
import {InstructionalText} from './components/InstructionalText/InstructionalText';

export function InstrumentPrintPreviewView() {
  const [instrumentData, setInstrumentData] = useState<IInstrumentDetailsAndParticipantSummary>(null);

  const urlParams = useParams<{id: string}>();
  const instrumentId = toNumber(urlParams?.id);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();

  const header = useMemo(
    () => (
      <>
        <HeaderLogo headerUrl={instrumentData?.templateData?.header?.url} />
        <InstructionalText instructionText={instrumentData?.templateData?.instructionText} />
      </>
    ),
    [instrumentData]
  );

  useEffect(() => {
    const fetchInstrumentData = async () => {
      try {
        const {parsedBody: instrumentData} = await instrumentsApi.getInstrumentItemsAndParticipantsById(instrumentId);
        if (instrumentData) {
          setInstrumentData(instrumentData);
        }
      } catch (err) {
        dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      }
    };

    fetchInstrumentData();
  }, [instrumentId, dispatch]);

  // temporarily override body and html styling
  const htmlElement = document.getElementsByTagName('html')[0];
  htmlElement.classList.add(classes.htmlOverride);
  document.body.classList.add(classes.bodyOverride);
  useEffect(() => {
    return () => {
      htmlElement.classList.remove(classes.htmlOverride);
      document.body.classList.remove(classes.bodyOverride);
    };
  }, [htmlElement.classList, classes.bodyOverride, classes.htmlOverride]);

  const printButtonHandler = () => {
    window.print();
  };

  const backToTemplateHandler = () => {
    history.push(`${UIPages.adminPortal.instruments.detailsRoot}/${instrumentId}`);
  };

  let surveysPerId: any[];
  if (instrumentData?.participantSummary) {
    surveysPerId = instrumentData?.participantSummary.map((participant) => {
      // TODO: 'JAEVALNUM' is hardcoded but should come from the backend
      const footer = (
        <Footer
          footerUrl={instrumentData?.templateData?.footer?.url}
          footerText={`JAEVALNUM ${participant.sessionTemplateIdentifier}`}
        />
      );

      return (
        <SurveyForPrint
          key={participant.userId}
          elements={instrumentData.templateData.elements}
          headline={instrumentData.templateData.templateName}
          participantList={instrumentData?.participantSummary}
          header={header}
          footer={footer}
        />
      );
    });
  }

  // add participant list pages
  if (surveysPerId?.length > 0) {
    const summaryElement = {
      type: PaperNodeTypeEnum.participantsTable,
      participantList: instrumentData?.participantSummary,
    };
    const summary = (
      <SurveyForPrint
        key={'participant-list'}
        elements={[summaryElement]}
        participantList={instrumentData?.participantSummary}
        header={header}
        headline={'Participant List'}
      />
    );
    surveysPerId.push(summary);
  }

  return (
    <>
      <div className={classes.noPrint}>
        <Button.Primary clickHandler={backToTemplateHandler}>Back</Button.Primary>
        <Button.Primary clickHandler={printButtonHandler}>Print</Button.Primary>
      </div>
      {instrumentData?.templateData ? surveysPerId : <div className={classes.noPrint}>Loading...</div>}
    </>
  );
}

const useStyles = makeStyles(() => ({
  noPrint: {
    '@media print': {
      display: 'none',
    },
    textAlign: 'center',
    zIndex: 2,
  },
  htmlOverride: {
    height: 'auto',
    overflow: 'auto',
  },
  bodyOverride: {
    width: '800px', // fixed width to ensure proper scaling and positioning of questions and pages
    height: 'auto',
    margin: 'auto',
    overflow: 'auto',
    background: 'white',
    color: 'black',
  },
}));

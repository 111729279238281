export const importItemsMessages = {
  importStarting: 'Starting Item Bank Import...',
  importSuccess: 'Items were imported successfully.',
  mappingSuccess: 'File was uploaded successfully',
  downloadSuccess: 'Template was downloaded successfully',
  downloadIssuesSuccess: 'Issues were downloaded successfully',
  warning:
    'Are you sure you want to leave this page? You have unsaved changes. If you leave the page, these changes will be lost!',
  itemsImportWarning: 'Are you sure you want to cancel the data import? This action cannot be undone!',
};

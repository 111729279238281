import React, {Dispatch, FC, Fragment, SetStateAction, useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {Column, Text} from 'components-lib';
import {SelectionEnum, SelectionListItems} from 'filters-selections';
import {useActiveItem} from 'admin/library/items/hooks';
import {DefaultAnswerStacks} from '..';
import {useFormikContext} from 'formik';
import {IModalSelectAnswerStackFormValues} from './modalSelectAnswerStackValidationSchema';

const selectionList = [
  SelectionEnum.PillarMultiSelect,
  SelectionEnum.EvaluationType,
  SelectionEnum.DomainWithoutKnowledge,
  SelectionEnum.ProgramSelectWithDomainDependency,
  SelectionEnum.AdministrationType,
  SelectionEnum.SchoolLevel,
  SelectionEnum.Category,
];

interface IModalSelectAnswerStackContentProps {
  isDirty: boolean;
  setIsDirtyCallback: Dispatch<SetStateAction<boolean>>;
}

export const ModalSelectAnswerStackContent: FC<IModalSelectAnswerStackContentProps> = ({
  isDirty,
  setIsDirtyCallback,
}) => {
  const classes = useStyles();
  const {item} = useActiveItem();
  const {dirty, isValid} = useFormikContext<IModalSelectAnswerStackFormValues>();

  useEffect(() => {
    if (dirty && isValid && !isDirty) {
      setIsDirtyCallback(true);
    } else if (!isValid) {
      setIsDirtyCallback(false);
    }
  }, [dirty, isDirty, isValid, setIsDirtyCallback]);

  const selectionListItems = useMemo(() => <SelectionListItems list={selectionList} />, []);

  const metatags = useMemo(() => <div className={classes.metatags}>{selectionListItems}</div>, [
    classes,
    selectionListItems,
  ]);

  return (
    <Column>
      <Text.Heading as="h4" classes={{root: classes.heading}}>
        Add tags to make this item easy to find later on.
      </Text.Heading>
      {metatags}
      <Text.Heading as="h4" classes={{root: classes.heading}}>
        Select an answer stack. Then, mark the correct or positive answer(s).
      </Text.Heading>
      <Fragment>
        {item?.answerStacks && (
          <Fragment>
            <DefaultAnswerStacks />
          </Fragment>
        )}
      </Fragment>
    </Column>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  heading: {
    padding: '5px 0px',
    color: theme.palette.grey[800],
  },
  metatags: {
    maxWidth: 420,
  },
}));

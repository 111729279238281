import React, {FC, useCallback, useMemo} from 'react';
import {Dropdown, MenuItem} from 'admin/components';
import {pages} from 'paths';
import {useNavigate} from 'hooks';

interface IUploadDropdownProps {
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
}

export interface IUploadDropdownOption {
  name: string;
  clickHandler: () => void;
}

export const UploadDropdown: FC<IUploadDropdownProps> = ({justify}) => {
  const {push} = useNavigate();

  const redirectToUploadFilePage = useCallback(() => push(`${pages.adminPortal.upload.file}`), [push]);
  const redirectToUploadScormPage = useCallback(() => push(`${pages.adminPortal.upload.scorm}`), [push]);
  const redirectToUploadWebLinkPage = useCallback(() => push(`${pages.adminPortal.upload.webLink}`), [push]);

  const uploadItemsListOptions = useMemo(
    () => [
      {
        name: 'File',
        clickHandler: () => redirectToUploadFilePage(),
      },
      {
        name: 'SCORM Package',
        clickHandler: () => redirectToUploadScormPage(),
      },
      {
        name: 'Web Link',
        clickHandler: () => redirectToUploadWebLinkPage(),
      },
    ],
    [redirectToUploadFilePage, redirectToUploadScormPage, redirectToUploadWebLinkPage]
  ) as IUploadDropdownOption[];

  return (
    <Dropdown text="Upload" justify={justify} withTextButton={false} withSecondaryButton withRow={false}>
      {() =>
        uploadItemsListOptions.map(({name, clickHandler}: IUploadDropdownOption, index: number) => (
          <MenuItem key={index} clickHandlerCallback={clickHandler}>
            {name}
          </MenuItem>
        ))
      }
    </Dropdown>
  );
};

import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {Prompt, useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {useBeforeunload} from 'react-beforeunload';
import {IHttpResponse} from 'api';
import {pages} from 'paths';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';
import {programsMessages, SubtitlePageViewHeader} from 'admin';
import {Button, Container, Loading, PageViewHeader, Row, Table, Text} from 'components-lib';
import {SidebarBase} from 'components-lib/sidebar/SidebarBase';
import {PageLayoutTwoCol, PageLayoutWithFixedAreas} from 'layout';
import {ViewExpansionPanel} from '../components';
import {LevelReportingMenu} from 'admin/programs/details/components';
import {toastNotificationManager} from 'toast-notifications';
import {useProgramClass} from '../hooks';
import {useProgram, useProgramReports, useProgramReportsStoreActions} from 'admin/programs/details/hooks';
import {useAppDispatch, useNavigate} from 'hooks';
import {useInstrument} from 'admin/library/instruments/details/hooks';
import {IProgramClassReportsQueryParams} from 'admin/programs/details';
import {IGetProgramReportResponse, IProgramClassReportParams} from 'models';
import {createLinkForDownload, toNumber} from 'utils';
import {
  ExportTypesEnum,
  ProgramDetailsViewTypeEnum,
  ReportExportFormatsEnum,
  ReportTypesEnum,
  ReportTypeTermsEnum,
} from 'admin/programs/details/enums';
import {columns, generateReport} from './utils';
import {reportMessages} from 'admin/programs/details/utils';
import {defaultProgramMenuOptionId} from 'admin/programs/details/store';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {IProgramComparisonResult} from 'admin/programs/details/reports';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {useUserId} from 'user';
import {IColumn} from 'models/library/common';

export const ProgramClassReportsAndExportsView = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const {programId, classId} = useParams<IProgramClassReportParams>();
  const programIdParam = toNumber(programId);
  const classIdParam = toNumber(classId);
  const {push} = useNavigate();
  const {program, fetchProgram} = useProgram(programIdParam);
  const {currentClass, fetchClass} = useProgramClass(classIdParam);
  const {
    getAreaProgramClassExport,
    getAreaProgramClassDetailedExport,
    getAreaProgramClassAggregatedExport,
    getProgramComparison,
    getMergedProgramExport,
  } = useInstrument();
  const {reportTypes, exportTypes, loading, setIsLoading} = useProgramReports();
  const {isProgramLevelAllOption, selectedItem, clearProgramReportsState, selectedId} = useProgramReportsStoreActions();
  const {hasPermission} = usePermissions();
  const {userAreaId} = useUserId();
  const [sessionId, setSessionId] = useState<number>(classIdParam);
  const [d2lId, setD2lId] = useState<number>(programIdParam);
  const [d2lMergeId, setD2lMergeId] = useState<number>(programIdParam);
  const LoadingComponent = () => <Loading />;
  const compareColumns: IColumn[] = [
    {id: 'textField', label: '', align: 'left'},
    {id: 'compareBtn', label: '', className: 'btn-col', align: 'right'},
  ];

  // Modal
  const [compareResult, setCompareResult] = useState<IProgramComparisonResult>({
    pasProgramName: program?.name,
    d2lProgramName: program?.name,
    pasColumns: '',
    d2lColumns: '',
    className: null,
    areaId: userAreaId?.toString(),
    numColsOnlyInPAS: 0,
    numColsOnlyInD2L: 0,
    numColsInBoth: 0,
    valuesOnlyInPAS: [],
    valuesOnlyInD2L: [],
    valuesInBoth: [],
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalLoading, setIsModalLoading] = useState<boolean>(false);
  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    height: '90%',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '20px',
  };

  useBeforeunload(() => {
    if (loading) {
      return reportMessages.progress;
    }
  });

  const shouldDisableAchievementReports = !isProgramLevelAllOption;

  useEffect(() => {
    fetchProgram(true);
  }, [fetchProgram]);

  useEffect(() => {
    fetchClass(true);
  }, [fetchClass]);

  useEffect(() => {
    return () => {
      clearProgramReportsState();
    };
  }, [clearProgramReportsState]);

  const filteredReportTypes = useMemo(() => {
    if (isProgramLevelAllOption) {
      return reportTypes;
    }

    return reportTypes.filter(
      (item) => !item.name.toLowerCase().includes(ReportTypeTermsEnum.Achievement.toLowerCase())
    );
  }, [reportTypes, isProgramLevelAllOption]);

  // Raw Data Export

  const getRawDataExport = useCallback(() => getAreaProgramClassExport(programIdParam, classIdParam, null), [
    classIdParam,
    programIdParam,
    getAreaProgramClassExport,
  ]);

  // Raw Data Export (Detailed)

  const getDetailedDataExport = useCallback(
    () => getAreaProgramClassDetailedExport(programIdParam, classIdParam, null),
    [classIdParam, programIdParam, getAreaProgramClassDetailedExport]
  );

  // Raw Data Export (Aggregated)

  const getAggregatedDataExport = useCallback(
    () => getAreaProgramClassAggregatedExport(programIdParam, classIdParam, null),
    [classIdParam, programIdParam, getAreaProgramClassAggregatedExport]
  );

  const getRawExport = useCallback(
    (type: ExportTypesEnum) => {
      switch (type) {
        case ExportTypesEnum.DataFileAggregated:
          return getAggregatedDataExport();
        case ExportTypesEnum.DataFileDetailed:
          return getDetailedDataExport();
        default:
          return getRawDataExport();
      }
    },
    [getRawDataExport, getDetailedDataExport, getAggregatedDataExport]
  );

  const reportRows = useMemo(() => {
    const generateReportClickHandler = async (type: ReportTypesEnum, exportFormat: ReportExportFormatsEnum) => {
      setIsLoading(true);

      const params: IProgramClassReportsQueryParams = {
        programId: programIdParam,
        programSessionId: classIdParam,
        areaId: null,
        type,
        exportFormat,
      };

      if (selectedId > defaultProgramMenuOptionId) {
        params.matchedSurveyTypesId = selectedId;
      }

      try {
        const response: IHttpResponse<IGetProgramReportResponse> = await generateReport(type, params);

        if (response.ok) {
          const {mimeType, data, fileName} = response.parsedBody;
          createLinkForDownload(`data:${mimeType};base64,${data}`, fileName);
          dispatch(toastNotificationManager.createSuccessToastAction(programsMessages.reportSuccess));
        }
      } catch (err) {
        dispatch(toastNotificationManager.createErrorToastAction(err.statusText || programsMessages.reportError));
      } finally {
        setIsLoading(false);
      }
    };

    return filteredReportTypes.map(({name, type}) => ({
      name,
      viewBtn: (
        <>
          <Button.Secondary
            size="small"
            clickHandler={() => generateReportClickHandler(type, ReportExportFormatsEnum.PDF)}
            disabled={loading}
          >
            PDF Report
          </Button.Secondary>
          <Button.Secondary
            size="small"
            clickHandler={() => generateReportClickHandler(type, ReportExportFormatsEnum.CSV)}
            disabled={loading}
          >
            Excel Report
          </Button.Secondary>
        </>
      ),
    }));
  }, [classIdParam, programIdParam, dispatch, loading, filteredReportTypes, setIsLoading, selectedId]);

  const exportRows = useMemo(() => {
    const generateExportClickHandler = async (type: ExportTypesEnum) => {
      setIsLoading(true);

      return getRawExport(type)
        .then(() => setIsLoading(false))
        .finally(() => setIsLoading(false));
    };

    if (!hasPermission(USAAndSuperAdminRoles)) {
      exportTypes.pop();
    }

    return exportTypes.map(({name, type}) => ({
      name,
      viewBtn: (
        <Fragment>
          <Button.Secondary
            size="small"
            clickHandler={() => generateExportClickHandler(type)}
            disabled={loading || shouldDisableAchievementReports}
          >
            CSV Export
          </Button.Secondary>
        </Fragment>
      ),
    }));
  }, [loading, exportTypes, setIsLoading, shouldDisableAchievementReports, getRawExport]);

  const getComparison = useCallback(() => getProgramComparison(null, d2lId, userAreaId, classIdParam), [
    getProgramComparison,
    d2lId,
    userAreaId,
  ]);

  const getMergedExport = useCallback(() => getMergedProgramExport(null, d2lId, userAreaId, classIdParam), [
    getMergedProgramExport,
    d2lId,
    userAreaId,
  ]);

  const compareRows = useMemo(() => {
    const generateMergeClickHandler = async () => {
      setIsLoading(true);
      getMergedExport()
        .then(() => setIsLoading(false))
        .finally(() => setIsLoading(false));
    };

    const generateComparisonClickHandler = async () => {
      handleOpen();
      setIsModalLoading(true);
      getComparison()
        .then((result) => {
          setCompareResult(result.payload);
          setIsModalLoading(false);
        })
        .finally(() => {
          setIsModalLoading(false);
        });
    };

    return [
      {
        textField: (
          <Fragment>
            <label>
              <TextField
                label="PAS Class Id"
                variant="outlined"
                value={sessionId}
                type="number"
                onChange={(e) => setSessionId(toNumber(e.target.value))}
                style={{marginRight: '18px'}}
              />
            </label>
            <label>
              <TextField
                label="D2L Program Id"
                variant="outlined"
                value={d2lMergeId}
                type="number"
                onChange={(e) => setD2lMergeId(toNumber(e.target.value))}
              />
            </label>
          </Fragment>
        ),
        compareBtn: (
          <Fragment>
            <Button.Secondary size="small" clickHandler={() => generateMergeClickHandler()}>
              CSV Export
            </Button.Secondary>
          </Fragment>
        ),
      },
      {
        textField: (
          <Fragment>
            <label>
              <TextField
                label="PAS Class Id"
                variant="outlined"
                value={sessionId}
                type="number"
                onChange={(e) => setSessionId(toNumber(e.target.value))}
                style={{marginRight: '18px'}}
              />
            </label>
            <label>
              <TextField
                label="D2L Program Id"
                variant="outlined"
                value={d2lId}
                type="number"
                onChange={(e) => setD2lId(toNumber(e.target.value))}
              />
            </label>
          </Fragment>
        ),
        compareBtn: (
          <Fragment>
            <Button.Secondary size="small" clickHandler={() => generateComparisonClickHandler()}>
              Compare Programs
            </Button.Secondary>
          </Fragment>
        ),
      },
    ];
  }, [userAreaId, sessionId, d2lId]);

  const headerSubheading = useMemo(
    () =>
      program && (
        <SubtitlePageViewHeader>
          {' '}
          / {program?.name} / {currentClass?.name}
        </SubtitlePageViewHeader>
      ),
    [currentClass, program]
  );

  const header = useMemo(() => {
    return (
      <PageViewHeader
        heading="Programs"
        subHeading={headerSubheading}
        withBackButton
        backButtonClickHandler={() => push(`${pages.adminPortal.programs.detailsLink}/${programId}`)}
      />
    );
  }, [headerSubheading, programId, push]);

  const content = useMemo(() => {
    return (
      <PageLayoutWithFixedAreas header={header} headerSize="small" withSecondaryNavigation={false}>
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <Container disableGutters classes={{root: classes.exports}}>
              <Row justify="flex-start">
                <Text.Heading as="h3">Exports</Text.Heading>
              </Row>
              <Container disableGutters classes={{root: classes.exportsTable}}>
                <Text.Heading as="h4">Aggregated program-level data</Text.Heading>
                <Table columns={columns} rows={exportRows} />
              </Container>
            </Container>

            <Container>
              <Row justify="space-between">
                <Text.Heading as="h3">Reports</Text.Heading>
                <LevelReportingMenu programViewType={ProgramDetailsViewTypeEnum.Class} />
              </Row>
              {selectedItem && <Text.Heading as="h4">{selectedItem?.displayText}</Text.Heading>}
              <Table columns={columns} rows={reportRows} />
            </Container>

            {hasPermission(USAAndSuperAdminRoles) && (
              <Container disableGutters classes={{root: classes.exports}}>
                <Row justify="flex-start">
                  <Text.Heading as="h3">Compare PAS and D2L Data</Text.Heading>
                </Row>
                <Container disableGutters classes={{root: classes.exportsTable}}>
                  <Table columns={compareColumns} rows={compareRows} />
                </Container>
              </Container>
            )}
          </Fragment>
        )}
        <Prompt when={loading} message={reportMessages.progress} />

        {hasPermission(USAAndSuperAdminRoles) && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box css={style}>
              {modalLoading ? (
                <Loading />
              ) : (
                <Container>
                  <Typography id="modal-modal-title" variant="h1">
                    Program Comparison
                  </Typography>

                  {compareResult.className && (
                    <Typography variant="h2">Class Name: {compareResult.className}</Typography>
                  )}

                  {compareResult.areaId && <Typography variant="h2">Area Id: {compareResult.areaId}</Typography>}

                  <Grid container className={classes.grid} spacing={4}>
                    <Grid item xs={4} className={classes.item}>
                      <Typography variant="h3">PAS</Typography>
                      <Typography variant="h4" style={{marginBottom: '0px'}}>
                        {compareResult.pasProgramName}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.item}>
                      <Typography variant="h3">D2L</Typography>
                      <Typography variant="h4" style={{marginBottom: '0px'}}>
                        {compareResult.d2lProgramName}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.item}>
                      <Typography variant="h3">Both</Typography>
                    </Grid>

                    <Grid item xs={4} zeroMinWidth>
                      {compareResult.valuesOnlyInPAS?.map((item, index) => {
                        return (
                          <Typography
                            variant="caption"
                            component="div"
                            key={index}
                            style={{overflowWrap: 'break-word', marginBottom: '4px'}}
                          >
                            {item}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Grid item xs={4} zeroMinWidth>
                      {compareResult.valuesOnlyInD2L?.map((item, index) => {
                        return (
                          <Typography
                            variant="caption"
                            component="div"
                            key={index}
                            style={{overflowWrap: 'break-word', marginBottom: '4px'}}
                          >
                            {item}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Grid item xs={4} zeroMinWidth>
                      {compareResult.valuesInBoth?.map((item, index) => {
                        return (
                          <Typography
                            variant="caption"
                            component="div"
                            key={index}
                            style={{overflowWrap: 'break-word', marginBottom: '4px'}}
                          >
                            {item}
                          </Typography>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Container>
              )}
            </Box>
          </Modal>
        )}
      </PageLayoutWithFixedAreas>
    );
  }, [header, reportRows, loading, classes.exports, classes.exportsTable, exportRows, selectedItem]);

  const sidebar = useMemo(() => {
    return (
      <SidebarBase>
        <ViewExpansionPanel disabled={loading} />
      </SidebarBase>
    );
  }, [loading]);

  return <PageLayoutTwoCol withSecondaryNavigation={false} sidebar={sidebar} content={content} />;
};

const useStyles = makeStyles((theme) => ({
  exports: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    marginBottom: theme.spacing(3),
  },
  exportsTable: {
    paddingBottom: theme.spacing(2),
  },
  grid: {
    height: '100%',
  },
  item: {
    paddingBottom: '0px!important',
  },
}));

import {createAsyncThunk} from '@reduxjs/toolkit';
import {pamApi} from 'api';
import {IGetInstrumentsApiResponse, IGetInstrumentsThunkPayload} from 'models/resources';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchInstrumentsThunk: any = createAsyncThunk<IGetInstrumentsApiResponse, null>(
  `${prefix} fetchResources`,
  async (payload: IGetInstrumentsThunkPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await pamApi.getAllInstruments(payload);
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Text} from 'components-lib';
import {EntityStatusEnum} from 'enums';

interface IStatusProps {
  status: EntityStatusEnum;
  loading?: boolean;
  message?: string;
}

const getStatusText = (status: EntityStatusEnum, message: string) => {
  if (status === EntityStatusEnum.live) {
    return EntityStatusEnum.published;
  }

  if (status === EntityStatusEnum.error) {
    return message;
  }

  return status;
};

export function Status({status, loading, message}: IStatusProps) {
  const isLive = status === EntityStatusEnum.live;
  const isPublished = status === EntityStatusEnum.published;
  const isLiveOrPublished = isLive || isPublished;

  const statusText = getStatusText(status, message);

  const classes = useStyles({status, isLiveOrPublished});

  return <Text.Paragraph>Status: {<span className={classes.status}>{loading ? '' : statusText}</span>}</Text.Paragraph>;
}
interface IStatusStyles {
  isLiveOrPublished: boolean;
}

const useStyles = makeStyles((theme) => ({
  status: ({isLiveOrPublished}: IStatusStyles) => {
    return {
      color: !isLiveOrPublished ? theme.palette.error.main : theme.palette.secondary.main,
    };
  },
}));

import {Typography} from '@material-ui/core';
import {IComponentWithChildren} from 'models';
import React from 'react';
interface IHeadingProps extends IComponentWithChildren {
  classes?: {[key: string]: any};
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  color?: 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'error';
}

export function Heading({as, classes, children, color}: IHeadingProps) {
  return (
    <Typography variant={as} classes={classes} color={color}>
      {children}
    </Typography>
  );
}

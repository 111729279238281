import React, {FC, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {FormNameEnum} from 'filters-selections';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {fetchTagGradeLevelsThunk, filtersTagGradeLevelsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {useAppDispatch} from 'hooks';
import {IWithDisabled} from 'models/common';

type ITagGradeLevelSelectProps = IWithDisabled;

export const TagGradeLevelSelect: FC<ITagGradeLevelSelectProps> = ({disabled}) => {
  const dispatch = useAppDispatch();
  const gradeLevels = useSelector(filtersTagGradeLevelsEntitiesCollectionSelector);

  useEffect(() => {
    if (!gradeLevels.length) {
      dispatch(fetchTagGradeLevelsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const options = useMemo(() => gradeLevels.map((gradeLevels) => ({value: gradeLevels.id, label: gradeLevels.name})), [
    gradeLevels,
  ]);

  return (
    <ItemWithPlaceholder label="Grade Levels" hasItems={!!gradeLevels.length}>
      <Form.SelectAutocomplete
        name={FormNameEnum.gradeLevels}
        options={options}
        multiple
        disableCloseOnSelect
        disabled={disabled}
      />
    </ItemWithPlaceholder>
  );
};

import {IActionFulfilled} from 'models/common';
import {IFilesAllFilter, IFilesAllSortOrder} from 'models/resources/files';
import {cloneDeep} from 'utils/functions';
import {filesAllInitialState} from './filesAll.initialState';
import {IFilesAllStoreState} from './IFilesAllStoreState';

export const filesAllReducer = {
  clear(state: IFilesAllStoreState) {
    state.files = {...filesAllInitialState.files};
  },

  clearFile(state: IFilesAllStoreState) {
    state.file = {...filesAllInitialState.file};
  },

  setCurrentPage: (state: IFilesAllStoreState, action: IActionFulfilled<null, number>) => {
    state.pagination.currentPage = action.payload;
  },

  setPerPage: (state: IFilesAllStoreState, action: IActionFulfilled<null, number>) => {
    state.pagination.perPage = action.payload;
    resetPagination(state);
  },

  setSortOrderFilesAll: (state: IFilesAllStoreState, action: IActionFulfilled<null, IFilesAllSortOrder>) => {
    state.sortOrder = action.payload;
  },

  setFilterFilesAll: (state: IFilesAllStoreState, action: IActionFulfilled<null, IFilesAllFilter>) => {
    state.filter = action.payload;
    resetPagination(state);
  },

  resetFilterFilesAll: (state: IFilesAllStoreState) => {
    state.filter = cloneDeep(filesAllInitialState.filter);
    resetPagination(state);
  },
};

const resetPagination = (state: IFilesAllStoreState) => {
  state.pagination.currentPage = filesAllInitialState.pagination.currentPage;
  state.pagination.totalCount = filesAllInitialState.pagination.totalCount;
};

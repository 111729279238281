import {filtersSelectionsInitialState} from './filtersSelections.initialState';
import {IFiltersSelectionsStoreState} from './IFiltersSelectionsStoreState';

export const filtersReducer = {
  clearSchools(state: IFiltersSelectionsStoreState) {
    state.schoolNames = {...filtersSelectionsInitialState.schoolNames};
  },
  clearResources(state: IFiltersSelectionsStoreState) {
    state.resources = {...filtersSelectionsInitialState.resources};
  },
  clearInstruments(state: IFiltersSelectionsStoreState) {
    state.initialMappedInstruments = {...filtersSelectionsInitialState.initialMappedInstruments};
  },
};

import {ALL_AREAS_INDEX} from 'utils';
import {IFetchAreaProgramClassQueryParams} from '../../models';

export const mapAreaProgramClassExport = (
  programId: number,
  sessionId?: number,
  areaId?: number
): IFetchAreaProgramClassQueryParams => {
  const hasAreaId = !!areaId;
  const isSingleArea = hasAreaId && areaId > ALL_AREAS_INDEX;

  if (sessionId) {
    return {programId, sessionId};
  }

  if (isSingleArea) {
    return {programId, areaId};
  }

  return {
    programId,
  };
};

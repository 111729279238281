import {createAsyncThunk} from '@reduxjs/toolkit';
import {learningPathsApi} from 'api';
import {
  IDuplicateLearningPathApiResponse,
  IDuplicateLearningPathPayload,
  IDuplicateLearningPathThunkPayload,
} from 'models/resources/learning-paths';
import {toastNotificationManager} from 'toast-notifications';
import {learningPathsMessages} from '../../utils';
import {prefix} from './config';

export const duplicateLearningPathThunk: any = createAsyncThunk<
  IDuplicateLearningPathApiResponse,
  IDuplicateLearningPathThunkPayload
>(
  `${prefix} duplicateLearningPath`,
  async (
    {learningPathId, program, expirationDate}: IDuplicateLearningPathThunkPayload,
    {dispatch, rejectWithValue}
  ) => {
    try {
      const payload = mapPayloadForDuplicate(program, expirationDate);
      const {parsedBody} = await learningPathsApi.postDuplicateLearningPath(learningPathId, payload);
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(learningPathsMessages.errorDuplicating));
      return rejectWithValue(err);
    }
  }
);

function mapPayloadForDuplicate(program: number, expirationDate: string | Date): IDuplicateLearningPathPayload {
  return {
    programId: program,
    expirationDate,
  };
}

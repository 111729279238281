import {createAsyncThunk} from '@reduxjs/toolkit';
import {permissionsApi} from 'api';
import {prefix} from './config';

export const fetchUserRoleListThunk: any = createAsyncThunk(
  `${prefix} fetchUserRoleList`,
  async (_, {rejectWithValue}) => {
    try {
      const {parsedBody} = await permissionsApi.getUserRolesList();
      return parsedBody;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import React, {FC, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Accordion, Column} from 'components-lib';
import {Form} from 'forms';
import {
  delayTime,
  dynamicSearchMessages,
  fetchSchoolDistrictThunk,
  FormNameEnum,
  loadingStateDistricts,
  schoolDistrictsEntitiesCollectionSelector,
} from 'filters-selections';
import {IOption, IWithDisabled} from 'models/common';
import {useDebounce} from 'hooks';

type ISchoolDistrictFilterMultiProps = IWithDisabled;

export const SchoolDistrictFilterMulti: FC<ISchoolDistrictFilterMultiProps> = ({disabled}) => {
  const dispatch = useDispatch();
  const schoolDistricts = useSelector(schoolDistrictsEntitiesCollectionSelector);
  const loading = useSelector(loadingStateDistricts);

  const options: IOption[] = useMemo(() => {
    if (loading) {
      return [];
    }

    return schoolDistricts
      ? schoolDistricts.map((schoolDistrict) => ({value: schoolDistrict.id, label: schoolDistrict.displayText}))
      : [];
  }, [schoolDistricts, loading]);

  const debouncedSearch = useDebounce((query: string) => dispatch(fetchSchoolDistrictThunk(query)), delayTime);

  const handleInputChange = useCallback((value: string) => !!value && !!value.trim() && debouncedSearch(value.trim()), [
    debouncedSearch,
  ]);

  return (
    <Accordion heading="School Districts">
      <Column>
        <Form.SearchSelectAutocomplete
          isFilter
          multiple
          disableCloseOnSelect
          loading={loading}
          name={FormNameEnum.schoolDistricts}
          options={options}
          disabled={disabled || loading}
          noOptionsText={dynamicSearchMessages.noOptionsSchoolDistrict}
          inputChangeHandler={handleInputChange}
          withFontWeightBold={true}
          withCheckboxRenderOption={false}
        />
      </Column>
    </Accordion>
  );
};

import React from 'react';
import {makeStyles} from '@material-ui/core';

export const AnswerComponentWrapper = ({children}) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

const useStyles = makeStyles((theme) => ({
  root: () => ({
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }),
}));

import React, {Fragment, useMemo} from 'react';
import {Box} from '@material-ui/core';
import {FormErrorBox} from 'admin/components';
import {Column, Row, Loading} from 'components-lib';
import {IItemDetails} from 'models';
import {ItemQuestion, ItemQuestionEdit} from '../../../../components';
import {ActiveModeEnum} from 'admin/library/items/enums';
import {useItems} from 'admin/library/items/hooks';

interface IItemBuilderCreateContentProps {
  item: IItemDetails;
  questionId: number;
  loadingPageSection: boolean;
  pageLoading: boolean;
}

export function ItemBuilderCreateContent({loadingPageSection}: IItemBuilderCreateContentProps) {
  const {saving} = useItems();
  const loading = saving || loadingPageSection;

  const errorBox = useMemo(
    () => (
      <Fragment>
        {!loadingPageSection && (
          <Box
            mt={5}
            mr={3}
            css={{
              paddingTop: '4px',
            }}
          >
            <FormErrorBox />
          </Box>
        )}
      </Fragment>
    ),
    [loadingPageSection]
  );

  const content = useMemo(
    () => (
      <Row align="flex-start" justify="space-between">
        <Column sm={8} md={8} lg={8} xl={8}>
          <ItemQuestion mode={ActiveModeEnum.create}>
            <ItemQuestionEdit />
          </ItemQuestion>
        </Column>
        <Column sm={4} md={4} lg={4} xl={4}>
          {errorBox}
        </Column>
      </Row>
    ),
    [errorBox]
  );

  return <Column>{loading ? <Loading /> : content}</Column>;
}

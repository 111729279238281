import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';

import {useAppDispatch, useNavigate} from 'hooks';
import {
  IItemBankTableRow,
  IItemsImportFieldDetails,
  IUploadItemBankPayload,
  TGetImportTemplateApiResponse,
} from 'models';
import {createLinkForDownload, prepareMultiPartFormData} from 'utils';
import {
  fetchImportCriticalIssuesThunk,
  fetchImportInstructionalTextThunk,
  fetchImportItemsTemplateThunk,
  fetchItemBankPreviewTableRowsThunk,
  importItemsThunk,
  uploadItemBankThunk,
} from '../store/thunks';
import {
  downloadingSelector,
  loadingSelector,
  templateInstructionalTextSelector,
  uploadingSelector,
  importingSelector,
  itemsImportFieldListSelector,
  hasRequiredFieldErrorsSelector,
  itemsImportFileNameSelector,
  itemImportIdSelector,
  itemImportPreviewRowsSelector,
  itemImportCriticalIssueCountSelector,
  itemImportPreviewLoadingSelector,
  downloadingCriticalIssuesSelector,
} from './../store/itemsImport.selectors';
import {itemsImportActions as actions} from '../store/itemsImport.slice';
import {pages} from 'paths';

const defaultCriticalIssuesCount = 0;

export const useItemsImport = () => {
  const dispatch = useAppDispatch();
  const {push} = useNavigate();
  const downloading = useSelector(downloadingSelector) as boolean;
  const downloadingCriticalIssues = useSelector(downloadingCriticalIssuesSelector) as boolean;
  const loading = useSelector(loadingSelector) as boolean;
  const uploading = useSelector(uploadingSelector) as boolean;
  const importing = useSelector(importingSelector) as boolean;
  const previewLoading = useSelector(itemImportPreviewLoadingSelector) as boolean;
  const templateInstructionalText = useSelector(templateInstructionalTextSelector) as string;
  const fieldList = useSelector(itemsImportFieldListSelector) as IItemsImportFieldDetails[];
  const hasRequiredFieldErrors = useSelector(hasRequiredFieldErrorsSelector) as boolean;
  const fileName = useSelector(itemsImportFileNameSelector) as string;
  const itemImportId = useSelector(itemImportIdSelector) as number;
  const itemImportPreviewRows = useSelector(itemImportPreviewRowsSelector) as IItemBankTableRow[];
  const itemImportCriticalIssueCount = useSelector(itemImportCriticalIssueCountSelector) as number;

  const shouldDisableImport = itemImportCriticalIssueCount > defaultCriticalIssuesCount;

  const redirectToViewListOfQuestions = useCallback(() => {
    push(`${pages.adminPortal.items.root}`);
  }, [push]);

  const downloadTemplate = useCallback(
    () =>
      dispatch(fetchImportItemsTemplateThunk())
        .then(unwrapResult)
        .then((parsedBody: TGetImportTemplateApiResponse) => {
          const {mimeType, data, fileName} = parsedBody;
          createLinkForDownload(`data:${mimeType};base64,${data}`, fileName);
        }),
    [dispatch]
  );

  const downloadIssues = useCallback(
    () =>
      dispatch(fetchImportCriticalIssuesThunk(itemImportId))
        .then(unwrapResult)
        .then(({mimeType, data, fileName}: TGetImportTemplateApiResponse) => {
          createLinkForDownload(`data:${mimeType};base64,${data}`, fileName);
        }),
    [dispatch, itemImportId]
  );

  const fetchImportInstructionalText = useCallback(() => dispatch(fetchImportInstructionalTextThunk()), [dispatch]);

  const uploadItemBank = useCallback(
    (payload: IUploadItemBankPayload) =>
      dispatch(uploadItemBankThunk(prepareMultiPartFormData(payload))).then(unwrapResult),
    [dispatch]
  );

  const resetMappingState = useCallback(() => dispatch(actions.resetMapping()), [dispatch]);

  const fetchItemBankPreviewTableRows = useCallback(() => dispatch(fetchItemBankPreviewTableRowsThunk(itemImportId)), [
    dispatch,
    itemImportId,
  ]);

  const startItemBankImport = useCallback(
    (resetFormCallback: () => void) =>
      dispatch(importItemsThunk(itemImportId))
        .then(() => redirectToViewListOfQuestions())
        .then(() => resetFormCallback()),
    [dispatch, itemImportId, redirectToViewListOfQuestions]
  );

  return {
    loading,
    downloading,
    uploading,
    importing,
    previewLoading,
    downloadingCriticalIssues,
    fieldList,
    fileName,
    hasRequiredFieldErrors,
    templateInstructionalText,
    itemImportPreviewRows,
    itemImportCriticalIssueCount,
    shouldDisableImport,

    downloadTemplate,
    downloadIssues,
    fetchImportInstructionalText,
    uploadItemBank,
    resetMappingState,
    fetchItemBankPreviewTableRows,
    startItemBankImport,
  };
};

import React, {FC, useCallback, useMemo} from 'react';
import {Form} from 'forms';
import {FormNameEnum} from 'filters-selections';
import {
  IModalUserActivityFormValues,
  modalUserActivityFormValidationSchema,
} from './modalUserActivityFormValidationSchema';
import {useUserActivity} from 'permissions';
import {FormikHelpers} from 'formik';

interface IModalUserActivityProps {
  setIsOpen: (arg: boolean) => void;
}

export const ModalUserActivityForm: FC<IModalUserActivityProps> = ({children, setIsOpen}) => {
  const {getUserActivityExport} = useUserActivity();

  const submitHandler = useCallback(
    ({from, to, rolesList}, {resetForm}: FormikHelpers<any>) =>
      getUserActivityExport(from, to, rolesList)
        .then(() => setIsOpen(false))
        .then(() => resetForm()),
    [setIsOpen, getUserActivityExport]
  );

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.from]: null,
      [FormNameEnum.to]: null,
      [FormNameEnum.role]: [],
    }),
    []
  );

  return (
    <Form.Form<IModalUserActivityFormValues>
      initialValues={initialValues}
      validationSchema={modalUserActivityFormValidationSchema}
      submitHandler={submitHandler}
    >
      {children}
    </Form.Form>
  );
};

import {Column, Row, Table} from 'components-lib';
import React, {Fragment} from 'react';
import {GoogleSitesAreasSearch, GoogleSitesAreasSortBy} from '..';

export const GoogleSiteAreasTable = ({rows, columns}) => {
  return (
    <Fragment>
      <Row justify="space-between">
        <Column sm={4} md={4} lg={3} xl={2}>
          <GoogleSitesAreasSearch />
        </Column>
        <Column sm={4} md={4} lg={3} xl={2}>
          <Row justify="flex-end">
            <Column sm={12} md={12} lg={12} xl={12}>
              <GoogleSitesAreasSortBy />
            </Column>
          </Row>
        </Column>
      </Row>
      <Table rows={rows} columns={columns} hasRows={!!rows.length} />
    </Fragment>
  );
};

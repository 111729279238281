import React, {FC, Fragment, useMemo} from 'react';
import {Button, PageViewHeader, Tooltip} from 'components-lib';
import {SubtitlePageViewHeader} from 'admin';
import {
  useClassPaperAssessments,
  useUploadPaperAssessmentModal,
} from 'admin/programs/details/classes/details/paper-assessments/hooks';
import {UploadPaperAssessmentModal} from '../UploadPaperAssessmentModal/UploadPaperAssessmentModal';
import {PaperAssessmentsAllSortBy} from '../PaperAssessmentsAllSortBy/PaperAssessmentsAllSortBy';
import {FormContextProvider} from 'forms';

export const PaperAssessmentsHeader: FC = () => {
  const {redirectToProgram, program, currentClass} = useClassPaperAssessments();
  const {
    openUploadPaperAssessmentModal,
    isUploadModalOpen,
    closeUploadPaperAssessmentModal,
  } = useUploadPaperAssessmentModal();

  const shouldRenderHeader = program && currentClass;
  const subtitle = `${program?.name} / ${currentClass?.name}`;

  const headerSubheading = useMemo(
    () => shouldRenderHeader && <SubtitlePageViewHeader> / {subtitle} / Show Paper Assessments</SubtitlePageViewHeader>,
    [subtitle, shouldRenderHeader]
  );

  const renderSortOrder = useMemo(() => <PaperAssessmentsAllSortBy />, []);

  return (
    <Fragment>
      <PageViewHeader
        heading="Programs"
        subHeading={
          <Tooltip title={subtitle} size="large">
            {headerSubheading}
          </Tooltip>
        }
        withBackButton
        sort={renderSortOrder}
        action={<Button.Secondary clickHandler={openUploadPaperAssessmentModal}>Upload</Button.Secondary>}
        backButtonClickHandler={redirectToProgram}
      />
      {isUploadModalOpen && (
        <FormContextProvider>
          <UploadPaperAssessmentModal isOpen={isUploadModalOpen} closeClickHandler={closeUploadPaperAssessmentModal} />
        </FormContextProvider>
      )}
    </Fragment>
  );
};

import {createSelector} from '@reduxjs/toolkit';
import {TStateStore} from 'store';

const getItemsImportState = (state: TStateStore) => state.itemsImport;

export const itemsImportPageLoadingStatusSelector = createSelector(
  getItemsImportState,
  (itemsImport) => itemsImport.page
);

export const savingSelector = createSelector(getItemsImportState, (items) => items.page.saving);
export const loadingSelector = createSelector(getItemsImportState, (items) => items.page.loading);
export const downloadingSelector = createSelector(getItemsImportState, (items) => items.page.downloading);
export const uploadingSelector = createSelector(getItemsImportState, (items) => items.page.uploading);
export const importingSelector = createSelector(getItemsImportState, (items) => items.page.importing);

export const templateInstructionalTextSelector = createSelector(
  getItemsImportState,
  (items) => items.templateInstructionalText
);

export const itemsImportFieldListSelector = createSelector(
  getItemsImportState,
  (itemsImport) => itemsImport.mapping.fieldList
);

export const hasRequiredFieldErrorsSelector = createSelector(
  getItemsImportState,
  (items) => items.mapping.hasRequiredFieldErrors
);

export const itemsImportFileNameSelector = createSelector(
  getItemsImportState,
  (itemsImport) => itemsImport.mapping.fileName
);

export const itemImportIdSelector = createSelector(
  getItemsImportState,
  (itemsImport) => itemsImport.mapping.itemImportId
);

export const itemImportPreviewRowsSelector = createSelector(
  getItemsImportState,
  (itemsImport) => itemsImport.preview.previewRows
);

export const itemImportCriticalIssueCountSelector = createSelector(
  getItemsImportState,
  (itemsImport) => itemsImport.preview.criticalIssueCount
);

export const itemImportPreviewLoadingSelector = createSelector(
  getItemsImportState,
  (itemsImport) => itemsImport.preview.loading
);

export const downloadingCriticalIssuesSelector = createSelector(
  getItemsImportState,
  (items) => items.preview.downloadingCriticalIssues
);

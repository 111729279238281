import React, {FC, Fragment, useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';

import {ToggleButton as Toggle} from 'components-lib';
import {Form} from 'forms';
import {FormNameEnum} from 'filters-selections';
import {BUTTON_NUM_LIST} from 'utils';
import {IAnswer, IToggleOption} from 'models';
import {Label} from 'components-lib';
import {mapToggleButtonOptions} from 'admin/library/utils';

interface IRatingToggleGroupProps {
  disabled?: boolean;
  list?: number[];
  label?: string;
  withLabel: boolean;
  name?: FormNameEnum;
  answers?: IAnswer[];
  withPreview?: boolean;
  withForm?: boolean;
  className?: string;
}

export const RatingToggleGroup: FC<IRatingToggleGroupProps> = ({
  list = BUTTON_NUM_LIST,
  label = 'Positive Score',
  withLabel = false,
  disabled = false,
  name,
  answers,
  withPreview = false,
  withForm = false,
  className,
}) => {
  const classes = useStyles({className});

  const defaultButtons = useMemo(
    () =>
      list.map((value) => {
        return {
          value,
          label: value.toString(),
          selected: undefined,
        };
      }),
    [list]
  );

  const getToggleButtons = useCallback((): IToggleOption[] => {
    if (withPreview && answers) {
      return mapToggleButtonOptions(answers);
    }

    return defaultButtons;
  }, [withPreview, answers, defaultButtons]);

  const buttons = getToggleButtons();

  const buttonGroup = useMemo(() => {
    return (
      <Fragment>
        {buttons.map((option: IToggleOption, idx: number) => (
          <Toggle
            key={`button-${idx}`}
            name={`button-${name}-${idx}`}
            value={option.value}
            aria-label={option.label}
            disabled={disabled}
            selected={withPreview && option.selected}
          >
            {option.label}
          </Toggle>
        ))}
      </Fragment>
    );
  }, [disabled, name, withPreview, buttons]);

  const toggleGroup = useMemo(
    () => (
      <Fragment>
        {withForm ? (
          <FormToggleButtonGroup buttons={buttons} disabled={disabled} withPreview={withPreview} name={name} />
        ) : (
          <ButtonGroup className={className}>{buttonGroup}</ButtonGroup>
        )}
      </Fragment>
    ),
    [className, disabled, name, withPreview, buttonGroup, withForm, buttons]
  );

  return (
    <div>
      {withLabel && <Label label={label} />}
      <div className={classes.buttonGroupContainer}>{toggleGroup}</div>
    </div>
  );
};

function FormToggleButtonGroup({buttons, name, disabled, withPreview}) {
  const classes = useStyles();

  return (
    <Form.ToggleButtonGroup name={name}>
      {buttons.map((option: IToggleOption, idx: number) => (
        <ToggleButton
          className={classes.toggleButton}
          key={`button-${idx}`}
          name={`button-${name}-${idx}`}
          value={option.value}
          aria-label={option.label}
          disabled={disabled}
          selected={withPreview ? option.selected : undefined}
        >
          {option.label}
        </ToggleButton>
      ))}
    </Form.ToggleButtonGroup>
  );
}

function ButtonGroup({children, className}) {
  return (
    <ToggleButtonGroup className={className} aria-label="toggle">
      {children}
    </ToggleButtonGroup>
  );
}

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    padding: 7.7,
    color: theme.palette.grey[900],
    fontWeight: 'bold',
    border: `1px solid ${theme.palette.grey[300]}`,
    '&.Mui-selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
    '& span': {
      width: 10,
    },
  },
  label: {
    color: theme.palette.grey[800],
    fontSize: '0.75rem',
  },
  buttonGroupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  rangeLabel: {
    color: theme.palette.grey[800],
    display: 'flex',
    alignSelf: 'center',
    margin: theme.spacing(2),
    fontSize: '0.75rem',
  },
}));

import React, {FC, ReactNode} from 'react';
import {Link as MuiLink, makeStyles} from '@material-ui/core';
import {IWithDisabled} from 'models/common';

interface ILinkProps extends IWithDisabled {
  clickHandler: () => void;
  children: ReactNode;
  withNewTab?: boolean;
  className?: string;
}

export const Link: FC<ILinkProps> = ({clickHandler, children, withNewTab = false, className, disabled}) => {
  const {link} = useStyles({disabled});

  return (
    <MuiLink
      className={className ? className : link}
      onClick={clickHandler}
      target={withNewTab ? '_blank' : ''}
      rel={withNewTab ? 'noopener noreferrer' : ''}
    >
      {children}
    </MuiLink>
  );
};

type TLinkStyles = Pick<ILinkProps, 'disabled'>;

const useStyles = makeStyles((theme) => ({
  link: ({disabled}: TLinkStyles) => {
    let styles: any = {
      cursor: 'pointer',
    };

    if (disabled) {
      styles = {
        ...styles,
        color: theme.palette.grey[600],
        pointerEvents: 'none',
        display: 'flex', // See: https://stackoverflow.com/questions/54428720/css-pointer-events-none-not-working-in-chrome
      };
    }

    return styles;
  },
}));

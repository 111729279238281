import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {useAppDispatch} from 'hooks';
import {SortByDropdown} from 'admin';
import {programsClassesSortOrderSelector, programClassesActions as actions} from '../../store';
import {IProgramClassesSortOrder} from 'models/programs';
import {getProgramClassesMenuItemsList, programClassesOrderingMap} from 'admin/programs/details';

export const ProgramsClassesSortBy = () => {
  const dispatch = useAppDispatch();
  const sortOrder = useSelector(programsClassesSortOrderSelector);

  const handleItemClick = useCallback(
    (sortOrder: IProgramClassesSortOrder) => {
      dispatch(actions.setSortOrderProgramClasses(sortOrder));
    },
    [dispatch]
  );

  return (
    <SortByDropdown
      label="Sort By: "
      handleItemClick={handleItemClick}
      getMenuItemsList={getProgramClassesMenuItemsList}
      sortOrder={sortOrder}
      map={programClassesOrderingMap}
    />
  );
};

import React, {FC, ReactNode} from 'react';

interface IWrapperProps {
  children?: ReactNode;
  className?: string;
}

export const Wrapper: FC<IWrapperProps> = ({children, className}) => {
  return <div className={className}>{children}</div>;
};

import {makeStyles} from '@material-ui/core';
import {CardLinkInfoTooltip} from 'admin/library/instruments/components';
import {Divider, Row, Tag, Text} from 'components-lib';
import {QuestionAnswersTypeEnum} from 'enums';
import React, {Fragment, ReactNode} from 'react';
import {cardTypeMap} from '../../enums';

interface ICardBodyProps {
  id: string;
  type?: QuestionAnswersTypeEnum;
  title?: string;
  position: number;
  itemPrefixText?: string;
  actions?: ReactNode;
  withItemInfo?: boolean;
  openInfoLinkClickHandler?: () => void;
  linkedQuestionAnswerStackId?: number;
}

export function EntityCardBody({
  id,
  type,
  title,
  position,
  itemPrefixText = 'Item',
  actions,
  withItemInfo,
  linkedQuestionAnswerStackId,
  openInfoLinkClickHandler,
}: ICardBodyProps) {
  const classes = useStyles();
  return (
    <>
      <Row justify="flex-start" classes={{root: classes.header}}>
        <Tag label={`${itemPrefixText} ${position + 1}`} color="primary" />
        {type && (
          <>
            <Divider.Vertical size="small" />
            <Text.Paragraph variant="body2">{cardTypeMap.get(type)}</Text.Paragraph>
          </>
        )}
        {id && (
          <Fragment>
            <Divider.Vertical size="small" />
            <Text.Paragraph variant="body2">Item ID: {id}</Text.Paragraph>
          </Fragment>
        )}
        {linkedQuestionAnswerStackId && withItemInfo && (
          <>
            <Divider.Vertical size="small" />
            <CardLinkInfoTooltip clickHandler={openInfoLinkClickHandler} />
          </>
        )}
        {!!actions && <div className={classes.actions}>{actions}</div>}
      </Row>
      {title && (
        <Row justify="flex-start">
          <Text.Heading as="h3">{title}</Text.Heading>
        </Row>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => {
  const marginBottom = theme.spacing(3);
  return {
    header: {
      marginBottom,
    },
    heading: {
      marginBottom,
    },
    actions: {
      marginLeft: 'auto',
    },
    paragraph: {
      color: theme.palette.grey[100],
    },
  };
});

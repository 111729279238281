import {
  IBaseInstrumentItem,
  IBaseResourceItem,
  ICreateLearningPathPayload,
  ICreateUpdateLearningPathBaseMetadataPayload,
  ILearningPathDetailsValues,
  ILearningPathSession,
  ILearningPathSessionItem,
  IUpdateLearningPathPayload,
} from 'models/resources';

const mapLearningPathResources = (resources: IBaseResourceItem[]) =>
  resources.map((resource) => resource.contentItemId);

const mapLearningPathInstruments = (instruments: IBaseInstrumentItem[]) =>
  instruments.map((instrument) => instrument.id);

const mapCreateUpdateLPMetadataPayload = (values): ICreateUpdateLearningPathBaseMetadataPayload => ({
  keyTerms: values.keyTerms,
  learningObjectives: values.learningObjectives,
  accessExpirationDate: values.expirationDate,
  languages: values.language ? [values.language] : [],
  pillars: [values.primaryPillar, values.secondaryPillar],
  programs: values.program ? [values.program] : [],
  gradeLevels: values.gradeLevels,
  programYear: values.futureProgramYear,
});

const mapCreateUpdateLPSessionsPayload = (builderSessions: ILearningPathSessionItem[]): ILearningPathSession[] =>
  builderSessions.length
    ? builderSessions.map(({title, description, resources, instruments}) => {
        const resourceIds = mapLearningPathResources(resources);
        const instrumentIds = mapLearningPathInstruments(instruments);

        return {
          title,
          description,
          resourceIds,
          instrumentIds,
        };
      })
    : [];

export const mapCreateUpdateLearningPathPayload = (
  values: ILearningPathDetailsValues,
  builderSessions: ILearningPathSessionItem[],
  isEdit = false
): ICreateLearningPathPayload | IUpdateLearningPathPayload => {
  const metadata = mapCreateUpdateLPMetadataPayload(values);
  const sessions = mapCreateUpdateLPSessionsPayload(builderSessions);

  let payload = {
    title: values.learningPathName,
    briefDescription: values.briefDescription,
    metadata,
    sessions,
  } as IUpdateLearningPathPayload;

  if (isEdit) {
    payload = {...payload, id: values.id};
  }

  return payload;
};

import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core';
import {useFormikContext} from 'formik';
import {Column, Row} from 'components-lib';
import {IntegrationConfirmCheckbox} from 'admin/integrations/components';
import {AddIntegrationInputList} from '../..';
import {IAddIntegrationFormBaseValues} from 'models';
import {FormNameEnum} from 'enums/form';
import {useAddIntegration} from 'admin/integrations/add-integration';
import {getEditorPermissionCheckboxText} from 'admin/integrations/utils';
import {getAddIntegrationGoogleSitesList} from 'admin/integrations/add-integration/utils';

export const GoogleSitesDestinationContent = () => {
  const classes = useStyles();
  const {serviceSourceEmail} = useAddIntegration();
  const {
    values: {email, integrationType, canGiveEditorPermissions},
    setFieldValue,
  } = useFormikContext<IAddIntegrationFormBaseValues>();
  const type = Number(integrationType);
  const inputList = getAddIntegrationGoogleSitesList(type);
  const hasServiceEmail = !!serviceSourceEmail;
  const shouldShowError = canGiveEditorPermissions === false;
  const checkboxText = getEditorPermissionCheckboxText(serviceSourceEmail);

  useEffect(() => {
    if (!hasServiceEmail) {
      setFieldValue(FormNameEnum.email, serviceSourceEmail ?? '');
    }
  }, [email, setFieldValue, hasServiceEmail, serviceSourceEmail]);

  return (
    <Row justify="flex-start" classes={{root: classes.row}}>
      <Column sm={6} md={6} lg={6} xl={4}>
        <AddIntegrationInputList list={inputList} withHeightThreeStepsActivity={false} />
        <IntegrationConfirmCheckbox
          text={checkboxText}
          name={FormNameEnum.canGiveEditorPermissions}
          showError={shouldShowError}
        />
      </Column>
    </Row>
  );
};

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(2),
  },
}));

import React, {FC} from 'react';
import {FormNameEnum} from 'enums/form';
import {IWithDisabled} from 'models/common';
import {Form} from 'forms';
import {useFormikContext} from 'formik';
import {IAddIntegrationFormBaseValues} from 'models/integrations';
interface IIntegrationConfirmCheckboxProps extends IWithDisabled {
  text: string;
  name?: FormNameEnum;
  showError: boolean;
  value?: boolean;
}

export const IntegrationConfirmCheckbox: FC<IIntegrationConfirmCheckboxProps> = ({
  text,
  disabled,
  name = FormNameEnum.canGiveEditorPermissions,
  showError,
  value,
}) => {
  const {
    values: {canGiveEditorPermissions},
  } = useFormikContext<IAddIntegrationFormBaseValues>();

  return (
    <Form.Checkbox
      name={name}
      label={text}
      disabled={disabled}
      checked={value || canGiveEditorPermissions}
      color={!showError ? 'primary' : 'secondary'}
    />
  );
};

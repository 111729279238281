import {FormNameEnum} from 'filters-selections';
import {useIsDomainKnowledgeSelected} from 'filters-selections/hooks';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {filtersCompetenciesEntitiesCollectionSelector, fetchCompetenciesThunk} from 'filters-selections/store';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ICompetencySelectProps extends IWithDisabled {
  withDomainKnowledge?: boolean;
}

export function CompetencyByDomainSelect({disabled}: ICompetencySelectProps) {
  const dispatch = useDispatch();
  const competencies = useSelector(filtersCompetenciesEntitiesCollectionSelector);
  const {isDomainKnowledgeSelected} = useIsDomainKnowledgeSelected();

  useEffect(() => {
    if (!competencies.length) {
      dispatch(fetchCompetenciesThunk());
    }
  }, [competencies.length, dispatch]);

  const options = useMemo(
    () =>
      competencies.map((competency) => ({
        value: competency.id,
        label: competency.name,
      })),
    [competencies]
  );

  return (
    <ItemWithPlaceholder label="Competency" hasItems={!!competencies.length}>
      <Form.SelectAutocomplete
        name={FormNameEnum.competencies}
        options={options}
        multiple
        disabled={!isDomainKnowledgeSelected || disabled}
      />
    </ItemWithPlaceholder>
  );
}

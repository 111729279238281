export enum InstrumentsFilterNamesEnum {
  SEARCH_BY_NAME = 'name',
  SEARCH = 'search',
  PILLARS = 'pillars',
  PHASES = 'phases',
  PROGRAMS = 'programs',
  TYPES = 'types',
  STATUSES = 'statuses',
  AUDIENCES = 'audiences',
  AREAS = 'areas',
  SORT_BY = 'sortBy',
  ORDER_BY = 'orderBy',
  INSTRUMENT_VIEW_TYPE = 'isGeneralTemplate',
  PILLAR = 'pillar',
  EVALUATION_TYPE = 'evaluationType',
  DOMAIN = 'domain',
  LEARNING_OBJECTIVE = 'learningObjective',
  CATEGORY = 'category',
  COMPETENCY = 'competency',
  CONCEPT = 'concept',
  ADMINISTRATION = 'administration',
  SCHOOL_LEVEL = 'schoolLevel',
  TEXT = 'text',
}

import React, {FC, useMemo} from 'react';
import {FormikHelpers} from 'formik/dist/types';
import {useItem, useItemStateActions} from 'admin/library/items/hooks';
import {updateItemThunk} from 'admin/library/items/store';
import {FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import {useAppDispatch, useNavigate, useQueryParams} from 'hooks';
import {ICreateQuestionPayload, IWithChildren} from 'models';
import {IItemCreateFormValues, itemCreateFormValidationSchema} from './config';
import {mapItemFormDataToUpdatePayload} from 'admin/library/items/builder/utils';

type IItemBuilderCreateFormProps = IWithChildren;

export const ItemBuilderCreateForm: FC<IItemBuilderCreateFormProps> = ({children}) => {
  const dispatch = useAppDispatch();
  const {createQuestion} = useItemStateActions();
  const {push} = useNavigate();
  const {item} = useItem();
  const {questionId} = useQueryParams();

  const initialValues: IItemCreateFormValues = useMemo(
    () => ({
      [FormNameEnum.questionId]: item.questionId ?? null,
      [FormNameEnum.text]: item.text ?? '',
      [FormNameEnum.questionTextHtml]: item.questionTextHtml ?? '',
      [FormNameEnum.type]: item.questionOptionsTypeId ?? null,
    }),
    [item]
  );

  const submitHandler = async (data: IItemCreateFormValues, {resetForm}: FormikHelpers<IItemCreateFormValues>) => {
    if (!questionId) {
      const response = (await createQuestion({
        text: data.text,
        questionTextHtml: data.questionTextHtml,
        questionOptionsType: data.type as number,
      })) as ICreateQuestionPayload;
      push(`?questionId=${response.questionId}`);
    } else {
      const payloadData = mapItemFormDataToUpdatePayload({...data, questionId: Number(questionId)});
      dispatch(updateItemThunk(payloadData));
    }
  };

  return (
    <Form.Form<IItemCreateFormValues>
      initialValues={initialValues}
      validationSchema={itemCreateFormValidationSchema}
      submitHandler={submitHandler}
    >
      {children}
    </Form.Form>
  );
};

import {combineReducers} from 'redux';
import {uploadSlice} from '../upload/store/upload.slice';
import {filesReducer} from './../files/store/files.reducer';
import {googleSitesSlice} from '../google-sites/store/googleSites.slice';
import {learningPathsReducer} from '../learning-paths/store/learningPaths.reducer';

export const resourcesReducer = combineReducers({
  files: filesReducer,
  googleSites: googleSitesSlice.reducer,
  upload: uploadSlice.reducer,
  learningPaths: learningPathsReducer,
});

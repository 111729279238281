export enum QuestionAnswersTypeEnum {
  radioGroup = 'radiogroup',
  checkbox = 'checkbox',
  matrix = 'matrix',
  dropdown = 'dropdown',
  text = 'text', // openEnded
  rating = 'rating', // numericScale
  matrixdropdown = 'matrixdropdown',
  html = 'html',
  questionAnswerEnum = 10,
  matrixEnum = 20,
  matrixHeaderAnswerEnum = 30,
  dropdownAnswerEnum = 40,
  ratingAnswerEnum = 50,
  openEndedAnswerEnum = 60,
}

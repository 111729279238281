import React, {FC, useCallback, useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {Box} from '@material-ui/core';
import {IProgramClassExportParams, IWithChildren} from 'models';
import {Form} from 'forms';
import {ILevelReportingFormBaseValues, levelReportingValidationSchema} from './config';
import {FormNameEnum} from 'enums/form';
import {useProgramReports, useProgramReportsStoreActions} from '../../hooks';
import {ProgramDetailsViewTypeEnum} from '../..';

interface ILevelReportingMenuFormProps extends IWithChildren {
  programViewType?: ProgramDetailsViewTypeEnum;
}

export const LevelReportingMenuForm: FC<ILevelReportingMenuFormProps> = ({children, programViewType}) => {
  const {setSelectedId, menuList, selectedItem} = useProgramReportsStoreActions();
  const {fetchProgramLevelsOfReporting, fetchProgramClassLevelsOfReporting} = useProgramReports();
  const {programId, classId} = useParams<IProgramClassExportParams>();

  const shouldFetchProgramLevelsOfReporting = !!programId;
  const shouldFetchProgramClassLevelsOfReporting = !!programId && !!classId;
  const isProgramViewType = programViewType === ProgramDetailsViewTypeEnum.Program;

  useEffect(() => {
    if (isProgramViewType) {
      shouldFetchProgramLevelsOfReporting && fetchProgramLevelsOfReporting();
    } else {
      shouldFetchProgramClassLevelsOfReporting && fetchProgramClassLevelsOfReporting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProgramViewType, programId, shouldFetchProgramLevelsOfReporting, shouldFetchProgramClassLevelsOfReporting]);

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.selectedId]: selectedItem?.id,
      [FormNameEnum.searchTerm]: '',
      [FormNameEnum.menuItems]: menuList,
      [FormNameEnum.filteredMenuItems]: menuList,
    }),
    [menuList, selectedItem]
  );

  const submitHandler = useCallback(({selectedId}: ILevelReportingFormBaseValues) => setSelectedId(selectedId), [
    setSelectedId,
  ]);

  return (
    <Form.Form<ILevelReportingFormBaseValues>
      initialValues={initialValues}
      validationSchema={levelReportingValidationSchema}
      submitHandler={submitHandler}
    >
      <Box p={3} m={2}>
        {children}
      </Box>
    </Form.Form>
  );
};

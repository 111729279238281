import {makeStyles} from '@material-ui/core';
import {IComponentWithChildren} from 'models';
import React, {Ref} from 'react';

interface IDropContainerProps extends IComponentWithChildren {
  drop: Ref<any>;
}

export function DropContainer({children, drop}: IDropContainerProps) {
  const classes = useStyles();
  return (
    <div ref={drop} className={classes.root}>
      {children}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

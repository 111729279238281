import React, {useCallback} from 'react';
import {SearchOnTypeSelectAutocomplete, SelectAutocomplete} from 'components-lib';
import {FieldProps} from 'formik';
import {IOption, IWithDisabled} from 'models';
import {Field} from './Field';

interface IFormSelectAutocompleteProps extends IWithDisabled {
  name: string;
  open?: boolean;
  options: IOption[];
  multiple?: boolean;
  noOptionsText?: string;
  withCheckboxRenderOption?: boolean;
  withFontWeightBold?: boolean;
  disableCloseOnSelect?: boolean;
  loading?: boolean;
  withSearchOnType?: boolean;
  isFilter?: boolean;
  filterOptions?: (options: IOption[]) => IOption[];
  inputChangeHandler?: (value: string) => void;
  onOpen?: () => void;
}

export function FormSelectAutocomplete({
  name,
  open,
  options,
  multiple = false,
  noOptionsText = 'No options',
  disabled = false,
  withCheckboxRenderOption = true,
  withFontWeightBold = false,
  withSearchOnType,
  loading = false,
  isFilter,
  inputChangeHandler,
  disableCloseOnSelect,
  filterOptions,
  onOpen,
}: IFormSelectAutocompleteProps) {
  return (
    <Field
      loading={loading}
      name={name}
      open={open}
      options={options}
      multiple={multiple}
      noOptionsText={noOptionsText}
      disabled={disabled}
      isFilter={isFilter}
      withCheckboxRenderOption={withCheckboxRenderOption}
      withFontWeightBold={withFontWeightBold}
      inputChangeHandler={inputChangeHandler}
      withSearchOnType={withSearchOnType}
      disableCloseOnSelect={disableCloseOnSelect}
      onOpen={onOpen}
      filterOptions={filterOptions}
      Component={SelectAutocompleteDecorated}
    />
  );
}

interface ISelectDecoratedProps extends FieldProps, IFormSelectAutocompleteProps {
  onBlur: () => void;
}

function SelectAutocompleteDecorated({
  field: {name},
  form: {setFieldValue, errors, values},
  ...rest
}: ISelectDecoratedProps) {
  const error = errors[name];
  const value = values[name];
  const isValueArray = Array.isArray(value);

  let props: any = {
    name,
    value,
  };

  if (error) {
    props = {
      ...props,
      error: true,
      helperText: error,
    };
  }

  const changeHandler = useCallback(
    (newValue: IOption | IOption[]) => {
      if (Array.isArray(newValue)) {
        const values = newValue.map((v: IOption) => v.value);
        return setFieldValue(name, values);
      }

      const value = !!newValue ? newValue.value : newValue; // for nullable values
      setFieldValue(name, value);
    },
    [name, setFieldValue]
  );

  const selected = rest.options.filter((option) => (isValueArray ? value?.includes(option.value) : []));

  if (rest.withSearchOnType) {
    return (
      <SearchOnTypeSelectAutocomplete
        {...props}
        {...rest}
        values={selected}
        changeHandler={changeHandler}
        blurHandler={rest.onBlur}
      />
    );
  }

  return (
    <SelectAutocomplete
      {...props}
      {...rest}
      values={selected}
      changeHandler={changeHandler}
      blurHandler={rest.onBlur}
    />
  );
}

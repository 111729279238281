import React from 'react';
import {makeStyles} from '@material-ui/core';

import {Column, Container, Row} from 'components-lib';
import {FileUploadFormInput} from 'admin/resources/upload/components';
import {FormNameEnum} from '../../../enums';

export const WebLinkInput = () => {
  const classes = useStyles();

  return (
    <Container disableGutters classes={{root: classes.webLinkInput}}>
      <Row justify="flex-start" align="flex-start">
        <Column sm={12} md={12} lg={12}>
          <FileUploadFormInput label="Web Link" name={FormNameEnum.webLink} />
        </Column>
      </Row>
    </Container>
  );
};

export const useStyles = makeStyles((theme) => ({
  webLinkInput: {
    paddingBottom: theme.spacing(3),
  },
}));

import {SortingOrderEnum} from 'enums';
import {DigitalResourceDropdownNameEnum, DigitalResourcesOrderingEnum} from '../enums';

export const digitalResourcesOrderingMap = {
  [DigitalResourcesOrderingEnum.Name]: {
    [SortingOrderEnum.ASC]: DigitalResourceDropdownNameEnum.NAME_ASC,
    [SortingOrderEnum.DESC]: DigitalResourceDropdownNameEnum.NAME_DESC,
  },
  [DigitalResourcesOrderingEnum.Created]: {
    [SortingOrderEnum.ASC]: DigitalResourceDropdownNameEnum.CREATED_OLDEST,
    [SortingOrderEnum.DESC]: DigitalResourceDropdownNameEnum.CREATED_NEWEST,
  },
};

import {makeStyles} from '@material-ui/core';
import {layoutConfig} from 'layout';
import {IComponentWithChildren} from 'models';
import React from 'react';
import {borderGeneral} from 'styles';

interface IDroppableAreaProps extends IComponentWithChildren {
  isActive: boolean;
  asOverlay?: boolean;
}

export function DroppableArea({children, isActive, asOverlay = false}: IDroppableAreaProps) {
  const classes = useStyles({isActive, asOverlay});
  return <div className={classes.root}>{!asOverlay && children}</div>;
}

const useStyles = makeStyles((theme) => ({
  root: ({isActive, asOverlay}: Pick<IDroppableAreaProps, 'isActive' | 'asOverlay'>) => ({
    zIndex: 99,
    position: asOverlay ? 'absolute' : 'relative',
    opacity: asOverlay ? 0.9 : 1,
    width: '100%',
    height: '100%',
    minHeight: asOverlay
      ? `calc(100vh - ${layoutConfig.scrollableAreaWithoutSecondaryNavigationSubtrahend + 190}px)`
      : '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    background: isActive ? theme.palette.secondary.light : theme.palette.grey[50],
    ...borderGeneral,
    borderStyle: 'dashed',
  }),
}));

import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Accordion, Text} from 'components-lib';
import {Form} from 'forms';
import {FormNameEnum} from '../../../enums';
import {fetchCompetenciesThunk, filtersCompetenciesEntitiesCollectionSelector} from '../../../store';
import {IWithDisabled} from 'models';

export function CompetenciesFilter({disabled}: IWithDisabled) {
  const dispatch = useDispatch();
  const competencies = useSelector(filtersCompetenciesEntitiesCollectionSelector);

  useEffect(() => {
    if (!competencies.length) {
      dispatch(fetchCompetenciesThunk());
    }
  }, [competencies.length, dispatch]);

  const options: any = useMemo(() => {
    return competencies.map((competencies) => ({value: competencies.id, label: competencies.name}));
  }, [competencies]);

  return (
    <Accordion heading="Competencies">
      {competencies.length ? (
        <Form.SelectAutocomplete name={FormNameEnum.competencies} options={options} multiple disabled={disabled} />
      ) : (
        <Text.Caption>No Data</Text.Caption>
      )}
    </Accordion>
  );
}

import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchConceptsThunk, filtersConceptsEntitiesCollectionSelector, FormNameEnum} from 'filters-selections';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
import {useFormikContext} from 'formik';
import {IAddWebLinkFormBaseValues, IUploadFileFormBaseValues, IUploadScormFormBaseValues} from 'admin/resources/upload';
import {useIsCompetencySelected} from 'filters-selections/hooks';

interface IKnowledgeSkillsAndAbilitiesSelectProps extends IWithDisabled {
  infoText?: string;
}

export function KnowledgeSkillsAndAbilitiesSelect({disabled, infoText}: IKnowledgeSkillsAndAbilitiesSelectProps) {
  const dispatch = useDispatch();
  const knowledgeSkillsAndAbilities = useSelector(filtersConceptsEntitiesCollectionSelector);
  const {hasCompetenciesSelected} = useIsCompetencySelected();

  const {values, setFieldValue} = useFormikContext<
    IUploadFileFormBaseValues | IUploadScormFormBaseValues | IAddWebLinkFormBaseValues
  >();

  useEffect(() => {
    if (values.competencies?.length && !knowledgeSkillsAndAbilities.length) {
      dispatch(fetchConceptsThunk(values.competencies));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.competencies, knowledgeSkillsAndAbilities]);

  useEffect(() => {
    if (!hasCompetenciesSelected && values.competencies.length === 0 && values.knowledgeSkillsAndAbilities.length > 0) {
      setFieldValue(FormNameEnum.knowledgeSkillsAndAbilities, []);
    }
  }, [hasCompetenciesSelected, values.competencies, values.knowledgeSkillsAndAbilities, setFieldValue]);

  const options = useMemo(
    () =>
      knowledgeSkillsAndAbilities.map((knowledge) => ({
        value: knowledge.id,
        label: knowledge.name,
      })),
    [knowledgeSkillsAndAbilities]
  );

  const shouldDisableAutocomplete = disabled || !values.competencies.length || !knowledgeSkillsAndAbilities.length;
  const hasItems = hasCompetenciesSelected ? !!knowledgeSkillsAndAbilities.length : true;
  return (
    <ItemWithPlaceholder label="KSAs" hasItems={hasItems} infoText={infoText}>
      <Form.SelectAutocomplete
        name={FormNameEnum.knowledgeSkillsAndAbilities}
        options={options}
        multiple
        disabled={shouldDisableAutocomplete}
      />
    </ItemWithPlaceholder>
  );
}

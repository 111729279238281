import {createAsyncThunk} from '@reduxjs/toolkit';
import {googleSitesApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {IGoogleSiteDetails} from 'models/resources';

export const fetchGoogleSitesSourceDetailsThunk: any = createAsyncThunk<IGoogleSiteDetails, string>(
  `${prefix} fetchGoogleSitesSourceDetails`,
  async (siteFieldId: string, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await googleSitesApi.getDetailsGoogleSiteSource(siteFieldId);
      return parsedBody;
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

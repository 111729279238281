import {createAsyncThunk} from '@reduxjs/toolkit';
import {addIntegrationMessages} from 'admin/integrations/add-integration';
import {integrationsApi} from 'api';
import {ICreateGoogleSitesDestinationPayload} from 'models/integrations';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const createGoogleSitesDestinationThunk: any = createAsyncThunk(
  `${prefix} createGoogleSitesDestinationThunk`,
  async (payload: ICreateGoogleSitesDestinationPayload, {dispatch, rejectWithValue}) => {
    try {
      const response = await integrationsApi.createGoogleSitesDestination(payload);
      dispatch(toastNotificationManager.createSuccessToastAction(addIntegrationMessages.createIntegrationSuccess));
      return response.data;
    } catch (error) {
      dispatch(
        toastNotificationManager.createErrorToastAction(
          error.messages ?? addIntegrationMessages.unknownIntegrationError
        )
      );
      return rejectWithValue({error});
    }
  }
);

import {Radio} from 'components-lib';
import React from 'react';

interface IFormRadioProps {
  name?: string;
  value: string | number | any;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
}

export function FormRadio({name, value, label, disabled = false, checked, ...rest}: IFormRadioProps) {
  return <Radio {...rest} name={name} value={value} label={label} disabled={disabled} checked={checked} />;
}

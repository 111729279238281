import {IAnswer, IItemColumn, IQuestionAnswerStack} from 'models';
import React from 'react';
import {AnswersMatrixView} from './AnswersMatrixView';

interface IAnswersMatrixProps {
  //   columns?: IItemColumn[] | IQuestionAnswerStack[];
  columns?: any; // TODO model
  rows?: IAnswer[];
}

export function AnswersMatrix({columns = [], rows = []}: IAnswersMatrixProps) {
  const isFromBuilder = (columns[0] && (columns[0] as any).questionAnswerStackId) ?? false;
  // TODO add question text for the builder items
  const columnsUpdated: any = isFromBuilder
    ? addExtraColumn(mapColumns(columns as IQuestionAnswerStack[]))
    : addExtraColumn(columns);
  return <AnswersMatrixView columns={columnsUpdated} rows={rows} />;
}

function mapColumns(columns: IQuestionAnswerStack[]): IItemColumn[] {
  return columns[0].choices.map((choice, idx) => ({text: choice.text, value: choice.value, position: idx}));
}

function addExtraColumn(columns: IItemColumn[]): IItemColumn[] {
  return [null, ...columns];
}

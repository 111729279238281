import React, {FC, useMemo} from 'react';
import {SelectionEnum, SelectionListItems} from 'filters-selections';
import {Container} from 'components-lib';
import {makeStyles} from '@material-ui/core';

interface ILinkExistingResourcesProps {
  isResource?: boolean;
}

export const LinkExistingResources: FC<ILinkExistingResourcesProps> = ({isResource = true}) => {
  const classes = useStyles();
  let list = useMemo(() => [SelectionEnum.Resources], []);

  if (!isResource) {
    list = [SelectionEnum.Instruments];
  }

  return (
    <Container disableGutters classes={{root: classes.selectionList}}>
      <SelectionListItems list={list} />
    </Container>
  );
};

const useStyles = makeStyles(({spacing}) => ({
  selectionList: {
    paddingLeft: spacing(2),
  },
}));

import {Button} from 'components-lib';
import {useFormikContext} from 'formik';
import React, {useCallback} from 'react';
import {values} from 'utils';

interface IFormButtonClearProps {
  clickHandler?: () => void;
}

export function FormButtonClear({clickHandler}: IFormButtonClearProps) {
  const {dirty, resetForm, values: formValues} = useFormikContext();

  const hasValues = values(formValues).some((value) => {
    if (Array.isArray(value)) {
      return !!value.length;
    }

    return !!value;
  });

  const resetFormHandler = useCallback(() => {
    resetForm();
    clickHandler && clickHandler();
  }, [clickHandler, resetForm]);

  return (
    <Button.Primary size="small" disabled={!dirty && !hasValues} clickHandler={resetFormHandler}>
      Clear
    </Button.Primary>
  );
}

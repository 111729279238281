import {createAsyncThunk} from '@reduxjs/toolkit';
import {learningPathsApi} from 'api';
import {ICreateUpdateLearningPathApiResponse, IUpdateLearningPathPayload} from 'models/resources/learning-paths';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const updateLearningPathThunk: any = createAsyncThunk<
  ICreateUpdateLearningPathApiResponse,
  IUpdateLearningPathPayload
>(`${prefix} updateLearningPathThunk`, async (payload: IUpdateLearningPathPayload, {dispatch, rejectWithValue}) => {
  try {
    const {parsedBody} = await learningPathsApi.updateLearningPath(payload);
    dispatch(toastNotificationManager.createSuccessToastAction('Learning path was updated successfully!'));
    return parsedBody;
  } catch (error) {
    dispatch(toastNotificationManager.createErrorToastAction(error.messages));
    return rejectWithValue({error});
  }
});

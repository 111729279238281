import React, {FC, useCallback, useMemo} from 'react';
import {Form} from 'forms';
import {IWithChildren} from 'models/common';
import {FormNameEnum} from 'filters-selections';
import {googleSitesAreasFormValidationSchema, IGoogleSitesAreasFormValues} from 'admin/resources/google-sites/details';
import {useGoogleSiteHistory} from 'admin/resources/google-sites/details/hooks';

type IGoogleSitesAreasFormProps = IWithChildren;

export const GoogleSitesAreasForm: FC<IGoogleSitesAreasFormProps> = ({children}) => {
  const {googleSiteHistoryItemsEntitiesCollection} = useGoogleSiteHistory();

  const submitHandler = useCallback(() => null, []);

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.searchTerm]: '',
      [FormNameEnum.historyList]: googleSiteHistoryItemsEntitiesCollection ?? [],
      [FormNameEnum.historyFilteredList]: [],
    }),
    [googleSiteHistoryItemsEntitiesCollection]
  );

  return (
    <Form.Form<IGoogleSitesAreasFormValues>
      initialValues={initialValues}
      validationSchema={googleSitesAreasFormValidationSchema}
      submitHandler={submitHandler}
    >
      {children}
    </Form.Form>
  );
};

import React, {useMemo} from 'react';
import {Text} from 'components-lib';
import {FormNameEnum, SelectionEnum, SelectionListItems} from 'filters-selections';
import {useFormikContext} from 'formik';
import {Form} from 'forms';
import {usePermissions} from 'permissions';
import {checkIfSuperAdmin} from 'admin/library/instruments/details';
import {ProgramSingleSelect} from 'filters-selections/selection/components/ProgramSelect/ProgramSingleSelect';
import {ProgramSelect} from 'filters-selections/selection/components/ProgramSelect/ProgramSelect';
import {ProgramYearMultiFilter} from 'filters-selections/filters/components/ProgramYearFilter';

export function RolloverProgramFormFields() {
  const {values} = useFormikContext();
  const {hasPermission} = usePermissions();
  const isSuperAdmin = checkIfSuperAdmin(hasPermission);

  const fieldsListDefault = [SelectionEnum.ProgramMultiSelection];

  const selectionListItems = useMemo(() => {
    const fieldsListDefault = [SelectionEnum.ProgramMultiSelection];
    const list = fieldsListDefault;
    return <SelectionListItems list={list} />;
  }, []);

  return (
    <>
      <Text.Paragraph>Select the destination programs to roll-over to</Text.Paragraph>
      {selectionListItems}
      {/* TODO: what if program name doesn't include year? How to filter programs by year? */}
      {/* <ProgramYearMultiFilter /> */}
    </>
  );
}

import {createAsyncThunk} from '@reduxjs/toolkit';
import {programsApi} from 'api';
import {IProgramBase, IWithNavigationPush} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';
import {pages} from 'paths';

interface IFetchProgramPayload extends IWithNavigationPush {
  programId: number;
}

export const fetchProgramThunk: any = createAsyncThunk<IProgramBase, IFetchProgramPayload>(
  `${prefix} fetchProgram`,
  async ({programId, push}, {rejectWithValue, dispatch}) => {
    try {
      const {parsedBody} = await programsApi.getActiveProgramsByIds([programId]);

      if (!parsedBody.length) {
        push(pages.error);
      } else {
        return parsedBody[0];
      }
    } catch (err) {
      dispatch(
        toastNotificationManager.createErrorToastAction(err.parsedBody || err.statusText || 'Unknown error occurred.')
      );

      push(pages.error);

      return rejectWithValue({error: err});
    }
  }
);

import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {ToggleButton as Button} from '@material-ui/lab';

interface IToggleButtonProps {
  disabled?: boolean;
  list?: number[];
  label?: string;
  name?: string;
  withPreview?: boolean;
  withForm?: boolean;
  value?: any;
  selected?: boolean;
}

export const ToggleButton: FC<IToggleButtonProps> = ({label, name, disabled, value, selected}) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.toggleButton}
      name={name}
      value={value}
      aria-label={label}
      disabled={disabled}
      selected={selected}
    >
      {label}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    padding: 7.7,
    color: theme.palette.grey[900],
    fontWeight: 'bold',
    border: `1px solid ${theme.palette.grey[300]}`,
    '&.Mui-selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
    '& span': {
      width: 10,
    },
  },
}));

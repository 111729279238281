import {DatePicker, Row} from 'components-lib';
import {FieldProps} from 'formik';
import React, {useCallback} from 'react';
import {Field} from './Field';

interface IFormDatePickerProps {
  name: string;
  value: Date;
  label?: string;
  withMinDate?: boolean;
  withMaxDate?: boolean;
  maxWidth?: boolean;
  minDateFormField?: string;
  maxDateFormField?: string;
  disabled?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDateMessage?: string;
  maxDateMessage?: string;
  format?: string;
}

export function FormDatePicker({
  name,
  value,
  label = '',
  withMinDate = false,
  withMaxDate = false,
  maxWidth = false,
  minDateFormField,
  maxDateFormField,
  disabled = false,
  disableFuture = true,
  disablePast = false,
  minDateMessage,
  maxDateMessage,
  format,
}: IFormDatePickerProps) {
  return (
    <Field
      name={name}
      value={value}
      label={label}
      withMinDate={withMinDate}
      withMaxDate={withMaxDate}
      maxWidth={maxWidth}
      minDateFormField={minDateFormField}
      maxDateFormField={maxDateFormField}
      minDateMessage={minDateMessage}
      maxDateMessage={maxDateMessage}
      Component={DatePickerDecorated}
      disabled={disabled}
      disableFuture={disableFuture}
      disablePast={disablePast}
      format={format}
    />
  );
}

interface IInputDecoratedProps extends FieldProps, IFormDatePickerProps {}

function DatePickerDecorated({field: {name, value}, form: {values, setFieldValue}, ...rest}: IInputDecoratedProps) {
  // TODO display errors?
  let minDate = null;
  let maxDate = null;

  if (rest.withMinDate) {
    minDate = values[rest.minDateFormField];
  }

  if (rest.withMaxDate) {
    maxDate = values[rest.maxDateFormField];
  }

  const changeHandler = useCallback(
    (date: Date) => {
      setFieldValue(name, date);
    },
    [name, setFieldValue]
  );

  return (
    <Row justify="flex-start">
      <DatePicker
        value={value}
        changeHandler={changeHandler}
        label={rest.label}
        minDate={minDate}
        maxDate={maxDate}
        maxWidth={rest?.maxWidth}
        disabled={rest?.disabled}
        disableFuture={rest.disableFuture}
        disablePast={rest.disablePast}
        format={rest.format}
        minDateMessage={rest.minDateMessage}
        maxDateMessage={rest.maxDateMessage}
      />
    </Row>
  );
}

import React, {Fragment} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {NoPermissionsPage} from 'pages';
import {pages, paths} from 'paths';
import {SuperAdminRole, withRole} from 'permissions';
import {IntegrationsAllView} from './all';
import {IntegrationsDetailView} from './details/IntegrationsDetailView';
import {AddIntegrationView} from './add-integration/AddIntegrationView';

const AdminPortalIntegrationsAllEnhanced = withRole(
  SuperAdminRole,
  `${paths.adminPortal.root}${paths.noPermission}`
)(IntegrationsAllView);

const AdminPortalIntegrationsDetailEnhanced = withRole(
  SuperAdminRole,
  `${paths.adminPortal.root}${paths.noPermission}`
)(IntegrationsDetailView);

const AdminPortalAddIntegrationEnhanced = withRole(
  SuperAdminRole,
  `${paths.adminPortal.root}${paths.noPermission}`
)(AddIntegrationView);

export const Integrations = () => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path={pages.adminPortal.integrations.root}
          render={() => <Redirect to={pages.adminPortal.integrations.root} />}
        />
        <Route path={pages.adminPortal.integrations.all}>
          <AdminPortalIntegrationsAllEnhanced />
          <Route path={`${pages.adminPortal.integrations.root}${paths.noPermission}`}>
            <NoPermissionsPage />
          </Route>
        </Route>
        <Route path={pages.adminPortal.integrations.details}>
          <AdminPortalIntegrationsDetailEnhanced />
          <Route path={`${pages.adminPortal.integrations.root}${paths.noPermission}`}>
            <NoPermissionsPage />
          </Route>
        </Route>
        <Route path={pages.adminPortal.integrations.add}>
          <AdminPortalAddIntegrationEnhanced />
          <Route path={`${pages.adminPortal.integrations.root}${paths.noPermission}`}>
            <NoPermissionsPage />
          </Route>
        </Route>
      </Switch>
    </Fragment>
  );
};

import {useAppDispatch, useNavigate} from 'hooks';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {
  activeEntitySelector,
  fetchProgramClassThunk,
  loadingSelector,
  programClassDetailsActions as actions,
} from '../store';

export function useProgramClass(classId: number) {
  const dispatch = useAppDispatch();
  const {push} = useNavigate();
  const currentClass = useSelector(activeEntitySelector);
  const loading = useSelector(loadingSelector);

  const fetchClass = useCallback(
    (optional?: boolean) => {
      if (!loading && (!optional || !currentClass || currentClass.id !== classId)) {
        dispatch(actions.resetActiveEntry());
        dispatch(fetchProgramClassThunk({classId, push}));
      }
    },
    [loading, currentClass, classId, dispatch, push]
  );

  return {
    loading,
    currentClass,
    fetchClass,
    reset: actions.resetActiveEntry,
  };
}

import {useAppDispatch, useNavigate} from 'hooks';
import {RolesEnum} from 'permissions/enums';
import {permissionsManager} from 'permissions/services';
import {hasRequiredPermissions} from 'permissions/utils';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {fetchUserRoleThunk, permissionsStateSelector, userRoleListStateSelector} from '../store';

export function usePermissions() {
  const dispatch = useAppDispatch();
  const {loading: permissionsLoading, requestTriggered: permissionsRequestTriggered} = useSelector(
    permissionsStateSelector
  );
  const {push} = useNavigate();

  const userRoles = permissionsManager.getActiveUserRoles();

  const fetchUserRole = useCallback(() => {
    dispatch(fetchUserRoleThunk({push}));
  }, [dispatch, push]);

  const hasPermission = useCallback(
    (requiredRoles: RolesEnum[]) => {
      return hasRequiredPermissions(userRoles, requiredRoles);
    },
    [userRoles]
  );

  const allUserRoles = useSelector(userRoleListStateSelector);

  return {fetchUserRole, permissionsLoading, permissionsRequestTriggered, userRoles, allUserRoles, hasPermission};
}

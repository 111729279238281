import React, {FC, useCallback, useMemo} from 'react';
import {batch} from 'react-redux';
import {FormikHelpers} from 'formik/dist/types';
import {Form} from 'forms';
import {IWithChildren} from 'models/common';
import {useFileEdit, useFileType} from '../../hooks';
import {IEditFileMetadataFormBaseValues, editFileMetadataValidationSchema} from './config';
import {mapEditFileInitialValues} from '../../utils';
import {usePermissions} from 'permissions';
import {SuperAndNationalAdminroles} from 'enums/admin-roles';

type IFilesEditResourceFormProps = IWithChildren;

export const FilesEditResourceForm: FC<IFilesEditResourceFormProps> = ({children}) => {
  const {fileMetadata, updateResource, fileDetails} = useFileEdit();
  const fileType = useFileType();
  const {hasPermission} = usePermissions();

  const hasAdminPermission = hasPermission(SuperAndNationalAdminroles);

  const initialValues = useMemo(
    () => mapEditFileInitialValues(fileDetails, fileMetadata, fileType, hasAdminPermission),
    [fileMetadata, fileDetails, fileType, hasAdminPermission]
  );

  const submitHandler = useCallback(
    (formValues: IEditFileMetadataFormBaseValues, {resetForm, setSubmitting}: FormikHelpers<any>) => {
      return batch(() => {
        resetForm();
        setSubmitting(false);
        updateResource(formValues, fileType);
      });
    },
    [updateResource, fileType]
  );

  return (
    <Form.Form<IEditFileMetadataFormBaseValues>
      initialValues={initialValues}
      validationSchema={editFileMetadataValidationSchema}
      submitHandler={submitHandler}
    >
      {children}
    </Form.Form>
  );
};

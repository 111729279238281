import {IBindBaseEnumEntity} from 'models';

export const getProgramYearsCollectionString = (
  programYearsCollection: IBindBaseEnumEntity[],
  programYearsFilter: number[]
) => {
  const filteredProgramYears = programYearsCollection.filter((programYear) =>
    programYearsFilter.includes(programYear.id)
  );
  const programYearNames = filteredProgramYears.map((programYear) => programYear.displayText);
  return programYearNames;
};

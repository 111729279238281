import React, {FC, useCallback, useMemo} from 'react';
import {Form} from 'forms';
import {FormNameEnum} from 'enums/form';
import {IWithChildren} from 'models/common';
import {IGoogleSiteDetails} from 'models/resources';
import {googleSitesFormValidationSchema, IGoogleSitesFormValues} from '../../utils/forms/all';

interface IGoogleSitesFormProps extends IWithChildren {
  googleSitesList: IGoogleSiteDetails[];
}

export const GoogleSitesForm: FC<IGoogleSitesFormProps> = ({children, googleSitesList}) => {
  const submitHandler = useCallback(() => null, []);

  const initialValues = useMemo(
    () => ({
      [FormNameEnum.searchTerm]: '',
      [FormNameEnum.googleSitesList]: googleSitesList ?? [],
      [FormNameEnum.googleSitesFilteredList]: googleSitesList ?? [],
    }),
    [googleSitesList]
  );

  return (
    <Form.Form<IGoogleSitesFormValues>
      initialValues={initialValues}
      validationSchema={googleSitesFormValidationSchema}
      submitHandler={submitHandler}
    >
      {children}
    </Form.Form>
  );
};

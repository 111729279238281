import {SortingOrderEnum} from 'enums';
import {ProgramsClassesOrderingEnum} from '../enums/ProgramsClassesOrdering.enum';

export const programClassesOrderingMap = {
  [ProgramsClassesOrderingEnum.Class]: {
    [SortingOrderEnum.ASC]: 'Class: A-Z',
    [SortingOrderEnum.DESC]: 'Class: Z-A',
  },
  [ProgramsClassesOrderingEnum.BCRMClassNumber]: {
    [SortingOrderEnum.ASC]: 'BCRM Class Number: A-Z',
    [SortingOrderEnum.DESC]: 'BCRM Class Number: Z-A',
  },
  [ProgramsClassesOrderingEnum.SchoolName]: {
    [SortingOrderEnum.ASC]: 'School Name: A-Z',
    [SortingOrderEnum.DESC]: 'School Name: Z-A',
  },
  [ProgramsClassesOrderingEnum.NumberOfStudents]: {
    [SortingOrderEnum.ASC]: 'Number of Students: Asc',
    [SortingOrderEnum.DESC]: 'Number of Students: Desc',
  },
};

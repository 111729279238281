import React, {FC, Fragment} from 'react';
import {Row} from 'components-lib';
import {ErrorIcon, ErrorMessage} from '..';

interface IErrorBoxMessageProps {
  errorMessage?: string | string[];
  defaultMessage: string;
}

export const ErrorBoxMessage: FC<IErrorBoxMessageProps> = ({errorMessage, defaultMessage}) => {
  return (
    <Fragment>
      <Row align="center" justify="center">
        <ErrorIcon />
        <ErrorMessage message={errorMessage} defaultMessage={defaultMessage} withBottomMargin={false} />
      </Row>
    </Fragment>
  );
};

import React, {useCallback} from 'react';
import {Button, Column, Modal, Row, Text} from 'components-lib';
import {ReactComponent as IllustrationPlane} from 'resources/images/instruments/Illustration_paperplane.svg';
import {ReactComponent as IllustrationTrash} from 'resources/images/instruments/Illustration_trash.svg';
import {IInstrumentDetails} from 'models';
import {pages} from 'paths';
import {ToastNotificationTypeEnum, useToastNotifications} from 'toast-notifications';
import {instrumentsMessages} from '../../../utils';
import {useAppDispatch, useNavigate} from 'hooks';
import {useInstruments} from 'admin/library/instruments/hooks';
import {deleteInstrumentThunk} from 'admin/library/instruments/store';
import {useInstrument} from '../../hooks';
import {ModalCloneInstrumentWithForm} from 'admin/library/instruments/components/ModalCloneInstrument/ModalCloneInstrumentWithForm';

interface IModalsInstrumentDetailsProps {
  template: IInstrumentDetails;
  isDeleteModalOpen: boolean;
  isPublishModalOpen: boolean;
  isCloneModalOpen: boolean;
  deleteModalOnClose: () => void;
  publishModalOnClose: () => void;
  setIsCloneModalOpen: (state: boolean) => void;
  fetchInstrumentById: (instrumentId: number) => void;
}

export function ModalsInstrumentDetails({
  template,
  isDeleteModalOpen,
  deleteModalOnClose,
  isPublishModalOpen,
  publishModalOnClose,
  isCloneModalOpen,
  setIsCloneModalOpen,
}: IModalsInstrumentDetailsProps) {
  const {goBackOptionally} = useNavigate();
  const dispatch = useAppDispatch();
  const {addToastNotification} = useToastNotifications();
  const {pageSaving, pageLoading, pageDeleting} = useInstruments();
  const {publishMasterTemplate} = useInstrument();

  const handleDeleteModalClose = useCallback(() => {
    deleteModalOnClose();
  }, [deleteModalOnClose]);

  const handleDeleteModalFinish = useCallback(() => {
    dispatch(deleteInstrumentThunk({instrumentId: template.templateId})).then(() => {
      deleteModalOnClose();
      goBackOptionally(pages.adminPortal.instruments.all);
    });
  }, [dispatch, deleteModalOnClose, goBackOptionally, template.templateId]);

  const handlePublishModalClose = useCallback(() => {
    publishModalOnClose();
  }, [publishModalOnClose]);

  const handlePublishModalFinish = async () => {
    if (template.isMetaDataValid) {
      const {templateId} = template;
      publishMasterTemplate(templateId, publishModalOnClose);
    } else {
      addToastNotification(instrumentsMessages.publishError, ToastNotificationTypeEnum.error);
    }
  };

  return (
    <>
      <Modal isOpen={isDeleteModalOpen} handleClose={handleDeleteModalClose} title="Delete Confirmation" size="small">
        <Column>
          <Row align="center">
            <IllustrationTrash />
            <Text.Paragraph align="center" withMargin>
              Are you sure you want to permanently delete instrument &quot;{template?.templateName}&quot;?
            </Text.Paragraph>
            <Text.Paragraph>This action cannot be undone!</Text.Paragraph>
          </Row>
        </Column>
        <>
          <Button.Secondary clickHandler={handleDeleteModalClose} size="small">
            Cancel
          </Button.Secondary>
          <Button.Primary clickHandler={handleDeleteModalFinish} disabled={pageLoading || pageDeleting} size="small">
            {pageDeleting ? 'Loading...' : 'Delete'}
          </Button.Primary>
        </>
      </Modal>

      <Modal
        isOpen={isPublishModalOpen}
        handleClose={handlePublishModalClose}
        title="Publish Confirmation"
        size="small"
      >
        <Column>
          <Row align="center">
            <IllustrationPlane />
            <Text.Paragraph align="center" withMargin>
              Are you sure you want to publish instrument &quot;{template?.templateName}&quot; with the current tags?
            </Text.Paragraph>
          </Row>
        </Column>
        <>
          <Button.Secondary clickHandler={handlePublishModalClose} size="small">
            Cancel
          </Button.Secondary>
          <Button.Primary clickHandler={handlePublishModalFinish} size="small" disabled={pageSaving}>
            {pageSaving ? 'Loading...' : 'Publish'}
          </Button.Primary>
        </>
      </Modal>

      <ModalCloneInstrumentWithForm
        instrumentId={template.templateId}
        isOpen={isCloneModalOpen}
        setIsOpen={setIsCloneModalOpen}
      />
    </>
  );
}

import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormNameEnum} from 'filters-selections';
import {IWithMultiSelectOption} from 'filters-selections/models';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {fetchPillarsThunk, pillarsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {IOption, IWithDisabled} from 'models';

interface IPillarSelectProps extends IWithMultiSelectOption, IWithDisabled {
  label?: string;
  name?: string;
  shouldFetch?: boolean;
}

export function PillarSelect({
  selectionType,
  disabled,
  label = 'Pillar',
  shouldFetch = true,
  name = FormNameEnum.pillars,
}: IPillarSelectProps) {
  const dispatch = useDispatch();
  const pillars = useSelector(pillarsEntitiesCollectionSelector);
  const isMultiSelect = selectionType === 'multi';

  useEffect(() => {
    if (shouldFetch && !pillars.length) {
      dispatch(fetchPillarsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options: IOption[] = useMemo(() => pillars.map((pillar) => ({value: pillar.id, label: pillar.name})), [
    pillars,
  ]);

  return (
    <ItemWithPlaceholder label={label} hasItems={!!pillars.length}>
      {isMultiSelect ? (
        <Form.SelectAutocomplete name={name} options={options} multiple disabled={disabled} />
      ) : (
        <Form.Select name={name} options={options} disabled={disabled} />
      )}
    </ItemWithPlaceholder>
  );
}

import {createUniqueId} from 'utils/common';
import {ILearningPathSessionItem} from 'models/resources/learning-paths';
import {SessionBuilderEditModeEnum} from 'enums/learning-paths';

export const createEmptySession: () => ILearningPathSessionItem = () => ({
  id: createUniqueId(),
  title: '',
  description: '',
  resources: [],
  instruments: [],
  mode: SessionBuilderEditModeEnum.Edit,
  isAccordionExpanded: true,
  createdOn: new Date().toISOString(),
});

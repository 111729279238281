import {IAuditLogsColumn} from './../../models';
import {AuditLogsColumnsTableEnum, AuditLogsLabelEnum} from './../../enums';

export const getAuditLogsColumns = (): IAuditLogsColumn[] => [
  {
    id: AuditLogsColumnsTableEnum.DateOfChange,
    label: AuditLogsLabelEnum.DateOfChange,
    align: 'left',
  },
  {
    id: AuditLogsColumnsTableEnum.User,
    label: AuditLogsLabelEnum.User,
    align: 'left',
  },
  {
    id: AuditLogsColumnsTableEnum.Activity,
    label: AuditLogsLabelEnum.Activity,
    align: 'left',
  },
  {
    id: AuditLogsColumnsTableEnum.Outcome,
    label: AuditLogsLabelEnum.Outcome,
    align: 'left',
  },
  {
    id: AuditLogsColumnsTableEnum.Remarks,
    label: AuditLogsLabelEnum.Remarks,
    align: 'left',
  },
];

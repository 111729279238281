import {createAsyncThunk} from '@reduxjs/toolkit';
import {fileManagerApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const uploadInstrumentFooterLogoThunk: any = createAsyncThunk<any, FormData>(
  `${prefix} uploadInstrumentHeaderLogo`,
  async (payload: FormData, {rejectWithValue, dispatch}) => {
    try {
      const parsedBody = await fileManagerApi.uploadFooterLogo(payload);
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

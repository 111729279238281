import {makeStyles} from '@material-ui/core';
import {Button, Column, Row, Text} from 'components-lib';
import {QuestionAnswersTypeEnum} from 'enums';
import {FormNameEnum} from 'filters-selections';
import {ArrayHelpers} from 'formik';
import {Form} from 'forms';
import {IAnswerStack, IAnswerStackCustom} from 'models';
import React from 'react';
import {groupBy} from 'utils';
import {useCustomAnswerStack} from '../../hooks';
import {ItemAnswerStack} from '../ItemAnswerStack/ItemAnswerStack';
import {ItemAnswerStackCustom} from '../ItemAnswerStackCustom/ItemAnswerStackCustom';
import {SectionContainer} from '../SectionContainer/SectionContainer';

enum PublishedTextEnum {
  Published = 'Published',
  NotPublished = 'Not Published',
}

interface IItemAnswersProps {
  answerStacks: IAnswerStack[];
  questionType: QuestionAnswersTypeEnum;
  disableAll?: boolean;
}

export function ItemAnswers({answerStacks, questionType, disableAll}: IItemAnswersProps) {
  const classes = useStyles();
  const {customStacksValue, addCustomStack} = useCustomAnswerStack();
  const isMatrix = questionType === QuestionAnswersTypeEnum.matrixEnum;
  const heading = isMatrix ? 'Matrix Scale(s)' : 'Answers';
  const caption = isMatrix
    ? 'Select matrix scale(s). Then mark the correct or positive answer(s). You will have the ability to add child items during instrument creation.'
    : 'Select answer stack(s). Then mark the correct or positive answer(s).';

  const hasStacks = !!answerStacks && !!answerStacks.length;
  let stacksGrouped = {};

  if (hasStacks) {
    stacksGrouped = groupBy(
      answerStacks.map((stack) => ({
        ...stack,
        isPublished: stack.isApproved ? PublishedTextEnum.Published : PublishedTextEnum.NotPublished,
      })),
      (item) => item.isPublished
    );
  }

  return (
    <SectionContainer>
      <Row justify="flex-start">
        <Column className={classes.heading}>
          <Text.Heading as="h4">{heading}</Text.Heading>
          <Text.Paragraph>{caption}</Text.Paragraph>
          <Text.Paragraph>In order to proceed you should select at least one answer stack.</Text.Paragraph>
        </Column>
      </Row>

      {!isMatrix && (
        <Form.Array name={FormNameEnum.customStacks}>
          {(arrayHelpers: ArrayHelpers) => (
            <>
              <Row justify="flex-start">
                <Button.Primary
                  size="small"
                  isFirst
                  clickHandler={() => {
                    const customStack = addCustomStack();
                    arrayHelpers.insert(customStacksValue.length, customStack);
                  }}
                  disabled={disableAll}
                >
                  Create Answer Stack
                </Button.Primary>
              </Row>
              <Row justify="flex-start">
                {!!customStacksValue.length &&
                  customStacksValue.map((stack: IAnswerStackCustom, idx: number) => (
                    <ItemAnswerStackCustom
                      key={stack.id}
                      idx={idx}
                      stack={stack}
                      removeClickHandler={() => {
                        arrayHelpers.remove(idx);
                      }}
                    />
                  ))}
              </Row>
            </>
          )}
        </Form.Array>
      )}

      <Row justify="flex-start">
        <Form.Array name={FormNameEnum.answerStacks}>
          {() => {
            const published = stacksGrouped[PublishedTextEnum.Published];
            const notPublished = stacksGrouped[PublishedTextEnum.NotPublished];
            const additionalIdx = (published && published.length) ?? 0;

            return (
              hasStacks && (
                <>
                  {published && (
                    <>
                      <Text.Heading as="h4">{PublishedTextEnum.Published}</Text.Heading>
                      {published.map((answerStack: IAnswerStack, idx: number) => (
                        <ItemAnswerStack
                          key={answerStack.questionAnswerStackId}
                          idx={idx}
                          answerStack={answerStack}
                          disabled={disableAll}
                        />
                      ))}
                    </>
                  )}
                  <div className={classes.withMarginTop}>
                    {notPublished && (
                      <>
                        <Text.Heading as="h4">{PublishedTextEnum.NotPublished}</Text.Heading>
                        {notPublished.map((answerStack: IAnswerStack, idx: number) => (
                          <ItemAnswerStack
                            key={answerStack.questionAnswerStackId}
                            idx={additionalIdx + idx}
                            answerStack={answerStack}
                            disabled={disableAll}
                          />
                        ))}
                      </>
                    )}
                  </div>
                </>
              )
            );
          }}
        </Form.Array>
      </Row>
    </SectionContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(3),
  },
  withMarginTop: {
    marginTop: theme.spacing(2),
  },
}));

import React, {Fragment} from 'react';
import {useGoogleSitesDetails} from '../../../hooks';

export const Subheading = () => {
  const {googleSite} = useGoogleSitesDetails();
  const hasGoogleSiteName = !!googleSite?.name;
  const subHeadingName = `\u00a0 / ${googleSite.name}`;

  return <Fragment> {hasGoogleSiteName && subHeadingName} </Fragment>;
};

import {Search} from 'components-lib';
import {FieldProps} from 'formik';
import React from 'react';
import {Field} from './Field';
import {IWithDisabled} from 'models';
import {useDebounceUserInput} from 'hooks';

interface IFormSearchProps extends IWithDisabled {
  name: string;
  value?: string;
  debounceDelay?: number;
  withIcon?: boolean;
  submitDisabled?: boolean;
}

export function FormSearch({name, disabled, value, debounceDelay, withIcon, submitDisabled}: IFormSearchProps) {
  return (
    <Field
      name={name}
      value={value}
      debounceDelay={debounceDelay}
      withIcon={withIcon}
      Component={SearchDecorated}
      disabled={disabled}
      submitDisabled={submitDisabled}
    />
  );
}

interface ISearchDecoratedProps extends FieldProps, IFormSearchProps {}

function SearchDecorated({field: {name, onChange}, form: {values}, debounceDelay, ...rest}: ISearchDecoratedProps) {
  const initialValue: string = values[name];
  const [value, changeHandler] = useDebounceUserInput(initialValue, onChange, debounceDelay);

  const props = {
    ...rest,
    value,
    changeHandler,
  };

  return <Search {...props} />;
}

import React, {FC} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {pages, paths} from 'paths';
import {LearningPathsViewAll} from './views/all';
import {LearningPathViewCreate} from './views/create';
import {LearningPathViewEdit} from './views/edit';
import {AdminRoles, withRole} from 'permissions';

const LearningPathsViewAllEnhanced: () => JSX.Element = withRole(
  AdminRoles,
  `${paths.adminPortal.root}${paths.noPermission}`
)(LearningPathsViewAll);

const LearningPathViewCreateEnhanced: () => JSX.Element = withRole(
  AdminRoles,
  `${paths.adminPortal.root}${paths.noPermission}`
)(LearningPathViewCreate);

const LearningPathViewEditEnhanced: () => JSX.Element = withRole(
  AdminRoles,
  `${paths.adminPortal.root}${paths.noPermission}`
)(LearningPathViewEdit);

export const LearningPaths: FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={pages.adminPortal.learningPaths.root}
        render={() => <Redirect to={pages.adminPortal.learningPaths.all} />}
      />
      <Route path={pages.adminPortal.learningPaths.all} component={LearningPathsViewAllEnhanced} />
      <Route path={pages.adminPortal.learningPaths.create} component={LearningPathViewCreateEnhanced} />
      <Route path={pages.adminPortal.learningPaths.edit} component={LearningPathViewEditEnhanced} />
    </Switch>
  );
};

import React, {useMemo} from 'react';
import {Text} from 'components-lib';
import {FormNameEnum, SelectionEnum, SelectionListItems} from 'filters-selections';
import {useFormikContext} from 'formik';
import {Form} from 'forms';
import {usePermissions} from 'permissions';
import {checkIfAreaAdmin} from '../../details/utils';

const fieldsListDefault = [
  SelectionEnum.InstrumentName,
  SelectionEnum.Pillar,
  SelectionEnum.InstrumentType,
  SelectionEnum.InstrumentSubType,
  SelectionEnum.Audience,
  SelectionEnum.Phase,
  SelectionEnum.ProgramMultiSelection,
];

export function CreateInstrumentFormFields() {
  const {values} = useFormikContext();
  const {hasPermission} = usePermissions();
  const isGeneralInstrument = values[FormNameEnum.isGeneralInstrument];
  const isAreaAdmin = checkIfAreaAdmin(hasPermission);

  const selectionListItems = useMemo(() => {
    const fieldsListGeneralInstrument = [SelectionEnum.InstrumentName];
    const list = isGeneralInstrument ? fieldsListGeneralInstrument : fieldsListDefault;

    return <SelectionListItems list={list} disableAll={isGeneralInstrument} />;
  }, [isGeneralInstrument]);

  return (
    <>
      <Text.Paragraph>Add tags for the newly created instrument. You can always edit this later.</Text.Paragraph>
      <Form.Switch
        label="General Instrument"
        disabled={isAreaAdmin}
        value={isGeneralInstrument}
        name={FormNameEnum.isGeneralInstrument}
      />
      {selectionListItems}
    </>
  );
}

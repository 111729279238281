import React, {FC} from 'react';
import {Form} from 'forms';
import {FormNameEnum} from 'filters-selections';
interface ICreateQuestionEditorProps {
  readOnly: boolean;
  withEdit: boolean;
  toolbarHidden: boolean;
}

export const CreateQuestionEditor: FC<ICreateQuestionEditorProps> = ({readOnly, withEdit, toolbarHidden}) => {
  return (
    <Form.RichTextEditor
      readOnly={readOnly}
      withEdit={withEdit}
      name={FormNameEnum.questionTextHtml}
      placeholder="Add question text here..."
      toolbarHidden={toolbarHidden}
    />
  );
};

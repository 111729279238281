import React, {FC, useRef} from 'react';
import {SessionResourceListItem} from '..';
import {IBaseResourceItem} from 'models';
import {BuilderItemDragTypeEnum} from 'admin/library/enums';
import {DragItem, useItemDrag, useItemDrop} from 'dragAndDrop';
import {USAAndSuperAdminRoles, usePermissions} from 'permissions';

interface ISessionResourceDragListItemProps {
  index: number;
  itemId?: string;
  resourceItem: IBaseResourceItem;
  canDelete?: boolean;
  canHide?: boolean;
  isEditMode?: boolean;
  isResource?: boolean;
  moveResource: (dragIndex: number, hoverIndex: number) => void;
}

export const SessionResourceDragListItem: FC<ISessionResourceDragListItemProps> = ({
  index,
  itemId,
  resourceItem,
  canDelete,
  isEditMode,
  isResource,
  moveResource,
}) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const itemDrag = {
    ...resourceItem,
    index,
    type: BuilderItemDragTypeEnum.sessionResourceItem,
  } as IBaseResourceItem;

  const {hasPermission} = usePermissions();
  const canDrag = isEditMode && hasPermission(USAAndSuperAdminRoles);
  const {drag} = useItemDrag(itemDrag, canDrag);
  const {drop, handlerId} = useItemDrop(moveResource, BuilderItemDragTypeEnum.sessionResourceItem, itemRef, index);

  drag(drop(itemRef));

  return (
    <DragItem drag={itemRef} handlerId={handlerId}>
      <SessionResourceListItem
        itemId={itemId}
        resourceItem={resourceItem}
        canDelete={canDelete}
        isEditMode={isEditMode}
        isResource={isResource}
      />
    </DragItem>
  );
};

import {QuestionAnswersTypeEnum} from 'enums';
import {IChoice} from 'models/library/common/IAnswer';

export const getTypeRadioGroup = (questionTypeAnswerStack: string) =>
  questionTypeAnswerStack === QuestionAnswersTypeEnum.radioGroup;

export const getTypeCheckbox = (questionTypeAnswerStack: string) =>
  questionTypeAnswerStack === QuestionAnswersTypeEnum.checkbox;

export const getTypeDropdown = (questionTypeAnswerStack: string) =>
  questionTypeAnswerStack === QuestionAnswersTypeEnum.dropdown;

export const checkCorrectAnswer = (choices: IChoice[]) => {
  const isCorrect = choices.find((choice) => choice.isCorrect);
  return !!isCorrect;
};

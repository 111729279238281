import React, {FC} from 'react';
import {Form} from 'forms';
import {ItemWithPlaceholder} from '../ItemWithPlaceholder';
import {IWithDisabled} from 'models';

interface ITextInputProps extends IWithDisabled {
  label: string;
  name: string;
  value?: string;
  infoText?: string;
  autoFocus?: boolean;
}

export const TextInputSelection: FC<ITextInputProps> = ({
  label,
  name,
  disabled = false,
  autoFocus = false,
  value = '',
  infoText,
}) => (
  <ItemWithPlaceholder label={label} hasItems infoText={infoText}>
    <Form.InputWithField name={name} type="text" disabled={disabled} value={value} autoFocus={autoFocus} />
  </ItemWithPlaceholder>
);

import {FiltersEnum} from 'filters-selections';

export const filesAllLearningPathsFilterList = [
  FiltersEnum.ProgramMulti,
  FiltersEnum.ProgramYearMulti,
  FiltersEnum.TagGradeLevels,
  FiltersEnum.Pillar,
  FiltersEnum.Languages,
  FiltersEnum.LearningPathStatusesCheckbox,
];

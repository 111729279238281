import React, {FC} from 'react';
import {FormNameEnum} from 'filters-selections';
import {DatePickerSelection} from '../DatePickerSelection/DatePickerSelection';
import {IWithDisabled} from 'models/common';

interface IFromDatePickerProps extends IWithDisabled {
  disableFuture?: boolean;
}

export const FromDatePicker: FC<IFromDatePickerProps> = ({disabled, disableFuture = true}) => (
  <DatePickerSelection
    label="From"
    name={FormNameEnum.from}
    disableFuture={disableFuture}
    disabled={disabled}
    maxDateMessage="The selected date should be in the past"
  />
);

import React, {FC, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from 'forms';
import {Accordion} from 'components-lib';
import {fetchProgramYearsThunk} from 'filters-selections';
import {FormNameEnum} from 'filters-selections/enums';
import {filtersYearsEntitiesCollectionSelector} from 'filters-selections/store';
import {IOption, IWithDisabled} from 'models';
import {IWithMultiSelectOption} from 'filters-selections/models';
interface IProgramYearFilterProps extends IWithMultiSelectOption, IWithDisabled {
  name?: string;
}

export const ProgramYearFilter: FC<IProgramYearFilterProps> = ({
  selectionType,
  disabled,
  name = FormNameEnum.programYear,
}) => {
  const dispatch = useDispatch();

  const programYears = useSelector(filtersYearsEntitiesCollectionSelector);

  const isMultiSelect = selectionType === 'multi';

  useEffect(() => {
    if (!programYears.length) {
      dispatch(fetchProgramYearsThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options: IOption[] = useMemo(
    () =>
      programYears.map((programYear) => ({
        value: programYear.id,
        label: programYear.displayText,
      })),
    [programYears]
  );

  return (
    <Accordion heading="Program Years">
      {isMultiSelect ? (
        <Form.SelectAutocomplete name={name} options={options} multiple disabled={disabled} />
      ) : (
        <Form.Select name={name} options={options} disabled={disabled} />
      )}
    </Accordion>
  );
};

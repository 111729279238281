import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {useAppDispatch} from 'hooks';
import {
  levelOfReportsLoadingSelector,
  levelOfReportsMenuItemsSelector,
  programDetailsActions as actions,
  selectedIdSelector,
} from '../store';
import {IProgramClassReportMenuListOption, IProgramClassReportParams} from 'models';
import {defaultProgramClassMenuItemOption, defaultProgramMenuItemOption} from '../store/programDetails.initialState';

export const useProgramReportsStoreActions = () => {
  const dispatch = useAppDispatch();
  const {classId} = useParams<IProgramClassReportParams>();

  const loading = useSelector(levelOfReportsLoadingSelector) as boolean;
  const selectedId = useSelector(selectedIdSelector) as number;
  const menuList = useSelector(levelOfReportsMenuItemsSelector) as IProgramClassReportMenuListOption[];

  const selectedItem = useMemo(() => {
    const defaultSelectedOption = classId ? defaultProgramClassMenuItemOption : defaultProgramMenuItemOption;

    if (!menuList.length) {
      return defaultSelectedOption;
    }

    return menuList.find((item) => item.id === selectedId);
  }, [selectedId, menuList, classId]);

  const clearProgramReportsState = useCallback(() => dispatch(actions.clearLevelOfReports()), [dispatch]);

  const setSelectedId: (id: number) => void = useCallback((id: number) => dispatch(actions.setSelectedId(id)), [
    dispatch,
  ]);

  const isProgramLevelAllOption = selectedItem?.id === defaultProgramMenuItemOption.id;

  return {
    loading,
    selectedId,
    selectedItem,
    menuList,
    setSelectedId,
    isProgramLevelAllOption,
    clearProgramReportsState,
  };
};

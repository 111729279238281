import React, {useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {PageLayoutTwoCol, PageLayoutWithFixedAreas} from 'layout';
import {PaperAssessmentsHeader, SidebarFilters} from './components';
import {Button, Icon, Loading, PaginationLocal, Row, Table, Tooltip, Wrapper} from 'components-lib';
import {
  useClassPaperAssessments,
  useClassPaperAssessmentsStoreActions,
  useProgramClassDetails,
  useProgramClassDetailsActions,
} from './hooks';
import {paperAssessmentsColumns} from './utils';
import {IProgramClassPaperAssessmentListItem} from 'models';

export const ProgramClassPaperAssessmentsView = () => {
  const classes = useStyles();

  const {
    loading,
    downloadPaperAssessment,
    paperAssessmentsIds,
    paperAssessmentsEntities,
    fetchClassPaperAssessmentsPaged,
  } = useClassPaperAssessments();

  const {
    clearPaperAssessments,
    totalCount,
    perPage,
    currentPage,
    setPerPage,
    setCurrentPage,
  } = useClassPaperAssessmentsStoreActions();
  const {programId, classId} = useProgramClassDetails();

  useProgramClassDetailsActions();

  const shouldFetchPaperAssessments = !!programId && !!classId;

  useEffect(() => {
    if (shouldFetchPaperAssessments) {
      fetchClassPaperAssessmentsPaged();
    }

    return () => {
      clearPaperAssessments();
    };
  }, [clearPaperAssessments, shouldFetchPaperAssessments, fetchClassPaperAssessmentsPaged]);

  const rows = useMemo(() => {
    if (!paperAssessmentsIds.length) {
      return [];
    }

    return paperAssessmentsIds.map((key: string) => {
      const {description, downloadUrl, title, uploadedBy} = paperAssessmentsEntities[
        key
      ] as IProgramClassPaperAssessmentListItem;
      const descriptionTooltipText = description ?? '';
      const shouldDisableTooltip = !descriptionTooltipText;

      return {
        name: (
          <Row justify="flex-start" align="center">
            <Wrapper className={classes.infoText}>
              <Tooltip title={descriptionTooltipText}>
                <Icon.InfoIcon disabled={shouldDisableTooltip} />
              </Tooltip>
            </Wrapper>
            <Wrapper className={classes.paperAssessmentsName}>
              <Tooltip size="large" title={title}>
                {title}
              </Tooltip>
            </Wrapper>
          </Row>
        ),
        uploadedBy: <Row justify="flex-start">{uploadedBy}</Row>,
        actions: (
          <Button.Secondary size="small" clickHandler={() => downloadPaperAssessment(downloadUrl)} disabled={false}>
            Download
          </Button.Secondary>
        ),
      };
    });
  }, [
    classes.infoText,
    downloadPaperAssessment,
    classes.paperAssessmentsName,
    paperAssessmentsEntities,
    paperAssessmentsIds,
  ]);

  const footer = useMemo(() => {
    return (
      <PaginationLocal
        onPageChange={setCurrentPage}
        onPerPageChange={setPerPage}
        totalCount={totalCount}
        perPage={perPage}
        currentPage={currentPage}
        disabled={loading}
      />
    );
  }, [currentPage, perPage, setCurrentPage, setPerPage, totalCount, loading]);

  const content = useMemo(() => {
    return (
      <PageLayoutWithFixedAreas
        headerSize="small"
        withSecondaryNavigation={false}
        header={<PaperAssessmentsHeader />}
        footer={footer}
      >
        {loading ? <Loading /> : <Table rows={rows} columns={paperAssessmentsColumns} hasRows={!!rows.length} />}
      </PageLayoutWithFixedAreas>
    );
  }, [rows, footer, loading]);

  return <PageLayoutTwoCol withSecondaryNavigation={false} sidebar={<SidebarFilters />} content={content} />;
};

const useStyles = makeStyles((theme) => ({
  infoText: {
    cursor: 'pointer',
    paddingRight: theme.spacing(2),
  },
  paperAssessmentsName: {
    maxWidth: '30vw',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  '@media screen and (max-width: 1400px)': {
    paperAssessmentsName: {
      maxWidth: '25vw',
    },
  },

  '@media screen and (max-width: 1280px)': {
    paperAssessmentsName: {
      maxWidth: '20vw',
    },
  },
}));

import React, {FC} from 'react';
import {FormNameEnum} from 'filters-selections';
import {TextInputSelection} from '../TextInputSelection/TextInputSelection';
import {IWithDisabled} from 'models/common';

type IWebLinkTextInputProps = IWithDisabled;

export const WebLinkTextInput: FC<IWebLinkTextInputProps> = ({disabled}) => (
  <TextInputSelection name={FormNameEnum.webLink} label="Web Link" disabled={disabled} />
);

import {createAsyncThunk} from '@reduxjs/toolkit';
import {itemsApi} from 'api';
import {IUpdateItemPayload, IUpdateItemResponse} from 'models';
import {prefix} from './config';
import {itemsMessages} from '../../utils';
import {toastNotificationManager} from 'toast-notifications';

export const updateItemThunk: any = createAsyncThunk<IUpdateItemResponse, IUpdateItemPayload>(
  `${prefix} updateItem`,
  async (payload: IUpdateItemPayload, {dispatch, rejectWithValue}) => {
    try {
      const {parsedBody} = await itemsApi.updateItem(payload);
      dispatch(toastNotificationManager.createSuccessToastAction(itemsMessages.updateSuccess));
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

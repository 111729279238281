import {replaceWithNullIfEmptyString} from 'admin';
import {IIntegrationDetailsFormBaseValues} from 'models/integrations';

export const mapUpdateGoogleClassroomPayload = ({
  serviceEmail,
  integrationName,
  certificate,
  masterClassroomEmailAccount,
}: IIntegrationDetailsFormBaseValues) => {
  const data = new FormData();

  const configServiceEmail = replaceWithNullIfEmptyString(serviceEmail);
  const configIntegrationName = replaceWithNullIfEmptyString(integrationName);

  if (masterClassroomEmailAccount) {
    data.append('masterClassroomEmailAccount', masterClassroomEmailAccount);
  }

  data.append('certificate', certificate ?? null);
  data.append('name', configIntegrationName);
  data.append('serviceEmailAccount', configServiceEmail);

  return data;
};

import {useAppDispatch} from 'hooks';
import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {integrationsActions as actions} from '../store/integrations.slice';
import {
  fetchIntegrationStatusesThunk,
  fetchIntegrationTypesThunk,
  integrationsAllFilterSelector,
  integrationsAllSelector,
  integrationsAllSortOrderSelector,
  pageLoadingSelector,
  paginationSelector,
} from '../store';
import {fetchIntegrationsPagedThunk} from '../store';
import {integrationFiltersEntitiesSelector} from 'filters-selections';
import {fetchIntegrationListDropdownThunk} from 'filters-selections/store';
import {IIntegratiosAllFilter} from 'models';

export const useIntegrations = () => {
  const dispatch = useAppDispatch();

  const {currentPage, perPage, totalCount} = useSelector(paginationSelector);
  const pageLoading = useSelector(pageLoadingSelector);
  const integrationsList = useSelector(integrationsAllSelector);
  const filter = useSelector(integrationsAllFilterSelector);
  const sortOrder = useSelector(integrationsAllSortOrderSelector);
  const filterEntities = useSelector(integrationFiltersEntitiesSelector);

  const setCurrentPage = useCallback((newPage: number) => dispatch(actions.setCurrentPage(newPage)), [dispatch]);

  const setPerPage = useCallback((perPage: number) => dispatch(actions.setPerPage(perPage)), [dispatch]);

  const fetchIntegrations = useMemo(
    () => () =>
      dispatch(
        fetchIntegrationsPagedThunk({
          filter,
          sortOrder,
          pagination: {
            page: currentPage,
            itemsPerPage: perPage,
          },
        })
      ),
    [dispatch, filter, currentPage, perPage, sortOrder]
  );

  const fetchIntegrationTypes = useMemo(() => () => dispatch(fetchIntegrationTypesThunk()), [dispatch]);

  const fetchIntegrationStatuses = useMemo(() => () => dispatch(fetchIntegrationStatusesThunk()), [dispatch]);

  const fetchIntegrationListDropdown = useMemo(() => () => dispatch(fetchIntegrationListDropdownThunk()), [dispatch]);

  const setIntegrationFilterItems = useCallback(
    (updated: IIntegratiosAllFilter) => dispatch(actions.setFilterIntegrations(updated)),
    [dispatch]
  );

  return {
    filter,
    filterEntities,
    currentPage,
    perPage,
    totalCount,
    setCurrentPage,
    setPerPage,
    pageLoading,
    integrationsList,
    fetchIntegrations,
    fetchIntegrationTypes,
    fetchIntegrationStatuses,
    fetchIntegrationListDropdown,
    setIntegrationFilterItems,
  };
};

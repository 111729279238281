import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Container} from 'components-lib';
import {DetailsKSAItem} from '..';
import {useEditLearningPath} from '../../hooks';
import {LearningPathLabelEnum} from 'admin/resources/learning-paths';

export const KnowledgeSkillAndAbilitiesDetails = () => {
  const classes = useStyles();
  const {learningPathDetails} = useEditLearningPath();

  return (
    <Container disableGutters classes={{root: classes.ksasContainer}}>
      <DetailsKSAItem heading={LearningPathLabelEnum.Competencies} list={learningPathDetails?.metadata?.competencies} />
      <DetailsKSAItem
        heading={LearningPathLabelEnum.KnowledgeSkillsAndAbilities}
        list={learningPathDetails?.metadata?.ksAs}
      />
    </Container>
  );
};

export const useStyles = makeStyles((theme) => ({
  ksasContainer: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));

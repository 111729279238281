import {FiltersEnum} from 'filters-selections';
import {LearningPathsAllFilterNamesEnum} from '../../enums';

export const learningPathsAllFilterToFilterNameMap = {
  [FiltersEnum.ProgramMulti]: LearningPathsAllFilterNamesEnum.PROGRAMS,
  [FiltersEnum.Pillar]: LearningPathsAllFilterNamesEnum.PILLARS,
  [FiltersEnum.ProgramYearMulti]: LearningPathsAllFilterNamesEnum.PROGRAM_YEARS,
  [FiltersEnum.TagGradeLevels]: LearningPathsAllFilterNamesEnum.GRADE_LEVELS,
  [FiltersEnum.Languages]: LearningPathsAllFilterNamesEnum.LANGUAGES,
  [FiltersEnum.LearningPathStatusesCheckbox]: LearningPathsAllFilterNamesEnum.LEARNING_PATH_STATUSES,
};

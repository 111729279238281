import {makeStyles} from '@material-ui/core';
import React from 'react';
import {Loading} from '../Loading/Loading';

interface ILoadingSmallProps {
  position?: 'left' | 'right';
}

export function LoadingSmall({position = 'right'}: ILoadingSmallProps) {
  const classes = useStyles({position});

  return (
    <div className={classes.spinner}>
      <Loading fullHeight={false} size={10} />
    </div>
  );
}

const useStyles = makeStyles(() => {
  return {
    container: {
      position: 'relative',
    },
    spinner: ({position}: any) => {
      if (position === 'left') {
        return {position: 'absolute', top: 16, left: 14};
      }

      return {position: 'absolute', top: 16, right: 14};
    },
  };
});

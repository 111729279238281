import React, {FC} from 'react';
import {FormNameEnum} from 'filters-selections';
import {IWithDisabled} from 'models/common';
import {Form} from 'forms';
interface IPermissionCheckboxProps extends IWithDisabled {
  checked?: boolean;
}

export const PermissionCheckbox: FC<IPermissionCheckboxProps> = ({disabled, checked}) => (
  <Form.Checkbox
    name={FormNameEnum.hasUploadPermissions}
    label="I acknowledge that I have permissions and paperwork necessary to submit and upload this file"
    disabled={disabled}
    checked={checked}
  />
);

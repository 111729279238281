import {createAsyncThunk} from '@reduxjs/toolkit';
import {programsApi} from 'api';
import {IGetLevelsOfReportingApiResponse, IGetLevelsOfReportingPayload, IGetLevelsOfReportingQueryParams} from 'models';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchLevelsOfReportingListThunk: any = createAsyncThunk<
  IGetLevelsOfReportingApiResponse,
  IGetLevelsOfReportingPayload
>(`${prefix} fetchLevelsOfReportingList`, async ({programId, classId}, {rejectWithValue, dispatch}) => {
  try {
    let queryParams = {} as IGetLevelsOfReportingQueryParams;

    if (programId) {
      queryParams = {...queryParams, programId};
    }

    if (classId) {
      queryParams = {...queryParams, sessionId: classId};
    }

    const {parsedBody} = await programsApi.getLevelsOfReportingListByProgramId(queryParams);

    return parsedBody;
  } catch (err) {
    dispatch(
      toastNotificationManager.createErrorToastAction(err.parsedBody || err.statusText || 'Unknown error occurred.')
    );

    return rejectWithValue({error: err});
  }
});

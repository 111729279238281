import React, {FC, Fragment, useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {useAddIntegration, useAddIntegrationStateActions} from '../../hooks';
import {Column, Row, Wrapper} from 'components-lib';
import {SelectionEnum, SelectionListItems} from 'filters-selections';
import {Subtitle} from '..';
import {ErrorBox} from 'admin/components/ErrorBox/ErrorBox';
import {heightThreeStepsActivity} from 'utils/layout';
import {fixedInputHeight} from '../../utils';

const firstColList = [
  SelectionEnum.IntegrationTypeSelect,
  SelectionEnum.AreaSingleBasedOnDistrictAndGoogleSites,
  SelectionEnum.SchoolNameBasedOnAreaAndDistrict,
];

const secondColList = [SelectionEnum.IntegrationNameInput, SelectionEnum.SchoolDistrictBasedOnArea];

export const AddIntegrationMetadata: FC = () => {
  const classes = useStyles();
  const {fetchPublishedSourceDetails} = useAddIntegrationStateActions();
  const {hasServiceSourceEmail, googleSitesSourceErrorMessage, canShowGoogleSitesErrorBox} = useAddIntegration();

  useEffect(() => {
    if (!hasServiceSourceEmail) {
      fetchPublishedSourceDetails();
    }
  }, [fetchPublishedSourceDetails, hasServiceSourceEmail]);

  const renderContentFirstColList = useMemo(
    () => <SelectionListItems className={classes.selectionItem} list={firstColList} withMargin={false} />,
    [classes.selectionItem]
  );

  const renderContentSecondColList = useMemo(
    () => <SelectionListItems className={classes.selectionItem} list={secondColList} withMargin={false} />,
    [classes.selectionItem]
  );

  return (
    <Fragment>
      <Subtitle withColor={false} as="h3" color="textPrimary" title="Step 1: Choose Integration Details" />
      <Row justify="flex-start" classes={{root: classes.row}}>
        <Column sm={8} md={8} lg={8} xl={6} className={classes.content}>
          <Row align="flex-start">
            <Column sm={6} md={6} lg={6} xl={6}>
              {renderContentFirstColList && renderContentFirstColList}
            </Column>
            <Column sm={6} md={6} lg={6} xl={6}>
              {renderContentSecondColList && renderContentSecondColList}
            </Column>
          </Row>
        </Column>
        <Column sm={4} md={4} lg={4} xl={4} className={classes.content}>
          <Row align="flex-start" justify="flex-start">
            <Column sm={6} md={6} lg={6} xl={6}>
              <Wrapper className={classes.errorBox}>
                {canShowGoogleSitesErrorBox && <ErrorBox errorMessages={googleSitesSourceErrorMessage} />}
              </Wrapper>
            </Column>
          </Row>
        </Column>
      </Row>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(2),
  },
  content: {
    height: heightThreeStepsActivity,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  inputName: {
    height: fixedInputHeight,
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  selectionItem: {
    height: fixedInputHeight,
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  errorBox: {
    paddingTop: theme.spacing(4) - theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}));

import {FormControlLabel, makeStyles, Radio, RadioGroup} from '@material-ui/core';
import {Column, Text} from 'components-lib';
import {useAppDispatch} from 'hooks';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {filtersAreasEntitiesCollectionSelector, fetchAreasThunk} from 'filters-selections';
import {IBindBaseEntity, IWithDisabled} from 'models';
import {toNumber} from 'utils';
import {useSortOrder} from '../../hooks';
import {SortingOrderEnum} from 'enums';

interface IProps extends IWithDisabled {
  selectedArea: number;
  onSelectArea: React.Dispatch<number>;
  withAllAreas?: boolean;
}

export function AreaSelect({selectedArea, onSelectArea, disabled, withAllAreas = true}: IProps) {
  const NAME_FIELD = 'name';
  const ALL_AREAS = 'All areas';
  const NEGATIVE_ID = -1;

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const {sortBy} = useSortOrder();
  const areas: IBindBaseEntity[] = useSelector(filtersAreasEntitiesCollectionSelector);

  const allAreasOption = useMemo(() => ({id: NEGATIVE_ID, name: ALL_AREAS}), [NEGATIVE_ID]);
  const sortedOptions = sortBy(areas, NAME_FIELD, SortingOrderEnum.ASC);
  let extendedAreas = useMemo(() => [...sortedOptions], [sortedOptions]);

  if (withAllAreas) {
    extendedAreas = [allAreasOption, ...extendedAreas];
  }

  const onChange = useCallback(
    (_, value: string) => {
      onSelectArea(toNumber(value));
    },
    [onSelectArea]
  );

  useEffect(() => {
    dispatch(fetchAreasThunk());
  }, [dispatch]);

  return (
    <Column className={classes.root}>
      <Text.Heading as="h4">JA Area</Text.Heading>
      <RadioGroup aria-label="choose_area" name="choose_area" value={selectedArea} onChange={onChange}>
        {!!areas.length ? (
          extendedAreas.map((area) => (
            <FormControlLabel
              value={area.id}
              control={<Radio color="primary" />}
              label={area.name}
              key={area.name}
              disabled={disabled}
            />
          ))
        ) : (
          <Text.Caption>No Areas</Text.Caption>
        )}
      </RadioGroup>
    </Column>
  );
}

const useStyles = makeStyles({
  root: {
    paddingLeft: 10,
  },
});

import React, {FC} from 'react';
import {IWithDisabled} from 'models';
import {ProgramYearSelect} from './ProgramYearSelect';
import {FormNameEnum} from 'filters-selections';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProgramYearSingleSelectProps extends IWithDisabled {}

export const ProgramYearSingleSelect: FC<IProgramYearSingleSelectProps> = ({disabled}) => {
  return <ProgramYearSelect selectionType="single" disabled={disabled} name={FormNameEnum.programYear} />;
};

import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch, useNavigate} from 'hooks';
import {
  programsSelector,
  filterSelector,
  paginationSelector,
  fetchProgramsPagedThunk,
  pageLoadingSelector,
  programsAllSortOrderSelector,
  programsAllActions as actions,
} from '../store';

import {IProgramsAllFilter} from 'models';
import {
  filtersEntitiesCollectionSelector,
  filtersEntitiesSelector,
  filtersYearsEntitiesCollectionSelector,
} from 'filters-selections';
import {pages} from 'paths';
import {mapProgramsPayload} from '../utils';

export const usePrograms = () => {
  const dispatch = useAppDispatch();
  const {push} = useNavigate();
  const loading = useSelector(pageLoadingSelector) as boolean;
  const filter = useSelector(filterSelector);
  const programs = useSelector(programsSelector);
  const {currentPage, perPage, totalCount} = useSelector(paginationSelector);
  const filterEntities = useSelector(filtersEntitiesSelector);
  const filterEntitiesCollection = useSelector(filtersEntitiesCollectionSelector);
  const yearsCollection = useSelector(filtersYearsEntitiesCollectionSelector);
  const sortOrder = useSelector(programsAllSortOrderSelector);

  const fetchPrograms: () => void = useCallback(() => {
    const payload = mapProgramsPayload({
      filter,
      currentPage,
      perPage,
      sortOrder,
      programYearsCollection: yearsCollection,
    });

    return dispatch(fetchProgramsPagedThunk(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filter, perPage, sortOrder]);

  const setFilter: (filter: IProgramsAllFilter) => void = useCallback(
    (filter: IProgramsAllFilter) => dispatch(actions.setFilter(filter)),
    [dispatch]
  );
  const resetFilter: () => void = useCallback(() => dispatch(actions.resetFilter()), [dispatch]);

  const setCurrentPage = useCallback((newPage: number) => dispatch(actions.setCurrentPage(newPage)), [dispatch]);

  const setPerPage = useCallback((perPage: number) => dispatch(actions.setPerPage(perPage)), [dispatch]);

  const redirectToProgramDetails: (id: string) => void = useCallback(
    (id) => push(`${pages.adminPortal.programs.detailsLink}/${id}`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    loading,
    filter,
    programs,
    currentPage,
    perPage,
    totalCount,
    filterEntities,
    filterEntitiesCollection,
    yearsCollection,

    fetchPrograms,
    setCurrentPage,
    setPerPage,
    setFilter,
    resetFilter,
    redirectToProgramDetails,
  };
};

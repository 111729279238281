import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {
  itemSortOrderSelector,
  itemPageLoadingStatusSelector,
  itemsFilterSelector,
  itemsForPreviewSelector,
  itemsSortByColumnsSelector,
  paginationSelector,
  fetchListItemsPagedThunk,
  savingSelector,
} from '../store';
import {useAppDispatch} from 'hooks';
import {itemFiltersEntitiesSelector} from 'filters-selections';
import {itemsActions as actions} from '../store/items.slice';

export function useItems() {
  const dispatch = useAppDispatch();
  const saving = useSelector(savingSelector);
  const {loading: pageLoading} = useSelector(itemPageLoadingStatusSelector);

  const filter = useSelector(itemsFilterSelector);
  const filterEntities = useSelector(itemFiltersEntitiesSelector);
  const itemsList = useSelector(itemsForPreviewSelector);
  const sortByColumns = useSelector(itemsSortByColumnsSelector);
  const sortOrder = useSelector(itemSortOrderSelector);

  const {currentPage, perPage, totalCount} = useSelector(paginationSelector);

  const setCurrentPage = useCallback((newPage: number) => dispatch(actions.setCurrentPage(newPage)), [dispatch]);
  const setPerPage = useCallback((perPage: number) => dispatch(actions.setPerPage(perPage)), [dispatch]);

  const fetchItems = useCallback(
    () =>
      dispatch(
        fetchListItemsPagedThunk({
          filter,
          sortOrder,
          pagination: {
            page: currentPage,
            itemsPerPage: perPage,
          },
        })
      ),
    [currentPage, dispatch, filter, perPage, sortOrder]
  );

  return {
    pageLoading,
    saving,
    filter,
    filterEntities,
    sortByColumns,
    sortOrder,
    currentPage,
    perPage,
    totalCount,
    itemsList,
    fetchItems,
    setCurrentPage,
    setPerPage,
  };
}

import React, {FC, ReactNode} from 'react';
import {SubtitlePageViewHeader} from 'admin/components';
import {Row} from 'components-lib';
interface ISubtitleProps {
  title: string;
  withColor: boolean;
  withMargin?: boolean;
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  color: 'primary' | 'secondary' | 'textPrimary' | 'error';
  actions?: ReactNode;
}

export const Subtitle: FC<ISubtitleProps> = ({withColor, withMargin, as, color, title, actions}) => {
  return (
    <SubtitlePageViewHeader withColor={withColor} withMargin={withMargin} as={as} color={color}>
      <Row justify="space-between">
        {title}
        {actions && actions}
      </Row>
    </SubtitlePageViewHeader>
  );
};

import {makeStyles} from '@material-ui/core';
import {Bullet, Row, Text} from 'components-lib';
import moment from 'moment';
import React, {useMemo} from 'react';

const format = 'MMM Do, YYYY';

// TODO required
interface ISidebarAuthorProps {
  author?: string;
  date?: string;
  creatorArea?: string;
  questionType?: string;
}
export function SidebarAuthor({author = '', date = '', creatorArea = '', questionType = ''}: ISidebarAuthorProps) {
  const classes = useStyles();
  const dateFormatted = useMemo(() => {
    return date ? moment(date).format(format) : '';
  }, [date]);

  return (
    <>
      {author && (
        <Row justify="flex-start">
          <Bullet className={classes.details} />
          <Text.Paragraph variant="body1">
            Author: {author} {creatorArea && <span>({creatorArea})</span>}
          </Text.Paragraph>
        </Row>
      )}
      {dateFormatted && (
        <Row justify="flex-start">
          <Bullet className={classes.details} />
          <Text.Paragraph variant="body1">{dateFormatted}</Text.Paragraph>
        </Row>
      )}
      {questionType && (
        <Row justify="flex-start">
          <Bullet className={classes.details} />
          <Text.Paragraph variant="body1">{questionType}</Text.Paragraph>
        </Row>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  details: {
    marginLeft: theme.spacing(3),
  },
}));

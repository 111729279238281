import React, {FC, Fragment, ReactNode, useMemo} from 'react';
import {PageViewHeader} from 'components-lib';
import {SubtitlePageViewHeader} from 'admin/components';

interface IResourceHeaderProps {
  heading: string;
  subHeading?: string;
  withBackButton?: boolean;
  withSubHeading?: boolean;
  extraHeading?: ReactNode;
  backButtonClickHandler?: () => void;
}
export const ResourceHeader: FC<IResourceHeaderProps> = ({
  heading,
  subHeading,
  extraHeading,
  withBackButton = true,
  withSubHeading = true,
  backButtonClickHandler,
}) => {
  const headerSubHeading = useMemo(() => {
    return <Fragment>{subHeading && <SubtitlePageViewHeader> {`/ ${subHeading}`}</SubtitlePageViewHeader>}</Fragment>;
  }, [subHeading]);

  return (
    <PageViewHeader
      fullFlexWidth
      withBackButton={withBackButton}
      extraHeading={extraHeading}
      withColumnPadding={false}
      heading={heading}
      subHeading={withSubHeading && headerSubHeading}
      headingColor="textPrimary"
      backButtonClickHandler={backButtonClickHandler}
    />
  );
};

import React, {FC} from 'react';
import {FormNameEnum} from 'filters-selections';
import {TextInputSelection} from '../TextInputSelection/TextInputSelection';
import {IWithDisabled} from 'models/common';
interface IKeyTermsTextInputProps extends IWithDisabled {
  infoText?: string;
}

export const KeyTermsTextInput: FC<IKeyTermsTextInputProps> = ({disabled, infoText}) => (
  <TextInputSelection name={FormNameEnum.keyTerms} label="Key Terms" disabled={disabled} infoText={infoText} />
);

import {axiosApiInstance} from 'api';
import {config} from 'config';
import {ApiPathsEnum} from 'enums';
import {getApiMultipartFormDataHeadersConfig} from 'utils';

const {jaEeApi: apiConfig} = config;

export const fileManagerApi = {
  async uploadHeaderLogo(data: FormData) {
    const config = getApiMultipartFormDataHeadersConfig();
    const baseUrl = `${apiConfig.baseUrlUploader}/${ApiPathsEnum.HEADER_AND_FOOTER}/UploadHeader`;
    return axiosApiInstance.post(baseUrl, data, config);
  },

  async uploadFooterLogo(data: FormData) {
    const config = getApiMultipartFormDataHeadersConfig();
    const baseUrl = `${apiConfig.baseUrlUploader}/${ApiPathsEnum.HEADER_AND_FOOTER}/UploadFooter`;
    return axiosApiInstance.post(baseUrl, data, config);
  },
};

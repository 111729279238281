import {makeStyles} from '@material-ui/core';
import React, {useCallback, useRef} from 'react';
import {useToastNotifications} from '../../hooks/useToastNotification';
import {Notification} from '../Notification/Notification';

export function ToastNotification() {
  const classes = useStyles();
  const {current: scheduled} = useRef({});
  const {removeToastNotification, notifications, ids} = useToastNotifications();

  const updateSchedule = useCallback(
    (id) => {
      if (scheduled[id]) {
        delete scheduled[id];
        return;
      }

      scheduled[id] = true;
    },
    [scheduled]
  );

  const isScheduled = useCallback(
    (id) => {
      return scheduled[id];
    },
    [scheduled]
  );

  const close = useCallback(
    (id) => () => {
      updateSchedule(id);
      removeToastNotification(id);
    },
    [updateSchedule, removeToastNotification]
  );

  const autoClose = useCallback(
    (id, duration) => {
      setTimeout(() => {
        if (ids.includes(id)) {
          removeToastNotification(id);
        }

        updateSchedule(id);
      }, duration);
    },
    [removeToastNotification, ids, updateSchedule]
  );

  const renderNotifications = useCallback(() => {
    if (!notifications || !notifications.length) return null;

    return notifications.map(({id, duration, message, type}) => {
      if (!isScheduled(id)) {
        updateSchedule(id);
        autoClose(id, duration);
      }
      return <Notification key={id} type={type} message={message} closeHandler={close(id)} />;
    });
  }, [notifications, updateSchedule, autoClose, close, isScheduled]);

  return <div className={classes.container}>{renderNotifications()}</div>;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    bottom: 82,
    right: 32,
    zIndex: 1301,
  },
});

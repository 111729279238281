import * as Yup from 'yup';
import {FormNameEnum} from 'filters-selections';

export interface IInstrumentsAllFiltersFormValues {
  [FormNameEnum.programs]: number[];
  [FormNameEnum.pillars]: string[];
  [FormNameEnum.audience]: string[];
  [FormNameEnum.phase]: string[];
  [FormNameEnum.instrumentType]: string[];
  [FormNameEnum.instrumentStatus]: string[];
  [FormNameEnum.name]: string;
  [FormNameEnum.instrumentViewType]: number | null;
}

export const instrumentsAllFiltersFormValidationSchema = Yup.object().shape({
  [FormNameEnum.programs]: Yup.array(Yup.number()),
  [FormNameEnum.pillar]: Yup.array(Yup.number()),
  [FormNameEnum.audience]: Yup.array(Yup.number()),
  [FormNameEnum.phase]: Yup.array(Yup.number()),
  [FormNameEnum.instrumentType]: Yup.array(Yup.number()),
  [FormNameEnum.instrumentStatus]: Yup.array(Yup.number()),
  [FormNameEnum.name]: Yup.string().nullable(),
  [FormNameEnum.instrumentViewType]: Yup.number().nullable(),
});

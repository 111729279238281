import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {useAppDispatch, useNavigate} from 'hooks';
import {pages} from 'paths';
import {
  deleteLearningPathThunk,
  exportLearningPathThunk,
  fetchLearningPathByIdThunk,
  learningPathDetailsSelector,
  learningPathExportingSelector,
  loadingLearningPathSelector,
  publishLearningPathThunk,
  updateLearningPathThunk,
} from '../store';
import {ILearningPathDetails} from 'models/resources/learning-paths';
import {filtersFutureYearsEntitiesCollectionSelector, filtersFutureYearsEntitiesSelector} from 'filters-selections';
import {ILearningPathViewEditFormBaseValues} from '../components/EditLearningPathDetailsForm/config';
import {
  builderSessionItemsCollectionSelector,
  useLearningPathBuilderItemsEntities,
} from 'admin/resources/learning-paths/views/session-builder';
import {mapCreateUpdateLearningPathPayload} from 'admin/resources/learning-paths/utils/forms';
import {createLinkForDownload} from 'utils';

export const useEditLearningPath = () => {
  const dispatch = useAppDispatch();
  const {push} = useNavigate();
  const {builderSessionItemsCollection, hasSessions} = useLearningPathBuilderItemsEntities();

  const loading = useSelector(loadingLearningPathSelector) as boolean;
  const learningPathDetails = useSelector(learningPathDetailsSelector) as ILearningPathDetails;
  const sessions = useSelector(builderSessionItemsCollectionSelector);
  const futureProgramYears = useSelector(filtersFutureYearsEntitiesSelector);
  const futureProgramYearsCollection = useSelector(filtersFutureYearsEntitiesCollectionSelector);
  const exporting = useSelector(learningPathExportingSelector);

  const fetchLearningPathById: (id: string) => void = useCallback(
    (id) => {
      return dispatch(fetchLearningPathByIdThunk(id));
    },
    [dispatch]
  );

  const redirectToViewListOLearningPaths: () => void = useCallback(
    () => push(`${pages.adminPortal.learningPaths.root}`),
    [push]
  );

  const updateLearningPath = useCallback(
    (formValues: ILearningPathViewEditFormBaseValues) => {
      const futureProgramYearObj = futureProgramYears[formValues.futureProgramYear];
      const payload = mapCreateUpdateLearningPathPayload(
        {
          ...formValues,
          futureProgramYear: futureProgramYearObj ? futureProgramYearObj.displayText : null,
        },
        builderSessionItemsCollection,
        true
      );

      return dispatch(updateLearningPathThunk(payload)).then(unwrapResult);
    },
    [dispatch, builderSessionItemsCollection, futureProgramYears]
  );

  const deleteLearningPath: (contentItemId: string) => void = useCallback(
    (contentItemId) => {
      return dispatch(deleteLearningPathThunk(contentItemId)).then(() => redirectToViewListOLearningPaths());
    },
    [dispatch, redirectToViewListOLearningPaths]
  );

  const publishLearningPath: (contentItemId: string) => void = useCallback(
    (contentItemId) => {
      return dispatch(publishLearningPathThunk(contentItemId)).then(() => redirectToViewListOLearningPaths());
    },
    [dispatch, redirectToViewListOLearningPaths]
  );

  const fetchExportLearningPath: (id: string) => void = useCallback((id) => {
    return dispatch(exportLearningPathThunk(id))
      .then(unwrapResult)
      .then(({blob, fileName}) => {
        const objectUrl = window.URL.createObjectURL(blob);
        createLinkForDownload(objectUrl, fileName);
        // Freeing resources in memory
        window.URL.revokeObjectURL(objectUrl);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    exporting,
    sessions,
    learningPathDetails,
    futureProgramYears,
    futureProgramYearsCollection,
    hasSessions,

    publishLearningPath,
    updateLearningPath,
    deleteLearningPath,
    fetchLearningPathById,
    redirectToViewListOLearningPaths,
    fetchExportLearningPath,
  };
};

export enum FormNameEnum {
  file = 'file',
  isAccessibleToStudents = 'isAccessibleToStudents',
  hasUploadPermissions = 'hasUploadPermissions',
  displayName = 'displayName',
  keyTerms = 'keyTerms',
  programs = 'programs',
  sessions = 'sessions',
  pillars = 'pillars',
  competencies = 'competencies',
  gradeLevels = 'gradeLevels',
  skillsAndBehaviors = 'skillsAndBehaviors',
  languages = 'languages',
  briefDescription = 'briefDescription',
  learningObjectives = 'learningObjectives',
  accessExpirationDate = 'accessExpirationDate',
  fileType = 'fileType',
  knowledgeSkillsAndAbilities = 'knowledgeSkillsAndAbilities',
  shouldDisableFileType = 'shouldDisableFileType',
  webLink = 'webLink',
  time = 'time',
  expirationDate = 'expirationDate',
}

export const config = {
  jaEeApi: {
    baseUrl: process.env.REACT_APP_JA_EE_BASE_URL,
    baseUrlSurvey: process.env.REACT_APP_JA_EE_SURVEY_BASE_URL,
    baseUrlPermissions: process.env.REACT_APP_JA_EE_PERMISSIONS_BASE_URL,
    baseUrlUms: process.env.REACT_APP_JA_EE_UMS_BASE_URL,
    baseUrlUploader: process.env.REACT_APP_JA_EE_UPLOADER_BASE_URL,
    baseUrlDigitalResources: process.env.REACT_APP_JA_EE_DIGITAL_RESOURCES_BASE_URL,
    baseUrlReports: process.env.REACT_APP_JA_EE_REPORTS_BASE_URL,
    baseUrlExports: process.env.REACT_APP_JA_EE_EXPORTS_BASE_URL,
    baseUrlNotFound: process.env.REACT_APP_JA_EE_NOT_FOUND_REDIRECT_URL,
    baseUrlDownloadDigitalResource: process.env.REACT_APP_JA_EE_DIGITAL_RESOURCES_DOWNLOAD_BASE_URL,
    baseUrlIntegrations: process.env.REACT_APP_JA_PIC_INTEGRATIONS_BASE_URL,
    baseUrlLearningPaths: process.env.REACT_APP_JA_PAM_LEARNING_PATHS_BASE_URL,
    baseUrlPam: process.env.REACT_APP_JA_PAM_BASE_URL,
  },
  jaEeTestApi: {
    baseUrl: process.env.REACT_APP_JA_EE_TEST_API_BASE_URL,
    localBaseUrl: process.env.REACT_APP_JA_EE_LOCAL_TEST_API_BASE_URL,
    subscriptionKey: process.env.REACT_APP_JA_EE_TEST_API_SUBSCRIPTION_KEY,
  },
  jaEEfileUploadApi: {
    baseUrl: process.env.REACT_APP_JA_EE_FILE_UPLOAD_URL,
  },
  oidc: {
    authority: process.env.REACT_APP_JASA_AUTHORITY,
    client_id: process.env.REACT_APP_JASA_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_JASA_REDIRECT_URL,
    response_type: 'id_token token',
    scope: 'openid profile jaeeapim',
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_PAGE,
    silent_redirect_uri: process.env.REACT_APP_JASA_SILENT_REDIRECT_URL,
    automaticSilentRenew: false,
    loadUserInfo: true,
    monitorSession: false,
    accessTokenExpiringNotificationTime: 60,
    checkSessionInterval: 20000,
    revokeAccessTokenOnSignout: true,
  },
  authentication: {
    endSessionUrl: process.env.REACT_APP_END_SESSION_URL,
  },
  isProduction: process.env.NODE_ENV === 'production',
};

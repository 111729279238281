interface ILevelReportingMenuConfig {
  style: {
    height: number;
    width: number;
  };
}

const DEFAULT_HEIGHT = 333 as number;
const DEFAULT_WIDTH = 404 as number;

export const menuConfig: ILevelReportingMenuConfig = {
  style: {
    height: DEFAULT_HEIGHT,
    width: DEFAULT_WIDTH,
  },
};

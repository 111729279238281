import {Divider} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';

interface IDividerVerticalProps {
  size?: 'small' | 'medium';
}

export function DividerVertical({size}: IDividerVerticalProps) {
  const classes = useStyles({size});
  return <Divider orientation="vertical" flexItem className={classes.root} />;
}

const useStyles = makeStyles((theme) => ({
  root: ({size}: IDividerVerticalProps) => {
    const margin = size === 'small' ? theme.spacing(3) : theme.spacing(4);
    return {
      marginRight: margin,
      marginLeft: margin,
    };
  },
}));

import {Row, Tag} from 'components-lib';
import React from 'react';

interface ISidebarTagProps {
  text: string;
}

export function SidebarTag({text}: ISidebarTagProps) {
  return (
    <Row justify="flex-start">
      <Tag label={text} color="default" />
    </Row>
  );
}

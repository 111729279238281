import {ArrayHelpers, FieldArray} from 'formik';
import {IComponentWithChildren} from 'models';
import React from 'react';

interface IFormArrayProps extends IComponentWithChildren {
  name: string;
}

export function FormArray({name, children}: IFormArrayProps) {
  return <FieldArray name={name} render={(arrayHelpers: ArrayHelpers) => children(arrayHelpers)} />;
}

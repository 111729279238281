import {FormNameEnum} from 'filters-selections';
import {ItemWithPlaceholder} from 'filters-selections/selection/components';
import {filtersEvaluationTypeEntitiesCollectionSelector, fetchEvaluationTypesThunk} from 'filters-selections/store';
import {Form} from 'forms';
import {IWithDisabled} from 'models';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEvaluationTypeSelectProps extends IWithDisabled {}

export function EvaluationTypeSelect({disabled}: IEvaluationTypeSelectProps) {
  const dispatch = useDispatch();
  const evaluationTypes = useSelector(filtersEvaluationTypeEntitiesCollectionSelector);

  useEffect(() => {
    if (!evaluationTypes.length) {
      dispatch(fetchEvaluationTypesThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = useMemo(
    () => evaluationTypes.map((evaluation) => ({value: evaluation.id, label: evaluation.displayText})),
    [evaluationTypes]
  );

  return (
    <ItemWithPlaceholder label="Evaluation Type" hasItems={!!evaluationTypes.length}>
      <Form.SelectAutocomplete name={FormNameEnum.evaluationTypes} options={options} multiple disabled={disabled} />
    </ItemWithPlaceholder>
  );
}

import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core';
import {Wrapper, Button} from 'components-lib';
import {Form} from 'forms';
import {useFormikContext} from 'formik';
import {useItemsImport} from 'admin';
interface IContentFooterProps {
  isFirstStep: boolean;
  isSecondStep: boolean;
  isThirdStep: boolean;
  shouldDisableCancelButton: boolean;
  shouldDisableNextButton: boolean;
  shouldDisableImportButton: boolean;
  openModalCancelDataImportHandler: () => void;
  nextClickHandler: (formValues: any, resetForm: any) => void;
}

export const ContentFooter: FC<IContentFooterProps> = ({
  isFirstStep,
  isSecondStep,
  isThirdStep,
  shouldDisableCancelButton,
  shouldDisableNextButton,
  shouldDisableImportButton,
  openModalCancelDataImportHandler,
  nextClickHandler,
}) => {
  const classes = useStyles();
  const {values, resetForm} = useFormikContext();
  const {loading, uploading, previewLoading, hasRequiredFieldErrors} = useItemsImport();

  let nextButtonText = 'Next';

  if (loading || uploading || previewLoading) {
    nextButtonText = 'Loading';
  }

  return (
    <Wrapper className={classes.actionButtons}>
      <Button.Secondary disabled={shouldDisableCancelButton} clickHandler={openModalCancelDataImportHandler}>
        Cancel
      </Button.Secondary>
      {isFirstStep && <Form.ButtonSubmit size="large" text={nextButtonText} disabled={shouldDisableNextButton} />}
      {isSecondStep && (
        <Button.Primary
          size="large"
          disabled={hasRequiredFieldErrors}
          clickHandler={() => nextClickHandler(values, resetForm)}
        >
          Next
        </Button.Primary>
      )}
      {isThirdStep && <Form.ButtonSubmit size="large" text="Import Data" disabled={shouldDisableImportButton} />}
    </Wrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
}));

import {makeStyles} from '@material-ui/core';
import {Text} from 'components-lib';
import React from 'react';

interface IMatrixEditExtraHeaderProps {
  id: number;
}

export function MatrixEditExtraHeader({id}: IMatrixEditExtraHeaderProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Text.Heading as="h4">Editing matrix item with ID: {id} </Text.Heading>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
    bottom: 20,
  },
});

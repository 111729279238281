import {createAsyncThunk} from '@reduxjs/toolkit';
import {pamApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {getContentResponseHeaders} from '../../utils';
import {prefix} from './config';

export const downloadFileByIdThunk: any = createAsyncThunk<any, string>(
  `${prefix} downloadFileById`,
  async (id, {dispatch, rejectWithValue}) => {
    dispatch(toastNotificationManager.createSuccessToastAction('The resource is being downloaded.'));
    try {
      const response = await pamApi.downloadFileByResourceId(id);
      const {mimeType, fileName} = getContentResponseHeaders(response);

      return {
        blob: response.data,
        fileName,
        mimeType,
      };
    } catch (error) {
      dispatch(toastNotificationManager.createErrorToastAction(error.parsedBody));
      return rejectWithValue({error});
    }
  }
);

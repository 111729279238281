import React from 'react';
import {SortByDropdown} from 'admin/components';
import {getSortGoogleSiteDetailsMenuItemsList, googleSiteDetailsOrderingMap} from 'admin/resources/google-sites';
import {useGoogleSiteDetailsSortOrder} from 'admin/resources/google-sites/details/hooks';

export const GoogleSitesAreasSortBy = () => {
  const {sortOrder, handleItemClick} = useGoogleSiteDetailsSortOrder();

  return (
    <SortByDropdown
      label="Sort By: "
      handleItemClick={handleItemClick}
      getMenuItemsList={getSortGoogleSiteDetailsMenuItemsList}
      sortOrder={sortOrder}
      map={googleSiteDetailsOrderingMap}
      justify="flex-end"
    />
  );
};

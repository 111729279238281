import React from 'react';
import {useBeforeunload} from 'react-beforeunload';
import {useUploadActionButtons} from 'admin/resources/upload/hooks';
import {uploadMessages} from 'admin/upload/utils';
import {UploadFooter} from 'admin/resources/upload/components';

export const WebLinkFooter = () => {
  const {
    hasOneOfAddWebLinkRequiredFields,
    shouldDisableAddWebLink,
    redirectToViewListOfFiles,
  } = useUploadActionButtons();

  useBeforeunload(() => {
    if (hasOneOfAddWebLinkRequiredFields) {
      return uploadMessages.warning;
    }
  });

  return (
    <UploadFooter
      showPrompt={hasOneOfAddWebLinkRequiredFields}
      redirectCallback={redirectToViewListOfFiles}
      disableSubmit={shouldDisableAddWebLink}
    />
  );
};

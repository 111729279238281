import React, {useCallback, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {Filters} from 'components-lib';
import {FiltersList, FormNameEnum} from 'filters-selections';
import {Form} from 'forms';
import {AdminRoles, usePermissions} from 'permissions';
import {toNumber} from 'utils';
import {ViewExpansionPanel} from '../../../components';
import {FilterBackendNamesEnum} from '../../enums';
import {useProgramClassInstruments} from '../../hooks';
import {classInstrumentFilterList, sidebarFiltersValidationSchema} from '../../utils';

interface IFilterForm {
  [FormNameEnum.search]: string;
  [FormNameEnum.instrumentStatus]: number[];
  [FormNameEnum.audience]?: number[];
  [FormNameEnum.instrumentType]?: number[];
  [FormNameEnum.phase]?: number[];
  [FormNameEnum.pillar]: number[];
  [FormNameEnum.instrumentViewType]: number | null;
}

export function SidebarFilters() {
  const {programId} = useParams<{programId: string}>();
  const {loading, filter, setFilter, resetFilter} = useProgramClassInstruments(toNumber(programId));
  const {hasPermission} = usePermissions();
  const shouldRenderFilters = hasPermission(AdminRoles);

  const initialValues: IFilterForm = useMemo(
    () => ({
      [FormNameEnum.search]: filter.name,
      [FormNameEnum.instrumentStatus]: filter.statuses,
      [FormNameEnum.audience]: filter.audiences,
      [FormNameEnum.instrumentType]: filter.types,
      [FormNameEnum.phase]: filter.phases,
      [FormNameEnum.pillar]: filter.pillars,
      [FormNameEnum.instrumentViewType]: filter.isGeneralTemplate,
    }),
    [filter]
  );

  const renderFiltersList = useMemo(() => {
    return (
      <>
        <ViewExpansionPanel disabled={loading} />
        {shouldRenderFilters && <FiltersList filters={classInstrumentFilterList} disableAll={loading} />}
      </>
    );
  }, [loading, shouldRenderFilters]);

  const submitHandler = useCallback(
    (values: IFilterForm) => {
      return setFilter({
        [FilterBackendNamesEnum.SEARCH_BY_NAME]: values.search,
        [FilterBackendNamesEnum.INSTRUMENT_STATUSES]: values.status,
        [FilterBackendNamesEnum.AUDIENCES]: values.audience,
        [FilterBackendNamesEnum.TYPES]: values.type,
        [FilterBackendNamesEnum.PHASES]: values.phase,
        [FilterBackendNamesEnum.PILLARS]: values.pillar,
        [FilterBackendNamesEnum.INSTRUMENT_VIEW_TYPE]: values.instrumentViewType,
      });
    },
    [setFilter]
  );

  return (
    <Form.Form<IFilterForm>
      initialValues={initialValues}
      validationSchema={sidebarFiltersValidationSchema}
      submitHandler={submitHandler}
    >
      <Filters.FiltersPanel
        clearButtonClickHandler={resetFilter}
        searchFieldName={'search'}
        filters={renderFiltersList}
        disableAll={loading}
      />
    </Form.Form>
  );
}

import React, {FC} from 'react';
import VerticalPositioned from './VerticalPositioned/VerticalPositioned';
import {HorizontalPositioned} from './HorizontalPositioned/HorizontalPositioned';
import {BrowseButton} from './BrowseButton/BrowseButton';
import {IntegrationCertificateEnum} from 'admin/integrations/enums';
import {IWithDisabled} from 'models/common';
interface IUploadSingleFileProps extends IWithDisabled {
  position: 'vertical' | 'horizontal';
  name: string;
  contextName?: string;
  loading: boolean;
  accept?: string;
  title?: string;
  withOnlyBrowseButton?: boolean;
  deleteCallback?: () => void;
}

export const UploadSingleFile: FC<IUploadSingleFileProps> = ({
  position = 'vertical',
  title,
  name,
  loading,
  accept = IntegrationCertificateEnum.P12,
  disabled,
  withOnlyBrowseButton = false,
  deleteCallback,
}) => {
  const isPositionVertical = position === 'vertical';

  if (isPositionVertical) {
    return (
      <VerticalPositioned
        name={name}
        loading={loading}
        accept={accept}
        disabled={disabled}
        deleteCallback={deleteCallback}
      />
    );
  }

  if (!isPositionVertical && withOnlyBrowseButton) {
    return (
      <BrowseButton
        disabled={disabled}
        loading={loading}
        name={name}
        accept={accept}
        withOnlyBrowseButton={withOnlyBrowseButton}
      />
    );
  }

  return (
    <HorizontalPositioned
      disabled={disabled}
      title={title}
      name={name}
      accept={accept}
      loading={loading}
      deleteCallback={deleteCallback}
      withOnlyBrowseButton={withOnlyBrowseButton}
    />
  );
};

import React from 'react';
import {makeStyles} from '@material-ui/core';
import {IBuilderDragItem} from 'admin/library/models';
import {ListInfinite, Loading, Row, Text} from 'components-lib';
import {TBuilderItemBase} from 'models';
import {useBuilderItemsEntities} from '../../hooks';
import {PoolItemMatrixMode} from '../PoolItemMatrixMode/PoolItemMatrixMode';

interface IPoolItemsListProps {
  parentQuestionId: number;
  hasMorePages: boolean;
  loading: boolean;
  builderItemsBase: TBuilderItemBase[];
  loadMoreHandler: () => void;
  dropHandler: (item: IBuilderDragItem) => void;
}

export function PoolItemsMatrixList({
  parentQuestionId,
  hasMorePages,
  loading,
  builderItemsBase,
  loadMoreHandler,
  dropHandler,
}: IPoolItemsListProps) {
  const classes = useStyles();
  const {builderItemsEntities} = useBuilderItemsEntities();

  const itemRows = builderItemsEntities[parentQuestionId].rows ?? [];
  const hasBuilderItems = builderItemsBase.length > 0;
  const loadingSize = hasBuilderItems ? 20 : 40;

  return (
    <ListInfinite hasMorePages={hasMorePages} loadMoreHandler={loadMoreHandler} loading={loading}>
      {!!builderItemsBase.length &&
        builderItemsBase.map((item, idx) => (
          <PoolItemMatrixMode key={idx} item={item} itemRows={itemRows} dropHandler={dropHandler} />
        ))}

      <Row justify="center" align="flex-start" classes={{root: classes.loadingContainer}}>
        {loading && <Loading halfHeight={!hasBuilderItems} size={loadingSize} />}
        {loading && hasBuilderItems && <Text.Caption>Loading more items...</Text.Caption>}
      </Row>
    </ListInfinite>
  );
}

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    marginBottom: theme.spacing(2),
  },
}));

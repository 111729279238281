import React, {useEffect} from 'react';
import List from '@material-ui/core/List';
import {useFormikContext} from 'formik';
import {Column, Row} from 'components-lib';
import {AddResourcesDrawerListItem} from '..';
import {uniq} from 'utils';
import {FormNameEnum} from 'enums/form';
import {IAddSessionResourcesFormValues} from '../AddSessionResourcesForm/config';
import {getResourcesCollectionByIds} from 'admin/resources/learning-paths/views/create';
import {useLearningPathBuilderItemsEntities} from 'admin/resources/learning-paths/views/session-builder';

export const AddResourcesDrawerList = () => {
  const {
    values: {resources, selectedResources, selectedResourcesIds},

    setFieldValue,
  } = useFormikContext<IAddSessionResourcesFormValues>();
  const {resourcesConcatenatedBaseEntitiesCollection} = useLearningPathBuilderItemsEntities();

  useEffect(() => {
    if (resources.length > 0) {
      const uniqueCollectionIds = uniq([...resources, ...selectedResourcesIds]) as string[];

      setFieldValue(FormNameEnum.selectedResourcesIds, uniqueCollectionIds);
      setFieldValue(
        FormNameEnum.selectedResources,
        getResourcesCollectionByIds(uniqueCollectionIds, resourcesConcatenatedBaseEntitiesCollection)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources, resourcesConcatenatedBaseEntitiesCollection]);

  return (
    <Row justify="center">
      <Column>
        <List>
          {selectedResources?.length > 0 &&
            selectedResources.map((item) => (
              <AddResourcesDrawerListItem key={item?.contentItemId} id={item?.contentItemId} name={item?.title} />
            ))}
        </List>
      </Column>
    </Row>
  );
};

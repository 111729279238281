import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Accordion, Column, Text} from 'components-lib';
import {FormNameEnum} from 'filters-selections/enums';
import {filtersEducatorsEntitiesCollectionSelector} from 'filters-selections/store';
import {Form} from 'forms';
import {IOption, IWithDisabled} from 'models';

export function EducatorFilter({disabled}: IWithDisabled) {
  const educators = useSelector(filtersEducatorsEntitiesCollectionSelector);

  const options: IOption[] = useMemo(() => {
    return educators.map((program) => ({value: program.id, label: program.name}));
  }, [educators]);

  return (
    <Accordion heading="Educators">
      <Column>
        {educators.length ? (
          <Form.SelectAutocomplete name={FormNameEnum.educators} options={options} multiple disabled={disabled} />
        ) : (
          <Text.Caption>No Educators</Text.Caption>
        )}
      </Column>
    </Accordion>
  );
}

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

interface IExpandIconProps {
  color?: 'primary' | 'secondary' | 'inherit';
}

export function ExpandIcon({color = 'inherit'}: IExpandIconProps) {
  return <ExpandMoreIcon color={color} />;
}

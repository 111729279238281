import React, {FC, Fragment, useCallback} from 'react';
import {Button, Modal} from 'components-lib';
import {TableVersions} from './TableVersions';
import {IFileVersion} from 'models/resources';
interface IModalVersionsProps {
  isOpen: boolean;
  versions: IFileVersion[];
  setIsOpen: (arg: boolean) => void;
}

export const ModalVersions: FC<IModalVersionsProps> = ({isOpen, setIsOpen, versions}) => {
  const closeClickHandler = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <Modal isOpen={isOpen} handleClose={closeClickHandler} title="Version" size="large">
      <TableVersions versions={versions} />
      <Fragment>
        <Button.Secondary clickHandler={closeClickHandler}>Close</Button.Secondary>
      </Fragment>
    </Modal>
  );
};

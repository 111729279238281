import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Checkbox, Wrapper} from 'components-lib';

export const GoogleSiteAreasCheckbox = () => {
  const classes = useStyles();
  return (
    <Wrapper className={classes.googleSiteAreaCheckbox}>
      <Checkbox checked={false} disabled />
    </Wrapper>
  );
};

export const useStyles = makeStyles((theme) => ({
  googleSiteAreaCheckbox: {
    paddingRight: theme.spacing(2),
  },
}));

import {createAsyncThunk} from '@reduxjs/toolkit';
import {itemsApi} from 'api';
import {toastNotificationManager} from 'toast-notifications';
import {prefix} from './config';

export const fetchPossibleDefaultAnswerStacksThunk: any = createAsyncThunk<any>(
  `${prefix} fetchPossibleDefaultAnswerStacks`,
  async (id, {dispatch, rejectWithValue}) => {
    try {
      const questionId = Number(id);
      const {parsedBody} = await itemsApi.getPossibleDefaultAnswerStacks(questionId);
      return parsedBody;
    } catch (err) {
      dispatch(toastNotificationManager.createErrorToastAction(err.parsedBody));
      return rejectWithValue({error: err});
    }
  }
);

import {IVerticalDropdownItem} from 'admin/resources/files';

export const mapFilesDropdownList = (list: IVerticalDropdownItem[], isFileExpired: boolean) =>
  list.map((item) => {
    if (isFileExpired && !item.isDownload) {
      return {
        ...item,
        disabled: true,
      };
    }
    return item;
  });

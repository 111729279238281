import React, {useCallback, useState} from 'react';
import {unwrapResult} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'hooks';
import {IWithModal, IWithSetSubmitting} from '../../../../../../models/index';
import {rolloverTemplatesThunk} from '../../../../../../admin/library/instruments/store/thunks/rolloverTemplates.thunk';
import {IRolloverProgramsFormData} from '../RolloverProgramsForm';
import {RolloverProgramsForm} from '../RolloverProgramsForm';
import {ModalRolloverProgram} from '../ModalRolloverProgram';
import {ITemplateRolloverDto} from 'models';
import {IGetTemplateRolloverResponse} from 'models/library/instruments/IGetTemplateRolloverResponse';
import {createLinkForDownload} from 'utils';
import {downloadFileThunk} from 'admin/resources/files/views/all/store';

interface IModalRolloverProgramInstrumentsProps extends IWithModal {
  sourceMasterTemplateIds: number[];
}

export function ModalRolloverProgramInstruments({
  sourceMasterTemplateIds,
  isOpen,
  setIsOpen,
}: IModalRolloverProgramInstrumentsProps) {
  const dispatch = useDispatch();
  const {push, locationState} = useNavigate();
  const [error, setError] = useState<string>();

  const closeHandler = useCallback(() => setIsOpen(false), [setIsOpen]);

  const submitClickHandler = useCallback(
    ({programs}: IRolloverProgramsFormData, actions: IWithSetSubmitting) => {
      const templateRolloverData: ITemplateRolloverDto = {
        sourceMasterTemplateIds: sourceMasterTemplateIds,
        destinationProgramIds: programs,
      };

      setError('');

      dispatch(rolloverTemplatesThunk({templateRolloverData}))
        .then(unwrapResult)
        .then((response: IGetTemplateRolloverResponse) => {
          setIsOpen(false);
          actions.setSubmitting(false);

          const blob = new Blob([response.data], {
            type: response.mimeType,
          });

          const objectUrl = window.URL.createObjectURL(blob);
          createLinkForDownload(objectUrl, response.fileName);
          // Freeing resources in memory
          window.URL.revokeObjectURL(objectUrl);
        })
        .catch((error: Error) => {
          actions.setSubmitting(false);
          setError(error.message);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceMasterTemplateIds, locationState, setIsOpen, downloadFileThunk]
  );

  return (
    <RolloverProgramsForm submitHandler={submitClickHandler}>
      <ModalRolloverProgram isOpen={isOpen} error={error} closeHandler={closeHandler} />
    </RolloverProgramsForm>
  );
}

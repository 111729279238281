import {SortingOrderEnum, EntitiesPerPageEnum} from 'enums';
import {IInstrumentsAllFilter} from 'models';
import {InstrumentsFilterNamesEnum, InstrumentsOrderingEnum} from '../enums';
import {IInstrumentsStoreState} from './IInstrumentsStoreState';

const filterDefault: IInstrumentsAllFilter = {
  [InstrumentsFilterNamesEnum.SEARCH_BY_NAME]: '',
  [InstrumentsFilterNamesEnum.PROGRAMS]: [],
  [InstrumentsFilterNamesEnum.PILLARS]: [],
  [InstrumentsFilterNamesEnum.PHASES]: [],
  [InstrumentsFilterNamesEnum.AUDIENCES]: [],
  [InstrumentsFilterNamesEnum.TYPES]: [],
  [InstrumentsFilterNamesEnum.STATUSES]: [],
  [InstrumentsFilterNamesEnum.ADMINISTRATION]: [],
  [InstrumentsFilterNamesEnum.CONCEPT]: [],
  [InstrumentsFilterNamesEnum.DOMAIN]: [],
  [InstrumentsFilterNamesEnum.COMPETENCY]: [],
  [InstrumentsFilterNamesEnum.CATEGORY]: '',
  [InstrumentsFilterNamesEnum.LEARNING_OBJECTIVE]: '',
  [InstrumentsFilterNamesEnum.EVALUATION_TYPE]: [],
  [InstrumentsFilterNamesEnum.SCHOOL_LEVEL]: [],
  [InstrumentsFilterNamesEnum.AREAS]: [],
  [InstrumentsFilterNamesEnum.INSTRUMENT_VIEW_TYPE]: null,
  [InstrumentsFilterNamesEnum.SORT_BY]: null,
  [InstrumentsFilterNamesEnum.ORDER_BY]: null,
};

export const instrumentsInitialState: IInstrumentsStoreState = {
  sortOrder: {
    order: InstrumentsOrderingEnum.Name,
    sort: SortingOrderEnum.ASC,
  },
  filter: filterDefault,
  page: {
    loading: true,
    saving: false,
    deleting: false,
  },
  pagination: {
    currentPage: 1,
    totalCount: 0,
    perPage: EntitiesPerPageEnum.FIFTY,
  },

  templates: {
    result: [],
    entities: {
      items: {},
    },
  },

  survey: {
    previewTemplate: {
      result: [],
      entities: {
        items: {},
      },
    },
  },

  builder: {
    poolItems: {
      result: [],
      entities: {
        items: {},
      },
    },
    builderItems: {
      result: [],
      entities: {
        items: {},
      },
      loading: false,
    },
    temporaryBuilderItems: {
      result: [],
      entities: {
        items: {},
      },
    },
    pretestItems: {
      result: [],
      entities: {
        items: {},
      },
    },
    pagesTotal: {
      poolItems: 0,
    },
    searchText: '',
    filter: filterDefault,
  },
};

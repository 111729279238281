import {schema as Schema} from 'normalizr';

export const itemPoolEntity = new Schema.Entity('items', {}, {idAttribute: 'questionId'});
export const poolItemsSchema = new Schema.Array(itemPoolEntity);

export const itemBuilderEntity = new Schema.Entity('items', {}, {idAttribute: 'questionAnswerStackId'});
export const builderItemsSchema = new Schema.Array(itemBuilderEntity);

export const pretestItemEntity = new Schema.Entity('items', {}, {idAttribute: 'questionId'});
export const pretestItemsSchema = new Schema.Array(pretestItemEntity);

export const instrumentsListEntity = new Schema.Entity('items', {}, {idAttribute: 'templateId'});
export const instrumentsListSchema = new Schema.Array(instrumentsListEntity);
